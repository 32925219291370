import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { CheckValidatorService } from 'src/app/services/tools/check-validator.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    imports: [NgIf, MatCardModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatIconModule, MatTooltipModule, MatCheckboxModule, RouterLink, MatButtonModule, MatProgressSpinnerModule]
})
export class LoginComponent implements OnInit {

  waitingConnexion: boolean = false;

  loginForm       : FormGroup;
  innerWidth      : any;
  innerHeight     : any;

  today           : string = '';
  isServiceFFEA   : boolean = false;
  fragmentForgetPassword : string = '';
  // US 346 : afficher/masquer le mot de passe
  hidePassword : boolean = true;
  
  get adresseEmail() { return this.loginForm.get('adresseEmail'); }

  constructor(
    private fBuilder: FormBuilder, 
    private apiAuth: ApiAuthentificationService,
    private router: Router
    ) { 
      this.loginForm = this.fBuilder.group({
        adresseEmail: ['', [Validators.required, CheckValidatorService.checkMail]],
        motDePasse:   ['', [Validators.required/*, /CheckValidatorService.verificationFormatMotDePasse*/]], // TODO : Décommenter ce bloc pour le contrôle du mot de passe. (commenté pour pouvoir utilisé en mode dév des mots de passe "simple")
        rememberMe :  [0]
      });

      if(this.apiAuth.getRememberMeOnLocalStorage() === true) {
        this.loginForm.patchValue({
          adresseEmail: this.apiAuth.getUserLoginOnLocalStorage(),
          // motDePasse  : this.apiAuth.getUserPasswordOnLocalStorage(),
          rememberMe  : this.apiAuth.getRememberMeOnLocalStorage()
        });
      }      
    }
  
  ngOnInit(): void {       
    this.innerWidth   = window.innerWidth;
    this.innerHeight  = window.innerHeight;
    
    this.isServiceFFEA = this.apiAuth.isServiceFFEA()
    this.fragmentForgetPassword = ( this.isServiceFFEA ? this.apiAuth.CONST_URL_ROOT_SERVICE : this.apiAuth.CONST_URL_ROOT_EXTRANET)
  }

  ngOnDestroy(): void {        
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
  }

  public isMobileFormat() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    if (this.innerWidth <= 500 || this.innerHeight <= 500) {
      return true;
    } else {
      return false;
    }
  }

  connexion(){

    this.waitingConnexion = true;
    this.apiAuth.setRememberMeOnLocalStorage(this.loginForm.value.rememberMe);

    this.apiAuth.logIn(
          this.loginForm.value.adresseEmail, 
          this.loginForm.value.motDePasse
      ).subscribe(
        (data: any) => {
          this.waitingConnexion = false;
        },
        (err: any) => {
          this.waitingConnexion = false;
        },
        () => {
          this.waitingConnexion = false;
        }
      );  
  }

  goToSupport() {
    this.router.navigate(['/support/nous-contacter']);
  }
}
