import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, combineLatest, merge } from 'rxjs';
import { map, scan } from 'rxjs/operators';
import { Analyse, AnalyseFilter, AnalyseInitiale } from 'src/app/interfaces/adela/analyse';
import { ListeElements } from 'src/app/interfaces/adela/liste-elements';
import { ListeTypeAffaire } from 'src/app/interfaces/adela/liste-type-affaire';
import { TypeElement } from 'src/app/interfaces/adela/resultat-analyse';
import { CST_ACTION_ADD, CST_ACTION_MODIFY, CST_ACTION_REMOVE } from 'src/app/interfaces/constantes';
import { ApiAdelaService } from 'src/app/services/api-adela.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class DataAccessService {



  fluides$      : Observable<ListeElements[]>     = this.apiAdela.getTypeFluide()
  organes$      : Observable<ListeElements[]>     = this.apiAdela.getOrgane()
  prestations$  : Observable<Analyse[]>           = this.apiAdela.getAnalyse(1)   
  typeAnalyses$ : Observable<ListeTypeAffaire[]>  = this.apiAdela.getTypeAnalyse()
  typeElements$ : Observable<TypeElement[]>       = this.apiAdela.getTypeElement()

  private analyseFilterSubject = new BehaviorSubject<AnalyseFilter>({})
  filterAction$  =  this.analyseFilterSubject.asObservable()

  private prestationInsertedOrDeleteSubject = new Subject<any>()
  prestationInsertedOrDeleteAction$ = this.prestationInsertedOrDeleteSubject.asObservable()

  prestationUpdatedSubject = new BehaviorSubject<Analyse>(AnalyseInitiale)
  prestationUpdatedAction$ = this.prestationUpdatedSubject.asObservable()

prestationsWithAllOperations$ = merge(
  this.prestations$,
  this.prestationInsertedOrDeleteAction$,
  this.prestationUpdatedAction$
).pipe(
  scan((acc,value) => {
    if (value instanceof Array) {
        acc = value
        return acc
    }else{
      switch (value.action) {
        case CST_ACTION_ADD:
          return [value,...acc]
          break;
        
        case CST_ACTION_MODIFY:
          const prestationsUpdated = acc.map((prestation:Analyse)=> prestation.id === value.id? value : prestation)
          acc = prestationsUpdated
          return acc
          break;
      
        case CST_ACTION_REMOVE:
            const index = acc.findIndex((prestation:Analyse) => prestation.id === value.id)
          if (index>-1) {
            acc.splice(index,1)
            return acc
          }else{
            return acc
          }
          break;

        default:
          return acc
          break;
      }
    }
  })
)
     
prestationsAll$ = combineLatest([
  this.prestationsWithAllOperations$,
  this.fluides$,
  this.organes$
]).pipe(map(
  ([prestations,fluides,organes])=>{
    const newPrestations =prestations.map((prestation:Analyse)=>({
      ...prestation,
      organe:organes.find(organe => prestation.idOrgane === organe.id)?.nom,
      fluide:fluides.find(fluide => prestation.idFluide === fluide.id)?.nom
    }))
    return newPrestations
    }
))

  constructor(  private apiAdela : ApiAdelaService
              ,private apiToast : PersoSnackbarService) { }

  deletePrestation(prestationToDelete:Analyse){
    
    this.apiAdela.deletePrestation(prestationToDelete.id).subscribe(
      (info)  => {
        this.prestationInsertedOrDeleteSubject.next({...prestationToDelete,action:CST_ACTION_REMOVE})
        this.apiToast.showInfo(info.message)
      },
      (error) => {
        this.apiToast.showError(error.error)
      }
    )
  }

  savePrestation(prestationToSave :Analyse | undefined){
    
    if (prestationToSave) {
      const created:boolean = prestationToSave.id===0? true : false
      
      this.apiAdela.postPrestation(prestationToSave).subscribe(
        (prestation:Analyse)=> {
          
          if (created===true) {
            this.prestationInsertedOrDeleteSubject.next({...prestation,action:CST_ACTION_ADD});
          }else{
            this.prestationUpdatedSubject.next({...prestation,action:CST_ACTION_MODIFY});
          }
          this.apiToast.showInfo('La prestation a bien été enregistrée')
        },
        () => this.apiToast.showError('Impossible d\'enregistrer la prestation')
      )
    }

  }
  
}
