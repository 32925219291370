import { DatePipe, NgIf } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableFilter, MatTableFilterModule } from 'mat-table-filter';
import { Etiquette } from 'src/app/interfaces/adela/etiquette';
import { Adresse, AdresseInit } from 'src/app/interfaces/adresse/adresse';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ApiAdelaService } from 'src/app/services/api-adela.service';
import { MatTableResponsiveDirective } from '../../../directive/mat-table-responsive/mat-table-responsive.directive';
import { CarteAdresseComponent } from '../../templates/cards/carte-adresse/carte-adresse.component';
import { EntiteSearchComponent } from '../../templates/entite-search/entite-search.component';
import { DialogEtiquetteAdelaConfirmationComponent } from '../dialog-etiquette-adela-confirmation/dialog-etiquette-adela-confirmation.component';


// Interface local utilisé pour pouvoir filtrer la table "Etiquette"
// Cette programmation est dû aux filtres de recherche qui bloque lorsqu'on tente d'accéder à la sous-interface Entite d'Etiquette
// On est donc obligé de déclaré une interface 'Vue Table' = tableEtiquette
export interface TableEtiquette {
  id            : number;
  id_entite     : number;
  numero        : string;
  date          : Date;
  habilitation  : string;
  raison_sociale: string;
  adresse       : string;
  ville         : string;
  code_postal   : string;
  actif         : boolean;
  type          : string
}

@Component({
    selector: 'app-etiquette-adela',
    templateUrl: './etiquette-adela.component.html',
    styleUrls: ['./etiquette-adela.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatFormFieldModule, MatInputModule, MatTableModule, MatTableFilterModule
            , MatTableResponsiveDirective, NgIf, MatButtonModule, MatIconModule, MatPaginatorModule
            , DatePipe,EntiteSearchComponent,CarteAdresseComponent,MatTooltipModule]
})

export class EtiquetteAdelaComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;
  
  // Gestion de l'affichage
  waitingPrint              : boolean = false;
  
  // Variables pour gérer l'envoi/réception avec l'apiAdela
  etiquette   : Etiquette         = <Etiquette>{};
  etiquettes  : Array<Etiquette>  = new Array<Etiquette>();
  
  // Tableau des étiquettes
  tableEtiquettes : TableEtiquette[] = [];
  displayedColumns: string[] = ['numero', 'type','date_impression', 'date','actions'];
  dataSource      : MatTableDataSource<Etiquette>;
  filterType      : MatTableFilter = MatTableFilter.ANYWHERE;
  filterEntity    : TableEtiquette = <TableEtiquette>{};
  searching       : boolean = false
  entiteSelected   : Entite|null =null
  adresseEntite     : Adresse   = AdresseInit

  constructor(private apiAdela    : ApiAdelaService
            , public dialog       : MatDialog) {
    //Initialisation de la source de données de la table
    this.dataSource   = new MatTableDataSource();    
  }

  searchingEntite(){
    this.searching = true
  }

  setEntite(entite:Entite|null){
    this.searching = false
    if (entite) {
      this.entiteSelected = entite
      this.adresseEntite = {'ligne1':entite.adresse,'ligne2':entite.adresse_cplt,'ligne3':entite.adresse_ligne3, 'cp':entite.code_postal,'ville':entite.ville,'nom':entite.raison_sociale}
      this.getEtiquettes(entite.id!)
    }
  }

  ngOnInit(): void {
    this.searching = true
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: any) {
    // filterValue = filterValue.target.value;
    // filterValue = filterValue.trim(); // Remove whitespace
    // filterValue = filterValue.toLowerCase(); // Default to lowercase
    // this.dataSource.filter = filterValue;
    // this.dataSource.filterPredicate = (data: TableEtiquette, filter: string) => {      
    //   return (data.habilitation.trim().toLowerCase().startsWith(filter) == true
    //     || data.raison_sociale.trim().toLowerCase().startsWith(filter) == true);
    //  };
  }

  onImprimerEtiquette(id: number, id_entite: number) {
    // Recherche l'étiquette dans le tableau d'interface car le back-end attend un objet de type interface Etiquette
    let etiquette: Etiquette | undefined = <Etiquette>{}

    if(id > 0) { 
      etiquette = this.etiquettes.find(etiquette => etiquette.id === id);
    } else {
      etiquette = this.etiquettes.find(etiquette => etiquette.id_entite === id_entite);
    }
    
    
    this.waitingPrint = true;    
    
    if(etiquette != undefined) {
      if(etiquette.id_entite > 0){
        
        const dialogRef = this.dialog.open(DialogEtiquetteAdelaConfirmationComponent, { panelClass: 'custom-dialog-container' });
        dialogRef.componentInstance.etiquette = etiquette;
          dialogRef.afterClosed()
          .subscribe(
            (data: boolean) => { 
              if(data) {
                this.getEtiquettes(this.entiteSelected!.id!)
                this.waitingPrint = false;
              }
            },
            (err: any) => { 
              '/!\\ error dialogConfirmationImpression: ' + console.log(err);
              this.waitingPrint = false;
            }
          );
      }
    } else {
      this.waitingPrint = false;
    }
  }

  bindTableWithEtiquette(etiquettes : Array<Etiquette>) {     
    this.tableEtiquettes = [];

    for(let i = 0; i < etiquettes.length; i++) {
      let tableEtiquette : TableEtiquette = <TableEtiquette>{};
      tableEtiquette.id             = etiquettes[i].id;
      tableEtiquette.id_entite      = etiquettes[i].id_entite;
      tableEtiquette.numero         = etiquettes[i].numero;
      tableEtiquette.date!           = etiquettes[i].date;
      tableEtiquette.habilitation   = etiquettes[i].entite.habilitation;
      tableEtiquette.raison_sociale = etiquettes[i].entite.raison_sociale;
      tableEtiquette.adresse        = etiquettes[i].entite.adresse;
      tableEtiquette.ville          = etiquettes[i].entite.ville;
      tableEtiquette.code_postal    = etiquettes[i].entite.code_postal;
      tableEtiquette.actif          = etiquettes[i].entite.EstActif;
      this.tableEtiquettes.push(tableEtiquette);
    }
  }

  getEtiquettes(idEntite:number) { 
    this.apiAdela.getEtiquettesDossier(idEntite).subscribe(
      (etiquettes:Etiquette[])=>{
        this.etiquettes = etiquettes
        this.bindTableWithEtiquette(this.etiquettes);
        this.dataSource.data = this.etiquettes;
    })
  }
}
