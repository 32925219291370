<ng-container *ngIf="isMobileFormat(); then loginInLine else loginInColumn"></ng-container>

<ng-template #loginInLine>
    <mat-card appearance="outlined" *ngIf="btokenValide" style="margin-top: 100px;">
        <mat-card-content class=".mat-card-content">
            <form [formGroup]="updateForm">
                <div>
                    <h2>Définition de votre nouveau mot de passe</h2>
                    <mat-label>
                    </mat-label>
                </div>
        
                <mat-form-field>
                    <mat-label>Nouveau mot de passe</mat-label>
                    <input 
                        matInput 
                        type            = "password" 
                        formControlName = "new"
                        placeholder     = "Nouveau mot de passe"
                        [type]          = "hidePasswordNewPassword ? 'password' : 'text'">
                        <mat-icon 
                            matSuffix 
                            mat-icon-button 
                            style           = "cursor: pointer" 
                            color           = "primary" 
                            [matTooltip]    = "!hidePasswordNewPassword ? 'Masquer le mot de passe' : 'Afficher le mot de passe'" 
                            (click)         = "hidePasswordNewPassword = !hidePasswordNewPassword"
                            >{{!hidePasswordNewPassword ? 'visibility_off' : 'visibility'}}
                        </mat-icon>                    
                        <mat-error *ngIf="new?.invalid && (new?.dirty || new?.touched)">Format incorrect : mot de passe doit contenir au minimum 8 caractères dont une majuscule, une minuscule, un nombre et un caractère spécial</mat-error>
                    </mat-form-field>
                    
                    
                    <mat-form-field>
                        <mat-label>Confirmer mot de passe</mat-label>
                        <input 
                            matInput 
                            type            = "password"
                            formControlName = "confirmation"
                            placeholder     = "Confirmation du mot de passe" 
                            [type]          = "hidePasswordConfirmPassword ? 'password' : 'text'">
                        <mat-icon 
                            matSuffix 
                            mat-icon-button 
                            style           = "cursor: pointer" 
                            color           = "primary" 
                            [matTooltip]    = "!hidePasswordConfirmPassword ? 'Masquer le mot de passe' : 'Afficher le mot de passe'" 
                            (click)         = "hidePasswordConfirmPassword = !hidePasswordConfirmPassword"
                            >{{!hidePasswordConfirmPassword ? 'visibility_off' : 'visibility'}}
                        </mat-icon>
                        <mat-error *ngIf="confirmation?.invalid && (confirmation?.dirty || confirmation?.touched)">Format incorrect : mot de passe doit contenir au minimum 8 caractères dont une majuscule, une minuscule, un nombre et un caractère spécial</mat-error>
                    </mat-form-field>
                    
                    
                <mat-hint>8 caractères minimum dont une majuscule, une minuscule, un nombre, un caractère spécial</mat-hint>
            </form>
        </mat-card-content>
        <mat-card-actions style="display:flex; justify-content: center;">
                <button mat-raised-button color="primary" style="margin: 10px; width: 100%;"
                [disabled]="updateForm.pristine ||!(updateForm.valid && updateForm.dirty)"
                (click)="updatePassword()"
                >Mettre à jour</button>
        </mat-card-actions> 
    </mat-card>
</ng-template>

<ng-template #loginInColumn class="host">
    <mat-card appearance="outlined" *ngIf="btokenValide" style="margin-top: 100px;">
        <mat-card-content class=".mat-card-content">
            <form [formGroup]="updateForm" >
                <div>
                    <h2>Définition de votre nouveau mot de passe</h2>
                    <mat-label>
                    </mat-label>
                </div>
        
                <mat-form-field>
                    <mat-label>Nouveau mot de passe</mat-label>
                    <input 
                        matInput 
                        name            = "new" 
                        formControlName = "new"
                        placeholder     = "Nouveau mot de passe"
                        [type]          = "hidePasswordNewPassword ? 'password' : 'text'"
                        >
                        <mat-icon 
                                style="cursor: pointer" 
                                matSuffix 
                                mat-icon-button 
                                color="primary" 
                                [matTooltip]="!hidePasswordNewPassword ? 'Masquer le mot de passe' : 'Afficher le mot de passe'" 
                                (click)="hidePasswordNewPassword = !hidePasswordNewPassword">{{!hidePasswordNewPassword ? 'visibility_off' : 'visibility'}}</mat-icon>    
                        <mat-hint>8 caractères minimum dont une majuscule, une minuscule, un nombre, un caractère spécial</mat-hint>
                        <mat-error *ngIf="new?.invalid && (new?.dirty || new?.touched)">Format incorrect : mot de passe doit contenir au minimum 8 caractères dont une majuscule, une minuscule, un nombre et un caractère spécial</mat-error>
                </mat-form-field>
        
                <mat-form-field>
                    <mat-label>Confirmer mot de passe</mat-label>
                    <input 
                        matInput 
                        name            = "confirmation" 
                        formControlName = "confirmation"
                        placeholder     = "Confirmation du mot de passe" 
                        [type]          = "hidePasswordConfirmPassword ? 'password' : 'text'"
                        >
                        <mat-icon style="cursor: pointer" matSuffix mat-icon-button color="primary" [matTooltip]="!hidePasswordConfirmPassword ? 'Masquer le mot de passe' : 'Afficher le mot de passe'" (click)="hidePasswordConfirmPassword = !hidePasswordConfirmPassword">{{!hidePasswordConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                        <mat-hint>8 caractères minimum dont une majuscule, une minuscule, un nombre, un caractère spécial</mat-hint>
                        <mat-error *ngIf="confirmation?.invalid && (confirmation?.dirty || confirmation?.touched)">Format incorrect : mot de passe doit contenir au minimum 8 caractères dont une majuscule, une minuscule, un nombre et un caractère spécial</mat-error>
                </mat-form-field>
            </form>
        </mat-card-content>
        <mat-card-actions style="display:flex; justify-content: center;">
            <button mat-raised-button color="primary" style="margin: 10px; width: 100%;"
                    [disabled]="updateForm.pristine ||!(updateForm.valid && updateForm.dirty)"
                    (click)="updatePassword()"
                    >Mettre à jour</button>
        </mat-card-actions>
    </mat-card>
</ng-template>