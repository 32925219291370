<div class="content" style="height: 100px;width:300px">
    <div class="titlebox">{{entite.raison_sociale}}</div>
   
    <div class="div-container-left">
        <div class="label">SIRET :</div>
        <div class="valeur">{{entite.siret}}</div>
    </div>
   
    <div class="div-container-left">
        <div class="label">SIREN :</div>
        <div class="valeur">{{entite.siren}}</div>
    </div>

    <div class="div-container-left">
        <div class="label">TVA INTRA. :</div>
        <div class="valeur">{{tvaIntracommunautaire}}</div>
    </div>
   
</div>
