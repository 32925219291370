<mat-card appearance="outlined">
    <mat-card-title>Sélectionnez votre filtre</mat-card-title>
    <mat-card-content>
        <form [formGroup]="filterForm" >
            <div class="div-container-left">
                <mat-form-field>
                    <mat-label>Statut</mat-label>
                    <mat-select formControlName="status">
                        <mat-option value="">Tous</mat-option>
                        <mat-option value="-1">Erreur</mat-option>
                        <mat-option value="0">Pas envoyé</mat-option>
                        <mat-option value="1">Envoyé</mat-option>
                    </mat-select>
                </mat-form-field>
            
                <mat-form-field>
                    <mat-label>Type d'envoi</mat-label>
                    <mat-select formControlName="typeEnvoi">
                        <mat-option value="">Tous</mat-option>
                        <mat-option value="1">Immédiat</mat-option>
                        <mat-option value="2">Différé</mat-option>
                    </mat-select>
                </mat-form-field>
            
                <mat-form-field class="formDate">
                    <mat-label>Date de début</mat-label>
                    <input matInput   type ="date" formControlName="dateStart">
                </mat-form-field>
            
                <mat-form-field class="formDate">
                    <mat-label>Date de fin</mat-label>
                    <input matInput   type ="date" formControlName="dateEnd">
                </mat-form-field>
            </div>
            <div class="div-container-left">
                <mat-form-field>
                    <mat-label>Destinataire contient</mat-label>
                    <input matInput formControlName="destinataire" placeholder="Rechercher en contient par e-mail">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Sujet contient</mat-label>
                    <input matInput formControlName="sujet" placeholder="Rechercher dans le sujet">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Contenu contient</mat-label>
                    <input matInput formControlName="contenu" placeholder="Rechercher dans le contenu">
                </mat-form-field>
            </div>
        </form>
    </mat-card-content>
    <mat-card-actions align="end">
        <button mat-raised-button color="primary" (click)="applyFilter()"><mat-icon>search</mat-icon>Rechercher</button>
    </mat-card-actions>
</mat-card>