import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class PersoSnackbarService {

  readonly typeError: string  = 'Erreur';
  readonly typeAlert: string  = 'Alert';
  readonly typeInfo:  string  = "Info" ;

  constructor(private snackbar: MatSnackBar) {  }

  public getAuthUserId() {
    return this.snackbar.open("","");
  }

  public showError(message: string):void{
    this.snackbar.open(message,"Fermer", { duration: 5000, panelClass: ['red-snackbar', 'login-snackbar'] }) 
  }

  public showAlert(message: string, action?: string):void{
    this.snackbar.open(message, "Fermer", { duration: 2500 })
  }

  public showInfo(message: string, action?: string):void{
    this.snackbar.open(message, "Ok", { duration: 3500, panelClass: ['blue-snackbar', 'login-snackbar'] })
  }

  public validate(message: string, action?: string):void{
    this.snackbar.open(message, "Ok", { duration: 2500, panelClass: ['green-snackbar', 'login-snackbar'] })
  }
}
