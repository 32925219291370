<mat-card appearance="outlined">
    <mat-tab-group dynamicHeight>
        <mat-tab label="Commandes à facturer">
            <app-facturation-filtre  (filterSelected) = "updateFilterCommandes($event)"></app-facturation-filtre>
            <app-facturation-liste [commandes]       = "commandesAffichees"></app-facturation-liste>
        </mat-tab>
        <mat-tab label="dossiers ADELA à facturer">
            <app-facturation-filtre  (filterSelected) = "updateFilterDossiers($event)"></app-facturation-filtre>
            <app-facturation-liste-adela [dossiers]       = "dossiersAffiches"></app-facturation-liste-adela>
        </mat-tab>
    </mat-tab-group>
</mat-card>