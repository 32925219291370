<app-filter-prestation [modeEdit]             = "modeEdit"
                        [fluides$]          = "fluides$" 
                        [organes$]          = "organes$"
                        (filterSelected)    = "updateFilter($event)"
                        (createPrestation)="createPrestation()"
                    ></app-filter-prestation>

<app-edit-prestation *ngIf="modeEdit===true"
                        [prestation]        = "prestationEdited"
                        [fluides$]          = "fluides$" 
                        [organes$]          = "organes$"
                        [typeAnalyses$]     = "typeAnalyses$"
                        [typeElements$]     = "typeElements$"
                        (prestationSaved)   = "savePrestation($event)"
                    ></app-edit-prestation>

<app-list-prestation    
                        [modeEdit]              = "modeEdit"
                        [prestationsDisplayed$] = "prestationsDisplayed$"
                        (prestationSelected)    = "editPrestation($event)"
                    ></app-list-prestation>
