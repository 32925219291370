<!-- <p>table-adc works!</p> -->
<mat-card appearance="outlined" class="mat-card" style="background-color: #fff;">
            <mat-card-subtitle>Mes Dossiers</mat-card-subtitle>

            <div class="container">
                <table mat-table matTableExporter [dataSource]="dataSourceMatTable" #exporter="matTableExporter" [hiddenColumns]="[5]" class="tableDossier table-primary">

                    <!-- Service Column  -->
                    <!-- <ng-container matColumnDef="service">
                        <th mat-header-cell *matHeaderCellDef> Service </th>
                        <td mat-cell *matCellDef="let dossier">
                            <mat-icon>{{dossier.libelleService}}</mat-icon>
                        </td>
                    </ng-container> -->

                    <!-- Immatriculation Column  -->
                    <ng-container matColumnDef="immatriculation">
                        <th mat-header-cell *matHeaderCellDef class="premiere-colonne" style="width: 128px;"> Immatriculation </th>
                        <td mat-cell *matCellDef="let dossier" class="colonne-immat"> {{dossier.immatriculation}} </td>
                    </ng-container>

                    <!-- Reference Column  -->
                    <ng-container matColumnDef="reference">
                        <th mat-header-cell *matHeaderCellDef> Référence </th>
                        <td mat-cell *matCellDef="let dossier">
                            <button mat-flat-button (click)="downloadDossier(dossier.id, dossier.etat.code)"
                            style="text-decoration: underline;">
                                {{dossier.referenceDossierExpert}}
                            </button>
                        </td>
                    </ng-container>
                    <!-- 
                        <button mat-flat-button class="label-primary" style="font-size: 1em!important;">
                        </button>
                        (click)="downloadDossier(dossier.id, dossier.referenceDossierExpert)" 
                    -->

                    <!-- Cabinet Column  -->
                    <ng-container matColumnDef="cabinet">
                        <th mat-header-cell *matHeaderCellDef> Cabinet </th>
                        <td mat-cell *matCellDef="let dossier"> {{dossier.entite.raison_sociale}} </td>
                    </ng-container>

                    <!-- Expert Column  -->
                    <ng-container *ngIf="this.isAdminADC" matColumnDef="expert">
                        <th mat-header-cell *matHeaderCellDef> Expert </th>
                        <td mat-cell *matCellDef="let dossier"> {{dossier.utilisateur.nom}}
                            {{dossier.utilisateur.prenom}}</td>
                    </ng-container>

                    <!-- Date Column  -->
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef style="width: 92px;"> Date </th>
                        <td mat-cell *matCellDef="let dossier"> {{dossier.dateDepot | date: 'dd/MM/yyyy'}}
                        </td>
                    </ng-container>

                    <!-- Etat Column 
                    color="{{ dossier.etat.code === 'apiAdc.CONST_STATUS_ADC_6_CLOTURE' ? 'basic' : 'primary' }}"
                     -->
                    <ng-container matColumnDef="etat">
                        <th mat-header-cell *matHeaderCellDef style="width: 242px;"> Etat </th>
                        <td mat-cell *matCellDef="let dossier">
                            <button mat-stroked-button class="button-etat"
                                title="{{dossier.etat.description }}"
                                [ngStyle]="{ color : getColorByStatus(dossier.etat.code)}"
                                (click)="dialogHistoriqueDossier(dossier.id)">{{dossier.etatLibelle}}</button>
                        </td>
                    </ng-container>

                    <!-- Resultat Column  -->
                    <ng-container *ngIf="isAdminADC" matColumnDef="open">
                        <th mat-header-cell *matHeaderCellDef  style="width: 60px;"> Rapport </th>
                        <td mat-cell *matCellDef="let dossier">
                            <button mat-flat-button class="label-primary"
                                (click)="openFile(dossier.etat.code, dossier.id)" target="_blank"
                                title="{{ dossier.etat.libelle }}">
                                <mat-icon class="icon-display-32" color="accent" svgIcon="{{ getIcone(dossier.etat.code) }}">
                                    <span class="cdk-visually-hidden">{{ getIconeName(dossier.etat.code) }}</span>
                                </mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <!-- Resultat Column  -->
                    <ng-container *ngIf="!isAdminADC" matColumnDef="download">
                        <th mat-header-cell *matHeaderCellDef style="width: 60px;"> Rapport </th>
                        <td mat-cell *matCellDef="let dossier">
                            <button mat-flat-button class="label-primary" style="width: 48px; height: 48px;"
                                (click)="downloadDossier(dossier.id, dossier.etat.code)">
                                <mat-icon class="icon-display-32" color="accent">
                                    file_present
                                </mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <div class="div-container-between" style="align-items: center;background-color:white">
                    <app-export-table [fileExportName]="'EXTRANET - Mes dossiers ADC'" [exporter]="exporter" [nb_ligne]="this.dataSourceMatTable.data.length"></app-export-table>
                    <mat-paginator [pageSizeOptions]="[10,25,50]"
                        showFirstLastButtons 
                        aria-label="Nombre de lignes visibles...">
                     </mat-paginator>
                </div>
                <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                    showFirstLastButtons 
                    aria-label="Select page of periodic elements"
                    #paginator>
                </mat-paginator> -->
            </div>
        </mat-card>
    