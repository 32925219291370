export interface DossierConcentrateur {
    control     : Control
    vehicule    : Vehicule
    expertise   : Expertise
}

interface Control {
    logiciel        : string
    code_partenaire : string
    code_expert     : string
}

const ControlInit:Control={
    logiciel        : 'RDEA Certif',
    code_partenaire : '',
    code_expert     : ''
}

interface ConstatationTechnique {
    date_immatriculation            : Date | null
    demande_fo                      : string
    type_mines                      : string
    code_marque                     : string
    modele                          : string
    code_genre                      : string
    code_carrosserie                : string
    puissance_reelle?               : number
    puissance_fiscale?              : number
    code_energie                    : string
    code_couleur                    : string
    kilometrage?                    : number
    usure_pneumatique_AVD           : number
    usure_pneumatique_AVG           : number
    usure_pneumatique_ARD_AVANT     : number
    usure_pneumatique_ARD_ARRIERE   : number
    usure_pneumatique_ARG_AVANT     : number
    usure_pneumatique_ARG_ARRIERE   : number
    reparabilite_economique         : string
    reparabilite_technique          : string
}

const ConstatationTechniqueInit:ConstatationTechnique = {
    date_immatriculation            : null,
    demande_fo                      : '',
    type_mines                      : '',
    code_marque                     : '',
    modele                          : '',
    code_genre                      : '',
    code_carrosserie                : '',
    code_energie                    : '',
    code_couleur                    : '',
    usure_pneumatique_AVD           : 0,
    usure_pneumatique_AVG           : 0,
    usure_pneumatique_ARD_AVANT     : 0,
    usure_pneumatique_ARD_ARRIERE   : 0,
    usure_pneumatique_ARG_AVANT     : 0,
    usure_pneumatique_ARG_ARRIERE   : 0,
    reparabilite_economique         : '',
    reparabilite_technique          : '',
}

interface Vehicule {
    certificat_present      : string
    numero_immatriculation  : string
    numero_vin              : string
    numero_formule          : string
    date_certificat         : Date | null
    numero_dossier          : string
    constatation_technique  : ConstatationTechnique
}

const VehiculeInit: Vehicule = {
    certificat_present      : '',
    numero_immatriculation  : '',
    numero_vin              : '',
    numero_formule          : '',
    date_certificat         : null,
    numero_dossier          : '',
    constatation_technique  : ConstatationTechniqueInit,
}

interface Sinistre {
    code_nature_evenement   : string
    code_nature_adverse     : string
    date_evenement          : Date | null
}

const SinistreInit:Sinistre = {
    code_nature_evenement   : '',
    code_nature_adverse     : '',
    date_evenement          : null,
}

interface ReparationGlobale {
    nb_points_choc          : number
    totalHT                 : number
    sous_total_HT_pieces    : number
}

const ReparationGlobaleInit:ReparationGlobale = {
    nb_points_choc          : 0,
    totalHT                 : 0,
    sous_total_HT_pieces    : 0,
}

interface ValeurVehicule {
    valeur_totale_HT_vehicule   : number
    valeur_totale_TTC_vehicule  : number
}

const ValeurVehiculeInit:ValeurVehicule = {
    valeur_totale_HT_vehicule   : 0,
    valeur_totale_TTC_vehicule  : 0,
}

interface Expertise {
    type_operation      : string
    date_examen_1A?     : Date | null
    date_rapport_1A?    : Date | null
    dangereux_1A?       : string
    date_rapport_1B?    : Date | null
    date_examen_1B?     : Date | null
    dangereux_1B?       : string
    date_rapport_1C?    : Date | null
    date_examen_1C?     : Date | null
    peut_circuler       : string
    deficience          : Deficience[]
    reparable_1A?       : string
    reparable_1B?       : string
    sinistre            : Sinistre
    reparation_globale?  : ReparationGlobale
    points_de_choc      : ZoneChoc[]
    valeur_vehicule?     : ValeurVehicule
}

const ExpertiseInit:Expertise = {
    type_operation      : '',
    peut_circuler       : '',
    deficience          : [],
    sinistre            : SinistreInit,
    reparation_globale  : ReparationGlobaleInit,
    points_de_choc      : [],
    valeur_vehicule     : ValeurVehiculeInit
}

export interface ZoneChoc{
    designation_choc      : string
    code_loc_point_choc   : string
    code_zone_deformation : string
  }
  
export interface Deficience{
    code_dangerosite      : string
    deficience_imputable  : string
    date_dangerosite?     : Date|null
  }

export const ZoneChocInit:ZoneChoc={
    designation_choc      : '',
    code_loc_point_choc   : '',
    code_zone_deformation : ''
}

export const DossierConcentrateurInit:DossierConcentrateur={
    control     : ControlInit,
    vehicule    : VehiculeInit,
    expertise   : ExpertiseInit,    
}


