import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

@Injectable({ providedIn: 'root' })
export class TelephoneValidator {
    verificationFormatTelephoneRequis(bPourFax: boolean = false):ValidatorFn {
      return (control: AbstractControl):ValidationErrors | null  => {
        
        if (!control.value || control.value.trim() === '') {
            return null; // Valeur valide si vide ou nulle
        }
    
        // Supprimer tous les espaces de la chaîne
        const valeurSansEspaces = control.value.replace(/\s/g, '');
    
        // Vérifier si la chaîne contient exactement 10 caractères composés uniquement de chiffres 0 à 9
        if (/^[0-9]{10}$/.test(valeurSansEspaces)) {
            return null; // Valeur valide si contient exactement 10 caractères composés uniquement de chiffres 0 à 9
        } else {
            // Valeur invalide si ne contient pas exactement 10 caractères composés uniquement de chiffres 0 à 9
            if (bPourFax) {
                return { dixchiffres: 'Le numéro de fax doit contenir exactement 5 numéros de 2 chiffres' };
            } else {
                return { dixchiffres: 'Le numéro de téléphone doit contenir exactement 5 numéros de 2 chiffres' }; 
            }
        }
    }

    }
}