
<app-filtre-modele  (newModuleArrayEvent)       = "getNewArrayModele($event)" 
                    (newIdSousCategorie)        = "getNewIdSousCategorie($event)"
                    (categorieEditEvent)        = "getCategorieEdit($event)"
                    (sousCategorieEditEvent)    = "getSousCategorieEdit($event)"
                    [sousCategoriesChange]      = "sousCategorieChange"    >
</app-filtre-modele>

<app-edit-categorie *ngIf="modeEditCategorie" [categorie]="categorie" (resultValidateEvent) = "getResultValideCategorie($event)"></app-edit-categorie>

<app-edit-sous-categorie *ngIf="modeEditSousCategorie" [sousCategorie]="sousCategorie" (resultValidateEvent) = "getResultValideSousCategorie($event)"></app-edit-sous-categorie>

<app-edit-modele *ngIf="modeEditModele"  [modele]="modele" (resultValidateEvent) = "getResultValideModele($event)"></app-edit-modele>

<app-table-modele *ngIf="!modeSelectModele" [modeles] ="modeles"  (modeleEditEvent) = "getModeleEdit($event)" ></app-table-modele>

<app-liste-modele *ngIf="modeSelectModele" [modeles] ="modeles" (modeleSelectedEvent) = "getModeleSelected($event)"></app-liste-modele>