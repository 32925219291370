import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ResultatActionEnMasse } from 'src/app/interfaces/resultat-action-en-masse';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { NgStyle, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTableResponsiveLargeDirective } from '../../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';
export interface DialogData {
  type: string;
  dataSource_Cabinets: any;
}
@Component({
    selector: 'app-mass-update-liste-cabinets',
    templateUrl: './mass-update-liste-cabinets.component.html',
    styleUrls: ['./mass-update-liste-cabinets.component.scss'],
    standalone: true,
    imports: [MatDialogModule, MatTableModule, MatTableResponsiveLargeDirective, MatIconModule, NgStyle, MatPaginatorModule, NgIf, MatButtonModule, MatProgressSpinnerModule]
})
export class MassUpdateListeCabinetsComponent implements OnInit {
  // Constant pour la gestion de l'affichage
  CONST_CODE_TYPE_ACTION_ACTIVATION     : string = 'activate'   as const;
  CONST_CODE_TYPE_ACTION_DESACTIVATION  : string = 'deactivate' as const;
  CONST_CODE_TYPE_ACTION_IDENTIFIANTS   : string = 'identifiant' as const;

  // gestion de l'affichage
  dataSourceMatTable  : MatTableDataSource<any> = new MatTableDataSource<any>();
  typeUpdatePage      : string = '';
  displayedColumns    : Array<string> = ['ent_habilitation', 'ent_numero_client', 'ent_raison_sociale' , 'ent_active', 'ent_code_postal', 'ent_ville', 'ent_siren', 'resultat'];
  waitingApi          : boolean = false;
  sTitre              : string = '';
  sSousTitre          : string = '';
  action_confirmee    : boolean = false;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;

  constructor(
      private apiEntite                     : ApiEntiteService
    , private toast                         : PersoSnackbarService
    , public dialogRef                      : MatDialogRef<MassUpdateListeCabinetsComponent>
    , @Inject(MAT_DIALOG_DATA) public data  : DialogData
  ) { }

  ngOnInit(): void {
    this.init();
  }

  ngOnChanges() {
    this.init();
  }

  ngAfterViewInit() {
    this.dataSourceMatTable.paginator = this.paginator;
    this.dataSourceMatTable.sort      = this.sort;
  }

  private init () {
    if(this.data.type != undefined) {
      this.typeUpdatePage = this.data.type;

      if(this.data.dataSource_Cabinets != undefined) {
        switch (this.data.type) {
          case this.CONST_CODE_TYPE_ACTION_ACTIVATION:
            this.dataSourceMatTable.data = this.supprimerCabinetsActif(this.data.dataSource_Cabinets);
            
            if (this.dataSourceMatTable.data.length == 1) {
              this.sTitre     = 'Activation d\'un cabinet';
              this.sSousTitre = 'Le cabinet suivant va être activé';
            } else {
              this.sTitre     = 'Activation de ' + this.dataSourceMatTable.data.length +' cabinets';
              this.sSousTitre = 'Les cabinets suivants vont être activés';
            }
            break;
          
          case this.CONST_CODE_TYPE_ACTION_DESACTIVATION :  
            this.dataSourceMatTable.data = this.supprimerCabinetsInactif(this.data.dataSource_Cabinets);

            if (this.dataSourceMatTable.data.length == 1) {
              this.sTitre     = 'Désactivation d\'un cabinet';
              this.sSousTitre = 'Le cabinet suivant va être désactivé';
            } else {
              this.sTitre     = 'Désactivation de ' + this.dataSourceMatTable.data.length +' cabinets';
              this.sSousTitre = 'Les cabinets suivants vont être désactivés';
            }
            break;

          case this.CONST_CODE_TYPE_ACTION_IDENTIFIANTS :
            this.dataSourceMatTable.data = this.supprimerCabinetsInactif(this.data.dataSource_Cabinets);

            if (this.dataSourceMatTable.data.length == 1) {
              this.sTitre     = 'Réinitialisation du mot de passe d\'un cabinet';
              this.sSousTitre = 'Pour le cabinet suivant, un mail de réinitialisation de mot de passe a été envoyé';
            } else {
              this.sTitre = 'Réinitialisation du mot de passe de ' + this.dataSourceMatTable.data.length + ' cabinets';
              this.sSousTitre = 'Pour les cabinets suivants, un mail de réinitialisation de mot de passe a été envoyé';
            }            
            break;

          default:
            this.dataSourceMatTable.data = this.data.dataSource_Cabinets;
            break;
        }
        
        if (this.dataSourceMatTable.data.length <= 0) {
          this.dialogRef.close();
        }
      } else {
          this.dialogRef.close();  
      }

    } else {
      this.dialogRef.close();
    }
  }

  public onClickRetour() : void {
    this.dialogRef.close();
  }

  public onClickActivation() :void {
    this.waitingApi = true
    this.apiEntite.postMassUpdateActivate(this.dataSourceMatTable.data)
    .subscribe(
      (resultat: ResultatActionEnMasse[]) => {
        for(let i = 0; i < resultat.length; i++) {
          this.dataSourceMatTable.data.find(Cabinet => {
            if(Cabinet.id == resultat[i].id ) {
              Cabinet.resultat = resultat[i].resultat;
            }
          });
        }
        this.toast.showInfo("Entité(s) activée(s)");
        this.waitingApi = false;
        this.action_confirmee = true;
      },
      (err: any) => {
        this.toast.showError(err.error);
        this.waitingApi = false;
      }
    );
  }

  public onClickDesactivation() :void {
    this.waitingApi = true
    this.apiEntite.postMassUpdateDeactivate(this.dataSourceMatTable.data)
    .subscribe(
      (resultat: ResultatActionEnMasse[]) => {
        for(let i = 0; i < resultat.length; i++) {
          this.dataSourceMatTable.data.find(Cabinet => {
            if(Cabinet.id == resultat[i].id ) {
              Cabinet.resultat = resultat[i].resultat;
            }
          });
        }
        this.toast.showInfo("Entité(s) désactivée(s)");
        this.waitingApi = false;
        this.action_confirmee = true;
      },
      (err: any) => {
        this.toast.showError(err.error);
        this.waitingApi = false;
      }
    ); 
  }

  public onClickResetPassword() : void {
    this.waitingApi = true
    this.apiEntite.postMassUpdateResetPasswordConcentrateur(this.dataSourceMatTable.data)
    .subscribe(
      (resultat: ResultatActionEnMasse[]) => {
        for(let i = 0; i < resultat.length; i++) {
          this.dataSourceMatTable.data.find(Cabinet => {
            if(Cabinet.id == resultat[i].id ) {
              Cabinet.resultat = resultat[i].resultat;
            }
          });
        }
        this.toast.showInfo("Réinitialisation des mot(s) de passe terminé(s)");
        this.waitingApi = false;
        this.action_confirmee = true;
      },
      (err: any) => {
        this.toast.showError(err.error);
        this.waitingApi = false;
      }
    ); 
  }
  
  private supprimerCabinetsInactif(data : Array<Entite>) : Array<Entite> {
    return data.filter(cabinet => cabinet.EstActif == true)
  }

  private supprimerCabinetsActif(data : Array<Entite>) : Array<Entite> {
    return data.filter(cabinet => cabinet.EstActif == false)
  }

}
