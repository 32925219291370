<mat-card appearance="outlined" class="mat-card-no-shadow" style="padding: 0px;">
    <form [formGroup]="FiltreFormGroup">
        <mat-expansion-panel id="expandFilter" [(expanded)]="expandPannel" #expandFilter>                                
            <mat-expansion-panel-header style="background-color: #fff">
                <mat-panel-title class="label-accent">
                    <mat-icon>search</mat-icon>Veuillez sélectionner un type et les éventuelles options de votre recherche...
                </mat-panel-title>
            </mat-expansion-panel-header>

            <!-- Types de demande -->
            <mat-form-field>
                <mat-label>Type</mat-label>
                <mat-select required name="type" id="type" formControlName="types" multiple>
                    <mat-option *ngFor="let type of typesInit" [value]="type" >
                        {{ type.libelle }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="!FiltreFormGroup?.valid && FiltreFormGroup.touched">Vous devez sélectionner au moins un type pour votre recherche</mat-error>
            </mat-form-field> 

            <!-- Etats de la demande -->
            <mat-form-field>
                <mat-label>Etat</mat-label>
                <mat-select required name="etat" id="etat" formControlName="etats" multiple>
                    <mat-option *ngFor="let etat of etatsInit" [value]="etat" >
                        {{ etat.libelle }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="!FiltreFormGroup?.valid && FiltreFormGroup.touched">Vous devez sélectionner au moins un état pour votre recherche</mat-error>
            </mat-form-field> 

            <!-- Texte libre -->
            <mat-form-field>
                <mat-label>Les résultats doivent contenir</mat-label>
                <input matInput type="text" placeholder="mots qui doivent figurer dans les titres ou description des tickets" name="texte" id="texte" formControlName="texte" oninput="this.value = this.value.toUpperCase()">
            </mat-form-field>

            <!-- Numéro -->
            <mat-form-field>
                <mat-label>Numéro</mat-label>
                <input matInput type="number" placeholder="Numéro du ticket" name="numero" id="numero" formControlName="numero" oninput="this.value = this.value.toUpperCase()">
                <mat-error *ngIf="numero?.invalid && (numero?.dirty || numero?.touched)"></mat-error>
            </mat-form-field> 

            <!-- Contact -->
            <mat-form-field>
                <mat-label>Contact</mat-label>
                <input matInput type="text"[maxLength]="6" placeholder="Contact" name="contact" id="contact" formControlName="contact" oninput="this.value = this.value.toUpperCase()">
                <mat-error *ngIf="contact?.invalid && (contact?.dirty || contact?.touched)"></mat-error>
            </mat-form-field> 

            <!-- Immatriculation -->
            <mat-form-field>
                <mat-label>Immatriculation</mat-label>
                <input matInput type="text" [maxLength]="10" placeholder="Immatriculation" name="immatriculation" id="immatriculation" formControlName="immatriculation" oninput="this.value = this.value.toUpperCase()">
                <mat-error *ngIf="immatriculation?.invalid && (immatriculation?.dirty || immatriculation?.touched)"></mat-error>
            </mat-form-field>
            
            <!-- Habilitation -->
            <mat-form-field>
                <mat-label>Habilitation</mat-label>
                <input matInput type="text" placeholder="Habilitation" name="habilitation" id="habilitation" formControlName="habilitation" oninput="this.value = this.value.toUpperCase()">
                <mat-error *ngIf="habilitation?.invalid && (habilitation?.dirty || habilitation?.touched)"></mat-error>
            </mat-form-field>

            <mat-action-row>
                <div class="div-container-between" style="padding: 8px;">
                    <div class="div-container-center" style="width:100%; display: flex; justify-content: center;">
                        <button mat-flat-button 
                                *ngIf       = "!waitingResearch"
                                submit 
                                color       = "primary" 
                                [disabled]  = "waitingResearch || !FiltreFormGroup.valid" 
                                (click)     = "onSearchingTickets()">
                            <mat-icon>search</mat-icon>Rechercher                                    
                        </button>
                        <mat-spinner diameter="24" *ngIf = "waitingResearch"></mat-spinner>
                    </div>
                </div>
            </mat-action-row>
        </mat-expansion-panel>
    </form>
</mat-card>