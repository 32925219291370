import { PIECEJOINTE } from "src/app/interfaces/piece-jointe"
import { Entite } from "src/app/interfaces/profil-user/entite"

export const CST_STA_SUS_ENCOURS      = 'SUS_ENCOURS'
export const CST_STA_SUS_OUVRIR      = 'SUS_OUVRIR'
export const CST_STA_SUS_FERMER       = 'SUS_FERMER'
export const CST_STA_SUS_DEFINITIVE   = 'SUS_DEFINITIVE'
export const CST_STA_SUS_RAPPEL1      = 'SUS_RAPPEL1'
export const CST_STA_SUS_RAPPEL2      = 'SUS_RAPPEL2'
export const CST_STA_SUS_RAPPEL3      = 'SUS_RAPPEL3'


export const statuts = [
  {
    nom:"Suspensions ouvertes",
    code : CST_STA_SUS_OUVRIR
  }, 
  {
      nom:"Suspensions en cours",
      code : CST_STA_SUS_ENCOURS
    },
    {
      nom:"Suspensions fermées",
      code : CST_STA_SUS_FERMER
    },
    {
      nom:"Suspensions définitives",
      code : CST_STA_SUS_DEFINITIVE
    },
  ]

export interface TypeSuspension{
    id      : number,
    code    : string,
    nom     : string,
    action? : string
}

export const TypeSuspensionInitial:TypeSuspension={
    id:0,
    code:'',
    nom:'',
    action:''
}

export interface ActionSuspension{
    id                  : number,
    code                : string,
    nom                 : string,
    Description         : string,
    idUtilisateur       : number,
    mailDestinataire    : string,
    date           : string,
    nom_utilisateur     : string
}

export interface Suspension{
    id          : number,
    idEntite    : number,
    idType?     : number,
    entite?     : Entite,
    note?       : string,
    type?       : TypeSuspension,
    actions?    : Array<ActionSuspension>
    statut?     : ActionSuspension,
    date?       : string,
    pjs         : Array<PIECEJOINTE>,
    action?     : string
}

export interface SuspensionRappel{
  id            : number,
  index         :number
}
export interface SuspensionFiltre{
    habilitation?    : string,
    contact?         : string,
    raison_sociale?  : string,
    siren?           : string,
    statut?          : string
}

export const SuspensionInitiale : Suspension={
  id        : 0,
  idType    : 0,
  idEntite  : 0,
  note      : '',
  actions   : [],
  pjs       : []
}