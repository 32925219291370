import { Component, ViewChild, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { AsyncPipe, DatePipe, NgFor, NgIf } from '@angular/common';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { EntiteListComponent } from 'src/app/components/templates/entite-list/entite-list.component';
import { Cabinet, Mission, Redirection, RedirectionDossiers } from '../../interface/wsa';
import { ApiWsaService } from '../../data_acces/api-wsa.service';
import { MatRippleModule } from '@angular/material/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { SelectionModel } from "@angular/cdk/collections";
import { MatListModule } from '@angular/material/list';

@Component({
  selector: 'app-mission',
  templateUrl: './mission.component.html',
  styleUrls: ['./mission.component.scss'],
  standalone  : true,
  imports     : [MatCardModule,MatFormFieldModule,EntiteListComponent,MatInputModule,MatButtonModule,NgFor,NgIf,AsyncPipe
                  , MatTableModule,MatTooltipModule,MatIconModule,MatProgressSpinnerModule,MatPaginatorModule,MatRippleModule
                  , FormsModule,ReactiveFormsModule,DatePipe,MatCheckboxModule,MatListModule
                ]
})
export class MissionComponent {
  filtreCabinet = new FormGroup({
      habilitation : new FormControl('')
    , raisonSociale : new FormControl('')
  })
  habilitation$     = new BehaviorSubject<string>('')
  apiWsa            = inject(ApiWsaService)
  apiToast          = inject(PersoSnackbarService)
  cabinets$         = this.habilitation$.pipe(mergeMap(
    (habilitation:string)=> {
      const redirection : Redirection = { 'source'        : habilitation
                                        , 'cible'         : this.filtreCabinet.get('habilitation')!.value
                                        , 'raisonSociale' : this.filtreCabinet.get('raisonSociale')!.value}
      if (!habilitation) {
        return of([])
      }else{
        return this.apiWsa.getCabinets(redirection)
      }
    }
  ))
  
  @ViewChild('paginatorCabinets', { static: true }) public paginatorCabinets!: MatPaginator;
  @ViewChild('paginatorMissions', { static: true }) public paginatorMissions!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort | null = null;
  
  dataSourceCabinets          : MatTableDataSource<Cabinet> = new MatTableDataSource<Cabinet>();
  dataSourceMissions          : MatTableDataSource<Mission> = new MatTableDataSource<Mission>();
  selectionCabinet            = new SelectionModel<Cabinet>(false, []);
  selectionMission            = new SelectionModel<Mission>(true, []);
  entite!                     : Entite
  displayedColumnsCabinets    : Array<string> = ['select','habilitation', 'raison_sociale'];
  displayedColumnsMissions    : Array<string> = ['select','numeroSinistre', 'dateCreation', 'assure', 'datePassage'];
  
  waiting                      : boolean = false
  waitingCabinets              : boolean = false
  waitingMissions              : boolean = false

  private unsubscribe$: Subject<void> = new Subject(); //Pour éviter les fuites de mémoire

  filtreMissionForm:FormGroup = new FormGroup({
    numeroSinistre  : new FormControl<string>('')
    ,codeExpert     : new FormControl<string>('')
  })

  ngOnInit(){
    this.dataSourceCabinets.paginator = this.paginatorCabinets
    this.cabinets$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (cabinets:Cabinet[])=>{
        this.waitingCabinets = false
        this.dataSourceCabinets.data      = cabinets
        this.selectionCabinet.clear()
      }
      ,(error)=> this.waitingCabinets = false
    )
    
  }

  rechercheCabinets(){
    this.waitingCabinets = true
    this.habilitation$.next(this.entite.habilitation)
  }

  selectCabinet(row: Cabinet) {
    if (!this.selectionCabinet.isSelected(row)) {
      this.selectionCabinet.clear();
    }
    this.selectionCabinet.toggle(row);
  }

  selectMission(row:Mission){
    this.selectionMission.toggle(row);
  }


  onSetEntite(entite:Entite){
    this.entite= entite
  }

  getMissions(){
    this.waitingMissions = true
    this.filtreMissionForm.get('codeExpert')?.setValue(this.entite.habilitation)
    
    this.apiWsa.getMissions(this.filtreMissionForm.value).subscribe(
      (missions:Mission[])=>{
        this.waitingMissions = false
        this.dataSourceMissions.data      = missions
        this.dataSourceMissions.paginator = this.paginatorMissions
        this.selectionMission.clear()
      }
      ,(error)=>this.waitingMissions = false
    )
  }

  rediriger(){
    
    const redirection:RedirectionDossiers = {
      'ancienCode'    : this.entite.habilitation
      ,'nouveauCode'  : this.selectionCabinet.selected[0].habilitation
      ,'dossiers'     : this.selectionMission.selected.map((mission:Mission) => mission.idDossier).toString()
    }
    
    this.waiting = true
    
    this.apiWsa.redirigerMissions(redirection).subscribe(
      ()=>{
        this.apiToast.showInfo('La redirection a bien été effectuée')

        this.dataSourceMissions.data = this.dataSourceMissions.data.filter(
          (mission:Mission) => !this.selectionMission.selected.find(
            (missionSelected:Mission) => missionSelected.idDossier===mission.idDossier
          )
        )
        this.selectionMission.clear()
        this.selectionCabinet.clear()
        this.waiting = false
      }
    ,(error)=>{
        this.waiting=false
        this.apiToast.showError(error.error)
      }
    )
    
  }

}
