<mat-card appearance="outlined" style="width: 100%;">
    <mat-toolbar *ngIf="profilUtilisateur$.droits_utilisateur.droit_module_admin_compta" class="navbar">
        <div class="div-container-between" >
            <div style="width: 180px;">
                <a mat-button  *ngIf="true" class="label-accent"><mat-icon >account_balance</mat-icon>Comptabilité</a>
            </div>
            <div class="div-container-left" style="width: 80%;">
    
            </div>
            <div class="label-accent" style="padding-right: 8px; font-size: 14px;">
                {{ dateDuJour | date: 'EEEE d MMMM, y' }}
            </div>
            <div style="width: 100%; text-align: right;">
                <button mat-button color="primary" [matMenuTriggerFor]="menu"><mat-icon>menu</mat-icon></button>
                <mat-menu #menu="matMenu" style="padding: 2px;">
                    <!-- saisir un paiement     //  [disabled]="estAnnulee" : il faut laisser la possibilité de saisir un paiement quelle que soit le statut de la facture -->
                    <button mat-menu-item (click)="addPaiement()" [disabled]="!canIAddPaiement">  
                    <mat-icon>account_balance_wallet</mat-icon>{{ facture$.type_document === 'FACTURE' ? 'Paiement' : 'Remboursement' }}</button>
                    <!-- saisir un avoir -->
                    <button mat-menu-item (click)="addCreditNote()" [disabled]="!canIDoaCreditNote">
                    <mat-icon color="accent">block</mat-icon> Faire un avoir</button>
                    <!-- recréer la facture -->
                    <button mat-menu-item (click)="copyInvoice()"                     
                    [disabled]="!(estAnnulee && this.facture$.type_document == 'FACTURE')">
                    <mat-icon>file_copy</mat-icon> Recréer la facture</button>
                </mat-menu>
        
            </div>   
        </div>
        
    </mat-toolbar>
    
    <mat-card-content>
        <!-- recapitulatif d'adresse de facturations -->
        <div class="div-container-left" style="align-items: flex-start;">
            <img src="../../../../assets/img/charte/FFEA 2 lignes bloc aplat coul.jpg" style="width: 120px;">
            
            <div style="width: 80%; vertical-align: top;">
                <ul style="list-style-type: none; padding: 0px;">
                    <li class="lable-alert">{{ facture$.type_document }} N° :</li>
                    <li class="titre label-primary"><div style="display:inline-block; width: 200px; margin-right: 16px;">{{ facture$.numero }}</div> <button mat-stroked-button [ngStyle]="{ 'color': getBackgroundColorByStatus(etat$.code) }">{{ etat$.libelle }}</button></li>
                </ul>
                <!-- Document/Piece précédent le document en cours de visualisation  -->
                <ul *ngIf="facturePrecedente$?.id" style="list-style-type: none; padding: 0px;">
                    <li class="lable-alert">depuis {{ facturePrecedente$.type_document }} N° :</li>
                    <li class="titre label-primary">
                        <button mat-button color="primary" style="width: 200px; margin-right: 16px;" 
                        (click)="loadFacture(facturePrecedente$.id)"><mat-icon>skip_previous</mat-icon> {{ facturePrecedente$.numero }}</button> <span mat-button [ngStyle]="{ 'color': getBackgroundColorByStatus(etatOrigine$.code) }" style="font-size: 14px;">{{ etatOrigine$.libelle }}</span></li>
                </ul>
                    
                    <!-- Document/Piece suivant le document en cours de visualisation -->
                <div *ngFor="let fac of facturesSuivantes$">
                    <ul  style="list-style-type: none; padding: 0px;">
                        <li class="lable-alert">transformé en {{ fac.type_document }} N° :</li>
                        <li class="titre label-primary">
                            <button mat-button color="primary" style="width: 200px; margin-right: 16px;" 
                            (click)="loadFacture(fac.id)"><mat-icon>skip_next</mat-icon> {{ fac.numero }}</button> <span mat-stroked-button [ngStyle]="{ 'color': getBackgroundColorByStatus(fac.Etat.code) }" style="font-size: 14px;">{{ fac.Etat.libelle }}</span></li>
                    </ul>
                </div>
                
            </div>
            
        </div>

        <div class="div-container-between" style="margin-bottom: 20px;">
            <app-carte-entite [entite]="entite$" [tvaIntracommunautaire]="facture$.tvaIntracommunautaire"></app-carte-entite>
            <app-carte-adresse [adresse]="adresseEntite" *ngIf="adresseEntite"></app-carte-adresse>
        </div>
        <div class="div-container-right" style="margin-bottom: 20px;" *ngIf="entite$.telephone!=''">
            <li style="padding-top: 8px;">Téléphone : {{ entite$.telephone |phoneFormat}}</li>
        </div>
        <!-- tableau recapitulatif de commande -->
        <div class="container">
            <table mat-table matTableResponsiveLarge [dataSource]="facture$.LignesFacture" class="tableArticle">

                <!-- Réf. Article Column -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef class="premiere-colonne">ID </th>
                    <td mat-cell *matCellDef="let ligneFacture"> {{ ligneFacture.idArticle }} </td>
                </ng-container>

                <!-- Article Column -->
                <ng-container matColumnDef="article">
                    <th mat-header-cell *matHeaderCellDef width="400px"> Article </th>
                    <td mat-cell *matCellDef="let ligneFacture"> {{ ligneFacture.libelle }} </td>
                </ng-container>

                <!-- Quantité Column -->
                <ng-container matColumnDef="quantite">
                    <th mat-header-cell *matHeaderCellDef> Quantité </th>
                    <td mat-cell *matCellDef="let ligneFacture"> {{ ligneFacture.quantite }} &nbsp;&nbsp;x</td>
                </ng-container>

                <!-- PUHT Column -->
                <ng-container matColumnDef="prix_unitaire_ht">
                    <th mat-header-cell *matHeaderCellDef> Prix untaire HT </th>
                    <td mat-cell *matCellDef="let ligneFacture">{{ ligneFacture.puht | number:'1.2-2':'fr' }}&nbsp;€ </td>
                </ng-container>

                <!-- Total HT Column -->
                <ng-container matColumnDef="total_ht">
                    <th mat-header-cell *matHeaderCellDef> Total HT </th>
                    <td mat-cell *matCellDef="let ligneFacture"> {{ ligneFacture.montantHT | number:'1.2-2':'fr' }}&nbsp;€ </td>
                </ng-container>

                <!-- / mois -->
                <ng-container matColumnDef="par_Mois">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let ligneFacture"> {{ isLocation ? '/ mois' : '' }}</td>
                </ng-container>

                <!-- % Remise Column -->
                <ng-container matColumnDef="pourcentage_remise">
                    <th mat-header-cell *matHeaderCellDef> % Remise </th>
                    <td mat-cell *matCellDef="let ligneFacture"> {{ligneFacture.pourcentage_remise}}&nbsp;% </td>
                </ng-container>

                <!-- Total Remise HT Column -->
                <ng-container matColumnDef="total_remise_ht">
                    <th mat-header-cell *matHeaderCellDef> Total remise HT </th>
                    <td mat-cell *matCellDef="let ligneFacture"> {{ligneFacture.total_remise_ht}}&nbsp;€ </td>
                </ng-container>

                <!-- TVA Column -->
                <ng-container matColumnDef="tauxTVA">
                    <th mat-header-cell *matHeaderCellDef>TVA</th>
                    <td mat-cell *matCellDef="let ligneFacture"> {{ ligneFacture.tauxTVA }} %
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>        
        <form [formGroup]="formModeSelected">
            <mat-form-field style="max-width: 350px;" >
                <mat-label>Sélection du mode de paiement</mat-label>
                <mat-select 
                    formControlName="idSelectedMode"
                    [disabled]="!profilUtilisateur$.droits_utilisateur.est_admin"
                    
                >
                    <mat-option value="0">Aucun</mat-option>
                    <mat-option 
                        *ngFor  = "let modePaiement of modesPaiement" 
                        [value]   = "modePaiement.id"
                        >{{modePaiement.libelle}}</mat-option>
                </mat-select>
            </mat-form-field>
            <button 
                mat-raised-button 
                color       = "primary" 
                (click)     = "enregistrerModePaiementDefaut()"
                [disabled]  = "!profilUtilisateur$.droits_utilisateur.est_admin"
                >Enregistrer
            </button>
        </form>
            <!-- reglement et lettrage // totaux -->
            <div class="div-container-between" style="margin-top: 20px;">
                
                    <mat-accordion style="width: 70%;">
                        
                        <mat-expansion-panel [(expanded)]="avecReglement">
                            <mat-expansion-panel-header>
                            
                                <mat-panel-title>
                                    <mat-icon>account_balance_wallet</mat-icon> <span class="spanHeader">Règlement :</span> 
                                </mat-panel-title>

                                <mat-panel-description>
                                    <span [ngStyle]="{ 'color': getBackgroundColorByStatus(etat$.code) }" style="padding: 0 4px 0 4px;">{{ etat$.libelle }}</span>
                                    <div class="div-container-left" style="flex-wrap: wrap; gap: 8px;">
                                        <span class="spanHeader">- {{ etat$.description }}</span>
                                        <span *ngIf="etat$.code == apiFacture.CONST_ETAT_FACTURE_2_PARTIELLEMENT_REGLE && facture$.type_document == apiFacture.CONST_TYPE_FACTURE" class="spanHeader">- Reste dû {{ sommeDue }} €</span>
                                    </div>
                                </mat-panel-description>
                            
                            </mat-expansion-panel-header>
                            
                            <div *ngFor="let reglement of reglement$">
                                <div class="div-container-between">
                                    <div>{{ reglement.dateReglement | date : 'dd/MM/yyyy' }} : </div>
                                    <ng-container *ngIf="(reglement.libelle === '' || reglement.montant === 0); then libCommentaire else libReglement"></ng-container>
                                    <ng-template #libReglement>
                                        <div class="label-primary">{{ reglement.ModePaiement.libelle }} N° {{ reglement.libelle }}</div>
                                        <div class="label-accent">{{ reglement.montant  | number:'1.2-2':'fr' }} €</div>
                                    </ng-template>
                                    <ng-template #libCommentaire></ng-template>
                                </div>
                                <div class="div-container-between label-grey" style="color: #646464; font-size: 11x;">{{ reglement.commentaire }}</div>
                            </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                            
                                <mat-panel-title>
                                    <mat-icon>account_balance</mat-icon><span class="spanHeader">Remise en banque :</span> 
                                </mat-panel-title>
                            
                                <mat-panel-description>
                                    <span *ngIf="nbRemiseEnBanque > 0">{{ nbRemiseEnBanque }} / {{ reglement$.length }}</span>
                                </mat-panel-description>
                            </mat-expansion-panel-header>

                            <div *ngFor="let reglement of reglement$ ; index as indice">
                                <ng-container *ngIf=" reglement.remise_en_banque.numero_bordereau != ''; then remis else nonRemis"></ng-container>
                                <ng-template #remis>
                                    <div class="div-container-between">
                                        <div>{{ reglement.remise_en_banque.date_creation | date : 'dd/MM/yyyy' }} : </div>
                                        <div class="label-primary">Bordereau N° {{ reglement.remise_en_banque.numero_bordereau }}</div>
                                        <div class="label-accent">{{ reglement.montant  | number:'1.2-2':'fr' }} €</div>
                                    </div>
                                </ng-template>  
                                <ng-template #nonRemis>
                                    <div class="div-container-between">
                                        <div>{{ reglement.remise_en_banque.date_creation | date : 'dd/MM/yyyy' }} : </div>
                                        <div class="label-primary">{{ reglement.ModePaiement.libelle }} N° {{ reglement.libelle }}</div>
                                        <div class="label-accent">non-remis</div>
                                    </div>
                                </ng-template>
                            </div>

                        </mat-expansion-panel>
                    </mat-accordion>

                <div style="min-width: 300px;">
                    <div *ngFor="let total of totaux">
                        <div class="div-container-between" style="margin: 15px;">
                            <div>{{ total.libelle }}</div>
                            <div class="label-primary titre">{{total.montant | number:'1.2-2':'fr'}} €</div>
                        </div>
                    </div>
                </div>

            </div>

        <div class="div-button-center">
            <button mat-button color="warn" (click)="pagePrecendente()" class="button-width">Fermer</button>
        </div>
    </mat-card-content>
</mat-card>