import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiErrorService } from 'src/app/services/support/api-error.service';
import { TypeSuspension } from '../interfaces/suspension';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TypesuspensionService {

  url: string       = '/api/suspension/type';
  typesSuspension$  = this.getTypesSuspension()

  constructor(private http:HttpClient
            , private apiError    : ApiErrorService) { }

  // 1 - TYPES DE SUSPENSION

  //1.1 - Select
  public getTypesSuspension():Observable<TypeSuspension[]>{
    return this.http.get<TypeSuspension[]>(this.url)
                    .pipe(catchError(this.apiError.handleError<TypeSuspension[]>('getTypesSuspension',[])));
  }

  //1.2 - Update
  public putTypeSuspension(typeSuspension:TypeSuspension):Observable<TypeSuspension | boolean>{
    return this.http.put<TypeSuspension>(this.url, typeSuspension)
                    .pipe(catchError(this.apiError.handleError<boolean>('putTypeSuspension',false)));
  }

  //1.3 - Insert
  public postTypeSuspension(typeSuspension:TypeSuspension):Observable<TypeSuspension | boolean>{
    
    return this.http.post<TypeSuspension>(this.url, typeSuspension)
                    .pipe(catchError(this.apiError.handleError<boolean>('postTypeSuspension',false)));
  }

  //1.4 - Delete
  public deleteTypeSuspension(idTypeSuspension:number): Observable<boolean> {    
    return this.http.delete<boolean>(this.url+'/'+idTypeSuspension)
                    .pipe(catchError(this.apiError.handleError<boolean>('deleteTypeSuspension',false)));
  }

}
