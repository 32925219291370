import { DatePipe, NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { MatTableExporterModule } from 'mat-table-exporter';
import { ExportTableComponent } from 'src/app/components/templates/export-table/export-table.component';
import { ImmatDirective } from 'src/app/directive/immat.directive';
import { SuiviService } from '../../data_access/suivi.service';
import { Suivi } from '../../interfaces/suivi';
import { PaginationService } from 'src/app/services/pagination.service';

@Component({
  selector: 'app-list-suivi',
  templateUrl: './list-suivi.component.html',
  styleUrls: ['./list-suivi.component.scss'],
  standalone:true,
  imports:[MatCardModule,MatIconModule,MatButtonModule,MatTooltipModule,NgIf,MatTableModule,DatePipe
          ,RouterLink,MatPaginatorModule,ExportTableComponent,MatTableExporterModule
          ,MatSlideToggleModule, ImmatDirective,NgClass,MatProgressSpinnerModule]
})

export class ListSuiviComponent {
  @Input() suivis:Suivi[]       = []
  @Input() error : boolean      = false
  @Output() archiveEvent        = new EventEmitter<Suivi>()
  @Output() editSuiviEvent      = new EventEmitter<Suivi>()
  @Output() editSuiviSivEvent   = new EventEmitter<Suivi>()

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;

  dataSourceMatTable  : MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns    : string[] = ['statut','dossier','immat','date_creation','date_envoi','entite_nom','Actions'];
  waiting             : boolean =false
  pagination        = inject(PaginationService)
  
  constructor(public apiSuiviRDEA       : SuiviService) {}

  ngOnInit(): void {
    this.initDataSource()
  }

  ngOnChanges(){
   this.initDataSource()
  }
  ngAfterViewInit() {
    setTimeout(() => {
      if (this.paginator) {
        this.dataSourceMatTable.paginator = this.paginator;
      }
      if (this.sort) {
        this.dataSourceMatTable.sort = this.sort;
      }
      // Restaurer la page enregistrée
      const currentPage = this.pagination.getCurrentPage();
      this.paginator!.pageIndex = currentPage;
    });
  }
  initDataSource(){
    if(this.suivis != undefined) {
      this.dataSourceMatTable.data = this.suivis;
      this.waiting = false
    }
    this.dataSourceMatTable.paginator = this.paginator;
    this.dataSourceMatTable.sort = this.sort;
    if (this.error) {
      this.waiting = false
    }
  }
  editer(suivi:Suivi){
    this.waiting = true
    this.editSuiviEvent.next(suivi)
  }

  editerSiv(suivi:Suivi){
    this.editSuiviSivEvent.next(suivi)
  }
  
  archive(suivi:Suivi){
    suivi.statut = this.apiSuiviRDEA.CONST_STATUT_ARCHIVE;
    this.archiveEvent.next(suivi)
  }

  ngOnDestroy() {
    // Sauvegarder la page actuelle
    this.pagination.setCurrentPage(this.paginator!.pageIndex);
  }
}
