import { Component, OnInit } from '@angular/core';
import {  TypeSuspension } from '../../interfaces/suspension';

import { MatDialog } from '@angular/material/dialog';
import { OuiNonAnnulerComponent } from 'src/app/components/templates/oui-non-annuler/oui-non-annuler.component';
import { TypeSuspensionFacadeService } from '../../facades/type-suspension-facade.service';
import { CST_ACTION_ADD, CST_ACTION_MODIFY, CST_ACTION_REMOVE } from 'src/app/interfaces/constantes';
import { TableTypesuspensionComponent } from '../table-typesuspension/table-typesuspension.component';
import { EditTypeSuspensionComponent } from '../edit-type-suspension/edit-type-suspension.component';
import { NgIf } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-shell-typesuspension',
    templateUrl: './shell-typesuspension.component.html',
    styleUrls: ['./shell-typesuspension.component.scss'],
    standalone: true,
    imports: [NgIf, EditTypeSuspensionComponent, TableTypesuspensionComponent]
})
export class ShellTypesuspensionComponent implements OnInit {

  typeSuspensionToSave! : TypeSuspension
  typesSuspension$      = this.typeSuspensionFacade.typesSuspension$
  modeEdit              : boolean = false
  currentElement        : string = ''

  constructor(private typeSuspensionFacade:TypeSuspensionFacadeService,
                private  dialog         : MatDialog
                , private router        : Router
                , private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe(url => {
      
      const elements    = this.router.url.split('/');
      this.currentElement = elements[elements.length - 1];
      
      switch (this.currentElement) {
        
        case 'type':
          this.modeEdit           = false
          break;
        
        case 'edit':
          this.modeEdit         = true
          break;

        default:
          break;
      }
    });
  }

  editTypeSuspension(typeSuspension: TypeSuspension){
    if (typeSuspension.action===CST_ACTION_REMOVE) {
      const dialogOuiNon = this.dialog.open(OuiNonAnnulerComponent, { 
        width: '320px', 
        data: { 
          title: "",
          message: "Voulez-vous vraiment supprimer le type de suspension " + typeSuspension.nom + " ?"        
          , yes_no_cancel: 2 
        } 
      });
      dialogOuiNon.afterClosed()
     .subscribe(
       res => {
        switch (res) {
           case true:
            this.saveTypeSuspension(typeSuspension)        
             break;
          default:
             break;
         }
     });
      
    }else{
      this.modeEdit = true
      this.router.navigate(['/app/suspension/type/edit']);
      this.typeSuspensionToSave = typeSuspension
    }
  }

  saveTypeSuspension(typeSuspension:TypeSuspension){
    if (typeSuspension) {
      switch (typeSuspension.action) {
        case CST_ACTION_ADD:
          //We add a type of suspension:
          this.typeSuspensionFacade.add(typeSuspension)  
          break;
  
        case CST_ACTION_MODIFY:
          this.typeSuspensionFacade.modify(typeSuspension)  
          break;
  
        case CST_ACTION_REMOVE:
          this.typeSuspensionFacade.remove(typeSuspension.id)
          break;
      }
    }
    
    this.modeEdit = false
    this.router.navigate(['/app/suspension/type']);
  }

}
