import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { ModePaiement } from 'src/app/interfaces/facture/mode-paiement';
import { ApiFactureService } from 'src/app/services/api-facture.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { Reglement } from "src/app/interfaces/facture/reglement";
import { TableRemiseComponent } from '../table-remise/table-remise.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgFor } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';

@Component({
    selector: 'app-mes-remises',
    templateUrl: './mes-remises.component.html',
    styleUrls: ['./mes-remises.component.scss'],
    standalone: true,
    imports: [MatToolbarModule, NgIf, MatButtonModule, MatIconModule, MatCardModule, ReactiveFormsModule, MatExpansionModule, MatFormFieldModule, MatSelectModule, MatOptionModule, NgFor, MatProgressSpinnerModule, TableRemiseComponent]
})
export class MesRemisesComponent implements OnInit {

  // Gestion de l'affichage
  waitingResearch       : boolean = false;
  expandPannel          : boolean = false;
  // Liste des interfaces pour les combos
  modes_paiement: ModePaiement[] = [];
  mode_paiementSelectionne: string = "";

  // Formulaire de filtrage
  FiltreFormGroup: any;

  // Tableau des règlements
  reglements: Array<Reglement> = [];

  get mode_paiement() { return this.FiltreFormGroup.get('mode_paiement'); }

  constructor(private _fb: FormBuilder
            , private toast: PersoSnackbarService
            , private apiFacture: ApiFactureService) { }

  ngOnInit(): void {
    
    // Récupération des paramètres du formulaire de filtrage
    this.chargeModePaiement();

    // Initialisation du formulaire de filtrage
    this.FiltreFormGroup = this._fb.group({
      mode_paiement: ['', [Validators.required]]           
    });    
    
    
  }
  

  public chargeModePaiement() {
    this.apiFacture.getModePaiement()
      .subscribe(
        (data: ModePaiement[] ) => {              
          this.modes_paiement = data;
          this.FiltreFormGroup.patchValue({
            mode_paiement: this.modes_paiement
          });
          this.FiltreFormGroup.get('mode_paiement').setValue('TOUS')
        }
      );    
  }

  public onRechercheReglement() { 
    this.waitingResearch = true;
    this.reglements = [];
    this.mode_paiementSelectionne = ""
    
    this.apiFacture.getReglementARemettre(this.FiltreFormGroup.controls.mode_paiement.value)
      .subscribe(
        (data: Reglement[]) => {
          this.reglements = data;
          this.mode_paiementSelectionne = this.FiltreFormGroup.controls.mode_paiement.value;
          this.toast.showInfo('Recherche terminée');
          this.waitingResearch = false;
        },
        (err: any) => {
          console.error('/!\ err: ', err.message);
          this.waitingResearch = false;
        }
      );
  }
}