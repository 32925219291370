import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InvitationCollaborateurBdd } from 'src/app/interfaces/invitation-collaborateur/invitation-collaborateur-bdd';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  standalone:true,
  imports:[MatCardModule,MatTableModule, MatSortModule, MatPaginatorModule,MatIconModule,MatButtonModule,MatTooltipModule,NgIf,NgClass]
})
export class ListComponent {
  @Input() invitations: InvitationCollaborateurBdd[] = [];
  @Output() annuleInvitationEvent = new EventEmitter<number>()

  displayedColumns: string[] = ['inv_nom', 'inv_prenom', 'inv_email', 'grp_nom', 'inv_dirigeant', 'inv_succursales', 'inv_etat', 'action'];
  dataSource!: MatTableDataSource<any>;

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.invitations);
  }

  ngOnChanges(): void {
    if (this.dataSource) {
      this.dataSource.data = this.invitations;
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  onCancel(idInvitation:number){
    this.annuleInvitationEvent.next(idInvitation)
  }

}
