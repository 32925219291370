<mat-card appearance="outlined" >
    <mat-card-header>
      <mat-card-title>{{rapports1a.length}} Opération<span *ngIf="rapports1a.length >1">s</span></mat-card-title>
    </mat-card-header>

  <div class="container" >
      <table 
        mat-table 
        matTableExporter 
        matSort 
        [dataSource]  = "dataSource" 
        #exporter     = "matTableExporter">
    
        <!-- Date Column -->
        <ng-container matColumnDef="dateOpe1a">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Date opération 1A </th>
          <td mat-cell *matCellDef="let element"> {{ element.date_traitement_anea|date: 'dd/MM/yyyy' }} </td>
        </ng-container>
    
        <!-- habilitation -->
        <ng-container matColumnDef="habilitation">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Habilitation </th>
          <td mat-cell *matCellDef="let element"> {{ element.habilitation }} </td>
        </ng-container>
    
        <!-- Immatriculation -->
        <ng-container matColumnDef="immat">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Immatriculation </th>
          <td mat-cell *matCellDef="let element"> {{ element.immatriculation }} </td>
        </ng-container>
    
        <!-- Dossier -->
        <ng-container matColumnDef="dossier">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Dossier </th>
          <td mat-cell *matCellDef="let element"> {{ element.numero_dossier }} </td>
        </ng-container>
    
        <!-- Agrément -->
        <ng-container matColumnDef="agrement">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Agrément </th>
          <td mat-cell *matCellDef="let element"> {{ element.agrement_ve }} </td>
        </ng-container>

        <!-- Nom -->
        <ng-container matColumnDef="nom">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom de l'expert </th>
            <td mat-cell *matCellDef="let element"> {{ element.nomExpert }} </td>
          </ng-container>

          <!-- Prénom -->
        <ng-container matColumnDef="prenom">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Prénom de l'expert </th>
            <td mat-cell *matCellDef="let element"> {{ element.prenomExpert }} </td>
          </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    
      <div class="div-container-between" style="align-items: center;background-color:white">
        <app-export-table 
          [fileExportName]="'Rapport des opérations 1B non envoyées'" 
          [exporter]="exporter" 
          [nb_ligne]="dataSource.data.length"
        ></app-export-table>
        <mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  
</mat-card>
