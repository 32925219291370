<div class="content">
    <div class="titlebox">Recherche d'entités</div>

    <form [formGroup]="filterEntityForm">

            <mat-form-field style="max-width:150px">
                <mat-label>Habilitation</mat-label>
                <input  matInput 
                        type            = "text" 
                        placeholder     = "Habilitation" 
                        formControlName = "habilitation" 
                        oninput         = "this.value = this.value.toUpperCase()"
                        >
            </mat-form-field> 

            <mat-form-field style="max-width:150px">
                <mat-label>Numéro Contact</mat-label>
                <input  matInput 
                        type            = "text" 
                        placeholder     = "Numéro Contact" 
                        formControlName = "numeroContact"
                        >
            </mat-form-field> 
        
            <mat-form-field style="max-width:200px">
                <mat-label>Raison sociale</mat-label>
                <input  matInput 
                        type            = "text" 
                        placeholder     = "Raison sociale"
                        formControlName = "raison_sociale"
                        >
            </mat-form-field>

            <mat-form-field style="max-width:120px">
                <mat-label>SIREN</mat-label>
                <input  matInput 
                        type            = "text" 
                        placeholder     = "SIREN" 
                        formControlName = "siren"
                        >
            </mat-form-field>
            
            <mat-form-field style="max-width:250px">
                <mat-label>Ville</mat-label>
                <input  matInput 
                        type            = "text" 
                        placeholder     = "Ville"
                        formControlName = "ville"
                        >
            </mat-form-field>

            <mat-form-field style="max-width:200px">
                <mat-label>Type d'entité</mat-label>
                <mat-select 
                    formControlName     = "typeEntite"
                    >
                    <mat-option value="">Tous</mat-option>
                    <mat-option 
                        *ngFor  = "let type of typesEntite" 
                        [value] = "type.code" 
                    >{{ type.libelle }}</mat-option>
                </mat-select>
        </mat-form-field>
        <div class="div-container-right">
            <mat-card-actions align="end">
                <button mat-flat-button 
                        *ngIf       = "!waiting"
                        color       = "primary" 
                        (click)     = "onSearchEntites()"
                        >
                    <mat-icon>search</mat-icon>Rechercher                                    
                </button>
                <button
                    mat-flat-button
                    *ngIf       = "!waiting"
                    color       = "primary" 
                    (click)     = "cancel()"
                >
                <mat-icon>cancel</mat-icon>Annuler</button>
                <mat-spinner diameter="24" *ngIf="waiting"></mat-spinner>
            </mat-card-actions>
        </div>
    </form>
            
  
    <mat-table [dataSource]= "dataSourceMatTable">
        
        <!-- Type Entité --> 
        <ng-container matColumnDef="typ_ent_nom">
            <mat-header-cell *matHeaderCellDef> Type entité </mat-header-cell>
            <mat-cell *matCellDef="let Entite"> {{ Entite.typ_ent_nom }}</mat-cell>
        </ng-container>
      
        <!-- Habilitation --> 
        <ng-container matColumnDef="ent_habilitation">
            <mat-header-cell *matHeaderCellDef class="column-habilitation"> Habilitation </mat-header-cell>
            <mat-cell *matCellDef="let Entite" class="column-habilitation"> {{ Entite.habilitation }}</mat-cell>
        </ng-container>
              
        <!-- Numéro de contact -->
        <ng-container matColumnDef="ent_numero_client">
            <mat-header-cell *matHeaderCellDef class="column-contact" > Contact </mat-header-cell>
            <mat-cell *matCellDef="let Entite" class="column-contact" > {{ Entite.numero_client }}</mat-cell>
        </ng-container>
      
        <!-- Raison sociale -->
        <ng-container matColumnDef="ent_raison_sociale">
            <mat-header-cell *matHeaderCellDef > Raison sociale </mat-header-cell>
            <mat-cell *matCellDef="let Entite"> {{ Entite.raison_sociale }}</mat-cell>
        </ng-container>
              
        <!-- CP -->
        <ng-container matColumnDef="ent_code_postal">
            <mat-header-cell *matHeaderCellDef class="column-code-postal"> CP </mat-header-cell>
            <mat-cell *matCellDef="let Entite" class="column-code-postal"> {{ Entite.code_postal }}</mat-cell>
        </ng-container>
      
        <!-- Ville -->
        <ng-container matColumnDef="ent_ville">
            <mat-header-cell *matHeaderCellDef> Ville </mat-header-cell>
            <mat-cell *matCellDef="let Entite"> {{ Entite.ville }}</mat-cell>
        </ng-container>
      
        <!-- SIREN -->
        <ng-container matColumnDef="ent_siren">
            <mat-header-cell *matHeaderCellDef> SIREN </mat-header-cell>
            <mat-cell *matCellDef="let Entite"> {{ Entite.siren }}</mat-cell>
        </ng-container>
              
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row matRipple *matRowDef="let entite; columns: displayedColumns;" (click)="selectEntite(entite)" style="cursor:pointer"></mat-row>
      
      </mat-table>
      

    <div class="div-container-right" style="align-items: center;background-color:white">
        <mat-paginator
            [pageSizeOptions]="[5,10,25]"
            showFirstLastButtons 
            aria-label="Nombre de lignes visibles...">
        </mat-paginator>
    </div>
        
</div>