import { Component, OnInit, ViewChild,Input, OnChanges, SimpleChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Modele } from 'src/app/interfaces/support/modele';
import { OuiNonAnnulerComponent } from '../../../templates/oui-non-annuler/oui-non-annuler.component';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { ModeleFacadeService } from 'src/app/services/modele/modele-facade.service';
import {Subject} from 'rxjs';
import { takeUntil} from 'rxjs/operators';
import { MatTableResponsiveLargeDirective } from '../../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, DatePipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-table-modele',
    templateUrl: './table-modele.component.html',
    styleUrls: ['./table-modele.component.scss'],
    standalone: true,
    imports: [MatCardModule, NgIf, MatButtonModule, MatIconModule, MatTooltipModule, MatTableModule, MatTableResponsiveLargeDirective, MatPaginatorModule, DatePipe]
})
export class TableModeleComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;
  @Input()  modeles : Modele[]=[];
  @Input() idSousCategorie:number = 0;
  @Output() modeleEditEvent = new EventEmitter<Modele>();

  dataSourceMatTable: MatTableDataSource<Modele> = new MatTableDataSource<Modele>();
  displayedColumns            : string[] = ['nom' ,'sous_categorie', 'date_creation', 'actions']
  idSousCategorieModele       : number = this.idSousCategorie;
  destroyed                   : Subject<void> = new Subject<void>();

  constructor(public apiModeleFacade :ModeleFacadeService
              , public dialog       : MatDialog
              , private toast       : PersoSnackbarService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes:SimpleChanges){
    this.initMatTable()
    this.idSousCategorieModele = this.idSousCategorie;
  }

  ngAfterViewInit() {
    this.dataSourceMatTable.paginator = this.paginator;
    this.dataSourceMatTable.sort = this.sort;
  }

  initMatTable(){
    this.dataSourceMatTable.data      = this.modeles;
    this.dataSourceMatTable.paginator = this.paginator;
    this.dataSourceMatTable.sort      = this.sort;
  }

  onCreer(){
    let modele : Modele = <Modele>{}
     //on signifie qu'il s'agit d'une création
     modele.mdl_id = 0;
     modele.mdl_sou_cat_id = this.idSousCategorieModele
    this.modeleEditEvent.emit(modele)
  }
  
  onDupliquer(modele:Modele){
   
    const modeleACreer = {...modele, 'mdl_code':'','mdl_id':0,'mdl_nom':modele.mdl_nom + " (bis)"}
    
    this.modeleEditEvent.emit(modeleACreer)
  }

  onEditer(modele:Modele){
    this.modeleEditEvent.emit(modele)
  }

  onSupprimer(modeleASupprimer:Modele){
    //on demande confirmation:
    const messageConfirmation = "Voulez-vous vraiment supprimer le modèle <".concat(modeleASupprimer.mdl_nom,"> ?")
    const dialogRef = this.dialog.open(OuiNonAnnulerComponent, {
      data: {message: messageConfirmation , title: 'Demande de confirmation', type:2},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.apiModeleFacade.deleteModele(modeleASupprimer.mdl_id).pipe(takeUntil(this.destroyed)).subscribe(
          (data:any)=>{
            this.toast.showInfo('Le modèle a bien été modifié.')
            //on supprime le  modèle de la liste en cours:
            this.apiModeleFacade.removeModele(modeleASupprimer.mdl_id)
            this.initMatTable()
          }
        )
      }
    });
    
  }

  ngOnDestroy() {
    this.destroyed.next(); // emits an undefined value to raise the flag
    this.destroyed.complete(); // prevents all further emission
  }
  
}
