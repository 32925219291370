import { Component, Input, OnInit } from '@angular/core';
import { LegendPosition, BarChartModule } from '@swimlane/ngx-charts';

@Component({
    selector: 'app-repartition-dangerosite',
    templateUrl: './repartition-dangerosite.component.html',
    styleUrls: ['./repartition-dangerosite.component.scss'],
    standalone: true,
    imports: [BarChartModule]
})
export class RepartitionDangerositeComponent implements OnInit {

  public chartData: {
    name: string,
    series: {
      name: string,
      value ? : string | number
    } []    
  } [];  

  // Données à afficher
  @Input() ELEMENT_DATA_TOTAL_LS3: number = 0;
  @Input() ELEMENT_DATA_TOTAL_CA3: number = 0; 
  @Input() ELEMENT_DATA_TOTAL_DI3: number = 0;
  @Input() ELEMENT_DATA_TOTAL_SP4: number = 0;
  @Input() ELEMENT_DATA_TOTAL_EN5: number = 0; 

  // Options
  legendPosition = LegendPosition.Right;

  constructor() {
    this.chartData = [
      { 
        name: '',
        series: [
          {
            name: 'LS3',
            value: 0
          },
          {
            name: 'CA3',
            value: 0
          },
          {
            name: 'DI3',
            value: 0
          },
          {
            name: 'SP4',
            value: 0
          },
          {
            name: 'EN5',
            value: 0
          }
          
        ]
      }            
    ];
   }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.chartData = [
      { 
        name: '',
        series: [
          {
            name: 'LS3',
            value: this.ELEMENT_DATA_TOTAL_LS3
          },
          {
            name: 'CA3',
            value: this.ELEMENT_DATA_TOTAL_CA3
          },
          {
            name: 'DI3',
            value: this.ELEMENT_DATA_TOTAL_DI3
          },
          {
            name: 'SP4',
            value: this.ELEMENT_DATA_TOTAL_SP4
          },
          {
            name: 'EN5',
            value: this.ELEMENT_DATA_TOTAL_EN5
          }
        ]
      }            
    ];
  }
}
