import { Component, OnInit,Input,Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil} from 'rxjs/operators';
import { SousCategorie } from 'src/app/interfaces/support/sous-categorie';
import { ApiSupportService } from 'src/app/services/api-support.service';
import { ModeleFacadeService } from 'src/app/services/modele/modele-facade.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { NgFor, AsyncPipe } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { AutofocusDirective } from '../../../../directive/autofocus.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-edit-sous-categorie',
    templateUrl: './edit-sous-categorie.component.html',
    styleUrls: ['./edit-sous-categorie.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatFormFieldModule, AutofocusDirective, MatSelectModule, MatOptionModule, NgFor, MatInputModule, MatButtonModule, AsyncPipe]
})
export class EditSousCategorieComponent implements OnInit,OnDestroy {
  @Input() sousCategorie            : SousCategorie = <SousCategorie>{};
  @Output() resultValidateEvent     = new EventEmitter<boolean>() ;
  SousCategorieFormGroup!           : FormGroup     
  titre                             : string =''
  destroyed                         : Subject<void> = new Subject<void>();

  constructor(private _fb         : FormBuilder
              , public apiModeleFacade  : ModeleFacadeService
              , private toast           : PersoSnackbarService) { }

  ngOnInit(): void {
    this.initForm()
    this.Editer()
  }

  ngOnChanges(){
    if (this.sousCategorie.sca_id==0) {
      this.titre = "Création d'une sous-catégorie"
    }else{
      this.titre = "Edition d'une sous-catégorie"
    }
    this.initForm()
    this.Editer()
  }

  initForm(){
    this.SousCategorieFormGroup        = this._fb.group({
      nom         : ['', [Validators.required]],
      code        : [''],
      idCategorie : [0,[Validators.required]]
    })
  }

  Editer(){
    this.SousCategorieFormGroup.patchValue({
      nom         : this.sousCategorie.sca_nom,
      code        : this.sousCategorie.sca_code,
      idCategorie : this.sousCategorie.sca_cat_id
    })
  }

  onValide(){
    this.sousCategorie.sca_code     = this.SousCategorieFormGroup.controls.code.value
    this.sousCategorie.sca_nom      = this.SousCategorieFormGroup.controls.nom.value
    this.sousCategorie.sca_cat_id   = this.SousCategorieFormGroup.controls.idCategorie.value
    // let subject = new ReplaySubject(1);
    if (this.sousCategorie.sca_id==0) {
      //Création de sous-catégorie
      this.apiModeleFacade.creeSousCategorie(this.sousCategorie).pipe(takeUntil(this.destroyed)).subscribe((res:boolean)=>{if (res) {
        this.resultValidateEvent.emit(true)     
      }}
      )
    }else{
      //Il s'agit d'une modification
      this.apiModeleFacade.modifierSousCategorie(this.sousCategorie).pipe(takeUntil(this.destroyed)).subscribe((res:boolean)=>{if (res) {
        this.resultValidateEvent.emit(true)     
      }}
      )
    }
  }

  onAnnule(){
    this.resultValidateEvent.emit(true)
  }

  ngOnDestroy() {
    this.destroyed.next(); // emits an undefined value to raise the flag
    this.destroyed.complete(); // prevents all further emission
  }
}
