import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { mergeMap, pairwise, startWith } from 'rxjs/operators';
import { ResultatAnalyse, ValeurResultat } from 'src/app/interfaces/adela/resultat-analyse';
import { ApiAdelaService } from 'src/app/services/api-adela.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-resultat-analyse-edit',
    templateUrl: './resultat-analyse-edit.component.html',
    styleUrls: ['./resultat-analyse-edit.component.scss'],
    standalone: true,
    imports: [MatCardModule, NgIf, ReactiveFormsModule, NgFor, MatFormFieldModule, MatInputModule, MatButtonModule, MatIconModule, MatProgressSpinnerModule]
})
export class ResultatAnalyseEditComponent implements OnInit {

@Input()          idDossier         : number          = 0;
@Input()          etatCode          : string          = '';
@Output()         closeDossier      = new EventEmitter<boolean>();

analyseDossier    : ResultatAnalyse = <ResultatAnalyse>{};
resultsPC         : ValeurResultat[] = [];
resultsPCB        : ValeurResultat[] = []
resultsSpect      : ValeurResultat[] = []
resultsSansCat    : ValeurResultat[] = []
done              : boolean = false;
categoriesForm    : Array<FormArray> =[]
AnalyseForm!      : FormGroup;
waiting           : boolean = false
idResultat        : number = 0

  get resultsPCArray(){
    return this.AnalyseForm.get(['resultsPCArray']) as FormArray
  }

get resultsPCBArray(){
  return this.AnalyseForm.get(['resultsPCBArray']) as FormArray
}

get resultsSpectArray(){
  return this.AnalyseForm.get(['resultsSpectArray']) as FormArray
}

get resultsSansCatArray(){
  return this.AnalyseForm.get(['resultsSansCatArray']) as FormArray
}

  constructor(
              public apiAdela      : ApiAdelaService,
              private fb            : FormBuilder,
              private toast         : PersoSnackbarService
  ) {}

  
  ngOnInit(): void {

    this.apiAdela.getResultatAnalyse(this.idDossier,true).subscribe(
      (data : ResultatAnalyse) => {
        this.analyseDossier = data;
        this.idResultat     = this.analyseDossier.id
        
        this.resultsPC      = this.analyseDossier.tabst_ValeurResultat.filter((result) => result.st_TypeValeurResultat.code_categorie === 'PHYSICOCHIMIQUE' && result.id_resultat_analyse!=0)
        this.resultsPCB     = this.analyseDossier.tabst_ValeurResultat.filter((result) => result.st_TypeValeurResultat.code_categorie === 'PHYSICOCHIMIQUE_BSM' && result.id_resultat_analyse!=0)
        this.resultsSpect   = this.analyseDossier.tabst_ValeurResultat.filter((result) => result.st_TypeValeurResultat.code_categorie === 'SPECTROMETRIE' && result.id_resultat_analyse!=0)
        this.resultsSansCat = this.analyseDossier.tabst_ValeurResultat.filter((result) => result.st_TypeValeurResultat.code_categorie === '' && result.id_resultat_analyse!=0)
        
        this.AnalyseForm = this.fb.group({
          
          interpretation     : new FormControl(this.analyseDossier.interpretation),

          resultsPCArray:this.fb.array(
            this.resultsPC.map((res)=>this.generateFormControl(res))
          ),

          resultsPCBArray : this.fb.array(
            this.resultsPCB.map((res)=>this.generateFormControl(res))  
          ),

          resultsSpectArray : this.fb.array(
            this.resultsSpect.map((res)=>this.generateFormControl(res))  
          ),

          resultsSansCatArray : this.fb.array(
            this.resultsSansCat.map((res)=>this.generateFormControl(res))  
          )
          
        })

        this.categoriesForm = [this.resultsPCArray,this.resultsPCBArray,this.resultsSpectArray,this.resultsSansCatArray]
        this.done = true
        
        if (this.etatCode==='CLOTURE') {
          this.AnalyseForm.disable()
        }
      }
      
        
    )
   
  }

  generateFormControl(data:ValeurResultat):FormGroup{
    const group =  this.fb.group({
      resultValue       : data.valeur_new? data.valeur_new : data.valeur,
      resultValueSaved  : data.valeur_new? data.valeur_new : data.valeur,
      resultValueInit   : data.valeur,
      resultInit        : data.valeur_new? false : true, 
      resultUnit        : data.st_TypeValeurResultat.unite,
      resultLabel       : data.st_TypeValeurResultat.libelle,
      resultId          : data.id
    })
    if (this.etatCode==='CLOTURE') {
      // group.disable()
    }
    group.controls['resultValue'].valueChanges.pipe(startWith(group.controls['resultValue'].value), pairwise()).subscribe(
      ([prev, next])=> {
        if (group.controls['resultValueInit'].value === prev) {
          group.controls['resultInit'].setValue(false)
          group.controls['resultValueInit'].setValue(prev)
        }
        if (next ===group.controls['resultValueInit'].value) {
          group.controls['resultInit'].setValue(true)
          group.controls['resultValueInit'].setValue('')
        }
      }
    )
    return group
  }
  
  save(){
    this.waiting = true;
    let analyseValues : Array<any>=[];
    
    analyseValues = this.buildAnalyse(analyseValues,this.AnalyseForm.value.resultsPCArray)
    analyseValues = this.buildAnalyse(analyseValues,this.AnalyseForm.value.resultsPCBArray)
    analyseValues = this.buildAnalyse(analyseValues,this.AnalyseForm.value.resultsSpectArray)
    analyseValues = this.buildAnalyse(analyseValues,this.AnalyseForm.value.resultsSansCatArray)
    
    this.apiAdela.postAnalyse(analyseValues,this.AnalyseForm.value.interpretation,this.idResultat).subscribe(
      ()=> {
        this.toast.showInfo('Vos modifications ont bien été prises en compte')
        this.waiting = false
        this.AnalyseForm.markAsPristine()
      }

      ,error => {
        this.toast.showError('Un problème est survenu lors de la modification')
        this.waiting = false
      })
  }

  close(){
    let analyseValues : Array<any>=[];
    this.waiting = true
    
    analyseValues = this.buildAnalyse(analyseValues,this.AnalyseForm.value.resultsPCArray)
    analyseValues = this.buildAnalyse(analyseValues,this.AnalyseForm.value.resultsPCBArray)
    analyseValues = this.buildAnalyse(analyseValues,this.AnalyseForm.value.resultsSpectArray)
    analyseValues = this.buildAnalyse(analyseValues,this.AnalyseForm.value.resultsSansCatArray)
    
    this.apiAdela.postAnalyse(analyseValues,this.AnalyseForm.value.interpretation,this.idResultat).pipe(mergeMap(
      ()=> {return this.apiAdela.cloturerDossierADELA(this.idDossier)}
    )).subscribe(
      (data: any) => {
        this.toast.showInfo('Votre dossier a bien été clôturé')
        this.waiting = false
        this.closeDossier.next(true)
      },
      (error)     =>{
        this.toast.showError('Suite à un problème technique, votre dossier n\'a pas pu être clôturé')
        this.waiting = false
        
      } 
    )
    
  }
  buildAnalyse(analyseValues:Array<any>,resultArray:Array<any>){
    
    resultArray.forEach((analyse:any) => {
      const analyseFound = this.analyseDossier.tabst_ValeurResultat.find((res) => res.id === analyse.resultId)
      if (analyseFound?.valeur_new) {
        if (analyseFound.valeur_new != analyse.resultValue) {
          analyseValues.push(analyse)
        }
      }else{
        //Etat initial : on n'a jamais modifié cette valeur
        if (analyseFound?.valeur != analyse.resultValue) {
          analyseValues.push(analyse)
        }
      }
    });
    
    return analyseValues
  }

}
