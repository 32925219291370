import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Observable } from 'rxjs';
import { OuiNonAnnulerComponent } from 'src/app/components/templates/oui-non-annuler/oui-non-annuler.component';
import { Analyse } from 'src/app/interfaces/adela/analyse';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatTableResponsiveLargeDirective } from '../../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';
import { NgIf, NgClass } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-list-prestation',
    templateUrl: './list-prestation.component.html',
    styleUrls: ['./list-prestation.component.scss'],
    standalone: true,
    imports: [MatCardModule, NgIf, MatTableModule, MatTableResponsiveLargeDirective, MatTableExporterModule, MatCheckboxModule, MatIconModule, NgClass, MatButtonModule, MatTooltipModule, MatPaginatorModule]
})
export class ListPrestationComponent implements OnInit{
  @Input() prestationsDisplayed$  : Observable<Analyse[]>= new Observable<Analyse[]>()
  @Input() modeEdit               : boolean = true
  @Output() prestationSelected = new EventEmitter<Analyse>()


  ds      : MatTableDataSource<Analyse> = new MatTableDataSource
 
  displayedColumns    : Array<String> = [ 'active','affiche','code', 'categorie', 'designation', 'libelle','codeLaboratoire', 'organe', 'fluide','tarif', 'actions'];
  columnNames = [ 'active','affiche','code', 'categorie', 'designation','libelle', 'codeLaboratoire', 'organe', 'fluide','tarif', 'actions'];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;
  
  constructor(public  dialog       : MatDialog) { }

  ngOnInit(): void {
       
    this.prestationsDisplayed$.subscribe((prestations)=>{
      this.ds.data = prestations;
    })
  }

  ngAfterViewInit(){
    this.ds.paginator = this.paginator
  }

  editer(prestation:Analyse){
  this.prestationSelected.next(prestation)
  }

  dupliquer(prestation:Analyse){
    const prestationDuplicated = {...prestation,'id':0,'duplicated':true}
    this.prestationSelected.next(prestationDuplicated)
    }

  supprimer(prestation:Analyse){
    const dialogOuiNon = this.dialog.open(OuiNonAnnulerComponent, { 
      width: '320px', 
      data: { 
        title: "",
        message: "Voulez-vous vraiment supprimer la prestation " + prestation.designation + " ?"        
        , yes_no_cancel: 2 
      } 
    });

    dialogOuiNon.afterClosed()
     .subscribe(
       res => {
        switch (res) {
           case true:
             const prestationCanceled = {...prestation,'canceled':true}
             this.prestationSelected.next(prestationCanceled)
              
             break;
          default:
             break;
         }
     });

  }
}

  


