<mat-card appearance="outlined" class="mat-card-no-shadow" style="padding: 0px; margin-top: 16px; margin-bottom: 16px;">
    <form [formGroup]="FiltreFormGroup">
        <mat-expansion-panel id="expandFilter" [(expanded)]="expandPannel" #expandFilter>                                
            <mat-expansion-panel-header style="background-color: #fff">
                <mat-panel-title class="label-accent">
                    <mat-icon>search</mat-icon>Veuillez sélectionner un service et les éventuelles options de votre recherche...
                </mat-panel-title>
            </mat-expansion-panel-header>

            <!-- Recherche de services externe -->
            <div>
                <mat-form-field>
                    <mat-label>Environnement</mat-label>
                    <mat-select name="environnement" id="environnement" formControlName="environnement" multiple>
                        <mat-option *ngFor="let environnement of environnements" [value]="environnement">
                            {{ environnement.libelle }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="!FiltreFormGroup?.valid && FiltreFormGroup.touched">Vous devez sélectionner au moins un environnement pour votre recherche</mat-error>
                </mat-form-field> 

                <mat-form-field>
                    <mat-label>Service</mat-label>
                    <mat-select name="service" id="service" formControlName="service" multiple>
                        <mat-option *ngFor="let service of services" [value]="service">
                            {{ service.libelle }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="!FiltreFormGroup?.valid && FiltreFormGroup.touched">Vous devez sélectionner au moins un service pour votre recherche</mat-error>
                </mat-form-field> 

                <mat-form-field style="max-width: 500px;">
                    <mat-label>Service externe</mat-label>
                    <mat-select name="service_externe" id="service_externe" formControlName="service_externe" multiple>
                        <mat-option *ngFor="let service_externe of services_externe" [value]="service_externe">
                            {{ service_externe.libelle }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="!FiltreFormGroup?.valid && FiltreFormGroup.touched">Vous devez sélectionner au moins un service externe pour votre recherche</mat-error>
                </mat-form-field>            
            </div>
            <mat-action-row>
                <div class="div-container-between" style="padding: 8px;">
                    <div class="div-container-center" style="width:100%; display: flex; justify-content: center;">
                        <button mat-flat-button 
                                *ngIf       = "!waitingResearch"
                                submit 
                                color       = "primary" 
                                [disabled]  = "waitingResearch || !FiltreFormGroup.valid" 
                                (click)     = "onSearchingServicesExterne()">
                            <mat-icon>search</mat-icon>Rechercher                                    
                        </button>
                        <mat-spinner diameter="24" *ngIf = "waitingResearch"></mat-spinner>
                    </div>
                </div>
            </mat-action-row>
        </mat-expansion-panel>
    </form>
</mat-card>

<app-table-service-externe [dataSource]="ds_services_externe"></app-table-service-externe>          