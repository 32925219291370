
<div>      
      <div class="wrapper">
            <ng-container *ngIf="isServiceFFEA(); then isService else isExtranet"></ng-container>
            
            <ng-template #isService>
                  <div *ngIf="utl_profil != undefined" id="tiles" class="container">
            
                        <!-- REPARATEUR : SERVICE FFEA - Recherche de dossier -->
                        <mat-card appearance="outlined" class="card" *ngIf="utl_profil.droits_utilisateur?.droit_module_reparateur">
                              <div class="header">
                                    <mat-icon color="primary" class="header-image-service">car_repair</mat-icon>
                                    <br><span class="span-title">Réparateur</span>
                              </div>
                              <br>
                              <mat-card-actions class="actions">
                                    <a routerLinkActive="active" routerLink="/svc/reparateur/dossier">
                                          <mat-icon>search</mat-icon>
                                          <span class="span-a">&nbsp;&nbsp;&nbsp;Rechercher un dossier</span>
                                    </a>
                                    <br>
                                    <a routerLinkActive="active" routerLink="/svc/profil/{{ utl_profil.id }}" fragment="0">
                                          <mat-icon>account_circle</mat-icon>
                                          <span class="span-a">&nbsp;&nbsp;&nbsp;Profil utilisateur</span>
                                    </a>
                                    <br>
                                    <a routerLinkActive="active" routerLink="/svc/profil/{{ utl_profil.id }}" fragment="1">
                                          <mat-icon>garage</mat-icon>
                                          <span class="span-a">&nbsp;&nbsp;&nbsp;Compte réparateur</span>
                                    </a>
                              </mat-card-actions>
                        </mat-card>
            
                        <!-- ADMIN SERVICE : SERVICE FFEA -->
                        <mat-card appearance="outlined" class="card" *ngIf="utl_profil.droits_utilisateur?.est_admin || utl_profil.droits_utilisateur?.droit_admin_ffea">
                              <div class="header">
                                    <mat-icon color="primary" class="header-image-service">manage_accounts</mat-icon>
                                    <br><span class="span-title">Administrateur</span>
                              </div>
                              <br>
                              <mat-card-actions class="actions">
                                    <a routerLinkActive="active" routerLink="/svc/admin/user">
                                          <mat-icon color="warn">switch_account</mat-icon>
                                          <span class="span-a">&nbsp;&nbsp;&nbsp;Gestion des utilisateurs</span>
                                    </a>
                                    <br>
                                    <a routerLinkActive="active" routerLink="/svc/admin/service-externe">
                                          <mat-icon color="warn">settings_applications</mat-icon>
                                          <span class="span-a">&nbsp;&nbsp;&nbsp;Gestion des services externes</span>
                                    </a>
                                    <br>
                                    <a routerLinkActive="active" routerLink="/svc/admin/log-service-externe">
                                          <mat-icon color="warn">description</mat-icon>
                                          <span class="span-a">&nbsp;&nbsp;&nbsp;Journaux des services externes</span>
                                    </a>
                              </mat-card-actions>
                        </mat-card> 
                  </div>
            </ng-template>
        
            <ng-template #isExtranet>
                 
                  <div *ngIf="utl_profil != undefined" id="tiles" class="container">
            
                        <!-- ACCUEIL -->
                        <mat-card appearance="outlined" class="card">
                              <div class="header">
                                    <mat-icon color="primary" class="header-image">home</mat-icon>
                                    <br><span class="span-title">ACCUEIL</span>
                              </div>
                              <br>
                              <mat-card-actions class="actions">
                                    <a    routerLinkActive  = "active" 
                                          [routerLink]      = " ['/'+authUser.root+'/profil/' +authUser.userConnect.id]"
                                          fragment          = "0">
                                          <mat-icon ><span class="material-symbols-outlined">
                                                account_circle
                                                </span></mat-icon>
                                          <span class="span-a">&nbsp;&nbsp;&nbsp;Mon profil</span>
                                    </a>
                                    <br>

                                    <a    routerLinkActive  = "active" 
                                          [routerLink]      = " ['/'+authUser.root+'/profil/' +authUser.userConnect.id]"
                                          fragment          = "1">
                                          <mat-icon ><span class="material-symbols-outlined">
                                                apartment
                                                </span></mat-icon>
                                          <span class="span-a">&nbsp;&nbsp;&nbsp;Mes entités</span>
                                    </a>
                                    <br>
                                   
                                    <a routerLinkActive="active" routerLink="/app/facture/mes-factures" *ngIf="utl_profil?.droits_utilisateur?.droit_module_mes_factures || utl_profil?.est_dirigeant">
                                          <mat-icon>account_balance</mat-icon>
                                          <span class="span-a">&nbsp;&nbsp;&nbsp;Mes factures</span>
                                    </a>   
                              </mat-card-actions>
                        </mat-card>

                        <!-- ADELA : ANALYSE DES FLUIDES -->
                        <mat-card appearance="outlined" *ngIf="utl_profil.droits_utilisateur.droit_module_adela || utl_profil.droits_utilisateur.est_admin" class="card">
                              <div class="header">
                                    <mat-icon color="primary" class="header-image">invert_colors</mat-icon>
                                    <br><span class="span-title">ADELA</span>
                              </div>
                              <br>
                              <mat-card-actions class="actions">
                                    <a routerLinkActive="active" routerLink="/app/adela">
                                          <mat-icon ><span class="material-symbols-outlined">
                                                invert_colors
                                                </span></mat-icon>
                                          <span class="span-a">&nbsp;&nbsp;&nbsp;Nouvelle analyse de fluide</span>
                                    </a>
                                    <br>

                                    <a routerLinkActive="active" routerLink="/app/adela-poudre">
                                          <mat-icon ><span class="material-symbols-outlined">
                                                fire_extinguisher
                                                </span></mat-icon>
                                          <span class="span-a">&nbsp;&nbsp;&nbsp;Nouvelle analyse de poudre</span>
                                    </a>
                                    <br>
                                   
                                    <a routerLinkActive="active" [routerLink]="['/app/article/kits', this.cmdCategorie.KIT_ADELA]">
                                          <mat-icon>shopping_bag</mat-icon>
                                          <span class="span-a">&nbsp;&nbsp;&nbsp;Commande de kits</span>
                                    </a>
                                    <br>
                                    <a routerLinkActive="active" routerLink="/app/dossier/mes-dossiers">
                                          <mat-icon>folder</mat-icon>
                                          <span class="span-a">&nbsp;&nbsp;&nbsp;Mes demandes d'analyse</span>
                                    </a>
                                    <br>
                                    <a routerLinkActive="active" [routerLink]="['/app/commande/mes-commandes']">
                                          <mat-icon ><span class="material-symbols-outlined">
                                                list_alt
                                                </span></mat-icon>
                                          <span class="span-a">&nbsp;&nbsp;&nbsp;Mes commandes</span>
                                    </a>
                                    <br>
                                    <a routerLinkActive="active" [routerLink]="['/app/tarif', 'adela']">
                                          <mat-icon>euro</mat-icon>
                                          <span class="span-a">&nbsp;&nbsp;&nbsp;Grille tarifaire</span>
                                    </a>
                              </mat-card-actions>
                        </mat-card>
            
                        <!-- Historique véhicule -->
                        <mat-card appearance="outlined" *ngIf="isVisibleConcentrateur() "
                              class="card">
                              <div class="header">
                                    <mat-icon color="primary" class="header-image">history</mat-icon>
                                    <br><span class="span-title">Concentrateur</span>
                              </div>
                              <br>
                              <mat-card-actions class="actions">
                                    <a routerLinkActive="active" routerLink="/app/historiquevehicule" *ngIf="utl_profil.droits_utilisateur.droit_module_historique_vehicule">
                                          <mat-icon>search</mat-icon>
                                          <span class="span-a">&nbsp;&nbsp;&nbsp;Historique véhicule</span>
                                    </a>
                                    <br>
                                    <a routerLinkActive="active" routerLink="/app/rdea-suivi" *ngIf="utl_profil.droits_utilisateur.droit_rdea">
                                          <mat-icon>upload_file</mat-icon>
                                          <span class="span-a">&nbsp;&nbsp;&nbsp;RDEA Certif</span>
                                    </a>
                                    <br>
                                    <a routerLinkActive="active" routerLink="/app/acquittement/mes-acquittements" *ngIf="utl_profil.droits_utilisateur.droit_module_acquittement">
                                          <mat-icon>sync_alt</mat-icon>
                                          <span class="span-a">&nbsp;&nbsp;&nbsp;Acquittement</span>
                                    </a>
                                    <br>
                                    <a routerLinkActive="active" routerLink="/app/rapport1a" >
                                          <mat-icon><span class="material-symbols-outlined">
                                                problem
                                                </span></mat-icon>
                                                <span class="span-a">&nbsp;&nbsp;&nbsp;Rapport des opérations 1B non envoyées</span>
                                    </a>

                              </mat-card-actions>
                        </mat-card>
            
                        <!-- Statistiques Concentrateur -->
                        <mat-card appearance="outlined" class="card" *ngIf="isVisibleStats()">
                              <div class="header">
                                    <mat-icon color="primary" class="header-image">leaderboard</mat-icon>
                                    <br><span class="span-title">Statistiques</span>
                              </div>
                              <br>
                              <mat-card-actions class="actions">
                                    <a routerLinkActive="active" routerLink="/app/statistiques/mes-statistiques"
                                          *ngIf="utl_profil.est_dirigeant || utl_profil.droits_utilisateur.est_admin">
                                          <mat-icon>call_missed_outgoing</mat-icon>
                                          <span class="span-a">&nbsp;&nbsp;&nbsp;Utilisation du concentrateur</span>
                                    </a>
                                    <br *ngIf="utl_profil.est_dirigeant || utl_profil.droits_utilisateur.est_admin">
                                    <a routerLinkActive="active" routerLink="/app/statistiques/mes-statistiques">
                                          <mat-icon>call_missed_outgoing</mat-icon>
                                          <span class="span-a">&nbsp;&nbsp;&nbsp;Observatoire des tarifs publics</span>
                                    </a>
                              </mat-card-actions>
                        </mat-card>
            
                        <!-- Commande de kits et outil diagnostic -->
                        <mat-card appearance="outlined" class="card" *ngIf="utl_profil.droits_utilisateur.droit_module_outil_diagnostic">
                              <div class="header">
                                    <mat-icon color="primary" class="header-image">shopping_cart</mat-icon>
                                    <br><span class="span-title">Outils diagnostic</span>
                              </div>
                              <br>
                              <mat-card-actions class="actions">
                                   
                                    <br>
                                    <a routerLinkActive="active" [routerLink]="['/app/article/kits', this.cmdCategorie.DIAG]">
                                          <mat-icon>work</mat-icon>
                                          <span class="span-a">&nbsp;&nbsp;&nbsp;Outils diagnostic</span>
                                    </a>
                                    <br>
                                    <a routerLinkActive="active" href="{{ utl_profil.id > 0 ? apiParam.urlMajOutilDiagnostic : '' }}" target="_blank" class="link-active">
                                          <mat-icon class="blue-icon link-icon">next_week</mat-icon>
                                          <span class="span-a">&nbsp;&nbsp;&nbsp;Mise à jour Outil diagnostic</span>
                                    </a>
                                    
                              </mat-card-actions>
                        </mat-card>
            
                        <!-- ADC -->
                        <mat-card appearance="outlined" class="card" *ngIf="isVisibleADC()" style="background-color: #c9a6a6;">
                              <div class="header">
                                    <mat-icon color="warn" class="header-image">key</mat-icon>
                                    <br><span class="span-title">Analyses de clés</span>
                              </div>
                              <br>
                              <mat-card-actions class="actions">
                                    <a routerLinkActive="active" routerLink="/app/adc">
                                          <mat-icon>add</mat-icon>
                                          <span class="span-a">&nbsp;&nbsp;&nbsp;Nouvelle analyse</span>
                                    </a>
                                    <br>
                                    <a routerLinkActive="active" [routerLink]="['/app/article/kits', this.cmdCategorie.KIT_ADELA]">
                                          <mat-icon>shopping_bag</mat-icon>
                                          <span class="span-a">&nbsp;&nbsp;&nbsp;Commande de kits</span>
                                    </a>
                                    <br>
                                    <a routerLinkActive="active" [routerLink]="['/app/tarif', 'adc']">
                                          <mat-icon>euro</mat-icon>
                                          <span class="span-a">&nbsp;&nbsp;&nbsp;Grille tarifaire</span>
                                    </a>
                              </mat-card-actions>
                        </mat-card>
                        
                        <!-- Portail administrateur -->
                        <mat-card appearance="outlined" class="card" *ngIf="utl_profil.droits_utilisateur.est_admin || utl_profil.droits_utilisateur.droit_module_admin_extranet">
                              <div class="header">
                                    <mat-icon color="primary" class="header-image">settings_applications</mat-icon>
                                    <br><span class="span-title">Administration extranet</span>
                              </div>
                              <br>
                              <mat-card-actions class="actions">
                                    <div>
                                          <a routerLinkActive="active" routerLink="/app/administration-extranet/cabinets">
                                                <mat-icon>apartment</mat-icon>
                                                <span class="span-a">&nbsp;&nbsp;&nbsp;Gestion des cabinets</span>
                                          </a>
                                          <br>
                                          <a routerLinkActive="active" routerLink="/app/administration-extranet/utilisateurs">
                                                <mat-icon>account_circle</mat-icon>
                                                <span class="span-a">&nbsp;&nbsp;&nbsp;Gestion des utilisateurs</span>
                                          </a>
                                          <br>
                                        
                                          <a routerLinkActive="active" routerLink="/app/administration-extranet/fournisseurs">
                                                <mat-icon>trolley</mat-icon>
                                                <span class="span-a">&nbsp;&nbsp;&nbsp;Gestion des fournisseurs</span>
                                          </a>
                                          <br>
                                    </div>
                                                                           
                                           
                                    <div *ngIf="utl_profil.droits_utilisateur.est_admin">
                                          <a routerLinkActive="active" routerLink="/app/administration-extranet/parametres">
                                                <mat-icon>settings</mat-icon>
                                                <span class="span-a">&nbsp;&nbsp;&nbsp;Gestion des paramètres</span>
                                          </a>
                                          <br>
                                    </div>    

                                   
                              </mat-card-actions>
                        </mat-card>

                         <!-- Tickets -->
                         <mat-card appearance="outlined" class="card" *ngIf="isVisibleTicket()">
                              <div class="header">
                                    <mat-icon color="primary" class="header-image">support_agent</mat-icon>
                                    <br><span class="span-title">Support</span>
                              </div>
                              <br>
                              <mat-card-actions class="actions">
                                    <a routerLinkActive="active" routerLink="/app/support/liste-tickets">
                                          <mat-icon>sell</mat-icon>
                                          <span class="span-a">&nbsp;&nbsp;&nbsp;Gestion des tickets</span>
                                    </a>      
                                    <br>                                                        
                                    <a    *ngIf="utl_profil.droits_utilisateur.est_admin" 
                                          routerLinkActive="active" routerLink="/app/support/modele">
                                          <mat-icon>description</mat-icon>
                                          <span class="span-a">&nbsp;&nbsp;&nbsp;Gestion des modèles de réponses</span>
                                    </a>
                                    <br>                                                        
                                    <a    *ngIf="utl_profil.droits_utilisateur.est_admin"
                                          routerLinkActive="active" routerLink="/app/administration-extranet/support">
                                          <mat-icon>contact_support</mat-icon>
                                          <span class="span-a">&nbsp;&nbsp;&nbsp;gestion des types de demande</span>
                                    </a>
                                    <br> 
                              </mat-card-actions>
                        </mat-card>

                          <!-- Comptabilité -->
                        <mat-card appearance="outlined" *ngIf="utl_profil.droits_utilisateur.droit_module_admin_compta" class="card">
                              <div class="header">
                                    <mat-icon color="primary" class="header-image">euro</mat-icon>
                                    <br><span class="span-title">Administration Comptabilité</span>
                              </div>
                              <br>
                              <mat-card-actions class="actions">
                                    <a routerLinkActive="active" routerLink="/app/compta/factures">
                                          <mat-icon>receipt_long</mat-icon>
                                          <span class="span-a">&nbsp;&nbsp;&nbsp;Factures</span>
                                    </a>
                                    <br>
                                    
                                    <a routerLinkActive="active" routerLink="/app/facture/mes-factures">
                                          <mat-icon>account_balance</mat-icon>
                                          <span class="span-a">&nbsp;&nbsp;&nbsp;Gestion des factures</span>
                                    </a>                                                              
                                    <br>

                                    <a routerLinkActive="active" routerLink="/app/compta/facturation">
                                          <mat-icon ><span class="material-symbols-outlined">
                                                order_play
                                                </span></mat-icon>
                                          <span class="span-a">&nbsp;&nbsp;&nbsp;Lancement de la facturation</span>
                                    </a>
                                    <br>

                                    <a routerLinkActive="active" routerLink="/app/administration-extranet/comptabilite">
                                          <mat-icon>feed</mat-icon>
                                          <span class="span-a">&nbsp;&nbsp;&nbsp;Gestion des coordonnées bancaires</span>
                                    </a>  
                                    <br>
                                    
                                    <a routerLinkActive="active" routerLink="/app/compta/statistique">
                                          <mat-icon>
                                                <span class="material-symbols-outlined">
                                                      trending_up
                                                </span>
                                          </mat-icon>
                                          <span class="span-a">&nbsp;&nbsp;&nbsp;Statistiques</span>
                                    </a>  
                              </mat-card-actions>
                        </mat-card>

                    <!-- Suspensions -->
                    <mat-card appearance="outlined" *ngIf="utl_profil.droits_utilisateur.droit_module_suspension"
                    class="card">
                    <div class="header">
                          <mat-icon color="primary" class="header-image">no_accounts</mat-icon>
                          <br><span class="span-title">Suspensions</span>
                    </div>
                    <br>
                    <mat-card-actions class="actions">
                          <a routerLinkActive="active" routerLink="/app/suspension/type">
                                <mat-icon>format_list_numbered</mat-icon>
                                <span class="span-a">&nbsp;&nbsp;&nbsp;Gestion des types de suspension</span>
                          </a>
                    </mat-card-actions>
                    
                    <mat-card-actions class="actions">
                        <a routerLinkActive="active" routerLink="/app/suspension">
                              <mat-icon>no_accounts</mat-icon>
                              <span class="span-a">&nbsp;&nbsp;&nbsp;Gestion des suspensions</span>
                        </a>
                  </mat-card-actions>
              </mat-card>

            <!-- Administration ADELA  -->
            <mat-card appearance="outlined" *ngIf="utl_profil.droits_utilisateur.droit_module_admin_adela" class="card">
            <div class="header">
                  <mat-icon color="primary" class="header-image">invert_colors</mat-icon>
                  <br><span class="span-title">Administration ADELA</span>
            </div>
            <br>
            <mat-card-actions class="actions">
                  <div>
                        <a routerLinkActive="active" routerLink="/app/adela/composantes">
                              <mat-icon ><span class="material-symbols-outlined">
                                    view_compact_alt
                                    </span></mat-icon>
                              <span class="span-a">&nbsp;&nbsp;&nbsp;Gestion des composants</span>
                        </a>
                        <br>
                  </div>
                  <div>
                        <a routerLinkActive="active" routerLink="/app/administration-extranet/prestations">
                              <mat-icon>handyman</mat-icon>
                              <span class="span-a">&nbsp;&nbsp;&nbsp;Gestion des prestations</span>
                        </a>
                        <br>
                  </div>
                  <div>
                        <a routerLinkActive="active" routerLink="/app/articles">
                              <mat-icon>qr_code</mat-icon>
                              <span class="span-a">&nbsp;&nbsp;&nbsp;Gestion des articles</span>
                        </a>
                        <br>
                  </div>
                  <div>
                        <a routerLinkActive="active" routerLink="/app/adela/etiquette">
                              <mat-icon>calendar_month</mat-icon>
                              <span class="span-a">&nbsp;&nbsp;&nbsp;Historique des étiquettes</span>
                        </a>
                        <br>
                  </div>
                  <div>
                        <a routerLinkActive="active" routerLink="/app/adela/impression-etiquette">
                              <mat-icon>print</mat-icon>
                              <span class="span-a">&nbsp;&nbsp;&nbsp;Impression des étiquettes</span>
                        </a>
                        <br>
                  </div>
                  <div>
                        <a routerLinkActive="active" routerLink="/app/phrasier">
                              <mat-icon>description</mat-icon>
                              <span class="span-a">&nbsp;&nbsp;&nbsp;Gestion des phrasiers</span>
                        </a>
                        <br>
                  </div>

            </mat-card-actions>
      </mat-card>
              
              <!-- Gestion des emails -->
              
              <mat-card appearance="outlined" class="card" *ngIf="utl_profil.droits_utilisateur.droit_module_gestion_emails">
                  <div class="header">
                        <mat-icon color="primary" class="header-image">email</mat-icon>
                        <br><span class="span-title">Gestion des emails</span>
                  </div>
                  <br>
                  <mat-card-actions class="actions">
                        
                        <div>
                              <a routerLinkActive="active" routerLink="/app/administration-extranet/emails">
                                    <mat-icon>mail</mat-icon>
                                    <span class="span-a">&nbsp;&nbsp;&nbsp;Paramétrage des emails</span>
                              </a>
                              <br>
                        </div> 

                        <div *ngIf="utl_profil.droits_utilisateur.est_admin">
                              <a routerLinkActive="active" routerLink="/app/administration-extranet/mails">
                                    <mat-icon>visibility</mat-icon>
                                    <span class="span-a">&nbsp;&nbsp;&nbsp;Consultation des emails</span>
                              </a>
                              <br>
                        </div>    

                  </mat-card-actions>
            </mat-card>

            <!-- Gestion des droits -->
              
            <mat-card appearance="outlined" class="card" *ngIf="utl_profil.droits_utilisateur.est_admin">
                  <div class="header">
                        <mat-icon color="primary" class="header-image">groups</mat-icon>
                        <br><span class="span-title">Gestion des Groupes/Droits</span>
                  </div>
                  <br>
                  <mat-card-actions class="actions">

                        <div *ngIf="utl_profil.droits_utilisateur.est_admin">
                              <a routerLinkActive="active" routerLink="/app/groupe">
                                    <mat-icon>groups</mat-icon>
                                    <span class="span-a">&nbsp;&nbsp;&nbsp;Liens entre types d'entités et groupes</span>
                              </a>
                              <br>
                        </div> 

                        <div *ngIf="utl_profil.droits_utilisateur.est_admin">
                              <a routerLinkActive="active" routerLink="/app/droit">
                                    <mat-icon>
                                          <span class="material-symbols-outlined">passkey</span>
                                      </mat-icon>
                                    <span class="span-a">&nbsp;&nbsp;&nbsp;Gestion des droits</span>
                              </a>
                              <br>
                        </div> 


                  </mat-card-actions>
            </mat-card>

            <mat-card appearance="outlined" class="card" *ngIf="utl_profil.droits_utilisateur.droit_module_admin_wsa">
                  <div class="header">
                        <mat-icon color="primary" class="header-image">
                              settings
                        </mat-icon>
                        <br><span class="span-title">Administration WSA</span>
                  </div>
                  <br>
                  <mat-card-actions class="actions">

                        <div>
                              <a routerLinkActive="active" routerLink="/app/wsa/cabinets">
                                    <mat-icon><span class="material-symbols-outlined">
                                          apartment
                                          </span></mat-icon>
                                    <span class="span-a">&nbsp;&nbsp;&nbsp;Redirection de cabinets</span>
                              </a>
                              <br>

                              <a routerLinkActive="active" routerLink="/app/wsa/missions">
                                    <mat-icon><span class="material-symbols-outlined">
                                          work
                                          </span></mat-icon>
                                    <span class="span-a">&nbsp;&nbsp;&nbsp;Redirection de  missions</span>
                              </a>
                              <br>

                              <a routerLinkActive="active" routerLink="/app/wsa/redirections">
                                    <mat-icon><span class="material-symbols-outlined">
                                          calendar_month
                                          </span></mat-icon>
                                    <span class="span-a">&nbsp;&nbsp;&nbsp;Historique des redirections</span>
                              </a>
                              <br>
                        </div> 

                  </mat-card-actions>
            </mat-card>

                  </div>
            </ng-template>
      </div>
</div>