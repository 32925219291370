import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { FiltreRecherchePrelevement } from 'src/app/interfaces/facture/filtre-recherche-prelevement';
import { ApiFactureService } from 'src/app/services/api-facture.service';
import { ListeHistoriquePrelevementsComponent } from '../historique-prelevements/liste-historique-prelevements/liste-historique-prelevements.component';
import { ListeInformationsBancairesComponent } from '../informations-bancaires/liste-informations-bancaires/liste-informations-bancaires.component';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'app-home-comptabilite',
    templateUrl: './home-comptabilite.component.html',
    styleUrls: ['./home-comptabilite.component.scss'],
    standalone: true,
    imports: [MatTabsModule, MatIconModule, ListeInformationsBancairesComponent, ListeHistoriquePrelevementsComponent]
})
export class HomeComptabiliteComponent implements OnInit, OnDestroy {

  selected = new FormControl(0);
  sub_filtre: Subscription = new Subscription();
  @ViewChild("tab", { static: false }) tab!: MatTabGroup;

  constructor(private routed    : ActivatedRoute
            , private apiFacture: ApiFactureService) { }

  ngOnInit(): void {

    this.routed.fragment
      .subscribe(tabsToSelect => {
        // console.info(data)
        if ( tabsToSelect != undefined ) {
          this.selected.setValue(+tabsToSelect)
        }
      });

    this.sub_filtre = this.apiFacture.filtre_recherche_prelevement.subscribe({
      next: (filtre: FiltreRecherchePrelevement) => {
        // Switch de tab sur historique prélèvements
        const tabGroup = this.tab;
        if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;
        const tabCount = tabGroup._tabs.length;
        let selectedIndex: number = 0;
        if(tabGroup.selectedIndex != null) { selectedIndex = tabGroup.selectedIndex }      
        tabGroup.selectedIndex = (selectedIndex + 1) % tabCount;
      }
    });
  }

  ngOnDestroy(): void {
    this.sub_filtre.unsubscribe();
  }

}
