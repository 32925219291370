export interface Adresse {
    id?             : number,
    ligne1          : string,
    ligne2?         : string,
    ligne3?         : string,
    cp              : string,
    ville           : string
    relAdr?         : RelAdresse,
    contact?        : string,
    nom?            : string,
    action?         : string
  }
  
  export interface RelAdresse{
    id?           : number,
    adr_id?       : number,
    tbl_id?       : number,
    type          : string,
    fct           : string
  }
  export const AdresseInit : Adresse = {
    id              : 0 ,
    ligne1          : '',
    ligne2          : '',
    ligne3          : '',
    cp              : '',
    ville           : '',
    contact         : ''
  }
  
  export interface TypeAdresse{
    type    : string,
    libelle : string
  }
  
  export const TypesAdresse = [
      {'type' : 'LIV', 'libelle' : 'Adresse de livraison'}
    , {'type' : 'FAC', 'libelle' : 'Adresse de facturation'}
  ]
  
  
  export const CST_TYPE_ADR_CABINET = 'CABINET'
