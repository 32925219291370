import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { PIECEJOINTE } from 'src/app/interfaces/piece-jointe';
import { PieceJointeService } from 'src/app/services/piece-jointe.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, DatePipe } from '@angular/common';
import { MatTableResponsiveLargeDirective } from '../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';

@Component({
    selector: 'app-piece-jointe',
    templateUrl: './piece-jointe.component.html',
    styleUrls: ['./piece-jointe.component.scss'],
    standalone: true,
    imports: [MatTableModule, MatTableResponsiveLargeDirective, MatCheckboxModule, NgIf, MatButtonModule, MatIconModule, MatPaginatorModule, DatePipe]
})
export class PieceJointeComponent implements OnInit {

  @Input() pjs : PIECEJOINTE[]=[]
  @Output() pjsToDelete : EventEmitter<PIECEJOINTE[]> = new EventEmitter<PIECEJOINTE[]>()

  dataSourceMatTable  : MatTableDataSource<PIECEJOINTE> = new MatTableDataSource<PIECEJOINTE>();
  displayedColumns    : Array<String> = [ 'supprimer', 'nom', 'date', 'actions'];
  columnNames = ['supprimer', 'nom', 'date', 'actions'];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;

  selection = new SelectionModel<PIECEJOINTE>(true, []);

  constructor( private apiPJ:PieceJointeService) { }

  ngOnInit(): void {}

  download(id:number,nom:string){
    this.apiPJ.downloadPieceJointe(id,nom)
  }

  ngOnChanges(){
       
    this.dataSourceMatTable.data = this.pjs
    this.dataSourceMatTable.paginator = this.paginator
  }
  
  changeSelection($event:MatCheckboxChange,pj:PIECEJOINTE){
    $event ? this.selection.toggle(pj) : null
    
    this.pjs.map((pj:PIECEJOINTE) => pj.aSupprimer = false)
    
    this.selection.selected.forEach(
      (pjSelected:PIECEJOINTE)=> this.pjs.map((pj:PIECEJOINTE)=> pj.id === pjSelected.id? pj.aSupprimer = true : null)
    )    
    this.pjsToDelete.next(this.pjs)
  }
}
