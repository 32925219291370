<table mat-table matTableResponsiveLarge [dataSource]="dataSourceMatTable" class="table-primary" class="table-primary" >                    
    <!-- A supprimer -->
    <ng-container matColumnDef="supprimer">
        <th mat-header-cell *matHeaderCellDef class="derniere-colonne" style="text-align: left;">A supprimer</th>
        <td mat-cell *matCellDef="let pj" class="center">
            <mat-checkbox (click)="$event.stopPropagation()"
                (change)="changeSelection($event,pj)"
                [checked]="selection.isSelected(pj)"
                >
            </mat-checkbox>                                                
        </td>
    </ng-container>

    <!-- Nom -->
    <ng-container matColumnDef="nom">
        <th mat-header-cell *matHeaderCellDef  class="colonne-invisible" style="padding-right: 8px; padding-left: 8px;">Nom</th>
        <td mat-cell *matCellDef="let pj" title="{{ pj.nom }}" 
            class="colonne-invisible">
            {{ pj.nom }}
        </td>
    </ng-container>
    
    
    <!-- Date Column -->
    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef  style="width: 150px;">Date</th>
        <td mat-cell *matCellDef="let pj">
            <div *ngIf="pj.date >= '19000101'">{{pj.date | date: 'dd/MM/yyyy HH:mm:ss'}}</div>
            <div *ngIf="pj.date < '19000101'">__/__/____</div>                            
        </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="derniere-colonne" style="text-align: right;">Télécharger</th>
        <td mat-cell *matCellDef="let pj" class="colonne-module" style="text-align: right;">
            <button mat-icon-button class="button-etat" color="primary"
            style="width: 48px; height: 48px;"
            (click)="download(pj.id,pj.nom)">
            <mat-icon color="primary">download</mat-icon>
        </button>                                               
        </td>
    </ng-container>
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>                    
</table>

<div class="div-container-between" style="align-items: center;background-color:white">
    <mat-paginator [pageSizeOptions]="[10,25,50]" showFirstLastButtons  aria-label="Nombre de lignes visibles..."></mat-paginator>
</div>                