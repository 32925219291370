import { Component, EventEmitter, Input, OnChanges, OnInit, Output, inject } from '@angular/core';
import { FormBuilder, FormControl, Validators, ReactiveFormsModule, NgModel, FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ActivatedRoute } from '@angular/router';
import { EntiteAccesConcentrateur } from 'src/app/interfaces/profil-user/entite-acces-concentrateur';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { CheckValidatorService } from 'src/app/services/tools/check-validator.service';
import { DialogEntiteAccesConcentrateurValidationComponent } from '../dialog-entite-acces-concentrateur-validation/dialog-entite-acces-concentrateur-validation.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { ApiCleService } from 'src/app/services/api-cle.service';
import { ClePublique } from 'src/app/interfaces/cle-publique';

@Component({
    selector: 'app-entite-acces-concentrateur',
    templateUrl: './entite-acces-concentrateur.component.html',
    styleUrls: ['./entite-acces-concentrateur.component.scss'],
    standalone: true,
    imports: [ReactiveFormsModule, MatCardModule, MatIconModule, MatSlideToggleModule, MatFormFieldModule, MatInputModule, MatTooltipModule
              , NgIf, MatButtonModule, MatProgressSpinnerModule, FormsModule]
})
export class EntiteAccesConcentrateurComponent implements OnInit, OnChanges {

  waiting_save_acces            : boolean = false;
  waiting_save_data             : boolean = false;
  decryptedPublicKey            : string  = ''

  @Input() acces_concentrateur  : EntiteAccesConcentrateur = <EntiteAccesConcentrateur>{};
  @Input() indice               : string = '';
  @Input() habilitation         : string= '';

  @Output() cellClicked = new EventEmitter<boolean>();
  
  utl_id                : number = 0;
  ent_id                : number = 0;
  profilUtilisateur     : ProfilUtilisateur = <ProfilUtilisateur>{};

  accesConcentrateurForm = this.fb.group({
    id                  : [0],
		habilitation        : [''],
		indice              : [''],
		mot_de_passe        : [''],
		date_creation       : new FormControl(),
		date_modification   : new FormControl(),
		actif               : [{value: false, disabled : this.waiting_save_acces}],
		habilitation_indice : [''],
    mailAuto            : new FormControl(),
    mailConcentrateur_old   : ['', [Validators.required, CheckValidatorService.checkMail]],
    mailConcentrateur   : new FormControl('',Validators.required)
  });
  
  get reference()               { return this.accesConcentrateurForm.get('mailConcentrateur'); }

  // US 346 : afficher/masquer le mot de passe
  hidePassword : boolean = true;
  
  apiCle        = inject(ApiCleService)
  clePublique     : ClePublique|null  = null
  cleSecret       : string            = ''
  estAdmin        : boolean = this.apiAuth.userConnect.droits_utilisateur.est_admin

  constructor(private apiEntite   : ApiEntiteService
            , private route       : ActivatedRoute
            , private apiAuth     : ApiAuthentificationService
            , public dialog       : MatDialog
            , private toast       : PersoSnackbarService
            , private fb          : FormBuilder) { }

  ngOnInit(): void {
    // On force le reset des valeurs par défaut, car lorsqu'on recharge le component entite-acces-concentrateur,    
    // des valeurs peuvent déjà être configuré par une "session" précédente (session dans le sens appel depuis une autre page)
    // mais ne pas être réinitialisé car le component ne refait pas la déclaration des variables puisqu'il a déjà été initialisé par la "session" précédente    
    this.waiting_save_acces           = false;
    this.ent_id = this.route.snapshot.params['id'];
    this.profilUtilisateur   = this.apiAuth.userConnect;
    this.utl_id = this.profilUtilisateur.id;

    this.accesConcentrateurForm.patchValue({
      id                  : 0,
      mot_de_passe        : '',
      date_creation       : null,
      date_modification   : null,
      actif               : false,
      habilitation_indice : '',
      mailAuto            : false,
      mailConcentrateur   : '',
    });
           
    this.accesConcentrateurForm.controls['mailConcentrateur'].disable();
    this.loadDataConcentrateur();
    this.apiCle.getCle(this.ent_id).subscribe(
      (cle:ClePublique) =>{
        this.clePublique        = cle
        if (this.clePublique) {
          this.decryptedPublicKey = this.apiAuth.decrypteCleApiPublique(this.clePublique.clientSecret)
        }
      }
    )
  }

  ngOnChanges(): void {
    this.accesConcentrateurForm.patchValue({
      habilitation : this.habilitation,
      indice: this.indice
    });
  }
  
  // chargement des information du concentrateur lié à l'entité en cours de consultation
  private loadDataConcentrateur() {
    
    if(this.utl_id != undefined && this.utl_id > 0 && this.ent_id != undefined && this.ent_id > 0) {
      
      this.apiEntite.getAccesConcentrateur(this.utl_id, this.ent_id)
      .subscribe(
        (data: EntiteAccesConcentrateur) => {
          
          if(data.id > 0) {    
            
            this.accesConcentrateurForm.patchValue({
              id                  : data.id,
              habilitation        : data.habilitation,
              indice              : data.indice,
              mot_de_passe        : data.mot_de_passe,
              date_creation       : data.date_creation,
              date_modification   : data.date_modification,
              actif               : data.actif,
              habilitation_indice : data.habilitation_indice,
              mailAuto            : data.mailAuto,
              mailConcentrateur   : data.mailConcentrateur
            });

            if(data.actif) { 
              this.accesConcentrateurForm.controls['mailConcentrateur'].enable();
            } else {            
              this.accesConcentrateurForm.controls['mailConcentrateur'].disable();
            }
          }          
        },
        err => {
          console.log(err.message);
        }
      )
    }    
  }

  saveAccesConcentrateur() {  
    const acces = this.accesConcentrateurForm.controls.actif.value
    this.waiting_save_acces = true;       
    this.acces_concentrateur.id                 = this.accesConcentrateurForm.controls.id.value? this.accesConcentrateurForm.controls.id.value:0;
    this.acces_concentrateur.habilitation       = this.accesConcentrateurForm.controls.habilitation.value? this.accesConcentrateurForm.controls.habilitation.value : '';
    this.acces_concentrateur.indice             = this.accesConcentrateurForm.controls.indice.value? this.accesConcentrateurForm.controls.indice.value : '';
    this.acces_concentrateur.actif              = acces!;        
    this.acces_concentrateur.mot_de_passe       = this.accesConcentrateurForm.controls.mot_de_passe.value? this.accesConcentrateurForm.controls.mot_de_passe.value :'';
    this.acces_concentrateur.date_creation      = this.accesConcentrateurForm.controls.date_creation.value;
    this.acces_concentrateur.date_modification  = this.accesConcentrateurForm.controls.date_modification.value;
    this.acces_concentrateur.mailAuto           = ( acces === false ? false : this.accesConcentrateurForm.controls.mailAuto.value );
    this.acces_concentrateur.mailConcentrateur  = this.accesConcentrateurForm.controls.mailConcentrateur.value? this.accesConcentrateurForm.controls.mailConcentrateur.value:'';

    if(this.acces_concentrateur.actif == false) {
      // Confirmation de la désactivation
      const dialogRef = this.dialog.open(DialogEntiteAccesConcentrateurValidationComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result == true){
          this.apiEntite.postAccesConcentrateur(this.acces_concentrateur, this.utl_id, this.ent_id)
          .subscribe(
            (data: EntiteAccesConcentrateur) => {
              this.acces_concentrateur = data;
              
              this.accesConcentrateurForm.patchValue({
                id                  : data.id,
                habilitation        : data.habilitation,
                indice              : data.indice,
                mot_de_passe        : data.mot_de_passe,
                date_creation       : data.date_creation,
                date_modification   : data.date_modification,
                actif               : data.actif,
                habilitation_indice : data.habilitation_indice,
                mailAuto            : data.mailAuto,
                mailConcentrateur   : data.mailConcentrateur
              });
              
              if(data.actif) { 
                this.accesConcentrateurForm.controls['mailConcentrateur'].enable();
              } else {            
                this.accesConcentrateurForm.controls['mailConcentrateur'].disable();
              }

              this.toast.showInfo("Accès Concentrateur désactivé");  
              this.waiting_save_acces = false;     
              this.cellClicked.emit(acces!);
            },
            (err: any) => {
              this.accesConcentrateurForm.controls['actif'].setValue(true);          
              '/!\\ error postAccesConcentrateur: ' + console.log(err);    
              this.waiting_save_acces = false;  
              this.cellClicked.emit(acces!);    
            }
          );
        } else {
          this.accesConcentrateurForm.controls['actif'].setValue(true);
          this.waiting_save_acces = false;
          this.cellClicked.emit(acces!);
        }
      });
    } else {
      this.apiEntite.postAccesConcentrateur(this.acces_concentrateur, this.utl_id, this.ent_id)
      .subscribe(
        (data: EntiteAccesConcentrateur) => {
          this.acces_concentrateur = data;
          
          this.accesConcentrateurForm.patchValue({
            id                  : data.id,
            habilitation        : data.habilitation,
            indice              : data.indice,
            mot_de_passe        : data.mot_de_passe,
            date_creation       : data.date_creation,
            date_modification   : data.date_modification,
            actif               : data.actif,
            habilitation_indice : data.habilitation_indice,
            mailAuto            : data.mailAuto,
            mailConcentrateur   : data.mailConcentrateur
          });        
                 
          if(data.actif) { 
            this.accesConcentrateurForm.controls['mailConcentrateur'].enable();
          } else {            
            this.accesConcentrateurForm.controls['mailConcentrateur'].disable();
          }

          this.toast.validate("Accès Concentrateur activé");      
          this.waiting_save_acces = false; 
          this.cellClicked.emit(acces!);
        },
        (err: any) => {
          this.accesConcentrateurForm.controls['actif'].setValue(false);          
          '/!\\ error postAccesConcentrateur: ' + console.log(err);
          this.waiting_save_acces = false;     
          this.cellClicked.emit(acces!);     
        }
      );
    }      
  }

  onToggleMailConcentrateur($e: MatSlideToggleChange) {        
    this.saveDataConcentrateur();        
  }

  saveDataConcentrateur() {
    this.waiting_save_data = true; 
    this.apiEntite.postAccesConcentrateur(this.accesConcentrateurForm.value as EntiteAccesConcentrateur, this.utl_id, this.ent_id)
    .subscribe(
      (data: EntiteAccesConcentrateur) => {

        this.toast.validate("Enregistrement effectué");      
        this.waiting_save_data = false; 
      }
      , err => {
        this.toast.showInfo(err.message);
        this.waiting_save_data = false; 
      }
      , () => {
        this.waiting_save_data = false; 
      }
    )
  }
  changeActive(active:boolean){
    const cleAActualiser:ClePublique = {'active':active,'idEntite' : this.ent_id}
    
    this.apiCle.activeDesactiveCle(cleAActualiser).subscribe(
      (cle:ClePublique)=>{
        this.clePublique = cle
        this.decryptedPublicKey = this.apiAuth.decrypteCleApiPublique(this.clePublique.clientSecret)
      }
    )
  }

  regenererCle(){
    this.apiCle.regenererCle(this.clePublique!).subscribe(
      (cle:ClePublique)=> {
        this.clePublique = cle
        this.decryptedPublicKey = this.apiAuth.decrypteCleApiPublique(this.clePublique.clientSecret)
        this.toast.showInfo('Votre clé a bien été régénérée')
      },
      (error)=>{
        this.toast.showError('Votre clé n\'a pas pu être régénérée')
        console.log(error.erreur);
        
      }
    )
  }
}
