import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { RelationUtilisateurEntite } from '../interfaces/utilisateur-entite/relation-utilisateur-entite';
import { ApiAuthentificationService } from '../services/api-authentification.service';

@Injectable({
  providedIn: 'root'
})
export class ProtectExtranetModificationInformationEntiteGuard  {
  
  ent_id: number = 0; // Paramètre d'entrée : détermine à quelle entité l'utilisateur souhaite accéder

  constructor(private apiAuth : ApiAuthentificationService
            , private router: Router){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const tree: UrlTree = this.router.parseUrl("/app/home");

    // Cas particulier où l'on souhaite accéder à /app/entite?habilitation=...
    // On ne teste pas ici les relations, car on va déjà rechercher dans le component si l'entité existe dans notre base de données
    // On route vers /app/entite/:id si on la trouve, sinon on redirige vers la page d'accueil
    if(route.queryParams.action == 'nouveau_collaborateur_depuis_concentrateur' && route.queryParams.habilitation != undefined && route.queryParams.habilitation != '') {
      return true;
    }
    
    if(this.apiAuth.userConnect.droits_utilisateur.droit_module_admin_extranet) {
      return true;
    }

    this.ent_id = route.params['id'];        

    // Objectif du guard : rechercher si l'utilisateur à une relation utilisateur / entité correspondant à l'entité dont il souhaite accéder
    let local_relUserEnt: RelationUtilisateurEntite = <RelationUtilisateurEntite>{};

    // On recherche dans les relations utilisateur / entité de l'utilisateur connecté
    this.apiAuth.relUserEnt.find(relUserEnt => {

      // Si on a un match + entité inscrite : on autorise l'accès
      if(relUserEnt.entite_id == this.ent_id && relUserEnt.entite.EstInscrit == true) {
        local_relUserEnt = relUserEnt;
      }

    });

    // accès dédié à l'extranet
    if ( this.apiAuth.root === this.apiAuth.CONST_URL_ROOT_SERVICE ) {
      return false
    }
      
    // Si aucun match : on refuse l'accès => direction la page d'accueil
    if(local_relUserEnt.entite_id <= 0 || local_relUserEnt.entite_id == undefined) {
      return tree;
    }

    return true;
  } 
}