<mat-card appearance="outlined" >
    <mat-card-header>
      <mat-card-title>{{mails.length}} mail<span *ngIf="mails.length >1">s</span></mat-card-title>
    </mat-card-header>

  <div class="container" >
      <table mat-table [dataSource]="dataSource" matSort >
    
        <!-- Statut Column -->
        <ng-container matColumnDef="ml_statut">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Statut </th>
          <td mat-cell *matCellDef="let element"> {{ getStatus(element.ml_statut) }} </td>
        </ng-container>
    
        <!-- Type d'envoi Column -->
        <ng-container matColumnDef="ml_type_envoi">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Type d'envoi </th>
          <td mat-cell *matCellDef="let element"> {{ getTypeEnvoi(element.ml_type_envoi) }} </td>
        </ng-container>
    
        <!-- Date d'envoi Column -->
        <ng-container matColumnDef="ml_date_demande">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Demandé le </th>
          <!-- <div *ngIf="facture.dateCreation >= '19000101'">{{facture.dateCreation | date: 'dd/MM/yyyy'}}</div> -->
          <!-- <div *ngIf="facture.dateCreation < '19000101'">__/__/____</div> -->
          <td mat-cell *matCellDef="let element"> {{ element.ml_date_demande|date: 'dd/MM/yyyy' }} </td>
        </ng-container>
    
        <!-- Destinataire Column -->
        <ng-container matColumnDef="ml_destinataire">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Destinataire </th>
          <td mat-cell *matCellDef="let element"> {{ element.ml_destinataire }} </td>
        </ng-container>
    
        <!-- Sujet Column -->
        <ng-container matColumnDef="ml_sujet">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Sujet </th>
          <td mat-cell *matCellDef="let element"> {{ element.ml_sujet }} </td>
        </ng-container>
    
        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Actions </th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="selectMail(element)">
              <mat-icon>visibility</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Sélection -->
        <ng-container matColumnDef="selection">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox 
              [checked] = "isSelected(element)" 
              (change)  = "toggleSelection(element)"
              >

            </mat-checkbox>
          </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    
      <mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
    </div>

  <mat-card-footer align="end">
    <button 
      mat-raised-button
      color       = "primary"
      [disabled]  ="!isAnyMailSelected()" 
      (click)     = "resetSelectedMails()">
      Renvoyer les mails sélectionnés</button>

  </mat-card-footer>
</mat-card>