import { enableProdMode, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { GeoApiGouvAddressModule } from '@placeme/ngx-geo-api-gouv-address';
import { MatTableExporterModule } from 'mat-table-exporter';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatTableFilterModule } from 'mat-table-filter';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app/app-routing.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { TelephoneDirective } from './app/directive/telephone.directive';
import { Title, BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { TitleCasePipe, DatePipe } from '@angular/common';
import { CustomMatPaginatorIntl } from './app/components/custom/custom-mat-paginator-intl';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBar } from '@angular/material/snack-bar';
import { Interceptor } from './app/interceptor/http-interceptor.interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

if (environment.production) {
  enableProdMode();
}
registerLocaleData(localeFr);

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, AppRoutingModule, ReactiveFormsModule, FormsModule, MatSlideToggleModule, MatDialogModule
                            ,MatTableFilterModule, NgxChartsModule, ImageCropperModule, MatTableExporterModule, CKEditorModule
                            , GeoApiGouvAddressModule.forRoot(), ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        })),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Interceptor,
            multi: true
        },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: { duration: 2500 }
        },
        {
            provide: LOCALE_ID,
            useValue: 'fr'
        },
        {
            provide: MatPaginatorIntl,
            useClass: CustomMatPaginatorIntl
        },
        TitleCasePipe,
        Title,
        DatePipe,
        TelephoneDirective,
        MatSnackBar,
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline',subscriptSizing:'dynamic' } },
        // {
        //     provide: MAT_DIALOG_SCROLL_STRATEGY,
        //     useFactory: (scrollStrategyOptions: ScrollStrategyOptions) => scrollStrategyOptions.noop,
        //     deps: [ScrollStrategyOptions]
        // },
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi())
    ]
})
  .catch(err => console.error(err));
