<div class="spinner-container" *ngIf="waiting_save">
    <mat-spinner diameter="24"></mat-spinner>
</div>

<form [formGroup]="entiteForm">
    <div *ngIf="entiteForm.controls.id.value == undefined">
        <p class="label-error">Aucune entité ne correspond à cet identifiant.</p>
    </div>

    <mat-card appearance="outlined">
        <mat-card-title>
            <mat-icon>feed</mat-icon>
            Informations générales
        </mat-card-title>

        <mat-card-content  class=".mat-card-content">
            <mat-form-field>
                <mat-label for="raison_sociale">Raison Sociale</mat-label>
                <input matInput required type="text" placeholder="Raison Sociale" formControlName="raison_sociale"
                    id="raison_sociale" name="raison_sociale" oninput="this.value = this.value.toUpperCase()"
                    style="font-size: 20px;">
                <mat-error>La raison sociale est obligatoire</mat-error>
            </mat-form-field>

            
            <div class="div-container-evenly">
                <mat-form-field>
                    <mat-label for="id_type_ent">Type entité</mat-label>
                    <mat-select (selectionChange)="selectionneTypeEntite($event)" name="typeEntite"
                    formControlName="id_type_entite" value="typeEntiteSelected" [disabled]="disableSelect.value!" required>
                    <mat-option *ngFor="let type of typeEntites" [value]="type.id">{{ type.libelle }}
                    </mat-option>
                    </mat-select>
                </mat-form-field>
            
                <mat-form-field style="width: 20%;">
                    <mat-label for="habilitation">Contact N°</mat-label>
                    <input matInput type="text" formControlName="numero_client" placeholder="Numéro de contact"
                        name="numero_client" required>
                    <mat-hint>Votre numéro de référence</mat-hint>
                </mat-form-field>
            </div>
            <app-adresse [adresse]="adresseEntite" controlKey="adresse"></app-adresse>

            <mat-form-field>
                <mat-label for="telephone">Téléphone</mat-label>
                <input matInput type="text" formControlName="telephone" placeholder="Numéro de téléphone" appTelephone>
                <mat-hint>Numéro de téléphone sans espace ni ponctuation</mat-hint>
                <mat-error *ngIf="entiteForm.controls.telephone?.invalid && (entiteForm.controls.telephone?.dirty || entiteForm.controls.telephone?.touched)">{{
                    entiteForm.controls.telephone?.errors?.erreur}}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label for="mail_contact">Mail Contact</mat-label>
                <input matInput type="email" formControlName="mail_contact" placeholder="Adresse email de contact">
                <mat-hint>Mail de contact pour les retours d'information</mat-hint>
                <mat-error *ngIf="entiteForm.controls.mail_contact?.invalid && (entiteForm.controls.mail_contact?.dirty || entiteForm.controls.mail_contact?.touched)">{{
                    entiteForm.controls.mail_contact?.errors?.erreur}}</mat-error>
            </mat-form-field>

            <div class="div-button-right">
                <button mat-raised-button *ngIf="isDirigeant" color="primary" type="submit"
                    [disabled]="(entiteForm.pristine && !dirty) || (!entiteForm.valid && entiteForm.dirty) || (dirty && invalidAdresse) || waiting_save"
                    (click)="enregistreEntite()">
                    <span>Enregistrer</span>
                </button>
            </div>
        </mat-card-content>
    </mat-card>
</form>