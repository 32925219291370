import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { McelGlobale } from 'src/app/interfaces/statistiques/mcel-globale';
import { ApiStatistiqueService } from 'src/app/services/api-statistique.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { CheckValidatorService } from 'src/app/services/tools/check-validator.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatChipEvent, MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { TableMcelComponent } from '../table-mcel/table-mcel.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';

export interface filtre_type_recherche_mcel{
  code: string,
  libelle: string
};

export interface CodePostal{code:string};

@Component({
    selector: 'app-filtre-mcel',
    templateUrl: './filtre-mcel.component.html',
    styleUrls: ['./filtre-mcel.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatExpansionModule, MatIconModule, MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, NgIf, MatChipsModule, MatButtonModule, MatProgressSpinnerModule, TableMcelComponent]
})
export class FiltreMcelComponent implements OnInit {
  
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  selectable                  = true;
  removable                   = true;
  addOnBlur                   = true;

  CONST_FILTRE_3_MOIS = 'MCEL_3_MOIS' as const;
  CONST_FILTRE_6_MOIS = 'MCEL_6_MOIS' as const;
  CONST_FILTRE_ANNEE  = 'MCEL_ANNEE'  as const;
  
  // codePostaux         :CodePostal[]=[];
  listCodePostaux:string='';
  // Gestion de l'affichage
  waitingResearch       : boolean = false;
  expandPannel          : boolean = true;

  // Formulaire de filtrage
  FiltreFormGroup: FormGroup;
  
  types_recherche: Array<filtre_type_recherche_mcel> = [
    { code: this.CONST_FILTRE_3_MOIS, libelle: '3 derniers mois'},
    { code: this.CONST_FILTRE_6_MOIS, libelle: '6 derniers mois'},
    { code: this.CONST_FILTRE_ANNEE, libelle: 'Année en cours'}
  ];

  // Données
  stats_mcel_globale: Array<McelGlobale> = [];

  // get code_postal() { return this.FiltreFormGroup.get('code_postal'); }
  get type_recherche() { return this.FiltreFormGroup.get('type_recherche'); }  

  constructor(private _fb   : FormBuilder
            , private apiStatistique: ApiStatistiqueService
            , private toast         : PersoSnackbarService) {
    // Initialisation du formulaire de filtrage
    this.FiltreFormGroup = this._fb.group({
      codesPostaux   : this._fb.array([],Validators.required),
      type_recherche: ['', [Validators.required]]
    });
  }

  get cpControls() : FormArray{
    return this.FiltreFormGroup.controls['codesPostaux'] as FormArray
  }

  ngOnInit(): void {
    this.FiltreFormGroup.patchValue({
      code_postal   : '',
      type_recherche: ''
    });
  }

  public onSearchingStatistiques() {
    this.waitingResearch = true;

    this.stats_mcel_globale = [];

    this.apiStatistique.getMCELGlobal(this.cpControls.value.toString().replaceAll(',',';'), this.FiltreFormGroup.controls.type_recherche.value)
    .subscribe(
      (data: McelGlobale[]) => {
        
        this.stats_mcel_globale = data;
        this.toast.showInfo('Recherche terminée');
        this.waitingResearch = false;
        this.expandPannel = false;
        
      },
      (err: any) => {
        console.error('/!\ err: ', err.message);
        this.waitingResearch = false;
      }
    );
  }

  add(event: MatChipInputEvent): void {
    let value = event.value;

    // Add our post code
    if (value) {
      if (value.length > 5) {
        value = value.substring(0,5)
      }
      
      this.cpControls.push(this._fb.control(value));
      this.cpControls.updateValueAndValidity();
      
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  remove(codepostal: CodePostal): void {
    const index = this.cpControls.value.indexOf(codepostal);

    if (index >= 0) {
      this.cpControls.removeAt(index);
      this.cpControls.updateValueAndValidity();
    }
  }
}
