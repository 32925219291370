import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';

import { TableAdela } from 'src/app/interfaces/adela/table-adela';
import { Dossier } from 'src/app/interfaces/dossier/dossier';
import { ListeEtat } from 'src/app/interfaces/dossier/liste-etat';
import { Service } from 'src/app/interfaces/dossier/service';
import { TableauDossier } from 'src/app/interfaces/dossier/tableau-dossier';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';

import { NgFor, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { TypeElement } from 'src/app/interfaces/adela/resultat-analyse';
import { Etat } from 'src/app/interfaces/dossier/etat';
import { ApiAdcService } from 'src/app/services/api-adc.service';
import { ApiAdelaService } from 'src/app/services/api-adela.service';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiUtilitaireService } from 'src/app/services/tools/api-utilitaire.service';
import { ImmatDirective } from '../../../directive/immat.directive';
import { TableAdcComponent } from '../../adc/table-adc/table-adc.component';
import { AnalyseAdelaPoudreComponent } from '../../adela/analyse-adela-poudre/analyse-adela-poudre.component';
import { AnalyseAdelaComponent } from '../../adela/analyse-adela/analyse-adela.component';
import { TableAdelaComponent } from '../../adela/table-adela/table-adela.component';

@Component({
    selector: 'app-mes-dossiers',
    templateUrl: './mes-dossiers.component.html',
    styleUrls: ['./mes-dossiers.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatExpansionModule, MatIconModule, MatFormFieldModule, MatSelectModule
              , NgFor, MatOptionModule, NgIf, MatInputModule, ImmatDirective, MatButtonModule, MatProgressSpinnerModule
              , AnalyseAdelaPoudreComponent, AnalyseAdelaComponent, TableAdelaComponent, TableAdcComponent]
})
export class MesDossiersComponent implements OnInit {
  // constante en corélation avec les constante de l'API
  CONST_CODE_SERVICE_ADELA = 'ADELA'  as const;
  CONST_CODE_SERVICE_ADC   = 'ADC'    as const;

  // Gestion de l'affichage
  waitingResearch       : boolean = false;
  ExpandPannel          : boolean = true;
  adelaExpanded         : boolean = false;

  // Liste des interfaces pour les combos
  profilUtilisateur$  : ProfilUtilisateur = <ProfilUtilisateur>{};
  entites$            : Entite[] = [];
  services$           : Service[] = [];
  etats$              : Array<ListeEtat> = [];
  // Résultats
  dossiers$           : TableauDossier = <TableauDossier>{};
  dossier_ADC$        : Dossier[] = [];
  dossier_ADELA$      : TableAdela[] = [];
  typesElements       : TypeElement[] =[];
  // Formulaire de filtrage
  FiltreFormGroup     : any;
  servicesSelected    : Service[]=[]
  viewDossierPoudre   : boolean = false;
  viewDossierFluide   : boolean = false;
  idDossier           : number = 0;
  dossier?            : Dossier;
  viewTableADC        : boolean = false;
  viewTableADELA      : boolean = false;

  get immatriculation() { return this.FiltreFormGroup.get('immatriculation');}
  get reference()       { return this.FiltreFormGroup.get('reference'); }
  get etiquette()       { return this.FiltreFormGroup.get('etiquette'); }
  get entite()          { return this.FiltreFormGroup.get('entite'); }
  get dateDebut()       { return this.FiltreFormGroup.get('dateDebut'); }
  get dateFin()         { return this.FiltreFormGroup.get('dateFin'); }
  get services()        : FormArray { return <FormArray>this.FiltreFormGroup.get('tab_Service') as FormArray; }
  get etat()            : FormArray { return <FormArray>this.FiltreFormGroup.get('tab_Etat') as FormArray; }
  get typeElement()     : FormArray { return <FormArray>this.FiltreFormGroup.get('tab_TypeElement') as FormArray; }

  get filteredFormData() {
    if (this.FiltreFormGroup.value.tab_Service.length > 0) {
      return this.etats$.filter(etat => {
        for(let i = 0; i < this.FiltreFormGroup.value.tab_Service.length; i++) {
          if(etat.nomService === this.FiltreFormGroup.value.tab_Service[i].code) {
            return etat
          }
        }
        return undefined;
      });
    } else {
      return undefined;
    }
  }
  currentElement: string='' ;
  constructor(  private _fb         : FormBuilder
              , private authUser    : ApiAuthentificationService
              , private apiEntite   : ApiEntiteService
              , private apiADELA    : ApiAdelaService
              , private apiADC      : ApiAdcService
              , private apiUtilitaire: ApiUtilitaireService
              , private router      : Router
              , private toast       : PersoSnackbarService
              , public dialog       : MatDialog
              , private activatedRoute: ActivatedRoute
            ) { }
  
  
  ngOnInit(): void {
    this.activatedRoute.url.subscribe(url => {
      
      const elements    = this.router.url.split('/');
      this.currentElement = elements[elements.length - 1];
      
      switch (this.currentElement) {
        
        case 'mes-dossiers':
          this.viewDossierFluide = false
          this.viewDossierPoudre = false
          break;
        
          case 'adela':
          this.viewDossierFluide = true
          break;
        
          case 'adela-poudre':
          this.viewDossierPoudre = true
          break;

        default:
          break;
      }
    });
    


    this.profilUtilisateur$ = this.authUser.userConnect;
    
    this.apiADELA.getTypeElement().subscribe(
      (typesElements)=> {
        this.typesElements = typesElements
        this.typeElement.setValue(typesElements)
      }
    )
      
    // Initialisation du formulaire de filtrage
    this.FiltreFormGroup = this._fb.group({
      idUser          : [this.profilUtilisateur$.id],
      immatriculation : ['', [this.verificationFormatImmatriculation]],
      reference       : ['', [this.verificationFormatReference]],
      etiquette       : [''],
      entite          : [<Entite>{}],
      numClient       : [''],
      dateDebut       : ['', [this.verificationFormatDateDebut.bind(this)]],
      dateFin         : ['', [this.verificationFormatDateFin.bind(this)]],
      tab_Etat        : [Array<ListeEtat>(), [this.verificationFormatEtat.bind(this)]],
      tab_Service     : [Array<Service>(), [Validators.required, this.verificationFormatService.bind(this)]],
      tab_TypeElement : [Array<TypeElement>(),[Validators.required]]
    });

    // Récupération des paramètres du formulaire de filtrage
    this.chargeListeCabinet();    
    this.chargeListeService();
  }

  getDossier(dossier:TableAdela){  
    this.idDossier = dossier.id
    
     switch (dossier.typeElementCode) {
      case 1:
        this.viewDossierFluide = true
        this.router.navigate(['app/dossier/mes-dossiers/adela']);
        break;
      
      case 2:
        this.viewDossierPoudre = true
        this.router.navigate(['app/dossier/mes-dossiers/adela-poudre']);
        break;

      default:
        break;
    }
  }

 
  closeDossier(){
    this.viewDossierPoudre = false
    this.viewDossierFluide = false
  }

  adelaSelected(){
    return this.servicesSelected.find((service)=>service.code==="ADELA")
  }
  
  selectService(){
    this.viewTableADC   = false;
    this.viewTableADELA = false;

    this.servicesSelected.forEach((service)=>{
      switch (service.code) {
        case 'ADC':
          this.viewTableADC=true
          break;
        case 'ADELA':
          this.viewTableADELA=true
          break;
        default:
          break;
      }
    })
  }
  public chargeListeService() {
    this.apiUtilitaire.getService(this.apiUtilitaire.CONST_CODE_ENVIRONNEMENT_EXTRANET_FFEA)
    .subscribe(
      (data: Service[] ) => {
        this.services$ = data.filter((service) => service.actif == true);
        
        this.chargeListeEtat(); 

        this.defaultSelection();
        this.FiltreFormGroup.controls['tab_Service'].setValue( this.services$);
        this.selectService()
        this.FiltreFormGroup.value.tab_Service = this.services$;
      },
      (err: any) => {
        console.error('/!\ err: ', err.message);
      }
    );
  }

    // Parcours des services FFEA et restriction des accès à ces derniers selon les droits utilisateur.
    private defaultSelection(){
      // parcours des services disponible
      for ( let index = this.services$.length-1; index >= 0; index-- ) {
        // gestion des acces au service utilisateurs
        switch (this.services$[index].code) {
          // Acces service : ANALYSE DE CLÉ
          case this.CONST_CODE_SERVICE_ADC: {
            if ( !this.profilUtilisateur$.droits_utilisateur.droit_module_adc && !this.profilUtilisateur$.droits_utilisateur.droit_module_admin_adc && !this.profilUtilisateur$.droits_utilisateur.est_admin ) { 
              this.services$.splice(index ,1);
            }
            break;
          }

          // Acces service : ADELA
          case this.CONST_CODE_SERVICE_ADELA: {
            if ( !this.profilUtilisateur$.droits_utilisateur.droit_module_adela && !this.profilUtilisateur$.droits_utilisateur.droit_module_admin_adela && !this.profilUtilisateur$.droits_utilisateur.est_admin ) { 
              this.services$.splice(index ,1);
            }
            break;
          }
          default: {
            this.services$.splice(index ,1);
            break;
          }
        }
      }
    }

  public searchingFolder(){
    this.waitingResearch = true;
    this.ExpandPannel = false;

    // Sélection d'un service obligatoire
    if ( this.FiltreFormGroup.controls.tab_Service.value.length == 0 ) {
      this.toast.showInfo('Vous devez sélectionner un service')
      return;
    }
    
    // RAZ des données
    this.dossier_ADC$ = [];
    this.dossier_ADELA$ = [];

    for(let i = 0; i < this.FiltreFormGroup.controls.tab_Service.value.length; i++) {
      if(this.FiltreFormGroup.controls.tab_Service.value[i].code === this.CONST_CODE_SERVICE_ADC) {
      this.apiADC.getAllFolder(this.FiltreFormGroup.value)
      .subscribe(
        (data: any) => {
          this.dossier_ADC$ = data;
        },
        err => { 
          console.error('/!\ err: ', err.message); 
          this.waitingResearch = false;
        },
        () => {
          this.waitingResearch = false;
        });
      }

      if(this.FiltreFormGroup.controls.tab_Service.value[i].code === this.CONST_CODE_SERVICE_ADELA) {
        
        this.apiADELA.getAllFolder(this.FiltreFormGroup.value)
        .subscribe(
          (data: any) => {
            this.dossier_ADELA$ = data;
            if (data.length>0) {
              this.adelaExpanded = true
            }
          },
          err => {
            console.error('/!\ err: ', err.message);
            this.waitingResearch = false;
          },
          () => {
            this.waitingResearch = false;
          });
      }
    }
  }

  // demande de rafraichissement
  public refreshADELA($event: boolean) {
    this.searchingFolder();
  }
  // demande de rafraichissement
  public refreshADC($event: boolean) {
    this.searchingFolder();
  }


  verificationFormatImmatriculation(fcControler: FormControl): any {
    let plaque: string = fcControler.value;
    
    let regex : any = /^[0-9A-Z\-\ ]{1,10}$/;

    if (plaque != '' && !regex.test(plaque)) {
      return { erreur: 'Immatriculation au format AA-123-AA ou 9999ZZZ99' }
    }
    
    return null;
  }
  
  verificationFormatReference(fcControler: FormControl): any {
    let reference : string = fcControler.value;
    let a_regex   : any = /^[0-9A-Z]{1,24}$/;
    
    if (reference != '' && !a_regex.test(reference)) {
      return { erreur: 'Référence de 24 caractères comprenant lettres en majuscule ou chiffres' }
    }

    return null;
  }

  verificationFormatDateDebut(fcControler: FormControl): any {
    if(this.FiltreFormGroup == undefined){
      return null;
    }
    
    let dateFin     : Date = new Date(this.FiltreFormGroup.controls.dateFin.value);
    let dateDebut   : Date = new Date(fcControler.value);
    let dateToday   : Date = new Date();
    
    dateDebut.setHours(0,0,0,0);
    dateToday.setHours(23,59,59,999);
    
    if(dateFin != undefined) {
      dateFin.setHours(23,59,59,999);
      if(dateDebut > dateFin) { return { erreur: 'Date de début doit être e à la date de fin' }}
    }

    if(dateDebut.getTime() > dateToday.getTime()){
      return { erreur: 'Date de début doit être antérieure à la date du jour' }
    }
    
    return null;
  }

  verificationFormatDateFin(fcControler: FormControl): any {
    if(this.FiltreFormGroup == undefined){
      return null;
    }

    let dateFin   : Date = new Date(fcControler.value);
    let dateDebut : Date = new Date(this.FiltreFormGroup.controls.dateDebut.value);
    let dateToday : Date = new Date();
    
    dateDebut.setHours(0,0,0,0);
    dateToday.setHours(23,59,59,999);
    dateFin.setHours(23,59,59,999);
    
    if(dateFin.getTime() > dateToday.getTime() || (dateDebut.getTime() != null && dateFin.getTime() < dateDebut.getTime())){
      return { erreur: 'Date de fin doit être antérieure à la date de début ou du jour' }
    }
    
    return null;
  }

  verificationFormatService(fcControler: FormControl): any {
    if(this.FiltreFormGroup == undefined){
      return null;
    }
            
    this.FiltreFormGroup.controls['tab_Etat'].setValue(this.etats$);
    
    return null;
  }


  verificationFormatEtat(fcControler: FormControl): any {
    let bEtatADELA: boolean = false;
    let bEtatADC  : boolean = false;

    if(this.FiltreFormGroup == undefined){
      return null;
    }

    for(let i = 0; i < this.FiltreFormGroup.controls.tab_Service.value.length; i++) {
      if(this.FiltreFormGroup.controls.tab_Service.value[i].code === this.CONST_CODE_SERVICE_ADC) {
        for(let i = 0; i < fcControler.value.length; i++) {
          if(fcControler.value[i].service === this.CONST_CODE_SERVICE_ADC) {
            bEtatADC = true;
          }
        }
        
        if(bEtatADC === false) {
          return { erreur: 'Au moins un état du service ADC doit être sélectionné' }
        }
      }

      if(this.FiltreFormGroup.controls.tab_Service.value[i].code === this.CONST_CODE_SERVICE_ADELA) {
        for(let i = 0; i < fcControler.value.length; i++) {
          if(fcControler.value[i].service === this.CONST_CODE_SERVICE_ADELA) {
            bEtatADELA = true;
          }
        }
        
        if(bEtatADELA === false) {
          return { erreur: 'Au moins un état du service ADELA doit être sélectionné' }
        }
      }
    }

    if(fcControler.value.length === 0) {
      return { erreur: 'Au moins un état par service doit être sélectionné' }
    }

    return null;
  }
  
  public chargeListeCabinet() {
    if ( this.profilUtilisateur$.droits_utilisateur.droit_module_admin_adc || this.profilUtilisateur$.droits_utilisateur.droit_module_admin_adela) {
      this.apiEntite.getAllEntite()
      .subscribe(
        (data: Entite[] ) => {
          this.entites$ = data;
        }
      );
    } else {
      this.apiEntite.getEntitesByIdUtilisateur(this.profilUtilisateur$.id)
      .subscribe(
      (data: Entite[])  => {
          this.entites$ = data;
        }
      );
    }
  }

  public chargeListeEtat() {
    this.etats$ =[];

    for ( let index = 0; index < this.services$.length; index++ ) {
      if (this.services$[index].code == this.CONST_CODE_SERVICE_ADELA && this.services$[index].actif == true) {
        // Ajout des états pour ADELA
        this.apiADELA.getEtat()
        .subscribe(
          (data: Etat[] ) => {
            if ( data != undefined && data.length > 0 ) {
              this.etats$.push({nomService:this.CONST_CODE_SERVICE_ADELA, tab_Etat: data, selected: true});
              this.etat.setValue(data.filter((etat)=>etat.code!='CLOTURE'))
            }
          },
          err => {
            console.error('/!\ err: ', err.message);
          }
        );
      }

      if (this.services$[index].code == this.CONST_CODE_SERVICE_ADC && this.services$[index].actif == true) {
        
        // Ajout des états ADC
        this.apiADC.getEtat()
        .subscribe(
          (data: any ) => {
            let tempEtat: Array<Etat> = [];
            if ( data != undefined && data.length > 0 ) {
              this.etats$.push({nomService:this.CONST_CODE_SERVICE_ADC, tab_Etat: data, selected: true});
              for(let n = 0; n < this.etats$.length; n++) {
                for(let i = 0; i < this.etats$[n].tab_Etat.length; i++) {
                  tempEtat.push(this.etats$[n].tab_Etat[i]);
                }
              }
              this.FiltreFormGroup.controls['tab_Etat'].setValue( tempEtat);
              this.FiltreFormGroup.value.tab_Etat = tempEtat;
            }
          },
          err => {
            console.error('/!\ err: ', err.message);
          }
        );
      }
    }
  }

  public NouvelleAnalyseADELA() {
    this.router.navigate([ '/app/adela' ]);
  }

  public NouvelleAnalyseADELAPoudre(){
    this.router.navigate([ '/app/adela-poudre' ]);
  }
}