import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { GroupeService } from 'src/app/components/groupe/data-access/groupe.service';
import { Groupe } from 'src/app/components/groupe/groupe-interface';
import { Service } from 'src/app/interfaces/dossier/service';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { ServiceExterne } from 'src/app/interfaces/services-ffea/service-externe';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiUtilisateurService } from 'src/app/services/api-utilisateur.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { ApiUtilitaireService } from 'src/app/services/tools/api-utilitaire.service';
import { TitleCaseDirective } from '../../../../../directive/title-case.directive';
@Component({
    selector: 'app-filtre-liste-utilisateurs-services',
    templateUrl: './filtre-liste-utilisateurs-services.component.html',
    styleUrls: ['./filtre-liste-utilisateurs-services.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatExpansionModule, MatIconModule, MatFormFieldModule, MatInputModule, NgIf, TitleCaseDirective, MatSelectModule, NgFor, MatOptionModule, MatButtonModule, MatProgressSpinnerModule]
})
export class FiltreListeUtilisateursServicesComponent implements OnInit {

  
  @Output() newItemEvent = new EventEmitter<Array<ProfilUtilisateur>>();
  
  // Gestion de l'affichage
  expandPannel    : boolean = true;
  waitingResearch : boolean = false;
  
  // Combo type 
  groupes              : Array<Groupe> = Array<Groupe>();
  // environnements      : Array<Environnement> = Array<Environnement>();

  // Formulaire de filtrage
  FiltreFormGroup: FormGroup;

  //Services:
  servicesExternes : Array<ServiceExterne> = [];
  services : Array<Service> = Array<Service>();
  
  // Accesseur en lecture des formControl
  get groupe()           : FormArray  { return <FormArray>this.FiltreFormGroup.get('groupe') as FormArray }
  get nom()             { return this.FiltreFormGroup.get('nom'); }
  get prenom()          { return this.FiltreFormGroup.get('prenom'); }
  // get tabenvironnement()  : FormArray  { return <FormArray>this.FiltreFormGroup.get('tabenvironnement') as FormArray }
  get tabservice()        : FormArray  { return <FormArray>this.FiltreFormGroup.get('tabservice') as FormArray }
  get tabserviceexterne() : FormArray  { return <FormArray>this.FiltreFormGroup.get('tabserviceexterne') as FormArray }
  get filteredtabserviceexterneFormData() {    
    if (this.FiltreFormGroup.value.tabservice.length > 0) {
      return this.servicesExternes.filter(serviceexterne => {
        for(let i = 0; i < this.FiltreFormGroup.value.tabservice.length; i++) {
          if(serviceexterne.service.code === this.FiltreFormGroup.value.tabservice[i].code) {
            return serviceexterne
          }          
        }
        this.FiltreFormGroup.controls['tabserviceexterne'].setValue(Array<ServiceExterne>());
        return undefined;
      });
    } else {
      this.FiltreFormGroup.controls['tabserviceexterne'].setValue(Array<ServiceExterne>());
      return undefined;
    }        
  }
  constructor(
      private _fb                 : FormBuilder
    , private apiUtilisateur      : ApiUtilisateurService
    , private apiUtilitaire       : ApiUtilitaireService
    , private toast               : PersoSnackbarService
    , private apiAuthentification : ApiAuthentificationService
    , private apiGroupe           : GroupeService) { 

    // Déclaration du formulaire
    this.FiltreFormGroup = this._fb.group({
      groupe             : [Array<Groupe>(), [Validators.required]],
      nom               : ['', ''],
      prenom            : ['', ''],
//      tabenvironnement  : [Array<Environnement>(), [Validators.required]],
      tabservice        : [Array<Service>()],
      tabserviceexterne : [Array<ServiceExterne>()]
    });    
  }

  ngOnInit(): void {
    // Récupération des paramètres du formulaire de filtrage
    this.chargeFiltre();
  }

  private chargeFiltre(): void { 
    
    // this.chargerEnvironnements();
    this.chargerGroupes();
    this.chargerServices();
    this.chargeFiltreServicesExternes();
  }

/*   private chargerEnvironnements(): void {
    this.apiUtilitaire.getEnvironnement(this.apiUtilitaire.CONST_CODE_ENVIRONNEMENT_ALL)
    .subscribe(
      (data: Environnement[]) => {
        this.environnements = data;
        this.FiltreFormGroup.patchValue({            
          tabenvironnement     : this.environnements
        });
      },
      (err: any) => {
        console.log('err getEnvironnement() ' + err.error);
      }
    );
  } */

  private chargerGroupes(): void {
    this.apiGroupe.getGroupes(this.apiAuthentification.GetEnvironmentCode())
    .subscribe(
      (data: Groupe[]) => {
        this.groupes = data;
        this.FiltreFormGroup.patchValue({            
          groupe     : this.groupes
        });
      },
      (err: any) => {
        console.log('err getGroupes() ' + err.error);
      }
    );
  }

  private chargerServices(): void {
    this.apiUtilitaire.getService(this.apiUtilitaire.CONST_CODE_ENVIRONNEMENT_SERVICES_FFEA)
    .subscribe(
      (data : Service[])=>{
        // Par défaut, toutes les options du filtre "Service" sont cochées.
        this.services = data;
        this.FiltreFormGroup.patchValue({
          tabservice: this.services
        });
      },
      (err: any) => {
        console.log('err getService() ' + err.error);
      }
    );
  }

  private chargeFiltreServicesExternes():void{
    this.apiUtilitaire.getServiceExterne(this.apiUtilitaire.CONST_CODE_ENVIRONNEMENT_SERVICES_FFEA)
    .subscribe(
      (data : ServiceExterne[])=>{
        // Par défaut, toutes les options du filtre "Service Externe" sont cochées.
        this.servicesExternes = data;
        this.FiltreFormGroup.patchValue({
          tabserviceexterne: this.servicesExternes
        });
      },
      (err: any) => {
        console.log('err getServiceExterne() ' + err.error);
      }
    );
  }

  public onSearchingUtilisateurs() {
    this.waitingResearch = true;
    this.addNewItem([]);

    this.apiUtilisateur.postListeUtilisateurParEnvironnement(this.apiUtilitaire.CONST_CODE_ENVIRONNEMENT_SERVICES_FFEA, this.FiltreFormGroup.value)
    .subscribe(
      (data: ProfilUtilisateur[]) => {
        
        
        this.addNewItem(data);
        this.toast.showInfo('Recherche terminée');
        this.waitingResearch = false;
        this.expandPannel = false;
      },
      (err: any) => {
        console.error('/!\ err: ', err.message);
        this.waitingResearch = false;
      });

  }

  public addNewItem(value: Array<ProfilUtilisateur>) {
    this.newItemEvent.emit(value);
  }
}
