import { Component, OnInit } from '@angular/core';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiDocutechService } from 'src/app/services/api-docutech.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { DatePipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-doc-apercu',
    templateUrl: './doc-apercu.component.html',
    styleUrls: ['./doc-apercu.component.scss'],
    standalone: true,
    imports: [MatCardModule, DatePipe]
})
export class DocApercuComponent implements OnInit {

  timeLeft: number    = 2; // 2 seconds
  interval: any       = 0;
  indexTab: number    = 1;

  docTemp: any = {
    titre: 'Lorem ipsum dolor sit amet consectetur adipiscing elit',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ornare tortor sem, eu tristique tortor laoreet eu. Sed vitae pharetra velit. Sed iaculis nunc ut lacus cursus dapibus. Suspendisse tristique mi id enim auctor eleifend. Mauris ultrices massa diam, ut ultricies velit mattis quis. Nulla mollis diam lectus, et lacinia nunc finibus vel. Nulla nisi risus, finibus et velit id, ultricies euismod magna. In in mi massa. Sed posuere mollis elit ac mattis.',
    contenu: `
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi quis interdum lorem. Donec diam sem, consequat et
    rutrum et, luctus eget risus. Duis vel metus lacus. Aenean tempor ex nec metus auctor gravida. Vivamus fringilla
    aliquam condimentum. Fusce eu mollis metus. Pellentesque ut ipsum maximus, vestibulum metus quis, pretium enim.
    Etiam finibus libero dolor, eget suscipit lacus iaculis quis. Nullam iaculis nisi a lacus elementum posuere. Nam
    ultricies ligula vel augue efficitur, sit amet tincidunt lacus ultricies. Etiam hendrerit, metus ac pellentesque
    volutpat, nisl libero accumsan nunc, ac venenatis nisl nisi ut massa. Nullam vestibulum dolor eu lectus finibus,
    ac tincidunt diam elementum. Proin cursus vitae odio et convallis. Duis convallis dui gravida dapibus faucibus.
    <br>
    <br>
    Nam est velit, sodales eu ligula eu, <a href="https://angular.io/guide/animations" target="_blank">sodales
        pulvinar orci</a>. Quisque consequat, mi fringilla porta varius, urna velit ultrices dui, et vestibulum leo
    nisi maximus turpis. Aenean cursus finibus risus, id facilisis eros pellentesque posuere. Sed pretium eros id
    mattis pellentesque. Quisque vitae lacinia mauris. Etiam auctor nulla vitae ipsum posuere varius. Duis ornare
    ornare imperdiet. Etiam vel lorem ut urna venenatis fringilla.
    <br>
    <br>
    Proin in dapibus est. Nulla facilisi. Mauris a lacus auctor, porta dui sed, rutrum mauris. Donec egestas leo at
    nisl rhoncus, ac laoreet urna maximus. Vivamus quis mollis odio, sed aliquam dolor. Etiam tellus diam, consequat
    eget bibendum quis, venenatis sed justo. Morbi ac pellentesque urna.
    <br>
    <br> 
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi quis interdum lorem. Donec diam sem, consequat et
        rutrum et, luctus eget risus. Duis vel metus lacus. Aenean tempor ex nec metus auctor gravida. Vivamus fringilla
        aliquam condimentum. Fusce eu mollis metus. Pellentesque ut ipsum maximus, vestibulum metus quis, pretium enim.
        Etiam finibus libero dolor, eget suscipit lacus iaculis quis. Nullam iaculis nisi a lacus elementum posuere. 
        
        Nam
        ultricies ligula vel augue efficitur, sit amet tincidunt lacus ultricies. Etiam hendrerit, metus ac pellentesque
        volutpat, nisl libero accumsan nunc, ac venenatis nisl nisi ut massa. Nullam vestibulum dolor eu lectus finibus,
        ac tincidunt diam elementum. Proin cursus vitae odio et convallis. Duis convallis dui gravida dapibus faucibus.
    `,
    datePublication: new Date(),
    Categorie: {
      libelle: 'Développement > Catégorie'
    }
  }

  constructor(
    private apiAuthentification: ApiAuthentificationService
    , private ApiDoc: ApiDocutechService
    , private toast: PersoSnackbarService
  ) {

   }

  ngOnInit(): void {

    

  }

  private loadDocument() {

  }



}
