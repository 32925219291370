import { Component, OnInit } from '@angular/core';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { FooterComponent } from '../footer/footer.component';
import { RouterOutlet } from '@angular/router';
import { NavBarComponent } from '../nav-bar/nav-bar.component';
import { AppUpdateComponent } from '../../application/app-update/app-update.component';
import { AppInstallComponent } from '../../application/app-install/app-install.component';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-simple-page',
    templateUrl: './simple-page.component.html',
    styleUrls: ['./simple-page.component.scss'],
    standalone: true,
    imports: [NgClass, AppInstallComponent, AppUpdateComponent, NavBarComponent, RouterOutlet, FooterComponent]
})
export class SimplePageComponent implements OnInit {

  classBody: string = '';

  constructor(private authUser : ApiAuthentificationService) { }

  ngOnInit(): void {
    if(this.authUser.isServiceFFEA()) {
      document.documentElement.style.setProperty('--primary', '#273a82');
    } else {
      document.documentElement.style.setProperty('--primary', '#e9e9e9');
    }
  }

}
