<mat-tab-group [selectedIndex]="selected.value" animationDuration="0ms" class ="tabretrait">
    <mat-tab label="Mes Factures">
        <mat-card appearance="outlined" class="mat-card-no-shadow" style="padding: 0px;">
            <form [formGroup]="FiltreFormGroup">
                <mat-expansion-panel id="expandFilter" [(expanded)]="expandPannel" #expandFilter>                                
                    <mat-expansion-panel-header style="background-color: #fff">
                        <mat-panel-title class="label-accent">
                            <mat-icon>search</mat-icon>Veuillez sélectionner un service et les éventuelles options de votre recherche...
                        </mat-panel-title>
                    </mat-expansion-panel-header>
        
                    <!-- Recherche de factures -->
                    <div *ngIf="!isExportVisible">
        
                        <mat-form-field>
                            <mat-label>Service</mat-label>
                            <mat-select name="service" id="service" formControlName="service" multiple>
                                <mat-option *ngFor="let service of services" [value]="service.id">
                                    {{ service.libelle }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="!FiltreFormGroup?.valid && FiltreFormGroup.touched">Vous devez sélectionner au moins un service pour votre recherche</mat-error>
                        </mat-form-field>
            
                        <mat-form-field>
                            <mat-label>Type Document</mat-label>
                            <mat-select name="type" id="type" formControlName="type" multiple>
                                <mat-option *ngFor="let type of types" [value]="type">
                                    {{ type }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="!FiltreFormGroup?.valid && FiltreFormGroup.touched">Vous devez sélectionner au moins un type de document pour votre recherche</mat-error>
                        </mat-form-field>
            
                        <mat-form-field>
                            <mat-label>État</mat-label>
                            <mat-select name="etat" id="etat" formControlName="etat" multiple>
                                <mat-option *ngFor="let etat of etats; let indice = index" [value]="etat.id">
                                    {{ indice +1 }}. {{ etat.libelle }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="!FiltreFormGroup?.valid && FiltreFormGroup.touched">Vous devez sélectionner au moins un état de facture pour votre recherche</mat-error>
                        </mat-form-field>
            
                        <mat-form-field>
                            <mat-label>N° Facture</mat-label>
                            <input matInput type="text" id="facture" formControlName="facture">
                            <mat-error *ngIf="facture?.invalid && (facture?.dirty || facture?.touched)">{{ facture.errors?.erreur }}</mat-error>
                        </mat-form-field>
            
                        <button mat-raised-button (click)="searchingEntite()" color="primary" style="margin: 10px;" *ngIf="profilUtilisateur.droits_utilisateur.droit_module_admin_compta || profilUtilisateur.droits_utilisateur.droit_module_admin_adela">Rechercher une entite</button>
                        <app-entite-search *ngIf="searching" (entiteEvent)="setEntite($event)"></app-entite-search>
                        <div *ngIf="entiteSelected" class="entite">
                            <div style="margin-right: 15px;">
                                {{entiteSelected.raison_sociale}}
                            </div>
                            <button mat-mini-fab (click)="cancelEntite()"  matTooltip="Supprimer l'entité de la recherche"><mat-icon>close</mat-icon></button>
                        </div>

                    
                        <mat-form-field *ngIf="!(profilUtilisateur.droits_utilisateur.droit_module_admin_compta || profilUtilisateur.droits_utilisateur.droit_module_admin_adela)">
                            <mat-label>Cabinet</mat-label>
                            <mat-select name="entite" id="entite" formControlName="idEntite">
                                <mat-option></mat-option>
                                <mat-option *ngFor="let entite of entites" [value]="entite.id">
                                    {{ entite.raison_sociale }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div>
                            <mat-form-field class="formDate">
                                <mat-label>Date de début</mat-label>
                                <input matInput type="date" id="dateDebut" formControlName="dateDebut">
                                <mat-error *ngIf="dateDebut?.invalid && (dateDebut?.dirty || dateDebut?.touched)">{{ dateDebut.errors?.erreur }}</mat-error>
                            </mat-form-field>

                            <mat-form-field class="formDate">
                                <mat-label>Date de fin</mat-label>
                                <input matInput type="date" id="dateFin" formControlName="dateFin">
                                <mat-error *ngIf="dateFin?.invalid && (dateFin?.dirty || dateFin?.touched)">{{ dateFin.errors?.erreur }}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    
                    <mat-action-row>
                        <div class="div-container-between" style="padding: 8px;">
                            <div *ngIf="!isExportVisible" class="div-container-center" style="width:100%; display: flex; justify-content: center;">
                                <button 
                                    mat-flat-button  
                                    *ngIf       = "!waitingResearch"
                                    color       = "primary" 
                                    [disabled]  = "!FiltreFormGroup.valid" 
                                    (click)     = "onSearchingInvoice()"
                                    >
                                    <mat-icon>search</mat-icon>Rechercher                                 
                                </button>
                                <mat-spinner diameter="24" *ngIf="waitingResearch"></mat-spinner>
                            </div>
                        </div>
                    </mat-action-row>
                </mat-expansion-panel>
            </form>
        </mat-card>
    
        <mat-card appearance="outlined" class="mat-card-no-shadow" style="padding: 0px">
            <app-table-facture [dataSource]="factures"></app-table-facture>          
        </mat-card>
    </mat-tab>

    <!-- gestion de la comptabilite -->
    <mat-tab label="Mes exports" *ngIf="profilUtilisateur.droits_utilisateur.droit_module_admin_compta">
          <app-mes-exports></app-mes-exports>
    </mat-tab>

    <mat-tab label="Mes règlements" *ngIf="profilUtilisateur.droits_utilisateur.droit_module_admin_compta">
        <app-mes-remises></app-mes-remises>
    </mat-tab>

    <mat-tab label="Chiffre d'affaires" *ngIf="profilUtilisateur.droits_utilisateur.droit_module_admin_compta == true">
        <app-mes-chiffres-affaires></app-mes-chiffres-affaires>
    </mat-tab>

    <mat-tab label="Édition relevés" *ngIf="profilUtilisateur.droits_utilisateur.droit_module_admin_compta == true">
        <app-releves></app-releves>
    </mat-tab>
</mat-tab-group>