<mat-card>
  <mat-card-title>Critères de recherche</mat-card-title>
  <mat-card-content>
    <form [formGroup]="filterForm" (ngSubmit)="applyFilter()">
      <div class="div-container-left">
        <mat-form-field>
          <mat-label>Nom</mat-label>
          <input matInput formControlName="nom">
        </mat-form-field>
        
        <mat-form-field>
          <mat-label>Prénom</mat-label>
          <input matInput formControlName="prenom">
        </mat-form-field>
      
        <mat-form-field>
          <mat-label>État</mat-label>
          <mat-select formControlName="idEtat">
            <mat-option value="0">Tous</mat-option>
            <mat-option *ngFor="let etat of etats" [value]="etat.id">{{ etat.libelle }}</mat-option>
          </mat-select>
        </mat-form-field>
      
        <button 
            mat-raised-button 
            type="submit"
            color="primary">
            <mat-icon>search</mat-icon>Rechercher
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
