import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HistoriqueVehicule } from 'src/app/interfaces/vehicule/historique-vehicule';
import { HitoriqueVehiculeService } from 'src/app/services/hitorique-vehicule.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { saveAs } from "file-saver";
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';

import { ApiUtilisateurService } from 'src/app/services/api-utilisateur.service';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ApiParametreService, Balise } from 'src/app/services/api-parametre.service';
import { PluralFormatPipe } from '../../../pipe/plural-format.pipe';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgFor, NgStyle, DatePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';

export interface Incoherence {
  id: number,
  date_expertise: string,
  date_sinistre: string,
  kilometrage: number
  habilitation: string,
  numero_dossier: number,
  immatriculation: string,
  date_mouvement: string,
  tab_OperationSIV: string[],
  procedure_vge: string,
  reparabilite_economique: string,
  reparabilite_technique: string,
  montant_ht_reparation: number,
  tab_ZoneChoc: string[]
}

export interface Concentrateur {
  immatriculation: string,
  numero_dossier: string,
  operation: string,
  procedure_vge: string,
  date_envoi: string,
  date_effet: string,
  etat_message: string,
  message_erreur: string,
  tab_Erreurs: [
      {
        code: number,
        libelle: string
      }
  ]
}

export interface Adc {
  statut_dossier: number,
  etat_dossier: string,
  date_modification: string,
}

export interface ADELA {
  id: number,
  date_envoi_mission: string,
  date_cloture_mission: string,
  type_fluide: string,
  kilometrage: number,
  habilitation: string,
  reference_dossier_expert: string,
  immatriculation: string,
  tpe_libelle : string,
  tpe_code : string
  
}

export interface CEA {
  id: number,
  date_demande: string,
  date_cloture: string,
  kilometrage: number,
  habilitation: string,
  reference_expert: string,
  immatriculation: string
}

export interface AppelOffre {
  id: number,
  date_demande: string,
  date_cloture: string,
  kilometrage: number,
  habilitation: string,
  reference_expert: string,
  immatriculation: string
}

export interface AffairesVO {
  id: number,
  immatriculation: string,
  rep: string,
  expertise: {
    kilometrage: number,
    date: string,
    habilitation: string,
    dangereux: string,
    montant_reparation: number,
    repare: string,
    conforme: string,
    date_conformite: string,
    tech_rep: string,
    tab_OperationSIV: string[]
  }
}

export interface Saisie {
  immatriculation: string,
  vin: string
}

export interface askDocument {
  immatriculation: string,
  vin: string,
  action: number,
  idExpediteur: number,
  expediteur: string,
  destinataire: string,
  destinataireCC: string,
  destinataireCCI: string,
  sujet: string,
  messageHtml: string
}

const ELEMENT_DATA: Incoherence[] = [];

@Component({
    selector: 'app-historique-vehicule',
    templateUrl: './historique-vehicule.component.html',
    styleUrls: ['./historique-vehicule.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatTabsModule, MatInputModule, MatButtonModule, MatIconModule, NgIf, MatProgressSpinnerModule,
               MatFormFieldModule, MatExpansionModule, NgFor, NgStyle, DatePipe, PluralFormatPipe,MatGridListModule]
})

export class HistoriqueVehiculeComponent implements OnInit {
  
  readonly SEARCH_IMMAT:string  = 'IMMAT'
  readonly SEARCH_VIN  :string    = 'VIN'
  readonly AUCUNE_INFO :string = "Aucune information n'a été trouvée"
  // gestion de la taille écran
  innerWidth: any;

  // Objet HistoriqueVehicule de l'interface
  historyVehicle$: HistoriqueVehicule = <HistoriqueVehicule>{};
  
  // formulaire de saisie pour la recherche de vehicule
  saisieForm$ = this.fb.group({
    immatriculation: new FormControl(''),
    vin: new FormControl('')
  });

  // recherche de vehicule
  tab_Adc: Adc[] = [];
  tab_ADELA: ADELA[] = [];
  tab_Incoherence: Incoherence[] = [];
  tab_Concentrateur: Concentrateur[] = [];
  tab_CEA: CEA[] = [];
  tab_AppelOffres: AppelOffre[] = [];
  tab_Affaires5000VO: AffairesVO[] = [];
  tab_Entites: any[] = [];

  // ouverture automatique de l'accordéon
  nb_Incoherence: number = 0;
  nb_Concentrateur: number = 0;
  nb_ADC: number = 0;
  nb_ADELA: number = 0;
  nb_ADELA_Poudre: number = 0;
  adelaFluideTitle : string ='';
  adelaPoudreTitle : string ='';
  nb_AppelOffres: number = 0;
  nb_CEA: number = 0;
  nb_1000VO: number = 0;
  nb_2500VO: number = 0;
  nb_5000VO: number = 0;
  waitingResearch: boolean = false;
  waitingsendQueryByMail: boolean = false;
  waitingDownload: boolean = false;
  waitingSend: boolean = false;
  sendQuery: boolean = false;
  vehiculeFound: boolean = false;
  displayedColumns: string[] = ['date_expertise', 'date_sinistre', 'date_mouvement', 'kilometrage', 'tab_OperationSIV', 'procedure_vge', 'reparabilite_economique', 'reparabilite_technique', 'tab_ZoneChoc'];
  displayedErreurs: string[] = ['code','libelle'];
  searchType : string = ''// VIN si recherche par VIN, IMMAT si recherche par IMMATRICULATION
  // formulaire d'envoi de demande d'information supplémentaire
  queryForm$ = this.fb.group({
    queryFrom: [''],
    queryTo: [''],
    queryMessage: ['']
  });
  get queryFrom() { return this.queryForm$.get('queryFrom'); }
  get queryTo() { return this.queryForm$.get('queryTo'); }
  get queryMessage() { return this.queryForm$.get('queryMessage'); }

  texteMail: string = '';

  SelectEntiteForm      = new FormControl();
  selectedEntite: string = '';

  // user information: 
  profilUtilisateur: ProfilUtilisateur = <ProfilUtilisateur>{};

  constructor(
    private apiHistoriqueImmatriculation: HitoriqueVehiculeService,
    private apiAuth: ApiAuthentificationService,
    private apiUtilisateur: ApiUtilisateurService,
    private routed: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private toast: PersoSnackbarService,
    private apiParametre:ApiParametreService
  ) { }

  ngOnInit(): void {
    //Récupération du paramètre qui lit le message par défaut pour la demande d'historique:
    this.apiParametre.getParametre("EMAIL_DEMANDE_HISTO_VEH")
    this.innerWidth = window.innerWidth;


    // récupération des paramètres optionnel dans l'url
    let immatriculation: string = '';
    let vin: string = '';
    immatriculation = this.routed.snapshot.params['immat'];
    vin = this.routed.snapshot.params['vin'];

    
    // Affectation des valeurs récupéré de l'URL
    this.saisieForm$.patchValue({
      immatriculation: immatriculation,
      vin: vin
    })
    
    // récupération des informations de l'utilisateurs connecté
    this.apiUtilisateur.getUtilisateur(this.apiAuth.userConnect.id)
      .subscribe(
        data => { this.profilUtilisateur = data },
        err => {
          console.log(err.message);
          this.toast.showInfo('Fiche utilisateur inaccessible ou introuvable');
          this.router.navigate(['login']);
        });

    if (immatriculation || vin ) {
      this.searchingVehicule();
    }
  }

  // Mettre à jour la taille à chaque changement de taille écran
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  public isMobileFormat() {
    // return true;
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 640) {
      return true;
    } else {
      return false;
    }
  }

  setDefaultData(data: any){
    if (data.immatriculation =='') {
      data.immatriculation = this.saisieForm$.value.immatriculation
    }
    if (data.vin == '') {
      data.vin = this.saisieForm$.value.vin
    }
  }

  setData(data: any): void {
    this.historyVehicle$ = data;
    this.tab_ADELA = data.tab_ADELA;
    this.nb_ADELA         = data.tab_ADELA.filter((dossier:any)=>dossier.tpe_code==="1" || dossier.tpe_code==='').length;
    this.nb_ADELA_Poudre  = data.tab_ADELA.filter((dossier:any)=>dossier.tpe_code==="2").length;
    this.tab_Adc = data.adc.tab_ADCHisto;
    this.nb_ADC = data.adc.tab_ADCHisto.length;
    this.tab_CEA = data.tab_CEA;
    this.nb_CEA = data.tab_CEA.length;
    this.tab_AppelOffres = data.tab_AppelOffres;
    this.nb_AppelOffres = data.tab_AppelOffres.length;
    this.tab_Incoherence = data.tab_Incoherence;
    this.nb_Incoherence = data.tab_Incoherence.length;
    this.tab_Concentrateur = data.tab_Concentrateur;
    this.nb_Concentrateur = data.tab_Concentrateur.length;
    this.tab_Affaires5000VO = data.sid.tab_Affaires5000VO;
    this.tab_Entites = data.tab_Entite;
    this.nb_5000VO = data.sid.tab_Affaires5000VO.length;
    this.nb_1000VO = (data.sid.st_Affaires1000VO.id > 0 ? 1 : 0);
    this.nb_2500VO = (data.sid.st_Affaires2500VO.id > 0 ? 1 : 0);
  }

  getMessage(immat:string){
    this.apiParametre.parametreWithFilter$.subscribe(
      (parametre) => {
        const balise:Balise={"immat":immat,"nom":this.profilUtilisateur.nom,"prenom":this.profilUtilisateur.prenom}
        this.texteMail = this.apiParametre.getMessageWithouthBalise(parametre!.valeur,balise)}
    )
    }
  
  // recherche d'un véhicule
  searchingVehicule() {        
    this.innerWidth = window.innerWidth;
    this.vehiculeFound = false;
    // as-t-on une recherche a effectuer ?
    if ((this.saisieForm$.value.immatriculation == undefined || this.saisieForm$.value.immatriculation == '') && (this.saisieForm$.value.vin == undefined || this.saisieForm$.value.vin == '')) {
      this.toast.showInfo('Merci de renseigner une immatriculation ou un numéro VIN.');
    } else {
      // recherche d'un historique de véhicule
      if (this.saisieForm$.value.immatriculation != undefined && this.saisieForm$.value.immatriculation != '') {
        this.searchType = this.SEARCH_IMMAT
        this.waitingResearch = true;
        this.apiHistoriqueImmatriculation.getHistoriqueImmatriculation(this.saisieForm$.value.immatriculation)
          .subscribe(
            (data) => {
              
              this.setData(data);   
              this.setDefaultData(data)         
              this.vehiculeFound = data.trouve;
              this.getMessage(data.immatriculation)
             
            },
            (error: any) => {
              this.waitingResearch = false;
              switch (error.status) {
                case 404:
                  this.toast.showInfo('Aucune donnée sur le véhicule');
                  break;

                default: 
                  console.log('/!\\ err put HistoriqueImmatriculation: ' + error.error);
              }
            },
            () => {
              this.waitingResearch = false;
            }
          );
      } else if (this.saisieForm$.value.vin != undefined && this.saisieForm$.value.vin) {
        this.searchType = this.SEARCH_VIN
        this.waitingResearch = true;
        this.apiHistoriqueImmatriculation.getHistoriqueVIN(this.saisieForm$.value.vin)
          .subscribe(
            (data) => {              
              this.setData(data);
              this.setDefaultData(data)
                this.vehiculeFound = data.trouve;
            },
            (error: any) => {
              switch (error.status) {
                case 404:
                  this.toast.showInfo('Aucune donnée sur le véhicule');
                  break;

                default: 
                  console.log('/!\\ err put HistoriqueVin: ' + error.error);
              }
              this.waitingResearch = false;
            },
            () => {
              this.waitingResearch = false;
            }
          );
      }
    }
  }

  // Coloration selon certain mot/valeur
  getColorText(element: string): string {
    switch (element) {
      case 'Oui' || '1': return '#30BC6D';
      case 'Non' || '0': return '#FF4641';
      default:
        switch (true) {
          case (element.indexOf('error') !== -1): return '#FF4641';
          case (element.indexOf('erreur') !== -1): return '#FF4641';
          case (element.toLowerCase().indexOf('refusé') !== -1): return '#FF4641';
          case (element.indexOf('succès') !== -1): return '#30BC6D';
          default: return 'rgb(39,58,130)';
        }
    }
  }

  getReverseColorText (element: string) {
    switch (element) {
      case 'Oui' || '1': return '#FF4641';
      case 'Non' || '0': return '#30BC6D';
      default:
        switch (true) {
          case (element.indexOf('error') !== -1): return '#FF4641';
          case (element.toLowerCase().indexOf('refusé') !== -1): return '#FF4641';
          case (element.indexOf('succès') !== -1): return '#30BC6D';
          default: return 'rgb(39,58,130)';
        }
    }
  }

  // Affiche/Masque l'envoi d'une demande d'information aux cabinets
  toggleSendQueryTo() {
    if (this.sendQuery === true) {
      this.sendQuery = false;
    } else {
      this.sendQuery = true;
    }
  }

  // Téléchargement de l'historique du document.
  printDocument() {
    let form: askDocument = <askDocument>{};
    this.waitingDownload = true;

    form.immatriculation = this.saisieForm$.value.immatriculation!;
    form.vin = this.saisieForm$.value.vin!;
    form.idExpediteur = this.apiAuth.authUser.id;
    form.expediteur = '';
    form.sujet = '';
    form.destinataire = '';
    form.action = 1   // download document ...

    this.apiHistoriqueImmatriculation.getHistoriqueVehiculeDocument(form)
      .subscribe(
        data => {
          let fileName:string
          // les service REST renvoi le buffer du fichier en réponse qui est réécrit coté utilisateur.
          if (form.vin && form.vin !='') {
            fileName = form.vin
          }else{
            fileName = form.immatriculation
          }
          
          saveAs(data, `historique-vehicule-` + fileName + `.pdf`);
          this.toast.showInfo('Fichier téléchargé')
        },
        err => {
          this.toast.showError('Problème rencontré lors du téléchargment du fichier.');
          console.error(err.error.message);
          this.waitingDownload = false;
        },
        () => {
          this.waitingDownload = false;
        }
      );
  }

  // Envoi du document par mail
  sendDocumentByMail() {
    let form: askDocument = <askDocument>{};
    this.waitingSend = true;

    form.immatriculation = this.saisieForm$.value.immatriculation!;
    form.vin = this.saisieForm$.value.vin!;
    form.idExpediteur = this.apiAuth.authUser.id;
    form.sujet = '';
    form.messageHtml = '';
    form.destinataire = this.profilUtilisateur.email;
    form.action = 2   // send document to me...

    this.apiHistoriqueImmatriculation.sendHistoriqueVehiculeDocument(form)
      .subscribe(
        data => {
          this.toast.showInfo('Email envoyé')
        },
        err => {
          this.toast.showError("Erreur pendant l'envoi du fichier.");
          this.waitingSend = false;
        },
        () => { this.waitingSend = false }
      );
  }

  // envoi d'une requete au(x) ayant intervenu sur l'historique du vehicule
  sendQueryByMail() {
    let form: askDocument = <askDocument>{};
    this.waitingsendQueryByMail = true;

    form.immatriculation = this.historyVehicle$.immatriculation;
    form.vin = this.historyVehicle$.vin;
    form.idExpediteur = this.apiAuth.authUser.id;
    form.destinataire = ''
    form.destinataireCC = '';
    
    this.tab_Entites.forEach(function(entite: any) { 
      if ( form.destinataireCCI === '' || form.destinataireCCI == undefined ) {
        form.destinataireCCI = entite.mail_contact;
      } else {
        form.destinataireCCI += ';'+entite.mail_contact;
      }
    })

    form.expediteur = this.profilUtilisateur.email,
    form.sujet = 'Demande d’information sur un véhicule ' + form.vin;
    form.action = 2   // send document to a cabinet ...

    this.apiHistoriqueImmatriculation.sendHistoriqueVehiculeDocument(form)
      .subscribe(
        data => {
          this.toast.showInfo('Demande d\'information envoyée')
          this.waitingsendQueryByMail = false;
        },
        err => {
          this.toast.showError("Erreur pendant l'envoi des informations.");
          this.waitingsendQueryByMail = false;
        },
        () => {
          this.waitingsendQueryByMail = false;
        }
      );
  }

  onSelectEntite($event: any) {
    this.selectedEntite = $event.target.value;
    console.info('select entite: ' + $event.value.mail_contact);
  }

}
