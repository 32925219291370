import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Service } from 'src/app/interfaces/dossier/service';
import { ServiceExterne } from 'src/app/interfaces/services-ffea/service-externe';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { ApiUtilitaireService } from 'src/app/services/tools/api-utilitaire.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgIf, NgFor } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-fiche-service-externe',
    templateUrl: './fiche-service-externe.component.html',
    styleUrls: ['./fiche-service-externe.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, NgIf, MatSelectModule, NgFor, MatOptionModule, MatSlideToggleModule, MatButtonModule, MatIconModule, MatProgressSpinnerModule]
})
export class FicheServiceExterneComponent implements OnInit {

  // Variable pour gérer l'affichage
  bWaitingModification: boolean = false;

  // Variables pour gérer le service externe
  id              : number = 0;
  service_externe : ServiceExterne = <ServiceExterne>{};
  
  // Variables utilisées pour le formulaire
  formGroupServiceExterne = this.fBuilder.group({
      srx_libelle      : ['', [Validators.required]],
      srx_code         : ['', Validators.required],
      srx_url          : ['', [Validators.required]],
      srx_utilisateur  : '',
      srx_mot_de_passe : '',
      srx_actif        : new FormControl<boolean>(true,[Validators.required]) ,
      srx_service      : [0, [Validators.required]]
  });

  services: Array<Service> = Array<Service>();

  get srx_libelle()       { return this.formGroupServiceExterne.get('srx_libelle'); }
  get srx_url()           { return this.formGroupServiceExterne.get('srx_url'); }
  get srx_utilisateur()   { return this.formGroupServiceExterne.get('srx_utilisateur'); }
  get srx_mot_de_passe()  { return this.formGroupServiceExterne.get('srx_mot_de_passe'); }
  get srx_actif()         { return this.formGroupServiceExterne.get('srx_actif'); }
  get srx_service()       { return this.formGroupServiceExterne.get('srx_service'); }

  constructor(private apiUtilitaire: ApiUtilitaireService
            , private apiAuth     : ApiAuthentificationService
            , private fBuilder    : FormBuilder
            , private toast       : PersoSnackbarService
            , private routed      : ActivatedRoute
            , private router      : Router) { }

  ngOnInit(): void {
    // Récupération de l'identifiant du service externe + chargement des donneés du formulaire
    this.id = this.routed.snapshot.params['id'];
    this.getServiceExterne(this.id);
  }

  // Enregistrement du formulaire
  onClickEnregistrer(){
    this.bindFormulaireServiceExterne()
    this.putServiceExterne(this.id, this.apiAuth.userConnect.id, this.service_externe);    
  }
  
  // Retour à la page principale des services externe
  onClickAnnuler(){
    this.router.navigate(['/svc/admin/service-externe']);
  }

  bindFormulaireServiceExterne() {
    let service: any;
    this.service_externe              = <ServiceExterne>{};

    service = this.services.find(service => service.id == this.formGroupServiceExterne.controls.srx_service.value);
    if(service != undefined) {
      this.service_externe.service      = service
    }
    
    this.service_externe.id           = this.id;    
    this.service_externe.code         = this.formGroupServiceExterne.controls.srx_code.value!;
    this.service_externe.libelle      = this.formGroupServiceExterne.controls.srx_libelle.value!;
    this.service_externe.url          = this.formGroupServiceExterne.controls.srx_url.value!;
    this.service_externe.utilisateur  = this.formGroupServiceExterne.controls.srx_utilisateur.value!;
    this.service_externe.mot_de_passe = this.formGroupServiceExterne.controls.srx_mot_de_passe.value!;        
    this.service_externe.actif        = this.formGroupServiceExterne.controls.srx_actif.value!;
  }

  // Modification d'un service externe
  public putServiceExterne(id: number, utl_id: number, service_externe: ServiceExterne) : void {     
    this.bWaitingModification = true;
    this.apiUtilitaire.putServiceExterne(id, utl_id, service_externe)
      .subscribe(
        (data: any) => { 
          this.toast.showInfo("Service externe modifié");
          this.router.navigate(['/svc/admin/service-externe']);
          this.bWaitingModification = false;
        },
        (err: any) => { 
          '/!\\ error putServiceExterne: ' + console.log(err);
          this.bWaitingModification = false;
        },
        () => { 
          
          this.bWaitingModification = false;
        }
      );
  }

  // Chargement d'un service externe + liste des services
  public getServiceExterne(id: number) {
    if(id > 0) {
      this.apiUtilitaire.getService(this.apiUtilitaire.CONST_CODE_ENVIRONNEMENT_SERVICES_FFEA)
        .pipe(
          map( (data: Service[]) => {
            this.services = data;
          }),
          switchMap(() => this.apiUtilitaire.getServiceExterneById(id)),
          catchError((error: HttpErrorResponse) => {
            this.toast.showError(error.error);
            return throwError(error);
          })
        ).subscribe(
          (data: ServiceExterne) => {
            this.service_externe = data;
            this.formGroupServiceExterne.patchValue({
              srx_code         : data.code,
              srx_libelle      : data.libelle,
              srx_url          : data.url,
              srx_utilisateur  : data.utilisateur,
              srx_mot_de_passe : data.mot_de_passe,
              srx_actif        : data.actif,
              srx_service      : data.service.id
            });
          });                
    }
  }

}
