<div>
    <h1 mat-dialog-title>{{ titre_page }}</h1>
    <h4>{{ sous_titre_page }}</h4>    

    <div class="container" mat-dialog-content>                
        <table mat-table matTableResponsiveLarge [dataSource]="dataSourceMatTable" class="table-primary">

            <!-- Référence article --> 
            <ng-container matColumnDef="rtc_reference">
                <th mat-header-cell *matHeaderCellDef > Référence </th>
                <td mat-cell *matCellDef="let article" > {{ article.reference }} </td> 
            </ng-container>
            
            <!-- Libellé / Titre --> 
            <ng-container matColumnDef="rtc_titre">
                <th mat-header-cell *matHeaderCellDef style="padding: 0px 10px 0px 10px;"> Désignation </th>
                <td mat-cell *matCellDef="let article" style="padding: 0px 10px 0px 10px;"> {{ article.titre }} </td> 
            </ng-container>

            <!-- Résultat de retour -->
            <ng-container matColumnDef="rtc_resultat">
                <th mat-header-cell *matHeaderCellDef> Résultat </th>
                <td mat-cell *matCellDef="let article" > {{ article.resultat }} </td> 
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5,10,25]"
            showFirstLastButtons 
            aria-label="Nombre de lignes visibles...">
            </mat-paginator>
    </div>

    <br>
    <div class="div-button-center" mat-dialog-actions>
        <button mat-raised-button 
                color   = "primary" 
                class   = "button-width" 
                *ngIf   = "type_action_de_masse == CONST_CODE_TYPE_ACTION_SUPPRESSION &&!waitingApi" 
                (click) = "onSuppressionMasse()">
            <mat-icon>delete</mat-icon>
            <span class="span-button">Supprimer</span>
        </button>
        <button mat-raised-button
                color   = "primary" 
                class   = "button-width" 
                *ngIf   = "type_action_de_masse == CONST_CODE_TYPE_ACTION_DESACTIVATION &&!waitingApi" 
                (click) = "onDesactivationMasse()">
            <mat-icon>close</mat-icon>
            <span class="span-button">Désactiver</span>
        </button>
        <button mat-raised-button 
                color   = "primary" 
                class   = "button-width" 
                *ngIf   = "type_action_de_masse == CONST_CODE_TYPE_ACTION_ACTIVATION &&!waitingApi" 
                (click) = "onActivationMasse()">
            <mat-icon>check</mat-icon>
            <span class="span-button">Activer</span>
        </button>
        <button mat-button color="warn" [mat-dialog-close]="action_confirmee" class="button-width">Fermer</button>
        <mat-spinner diameter="24" *ngIf="waitingApi"></mat-spinner>
    </div>
</div>