import { Injectable }   from '@angular/core';
import { HttpClient }   from '@angular/common/http';
import { Observable }   from 'rxjs';
import { Fournisseur }  from '../interfaces/commande/fournisseur/fournisseur';
import { FiltreRechercheFournisseur } from '../interfaces/commande/fournisseur/filtre-recherche-fournisseur';

@Injectable({
  providedIn: 'root'
})
export class ApiFournisseurService {

  url: string = '/api';

  constructor(private http : HttpClient) { }

  // Récupérer la liste des fournisseurs
  public getSupplier() : Observable<Fournisseur[]> {
    // return this.http.get<Fournisseur[]>( this.url +'/fournisseur')
    let filtre_recherche: FiltreRechercheFournisseur = <FiltreRechercheFournisseur>{};

    return this.putSearchingSupplierByFilter(filtre_recherche);
  }
  
  // Récupérer le fournisseur depuis son Id
  public getSupplierById(id: number) : Observable<Fournisseur> {
    return this.http.get<Fournisseur>( this.url +'/fournisseur/'+id)
  }

  // Récupérer une liste de fournisseur en fonction de critère de recherche
  public putSearchingSupplierByFilter(filtre_recherche: FiltreRechercheFournisseur) : Observable<Fournisseur[]> {
    return this.http.put<Fournisseur[]>( this.url +'/fournisseur', filtre_recherche)
  }

  // Modification d'un fournisseur
  public putSupplier(fournisseur: Fournisseur) : Observable<Fournisseur> {
    return this.http.put<Fournisseur>(this.url+'/fournisseur/'+fournisseur.id, fournisseur)
  }

  // Création d'un fournisseur
  public postSupplier(fournisseur: Fournisseur) : Observable<Fournisseur> {
    return this.http.post<Fournisseur>(this.url+'/fournisseur', fournisseur)
  }
}
