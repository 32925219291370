import { NgFor, NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { forkJoin } from 'rxjs';
import { Environnement } from 'src/app/interfaces/utilitaire/environnement';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { ApiUtilitaireService } from 'src/app/services/tools/api-utilitaire.service';
import { OuiNonAnnulerComponent } from '../../templates/oui-non-annuler/oui-non-annuler.component';
import { GroupeService } from '../data-access/groupe.service';
import { Droit, GpeUtlDrt, Groupe, GroupeInit } from '../groupe-interface';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { InitTypeEntite, TypeEntite } from 'src/app/interfaces/profil-user/type-entite';

@Component({
    selector: 'app-groupe-shell',
    templateUrl: './groupe-shell.component.html',
    styleUrls: ['./groupe-shell.component.scss'],
    standalone: true,
    imports: [NgIf, MatCardModule, MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, MatButtonModule
              , MatIconModule, MatTooltipModule, MatInputModule, ReactiveFormsModule, FormsModule
              , MatProgressSpinnerModule, MatListModule]
})
export class GroupeShellComponent implements OnInit {
  
  groupes         : Groupe[]              = []
  droits          : Droit[]               = []
  selectedGroup   : Groupe                = GroupeInit
  edition         : boolean               = false
  groupeInit                              = GroupeInit
  waiting         : boolean               = false
  environnements : Environnement[]        = []
  idEnv           : number                = 0;
  idsType         : number[]                = [];
  waitingGroupe   : boolean               = false
  FormDroit : FormGroup = new FormGroup({
    droitsListe: new FormControl([])

  })
  
  typesEntite : TypeEntite[] = []

  constructor(private apiGroupe:GroupeService
              , private apiToast  : PersoSnackbarService
              , private  dialog   : MatDialog
              , private apiUti    : ApiUtilitaireService
            ) { }

  ngOnInit(): void {
    forkJoin([
              this.apiGroupe.getGroupes("", true,true)
              ,this.apiGroupe.getDroits()
              , this.apiUti.getEnvironnement()
            ]
              ).subscribe(([groupes,droits,environnements])=>{
                this.groupes = groupes
                this.droits = droits
                this.environnements = environnements
              })
  }

  setSelectedGroup(groupe:Groupe){
    this.selectedGroup = groupe
  }

  getSelectedDroitGroupe(droit:Droit){
    const numIndex:number = this.selectedGroup?.droits.findIndex((droitGp:Droit)=>droitGp.id === droit.id ) as number
    return numIndex>=0? true:false
  }

  editGroup(groupe:Groupe){
    this.selectedGroup  = groupe
    this.edition = true
  }

  deleteGroup(groupeASupprimer:Groupe){
    const dialogOuiNon = this.dialog.open(OuiNonAnnulerComponent, { 
      width: '320px', 
      data: { 
        title: "",
        message: "Voulez-vous vraiment supprimer le groupe " + groupeASupprimer.nom + " ?"        
        , yes_no_cancel: 2 
      } 
    });
    dialogOuiNon.afterClosed()
   .subscribe(
     res => {
      switch (res) {
         case true:
          this.apiGroupe.deleteGroup(groupeASupprimer.id).subscribe(
            (data:any)=> {
            this.groupes = this.groupes.filter((groupe:Groupe)=> groupe.id!=groupeASupprimer.id)
            }
            ,(error)=>this.apiToast.showError(error.error)
          )       
           break;
        default:
           break;
       }
   });
    
  }
  selectGroup(){
    this.idEnv = this.selectedGroup.idenv
    this.FormDroit.controls.droitsListe.reset()
    this.FormDroit.controls.droitsListe.patchValue(this.selectedGroup.droits.map((droit:Droit)=>droit.id))
  }
  
  cancel(){
    this.edition = false
  }

  validateGroup(){
    this.waitingGroupe=true
    this.selectedGroup.idenv = this.idEnv
    
    if (this.selectedGroup?.id===0) {
      this.apiGroupe.postGroupe(this.selectedGroup).subscribe(
        (groupe:Groupe)=> {
          this.waitingGroupe = false
          this.groupes = [...this.groupes,groupe]
      }
        
      )
    }else{
      this.apiGroupe.putGroupe(this.selectedGroup).subscribe(
        (groupeModifie:Groupe)=>{
          this.waitingGroupe = false
          this.groupes = this.groupes.map((groupe:Groupe)=>groupeModifie.id===groupe.id? groupeModifie : groupe)
        }
        ,(error)=>console.log(error.error)
        
      )
    }
    this.edition = false
  }

  validateDroits(){
    this.waiting = true
    
    let gpeUtlDrt:GpeUtlDrt = {'groupe':this.selectedGroup.id,'droits':this.FormDroit.controls.droitsListe.value.toString()}
    
    this.apiGroupe.putDroits(gpeUtlDrt).subscribe(
      (groupeModifie:Groupe)=>{
        this.waiting = false
        this.apiToast.showInfo('Vos modifications ont bien été prises en compte')
        this.groupes = this.groupes.map((groupe:Groupe)=> groupe.id!=groupeModifie.id? groupe : groupeModifie)
        this.selectedGroup= groupeModifie
      },
      (error)=>this.waiting = false
    )
  }
}
