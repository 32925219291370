import { Component, OnInit } from '@angular/core';
import { ApiAdelaService } from 'src/app/services/api-adela.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { Etiquette } from 'src/app/interfaces/adela/etiquette';
import { FormBuilder, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { saveAs } from 'file-saver';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-dialog-etiquette-adela-confirmation',
    templateUrl: './dialog-etiquette-adela-confirmation.component.html',
    styleUrls: ['./dialog-etiquette-adela-confirmation.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, NgIf, MatButtonModule, MatDialogModule]
})
export class DialogEtiquetteAdelaConfirmationComponent implements OnInit {

  get quantite()  { return this.formGroupQuantite.get('quantite'); }
  get copie()     { return this.formGroupQuantite.get('copie'); }
  
  formGroupQuantite : any;
  etiquette         : Etiquette = <Etiquette>{};
  nombre            : number    = 0;


  constructor(private apiAdela  : ApiAdelaService
              ,private _fb      : FormBuilder
              ,private toast    : PersoSnackbarService) { }

  ngOnInit(): void {
        this.formGroupQuantite = this._fb.group({
          quantite  : [1, [Validators.required, this.verificationFormatQuantite]],
          copie     : [1, [Validators.required, this.verificationFormatCopie]]          
        });
  }

  verificationFormatQuantite(fcControler: FormControl): any {
    let quantite : number = fcControler.value;
    
    if (quantite <= 0) {      
      return { erreur: 'Le nombre d\'étiquette doit être compris entre 1 et 100' }
    }

    return null;
  }

  verificationFormatCopie(fcControler: FormControl): any {
    let copie : number = fcControler.value;
    
    if (copie <= 0) {      
      return { erreur: 'Le nombre de copie doit être compris entre 1 et 3' }
    }

    return null;
  }

  onDeclineImpression(){
    this.toast.showInfo("Impression annulée");
  }

  onAcceptImpression(){    
    if(this.etiquette.id! > 0) {
      this.modifierEtiquette();
    } else {
      this.creerEtiquette();
    }    
  }

  modifierEtiquette(){    
    this.apiAdela.putEtiquette(this.etiquette.id!, this.formGroupQuantite.controls.quantite.value, this.formGroupQuantite.controls.copie.value, this.etiquette)
      .subscribe(
        (data: any) => { 
          saveAs(data, `etiquettes.pdf`);         
          this.toast.showInfo("Étiquette créée.");
        },
        (err: any) => { 
          '/!\\ error getEtiquettes: ' + console.log(err);
        }
      );
  }

  creerEtiquette(){
    this.apiAdela.postEtiquette(this.etiquette, this.formGroupQuantite.controls.quantite.value, this.formGroupQuantite.controls.copie.value)
      .subscribe(
        (data: any) => {     
          saveAs(data, `etiquettes.pdf`);      
          this.toast.showInfo("Étiquette créée.");
        },
        (err: any) => { 
          '/!\\ error getEtiquettes: ' + console.log(err);
        },
        () => { console.log('complete: getEtiquettes') }
      );        
  }
}
