import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { CheckValidator } from 'src/app/validators/check.validator';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    standalone: true,
    imports: [NgIf, MatCardModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatIconModule, MatTooltipModule, MatButtonModule]
})
export class ResetPasswordComponent implements OnInit {
  token                     : string = '';   
  id                        : any = 0;
  btokenValide              : boolean = false;
  innerWidth                : any;
  innerHeight               : any;
  root                      : string = '';
  hidePasswordNewPassword   : boolean = true;
  hidePasswordConfirmPassword: boolean = true;

  get new() { return this.updateForm.get('new'); }
  get confirmation() { return this.updateForm.get('confirmation'); }
  
  updateForm = this.fBuilder.group({
    new           : new FormControl('', [Validators.required, this.checkValidator.verificationFormatMotDePasse()]),
    confirmation  : new FormControl('', [Validators.required, this.checkValidator.verificationFormatMotDePasse()])
  });

  constructor(
    private routed: ActivatedRoute, 
    private fBuilder: FormBuilder, 
    private authService: ApiAuthentificationService, 
    private toast: PersoSnackbarService,
    private checkValidator : CheckValidator
  ) { }

  ngOnInit(): void { 
    
    // Récupération du token depuis l'url (reçu par mail) => 
    // .../login/reset-password/...
    this.token        = this.routed.snapshot.params['id_token'];
    this.innerWidth   = window.innerWidth;
    this.innerHeight  = window.innerHeight;

    if ( this.token != undefined && this.token != '' ) {
      this.authService.resetPassword(this.token).subscribe(
        (resultAuth: boolean) => { 
          this.btokenValide = true;
          this.id           = this.authService.authUser.id;   
          this.root         = this.authService.authUser.root;       
        },
        (err) => { 
          this.toast.showAlert("Le lien utilisé est obsolète.");
          this.authService.navigateToLoginPage();
        },
        () => {}
      )
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
  }

  public isMobileFormat() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    if (this.innerWidth <= 500 || this.innerHeight <= 500) {
      return true;
    } else {
      return false;
    }
  }

  
  updatePassword(){  
    if (this.id > 0){          
      this.authService.putPasswordUtilisateur(
                                              this.id, 
                                              this.token, 
                                              '',
                                              this.updateForm.value.new? this.updateForm.value.new : '',
                                              this.updateForm.value.confirmation? this.updateForm.value.confirmation :'')
      .subscribe(
        (succes: boolean) => {
          this.authService.navigateToLoginPage();
          this.toast.showInfo("Mot de passe mis à jour.");
        }
        ,(err) => { 
          this.toast.showError(err.message);
        }
        ,() => { }
      );  
    }
  }
}

