<mat-card>
    <mat-card-title>{{ titre_page }}</mat-card-title>
    <mat-card-subtitle>{{ sous_titre_page }}</mat-card-subtitle>
    <mat-card-content>

        <table mat-table matTableResponsiveLarge [dataSource]="dataSourceMatTable" class="table-primary">
    
            <!-- Référence type demande --> 
            <ng-container matColumnDef="typ_libelle">
                <th mat-header-cell *matHeaderCellDef > Type demande </th>
                <td mat-cell *matCellDef="let type_demande" > {{ type_demande.libelle }} </td> 
            </ng-container>                        
    
            <!-- Résultat de retour -->
            <ng-container matColumnDef="typ_resultat">
                <th mat-header-cell *matHeaderCellDef> Résultat </th>
                <td mat-cell *matCellDef="let type_demande" > {{ type_demande.resultat }} </td> 
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5,10,25]"
            showFirstLastButtons 
            aria-label="Nombre de lignes visibles...">
        </mat-paginator>
    </mat-card-content> 
    <mat-card-actions align="end">
        <button mat-raised-button color="primary" class="button-width" *ngIf="type_action_de_masse == CONST_CODE_TYPE_ACTION_SUPPRESSION && !waitingApi" (click)="onSuppressionMasse()">
            <mat-icon>delete</mat-icon>
            <span class="span-button">Supprimer</span>
        </button>
        <button mat-raised-button color="primary" class="button-width" *ngIf="type_action_de_masse == CONST_CODE_TYPE_ACTION_DESACTIVATION && !waitingApi" (click)="onDesactivationMasse()">
            <mat-icon>close</mat-icon>
            <span class="span-button">Désactiver</span>
        </button>
        <button mat-raised-button color="primary" class="button-width" *ngIf="type_action_de_masse == CONST_CODE_TYPE_ACTION_ACTIVATION && !waitingApi" (click)="onActivationMasse()">
            <mat-icon>check</mat-icon>
            <span class="span-button">Activer</span>
        </button>
        <button mat-button color="warn" [mat-dialog-close]="action_confirmee" class="button-width">Fermer</button>
        <mat-spinner diameter="24" *ngIf="waitingApi"></mat-spinner>
    </mat-card-actions>
    

</mat-card>