import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { environment } from 'src/environments/environment';
import { RDEAFile } from '../components/rdea/interfaces/rdeaFile';
import { Observable } from 'rxjs';
import { DossierConcentrateur, Deficience, ZoneChoc, ZoneChocInit, DossierConcentrateurInit } from '../interfaces/concentrateur';

@Injectable({
  providedIn: 'root'
})
export class ApiConcentrateurService {

  apiUrl:string = 'api/rdea'
  private http: HttpClient;
  apiAuth = inject(ApiAuthentificationService)

  constructor(private handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }

  formatErrors(erreur:string){
    let erreurs : string[] = erreur.split(/\r?\n|\r|\n/g)
    return erreurs
  }
  sendFile(rdeaFile:RDEAFile,entite:Entite){
    
    const password      = entite.acces_concentrateur.mot_de_passe
    const login         = entite.acces_concentrateur.habilitation
    const authorization = btoa(login + ':' + password)
    const header        = {headers:new HttpHeaders().set('Authorization',`Basic ` +authorization).set('Referrer-Policy','same-origin')}
    const rdeaJson      = this.getConcentrateurDossier(rdeaFile,entite)

    return this.http.post(environment.urlApiConcentrateur+'dossier', rdeaJson, header)
  }

  private getConcentrateurDossier(rdeaFile:RDEAFile,entite:Entite) : DossierConcentrateur{
    let pointsChocs           : ZoneChoc[] = [];
    let deficiences           : Deficience[] = [];
    let dossierConcentrateur  : DossierConcentrateur = {...DossierConcentrateurInit};

    if (rdeaFile.expertise.chocArriere) {
      this.setPointChoc('chocArriere',pointsChocs)
    }

    if (rdeaFile.expertise.chocArriereD) {
      this.setPointChoc('chocArriereD',pointsChocs)
    }

    if (rdeaFile.expertise.chocLateralD) {
      this.setPointChoc('chocLateralD',pointsChocs)
    }
    
    if (rdeaFile.expertise.chocAvantD) {
      this.setPointChoc('chocAvantD',pointsChocs)
    }
    
    if (rdeaFile.expertise.chocArriereG) {
      this.setPointChoc('chocArriereG',pointsChocs)
    }
    
    if (rdeaFile.expertise.chocLateralG) {
      this.setPointChoc('chocLateralG',pointsChocs)
    }
    
    if (rdeaFile.expertise.chocAvantG) {
      this.setPointChoc('chocAvantG',pointsChocs)
    }
    
    if (rdeaFile.expertise.chocAvant) {
      this.setPointChoc('chocAvant',pointsChocs)
    }
    
    if (rdeaFile.expertise.chocHabitacle) {
      this.setPointChoc('chocHabitacle',pointsChocs)
    }
    
    if (rdeaFile.expertise.chocMoteurBvPonts) {
      this.setPointChoc('chocMoteurBvPonts',pointsChocs)
    }
    
    if (rdeaFile.expertise.chocTrainsRoulants) {
      this.setPointChoc('chocTrainsRoulants',pointsChocs)
    }
    
    if (rdeaFile.expertise.chocSoubassement) {
      this.setPointChoc('chocSoubassement',pointsChocs)
    }
    
    if (rdeaFile.expertise.chocDessus) {
      this.setPointChoc('chocDessus',pointsChocs)
    }
    
    if (rdeaFile.expertise.ensemble) {
      this.setPointChoc('ensemble',pointsChocs)
    }
    

    //Deficiences:
    let dateDangerosite:Date|null=null
    
    if (rdeaFile.conclusion.dangereux==1) {
      dateDangerosite = rdeaFile.information.dateEffet
    }

    if (rdeaFile.conclusion.ca3) {
      this.setDeficience('ca3',deficiences,dateDangerosite)
    }
    if (rdeaFile.conclusion.d3) {
      this.setDeficience('d3',deficiences,dateDangerosite)
    }
    if (rdeaFile.conclusion.ls3) {
      this.setDeficience('ls3',deficiences,dateDangerosite)
    }
    if (rdeaFile.conclusion.sp4) {
      this.setDeficience('sp4',deficiences,dateDangerosite)
    }
    if (rdeaFile.conclusion.en5) {
      this.setDeficience('en5',deficiences,dateDangerosite)
    }

    if (pointsChocs.length > 0) {
      dossierConcentrateur.expertise.points_de_choc = [];
      dossierConcentrateur.expertise.points_de_choc = dossierConcentrateur.expertise.points_de_choc.concat(pointsChocs);  
    }
    if (deficiences.length > 0) {
      dossierConcentrateur.expertise.deficience = [];
      dossierConcentrateur.expertise.deficience = dossierConcentrateur.expertise.deficience.concat(deficiences);
    }
    
    dossierConcentrateur.expertise.sinistre.code_nature_evenement = rdeaFile.reference.natureEvenement;
    dossierConcentrateur.expertise.sinistre.code_nature_adverse = rdeaFile.reference.natureAdverse;
    dossierConcentrateur.expertise.sinistre.date_evenement = rdeaFile.reference.dateSinistre;
    
    const type:string=rdeaFile.information.type.toString()
    
    switch (type) {
      case "1":
        dossierConcentrateur.expertise.type_operation   = 'declaration';
        dossierConcentrateur.expertise.date_examen_1A   = rdeaFile.information.dateEffet;
        dossierConcentrateur.expertise.date_rapport_1A  = new Date()
        dossierConcentrateur.expertise.dangereux_1A     = rdeaFile.conclusion.dangereux==1? 'oui' : 'non'
        dossierConcentrateur.expertise.reparable_1A     = rdeaFile.conclusion.techReparable==1? 'oui' : 'non'
        dossierConcentrateur.expertise.peut_circuler    = 'nc';

        dossierConcentrateur.vehicule.constatation_technique.demande_fo = 'non';

        delete dossierConcentrateur.expertise.date_rapport_1B;
        delete dossierConcentrateur.expertise.date_examen_1B;
        delete dossierConcentrateur.expertise.date_rapport_1C;
        delete dossierConcentrateur.expertise.date_examen_1C;
        delete dossierConcentrateur.expertise.dangereux_1B;
        delete dossierConcentrateur.expertise.reparable_1B;

        break;
    
      case "2":
        dossierConcentrateur.expertise.type_operation   = 'premier';
        dossierConcentrateur.expertise.date_rapport_1B  = rdeaFile.information.dateEffet;
        dossierConcentrateur.expertise.date_examen_1B   = rdeaFile.information.dateEffet;
        dossierConcentrateur.expertise.dangereux_1B     = rdeaFile.conclusion.dangereux==1? 'oui' : 'non';
        dossierConcentrateur.expertise.reparable_1B     = rdeaFile.conclusion.techReparable==1? 'oui' : 'non';
        dossierConcentrateur.expertise.peut_circuler    = 'nc';

        dossierConcentrateur.vehicule.constatation_technique.demande_fo = 'oui';

        delete dossierConcentrateur.expertise.date_rapport_1A;
        delete dossierConcentrateur.expertise.date_examen_1A;
        delete dossierConcentrateur.expertise.date_rapport_1C;
        delete dossierConcentrateur.expertise.date_examen_1C;
        delete dossierConcentrateur.expertise.dangereux_1A;
        delete dossierConcentrateur.expertise.reparable_1A;
        break;
        
      case "3":
        dossierConcentrateur.expertise.type_operation   = 'premier';
        dossierConcentrateur.expertise.date_rapport_1B  = rdeaFile.information.dateEffet;
        dossierConcentrateur.expertise.date_examen_1B   = new Date();
        dossierConcentrateur.expertise.dangereux_1B     = rdeaFile.conclusion.dangereux==1? 'oui' : 'non';
        dossierConcentrateur.expertise.reparable_1B     = rdeaFile.conclusion.techReparable==1? 'oui' : 'non';
        dossierConcentrateur.expertise.peut_circuler    = 'nc';

        delete dossierConcentrateur.expertise.date_rapport_1A;
        delete dossierConcentrateur.expertise.date_examen_1A;
        delete dossierConcentrateur.expertise.date_rapport_1C;
        delete dossierConcentrateur.expertise.date_examen_1C;
        delete dossierConcentrateur.expertise.dangereux_1A;
        delete dossierConcentrateur.expertise.reparable_1A;

        dossierConcentrateur.vehicule.constatation_technique.demande_fo = 'non';

        break;
          
      case "4":
        dossierConcentrateur.expertise.type_operation  = 'deuxieme';
        dossierConcentrateur.expertise.date_rapport_1C = rdeaFile.information.dateEffet;
        dossierConcentrateur.expertise.date_examen_1C  = rdeaFile.information.dateEffet;
        dossierConcentrateur.expertise.peut_circuler   = 'oui'
        dossierConcentrateur.vehicule.constatation_technique.demande_fo = 'non';

        delete dossierConcentrateur.expertise.date_rapport_1A;
        delete dossierConcentrateur.expertise.date_examen_1A;
        delete dossierConcentrateur.expertise.date_rapport_1B;
        delete dossierConcentrateur.expertise.date_examen_1B;
        delete dossierConcentrateur.expertise.dangereux_1A;
        delete dossierConcentrateur.expertise.reparable_1A;
        delete dossierConcentrateur.expertise.dangereux_1B;
        delete dossierConcentrateur.expertise.reparable_1B;
        break;
            
      default:
        break;
    }

    dossierConcentrateur.control.code_partenaire  = entite.habilitation;
    dossierConcentrateur.control.code_expert      = this.apiAuth.userConnect.agrement_ve;

    dossierConcentrateur.vehicule.certificat_present      = "oui";
    dossierConcentrateur.vehicule.numero_immatriculation  = rdeaFile.technique.immatriculation;
    dossierConcentrateur.vehicule.numero_vin              = rdeaFile.technique.vin;
    dossierConcentrateur.vehicule.numero_formule          = rdeaFile.technique.numeroFormule;
    dossierConcentrateur.vehicule.date_certificat         = rdeaFile.technique.dateCi;
    dossierConcentrateur.vehicule.numero_dossier          = rdeaFile.reference.numeroDossier;

    dossierConcentrateur.vehicule.constatation_technique.date_immatriculation           = rdeaFile.technique.dateMec;
    dossierConcentrateur.vehicule.constatation_technique.type_mines                     = rdeaFile.technique.typeMine;
    dossierConcentrateur.vehicule.constatation_technique.code_marque                    = rdeaFile.technique.marque;
    dossierConcentrateur.vehicule.constatation_technique.modele                         = rdeaFile.technique.modele;
    dossierConcentrateur.vehicule.constatation_technique.code_genre                     = rdeaFile.technique.genre;
    dossierConcentrateur.vehicule.constatation_technique.code_carrosserie               = rdeaFile.technique.carrosserie;
    dossierConcentrateur.vehicule.constatation_technique.code_energie                   = rdeaFile.technique.energie;
    dossierConcentrateur.vehicule.constatation_technique.code_couleur                   = rdeaFile.technique.couleur;
    dossierConcentrateur.vehicule.constatation_technique.usure_pneumatique_AVD          = rdeaFile.expertise.usureAvd;
    dossierConcentrateur.vehicule.constatation_technique.usure_pneumatique_AVG          = rdeaFile.expertise.usureAvg;
    dossierConcentrateur.vehicule.constatation_technique.usure_pneumatique_ARD_AVANT    = rdeaFile.expertise.usureArd;
    dossierConcentrateur.vehicule.constatation_technique.usure_pneumatique_ARD_ARRIERE  = rdeaFile.expertise.usureArd2;
    dossierConcentrateur.vehicule.constatation_technique.usure_pneumatique_ARG_AVANT    = rdeaFile.expertise.usureArg;
    dossierConcentrateur.vehicule.constatation_technique.usure_pneumatique_ARG_ARRIERE  = rdeaFile.expertise.usureArg2;
    dossierConcentrateur.vehicule.constatation_technique.reparabilite_economique        = rdeaFile.conclusion.ecoReparable==1? 'oui' :'non'
    dossierConcentrateur.vehicule.constatation_technique.reparabilite_technique         = rdeaFile.conclusion.techReparable==1? 'oui' :'non'
    if (rdeaFile.technique.puissanceReelle > 0) {
      dossierConcentrateur.vehicule.constatation_technique.puissance_reelle     = rdeaFile.technique.puissanceReelle;
    } else {
      delete dossierConcentrateur.vehicule.constatation_technique.puissance_reelle;
    }
    if (rdeaFile.technique.puissanceFiscale > 0) {
      dossierConcentrateur.vehicule.constatation_technique.puissance_fiscale     = rdeaFile.technique.puissanceFiscale;
    } else {
      delete dossierConcentrateur.vehicule.constatation_technique.puissance_fiscale;
    }
    if (rdeaFile.technique.kilometrage > 0) {
      dossierConcentrateur.vehicule.constatation_technique.kilometrage     = rdeaFile.technique.kilometrage;
    } else {
      delete dossierConcentrateur.vehicule.constatation_technique.kilometrage;
    }

    if (pointsChocs.length == 0 && rdeaFile.expertise.totalHt == 0 && rdeaFile.expertise.piecesHt == 0) {
      delete dossierConcentrateur.expertise.reparation_globale
    } else {
      dossierConcentrateur.expertise.reparation_globale!.nb_points_choc        = pointsChocs.length;
      dossierConcentrateur.expertise.reparation_globale!.totalHT               = rdeaFile.expertise.totalHt;
      dossierConcentrateur.expertise.reparation_globale!.sous_total_HT_pieces  = rdeaFile.expertise.piecesHt;
    }

    if (rdeaFile.expertise.valeurTotaleHt == 0 && rdeaFile.expertise.valeurTotaleTtc == 0) {
      delete dossierConcentrateur.expertise.valeur_vehicule;
    } else {
      dossierConcentrateur.expertise.valeur_vehicule!.valeur_totale_HT_vehicule  = rdeaFile.expertise.valeurTotaleHt;
      dossierConcentrateur.expertise.valeur_vehicule!.valeur_totale_TTC_vehicule = rdeaFile.expertise.valeurTotaleTtc;
    }

    return dossierConcentrateur
  }

  private setDeficience(formControlName:string,deficiences:Deficience[],date:Date|null){
    let deficience:Deficience={'code_dangerosite':'','deficience_imputable':'','date_dangerosite':date }

    switch (formControlName) {
      case 'ca3':
        deficience.code_dangerosite     = 'CA3'
        deficience.deficience_imputable = 'oui'
        break;

      case 'd3':
        deficience.code_dangerosite     = 'DI3'
        deficience.deficience_imputable = 'oui'
        break;

      case 'ls3':
        deficience.code_dangerosite     = 'LS3'
        deficience.deficience_imputable = 'oui'
        break;

      case 'sp4':
        deficience.code_dangerosite     = 'SP4'
        deficience.deficience_imputable = 'oui'
        break;

      case 'en5':
        deficience.code_dangerosite     = 'EN5'
        deficience.deficience_imputable = 'oui'
        break;
    
      default:
        break;
    }

    if (deficience.code_dangerosite!='') {
      deficiences.push(deficience)
    }
  }

  private setPointChoc(formControlName:string,pointsChocs:ZoneChoc[]){
    // let pointChoc: ZoneChoc = ZoneChocInit;
    let pointChoc: ZoneChoc = {...ZoneChocInit};

    switch (formControlName) {
      case 'ensemble':
        pointChoc.code_loc_point_choc   = 'TAPC11'
        pointChoc.code_zone_deformation = 'TAZD11' 
        pointChoc.designation_choc      = 'ENSEMBLE'
        break;
      
      case 'chocDessus':
        pointChoc.code_loc_point_choc   = 'TAPC09'
        pointChoc.code_zone_deformation = 'TAZD09' 
        pointChoc.designation_choc      = 'DESSUS'
        break;
      
      case 'chocSoubassement':
        pointChoc.code_loc_point_choc   = 'TAPC10'
        pointChoc.code_zone_deformation = 'TAZD10' 
        pointChoc.designation_choc      = 'SOUBASSEMENT'
        break;

      case 'chocTrainsRoulants':
        pointChoc.code_loc_point_choc   = 'TAPC12'
        pointChoc.code_zone_deformation = 'TAZD12' 
        pointChoc.designation_choc      = 'TRAINS ROULANTS'
        break;
      
      case 'chocMoteurBvPonts':
        pointChoc.code_loc_point_choc   = 'TAPC13'
        pointChoc.code_zone_deformation = 'TAZD13' 
        pointChoc.designation_choc      = 'MOTEUR/BV/PONTS'
        break;
    
      case 'chocHabitacle':
        pointChoc.code_loc_point_choc   = 'TAPC14'
        pointChoc.code_zone_deformation = 'TAZD14' 
        pointChoc.designation_choc      = 'HABITACLE'
        break;
  
      case 'chocAvant':
        pointChoc.code_loc_point_choc   = 'TAPC01'
        pointChoc.code_zone_deformation = 'TAZD01' 
        pointChoc.designation_choc      = 'AVANT'
        break;
    
      case 'chocAvantG':
        pointChoc.code_loc_point_choc   = 'TAPC06'
        pointChoc.code_zone_deformation = 'TAZD06' 
        pointChoc.designation_choc      = 'TIERS AVANT GAUCHE'
        break;

      case 'chocLateralG':
        pointChoc.code_loc_point_choc   = 'TAPC07'
        pointChoc.code_zone_deformation = 'TAZD07' 
        pointChoc.designation_choc      = 'CENTRAL GAUCHE'
        break;

      case 'chocArriereG':
        pointChoc.code_loc_point_choc   = 'TAPC08'
        pointChoc.code_zone_deformation = 'TAZD08' 
        pointChoc.designation_choc      = 'TIERS ARRIERE GAUCHE'
        break;

      case 'chocAvantD':
        pointChoc.code_loc_point_choc   = 'TAPC02'
        pointChoc.code_zone_deformation = 'TAZD02' 
        pointChoc.designation_choc      = 'TIERS AVANT DROIT'
        break;

      case 'chocLateralD':
        pointChoc.code_loc_point_choc   = 'TAPC03'
        pointChoc.code_zone_deformation = 'TAZD03' 
        pointChoc.designation_choc      = 'CENTRAL DROIT'
        break;

      case 'chocArriereD':
        pointChoc.code_loc_point_choc   = 'TAPC04'
        pointChoc.code_zone_deformation = 'TAZD04' 
        pointChoc.designation_choc      = 'TIERS ARRIERE DROIT'
        break;

      case 'chocArriere':
        pointChoc.code_loc_point_choc   = 'TAPC05'
        pointChoc.code_zone_deformation = 'TAZD05' 
        pointChoc.designation_choc      = 'ARRIERE'
        break;

      case '' :
        pointChoc.code_loc_point_choc   = 'TAPC15'
        pointChoc.code_zone_deformation = 'TAZD15' 
        pointChoc.designation_choc      = 'NON PRECISE'
        break;

      default:
        break;
    }

    if (pointChoc.code_loc_point_choc!='') {
      pointsChocs.push(pointChoc)
    }
  }

  public remettreDispositionAcquittement(idSession: number, entite:Entite): Observable<any> {
    const password      = entite.acces_concentrateur.mot_de_passe
    const login         = entite.acces_concentrateur.habilitation
    const authorization = btoa(login + ':' + password)
    const header        = {headers:new HttpHeaders().set('Authorization','Basic '+authorization).set('Referrer-Policy','same-origin')}

    return this.http.get<any>(environment.urlApiConcentrateur+'acquittement/remisedisposition/'+idSession, header)
  }
}
