<mat-expansion-panel style="margin-top: 16px !important;" [(expanded)]="bExpandPannel">

    <mat-expansion-panel-header>
      <mat-panel-title>Modifier votre mot de passe</mat-panel-title>
    </mat-expansion-panel-header>

       

            <form [formGroup]="updateForm" (ngSubmit)="updatePassword()">
              
                <div class="div-container-between" style="align-items: center;">
                    <mat-form-field>
                        <mat-label>Ancien mot de passe</mat-label>
                        <input 
                            matInput 
                            formControlName = "current"  
                            placeholder     = "Ancien mot de passe" 
                            [type]          = "hideOldPassword ? 'password' : 'text'" >
                        <mat-icon 
                            matSuffix
                            mat-icon-button color   = "primary" 
                            [matTooltip]            = "!hideOldPassword ? 'Masquer l\'ancien mot de passe' : 'Afficher l\'ancien mot de passe'" 
                            (click)                 = "hideOldPassword = !hideOldPassword"
                            style                   = "cursor: pointer"
                        >    
                                {{!hideOldPassword ? 'visibility_off' : 'visibility'}}    
                        </mat-icon>
                    </mat-form-field>    
                </div>
                <div class="div-container-between" style="align-items: center;">
                    <mat-form-field>
                        <mat-label>Nouveau mot de passe</mat-label>
                        <input 
                            matInput 
                            formControlName = "new"  
                            placeholder     = "Nouveau mot de passe" 
                            [type]          = "hideNewPassword ? 'password' : 'text'" 
                        >
                        <mat-error *ngIf="new?.invalid && (new?.dirty || new?.touched)">{{confirmation?.errors?.erreur}}</mat-error>
                        <mat-icon 
                            mat-icon-button 
                            matSuffix
                            color           = "primary" 
                            [matTooltip]    ="!hideNewPassword ? 'Masquer le nouveau mot de passe' : 'Afficher le nouveau mot de passe'" 
                            (click)         = "hideNewPassword = !hideNewPassword"
                            style           = "cursor: pointer"
                        >
                            {{!hideNewPassword ? 'visibility_off' : 'visibility'}}
                        
                            </mat-icon>
                    </mat-form-field>    
                </div>

                <div class="div-container-between" style="align-items: center;">
                    <mat-form-field>
                        <mat-label>Confirmer mot de passe</mat-label>
                        <input 
                            matInput 
                            formControlName = "confirmation"  
                            placeholder     = "Confirmation du mot de passe" 
                            [type]          = "hideConfirmPassword ? 'password' : 'text'" 
                        >
                        <mat-error *ngIf="confirmation?.invalid && (confirmation?.dirty || confirmation?.touched)">{{confirmation?.errors?.erreur}}</mat-error>
                        <mat-icon 
                        matSuffix
                        mat-icon-button color   = "primary" 
                        [matTooltip]            = "!hideConfirmPassword ? 'Masquer le nouveau mot de passe' : 'Afficher le nouveau mot de passe'" 
                        (click)                 = "hideConfirmPassword = !hideConfirmPassword"
                        style                   = "cursor: pointer"
                        >
                        {{!hideConfirmPassword ? 'visibility_off' : 'visibility'}}                
                    </mat-icon>
                    </mat-form-field>    
                </div>
                <div class="div-container-between" style="align-items: center;">
                    <mat-hint>8 caractères minimum dont une majuscule, une minuscule, un nombre, un caractère spécial</mat-hint>
                </div>
                <div class="button">
                    <button mat-raised-button color="warn" type="button" (click)="cancelPassword()">Annuler</button>
                    <button mat-raised-button color="primary" style="margin-left: 10px;"
                    [disabled]="updateForm.pristine ||!(updateForm.valid && updateForm.dirty)">Mettre à jour</button>
                </div>
            </form>
        

</mat-expansion-panel>


