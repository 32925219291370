import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionSuspension, CST_STA_SUS_DEFINITIVE, CST_STA_SUS_FERMER, CST_STA_SUS_OUVRIR, CST_STA_SUS_RAPPEL1, CST_STA_SUS_RAPPEL2, CST_STA_SUS_RAPPEL3, Suspension, SuspensionInitiale, SuspensionRappel, TypeSuspension } from '../../interfaces/suspension';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { UploadParameter, initialUploadParameter } from 'src/app/components/templates/upload-files/upload-files.component';
import { UploadFile } from 'src/app/interfaces/fichier';
import { PIECEJOINTE } from 'src/app/interfaces/piece-jointe';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { PieceJointeComponent } from '../../../templates/piece-jointe/piece-jointe.component';
import { UploadFilesComponent } from '../../../templates/upload-files/upload-files.component';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { NgIf, NgFor, AsyncPipe, DatePipe } from '@angular/common';
import { NavigationStart, Router } from '@angular/router';


@Component({
    selector: 'app-edit-suspension',
    templateUrl: './edit-suspension.component.html',
    styleUrls: ['./edit-suspension.component.scss'],
    standalone: true,
    imports: [NgIf, ReactiveFormsModule, MatCardModule, MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, MatInputModule, UploadFilesComponent, PieceJointeComponent, MatGridListModule, MatButtonModule, MatTooltipModule, MatIconModule, AsyncPipe, DatePipe]
})
export class EditSuspensionComponent implements OnInit {

  @Input() suspension$!         : BehaviorSubject<Suspension> 
  @Input() typesSuspension$!    : Observable<TypeSuspension[]>
  @Input() waitingOperation$   : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  @Output() suspensionEvent     = new EventEmitter<Suspension | boolean>()
  @Output() clotureEvent        = new EventEmitter<SuspensionRappel>()
  @Output() rappelEvent         = new EventEmitter<SuspensionRappel>()

  title                       : string =''
  suspensionForm!              :FormGroup
  actions                     : ActionSuspension[]  =[]
  suspension                  : Suspension = SuspensionInitiale
  disabledRappel1             : boolean = true
  disabledRappel2             : boolean = true
  disabledRappel3             : boolean = true
  disabledClotureCabinet      : boolean = true
  disabledClotureSuspension   : boolean = false
  disabledTypeSuspension      : boolean = false
  uploadParameter             : UploadParameter = initialUploadParameter
  uploadFiles                 : UploadFile[]=[]
  isWaiting                   : boolean = false

  constructor(private formBuilder : FormBuilder
              , private router        : Router) { }

  ngOnInit(): void {
    
    this.suspension$.subscribe(
      (suspension:Suspension)=>{
        this.suspension = suspension
        
        if (this.suspension) {
          this.title  = this.suspension.id===0? 'Création d\'une suspension' : 'Modification d\'une suspension'
          this.disabledClotureSuspension = this.suspension.id===0? true : false
          this.isWaiting = false
        }
        
        this.initFormGroup()
        this.setEnabledButtons()
      }
      )
  }

  setEnabledButtons(){
    let found : boolean = false
    if (this.suspension.actions) {
      this.suspension.actions.every(
        (action:ActionSuspension)=>{
          
          found = true
          this.disabledTypeSuspension = true
          
          switch (action.code) {
            
            case CST_STA_SUS_OUVRIR:
              this.disabledRappel1 = false
              this.disabledTypeSuspension = false
              this.disabledClotureCabinet = true
              break;
            
            case CST_STA_SUS_RAPPEL1:
              this.disabledRappel1 = false
              this.disabledRappel2 = false
              this.disabledTypeSuspension = true
              this.disabledClotureCabinet = true
              break;
            
            case CST_STA_SUS_RAPPEL2:
              this.disabledRappel1 = true
              this.disabledRappel2 = false
              this.disabledRappel3 = false
              this.disabledTypeSuspension = true
              this.disabledClotureCabinet = true
              break;
              
            case CST_STA_SUS_RAPPEL3:
                this.disabledRappel1 = true
                this.disabledRappel2 = true
                this.disabledRappel3 = false
                this.disabledTypeSuspension = true
                this.disabledClotureCabinet = false
                break;
            
             case CST_STA_SUS_FERMER:
             case CST_STA_SUS_DEFINITIVE:
                this.disabledRappel1 = true
                this.disabledRappel2 = true
                this.disabledRappel3 = true
                this.disabledClotureCabinet = true
                this.disabledClotureSuspension = true
                this.disabledTypeSuspension = true
                break;
              
              default:
                found = false
              break;
          }
          return !found
        }
      )
    }
  }

  initFormGroup(){
    this.suspensionForm = this.formBuilder.group({
      note    : [this.suspension? this.suspension.note : ''],
      idType  : [this.suspension? this.suspension.idType : '',Validators.min(1)]
    })
    
  }

  saveSuspension(){
    this.isWaiting = true
   
    this.suspension.idType  = this.suspensionForm.controls['idType'].value
    this.suspension.note    = this.suspensionForm.controls['note'].value
    if (this.uploadFiles.length != 0) {
      this.uploadFiles.forEach((uploadFile:UploadFile)=>{
        let pj : PIECEJOINTE = <PIECEJOINTE>{}
          pj.contenu  = uploadFile.contenu? uploadFile.contenu : '';
          pj.nom      = uploadFile.file? uploadFile.file.name : '';
          pj.id       = 0;

          this.suspension.pjs.push(pj); 
      })
    }
    this.uploadFiles = []
    this.suspensionEvent.emit(this.suspension)
    
    
  }

  envoyerRappel(index:number){
    const rappel : SuspensionRappel ={'id':this.suspension.id,'index':index}
    this.rappelEvent.next(rappel)
  }

  fermeSuspension(){
    this.suspensionEvent.emit(false)
  }

  cloturerSuspension(){
    const cloture : SuspensionRappel = {'id':this.suspension.id,'index':1}
    this.clotureEvent.next(cloture)
  }

  cloturerCabinet(){
    const cloture : SuspensionRappel = {'id':this.suspension.id,'index':2}
    this.clotureEvent.next(cloture)
  }

  getFiles(files:UploadFile[]):void{
    this.uploadFiles = files;
  }
  
  getPjs(pjsSelected:PIECEJOINTE[]){
    
    pjsSelected.forEach(
      (pjSelected:PIECEJOINTE)=> this.suspension.pjs.map((pj:PIECEJOINTE)=> pj.id === pjSelected.id? pj.aSupprimer = pjSelected.aSupprimer : null)
    )
    
  }
  
}
