import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { MatButtonModule } from '@angular/material/button';
import { MatTableResponsiveLargeDirective } from '../../../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';
import { NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-table-liste-utilisateurs-services',
    templateUrl: './table-liste-utilisateurs-services.component.html',
    styleUrls: ['./table-liste-utilisateurs-services.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatExpansionModule, MatIconModule, NgIf, MatTableModule, MatTableResponsiveLargeDirective, MatButtonModule, MatPaginatorModule]
})
export class TableListeUtilisateursServicesComponent implements OnInit {
  
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;
  @Input() dataSource: any;
  
  dataSourceMatTable: MatTableDataSource<ProfilUtilisateur> = new MatTableDataSource<ProfilUtilisateur>();
  expandPannel        : boolean = true;
  displayedColumns    : Array<string> = ['utl_nom', 'utl_prenom', 'drt_code' , 'drt_acces_module_reparateur','drt_acces_anea_partage', 'actions'];

  constructor(private route: Router) { }

  ngOnInit(): void {
    if(this.dataSource != undefined) {
      this.dataSourceMatTable.data = this.dataSource;
    }
  }

  ngAfterViewInit() {
    this.dataSourceMatTable.paginator = this.paginator;
    this.dataSourceMatTable.sort = this.sort;
  }

  ngOnChanges() {
    // obligatoire
    if(this.dataSource != undefined) {
      this.dataSourceMatTable.data = this.dataSource;
    }     
  }

  public onModifier(utl_id: number): void {
    this.route.navigate(['/svc/profil', utl_id])
  }

}
