import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {  FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule, MatStepper  } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { Adresse, AdresseInit } from 'src/app/interfaces/adresse/adresse';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { EntiteTva } from 'src/app/interfaces/profil-user/entite-tva';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { TypeEntite } from 'src/app/interfaces/profil-user/type-entite';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { ApiUtilisateurService } from 'src/app/services/api-utilisateur.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { AgrementValidator } from 'src/app/validators/agrement.validator';
import { TelephoneDirective as TelephoneDirective_1 } from '../../directive/telephone.directive';
import { TitleCaseDirective } from '../../directive/title-case.directive';
import { GroupeService } from '../groupe/data-access/groupe.service';
import { Groupe } from '../groupe/groupe-interface';
import { AdresseComponent } from '../templates/adresse/adresse.component';
import { forkJoin } from 'rxjs';
import { InscriptionService } from '../../services/inscription.service';
import { SirenComponent } from '../templates/siren/siren.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TaskService } from 'src/app/services/task.service';
import { TASK } from 'src/app/interfaces/task.interface';
import { NewlineToBrPipe } from 'src/app/pipe/newline-to-br.pipe';
import { ProgressComponent } from '../templates/progress/progress.component';
import { MailValidator } from 'src/app/validators/mail.validator';
import { TelephoneValidator } from 'src/app/validators/telephone.validator';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import { UtilisateurInscription } from 'src/app/interfaces/profil-user/profil-utilisateur';

export interface entite_ARD {
  Entite: Entite;
  Employes: Array<ProfilUtilisateur>
}
export interface profil_ARD {
  profil: ProfilUtilisateur;
  Entites: Array<entite_ARD>
}
@Component({
    selector: 'app-inscription',
    templateUrl: './inscription.component.html',
    styleUrls: ['./inscription.component.scss'],
    standalone: true,
    imports: [MatSlideToggleModule, MatCardModule, MatStepperModule, NgClass, ReactiveFormsModule, MatIconModule, MatFormFieldModule, 
              MatInputModule, NgIf, TitleCaseDirective, TelephoneDirective_1, MatButtonModule, MatSelectModule, NgFor
              , MatOptionModule, AdresseComponent,SirenComponent,MatProgressSpinnerModule,NewlineToBrPipe,ProgressComponent],
    providers: [
                {
                  provide: STEPPER_GLOBAL_OPTIONS,
                  useValue: {showError: true},
                }]
})
export class InscriptionComponent implements OnInit {

  // Gestion de l'affichage du Stepper
  verrouillerStepper  : boolean = false;
  deplierStepper      : boolean = false;
  errorMessageStepper : string = 'Information(s) manquante(s) ou incorrecte(s)'

  // Variables récupérées dans l'URL
  idLogin : number = 0;
  idEntite: number = 0;
  password: string = '';

  // Chargement / Récupération des valeurs dans les combos
  typeEntites       : Array<TypeEntite> = new Array<TypeEntite>();
  typeEntiteSelected: number = 0;

  tvas              : Array<EntiteTva> = new Array<EntiteTva>();
  tvaSelected       : number = 0;
  
  // Chargement des droits utilsateurs
 groupes            : Array<Groupe> = new Array<Groupe>();
  
  // Chargement des employés de l'entité
  employes          : Array<any> = new Array<any>();

  // Variables utilisées pour envoyer au backend
  dirigeant : profil_ARD = <profil_ARD>{};
  entite    : entite_ARD = <entite_ARD>{};

  //Intégration du composant Adresse:
  adresse                 : Adresse = AdresseInit
  inscriptionValidee :boolean = false;

  inscriptionARD    : FormGroup
  waiting           : boolean = false
  inscriptionEnded  : boolean = false
  task!             : TASK
  dirigeantModifie  : boolean = false

  // Accesseur en lecture des contrôle du formulaire du dirigeant
  get nom()             { return this.inscriptionARD.get('dirigeantARD')!.get('nom'); }
  get prenom()          { return this.inscriptionARD.get('dirigeantARD')!.get('prenom'); }
  get agrement_ve()     { return this.inscriptionARD.get('dirigeantARD')!.get('agrement_ve') as FormControl; }
  get email()           { return this.inscriptionARD.get('dirigeantARD')!.get('email'); }
  get telephone()       { return this.inscriptionARD.get('dirigeantARD')!.get('telephone'); }
  get estDirigeant()    { return this.inscriptionARD.get('dirigeantARD')!.get('estDirigeant'); }
  get estImporte()      { return this.inscriptionARD.get('dirigeantARD')!.get('estImporte'); }
  get idgrp()          { return this.inscriptionARD.get('dirigeantARD')!.get('idgrp'); }

  // Accesseur en lecture des contrôles du formulaire de l'entité
  get habilitation()    { return this.inscriptionARD.get('entiteARD')!.get('habilitation'); }
  get fax()             { return this.inscriptionARD.get('entiteARD')!.get('fax'); }
  get mail_facturation(){ return this.inscriptionARD.get('entiteARD')!.get('mail_facturation'); }
  get mail_statistique(){ return this.inscriptionARD.get('entiteARD')!.get('mail_statistique'); }
  get mail_contact()    { return this.inscriptionARD.get('entiteARD')!.get('mail_contact'); }
  get idtva()           { return this.inscriptionARD.get('entiteARD')!.get('idtva'); }
  get type_entite()     { return this.inscriptionARD.get('entiteARD')!.get('type_entite'); }


  constructor(
      private apiAuth             : ApiAuthentificationService
    , private apiEntite           : ApiEntiteService
    , private apiGroupe           : GroupeService
    , private fb                  : FormBuilder
    , private routed              : ActivatedRoute
    , private toast               : PersoSnackbarService
    , private agrementValidator   : AgrementValidator
    , private apiInscription      : InscriptionService
    , private apiTask             : TaskService
    , private apiUtilisateur      : ApiUtilisateurService
    , private MailValidator       : MailValidator
    , private TelephoneValidator  : TelephoneValidator
    , private router              : Router
  ) {this.inscriptionARD = this.fb.group({
        dirigeantARD: this.buildUserGroup(true),
        entiteARD: this.fb.group({
          id              : new FormControl<number|null>(null),
          idExterne       : new FormControl<number>(0),
          id_type_entite  : new FormControl<number>(0,Validators.required),
          typ_ent_nom     : new FormControl(''),
          habilitation    : new FormControl('', [Validators.required]),
          compteTiers     : new FormControl(''),
          telephone       : new FormControl('', [Validators.required, this.TelephoneValidator.verificationFormatTelephoneRequis()]),
          fax             : new FormControl('', [this.TelephoneValidator.verificationFormatTelephoneRequis(true)]),
          mail_facturation: new FormControl('', [this.MailValidator.checkValidatorMail()] ),
          mail_statistique: new FormControl('', [this.MailValidator.checkValidatorMail()] ),
          mail_contact    : new FormControl('', [this.MailValidator.checkValidatorMail()] ),
          idtva             : new FormControl<number>(0, [Validators.required]),
        }),
        usersARD: this.fb.array([])
  });}

  ngOnInit(): void {
    // On récupère les informations de l'url pour identifier l'utilisateur et récupérer ses informations
    this.idLogin  = this.routed.snapshot.params['login'];
    this.idEntite = this.routed.snapshot.params['idEntite'];
    this.password = this.routed.snapshot.params['pass'];
    this.loadData()
  }
 
  get users(): FormArray {
    return this.inscriptionARD.get('usersARD') as FormArray;
  }
  
  buildUserGroup(estDirigeant:boolean = false){
    

    let userGroup: FormGroup = this.fb.group({
      id                          : [0],
      idExterne                   : [0],
      agrement_ve                 : [''],
      nom                         : ['', Validators.required],
      prenom                      : ['', Validators.required],
      email                       : [''],
      motdepasse                  : [''],
      reinitialisation_motdepasse : [true],
      telephone                   : ['', this.TelephoneValidator.verificationFormatTelephoneRequis()],
      actif                       : [true],
      idgrp                       : [0, Validators.required],
      est_dirigeant               : [estDirigeant, [Validators.required]],
      estImporte                  : [true]
    });

    if (estDirigeant) {
      userGroup.controls.agrement_ve.setValidators([Validators.required, this.agrementValidator.verificationFormatAgrementVE(), this.verificationDoublonAgrementVE(-1)]);
      userGroup.controls.email.setValidators([Validators.required, Validators.email, this.verificationDoublonEmailExpert(-1), this.MailValidator.checkValidatorMail()]);
    } else {
      const index = this.users ? this.users.length : 0;
      userGroup.controls.agrement_ve.setValidators([Validators.required, this.agrementValidator.verificationFormatAgrementVE(), this.verificationDoublonAgrementVE(index)]);
      userGroup.controls.email.setValidators([Validators.required, Validators.email, this.verificationDoublonEmailExpert(index), this.MailValidator.checkValidatorMail()]);
    }

         // Ajout de l'écouteur pour le champ Agrément VE du nouveau collaborateur
         userGroup.get('agrement_ve')!.valueChanges.subscribe((newValue) => {
          const agrementVeControl = userGroup.get('agrement_ve');
          if (agrementVeControl && agrementVeControl.valid && agrementVeControl.dirty) {
    
            this.apiUtilisateur.getUserName(newValue).subscribe((user: UtilisateurInscription) => {
              
              if (user) {
                // Mise à jour des champs nom et prénom dans le FormGroup
                userGroup.patchValue({
                  nom       : user.nom,
                  prenom    : user.prenom,
                  email     : user.email,
                  telephone : user.telephone.replace(/\./g,'').replace(/\s/g, ''), // pour supprimer les points et les espaces qui pourraient arriver avec le numéro de telephone,
                  idExterne : user.idexterne
                });
                userGroup.updateValueAndValidity();
              }
            }, error => {
              console.error('Erreur lors de l\'appel au web-service', error);
              userGroup.patchValue({
                nom       : '',
                prenom    : '',
                email     : '',
                telephone : '',
                idExterne : 0
              });
              userGroup.updateValueAndValidity();
            });
          }
        });

    return userGroup
  }

  addUser() {
    const userGroup = this.buildUserGroup()
    const index = this.users ? this.users.length : 0;

    this.users.push(userGroup);
    this.toggleValidators(userGroup, index);
  }

  loadData() {
    
    forkJoin({
      typeEntites : this.apiEntite.getAllTypeEntiteHC(this.apiAuth.CONST_ENV_CODE_EXTRANET),
      tvas        : this.apiEntite.getTVA(),
      groupes     : this.apiGroupe.getGroupesParCodeEntite(this.apiEntite.CONST_CODE_TYPE_ENTITE_EXPERT),
      data        : this.apiAuth.logInARD(this.idLogin, this.idEntite, this.password)
    }).subscribe(({ typeEntites, tvas, groupes, data }) => {
      this.typeEntites = typeEntites;
      this.tvas = tvas;
      this.groupes = groupes;
      
      if (data.Entites.length > 0) {
        // On vérifie d'abord si l'entité en question est bien candidate à une inscription:
        if (data.Entites[0].autoriserMigration === false || data.Entites[0].estMigre === 1 || data.Entites[0].siretExistant === 1) {
          //Une inscription n'est pas possible
          //On enregistre les informations de l'entité dans le service:
          this.apiInscription.memoriseData(data.Entites[0])
          //on redirige vers la page d'information d'inscription non possible:
          this.router.navigate(['/inscription-refusee'])
        }


        for (let i = data.Entites[0].Utilisateurs.length - 1; i >= 0; i--) {
          if (data.idutilisateurs == data.Entites[0].Utilisateurs[i].idUtilisateurs) {
            data.Entites[0].Utilisateurs.splice(i, 1);
          }
        }

        // Initialise les valeurs du formulaire du dirigeant
        this.inscriptionARD.get('dirigeantARD')!.patchValue({
          id              : (data.id != '' ? data.id : 0),
          idExterne       : data.idutilisateurs,
          agrement_ve     : data.agrement,
          nom             : data.nom,
          prenom          : data.prenom,
          email           : data.mail,
          motdepasse      : '',
          reinitialisation_motdepasse: true,
          telephone       : data.portable.replace(/\./g,'').replace(/\s/g, ''), // pour supprimer les points et les espaces qui pourraient arriver avec le numéro de telephone
          date_creation   : Date(),
          date_derniere_modification: '',
          actif           : true,
          idgrp          : this.GetIDGroupeParDefaut(),
          estDirigeant    : true
        });

        this.inscriptionARD.get('dirigeantARD')!.markAllAsTouched();

        // Initialise les valeurs du formulaire de l'entité
        let type: any = this.typeEntites.find(type => type.code === this.apiEntite.CONST_CODE_TYPE_ENTITE_EXPERT)
        
        this.tvaSelected        = data.Entites[0].tva_id;
        this.typeEntiteSelected = data.Entites[0].id_type_entite;
        this.adresse = {...this.adresse
          ,"ligne1":data.Entites[0].adresse1.trim()
          ,"cp":data.Entites[0].cp.trim()
          ,"ville":data.Entites[0].ville.trim()
          ,"ligne2":data.Entites[0].adresse2.trim()
          ,"ligne3":data.Entites[0].adresse3.trim()
        }
        
        this.inscriptionARD.get('entiteARD')!.patchValue({
          id                : (data.Entites[0].ID != '' ? data.Entites[0].ID : ''),
          idExterne         : data.Entites[0].idcabinets,
          typ_ent_nom       : type.libelle,
          habilitation      : data.Entites[0].habilitation,
          compteTiers       : data.Entites[0].compte_bancaire,
          telephone         : data.Entites[0].tel.replace(/\./g,'').replace(/\s/g, ''), // pour supprimer les points et les espaces qui pourraient arriver avec le numéro de telephone,
          id_type_entite    : type.id,
          fax               : data.Entites[0].fax.replace(/\./g,'').replace(/\s/g, ''), // pour supprimer les points et les espaces qui pourraient arriver avec le numéro de fax,
          mail_facturation  : data.Entites[0].mail_facturation,
          mail_statistique  : (data.Entites[0].mail_statistique ? data.Entites[0].mail_statistique : data.Entites[0].mail_facturation),
          mail_contact      : data.Entites[0].mail_ap,
          active            : true,
          idtva             : data.Entites[0].tva_id,
          id_adresse        : data.Entites[0].id_adresse
        });
        
        this.inscriptionARD.get('groupSiren.siret')?.setValue(data.Entites[0].siret)
        this.inscriptionARD.get('groupSiren.raisonSociale')?.setValue(data.Entites[0].raison_sociale)
        this.inscriptionARD.get('entiteARD')!.markAllAsTouched();
        
        // Initialise les valeurs des formulaires des employés
        this.employes = data.Entites[0].Utilisateurs;
                
        this.employes.forEach(employe => {
          this.addUser();
          const employeGroup = this.users.at(this.users.length - 1);
          employeGroup.patchValue({
            id                          : employe.id,
            idExterne                   : employe.idExterne,
            agrement_ve                 : employe.agrement,
            nom                         : employe.nom,
            prenom                      : employe.prenom,
            email                       : employe.mail,
            motdepasse                  : '',
            reinitialisation_motdepasse : true,
            date_creation               : new Date(),
            date_derniere_modification  : '',
            actif                       : true,
            estDirigeant                : false,
            telephone                   : employe.portable ? '' : employe?.portable?.replace(/\./g,'').replace(/\s/g, ''), // pour supprimer les points et les espaces qui pourraient arriver avec le numéro de telephone,
            idgrp                       : this.GetIDGroupeParDefaut(),
          });
        });
      }
    },
    err => { console.log(err)});
  }

  toggleValidators(userGroup: FormGroup, index : number) {
    const estImporteControl = userGroup.get('estImporte')!;
    estImporteControl.valueChanges.subscribe((value: boolean) => {
      if (value) {
        userGroup.get('agrement_ve')!.setValidators([Validators.required, this.agrementValidator.verificationFormatAgrementVE(), this.verificationDoublonAgrementVE(index)]);
        userGroup.get('nom')!.setValidators(Validators.required);
        userGroup.get('prenom')!.setValidators(Validators.required);
        userGroup.get('email')!.setValidators([Validators.required, Validators.email , this.verificationDoublonEmailExpert(index), this.MailValidator.checkValidatorMail()]);
      } else {
        userGroup.get('agrement_ve')!.clearValidators();
        userGroup.get('nom')!.clearValidators();
        userGroup.get('prenom')!.clearValidators();
        userGroup.get('email')!.clearValidators();
      }
      userGroup.get('agrement_ve')!.updateValueAndValidity();
      userGroup.get('nom')!.updateValueAndValidity();
      userGroup.get('prenom')!.updateValueAndValidity();
      userGroup.get('email')!.updateValueAndValidity();
    });
  }

   
  // Sélection d'un type entité
  SelectionneTypeEntite(event: any) { 
    this.typeEntiteSelected = event.target.value;
    }

  // Sélection d'une TVA
  SelectionneTVA(event: any) { 
    this.tvaSelected = event.target.value;
    }

  SelectionneGoupe(event: any, indexUser : number) {
    
    let userFormControl : FormControl;
    let groupe: Groupe | undefined = this.groupes.find( (groupe) => ( groupe.id == event.value ));

    if (groupe != undefined) {
      if (indexUser == -1) {
        // cas spécifique au dirigeant
        userFormControl = this.agrement_ve;
      }
       else {
        userFormControl = this.users.at(indexUser).get("agrement_ve") as FormControl;
      }
  
      if (groupe.estExpert) {
        // le numéro d'agrément doit être visible et obligatoire
          userFormControl.enable();
          userFormControl.setValidators([Validators.required, this.agrementValidator.verificationFormatAgrementVE(), this.verificationDoublonAgrementVE(indexUser)]);
      } else {
        // le numéro d'agrément doit être invisible et donc pas saisi
          userFormControl.disable();
          userFormControl.clearValidators();
      }
      
      userFormControl.markAsTouched();
      userFormControl.updateValueAndValidity();
    }
  }

  GetIDGroupeParDefaut(): number|null {
    let groupe : Groupe|undefined;

    groupe = this.groupes.find( (groupe) => ( groupe.GroupeParDefaut == true ));

    return (groupe !=undefined ? groupe.id : null)
  }

  saveInscription(){
    if (this.inscriptionARD.invalid) {
      return;
    }
    this.waiting = true
    const inscriptionData = this.inscriptionARD.value;
    const tva = {'id':this.idtva!.value}
    inscriptionData.entiteARD = {...inscriptionData.entiteARD,tva,
                                 'siren':inscriptionData.groupSiren.siren
                                , 'siret':inscriptionData.groupSiren.siret
                                , 'raison_sociale' : inscriptionData.groupSiren.raisonSociale}
    inscriptionData.adresse = {
      'ligne1'  : inscriptionData.adresse.adresseLigne1
      ,'ligne2' : inscriptionData.adresse.adresseLigne2
      ,'ligne3' : inscriptionData.adresse.adresseLigne3
      , 'cp'    : inscriptionData.adresse.adresseCP
      ,'ville'  : inscriptionData.adresse.adresseVille
    }
    delete inscriptionData.groupSiren
    const users = inscriptionData.usersARD.filter((user:any)=>user.estImporte===true)
    const inscription = {...inscriptionData,'usersARD':users}
    
    this.apiInscription.saveInscription(inscription).subscribe(
      (taskId:number) => {        
        this.pollTaskStatus(taskId)
    }
  ,(error)=>{
    this.toast.showError('Un problème a empêché l\'enregistrement du cabinet et des collaborateurs')
    this.waiting = false
    console.log(error)
  }
   );
  }

  setAdresse(adresse:Adresse){
    this.adresse = adresse
  }

  pollTaskStatus(taskId:number) {
    const intervalId = setInterval(() => {
      this.apiTask.getTask(taskId).subscribe((task:TASK) => {
        
        this.task = task
        switch (task.status) {
          
          case 'in_progress':
            break;
          
            case 'completed':
            clearInterval(intervalId);            
            // this.toast.showInfo('La tâche ' + task.task_name + ' s\'est correctement déroulée')
            this.waiting = false
            this.inscriptionEnded = true
            break;
          
            case 'failed' :
            clearInterval(intervalId);
            this.waiting = false
            this.inscriptionEnded = true
            break;

          default:
            this.waiting = false
            clearInterval(intervalId);
            break;
        }
        
      }
    ,(error)=>{
      clearInterval(intervalId)
      console.log(error);
      
    })
    }, 500); // Vérifie toutes les 0.5 secondes
  }

  public addNewEmployee(stepper: MatStepper | undefined) {
    this.addUser();
    const employeGroup = this.users.at(this.users.length - 1);
    employeGroup.patchValue({
      id                          : 0,
      idExterne                   : 0,
      agrement_ve                 : '',
      nom                         : '',
      prenom                      : '',
      email                       : '',
      motdepasse                  : '',
      reinitialisation_motdepasse : true,
      date_creation               : new Date(),
      date_derniere_modification  : '',
      actif                       : true,
      estDirigeant                : false,
      telephone                   : '',
      idgrp                      : this.GetIDGroupeParDefaut(),
    });
        
    setTimeout(() => {
      // stepper!.next();
      stepper!.selectedIndex = this.users.length + 1;
    });
  };
  
  public deleteNewEmployee(stepper: MatStepper, userIndex: number) {
    stepper.previous();
    this.users.removeAt(userIndex);
  };

  public isEmployeeStepValid(userIndex: number) {
    const employeGroup = this.users.at(userIndex);
    return employeGroup.valid
  };

  private verificationDoublonAgrementVE(index: number):ValidatorFn {
    return (control: AbstractControl) => {
      let hasDuplicate: boolean = false;
      //let s_agrement_ve: string = control.value;
      let s_agrement: string = control.value.substring(0,6);
      let callbackAgrementSansVe = (element: string) => element.substring(0,6) == s_agrement
      let tabAgrements : Array<string>;

      if (s_agrement) {
        if (this.users) {

          // pour vérification au niveau du dirigeant
          const dirigeant = this.inscriptionARD.get('dirigeantARD') as FormGroup;
          // pour vérification au niveau des employés
          // le controle est à faire sur les éléments à importer
          let attributes  : Array<string> = this.users.value.filter((x:any) => x.estImporte == true)
          attributes = attributes.map((x:any) => x.agrement_ve);
          
          if (index >= 0 ) {
            attributes.splice(index,1);
            tabAgrements = attributes.filter(callbackAgrementSansVe);
            hasDuplicate = ( tabAgrements.length > 0 || (dirigeant.controls.agrement_ve.enabled && dirigeant.value.agrement_ve.substring(0,6) == s_agrement) )            
          } else {
            // partie dirigeant
            tabAgrements = attributes.filter(callbackAgrementSansVe);
            hasDuplicate = ( tabAgrements.length > 0 )
          }

          if (hasDuplicate) {      
            return { DoublonAgrementVE: 'Agrément '+s_agrement+' a déjà été saisi'}
          }
        }
      }
      return null;
    };
  }

  private verificationDoublonEmailExpert(index: number):ValidatorFn {
    return (control: AbstractControl) => {
      let hasDuplicate: boolean = false;
      //let s_agrement_ve: string = control.value;
      let email     : string = control.value;

      if (email) {
        if (this.users) {

          // pour vérification au niveau du dirigeant
          const dirigeant = this.inscriptionARD.get('dirigeantARD') as FormGroup;
          // pour vérification au niveau des employés
          // le controle est à faire sur les éléments à importer
          let attributes  : Array<string> = this.users.value.filter((x:any) => x.estImporte == true)
          attributes = attributes.map((x:any) => x.email);
          
          if (index == -1) {
            // partie dirigeant
            // je recherche l'email dans le tableau des employés
            hasDuplicate = ( attributes.indexOf(email) >= 0)
          } else {
            // on recherche à partir des employés
            hasDuplicate = ( (attributes.indexOf(email) >= 0 && attributes.lastIndexOf(email)!=index) || dirigeant.value.email == email)
          }
          

          if (hasDuplicate) {      
            return { DoublonEmailExpert: 'l\'adresse email '+email+' a déjà été saisi'}
          }
        }
      }
      return null;
    };
  }

  public modifierDirigeant() : void {
    let dirigeantGroup : FormGroup | null = this.inscriptionARD.get('dirigeantARD') as FormGroup;
    
    if (dirigeantGroup != null ) {
      // réinitialisation des données du formulaire
      dirigeantGroup?.patchValue({
        id              : 0,
        idExterne       : 0,
        agrement_ve     : '',
        nom             : '',
        prenom          : '',
        email           : '',
        motdepasse      : '',
        telephone       : '',
        reinitialisation_motdepasse: true,
        date_creation   : Date(),
        date_derniere_modification: '',
        actif           : true,
        idgrp           : this.GetIDGroupeParDefaut(),
        estDirigeant    : true
      });
      dirigeantGroup?.updateValueAndValidity;
    }
    
    // Le champ agrement Ve doit devenir modifiable
    this.dirigeantModifie = true;
  }
}