import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, switchMap,tap } from 'rxjs/operators';
import { Pagination, PaginationInitial } from 'src/app/interfaces/pagination';
import { FiltreRechercheTicket } from 'src/app/interfaces/support/filtre-recherche-ticket';
import { HistoTicket } from 'src/app/interfaces/support/histo-ticket';
import { Ticket } from 'src/app/interfaces/support/ticket';
import { ApiSupportService } from 'src/app/services/api-support.service';

export interface TicketState {
  tickets: Ticket[];
  pagination: Pagination;
  criteria: FiltreRechercheTicket;
  loading: boolean;
}

let _state: TicketState = {
  tickets: [],
  criteria: {types:[],etats:[],texte:'',numero:'',contact:'',immatriculation:'',vin:'',habilitation:''},
  pagination: PaginationInitial,
  loading: false,
};


@Injectable({
  providedIn: 'root'
})
export class TicketFacadeService {

  private store = new BehaviorSubject<TicketState>(_state);
  private state$ = this.store.asObservable();
  
  tickets:Ticket[]=[]

  tickets$ = this.state$.pipe(
    map((state) => state.tickets),
    distinctUntilChanged()
  );

  criteria$ = this.state$.pipe(
    map((state) => state.criteria),
    distinctUntilChanged()
  );

  pagination$ = this.state$.pipe(
    map((state) => state.pagination),
    distinctUntilChanged()
  );

  loading$ = this.state$.pipe(map((state) => state.loading));

  /**
   * Viewmodel that resolves once all the data is ready (or updated)...
   */
  vm$: Observable<TicketState> = combineLatest([
    this.pagination$,
    this.criteria$,
    this.tickets$,
    this.loading$
  ]).pipe(
    map(([pagination, criteria, tickets, loading]) => {
      return { pagination, criteria, tickets, loading };
    })
  );
  constructor( private apiSupport: ApiSupportService) {
   combineLatest([this.criteria$, this.pagination$])
    .pipe(
      switchMap(([criteria, pagination]) => {
        return this.findTickets(criteria);
      })
    )
    .subscribe((tickets) => {
      this.updateState({ ..._state, tickets, loading: false });
    }); }

    getStateSnapshot(): TicketState {
      return { ..._state, pagination: { ..._state.pagination } };
    }

    buildSearchFormGroup(): any {
      // const searchTicket= new FormGroup();
      
      // searchTicket.valueChanges.pipe(distinctUntilChanged()).subscribe((value)=>this.updateSearchCriteria(value))
      // searchTerm.valueChanges
      //   .pipe(distinctUntilChanged())
      //   .subscribe((value) => this.updateSearchCriteria(value));
  
      // return searchTicket;
    }

    updateSearchCriteria(criteria: FiltreRechercheTicket) {
      this.updateState({ ..._state, criteria, loading: true });
    }
  
    updatePagination(selectedSize: number, currentPage: number = 0) {
      const pagination = { ..._state.pagination, currentPage, selectedSize };
      this.updateState({ ..._state, pagination, loading: true });
    }

    updateTickets(tickets:Ticket[]){
      this.updateState({ ..._state, tickets, loading: true })
    }

    private updateState(state: TicketState) {
      this.store.next((_state = state));
    }

    updateHistoTicket(histoTicket: HistoTicket){
      let subject = new BehaviorSubject<Ticket>(_state.tickets[0]);
      this.apiSupport.postHistoTicket(histoTicket).subscribe((ticketUpdated:Ticket[])=>{
        _state.tickets = _state.tickets.map((ticket) => ticket.id === ticketUpdated[0].id? ticketUpdated[0] : ticket)
        subject.next(ticketUpdated[0])
        subject.complete()
      })
      return subject
    }

    findTickets(
      criteria: FiltreRechercheTicket 
    ): Observable<Ticket[]> {
      
      if (this.isFilterValid(criteria) && criteria!=_state.criteria) {
        this.tickets$ = this.apiSupport.getTickets(criteria)
        this.updateSearchCriteria(criteria)
        _state.pagination.currentPage=0
      }
      return this.tickets$
    }

    isFilterValid(criteria: FiltreRechercheTicket):boolean{
      let isValid : boolean = true
      if (criteria.contact=='' 
            && criteria.etats.length==0 
            && criteria.habilitation=='' 
            && criteria.immatriculation=='' 
            && criteria.numero==''
            && criteria.texte==''
            && criteria.types.length==0
            && criteria.vin =='') {
              isValid = false    
      }
      return isValid
    }
  
}
