<mat-card>
    <mat-card-header>
        <mat-card-title>Modification de paramètre</mat-card-title>
    </mat-card-header>

    <mat-card-content [formGroup]="parametreForm">
        <div class="div-container">
            <mat-form-field>
                <mat-label>Libellé</mat-label>
                <input matInput type="text" formControlName="parametreNom" maxlength="100">
                <mat-error *ngIf="parametreForm.get('parametreNom')?.hasError('required')">La saisie du nom est obligatoire</mat-error>
            </mat-form-field>

            <mat-form-field class="textarea">
                <mat-label>Description</mat-label>
                <textarea matInput type="text" formControlName="parametreNote" style="max-width:100%;" (keyup)="autoGrowTextZone($event)" class="textarea"></textarea>
                <mat-error *ngIf="parametreForm.get('parametreNote')?.hasError('required')">La saisie de la description est obligatoire</mat-error>
            </mat-form-field>
        </div>
    </mat-card-content>
    <mat-card-actions align="end">
        <button mat-raised-button color = "primary" (click) = "onValidate()" [disabled]="parametreForm.invalid">Enregistrer</button>
        <button mat-raised-button color = "primary" (click) = "onCancel()">Annuler</button>
    </mat-card-actions>
</mat-card>