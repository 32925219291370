import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { PRIMARY_OUTLET, Router, UrlSegmentGroup, UrlTree } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mapTo, switchMap, tap } from 'rxjs/operators';
import { Authentification } from '../interfaces/authentification/authentification';
import { Token } from '../interfaces/authentification/token';
import { UpdatePassword } from '../interfaces/authentification/update-password';
import { ProfilUtilisateur } from '../interfaces/profil-user/profil-utilisateur';
import { ResultatActionEnMasse } from '../interfaces/resultat-action-en-masse';
import { RelationUtilisateurEntite } from '../interfaces/utilisateur-entite/relation-utilisateur-entite';
import { ApiRelUtilisateurEntiteService } from './api-rel-utilisateur-entite.service';
import { ApiUtilisateurService } from './api-utilisateur.service';
import { PersoSnackbarService } from './perso-snackbar.service';
import { ApiUtilitaireService } from './tools/api-utilitaire.service';
import { ContexteService } from 'src/app/services/contexte.service';

@Injectable({
  providedIn: 'root'
})
export class ApiAuthentificationService {
  
  private readonly urlApi: string = '/api';

  private readonly LOCAL_STORAGE_USER_REMEMBER_ME = 'USER_REMEMBER_ME';
  private readonly LOCAL_STORAGE_USER_TOKEN_LD    = 'USER_TOKEN_LD';
  private readonly LOCAL_STORAGE_USER             = 'USER';
  private readonly LOCAL_STORAGE_USER_TOKEN_CD    = 'USER_TOKEN_CD';
  private readonly LOCAL_STORAGE_TOKEN_LD         = 'TOKEN_LD';
  private readonly LOCAL_STORAGE_TOKEN_CD         = 'TOKEN_CD';
  private readonly LOCAL_STORAGE_USER_LOGIN       = 'USER_LOGIN';
  private readonly LOCAL_STORAGE_USER_PASSWORD    = 'USER_PASSWORD';
  private readonly LOCAL_STORAGE_LAST_URL         = 'LAST_URL';

  private readonly cleSalage: string  = '#EtUnPeuDeVitriol!';
  // private readonly cleKEOPS : string  = '$93yXNDddU6jmA994$';
  private readonly cleHash  : string  = 'CLEew24m687fr8UYnCEUU8aQB95t';
  
  utilisateur_authentifie   : boolean = false;
  appelDepuisLogin          : boolean = false;
  
  authUser    : Authentification  = <Authentification> {};
  userConnect : ProfilUtilisateur = <ProfilUtilisateur> {};
  profils     : Array<ProfilUtilisateur> = [];
  relUserEnt  : Array<RelationUtilisateurEntite> = new Array<RelationUtilisateurEntite> ();
      
  tree: UrlTree = <UrlTree>{};
  // primary outlet
  primary: UrlSegmentGroup = <UrlSegmentGroup>{};

  CONST_TRIGGER_REFRESH_TOKEN_CD    = 1                             as const; // Signifie qu'une heure avant la fin de la date d'expiration du token court, il est rafraîchit
  CONST_UNDIFINED                   = 'undefined'                   as const;
  CONST_TOKEN_COURTE_DUREE          = 'TKNCD'                       as const;
  CONST_TOKEN_LONGUE_DUREE          = 'TKNLD'                       as const;

  CONST_ENV_CODE_EXTRANET           = 'EXTR'                        as const;
  CONST_URL_ROOT_EXTRANET           = 'app'                         as const;
  CONST_ENV_CODE_SERVICE            = 'FFEA'                        as const;
  CONST_URL_ROOT_SERVICE            = 'svc'                         as const;

  // description de nom classe pour la navbar
  CONST_BODY_SERVICE    : string    = 'body-service'                as const;
  CONST_BODY_EXTRANET   : string    = 'body-extranet'               as const;
  CONST_BANDEAU_SERVICE  : string   = 'navbar-service'              as const;
  CONST_BANDEAU_EXTRANET : string   = 'navbar'                      as const;

  root : string = '';  // root du service (service) ou de l'extranet (app)
  
  CONST_CLE_CRYPTAGE_APIPUBLIQUE : string = "aBoj3c3bHSksZV"        as const;


  // Accesseur en lecture
  public getRememberMeOnLocalStorage()  : boolean { return (localStorage.getItem(this.LOCAL_STORAGE_USER_REMEMBER_ME) == "true"); }
  public getUsertokenLDOnLocalStorage() : string  { return localStorage.getItem(this.LOCAL_STORAGE_USER_TOKEN_LD)!; }
  public getUsertokenCDOnLocalStorage() : string  { return localStorage.getItem(this.LOCAL_STORAGE_USER_TOKEN_CD)!; }
  public getUserLoginOnLocalStorage()   : string  { return (localStorage.getItem(this.LOCAL_STORAGE_USER_LOGIN))!; }
  public getLastURLOnLocalStorage()     : string  { return localStorage.getItem(this.LOCAL_STORAGE_LAST_URL)!; }
  
  public getUserOnLocalStorage()     : any
  { const User = localStorage.getItem(this.LOCAL_STORAGE_USER);
    if (User != this.CONST_UNDIFINED && User != null && User!='') {
      return JSON.parse(User);
    }else{
      return null;
    }
  }

  public getTokenLDOnLocalStorage()     : Token
  { const tokenLd = localStorage.getItem(this.LOCAL_STORAGE_TOKEN_LD);
    if (tokenLd != this.CONST_UNDIFINED && tokenLd != null && tokenLd!='') {
      return JSON.parse(tokenLd);
    }else{
      return {'token':'','expery_date':''};
    }
  }
    
    public getTokenCDOnLocalStorage()     : Token  
  { const tokenCd = localStorage.getItem(this.LOCAL_STORAGE_TOKEN_CD);
    if (tokenCd != this.CONST_UNDIFINED && tokenCd!==null && tokenCd !='') {
      return JSON.parse(tokenCd);
    }else{
      return {'token':'','expery_date':''};
    }
  }

  // Accesseur en écriture
  public setLastURLOnLocalStorage(lastURL: string) : void  { localStorage.setItem(this.LOCAL_STORAGE_LAST_URL, lastURL); }
  public setRememberMeOnLocalStorage(rememberMe: boolean) : void  { localStorage.setItem(this.LOCAL_STORAGE_USER_REMEMBER_ME, rememberMe.toString()); }

  constructor(private http            : HttpClient
            , private router          : Router
            , private Toast           : PersoSnackbarService
            , private userService     : ApiUtilisateurService
            , private apiRelUserEnt   : ApiRelUtilisateurEntiteService
            , private apiUtilitaire   : ApiUtilitaireService
            , private contexteService : ContexteService) 
  { 
    this.authUser = new Authentification();

    // // récupération de la racine du site lors de l'authentification
    // this.tree = this.router.parseUrl(this.router.url);
    // this.primary = this.tree.root.children[PRIMARY_OUTLET];
    // if ( this.primary != undefined ) {
    //   this.root = ( this.primary.segments[0].path === this.CONST_URL_ROOT_SERVICE ? this.CONST_URL_ROOT_SERVICE : this.CONST_URL_ROOT_EXTRANET );  
    //   this.authUser.root = this.root;
    // }
  }

  // est-on sur un URL du service FFEA ?
  public isServiceFFEA() {
    // if ( this.root === '' || this.authUser.root === '' ) {
      // récupération de la racine du site
      this.tree = this.router.parseUrl(this.router.url);
      this.primary = this.tree.root.children[PRIMARY_OUTLET];
      if ( this.primary != undefined ) {
        this.root = ( 
          ( this.primary.segments[0].path === this.CONST_URL_ROOT_SERVICE 
          || this.primary.segments[0].path === 'service') 
          ? this.CONST_URL_ROOT_SERVICE : this.CONST_URL_ROOT_EXTRANET );   
      // }
      return ( this.primary.segments[0].path === this.CONST_URL_ROOT_SERVICE 
        || this.primary.segments[0].path === 'service');
    } 

    return ( this.authUser.root === this.CONST_URL_ROOT_SERVICE )
  }

  public GetEnvironmentCode() {
    if (this.isServiceFFEA() == true) {
      return this.CONST_ENV_CODE_SERVICE
    } else {
      return this.CONST_ENV_CODE_EXTRANET
    }
  }

  public logIn(mail: string, password: string): Observable<boolean> {
    // Réinitialisation de l'authentification
    this.authUser = new Authentification();  
    this.removeAuth();
    
        // Pour supprimer le contexte des pages enregistré dans le service
    // permet de ne pas conserver les filtre de recherche ou les résultats des pages
    this.contexteService.deleteContext();
    
    // Chargement de la structure
    this.authUser.login         = mail;
    this.authUser.mot_de_passe  = this.HashPassword(password);   
    this.authUser.root          = this.root;


    if(this.getRememberMeOnLocalStorage() === true) {
      this.rememberMe(this.authUser, password);
    } else {
      this.notRememberMe();
    }
    
    
    return this.http.post<Authentification>(this.urlApi + "/authentification/logIn", this.authUser)
    .pipe(   // .pipe (fonction RxJS permettant d'exécuter des traitements à la suite les uns des autres. Il s'agit simplement d'une fonction permettant de simplifier l'écriture du code.)
        tap((data: Authentification) => { // .tap (fonction RxJS permettant d'exécuter un traitement quelconque)
          
          this.authUser = data; 
          this.appelDepuisLogin = true;   
          this.storeAuth(this.authUser);
        })
        , switchMap(this.loadUserConnect.bind(this))
        , tap(
          (data: boolean) => {     
            if (data == true) {      
              this.utilisateur_authentifie = true;
              this.apiUtilitaire.getUrlMaJOutilDiag();                  
              this.router.navigate([ ( '/'+this.authUser.root+'/home' ) ]);
            }
        })
        , catchError((error: HttpErrorResponse) => { // .catchError (fonction RxJS (?) permettant de gérer une erreur renvoyer par l'API)
          this.utilisateur_authentifie = false;
          console.log(error);
          
          this.Toast.showError(error.error);
          return throwError(error);
        })
        , mapTo(true)  // .mapTo (fonction RxJS permettant de transformer un observable renvoyer par l'API en un nouveau type d'observable)        
      )
  }

  public logInARD(idUtilisateurARD: number, idEntiteARD: number, motDePasseCrypte: string) {
    return this.http.get<any>(this.urlApi+"/authentification/logInARD/"+idUtilisateurARD+"/"+idEntiteARD+"/"+motDePasseCrypte)
  }

  public logOut(): Observable<boolean> {    
    
    // Réinitialisation de l'authentification
    this.authUser     = new Authentification();
    this.userConnect  = <ProfilUtilisateur> {};  
    
    this.utilisateur_authentifie = false;

    if(this.getRememberMeOnLocalStorage() == false) {
      this.notRememberMe();
    }
    this.removeAuth();    
    this.utilisateur_authentifie = false;
    
    // Pour supprimer le contexte des pages enregistré dans le service
    // permet de ne pas conserver les filtre de recherche ou les résultats des pages
    this.contexteService.deleteContext();
    
    if ( this.root === this.CONST_URL_ROOT_SERVICE ) {
      this.router.navigate([ '/service' ]);
    } else {
      this.router.navigate([ '/login' ]);
    }
    return of (true); // .of (fonction RxJS permettant de transformer le type de retour)
  }

  public HashPassword(passwordToHash: string) : string {
    return CryptoJS.HmacSHA512(passwordToHash + this.cleSalage, this.cleHash).toString(CryptoJS.enc.Base64).toUpperCase();
  }

  public cryptPassword(keys: string, passwordToCrypt: any): string {
      var key = CryptoJS.enc.Utf8.parse(keys);
      var iv  = CryptoJS.enc.Utf8.parse(keys);
      var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(passwordToCrypt.toString()), key,
      {
          keySize: 128 / 8,
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      });
      
      return encrypted.toString();
  }

  public decryptPassword(keys: string, passwordToDecrypt: any): string {    
      var key = CryptoJS.enc.Utf8.parse(keys);
      var iv = CryptoJS.enc.Utf8.parse(keys);
      var decrypted = CryptoJS.AES.decrypt(passwordToDecrypt, key, {
          keySize: 128 / 8,
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      });
  
      return decrypted.toString(CryptoJS.enc.Utf8);
  }

  decrypteCleApiPublique(encryptedData: any): string {
    try {
        // La clé de cryptage utilisée dans WinDev
        const bufCle = CryptoJS.MD5(this.CONST_CLE_CRYPTAGE_APIPUBLIQUE).toString(CryptoJS.enc.Hex);

        // Vérifier si la chaîne cryptée est en base64 ou en hexadécimal
        let encryptedHex: string;
        if (/^[0-9A-Fa-f]+$/.test(encryptedData)) {
            // Si c'est de l'hexadécimal, utiliser tel quel
            encryptedHex = encryptedData;
        } else {
            // Si c'est en base64, convertir en hexadécimal
            encryptedHex = CryptoJS.enc.Hex.stringify(CryptoJS.enc.Base64.parse(encryptedData));
        }

        // console.log('Key (bufCle):', bufCle);
        // console.log('Encrypted (Hex):', encryptedHex);

        // Extraire l'IV des premiers 16 octets (32 caractères hexadécimaux)
        const ivHex = encryptedHex.substring(0, 32);
        const encryptedMessageHex = encryptedHex.substring(32);

        // console.log('IV (Hex):', ivHex);
        // console.log('Encrypted Message (Hex):', encryptedMessageHex);

        // Décryptage de la chaîne
        const decrypted = CryptoJS.AES.decrypt(CryptoJS.enc.Base64.stringify(CryptoJS.enc.Hex.parse(encryptedMessageHex)), CryptoJS.enc.Hex.parse(bufCle), {
            iv: CryptoJS.enc.Hex.parse(ivHex),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        // console.log(decrypted);
        
        // Convertir le résultat en chaîne UTF-8
        const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
        // console.log('Decrypted Text:', decryptedText);
        return decryptedText;
      
    } catch (error:any) {
        console.error('Decryption error:', error);
        throw new Error('Decryption failed: ' + error.message);
    }
}

  public loadUserConnect(): Observable <boolean> {     
    return this.userService.getUtilisateurParToken(this.getUsertokenCDOnLocalStorage())
      .pipe(
        tap((user: ProfilUtilisateur) => {
          
          this.authUser.id = user.id;
          this.authUser.login = user.email;
          this.authUser.mot_de_passe = user.motdepasse;
          this.authUser.token_cd = this.getUsertokenCDOnLocalStorage();
          this.authUser.token_ld = this.getUsertokenLDOnLocalStorage();
          this.authUser.tokenCD = this.getTokenCDOnLocalStorage();
          this.authUser.tokenLD = this.getTokenLDOnLocalStorage();
          this.authUser.root = this.root;
          this.userConnect = user;
          // this.setDroitUser();
        })
      , switchMap(() => this.apiRelUserEnt.getRelUtilisateurEntite(this.userConnect.id, 0))
      , tap((data: RelationUtilisateurEntite[]) => { this.relUserEnt = data })
      , catchError((error: HttpErrorResponse) => {
          this.Toast.showError(error.error);
          return throwError(error);
        })
      , mapTo(true)
    )  
  }
  
  // Demande de ré-initialisation d'un mot de passe oublié -> le serveur effectue un envoi de mail
  public sendMailForgottenPassword(mail: string) {
    mail = encodeURIComponent(mail);
    return this.http.delete(this.urlApi + '/forgotten-password?ml=' + mail);
  }

  public putPasswordUtilisateur(id: number, token: string, currentPass: string, newPass: string, newPassConfirmation: string): Observable <boolean> {       

    // Objet updatePassword de l'interface
    let UpdatePass = new UpdatePassword();

    // Assigner les valeurs à l'objet
    // dans le cas d'un mot de passe oublié, on ne présente pas le hash du mot de passe courant, mais le token de modification
    UpdatePass.token         = token;
    UpdatePass.current       = (currentPass.length > 0) ? this.HashPassword(currentPass) : '';
    UpdatePass.new           = this.HashPassword(newPass);
    UpdatePass.confirmation  = this.HashPassword(newPassConfirmation);    
    UpdatePass.newCrypted     = newPass; //A crypter!
    
    return this.http.put<UpdatePassword>(this.urlApi + "/authentification/" + id + "/update-password", UpdatePass)
    .pipe(
      catchError((error:HttpErrorResponse) => {
        this.Toast.showError(error.error);
        return throwError(error); 
      })
      , mapTo(true)
    );
  }

  private storeAuth(authentification: Authentification) {   
    // localStorage.setItem(this.LOCAL_STORAGE_USER, JSON.stringify(authentification));
    localStorage.setItem(this.LOCAL_STORAGE_USER_TOKEN_LD, authentification.token_ld.toString());
    localStorage.setItem(this.LOCAL_STORAGE_USER_TOKEN_CD, authentification.token_cd.toString());
    localStorage.setItem(this.LOCAL_STORAGE_TOKEN_LD, JSON.stringify(authentification.tokenLD));
    localStorage.setItem(this.LOCAL_STORAGE_TOKEN_CD, JSON.stringify(authentification.tokenCD));
  }

  private removeAuth() {
    localStorage.removeItem(this.LOCAL_STORAGE_USER_TOKEN_LD);
    localStorage.removeItem(this.LOCAL_STORAGE_TOKEN_LD);
    localStorage.removeItem(this.LOCAL_STORAGE_USER_TOKEN_CD);
    localStorage.removeItem(this.LOCAL_STORAGE_TOKEN_CD);
  }

  public rememberMe(authentification: Authentification, password: string): void {    
    localStorage.setItem(this.LOCAL_STORAGE_USER_REMEMBER_ME, JSON.stringify(true));
    localStorage.setItem(this.LOCAL_STORAGE_USER_LOGIN,  authentification.login);
    this.storeAuth(authentification);
  }

  public notRememberMe(): void {
    // supprimer les infos dans la session
    localStorage.removeItem(this.LOCAL_STORAGE_USER_REMEMBER_ME);
    localStorage.removeItem(this.LOCAL_STORAGE_USER_LOGIN);
    localStorage.removeItem(this.LOCAL_STORAGE_USER_PASSWORD);
    localStorage.removeItem(this.LOCAL_STORAGE_USER_TOKEN_CD);
    localStorage.removeItem(this.LOCAL_STORAGE_USER_TOKEN_LD);
    localStorage.removeItem(this.LOCAL_STORAGE_TOKEN_CD);
    localStorage.removeItem(this.LOCAL_STORAGE_TOKEN_LD);
  }

  public tokenValide(): Observable<boolean> {             
    return this.http.get<boolean>(this.urlApi+'/authentification/token-valide')
    .pipe(
        switchMap(this.loadUserConnect.bind(this))
        , tap((data: boolean) => { if(data) { this.utilisateur_authentifie = true;
        
         } })
        , catchError((error:HttpErrorResponse) => {
          this.utilisateur_authentifie = false;
          this.Toast.showError(error.error);
          this.logOut();
          return throwError(error);
      })
      , mapTo(true)        
    );
  }

  // public tokenValide(): boolean {         
  //  const token = this.getTokenCDOnLocalStorage();
  //  const now = new Date();
   
  //  if (new Date(token.expery_date) <= now) {
    
  //   return false;
  //  }else{
  //   return true;
  //  }
  // }

  public refreshTokenCD(): Observable<boolean> {          
    this.authUser.token_ld  = this.getUsertokenLDOnLocalStorage(); 
    this.authUser.root      = this.root;
    
    if(this.authUser.token_ld != '' && this.authUser.token_ld != null) {
      return this.http.post<Authentification>(this.urlApi+'/authentification/refreshToken', this.authUser)
      .pipe(
        tap((data: Authentification) => {                
          this.authUser = data;
          this.storeAuth(this.authUser);
        })
        , switchMap(() => this.loadUserConnect())
        , tap((data: boolean) => { 
          this.utilisateur_authentifie = true          
         })
        , catchError((error:HttpErrorResponse) => {
          this.utilisateur_authentifie = false;
          this.Toast.showError(error.error);
          this.logOut();
          return throwError(error);
        })
        , mapTo(true)        
      );
    } else {
      return of(false);
    }    
  }

  public resetPassword(token: string): Observable<boolean> {
    return this.http.post<Authentification>(this.urlApi+'/authentification/reset-password/'+token, this.authUser)
      .pipe(
        tap((data: Authentification) => {
          this.authUser = data;

        })
        , catchError((error:HttpErrorResponse) => {
          this.Toast.showError(error.error);
          return throwError(error);
        })
        , mapTo(true) 
      );
  }
  
  public navigateToLoginPage() {
    if ( this.authUser.root === this.CONST_URL_ROOT_SERVICE || this.root === this.CONST_URL_ROOT_SERVICE ) { 
      this.router.navigate([( `/service`)]);
    } else {
      this.router.navigate([( `/login`)]);
    }
  }

  public navigateToHome() {
    this.router.navigate([( `/`+this.authUser.root+`/home`)]);
  }

    // Action de masse - MassUpdate
  public postMassUpdateResetPassword(tab_Utilisateur :Array<ProfilUtilisateur>): Observable<ResultatActionEnMasse[]> {
    return this.http.post<ResultatActionEnMasse[]>(this.urlApi + '/authentification/action-en-masse/reset-password', tab_Utilisateur);
  }

  public verificationFormatMotDePasse(): ValidatorFn {
    return (control:AbstractControl):ValidationErrors|null =>{
      let motdepasse: string = control.value;
      let regex: any = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/;
  
      if(!regex.test(motdepasse)) {
        return { erreur: 'Format incorrect : mot de passe doit contenir au minimum 8 caractères dont une majuscule, une minuscule, un nombre et un caractère spécial'}      
      }
  
      return null;

    }
  }
}
