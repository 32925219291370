import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { ApiRelUtilisateurEntiteService } from 'src/app/services/api-rel-utilisateur-entite.service';

@Component({
    selector: 'app-entite-list',
    templateUrl: './entite-list.component.html',
    styleUrls: ['./entite-list.component.scss'],
    standalone: true,
    imports: [NgIf, MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, AsyncPipe]
})
export class EntiteListComponent implements OnInit {
  @Input() fieldName          : string = '';
  @Input() required           : boolean = false;
  @Input() idEntite           : number = 0;
  @Input() activated          : boolean = true;
  @Input() allEntities        : boolean = false;
  @Input() CoordBanqDisabled  : boolean = true
  
  @Output() entiteEmitter     = new EventEmitter<Entite>()
  
   entite!                     : Entite

  entites$=combineLatest([
    this.apiEntite.entitesActivated$
    ,this.apiRelUserEnt.getRelUtilisateurEntite(this.apiAuth.userConnect.id, 0)
  ]).pipe(
    map(([entites,relUserEntites])=>{
      
      let tabEntite : Entite[];

      if (this.allEntities) {

        tabEntite = entites;
      } else{
        tabEntite = relUserEntites.filter((relUserEntite)=> relUserEntite.est_active===true && relUserEntite.entite.EstActif === true).map((relUserEntite)=> relUserEntite.entite);
      }

      if(tabEntite.length===1) {
        this.entite = tabEntite[0];
        this.onSelectEntite(this.entite);
      };
      
      return tabEntite;
    })
  )


  constructor(  public apiEntite     : ApiEntiteService
                , private apiRelUserEnt : ApiRelUtilisateurEntiteService
                ,private apiAuth : ApiAuthentificationService) { }

  ngOnInit(): void {
    // this.apiRelUserEnt.getRelUtilisateurEntite(this.apiAuth.userConnect.id, 0)
  }
  
  onSelectEntite(entiteSelected: Entite){
    this.entiteEmitter.emit(entiteSelected)
  }
 
  
}
