import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

@Injectable({ providedIn: 'root' })
export class DateRangeValidator {
    verifyDateRange(minDate:Date|null,maxDate:Date|null, required:boolean|null = false):ValidatorFn {
      return (control: AbstractControl):ValidationErrors | null  => {
          
        const dateString = control.value;
        
        if (!dateString && required ===false) {
            return null;
        }
        if (!dateString && required ===true) {
            return {erreur:'La date est requise'}
        }
        
        const date = new Date(dateString)

        if (date && date instanceof Date) {
            if (!minDate && maxDate && date>maxDate) {
                return {erreur:'La date ne doit pas être postérieure à '+maxDate.toLocaleDateString()}
            }
            if (!maxDate && minDate && date<minDate) {
                return {erreur:'La date ne doit pas être antérieure à '+minDate.toLocaleDateString()}
            }
            if (minDate && maxDate && (date<minDate || date>maxDate)) {
                return {erreur:'La date doit être comprise entre ' + minDate.toLocaleDateString()+ ' et ' + maxDate.toLocaleDateString()}
            }
        }
    
        return null;
        };
        }

  }