import { Component, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgSwitch, NgSwitchCase, NgIf } from '@angular/common';

export interface DialogData {
  title: string;
  message: string;
  yes_no_cancel?: number;
  type : number
}

@Component({
    selector: 'app-oui-non-annuler',
    templateUrl: './oui-non-annuler.component.html',
    styleUrls: ['./oui-non-annuler.component.scss'],
    standalone: true,
    imports: [NgSwitch, NgSwitchCase, MatDialogModule, MatIconModule, MatButtonModule, NgIf]
})
export class OuiNonAnnulerComponent implements OnInit {

  dialogData      : DialogData = <DialogData>{};
  title           : string = 'Confirmer';
  message         : string = '';
  type            : number = 1;
  // yes_no_cancel : 1=ok / 2=oui-non / 3=oui-non-annuler
  yes_no_cancel   : number = 2;


  constructor(
    public dialogRef: MatDialogRef<OuiNonAnnulerComponent>
    , @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {

  }

  ngOnInit(): void {
  }

  onConfirme(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

  onCancel(): void {
    this.dialogRef.close(undefined);
  }

}
