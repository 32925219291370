import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

@Injectable({ providedIn: 'root' })
export class VehiculeValidator {
    
   verifieVin(): ValidatorFn{
    return (control : AbstractControl):ValidationErrors |null =>{
      const vin : string = control.value
      if (!vin) {
        return null
      }
      if (vin.length!=17) {
        return {erreur:vin.length+' caractères sur 17'}
      }else{
        return null
      }
    }
   }

  }