import { Component, Input, OnChanges } from '@angular/core';
import { Entite } from 'src/app/interfaces/profil-user/entite';

@Component({
  selector: 'app-carte-entite',
  templateUrl: './carte-entite.component.html',
  styleUrls: ['./carte-entite.component.scss'],
  standalone:true,
  imports:[]
})
export class CarteEntiteComponent implements OnChanges {

  @Input() entite!:Entite
  @Input() tvaIntracommunautaire!:string

  ngOnInit(){
  }

  ngOnChanges() {
    let cleTVA : number;

    if ((this.tvaIntracommunautaire == undefined || this.tvaIntracommunautaire == "") && this.entite.siren != undefined )  {

      cleTVA = 12 + 3 * (Number(this.entite.siren) % 97) % 97;

      this.tvaIntracommunautaire = 'FR'+ cleTVA.toString() + this.entite.siren
    }
  }
}
