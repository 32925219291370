import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { catchError, tap } from 'rxjs/operators';
import { AccesConcentrateur } from '../interfaces/profil-user/acces-concentrateur';
import { ProfilUtilisateur } from '../interfaces/profil-user/profil-utilisateur';
import { ResultatActionEnMasse } from '../interfaces/resultat-action-en-masse';
import { RelationUtilisateurEntite } from '../interfaces/utilisateur-entite/relation-utilisateur-entite';
import { PersoSnackbarService } from './perso-snackbar.service';
import { UtilisateurInscription } from '../interfaces/profil-user/profil-utilisateur';

@Injectable({
  providedIn: 'root'
})
export class  ApiUtilisateurService {
  url: string = '/api';
  
  public isDataModify: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  
  constructor(private http: HttpClient, private Toast: PersoSnackbarService){}

  public getUtilisateurParToken(s_Token: string): Observable<ProfilUtilisateur> {
    return this.http.get<ProfilUtilisateur>(this.url+"/utilisateur/token/"+s_Token)
    .pipe(
      tap((user:ProfilUtilisateur)=> {
        return user;        
      })
      ,catchError((error:HttpErrorResponse) => {
        this.Toast.showError(error.error);
        return throwError(error);
      })
    )
  }

  // Récupération d'un utilisateur à partir de son agrément
  public getUtilisateurByAgrement(agrement: string) : Observable<ProfilUtilisateur> {
    return this.http.get<ProfilUtilisateur>(this.url+`/utilisateur?agrement=`+agrement);
  }

  public getUtilisateur(n_Id: number) : Observable<ProfilUtilisateur> {
    
    return this.http.get<ProfilUtilisateur>(this.url+"/utilisateur/"+n_Id)
    .pipe(
      tap((user:ProfilUtilisateur)=> {
        return user;        
      })
      ,catchError((error:HttpErrorResponse) => {
        this.Toast.showError(error.error);
        return throwError(error);
      })
    )
  }

  // point d'entrée hors connexion
  public postUtilisateur(profilUtilisateur: ProfilUtilisateur, idEntite: any): Observable<ProfilUtilisateur> {    
    return this.http.post<ProfilUtilisateur>(this.url+"/hc/utilisateur?id_entite="+idEntite, profilUtilisateur);
  }

  getUserName(agrement:string):Observable<UtilisateurInscription>{
    return this.http.get<UtilisateurInscription>(this.url+"/hc/utilisateur/" + agrement)
  }

  // *************** Accès Concentrateur ***************
  public postCreerAccesConcentrateur(acces_concentrateur : AccesConcentrateur, id_utl_dirigeant: number, id_ent: number, id_utl_expert: number): Observable<AccesConcentrateur> {    
    return this.http.post<AccesConcentrateur>(this.url+"/utilisateur/creer-acces-concentrateur/"+id_utl_dirigeant+"/"+id_ent+"/"+id_utl_expert, acces_concentrateur);
  }

  public postSupprimerAccesConcentrateur(acces_concentrateur : AccesConcentrateur, id_utl_dirigeant: number, id_ent: number, id_utl_expert: number): Observable<AccesConcentrateur> {    
    return this.http.post<AccesConcentrateur>(this.url+"/utilisateur/supprimer-acces-concentrateur/"+id_utl_dirigeant+"/"+id_ent+"/"+id_utl_expert, acces_concentrateur);
  }

  public putUtilisateur(n_Id: number, profilUtilisateur: ProfilUtilisateur): Observable<ProfilUtilisateur> {   
    // Ajout d'une option dans l'header de la requête pour convertir le format de retour JSON en text car sinon, il y aura une erreur de parsing JSON
    let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }), responseType: 'text' as 'json' };
    
    return this.http.put<ProfilUtilisateur>(this.url+"/utilisateur/"+n_Id,profilUtilisateur, httpOptions);
  }

  public putRoleEntite(rel_utl_ent :RelationUtilisateurEntite) : Observable<RelationUtilisateurEntite> {
    let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }), responseType: 'text' as 'json' }; 
    return this.http.put<RelationUtilisateurEntite>(this.url+'/rel_utilisateur_entite', rel_utl_ent, httpOptions);
  }

  public activateOrDeactivateUser(n_Id: number){
    return this.http.delete<ProfilUtilisateur>(this.url+"/utilisateur/"+n_Id);
  }

  public uploadProfileImage(n_Id: number, profilUtilisateur: ProfilUtilisateur): Observable<ProfilUtilisateur> {
    // Ajout d'une option dans l'header de la requête pour convertir le format de retour JSON en text car sinon, il y aura une erreur de parsing JSON
    let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }), responseType: 'text' as 'json' }; 
    return this.http.put<ProfilUtilisateur>(this.url+"/utilisateur/"+n_Id+"/photo-profil",profilUtilisateur, httpOptions);
  }

  public postListeUtilisateurParEnvironnement(s_CodeEnvironnement : String, filter: any): Observable<ProfilUtilisateur[]> {
    return this.http.post<ProfilUtilisateur[]>(this.url + '/utilisateur/environnement/'+s_CodeEnvironnement, filter);
  }

  // Action de masse - MassUpdate
  public postMassUpdateactivateOrDeactivateUser(tab_Utilisateur :Array<ProfilUtilisateur>): Observable<ResultatActionEnMasse[]> {
    return this.http.post<ResultatActionEnMasse[]>(this.url + '/utilisateur/action-en-masse/activation-desactivation', tab_Utilisateur);
  }

  public formateAgrement(agrement:number,expert:boolean=false):string{
    let agrementFormate =  ("000000" + agrement).slice(-6) ;
    if (expert===true) {
      agrementFormate += "-VE"
    }
    return agrementFormate;
  }

  public getNewPassword():Observable<string>{
    return this.http.get<string>(this.url+'/utilisateur/password/new')
  }
}
