<mat-card appearance="outlined">
    <form [formGroup]="formGroupServiceExterne">

        <h4>Modifier un service externe</h4>

        <mat-form-field>
              <mat-label>Libellé</mat-label>
              <input matInput type="text" id="srx_libelle" formControlName="srx_libelle" required>
              <mat-error *ngIf="srx_libelle?.invalid && (srx_libelle?.dirty || srx_libelle?.touched)">Vous devez saisir un libellé</mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>URL</mat-label>
            <input matInput type="text" id="srx_url" formControlName="srx_url" required>
            <mat-error *ngIf="srx_url?.invalid && (srx_url?.dirty || srx_url?.touched)">Vous devez saisir une URL</mat-error>
        </mat-form-field>

        <!-- 
            Champ implémenter, mais ne sont pas modifiable pour le moment
            à noter, il faudra prévoir de masquer le mot de passe et le hasher en base de données
        -->
        <mat-form-field *ngIf="false">
            <mat-label>Utilisateur</mat-label>
            <input matInput type="text" id="srx_utilisateur" formControlName="srx_utilisateur">
            <mat-hint>Nom du compte utilisé par l'API.</mat-hint>
        </mat-form-field>

        <mat-form-field *ngIf="false">
            <mat-label>Mot de passe</mat-label>
            <input matInput type="text" id="srx_mot_de_passe" formControlName="srx_mot_de_passe">
            <mat-hint>Mot de passe du compte utilisé par l'API.</mat-hint>
        </mat-form-field>


        <mat-form-field>
            <mat-label>Service</mat-label>
            <mat-select name="srx_service" id="srx_service" formControlName="srx_service" required>
                <mat-option *ngFor="let service of services" [value]="service.id">
                    {{ service.libelle }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="srx_service?.invalid && (srx_service?.dirty || srx_service?.touched)">Vous devez sélectionner un service</mat-error>
        </mat-form-field> 

        <mat-slide-toggle name="srx_actif" formControlName="srx_actif" color="primary">
            {{( formGroupServiceExterne.controls.srx_actif.value ? 'Service externe activé' : 'Service externe désactivé' )}}
        </mat-slide-toggle>

        <div class="div-button-right" style="margin-top: 16px;">
            <button mat-flat-button type="button" color="basic" (click)="onClickAnnuler()"><mat-icon>west</mat-icon>Annuler</button>
            <button mat-flat-button 
                    *ngIf       = "!bWaitingModification"
                    color       = "primary" 
                    (click)     = "onClickEnregistrer()" 
                    [disabled]  = "formGroupServiceExterne.pristine || !(formGroupServiceExterne.valid && formGroupServiceExterne.dirty)"
                    >
                    Enregistrer
                    <mat-icon>save</mat-icon></button>
                    <mat-spinner diameter="24" *ngIf="bWaitingModification"></mat-spinner>
        </div>
  </form>
</mat-card>