import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable,of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ResultatActionEnMasse } from '../interfaces/resultat-action-en-masse';
import { EtatTicket } from '../interfaces/support/etat-ticket';
import { FiltreRechercheTicket } from '../interfaces/support/filtre-recherche-ticket';
import { FiltreRechercheTypeDemande } from '../interfaces/support/filtre-recherche-type-demande';
import { HistoTicket } from '../interfaces/support/histo-ticket';
import { MessageTicket } from '../interfaces/support/message-ticket';
import { Ticket } from '../interfaces/support/ticket';
import { TypeDemande } from '../interfaces/support/type-demande';
import { FormGroup } from '@angular/forms';
import { Modele } from '../interfaces/support/modele';
import { Categorie } from '../interfaces/support/categorie';
import { SousCategorie } from '../interfaces/support/sous-categorie';
import { PersoSnackbarService } from './perso-snackbar.service';

@Injectable({
  providedIn: 'root'
})

export class ApiSupportService {
  
  CONST_CODE_ETAT_TICKET_NEW       = 'CODE_NOUVEAU'      as const;
  CONST_CODE_ETAT_TICKET_ENCOURS   = 'CODE_ENCOURS'      as const;
  CONST_CODE_ETAT_TICKET_CLOTURE   = 'CODE_CLOTURE'      as const;
  CONST_DATE_NULL                   = "0000-00-00T00:00:00.000" as const;
  
  modeles$                     = new BehaviorSubject<Modele[]>([]);
  categories$                  = new BehaviorSubject<Categorie[]>([]);
  sousCategories$              = new BehaviorSubject<SousCategorie[]>([]);
  categories                  : Categorie[] = [];
  sousCategories              : SousCategorie[] = [];
  modeles                     : Modele[] = [];
  modeleInit                  : Modele = {'mdl_code':'','mdl_contenu':'','mdl_id':0,'mdl_sou_cat_id':0,'mdl_nom':''};
  
  public isDataModify: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public TicketResultSave:Ticket[]=[];
  public FormGroupSaved?:FormGroup;
  public FileFormat:string[] = [
    "application/octet-stream"
    ,"application/pdf"
    ,"image/png"
    ,"image/jpeg"
    ,"application/msword"
    ,"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ,"text/csv"
    ,"application/vnd.ms-excel"
    ,"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  ];
  url: string = '/api';

  constructor(private http: HttpClient
            , private toast       : PersoSnackbarService) {}


  // 4 - TICKETS

  //4.1 - Select
  public getTickets(filtreTicket: FiltreRechercheTicket): any { 
    return this.http.post<Ticket[]>(this.url+"/support/ticket/liste",filtreTicket)
                    .pipe(catchError(this.handleError<Ticket[]>('getTickets',[])));
  }
 
  //4.2 - Chargement d'un ticket complet depuis son id (on retourne toutefois un tableau qui n'a qu'un seul élément)
  public getTicketComplet(idTicket:number): Observable<Ticket[]> { 
    return this.http.get<Ticket[]>(this.url+"/support/ticket/"+idTicket);
  } 
 
  //4.3 - Insert
  public postTicket(ticket: Ticket): Observable<Ticket> {
    return this.http.post<Ticket>(this.url+"/support/ticket", ticket)
                  .pipe(catchError(this.handleError<Ticket>('postTicket')));
  }

  //4.4 - Mise à jour de la date de première lecture
  public updateFirstLectureTicket(idTicket:number):Observable<Ticket[]>{
    return this.http.get<Ticket[]>(this.url+"/support/premierelecture/"+idTicket)
            .pipe(catchError(this.handleError<Ticket[]>('updateFirstLectureTicket',[])));
  }

  //4.5 - Insert un historique de ticket:
  public postHistoTicket(histoTicket: HistoTicket) : Observable<Ticket[]> {
    return this.http.post<Ticket[]>(this.url+'/support/ticket/histo', histoTicket)
                    .pipe(catchError(this.handleError<Ticket[]>('postHistoTicket',[])));
  }

  //4.6 - Insert un message:
  public postMessage(message: MessageTicket): Observable<MessageTicket> {    
    return this.http.post<MessageTicket>(this.url+"/support/ticket/message", message)
                    .pipe(catchError(this.handleError<MessageTicket>('postMessage')));
  }

  public getListeEtatTicket(): Observable<EtatTicket[]> {
    return this.http.get<EtatTicket[]>(this.url+"/support/liste/etat-ticket")
                  .pipe(catchError(this.handleError<EtatTicket[]>('getListeEtatTicket')));
  }

// 5 - TYPES DE DEMANDE

 //5.1 - Select
 public getListeTypeDemande(): Observable<TypeDemande[]> {   
  return this.http.get<TypeDemande[]>(this.url+"/support/liste/type-demande");
}

  // 5.2 - Select : Chargement d'un type de demande
  public getTypeDemande(id: number): Observable<TypeDemande> {
    let url_parametres: string = '';
    url_parametres += (id > 0 ? 'id=' + id : '');
    return this.http.get<TypeDemande>(this.url+"/support/type-demande?" + url_parametres);
  }

  // 5.3 - Update
  public putTypeDemande(typ_id: number, type_demande: TypeDemande) : Observable<TypeDemande> {
    return this.http.put<TypeDemande>(this.url+'/support/type-demande/fiche/'+typ_id, type_demande);
  }

  // 5.4 - Insert
  public postTypeDemande(type_demande: TypeDemande) : Observable<TypeDemande> {
    return this.http.post<TypeDemande>(this.url+'/support/type-demande/fiche', type_demande);
  }

  //5.5 - Recherche de type de demande avec des filtres
  public postSearchingTypeDemande(filtre_recherche: FiltreRechercheTypeDemande) : Observable<TypeDemande[]> {
    return this.http.post<TypeDemande[]>( this.url + '/support/type-demande', filtre_recherche );
  }

  // 5.6 - Suppression de type de demande en masse
  public postSuppressionEnMasseTypeDemande(type_demande: Array<TypeDemande>) : Observable<ResultatActionEnMasse[]> {
    return this.http.post<ResultatActionEnMasse[]>( this.url + '/support/type-demande/action-en-masse/suppression', type_demande );
  }

  //5.7 - Activation de type de demande en masse
  public postActivationEnMasseTypeDemande(type_demande: Array<TypeDemande>) : Observable<ResultatActionEnMasse[]> {
    return this.http.post<ResultatActionEnMasse[]>( this.url + '/support/type-demande/action-en-masse/activation', type_demande );
  }

  //5.8 - Désactivation de type de demande en masse
  public postDesactivationEnMasseTypeDemande(type_demande: Array<TypeDemande>) : Observable<ResultatActionEnMasse[]> {
    return this.http.post<ResultatActionEnMasse[]>( this.url + '/support/type-demande/action-en-masse/desactivation', type_demande );
  }

  // DIVERS

  public getTicketStateIcon(ticketState:string):string{
    let stateIcon:string='';
    switch (ticketState) {
      case this.CONST_CODE_ETAT_TICKET_NEW:
        stateIcon='post_add'
        break;
      case this.CONST_CODE_ETAT_TICKET_ENCOURS:
        stateIcon='overview'
        break;
      case this.CONST_CODE_ETAT_TICKET_CLOTURE:
        stateIcon='lock'
        break;
    
      default:
        break;
    }
   

    return stateIcon;
  }

  public getStateClass(ticketState:string):string{
      let stateClass:string=''
      switch (ticketState) {
        case this.CONST_CODE_ETAT_TICKET_NEW:
          stateClass='tckNouveau'
          break;
        case this.CONST_CODE_ETAT_TICKET_ENCOURS:
          stateClass='tckEnCours'
          break;
        case this.CONST_CODE_ETAT_TICKET_CLOTURE:
          stateClass='tckCloture'
          break;
      
        default:
          break;
      } 
      return stateClass;
  }

  public keepSearchResult(ticketListSaved:Ticket[]){
    this.TicketResultSave = ticketListSaved;
  }
  public keepSearchFilter(formGroupSaved:FormGroup){
    this.FormGroupSaved = formGroupSaved;
  }

  // Téléchargement de pièces jointes
  public getDownFile(id_PJ: number, id_Ticket: number) {
    return this.http.get( this.url+'/support/ticket/' + id_PJ + '/' + id_Ticket, { responseType: "blob", headers: {'Content-Type': 'application/json', Accept: 'application/octet-stream,application/pdf,image/png,image/jpeg,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'} });
    // return this.http.get( this.url+'/support/ticket/' + id_PJ + '/' + id_Ticket, { responseType: "blob", headers: {'Content-Type': 'application/json', Accept: this.FileFormat} });
  }

  public getDateNull(date:string | null):boolean{
    return date== this.CONST_DATE_NULL? true:false;
  }

  // GESTION DES ERREURS

  private handleError<T>(operation = 'operation', result?: T) {
    
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.log(error);// log to console instead
  
      // TODO: better job of transforming error for user consumption
      this.toast.showError(error.error);  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
