<div>
    
    <img src="../../../../assets/img/charte/FFEA 2 lignes bloc aplat coul.jpg" style="width: 120px;">

    <h1 mat-dialog-title>
        <span class="label-accent">Commande N°{{ commande$.numero }}</span>&nbsp;
        <button mat-stroked-button color="primary">{{ status$.libelle }}</button>
    </h1>

    <div class="container" mat-dialog-content>        
        <h4>Contact N°{{ entite$.numero_client }}</h4>

        <!-- recapitulatif d'adresse de livraison/facturations -->
        <div class="container div-container-evenly">
            <div class="adresseLivraison div-padding">
                <div class="div-container" style="align-items: stretch;">
                    <div>
                        <p style="font-weight: bold;">Adresse de Facturation</p>
                        <app-carte-adresse [adresse]="commande$.adresseFacturation" *ngIf="commande$.adresseFacturation && commande$.adresseFacturation.ligne1!=''" [title]="commande$.raison_sociale"></app-carte-adresse>
                    </div>

                    <div>
                        <p style="font-weight: bold;">Adresse de Livraison</p>
                        <app-carte-adresse [adresse]="commande$.adresseLivraison" *ngIf="commande$.adresseLivraison && commande$.adresseLivraison.ligne1!=''" [title]="commande$.raison_sociale"></app-carte-adresse>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- tableau recapitulatif de commande -->
        <div class="container">
            <table mat-table matTableResponsiveLarge [dataSource]="dataSource" class="tableArticle">
                
                <!-- Article Column -->
                <ng-container matColumnDef="article">
                    <th mat-header-cell *matHeaderCellDef> Article </th>
                    <td mat-cell *matCellDef="let ligne_commande"> {{ligne_commande.article}} </td>
                </ng-container>
                
                <!-- Quantité Column -->
                <ng-container matColumnDef="quantite">
                    <th mat-header-cell *matHeaderCellDef> Quantité </th>
                    <td mat-cell *matCellDef="let ligne_commande"> {{ligne_commande.quantite}} &nbsp;&nbsp;x
                    </td>
                </ng-container>
                
                <!-- PUHT Column -->
                <ng-container matColumnDef="prix_unitaire_ht">
                    <th mat-header-cell *matHeaderCellDef> Prix unitaire HT </th>
                    <td mat-cell *matCellDef="let ligne_commande">{{ligne_commande.prix_unitaire_ht}}&nbsp;€ </td>
                </ng-container>
                
                <!-- Total HT Column -->
                <ng-container matColumnDef="total_ht">
                    <th mat-header-cell *matHeaderCellDef> Total HT </th>
                    <td mat-cell *matCellDef="let ligne_commande"> {{ligne_commande.total_ht}}&nbsp;€ </td>
                </ng-container>
                
                <!-- / mois -->
                <ng-container matColumnDef="par_Mois">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let ligne_commande"> {{ isLocation ? '/ mois' : '' }}</td>
                </ng-container>

                <!-- % Remise Column -->
                <ng-container matColumnDef="pourcentage_remise">
                    <th mat-header-cell *matHeaderCellDef> % Remise </th>
                    <td mat-cell *matCellDef="let ligne_commande"> {{ligne_commande.pourcentage_remise}}&nbsp;% </td>
                </ng-container>

                <!-- Total Remise HT Column -->
                <ng-container matColumnDef="total_remise_ht">
                    <th mat-header-cell *matHeaderCellDef> Total remise HT </th>
                    <td mat-cell *matCellDef="let ligne_commande"> {{ligne_commande.total_remise_ht}}&nbsp;€ </td>
                </ng-container>
                
                <!-- TVA Column -->
                <ng-container matColumnDef="tauxTVA">
                    <th mat-header-cell *matHeaderCellDef>TVA</th>
                    <td mat-cell *matCellDef="let ligne_commande"> {{ ligne_commande.tauxTVA }} %</td>
                </ng-container>
                
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    
        <div class="div-container">
            <div *ngFor="let element of totaux$.total" class="totaux div-padding">
                <p class="totauxLibelle" style="color: rgb(39,58,130)">{{ element.libelle }}</p>
                <p class="totauxPrix">{{ element.montant }}&nbsp;€</p>
            </div>
        </div>

    </div>

    <div class="div-container-left" mat-dialog-actions>
        <button mat-raised-button [mat-dialog-close]="apiCmd.CONST_ETAT_REFUSEE" *ngIf="isWaitingForODAction" color="warn">Refuser</button>
        <button mat-raised-button [mat-dialog-close]="apiCmd.CONST_ETAT_ANNULEE" *ngIf="isWaitingForKitAction" color="warn">Annuler</button>
        
        <div class="div-button-center">
            <button mat-button color="warn" [mat-dialog-close]="true" class="button-width">Fermer</button>
        </div>
        <button mat-raised-button [mat-dialog-close]="apiCmd.CONST_ETAT_VALIDEE" *ngIf="isWaitingForODAction" color="primary">Valider</button>
        <button mat-raised-button [mat-dialog-close]="apiCmd.CONST_ETAT_VALIDEE" *ngIf="isWaitingForKitAction" color="primary">Expédier</button>
    </div>
</div>