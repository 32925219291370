
<mat-card appearance="outlined">
    <mat-card-title>
      <div class="div-container-left">
            <mat-icon color="primary">apartment</mat-icon>
            <span>Liste des succursales</span>
      </div>
    </mat-card-title>
    <br>
    <mat-card-subtitle>
            <mat-slide-toggle *ngIf="nb_succursale > 0 && est_dirigeant" (change)="onSuccursalesToggle($event)">Voir les succursales inactives</mat-slide-toggle>          
    </mat-card-subtitle>
    <br>
    <mat-card-content  class=".mat-card-content">
            <mat-label *ngIf="nb_succursale == 0 || RelUtilisateurEntite == undefined">
                  Vous n'avez pas de succursale rattachée à votre entité.
            </mat-label>
            <div *ngIf="RelUtilisateurEntite != undefined">
                  <mat-accordion *ngFor="let rel of obs$ | async, index as indice">                
                        <mat-expansion-panel hideToggle="false">
                              <mat-expansion-panel-header style="padding-top: 4px !important; padding-bottom: 4px !important;">
                                    <mat-panel-title>
                                          <div class="div-container-left">
                                                <mat-label class="grey-icon">{{ pageIndex * pageSize + indice + 1 }}. </mat-label>                                  
                                                <mat-icon class="img-logo grey-icon" *ngIf="!rel.entite.logo">apartment</mat-icon>
                                                <img class="img-logo" style="margin-right: 8px; margin-left: 8px;"
                                                      *ngIf="rel.entite.logo" 
                                                      [src]="_sanitizer.bypassSecurityTrustResourceUrl(rel.entite.logo)">                                                                                    
                                                <span>{{ rel.entite.raison_sociale |uppercase}}</span>
                                          </div>                                                                                                                                                            
                                    </mat-panel-title>      
                                    
                                    <mat-panel-description class="panel-description">
                                          <div class="panel-description-entite-details">                                          
                                                <mat-icon color="primary" *ngIf="rel.entite.EstActif">done</mat-icon>
                                                <mat-label *ngIf="rel.entite.EstActif">Actif</mat-label>

                                                <mat-icon color="warn" *ngIf="!rel.entite.EstActif">close</mat-icon>
                                                <mat-label *ngIf="!rel.entite.EstActif">Inactif</mat-label>
                                          </div>
                                                                                                                                                                                          
                                          <div class="panel-description-entite-details">
                                                <mat-icon color="primary" *ngIf="getAccesConcentrateur(rel.entite)">done</mat-icon>  
                                                <mat-icon color="warn" *ngIf="!getAccesConcentrateur(rel.entite)">close</mat-icon>
                                                <mat-label>Accès Concentrateur</mat-label>
                                          </div>                                                                                                                                          
                                          
                                          <div class="panel-description-entite-details">
                                                <button (click)="openSuccursale(rel.entite.id, $event);false" mat-icon-button color="primary">
                                                      <mat-icon>zoom_in</mat-icon>
                                                </button>             
                                          </div>
                                    </mat-panel-description>
                              </mat-expansion-panel-header>
        
                              <form>                                                                                                                                                                              
                                    <mat-form-field class="form-entite-details">
                                          <mat-label for="ent_actif">                                          
                                                Entité Active / Inactive
                                          </mat-label>
                                          <div style="display: flex;">
                                                <mat-icon color="primary" *ngIf="rel.entite.EstActif">done</mat-icon>
                                                <mat-icon color="warn" *ngIf="!rel.entite.EstActif">close</mat-icon>
                                                <input matInput type="text" placeholder="Entité actif / Inactif" name="ent_actif"
                                                      value="{{ rel.entite.EstActif ? 'Active' : 'Inactive'}}" [disabled]="true">
                                          </div>                                    
                                    </mat-form-field>
      
                                    <mat-form-field class="form-entite-details">
                                          <mat-label for="ent_acces_concentrateur">                                          
                                                Accès Concentrateur
                                          </mat-label>
                                          <div style="display: flex;">
                                                <mat-icon color="primary" *ngIf="getAccesConcentrateur(rel.entite)">done</mat-icon>
                                                <mat-icon color="warn" *ngIf="!getAccesConcentrateur(rel.entite)">close</mat-icon>
                                                <input matInput type="text" placeholder="Entité active / Inactive" name="utl_actif"
                                                      value="{{ getAccesConcentrateur(rel.entite) ? 'Accès autorisé' : 'Accès refusé' }}" [disabled]="true">
                                          </div>                                    
                                    </mat-form-field>
                                    <div class="div-container-left" style="margin-left: 20px;" *ngIf="rel.entite.telephone!=''">
                                          <div style="display: flex;align-items: center;">
                                                <div class="div-container-left">
                                                      <mat-icon style="color: rgba(39,58,130,1);" >call</mat-icon>
                                                      <span>{{ rel.entite.telephone | phoneFormat }}</span>
                                                </div>
                                          </div>
                                          <div style="display: flex;align-items: center;" *ngIf="rel.entite.mail_contact!=''">
                                                <div class="div-container-left">
                                                      <mat-icon style="color: rgba(39,58,130,1);">mail</mat-icon>
                                                      <span>{{ rel.entite.mail_contact }}</span>
                                                </div>
                                          </div>
                                          
                                    </div>
                                                                          
                                </form>
                                
                                <mat-action-row class="action-row">                                    
                                    <button (click)="openSuccursale(rel.entite.id, $event)" mat-icon-button color="primary">
                                          <mat-icon>zoom_in</mat-icon>
                                    </button>             
                              </mat-action-row>
                          </mat-expansion-panel>
                    </mat-accordion>
                  <mat-paginator *ngIf="nb_succursale > 0 && RelUtilisateurEntite != undefined" [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons (page)="onChangePaginator($event)"></mat-paginator>
            </div>            
    </mat-card-content>
</mat-card>