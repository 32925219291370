import { NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { Router } from '@angular/router';
import { Categorie } from 'src/app/interfaces/commande/categorie';
import { Commande } from 'src/app/interfaces/commande/commande';
import { Etat } from 'src/app/interfaces/dossier/etat';
import { ListeEtat } from 'src/app/interfaces/dossier/liste-etat';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiCommandeService } from 'src/app/services/api-commande.service';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { TableCommandeComponent } from '../table-commande/table-commande.component';
import { EntiteSearchComponent } from '../../templates/entite-search/entite-search.component';
import { MatTooltipModule } from '@angular/material/tooltip';

export interface ListeCategorie {
  nomService : string;
  tab_Categorie : Categorie[];
  selected: boolean
}

@Component({
    selector: 'app-mes-commandes',
    templateUrl: './mes-commandes.component.html',
    styleUrls: ['./mes-commandes.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatExpansionModule, MatIconModule, MatFormFieldModule, MatInputModule, NgIf
              , MatSelectModule, MatOptionModule, NgFor, MatButtonModule, MatProgressSpinnerModule, TableCommandeComponent
              , EntiteSearchComponent, MatTooltipModule]
})
export class MesCommandesComponent implements OnInit {
    
  // Liste des interfaces pour les combos
  commande$           : Array<Commande> = [];
  profilUtilisateur$  : ProfilUtilisateur = <ProfilUtilisateur>{};
  etats$              : Etat[] = [];
  categories$         : Categorie[] = [];
  haveSomeCommand     : boolean = false;
  entites             : Entite[] = [];

  cmdCategorie: any = {
    KIT_ADELA: '1',
    DIAG: '3',
    OPTION_DIAG  : '4'
  };

  waitingResearch   : boolean = false;
  isExpanded        : boolean = true;

  entiteSelected    : Entite|null =null
  searching         : boolean = false

  // Formulaire de filtrage
  FiltreFormGroup: any = this._fb.group({
    idCommande    : [0],
    idUtilisateur : [0],
    idEntite      : [0],
    reference     : [''],
    etats         : [Array<ListeEtat>(), [Validators.required]],
    categories    : [Array<ListeCategorie>(), [Validators.required]],
    dateDebut     : [''],
    dateFin       : [''],
    option        : ['']
  });
  
  get idUtilisateur()   { return this.FiltreFormGroup.get('idUtilisateur'); }
  get reference()       { return this.FiltreFormGroup.get('reference'); }
  get dateDebut()       { return this.FiltreFormGroup.get('dateDebut'); }
  get dateFin()         { return this.FiltreFormGroup.get('dateFin'); }
  get option()          { return this.FiltreFormGroup.get('option'); }
  get etats()           { return this.FiltreFormGroup.get('etats'); }
  get categories()      { return this.FiltreFormGroup.get('categories');}

  constructor(
    private   apiAuth     : ApiAuthentificationService
    , private apiCmd      : ApiCommandeService
    , private _fb         : FormBuilder 
    , private route       : Router
    , private apiEntite   : ApiEntiteService
  ) {
    
  }

  ngOnInit(): void {
    this.FiltreFormGroup.patchValue({
      idUtilisateur: this.apiAuth.userConnect.id
    })
    
    this.profilUtilisateur$ = this.apiAuth.userConnect
    this.loadStatusCommand();
    this.loadCategorieCommande();
    this.loadCabinets();
  }

  setEntite(entite:Entite|null){
    this.searching = false
    if (entite) {
      this.entiteSelected = entite
    }
  }

  searchingEntite(){
    this.searching = true
  }

  cancelEntite(){
    this.entiteSelected = null
  }

  // Recherche et Chargement des commandes
  public findAll() {
    // Commande
    this.waitingResearch = true;
    this.commande$ = Array<Commande>();
    if (this.profilUtilisateur$.droits_utilisateur.droit_module_admin_compta || this.profilUtilisateur$.droits_utilisateur.droit_module_admin_adela) {
      this.FiltreFormGroup.get('idEntite').setValue(this.entiteSelected?.id? this.entiteSelected?.id : 0 )
    }
    
    this.apiCmd.getAllCommand(this.FiltreFormGroup.value)
    .subscribe(
      (data: Commande[]) => {        
        if ( data != undefined ) {
          
          this.commande$ = data;
          this.haveSomeCommand=true
          this.isExpanded = (this.commande$.length <= 0);
        }
      },
      (err) => { 
        
        this.commande$ = [];
        this.waitingResearch = false;
      },
      () => {
        this.waitingResearch = false;
      }
    );
  }

  // Chargement des status/Etats de commande : ENREGISTRE, ENVOYER, REFUSE, ANNULE, ...
  public loadStatusCommand() {
    this.apiCmd.getStatusCommand()
    .subscribe(
      (data : Etat[]) => {
        this.etats$ = data;
        this.etats.setValue(this.etats$.filter((etat)=> etat.code === this.apiCmd.CONST_ETAT_ATTENTE || etat.code === this.apiCmd.CONST_ETAT_VALIDEE || etat.code === this.apiCmd.CONST_ETAT_PREPARATION).map((etat) => etat.code))
      }
      , (err => { console.log(err) })
      , () => {}
    )
  }
  // Chargement des catgorie de commandes
  public loadCategorieCommande() {
    this.apiCmd.getAllCategories()
    .subscribe(
      (data: Categorie[]) => {
        this.categories$ = data;
        this.categories.setValue(this.categories$.map((categorie)=>categorie.code))
      },
      (err) => { console.error(err) },
      () => {}
    )
  }
  
  
  public refreshCommand($event: boolean) {
    this.findAll();
  }
  // Chargement de la page de commande kit
  public goToCommandKIT() {
    this.route.navigate(['/app/article/kits', this.cmdCategorie.KIT_ADELA])
  }
  // Chargement de la page de commande d'outil diagnostic
  public goToCommandDIAG() {
    this.route.navigate(['/app/article/kits', this.cmdCategorie.DIAG])
  }
 
  private loadCabinets() {
    if (!(this.profilUtilisateur$.droits_utilisateur.droit_module_admin_compta || this.profilUtilisateur$.droits_utilisateur.droit_module_admin_adela)) {
      this.apiEntite.getEntitesByIdUtilisateur(this.profilUtilisateur$.id, 0, '', true)
       .subscribe(
         (data: Entite[])  => { 
          this.entites = data;
        }
      )  
    }
  }
 
  }
