import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { EntiteAdresse } from '../entite-adresse';
import { EntiteAdresseComponent } from '../entite-adresse.component';

@Component({
    selector: 'app-dialog-entite-adresse',
    templateUrl: './dialog-entite-adresse.component.html',
    styleUrls: ['./dialog-entite-adresse.component.scss'],
    standalone: true,
    imports: [MatDialogModule, EntiteAdresseComponent, MatButtonModule]
})
export class  DialogEntiteAdresseComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogEntiteAdresseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Entite[]) { }

  ngOnInit(): void {
  }

  getAdresse(entiteAdresse:EntiteAdresse){
    this.dialogRef.close(entiteAdresse);
  }

  cancel(){
    this.dialogRef.close(false);
  }

}
