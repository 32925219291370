import { Component, OnInit, EventEmitter } from '@angular/core';
import { DossierReparateur } from 'src/app/interfaces/services-ffea/reparateur/dossier-reparateur';
import { RechercheResultatComponent } from '../recherche-resultat/recherche-resultat.component';
import { RechercheFiltreComponent } from '../recherche-filtre/recherche-filtre.component';
import { RechercheStatusComponent } from '../recherche-status/recherche-status.component';
export interface OutPutRechercheDossierReparateur{
  dossier_reparateur: DossierReparateur;
  expandPannel_creativ_expertiz : boolean;
  expandPannel_alliance_experts : boolean;
  expandPannel_experts_groupe   : boolean;
}
export interface OutPutSelectEntite{
  code_reparateur_creativ_expertiz : boolean;
  code_reparateur_alliance_experts : boolean;
  code_reparateur_experts_groupe   : boolean;
}
@Component({
    selector: 'app-recherche-services-externe',
    templateUrl: './recherche-services-externe.component.html',
    styleUrls: ['./recherche-services-externe.component.scss'],
    standalone: true,
    imports: [RechercheStatusComponent, RechercheFiltreComponent, RechercheResultatComponent]
})
export class RechercheServicesExterneComponent implements OnInit {

  dossier_reparateur                : DossierReparateur = <DossierReparateur>{};
  expandPannel_creativ_expertiz     : boolean = false;
  expandPannel_alliance_experts     : boolean = false;
  expandPannel_experts_groupe       : boolean = false;
  code_reparateur_creativ_expertiz  : boolean = false;
  code_reparateur_alliance_experts  : boolean = false;
  code_reparateur_experts_groupe    : boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  // Transmets les résultats de la recherche au component des résultats
  public onChildResearchDossierReparateur(event: OutPutRechercheDossierReparateur): void {
    this.dossier_reparateur             = event.dossier_reparateur;
    this.expandPannel_creativ_expertiz  = event.expandPannel_creativ_expertiz;
    this.expandPannel_alliance_experts  = event.expandPannel_alliance_experts;
    this.expandPannel_experts_groupe    = event.expandPannel_experts_groupe;
  }

  public onChildSelectEntite(event: OutPutSelectEntite): void {
    this.code_reparateur_creativ_expertiz = event.code_reparateur_creativ_expertiz;
    this.code_reparateur_alliance_experts = event.code_reparateur_alliance_experts;
    this.code_reparateur_experts_groupe   = event.code_reparateur_experts_groupe;
  }
}
