import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Observer, from, of } from 'rxjs';
import { catchError, concatMap, take } from 'rxjs/operators';
import {  UploadFile } from 'src/app/interfaces/fichier';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgFor, NgStyle } from '@angular/common';

const INVALID_FILE    = ' Le fichier invalide.';
const INVALID_IMAGE   = ' L\'image est invalide.';
const INVALID_SIZES   = ' La taille total des fichiers est invalide .';
const INVALID_SIZE    = ' La taille du fichier est invalide .';
const ALREADY_SET     = ' Le fichier est déjà sélectionné.';

export interface UploadParameter{
  fileMaxNumber?   : number,
  fileMaxSize?     : number,
  activated?       : boolean,
  buttonText?      : string,
  accept?          : string,
  multiple?        : boolean
}

export const initialUploadParameter:UploadParameter={
  accept          : '',
  fileMaxNumber   : 0,
  fileMaxSize     : 5000,
  activated       : true,
  buttonText      : '',
  multiple        : true
}
@Component({
    selector: 'app-upload-files',
    templateUrl: './upload-files.component.html',
    styleUrls: ['./upload-files.component.scss'],
    standalone: true,
    imports: [NgIf, MatButtonModule, MatIconModule, NgFor, NgStyle]
})
export class UploadFilesComponent implements OnInit {
@Input() uploadParameter:UploadParameter= initialUploadParameter
@Output() uploadedFiles : EventEmitter<UploadFile[]>  = new EventEmitter<UploadFile[]>();

files           : UploadFile[] = [];
  
constructor(private toast         : PersoSnackbarService) { }

  ngOnInit(): void {
    this.setButtonName()
  }
  
  setButtonName(){
    if (!this.uploadParameter.buttonText) {
    
      switch (this.uploadParameter.fileMaxNumber) {
        case 0:
          this.uploadParameter.buttonText = 'Sélectionnez des fichiers'
          //no limit
          break;
        case 1:
          this.uploadParameter.buttonText = 'Sélectionnez un fichier'
          break;
        default:
          this.uploadParameter.buttonText = 'Sélectionnez des fichiers (max : ' + this.uploadParameter.fileMaxNumber + ' fichiers)'
          break;
      }}
    
  }
  
  
  // supprime le fichier depuis son indice de tableau
  public deleteFile(indice: number) {
    // On ne supprime pas la premiere occurrence
    this.files.splice(indice, 1)
    this.uploadedFiles.emit(this.files);
  }

  uploadFiles(event:Event): void {
    const input = event.target as HTMLInputElement;
    if (!input.files?.length) {
      return;
    }
    const files = input?.files;
    const numberOfFiles = files.length ;
    from(files)
    .pipe(
      concatMap((file: File) => this.validateFile(file).pipe(catchError((error: UploadFile) => of(error)))),
      take(numberOfFiles)
    )
    .subscribe((validatedFile:UploadFile) => {
      if (validatedFile.file) {
        if (this.uploadParameter.multiple===false) {
          //on remplace l'éventuel fichier déjà uploadé:
          this.files.splice(0)
        }
        this.files.push(validatedFile)
        this.uploadedFiles.emit(this.files);
        
      }else{
        this.toast.showError(validatedFile.error?.errorMessage? validatedFile.error?.errorMessage : '')
      }
    });
  }

  private validateFile(file: File): Observable<UploadFile> {
    const fileReader = new FileReader();
    const { type, name } = file;
    
    return new Observable((observer: Observer<UploadFile>) => {
      
      this.validateNotYetMax(file,observer)
      this.validateNotAlready(file,observer);
      this.validateSize(file, observer);
      
      fileReader.readAsDataURL(file);
      fileReader.onload = event => {
        if (this.isImage(type)) {
          const image = new Image();
          
          image.onload = () => {
            observer.next({ file });
            observer.complete();
          };
          image.onerror = () => {
            observer.error({ error: { name, errorMessage: INVALID_IMAGE } });
          };
          image.src = fileReader.result as string;
        } else {
          
          observer.next({file,contenu:fileReader.result as string});
          observer.complete();
        }
      };
      fileReader.onerror = () => {
        observer.error({ error: { name, errorMessage: INVALID_FILE } });
      };
    });
  }

  private isImage(mimeType: string): boolean {
    return false;
    // return mimeType.match(/image\/*/) !== null;
  }

  private validateSize(file: File, observer: Observer<UploadFile>): void {
    const {name, size} = file;
    if (!this.isValidSize(size)) {
      observer.error({error: {name, errorMessage: this.getConstInvalidSize()}});
    }
  }

  private validateNotAlready(file:File,observer: Observer<UploadFile>):void{
    const {name} = file;
    if(!this.isNotAlreadySet(file.name)){
      observer.error({error: {name, errorMessage: ALREADY_SET}});
    }
  }

  private validateNotYetMax(file:File,observer: Observer<UploadFile>){
    if (!this.isMaxNotYetSet()) {
      observer.error({error: { errorMessage:  'Le nombre maximal de ' + this.uploadParameter.fileMaxNumber + ' fichiers est déjà atteint' }});
    }
  }
  private isNotAlreadySet(name:string){
    const fileFound = this.files.map((file)=>file.file?.name).find((n)=>n===name)
    return fileFound? false : true
  }
  private isValidSize(size: number): boolean { 
    return ((size + this.getTotalSize()) / 1024) <= this.uploadParameter.fileMaxSize!;
  }

  private getTotalSize(){
    let totalSize:number=0
    if (this.uploadParameter.multiple===true) {
      //Dans le cas d'une sélection simple, le nouveau fichier remplacera l'ancien, donc on ne vérifie que la taille du fichier en cours et non la somme
      this.files.forEach((file:UploadFile)=> totalSize += file.file?.size? file.file?.size: 0 )
    }
    return totalSize
  }

  private getConstInvalidSize(){
    if (this.files.length===0) {
      return INVALID_SIZE
    }else{
      return INVALID_SIZES
    }
  }
  private isMaxNotYetSet(){
    return this.uploadParameter.fileMaxNumber != 0? (this.files.length < this.uploadParameter.fileMaxNumber!) : true
  }
  
}
