<form [formGroup]="suiviForm">
    <mat-card>
        <mat-card-content>
            <div class="content" formGroupName="information" style="margin-top: 0px;">
                <div class="titlebox">Initialisation du dossier</div>
                    <div class="div-container">
                    <label>Type d'opération :</label>
                    <mat-radio-group class="radio-group" formControlName="type">
                        <mat-radio-button value=1>Déclanchement d'opposition (1A)</mat-radio-button>
                        <mat-radio-button value=2>Infirmation d'opposition FO (1B)</mat-radio-button>
                        <mat-radio-button value=3>Confirmation d'opposition (1B)</mat-radio-button>
                        <mat-radio-button value=4>Levée d'opposition (1C)</mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="suiviForm.get('information.type')?.hasError('required')">La sélection d'un type d'opération est obligatoire</mat-error>
                
                    <!-- DATE EFFET -->
                    <mat-form-field  class="formDate">
                        <mat-label>Date d'effet</mat-label>
                        <input 
                            matInput 
                            formControlName = "dateEffet"
                            type            = "date"
                            >
                        <mat-error>{{getErrors('dateEffet')}}</mat-error>
                    </mat-form-field>

                    <!-- CABINET -->
                    <mat-form-field >
                        <mat-label>Cabinet</mat-label>
                        <mat-select formControlName="idEntite">
                            <mat-option 
                                *ngFor="let entite of entites" 
                                [value]="entite.id"
                                >
                                {{entite.raison_sociale}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="suiviForm.get('idEntite')?.hasError('required')">La sélection d'un cabinet est obligatoire</mat-error>
                    </mat-form-field>

                    <button mat-raised-button *ngIf="rdeaFile?.technique?.immatriculation!=''" (click)="editSuiviSIV()" color="primary">
                        <mat-icon>list</mat-icon>
                        Voir le suivi SIV
                    </button>
                </div>
            </div>

            <div class="content" style="margin-top: 10px;">
                <div class="titlebox">Informations du dossier</div>
    <mat-stepper #stepper orientation="vertical" linear="false">

        <mat-step [stepControl]="suiviForm.get('reference')!" formGroupName="reference" [errorMessage]="errorMessageStepper">
            <ng-template matStepLabel>Références</ng-template>
            <div class="div-container">

                <!-- NUMERO DE DOSSIER -->
                <mat-form-field >
                    <mat-label>Numéro de dossier</mat-label>
                    <input 
                        matInput 
                        oninput         = "this.value = this.value.toUpperCase()"
                        formControlName = "numeroDossier"
                        maxlength       = "15"
                        appAlphaNum
                        >
                        <mat-error *ngIf="suiviForm.get('reference.numeroDossier')?.hasError('required')">Le numéro de dossier est obligatoire</mat-error>
                </mat-form-field>

                <!-- NATURE DE L'EVENEMENT -->
                <mat-form-field >
                    <mat-label>Nature de l'évènement</mat-label>
                    <mat-select formControlName="natureEvenement">
                        <mat-option 
                            *ngFor="let natEven of references.natures_evenements" 
                            [value]="natEven.code"
                            >
                            {{natEven.valeur}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            
                <!-- NATURE ADVERSE -->
                <mat-form-field >
                    <mat-label>Nature adverse</mat-label>
                    <mat-select formControlName="natureAdverse">
                        <mat-option 
                            *ngFor="let natAdv of references.natures_adverses" 
                            [value]="natAdv.code"
                            >
                            {{natAdv.valeur}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                
                <!-- DATE DE SINISTRE -->
                <mat-form-field>
                    <mat-label>Date de sinistre</mat-label>
                    <input 
                        matInput 
                        formControlName = "dateSinistre"
                        type            = "date"
                        >
                    <mat-error>{{getErrors('dateSinistre')}}</mat-error>
                </mat-form-field>
            </div>
        </mat-step>

        <mat-step [stepControl]="suiviForm.get('technique')!" formGroupName="technique" [errorMessage]="errorMessageStepper">
            <ng-template matStepLabel>Informations techniques</ng-template>
            <div class="div-container">
                <!-- IMMATRICULATION -->
                <mat-form-field>
                    <mat-label>Immatriculation</mat-label>
                    <input 
                        matInput 
                        appImmat
                        formControlName = "immatriculation"
                        type            = "text"
                        (blur)            = "getInfosVehicule($event)"
                        >
                    <mat-error *ngIf="suiviForm.get('technique.immatriculation')?.hasError('required')">Le numéro d'immatriculation est obligatoire</mat-error>
                </mat-form-field>

                <!-- NUMERO VIN -->
                <mat-form-field>
                    <mat-label>VIN</mat-label>
                    <input 
                        matInput 
                        appAlphaNum
                        maxlength="17"
                        formControlName = "vin"
                        type            = "text"
                        >
                    <mat-error>{{getErrors('vin')}}</mat-error>
                </mat-form-field>

                <!-- NUMERO DE FORMULE -->
                <mat-form-field>
                    <mat-label>Numéro de formule</mat-label>
                    <input 
                        matInput 
                        appAlphaNum
                        formControlName = "numeroFormule"
                        type            = "text"
                        oninput         = "this.value = this.value.toUpperCase()"
                        maxlength       = "11"
                        >
                    <mat-error *ngIf="suiviForm.get('technique.numeroFormule')?.hasError('required')">Le numéro de formule est obligatoire</mat-error>
                </mat-form-field>

                <!-- DATE DE MISE EN SERVICE -->
                <mat-form-field class="formdate">
                    <mat-label>Date de MEC</mat-label>
                    <input 
                        matInput 
                        formControlName = "dateMec"
                        type            = "date"
                        >
                    <mat-error>{{getErrors('dateMec')}}</mat-error>
                </mat-form-field>

                <!-- DATE DE CI -->
                <mat-form-field class="formdate">
                    <mat-label>Date CI</mat-label>
                    <input 
                        matInput 
                        formControlName = "dateCi"
                        type            = "date"
                        >
                    <mat-error>{{getErrors('dateCi')}}</mat-error>
                </mat-form-field>

                <!-- CNIT -->
                <mat-form-field>
                    <mat-label>CNIT / Type mine</mat-label>
                    <input 
                        matInput 
                        formControlName = "typeMine"
                        type            = "text"
                        maxlength       = "15"
                        oninput         = "this.value = this.value.toUpperCase()"
                        >
                    <mat-error *ngIf="suiviForm.get('technique.typeMine')?.hasError('required')">Le type mine est obligatoire</mat-error>
                </mat-form-field>

                <!-- GENRE -->
                <mat-form-field>
                    <mat-label>Genre</mat-label>
                    <mat-select formControlName="genre">
                        <mat-option 
                            *ngFor="let genre of references.genres" 
                            [value]="genre.code"
                            >
                            {{genre.valeur}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="suiviForm.get('technique.genre')?.hasError('required')">La sélection d'un genre est obligatoire</mat-error>
                </mat-form-field>
            
                <!-- MARQUE -->
                <mat-form-field>
                    <mat-label>Marque</mat-label>
                    <mat-select formControlName="marque">
                        <mat-option 
                            *ngFor="let marque of references.marques" 
                            [value]="marque.code"
                            >
                            {{marque.valeur}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="suiviForm.get('technique.marque')?.hasError('required')">La marque est obligatoire</mat-error>
                </mat-form-field>

                <!-- MODELE -->
                <mat-form-field>
                    <mat-label>Modèle</mat-label>
                    <input 
                        matInput 
                        formControlName = "modele"
                        type            = "text"
                        maxlength       = "40"
                        oninput         = "this.value = this.value.toUpperCase()"
                        >
                    <mat-error *ngIf="suiviForm.get('technique.modele')?.hasError('required')">La modèle est obligatoire</mat-error>
                </mat-form-field>

                <!-- CARROSSERIE -->
                <mat-form-field>
                    <mat-label>Carrosserie</mat-label>
                    <mat-select formControlName="carrosserie">
                        <mat-option 
                            *ngFor="let carrosserie of references.carrosseries" 
                            [value]="carrosserie.code"
                            >
                            {{carrosserie.valeur}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="suiviForm.get('technique.carrosserie')?.hasError('required')">La sélection d'une carrosserie est obligatoire</mat-error>
                </mat-form-field>

                <!-- COULEUR -->
                <mat-form-field>
                    <mat-label>Couleur</mat-label>
                    <mat-select formControlName="couleur">
                        <mat-option 
                            *ngFor="let couleur of references.couleurs" 
                            [value]="couleur.code"
                            >
                            {{couleur.valeur}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="suiviForm.get('technique.couleur')?.hasError('required')">La sélection d'une couleur est obligatoire</mat-error>
                </mat-form-field>

                <!-- ENERGIE -->
                <mat-form-field>
                    <mat-label>Energie</mat-label>
                    <mat-select formControlName="energie">
                        <mat-option 
                            *ngFor="let energie of references.energies" 
                            [value]="energie.code"
                            >
                            {{energie.valeur}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="suiviForm.get('technique.energie')?.hasError('required')">La sélection d'une énergie est obligatoire</mat-error>
                </mat-form-field>

                <!-- KILOMETRAGE -->
                <mat-form-field class="smallNumber">
                    <mat-label>Kilométrage</mat-label>
                    <input 
                        matInput 
                        formControlName = "kilometrage"
                        type            = "text"
                        appThousandSeparator
                        >
                        <span matSuffix class="mat-Suffix">km</span>
                </mat-form-field>

                <!-- PUISSANCE FISCALE -->
                <mat-form-field class="smallNumber" >
                    <mat-label>Puissance fiscale</mat-label>
                    <input 
                        matInput 
                        formControlName = "puissanceFiscale"
                        type            = "number"
                        max             = "9999"
                        >
                </mat-form-field>

                <!-- PUISSANCE REELLE -->
                <mat-form-field  class="smallNumber">
                    <mat-label>Puissance réelle</mat-label>
                    <input 
                        matInput 
                        formControlName = "puissanceReelle"
                        type            = "number"
                        max             = "9999"
                        >
                </mat-form-field>
            </div>
        </mat-step>

        <mat-step [stepControl]="suiviForm.get('expertise')!" formGroupName="expertise" [errorMessage]="errorMessageStepper">
            <ng-template matStepLabel>Expertise</ng-template>
            <div class="div-container">
                <!-- VALEUR TOTALE HT -->
                <mat-form-field  class="smallNumber">
                    <mat-label>Valeur totale HT</mat-label>
                    <input 
                        matInput 
                        formControlName = "valeurTotaleHt"
                        type            = "text"
                        appThousandSeparator
                        >
                    <span matSuffix class="mat-Suffix">€</span>
                </mat-form-field>

                <!-- VALEUR TOTALT TTC -->
                <mat-form-field class="smallNumber" >
                    <mat-label>Valeur totale TTC</mat-label>
                    <input 
                        matInput 
                        formControlName = "valeurTotaleTtc"
                        type            = "text"
                        appThousandSeparator
                        >
                    <span matSuffix class="mat-Suffix">€</span>
                </mat-form-field>
                <!-- TOTAL REPARATION HT -->
                <mat-form-field class="smallNumber">
                    <mat-label>Total Réparations (HT)</mat-label>
                    <input 
                        matInput 
                        appThousandSeparator
                        formControlName = "totalHt"
                        type            = "text"
                        >
                    <span matSuffix class="mat-Suffix">€</span>
                </mat-form-field>

                <!-- PIECES HT -->
                <mat-form-field class="smallNumber">
                    <mat-label>Pièces (HT)</mat-label>
                    <input 
                        matInput 
                        appThousandSeparator
                        formControlName = "piecesHt"
                        type            = "text"
                        >
                    <span matSuffix class="mat-Suffix">€</span>
                </mat-form-field>
                <div class="div-container-left" style="align-items: stretch;">
                    <div class="content" style="max-width: fit-content;">
                        <div class="titlebox">Localisation des chocs</div>
                        <div>
                            <div style="border-bottom: solid 2px #273a82;margin-bottom: 10px;">
                                <mat-checkbox formControlName="ensemble" (change)="uncheckAll($event.checked)" >Ensemble</mat-checkbox>
                            </div>
                            <div>
                                <mat-checkbox formControlName="chocDessus" (change)="uncheckEnsemble($event.checked)">Dessus</mat-checkbox>
                                <mat-checkbox formControlName="chocSoubassement" (change)="uncheckEnsemble($event.checked)">Soubassement</mat-checkbox>
                                <mat-checkbox formControlName="chocTrainsRoulants" (change)="uncheckEnsemble($event.checked)">Trains roulants</mat-checkbox>
                                <mat-checkbox formControlName="chocMoteurBvPonts" (change)="uncheckEnsemble($event.checked)">Moteur/BV/Ponts</mat-checkbox>
                                <mat-checkbox formControlName="chocHabitacle"  (change)="uncheckEnsemble($event.checked)">Habitacle</mat-checkbox>
                            </div>
                            <div style="max-width: fit-content;">
                                <div class="center" ><mat-checkbox formControlName="chocAvant" (change)="uncheckEnsemble($event.checked)">Avant</mat-checkbox></div>
                                <div class="div-container-left">
                                    <div>
                                        <div class="vertical">
                                            <mat-checkbox formControlName="chocAvantG" labelPosition="before" (change)="uncheckEnsemble($event.checked)">Av. G</mat-checkbox>
                                        </div>
                                        <div>
                                            <mat-checkbox formControlName="chocLateralG" labelPosition="before" (change)="uncheckEnsemble($event.checked)">Lat. G</mat-checkbox>
                                        </div>
                                        <div class="vertical">
                                            <mat-checkbox formControlName="chocArriereG" labelPosition="before" (change)="uncheckEnsemble($event.checked)">Ar. G</mat-checkbox>
                                        </div>
                                    </div>
                                    <div>
                                        <img src="../../../../../assets/img/voiturechoc.png" alt="">
                                    </div>
                                    <div>
                                        <div>
                                            <mat-checkbox formControlName="chocAvantD" (change)="uncheckEnsemble($event.checked)">Av. D</mat-checkbox>
                                        </div>
                                        <div>
                                            <mat-checkbox formControlName="chocLateralD" (change)="uncheckEnsemble($event.checked)">Lat. D</mat-checkbox>
                                        </div>
                                        <div>
                                            <mat-checkbox formControlName="chocArriereD" (change)="uncheckEnsemble($event.checked)">Ar. D</mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="center"><mat-checkbox formControlName="chocArriere" (change)="uncheckEnsemble($event.checked)">Arrière</mat-checkbox></div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="content" style="max-width:min-content;">
                        <div class="titlebox">Usure des pneus</div>
                        <!-- Usure des pneus -->
                        <div style="display: flex;">

                            <div>
                                <!-- AVG -->
                                <div>
                                    <mat-form-field class="pourcentage">
                                        <mat-label>AVG</mat-label>
                                        <input 
                                            matInput 
                                            formControlName = "usureAvg"
                                            type            = "number"
                                            appPercent
                                            >
                                        <span matSuffix class="mat-Suffix">%</span>
                                    </mat-form-field>
                                </div>
                                <!-- ARG -->
                                <div>
                                    <mat-form-field class="pourcentage">
                                        <mat-label>ARG</mat-label>
                                        <input 
                                            matInput 
                                            formControlName = "usureArg"
                                            type            = "number"
                                            appPercent
                                            >
                                        <span matSuffix class="mat-Suffix">%</span>
                                    </mat-form-field>
                                </div>
                                <!-- ARG2 -->
                                <div>
                                    <mat-form-field class="pourcentage">
                                        <mat-label>ARG2</mat-label>
                                        <input 
                                            matInput 
                                            formControlName = "usureArg2"
                                            type            = "number"
                                            appPercent
                                            >
                                        <span matSuffix class="mat-Suffix">%</span>
                                    </mat-form-field>
                                </div>
                            </div>
        
                            <div>
                                <!-- AVD -->
                                <div>
                                    <mat-form-field class="pourcentage">
                                        <mat-label>AVD</mat-label>
                                        <input 
                                            matInput 
                                            formControlName = "usureAvd"
                                            type            = "number"
                                            appPercent
                                            >
                                        <span matSuffix class="mat-Suffix">%</span>
                                    </mat-form-field>
                                </div>
                                <!-- ARD -->
                                <div>
                                    <mat-form-field class="pourcentage">
                                        <mat-label>ARD</mat-label>
                                        <input 
                                            matInput 
                                            formControlName = "usureArd"
                                            type            = "number"
                                            appPercent
                                            >
                                        <span matSuffix class="mat-Suffix">%</span>
                                    </mat-form-field>
                                </div>
                                <!-- ARD2 -->
                                <div>
                                    <mat-form-field class="pourcentage">
                                        <mat-label>ARD2</mat-label>
                                        <input 
                                            matInput 
                                            formControlName = "usureArd2"
                                            type            = "number"
                                            appPercent
                                            >
                                        <span matSuffix class="mat-Suffix">%</span>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        

                    </div>
                </div>
            </div>    
        </mat-step>

        <mat-step [stepControl]="suiviForm.get('conclusion')!" formGroupName="conclusion" [errorMessage]="errorMessageStepper">
            <ng-template matStepLabel>Conclusion</ng-template>
            <div class="div-container">
                <div class="containerRB">
                    <div>
                        <fieldset class="cadre rb">
                            <legend class="legend">Dangereux</legend>
                            <mat-radio-group class="radio-group" formControlName="dangereux">
                                <mat-radio-button value="1" (change)="setDangerous(true)">oui</mat-radio-button>
                                <mat-radio-button value="0" (change)="setDangerous(false)">non</mat-radio-button>
                            </mat-radio-group>
                        </fieldset>
                    </div>
                    <div>
                        <fieldset class="cadre rb">
                            <legend class="legend">Economiquement réparable</legend>
                            <mat-radio-group class="radio-group" formControlName="ecoReparable">
                                <mat-radio-button value="1">oui</mat-radio-button>
                                <mat-radio-button value="0">non</mat-radio-button>
                            </mat-radio-group>
                        </fieldset>
                    </div>
                    <div>
                        <fieldset class="cadre">
                            <legend class="legend">Techniquement réparable</legend>
                            <mat-radio-group class="radio-group" formControlName="techReparable">
                                <mat-radio-button value="1">oui</mat-radio-button>
                                <mat-radio-button value="0">non</mat-radio-button>
                            </mat-radio-group>
                        </fieldset>
                    </div>
                </div>
                <div class="div-container-left">
                    <div>
                        <fieldset class="cadre dangereux">
                            <legend class="legend">Critères de dangerosité imputables au sinistre</legend>
                            <div class="div-container">
                                <mat-checkbox formControlName="ca3">CA3<div class="subLine">Déformation importante</div></mat-checkbox>
                                <mat-checkbox formControlName="d3">DI3<div class="subLine">Déformation importante</div></mat-checkbox>
                                <mat-checkbox formControlName="ls3">LS3<div class="subLine">Déformation importante</div></mat-checkbox>
                                <mat-checkbox formControlName="sp4">SP4<div class="subLine">Dysfonctionnement</div></mat-checkbox>
                                <mat-checkbox formControlName="en5">EN5<div class="subLine">Fissure, cassure ou fuite</div></mat-checkbox>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </mat-step>
        
        <mat-step *ngIf="errors.length>0">
            <ng-template matStepLabel><span class="error">Erreurs</span></ng-template>
            <div    style="font-size: 1.2em;">{{errors[0]}}</div>
            <ul *ngFor="let error of errors; let index = index; let isFirst = first">
                <li *ngIf="!isFirst" class="error">{{index}} - {{error}}</li>
            </ul>
        </mat-step>

        </mat-stepper>
        </div>
        </mat-card-content>
        <mat-card-actions align="end">
            <button  mat-raised-button color="primary" style="margin-left: 10px;"(click)="getRetour()">
                <mat-icon>west</mat-icon>
                Retour
            </button>
            <button *ngIf="!waiting" mat-raised-button color = "primary" [disabled] = "suiviForm.invalid" (click) = "sendFile()">
                <mat-icon>upload_file</mat-icon>
                Envoyer
            </button>
            <mat-spinner diameter="24" *ngIf="waiting"></mat-spinner>
        </mat-card-actions>
    </mat-card>
</form>
