import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { DossierADELA } from 'src/app/interfaces/adela/dossier-adela';
import { CommandeFacturee } from 'src/app/interfaces/commande/commande';
import { ApiAdelaService } from 'src/app/services/api-adela.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { ExportTableComponent } from '../../../templates/export-table/export-table.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatTableResponsiveLargeDirective } from '../../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';
import { NgIf, NgStyle, DatePipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-facturation-liste-adela',
    templateUrl: './facturation-liste-adela.component.html',
    styleUrls: ['./facturation-liste-adela.component.scss'],
    standalone: true,
    imports: [MatCardModule, NgIf, MatTableModule, MatTableResponsiveLargeDirective, MatTableExporterModule, MatButtonModule, NgStyle, MatProgressSpinnerModule, ExportTableComponent, MatPaginatorModule, DatePipe]
})
export class FacturationListeAdelaComponent implements OnInit {

  @Input() dossiers! : any[]

  dataSourceMatTable  : MatTableDataSource<DossierADELA> = new MatTableDataSource<DossierADELA>();
  displayedColumns = ['typeAnalyse','immatriculation', 'reference', 'etiquette', 'cabinet', 'date', 'etat', 'actions'];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;
  
  constructor(public apiAdela : ApiAdelaService
              ,private toast         : PersoSnackbarService) { }

  ngOnInit(): void {
  }
  
  factureDossier(dossierAFacturer:any){
        
    const dossierTrouve = this.dossiers.find((dossier) => dossier.st_Dossier.idDossier === dossierAFacturer.st_Dossier.idDossier)
      if (dossierTrouve){
        dossierTrouve.estTraitement = true
        this.dossiers = [...this.dossiers,dossierTrouve]
      }
    
    const dossiers:any[]=[dossierAFacturer]
    this.apiAdela.facturerDossiers(dossiers).subscribe(
      (dossiersFactures:CommandeFacturee[]) => {
        
        this.tagCommandesFacturees(dossiersFactures)
      }
      ,(error)=>console.log(error)
      
    )
  }

  tagCommandesFacturees(dossiersFactures:CommandeFacturee[]){
    let isErrorFacturation:boolean  = false;
    let errorFacturation:string     = ''

    dossiersFactures.forEach((dossiersFactures)=> {
      const dossierTrouve = this.dossiers.find((dossier)=>dossier.st_Dossier.idDossier === dossiersFactures.id)
      if (dossierTrouve){
        dossierTrouve.estFacturee = true
        this.dossiers = [...this.dossiers,dossierTrouve]
        
      }else{
        isErrorFacturation  = true
        errorFacturation    = dossierTrouve.resultat 
      }
    }
    
   )
   
   if (isErrorFacturation === false) {
     if (dossiersFactures.length === 1) {
       this.toast.showInfo('Le dossier a bien été facturé')
     }else{
       this.toast.showError('Les dossiers ont bien été facturés')
     }
    }else{
    this.toast.showError(errorFacturation) 
  }
  }

  ngOnChanges(){
    this.dataSourceMatTable.data = this.dossiers    
    this.dataSourceMatTable.paginator = this.paginator
  }

}
