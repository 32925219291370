<app-recherche-status></app-recherche-status>
<app-recherche-filtre (selectEntite)="onChildSelectEntite($event)" (research)="onChildResearchDossierReparateur($event)"></app-recherche-filtre>
<app-recherche-resultat [dataSource_creativ_expertiz]="this.dossier_reparateur.dossier_creativ_expertiz" 
                        [dataSource_alliance_experts]="this.dossier_reparateur.dossier_alliance_experts" 
                        [dataSource_experts_groupe]="this.dossier_reparateur.dossier_experts_groupe"
                        [expandPannel_creativ_expertiz]="expandPannel_creativ_expertiz"
                        [expandPannel_alliance_experts]="expandPannel_alliance_experts"
                        [expandPannel_experts_groupe]="expandPannel_experts_groupe"
                        [code_reparateur_creativ_expertiz]="code_reparateur_creativ_expertiz"
                        [code_reparateur_alliance_experts]="code_reparateur_alliance_experts"
                        [code_reparateur_experts_groupe]="code_reparateur_experts_groupe">
</app-recherche-resultat>