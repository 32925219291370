<app-filtre-liste-fournisseurs (newItemEvent)="addItem($event)"></app-filtre-liste-fournisseurs>
<mat-card appearance="outlined" class="mat-card-no-shadow" style="padding: 0px;">
    <mat-expansion-panel [expanded]="true" style="background-color: #fff">
        
        <mat-expansion-panel-header style="background-color: #fff">
            <mat-panel-title>
                <mat-icon color="primary">file</mat-icon>
                <span style="width: 220px;" *ngIf="ds_Fournisseurs != undefined"><span class="span-primary">{{ ds_Fournisseurs.length }}</span>FOURNISSEUR<span *ngIf="ds_Fournisseurs.length > 1">S</span></span>
                <span style="width: 220px;" *ngIf="ds_Fournisseurs == undefined"><span class="span-primary">0</span>FOURNISSEUR</span>        
            </mat-panel-title>
        </mat-expansion-panel-header>
        <app-table-liste-fournisseurs [dataSource]="ds_Fournisseurs"></app-table-liste-fournisseurs>
    </mat-expansion-panel>
</mat-card>