import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Reglement } from 'src/app/interfaces/facture/reglement';
import { DialogBordereauComponent } from '../dialog-bordereau/dialog-bordereau.component';
import { MesRemisesComponent } from '../mes-remises/mes-remises.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { NgIf, DatePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
    selector: 'app-table-remise',
    templateUrl: './table-remise.component.html',
    styleUrls: ['./table-remise.component.scss'],
    standalone: true,
    imports: [MatExpansionModule, MatIconModule, NgIf, MatCardModule, MatButtonModule, MatProgressSpinnerModule, MatTableModule, MatCheckboxModule, DatePipe]
})
export class TableRemiseComponent implements OnInit {

  // Données à afficher
  @Input() ELEMENT_DATA: Array<Reglement> = [];
  @Input() mode_paiement: string = '';
    
  // Affichage des données dans la table + gestion du sélecteur
  selection   = new SelectionModel<Reglement>(true, []);
  dataSource  = new MatTableDataSource<Reglement>();

  // Gestion de l'affichage (bouton Remise grisé après activation)
  waitingRemise: boolean = false;

  // Filtre des reglements sélectionnés
  reglementSelectedFilter: Array<number> = [];
  
  // Tableau des suivis
  displayedColumns: string[] = ['select', 'date_reglement', 'date_creation', 'libelle', 'commentaire', 'facture', 'raison_sociale', 'compte_tier', 'credit'];
  
  constructor(public dialog: MatDialog
            , private compMesRemise: MesRemisesComponent) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if(this.ELEMENT_DATA != undefined) {
      this.dataSource.data = this.ELEMENT_DATA;    
      this.reglementSelectedFilter = [];
      this.selection.clear();
    }    
  }

  public onRemise() {
    this.waitingRemise = true;
    this.reglementSelectedFilter = [];

    // Détermine quelles lignes sont sélectionnées, et les ajoute dans le filtre de remise sélectionnées
    for(let i = 0; i < this.dataSource.data.length; i++) {
      if(this.selection.isSelected(this.dataSource.data[i])) {
        this.reglementSelectedFilter.push(this.dataSource.data[i].id);
      }
    }
    
    const dialogCofig = new MatDialogConfig();

    dialogCofig.data = {
      reglementSelectedFilter: this.reglementSelectedFilter,
      mode_paiement: this.mode_paiement
    };
    
    const dialogRef = this.dialog.open(DialogBordereauComponent, dialogCofig);
    
    dialogRef.afterClosed().subscribe(result => {
      if (result == true){        
        this.compMesRemise.onRechercheReglement();
      }
      this.waitingRemise = false;
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;        
  }
  
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {      
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);  
  }
  
  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Reglement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
}
