import { AsyncPipe, DatePipe, DecimalPipe, NgFor, NgIf, NgStyle } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, NgModel, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DossierADELA } from 'src/app/interfaces/adela/dossier-adela';
import { Adresse } from 'src/app/interfaces/adresse/adresse';
import { Commande } from 'src/app/interfaces/commande/commande';
import { Service } from 'src/app/interfaces/dossier/service';
import { EtatFacture } from 'src/app/interfaces/facture/etat-facture';
import { Facture } from 'src/app/interfaces/facture/facture';
import { LigneFacture } from 'src/app/interfaces/facture/ligne-facture';
import { Reglement } from 'src/app/interfaces/facture/reglement';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { ApiFactureService } from 'src/app/services/api-facture.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { MatTableResponsiveLargeDirective } from '../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';
import { PhoneFormatPipe } from '../../../pipe/phone-format.pipe';
import { CarteAdresseComponent } from '../../templates/cards/carte-adresse/carte-adresse.component';
import { CarteEntiteComponent } from '../../templates/cards/carte-entite/carte-entite.component';
import { DialogRefacturationComponent } from '../dialog-refacturation/dialog-refacturation.component';
import { ReglementComponent } from '../reglement/reglement.component';
import { ModePaiement } from 'src/app/interfaces/facture/mode-paiement';
import { MatSelectModule } from '@angular/material/select';
import { forkJoin } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Component({
    selector: 'app-recap-facture',
    templateUrl: './recap-facture.component.html',
    styleUrls: ['./recap-facture.component.scss'],
    standalone: true,
    imports: [MatCardModule, NgIf, MatToolbarModule, MatButtonModule, MatIconModule, MatMenuModule, NgStyle, NgFor
              , MatDividerModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatTableModule
              , MatTableResponsiveLargeDirective, MatExpansionModule, DecimalPipe, DatePipe, PhoneFormatPipe
              ,CarteEntiteComponent,CarteAdresseComponent,MatSelectModule,FormsModule,AsyncPipe]
})
export class RecapFactureComponent implements OnInit {


  // variable d'environnement
  isLocation                : boolean = false;      // Est-ce une location d'outil diag ?.
  canIDoaCreditNote         : boolean = false;
  canIAddPaiement           : boolean = false;
  estAnnulee                : boolean = false;
  avecReglement             : boolean = false;
  displayedColumns          : string[] = ['id','article','quantite','prix_unitaire_ht','total_ht','par_Mois', 'pourcentage_remise', 'total_remise_ht','tauxTVA'];
  dateDuJour                : number = 0;

  // Variable 
  idFacture                 : number = 0;
  modeAdmin                 : number = 0;
  facture$                  : Facture = <Facture>{};
  facturePrecedente$        : Facture = <Facture>{};
  facturesSuivantes$        : Facture[] = <Facture[]>[];
  reglement$                : Array<Reglement> = [];
  nbRemiseEnBanque          : number = 0;
  entite$                   : Entite = <Entite>{};
  utilisateur$              : ProfilUtilisateur = <ProfilUtilisateur>{};
  etat$                     : EtatFacture = <EtatFacture>{};
  etatOrigine$              : EtatFacture = <EtatFacture>{};
  etatSuivant$              : EtatFacture = <EtatFacture>{};
  service$                  : Service = <Service>{};
  command$                  : Commande = <Commande>{};
  adela$                    : DossierADELA = <DossierADELA>{};
  dataSource$               : Array<LigneFacture> = [];
  profilUtilisateur$        : ProfilUtilisateur = <ProfilUtilisateur>{};
  adresseEntite!            : Adresse
  //
  formCompteTiers           : any = {};
  totaux                    : any = []
  // modesPaiement             : ModePaiement[]=[]
  modesPaiement            :  ModePaiement[]=[]
  idSelectedModePaiement    : number = 0
  idMemorySelectedModePaiement : number=0
  sommeDue                  : number = 0;

  formModeSelected = new FormGroup({
    idSelectedMode : new FormControl<number>(0)
  })
  constructor(
    public apiFacture: ApiFactureService,
    private apiEntite: ApiEntiteService,
    private apiAuth: ApiAuthentificationService,
    private _fb: FormBuilder
    , private routed        : ActivatedRoute
    , private route         : Router
    ,private toast: PersoSnackbarService,
    public dialog: MatDialog,
    private title: Title,
  ) { 
    
   }
   ngOnInit(): void {
    this.profilUtilisateur$ = this.apiAuth.userConnect;

    this.formCompteTiers = this._fb.group({
      numero: ['', Validators.required]
    })
   
    this.dateDuJour = Date.now();
    this.initData();
  }

 
  private initData() {
    const factureId = this.routed.snapshot.params['id'];

    forkJoin({
      facture: this.apiFacture.getFacture(factureId),
      modesPaiement: this.apiFacture.getModePaiement(),
      reglements : this.apiFacture.getReglement(factureId)
    }).pipe(
      tap(({ facture, modesPaiement, reglements }) => {
        
        this.facture$               = facture;
        this.idFacture              = facture.id
        this.modesPaiement          = modesPaiement;
        this.reglement$             = reglements;
        this.formModeSelected.patchValue({ idSelectedMode: +facture.idModePaiement });
        
        // Mise à jour des autres propriétés et composants
        this.updateComponentProperties(facture);
        this.calculerSommeDue();
      })
    ).subscribe();
  }

  private updateComponentProperties(facture: Facture) {
    // Mise à jour des propriétés basées sur la facture
    this.entite$ = facture.Entite;
    this.adresseEntite = {
      'cp': facture.Entite.code_postal,
      'ligne1': facture.Entite.adresse,
      'ligne2': facture.Entite.adresse_cplt,
      'ligne3': facture.Entite.adresse_ligne3,
      'ville': facture.Entite.ville,
      'nom': facture.Entite.raison_sociale,
      'contact': facture.Entite.numero_client
    };
    this.utilisateur$ = facture.Utilisateur;
    this.etat$ = facture.Etat;
    this.service$ = facture.Service;
    this.dataSource$ = facture.LignesFacture;
    this.totaux = this.calculateTotals(facture);

    this.title.setTitle("Facture n° " + facture.numero);

    this.checkActiveMenu();
  }

  public loadFacture(idFacture: number) {
    this.apiFacture.getFacture(idFacture).pipe(
      tap(facture => {
        this.updateComponentProperties(facture);
        this.formModeSelected.patchValue({ idSelectedMode: +facture.idModePaiement });
      })
    ).subscribe();

    this.apiFacture.getReglement(idFacture).subscribe(reglements => {
      this.reglement$ = reglements;
      this.calculerSommeDue();
    })
  }

  private calculateTotals(facture: Facture) {
    return [
      { libelle: 'Montant HT', montant: facture.totalHT },
      { libelle: 'Montant Remise HT', montant: facture.totalRemiseHT },
      { libelle: 'Frais de port', montant: facture.portHT },
      { libelle: 'Montant TVA (' + facture.tauxTVA + ' %)', montant: facture.totalTVA },
      { libelle: 'Montant TTC', montant: facture.totalTTC }
    ];
  }


  enregistrerModePaiementDefaut() {
    const idSelectedMode = this.formModeSelected.get('idSelectedMode')!.value;
    this.apiFacture.putModePaiement(this.idFacture, idSelectedMode!).subscribe(
      () => {
        this.toast.showInfo('Le mode de paiement a bien été mis à jour');
        this.facture$.idModePaiement = this.idSelectedModePaiement;
      },
      (error) => {
        this.toast.showError('Une erreur a empêché la mise à jour du mode de paiement');
        this.formModeSelected.patchValue({ idSelectedMode: +this.facture$.idModePaiement });
      }
    );
  }

  addPaiement() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      idFacture: this.idFacture,
      mode: (this.facture$.type_document === this.apiFacture.CONST_TYPE_AVOIR ? 'remboursement' : 'paiement'),
      titre: 'Ajouter un règlement',
      facture: this.facture$,
      reglements: this.reglement$
    };

    const dialogRef = this.dialog.open(ReglementComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.loadFacture(this.idFacture);
      }        
    });
  }

  public checkActiveMenu() {
    // Est-ce que je peut saisie un paiement ou un remboursement
    this.canIAddPaiement = true //( this.facture$.Etat.code != this.apiFacture.CONST_ETAT_FACTURE_4_ANNULE && this.facture$.Etat.code != this.apiFacture.CONST_ETAT_FACTURE_5_ARCHIVE && this.facture$.Etat.code != this.apiFacture.CONST_ETAT_FACTURE_6_AVOIR )
    // Est-ce que je peut faire un avoir ?
    this.canIDoaCreditNote = ( this.facture$.type_document === this.apiFacture.CONST_TYPE_FACTURE && ( this.facture$.Etat.code != this.apiFacture.CONST_ETAT_FACTURE_4_ANNULE && this.facture$.Etat.code != this.apiFacture.CONST_ETAT_FACTURE_5_ARCHIVE && this.facture$.Etat.code != this.apiFacture.CONST_ETAT_FACTURE_6_AVOIR ))
    // Est-ce que je peux refacturer ?
    this.estAnnulee = ( this.etat$.code == this.apiFacture.CONST_ETAT_FACTURE_4_ANNULE )
    // this.canIDoAnotherBill = ( this.facture$.type_document === this.apiFacture.CONST_TYPE_FACTURE && ( this.facture$.Etat.code != this.apiFacture.CONST_ETAT_FACTURE_4_ANNULE && this.facture$.Etat.code != this.apiFacture.CONST_ETAT_FACTURE_5_ARCHIVE && this.facture$.Etat.code != this.apiFacture.CONST_ETAT_FACTURE_6_AVOIR ))
  }

  // Création d'un avoir et d'un remboursement (le cas échéant)
  addCreditNote() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      idFacture: this.idFacture,
      titre: 'Ajouter un avoir',
      facture: this.facture$
    };

    const dialogRef = this.dialog.open(DialogRefacturationComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.loadFacture(this.idFacture);
      }        
    });
  }

  // Refacturation depuis une facture existante
  public copyInvoice() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      idFacture: this.idFacture,
      idUtilisateur: this.facture$.idUtilisateur,
      idEntite: this.facture$.idEntite
    };

    const dialogRef = this.dialog.open(DialogRefacturationComponent, dialogConfig);
  }

  //
  public getBackgroundColorByStatus(status: string) {
    return this.apiFacture.getBackgroundColorByStatus(status)
  }

  public pagePrecendente() {
    
    if (this.modeAdmin == 0) {
      this.route.navigate(['/app/facture/mes-factures']);
    }else{
      
      window.top!.close();
    }
  }

  public setCompteTiers(id: number ) {
    this.apiEntite.patchCompteTiers( id, this.formCompteTiers.value.numero )
    .subscribe(
      data => {
        this.toast.showInfo(data.message);
      },
      err => {
        this.toast.showError(err.message)
        console.log(err);
      },
      () => {}
    )
  }

  public calculerSommeDue() {
    let sommePaiements : number = 0;
    this.sommeDue = this.facture$.totalTTC;

    if (this.reglement$ != undefined) {
      this.reglement$.forEach(element => {sommePaiements += element.montant;});

      this.sommeDue = Math.round( (this.facture$.totalTTC - sommePaiements) * 100 ) / 100;
      if (this.sommeDue < 0) {
        this.sommeDue = 0;
      }
    } 
  }

}