<mat-card appearance="outlined">
    <mat-card-title>{{titre}}</mat-card-title>
    <mat-card-content [formGroup]="CategorieFormGroup">
        <div class="div-container-between">
            <mat-form-field>
                <mat-label>Nom</mat-label>
                <input  type = "text" 
                        matInput 
                        formControlName = "nom"
                        appAutofocus>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Code</mat-label>
                <input  type = "text" 
                        matInput 
                        style="text-transform: uppercase"
                        formControlName = "code">
            </mat-form-field>
        </div>
        <mat-card-actions>
            <div class="div-container-between" style="padding: 8px;">
                <div class="div-container-left" style="width:100%; display: flex; justify-content: center;">
                    <button mat-flat-button color="primary" (click)="onValide()" [disabled]="!CategorieFormGroup.valid" >Valider</button>
                    <button mat-flat-button color="primary" (click)="onAnnule()">Annuler</button>
                </div>
            </div>
        </mat-card-actions>
    </mat-card-content>
</mat-card>
