<div class="div-container-left" *ngIf="uploadParameter.activated">
    <!-- single accept=".jpg,.png,.pdf" -->
    <input #UploadFiles
    
    type          = "file" 
    class         = "file-input" 
    (change)      = "uploadFiles($event)" 
    [accept]      = "uploadParameter.accept"
    [multiple]    = "uploadParameter.multiple"
    >
    <div class="file-upload">
          <span style="color: #646464;">
                
          </span>
    </div>
    <button mat-stroked-button color="primary" 
            class="upload-btn" 
            (click)="UploadFiles.click()">
          <mat-icon>attach_file</mat-icon>{{uploadParameter.buttonText}}
    </button>
</div>

<!-- Liste des fichiers à uploader -->
<div *ngFor=" let file of files; let indice = index;"
class="div-container-left">

    <p style="vertical-align: middle; height: 28px;">
          <button mat-icon-button color="warn" (click)="deleteFile(indice)" ><mat-icon style="font-size: 20px;">delete</mat-icon></button>
          <span [ngStyle]="{  color : file.file?.name != '' ? '#273a82' : '#FF4081' }" style="height: 28px;">{{ file.file?.name }}</span>
    </p>
    
</div>
