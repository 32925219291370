<mat-card appearance="outlined" class="mat-card-no-shadow"  style="padding: 0px;">
    <form [formGroup]="FiltreFormGroup">
        <mat-expansion-panel id="expandFilter" [(expanded)]="expandPannel" #expandFilter> 

            <mat-expansion-panel-header style="background-color: #fff; padding-top: 3px; padding-bottom: 3px;">
                <mat-panel-title class="label-accent">
                    <mat-icon>search</mat-icon>Veuillez sélectionner un environnement et les éventuelles options de votre recherche...
                </mat-panel-title>
            </mat-expansion-panel-header>                                

            <mat-form-field>
                <mat-label>Email destinataire</mat-label>
                <input matInput type="text" id="email_destinataire" formControlName="email_destinataire">
                <mat-error *ngIf="email_destinataire?.invalid && (email_destinataire?.dirty || email_destinataire?.touched)">{{ email_destinataire?.errors?.erreur }}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Type demande</mat-label>
                <mat-select name="type_demande" id="type_demande" formControlName="type_demande" multiple required>
                    <mat-option *ngFor="let type_demande of type_demandes" [value]="type_demande">
                        {{ type_demande.libelle }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="!FiltreFormGroup?.valid && FiltreFormGroup.touched">Vous devez sélectionner au moins une type demande pour votre recherche</mat-error>
            </mat-form-field>                        

            <mat-action-row>    
                <div class="div-container-between" style="padding: 8px;">
                    <div class="div-container-center" style="width:100%; display: flex; justify-content: center;">
                        <button 
                            mat-flat-button 
                            submit 
                            *ngIf       = "!waitingResearch" 
                            color       = "primary" 
                            [disabled]  = "waitingResearch || !FiltreFormGroup.valid" 
                            (click)     = "onSearchTypeDemande()">
                            <mat-icon>search</mat-icon>Rechercher                                    
                        </button>
                        <mat-spinner diameter="24" *ngIf="waitingResearch"></mat-spinner>
                    </div>
                </div>
            </mat-action-row>
        </mat-expansion-panel>                
    </form>
</mat-card>