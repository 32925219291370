<div>
    <h1 mat-dialog-title>{{sTitre}}</h1>
    <h4>{{sSousTitre}}</h4>

    <div class="container" mat-dialog-content>        
        <table mat-table matTableResponsiveLarge [dataSource]="dataSourceMatTable" class="table-primary">
            
            <!-- Habilitation --> 
            <ng-container matColumnDef="ent_habilitation">
                <th mat-header-cell *matHeaderCellDef>Habilitation</th>
                <td mat-cell *matCellDef="let Entite" > {{ Entite.habilitation }} </td> 
            </ng-container>
            
            <!-- Numéro de contact -->
            <ng-container matColumnDef="ent_numero_client">
                <th mat-header-cell *matHeaderCellDef style="padding: 0px 5px 0px 5px;">Contact</th>
                <td mat-cell *matCellDef="let Entite" style="padding: 0px 5px 0px 5px;"> {{ Entite.numero_client }} </td>
            </ng-container>
    
            <!-- raison sociale -->
            <ng-container matColumnDef="ent_raison_sociale">
                <th mat-header-cell *matHeaderCellDef style="padding: 0px 5px 0px 5px;">Raison sociale</th>
                <td mat-cell *matCellDef="let Entite" style="padding: 0px 5px 0px 5px;"> {{ Entite.raison_sociale }} </td>
            </ng-container>
            
            <!-- Statut -->
            <ng-container matColumnDef="ent_active">
                <th mat-header-cell *matHeaderCellDef style="padding: 0px 5px 0px 5px;">État</th>
                <td mat-cell *matCellDef="let Entite" style="padding: 0px 5px 0px 5px;"> 
                    <mat-icon [ngStyle]="{ color: (Entite.EstActif ? '#04970E' : '#FF4081')}">{{ Entite.EstActif ? 'check_small' : 'cancel' }}</mat-icon> 
                </td>
            </ng-container>
            
            <!-- CP -->
            <ng-container matColumnDef="ent_code_postal">
                <th mat-header-cell *matHeaderCellDef style="padding: 0px 5px 0px 5px;">CP</th>
                <td mat-cell *matCellDef="let Entite" style="padding: 0px 5px 0px 5px;"> {{ Entite.code_postal }} </td>
            </ng-container>

            <!-- Ville -->
            <ng-container matColumnDef="ent_ville">
                <th mat-header-cell *matHeaderCellDef style="padding: 0px 5px 0px 5px;">Ville</th>
                <td mat-cell *matCellDef="let Entite" style="padding: 0px 5px 0px 5px;"> {{ Entite.ville }} </td>
            </ng-container>

            <!-- SIREN -->
            <ng-container matColumnDef="ent_siren">
                <th mat-header-cell *matHeaderCellDef style="padding: 0px 5px 0px 5px;">SIREN</th>
                <td mat-cell *matCellDef="let Entite" style="padding: 0px 5px 0px 5px;"> {{ Entite.siren }} </td>
            </ng-container>
            
            <!-- Résultat de retour -->
            <ng-container matColumnDef="resultat">
                <th mat-header-cell *matHeaderCellDef> Résultat </th>
                <td mat-cell *matCellDef="let Entite" > {{ Entite.resultat }} </td> 
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5,10,25]"
            showFirstLastButtons 
            aria-label="Nombre de lignes visibles...">
        </mat-paginator>
    </div>

    <br>   
    <div class="div-button-center" mat-dialog-actions>
        <button class="button-width" 
                *ngIf                       = "typeUpdatePage != undefined && typeUpdatePage == CONST_CODE_TYPE_ACTION_ACTIVATION && !waitingApi" 
                mat-raised-button color     = "primary" 
                (click)                     = "onClickActivation()">
            <mat-icon>check</mat-icon>
            <span class="span-button">Activer</span>
        </button>
        <button class="button-width" 
                *ngIf                       = "typeUpdatePage != undefined && typeUpdatePage == CONST_CODE_TYPE_ACTION_DESACTIVATION && !waitingApi" 
                mat-raised-button color     = "primary" 
                (click)                     = "onClickDesactivation()">
            <mat-icon>close</mat-icon>
            <span class="span-button">Désactiver</span>
        </button>
        <button class="button-width" 
                *ngIf                       = "typeUpdatePage != undefined && typeUpdatePage == CONST_CODE_TYPE_ACTION_IDENTIFIANTS && !waitingApi" 
                mat-raised-button color     = "primary" 
                (click)                     = "onClickResetPassword()">
            <mat-icon>password</mat-icon>
            <span class="span-button">Réinitialiser le mot de passe concentrateur</span>
        </button>
        <button mat-button color="warn" [mat-dialog-close]="action_confirmee" class="button-width">Fermer</button>
        <mat-spinner diameter="24" *ngIf="waitingApi"></mat-spinner>
    </div> 
</div>