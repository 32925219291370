
<mat-card appearance="outlined" style="background-color: #fff;"  class="mat-card-no-shadow" style="padding: 0px;">
    <mat-expansion-panel [(expanded)]="expandPannel" style="background-color: #fff">
        <mat-expansion-panel-header style="background-color: #fff">
            <mat-panel-title>
                <mat-icon color="primary">file</mat-icon>
                <span style="width: 220px;" *ngIf="dataSource_type_demande!= undefined"><span class="span-primary">{{ dataSource_type_demande.length }}</span>TYPE<span *ngIf="dataSource_type_demande.length > 1">S</span> DEMANDE</span>
                <span style="width: 220px;" *ngIf="dataSource_type_demande == undefined"><span class="span-primary">0</span>TYPE DEMANDE</span>        
            </mat-panel-title>
            <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>


            <div class="grid-container">
                <button mat-raised-button (click)="onCreer()" color="primary" type="submit" class="grid-item">
                    <mat-icon>add</mat-icon>
                    <span class="span-button">Nouveau</span>
                </button>

                <button mat-raised-button (click)="onSupprimer()" color="warn" type="submit" class="grid-item">
                    <mat-icon>delete</mat-icon>
                    <span class="span-button">Supprimer</span>
                </button>    

                <button mat-raised-button (click)="onActiver()" style="background-color: #04970E; color: #FFFFFF;" type="submit" class="grid-item">
                    <mat-icon>check</mat-icon>
                    <span class="span-button">Activer</span>
                </button>
    
                <button mat-raised-button (click)="onDesactiver()" color="warn" type="submit" class="grid-item">
                    <mat-icon>close</mat-icon>
                    <span class="span-button">Désactiver</span>
                </button>                                        
            </div>            

            <div class="container">                
                <table mat-table matTableResponsiveLarge [dataSource]="dataSourceMatTable" class="table-primary">

                    <!-- Checkbox Column -->
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef class="premiere-colonne" >
                            <mat-checkbox type="checkbox" (change)="$event ? masterToggle() : null" class="checkbox"
                                            [checked]="selection.hasValue() && isAllSelected()"
                                            [indeterminate]="selection.hasValue() && !isAllSelected()"
                                            [aria-label]="checkboxLabel()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? selection.toggle(row) : null"
                                        [checked]="selection.isSelected(row)"
                                        [aria-label]="checkboxLabel(row)"
                                        class="checkbox"></mat-checkbox>
                        </td>
                    </ng-container>

                    <!-- Position Column -->
                    <ng-container matColumnDef="position" >
                        <th mat-header-cell *matHeaderCellDef> No. </th>
                        <td mat-cell *matCellDef="let type_demande"> {{type_demande.position}} </td>
                    </ng-container>

                    <!-- Type demande --> 
                    <ng-container matColumnDef="typ_libelle">
                        <th mat-header-cell *matHeaderCellDef class="table-padding"> Type demande </th>
                        <td mat-cell *matCellDef="let type_demande" class="table-padding"> {{ type_demande.libelle }} </td> 
                    </ng-container>
                    
                    <!-- Emails destinataire --> 
                    <ng-container matColumnDef="typ_email_destinataire">
                        <th mat-header-cell *matHeaderCellDef class="table-padding"> Emails destinataire </th>
                        <td mat-cell *matCellDef="let type_demande" class="table-padding"> {{ type_demande.email_destinataire }} </td> 
                    </ng-container>
                    
                    <!-- Environnement : Extranet FFEA --> 
                    <ng-container matColumnDef="typ_environnement_extranet_ffea">
                        <th mat-header-cell *matHeaderCellDef class="table-padding"> Extranet FFEA </th>
                        <td mat-cell *matCellDef="let type_demande" class="table-padding"> 
                            <mat-icon [ngStyle]="{ color: (type_demande.extranet_ffea ? '#04970E' : '#FF4081')}">{{ type_demande.extranet_ffea ? 'check_small' : 'cancel' }}</mat-icon> </td> 
                    </ng-container>

                    <!-- Environnement : Services FFEA --> 
                    <ng-container matColumnDef="typ_environnement_services_ffea">
                        <th mat-header-cell *matHeaderCellDef class="table-padding"> Services FFEA </th>
                        <td mat-cell *matCellDef="let type_demande" class="table-padding"> 
                            <mat-icon [ngStyle]="{ color: (type_demande.services_ffea ? '#04970E' : '#FF4081')}">{{ type_demande.services_ffea ? 'check_small' : 'cancel' }}</mat-icon> </td> 
                    </ng-container>   
                    
                    <!-- Status --> 
                    <ng-container matColumnDef="typ_actif">
                        <th mat-header-cell *matHeaderCellDef class="table-padding"> État </th>
                        <td mat-cell *matCellDef="let type_demande" class="table-padding"> 
                            <mat-icon [ngStyle]="{ color: (type_demande.actif ? '#04970E' : '#FF4081')}">{{ type_demande.actif ? 'check_small' : 'cancel' }}</mat-icon> </td> 
                    </ng-container>   

                    <!-- Actions -->
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef class="derniere-colonne" style="text-align: right;"></th>
                        <td mat-cell *matCellDef="let type_demande" class="colonne-module" style="text-align: right;">
                            <button mat-icon-button class="button-etat" color="primary"
                                style="width: 48px; height: 48px;"
                                (click)="onClickOpenTypeDemande(type_demande.id)">
                                <mat-icon color="primary">edit</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[5,10,25]"
                                [pageSize]="25"
                    showFirstLastButtons 
                    aria-label="Nombre de lignes visibles...">
                 </mat-paginator>
            </div>
        </mat-expansion-panel>
    </mat-card>
