<form [formGroup]="suspensionForm" *ngIf="suspension != undefined">
    <mat-card appearance="outlined"><mat-card-title>{{title}}</mat-card-title></mat-card>

    <mat-card appearance="outlined">
        <mat-card-title>{{suspension.entite? suspension.entite.raison_sociale : ''}}</mat-card-title>
        <mat-card-content  class=".mat-card-content">
            <div class = "subTitle">N° Contact : {{suspension.entite? suspension.entite.numero_client : ''}}</div>
            <div class = "subTitle">{{suspension.entite? suspension.entite.adresse : ''}}</div>
            <div class = "subTitle">{{suspension.entite? suspension.entite.code_postal : ''}} {{suspension.entite? suspension.entite.ville : ''}}</div>
            <div class = "subTitle">{{suspension.entite? suspension.entite.mail_contact : ''}}</div>
        </mat-card-content>
        
    </mat-card>

    <mat-card appearance="outlined">
        <div class="div-container-between">
            <div class = "subTitle" *ngIf="suspension.statut">{{suspension.statut? suspension.statut!.nom : ''}} le {{suspension.date |date :'dd/MM/yyyy HH:mm:ss'}}</div>
            <div>
                <mat-form-field *ngIf="typesSuspension$|async as typesSuspension">
                    <mat-label>Type de suspension</mat-label>
                    <mat-select  formControlName="idType"
                                [disabled]="disabledTypeSuspension"
                    >
                        <mat-option *ngFor="let type of typesSuspension" [value]="type.id">{{type.nom}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </mat-card>

    <mat-card appearance="outlined">
        <mat-card-content>
            <mat-form-field style="max-width: 100%;min-height: 140px;" class="textarea">
                <mat-label>Notes</mat-label>
                <textarea 
                        class="mdc-text-field__input"
                        matInput 
                        placeholder = ""
                        rows        = "5"
                        formControlName="note"
                        ></textarea>
            </mat-form-field>
        </mat-card-content>
        
    </mat-card>

    <mat-card appearance="outlined">
        <mat-card-title>Pièces jointes</mat-card-title>
        <app-upload-files (uploadedFiles)="getFiles($event)" *ngIf="!isWaiting"></app-upload-files>
        <app-piece-jointe *ngIf="suspension.pjs.length>0" [pjs]="suspension.pjs" (pjsToDelete)="getPjs($event)"></app-piece-jointe>
    </mat-card>

    <mat-card appearance="outlined">
        <mat-card-title>Actions</mat-card-title>
        <mat-card-content  class=".mat-card-content">
            <div *ngIf="suspension!.actions!.length > 0;then mails else vide"></div>
            <ng-template #mails>
                <div *ngFor="let action of suspension!.actions">
                    <mat-grid-list cols="12" rowHeight="25px">
                        
                        <mat-grid-tile
                            colspan     = "2"
                            rowspan     = "1"
                            ><div class="action">
                                {{action.nom}}
                            </div>
                        </mat-grid-tile>
                       
                        <mat-grid-tile
                            colspan     = "2"
                            rowspan     = "1"
                            ><div class="date">
                                {{action.date | date : 'dd/MM/yyyy HH:mm:ss'}} 
                            </div>
                            
                        </mat-grid-tile>
                        <mat-grid-tile
                            colspan     = "3"
                            rowspan     = "1"
                            ><div>
                                {{action.nom_utilisateur}}
                            </div>
                        </mat-grid-tile>
                    </mat-grid-list>
                </div>
            </ng-template>
            <ng-template #vide>
                Aucune action n'a été encore prise pour ce cabinet
            </ng-template>
        </mat-card-content>
            
        <mat-card-actions>
            <div class="div-container-between"  *ngIf="!isWaiting">
                <div class="div-container">
        
                    <button mat-fab extended color = "accent"  
                            matTooltip="Envoyer le 1er rappel"
                            [disabled]="disabledRappel1"
                            (click)="envoyerRappel(1)"
                        >
                        <mat-icon><span class="material-symbols-outlined">counter_1</span></mat-icon>
                        <mat-icon><span class="material-symbols-outlined">mail</span></mat-icon>
                
                    </button>
                    <button mat-fab extended color = "accent"  
                            matTooltip="Envoyer le 2ème rappel"
                            [disabled]="disabledRappel2"
                            (click)="envoyerRappel(2)"
                        >
                        <mat-icon><span class="material-symbols-outlined">counter_2</span></mat-icon>
                        <mat-icon><span class="material-symbols-outlined">mail</span></mat-icon>
                
                    </button>
                    <button mat-fab extended color = "accent"  
                            matTooltip="Envoyer le 3ème rappel"
                            [disabled]="disabledRappel3"
                            (click)="envoyerRappel(3)"
                        >
                        <mat-icon><span class="material-symbols-outlined">counter_3</span></mat-icon>
                        <mat-icon><span class="material-symbols-outlined">mail</span></mat-icon>
                
                    </button>
            
                </div>
                <div class="div-container">
                    <button mat-fab extended color = "warn"
                    [disabled]  = "disabledClotureCabinet"
                    (click)     = "cloturerCabinet()"
                    >
                        <mat-icon><span class="material-symbols-outlined">cancel</span></mat-icon>
                        Clôturer le cabinet
                    </button>

                    <button mat-fab extended color = "primary"
                    [disabled]  = "disabledClotureSuspension"
                    (click)     = "cloturerSuspension()"
                    >
                        <mat-icon><span class="material-symbols-outlined">done</span></mat-icon>
                        Clôturer la suspension
                    </button>
                </div>
                <div class="div-container">
                    <button mat-flat-button 
                            color       = "primary" 
                            [disabled]  = "suspensionForm.invalid"
                            (click)     = "saveSuspension()"
                            >Enregistrer
                    </button>
                    <button mat-flat-button 
                            color       = "primary" 
                            (click) ="fermeSuspension()"
                            >Fermer
                    </button>
                </div>
            </div>
        </mat-card-actions>
    </mat-card>
</form>