<!-- EXPERTS GROUPE -->
<mat-accordion>
    <mat-expansion-panel [(expanded)]="expandPannel_experts_groupe" style="background-color: #fff">
        <mat-expansion-panel-header style="background-color: #fff">
            <mat-panel-title>
                <mat-icon color="accent" *ngIf="!code_reparateur_experts_groupe" matTooltip="La recherche est désactivée pour le service externe Experts Groupe, car aucun code réparateur ou identifiant n'est configuré pour ce réparateur">warning</mat-icon>
                <span style="width: 220px;" *ngIf="dataSource_experts_groupe != undefined">
                    <span class="span-primary">{{ total_dossier_experts_groupe }}</span>
                     EXPERTS GROUPE
                </span>        
                <span style="width: 220px;" *ngIf="dataSource_experts_groupe == undefined">
                    <span class="span-primary">0</span>
                    EXPERTS GROUPE
                </span>        
            </mat-panel-title>
            <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>

        <mat-card appearance="outlined" style="background-color: #fff;" class="mat-card-padding">
            <mat-card-subtitle>Dossiers</mat-card-subtitle>
            
            <div *ngIf="dataSource_experts_groupe != undefined">
                <span *ngIf="total_dossier_experts_groupe < 1">Nous n'avons pas trouvé de référence à votre dossier sur le site externe Experts Groupe</span>

                <div class="container">                
                    <table mat-table matTableResponsive [dataSource]="dataSource_experts_groupe" class="table-primary">
    
                        <!-- N° Sinistre Column-->
                        <ng-container matColumnDef="numero_sinistre">
                            <th mat-header-cell *matHeaderCellDef> N° Sinistre </th>
                            <td mat-cell *matCellDef="let dossier">{{ dossier.numero_sinistre }}</td>
                        </ng-container>
    
                        <!-- N° Mission Column-->
                        <ng-container matColumnDef="numero_mission">
                            <th mat-header-cell *matHeaderCellDef> N° Mission </th>
                            <td mat-cell *matCellDef="let dossier">{{ dossier.numero_mission }}</td>
                        </ng-container>
    
                        <!-- Immatriculation Column-->
                        <ng-container matColumnDef="immatriculation">
                            <th mat-header-cell *matHeaderCellDef> Immatriculation </th>
                            <td mat-cell *matCellDef="let dossier">{{ dossier.immatriculation }}</td>
                        </ng-container>
    
                        <!-- URL Column-->
                        <ng-container matColumnDef="url">
                            <th mat-header-cell *matHeaderCellDef> URL </th>
                            <td mat-cell *matCellDef="let dossier">
                                <button mat-button (click)="getUrlServiceExterne(CONST_CODE_SRX_EXPERTS_GROUPE)">
                                    <mat-icon>open_in_new</mat-icon>
                                    <span>Ouvrir le dossier</span>
                                </button>
                            </td>
                        </ng-container>
    
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>                    
                </div>                
            </div>  

            <div [hidden]="dataSource_experts_groupe == undefined">
                <div [hidden]="total_dossier_experts_groupe < 1">
                    <mat-paginator #paginatorExpertsGroupe [pageSizeOptions]="[10,25,50]" showFirstLastButtons></mat-paginator>
                </div>                
            </div>                                           
        </mat-card>
    </mat-expansion-panel>
</mat-accordion>

<!-- CREATIV EXPERTIZ -->
<mat-accordion>
    <mat-expansion-panel [(expanded)]="expandPannel_creativ_expertiz" style="background-color: #fff">
        <mat-expansion-panel-header style="background-color: #fff">
            <mat-panel-title>
                <mat-icon color="accent" *ngIf="!code_reparateur_creativ_expertiz" matTooltip="La recherche est désactivée pour le service externe Creativ Expertiz, car aucun code réparateur ou identifiant n'est configuré pour ce réparateur">warning</mat-icon>
                <span style="width: 220px;" *ngIf="dataSource_creativ_expertiz != undefined">
                    <span class="span-primary">{{ total_dossier_creativ_expertiz }}</span>
                     CREATIV' EXPERTIZ
                </span>        
                <span style="width: 220px;" *ngIf="dataSource_creativ_expertiz == undefined">
                    <span class="span-primary">0</span>
                    CREATIV' EXPERTIZ
                </span>        
            </mat-panel-title>
            <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>

        <mat-card appearance="outlined" style="background-color: #fff;" class="mat-card-padding">
            <mat-card-subtitle>Dossiers</mat-card-subtitle>
            <div *ngIf="dataSource_creativ_expertiz != undefined">
                <span *ngIf="total_dossier_creativ_expertiz < 1">Nous n'avons pas trouvé de référence à votre dossier sur le site externe Creativ' Expertiz</span>
                <div class="container" *ngIf="total_dossier_creativ_expertiz >= 1">                
                    <table mat-table matTableResponsive [dataSource]="dataSourceMatTable_creativ_expertiz" class="table-primary">
    
                        <!-- N° Sinistre Column-->
                        <ng-container matColumnDef="numero_sinistre">
                            <th mat-header-cell *matHeaderCellDef> N° Sinistre </th>
                            <td mat-cell *matCellDef="let dossier">{{ dossier.numero_sinistre }}</td>
                        </ng-container>
    
                        <!-- N° Mission Column-->
                        <ng-container matColumnDef="numero_mission">
                            <th mat-header-cell *matHeaderCellDef> N° Mission </th>
                            <td mat-cell *matCellDef="let dossier">{{ dossier.numero_mission }}</td>
                        </ng-container>
    
                        <!-- Immatriculation Column-->
                        <ng-container matColumnDef="immatriculation">
                            <th mat-header-cell *matHeaderCellDef> Immatriculation </th>
                            <td mat-cell *matCellDef="let dossier">{{ dossier.immatriculation }}</td>
                        </ng-container>
    
                        <!-- URL Column-->
                        <ng-container matColumnDef="url">
                            <th mat-header-cell *matHeaderCellDef> URL </th>
                            <td mat-cell *matCellDef="let dossier">
                                <button mat-button (click)="getUrlServiceExterne(CONST_CODE_SRX_CREATIV_EXPERTIZ)">
                                    <mat-icon>open_in_new</mat-icon>
                                    <span>Ouvrir le dossier</span>
                                </button>
                            </td>
                        </ng-container>
    
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>                
                </div>
            </div>
            
            <div [hidden]="dataSource_creativ_expertiz == undefined">
                <div [hidden]="total_dossier_creativ_expertiz < 1">
                    <mat-paginator #paginatorCreativExpertiz [pageSizeOptions]="[10,25,50]" showFirstLastButtons></mat-paginator>
                </div>                                    
            </div>
            
        </mat-card>
    </mat-expansion-panel>
</mat-accordion>

<!-- ALLIANCE EXPERTS -->
<mat-accordion>
    <mat-expansion-panel [(expanded)]="expandPannel_alliance_experts" style="background-color: #fff">
        <mat-expansion-panel-header style="background-color: #fff">
            <mat-panel-title>
                <mat-icon color="accent" *ngIf="!code_reparateur_alliance_experts" matTooltip="La recherche est désactivée pour le service externe Alliance Experts, car aucun code réparateur ou identifiant n'est configuré pour ce réparateur">warning</mat-icon>
                <span style="width: 220px;" *ngIf="dataSource_alliance_experts != undefined">
                    <span class="span-primary">{{ total_dossier_alliance_experts }}</span>
                     ALLIANCE EXPERTS
                </span>        
                <span style="width: 220px;" *ngIf="dataSource_creativ_expertiz == undefined">
                    <span class="span-primary">0</span>
                    ALLIANCE EXPERTS
                </span>        
            </mat-panel-title>
            <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>

        <mat-card appearance="outlined" style="background-color: #fff;" class="mat-card-padding">
            <mat-card-subtitle>Dossiers</mat-card-subtitle>
            
            <div *ngIf="dataSource_alliance_experts != undefined">
                <span *ngIf="total_dossier_alliance_experts< 1">Nous n'avons pas trouvé de référence à votre dossier sur le site externe Alliance Experts</span>

                <div class="container" *ngIf="total_dossier_alliance_experts >= 1">                
                    <table mat-table matTableResponsive [dataSource]="dataSource_alliance_experts" class="table-primary">
    
                        <!-- N° Sinistre Column-->
                        <ng-container matColumnDef="numero_sinistre">
                            <th mat-header-cell *matHeaderCellDef> N° Sinistre </th>
                            <td mat-cell *matCellDef="let dossier">{{ dossier.numero_sinistre }}</td>
                        </ng-container>
    
                        <!-- N° Mission Column-->
                        <ng-container matColumnDef="numero_mission">
                            <th mat-header-cell *matHeaderCellDef> N° Mission </th>
                            <td mat-cell *matCellDef="let dossier">{{ dossier.numero_mission }}</td>
                        </ng-container>
    
                        <!-- Immatriculation Column-->
                        <ng-container matColumnDef="immatriculation">
                            <th mat-header-cell *matHeaderCellDef> Immatriculation </th>
                            <td mat-cell *matCellDef="let dossier">{{ dossier.immatriculation }}</td>
                        </ng-container>
    
                        <!-- URL Column-->
                        <ng-container matColumnDef="url">
                            <th mat-header-cell *matHeaderCellDef> URL </th>
                            <td mat-cell *matCellDef="let dossier">
                                <button mat-button (click)="getUrlServiceExterne(CONST_CODE_SRX_ALLIANCE_EXPERTS)">
                                    <mat-icon>open_in_new</mat-icon>
                                    <span>Ouvrir le dossier</span>
                                </button>
                            </td>
                        </ng-container>
    
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>                
                </div>
            </div>

            <div [hidden]="dataSource_alliance_experts == undefined">
                <div [hidden]="total_dossier_alliance_experts < 1">
                    <mat-paginator #paginatorAllianceExperts [pageSizeOptions]="[10,25,50]" showFirstLastButtons></mat-paginator>
                </div>                
            </div>
            
        </mat-card>        
    </mat-expansion-panel>
</mat-accordion>