
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, inject } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Commande } from 'src/app/interfaces/commande/commande';
import { ApiCommandeService } from 'src/app/services/api-commande.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { DialogHistoEtatComponent } from '../dialog-histo-etat/dialog-histo-etat.component';
import { RecapCommandeComponent } from '../recap-commande/recap-commande.component';
import { ExportTableComponent } from '../../templates/export-table/export-table.component';
import { MatButtonModule } from '@angular/material/button';
import { NgStyle, NgIf, DatePipe } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatTableResponsiveLargeDirective } from '../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';
import { MatCardModule } from '@angular/material/card';
import { ApiFactureService } from 'src/app/services/api-facture.service';
import * as saveAs from 'file-saver';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { Etat } from 'src/app/interfaces/dossier/etat';
import { OuiNonAnnulerComponent } from '../../templates/oui-non-annuler/oui-non-annuler.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    selector: 'app-table-commande',
    templateUrl: './table-commande.component.html',
    styleUrls: ['./table-commande.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatTableModule, MatTableResponsiveLargeDirective, MatTableExporterModule, MatIconModule, MatTooltipModule,
              NgStyle, MatButtonModule, NgIf, ExportTableComponent, MatPaginatorModule, DatePipe, MatProgressSpinnerModule]
})
export class TableCommandeComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;
  @Input() dataSource: Commande[] = [];
  @Output() refreshCommand = new EventEmitter<boolean>();
  @Input() listeEtat : Etat[] = [];

  haveSomeCommand: boolean = false;
  apiFacture        = inject(ApiFactureService)
  dataSourceMatTable: MatTableDataSource<Commande> = new MatTableDataSource<Commande>();
  displayedColumns: Array<String> = ['idCategorie', 'option', 'numero', 'raison_sociale', 'expert', 'date_creation', 'idEtat','idfacture', 'actions'];
  columnNames = ['idcategorie', 'option', 'numero', 'raison_sociale', 'expert', 'date_creation', 'etat','idfacture', 'actions'];

  constructor(
      public apiCmd    : ApiCommandeService
    , private toast : PersoSnackbarService
    , public dialog   : MatDialog
    , public  apiAuth     : ApiAuthentificationService
  ) {  }

  ngOnInit(): void {
  }
  
  ngAfterViewInit() {
    this.dataSourceMatTable.paginator = this.paginator;
    this.dataSourceMatTable.sort = this.sort;
  }
  
  ngOnChanges() {
    // obligatoire
    if(this.dataSource != undefined) {
      this.dataSourceMatTable.data = this.dataSource;
    }    
  }

  public openCommandDetails(commande : Commande) {
    const dialogRef = this.dialog.open(RecapCommandeComponent, {width: '90%', data: {id: commande.id}});

    dialogRef.afterClosed().subscribe(result => {
      switch (result) {
        case this.apiCmd.CONST_ETAT_VALIDEE:
          this.expedierCommande(commande);
          break;

        case this.apiCmd.CONST_ETAT_REFUSEE :
          this.updateOrderStatus(commande, this.apiCmd.CONST_ETAT_REFUSEE);
          break;

        case this.apiCmd.CONST_ETAT_ANNULEE :
          this.annulerCommande(commande);
          break;
          
        default:
          break;
      }
    });
  }

  private updateOrderStatus(commande : Commande, etatCode : string) {
    commande.estTraitement = true;
    this.apiCmd.updateStatus(commande.id, etatCode)
    .subscribe(
      (data: any) => {
        let etat : Etat | undefined = this.listeEtat.find(element => element.code == etatCode);
        if (etat != undefined) {
          commande.Etat = etat;
          commande.idEtat = etat?.id;
          commande.estTraitement = false;
        }
      },
      (err) => { console.error(err); commande.estTraitement = false; },
      () => {commande.estTraitement = false;}
    )
  }

  expedierCommande(commande : Commande){
    const dialogOuiNon = this.dialog.open(OuiNonAnnulerComponent, { 
      width: '320px', 
      data: { 
        title: "Expédition de la commande",
        message: "Voulez-vous expédier la commande " + commande.numero + " ?",
        yes_no_cancel: 2 
      } 
    });

    dialogOuiNon.afterClosed()
    .subscribe(
      res => {
        switch (res) {
          case true:
            this.updateOrderStatus(commande, this.apiCmd.CONST_ETAT_VALIDEE)
            break;
          default:
            break;
        }
    });
  }

  annulerCommande(commande : Commande){
            
    const dialogOuiNon = this.dialog.open(OuiNonAnnulerComponent, { 
      width: '320px', 
      data: { 
        title: "Annulation de la commande",
        message: "Voulez-vous annuler la commande " + commande.numero + " ?",
        yes_no_cancel: 2 
      } 
    });

    dialogOuiNon.afterClosed()
    .subscribe(
      res => {
        switch (res) {
          case true:
            this.updateOrderStatus(commande, this.apiCmd.CONST_ETAT_ANNULEE)
            break;
          default:
            break;
        }
    });
  }
  
  public onRefreshCommand() {
    this.refreshCommand.emit(true);
  }
 
  public openHistoCommande(idCommande:number,refCommande:string){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    this.apiCmd.getHistoEtatCommande(idCommande).subscribe(
      (data: any) => {    
        dialogConfig.data = {
          histoCommande: data,
          refCommande: refCommande     
        };
      if (!data) {
        this.toast.showInfo("Aucun historique d'état pour cette facture");
        return;
      }
      const dialogRef = this.dialog.open(DialogHistoEtatComponent,dialogConfig)
    },
      (err) => { console.error(err) },
      () => {}
    );
  }


  downloadInvoice(commande:Commande){
    this.apiFacture.getDownloadFacture(commande.idfacture,'').subscribe(
      (data:any)=>{
        
        saveAs(data, 'facture_commande_' + commande.numero + '.pdf');
        this.toast.showInfo("Votre facture est bien téléchargée")
      }
    )
  }
}
