
<mat-card appearance="outlined" style="background-color: #fff;"   class="mat-card-no-shadow" style="padding: 0px;">
    <mat-expansion-panel [(expanded)]="expandPannel" style="background-color: #fff">
        
        <mat-expansion-panel-header style="background-color: #fff">
            <mat-panel-title>
                <mat-icon color="primary">file</mat-icon>
                <span style="width: 220px;" ><span class="span-primary">{{ nbTickets }}</span>TICKET<span *ngIf="nbTickets > 1">S</span></span>
            </mat-panel-title>
        </mat-expansion-panel-header>

            <div class="container">
                <table mat-table matTableResponsiveLarge [dataSource]="dataSourceMatTable" class="table-primary">
                
                   <!-- Numéro de ticket --> 
                    <ng-container matColumnDef="tck_id">
                        <th mat-header-cell *matHeaderCellDef> Numéro </th>
                        <td mat-cell *matCellDef="let ticket" > {{ ticket.id }} </td> 
                    </ng-container>
                    
                    <!-- Etat du ticket --> 
                    <ng-container matColumnDef="tck_etat">
                        <th mat-header-cell *matHeaderCellDef> Etat </th>
                        <!-- <td mat-cell [ngStyle]="{'font-weight': ticket.stEtat.code=='CODE_NOUVEAU'? 'bold':'normal'}" *matCellDef="let ticket" > {{ ticket.stEtat.libelle }} </td>  -->
                        <td mat-cell [ngClass]="this.apiSupportService.getStateClass(ticket.Etat!.code)" *matCellDef="let ticket" matTooltip="{{ticket.Etat.libelle}}"><mat-icon ><span class="material-symbols-outlined">{{this.apiSupportService.getTicketStateIcon(ticket.Etat!.code)}}</span></mat-icon> </td> 
                    </ng-container>

                    <!-- Type du ticket --> 
                    <ng-container matColumnDef="tck_type">
                        <th mat-header-cell *matHeaderCellDef> Type </th>
                        <td mat-cell *matCellDef="let ticket" > {{ ticket.Type.libelle }} </td> 
                    </ng-container>

                    <!-- Sujet du ticket -->
                    <ng-container matColumnDef="tck_titre">
                        <th mat-header-cell *matHeaderCellDef> Sujet </th>
                        <td mat-cell *matCellDef="let ticket" > {{ ticket.description }} </td>
                    </ng-container>

                    <!-- Nom de l'utilisateur -->
                    <ng-container matColumnDef="tck_utl_nom">
                        <th mat-header-cell *matHeaderCellDef> Nom </th>
                        <td mat-cell *matCellDef="let ticket" > {{ ticket.Utilisateur.prenom }} {{ ticket.Utilisateur.nom }} </td>
                    </ng-container>
            
                     <!-- Date du ticket -->
                     <ng-container matColumnDef="tck_date">
                        <th mat-header-cell *matHeaderCellDef> Crée le </th>
                        <td mat-cell *matCellDef="let ticket" > {{ ticket.date | date: 'dd/MM/yyyy HH:mm:ss' }}</td>
                    </ng-container>

                     <!-- Date de première lecture du ticket -->
                     <ng-container matColumnDef="tck_date_premiere_lecture">
                        <th mat-header-cell *matHeaderCellDef> Première lecture </th>
                        <td mat-cell *matCellDef="let ticket" > {{ ticket.date_premiere_lecture!='0000-00-00T00:00:00.000'? (ticket.date_premiere_lecture | date: 'dd/MM/yyyy HH:mm:ss')  : ('')}}</td>
                    </ng-container>

                     <!-- Date de la dernière réponse -->
                     <ng-container matColumnDef="tck_date_reponse">
                        <th mat-header-cell *matHeaderCellDef> Dernière réponse </th>
                        <td mat-cell *matCellDef="let ticket"> {{ ticket.date_dernier_message!='0000-00-00T00:00:00.000'? (ticket.date_dernier_message | date: 'dd/MM/yyyy HH:mm:ss')  : ('')}}</td>
                    </ng-container>
                    
                   
            
                    <!-- Voir -->
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef class="derniere-colonne" style="text-align: center;">Voir</th>
                        <td mat-cell *matCellDef="let ticket" class="colonne-module">
                            <button mat-icon-button class="button-etat" color="primary"
                                style="width: 48px; height: 48px;"
                                (click)="onVoir(ticket.id)">
                                <mat-icon color="primary">visibility</mat-icon>
                            </button>
                        </td>
                    </ng-container>
            
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator
                    (page)="handlePageEvent($event)"
                    [pageSizeOptions]="[5,10,25]"
                    showFirstLastButtons 
                    aria-label="Nombre de lignes visibles...">
                </mat-paginator>
            </div>
            
        </mat-expansion-panel>
    </mat-card>



