
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatPaginator, PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { Ticket } from 'src/app/interfaces/support/ticket';
import { ApiSupportService } from 'src/app/services/api-support.service';
import { Subject, Observable } from 'rxjs';
import { takeUntil} from 'rxjs/operators';
import { TicketFacadeService, TicketState } from 'src/app/services/ticket/ticket-facade.service';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableResponsiveLargeDirective } from '../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';
import { NgIf, NgClass, DatePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-table-ticket',
    templateUrl: './table-ticket.component.html',
    styleUrls: ['./table-ticket.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatExpansionModule, MatIconModule, NgIf, MatTableModule, MatTableResponsiveLargeDirective, NgClass, MatTooltipModule, MatButtonModule, MatPaginatorModule, DatePipe]
})
export class TableTicketComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;
  
  dataSourceMatTable  : MatTableDataSource<Ticket> = new MatTableDataSource<Ticket>();
  expandPannel        : boolean = true;
  displayedColumns    : Array<string> = ['tck_id', 'tck_titre', 'tck_etat', 'tck_type', 'tck_utl_nom' , 'tck_date','tck_date_premiere_lecture','tck_date_reponse', 'actions'];
  ticket?             : Ticket
  tickets             : Ticket[]=[]
  nbTickets           : number =0
  destroyed           : Subject<void> = new Subject<void>();
  vm$                 : Observable<TicketState> = this.ticketFacade.vm$;

  constructor(public apiSupportService      : ApiSupportService
            , private route                 : Router
            , private ticketFacade          : TicketFacadeService) { }

  ngOnInit(): void {
    this.vm$.pipe(takeUntil(this.destroyed)).subscribe((data)=>{
      this.dataSourceMatTable.data  = data.tickets;
      this.nbTickets                = data.tickets.length
    })
  }

  ngAfterViewInit() {
   
    this.vm$.pipe(takeUntil(this.destroyed)).subscribe((data)=>{
      this.paginator!.pageIndex = data.pagination.currentPage
      this.dataSourceMatTable.paginator = this.paginator;
      this.dataSourceMatTable.sort = this.sort;
    })
   
  }

  onVoir(idTicket:number){
    this.route.navigate(['/app/support/ticket/', idTicket]);
    
  }

  handlePageEvent(event:PageEvent){
    this.ticketFacade.updatePagination(event.pageSize,event.pageIndex)
  }

  ngOnDestroy() {
    this.destroyed.next(); // emits an undefined value to raise the flag
    this.destroyed.complete(); // prevents all further emission
  }
}
