import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appPercent]',
  standalone: true
})
export class PercentDirective {

  constructor(private el: ElementRef) { }

  @HostListener('blur') onBlur() {
    let value : number = this.el.nativeElement.value    
    if (value>100) {
      // value = value.replace(/[^0-9]*/g, '')
     value = 100
      
    }else if (value<0) {
      value=0
    }
    this.el.nativeElement.value = value
  }

}