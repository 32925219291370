import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ConfigReparateur } from 'src/app/interfaces/services-ffea/reparateur/config-reparateur';
import { DossierReparateur } from 'src/app/interfaces/services-ffea/reparateur/dossier-reparateur';
import { FiltreRecherche } from 'src/app/interfaces/services-ffea/reparateur/filtre-recherche';
import { ApiAdelaService } from 'src/app/services/api-adela.service';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { ApiReparateurService } from 'src/app/services/services-ffea/api-reparateur.service';
import { CheckValidatorService } from 'src/app/services/tools/check-validator.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgIf, NgFor } from '@angular/common';
import { ImmatDirective } from '../../../../../directive/immat.directive';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';

export interface OutPutRechercheDossierReparateur{
  dossier_reparateur            : DossierReparateur;
  expandPannel_creativ_expertiz : boolean;
  expandPannel_alliance_experts : boolean;
  expandPannel_experts_groupe   : boolean;
}
export interface OutPutSelectEntite{
  code_reparateur_creativ_expertiz : boolean;
  code_reparateur_alliance_experts : boolean;
  code_reparateur_experts_groupe   : boolean;
}
@Component({
    selector: 'app-recherche-filtre',
    templateUrl: './recherche-filtre.component.html',
    styleUrls: ['./recherche-filtre.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatExpansionModule, MatIconModule, MatFormFieldModule, MatInputModule, ImmatDirective, NgIf, MatSelectModule, NgFor, MatOptionModule, MatButtonModule, MatProgressSpinnerModule]
})
export class RechercheFiltreComponent implements OnInit {

  // Gestion de l'affichage
  waitingResearch : boolean = false;
  ExpandPannel    : boolean = true;
  
  // Interface des filtres de recherche d'un dossier réparateur
  filtre_recherche: FiltreRecherche = <FiltreRecherche>{};
  entites         : Array<Entite>   = Array<Entite>();
  reparateur      : Array<ConfigReparateur> = Array<ConfigReparateur>();

  // Formulaire des filtres de recherche
  FiltreFormGroup: FormGroup;

  // Accesseur en lecture du control du formulaire
  get immatriculation() { return this.FiltreFormGroup.get('immatriculation'); }
  get entite()          { return this.FiltreFormGroup.get('entite'); }

  // Gestion de l'évènement recherche dossier
  @Output() research = new EventEmitter<OutPutRechercheDossierReparateur>();

  // Gestion de l'évènement sélection entité
  @Output() selectEntite = new EventEmitter<OutPutSelectEntite>();
  output_select_entite: OutPutSelectEntite = <OutPutSelectEntite>{};

  constructor(private fb            : FormBuilder
            , private apiReparateur : ApiReparateurService
            , private toast         : PersoSnackbarService
            , private apiAuth       : ApiAuthentificationService
            , private apiEntite     : ApiEntiteService
            , private apiAdela      : ApiAdelaService) {
    // Initialisation du formulaire des filtres de recherche
    this.FiltreFormGroup = this.fb.group({
      immatriculation: ['', [Validators.required, CheckValidatorService.verificationFormatImmatriculation.bind(this)]],
      entite         : [<Entite>{}, [Validators.required]],
    });
   }

  ngOnInit(): void {
    this.getEntite();
  }

  // Chargement des entités associées à l'utilisateur
  getEntite(): void {
    this.apiEntite.getEntitesByIdUtilisateur(this.apiAuth.userConnect.id)
       .subscribe(
         (data: Entite[])  => { 
          this.entites = data;
          
          for(let i = this.entites.length - 1; i >= 0; i--) {
            if(this.entites[i].typ_ent_nom != this.apiEntite.CONST_LIBELLE_TYPE_ENTITE_REPARATEUR) {
              this.entites.splice(i, 1);
            }
          }
        },
        (err: any) => {
          '/!\\ error getEntitesByIdUtilisateur: ' + console.log(err);
        }
      );
  }

  onSelectEntite($event: any): void {
    this.output_select_entite = <OutPutSelectEntite>{};
    this.reparateur           = Array<ConfigReparateur>();

    if($event.value.id != undefined) {
      this.apiReparateur.getConfigReparateurEntite($event.value.id)
      .subscribe(
        (data: ConfigReparateur[]) => {
          this.reparateur = data;
          
          for(let i = 0; i < this.reparateur.length; i++) {
            switch (this.reparateur[i].codeServiceExterne) {
              case this.apiReparateur.CONST_CODE_SRX_ALLIANCE_EXPERTS :
                if(this.reparateur[i].codeReparateur != '') {
                  this.output_select_entite.code_reparateur_alliance_experts = true;
                }
                break;
              case this.apiReparateur.CONST_CODE_SRX_CREATIV_EXPERTIZ :
                if(this.reparateur[i].codeReparateur != '') {
                  this.output_select_entite.code_reparateur_creativ_expertiz = true;
                }
                break;
              case this.apiReparateur.CONST_CODE_SRX_EXPERTS_GROUPE :
                if(this.reparateur[i].codeReparateur != '') {
                  this.output_select_entite.code_reparateur_experts_groupe = true;
                }
                break;
              default:
                break;
            }
          }

          this.selectEntite.emit(this.output_select_entite);
        },
        (err: any) => {        
          '/!\\ error getConfigReparateurEntite: ' + console.log(err);
        }
      );
    }
  }

  // Recherche des dossiers réparateur
  onSearchingFile(): void {
    this.filtre_recherche                 = <FiltreRecherche>{};
    this.filtre_recherche.immatriculation = this.apiAdela.getImmatNonFormated(this.FiltreFormGroup.controls.immatriculation.value);
    this.filtre_recherche.reparateur      = this.reparateur;
    this.waitingResearch = true;
    
    this.apiReparateur.postSearchingFile(this.filtre_recherche, this.apiAuth.userConnect.id)
      .subscribe(
        (dossier_reparateur: DossierReparateur) => {
          
          this.research.emit({
            dossier_reparateur: dossier_reparateur,
            expandPannel_creativ_expertiz : (dossier_reparateur.dossier_creativ_expertiz.length > 0 ? true : false),
            expandPannel_alliance_experts : (dossier_reparateur.dossier_alliance_experts.length > 0 ? true : false),
            expandPannel_experts_groupe   : (dossier_reparateur.dossier_experts_groupe.length > 0 ? true : false)
          });          

          this.toast.showInfo("Recherche terminée")
          this.waitingResearch = false;
        },
        (err: any) => {
          this.research.emit({
            dossier_reparateur: <DossierReparateur>{},
            expandPannel_creativ_expertiz : false,
            expandPannel_alliance_experts : false,
            expandPannel_experts_groupe   : false
          });
          '/!\\ error postSearchingFile: ' + console.log(err);
          this.toast.showInfo(err.error);
          this.waitingResearch = false;
        }
      );
  }
}
