<app-rapport-suspension 
            (rapportEvent)      = "telecharger($event)" 
            [waitingRapport$]    = "waitingRapport$"></app-rapport-suspension>

<app-edit-suspension [suspension$]       = "suspension$" 
                    [typesSuspension$]  = "typesSuspension$"
                    [waitingOperation$] = "waitingOperation$"
                    (suspensionEvent)   = "saveSuspension($event)"
                    (rappelEvent)="envoyerRappel($event)"
                    (clotureEvent)="cloturer($event)"
                    *ngIf="modeEdit===true"
                    ></app-edit-suspension>

<div [hidden]="modeEdit===true">
                        <app-filter-suspension (filtreEvent)="applyFilter($event)" [waiting$]="waiting$"></app-filter-suspension>
                        <app-table-suspension [suspension$]="suspensions$" (suspensionEditEvent)="editSuspension($event)"></app-table-suspension>
</div>