import { Component, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiFactureService } from 'src/app/services/api-facture.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { ApiUtilitaireService } from 'src/app/services/tools/api-utilitaire.service';
import { OuiNonAnnulerComponent } from '../../templates/oui-non-annuler/oui-non-annuler.component';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgIf, NgFor, NgStyle, DecimalPipe, DatePipe } from '@angular/common';

export interface Filtre {
  idUser    : number,
  mois      : string,
  annee     : string
}

@Component({
    selector: 'app-mes-exports',
    templateUrl: './mes-exports.component.html',
    styleUrls: ['./mes-exports.component.scss'],
    standalone: true,
    imports: [NgIf, MatToolbarModule, MatButtonModule, MatIconModule, MatCardModule, ReactiveFormsModule, MatExpansionModule, MatFormFieldModule, MatSelectModule, MatOptionModule, NgFor, MatProgressSpinnerModule, MatTableModule, NgStyle, DecimalPipe, DatePipe]
})
export class MesExportsComponent implements OnInit {

  waitingExport         : boolean = false;
  expandPannel          : boolean = true;

  dateDuJour            : number  = 0;

  moi$                  : Array<string> = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
  annee$                : Array<string> = [];
  profilUtilisateur     : ProfilUtilisateur = <ProfilUtilisateur>{};
  // Formulaire de filtrage
  FiltreFormGroup       : any;
  // Tableau des factures
  displayedColumns    : string[] = ['icone', 'date','numeroFacture','cpt_general', 'cpt_tiers','cabinet','montant_ht','remise_ht','montant_total_ht','port_ht','montant_tva','debit','credit'];
  export$               : any[] = [];

  colorLigne            : string = ''

  
  constructor(private _fb          : FormBuilder
    , private authUser     : ApiAuthentificationService
    , private apiFacture   : ApiFactureService    
    , private apiUtilitaire: ApiUtilitaireService
    , private toast        : PersoSnackbarService
    , public  dialog       : MatDialog)
    {
      
    }
    
  ngOnInit(): void {
    this.profilUtilisateur = this.authUser.userConnect;
    // Initialisation du formulaire de filtrage
    this.FiltreFormGroup = this._fb.group({
      idUser          : [this.profilUtilisateur.id],
      mois            : [[]],
      annee           : [[]]      
    });

    this.dateDuJour = Date.now()
    this.getListeAnnee();
  }
  // récupération des la liste des années de facture disponible
  public getListeAnnee() {
    // Ajout des années disponible en facturation
    this.apiFacture.getAnnee()
    .subscribe(
      data => {
        this.annee$ = data;
        this.FiltreFormGroup.patchValue({
          annee : this.annee$
        });
      },
      err => {console.log(err)},
      () => {}
    )
  }

  // Export des factures selon les filtres demandés
  public onExportInvoice(): void {

    // check des data 
    if ( this.FiltreFormGroup.controls.mois.value == '' || this.FiltreFormGroup.controls.mois.value == undefined ) {
      this.toast.showInfo('Veuillez sélection le mois à exporter.');
      return;
    }
    // check des data 
    if ( this.FiltreFormGroup.controls.annee.value == '' || this.FiltreFormGroup.controls.annee.value == undefined ) {
      this.toast.showInfo('Veuillez sélection l\'année à exporter.');
      return;
    }

    // confirmation de l'export    
    const dialogOuiNon = this.dialog.open(OuiNonAnnulerComponent, { 
      width: '320px', 
      data: { 
        title: "Export des factures",
        message: "Voulez-vous exporter les factures du mois de "+this.FiltreFormGroup.controls.mois.value+" "+this.FiltreFormGroup.controls.annee.value+" ?"        
        , yes_no_cancel: 2 
      } 
    });
    
     dialogOuiNon.afterClosed()
     .subscribe(
       res => {
        switch (res) {
           case true:
            this.apiFacture.getExportFacture(this.FiltreFormGroup.value)
            .subscribe(
                (data : any) => {
                  this.export$ = data;
                  this.toast.showInfo('Factures exportées.');
                },
                err => {
                  this.toast.showError('Erreur lors de l\'export des factures.'+err.message);
                  console.log(err);
                });
              
             break;
          default:
             break;
         }
     });

  }

  public getIconeService(code: string) {
    this.colorLigne = '#323232'
    switch (code.substring(0,2)) {
      case this.apiUtilitaire.CONST_ABREGE_SERVICE_ADELA:
        return 'invert_colors';
      case this.apiUtilitaire.CONST_ABREGE_SERVICE_ADC:
        return 'key';
      case this.apiUtilitaire.CONST_ABREGE_SERVICE_APPEL_OFFRE:
        return 'table_view';
      case this.apiUtilitaire.CONST_ABREGE_SERVICE_OUTIL_DIAGNOSTIC:
        return 'work';
      case this.apiUtilitaire.CONST_ABREGE_SERVICE_EQUIPEMENT_EXPERT:
        return 'shopping_cart'
      case this.apiUtilitaire.CONST_ABREGE_SERVICE_CONCENTRATEUR:
        return 'upload_file'
      case 'Fa': // [Fa]cture
         this.colorLigne = '#fff'
        return ''
      default: 
        return ''
    }
  }  

}
