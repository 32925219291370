import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { ApiAuthentificationService } from '../services/api-authentification.service';
import { catchError, delay, switchMap, tap } from 'rxjs/operators';
import { PersoSnackbarService } from '../services/perso-snackbar.service';
import { Observable, of, throwError } from 'rxjs';

@Injectable()
export class Interceptor implements HttpInterceptor {
    nbTentative : number = 0;
    bRefreshToken: boolean = false;

    constructor(public authService  : ApiAuthentificationService
            ,   private Toast       : PersoSnackbarService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {                
        if(this.bRefreshToken) {
            this.nbTentative++;
        }
        
        if(this.nbTentative > 3) {
            return throwError('Nombre de tentative dépassé');
        }

        request = this.addToken(request);

        return of(true)
                .pipe(
                    delay(this.bRefreshToken ? 100 : 0),
                    switchMap(() => {
                        return next.handle(request)
                                .pipe(
                                    delay(this.bRefreshToken ? 1000 : 0),
                                    tap(() => {
                                        this.nbTentative = 0;                
                                    }),
                                    catchError(err => {

                                        if(err instanceof  HttpErrorResponse && err.error instanceof Blob){                    
                                            return new Promise<any>((resolve, reject) => {
                                                let reader = new FileReader();
                                                reader.onload = (evt: any) => {                    
                                                    try {
                                                        const errmsg = evt.target?.result;
                                                        reject(new HttpErrorResponse({
                                                            error: errmsg,
                                                            headers: err.headers,
                                                            status: err.status,
                                                            statusText: err.statusText
                                                        }));
                                                    } catch (error) {
                                                        reject(err);
                                                    }
                                                };
                                                reader.onerror = (error) => {
                                                    reject(err);
                                                };
                                                reader.readAsText(err.error);
                                            });                                
                                        }

                                        if (err instanceof HttpErrorResponse && err.status === 401) {
                                                        
                                            switch (err.error) {
                                                case 'Mot de passe incorrect.':
                                                    this.Toast.showError(err.error);
                                                    this.authService.logOut();
                                                    this.Toast.showError(err.error);
                                                    break;

                                                case 'Votre compte a ete bloque temporairement':
                                                    this.Toast.showError("Votre compte a été bloqué temporairement");                            
                                                    this.authService.logOut();
                                                    this.Toast.showError(err.error);
                                                    break;

                                                case 'Session inconnu':
                                                    this.Toast.showError( "Une authentification est nécessaire pour accéder à la ressource.");                            
                                                    this.authService.logOut();
                                                    this.Toast.showError(err.error);
                                                    break;

                                                // Token courte durée expiré => tentative de rafraîchissement + repasser la requête en erreur
                                                case 'Token expiré':
                                                    this.bRefreshToken = true;

                                                    return this.authService.refreshTokenCD()
                                                        .pipe(
                                                            switchMap(() => next.handle(this.addToken(request)))
                                                            , tap(() => this.bRefreshToken = false)    
                                                        );                            

                                                // Token longue durée expiré => déconnexion
                                                case 'Votre session a expiré, veuillez vous reconnecter.':
                                                    this.authService.logOut();
                                                    this.Toast.showError(err.error);
                                                    break;

                                                default: 
                                                    this.Toast.showError(err.error);
                                                    return throwError(err.error);
                                            }
                                            
                                            
                                        }   
                                              
                                        return throwError(err);
                            }));
                    })
                );        
    }

    private addToken(request: HttpRequest<any>) {      
        let token: string = this.authService.authUser.token_cd;
        if(token === '' || token === null) {
            token = this.authService.getUsertokenCDOnLocalStorage();
        }

        if(token != '' && token != null) {
            return request.clone({
                setHeaders: {
                    'Authorization': token
                }
            });            
        } else {
            return request;
        }
        
    }
}