<form [formGroup]="accesConcentrateurForm">
      <mat-card appearance="outlined">
            <mat-card-title>
                  <div class="div-container-left">
                        <mat-icon>center_focus_strong</mat-icon>
                        <span>Concentrateur</span>
                  </div>
            </mat-card-title>
            <mat-card-content class=".mat-card-content">
                  <div class="content">
                        <div class="titlebox">Autorisation d'accès</div>

                        <mat-slide-toggle 
                              name              = "actif" 
                              formControlName   = "actif" 
                              color             = "primary" 
                              >
                              {{(accesConcentrateurForm.controls.actif.value ? "L'accès au Concentrateur est activé" : "L'accès au Concentrateur est désactivé" )}}
                        </mat-slide-toggle>
                        <mat-form-field>
                              <mat-label for="mot_de_passe">Mot de passe </mat-label>
                              <input 
                                    matInput 
                                    readonly
                                    formControlName   = "mot_de_passe" 
                                    [type]            = "hidePassword ? 'password' : 'text'"
                                    >
                              <mat-icon 
                                    matSuffix 
                                    mat-icon-button 
                                    style             = "cursor: pointer" 
                                    color             = "primary" 
                                    [matTooltip]      = "!hidePassword ? 'Masquer le mot de passe' : 'Afficher le mot de passe'" 
                                    (click)           = "hidePassword = !hidePassword"
                              >
                                    {{!hidePassword ? 'visibility_off' : 'visibility'}}
                              </mat-icon>
                        </mat-form-field>   
                        <div class="div-button-right">
                              <button 
                                    mat-raised-button 
                                    *ngIf             = "!waiting_save_acces"
                                    (click)           = "saveAccesConcentrateur()" 
                                    type              = "submit" 
                                    color             = "primary"
                                    [disabled]        = "accesConcentrateurForm.controls.actif.pristine"
                                    >
                                                      Enregistrer
                              </button>
                              <mat-spinner diameter="24" *ngIf="waiting_save_acces"></mat-spinner>
                        </div>
                  </div>

                  <div class="content">
                        <div class="titlebox">Mails automatiques d'erreurs</div>

                        <p>Une fois activé, ce service permet la réception automatique de mails vous indiquant les erreurs renvoyées par le concentrateur lors du traitement de vos opérations. Ce service vous permettra une meilleure analyse des blocages et vous évitera un contact à notre support.
                        </p>

                        <mat-slide-toggle 
                              color             = "primary" 
                              formControlName   = "mailAuto" 
                              [disabled]="
                                    !(accesConcentrateurForm.controls.id.value! > 0) ||
                                     !(accesConcentrateurForm.controls.mailConcentrateur.valid) ||
                                      !(accesConcentrateurForm.controls.actif.value)"
                                      >
                              {{(accesConcentrateurForm.controls.mailAuto.value ? "Les mails automatiques sont activés" : "Les mails automatiques sont désactivés" )}}                                                
                        </mat-slide-toggle>
                        <mat-form-field>
                              <input 
                                    matInput 
                                    type              = "text" 
                                    formControlName   = "mailConcentrateur" 
                                    placeholder       = "Adresse mail de retour"
                                    >
                              <mat-hint for="mailAuto">Adresse mail destinataire des erreurs Concentrateur.</mat-hint>
                              <mat-error>{{ accesConcentrateurForm.controls.mailConcentrateur.errors?.erreur }}</mat-error>
                        </mat-form-field>
                        <div class="div-button-right">
                              <button 
                                    mat-raised-button 
                                    *ngIf             = "!waiting_save_data"
                                    (click)           = "saveDataConcentrateur()" 
                                    type              = "submit" 
                                    color             = "primary"
                                    [disabled]        = "(accesConcentrateurForm.controls.mailAuto.pristine 
                                                            && accesConcentrateurForm.controls.mailConcentrateur.pristine) 
                                                            || accesConcentrateurForm.controls.mailConcentrateur.invalid || accesConcentrateurForm.controls.mailConcentrateur.disabled "
                                    >
                                                  Enregistrer
                              </button>
                              <mat-spinner diameter="24" *ngIf="waiting_save_data"></mat-spinner>
                        </div>
                  </div>
            </mat-card-content>
            
      </mat-card>
</form>
<mat-card>
      <mat-card-title>
           Accès API publiques
      </mat-card-title>
      <mat-card-content>
            <div>
                  <mat-slide-toggle 
                              style="margin-top: 10px;"
                              color             = "primary" 
                              [checked]="clePublique?.active"
                              (change)="changeActive($event.checked)"
                              >
                              {{(clePublique?.active===true?  "Clé activée" : "Clé désactivée" )}}
                  </mat-slide-toggle>
                  <div style="margin-top: 20px;" *ngIf="clePublique?.active">
                        <div>
                              <mat-form-field *ngIf="clePublique" style="max-width: 100%;">
                                    <mat-label>Client ID</mat-label>
                                    <input 
                                    matInput    
                                    [(ngModel)]       = "clePublique.idClient"
                                    type              = "text" 
                                    placeholder       = "ID Client"
                                    readonly
                                    >
                                    </mat-form-field>
                        </div>
                        <div>
                              <mat-form-field *ngIf="clePublique" style="max-width: 100%;">
                                    <mat-label>Client secret</mat-label>
                                    <input 
                                                matInput    
                                                [(ngModel)]       = "decryptedPublicKey"
                                                type              = "text" 
                                                placeholder       = "Clé publique API"
                                                readonly
                                                >
                              </mat-form-field>
                        </div>
                        <button 
                              mat-raised-button 
                              *ngIf       = "estAdmin"
                              color       = "primary" 
                              style       = "margin-top: 20px;"
                              [disabled]  = "!clePublique"
                              (click)     = "regenererCle()"
                              >
                              Regénérer
                        </button>
                  </div>
            </div>
      </mat-card-content>
</mat-card>