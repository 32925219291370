import { Component, ViewChild, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { EntiteListComponent } from '../../templates/entite-list/entite-list.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ApiWsaService } from '../data_acces/api-wsa.service';
import { Cabinet, Redirection } from '../interface/wsa';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector    : 'app-wsa',
  templateUrl : './wsa.component.html',
  styleUrls   : ['./wsa.component.scss'],
  standalone  : true,
  imports     : [MatCardModule,MatFormFieldModule,EntiteListComponent,MatInputModule,MatButtonModule,NgFor,NgIf,AsyncPipe
                  , MatTableModule,MatTooltipModule,MatIconModule,MatProgressSpinnerModule,MatPaginatorModule
                ]
})

export class WsaComponent {

  habilitation$     = new BehaviorSubject<string>('')
  apiWsa            = inject(ApiWsaService)
  apiToast          = inject(PersoSnackbarService)
  cabinets$         = this.habilitation$.pipe(mergeMap(
    (habilitation:string)=> {
      const redirection : Redirection = {'source':habilitation}
      if (!habilitation) {
        return of([])
      }else{
        return this.apiWsa.getCabinets(redirection)
      }
    }
  ))
  cabinets:Cabinet[] = []

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;
  
  dataSourceMatTable  : MatTableDataSource<Cabinet> = new MatTableDataSource<Cabinet>();
  displayedColumns    : Array<string> = ['habilitation', 'raison_sociale', 'actions'];
  waitingValidation   : boolean = false
  private unsubscribe$: Subject<void> = new Subject(); //Pour éviter les fuites de mémoire

  constructor(){

  }
  ngOnInit(){
    this.cabinets$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (cabinets:Cabinet[])=>{
        this.cabinets                     = cabinets
        this.dataSourceMatTable.data      = this.cabinets
        this.dataSourceMatTable.paginator = this.paginator
      }
    )
  }
  onAddHabilitation(habilitation:string){
    //On s'assure que l'habilitation cible n'existe pas déjà:
    const habilit = this.cabinets.find((cabinet:Cabinet)=>cabinet.habilitation===habilitation)
    if (habilit) {
      //Cette habilitation est déjà cible
      this.apiToast.showInfo('Cette habilitation est déjà cible du cabinet sélectionné')
      return
    }
    const redirection:Redirection = {'source': this.habilitation$.getValue(),'cible':habilitation}
    this.apiWsa.createRedirection(redirection).subscribe(
      ()=> {
        this.apiToast.showInfo("Votre redirection a bien été effectuée")
        this.habilitation$.next(this.habilitation$.value)
      }
      ,(erreur)=> this.apiToast.showError(erreur)
    )
  }
  onSetEntite(entite:Entite){
    this.habilitation$.next(entite.habilitation)
  }

  onClickSupprime(habilitation:string){
    const redirectionToDelete : Redirection = {'source':this.habilitation$.value,'cible':habilitation}
    this.apiWsa.deleteRedirection(redirectionToDelete).subscribe(
      ()=>{
        this.apiToast.showInfo('Le cabinet a bien été supprimé de la liste')
        this.habilitation$.next(this.habilitation$.value)
      }
    )
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
