import { Component, Input, OnInit } from '@angular/core';
import { Prelevement } from 'src/app/interfaces/facture/prelevement';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { OutPutRecherchePrelevement, FiltreHistoriquePrelevementsComponent } from '../filtre-historique-prelevements/filtre-historique-prelevements.component';
import { TableHistoriquePrelevementsComponent } from '../table-historique-prelevements/table-historique-prelevements.component';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-liste-historique-prelevements',
    templateUrl: './liste-historique-prelevements.component.html',
    styleUrls: ['./liste-historique-prelevements.component.scss'],
    standalone: true,
    imports: [FiltreHistoriquePrelevementsComponent, MatCardModule, TableHistoriquePrelevementsComponent]
})
export class ListeHistoriquePrelevementsComponent implements OnInit {
  // US 328 : on affiche la liste des prélèvements pour le user et du coup l'entité est déjà identifiée. La liste des filtres sera réduite.
  @Input()  entiteEnCours!:Entite;

  numero_contact: string = '';

  prelevements                : Array<Prelevement> = Array<Prelevement>();

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if(this.entiteEnCours != undefined) {
      this.numero_contact = this.entiteEnCours.numero_client;
    }
  }

  // Transmets les résultats de la recherche au component des résultats
  public onChildResearchPrelevements(event: OutPutRecherchePrelevement): void {
    this.prelevements = event.prelevements;
  }

}
