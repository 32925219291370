<mat-dialog-content>
    <mat-card appearance="outlined">
        <mat-card-title>Recadrage de l'image</mat-card-title>
        <mat-card-content style="justify-content: center; display: flex; flex-wrap: wrap;">            
            <div>
                <image-cropper 
                    [imageChangedEvent]="imageChangedEvent" 
                    [maintainAspectRatio]="true" 
                    [aspectRatio]="1 / 1"
                    [resizeToWidth]="150" 
                    format="png" 
                    [transform]="transform"
                    [style.display]="showCropper ? null : 'none'"
                    (imageCropped)="imageCropped($event)" 
                    (imageLoaded)="imageLoaded()"
                    (cropperReady)="cropperReady()" 
                    (loadImageFailed)="loadImageFailed()">
                </image-cropper>
            </div>           
        </mat-card-content>

        <input style="display:flex; justify-content: center;" type="file" #fileUpload (change)="fileChangeEvent($event)" id="fileUpload" name="fileUpload" accept="image/*">
        <div style="display:flex; justify-content: center;" *ngIf="croppedImage">
            <button mat-button (click)="zoomOut()">
                <mat-icon color="primary">zoom_out</mat-icon>
            </button>
            <button mat-button (click)="zoomIn()">
                <mat-icon color="primary">zoom_in</mat-icon>
            </button>
            <button mat-button (click)="resetImage()">
                <mat-icon color="primary">restart_alt</mat-icon>
            </button>                      
        </div>
        
        <div style="display:flex; justify-content: center;" *ngIf="croppedImage" class="img-profil">
            <img class="img-profil" [src]="croppedImage"/>
        </div>

        <mat-card-actions>        
            <mat-dialog-actions align="center">
                <button mat-button mat-dialog-close>
                    <mat-icon color="warn">close</mat-icon>
                </button>
                <button mat-button [mat-dialog-close]="true" cdkFocusInitial>
                    <mat-icon color="primary">done</mat-icon>
                </button>
            </mat-dialog-actions>
        </mat-card-actions>
    </mat-card>                        
</mat-dialog-content>