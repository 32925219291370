import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiAuthentificationService } from '../services/api-authentification.service';

@Injectable({
  providedIn: 'root'
})

export class ProtectSuspensionGuard {
  constructor(private apiAuth : ApiAuthentificationService, private router    : Router){}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
    const tree: UrlTree = this.router.parseUrl("/app/home");

      if(!this.apiAuth.userConnect.droits_utilisateur.droit_module_suspension) {
        return tree;
      }
      
      return true;
  }
};
