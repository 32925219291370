import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { DossierReparateurAllianceExperts } from 'src/app/interfaces/services-ffea/reparateur/dossier-reparateur-alliance-experts';
import { DossierReparateurCreativExpertiz } from 'src/app/interfaces/services-ffea/reparateur/dossier-reparateur-creativ-expertiz';
import { DossierReparateurExpertsGroupe } from 'src/app/interfaces/services-ffea/reparateur/dossier-reparateur-experts-groupe';
import { ServiceExterne } from 'src/app/interfaces/services-ffea/service-externe';
import { ApiUtilitaireService } from 'src/app/services/tools/api-utilitaire.service';
import { MatButtonModule } from '@angular/material/button';
import { MatTableResponsiveDirective } from '../../../../../directive/mat-table-responsive/mat-table-responsive.directive';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
    selector: 'app-recherche-resultat',
    templateUrl: './recherche-resultat.component.html',
    styleUrls: ['./recherche-resultat.component.scss'],
    standalone: true,
    imports: [MatExpansionModule, NgIf, MatIconModule, MatTooltipModule, MatCardModule, MatTableModule, MatTableResponsiveDirective, MatButtonModule, MatPaginatorModule]
})
export class RechercheResultatComponent implements OnInit {

  CONST_CODE_SRX_EXPERTS_GROUPE         : string = 'EXPERTS_GROUPE';
  CONST_CODE_SRX_ALLIANCE_EXPERTS       : string = 'ALLIANCE_EXPERTS';
  CONST_CODE_SRX_CREATIV_EXPERTIZ       : string = 'CREATIV_EXPERTIZ';

  total_dossier_creativ_expertiz: number = 0;
  total_dossier_alliance_experts: number = 0;
  total_dossier_experts_groupe  : number = 0;

  @ViewChild('paginatorCreativExpertiz') paginator_creativ_expertiz!: MatPaginator;
  @ViewChild('paginatorAllianceExperts') paginator_alliance_experts!: MatPaginator;
  @ViewChild('paginatorExpertsGroupe') paginator_experts_groupe!: MatPaginator;

  @Input() dataSource_creativ_expertiz                                  : Array<DossierReparateurCreativExpertiz> = Array<DossierReparateurCreativExpertiz>();  
  dataSourceMatTable_creativ_expertiz                                   : MatTableDataSource<DossierReparateurCreativExpertiz> = new MatTableDataSource<DossierReparateurCreativExpertiz>([]);

  @Input() dataSource_alliance_experts                                  : Array<DossierReparateurAllianceExperts> = Array<DossierReparateurAllianceExperts>();  
  dataSourceMatTable_alliance_experts                                   : MatTableDataSource<DossierReparateurAllianceExperts> = new MatTableDataSource<DossierReparateurAllianceExperts>([]);

  @Input() dataSource_experts_groupe                                    : Array<DossierReparateurExpertsGroupe> = Array<DossierReparateurExpertsGroupe>();  
  dataSourceMatTable_experts_groupe                                     : MatTableDataSource<DossierReparateurExpertsGroupe> = new MatTableDataSource<DossierReparateurExpertsGroupe>([]);

  // Tableau des dossiers
  displayedColumns    : string[] = ['numero_sinistre', 'numero_mission', 'immatriculation', 'url'];
  
  @Input() expandPannel_creativ_expertiz : boolean = false;
  @Input() expandPannel_alliance_experts : boolean = false;
  @Input() expandPannel_experts_groupe   : boolean = false;
  
  @Input() code_reparateur_creativ_expertiz : boolean = false;
  @Input() code_reparateur_alliance_experts : boolean = false;
  @Input() code_reparateur_experts_groupe   : boolean = false;
  
  services_externe: Array<ServiceExterne> = Array<ServiceExterne>();

  constructor(private apiUtilitaire: ApiUtilitaireService) { }

  ngOnInit(): void {
    if(this.dataSource_creativ_expertiz != undefined) {
      this.dataSourceMatTable_creativ_expertiz.data = this.dataSource_creativ_expertiz;
    }

    if(this.dataSource_alliance_experts != undefined) {
      this.dataSourceMatTable_alliance_experts.data = this.dataSource_alliance_experts;
    }

    if(this.dataSource_experts_groupe != undefined) {
      this.dataSourceMatTable_experts_groupe.data = this.dataSource_experts_groupe;
    }

    this.dataSourceMatTable_creativ_expertiz.paginator  = this.paginator_creativ_expertiz;
    this.dataSourceMatTable_alliance_experts.paginator  = this.paginator_alliance_experts;
    this.dataSourceMatTable_experts_groupe.paginator    = this.paginator_experts_groupe;

    this.getServiceExterne(this.apiUtilitaire.CONST_CODE_ENVIRONNEMENT_SERVICES_FFEA);
  }

  ngAfterViewInit() {    
    //this.dataSourceMatTable_creativ_expertiz.paginator  = this.paginator.toArray()[0];
    //this.dataSourceMatTable_alliance_experts.paginator  = this.paginator.toArray()[1];
    this.dataSourceMatTable_experts_groupe.paginator    = this.paginator_experts_groupe;
  }

  ngOnChanges() {

    this.total_dossier_creativ_expertiz = 0;
    this.total_dossier_alliance_experts = 0;
    this.total_dossier_experts_groupe   = 0;

    // obligatoire
    if(this.dataSource_creativ_expertiz != undefined) {
      this.dataSourceMatTable_creativ_expertiz.data = this.dataSource_creativ_expertiz;
      this.total_dossier_creativ_expertiz = this.dataSourceMatTable_creativ_expertiz.data.length;
    }     

    if(this.dataSource_alliance_experts != undefined) {
      this.dataSourceMatTable_alliance_experts.data = this.dataSource_alliance_experts;
      this.total_dossier_alliance_experts = this.dataSourceMatTable_alliance_experts.data.length;
    }  

    if(this.dataSource_experts_groupe != undefined) {
      this.dataSourceMatTable_experts_groupe.data = this.dataSource_experts_groupe;
      this.total_dossier_experts_groupe   = this.dataSourceMatTable_experts_groupe.data.length;
    }  
  }

  public getServiceExterne(code_environnement: string) {
    this.apiUtilitaire.getServiceExterne(code_environnement)
      .subscribe(
        (data: ServiceExterne[]) => {
          this.services_externe = data;
        },
        (err: any) => {
          '/!\\ error postSearchingFile: ' + console.log(err);
        }
      )
  }

  public getUrlServiceExterne(code_service_externe: string) {
    let service_externe: any;
    service_externe = this.services_externe.find(service_externe => service_externe.code === code_service_externe)
    
    if(service_externe.url != undefined) {
      window.open(service_externe.url);
    }
  }
}
