<mat-card appearance="outlined">
    <mat-card-title>Edition d'un modèle</mat-card-title>
    <mat-card-content [formGroup]="ModeleFormGroup">
        <div class="div-container-between">
            <mat-form-field>
                <mat-label>Catégorie</mat-label>
                <mat-select formControlName="idCategorie" 
                            (selectionChange) = "getSousCategories($event.value)">
                    <mat-option *ngFor="let categorie of apiModeleFacade.categories$ | async; trackBy:trackByIdCategorie" 
                                [value]="categorie.cat_id"
                                >
                        {{categorie.cat_nom}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Sous-catégorie</mat-label>
                <mat-select formControlName="idSousCategorie">
                    <mat-option *ngFor="let souscategorie of sousCategories;trackBy:trackByIdSousCategorie" 
                                [value]="souscategorie.sca_id"
                            >
                        {{souscategorie.sca_nom}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="div-container-between">
            <mat-form-field>
                <mat-label>Nom</mat-label>
                <input  type = "text" 
                        matInput 
                        formControlName = "nom">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Code</mat-label>
                <input  type = "text" 
                        matInput 
                        style="text-transform: uppercase"
                        formControlName = "code">
            </mat-form-field>
        </div>
<!--         <div id="ckeditor">
            <ckeditor [editor]="Editor" formControlName="modeleText" ></ckeditor>
        </div> -->
        
        <ckeditor  [editor]="editor" formControlName="modeleText" ></ckeditor>  
        
    </mat-card-content>
    <mat-card-actions>
        <div class="div-container-between" style="padding: 8px;">
            <div class="div-container-left" style="width:100%; display: flex; justify-content: center;">
                <button mat-flat-button color="primary" (click)="onValiderModele()" [disabled]="!ModeleFormGroup.valid" >Valider</button>
                <button mat-flat-button color="primary" (click)="onAnnule()">Annuler</button>
            </div>
        </div>
    </mat-card-actions>
</mat-card>
