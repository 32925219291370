import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-forgotten-password',
    templateUrl: './forgotten-password.component.html',
    styleUrls: ['./forgotten-password.component.scss'],
    standalone: true,
    imports: [NgIf, MatCardModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatButtonModule]
})
export class ForgottenPasswordComponent implements OnInit {

  root  = new FormControl(0);
  resetForm = this.fBuilder.group({
    adresseEmail: new FormControl('', [Validators.required, Validators.email])
  });

  innerWidth: any;
  innerHeight: any;
  
  constructor(
    private routed: ActivatedRoute, 
    private fBuilder: FormBuilder, 
    private authService: ApiAuthentificationService,
    private toast: PersoSnackbarService
  ) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;

    if(this.authService.getRememberMeOnLocalStorage() === true) {
      this.resetForm.patchValue({
        adresseEmail: this.authService.getUserLoginOnLocalStorage()
      });
    }    

    this.routed.fragment
    .subscribe(tabsToSelect => {
      // console.info(data)
      if ( tabsToSelect != undefined ) {
        this.root.setValue(+tabsToSelect)
      }
    })
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
  }

  public isMobileFormat() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    if (this.innerWidth <= 500 || this.innerHeight <= 500) {
      return true;
    } else {
      return false;
    }
  }

  askResetPassword(): void {    
    if ( this.resetForm.value.adresseEmail != undefined && this.resetForm.value.adresseEmail != '' ) {
      this.authService.sendMailForgottenPassword(this.resetForm.value.adresseEmail)
        .subscribe(
          data => { 
            this.toast.showInfo('Ré-initialisation du mot de passe demandée')
            setTimeout(() => {
              this.authService.navigateToLoginPage()
            }, 1500);
           },
          err => {console.error(err.message)}
        );
    } else {
      this.toast.showInfo('adresse mail invalide');
    }
  }
}
