import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SuspensionFiltre, statuts } from '../../interfaces/suspension';
import { Observable, of } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-filter-suspension',
    templateUrl: './filter-suspension.component.html',
    styleUrls: ['./filter-suspension.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatOptionModule, NgFor, NgIf, MatButtonModule, MatIconModule, MatProgressSpinnerModule, AsyncPipe]
})
export class FilterSuspensionComponent implements OnInit {

  @Input()  waiting$!     : Observable<boolean>
  @Output() filtreEvent   = new EventEmitter<SuspensionFiltre>()
  
  filterForm!       : FormGroup 
  
  statuts = statuts

  constructor() { }

  ngOnInit(): void {
    this.initFormGroup()
    this.waiting$.subscribe()
  }

  initFormGroup(){
    this.filterForm = new FormGroup({
        habilitation    : new FormControl(''),
        contact         : new FormControl(''),
        raison_sociale  : new FormControl(''),
        siren           : new FormControl(''),
        statut          : new FormControl('')
    })
  }

  onSearchSuspension(){
    this.filtreEvent.next(this.filterForm.value)
  }
  
  telecharger(){
    
  }
}
