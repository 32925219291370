<mat-toolbar class="navbar">
    <div class="div-container-between" >
        <div style="width: 180px;">
            <a mat-button  *ngIf="true" class="label-accent"><mat-icon >account_balance</mat-icon>Comptabilité</a>
        </div>
        <div class="div-container-left" style="width: 80%;">

        </div>
        
        <div class="label-accent" style="padding-right: 8px; font-size: 14px;">
            <!-- {{ dateDuJour | date: 'EEEE d MMMM, y' }} -->
        </div>
    </div>
    
</mat-toolbar>

<mat-card appearance="outlined" class="mat-card-no-shadow" style="padding: 0px; margin-top: 16px; margin-bottom: 16px;">
    <form [formGroup]="FiltreFormGroup">
        <mat-expansion-panel id="expandFilter" [(expanded)]="expandPannel" #expandFilter>            
            <mat-expansion-panel-header>
                <mat-panel-title class="label-accent">
                    <mat-icon>search</mat-icon>Veuillez sélectionner un type de remise...
                </mat-panel-title>
            </mat-expansion-panel-header>                    

            <mat-form-field>
                <mat-label>Mode Paiement</mat-label>
                <mat-select name="mode_paiement" id="mode_paiement" formControlName="mode_paiement">
                    <mat-option [value]="'TOUS'">TOUS</mat-option>
                    <mat-option *ngFor="let mode_paiement of modes_paiement" [value]="mode_paiement.code">
                        {{ mode_paiement.libelle }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="!FiltreFormGroup?.valid && FiltreFormGroup.touched">Vous devez sélectionner au moins un mode de paiement pour votre recherche</mat-error>
            </mat-form-field>

            <mat-action-row>
                <div class="div-button-center" style="padding: 8px; width: 100%;">
                    <div class="button-container">
                        <button 
                            mat-flat-button 
                            *ngIf       = "!waitingResearch"
                            color       = "primary" 
                            (click)     = "onRechercheReglement()" 
                            [disabled]  = "waitingResearch || !FiltreFormGroup.valid"
                            >
                            <mat-icon>search</mat-icon>Rechercher
                        </button>
                        <mat-spinner diameter="24" *ngIf="waitingResearch"></mat-spinner>
                    </div>
                </div>
            </mat-action-row>
        </mat-expansion-panel>                
    </form>
</mat-card>

<mat-card appearance="outlined" class="mat-card-no-shadow" style="padding: 0px; margin-top: 16px; margin-bottom: 16px;">
    <app-table-remise [ELEMENT_DATA]="reglements" [mode_paiement]="mode_paiementSelectionne"></app-table-remise>
</mat-card>