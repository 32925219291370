<div class="box" [ngClass]="classBody">
    <div class="row header">
        <app-app-install></app-app-install>
        <app-app-update></app-app-update>
        <app-nav-bar [bModeConnecte]="false"></app-nav-bar>      
    </div>
    <div class="row content">
        <router-outlet></router-outlet>      
    </div>
    <div class="row footer">
        <app-footer class="footer" [ngClass]="classBody"></app-footer>
    </div>
</div>