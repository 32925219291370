<div>
    <h1 mat-dialog-title>{{ sTitre }}</h1>
    <h4>{{ sSousTitre }}</h4>  

    <div class="container" mat-dialog-content>     
        <!-- tableau récap des utilisateurs qui seront mis à jour -->
        <table mat-table matTableResponsiveLarge [dataSource]="dataSourceMatTable" class="table-primary">
            
            <!-- Nom --> 
            <ng-container matColumnDef="utl_nom">
                <th mat-header-cell *matHeaderCellDef> Nom </th>
                <td mat-cell *matCellDef="let ProfilUtilisateur" > {{ ProfilUtilisateur.nom }} </td> 
            </ng-container>
            
            <!-- Prénom -->
            <ng-container matColumnDef="utl_prenom">
                <th mat-header-cell *matHeaderCellDef style="padding: 0px 5px 0px 5px;"> Prénom </th>
                <td mat-cell *matCellDef="let ProfilUtilisateur" style="padding: 0px 5px 0px 5px;"> {{ ProfilUtilisateur.prenom }} </td>
            </ng-container>
    
            <!-- Agrément -->
            <ng-container matColumnDef="utl_agrement_ve">
                <th mat-header-cell *matHeaderCellDef style="padding: 0px 5px 0px 5px;"> Agrément </th>
                <td mat-cell *matCellDef="let ProfilUtilisateur" style="padding: 0px 5px 0px 5px;"> {{ ProfilUtilisateur.agrement_ve }} </td>
            </ng-container>
            
            <!-- Type -->
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef style="padding: 0px 5px 0px 5px;"> Rôle </th>
                <td mat-cell *matCellDef="let ProfilUtilisateur" style="padding: 0px 5px 0px 5px;"> {{ ProfilUtilisateur.droits_utilisateur.libelle }} </td>
            </ng-container>

            <!-- mail -->
            <ng-container matColumnDef="utl_mail">
                <th mat-header-cell *matHeaderCellDef style="padding: 0px 5px 0px 5px;"> Mail </th>
                <td mat-cell *matCellDef="let ProfilUtilisateur" style="padding: 0px 5px 0px 5px;"> {{ ProfilUtilisateur.email }} </td>
            </ng-container>

            <!-- Résultat de retour -->
            <ng-container matColumnDef="resultat">
                <th mat-header-cell *matHeaderCellDef> Résultat </th>
                <td mat-cell *matCellDef="let ProfilUtilisateur" > {{ ProfilUtilisateur.resultat }} </td> 
            </ng-container>
                
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5,10,25]"
            showFirstLastButtons 
            aria-label="Nombre de lignes visibles...">
        </mat-paginator>
    </div>

    <br>
    <div class="div-button-center" mat-dialog-actions>
        <button 
                mat-raised-button 
                class   = "button-width"
                color   = "primary"
                *ngIf   = "typeUpdatePage != undefined && typeUpdatePage == CONST_CODE_TYPE_ACTION_ACTIVATION && !waitingApi" 
                (click) = "onClickActivationDesactivation()">
            <mat-icon>check</mat-icon>
            <span class="span-button">Activer</span>                                        
        </button>

        <button 
            mat-raised-button 
            class   = "button-width"
            color   = "primary"
            *ngIf   = "typeUpdatePage != undefined && typeUpdatePage == CONST_CODE_TYPE_ACTION_DESACTIVATION && !waitingApi" 
            (click) = "onClickActivationDesactivation()">
            <mat-icon>close</mat-icon>
            <span class="span-button">Désactiver</span>                                    
        </button> 

        <button 
            mat-raised-button 
            class   = "button-width" 
            color   = "primary" 
            *ngIf   = "typeUpdatePage != undefined && typeUpdatePage == CONST_CODE_TYPE_ACTION_IDENTIFIANTS && !waitingApi" 
            (click) = "onClickResetPassword()">
            <mat-icon>password</mat-icon>
            <span class="span-button">Réinitialiser le mot de passe</span>                  
        </button>

        <button mat-button color="warn" [mat-dialog-close]="action_confirmee" class="button-width">Fermer</button>
        <mat-spinner diameter="24" *ngIf="waitingApi"></mat-spinner>
    </div>
</div>