import { Component, OnInit, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { Fournisseur } from 'src/app/interfaces/commande/fournisseur/fournisseur';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { ApiFournisseurService } from 'src/app/services/api-fournisseur.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { NgStyle, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTableResponsiveLargeDirective } from '../../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';
import { ContexteService, TYPE_FOURNISEUR } from 'src/app/services/contexte.service';

@Component({
    selector: 'app-table-liste-fournisseurs',
    templateUrl: './table-liste-fournisseurs.component.html',
    styleUrls: ['./table-liste-fournisseurs.component.scss'],
    standalone: true,
    imports: [MatTableModule, MatTableResponsiveLargeDirective, MatIconModule, NgStyle, MatButtonModule, NgIf, MatTooltipModule, MatProgressSpinnerModule, MatPaginatorModule]
})
export class TableListeFournisseursComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;
  @Input() dataSource: any;

  dataSourceMatTable  : MatTableDataSource<Fournisseur> = new MatTableDataSource<Fournisseur>();
  expandPannel        : boolean = true;
  displayedColumns    : Array<string> = ['code', 'denomination', 'statut', 'actions'];
  waitingValidation   : boolean = false;

  constructor(
      private router          : Router
    , private apiFournisseur  : ApiFournisseurService
    , private toast           : PersoSnackbarService
    , private contexte        : ContexteService
    , private cdr             : ChangeDetectorRef 
  ) { }

  ngOnInit(): void {
    this.contexte.setType(TYPE_FOURNISEUR)
  }

  ngAfterViewInit() {
    this.paginator!.pageIndex = this.contexte.getPageIndex(TYPE_FOURNISEUR)
    this.dataSourceMatTable.paginator = this.paginator;
    this.dataSourceMatTable.sort = this.sort;
    this.cdr.detectChanges();
  }

  ngOnChanges() {
    this.dataSourceMatTable.paginator = this.paginator;
    // obligatoire
    if(this.dataSource != undefined) {
      this.contexte.setTableData(this.dataSource)
      this.dataSourceMatTable.data = this.contexte.getTableData(TYPE_FOURNISEUR);
    }     
  }

  public onClickOuvrir(nIDFournisseur: number) : void {
    this.contexte.setPageIndex(this.dataSourceMatTable.paginator!.pageIndex)
    this.router.navigate(['/app/administration-extranet/fournisseur/', nIDFournisseur]);
  }

  public onClickActifInactif(fournisseur : Fournisseur): void {
    let message : string = '';

    this.waitingValidation = true;
    fournisseur.actif = !fournisseur.actif; 

    if (fournisseur.actif == true) {
      message = 'Fournisseur activé';
    } else {
      message = 'Fournisseur désactivé';
    }

    this.apiFournisseur.putSupplier(fournisseur)
    .subscribe(
      (data: Fournisseur) => { 
        this.toast.showInfo(message)
        this.waitingValidation = false;
      },
      (err: any) => { 
        '/!\\ error putSupplier: ' + console.log(err); 
        this.toast.showError(err.error);
        fournisseur.actif = !fournisseur.actif;
        this.waitingValidation = false;
      }
    );
  }

  public getToolTipMessage(actif : boolean): string {
    let message : string = '';

    if (actif == true) {
      message = 'Désactiver fournisser';
    } else {
      message = 'Activer fournisseur';
    }

    return message;
  }
}
