import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ApiUtilisateurService } from 'src/app/services/api-utilisateur.service';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ResultatActionEnMasse } from 'src/app/interfaces/resultat-action-en-masse';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatTableResponsiveLargeDirective } from '../../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';

export interface DialogData {
  type: string;
  dataSource_Utilisateurs: any;
}

@Component({
    selector: 'app-mass-update-liste-utilisateurs',
    templateUrl: './mass-update-liste-utilisateurs.component.html',
    styleUrls: ['./mass-update-liste-utilisateurs.component.scss'],
    standalone: true,
    imports: [MatDialogModule, MatTableModule, MatTableResponsiveLargeDirective, MatPaginatorModule, NgIf, MatButtonModule, MatIconModule, MatProgressSpinnerModule]
})
export class MassUpdateListeUtilisateursComponent implements OnInit {

  // Constant pour la gestion de l'affichage
  CONST_CODE_TYPE_ACTION_ACTIVATION     : string = 'activate'   as const;
  CONST_CODE_TYPE_ACTION_DESACTIVATION  : string = 'deactivate' as const;
  CONST_CODE_TYPE_ACTION_IDENTIFIANTS   : string = 'identifiant' as const;

  // gestion de l'affichage
  dataSourceMatTable  : MatTableDataSource<any> = new MatTableDataSource<any>();
  typeUpdatePage      : string = '';
  displayedColumns    : Array<string> = ['utl_nom', 'utl_prenom', 'utl_agrement_ve' , 'type', 'utl_mail', 'resultat'];
  waitingApi          : boolean = false;
  sTitre              : string = '';
  sSousTitre          : string = '';
  sToast              : string = '';
  action_confirmee    : boolean = false;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;

  constructor(
      private apiUtilisateur                : ApiUtilisateurService
    , private apiAuthentificationService    : ApiAuthentificationService 
    , private toast                         : PersoSnackbarService
    , public dialogRef                      : MatDialogRef<MassUpdateListeUtilisateursComponent>
    , @Inject(MAT_DIALOG_DATA) public data  : DialogData) { }

  ngOnInit(): void {
    this.init();
  }

  ngOnChanges() {
    // obligatoire
    this.init();
  }

  private init () {    
    if(this.data.type != undefined) {
      this.typeUpdatePage = this.data.type;

      if(this.data.dataSource_Utilisateurs != undefined) {
        switch (this.data.type) {
          case this.CONST_CODE_TYPE_ACTION_ACTIVATION:
            this.dataSourceMatTable.data = this.supprimerUtilisateursActif(this.data.dataSource_Utilisateurs);
            
            if (this.dataSourceMatTable.data.length == 1) {
              this.sTitre     = 'Activation d\'un utilisateur';
              this.sSousTitre = 'L\'utilisateur suivant va être activé';
              this.sToast     = 'Utilisateur activé'
            } else {
              this.sTitre     = 'Activation de ' + this.dataSourceMatTable.data.length +' utilisateurs';
              this.sSousTitre = 'Les utilisateurs suivants vont être activés';
              this.sToast     = 'Utilisateurs activés'
            }

            break;
          
          case this.CONST_CODE_TYPE_ACTION_DESACTIVATION :  
            this.dataSourceMatTable.data = this.supprimerUtilisateursInactif(this.data.dataSource_Utilisateurs);

            if (this.dataSourceMatTable.data.length == 1) {
              this.sTitre     = 'Désactivation d\'un utilisateur';
              this.sSousTitre = 'L\'utilisateur suivant va être désactivé';
              this.sToast     = 'Utilisateur désactivé'
            } else {
              this.sTitre     = 'Désactivation de ' + this.dataSourceMatTable.data.length +' utilisateurs';
              this.sSousTitre = 'Les utilisateurs suivants vont être désactivés';
              this.sToast     = 'Utilisateurs désactivés'
            }
            break;

          case this.CONST_CODE_TYPE_ACTION_IDENTIFIANTS :
            this.dataSourceMatTable.data = this.supprimerUtilisateursInactif(this.data.dataSource_Utilisateurs);

            if (this.dataSourceMatTable.data.length == 1) {
              this.sTitre     = 'Réinitialisation du mot de passe d\'un utilisateur';
              this.sSousTitre = 'Pour l\'utilisateur suivant, un mail de réinitialisation de mot de passe va être envoyé';
              this.sToast     = 'Réinitialisation du mot de passe terminée'
            } else {
              this.sTitre = 'Réinitialisation du mot de passe de ' + this.dataSourceMatTable.data.length + ' utilisateurs';
              this.sSousTitre = 'Pour les utilisateurs suivants, un mail de réinitialisation de mot de passe va être envoyé';
              this.sToast     = 'Réinitialisation des mots de passe terminée'
            }           
            break;

          default:
            this.dataSourceMatTable.data = this.data.dataSource_Utilisateurs;
              break;
        }
        
        if (this.dataSourceMatTable.data.length <= 0) {
          this.dialogRef.close();
        }
      } else {
          this.dialogRef.close();  
      }
    } else {
        this.dialogRef.close();
    }
  }

  ngAfterViewInit() {
    this.dataSourceMatTable.paginator = this.paginator;
    this.dataSourceMatTable.sort      = this.sort;
  }

  public onClickRetour() : void {
    this.dialogRef.close();
  }

  public onClickActivationDesactivation() :void {

    this.apiUtilisateur.postMassUpdateactivateOrDeactivateUser(this.dataSourceMatTable.data)
    .subscribe(
      (resultat: ResultatActionEnMasse[]) => {
        for(let i = 0; i < resultat.length; i++) {
          this.dataSourceMatTable.data.find(utilisateur => {
            if(utilisateur.id == resultat[i].id ) {
              utilisateur.resultat = resultat[i].resultat;
            }
          });
        }
        this.toast.showInfo(this.sToast);
        this.waitingApi = false;
        this.action_confirmee = true;
      },
      (err: any) => {
        this.toast.showError(err.error);
        this.waitingApi = false;
      }
    );
  }

  public onClickResetPassword() :void {
    this.apiAuthentificationService.postMassUpdateResetPassword(this.dataSourceMatTable.data)
    .subscribe(
      (resultat: ResultatActionEnMasse[]) => {
        for(let i = 0; i < resultat.length; i++) {
          this.dataSourceMatTable.data.find(utilisateur => {
            if(utilisateur.id == resultat[i].id ) {
              utilisateur.resultat = resultat[i].resultat;
            }
          });
        }
        this.waitingApi = false;
      },
      (err: any) => {
        this.toast.showError(err.error);
        this.waitingApi = false;
      }
    );    
  }

  private supprimerUtilisateursInactif(data : Array<ProfilUtilisateur>) : Array<ProfilUtilisateur> {
    return data.filter(utilisateur => utilisateur.actif == true)
  }

  private supprimerUtilisateursActif(data : Array<ProfilUtilisateur>) : Array<ProfilUtilisateur> {
    return data.filter(utilisateur => utilisateur.actif == false)
  }

}
