<mat-card appearance="outlined" style="background-color: #fff;" class="mat-card-padding">
    <mat-card-header>
        <mat-card-title>{{dossiers.length}} dossier<span *ngIf="dossiers.length >1">s</span> en attente de facturation</mat-card-title>
    </mat-card-header>

    <div class="container">
        <table mat-table matTableResponsiveLarge matTableExporter [dataSource]="dataSourceMatTable" #exporter="matTableExporter"  class="table-primary">
            <!-- Type d'analyse Column -->
            <ng-container matColumnDef="typeAnalyse" >
                <th mat-header-cell *matHeaderCellDef class="premiere-colonne" > Type d'analyse </th>
                <td mat-cell *matCellDef="let dossier"> {{dossier.st_Dossier.prestation.typeElement.libelle}} </td>
            </ng-container>

            <!-- Immatriculation Column -->
            <ng-container matColumnDef="immatriculation" >
                <th mat-header-cell *matHeaderCellDef > Immatriculation </th>
                <td mat-cell *matCellDef="let dossier" class="colonne-immat"> {{dossier.st_Dossier.immatriculation}} </td>
            </ng-container>
            
            <!-- Etiquette Column -->
            <ng-container matColumnDef="etiquette">
                <th mat-header-cell *matHeaderCellDef> N° Etiquette </th>
                <td mat-cell *matCellDef="let dossier"> {{dossier.st_Dossier.numEtiquette}} </td>
            </ng-container>

            <!-- Reference Column -->
            <ng-container matColumnDef="reference">
                <th mat-header-cell *matHeaderCellDef> Référence </th>
                <td mat-cell *matCellDef="let dossier" class="small-screen">
                    <button mat-flat-button class="custom-button" target="_blank" (click)="apiAdela.downloadDossier(dossier.st_Dossier.idDossier, dossier.st_Dossier.referenceDossierExpert)"
                        style="text-decoration: underline;">{{dossier.st_Dossier.referenceDossierExpert}}</button>
                </td>
            </ng-container>

            <!-- Cabinet Column -->
            <ng-container matColumnDef="cabinet">
                <th mat-header-cell *matHeaderCellDef> Cabinet </th>
                <td mat-cell *matCellDef="let dossier"> {{dossier.st_Entite.raison_sociale}} </td>
            </ng-container>

            <!-- Expert Column -->
            <ng-container matColumnDef="expert" >
                <th mat-header-cell *matHeaderCellDef> Expert </th>
                <td mat-cell *matCellDef="let dossier"> {{dossier.st_Utilisateur.nom}} {{dossier.st_Utilisateur.prenom}} </td>
            </ng-container>

            <!-- Date Column -->
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef style="width: 92px;"> Date </th>
                <td mat-cell *matCellDef="let dossier"> {{ (dossier.st_Dossier.dateEnvoi == '0000-00-00T00:00:00.000' || dossier.st_Dossier.dateEnvoi == '' ? '1900-01-01' : dossier.st_Dossier.dateEnvoi ) | date: 'dd/MM/yyyy'  }}
                </td>
            </ng-container>

            <!-- Etat Column -->
            <ng-container matColumnDef="etat">
                <th mat-header-cell *matHeaderCellDef class="etat-padding"> État </th>
                <td mat-cell *matCellDef="let dossier" class="etat-padding">
                    <button mat-stroked-button class="button-etat button-width" color="primary"
                        [ngStyle]="{ color: apiAdela.getColorByStatus(dossier.etatCode) }"
                        (click)="apiAdela.dialogHistoriqueDossier(dossier.st_Dossier.idDossier)">{{dossier.etat}}</button>
                </td>
            </ng-container>

            <!-- Actions Column -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="derniere-colonne" style="text-align: center;"></th>
                <td mat-cell *matCellDef="let dossier" class="center">
                    <div *ngIf="dossier.estFacturee===true" class="factureSuccess">Dossier facturé</div>
                    <button 
                        mat-raised-button 
                        *ngIf       = "dossier.estFacturee===false && !dossier.estTraitement" 
                        class       = "button-etat" 
                        color       = "primary" 
                        (click)     = "factureDossier(dossier)">
                        Facturer le dossier
                    </button>
                    <mat-spinner *ngIf="dossier.estFacturee===false && dossier.estTraitement===true" style="margin: auto;" diameter="40"></mat-spinner>                                                        
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="div-container-between" style="align-items: center;">
            <app-export-table [fileExportName]="'EXTRANET - Mes dossiers ADELA'" [exporter]="exporter" [nb_ligne]="this.dataSourceMatTable.data.length"></app-export-table>
            <mat-paginator [pageSizeOptions]="[10,25,50]"
                showFirstLastButtons 
                aria-label="Nombre de lignes visibles...">
             </mat-paginator>
        </div>
        
    </div>
</mat-card>
