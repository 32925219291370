import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';


export interface PeriodeEtablissement{
  etatAdministratifEtablissement:string
}
export interface Etablissement {
  siren                           : string,
  siret                           : string,
  etablissementSiege              : boolean
  uniteLegale   : {
    etatAdministratifUniteLegale  : string,
    denominationUniteLegale       : string,
    categorieJuridiqueUniteLegale : string,
    nomUniteLegale                : string,
    prenomUsuelUniteLegale        : string
  }
  adresseEtablissement:{
    typeVoieEtablissement         : string,
    indiceRepetitionEtablissement : string,
    numeroVoieEtablissement       : string,
    complementAdresseEtablissement:string,
    libelleVoieEtablissement      : string,
    codePostalEtablissement       : string,
    libelleCommuneEtablissement   : string
  }
  periodesEtablissement          : Array<PeriodeEtablissement>
}

export const EtablissementInitial:Etablissement={
  siren:'',
  siret:'',
  etablissementSiege:false,
  uniteLegale:{
    etatAdministratifUniteLegale:'',
    denominationUniteLegale:'',
    categorieJuridiqueUniteLegale: '',
    nomUniteLegale                : '',
    prenomUsuelUniteLegale        : ''
  },
  adresseEtablissement:{
    typeVoieEtablissement : '',
    indiceRepetitionEtablissement : '',
    numeroVoieEtablissement : '',
    complementAdresseEtablissement:'',
    libelleCommuneEtablissement:'',
    codePostalEtablissement:'',
    libelleVoieEtablissement:''
  },
  periodesEtablissement:[]
}
export interface Header{
  statut:number,
  message:string
}

export interface ReponseSiren{
  header :Header,
  etablissement:Etablissement
}

@Injectable({
  providedIn: 'root'
})

export class SirenService {
    
  url: string = '/api/siren';

    constructor(private http: HttpClient){}
    
    getEtablissement(siret:string):Observable<ReponseSiren>{
      return this.http.get<ReponseSiren>(this.url+"/"+siret)
    }
      
    public checkLengthSIRET(control: FormControl)   {
      const siret: string = control.value;
      if (siret.length != 14) {
        return {  erreur : 'Le numéro SIRET doit contenir 14 chiffres'}
      }
      return null
    }

    getVoieFormatee(etablissement:Etablissement):string{
      let voie        : string
      let numeroVoie  : string = etablissement.adresseEtablissement.numeroVoieEtablissement
      
      voie =     (numeroVoie!=''?   +numeroVoie : '')
            + ( etablissement.adresseEtablissement.indiceRepetitionEtablissement != '' ? ' ' + etablissement.adresseEtablissement.indiceRepetitionEtablissement : '')
             + ' '  + etablissement.adresseEtablissement.typeVoieEtablissement
             + ' '  + etablissement.adresseEtablissement.libelleVoieEtablissement

      return voie.trim()
    }
}
