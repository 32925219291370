import { Component, Input } from '@angular/core';
import { Mail } from '../../mail-interface';
import { MatCardModule } from '@angular/material/card';
import { DatePipe, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-mail-file',
  templateUrl: './mail-file.component.html',
  styleUrls: ['./mail-file.component.scss'],
  standalone:true,
  imports:[MatCardModule,DatePipe,NgIf,MatIconModule]
})
export class MailFileComponent {
  @Input() mail: Mail | null = null;

  ngOnInit(){
    
  }
}
