<div class="container">
    <table mat-table matTableResponsiveLarge [dataSource]="dataSource" class="table-primary">

        <!-- Agrement VE Column --> 
        <ng-container matColumnDef="agrement_ve">
            <th mat-header-cell *matHeaderCellDef style="text-align: left; padding-right: 16px;" class="premiere-colonne colonne-invisible"> Agrément VE </th>
            <td mat-cell *matCellDef="let stats_repartition_msg_expert" style="text-align: left;" class="colonne-invisible">{{ stats_repartition_msg_expert.agrement_ve }}</td>
        </ng-container>
        
        <!-- Expert Column --> 
        <ng-container matColumnDef="expert">
            <th mat-header-cell *matHeaderCellDef> Expert </th>
            <td mat-cell *matCellDef="let stats_repartition_msg_expert">{{ stats_repartition_msg_expert.nom }} {{ stats_repartition_msg_expert.prenom }}</td>
        </ng-container>
 
        <!-- Total Dossier Column --> 
        <ng-container matColumnDef="total_dossier">
            <th mat-header-cell *matHeaderCellDef> Dossier </th>
            <td mat-cell *matCellDef="let stats_repartition_msg_expert">{{ stats_repartition_msg_expert.stats_repartition_msg.total_dossier }}</td>
        </ng-container>

        <!-- Total 1A Oui Column --> 
        <ng-container matColumnDef="total_1a_oui">
            <th mat-header-cell *matHeaderCellDef> 1A OUI </th>
            <td mat-cell *matCellDef="let stats_repartition_msg_expert">{{ stats_repartition_msg_expert.stats_repartition_msg.total_1a_oui }}</td>
        </ng-container>

        <!-- Total 1A Non Column --> 
        <ng-container matColumnDef="total_1a_non">
            <th mat-header-cell *matHeaderCellDef> 1A NON </th>
            <td mat-cell *matCellDef="let stats_repartition_msg_expert">{{ stats_repartition_msg_expert.stats_repartition_msg.total_1a_non }}</td>
        </ng-container>

        <!-- Total 1B Oui Column --> 
        <ng-container matColumnDef="total_1b_oui">
            <th mat-header-cell *matHeaderCellDef> 1B OUI </th>
            <td mat-cell *matCellDef="let stats_repartition_msg_expert">{{ stats_repartition_msg_expert.stats_repartition_msg.total_1b_oui }}</td>
        </ng-container>

        <!-- Total 1A Non Column --> 
        <ng-container matColumnDef="total_1b_non">
            <th mat-header-cell *matHeaderCellDef> 1B NON </th>
            <td mat-cell *matCellDef="let stats_repartition_msg_expert">{{ stats_repartition_msg_expert.stats_repartition_msg.total_1b_non }}</td>
        </ng-container>

        <!-- Total 1C Column --> 
        <ng-container matColumnDef="total_1c">
            <th mat-header-cell *matHeaderCellDef class="derniere-colonne"> 1C </th>
            <td mat-cell *matCellDef="let stats_repartition_msg_expert">{{ stats_repartition_msg_expert.stats_repartition_msg.total_1c }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10,25,50]"
                    showFirstLastButtons 
                    aria-label="Nombre de lignes visibles...">
    </mat-paginator>
</div>