import { CurrencyPipe } from '@angular/common';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Analyse } from 'src/app/interfaces/adela/analyse';

@Component({
  selector: 'app-analyse-adela-selection',
  templateUrl: './analyse-adela-selection.component.html',
  styleUrls: ['./analyse-adela-selection.component.scss'],
  standalone:true,
  imports:[
    MatTableModule, MatDialogModule, MatButtonModule, CurrencyPipe,MatIconModule,MatTooltipModule]
  ,encapsulation: ViewEncapsulation.None // Désactive l'encapsulation pour appliquer les styles globalement
})
export class AnalyseAdelaSelectionComponent {

  displayedColumns: string[] = ['categorie', 'designation', 'typeAnalyse', 'fluide', 'Organe', 'tarif', 'select'];
  dataSource: MatTableDataSource<Analyse>;

  constructor(
    public dialogRef: MatDialogRef<AnalyseAdelaSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      analyses: Analyse[],
      getTypeFluide: (idFluide: number) => string,
      getOrganePrelevement: (idOrgane: number) => string,
      getTypeAnalyse: (idTypeAnalyse: number) => string
    }
  ) {
    this.dataSource = new MatTableDataSource(data.analyses);
  }

  onSelect(analysis: Analyse) {
    this.dialogRef.close(analysis);
  }

  onCancel() {
    this.dialogRef.close();
  }  
}
