import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Mail, MailFilter } from '../mail-interface';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  private apiUrl = 'api/mail';

  constructor(private http: HttpClient) {}

  postFilteredMails(filter: MailFilter): Observable<Mail[]> {
    return this.http.post<Mail[]>(this.apiUrl, filter);
  }

  putRazNbEssais(listeIdMails:string){
    const sListeIdMails ={"listeIdMails":listeIdMails}
    return this.http.put<string>(this.apiUrl+'/reset',sListeIdMails)
  }
}
