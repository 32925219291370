import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ImageCroppedEvent, ImageTransform, ImageCropperModule } from 'ngx-image-cropper';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
    selector: 'app-dialog-logo-entite',
    templateUrl: './dialog-logo-entite.component.html',
    styleUrls: ['./dialog-logo-entite.component.scss'],
    standalone: true,
    imports: [MatDialogModule, MatCardModule, ImageCropperModule, NgIf, MatButtonModule, MatIconModule]
})
export class DialogLogoEntiteComponent implements OnInit {

  @ViewChild("fileUpload", {static: false}) fileUpload: ElementRef = <ElementRef>{};
  
  imageChangedEvent: any = '';
  croppedImage: any = '';
  scale: number = 0.5;
  showCropper = false;
  transform: ImageTransform = {};
  constructor() { }

  ngOnInit(): void {
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    }
    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }
    imageLoaded() {
        /* show cropper */
        this.showCropper = true;
    }
    cropperReady() {
        /* cropper ready */
        this.showCropper = true;
    }
    loadImageFailed() {
        /* show message */
    }

  resetImage() {
      this.scale = 1;
      this.transform = {};
  }

  zoomOut() {
      this.scale -= .1;
      this.transform = {
          ...this.transform,
          scale: this.scale
      };
  }

  zoomIn() {
      this.scale += .1;
      this.transform = {
          ...this.transform,
          scale: this.scale
      };
  }
  
}
