import { AsyncPipe, DatePipe, NgFor, NgIf } from '@angular/common';
import { Component, ViewChild, inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FiltreRedirection, Redirection, RedirectionDossiers } from '../../interface/wsa';
import { ApiWsaService } from '../../data_acces/api-wsa.service';
import { EntiteListComponent } from 'src/app/components/templates/entite-list/entite-list.component';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { MatDialog } from '@angular/material/dialog';
import { OuiNonAnnulerComponent } from 'src/app/components/templates/oui-non-annuler/oui-non-annuler.component';
import { BehaviorSubject, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ExportTableComponent } from 'src/app/components/templates/export-table/export-table.component';
import { MatTableExporterDirective, MatTableExporterModule } from 'mat-table-exporter';

@Component({
  selector: 'app-redirection',
  templateUrl: './redirection.component.html',
  styleUrls: ['./redirection.component.scss'],
  standalone:true,
  imports:[MatCardModule,MatFormFieldModule,MatInputModule,MatButtonModule,NgFor,NgIf,AsyncPipe
          , MatTableModule,MatTooltipModule,MatIconModule,MatProgressSpinnerModule,MatPaginatorModule
          ,MatRippleModule,FormsModule,ReactiveFormsModule,DatePipe,EntiteListComponent
          , OuiNonAnnulerComponent,ExportTableComponent, MatTableExporterModule]
})
export class RedirectionComponent {

  @ViewChild('paginator', { static: true }) public paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort | null = null;
  
  dataSourceRedirections          : MatTableDataSource<Redirection> = new MatTableDataSource<Redirection>();
  
  apiWsa            = inject(ApiWsaService)
  apiToast          = inject(PersoSnackbarService)
  dialog            = inject(MatDialog)

  filtreMissionForm = new FormGroup({
    numeroSinistre : new FormControl('')
    ,dateDebut : new FormControl<Date|null>(null)
    , dateFin  : new FormControl<Date|null>(null)
  })

  entite!                         : Entite
  displayedColumnsRedirections    : Array<string> = ['dateRedirection', 'numeroSinistre', 'habilitation', 'raisonSociale', 'actions'];
  waiting                         : boolean = false
  waitingRemiseADisposition       : boolean = false
  remiseADispositionSubject       = new BehaviorSubject<Redirection|null>(null)
  remise$                         = this.remiseADispositionSubject.pipe(mergeMap(
    (redirectionSelectionnee:Redirection|null)=>{
      if (redirectionSelectionnee===null) {
        return of(null)
      }
      const redirection:RedirectionDossiers = {
        'ancienCode'    : redirectionSelectionnee!.nouveauCode!
        ,'nouveauCode'  : this.entite.habilitation
        ,'dossiers'     : redirectionSelectionnee!.idDossier!.toString()
      }
      
      this.waitingRemiseADisposition = true
      return this.apiWsa.remettreADispositioMissions(redirection)
    }))

  ngOnInit(){
    this.dataSourceRedirections.paginator = this.paginator

    this.remise$.subscribe(
      (data:any)=>{
        if (data!=undefined) {
          this.apiToast.showInfo('La remise à disposition de la mission ' + this.remiseADispositionSubject.getValue()?.numeroSinistre + ' a bien été effectuée')
          this.waitingRemiseADisposition = false
        }
      }
    ,(error)=>{
        this.waitingRemiseADisposition=false
        this.apiToast.showError(error.error)
      }
    )
  }

  onSetEntite(entite:Entite){
    this.entite= entite
  }

  getRedirections(){
    const numeroSinistre:string|null = this.filtreMissionForm.get('numeroSinistre')!.value
    this.waiting = true
    const filtre : FiltreRedirection = {
                                        'habilitation'    : this.entite?.habilitation? this.entite.habilitation : '' 
                                        ,'numeroSinistre' : numeroSinistre
                                        ,'dateDebut'      : this.filtreMissionForm.get('dateDebut')!.value
                                        , 'dateFin'       : this.filtreMissionForm.get('dateFin')!.value
                                      }    
    this.apiWsa.getHistorique(filtre).subscribe(
      (redirections:Redirection[]) => {
        this.waiting = false
        this.dataSourceRedirections.data = redirections
      }
      ,(error)=>this.waiting = false
    )
  }

  remiseADisposition(redirectionSelectionnee:Redirection){
    
    const dialogOuiNon = this.dialog.open(OuiNonAnnulerComponent, { 
      width: '320px', 
      data: { 
        title: "",
        message: "Voulez-vous vraiment remettre à disposition la mission " + redirectionSelectionnee.numeroSinistre + " ?"        
        , yes_no_cancel: 2 
      } 
    });

    dialogOuiNon.afterClosed()
     .subscribe(
       res => {
        switch (res) {
           case true:            
             this.remiseADispositionSubject.next(redirectionSelectionnee)
              
             break;
          default:
             break;
         }
     });
  }

}
