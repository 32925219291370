import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InvitationCollaborateur } from '../interfaces/invitation-collaborateur/invitation-collaborateur';
import { InvitationCollaborateurBdd } from '../interfaces/invitation-collaborateur/invitation-collaborateur-bdd';

@Injectable({
  providedIn: 'root'
})
export class ApiInvitationCollaborateurService {
  
  urlApi: string = '/api';

  constructor(private http: HttpClient) { }

  public getInvitationCollaborateur(tocken: string): Observable<InvitationCollaborateurBdd> {
    return this.http.get<InvitationCollaborateurBdd>(this.urlApi+`/hc/invitation-collaborateur?tocken=`+tocken);
  }

  public postInviterCollaborateur(invitationCollaborateur: InvitationCollaborateur, id_entite: number): Observable<InvitationCollaborateur> {
    return this.http.post<InvitationCollaborateur>(this.urlApi + `/invitation-collaborateur?id_entite=` + id_entite, invitationCollaborateur);
  }

  public postAccepterInvitation(invitationCollaborateur: InvitationCollaborateurBdd): Observable<InvitationCollaborateurBdd> {
    return this.http.post<InvitationCollaborateurBdd>(this.urlApi + `/hc/invitation-collaborateur/accepter`, invitationCollaborateur);
  }

  public postRefuserInvitation(invitationCollaborateur: InvitationCollaborateurBdd): Observable<InvitationCollaborateurBdd> {
    return this.http.post<InvitationCollaborateurBdd>(this.urlApi + `/hc/invitation-collaborateur/refuser`, invitationCollaborateur);
  }
}
