import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[appAutofocus]',
    standalone: true
})
export class AutofocusDirective {

  constructor(private elementRef: ElementRef) { }

  ngAfterContentInit(){
    this.elementRef.nativeElement.focus();
}
}
