import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'newlineToBr',
  standalone: true
})
export class NewlineToBrPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return value;
    }
    // Remplacer les retours chariot par <br>
    return value.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }

}
