import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { ApiAuthentificationService } from '../services/api-authentification.service';

@Injectable({
  providedIn: 'root'
})

@Injectable()
class PermissionService {
  
  constructor(private apiAuth : ApiAuthentificationService, private router    : Router){}

  canActivate(): boolean | UrlTree {
    const tree: UrlTree = this.router.parseUrl("/app/home");
        
    if(this.apiAuth.userConnect.droits_utilisateur.droit_module_admin_wsa) {
      return true;
    }
    return tree;
  }
}

export const protectAdministrationWsaGuard: CanActivateFn = (route, state) => {
  return inject(PermissionService).canActivate();
};
