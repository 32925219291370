<div #containerRef class="chart-col" style="margin-bottom: 100px;">
    <ngx-charts-pie-chart  
        (window:resize)="resizeChart(containerRef.offsetWidth)"
        [view]="view"
        [results]="chartData"
        [scheme]="'cool'"
        [labels]="true"
        [legend]="true"
        [legendPosition]="legendPosition"
        [legendTitle]="''"
        [animations]="false">
    </ngx-charts-pie-chart>
</div>