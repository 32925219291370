import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiErrorService } from 'src/app/services/support/api-error.service';
import { Droit, GpeUtlDrt, Groupe, TypeGroupes } from '../groupe-interface';

@Injectable({
  providedIn: 'root'
})
export class GroupeService {

  url : string = '/api'
  constructor(  private http : HttpClient
              , private apiError : ApiErrorService
            ) { }

              
  //1.1 - Select par code environnement
  public getGroupes(codeEnvironnement : string, chargerDroits: boolean = false,chargeTypesEntite:boolean=false):Observable<Groupe[]>{

    let filtre : string = '';
    
    if (codeEnvironnement != '') {
      filtre += '&codeenvironnement='+codeEnvironnement
    };

    return this.http.get<Groupe[]>(this.url+'/groupe?chargerdroits='+( chargerDroits==false?0:1)+'&chargertypes='+(chargeTypesEntite===false?0:1) + filtre)
                     .pipe(catchError(this.apiError.handleError<Groupe[]>('getGroupes',[])));
  }

    //1.1 - Select par code entité
    // possible d'executer hors connexion
    public getGroupesParCodeEntite(codeEntite : string):Observable<Groupe[]>{  
      return this.http.get<Groupe[]>(this.url+'/hc/groupes?codeentite=' + codeEntite)
                       .pipe(catchError(this.apiError.handleError<Groupe[]>('getGroupesParCodeEntite',[])));
    }

  public postGroupe(groupe:Groupe):Observable<Groupe>{
    return this.http.post<Groupe>(this.url + '/groupe',groupe)
                    .pipe(catchError(this.apiError.handleError<Groupe>('postGroupe')));
  }

  public putGroupe(groupe:Groupe):Observable<Groupe>{
    return this.http.put<Groupe>(this.url + '/groupe',groupe)
  }

  public getDroits():Observable<Droit[]>{
    return this.http.get<Droit[]>(this.url + '/droits')
                .pipe(catchError(this.apiError.handleError<Droit[]>('getDroits')));
  }

  public putDroits(gpeUtlDrt:GpeUtlDrt):Observable<any>{
    return this.http.put<any>(this.url + '/droits',gpeUtlDrt)
  }

  public putTypesEntite(typeGroupes:TypeGroupes):Observable<any>{
    return this.http.put<any>(this.url + '/types',typeGroupes)
  }

  public deleteGroup(idGroupe:number):Observable<any>{
    return this.http.delete<any>(this.url + '/groupe/'+idGroupe)
  }
}
