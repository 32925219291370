<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <mat-icon color="primary">file</mat-icon>
                <span style="width: 220px;" *ngIf="dataSource != undefined"><span class="span-primary">{{ dataSource.data.length }}</span>REGLEMENT<span *ngIf="dataSource.data.length > 1">S</span></span>
                <span style="width: 220px;" *ngIf="dataSource == undefined"><span class="span-primary">0</span>REGLEMENT</span>                                                                                                                     
            </mat-panel-title>
            <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>

            <mat-card-subtitle>Mes Règlements</mat-card-subtitle>
            
            <div class="div-button-center" style="padding: 8px;">
                <div class="button-container">                    
                    <button 
                        mat-flat-button 
                        *ngIf       = "!waitingRemise"
                        (click)     = "onRemise()" 
                        color       ="accent" 
                        [disabled]  = "waitingRemise || this.selection.selected.length <= 0"
                        >
                        <mat-icon>task</mat-icon>Remettre les règlements sélectionnés
                    </button>
                    <mat-spinner diameter="24" *ngIf="waitingRemise"></mat-spinner>
                </div>
            </div>            

            <div class="container">
                <table mat-table *ngIf="dataSource.data" [dataSource]="dataSource" class="table-primary">
                    
                    <!-- Checkbox Column -->
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef class="premiere-colonne">
                        <mat-checkbox type="checkbox" (change)="$event ? masterToggle() : null"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                                        [aria-label]="checkboxLabel()"></mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? selection.toggle(row) : null"
                                        [checked]="selection.isSelected(row)"
                                        [aria-label]="checkboxLabel(row)"
                                        style="padding-left: 24px;"></mat-checkbox>
                        </td>
                    </ng-container>
                    
                    <!-- Position Column -->
                    <ng-container matColumnDef="position" >
                        <th mat-header-cell *matHeaderCellDef> No. </th>
                        <td mat-cell *matCellDef="let reglement"> {{reglement.position}} </td>
                    </ng-container>

                    <!-- Date Règlement Column -->
                    <ng-container matColumnDef="date_reglement">
                        <th mat-header-cell *matHeaderCellDef> Date règlement </th>
                        <td mat-cell *matCellDef="let reglement"> 
                            <div *ngIf="reglement.dateReglement >= '19000101'">{{reglement.dateReglement | date: 'dd/MM/yyyy'}}</div>
                            <div *ngIf="reglement.dateReglement < '19000101'"> __/__/____</div>
                        </td>
                    </ng-container>

                    <!-- Date Création Column --> 
                    <ng-container matColumnDef="date_creation">
                        <th mat-header-cell *matHeaderCellDef> Date enregistrement </th>
                        <td mat-cell *matCellDef="let reglement"> 
                            <div *ngIf="reglement.dateCreation >= '19000101'">{{reglement.dateCreation | date: 'dd/MM/yyyy'}}</div>
                            <div *ngIf="reglement.dateCreation < '19000101'"> __/__/____</div>
                        </td>
                    </ng-container>

                    <!-- N° Chèque/Virement Column --> 
                    <ng-container matColumnDef="libelle">
                        <th mat-header-cell *matHeaderCellDef> N° Chèque/Virement </th>
                        <td mat-cell *matCellDef="let reglement">{{ reglement.libelle }}</td>
                    </ng-container>

                    <!-- Commentaire Column--> 
                    <ng-container matColumnDef="commentaire">
                        <th mat-header-cell *matHeaderCellDef> Commentaire </th>
                        <td mat-cell *matCellDef="let reglement">{{ reglement.commentaire }}</td>
                    </ng-container>

                    <!-- N° Facture Column -->
                    <ng-container matColumnDef="facture">
                        <th mat-header-cell *matHeaderCellDef> N° Facture </th>
                        <td mat-cell *matCellDef="let reglement">{{ reglement.facture.numero }}</td>
                    </ng-container>

                    <!-- Raison Sociale Column -->
                    <ng-container matColumnDef="raison_sociale">
                        <th mat-header-cell *matHeaderCellDef> Raison Sociale </th>
                        <td mat-cell *matCellDef="let reglement">{{ reglement.facture.Entite.raison_sociale }}</td>
                    </ng-container>

                    <!-- Compte Tiers Column -->
                    <ng-container matColumnDef="compte_tier">
                        <th mat-header-cell *matHeaderCellDef> Compte Tiers </th>
                        <td mat-cell *matCellDef="let reglement">{{ reglement.facture.Entite.compteTiers }}</td>
                    </ng-container>

                    <!-- Montant Crédit Column -->
                    <ng-container matColumnDef="credit">
                        <th mat-header-cell *matHeaderCellDef> Montant Crédit </th>
                        <td mat-cell *matCellDef="let reglement">{{ reglement.montant }} €</td>
                    </ng-container>
                    
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
    </mat-expansion-panel>
</mat-accordion>