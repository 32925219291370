import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Adresse, AdresseInit } from 'src/app/interfaces/adresse/adresse';
import { CST_ACTION_ADD, CST_ACTION_REMOVE } from 'src/app/interfaces/constantes';
import { MatTableResponsiveLargeDirective } from '../../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';

@Component({
    selector: 'app-table-adresse',
    templateUrl: './table-adresse.component.html',
    styleUrls: ['./table-adresse.component.scss'],
    standalone: true,
    imports: [MatCardModule, NgIf, MatButtonModule, MatIconModule, MatTooltipModule, MatTableModule, MatTableResponsiveLargeDirective, MatPaginatorModule]
})
export class TableAdresseComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;

  dataSourceMatTable  : MatTableDataSource<Adresse> = new MatTableDataSource<Adresse>();
  displayedColumns    : string[] = ['lignes' ,'cp','ville', 'actions']


  @Input() adresses? : Adresse[]=[]
  @Output() adresseEditEvent = new EventEmitter<Adresse>();

  constructor() { }

  ngOnInit(): void {}
  
  ngOnChanges(){
    if(this.adresses != undefined) {
      this.dataSourceMatTable.data = this.adresses;
    } 
  }

  getLignes(adresse:Adresse){
    return (adresse.ligne1 + ' ' + adresse.ligne2+ ' ' + adresse.ligne3).trim()
  }

  onCreer(){
    const adresseACreer : Adresse = {...AdresseInit,'action':CST_ACTION_ADD}
    this.adresseEditEvent.next(adresseACreer)
  }
  
  onEditer(adresse:Adresse){
    this.adresseEditEvent.next(adresse)
  }

  onSupprimer(adresse:Adresse){
    adresse.action = CST_ACTION_REMOVE
    this.adresseEditEvent.next(adresse)
  }

}
