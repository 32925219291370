import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import {  Suspension } from '../../interfaces/suspension';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { map } from 'rxjs/operators';
import { CST_ACTION_MODIFY, CST_ACTION_REMOVE } from 'src/app/interfaces/constantes';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTableResponsiveLargeDirective } from '../../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';
import { MatCardModule } from '@angular/material/card';
import { NgIf, AsyncPipe, DatePipe } from '@angular/common';


@Component({
    selector: 'app-table-suspension',
    templateUrl: './table-suspension.component.html',
    styleUrls: ['./table-suspension.component.scss'],
    standalone: true,
    imports: [NgIf, MatCardModule, MatTableModule, MatTableResponsiveLargeDirective, MatButtonModule, MatIconModule, MatTooltipModule, MatPaginatorModule, AsyncPipe, DatePipe]
})
export class TableSuspensionComponent implements OnInit {

  private paginator!:MatPaginator
  dataSource      = new MatTableDataSource<Suspension>();
  @ViewChild(MatPaginator, { static: false }) set matPaginator(mp:MatPaginator){
    this.paginator = mp
    this.dataSource.paginator = mp
  }
  @ViewChild(MatSort) sort: MatSort | null = null;
 
  @Input() suspension$!         : Observable<Suspension[]>
  @Output() suspensionEditEvent = new EventEmitter<Suspension>();
  
  displayedColumns            : string[] = ['statut' ,'habilitation','contact' ,'raison_sociale','ville' ,'siren','date', 'actions']

  suspensionAsMatTableDataSource$!:Observable<MatTableDataSource<Suspension>> 

  constructor() { }

  ngOnInit(): void {
    this.suspensionAsMatTableDataSource$ = this.suspension$.pipe(
      map((suspensions:Suspension[])=>{
        this.dataSource.data       = suspensions;
        this.dataSource.paginator  = this.paginator;
        return this.dataSource;
      })
    )
  }
  
  onEditer(suspension:Suspension){
    suspension.action = CST_ACTION_MODIFY
    this.suspensionEditEvent.next(suspension)
  }
  
  onSupprimer(suspension:Suspension){
    suspension.action = CST_ACTION_REMOVE
    this.suspensionEditEvent.next(suspension)
  }

  onCreer(){
    // const suspensionACreer:Suspension = {'action':CST_ACTION_ADD}
    // this.suspensionEditEvent.next(suspensionACreer)
  }

}
