<div class="param-container">
  <div class= "param-titre">{{paramVal.utiParam?.utl_prm_nom}}</div>
  <div class="param-note">{{ paramVal.utiParam!.utl_prm_note }}</div>
  <form [formGroup]="paramForm">
    <div class="div-container-left">
      <mat-slide-toggle 
        (change)    ="toggleParam(paramVal, $event)"
        [checked]   ="paramVal.isActivated"
        color       = "primary">
        Activer/Désactiver
      </mat-slide-toggle>
  
      <mat-form-field appearance="fill" *ngIf="paramVal.isActivated">
        <mat-label>{{ getPlaceHoler(paramVal.utiParam?.utl_prm_code) }}</mat-label>
        <input matInput [placeholder]="getPlaceHoler(paramVal.utiParam?.utl_prm_code)" type="email" formControlName="valeur"> 
        <mat-error *ngIf="paramForm.get('valeur')?.hasError('required')">L'adresse email est obligatoire</mat-error>
        <mat-error *ngIf="paramForm.get('valeur')?.hasError('checkValidatorMail')">{{ paramForm.get('valeur')?.errors?.checkValidatorMail }}</mat-error>
      </mat-form-field>

      <button mat-raised-button color="primary" (click)="handleButtonClick(paramVal)" [disabled]="paramForm.invalid">
        Enregistrer
      </button>
    </div>
  </form>
</div>
