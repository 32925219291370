import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import * as saveAs from 'file-saver';
import { Etiquette, Sequence } from 'src/app/interfaces/adela/etiquette';
import { Adresse, AdresseInit } from 'src/app/interfaces/adresse/adresse';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ApiAdelaService } from 'src/app/services/api-adela.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { CarteAdresseComponent } from '../../templates/cards/carte-adresse/carte-adresse.component';
import { EntiteSearchComponent } from '../../templates/entite-search/entite-search.component';

@Component({
  selector: 'app-etiquette',
  standalone: true,
  imports: [CommonModule,EntiteSearchComponent,CarteAdresseComponent,MatButtonModule,MatFormFieldModule
          ,MatInputModule,FormsModule,MatIconModule,ReactiveFormsModule,MatCardModule,MatProgressSpinnerModule],
  templateUrl: './etiquette.component.html',
  styleUrls: ['./etiquette.component.scss']
})
export class EtiquetteComponent {

  apiAdela          = inject(ApiAdelaService)
  toast             = inject(PersoSnackbarService)
  dialog            = inject(MatDialog)

  searching         : boolean   = false
  adresseEntite     : Adresse   = AdresseInit
  sequence!         : Sequence
  waitingSave       : boolean = false
  waitingPrint      : boolean = false
  nbrEtiquettes     = new FormControl(1,[Validators.required,Validators.min(1)])
  copie             = new FormControl(1,[Validators.required,Validators.min(1)])
  min               = new FormControl(0,[Validators.required,Validators.min(1)])
  max               = new FormControl(0,[Validators.required,Validators.min(1)])
  entiteSelected   : Entite|null =null

  searchingEntite(){
    this.searching = true
  }

  setEntite(entite:Entite|null){
    this.searching = false
    if (entite) {
      this.entiteSelected = entite
      this.adresseEntite = {'ligne1':entite.adresse,'ligne2':entite.adresse_cplt, 'ligne3':entite.adresse_ligne3,'cp':entite.code_postal,'ville':entite.ville,'nom':entite.raison_sociale}
    }
  }

  ngOnInit(){
    this.getSequence()
  }

  getSequence(){
    this.apiAdela.getSequence().subscribe(
      (sequence:Sequence)=>{
        this.setSequence(sequence)
    }
      
    )
  }
  setSequence(sequence:Sequence){
    this.sequence = sequence
    this.min.setValue(this.sequence.min)
    this.max.setValue(this.sequence.max)
  }
  imprimer(){
    this.waitingPrint = true
    const etiquette:Etiquette={'id_entite':this.entiteSelected!.id!
                                ,'numero':this.sequence.next.toString()
                                ,'id':0
                                ,'date':new Date()
                                ,'entite':this.entiteSelected!}

    this.apiAdela.postEtiquette(etiquette, this.nbrEtiquettes.value!, this.copie.value!)
      .subscribe(
        (data: any) => {     
          saveAs(data, `etiquettes` + this.entiteSelected?.raison_sociale + `.pdf`);      
          this.toast.showInfo("Étiquette créée.");
          this.getSequence()
          this.waitingPrint = false
        },
        (err: any) => { 
          console.log(err);
          this.waitingPrint = false
        }
      );       
  }

  enregistre(){
    this.waitingSave = true
    const sequenceUpdate : Sequence={...this.sequence,'min':this.min.value!,'max':this.max.value!}
    this.apiAdela.putSequence(sequenceUpdate).subscribe(
      (sequence:Sequence)=>{        
        this.setSequence(sequence)
        this.waitingSave  = false
      }
      ,(error)=>{
        this.waitingSave = false
        console.log(error)
      }
    )
  }
}
