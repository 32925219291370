import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { HistoriqueDossier } from 'src/app/interfaces/dossier/historique-dossier';
import { ApiAdelaService } from 'src/app/services/api-adela.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { NgStyle, DatePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatTableResponsiveDirective } from '../../../directive/mat-table-responsive/mat-table-responsive.directive';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
    selector: 'app-dialog-historique-dossier',
    templateUrl: './dialog-historique-dossier.component.html',
    styleUrls: ['./dialog-historique-dossier.component.scss'],
    standalone: true,
    imports: [MatDialogModule, MatTableModule, MatTableResponsiveDirective, MatButtonModule, NgStyle, MatPaginatorModule, DatePipe]
})
export class DialogHistoriqueDossierComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;
  
  id: number = 0;
  historiques: HistoriqueDossier[] = [];

  paginatorPageSizeOptions: Array<number> = [];

  // Tableau des dossiers
  displayedColumns: string[] = ['date', 'etat', 'description'];  
  dataSource: MatTableDataSource<HistoriqueDossier> = new MatTableDataSource<HistoriqueDossier>();
  
  // gestion de la taille écran
  innerWidth: any;

  constructor(private apiAdela: ApiAdelaService
            , private toast: PersoSnackbarService) { }

  ngOnInit(): void {   
    this.chargeHistoriqueDossier();   
    this.innerWidth = window.innerWidth;
    if(this.dataSource != undefined) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort      = this.sort;
    }

    if(this.isMobileFormat()) {
      this.paginatorPageSizeOptions = [2, 4, 10];        
    } else {
      this.paginatorPageSizeOptions = [5, 10, 25];
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort      = this.sort;
  }

  public isMobileFormat() {
    // return true;
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 640) {
      return true;
    } else {
      return false;
    }
  }

  public getColorByLibelle(code: string) {
    switch(code) {
      case this.apiAdela.CONST_LIB_ETAT_ADELA_1_Depose:
        return '#e48213';
        break;
      case this.apiAdela.CONST_LIB_ETAT_ADELA_2_Valide:				        
        return '#04970E';
        break;
      case this.apiAdela.CONST_LIB_ETAT_ADELA_3_EnAttenteEchantillon:	
        return 'rgb(39,58,130)';
        break;
      case this.apiAdela.CONST_LIB_ETAT_ADELA_4_EnCoursAnalyse:		    
        return '#e48213';
        break;
      case this.apiAdela.CONST_LIB_ETAT_ADELA_5_EnCoursDiagnostic:	  
        return '#e48213';
        break;
      case this.apiAdela.CONST_LIB_ETAT_ADELA_6_Cloture:				      
        return 'rgb(39,58,130)';
        break;
      case this.apiAdela.CONST_LIB_ETAT_ADELA_X_Refuse:		            
        return '#f44336';
        break;	
      default:
        return 'rgb(39,58,130)';
        break;
    }
  }


  public chargeHistoriqueDossier(){
    if(this.id > 0) {
      this.apiAdela.getHistoriqueDossier(this.id)
      .subscribe(
        (data: HistoriqueDossier[] ) => {
          this.historiques = data;
          this.dataSource.data = this.historiques;    
        },
        err => {         
          console.error('/!\ err: ' + err.message); 
        }
      );
    } else {
        this.toast.showInfo("Une erreur est survenue. Aucun historique n'est disponible");
    }     
  }
}
