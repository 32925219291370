
<mat-card appearance="outlined">
    <mat-card-title>
            <mat-icon color="primary">description</mat-icon>&nbsp; Journaux des fiches services externes
    </mat-card-title>
    
    <mat-card-content  class=".mat-card-content">
            <mat-label *ngIf="nb_logs == 0 || logs_fiche == undefined">
                  Il n'y a pas de logs de fiche service externe.
            </mat-label>
            <div *ngIf="logs_fiche != undefined">
                  <mat-accordion *ngFor="let log of obs$ | async, index as indice">                
                        <mat-expansion-panel hideToggle="false">
                                <mat-expansion-panel-header style="padding-top: 4px !important; padding-bottom: 4px !important;">
                                    <mat-panel-title>                                    
                                          <mat-label class="grey-icon">{{ pageIndex * pageSize + indice + 1 }}. </mat-label>
                                          <mat-label>{{ log.date | date: 'dd/MM/yyyy HH:mm:ss' }}</mat-label>  
                                          <mat-label style="padding-left: 20px;">{{ log.utilisateur.nom }} {{log.utilisateur.prenom }}</mat-label>                                                                                                                                                       
                                    </mat-panel-title>      
                                    
                                    <mat-panel-description class="panel-description">                                                                                                                                                                               
                                          <div class="panel-description-entite-details">
                                                <mat-label>{{ log.service_externe.libelle }}</mat-label>
                                          </div>
                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                        
                                <div class="grid-container">
                                    <mat-label for="avant_modification" class="grid-item">Avant modification</mat-label>
                                    <mat-label for="apres_modification" class="grid-item">Après modification</mat-label>

                                    <mat-form-field>
                                        <mat-label for="avant_libelle">Libellé</mat-label>
                                        <input matInput type="text" placeholder="Libellé" name="avant_libelle"
                                            value="{{ log.avant_libelle }}" [disabled]="true">
                                    </mat-form-field>
                                    <mat-form-field style="margin-left: 10px;">
                                        <mat-label for="apres_libelle">Libellé</mat-label>
                                        <input matInput type="text" placeholder="Libellé" name="apres_libelle"
                                            value="{{ log.apres_libelle }}" [disabled]="true">
                                    </mat-form-field>

                                    <mat-form-field>
                                        <mat-label for="avant_url">URL</mat-label>
                                        <input matInput type="text" placeholder="URL" name="avant_url"
                                            value="{{ log.avant_url }}" [disabled]="true">
                                    </mat-form-field>
                                    <mat-form-field style="margin-left: 10px;">
                                        <mat-label for="apres_url">URL</mat-label>
                                        <input matInput type="text" placeholder="URL" name="apres_url"
                                            value="{{ log.apres_url }}" [disabled]="true">
                                    </mat-form-field>

                                    <mat-form-field>
                                        <mat-label for="avant_utilisateur">Utilisateur</mat-label>
                                        <input matInput type="text" placeholder="Utilisateur" name="avant_utilisateur"
                                            value="{{ log.avant_utilisateur }}" [disabled]="true">
                                    </mat-form-field>
                                    <mat-form-field style="margin-left: 10px;">
                                        <mat-label for="apres_utilisateur">Utilisateur</mat-label>
                                        <input matInput type="text" placeholder="Utilisateur" name="apres_utilisateur"
                                            value="{{ log.apres_utilisateur }}" [disabled]="true">
                                    </mat-form-field>

                                    <mat-form-field>
                                        <mat-label for="avant_motdepasse">Mot de passe</mat-label>
                                        <input matInput type="text" placeholder="Mot de passe" name="avant_motdepasse"
                                            value="{{ log.avant_motdepasse }}" [disabled]="true">
                                    </mat-form-field>
                                    <mat-form-field style="margin-left: 10px;">
                                        <mat-label for="apres_motdepasse">Mot de passe</mat-label>
                                        <input matInput type="text" placeholder="Mot de passe" name="apres_motdepasse"
                                            value="{{ log.apres_motdepasse }}" [disabled]="true">
                                    </mat-form-field>

                                    <mat-form-field>
                                        <mat-label for="avant_service">Service</mat-label>
                                        <input matInput type="text" placeholder="Service" name="avant_service"
                                            value="{{ log.avant_service.libelle }}" [disabled]="true">
                                    </mat-form-field>                               
                                    <mat-form-field style="margin-left: 10px;">
                                        <mat-label for="apres_service">Service</mat-label>
                                        <input matInput type="text" placeholder="Service" name="apres_service"
                                            value="{{ log.apres_service.libelle }}" [disabled]="true">
                                    </mat-form-field>

                                    <mat-form-field>
                                        <mat-label for="avant_actif">                                          
                                                Service externe Actif / Inactif
                                        </mat-label>
                                        <div style="display: flex;">
                                                <mat-icon color="primary" *ngIf="log.avant_actif">done</mat-icon>
                                                <mat-icon color="warn" *ngIf="!log.avant_actif">close</mat-icon>
                                                <input matInput type="text" placeholder="Service externe actif / Inactif" name="srx_actif"
                                                    value="{{ log.avant_actif ? 'Actif' : 'Inactif'}}" [disabled]="true">
                                        </div>                                    
                                    </mat-form-field>
                                    <mat-form-field  style="margin-left: 10px;">
                                        <mat-label for="apres_actif">                                          
                                                Service externe Actif / Inactif
                                        </mat-label>
                                        <div style="display: flex;">
                                                <mat-icon color="primary" *ngIf="log.apres_actif">done</mat-icon>
                                                <mat-icon color="warn" *ngIf="!log.apres_actif">close</mat-icon>
                                                <input matInput type="text" placeholder="Service externe actif / Inactif" name="srx_actif"
                                                    value="{{ log.apres_actif ? 'Actif' : 'Inactif'}}" [disabled]="true">
                                        </div>                                    
                                    </mat-form-field>                                    
                                </div>                                                                                                                                                           
                          </mat-expansion-panel>
                    </mat-accordion>
                  <mat-paginator *ngIf="nb_logs != 0 && logs_fiche != undefined" [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons (page)="onChangePaginator($event)"></mat-paginator>
            </div>            
    </mat-card-content>
</mat-card>