<mat-card appearance="outlined">
    <mat-card-title>{{ ELEMENT_DATA.entite.raison_sociale }}</mat-card-title>

    <mat-card-content  class=".mat-card-content">         
        <!-- ADVANCED PIE CHART - REPARTITION DES MESSAGES CONCENTRATEUR DE L'ENTITE -->   
        <app-repartion-msg-concentrateur 
            [ELEMENT_DATA]="ELEMENT_DATA.stats_repartition_msg">
        </app-repartion-msg-concentrateur>

        <!-- TABLE - REPARTITION DES MESSAGES CONCENTRATEUR DES EXPERTS DE L'ENTITE -->
        <app-repartition-msg-concentrateur-expert
            [ELEMENT_DATA]="ELEMENT_DATA.stats_reparition_msg_expert">
        </app-repartition-msg-concentrateur-expert> 

        <mat-expansion-panel [(expanded)]="expandPannel">

            <mat-expansion-panel-header>
                <mat-panel-title class="label-accent">
                    <mat-icon>analytics</mat-icon>Détails des véhicules endommagés ...
                </mat-panel-title>
            </mat-expansion-panel-header>
            
            <div class="listeStatsEntite">
                <!-- PIE-CHART - REPARTITION DES VGE/VEI -->
                <app-repartition-vge-vei class="cardStatsEntite"
                    [ELEMENT_DATA_TOTAL_VEI]="ELEMENT_DATA.total_vei" 
                    [ELEMENT_DATA_TOTAL_VGE]="ELEMENT_DATA.total_vge">
                </app-repartition-vge-vei>

                <!-- PIE-CHART - REPARTITION DES VE OUI/NON -->
                <app-repartition-ve class="cardStatsEntite"
                    [ELEMENT_DATA_TOTAL_VE_OUI]="ELEMENT_DATA.total_ve_oui" 
                    [ELEMENT_DATA_TOTAL_VE_NON]="ELEMENT_DATA.total_ve_non">
                </app-repartition-ve>
            </div>            

            <!-- HORIZONTAL-BAR-CHART-NORMALIZED - REPARTITION DES CRITERES DE DANGEROSITE -->
            <app-repartition-dangerosite
                [ELEMENT_DATA_TOTAL_CA3]="ELEMENT_DATA.total_ca3" 
                [ELEMENT_DATA_TOTAL_LS3]="ELEMENT_DATA.total_ls3" 
                [ELEMENT_DATA_TOTAL_DI3]="ELEMENT_DATA.total_di3" 
                [ELEMENT_DATA_TOTAL_SP4]="ELEMENT_DATA.total_sp4"
                [ELEMENT_DATA_TOTAL_EN5]="ELEMENT_DATA.total_en5">
            </app-repartition-dangerosite>

        </mat-expansion-panel>       
    </mat-card-content>
</mat-card>