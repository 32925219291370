
import { NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { TypeEntite } from 'src/app/interfaces/profil-user/type-entite';
import { RelationUtilisateurEntite } from 'src/app/interfaces/utilisateur-entite/relation-utilisateur-entite';
import { ProtectExtranetModificationInformationEntiteGuard } from 'src/app/security/protect-extranet-modification-information-entite.guard';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { ApiRelUtilisateurEntiteService } from 'src/app/services/api-rel-utilisateur-entite.service';
import { TelephoneDirective } from '../../../directive/telephone.directive';
import { ListeSuccursaleComponent } from '../liste-succursale/liste-succursale.component';
import { CarteAdresseComponent } from '../../templates/cards/carte-adresse/carte-adresse.component';
import { PhoneFormatPipe } from 'src/app/pipe/phone-format.pipe';

@Component({
    selector: 'app-liste-entites',
    templateUrl: './liste-entites.component.html',
    styleUrls: ['./liste-entites.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatIconModule, NgIf, MatFormFieldModule, NgFor, MatExpansionModule, MatButtonModule
              , MatInputModule, TelephoneDirective, ListeSuccursaleComponent, CarteAdresseComponent
              , PhoneFormatPipe]
})

export class ListeEntitesComponent implements OnInit {

  CONST_NOM_TYPE_ENTITE_SUCCURSALE = 'Succursale' as const;

  userConnect               : ProfilUtilisateur = <ProfilUtilisateur>{};
  utl_id                    : number = 0;
  type_id_entite_principale : number = 0;
  isServiceFFEA             : boolean = false;

  listRelUtilisateurEntite  : Array<RelationUtilisateurEntite> = new Array<RelationUtilisateurEntite> ();
  listRelUltEntFiltered     : Array<RelationUtilisateurEntite> = new Array<RelationUtilisateurEntite> ();

  constructor(private RelUtilisateurEntite: ApiRelUtilisateurEntiteService, 
              private apiEntite: ApiEntiteService,
              private routed: ActivatedRoute, 
              private route: Router,
              private apiAuth: ApiAuthentificationService,
              private guardModifEnt: ProtectExtranetModificationInformationEntiteGuard,
              public _sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.utl_id = this.routed.snapshot.params['utl_id'];
    this.userConnect = this.apiAuth.userConnect;
    this.getDataEntite();
    this.isServiceFFEA = this.apiAuth.isServiceFFEA();
  }

  // Récupération de la ou des entites associés à l'utilisateur
  getDataEntite() {
    this.apiEntite.getAllTypeEntite()
    .subscribe((types_entite: TypeEntite[]) => {
      types_entite.find(typeEntite => {
        if(typeEntite.code === this.apiEntite.CONST_CODE_TYPE_ENTITE_EXPERT) {
         this.type_id_entite_principale = typeEntite.id;
        }
     });
    });

    this.RelUtilisateurEntite.getRelUtilisateurEntite(this.utl_id, 0)
    .subscribe(
      (data: RelationUtilisateurEntite[]) => {
        
        let addFiltered: boolean;
        this.listRelUtilisateurEntite   = data;

        // Détermine les succursales auxquelles l'utilisateur est rattaché, mais n'a pas de rattachement à l'entité mère.
        // Il faut dont afficher ces succursales comme étant des entités mères, sinon l'utilisateur ne pourra pas les voir dans sa liste d'entité associé

        for(let i=this.listRelUtilisateurEntite.length-1; i >= 0; i--) {
          addFiltered = true;

          if(this.listRelUtilisateurEntite[i].entite.typ_ent_nom == this.CONST_NOM_TYPE_ENTITE_SUCCURSALE) {

            for(let j=0; j < this.listRelUtilisateurEntite.length; j++) {
              
              if(this.listRelUtilisateurEntite[j].entite_id === this.listRelUtilisateurEntite[i].entite.id_entite_principale 
                && this.listRelUtilisateurEntite[j].est_active == true
                && this.listRelUtilisateurEntite[j].entite.EstInscrit == true) {
                addFiltered = false;
              }
            }                     
          }

          if(addFiltered) { this.listRelUltEntFiltered.push(this.listRelUtilisateurEntite[i]); }
        }

      }, 
      (err) => { console.log(err)});
  }

  public onAddSuccursaleToEntite(id_entite: any) {
    this.guardModifEnt.ent_id = id_entite;
    this.route.navigate(['/app/entite/'+id_entite+'/succursale', {data: "succursale"}]);    
  }

  openEntite( ent_id: any, $e:Event ) {
    this.guardModifEnt.ent_id = ent_id;
    this.route.navigate([ '/'+this.apiAuth.root+'/entite/'+ ent_id]);
    $e.stopPropagation();
  }

  getLabelRoleEntite(estDirigeant: boolean) {
    if(estDirigeant) {
      return 'Dirigeant·e'
    } else {
      return ''
    }
  }

  getSuccursale(id_entite: number) {
    let succursales:  any;
    let entite_mere: any;
    entite_mere = this.listRelUtilisateurEntite.find(rel => rel.entite_id == id_entite);

    if(entite_mere.est_dirigeant) {
      // Cas n°1 : l'expert est dirigeant de l'entité mère, on affiche toutes les succursales de l'entité
      succursales = this.listRelUtilisateurEntite.filter(rel => rel.entite.id_entite_principale == id_entite && rel.entite.typ_ent_nom == this.CONST_NOM_TYPE_ENTITE_SUCCURSALE && rel.entite.EstInscrit == true)
    } else {
      // Cas n°2 : l'expert n'est pas dirigeant de l'entité mère, on affiche les succursales uniquement actives et auquel il est rattaché
      succursales = this.listRelUtilisateurEntite.filter(rel => rel.entite.id_entite_principale == id_entite && rel.entite.typ_ent_nom == this.CONST_NOM_TYPE_ENTITE_SUCCURSALE && rel.entite.EstInscrit == true && rel.est_active == true)
    }

    return succursales;
  }
}
