import { Component, OnInit } from '@angular/core';
import { DossierADELA } from 'src/app/interfaces/adela/dossier-adela';
import { Commande, FiltreCommande } from 'src/app/interfaces/commande/commande';
import { ApiAdelaService } from 'src/app/services/api-adela.service';
import { ApiCommandeService} from 'src/app/services/api-commande.service';
import { FacturationListeAdelaComponent } from '../facturation-liste-adela/facturation-liste-adela.component';
import { FacturationListeComponent } from '../facturation-liste/facturation-liste.component';
import { FacturationFiltreComponent } from '../facturation-filtre/facturation-filtre.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';


@Component({
    selector: 'app-facturation-shell',
    templateUrl: './facturation-shell.component.html',
    styleUrls: ['./facturation-shell.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatTabsModule, FacturationFiltreComponent, FacturationListeComponent, FacturationListeAdelaComponent]
})
export class FacturationShellComponent implements OnInit {
  
  commandesAffichees  : Commande[]        = []
  dossiersAffiches    : DossierADELA[]    = []
  filtreDossier!      : FiltreCommande
  filtreCommande!     : FiltreCommande

  
  constructor(private apiCommandes: ApiCommandeService
              ,private apiAdela : ApiAdelaService
  ) { }

  ngOnInit(): void {
  }

  updateFilterCommandes(event:FiltreCommande){
    this.filtreCommande = event
    this.getCommandes()
  }

  updateFilterDossiers(event:FiltreCommande){
    this.filtreDossier = event
    this.getDossiers()
  }

  getDossiers(){
    this.apiAdela.getDossiersAFacturer(this.filtreDossier).subscribe(
      (dossiers)=>{
        this.dossiersAffiches = dossiers;
      },
      (error)=>console.log(error)
      
    )
  }

  getCommandes(){
    this.apiCommandes.getCommandesAFacturer(this.filtreCommande).subscribe(
      (commandes)=>{
        this.commandesAffichees = commandes;
      },
      (error)=>console.log(error)
      
    )
  }
  ngOnDestroy(){
    
  }
}
