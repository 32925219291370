import { Component, Input, OnInit } from '@angular/core';
import { PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { Observable, of } from 'rxjs';
import { LogRecherche } from 'src/app/interfaces/services-ffea/log-recherche';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor, AsyncPipe, DatePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-log-recherche-service-externe',
    templateUrl: './log-recherche-service-externe.component.html',
    styleUrls: ['./log-recherche-service-externe.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatIconModule, NgIf, MatFormFieldModule, NgFor, MatExpansionModule, MatInputModule, MatPaginatorModule, AsyncPipe, DatePipe]
})
export class LogRechercheServiceExterneComponent implements OnInit {
  length                        : number = 0;
  pageIndex                     : number = 0;
  pageSize                      : number = 5;
  previousPageIndex?            : number;
  pageSizeOptions               : Array<number> = [5,10,25];
  
  obs$                          : Observable<LogRecherche[]> = new Observable<LogRecherche[]>();
  nb_logs                       : number = 0;
  
  @Input() logs_recherche           : Array<LogRecherche> = new Array<LogRecherche>();

  constructor() { }

  ngOnInit(): void {
    // On force le reset des valeurs par défaut, car lorsqu'on recharge le component log-recherche-service-externe,    
    // des valeurs peuvent déjà être configuré par une "session" précédente (session dans le sens appel depuis une autre page)
    // mais ne pas être réinitialisé car le component ne refait pas la déclaration des variables puisqu'il a déjà été initialisé par la "session" précédente  
    this.length                 = 0;
    this.pageIndex              = 0;
    this.pageSize               = 5;
    this.obs$                   = new Observable<LogRecherche[]>();
    this.nb_logs                = 0;    
  }

  ngOnChanges(): void {
    if(this.logs_recherche != undefined) {
      this.nb_logs  = this.logs_recherche.length;  
      this.obs$     = of(this.logs_recherche.slice(this.pageIndex*this.pageSize, this.pageIndex*this.pageSize+this.pageSize));
      this.length   = this.logs_recherche.length;
    }    
  }

  onChangePaginator(event: PageEvent) {
    this.length             = event.length;
    this.pageIndex          = event.pageIndex;
    this.pageSize           = event.pageSize;
    this.previousPageIndex  = event.previousPageIndex;

    // Update des données à afficher
    this.obs$               = of(this.logs_recherche.slice(this.pageIndex*this.pageSize, this.pageIndex*this.pageSize+this.pageSize));
  }

}
