import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppUpdateService } from 'src/app/services/app-update.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-app-update',
    templateUrl: './app-update.component.html',
    styleUrls: ['./app-update.component.scss'],
    standalone: true,
    imports: [NgIf, MatToolbarModule, MatButtonModule, MatIconModule, AsyncPipe]
})
export class AppUpdateComponent implements OnInit {

  public hasNewUpdate$: Observable<boolean>;

  constructor(private appUpdateSvc: AppUpdateService) {
    this.hasNewUpdate$ = this.appUpdateSvc.newVersionFound$.pipe(
      filter((v: boolean) => v === true)
    );
   }

  ngOnInit(): void {
  }

  public updateApp(): void {
    this.appUpdateSvc.getUpdate();
  }

}
