export interface Reference{
    numeroDossier     : string
  , dateSinistre      : Date|null
  , natureEvenement   : string
  , natureAdverse     : string
}

export interface Information{
    type            : string,
    dateEffet       : Date|null
    idEntite        : number
}

export interface Technique{
    immatriculation   : string
  , vin               : string
  , numeroFormule     : string
  , dateMec           : Date|null
  , dateCi            : Date|null
  , typeMine          : string
  , marque            : string
  , modele            : string
  , genre             : string
  , carrosserie       : string
  , energie           : string
  , kilometrage       : number
  , puissanceFiscale  : number
  , puissanceReelle   : number
  , couleur           : string
}

export interface Expertise{
    ensemble            : boolean
    , valeurTotaleHt    : number
    , valeurTotaleTtc   : number
    , totalHt           : number
    , piecesHt          : number
    ,  usureAvg            : number
    , usureAvd            : number
    , usureArg            : number
    , usureArd            : number
    , usureArg2           : number
    , usureArd2           : number
    , chocDessus          : boolean
    , chocSoubassement    : boolean
    , chocTrainsRoulants  : boolean
    , chocMoteurBvPonts   : boolean
    , chocHabitacle       : boolean
    , chocAvant           : boolean
    , chocAvantG          : boolean
    , chocAvantD          : boolean
    , chocLateralG        : boolean
    , chocLateralD        : boolean
    , chocArriereG        : boolean
    , chocArriereD        : boolean
    , chocArriere         : boolean
  }

export interface Conclusion{
    dangereux     : number
  , ecoReparable  : number
  , techReparable : number
  , ca3           : boolean
  , d3            : boolean
  , ls3           : boolean
  , sp4           : boolean
  , en5           : boolean
}

export interface RDEAFile{
        reference   : Reference
    ,   information : Information
    ,   technique   : Technique
    ,   expertise   : Expertise
    ,   conclusion  : Conclusion
}

export const ReferenceInit:Reference={
    'dateSinistre'    : null
  , 'natureAdverse'   : ''
  , 'natureEvenement' : ''
  , 'numeroDossier'   : ''
}

export const InformationInit:Information={
    'dateEffet' : null
  , 'idEntite'  : 0
  , 'type'      : ''
}

export const TechniqueInit:Technique={
    'carrosserie'       : ''
  , 'couleur'           : ''
  , 'dateCi'            : null
  , 'dateMec'           : null
  , 'energie'           : ''
  , 'genre'             : ''
  , 'immatriculation'   :''
  , 'kilometrage'       : 0
  , 'marque'            : ''
  , 'modele'            : ''
  , 'numeroFormule'     : ''
  , 'puissanceFiscale'  : 0
  , 'puissanceReelle'   : 0
  , 'typeMine'          : ''
  , 'vin'               : ''
}

export const ExpertiseInit:Expertise={
    'chocArriere'       : false
  , 'chocArriereD'      : false
  , 'chocArriereG'      : false
  , 'chocAvant'         : false
  , 'chocAvantD'        : false
  , 'chocAvantG'        : false
  , 'chocDessus'        : false
  , 'chocHabitacle'     : false
  , 'chocLateralD'      : false
  , 'chocLateralG'      : false
  , 'chocMoteurBvPonts' : false
  , 'chocSoubassement'  : false
  , 'chocTrainsRoulants': false
  , 'ensemble'          : false
  , 'piecesHt'          : 0
  , 'usureArd'          : 0
  , 'usureArd2'         : 0
  , 'usureArg'          : 0
  , 'usureArg2'         : 0
  , 'usureAvd'          : 0
  , 'usureAvg'          : 0
  , 'valeurTotaleHt'    : 0
  , 'valeurTotaleTtc'   : 0
  , 'totalHt'           : 0
}

export const ConclusionInit : Conclusion={
  'ca3'             : false
  ,'d3'             : false
  ,'dangereux'      : 0
  ,'ecoReparable'   : 0
  ,'ls3'            : false
  ,'sp4'            : false
  ,'en5'            : false
  ,'techReparable'  : 0
}

export const RDEAInit:RDEAFile = {
    'conclusion'  : ConclusionInit
  , 'expertise'   : ExpertiseInit
  , 'information' : InformationInit
  , 'reference'   : ReferenceInit
  , 'technique'   : TechniqueInit
}