import { NgFor, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ActivatedRoute } from '@angular/router';
import { InvitationCollaborateur } from 'src/app/interfaces/invitation-collaborateur/invitation-collaborateur';
import { RelationUtilisateurEntite } from 'src/app/interfaces/utilisateur-entite/relation-utilisateur-entite';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { ApiInvitationCollaborateurService } from 'src/app/services/api-invitation-collaborateur.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { TitleCaseDirective } from '../../../directive/title-case.directive';
import { GroupeService } from '../../groupe/data-access/groupe.service';
import { Groupe } from '../../groupe/groupe-interface';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ShellComponent } from '../liste/ui/shell/shell.component';

@Component({
    selector: 'app-invitation-collaborateur',
    templateUrl: './invitation-collaborateur.component.html',
    styleUrls: ['./invitation-collaborateur.component.scss'],
    standalone: true,
    imports: [MatSlideToggleModule, NgFor, NgIf, ReactiveFormsModule, MatCardModule, MatIconModule, MatFormFieldModule
              , MatInputModule, TitleCaseDirective, MatSelectModule, MatOptionModule, MatButtonModule
              , MatProgressSpinnerModule, ShellComponent]
})
export class InvitationCollaborateurComponent implements OnInit {
  @Input() est_dirigeant_principal  : boolean = false;  
  @Input() id_entite_succursale     : number | undefined;
  id_entite                         : number = 0;
  groupes                           : Groupe[] = [];

  //@Input() invitationCollaborateur  : InvitationCollaborateur = <InvitationCollaborateur>{};
  private _invitationCollaborateur  : InvitationCollaborateur = <InvitationCollaborateur>{};

  @Input()
  set invitationCollaborateur(invitation: InvitationCollaborateur | undefined) {
    if(invitation != undefined) {
      this._invitationCollaborateur = invitation;
    }
  };
  @Input() RelUtilisateurEntite    : RelationUtilisateurEntite = <RelationUtilisateurEntite>{};
  
  estDirigeant            : boolean = false;
  estAdministrateur       : boolean = false;
  isWaiting               : boolean = false;
  entite                 : Entite|null = null

  get email()     { return this.invitationForm.get('email'); }
  get nom()       { return this.invitationForm.get('nom'); }
  get prenom()    { return this.invitationForm.get('prenom'); }
  get groupe()    { return this.invitationForm.get('groupe'); }
  get dirigeant() { return this.invitationForm.get('dirigeant'); }
  get inv_succursales() { return this.invitationForm.get('invSuccursales'); }

  invitationForm = this.fBuilder.group({
    nom         : new FormControl('',[Validators.required, this.verificationFormatNom()]),
    prenom      : new FormControl('', [Validators.required, this.verificationFormatPrenom()]),
    email       : new FormControl('', [Validators.required]),
    groupe      : new FormControl<number | null>(null, Validators.required),
    dirigeant   : new FormControl<boolean>(false,[]),
    invSuccursales : new FormControl<boolean>(false,[])
  });

  constructor(private fBuilder            : FormBuilder
            , private toast               : PersoSnackbarService
            , private route               : ActivatedRoute
            , private apiInvitation       : ApiInvitationCollaborateurService
            , public apiAuthentification : ApiAuthentificationService
            , private apiGroupe           : GroupeService
            , private apiEntite           : ApiEntiteService) { 
              
              this.route.params.subscribe(val => {
                this.ngOnInit();
              });
            }

  ngOnInit(): void {
    // On force le reset des valeurs par défaut, car lorsqu'on recharge le component liste-utilisateur,    
    // des valeurs peuvent déjà être configuré par une "session" précédente (session dans le sens appel depuis une autre page)
    // mais ne pas être réinitialisé car le component ne refait pas la déclaration des variables puisqu'il a déjà été initialisé par la "session" précédente
    this.id_entite               = 0;
    this._invitationCollaborateur = <InvitationCollaborateur>{};
    this.estDirigeant            = false;
    this.estAdministrateur       = false;

    // Affectation des valeurs par défaut  
    // Cas visualisation détails entité mère / succursale : on récupère l'id entité passé en url    
    this.id_entite          = this.route.snapshot.params['id'];
    this.apiEntite.getEntiteById(this.id_entite).subscribe(
      (entite:Entite[])=>{
        entite.length===1? this.entite = entite[0] : this.entite = null
      }
    )
    this.estAdministrateur  = this.apiAuthentification.userConnect.droits_utilisateur.est_admin;    
  }

  ngOnChanges(): void {
    let succursale : boolean = false;

    if(this.id_entite_succursale != undefined && this.id_entite_succursale > 0) {
      // Cas création d'une succursale : on récupère l'id entité succursale passé en input
      this.id_entite          = this.id_entite_succursale;
      succursale = true;
    }

    this.apiGroupe.getGroupesParCodeEntite(succursale? this.apiEntite.CONST_CODE_TYPE_ENTITE_PRESTATAIRE: this.apiEntite.CONST_CODE_TYPE_ENTITE_EXPERT)
    .subscribe(data => {
        this.groupes = data;
        
        this.invitationForm.patchValue({
          groupe    : this.GetIDGroupeParDefaut()
        });
      })

    if(this.RelUtilisateurEntite != undefined) {
      if(this.RelUtilisateurEntite.est_dirigeant == true
        && this.RelUtilisateurEntite.est_active == true  
        && this.RelUtilisateurEntite.entite_id == this.id_entite 
        && this.RelUtilisateurEntite.utilisateur_id == this.apiAuthentification.userConnect.id) {
        this.estDirigeant = true;
      }
    }

    // Input invitationCollaborateur a été modifié
    // On pré-saisie le formulaire avec les données reçues
    if(this._invitationCollaborateur.nom != undefined
        && this._invitationCollaborateur.prenom != undefined
        && this._invitationCollaborateur.email != undefined
        && this._invitationCollaborateur.estDirigeant != undefined) {
      this.invitationForm.patchValue({
        nom: this._invitationCollaborateur.nom,
        prenom: this._invitationCollaborateur.prenom,
        email: this._invitationCollaborateur.email,
        // estDirigeant:this._invitationCollaborateur.estDirigeant
      });            
      this.invitationForm.markAsDirty();
    }

  }

  inviterCollaborateur() {
    this.isWaiting = true;
    
    this._invitationCollaborateur.nom           = this.invitationForm.value.nom? this.invitationForm.value.nom : '';
    this._invitationCollaborateur.prenom        = this.invitationForm.value.prenom? this.invitationForm.value.prenom : '';
    this._invitationCollaborateur.email         = this.invitationForm.value.email? this.invitationForm.value.email : '';
    this._invitationCollaborateur.id_expediteur = this.apiAuthentification.userConnect.id;
    this._invitationCollaborateur.id_groupe     = this.invitationForm.value.groupe? this.invitationForm.value.groupe : 0;
    this._invitationCollaborateur.estDirigeant  = this.invitationForm.value.dirigeant? this.invitationForm.value.dirigeant : false ;
    this._invitationCollaborateur.invSuccursales  = this.invitationForm.value.invSuccursales? this.invitationForm.value.invSuccursales : false ;
        
    this.apiInvitation.postInviterCollaborateur(this._invitationCollaborateur, this.id_entite)
      .subscribe(data => {           
        
        // Réinitialisation des valeurs pour montrer qu'on peut envoyer plusieurs invitations à la suite               
        this._invitationCollaborateur.nom          = '';
        this._invitationCollaborateur.prenom       = '';
        this._invitationCollaborateur.email        = '';
        this._invitationCollaborateur.id_groupe    = 0;
        this._invitationCollaborateur.estDirigeant = false;
        this.invitationForm.patchValue({
          prenom    : '',
          nom       : '',
          email     : '',
          groupe    : this.GetIDGroupeParDefaut(),
          dirigeant : false,
          invSuccursales : false
        });  

      
        this.invitationForm.reset()

        this.toast.showInfo("Une invitation par mail a été envoyée.")
        this.isWaiting = false;
      },
      (err) => { this.toast.showError(err.message);console.log(err);
        this.isWaiting = false
      });   
  }

  /* VERFICATION FORMAT SAISIE */
  // Vérification du format du nom
  // Le nom doit faire entre 1 et 50 caractères avec uniquement des majuscules, tirets ou des espaces
  verificationFormatNom(): ValidatorFn {
    return (control:AbstractControl):ValidationErrors|null =>{
      let s_nom:    string  = control.value;
      let a_regex:  any     = /^[A-Z\-\ ]{1,50}$/;
      
      if (!a_regex.test(s_nom)) {      
        return { erreur: 'Le nom doit faire entre 1 et 50 caractères avec des tirets ou des espaces' }
      }
  
      return null;
    }
  }

 
  // Vérification du format du prénom
  // Le prénom doit faire entre 1 et 50 caractères avec une majuscule en première lettre et le reste en minuscule
  verificationFormatPrenom(): ValidatorFn {
    return (control:AbstractControl):ValidationErrors|null => {
      let s_prenom: string  = control.value;
      let a_regex:  any     = /^[A-Za-z\-\ ]{1,50}$/;
      
      if (!a_regex.test(s_prenom)) {      
        return { erreur: 'Le prénom doit faire entre 1 et 50 caractères avec des tirets ou des espaces' }
      }
  
      return null;

    }
  }

  GetIDGroupeParDefaut(): number|null {
    let groupe : Groupe|undefined;

    groupe = this.groupes.find( (groupe) => ( groupe.GroupeParDefaut == true ));

    return (groupe !=undefined ? groupe.id: null)
  }
}
