<mat-card appearance="outlined">
    <mat-card-header>
        <mat-card-title>{{commandes.length}} commande<span *ngIf="commandes.length >1">s</span> en attente de facturation</mat-card-title>
    </mat-card-header>
    <div class="container">
        <table mat-table matTableResponsiveLarge [dataSource]="dataSourceMatTable" class="table-primary" class="table-primary" matTableExporter #exporter="matTableExporter" [hiddenColumns]="[7]">                    
            
            <!-- Icon Column -->
            <ng-container matColumnDef="idCategorie">
                <th mat-header-cell *matHeaderCellDef style="text-align: left; padding-right: 16px;" class="premiere-colonne colonne-invisible">Service</th>
                <td mat-cell *matCellDef="let element" style="text-align: left;" class="colonne-invisible">
                    <mat-icon color="primary" svgIcon="{{ element.Categorie.libelle == 'Outil Diagnostic' ? 'home_repair_service' : 'add_shopping_cart' }}">
                        <span class="cdk-visually-hidden">{{ element.Categorie.libelle == 'Outil Diagnostic' ? 'Outil diagnostic' : 'Kits' }}</span>
                    </mat-icon>
                </td>
            </ng-container>
            
            <!-- Option Column -->
            <ng-container matColumnDef="option">
                <th mat-header-cell *matHeaderCellDef  class="colonne-invisible" style="padding-right: 8px; padding-left: 8px;">Option</th>
                <td mat-cell *matCellDef="let element" title="{{ element.option }}" 
                    [ngStyle]="{ 'color': apiCommande.getOptionColor(element.option) }" 
                    class="colonne-invisible">
                    {{ element.option }}
                </td>
            </ng-container>
            
            <!-- Référence Column -->
            <ng-container matColumnDef="numero">
                <th mat-header-cell *matHeaderCellDef  style="padding-left: 16px;">Référence</th>
                <td mat-cell *matCellDef="let element" class="commande">
                    <button mat-flat-button target="_blank" (click)="apiCommande.openCommandDetails( element.id )"
                    class="custom-button" style="text-decoration: underline;">{{element.numero}}</button>
                </td>
            </ng-container>

            <!-- Cabinet Column -->
            <ng-container matColumnDef="raison_sociale">
                <th mat-header-cell *matHeaderCellDef >Cabinet</th>
                <td mat-cell *matCellDef="let element" class="cabinet">{{element.raison_sociale}}</td>
            </ng-container>

            <!-- Expert Column -->
            <ng-container matColumnDef="expert">
                <th mat-header-cell *matHeaderCellDef >Expert</th>
                <td mat-cell *matCellDef="let element">{{element.nom+' '+element.prenom}}</td>
            </ng-container>

            <!-- Date Column -->
            <ng-container matColumnDef="date_creation">
                <th mat-header-cell *matHeaderCellDef  style="width: 92px;">Date</th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.date_creation >= '19000101'">{{element.date_creation | date: 'dd/MM/yyyy'}}</div>
                    <div *ngIf="element.date_creation < '19000101'">__/__/____</div>                            
                </td>
            </ng-container>

            <!-- Etat Column -->
            <ng-container matColumnDef="idEtat">
                <th mat-header-cell *matHeaderCellDef  class="etat-padding">État</th>
                <td mat-cell *matCellDef="let element" class="etat-padding">
                    <button mat-stroked-button class="button-etat button-width" color="primary"
                    (click)="apiCommande.openHistoCommande(element.id,element.numero)"
                    [ngStyle]="{ color: apiCommande.getEtatColor(element.Etat.code) }">{{ element.Etat.libelle }}</button>
                </td>
            </ng-container>

            <!-- Actions Column -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="derniere-colonne" style="text-align: center;"></th>
                <td mat-cell *matCellDef="let element" class="center">
                    <div *ngIf="element.estFacturee===true" class="factureSuccess">Commande facturée</div>
                    <button *ngIf="element.estFacturee===false && !element.estTraitement" mat-raised-button class="button-etat" color="primary" (click)="factureCommande(element)">
                        Facturer la commande
                    </button>
                    <mat-spinner *ngIf="element.estFacturee===false && element.estTraitement===true" style="margin: auto;" diameter="40"></mat-spinner>                                                        
                </td>
            </ng-container>
                    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>                    
        </table>

        <div class="div-container-between" style="align-items: center;background-color:white">
            <app-export-table [fileExportName]="'EXTRANET - Commandes'" [exporter]="exporter" [nb_ligne]="this.dataSourceMatTable.data.length"></app-export-table>
            <mat-paginator [pageSizeOptions]="[10,25,50]" showFirstLastButtons  aria-label="Nombre de lignes visibles..."></mat-paginator>
        </div>                
    </div>
</mat-card>
