<mat-card appearance="outlined" class="mat-card-padding" *ngIf="typesSuspensionAsMatTableDataSource$ |async as typesSuspension">
    <div class="div-container-between">
        <mat-card-title>{{typesSuspension.data.length}} type<span *ngIf="typesSuspension.data.length >1">s</span> de suspension</mat-card-title>
        <button mat-icon-button class="button-etat" 
                color="primary" 
                (click)="onCreer()">
                <mat-icon   color="primary" 
                            matTooltip="Créer un type de suspension">note_add
                </mat-icon>
        </button>
    </div>

    <div class="container">
        <table  
                mat-table 
                matTableResponsiveLarge 
                [dataSource]    = "typesSuspension" 
                class           = "table-primary">
        
           <!-- Nom du type de suspension --> 
            <ng-container matColumnDef="nom">
                <th mat-header-cell *matHeaderCellDef> Nom </th>
                <td mat-cell *matCellDef="let typeSuspension" > {{ typeSuspension.nom }} </td> 
            </ng-container>
            
             <!-- Code du type de suspension --> 
             <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef> Code </th>
                <td mat-cell *matCellDef="let typeSuspension" > {{ typeSuspension.code }} </td> 
            </ng-container>

            
            <!-- Editer -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell 
                    *matHeaderCellDef 
                    class   = "derniere-colonne" 
                    style   = "text-align: right;">
                </th>
                
                <td mat-cell 
                    *matCellDef = "let typeSuspension" 
                    class       = "colonne-module" 
                    style       = "text-align: right;">
                    
                    <button mat-icon-button 
                            color   = "primary"
                            style   = "width: 48px; height: 48px;"
                            (click) = "onEditer(typeSuspension)">
                        
                            <mat-icon   color="primary" 
                                    matTooltip="Editer ce type de suspension">edit
                        </mat-icon>
                    </button>

                    <button mat-icon-button 
                            class   = "button-etat" 
                            color   = "primary"
                            style   = "width: 48px; height: 48px;"
                            (click)="onSupprimer(typeSuspension)"
                            >
                        <mat-icon   color="warn" 
                                    matTooltip="Supprimer ce type de suspension">
                                    delete
                        </mat-icon>
                    </button>

                </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator
            [pageSizeOptions]="[5,10,25]"
            showFirstLastButtons 
            aria-label="Nombre de lignes visibles...">
        </mat-paginator>
    </div>
</mat-card>


