import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Filtre } from '../interfaces/acquittement/filtre';
import { Suivi } from '../interfaces/acquittement/suivi';
import { map } from 'rxjs/operators';
import { ApiAuthentificationService } from './api-authentification.service';

@Injectable({
  providedIn: 'root'
})
export class ApiAcquittementService {

  CONST_OPERATION_1A: number = 1 as const;
  CONST_OPERATION_1B: number = 2 as const;
  CONST_OPERATION_1C: number = 3 as const;

  url: string = '/api';

  constructor(private http: HttpClient
              ,private apiAuthentification: ApiAuthentificationService
  ) { }

  public postSuivi(filtre: Filtre): Observable<Suivi[]> {    
    return this.http.post<Suivi[]>(this.url+"/acquittement", filtre)
  }
}
