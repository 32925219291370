import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { MatButtonModule } from '@angular/material/button';
import { MatTableResponsiveLargeDirective } from '../../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';
import { NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-table-service-externe',
    templateUrl: './table-service-externe.component.html',
    styleUrls: ['./table-service-externe.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatExpansionModule, MatIconModule, NgIf, MatTableModule, MatTableResponsiveLargeDirective, MatButtonModule, MatPaginatorModule]
})
export class TableServiceExterneComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;
  @Input() dataSource: any;
  
  dataSourceMatTable: MatTableDataSource<any> = new MatTableDataSource<any>();
  
  expandPannel        : boolean = true;
  profilUtilisateur   : ProfilUtilisateur = <ProfilUtilisateur>{};
  
  // Tableau des services externe
  displayedColumns    : Array<string> = ['srx_libelle', 'srv_libelle', 'srx_actif', 'actions'];


  constructor(private authUser: ApiAuthentificationService
            , private route: Router) { }

  ngOnInit(): void {
    if(this.dataSource != undefined) {
      this.dataSourceMatTable.data = this.dataSource;
    }

    this.profilUtilisateur = this.authUser.userConnect;
  }

  ngAfterViewInit() {
    this.dataSourceMatTable.paginator = this.paginator;
    this.dataSourceMatTable.sort = this.sort;
  }

  ngOnChanges() {
    // obligatoire
    if(this.dataSource != undefined) {
      this.dataSourceMatTable.data = this.dataSource;
    }     
  }

  public onClickEdit(srx_id: number): void {
    this.route.navigate(['/svc/admin/service-externe/', srx_id])
  }

}
