<mat-card>
    <mat-card-header>

    </mat-card-header>
    <mat-card-content>
        <div style="margin:auto;width: fit-content;">
            <button mat-raised-button (click)="searchingEntite()" color="primary">Rechercher une entite</button>
            <app-entite-search *ngIf="searching" (entiteEvent)="setEntite($event)"></app-entite-search>
            <app-carte-adresse [adresse]="adresseEntite" *ngIf="adresseEntite.ligne1!=''" [title]="'Entité sélectionnée'"></app-carte-adresse>
            <div *ngIf="sequence" style="font-size: 1.2em;margin:20px auto;width: fit-content;">
                <fieldset class="cadre">
                    <legend class="legend">Gestion de la séquence</legend>
                    <div class="div-container-left">
                        <mat-form-field style="max-width: 150px;">
                            <mat-label>Début de séquence</mat-label>
                            <input  type="number"
                            matInput
                            [formControl]   = "min"
                            >
                        </mat-form-field>
                        <mat-form-field style="max-width: 150px;">
                            <mat-label>Fin de séquence</mat-label>
                            <input  type="number"
                            matInput
                            [formControl]   = "max"
                            >
                        </mat-form-field>
                        <div>
                            <mat-spinner diameter="24" *ngIf="waitingSave"></mat-spinner>
                            <button 
                            *ngIf="!waitingSave"
                            mat-raised-button 
                            color       = "primary" 
                            [disabled]  = "min.invalid || max.invalid || min.value! >= max.value!"
                            (click)     = "enregistre()"
                            >
                            Enregistrer
                            <mat-icon>save</mat-icon>
                        </button>
                    </div>
                </div>
            </fieldset>
            <fieldset class="cadre">
                <legend class="legend">Impression des étiquettes</legend>
                <div class="div-container-left" style="margin:10px">
                    <div class="label">Premier numéro disponible :</div>
                    <div class="colorBlue">{{+sequence.next}}</div>
                </div>
                
                <div class="div-container-left" style="margin:10px">
                    <div class="label">Dernier numéro imprimé : </div>
                    <div class="colorBlue gras" *ngIf="+nbrEtiquettes.value!>0">{{+sequence.next -1 + +nbrEtiquettes.value!}}</div>
                </div>
                
                <div class="div-container-left" style="margin:10px">
                    <div class="label">Dernier numéro disponible : </div>
                    <div class="colorBlue">{{sequence.max}}</div>
                </div>
                
                <div class="div-container-left"  *ngIf="sequence">
                    <mat-form-field style="max-width: 150px;">
                        <mat-label>Nombre d'étiquettes</mat-label>
                        <input 
                        matInput 
                        [formControl]   = "nbrEtiquettes"
                        type            = "number" 
                        min             = 0
                        [max]           = "+sequence.max - +sequence.etiquette.numero"
                        >
                        <mat-error></mat-error>
                    </mat-form-field>
                    
                    <mat-form-field style="max-width: 150px;">
                        <mat-label>Nombre de copie</mat-label>
                        <input 
                            matInput 
                            [formControl]   = "copie"
                            type            = "number"  
                            min             = "1" 
                            max             = "3" 
                            step            = "1" 
                            required
                        >
                    </mat-form-field>
                    
                    <div>
                        <mat-spinner  *ngIf="waitingPrint" diameter="24"></mat-spinner>
                        <button 
                        *ngIf       ="!waitingPrint"
                        mat-raised-button 
                        color       = "primary" 
                        [disabled]  = "nbrEtiquettes.invalid || !(entiteSelected && entiteSelected.id!>0) || copie.invalid"
                        (click)     = "imprimer()"
                        >
                        Imprimer
                        <mat-icon>print</mat-icon>
                    </button>
                </div>
            </div>
        </fieldset>
            </div>
        </div>
    </mat-card-content>
</mat-card>