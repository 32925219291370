import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ActivatedRoute, Router } from '@angular/router';
import { TelephoneDirective } from 'src/app/directive/telephone.directive';
import { Adresse, AdresseInit } from 'src/app/interfaces/adresse/adresse';
import { Fournisseur } from 'src/app/interfaces/commande/fournisseur/fournisseur';
import { ApiFournisseurService } from 'src/app/services/api-fournisseur.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { CheckValidatorService } from 'src/app/services/tools/check-validator.service';
import { TelephoneDirective as TelephoneDirective_1 } from '../../../../directive/telephone.directive';
import { AdresseComponent } from '../../../templates/adresse/adresse.component';

@Component({
    selector: 'app-fiche-fournisseur',
    templateUrl: './fiche-fournisseur.component.html',
    styleUrls: ['./fiche-fournisseur.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, NgIf, TelephoneDirective_1, AdresseComponent, MatSlideToggleModule, MatButtonModule, MatIconModule, MatProgressSpinnerModule]
})
export class FicheFournisseurComponent implements OnInit {

    // Accesseur en lecture du formulaire
    get code()          { return this.formGroupFournisseur.get('code'); }
    get libelle()       { return this.formGroupFournisseur.get('libelle'); }
    get email()         { return this.formGroupFournisseur.get('email'); }
    get telephone()     { return this.formGroupFournisseur.get('telephone'); }
    get pays()          { return this.formGroupFournisseur.get('pays'); }
  
    // Variables pour gérer le fournisseur (chargement, création, modification)
    id                : number = 0;
    fournisseur       : Fournisseur = <Fournisseur>{};
  
    // Variables pour gérer l'affichage
    waitingValidation : boolean = false;
    bModeCreation     : boolean = false;
    titre_page        : string  = '';

    // Variable utilisées pour le formulaire
    formGroupFournisseur: FormGroup = this.fb.group({
      code        : [{value: '', disabled : false}, [Validators.required]],
      libelle     : [{value: '', disabled : false}, [Validators.required]],
      email       : [{value: '', disabled : false}, CheckValidatorService.checkMail],
      telephone   : [{value: '', disabled : false}],
      pays        : [{value: '', disabled : false}],
      actif       : [{value: true, disabled : false}, [Validators.required]],
      idAdresse   : [{value: 0}]
    });

    //Intégration du composant Adresse:
  adresseFournisseur      :Adresse  = AdresseInit
  dirty                   : boolean = false;
  invalidAdresse          : boolean = false;

  constructor(
      private fb              : FormBuilder
    , private router          : Router
    , private routed          : ActivatedRoute
    , private apiFournisseur  : ApiFournisseurService
    , private toast           : PersoSnackbarService 
    , private phoneDirective  : TelephoneDirective
 
  ) { }

  ngOnInit(): void {
    this.id = this.routed.snapshot.params['id_fournisseur']

    if (this.id > 0) {                  
      // Mode édition
      this.bModeCreation  = false;
      this.titre_page     = 'Modifier un fournisseur'
      this.formGroupFournisseur.controls['code'].disable();     
      this.getFournisseur();
    } else {
      // Mode création
      this.titre_page     = 'Créer un fournisseur';
      this.formGroupFournisseur.controls['actif'].disable();
      this.bModeCreation  = true;    
    }
  }
  
  public onClickAnnuler() : void {
    this.router.navigate(['/app/administration-extranet/fournisseurs'])
  }

  public onClickValider() : void {

    this.fournisseur.code           = this.formGroupFournisseur.controls.code.value;
    this.fournisseur.libelle        = this.formGroupFournisseur.controls.libelle.value; 
    this.fournisseur.email          = this.formGroupFournisseur.controls.email.value; 
    this.fournisseur.telephone      = this.phoneDirective.getPhoneNumberWithoutFormat(this.formGroupFournisseur.controls.telephone.value); 
    this.fournisseur.adresse        = this.formGroupFournisseur.get('adresse.adresseLigne1')!.value
    this.fournisseur.adresse_cplt   = this.formGroupFournisseur.get('adresse.adresseLigne2')!.value
    this.fournisseur.adresse_Ligne3 = this.formGroupFournisseur.get('adresse.adresseLigne3')!.value
    this.fournisseur.ville          = this.formGroupFournisseur.get('adresse.adresseVille')!.value
    this.fournisseur.code_postal    = this.formGroupFournisseur.get('adresse.adresseCP')!.value
    this.fournisseur.idAdresse      = this.formGroupFournisseur.controls.idAdresse.value;
    this.fournisseur.pays           = this.formGroupFournisseur.controls.pays.value;
    this.fournisseur.actif          = this.formGroupFournisseur.controls.actif.value;

    if (this.bModeCreation == true) {
      this.fournisseur.id = 0;
      this.creerFournisseur();
    } else {
      this.fournisseur.id = this.id;
      this.modifierFournisseur();
    }
  }

  private getFournisseur() : void {
    this.apiFournisseur.getSupplierById(this.id)
    .subscribe(
      (fournisseur: Fournisseur) => {  
        this.adresseFournisseur = {...this.adresseFournisseur
          ,"ligne1":fournisseur.adresse
          ,"cp":fournisseur.code_postal
          ,"ville":fournisseur.ville
          ,"ligne2":fournisseur.adresse_cplt
          ,"ligne3":fournisseur.adresse_Ligne3
        }      
        this.formGroupFournisseur.patchValue({
          code        : fournisseur.code,
          libelle     : fournisseur.libelle,
          email     	: fournisseur.email,
          telephone	  : fournisseur.telephone,
          pays        : fournisseur.pays,
          actif       : fournisseur.actif,
          idAdresse   : fournisseur.idAdresse
      });
    },
      (err: any) => { 
        '/!\\ error getSupplierById: ' + console.log(err);
    });
  }

  private creerFournisseur() : void {
    this.waitingValidation = true;
    
    this.apiFournisseur.postSupplier(this.fournisseur)
    .subscribe(
      (data: Fournisseur) => { 
        this.fournisseur = data;
        this.toast.showInfo("Fournisseur créé")
        this.router.navigate(['/app/administration-extranet/fournisseurs'])
        this.waitingValidation = false;
      },
      (err: any) => { 
        '/!\\ error postSupplier: ' + console.log(err); 
        this.toast.showError(err.error);
        this.waitingValidation = false;
      }
    );
  }

  private modifierFournisseur() : void {
    this.waitingValidation = true;
    
    this.apiFournisseur.putSupplier(this.fournisseur)
    .subscribe(
      (data: Fournisseur) => { 
        this.fournisseur = data;
        this.toast.showInfo("Fournisseur modifié")
        this.router.navigate(['/app/administration-extranet/fournisseurs'])
        this.waitingValidation = false;
      },
      (err: any) => { 
        '/!\\ error putSupplier: ' + console.log(err); 
        this.toast.showError(err.error);
        this.waitingValidation = false;
      }
    ); 
  }
}
