<!-- <mat-icon aria-hidden="false" fontIcon="home"></mat-icon> -->


    <mat-expansion-panel [(expanded)]="expandPannel" style="background-color: #fff">
        <mat-expansion-panel-header style="background-color: #fff">
            <mat-panel-title>
                <mat-icon color="primary">file</mat-icon>
                <span style="width: 220px;" *ngIf="dataSource_entites!= undefined"><span class="span-primary">{{ dataSource_entites.length }}</span>COORDONNÉE<span *ngIf="dataSource_entites.length > 1">S</span> BANCAIRE<span *ngIf="dataSource_entites.length > 1">S</span></span>
                <span style="width: 220px;" *ngIf="dataSource_entites == undefined"><span class="span-primary">0</span>COORDONNÉE BANCAIRE</span>        
            </mat-panel-title>
            <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>
  
            <div class="container">                
                <table  mat-table 
                        matTableResponsiveLarge 
                        matTableExporter 
                        [dataSource]    = "dataSourceMatTable" 
                        #exporter       = "matTableExporter" 
                        [hiddenColumns] = "[7]" 
                        class           = "table-primary"
                        >
                    <!-- N° Contact --> 
                    <ng-container matColumnDef="ent_numero_client">
                        <th mat-header-cell *matHeaderCellDef class="table-padding"> N° Contact </th>
                        <td mat-cell *matCellDef="let entite" class="table-padding"> {{ entite.numero_client }} </td> 
                    </ng-container>
                    
                    <!-- Raison sociale --> 
                    <ng-container matColumnDef="ent_raison_sociale">
                        <th mat-header-cell *matHeaderCellDef class="table-padding"> Raison sociale </th>
                        <td mat-cell *matCellDef="let entite" class="table-padding"> {{ entite.raison_sociale }} </td> 
                    </ng-container>
                    
                    
                    <!-- statut  -->
                    <ng-container matColumnDef="ett_bnc_code">
                        <th mat-header-cell *matHeaderCellDef class="table-padding"> État </th>
                        <td mat-cell *matCellDef="let entite" class="table-padding" >
                            <div *ngIf="entite.coordonnees_bancaire.id > 0 && entite.coordonnees_bancaire.etat">
                                <mat-icon matTooltip="Coordonnées bancaires validées" matTooltipPosition="left" *ngIf="entite.coordonnees_bancaire.etat.code === CONST_CODE_ETAT_COORDONNEES_BANCAIRE_VALIDE" style="color: #04970E;" svgIcon="check_small"><span class="cdk-visually-hidden">Validé</span></mat-icon>
                                <mat-icon matTooltip="Coordonnées bancaires refusées" matTooltipPosition="left" *ngIf="entite.coordonnees_bancaire.etat.code === CONST_CODE_ETAT_COORDONNEES_BANCAIRE_REFUSE" style="color: #FF4081;" svgIcon="cancel"><span class="cdk-visually-hidden">Annulé</span></mat-icon>
                                <mat-icon matTooltip="Coordonnées bancaires à valider" matTooltipPosition="left" *ngIf="entite.coordonnees_bancaire.etat.code === CONST_CODE_ETAT_COORDONNEES_BANCAIRE_A_VALIDER" color="primary" svgIcon="fiber_new"><span class="cdk-visually-hidden">À valider</span></mat-icon>
                            </div>
                            
<!--                             <div *ngIf="entite.coordonnees_bancaire.id <= 0 && entite.historique_coordonnees_bancaire[0]">
                                <mat-icon matTooltip="Coordonnées bancaires validées" matTooltipPosition="left" *ngIf="entite.historique_coordonnees_bancaire[0].etat.code === CONST_CODE_ETAT_COORDONNEES_BANCAIRE_VALIDE" style="color: #04970E;" svgIcon="check_small"><span class="cdk-visually-hidden">Validé</span></mat-icon>
                                <mat-icon matTooltip="Coordonnées bancaires refusées" matTooltipPosition="left" *ngIf="entite.historique_coordonnees_bancaire[0].etat.code === CONST_CODE_ETAT_COORDONNEES_BANCAIRE_REFUSE" style="color: #FF4081;" svgIcon="cancel"><span class="cdk-visually-hidden">Annulé</span></mat-icon>
                                <mat-icon matTooltip="Coordonnées bancaires à valider" matTooltipPosition="left" *ngIf="entite.historique_coordonnees_bancaire[0].etat.code === CONST_CODE_ETAT_COORDONNEES_BANCAIRE_A_VALIDER" color="primary" svgIcon="fiber_new"><span class="cdk-visually-hidden">À valider</span></mat-icon>
                            </div> -->
                            
                        </td> 
                    </ng-container>

                    <!-- IBAN --> 
                    <ng-container matColumnDef="bnc_iban">
                        <th mat-header-cell *matHeaderCellDef class="table-padding"> IBAN </th>
                        <!-- <td mat-cell *matCellDef="let entite" class="table-padding"> {{ (entite.coordonnees_bancaire.id > 0 ? entite.coordonnees_bancaire.iban : (entite.historique_coordonnees_bancaire[0]? entite.historique_coordonnees_bancaire[0].iban:'')) }} </td>  -->
                        <td mat-cell *matCellDef="let entite" class="table-padding"> {{ entite.coordonnees_bancaire.iban }} </td> 
                    </ng-container>
                    
                    <!-- BIC --> 
                    <ng-container matColumnDef="bnc_bic">
                        <th mat-header-cell *matHeaderCellDef class="table-padding"> BIC </th>
                        <!-- <td mat-cell *matCellDef="let entite" class="table-padding"> {{ (entite.coordonnees_bancaire.id > 0 ? entite.coordonnees_bancaire.bic : (entite.historique_coordonnees_bancaire[0]? entite.historique_coordonnees_bancaire[0].bic:'')) }} </td>  -->
                        <td mat-cell *matCellDef="let entite" class="table-padding"> {{ entite.coordonnees_bancaire.bic }} </td>
                    </ng-container>

                    <!-- RUM --> 
                    <ng-container matColumnDef="bnc_rum">
                        <th mat-header-cell *matHeaderCellDef class="table-padding"> RUM </th>
                        <td 
                            mat-cell 
                            *matCellDef = "let entite" 
                            class       = "table-padding" 
                            style       = "max-width:250px ;"
                            matTooltip  = " {{ entite.coordonnees_bancaire.rum }}" >
                            {{ entite.coordonnees_bancaire.rum }}
                        </td> 
                    </ng-container>

                    <!-- Date Column -->
                    <ng-container matColumnDef="bnc_date_mandat">
                        <th mat-header-cell *matHeaderCellDef class="table-padding"> Date mandat </th>
                        <td mat-cell *matCellDef="let entite" class="table-padding">
                            <div *ngIf="entite.coordonnees_bancaire.id > 0">
                                <div *ngIf="entite.coordonnees_bancaire.date_mandat >= '19000101'">{{ entite.coordonnees_bancaire.date_mandat | date: 'dd/MM/yyyy'}}</div>
                                <div *ngIf="entite.coordonnees_bancaire.date_mandat < '19000101'">__/__/____</div>
                            </div>

<!--                             <div *ngIf="entite.coordonnees_bancaire.id <= 0 && entite.historique_coordonnees_bancaire[0]" >
                                <div *ngIf="entite.historique_coordonnees_bancaire[0].date_mandat >= '19000101'">{{ entite.historique_coordonnees_bancaire[0].date_mandat | date: 'dd/MM/yyyy'}}</div>
                                <div *ngIf="entite.historique_coordonnees_bancaire[0].date_mandat < '19000101'">__/__/____</div>
                            </div> -->
                            
                        </td>
                    </ng-container>

                    <!-- Actions -->
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef class="derniere-colonne" style="text-align: right;"></th>
                        <td mat-cell *matCellDef="let entite" class="colonne-module" style="text-align: right;">
                            <button mat-icon-button class="button-etat" color="primary"
                                style="width: 48px; height: 48px;"
                                (click)="onClickOpenMandatPrelevement(entite)">
                                <mat-icon matTooltip="Visualiser l'historique des prélèvements lié au mandat" matTooltipPosition="left" color="primary">view_timeline</mat-icon>
                            </button>

                            <button mat-icon-button class="button-etat" color="primary"
                                style="width: 48px; height: 48px;"
                                (click)="onValiderCoordonneesBancaire(entite.id)" [disabled]="entite.coordonnees_bancaire.id <= 0">
                                <mat-icon matTooltip="Visualiser le détail des coordonnées bancaires" matTooltipPosition="left" [color]="entite.coordonnees_bancaire.id <= 0 ? 'grey' : 'primary'">visibility</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <div class="div-container-between" style="align-items: center;background-color:white">
                    <app-export-table [fileExportName]="'EXTRANET-Infos Bancaires'" [exporter]="exporter" [nb_ligne]="this.dataSourceMatTable.data.length"></app-export-table>
                    <mat-paginator [pageSizeOptions]="[10,25,50]"
                        showFirstLastButtons 
                        aria-label="Nombre de lignes visibles...">
                     </mat-paginator>
                </div>
            </div>
    </mat-expansion-panel>

