import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { TASK } from '../interfaces/task.interface';
import { PersoSnackbarService } from './perso-snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  apiUrl : string = '/api/task'
  apiToast = inject(PersoSnackbarService)

  constructor(private http : HttpClient) { }

  getTask(taskId: number): Observable<TASK> {
    return this.http.get<TASK>(`${this.apiUrl}/${taskId}`);
  }

  

}
