import { Component, OnInit, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur'
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { MassUpdateListeUtilisateursComponent } from '../mass-update-liste-utilisateurs/mass-update-liste-utilisateurs.component';
import { ApiUtilisateurService } from 'src/app/services/api-utilisateur.service';
import { ExportTableComponent } from '../../../templates/export-table/export-table.component';
import { NgStyle } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatTableResponsiveLargeDirective } from '../../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ContexteService, TYPE_UTILISATEUR } from 'src/app/services/contexte.service';
import { GroupeService } from 'src/app/components/groupe/data-access/groupe.service';
import { Groupe } from 'src/app/components/groupe/groupe-interface';


@Component({
    selector: 'app-table-liste-utilisateurs',
    templateUrl: './table-liste-utilisateurs.component.html',
    styleUrls: ['./table-liste-utilisateurs.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatIconModule, MatTableModule, MatTableResponsiveLargeDirective, MatTableExporterModule, MatCheckboxModule, NgStyle
              , ExportTableComponent, MatPaginatorModule]
})
export class TableListeUtilisateursComponent implements OnInit {
  
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;
  
  // Données d'entrée
  @Input() dataSource: any;

  dataSourceMatTable  : MatTableDataSource<ProfilUtilisateur> = new MatTableDataSource<ProfilUtilisateur>();
  expandPannel        : boolean = true;
  displayedColumns    : Array<string> = ['select', 'utl_nom', 'utl_prenom', 'utl_agrement_ve', 'type' , 'utl_mail', 'utl_actif', 'actions'];
  selection           = new SelectionModel<ProfilUtilisateur>(true, []);
  groupes             : Groupe[] = []
  readonly type      : string = TYPE_UTILISATEUR

  constructor(
      private route             : Router
    , private toast             : PersoSnackbarService
    , public dialog             : MatDialog
    , private apiUtilisateur    : ApiUtilisateurService
    , private contexte        : ContexteService
    , private cdr             : ChangeDetectorRef 
    , private apiGroupe       : GroupeService
  ) { }

  ngOnInit(): void {
    
    this.apiGroupe.getGroupes('').subscribe(
      (groupes:Groupe[])=> this.groupes = groupes
    )
  }
  
  ngAfterViewInit() {
    this.paginator!.pageIndex = this.contexte.getPageIndex(this.type)
    this.paginator!.pageSize = this.contexte.getPages(this.type)
    this.dataSourceMatTable.paginator = this.paginator;
    this.dataSourceMatTable.sort = this.sort;
    this.cdr.detectChanges();
  }
  
  ngOnChanges() {
    this.dataSourceMatTable.paginator = this.paginator;
    // obligatoire
    if(this.dataSource != undefined) {
      this.dataSourceMatTable.data = this.dataSource;
      this.selection.clear();
    }     
  }

  public onClickOuvrir(ent_id: number): void {
    this.route.navigate(['/app/profil/', ent_id])
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceMatTable.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSourceMatTable.data);
  }

  private getSelectedUtilisateurs() : Array<ProfilUtilisateur> {
    let selectedUtilisateurs    : Array<ProfilUtilisateur> = [];

    for(let i = 0; i < this.dataSourceMatTable.data.length; i++) {
      if(this.selection.isSelected(this.dataSourceMatTable.data[i])) {
        selectedUtilisateurs.push(this.dataSourceMatTable.data[i]);
      }
    }

    return selectedUtilisateurs;
  }

  public onClickActiver() :void {
    this.onClickAction('activate');
  }

  public onClickDesactiver() :void {
    this.onClickAction('deactivate');
  }

  public onClickIdentifiants() :void {
    this.onClickAction('identifiant');
  }

  public onClickAction(action: string) {
    let selectedUtilisateurs    : Array<ProfilUtilisateur> = this.getSelectedUtilisateurs(); 

    if (selectedUtilisateurs.length > 0) {
      const dialogRef = this.dialog.open(MassUpdateListeUtilisateursComponent, {data : {type: action, dataSource_Utilisateurs: selectedUtilisateurs}});
      
      dialogRef.afterClosed().subscribe(result => {
        if(result) {
          this.apiUtilisateur.isDataModify.next(true);
          this.selection.clear();
        }        
      });

    } else {
      this.toast.showAlert('Vous devez sélectionner au moins un utilisateur'); 
    }
  }

  ngOnDestroy(){
    this.contexte.setType(this.type)
    this.contexte.setPageIndex(this.dataSourceMatTable.paginator!.pageIndex)
    this.contexte.setTableData(this.dataSource)
    this.contexte.setPages(this.paginator? this.paginator.pageSize : 5)
  }

  getGroupeName(idGroupe:number){
    const groupe = this.groupes.find((groupe:Groupe)=>groupe.id===idGroupe)
    
    if (groupe) {
      return groupe.nom
    }else{
      return ''
    }
  }
}
