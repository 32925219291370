<mat-card appearance="outlined">
    <h4>{{ titre_page }} </h4>

    <form [formGroup]="formGroupTypeDemande">
        
        <mat-form-field>
            <mat-label>Référence</mat-label>
            <input matInput type="text" id="typ_code" formControlName="typ_code" oninput="this.value = this.value" required>
            <mat-error *ngIf="typ_code?.invalid && (typ_code?.dirty || typ_code?.touched)">Référence obligatoire</mat-error>
        </mat-form-field>
        
        <mat-form-field>
            <mat-label>Type demande</mat-label>
            <input matInput type="text" id="typ_libelle" formControlName="typ_libelle" oninput="this.value = this.value" required>
            <mat-error *ngIf="typ_libelle?.invalid && (typ_libelle?.dirty || typ_libelle?.touched)">Type de demande obligatoire</mat-error>
        </mat-form-field>
        
        <mat-form-field>
            <mat-label>Email(s) destinataire</mat-label>
            <input matInput type="text" id="email_destinataire" formControlName="email_destinataire" required>
            <mat-hint>Vous pouvez saisir plusieurs adresse email séparé par un point-virgule (;). Exemple : abc@abc.com;def@def.com;...</mat-hint>
            <mat-error *ngIf="email_destinataire?.invalid && (email_destinataire?.dirty || email_destinataire?.touched)" required>Email(s) destinataire obligatoire</mat-error>
        </mat-form-field>                

        <mat-form-field>
            <mat-label>Environnement</mat-label>
            <mat-select name="environnement" id="environnement" formControlName="environnement" multiple required>
                <mat-option *ngFor="let environnement of environnements" [value]="environnement.id">
                    {{ environnement.libelle }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="environnement?.errors?.invalid && (environnement?.dirty || environnement?.touched)">Vous devez sélectionner un environnement</mat-error>
        </mat-form-field>

        <mat-slide-toggle name="actif" id="actif" formControlName="actif" color="primary">
            {{(formGroupTypeDemande.controls.actif.value ? 'Activé' : 'Désactivé' )}}
        </mat-slide-toggle>

        <div class="div-button-right" style="padding-top: 16px;">
            <button mat-button color="warn" class="button-width" (click)="onClickAnnuler()" style="padding-right: 16px;">
                <span>Annuler</span>
            </button>
            <button 
                mat-flat-button 
                *ngIf       = "!waitingValidation"
                color       = "primary" 
                class       = "button-width" 
                (click)     = "onClickValider()" 
                [disabled]  = "formGroupTypeDemande.pristine || !(formGroupTypeDemande.valid && formGroupTypeDemande.dirty)">
                <mat-icon>save</mat-icon>
                <span>Enregistrer</span>
            </button>
            <mat-spinner diameter="24" *ngIf="waitingValidation"></mat-spinner>
        </div>
    </form>
</mat-card>