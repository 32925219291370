<mat-card appearance="outlined">
    <mat-form-field>
        <mat-label>Modèle de réponse</mat-label>
        <mat-select (selectionChange) = "getModele($event.value)">
            <mat-option [value]="apiSupportService.modeleInit">Aucun modèle</mat-option>
            <mat-option *ngFor="let modele of modeles" 
                            [value]="modele"
                            >
                    {{modele.mdl_nom}}
                </mat-option>
        </mat-select>
    </mat-form-field>
</mat-card>
