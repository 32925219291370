import { DatePipe, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTableExporterModule } from 'mat-table-exporter';
import { ExportTableComponent } from 'src/app/components/templates/export-table/export-table.component';
import { MatTableResponsiveLargeDirective } from 'src/app/directive/mat-table-responsive-large/mat-table-responsive-large.directive';
import { Suivi } from 'src/app/interfaces/acquittement/suivi';

@Component({
  selector: 'app-list-rapport1a',
  templateUrl: './list-rapport1a.component.html',
  styleUrls: ['./list-rapport1a.component.scss'],
  standalone:true,
  imports: [
    ReactiveFormsModule,  MatInputModule, MatSelectModule,  MatButtonModule,  MatDatepickerModule,
    MatNativeDateModule,  MatFormFieldModule, MatTableModule, MatIconModule,  MatPaginatorModule, MatSortModule,DatePipe
    , MatCardModule, NgIf,ExportTableComponent,MatTableExporterModule,MatTableResponsiveLargeDirective
  ],
})
export class ListRapport1aComponent {

  @Input()  rapports1a: Suivi[] = [];
  @Output() selectedMail = new EventEmitter<Suivi>();

  displayedColumns: string[] = ['dateOpe1a', 'habilitation', 'immat', 'dossier', 'agrement', 'prenom', 'nom'];
  dataSource: MatTableDataSource<Suivi> = new MatTableDataSource<Suivi>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

 
  ngAfterViewInit() {
    
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  
  ngOnChanges() {
    this.dataSource.data = this.rapports1a
  }

}
