
    

            <div class="grid-container">
                <button mat-raised-button type="submit" color="primary" (click)="onClickActiver()" style="background-color: #04970E; color: #FFFFFF;" class="grid-item">
                    <mat-icon>check</mat-icon>
                    <span class="span-button">Activer</span>                                               
                </button>
                <button mat-raised-button type="submit" (click)="onClickDesactiver()" color="warn" class="grid-item">
                    <mat-icon>close</mat-icon>
                    <span class="span-button">Désactiver</span>
                </button>
                <button mat-raised-button type="submit" color="primary" (click)="onClickIdentifiants()" class="grid-item">
                    <mat-icon>password</mat-icon>
                    <span class="span-button">Réinitialiser le mot de passe</span>
                </button>
            </div>
            <div class="container">
                <table mat-table matTableResponsiveLarge matTableExporter [dataSource]="dataSourceMatTable" #exporter="matTableExporter" [hiddenColumns]="[0,7]" class="table-primary">
                     
                    <!-- Checkbox Column -->
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef class="premiere-colonne" style="padding-left: 24px;">
                        <mat-checkbox (change)="$event ? toggleAllRows() : null" class="checkbox"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? selection.toggle(row) : null"
                                        [checked]="selection.isSelected(row)"
                                        class="checkbox">
                        </mat-checkbox>
                        </td>
                    </ng-container>

                    <!-- Nom --> 
                    <ng-container matColumnDef="utl_nom">
                        <th mat-header-cell *matHeaderCellDef class="table-padding"> Nom </th>
                        <td mat-cell *matCellDef="let ProfilUtilisateur" class="table-padding"> {{ ProfilUtilisateur.nom }} </td> 
                    </ng-container>
                    
                    <!-- Prénom -->
                    <ng-container matColumnDef="utl_prenom">
                        <th mat-header-cell *matHeaderCellDef class="table-padding"> Prénom </th>
                        <td mat-cell *matCellDef="let ProfilUtilisateur" class="table-padding"> {{ ProfilUtilisateur.prenom }} </td>
                    </ng-container>
            
                    <!-- Agrément -->
                    <ng-container matColumnDef="utl_agrement_ve">
                        <th mat-header-cell *matHeaderCellDef class="table-padding"> Agrément </th>
                        <td mat-cell *matCellDef="let ProfilUtilisateur" class="table-padding"> {{ ProfilUtilisateur.agrement_ve }} </td>
                    </ng-container>
                    
                    <!-- Type -->
                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef class="table-padding"> Groupe </th>
                        <td mat-cell *matCellDef="let ProfilUtilisateur" class="table-padding"> {{getGroupeName(ProfilUtilisateur.idgrp)}} </td>
                    </ng-container>

                    <!-- mail -->
                    <ng-container matColumnDef="utl_mail">
                        <th mat-header-cell *matHeaderCellDef class="table-padding"> Mail </th>
                        <td mat-cell *matCellDef="let ProfilUtilisateur" class="table-padding"> {{ ProfilUtilisateur.email }} </td>
                    </ng-container>

                    <!-- Statut -->
                    <ng-container matColumnDef="utl_actif">
                        <th mat-header-cell *matHeaderCellDef class="table-padding">État</th>
                        <td mat-cell *matCellDef="let ProfilUtilisateur" class="table-padding"> 
                            <mat-icon [ngStyle]="{ color: (ProfilUtilisateur.actif ? '#04970E' : '#FF4081')}" svgIcon="{{ ProfilUtilisateur.actif ? 'check_small' : 'cancel' }}"><span class="cdk-visually-hidden">{{ ProfilUtilisateur.actif ? 'Validé' : 'Annulé' }}</span></mat-icon>
                        </td>
                    </ng-container>
                    
                    <!-- Ouvrir -->
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef class="derniere-colonne" style="text-align: right;"></th>
                        <td mat-cell *matCellDef="let ProfilUtilisateur" class="colonne-module" style="text-align: right;">
                            <button mat-icon-button class="button-etat" color="primary"
                                style="width: 48px; height: 48px;"
                                (click)="onClickOuvrir(ProfilUtilisateur.id)">
                                <mat-icon color="primary">edit</mat-icon>
                            </button>
                        </td>
                    </ng-container>
            
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <div class="div-container-between" style="align-items: center;background-color:white">
                    <app-export-table [fileExportName]="'EXTRANET - Utilisateurs'" [exporter]="exporter" [nb_ligne]="this.dataSourceMatTable.data.length"></app-export-table>
                    <mat-paginator
                        [pageSizeOptions]="[5,10,25]"
                        showFirstLastButtons 
                        aria-label="Nombre de lignes visibles...">
                    </mat-paginator>
                </div>
               
            </div>
    

