import { Component, OnInit, Output, EventEmitter,Input, OnDestroy  } from '@angular/core';
import { Modele } from 'src/app/interfaces/support/modele';
import { SousCategorie } from 'src/app/interfaces/support/sous-categorie';
import {Subject, BehaviorSubject} from 'rxjs';
import { takeUntil} from 'rxjs/operators';
import { Categorie } from 'src/app/interfaces/support/categorie';
import { MatDialog } from '@angular/material/dialog';
import { OuiNonAnnulerComponent } from 'src/app/components/templates/oui-non-annuler/oui-non-annuler.component';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { ModeleFacadeService } from 'src/app/services/modele/modele-facade.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
@Component({
    selector: 'app-filtre-modele',
    templateUrl: './filtre-modele.component.html',
    styleUrls: ['./filtre-modele.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, FormsModule, MatSelectModule, MatOptionModule, NgFor, MatButtonModule, MatIconModule, MatTooltipModule, NgIf, AsyncPipe]
})
export class FiltreModeleComponent implements OnInit,OnDestroy {
  @Output() newModuleArrayEvent     = new EventEmitter<Array<Modele>>();
  @Output() newIdSousCategorie      = new EventEmitter<number>();
  @Output() categorieEditEvent      = new EventEmitter<Categorie>();
  @Output() sousCategorieEditEvent  = new EventEmitter<SousCategorie>();
  @Input()  sousCategoriesChange     : boolean = false;

  sousCategories              : SousCategorie[]=[];
  modeles                     : Modele[]=[];
  idCategorie                 : number = 0;
  idSousCategorie             : number = 0;
  textContenu                 : string = '';
  code                        : string = '';
  destroyed                   : Subject<void> = new Subject<void>();
  sousCategories$              = new BehaviorSubject<SousCategorie[]>([]);
  

  constructor(public apiModeleFacade : ModeleFacadeService
            , public dialog       : MatDialog
            , private toast       : PersoSnackbarService) { }

  ngOnInit(): void {
    //Les requêtes de sélection des modèles, catégories et sous catégories ont été exécutées dans le composant parent!
    this.idSousCategorie=-1; //pour n'avoir aucun modèle
    //On s'abonne à modele$:
    this.apiModeleFacade.modeles$.pipe(takeUntil(this.destroyed)).subscribe(
      ()=>this.getModeles()
    )
    this.sousCategories$.pipe(takeUntil(this.destroyed)).subscribe()
  }

  ngOnChanges(){
    if (this.sousCategoriesChange) {
      this.getSousCategories(this.idCategorie)
    }
  }
  
  //GESTION DES MODELES:
  getModeles(){
    this.modeles = this.apiModeleFacade.getModelesFromFilter(this.idSousCategorie,this.idCategorie,this.textContenu, this.code)
    //on transmet l'information au parent:
    this.newModuleArrayEvent.emit(this.modeles)
    this.newIdSousCategorie.emit(this.idSousCategorie)
  
    
  }

  //GESTION DES CATEGORIE
   onCreeCategorie(){
    let categorie : Categorie = <Categorie>{}
    //on signifie qu'il s'agit d'une création
    categorie.cat_id = 0;
   this.categorieEditEvent.emit(categorie)
  }

  onModifieCategorie(){
    if (this.idCategorie > 0) {
      const categorie = this.apiModeleFacade.getCategorieFromId(this.idCategorie)
      if (categorie) {
        this.categorieEditEvent.emit(categorie)
      }
    }
  }

  onSupprimeCategorie(){
    if (this.idCategorie <= 0) {
      return;
    }
    const categorieASupprimer = this.apiModeleFacade.getCategorieFromId(this.idCategorie)
    if (!categorieASupprimer) {
      return;
    }
    //on demande confirmation:
    const messageConfirmation = "Voulez-vous vraiment supprimer la catégorie <".concat(categorieASupprimer.cat_nom,"> ?")
    const dialogRef = this.dialog.open(OuiNonAnnulerComponent, {
      data: {message: messageConfirmation , title: 'Demande de confirmation', type:2},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.apiModeleFacade.deleteCategorie(categorieASupprimer.cat_id)
        this.idCategorie = -1
      }
    }); 
  }

  //GESTION DES SOUS-CATEGORIE
  getSousCategories(idCategorie:number){
    this.sousCategories = this.apiModeleFacade.getSousCategoriesFromCategorie(idCategorie)
    this.sousCategories$.next(this.sousCategories)
    this.idSousCategorie=0
    this.getModeles()
  }

  onCreeSousCategorie(){
    let sousCategorie : SousCategorie = <SousCategorie>{}
    //on signifie qu'il s'agit d'une création
    sousCategorie.sca_id = 0;
   this.sousCategorieEditEvent.emit(sousCategorie)
  }

  onModifieSousCategorie(){
    if (this.idSousCategorie) {
      const sousCategorie = this.apiModeleFacade.getSousCategorieFromId(this.idSousCategorie)
      if (!sousCategorie) {
        return;
      }
      this.sousCategorieEditEvent.emit(sousCategorie)
    }
    
  }

  onSupprimeSousCategorie(){
    if (this.idCategorie) {
      const sousCategorieASupprimer = this.apiModeleFacade.getSousCategorieFromId(this.idSousCategorie)
      if (!sousCategorieASupprimer) {
        return;
      }
    
      //on demande confirmation:
      const messageConfirmation = "Voulez-vous vraiment supprimer la sous-catégorie <".concat(sousCategorieASupprimer.sca_nom,"> ?")
      const dialogRef = this.dialog.open(OuiNonAnnulerComponent, {
        data: {message: messageConfirmation , title: 'Demande de confirmation', type:2},
      });
      
      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this.apiModeleFacade.deleteSousCategorie(sousCategorieASupprimer.sca_id).pipe(takeUntil(this.destroyed)).subscribe((res:boolean)=>{if (res) {
            this.getSousCategories(sousCategorieASupprimer.sca_cat_id)
            this.idSousCategorie=-1
          }})
        }
      }); 
    }
  } 

  ngOnDestroy() {
    this.destroyed.next(); // emits an undefined value to raise the flag
    this.destroyed.complete(); // prevents all further emission
  }
 
}
