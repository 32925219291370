export interface Reference{
    code    : string,
    valeur  : string
}

export interface References{
        carrosseries        : Reference[]
    ,   couleurs            : Reference[]
    ,   energies            : Reference[]
    ,   genres              : Reference[]
    ,   marques             : Reference[]
    ,   natures_adverses    : Reference[]
    ,   natures_evenements  : Reference[]
    ,   zoneschoc           : Reference[]
}

export const ReferencesInit : References={
    carrosseries        :[],
    couleurs            :[],
    energies            :[],
    genres              :[],
    marques             :[],
    natures_evenements  :[],
    natures_adverses    :[],
    zoneschoc           :[]
}