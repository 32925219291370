import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {
  private pageIndex: number = 0;
  
  constructor() { }

  // Définir la page actuelle
  setCurrentPage(page: number): void {
    this.pageIndex = page;
  }

  // Obtenir la page actuelle
  getCurrentPage(): number {
    return this.pageIndex;
  }
  
}
