import { NgIf, NgStyle } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { saveAs } from "file-saver";
import { ModePaiement } from 'src/app/interfaces/facture/mode-paiement';
import { Fichier } from 'src/app/interfaces/fichier';
import { CoordonneesBancaire } from 'src/app/interfaces/profil-user/coordonnees-bancaire';
import { EtatCoordonneesBancaire } from 'src/app/interfaces/profil-user/coordonnees-bancaire/etat-coordonnees-bancaire';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { RelationUtilisateurEntite } from 'src/app/interfaces/utilisateur-entite/relation-utilisateur-entite';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';

@Component({
    selector: 'app-dialog-entite-coordonnee-bancaire',
    templateUrl: './dialog-entite-coordonnee-bancaire.component.html',
    styleUrls: ['./dialog-entite-coordonnee-bancaire.component.scss'],
    standalone: true,
    imports: [MatDialogModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, NgIf, NgStyle, MatButtonModule, MatIconModule]
})
export class DialogEntiteCoordonneeBancaireComponent implements OnInit {

  entite               : Entite = <Entite>{};
  RelUtilisateurEntite : RelationUtilisateurEntite = <RelationUtilisateurEntite>{};

  userConnect                   : ProfilUtilisateur = <ProfilUtilisateur>{};
  CoordonneeBancaire$           : CoordonneesBancaire = <CoordonneesBancaire>{}
  limitFileSize                 : number = 5; // Taille limite d'un fichier upload (5 Mo)

  // upload des mandats et IBAN
  IBANFile  : string = '';
  RUMFile   : string = '';
  
  file_IBAN : Fichier = <Fichier>{};
  file_RUM  : Fichier = <Fichier>{};

  // Gestion de l'affichage
  waiting_save                  : boolean = false;

  ent_id                        : number = 0;
  utl_id                        : number = 0;
  bnq_id                        : number = 0;

  isAdmin                       : boolean = false;
  isDirigeant                   : boolean = false;
  etatAValide?                  : EtatCoordonneesBancaire = <EtatCoordonneesBancaire>{};
  mandatRequired                : boolean = false
  modesPaiement                 : ModePaiement[]=[]

  formBancaire = this._fb.group({
    id                  : new FormControl(0),
    date_creation       : new FormControl(),
    date_modification   : new FormControl(),
    iban                : new FormControl('',[Validators.required,this.check_IBAN()]),
    bic                 : new FormControl('',[Validators.required,this.check_BIC()]),
    rum                 : new FormControl(''),
    date_mandat         : new FormControl(''),
    nom_fichier_iban    : new FormControl('',Validators.required),
    pdf_iban            : new FormControl(''),
    nom_fichier_rum     : new FormControl(''),
    pdf_rum             : new FormControl('')
  })

  get iban()  { return this.formBancaire.get('iban'); }
  get bic()   { return this.formBancaire.get('bic'); }
  get rum()   { return this.formBancaire.get('rum'); }
  get date_mandat() { return this.formBancaire.get('date_mandat'); }
  get nom_fichier_iban() { return this.formBancaire.get('nom_fichier_iban'); }
  get nom_fichier_rum() { return this.formBancaire.get('nom_fichier_rum'); }

  constructor(  private _fb                     : FormBuilder
              , private toast                   : PersoSnackbarService
              , public apiEntite                : ApiEntiteService
              , private apiAuth                 : ApiAuthentificationService
              , private dialogRef: MatDialogRef<DialogEntiteCoordonneeBancaireComponent>
              ,@Inject(MAT_DIALOG_DATA) data : any) {
                this.entite               = data.entite;
                this.RelUtilisateurEntite = data.RelUtilisateurEntite;
                this.ent_id               = data.ent_id;
                this.modesPaiement        = data.modesPaiement
              }

  ngOnInit(): void {
    if(this.RelUtilisateurEntite != undefined) {
      this.isDirigeant = this.RelUtilisateurEntite.est_dirigeant;
    }

    if(this.entite != undefined && this.entite.coordonnees_bancaire != undefined){
      this.entite.coordonnees_bancaire.iban = '';
      this.entite.coordonnees_bancaire.nom_fichier_iban = '';
      this.entite.coordonnees_bancaire.rum = '';
      this.entite.coordonnees_bancaire.nom_fichier_rum = '';
      this.entite.coordonnees_bancaire.bic = '';
      this.entite.coordonnees_bancaire.date_mandat = '';
      this.CoordonneeBancaire$  = this.entite.coordonnees_bancaire;
      this.bnq_id               = this.entite.coordonnees_bancaire.id;

     this.initializeForm();
     this.getEtatCoordonneesBancaireAValide();     
    }

      const modePrelevement = this.modesPaiement.find((modePaiement:ModePaiement) => modePaiement.code==='PRELEVEMENT')
      if (modePrelevement) {
        if (modePrelevement.id === this.entite.idModePaiementDefaut) {
          this.mandatRequired = true
          this.nom_fichier_rum?.addValidators(Validators.required)
        }
      }
  }

  
  private initializeForm(){
    this.formBancaire.patchValue({
      id                : 0,
      date_creation     : this.entite.coordonnees_bancaire.date_creation,
      date_modification : this.entite.coordonnees_bancaire.date_modification,
      bic               : this.entite.coordonnees_bancaire.bic,
      date_mandat       : this.entite.coordonnees_bancaire.date_mandat.split('T')[0],
      nom_fichier_iban  : this.entite.coordonnees_bancaire.nom_fichier_iban,
      nom_fichier_rum   : this.entite.coordonnees_bancaire.nom_fichier_rum
    });
  }

  private isFormChanged():boolean{
    //Vérifie si une donnée dans le formulaire a changé
    if(this.formBancaire.value.iban!=this.CoordonneeBancaire$.iban){
      return true
    }
    if(this.formBancaire.value.bic!=this.CoordonneeBancaire$.bic){
      return true
    }
    if(this.formBancaire.value.rum!=this.CoordonneeBancaire$.rum){
      return true
    }
    if(this.formBancaire.value.date_mandat!=this.CoordonneeBancaire$.date_mandat){
      return true
    }
    if(this.formBancaire.value.pdf_iban!=this.CoordonneeBancaire$.pdf_iban){
      return true
    }
    if(this.formBancaire.value.pdf_rum!=this.CoordonneeBancaire$.pdf_rum){
      return true
    }

    return false;
  }

  public enregistreData(): void {
    this.waiting_save = true;

    if (this.isFormChanged()== false) {
      this.toast.showInfo("Vous n'avez rien modifié");
      return;
    }

    this.CoordonneeBancaire$.id                = this.formBancaire.value.id? this.formBancaire.value.id:0;
    this.CoordonneeBancaire$.date_creation     = this.formBancaire.value.date_creation;
    this.CoordonneeBancaire$.date_modification = this.formBancaire.value.date_modification;
    this.CoordonneeBancaire$.iban              = this.formBancaire.value.iban? this.formBancaire.value.iban : '';
    this.CoordonneeBancaire$.bic               = this.formBancaire.value.bic? this.formBancaire.value.bic : '';
    this.CoordonneeBancaire$.rum               = this.formBancaire.value.rum? this.formBancaire.value.rum : '';
    this.CoordonneeBancaire$.date_mandat       = this.formBancaire.value.date_mandat? this.formBancaire.value.date_mandat : '';    
    this.CoordonneeBancaire$.pdf_iban          = this.formBancaire.value.pdf_iban? this.formBancaire.value.pdf_iban : '';    
    this.CoordonneeBancaire$.pdf_rum           = this.formBancaire.value.pdf_rum? this.formBancaire.value.pdf_rum : '';
    this.CoordonneeBancaire$.etat              = this.etatAValide!;
    this.CoordonneeBancaire$.idModePaiement    = this.entite.idModePaiementDefaut ? this.entite.idModePaiementDefaut : 0;

    this.apiEntite.putCoordonneesBancaire(this.ent_id, this.CoordonneeBancaire$, this.apiAuth.userConnect.id)
      .subscribe(
        (coordonnees_bancaire: CoordonneesBancaire) => {

          this.apiEntite.getEntiteById(this.ent_id)
          .subscribe(
            (data: Entite[]) => {
              this.CoordonneeBancaire$  = data[0].coordonnees_bancaire;
              this.bnq_id               = data[0].coordonnees_bancaire.id;
    
              this.formBancaire.patchValue({
                id                : 0,
                date_creation     : data[0].coordonnees_bancaire.date_creation,
                date_modification : data[0].coordonnees_bancaire.date_modification,
                iban              : data[0].coordonnees_bancaire.iban,
                bic               : data[0].coordonnees_bancaire.bic,
                rum               : data[0].coordonnees_bancaire.rum,
                date_mandat       : data[0].coordonnees_bancaire.date_mandat.split('T')[0],
                nom_fichier_iban  : data[0].coordonnees_bancaire.nom_fichier_iban,
                nom_fichier_rum   : data[0].coordonnees_bancaire.nom_fichier_rum
              });                            

              this.toast.showInfo('Données bancaires enregistrées');
              this.waiting_save = false;
              this.dialogRef.close(this.CoordonneeBancaire$);
            },
            (err) => { 
              this.toast.showError('Erreur lors de l\'enregistrement');
              this.waiting_save = false;
            }
          );          
        },
        (err) => { console.log(err) }
      );
  }

  private check_IBAN(): ValidatorFn {
    return (control:AbstractControl):ValidationErrors|null =>{
      
      let IBAN: string = control.value;
      let a_regex: any = /^[A-Z]{2}[0-9A-Z]{25,32}$/;
  
      if (!a_regex.test(IBAN)) {
        return { erreur: 'La taille du numéro IBAN est incorrecte.' }
      }
  
      if (!IBAN) {
        return null;
      }
  
      let parsedValue: string = String(IBAN);
  
      // iban should be at least 15 characters long
      if (parsedValue.length < 15) {
        return { erreur: 'Taille du numéro de l\'IBAN incorrecte' };
      }
  
      switch(IBAN.substring(0,2)) {
        case 'FR':
          break;
        case 'NC':
          break;
        default:
          return { erreur: 'Indicatif pays non-autorisé: '+IBAN.substring(0,2) }
      }
  
      return null;
    }
  }

  private check_BIC(): ValidatorFn  {
    return (control:AbstractControl) : ValidationErrors|null=>{

        let BIC: string = control.value;
        let regex: any = /^[A-Z]{6}[0-9A-Z]{2,5}$/;
    
        if (!regex.test(BIC)) {
          return { erreur: 'Format de numéro BIC invalide.' }
        }
    
        return null
    }
  }

/* 
    private check_IBAN_old(fcControler: FormControl): any {
    let IBAN: string = fcControler.value;
    let a_regex: any = /^[A-Z]{2}[0-9A-Z]{25,32}$/;

    if (!a_regex.test(IBAN)) {
      return { erreur: 'La taille du numéro IBAN est incorrecte.' }
    }

    if (!IBAN) {
      return null;
    }

    let parsedValue: string = String(IBAN);
    let invalid: boolean = false;

    // iban should be at least 15 characters long
    if (parsedValue.length < 15) {
      return { erreur: 'Taille du numéro de l\'IBAN incorrecte' };
    }

    switch(IBAN.substring(0,2)) {
      case 'FR':
        break;
      case 'NC':
        break;
      default:
        return { erreur: 'Indicatif pays non-autorisé: '+IBAN.substring(0,2) }
    }

    return null;
  } 

    private check_BIC_old(fcControler: FormControl): any {
    let BIC: string = fcControler.value;
    let regex: any = /^[A-Z]{6}[0-9A-Z]{2,5}$/;

    if (!regex.test(BIC)) {
      return { erreur: 'Format de numéro BIC invalide.' }
    }

    return null
  }

  private check_RUM(fcControler: FormControl): any {
    let RUM: string = fcControler.value;
    let regex: any = /^[0-9A-Z]{3,35}$/;

    if (!regex.test(RUM)) {
      return { erreur: 'Format de numéro RUM invalide.' }
    }

    return null
  }

  private check_Date_old (fcControler: FormControl): any {
    let date_mandat: string = fcControler.value;

    if (date_mandat == '' || date_mandat == '0000-00-00') {
      return { erreur: 'La date du mandat est obligatoire' }
    }

    return null
  }

  private check_Date(): ValidatorFn  {
    return (control:AbstractControl):ValidationErrors|null =>{

        let date_mandat: string = control.value;
    
        if (date_mandat == '' || date_mandat == '0000-00-00') {
          return { erreur: 'La date du mandat est obligatoire' }
        }
    
        return null
    }
  } */

  // envoi du document IBAN
  public onClickUploadIBAN(event: any): void {
    const file = event.target.files[0];
    
    this.file_IBAN.typeFichier = 'iban';
    this.file_IBAN.name        = event.target.files[0].name;
    this.formBancaire.patchValue({
      nom_fichier_iban : event.target.files[0].name
    })
    this.CoordonneeBancaire$.nom_fichier_iban = event.target.files[0].name;

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.file_IBAN.contenu = reader.result as string;
      this.file_IBAN.taille = event.target.files[0].size;

      if (this.file_IBAN.taille/1024/1024 > this.limitFileSize) {
        this.toast.showInfo('La taille limite d\'un fichier est de '+this.limitFileSize+' Mo')
      } else {
        this.formBancaire.patchValue({
          pdf_iban : reader.result as string
        });
      }      
    };
  }

  // envoi du document RUM
  public onClickUploadRUM(event: any): void {
    const file = event.target.files[0];
    
    this.file_RUM.typeFichier = 'rum';
    this.file_RUM.name        = event.target.files[0].name;
    this.CoordonneeBancaire$.nom_fichier_rum = event.target.files[0].name;
    this.formBancaire.patchValue({
      nom_fichier_rum : event.target.files[0].name
    })

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {      

      this.file_RUM.contenu = reader.result as string;
      this.file_RUM.taille = event.target.files[0].size;

      if (this.file_RUM.taille/1024/1024 > this.limitFileSize) {
        this.toast.showInfo('La taille limite d\'un fichier est de '+this.limitFileSize+' Mo')
      } else {
        this.formBancaire.patchValue({
          pdf_rum : reader.result as string
        });
      }      
    };
  }

  // téléchargement de l'IBAN ou RUM
  public dowloadFile(type: string) {
    this.apiEntite.getDownFile(type, this.ent_id, this.bnq_id)
      .subscribe(
        (data: any) => {
          if(data != undefined) {
            switch (type) {
              case 'iban':
                saveAs(data, this.CoordonneeBancaire$.nom_fichier_iban);
                break;
              case 'rum':
                saveAs(data, this.CoordonneeBancaire$.nom_fichier_rum);
                break;
              default:
                break;
            }
            this.toast.showInfo('Fichier téléchargé.')
          }
          this.toast.showInfo('Document ' +type+ ' téléchargé.');
        }
      )

  }

  public getEtatCoordonneesBancaireAValide(): void {
    this.apiEntite.getEtatCoordonneesBancaire()
      .subscribe(
        (data : Array<EtatCoordonneesBancaire>) => {
          this.etatAValide =data.find(etat =>etat.code===this.apiEntite.CONST_CODE_ETAT_COORDONNEES_BANCAIRE_A_VALIDER);
        }
      );
  }
 
}
