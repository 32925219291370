            <div class="container">
                <table mat-table matTableResponsiveLarge [dataSource]="dataSourceMatTable" class="table-primary">
                     
                    <!-- code -->
                    <ng-container matColumnDef="code">
                        <th mat-header-cell *matHeaderCellDef class="premiere-colonne"> Code </th>
                        <td mat-cell *matCellDef="let fournisseur" class="table-padding"> {{ fournisseur.code }} </td>
                    </ng-container>

                    <!-- denomination --> 
                    <ng-container matColumnDef="denomination">
                        <th mat-header-cell *matHeaderCellDef class="table-padding"> Dénomination </th>
                        <td mat-cell *matCellDef="let fournisseur" class="table-padding"> {{ fournisseur.libelle }} </td> 
                    </ng-container>            
                    
                    <!-- Statut -->
                    <ng-container matColumnDef="statut">
                        <th mat-header-cell *matHeaderCellDef class="table-padding"> Statut </th>
                        <td mat-cell *matCellDef="let fournisseur" class="table-padding"> 
                        <mat-icon [ngStyle]="{ color: (fournisseur.actif ? '#04970E' : '#FF4081')}">{{ fournisseur.actif ? 'check_small' : 'cancel' }}</mat-icon>
                        </td>
                    </ng-container>
                    
                    <!-- Ouvrir -->
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef class="derniere-colonne" style="text-align: right;"></th>
                        <td mat-cell *matCellDef="let fournisseur" class="colonne-module" style="text-align: right;">
                            <button mat-icon-button class="button-etat" color="primary"
                                style="width: 48px; height: 48px;"
                                (click)="onClickOuvrir(fournisseur.id)">
                                <mat-icon color="primary">edit</mat-icon>
                            </button>
                            <button mat-icon-button
                                    *ngIf   = "!waitingValidation" 
                                    class   = "button-etat" 
                                    color   = "primary"
                                    style   = "width: 48px; height: 48px;"
                                    (click) = "onClickActifInactif(fournisseur)">
                            <mat-icon color="primary" 
                                        [matTooltip]        = "getToolTipMessage(fournisseur.actif)" 
                                        matTooltipPosition  = "left">
                                        {{ fournisseur.actif ? 'cancel' : 'check_small'}}</mat-icon>
                        </button>
                        <mat-spinner diameter="24" *ngIf="waitingValidation"></mat-spinner>
                        </td>
                    </ng-container>
            
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator
                    [pageSizeOptions]="[5,10,25]"
                    showFirstLastButtons 
                    aria-label="Nombre de lignes visibles...">
                </mat-paginator>
            </div>
