import { Component, Input } from '@angular/core';
import { InvitationFilter } from '../../interface/invitation-filter.interface';
import { InvitationService } from '../../data-access/invitation.service';
import { EtatInvitation } from '../../interface/etat.interface';
import { ListComponent } from '../list/list.component';
import { FilterComponent } from '../filter/filter.component';
import { InvitationCollaborateurBdd } from 'src/app/interfaces/invitation-collaborateur/invitation-collaborateur-bdd';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  standalone:true,
  imports:[ListComponent,FilterComponent]
})
export class ShellComponent {
  @Input() idEntite : number = 0

  invitations : InvitationCollaborateurBdd[] = [];
  etats       : EtatInvitation[]          = [];
  filter      : InvitationFilter          = {};

  constructor(private invitationService: InvitationService) {}

  ngOnInit(): void {
    this.getEtats();
  }

  getEtats(): void {
    this.invitationService.getEtats().subscribe(data => {
      this.etats = data;
    });
  }

  getInvitations(): void {
    this.invitationService.getInvitations(this.filter).subscribe(data => {
      this.invitations = data;      
    });
  }

  onFilterChange(filter: InvitationFilter): void {
    filter.idEnt = this.idEntite
    this.filter = filter;
    this.getInvitations();
  }

  annuleInvitation(idInvitation:number){
    this.invitationService.annuleInvitation(idInvitation).subscribe(
      (invitationAnnulee:InvitationCollaborateurBdd)=>{
        this.invitations = this.invitations.map((invitation:InvitationCollaborateurBdd)=> invitation.id===idInvitation? invitationAnnulee : invitation)
        }
      ,(error)=>console.log(error.erreur)
      
    )
  }
}
