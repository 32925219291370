import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Output, ViewChild, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { TypeEntite } from 'src/app/interfaces/profil-user/type-entite';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiEntiteService } from 'src/app/services/api-entite.service';

@Component({
  selector: 'app-entite-search',
  templateUrl: './entite-search.component.html',
  styleUrls: ['./entite-search.component.scss'],
  standalone:true,
  imports:[MatFormFieldModule,NgFor,MatSelectModule,MatProgressSpinnerModule,MatIconModule,ReactiveFormsModule
          ,MatCardModule,NgIf,MatInputModule,MatButtonModule,MatTableModule,MatPaginatorModule,MatRippleModule]
})
export class EntiteSearchComponent {

  @Output() entiteEvent = new EventEmitter<Entite|null>()
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;
  
  filterEntityForm    = new FormGroup({
      habilitation    : new FormControl<string>('',{nonNullable:true})
    , numeroContact   : new FormControl<string>('',{nonNullable:true})
    , raison_sociale  : new FormControl<string>('',{nonNullable:true})
    , siren           : new FormControl<string>('',{nonNullable:true})
    , ville           : new FormControl<string>('',{nonNullable:true})
    , typeEntite      : new FormControl<string>('',{nonNullable:true})
  })

  apiEntite           = inject(ApiEntiteService) 
  apiAuth             = inject(ApiAuthentificationService)
  typesEntite         : TypeEntite[] = []
  waiting             : boolean = false
  dataSourceMatTable  : MatTableDataSource<Entite> = new MatTableDataSource<Entite>();
  displayedColumns     = ['typ_ent_nom','ent_habilitation', 'ent_numero_client', 'ent_raison_sociale' , 'ent_code_postal', 'ent_ville', 'ent_siren'];
 
  ngOnInit(){
    this.apiEntite.getAllTypeEntite(this.apiAuth.userConnect.environnement.code).subscribe(
      (typesEntite:TypeEntite[])=>this.typesEntite = typesEntite
    )
  }

  ngAfterViewInit() {
    this.dataSourceMatTable.paginator = this.paginator;
    this.dataSourceMatTable.sort = this.sort;
  }

  onSearchEntites(){
    this.waiting = true;
    let typeEntite : string
    
    if (this.filterEntityForm.controls.typeEntite.value==='') {
      typeEntite = this.typesEntite.map((type:TypeEntite)=> type.code).toString()
    }else{
      typeEntite = this.filterEntityForm.controls.typeEntite.value
    }
    
    this.apiEntite.getEntiteByFilter(this.filterEntityForm.controls.habilitation.value
                                    , this.filterEntityForm.controls.numeroContact.value
                                    , this.filterEntityForm.controls.raison_sociale.value
                                    , this.filterEntityForm.controls.siren.value
                                    , this.filterEntityForm.controls.ville.value
                                    , typeEntite
                                    , '')
    .subscribe(
      (data: Entite[]) => {        
        this.dataSourceMatTable.data = data
        this.waiting = false;
      },
      (err: any) => {
        console.error(err.message);
        this.waiting = false;
      });
  }

  selectEntite(entite:Entite){
    this.entiteEvent.next(entite)
  }

  cancel(){
    this.entiteEvent.next(null)
  }
}
