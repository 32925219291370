import { Component, OnInit } from '@angular/core';
import { Filtre } from 'src/app/components/facture/mes-factures/mes-factures.component';
import { EtatFacture } from 'src/app/interfaces/facture/etat-facture';
import { Facture } from 'src/app/interfaces/facture/facture';
import { ApiFactureService } from 'src/app/services/api-facture.service';
import { FactureListeComponent } from '../facture-liste/facture-liste.component';
import { FactureFiltreComponent } from '../facture-filtre/facture-filtre.component';

@Component({
    selector: 'app-facture-shell',
    templateUrl: './facture-shell.component.html',
    styleUrls: ['./facture-shell.component.scss'],
    standalone: true,
    imports: [FactureFiltreComponent, FactureListeComponent]
})
export class FactureShellComponent implements OnInit {
  facturesAffichees : Facture[]     = []
  etats             : EtatFacture[] = []
  filtre!:Filtre

  constructor(private apiFactures:ApiFactureService) { }

  ngOnInit(): void {
    this.apiFactures.getEtat().subscribe(
      (res)=>this.etats = res
    )
  }

  getFactures(){
    console.log(this.filtre);
    
    this.apiFactures.getAllFactureCompta(this.filtre).subscribe(
      (factures)=>{
        this.facturesAffichees = factures;
      },
      (error)=>console.log(error)
      
    )
  }

  updateFilter(event:Filtre){
    this.filtre = event
    this.getFactures()
  }

}
