
<mat-card>
    <div class="div-container-left">
        <app-entite-list [fieldName]        = "'Cabinet'"
                        (entiteEmitter)     = "onSetEntite($event)"
                        [CoordBanqDisabled] = "false"
                                        >
        </app-entite-list>
    
        <mat-form-field >
            <mat-label>Habilitation à rediriger</mat-label>
            <input 
            #habilitation
                matInput 
                required
                >
        </mat-form-field>
        <button 
            mat-raised-button 
            color   = "primary" 
            (click) = "onAddHabilitation(habilitation.value)"
            [disabled]="habilitation.value===''|| habilitation$.value===''"
            >Ajouter
        </button>
    </div>

    <table 
        mat-table 
        matTableResponsiveLarge 
        [dataSource]    = "dataSourceMatTable" 
        class           = "table-primary">
                     
        <!-- habilitation -->
        <ng-container matColumnDef="habilitation">
            <th mat-header-cell *matHeaderCellDef class="premiere-colonne"> Habilitation </th>
            <td mat-cell *matCellDef="let cabinet" class="table-padding"> {{ cabinet.habilitation }} </td>
        </ng-container>

        <!-- raison sociale --> 
        <ng-container matColumnDef="raison_sociale">
            <th mat-header-cell *matHeaderCellDef class="table-padding"> Raison sociale </th>
            <td mat-cell *matCellDef="let cabinet" class="table-padding"> {{ cabinet.raison_sociale }} </td> 
        </ng-container>            
        
        <!-- Ouvrir -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="derniere-colonne" style="text-align: right;"></th>
            <td mat-cell *matCellDef="let cabinet" class="colonne-module" style="text-align: right;">
                <button 
                    mat-icon-button 
                    class="button-etat" 
                    color="warn"
                    style="width: 48px; height: 48px;"
                    matTooltip="Supprimer ce cabinet"
                    (click)="onClickSupprime(cabinet.habilitation)"
                    >
                    <mat-icon color="warn">delete</mat-icon>
                </button>
                
            <mat-spinner diameter="24" *ngIf="waitingValidation"></mat-spinner>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator
        [pageSizeOptions]="[5,10,25]"
        showFirstLastButtons 
        aria-label="Nombre de lignes visibles...">
    </mat-paginator>
</mat-card>
