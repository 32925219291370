import { Injectable } from '@angular/core';
import { Modele } from 'src/app/interfaces/support/modele';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { ApiModeleService } from '../support/api-modele.service';
import { ApiCategorieService } from '../support/api-categorie.service';
import { ApiSousCategorieService } from '../support/api-sous-categorie.service';
import { Categorie } from 'src/app/interfaces/support/categorie';
import { SousCategorie } from 'src/app/interfaces/support/sous-categorie';
import { PersoSnackbarService } from '../perso-snackbar.service';
import { ApiAuthentificationService } from '../api-authentification.service';
@Injectable({
  providedIn: 'root'
})
export class ModeleFacadeService {
  CONST_DATE_NULL              = "0000-00-00T00:00:00.000" as const;

  modeles$                     = new BehaviorSubject<Modele[]>([]);
  categories$                  = new BehaviorSubject<Categorie[]>([]);
  sousCategories$              = new BehaviorSubject<SousCategorie[]>([]);
  
  modeles                     : Modele[] = [];
  categories                  : Categorie[] = [];
  sousCategories              : SousCategorie[] = [];

  constructor(private apiModeleService : ApiModeleService
            , private apiCategorieService: ApiCategorieService
            , private apiSousCategorieService:ApiSousCategorieService
            , private toast           : PersoSnackbarService
            , private authUser        : ApiAuthentificationService) { }

  public getAll(){
    return forkJoin([
      this.apiModeleService.getModeles()
      ,this.apiCategorieService.getCategories()
      ,this.apiSousCategorieService.getSousCategories()
    ]).subscribe(
      ([modeles,categories,sousCategories]:[Modele[],Categorie[],SousCategorie[]])=>{
      this.modeles$.next(modeles);
      this.categories$.next(categories)
      this.sousCategories$.next(sousCategories)
    })
  }
  // MODELE:

  public getModeles(){
    this.apiModeleService.getModeles().subscribe((res:Modele[])=>
      this.modeles$.next(res)
    )
  }

  public deleteModele(idModele:number):any{
    return this.apiModeleService.deleteModele(idModele)
  }

  public creerModele(modele:Modele):any{
    modele.mdl_creat_utl_id = this.authUser.userConnect.id
    this.apiModeleService.postModele(modele).subscribe((data)=>
    //on ajoute le modèle à la liste affichée:  
    this.addModele(data))
    this.toast.showInfo('Le modèle a bien été créé.')
  }
  public modifierModele(modele:Modele):any{
    modele.mdl_modif_utl_id = this.authUser.userConnect.id
    this.apiModeleService.putModele(modele).subscribe((data)=>{
      //On reporte les modifications dans la liste affichée des modèles
      this.updateModele(data);
      this.toast.showInfo('Le modèle a bien été modifié.');  
    })
  }

  public addModele(modele:Modele){
    this.modeles = this.modeles$.getValue()
    this.modeles.push(modele)
    this.modeles$.next(this.modeles)
  }

  public updateModele(modele:Modele){
    this.modeles = this.modeles$.getValue()
    this.modeles$.next(this.modeles.map((res)=> res.mdl_id===modele.mdl_id? modele : res))
  }

  public removeModele(idModele:number){
    this.modeles = this.modeles$.getValue().filter((res)=> res.mdl_id!=idModele)
    this.modeles$.next(this.modeles)
  }

  public getModelesFromFilter(idSousCategorie:number,idCategorie:number, content:string, code:string){
    if (content!='' || code!='') {
      if (idSousCategorie==-1) {
        idSousCategorie=0
      }   
    }
    
    return this.modeles$.getValue()
                            .filter((modele)=> idCategorie!=0? (idSousCategorie!=0? modele.mdl_sou_cat_id=== idSousCategorie : modele.souscategorie?.sca_cat_id===idCategorie): modele.mdl_sou_cat_id!=0)                            
                            .filter((modele) => modele.mdl_nom.toLowerCase().normalize('NFD').includes(content.toLowerCase().normalize('NFD')) || modele.mdl_contenu.toLowerCase().normalize('NFD').includes(content.toLowerCase().normalize('NFD')) )
                            .filter((modele) => modele.mdl_code.toLowerCase().normalize('NFD').startsWith(code.toLowerCase().normalize('NFD'))  )
  }

  //CATEGORIE :

  public getCategorie(){
    this.apiCategorieService.getCategories().subscribe((res:Categorie[])=>this.categories$.next(res))
  }

  public deleteCategorie(idCategorie:number):any{
    this.apiCategorieService.deleteCategorie(idCategorie).subscribe(()=>{
      //on supprime la catégorie de la liste en cours:
      this.removeCategorie(idCategorie)
      this.toast.showInfo('La catégorie a bien été supprimée.')
    })
  }

  public creeCategorie(categorie:Categorie):any{
    this.apiCategorieService.postCategorie(categorie).subscribe((data)=>{
      //On reporte les modifications dans la liste affichée des catégories
      this.addCategorie(data);
      this.toast.showInfo('La catégorie a bien été créee.');  
    })
  }

  public modifierCategorie(categorie:Categorie):any{
    this.apiCategorieService.putCategorie(categorie).subscribe((data)=>{
      //On reporte les modifications dans la liste 
      this.updateCategorie(data);
      this.toast.showInfo('La catégorie a bien été modifiée');
    })
  }

  //1.5 - Ajout dans la liste
  public addCategorie(categorie:Categorie){
    this.categories = this.categories$.getValue()
    this.categories.push(categorie)
    this.categories$.next(this.categories)
  }

  //1.6 - Modification dans la liste
  public updateCategorie(categorie:Categorie){
    this.categories = this.categories$.getValue()
    this.categories$.next(this.categories.map((res)=> res.cat_id===categorie.cat_id? categorie : res))
  }

  //1.7 - Suppression dans la liste
  public removeCategorie(idCategorie:number){
    this.categories = this.categories$.getValue().filter((res)=> res.cat_id!=idCategorie)
    
    this.categories$.next(this.categories)
  }

  //1.8 - Recherche dans la liste depuis un id
  public getCategorieFromId(idCategorie:number){
    return this.categories$.getValue().find((res)=> res.cat_id ===idCategorie)
  }

  // SOUS-CATEGORIE :
  public getSousCategorie(){
    
    this.apiSousCategorieService.getSousCategories().subscribe((res:SousCategorie[])=>{this.sousCategories$.next(res)})
    
  }
  
  public creeSousCategorie(sousCategorie:SousCategorie):any{
    let subject = new BehaviorSubject<boolean>(false);
    this.apiSousCategorieService.postSousCategorie(sousCategorie).subscribe((data)=>{
          //On reporte les modifications dans la liste affichée des catégories
          this.addSousCategorie(data);
          this.toast.showInfo('La sous-catégorie a bien été créee.');  
          subject.next(true);
          subject.complete()
        
    })
    return subject
  }

  public modifierSousCategorie(sousCategorie:SousCategorie):any{
    let subject = new BehaviorSubject<boolean>(false);
    this.apiSousCategorieService.putSousCategorie(sousCategorie).subscribe((data)=>{
      //On reporte les modifications dans la liste 
      this.updateSousCategorie(data);
      this.toast.showInfo('La sous-catégorie a bien été modifiée');
      subject.next(true);
      subject.complete()
    })
    return subject
  }

  public deleteSousCategorie(idSousCategorie:number):any{
    let subject = new BehaviorSubject<boolean>(false);
    this.apiSousCategorieService.deleteSousCategorie(idSousCategorie).subscribe((sousCategorieSupprimee:boolean)=>{
      if (sousCategorieSupprimee) {
        //on supprime la catégorie de la liste en cours:
        this.removeSousCategorie(idSousCategorie)
        this.toast.showInfo('La sous-catégorie a bien été supprimée.')
        subject.next(true);
        subject.complete()
      }
    })
    return subject
  }

  //2.5 - Ajout dans la liste
  public addSousCategorie(souscategorie:SousCategorie){
    this.sousCategories = this.sousCategories$.getValue()
    this.sousCategories.push(souscategorie)
    this.sousCategories$.next(this.sousCategories)
  }

  //2.6 - Modification dans la liste
  public updateSousCategorie(sousCategorie:SousCategorie){
    this.sousCategories = this.sousCategories$.getValue()
    this.sousCategories$.next(this.sousCategories.map((res)=> res.sca_id===sousCategorie.sca_id? sousCategorie : res))
    //Cela peut impacter également la liste des modèles:
    this.modeles = this.modeles$.getValue().map((res)=> {if (res.souscategorie?.sca_id===sousCategorie.sca_id) {res.souscategorie = sousCategorie
    } return res})
    this.modeles$.next(this.modeles)
  }

  //2.7 - Suppression de la liste
  public removeSousCategorie(idSousCategorie:number){
    this.sousCategories = this.sousCategories$.getValue().filter((res)=>res.sca_id!=idSousCategorie)
    this.sousCategories$.next(this.sousCategories)
  }

  //2.8 - Recherche dans la liste depuis une catégorie
  public getSousCategoriesFromCategorie(idCategorie:number){
    const sousCategories = this.sousCategories$.getValue()
    
    return sousCategories.filter((sousCategorie) => sousCategorie.sca_cat_id === idCategorie)
  }
  
  //2.9 - Recherche dans la liste depuis un id
  public getSousCategorieFromId(idSousCategorie:number){
    return this.sousCategories$.getValue().find((res)=> res.sca_id===idSousCategorie)
  }

  public getDateNull(date:string | null):boolean{
    return date== this.CONST_DATE_NULL? true:false;
  }
}
