import { Injectable } from '@angular/core';
import { Cabinet, FiltreMission, FiltreRedirection, Mission, Redirection, RedirectionDossiers } from '../interface/wsa';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ApiErrorService } from 'src/app/services/support/api-error.service';

@Injectable({
  providedIn: 'root'
})
export class ApiWsaService {
  url : string = '/api/wsa'

  constructor(private http : HttpClient
            , private apiError:ApiErrorService
  ) { }

  public getCabinets(redirection:Redirection):Observable<Cabinet[]>{
    
    return this.http.put<Cabinet[]>(this.url+'/cabinets',redirection)
                    .pipe(catchError(this.apiError.handleError<Cabinet[]>('getCabinets',[])));
  }

  public createRedirection(redirection:Redirection):Observable<any>{
    
    return this.http.post(this.url,redirection)
                    .pipe(catchError(this.apiError.handleError('createRedirection',null)));
  }

  public deleteRedirection(redirection:Redirection):Observable<any>{
    
    return this.http.put(this.url+'/supprimer',redirection)
                    .pipe(catchError(this.apiError.handleError('deleteRedirection',null)));
  }


  public getMissions(FiltreMission:FiltreMission):Observable<Mission[]>{    
    return this.http.post<Mission[]>(this.url+'/missions',FiltreMission)
                    .pipe(catchError(this.apiError.handleError('getMission',[])))
  }

  public redirigerMissions(redirection:RedirectionDossiers){
    return this.http.post<RedirectionDossiers>(this.url + '/missions/redirection',redirection)
  }

  public remettreADispositioMissions(redirection:RedirectionDossiers){
    return this.http.post<RedirectionDossiers>(this.url + '/missions/remise',redirection)
  }

  public getHistorique(filtre:FiltreRedirection):Observable<Redirection[]>{
    return this.http.post<Redirection[]>(this.url+'/redirections',filtre)
                    .pipe(catchError(this.apiError.handleError('getHistorique',[])))
  }

}
