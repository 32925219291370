import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FiltreContenu, Operation } from '../../interfaces/contenu';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { EMPTY, Observable } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-filter-email',
    templateUrl: './filter-email.component.html',
    styleUrls: ['./filter-email.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, NgIf, MatSelectModule, MatOptionModule, NgFor, MatButtonModule, MatIconModule, MatProgressSpinnerModule, AsyncPipe]
})
export class FilterEmailComponent implements OnInit {

  @Input()  waiting         : boolean = false
  @Input()  operations$     : Observable<Operation[]> = EMPTY
  @Output() filterEvent     = new EventEmitter<FiltreContenu>()
  @Output() createMail      = new EventEmitter<boolean>()
  
  filterForm!               : FormGroup 
  constructor() { }

  ngOnInit(): void {
    this.initFormGroup()
  }

  initFormGroup(){
    this.filterForm = new FormGroup({
        idOperation   : new FormControl(''),
        sujet         : new FormControl(''),
        contenu       : new FormControl(''),
    })
  }

  onSearchEmail(){
    
    this.filterEvent.next(this.filterForm.value)
  }

  onCreate(){
    this;this.createMail.next(true)
  }
}
