import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataAccessService } from '../../administration-extranet/gestion-prestations/services/data-access.service';
import { Analyse, AnalyseFilter, AnalyseInitialFilter } from 'src/app/interfaces/adela/analyse';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AsyncPipe, UpperCasePipe } from '@angular/common';
import { MatTableResponsiveDirective } from '../../../directive/mat-table-responsive/mat-table-responsive.directive';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-grille-tarifaire',
    templateUrl: './grille-tarifaire.component.html',
    styleUrls: ['./grille-tarifaire.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatTableModule, MatTableResponsiveDirective, AsyncPipe, UpperCasePipe]
})
export class GrilleTarifaireComponent implements OnInit {

  code_service : string = ''; // Code du service pour lequel on affiche la grille tarifaire  
  displayedColumns: string[] = ['prestation', 'prix_ht']; // Colonne à afficher dans la table
  filter : AnalyseFilter = AnalyseInitialFilter
  
  private analyseFilterSubject  = new BehaviorSubject<AnalyseFilter>({"affiche":"1"})
  filterAction$                 =  this.analyseFilterSubject.asObservable()

  prestationsDisplayed$ : Observable<Analyse[]> = combineLatest([
    this.prestationService.prestationsAll$
    , this.filterAction$
  ]).pipe(
    map(([prestations, filter]) => {
      return prestations.filter(
        (prestation:any) =>  (filter.affiche === "0" ? true : filter.affiche === "1"? prestation.affiche === true : prestation.affiche === false )
      );
    }),
    catchError(error => {
      console.error('Error in prestationsDisplayed$:', error);
      return of([]); // Return an empty array on error
    })
  );


  constructor(private route: ActivatedRoute
              , private prestationService : DataAccessService) { }


  ngOnInit(): void {
    this.code_service = this.route.snapshot.params['code_service'];
  }
}
