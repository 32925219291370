import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiAuthentificationService } from '../services/api-authentification.service';

@Injectable({
  providedIn: 'root'
})
export class ProtectAcquittementGuard  {

  constructor(private apiAuth : ApiAuthentificationService
            , private router  : Router){}
    
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const tree: UrlTree = this.router.parseUrl("/app/home");

    if(!this.apiAuth.userConnect.droits_utilisateur.droit_module_acquittement) {
      return tree;
    }

    return true;
  }
  
}
