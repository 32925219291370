import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, merge, of } from 'rxjs';
import { Suspension, SuspensionFiltre, SuspensionInitiale, SuspensionRappel } from '../interfaces/suspension';
import { map, mergeMap, scan } from 'rxjs/operators';
import { SuspensionService } from '../data_access/suspension.service';
import { _isNumberValue } from '@angular/cdk/coercion';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { CST_ACTION_ADD } from 'src/app/interfaces/constantes';

@Injectable({
  providedIn: 'root'
})
export class SuspensionFacadeService {

  constructor(private suspensionService : SuspensionService
              , private entiteService   : ApiEntiteService
              ) { }

  currentSuspensionSubject          = new BehaviorSubject<Suspension>(SuspensionInitiale)

  private suspensionAddedSubject    = new Subject<Suspension>()
  suspensionAddedAction$            = this.suspensionAddedSubject.asObservable()

  private suspensionRemovedSubject  = new Subject<any>()
  suspensionRemovedAction$          = this.suspensionRemovedSubject.asObservable()

  private suspensionModifiedSubject = new Subject<Suspension>()
  suspensionModifiedAction$         = this.suspensionModifiedSubject.asObservable()

  private suspensionFiltreSubject   = new Subject<SuspensionFiltre>()
  suspensionFiltreAction$           = this.suspensionFiltreSubject.asObservable().pipe(mergeMap(
    (filtre:SuspensionFiltre)=> {return this.suspensionService.getSuspensions(filtre)}
  ))

  
  suspensions$  = merge(
    this.suspensionFiltreAction$,
    this.suspensionAddedAction$,
    this.suspensionModifiedAction$,
    this.suspensionRemovedAction$
  ).pipe(
    scan((acc,value)=>{
      if (value instanceof Array) {
        return [...value] as Suspension[]
      }else if(_isNumberValue(value)){
        //We remove the Suspension
        const index = (acc as Suspension[]).findIndex((Suspension:Suspension) => Suspension.id === value)
          if (index) {
            (acc as Suspension[]).splice(index,1)
            return acc
          }else{
            //We didn't find in the array the Suspension (normally impossible!), so we do nothing and bring back the entire array
            return acc as Suspension[]
          }
      }else{
        if (value.action===CST_ACTION_ADD) {
          //We add the Suspension
          return [...acc as Suspension[],value] as Suspension[]
        }else{
          //We modify the Suspension
          return (acc as Suspension[]).map((Suspension:Suspension)=> Suspension.id===value.id? value : Suspension)
        
        }
      }
    })
  ) 

  
  add(suspension:Suspension){
    
    this.suspensionService.postSuspension(suspension).subscribe(
       (newSuspension)=>{
        if (newSuspension !=false) {
          this.currentSuspensionSubject.next(newSuspension as Suspension)
          this.suspensionAddedSubject.next(newSuspension as Suspension) 
        }
       }
      
    )    
  }

  remove(IdSuspension:number){
    this.suspensionService.deleteSuspension(IdSuspension).subscribe(
     ()=>{
        this.suspensionRemovedSubject.next(IdSuspension)
      }
    )
  }

  modify(suspension:Suspension){
    this.suspensionService.putSuspension(suspension).subscribe(
      (modifiedSuspension)=>{if (modifiedSuspension !=false) {
        
        this.currentSuspensionSubject.next(modifiedSuspension as Suspension)
        this.suspensionModifiedSubject.next(modifiedSuspension as Suspension)
      }
      }
    )
  }

  filter(suspensionFiltre:SuspensionFiltre){
    this.suspensionFiltreSubject.next(suspensionFiltre)
  }

  getSuspension(idSuspension : number){
    return this.suspensionService.getSuspension(idSuspension)
  }

  getEntite(idEntite:number){
    return this.entiteService.getEntite(idEntite)
  }

  getEntiteOrSuspension(idEntite:number):Observable<Entite|Suspension |null>{
    return this.suspensionService.getSuspensionFromEntite(idEntite).pipe(map((suspension:Suspension)=>{
      if (suspension.id > 0) {
        return suspension
      }else{
        return this.getEntite(idEntite)
      }
    }))
  }

  sendRecall(rappel:SuspensionRappel){
    return this.suspensionService.postRappel(rappel)
  }

  cloturer(cloture:SuspensionRappel){
    switch (cloture.index) {
      case 1:
        return this.suspensionService.clotureSuspension(cloture.id)
        case 2:
        return this.suspensionService.clotureCabinet(cloture.id)
      default:
        return of(false)
    }
  }

  getOrdinalRappel(index:number):string{
    switch (index) {
      case 1:
        return '1er'
      case 2:
        return '2nd'
      default:
        return index+'ème'
    }
  }

  telecharger(){
    return this.suspensionService.telechargerRapport()
   }
}
