<mat-card-header>
    <mat-card-title>Modification de paramètre</mat-card-title>
</mat-card-header>

<mat-card-content [formGroup]="parametreForm">
    <mat-form-field>
        <mat-label>Libellé</mat-label>
        <input matInput type="text" formControlName="parametreNote">
    </mat-form-field>

    <mat-form-field class="textarea">
        <mat-label>Valeur</mat-label>
        <textarea matInput type="text" formControlName="parametreValeur"></textarea>
    </mat-form-field>
</mat-card-content>

<mat-card-actions align="end">
    <button mat-raised-button color = "primary" (click) = "onValidate()">Enregistrer</button>
    <button mat-raised-button color = "primary" (click) = "onCancel()">Annuler</button>
</mat-card-actions>