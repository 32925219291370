import { Component, OnInit, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

export interface DialogData {
  photo: any;
}

@Component({
    selector: 'app-dialog-photo-resultat-analyse-adela',
    templateUrl: './dialog-photo-resultat-analyse-adela.component.html',
    styleUrls: ['./dialog-photo-resultat-analyse-adela.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatIconModule]
})

export class DialogPhotoResultatAnalyseAdelaComponent implements OnInit {

  constructor(public _sanitizer                     : DomSanitizer
            , public dialogRef                      : MatDialogRef<DialogPhotoResultatAnalyseAdelaComponent>
            , @Inject(MAT_DIALOG_DATA) public data  : DialogData) { }

  ngOnInit(): void {
  }

  onClickClose(): void {
    this.dialogRef.close();
  }
}
