import { NgFor, NgIf } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { TelephoneDirective } from 'src/app/directive/telephone.directive';
import { Adresse, AdresseInit } from 'src/app/interfaces/adresse/adresse';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { TypeEntite } from 'src/app/interfaces/profil-user/type-entite';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { ApiRelUtilisateurEntiteService } from 'src/app/services/api-rel-utilisateur-entite.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { CheckValidatorService } from 'src/app/services/tools/check-validator.service';
import { TelephoneDirective as TelephoneDirective_1 } from '../../../../directive/telephone.directive';
import { AdresseComponent } from '../../../templates/adresse/adresse.component';

@Component({
    selector: 'app-entite-service',
    templateUrl: './entite-service.component.html',
    styleUrls: ['./entite-service.component.scss'],
    standalone: true,
    imports: [NgIf, MatProgressSpinnerModule, ReactiveFormsModule, MatCardModule, MatIconModule, MatFormFieldModule, MatInputModule, MatSelectModule, NgFor, MatOptionModule, AdresseComponent, TelephoneDirective_1, MatButtonModule]
})
export class EntiteServiceComponent implements OnInit {

  @Input() isDirigeant: boolean = false;
  
  waiting_save                : boolean = false;
  // isDirigeant                 : boolean = false;
  disableSelect               = new FormControl(true);

  ent_id                      : number = 0;
  utl_id                      : number = 0;

  typeEntites                 : Array<TypeEntite> = new Array<TypeEntite>();  
  typeEntiteSelected          : number = 0;
  typeEntiteCode              : string = '';  

  // Formulaire
  typeEntiteForm = this._fb.group({
    id: [0],
    libelle: ['']
  });

   //Intégration du composant Adresse:
   adresseEntite           : Adresse = AdresseInit
   dirty                   : boolean = false;
   invalidAdresse          : boolean = false;

  // description d'un schema de validation de données
  entiteForm = this._fb.group({
      id                      : [0, Validators.required],
      idexterne               : [0],
      id_entite_principale    : [0],
      raison_sociale          : ['', Validators.required], 
      logo                    : [null], 
      id_type_entite          : [0],
      typ_ent_nom             : [''],
      habilitation            : [null],
      indice                  : [null],
      compteTiers             : [null], 
      siren                   : [null], 
      siret                   : [null], 
      adresse                 : [null], 
      adresse_cplt            : [null], 
      ville                   : [null], 
      code_postal             : [null], 
      telephone               : [null, [Validators.required, this.checkNumberPhone]], 
      fax                     : [null], 
      mail_facturation        : [null], 
      mail_statistique        : [null], 
      mail_contact            : [null, [Validators.required, CheckValidatorService.checkMail]], 
      EstActif                : [0], 
      EstInscrit              : [null], 
      numero_client           : [null]
  }) as FormGroup;

  constructor(
    private   apiAuth                 : ApiAuthentificationService
    , private apiEntite               : ApiEntiteService
    , private apiRelUtilisateurEntite : ApiRelUtilisateurEntiteService
    , private toast                   : PersoSnackbarService
    , private routed                  : ActivatedRoute
    , private _fb                     : FormBuilder
    , private check                   : CheckValidatorService
    , private phoneDirective        : TelephoneDirective
  ) { }

  ngOnInit(): void {
    // Récupération de l'identifiant entité saisie en paramètre de l'URL
    this.ent_id     = this.routed.snapshot.params['ent_id'];

    // Désactiver la saisie sur le propriété suivante du formulaire
    this.entiteForm.controls['numero_client'].disable();

    this.chargeEntite()
    
  }

  getInvalidAdresseBack(invalid:boolean){
    this.invalidAdresse = invalid
  }

  getAdresseBack(adresse:Adresse){
    this.adresseEntite = adresse
    this.dirty = true;
  }

  private chargeEntite() {
    
    this.apiEntite.getEntiteById(this.ent_id)
    .pipe(
      tap((entites: Entite[]) => { 
        //this.$Entite = entites[0]; 
        this.typeEntiteSelected = entites[0].id_type_entite; 
        this.adresseEntite = {...this.adresseEntite
          ,"ligne1"      : entites[0].adresse
          ,"cp"         : entites[0].code_postal
          ,"ville"      : entites[0].ville
          ,"ligne2"  : entites[0].adresse_cplt
          ,"ligne3"  : entites[0].adresse_ligne3
        }
        this.entiteForm.patchValue({
          id                  : entites[0].id,
          id_entite_principale: entites[0].id_entite_principale,
          raison_sociale      : entites[0].raison_sociale,
          numero_client       : entites[0].numero_client,
          id_type_entite      : entites[0].id_type_entite,
          typ_ent_nom         : entites[0].typ_ent_nom,
          telephone           : entites[0].telephone,
          mail_contact        : entites[0].mail_contact,
          EstActif            : entites[0].EstActif,
        })
      })
      ,switchMap(() => this.apiEntite.getAllTypeEntite( this.apiAuth.CONST_ENV_CODE_SERVICE))
      ,tap((type_entites: TypeEntite[]) => { this.typeEntites = type_entites })
      ,catchError((error: HttpErrorResponse) => { 
        this.toast.showError(error.error);
        return throwError(error);
      })
    ).subscribe(() => {});   
  }
  

  public enregistreEntite() { 
    this.waiting_save = true;

    let $Entite : Entite = <Entite>{};
    $Entite.id                   = this.entiteForm.controls.id.value                  
    $Entite.id_entite_principale = this.entiteForm.controls.id_entite_principale.value 
    $Entite.raison_sociale       = this.entiteForm.controls.raison_sociale.value       
    $Entite.numero_client        = this.entiteForm.controls.numero_client.value        
    $Entite.id_type_entite       = this.entiteForm.controls.id_type_entite.value       
    $Entite.typ_ent_nom          = this.entiteForm.controls.typ_ent_nom.value          
    $Entite.adresse              = this.entiteForm.get('adresse.adresseLigne1')!.value              
    $Entite.adresse_cplt         = this.entiteForm.get('adresse.adresseLigne2')!.value
    $Entite.adresse_ligne3       = this.entiteForm.get('adresse.adresseLigne3')!.value                      
    $Entite.ville                = this.entiteForm.get('adresse.adresseVille')!.value                              
    $Entite.code_postal          = this.entiteForm.get('adresse.adresseCP')!.value              
    $Entite.telephone            = this.phoneDirective.getPhoneNumberWithoutFormat(this.entiteForm.controls.telephone.value)            
    $Entite.mail_contact         = this.entiteForm.controls.mail_contact.value         
    $Entite.EstActif             = this.entiteForm.controls.EstActif.value             
  

    this.apiEntite.postEntite($Entite, this.apiAuth.userConnect.id)
    .subscribe(
      (data: Entite) => {
        this.toast.validate('Enregistrement effectué')
        this.waiting_save = false;
      },
      (err) => { 
        this.toast.showError(err.message);
        this.waiting_save = false; },
      () => { this.waiting_save = false }
    )
  }

  public selectionneTypeEntite($e: any) {

  }

  // Controle du format de téléphone : +33 4 00 00 00 00 / 04 00 00 00 00
  checkNumberPhone(control: FormControl) {
    let regExp = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
    if (control.value === '') { return null};
    return (regExp.test(control.value) ? null : { erreur: 'Le format du numéro de téléphone est incorrect' });
  }

}
