<div *ngIf="modeEdit===false">
    <mat-expansion-panel *ngFor="let entite of entites" class="expansionTitle">
        <mat-expansion-panel-header >
            <mat-icon ><span class="material-symbols-outlined">home_work</span></mat-icon>
            <span style="margin-left: 10px;">{{entite.raison_sociale}}</span>
        </mat-expansion-panel-header>
        <div class="div-container-right">
            <button 
                mat-mini-fab 
                color       = "primary" 
                matTooltip  = "Ajouter une adresse à {{entite.raison_sociale}}" 
                (click)     = "addAddress(entite.id? entite.id : 0)">
                <mat-icon>add</mat-icon>
            </button>
        </div>
        
        <mat-nav-list *ngFor="let adresse of entite.adresses">
            <div class="div-container-left">
                <a  mat-list-item  
                    class               = "listItem"
                    routerLinkActive    = "activeLink" 
                    (click)             = "selectAddress(adresse,entite)"
                    >
                    <div style="display:flex;justify-content: start;align-items: center;">
                        <mat-icon><span class="material-symbols-outlined">
                            mail
                        </span></mat-icon>
                        <span style="margin-left: 10px;" >{{getFormatedAddress(adresse)}}</span>
                    </div>
                        
                    
                </a>
                <button 
                    mat-mini-fab
                    matTooltip  = "éditer l'adresse" 
                    color       = "accent" 
                    (click)     = "modifyAddress(adresse,entite.id? entite.id : 0)">
                    <mat-icon>edit</mat-icon>
                </button>
            </div>
        </mat-nav-list>
    </mat-expansion-panel>
</div>
<app-edit-adresse 
    *ngIf           = "modeEdit===true" 
    [adresse]       = "addressToEdit" 
    [typesAdresse]  = "typesAdresse" 
    (adresseEvent)  = "getAdresseEdited($event)"
    ></app-edit-adresse>