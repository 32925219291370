import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { RelationUtilisateurEntite } from 'src/app/interfaces/utilisateur-entite/relation-utilisateur-entite';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiRelUtilisateurEntiteService } from 'src/app/services/api-rel-utilisateur-entite.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgIf, NgFor, UpperCasePipe } from '@angular/common';
import { MatTableResponsiveLargeDirective } from '../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';

export interface typeCollaborateur {
  code          : string,
  libelle       : string,
  est_dirigeant : boolean
}
export interface OutputParent2{
  disabled_informations_generales   : boolean,
  disabled_invitation_collaborateur : boolean,
  disabled_liste_collaborateur      : boolean
}
@Component({
    selector: 'app-invitation-liste-collaborateur',
    templateUrl: './invitation-liste-collaborateur.component.html',
    styleUrls: ['./invitation-liste-collaborateur.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatIconModule, MatTableModule, MatTableResponsiveLargeDirective, NgIf, MatSelectModule, NgFor, MatOptionModule, MatSlideToggleModule, MatPaginatorModule, MatButtonModule, MatProgressSpinnerModule, UpperCasePipe]
})
export class InvitationListeCollaborateurComponent implements OnInit {

  // Formulaire
  collaborateursForm = this.fBuilder.group({
    rows: this.fBuilder.array([])   
  });

  type_collaborateurs: Array<typeCollaborateur> = [
    {
      code : 'EXPERT',
      libelle : 'Expert',
      est_dirigeant: false
    },
    {
      code: 'DIRIGEANT',
      libelle: 'Dirigeant',
      est_dirigeant: true
    }
  ];

  // Gestion de l'affichage
  isDirigeant                     : boolean = false;
  waiting_save                    : boolean = false;

  // Variables d'entrées
  @Input() id_entite_principale   : number = 0;
  @Input() id_entite_succursale   : number | undefined;

  RelUtilisateurEntite            : Array<RelationUtilisateurEntite> = new Array<RelationUtilisateurEntite> ();  
  userConnect                     : ProfilUtilisateur = <ProfilUtilisateur>{};
  
  // Gestion du tableau  
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  dataSource: MatTableDataSource<RelationUtilisateurEntite> = new MatTableDataSource(this.RelUtilisateurEntite);
  displayedColumns: string[] = ['photo_profil', 'nom', 'prenom', 'est_dirigeant', 'est_active'];    
  
  // Gestion de l'évènement transmis au component parent
  @Output() cellClicked = new EventEmitter<OutputParent2>();
  
  constructor(public _sanitizer: DomSanitizer
            , private apiRelUtilisateurEntite: ApiRelUtilisateurEntiteService
            , private apiAuth: ApiAuthentificationService
            , private fBuilder: FormBuilder
            , private toast: PersoSnackbarService) { }

  ngOnInit(): void {
    // On force le reset des valeurs par défaut, car lorsqu'on recharge le component invitation-liste-collaborateur,    
    // des valeurs peuvent déjà être configuré par une "session" précédente (session dans le sens appel depuis une autre page)
    // mais ne pas être réinitialisé car le component ne refait pas la déclaration des variables puisqu'il a déjà été initialisé par la "session" précédente    
    this.isDirigeant          = false;
    this.userConnect          = this.apiAuth.userConnect;
    this.RelUtilisateurEntite = new Array<RelationUtilisateurEntite> ();              
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      const chng = changes[propName];
      if(propName == 'id_entite_principale') {
        this.id_entite_principale = chng.currentValue;
      }

      if(propName == 'id_entite_succursale') {
        this.id_entite_succursale = chng.currentValue;
      }            
    }  

    if(this.id_entite_principale > 0 
      && this.id_entite_succursale! > 0 
      && this.id_entite_succursale! != this.id_entite_principale) {
      this.getData();      
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;        
  }  

  initRows(utl_id: number, ent_id: number, est_dirigeant: boolean, est_active: boolean) {
    return this.fBuilder.group({
      utilisateur_id          : [utl_id, Validators.required], 
      entite_id               : [ent_id, Validators.required], 
      est_dirigeant           : [est_dirigeant, Validators.required],
      est_active              : [est_active, Validators.required]
    });
   }

  getData(): void {
    this.collaborateursForm.controls.rows = this.fBuilder.array([]);

    // Récupération de la listes des utilisateurs associé à l'entité principale
    this.apiRelUtilisateurEntite.getRelUtilisateurEntite(0, this.id_entite_principale)
    .subscribe(
      (data: RelationUtilisateurEntite[]) => {        
        this.RelUtilisateurEntite = data;
        
        // Suppression des relations inactives, pour ne pas les afficher
        for(let i = this.RelUtilisateurEntite.length - 1; i >= 0; i--) {                    
          if (this.RelUtilisateurEntite[i].est_active == false) {
            this.RelUtilisateurEntite.splice(i,1);
          } else {
            if(this.RelUtilisateurEntite[i].est_dirigeant === true && this.RelUtilisateurEntite[i].entite_id == this.id_entite_principale && this.RelUtilisateurEntite[i].utilisateur_id == this.userConnect.id) {
              this.RelUtilisateurEntite.splice(i,1);
              this.isDirigeant = true;
            } else {
              // Affectation de la nouvelle entité à la relation
              if(this.id_entite_succursale != undefined) {
                this.RelUtilisateurEntite[i].entite_id = this.id_entite_succursale;
              }
            }
          }
        }
        
        for(let i = 0; i < this.RelUtilisateurEntite.length; i++) {
          (this.collaborateursForm.controls.rows as FormArray).push(this.initRows(
            this.RelUtilisateurEntite[i].utilisateur_id,
            this.RelUtilisateurEntite[i].entite_id,
            this.RelUtilisateurEntite[i].est_dirigeant,
            this.RelUtilisateurEntite[i].est_active
          ));
        }
                        
        this.dataSource.data = this.RelUtilisateurEntite;
        }, 
      (err) => { console.log(err) }
    );
  }

  onToggle(event: MatSlideToggleChange, relation: RelationUtilisateurEntite): void {
    relation.est_active = event.checked
  }

  enregistrerCollaborateurs(): void {
    this.waiting_save = true;
    this.RelUtilisateurEntite = this.collaborateursForm.controls.rows.value as RelationUtilisateurEntite[];

    this.apiRelUtilisateurEntite.postListeCollaborateur(this.RelUtilisateurEntite)
      .subscribe(
        (liste_collaborateur: Array<RelationUtilisateurEntite>) => {
          this.RelUtilisateurEntite = liste_collaborateur;
          this.toast.showInfo('Enregistrement des collaborateurs effectué.');

          // emit event to parent
          this.cellClicked.emit({
            disabled_informations_generales   : true,
            disabled_liste_collaborateur      : true,
            disabled_invitation_collaborateur : false
          });

          this.waiting_save = false;
        },
        (error: any) => {
          console.error('/!\\ error postListeCollaborateur: ' + error)
          this.toast.showError('Erreur lors de l\'enregistrement des collaborateurs.');
          this.waiting_save = false;
        }
      );
  }
}
