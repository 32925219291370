import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GouvService {

  /* 
    Dans ce service l'utilisation de la classe HttpClient "habituelle" est à proscrire
    car la requette http nous renvoie une erreur cors policy causée par la présence
    du champ authorization dans l'entête http qui est ajoutée par notre intercepteur HTTP.

    l'idée pour un bon fonctionnement est de court circuiter cet intercepteur HTTP
    en instanciant la classe httpClient en passant un objet de type HttpBackend en paramètre du constructeur.
    Ce qui nous permet de retrouver dans ce service une couche http client native.
  */

  private http: HttpClient;

  constructor(private handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }

  getAdresse(adresse:string):any{
    return this.http.get<string>(environment.urlApiGouv +'/search?q='+adresse)
  }
}
