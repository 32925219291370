import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MailFilter } from '../../mail-interface';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-mail-filter',
  templateUrl: './mail-filter.component.html',
  styleUrls: ['./mail-filter.component.scss'],
  standalone:true,
  imports:[ ReactiveFormsModule, MatInputModule, MatSelectModule, MatButtonModule, MatNativeDateModule,
            MatFormFieldModule,MatDatepickerModule,MatCardModule,MatIconModule]
})
export class MailFilterComponent {

  @Output() filterChange = new EventEmitter<MailFilter>();

  filterForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.filterForm = this.fb.group({
      status        : [''],
      typeEnvoi     : [''],
      dateStart     : [''],
      dateEnd       : [''],
      destinataire  : [''],
      sujet         : [''],
      contenu       : ['']
    });
  }

  applyFilter() {
    this.filterChange.emit(this.filterForm.value);
  }
}
