export interface Suivi{
    id?                 : number
    immat?              : string,
    dossier?            : string,
    date_creation?      : Date,
    date_envoi?         : Date,
    id_entite?          : number
    nom_entite?         : string
    statut?             : number
    habiliation?        : string
}

export const SuiviInit:Suivi = {
    'statut': 0,
    'id'    : 0
}
export interface SuiviFiltre{
    immat?               : string,
    dossier?             : string,
    date_creation_debut? : string,
    date_creation_fin?   : string
    date_envoi_debut?    : string
    date_envoi_fin?      : string
    archive?             : boolean
    idEntite?            : number
}

export const SuiviFiltreInitial:SuiviFiltre = {
        'dossier'               :''
    ,   'immat'                 : ''
    ,   'date_creation_debut'   : ''
    ,   'date_creation_fin'     : ''
    ,   'date_envoi_debut'      : ''
    ,   'date_envoi_fin'        : ''
    ,   'archive'               : false
    ,   'idEntite'              : 0
}

export interface ErreurSiv{
    code    : number,
    libelle : string
}
export interface SuiviSiv {
    type            : number,
    envoisiv        : number,
    code_traitement : number,
    date_recu       : Date,
    date_reponse    : Date
    erreurs         : ErreurSiv[]

}