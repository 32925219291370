import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ExportTableComponent } from '../../../../templates/export-table/export-table.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatTableResponsiveLargeDirective } from '../../../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';
import { NgIf, NgStyle, DecimalPipe, DatePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-table-historique-prelevements',
    templateUrl: './table-historique-prelevements.component.html',
    styleUrls: ['./table-historique-prelevements.component.scss'],
    standalone: true,
    imports: [MatExpansionModule, MatIconModule, NgIf, MatTableModule, MatTableResponsiveLargeDirective, MatTableExporterModule
            , MatTooltipModule, NgStyle, ExportTableComponent, MatPaginatorModule, DecimalPipe, DatePipe,MatButtonModule]
})
export class TableHistoriquePrelevementsComponent implements OnInit {
  
  CONST_CODE_ETAT_PRELEVEMENT_A_PRELEVER  : string = 'A_PRELEVER' as const;
  CONST_CODE_ETAT_PRELEVEMENT_ENVOYE      : string = 'ENVOYE'     as const;
  CONST_CODE_ETAT_PRELEVEMENT_PRELEVE     : string = 'PRELEVE'    as const;
  CONST_CODE_ETAT_PRELEVEMENT_ERREUR_IBAN : string = 'ERREUR'     as const;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;
  @Input() dataSource_prelevements: any;
  @Input() numero_contact : string = '';

  baseFileExportName: string = 'EXTRANET - Paiement'
  fileExportName    : string = '';

  dataSourceMatTable: MatTableDataSource<any> = new MatTableDataSource<any>();
  
  expandPannel        : boolean = false;

  innerWidth: any;
  innerHeight: any;
  
  // Tableau des prélèvements
  displayedColumns    : Array<string> = [ 'date_creation', 'prl_type', 'prl_montant_ordre','prl_facture', 'etat', 'actions'];
  
  constructor(private route: Router) { }

  ngOnInit(): void {
    
    if(this.dataSource_prelevements != undefined) {
      this.dataSourceMatTable.data = this.dataSource_prelevements;
      if(this.dataSourceMatTable.data.length > 0) {
        
        this.expandPannel = true;
      }      
    }
  }

  ngAfterViewInit() {
    this.dataSourceMatTable.paginator = this.paginator;
    this.dataSourceMatTable.sort      = this.sort;
  }

  ngOnChanges() {
    // obligatoire
    if(this.dataSource_prelevements != undefined) {
      this.dataSourceMatTable.data = this.dataSource_prelevements;
      if(this.dataSourceMatTable.data.length > 0) {
        this.expandPannel = true;
      }      
    }   
    
    if(this.numero_contact != '') {
      this.fileExportName = this.baseFileExportName + ' - Contact ' + this.numero_contact;
    } else {
      this.fileExportName = this.baseFileExportName;
    }
  }

  public openFacture(id: number) {
    const url = this.route.serializeUrl(
        this.route.createUrlTree(['/app/facture/recap-facture', id, 0])
    );
    window.open(url, '_blank');
}

}
