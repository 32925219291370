import { _isNumberValue } from '@angular/cdk/coercion';
import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { fromEvent } from 'rxjs';


@Directive({
    selector: '[appImmat]',
    standalone: true
})


export class ImmatDirective {
  
  @Input() immatriculation!: string;
  @Output() valueChanged: EventEmitter<string> = new EventEmitter();
  
  actualValue       : string = "";
  transformedValue  : string = "";
  systemeImmat      : number = 0 //1 -> ancien système : 1234-AB-56, 2 -> nouveau système : AB-123-CD 
  separator         : string = '-'

  constructor(private element: ElementRef) {
    
    fromEvent(element.nativeElement, "input").subscribe(() => {
      this.transformValue(this.element.nativeElement.value);
    });
  }

  ngAfterViewChecked() {
    let elementType:string='input'

    let value = this.element.nativeElement.value

    if (value===undefined) {
      value = this.element.nativeElement.innerText
      elementType='innerText'
    }
    
    this.transformValue(value,elementType);
    }

   transformValue(value:string,elementType:string='input') {
  
    value = value.replace(/\W|_/g,'')
    
    if (value.length > this.actualValue.length) {
      this.actualValue =
        this.actualValue + value.slice(this.actualValue.length, value.length);
    } else {
      this.actualValue = value;
    }
    
    this.valueChanged.emit(this.actualValue);

    //analyse de la chaîne de caractères:
    const chaineSaisie = this.actualValue
    let chaineResult:string = '';
    
    this.getSysteme()

    for (let index = 0; index < chaineSaisie.length; index++) {
      const element = chaineSaisie[index];
      if (_isNumberValue(element)) {
        if (this.systemeImmat === 2) {
          //nouveau système d'immatriculation AB-123-CD
          if (index < 5 && index > 1) {
            chaineResult += element
          }
          
        }else{
          //ancien système d'immatriculation 1[234]-AB[C]-56
          switch (index) {
            case 0:
            case 1:
              chaineResult += element
              break;
            
              case 2:
              if (_isNumberValue(chaineResult[1])) {
                chaineResult += element
              }
              break;
            
              case 3:
                  if (_isNumberValue(chaineResult[index-1]) ||(!_isNumberValue(chaineResult[index-1]) && !_isNumberValue(chaineResult[index-2]))) {
                    chaineResult += element
                  }
                break;
              case 4:
                  if (!_isNumberValue(chaineResult[2])) {
                    chaineResult += element
                  }
                break;
              case 5:
                  if (!_isNumberValue(chaineResult[3])) {
                    chaineResult += element
                  }
                break;
              case 6:
              case 7:
              case 8:
                if (!_isNumberValue(chaineResult[index-1]) ||!_isNumberValue(chaineResult[index-2])) {
                  chaineResult += element
                }
                break;
            default:
              break;
          }
        }
      }else{
        //Le caractère est une lettre
        if (this.systemeImmat === 2) {
          //AB-123-CD
          if (index < 2 || index > 4 && index < 7) {
            chaineResult += element
          }
        }else{
          //ancien système 1[234]-AB[C]-56
          switch (index) {
            case 1:
            case 2:
            case 3:
              chaineResult += element
              break;
            case 4:
            case 5:
            case 6:
              if (_isNumberValue(chaineResult[index-3])) {
                chaineResult += element
              }
              break;
            default:
              break;
          }
        }
      }
    }
    this.actualValue = chaineResult
    this.transformedValue = this.formatValue(this.actualValue)
  
    if (elementType==='input') {
      this.element.nativeElement.value = this.transformedValue;
    }else{
      this.element.nativeElement.innerText = this.transformedValue;
    }
    this.element.nativeElement.style.color = '#273a82'
    this.element.nativeElement.style.fontWeight = 'bold'
    
}
  formatValue(value:string):string{
    const s           : string = value
    let valueFormated : string = '';
    
    for (let index = 0;  index < s.length; index++){
      const element = s[index];
      if (index===0) {
        valueFormated = s[index]
      }else{
        
        if (this.getSeparation(s[index],s[index - 1])){
          valueFormated += this.separator + s[index]
        }else{
          valueFormated += s[index]
        }
        
      }
    }
    
    return valueFormated.toUpperCase()
  }
  getSeparation(index1:string,index2:string){
    if ((_isNumberValue(index1) && !_isNumberValue(index2)) || (!_isNumberValue(index1) && _isNumberValue(index2)) ) {
      return true
    }else{
      return false
    }
  }
  getSysteme(){
    if (this.actualValue.slice(0,1).match(/[a-zA-Z]/i)) {
      this.systemeImmat = 2
    }else{
      this.systemeImmat = 1
    }
  }
  
}
