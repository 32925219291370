import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InvitationFilter } from '../interface/invitation-filter.interface';
import { EtatInvitation } from '../interface/etat.interface';
import { InvitationCollaborateurBdd } from 'src/app/interfaces/invitation-collaborateur/invitation-collaborateur-bdd';

@Injectable({
  providedIn: 'root'
})
export class InvitationService {

  private url = '/api';

  constructor(private http: HttpClient) {}

  getEtats(): Observable<EtatInvitation[]> {
    return this.http.get<EtatInvitation[]>(`${this.url}/invitation-collaborateur/etats`);
  }

  getInvitations(filter: InvitationFilter): Observable<InvitationCollaborateurBdd[]> {
    return this.http.post<InvitationCollaborateurBdd[]>(`${this.url}/invitation-collaborateur/liste`, filter);
  }

  annuleInvitation(idInvitation:number):Observable<any>{
    const annul = {'id':idInvitation}
    return this.http.put(`${this.url}/invitation-collaborateur/annuler`,annul)
  }
}
