import { Component, OnInit } from '@angular/core';
import { EntiteTva } from 'src/app/interfaces/profil-user/entite-tva';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { HabilitationComponent } from '../habilitation/habilitation.component';
import { TauxTvaComponent } from '../taux-tva/taux-tva.component';

@Component({
    selector: 'app-shell-tva-habiliation',
    templateUrl: './shell-tva-habiliation.component.html',
    styleUrls: ['./shell-tva-habiliation.component.scss'],
    standalone: true,
    imports: [TauxTvaComponent, HabilitationComponent]
})
export class ShellTvaHabiliationComponent implements OnInit {
  
  tauxTva : EntiteTva[] = []

  constructor(private apiEntiteService : ApiEntiteService) { }
  
  maxAnnee : string = ''
  minAnnee : string = '2019'
  
  ngOnInit(): void {
    this.maxAnnee = new Date().getFullYear().toString()
    this.getTauxTva()
  }

  getTauxTva(){
    this.apiEntiteService.getTVA().subscribe(
      (data)=> this.tauxTva = data
    )
  }
}
