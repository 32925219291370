import { Component, Input, OnInit } from '@angular/core';
import { LegendPosition, PieChartModule } from '@swimlane/ngx-charts';

@Component({
    selector: 'app-repartition-vge-vei',
    templateUrl: './repartition-vge-vei.component.html',
    styleUrls: ['./repartition-vge-vei.component.scss'],
    standalone: true,
    imports: [PieChartModule]
})
export class RepartitionVgeVeiComponent implements OnInit {

  view: any;

  public chartData: {
    name: string,
    value ? : string | number
  } [];  

  // Données à afficher
  @Input() ELEMENT_DATA_TOTAL_VGE: number = 0;
  @Input() ELEMENT_DATA_TOTAL_VEI: number = 0;  

  // Options
  legendPosition = LegendPosition.Below;

  constructor() {
    this.chartData = [
      {
        name: 'VGE (0)',
        value: 0
      },
      {
        name: 'VEI (0)',
        value: 0
      }
    ];
   }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.chartData = [
      {
        name: 'VGE (' + this.ELEMENT_DATA_TOTAL_VGE + ')',
        value: this.ELEMENT_DATA_TOTAL_VGE
      },
      {
        name: 'VEI (' + this.ELEMENT_DATA_TOTAL_VEI + ')',
        value: this.ELEMENT_DATA_TOTAL_VEI
      }
    ];
  }
  
  public resizeChart(width: any): void {
    this.view = [width, 320]
  }
}
