import { Component, Input, OnInit } from '@angular/core';
import { LegendPosition, PieChartModule } from '@swimlane/ngx-charts';

@Component({
    selector: 'app-repartition-ve',
    templateUrl: './repartition-ve.component.html',
    styleUrls: ['./repartition-ve.component.scss'],
    standalone: true,
    imports: [PieChartModule]
})
export class RepartitionVeComponent implements OnInit {

  view: any;
  
  public chartData: {
    name: string,
    value ? : string | number
  } [];  

  // Données à afficher
  @Input() ELEMENT_DATA_TOTAL_VE_OUI: number = 0;
  @Input() ELEMENT_DATA_TOTAL_VE_NON: number = 0;  

  // Options
  legendPosition = LegendPosition.Below;
  
  constructor() {
    this.chartData = [
      {
        name: 'VE OUI (0)',
        value: 0
      },
      {
        name: 'VE NON (0)',
        value: 0
      }
    ];
   }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.chartData = [
      {
        name: 'VE OUI (' + this.ELEMENT_DATA_TOTAL_VE_OUI + ')',
        value: this.ELEMENT_DATA_TOTAL_VE_OUI
      },
      {
        name: 'VE NON (' + this.ELEMENT_DATA_TOTAL_VE_NON + ')',
        value: this.ELEMENT_DATA_TOTAL_VE_NON
      }
    ];
  }

  public resizeChart(width: any): void {
    this.view = [width, 320]
  }

}
