import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Adresse, AdresseInit } from 'src/app/interfaces/adresse/adresse';

@Component({
  selector: 'app-carte-adresse',
  templateUrl: './carte-adresse.component.html',
  styleUrls: ['./carte-adresse.component.scss'],
  standalone:true,
  imports:[NgIf]
})
export class CarteAdresseComponent {

  @Input() adresse  : Adresse = AdresseInit
  @Input() title    : string = ''

  ngOnChanges(){
    if (!this.title) {
      if (this.adresse.contact && this.adresse.contact!='') {
        this.title = 'Contact '+this.adresse.contact
      }
    }
  }
}
