            <div class="grid-container">
        
                <button mat-raised-button (click)="onCreer()" color="primary" class="grid-item">
                    <mat-icon>add</mat-icon>
                    <span class="span-button">Nouveau</span>
                </button>
                <button mat-raised-button  color="primary" (click)="onClikActiver()" style="background-color: #04970E; color: #FFFFFF;" class="grid-item">
                    <mat-icon>check</mat-icon>
                    <span class="span-button">Activer</span>                                               
                </button>
                <button mat-raised-button  (click)="onClikDesactiver()" color="warn" class="grid-item">
                    <mat-icon>close</mat-icon>
                    <span class="span-button">Désactiver</span>
                </button>
                <button mat-raised-button  color="primary" (click)="onClikIdentifiant()" class="grid-item">
                    <mat-icon>password</mat-icon>
                    <span class="span-button">Réinialiser le mot de passe concentrateur</span>
                </button>
            </div>

            <div class="container">
                <table mat-table matTableResponsiveLarge matTableExporter [dataSource]="dataSourceMatTable" #exporter="matTableExporter" [hiddenColumns]="[0,8]" class="table-primary">
                     
                    <!-- Checkbox Column -->
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef class="premiere-colonne" style="padding-left: 24px;">
                        <mat-checkbox (change)="$event ? toggleAllRows() : null" class="checkbox"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? selection.toggle(row) : null"
                                        [checked]="selection.isSelected(row)"
                                        class="checkbox">
                        </mat-checkbox>
                        </td>
                    </ng-container>

                     <!-- Type Entité --> 
                     <ng-container matColumnDef="typ_ent_nom">
                        <th mat-header-cell *matHeaderCellDef class="table-padding"> Type entité </th>
                        <td mat-cell *matCellDef="let Entite" class="table-padding"> {{ Entite.typ_ent_nom }} </td> 
                    </ng-container>

                    <!-- Type de facturation --> 
                    <ng-container matColumnDef="typ_fac_libelle">
                        <th mat-header-cell *matHeaderCellDef class="table-padding"> Type de facturation </th>
                        <td mat-cell *matCellDef="let Entite" class="table-padding"> {{ Entite.type_facturation.libelle_type_facturation }} </td> 
                    </ng-container>

                    typ_fac_libelle

                    <!-- Habilitation --> 
                    <ng-container matColumnDef="ent_habilitation">
                        <th mat-header-cell *matHeaderCellDef class="table-padding"> Habilitation </th>
                        <td mat-cell *matCellDef="let Entite" class="table-padding"> {{ Entite.habilitation }} </td> 
                    </ng-container>
                    
                    <!-- Numéro de contact -->
                    <ng-container matColumnDef="ent_numero_client">
                        <th mat-header-cell *matHeaderCellDef class="table-padding"> Contact </th>
                        <td mat-cell *matCellDef="let Entite" class="table-padding"> {{ Entite.numero_client }} </td>
                    </ng-container>
            
                    <!-- raison sociale -->
                    <ng-container matColumnDef="ent_raison_sociale">
                        <th mat-header-cell *matHeaderCellDef class="table-padding"> Raison sociale</th>
                        <td mat-cell *matCellDef="let Entite" class="table-padding"> {{ Entite.raison_sociale }} </td>
                    </ng-container>
                    
                    <!-- Statut -->
                    <ng-container matColumnDef="ent_active">
                        <th mat-header-cell *matHeaderCellDef class="table-padding"> État </th>
                        <td mat-cell *matCellDef="let Entite" class="table-padding"> 
                            <mat-icon [ngStyle]="{ color: (Entite.EstActif ? '#04970E' : '#FF4081')}" svgIcon="{{ Entite.EstActif ? 'check_small' : 'cancel' }}">
                                <span class="cdk-visually-hidden">{{ Entite.EstActif ? 'Validé' : 'Annulé' }}</span>
                            </mat-icon> 
                        </td>
                    </ng-container>
                    
                    <!-- CP -->
                    <ng-container matColumnDef="ent_code_postal">
                        <th mat-header-cell *matHeaderCellDef class="table-padding"> CP</th>
                        <td mat-cell *matCellDef="let Entite" class="table-padding"> {{ Entite.code_postal }} </td>
                    </ng-container>

                    <!-- Ville -->
                    <ng-container matColumnDef="ent_ville">
                        <th mat-header-cell *matHeaderCellDef class="table-padding"> Ville</th>
                        <td mat-cell *matCellDef="let Entite" class="table-padding"> {{ Entite.ville }} </td>
                    </ng-container>

                    <!-- SIREN -->
                    <ng-container matColumnDef="ent_siren">
                        <th mat-header-cell *matHeaderCellDef class="table-padding"> SIREN</th>
                        <td mat-cell *matCellDef="let Entite" class="table-padding"> {{ Entite.siren }} </td>
                    </ng-container>
                    
                    <!-- Ouvrir -->
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef class="derniere-colonne" style="text-align: right;"></th>
                        <td mat-cell *matCellDef="let Entite" class="colonne-module" style="text-align: right;">
                            <div class="div-container-left">
                                <button mat-icon-button class="button-etat" color="primary"
                                    style="width: 48px; height: 48px;"
                                    (click)="onClickOuvrir(Entite.id)">
                                    <mat-icon color="primary">edit</mat-icon>
                                </button>

                                <button mat-icon-button class="button-etat" color="warn"
                                    style="width: 48px; height: 48px;"
                                    (click)="addEntite(Entite)"
                                    
                                    >
                                    <mat-icon *ngIf="apiAuthUser.userConnect.droits_utilisateur.droit_module_suspension" color="warn" matTooltip="suspendre le cabinet">block</mat-icon>
                                </button>
                            </div>
                        </td>
                    </ng-container>
            
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <div class="div-container-between" style="align-items: center;background-color:white">
                    <app-export-table 
                            [fileExportName]="'EXTRANET - Entités'" 
                            [exporter]="exporter" 
                            [nb_ligne]="this.dataSourceMatTable.data.length"
                            ></app-export-table>
                    <mat-paginator
                        [pageSizeOptions]="[5,10,25]"
                        showFirstLastButtons 
                        aria-label="Nombre de lignes visibles...">
                    </mat-paginator>
                </div>
                
            </div>
        
    

