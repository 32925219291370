<mat-card appearance="outlined">
    <mat-card-header>
        <mat-card-title>Sélectionner votre filtre</mat-card-title>
    </mat-card-header>
    <mat-card-content class="mat-card-content">
    
        <div class="div-container">
            <div class="div-container" style="width: 100%;">
                <mat-form-field>
                    <mat-label>Numéro de dossier contient</mat-label>
                    <input  type        = "text" 
                            matInput 
                            [(ngModel)] = "filterDossier"
                            (keyupX)     = "setFilter.click()">
                            
                </mat-form-field>

                <mat-form-field >
                    <mat-label>Immatriculation</mat-label>
                    <input  type        = "text" 
                            matInput 
                            appImmat
                            [(ngModel)] = "filterImmat"
                            (keyupX)     = "setFilter.click()">                           
                </mat-form-field>
                
                <mat-form-field>
                    <mat-label>Cabinet</mat-label>
                    <mat-select [(ngModel)]="filterEntiteId">
                        <mat-option [value]="0">Tous</mat-option>
                        <mat-option *ngFor="let relUtient of relUtiEntites" [value]="relUtient.entite.id">
                            {{relUtient.entite.raison_sociale}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="div-container" style="width: 100%;">
                <mat-form-field class="formDate">
                    <mat-label>Créé à partir de</mat-label>
                    <input  matInput 
                            type ="date"
                            [(ngModel)]="filterDateCreationDebut"
                            (changeX)="setFilter.click()"
                            >
                            
                </mat-form-field>
        
                <mat-form-field class="formDate">
                    <mat-label>Créé jusqu'au</mat-label>
                    <input  matInput  
                            type="date"
                            [(ngModel)]="filterDateCreationFin"
                            (changeX)="setFilter.click()"
                            >
                            
                </mat-form-field>
        
                <mat-form-field class="formDate">
                    <mat-label>Envoyé à partir de</mat-label>
                    <input  matInput 
                            type ="date"
                            [(ngModel)]="filterDateEnvoiDebut"
                            (changeX)="setFilter.click()">
                            
                </mat-form-field>
        
                <mat-form-field class="formDate">
                    <mat-label>Envoyé jusqu'au</mat-label>
                    <input  matInput  
                            type="date"
                            [(ngModel)]="filterDateEnvoiFin"
                            (changeX)="setFilter.click()"
                            >
                            
                </mat-form-field>
                <mat-slide-toggle [(ngModel)]="filterArchive">{{filterArchive? "Archivés" : "En cours"}}</mat-slide-toggle>
            </div>
        </div>
    </mat-card-content>

    <mat-card-actions>
        <div class="div-container-between" style="padding: 8px;">
            <div class="div-container-left" style="width:100%">
                <button mat-stroked-button type="button" color="basic" class="label-primary" (click)="newSuivi()">
                    <mat-icon>upload_file</mat-icon>
                    Nouvelle opération concentrateur
                </button> 
            </div>
            <div class="div-container-right"> 
                <button mat-raised-button color="primary" (click)="selectFilter()" *ngIf="!waitingResearch"><mat-icon>search</mat-icon>Rechercher</button>
                <button style="display: none;" #setFilter (click)="selectFilter()"></button>
                <mat-spinner diameter="24" *ngIf="waitingResearch"></mat-spinner>
            </div>
        </div>
    </mat-card-actions>
</mat-card>

