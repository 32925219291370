<mat-tab-group [selectedIndex]="selected.value" animationDuration="0ms" #tab class ="tabretrait">
    <mat-tab>
          <ng-template mat-tab-label>
                <mat-icon>account_balance</mat-icon>
                <span class="mat-tab-label">Coordonnées bancaires</span>
          </ng-template>

          <app-liste-informations-bancaires></app-liste-informations-bancaires>
    </mat-tab>

    <mat-tab>
          <ng-template mat-tab-label>
                <mat-icon>history</mat-icon>
                <span class="mat-tab-label">Historique prélèvements</span>
          </ng-template>

          <app-liste-historique-prelevements></app-liste-historique-prelevements>
    </mat-tab>
</mat-tab-group>