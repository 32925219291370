<mat-card appearance="outlined">
    <form [formGroup]="filterForm">

        <mat-form-field>
                <mat-label>Sujet</mat-label>
                <input 
                    matInput 
                    type            = "text" 
                    placeholder     = "Le sujet contient..."  
                    formControlName = "sujet" 
                    oninput         = "this.value = this.value.toUpperCase()"
                    >
                
            </mat-form-field> 

            <mat-form-field>
                <mat-label>Contenu</mat-label>
                <input 
                    matInput 
                    type            = "text" 
                    placeholder     = "Le corps du mail contient..." 
                    formControlName = "contenu"
                    >
                
            </mat-form-field> 
            
            <mat-form-field *ngIf="operations$|async as operations" style="max-width: 500px!important;">
                <mat-label>Opération</mat-label>
                <mat-select formControlName="idOperation">
                    <mat-option [value]="0">Toutes</mat-option>
                    <mat-option *ngFor="let operation of operations" [value]= operation.id>{{operation.nom}}</mat-option>
                </mat-select>
                
            </mat-form-field>

        </form>
        <mat-card-actions align="end" >
            <div *ngIf       = "!waiting ">
                <button mat-flat-button 
                                color       = "primary" 
                                [disabled]  = "waiting || !filterForm.valid" 
                                (click)     = "onSearchEmail()">
                            <mat-icon>search</mat-icon>Rechercher                                    
                </button>
                <button mat-raised-button color="primary" (click)="onCreate()">Créer un email<mat-icon color="white">add</mat-icon></button>
            </div>
            <mat-spinner diameter="24" *ngIf="waiting"></mat-spinner>
        </mat-card-actions>
</mat-card>

