<mat-card appearance="outlined">
    <div class="display-warning">
        <h2>Analyse de clé <span class="label-accent">{{ titreEtape | uppercase }}</span></h2>
    </div>

    <mat-stepper orientation="vertical" [ngClass]="{'openUpStepper' : isReadOnly == true }" [linear]="isLinear"
        #stepper>
        <mat-step [stepControl]="Etape1FormGroup">
            <form [formGroup]="Etape1FormGroup">
                <ng-template matStepLabel>
                    <mat-icon class="icon-display-32" color="accent">folder</mat-icon>&nbsp;&nbsp;&nbsp;
                    <mat-label>Préparation du dossier {{ Etape1FormGroup.reference }}</mat-label>
                </ng-template>
                
                <h4>Référence du dossier</h4>

                <mat-form-field>
                    <mat-label>Référence </mat-label>
                    <input matInput type="text" id="reference" formControlName="reference"
                        oninput="this.value = this.value.toUpperCase()" [disabled]="isReadOnly == true" required>
                    <mat-error *ngIf="reference?.invalid && (reference?.dirty || reference?.touched)">{{
                        reference.errors?.erreur }}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Numéro de sinistre </mat-label>
                    <input matInput type="text" id="numeroSinistre" formControlName="numeroSinistre"
                        oninput="this.value = this.value.toUpperCase()" required>
                    <mat-error *ngIf="numeroSinistre?.invalid && (numeroSinistre?.dirty || numeroSinistre?.touched)">{{
                        numeroSinistre.errors?.erreur }}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Date de sinistre </mat-label>
                    <input matInput type="date" id="dateSinistre" formControlName="dateSinistre"
                        required>
                    <mat-error *ngIf="dateSinistre?.invalid && (dateSinistre?.dirty || dateSinistre?.touched)">{{
                        dateSinistre.errors?.erreur }}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Compagnie d'assurance</mat-label>
                    <mat-select name="compagnie" id="compagnie" formControlName="compagnie"
                        (selectionChange)="onSelectChangeCompagnie($event)" required>
                        <mat-option *ngFor="let compagnie of compagnies$" [value]="compagnie">
                            {{ compagnie.code_gta }} - {{ compagnie.nom }}
                        </mat-option>
                        <mat-error *ngIf="compagnie?.invalid && (compagnie?.dirty || compagnie?.touched)">{{
                            compagnie.errors?.erreur }}</mat-error>
                    </mat-select>
                </mat-form-field>

                <mat-form-field *ngIf="isAutreCompagnie">
                    <mat-label>Autre compagnie d'assurance</mat-label>
                    <input matInput type="text" id="autreCompagnie" formControlName="autreCompagnie"
                        oninput="this.value = this.value.toUpperCase()" required>
                    <mat-error *ngIf="autreCompagnie?.invalid && (autreCompagnie?.dirty || autreCompagnie?.touched)">{{
                        autreCompagnie.errors?.erreur }}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Email du destinataire</mat-label>
                    <input matInput type="mail" id="emailExpert" formControlName="emailExpert" readonly
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" required>
                    <mat-error *ngIf="emailExpert?.invalid && (emailExpert?.dirty || emailExpert?.touched)">Le format de
                        l'adresse email est incorrect</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Cabinet</mat-label>
                    <mat-select name="entite" id="entite" formControlName="entite" required >
                        <mat-option *ngFor="let entite of filterEntitesOfType(allEntite)" [value]="entite" [disabled]="entite.coordonnees_bancaire.etat.code!=apiEntite.CONST_CODE_ETAT_COORDONNEES_BANCAIRE_VALIDE">
                            {{ entite.raison_sociale }}
                            <span *ngIf="entite.coordonnees_bancaire.etat.code!=apiEntite.CONST_CODE_ETAT_COORDONNEES_BANCAIRE_VALIDE" style="color: red;"> -- Coordonnées bancaires non validées</span>
                        </mat-option>
                        <mat-error *ngIf="((entite?.invalid && (entite?.dirty || entite?.touched)) || coordBancValidees==false)">{{
                            entite.errors?.erreur }}
                            </mat-error>
                    </mat-select>
                </mat-form-field>

                <!-- US 329 : blocage si les coordonénes bancaires du cabinet sélectionné ne sont pas validées -->
                <div *ngIf="!Etape1FormGroup.valid">
                    <mat-error *ngIf="coordBancValidees!=true">Les coordonnées bancaires de ce cabinet ne sont pas encore validées</mat-error>
                </div>
                
                <div class="div-button-right" *ngIf="isLinear">
                    <button mat-flat-button matStepperNext color="primary" >Suivant<mat-icon>east</mat-icon></button>
                </div>

            </form>
        </mat-step>

        <mat-step [stepControl]="Etape2FormGroup">
            <form [formGroup]="Etape2FormGroup">
                <ng-template matStepLabel>
                    <mat-icon class="icon-display-32" color="accent" style="height: 38x;">directions_car_filled
                    </mat-icon>&nbsp;&nbsp;&nbsp;
                    <mat-label>Véhicule </mat-label>
                </ng-template>

                <h4>Information sur le véhicule </h4>

                <mat-form-field>
                    <mat-label>Immatriculation </mat-label>
                    <input matInput type="text" id="immatriculation" formControlName="immatriculation"
                        oninput="this.value = this.value.toUpperCase()" appImmat>
                    <mat-error *ngIf="immatriculation?.invalid && (immatriculation?.dirty || immatriculation?.touched)">
                        {{ immatriculation.errors?.erreur }}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>VIN </mat-label>
                    <input matInput type="text" id="vin" formControlName="vin"
                        oninput="this.value = this.value.toUpperCase()" length = "17">
                    <mat-error *ngIf="vin?.invalid && (vin?.dirty || vin?.touched)">{{ vin.errors?.erreur }}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Marque </mat-label>
                    <input matInput type="text" id="marqueVehicule" formControlName="marqueVehicule"
                        oninput="this.value = this.value.toUpperCase()">
                    <mat-error *ngIf="marqueVehicule?.invalid && (marqueVehicule?.dirty || marqueVehicule?.touched)">{{
                        marqueVehicule.errors?.erreur }}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Modèle </mat-label>
                    <input matInput type="text" id="modele" formControlName="modele"
                        oninput="this.value = this.value.toUpperCase()">
                    <mat-error *ngIf="modele?.invalid && (modele?.dirty || modele?.touched)">{{ modele.errors?.erreur }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Date MEC </mat-label>
                    <input matInput type="date" id="dateMEC" formControlName="dateMEC">
                    <mat-error *ngIf="dateMEC?.invalid && (dateMEC?.dirty || dateMEC?.touched)">{{
                        dateMEC.errors?.erreur }}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Kilométrage </mat-label>
                    <input matInput type="text" id="kilometre" formControlName="kilometre">
                    <!-- <mat-error *ngIf="kilometrage?.invalid && (kilometrage?.dirty || kilometrage?.touched)">{{ kilometrage.errors?.erreur }}</mat-error> -->
                </mat-form-field>

                <div class="div-button-right" *ngIf="isLinear">
                    <button mat-flat-button matStepperPrevious type="button" color="basic">
                        <mat-icon>west</mat-icon>Précédent
                    </button>
                    <button mat-flat-button matStepperNext color="primary">Suivant<mat-icon>east</mat-icon></button>
                </div>
            </form>
        </mat-step>

        <mat-step [stepControl]="Etape3FormGroup">
            <form [formGroup]="Etape3FormGroup">
                <ng-template matStepLabel>
                    <mat-icon class="icon-display-32" color="accent">key</mat-icon>&nbsp;&nbsp;&nbsp;
                    Analyse de clé 
                </ng-template>

                <form [formGroup]="formCle">
                    <table *ngIf="!isReadOnly">
                        <tr>
                            <td style="width: 120px!important;">
                                <mat-form-field>
                                    <mat-label>Numéro de scellé </mat-label>
                                    <input matInput oninput="this.value = this.value.toUpperCase()" type="text"
                                        id="numeroScelle" formControlName="numeroScelle">
                                </mat-form-field>
                            </td>
                            <td style="width: 120px!important;">
                                <mat-form-field>
                                    <mat-label>Type de clé </mat-label>
                                    <mat-select matNativeControl name="typeCle" id="typeCle" formControlName="typeCle"
                                        (selectionChange)="onSelectTypeCle($event)" required>
                                        <!-- <mat-option [value]="typeCle" class="label-indication">à définir</mat-option> -->
                                        <mat-option *ngFor="let type of typeCles" [value]="type">{{ type }}</mat-option>
                                        <!-- <mat-option value="Secondaire">Secondaire</mat-option> -->
                                    </mat-select>
                                </mat-form-field>
                            </td>
                            <td style="width: 180%!important;">
                                <button mat-flat-button color="primary" [disabled]=!formCle.valid (click)="addKey()">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </table>
                </form>


                <h4>Liste des clé à analyser </h4>
                <mat-list>
                    <mat-list-item *ngFor="let cle of cles ; let index = index">
                        <p>
                            <span class="label-indication">{{ index + 1 }}</span>.&nbsp;
                            <span style="font-weight: bold;">{{ cle.numeroScelle }}</span> -
                            <span style="color: rgb(39,58,130);">Clé&nbsp;{{ cle.typeCle }}</span>
                        </p>
                    </mat-list-item>
                </mat-list>
                <mat-label *ngIf=" cles.length == 0 " class="label-primary">Aucune clé de déclaré ...</mat-label>
                <!-- <mat-form-field>
            </mat-form-field> -->
                <div *ngIf="!isAdminADC" style="margin-top: 24px!important;">
                    <h4>Document à joindre </h4>
                    <div class="div-input-file">
                        <mat-label class="label-indication" color="primary">Joindre ici une photocopie de la carte grise</mat-label>
                        <br>
                        <input #fileInput type="file" single accept=".jpg,.png,.pdf"
                        placeholder="Sélectionnez votre fichier..."
                        (change)="addFileToQueue($event, CONST_CATEGORIE_CG)" formControlName="file_cg">
                    </div>
                    <mat-divider color="primary"></mat-divider>
                        <!-- <button mat-flat-button  color="primary" (click)="">uplaod</button> -->
                        
                    <div class="div-input-file">
                        <mat-label class="label-indication" color="primary">Déposer ici le procès verbal de police </mat-label>
                        <br>
                        <input #fileInput type="file" single accept=".jpg,.png,.pdf"
                        placeholder="Sélectionnez votre fichier..."
                        (change)="addFileToQueue($event, CONST_CATEGORIE_PV)" formControlName="file_pv">
                    </div>
                    <mat-divider color="primary"></mat-divider>
                        
                    <div class="div-input-file">
                        <mat-label class="label-indication" color="primary">Déposer ici la déclaration de sinistre auprès de la compagnie </mat-label>
                        <br>
                        <input #fileInput type="file" single accept=".jpg,.png,.pdf"
                        placeholder="Sélectionnez votre fichier..."
                        (change)="addFileToQueue($event, CONST_CATEGORIE_SINISTRE)"
                        formControlName="file_declaration">
                    </div>
                    <mat-divider color="primary"></mat-divider>
                            
                    <div class="div-input-file">
                        <mat-label class="label-indication" color="primary">Déposer ici le formulaire de remise de clefs et signé </mat-label><br>
                        <input #fileInput type="file" single accept=".jpg,.png,.pdf"
                        placeholder="Sélectionnez votre fichier..."
                        (change)="addFileToQueue($event, CONST_CATEGORIE_REMISE)" formControlName="file_remisedecle">
                    </div>
                    <mat-divider color="primary"></mat-divider>
                                
                    <div class="div-button-right" style="padding-top: 24px;" *ngIf="isLinear">
                        <button mat-flat-button matStepperPrevious type="button" color="basic">
                            <mat-icon>west</mat-icon>Précédent
                        </button>
                        <button mat-flat-button matStepperNext color="primary">Suivant<mat-icon>east</mat-icon></button>
                    </div>
                </div>
            </form>
        </mat-step>
                    
        <mat-step [stepControl]="Etape4FormGroup">
            <form [formGroup]="Etape4FormGroup">
                <ng-template matStepLabel>
                    <mat-icon class="icon-display-32" color="accent">comment</mat-icon>&nbsp;&nbsp;&nbsp;
                    Commentaire ou objet de la demande
                </ng-template>

                <mat-form-field class="textarea">
                    <mat-label>Commentaires</mat-label>
                    <textarea matInput id="commentaire" formControlName="commentaire" 
                        onblur="this.value = this.value.toUpperCase()" style="height: 150px;"></textarea>
                    <mat-error *ngIf="commentaire?.invalid && (commentaire?.dirty || commentaire?.touched)">{{
                        commentaire.errors?.erreur }}</mat-error>
                </mat-form-field>

                <div *ngIf="!isReadOnly" style="padding-top: 24px;">
                    <p class="label-primary">Vous devez envoyer vos clefs uniquement lorsque vous avez reçu la
                        confirmation que l'analyse de celles-ci est possible. De plus, pour cet envoi, devez
                        impérativement utiliser les kits prévus à cet effet, disponible dans le module 'Commande' du
                        site.</p>
                </div>

                <mat-checkbox *ngIf="!isReadOnly" class="label-primary" color="accent" style="font-weight: bold;" id="isAccepted"
                    name="isAccepted" formControlName="isAccepted">J'accepte les conditions
                    d'envoi des clefs et je m'engage à les suivre après réception de la confirmation.</mat-checkbox>

                <!-- <p>Condition de validation</p>
                <p>{{ !Etape1FormGroup.valid }} / {{!Etape2FormGroup.valid}} / {{!Etape3FormGroup.valid}} / {{ cles.length === 0 }} / {{ !waiting_Save }} / {{ isAccepted === true}}</p> -->
                <div style="padding-top: 24px;" *ngIf="isLinear">
                    <mat-error *ngIf="!Etape1FormGroup.valid">
                        <mat-icon>report_problem</mat-icon>&nbsp;Les informations sur la référence du dossier sont
                        incomplètes incomplète.
                    </mat-error>
                    <mat-error *ngIf="!Etape2FormGroup.valid">
                        <mat-icon>report_problem</mat-icon>&nbsp;Les informations du véhicules sont incomplète.
                    </mat-error>
                    <mat-error *ngIf="!Etape4FormGroup.valid">
                        <mat-icon>report_problem</mat-icon>&nbsp;Le commentaire ou l'objet de la demande est
                        incomplète.
                    </mat-error>
                    <mat-error *ngIf="!cles.length">
                        <mat-icon>report_problem</mat-icon>&nbsp;Vous devez décrire les clés à analyser.
                    </mat-error>
                    <mat-error *ngIf="!isAccepted">
                        <mat-icon>report_problem</mat-icon>&nbsp;Les conditions d'envoi n'ont pas été validé.
                    </mat-error>
                </div>

                
                <mat-divider></mat-divider>

                <div *ngIf="isAdminADC" style="padding: 16px;">
                    <h4>Document joints </h4>
                    <div *ngFor="let file of fichiers, let index = index">
                        <div class="div-input-file">
                            <button mat-button color="primary" (click)="downloadFile( file.id, file.name )">
                                <mat-icon>description</mat-icon>{{ index+1 }}. {{ file.name }}
                            </button>
                        </div>
                    </div>
                </div>
                
                <!-- Enregistrement d'un nouveau dossier ADC -->
                <div class="div-button-right" style="padding-top: 24px!important;" *ngIf="isLinear">
                    <button mat-flat-button matStepperPrevious type="button" color="basic">
                        <mat-icon>west</mat-icon>Précédent
                    </button>
                    <div class="button-container">
                        <div class="spinner-container" *ngIf="waiting_Save">
                            <mat-spinner diameter="24"></mat-spinner>
                        </div>
                        <button mat-flat-button color="primary" style="text-align: center;"
                        [hidden]="waiting_Save"
                        [disabled]="!Etape1FormGroup.valid || !Etape2FormGroup.valid || !Etape3FormGroup.valid || !Etape4FormGroup.valid || cles.length === 0 || waiting_Save"
                        (click)="saveData()">
                        <mat-icon>done</mat-icon>Déposer ma demande
                    </button>
                </div>
            </div>
    
            <!-- DEPOSE => VALIDE/REFUSE - Validation ou Refus d'un dossier DEPOSE Commentaire de refus d'analyse -->
            <div *ngIf="Actualstatus == apiAdc.CONST_STATUS_ADC_1_DEPOSE" 
            class="mat-card-no-shadow mat-card-warn"
            style="padding: 16px; border-radius: 4px;">
            <mat-icon>badge</mat-icon><h4>Analyse de clé</h4>
                <form [formGroup]="formDEPOSE">
                    <div>
                        <mat-form-field class="textarea">
                            <mat-label>Motif du refus</mat-label>
                            <textarea matInput id="commentaireRefus" formControlName="commentaireRefus"
                            placeholder="Merci de bien vouloir saisir le motif de votre refus"
                                oninput="this.value = this.value.toUpperCase()" style="height: 150px;" required></textarea>
                            <mat-error *ngIf="commentaireRefus?.invalid && (commentaireRefus?.dirty || commentaireRefus?.touched)">{{ commentaire.errors?.erreur }}</mat-error>
                        </mat-form-field>

                        <div class="div-button-right" style="padding-top: 24px!important;">
                            <!-- <button mat-flat-button type="button" color="basic"
                                (click)="close()">
                                <mat-icon>west</mat-icon>Ferme
                            </button> -->
                            <div *ngIf="isReadOnly" class="button-container">
                                <div class="spinner-container" *ngIf="waiting_Refused">
                                    <mat-spinner diameter="24"></mat-spinner>
                                </div>
                                <button mat-flat-button color="warn" style="text-align: center;"
                                    title="Enregistrer le refus de cette demande"
                                    [hidden]="waiting_Refused" 
                                    [disabled]="!formDEPOSE.valid || waiting_Refused"
                                    (click)="updateRefuse()">
                                    <mat-icon>cancel</mat-icon>Refuser cette demande
                                </button>
                            </div>

                            <div *ngIf="isReadOnly" class="button-container">
                                <div class="spinner-container" *ngIf="waiting_Confirm">
                                    <mat-spinner diameter="24"></mat-spinner>
                                </div>
                                <button mat-flat-button color="primary" style="text-align: center;"
                                [hidden]="waiting_Confirm"
                                [disabled]="formDEPOSE.valid || waiting_Confirm"
                                (click)="updateValid()">
                                <mat-icon>done</mat-icon>Valider la demande
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <!-- EN ATTENTE DE CLE => ANALYSE -->
            <div *ngIf="Actualstatus == apiAdc.CONST_STATUS_ADC_3_ATTENTE_CLE" 
            class="mat-card-no-shadow mat-card-primary"
            style="padding: 16px; border-radius: 4px;">
            <mat-icon>badge</mat-icon><h4>Analyse de clé</h4>
                <form [formGroup]="formATTENTE_CLE">
                    <div><!--  -->
                        <mat-form-field>
                            <mat-label>Date de réception de clé(s) </mat-label>
                            <input type="date" matInput id="dateReceptionCle" formControlName="dateReceptionCle" required>
                            <mat-error *ngIf="dateReceptionCle?.invalid && (dateReceptionCle?.dirty || dateReceptionCle?.touched)">{{ dateReceptionCle.errors?.erreur }}</mat-error>
                        </mat-form-field>
                        <div class="div-button-right" style="padding-top: 24px!important;">
                            <div *ngIf="isReadOnly" class="button-container">
                                <!-- <button mat-flat-button type="button" color="basic" (click)="close()">
                                    <mat-icon>west</mat-icon>Fermer
                                </button> -->
                                <div class="spinner-container" *ngIf="waiting_Attente_cle">
                                    <mat-spinner diameter="24"></mat-spinner>
                                </div>
                                <button mat-flat-button color="primary" style="text-align: center;"
                                    title="Enregistrer la réception de clé"
                                    [hidden]="waiting_Attente_cle"
                                    [disabled]="!formATTENTE_CLE.valid || waiting_Attente_cle"
                                    (click)="updateAttenteCle()">
                                    <mat-icon>done</mat-icon>Réceptionner les clés
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
    
            <!-- ANALYSE => DIAGNOSTIC -->
            <div *ngIf="Actualstatus == apiAdc.CONST_STATUS_ADC_5_DIAGNOSTIC" 
            class="mat-card-no-shadow mat-card-primary"
            style="padding: 16px; border-radius: 4px;">
            <mat-icon>badge</mat-icon><h4>Analyse de clé</h4>
                <form [formGroup]="formDIAG">
                    <div><!--  -->
                        <div *ngIf="isDiagnostic">
                            <mat-form-field class="textarea">
                                <mat-label>Diagnostic des clés </mat-label>
                                <textarea matInput id="diagnostic" formControlName="diagnostic" 
                                    oninput="this.value = this.value.toUpperCase()" style="height: 150px;"></textarea>
                                <mat-error *ngIf="diagnostic?.invalid && (diagnostic?.dirty || diagnostic?.touched)">{{
                                    diagnostic.errors?.erreur }}</mat-error>
                            </mat-form-field>
                        </div>
                        
                        <div class="div-button-right" style="padding-top: 24px!important;">
                            <!-- <div *ngIf="isReadOnly && !isDiagnostic" class="button-container">
                                <div class="spinner-container" *ngIf="waiting_Analyse">
                                    <mat-spinner diameter="24"></mat-spinner>
                                </div>
                                <button mat-flat-button color="primary" style="text-align: center;"
                                    title=""
                                    [hidden]="waiting_Analyse || isDiagnostic"
                                    [disabled]="waiting_Analyse"
                                    (click)="updateAnalyse()">
                                    <mat-icon>done</mat-icon>Saisir le diagnostic
                                </button>
                            </div> -->
                            <div *ngIf="isReadOnly && isDiagnostic" class="button-container">
                                <div class="spinner-container" *ngIf="waiting_Diagnostic">
                                    <mat-spinner diameter="24"></mat-spinner>
                                </div>
                                <button mat-flat-button color="primary" style="text-align: center;"
                                    title="Enregistrer le diagnostic de cette demande"
                                    [hidden]="waiting_Diagnostic"
                                    [disabled]="!formDIAG.valid || waiting_Diagnostic"
                                    (click)="updateDiagnostic()">
                                    <mat-icon>done</mat-icon>Enregistrer le diagnostic
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
    
</form>
        </mat-step>

        <mat-step [stepControl]="formDIAG" *ngIf="Actualstatus == apiAdc.CONST_STATUS_ADC_6_CLOTURE">
            <form [formGroup]="formDIAG">

                <div *ngIf="isAdminADC" style="padding: 16px;">
                    <h4>Document joints </h4>
                    <div *ngFor="let file of fichiers, let index = index">
                        <div class="div-input-file">
                            <button mat-button color="primary" (click)="downloadFile( file.id, file.name )">
                                <mat-icon>description</mat-icon>{{ index+1 }}. {{ file.name }}
                            </button>
                        </div>
                    </div>
                </div>

                <ng-template matStepLabel>
                    <mat-icon class="icon-display-32" color="primary">comment</mat-icon>&nbsp;&nbsp;&nbsp;
                    Diagnostic de la demande 
                </ng-template>
                
                <mat-form-field class="textarea">
                    <mat-label>Diagnostic de l'analyse </mat-label>
                    <textarea matInput id="diagnostic" formControlName="diagnostic"
                    oninput="this.value = this.value.toUpperCase()" style="height: 150px;"></textarea>
                </mat-form-field>
            </form>
        </mat-step>

    </mat-stepper>

<div class="footer">
    
    
</div>