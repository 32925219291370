import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Entite } from 'src/app/interfaces/profil-user/entite';

@Injectable({
  providedIn: 'root'
})
export class EntiteAdresseService {

  url:string = '/api/utilisateur/entites'

  constructor(private http : HttpClient) { }

  getEntiteAdresse(IdUtilisateur:number):Observable<Entite[]>{
    return this.http.get<Entite[]>(this.url+'/'+IdUtilisateur);
  }

  

}
