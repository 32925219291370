import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router, RouterLink } from '@angular/router';
import { Observable } from 'rxjs';
import { DialogHistoriqueFactureComponent } from 'src/app/components/facture/dialog-historique-facture/dialog-historique-facture.component';
import { Facture } from 'src/app/interfaces/facture/facture';
import { ApiFactureService } from 'src/app/services/api-facture.service';
import { ApiUtilitaireService } from 'src/app/services/tools/api-utilitaire.service';
import { ExportTableComponent } from '../../../templates/export-table/export-table.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatTableResponsiveLargeDirective } from '../../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';
import { NgIf, NgStyle, DecimalPipe, DatePipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-facture-liste',
    templateUrl: './facture-liste.component.html',
    styleUrls: ['./facture-liste.component.scss'],
    standalone: true,
    imports: [MatCardModule, NgIf, MatTableModule, MatTableResponsiveLargeDirective, MatTableExporterModule, MatIconModule, NgStyle, MatTooltipModule, MatButtonModule, RouterLink, ExportTableComponent, MatPaginatorModule, DecimalPipe, DatePipe]
})
export class FactureListeComponent implements OnInit {

  @Input() factures! : Facture[]

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;

  dataSourceMatTable: MatTableDataSource<any> = new MatTableDataSource<any>();

  displayedColumns    : string[] = ['Service','Type','Numero','Reference','Date','Montant','Statut','Actions'];
  
  constructor(public apiFacture : ApiFactureService
            , public dialog: MatDialog
            , private route: Router
            , public apiUtilitaire: ApiUtilitaireService ) { }

  ngOnInit(): void {
    
  }
  
  ngOnChanges(){
    this.dataSourceMatTable.data = this.factures
    this.dataSourceMatTable.paginator = this.paginator
  }

  public dialogHistoriqueFacture(id: any) {
    const dialogRef = this.dialog.open(DialogHistoriqueFactureComponent);
    dialogRef.componentInstance.id = id
  }
  
  public openFacture(id: number) {
    this.route.navigate(['/app/facture/recap-facture/', id])
  }

}
