<mat-card appearance="outlined">
    <mat-card-title style="margin-bottom: 16px;">
        <mat-icon>car_repair</mat-icon>
        <span>Gestion de vos identifiants externes</span>
    </mat-card-title>
    
    <mat-card-content  class=".mat-card-content">
        <hr style="color: #273a82;">
        <p>
            <mat-icon color="warn">info</mat-icon>
            <span class="label-error" style="font-size: 18px;">ATTENTION ! Il est obligatoire que vous possédiez un compte auprès des sites de recherche suivants afin de garantir la confidentialité des informations qui vous seront données.</span>
        </p>
        <p>Veuillez renseigner votre code réparateur ou identifiant pour chaque service de recherche externe.
        </p>

        <form [formGroup]="listForm">
            <ng-container formArrayName="serviceExterneRow">
                <div *ngFor="let row of services$;  let index = index">
                    <ng-container formGroupName="{{index}}" style="border-bottom: #273a82;">

                            <div style="padding-left: 64px; padding-top: 16px;">
                                <h4 style="color: #273a82; margin-bottom: -16px;">{{index +1}}. {{ row.libelle | uppercase }}</h4>
                            </div>
                            <div style="padding-left: 64px;">
                                <mat-form-field>
                                    <input matInput type="text" id="codeReparateur" formControlName="codeReparateur">
                                    <mat-hint>identifiant / Compte pour {{ row.libelle }}</mat-hint>
                                 </mat-form-field>
                            </div>
                    </ng-container>
                </div>
            </ng-container>
            <div class="div-button-right">
                <button mat-raised-button 
                    *ngIf       = "isDirigeant &&!waiting_save" 
                    color       = "primary" 
                    type        = "submit"
                    [disabled]  = "waiting_save"
                    (click)     = "saveConfiguration()"
                    >
                    <span>Enregistrer</span>
                </button>
                <mat-spinner diameter="24" *ngIf="waiting_save"></mat-spinner>
            </div>
        </form>
    </mat-card-content>
    
    <mat-card-actions>

    </mat-card-actions>
</mat-card>
