import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { McelGlobale } from 'src/app/interfaces/statistiques/mcel-globale';
import { ExportTableComponent } from '../../templates/export-table/export-table.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatTableResponsiveLargeDirective } from '../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';

export interface MCEL{
  type: string;
  max: number;
  min: number;
  moy: number;
};
@Component({
    selector: 'app-table-mcel',
    templateUrl: './table-mcel.component.html',
    styleUrls: ['./table-mcel.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatExpansionModule, MatIconModule, MatTableModule, MatTableResponsiveLargeDirective, MatTableExporterModule, ExportTableComponent]
})
export class TableMcelComponent implements OnInit {

  // Données à afficher
  @Input() ELEMENT_DATA: McelGlobale = <McelGlobale>{};  
  mcel: Array<MCEL> = [];
  dataSource  = new MatTableDataSource<MCEL>();
  
  dossier     : number = 0;
  expandPannel: boolean = true;
  listCodePostaux:string='';

  mcel_t1: MCEL = <MCEL>{};
  mcel_t2: MCEL = <MCEL>{};
  mcel_t3: MCEL = <MCEL>{};

  // Tableau des MCEL
  displayedColumns    : string[] = ['type', 'min', 'moy', 'max'];  

  constructor() { }

  ngOnInit(): void {           
    
  }
  
  ngOnChanges(): void {
    this.chargeMCEL();
    
    this.ELEMENT_DATA.codePostaux.forEach((codePostal:string,index)=>{
      if (index > 0 && index < this.ELEMENT_DATA.codePostaux.length-1) {
        this.listCodePostaux += ', '
      }else if (index > 0 && index === this.ELEMENT_DATA.codePostaux.length-1) {
        this.listCodePostaux += ' et '
      }
      this.listCodePostaux += ' ' + codePostal
    })
    if (this.ELEMENT_DATA.codePostaux.length===1) {
      this.listCodePostaux = "Résultats pour le code postal " + this.listCodePostaux
    }else{
      this.listCodePostaux = "Résultats agrégés pour les codes postaux " + this.listCodePostaux
    }
  }

  public chargeMCEL(){
    this.mcel = [];    
    this.dossier = this.ELEMENT_DATA.nb_dossier;      

    for(let i = 0; i < 5; i++) {
      let single: MCEL = <MCEL> {};

      if(i === 0) {
        single = {
          type: 'Taux horaire T1',
          max: this.ELEMENT_DATA.max_t1,
          min: this.ELEMENT_DATA.min_t1,
          moy: this.ELEMENT_DATA.moy_t1
        };
      }

      if(i === 1) {
        single = {
          type: 'Taux horaire T2',
          max: this.ELEMENT_DATA.max_t2,
          min: this.ELEMENT_DATA.min_t2,
          moy: this.ELEMENT_DATA.moy_t2
        };
      }

      if(i === 2) {
        single = {
          type: 'Taux horaire T3',
          max: this.ELEMENT_DATA.max_t3,
          min: this.ELEMENT_DATA.min_t3,
          moy: this.ELEMENT_DATA.moy_t3
        };
      }

      if(i === 3) {
        single = {
          type: 'Taux horaire peinture',
          max: this.ELEMENT_DATA.max_peinture,
          min: this.ELEMENT_DATA.min_peinture,
          moy: this.ELEMENT_DATA.moy_peinture
        };
      }

      if(i === 4) {
        single = {
          type: 'Taux ingrédients peinture',
          max: this.ELEMENT_DATA.max_ingredients_peinture,
          min: this.ELEMENT_DATA.min_ingredients_peinture,
          moy: this.ELEMENT_DATA.moy_ingredients_peinture
        };
      }
      this.mcel.push(single);
    }        

    this.dataSource.data = this.mcel;
  }
}
