<mat-card appearance="outlined">
    <mat-card-title>Statistiques CONCENTRATEUR  par habilitation</mat-card-title>
    <mat-card-content  class=".mat-card-content">
        <form [formGroup]="filterForm">
            <div class = "div-container-left">
                <div>
                    <mat-form-field>
                        <mat-label>Habilitation</mat-label>
                        <input matInput 
                                type            = "text"
                                formControlName = "habilitation"
                        >
                        <mat-error>Saisie obligatoire</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field style="max-width: 100px;">
                        <mat-label>Année</mat-label>
                        <input  matInput
                                appAnnee 
                                [minAnnee]      = minAnnee
                                [maxAnnee]      = maxAnnee
                                formControlName = "annee"
                        >
                        <mat-error>Année invalide</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <button *ngIf="!waiting"
                            mat-raised-button 
                            color       = "primary" 
                            [disabled]  = "!filterForm.valid"
                            (click)     = "onSearch()"
                            ><mat-icon>search</mat-icon>Rechercher
                    </button>
                    <mat-spinner *ngIf="waiting" diameter="24"></mat-spinner>
                </div>
            </div>
        </form>
        <div>
            <mat-grid-list cols="12"  rowHeight="40px" style="margin-top: 30px;">

                <mat-grid-tile colspan="4" rowspan="1">
                    <div class="tileColor">Habilitation :</div>
                </mat-grid-tile>
                <mat-grid-tile colspan="8" rowspan="1">
                    <div>{{habilitation}}</div>
                </mat-grid-tile>

                <mat-grid-tile colspan="4" rowspan="1">
                    <div class="tileColor">Année :</div>
                </mat-grid-tile>
                <mat-grid-tile colspan="8" rowspan="1">
                    <div>{{annee}}</div>
                </mat-grid-tile>

                <mat-grid-tile colspan="4" rowspan="1">
                    <div class="tileColor">Nombre d’opérations 1C présentes dans le concentrateur :</div>
                </mat-grid-tile>
                <mat-grid-tile colspan="8" rowspan="1">
                    <div>{{nbOperations}}</div>
                </mat-grid-tile>

            </mat-grid-list>

            
        </div>
    </mat-card-content>
</mat-card>

