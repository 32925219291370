import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiAuthentificationService } from '../services/api-authentification.service';

@Injectable({
  providedIn: 'root'
})
export class ProtectEnvironmentExtranetGuard  {

  constructor(private apiAuth: ApiAuthentificationService){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    this.apiAuth.root = this.apiAuth.CONST_URL_ROOT_EXTRANET;

    return true;
  }
  
}
