<mat-card appearance="outlined">
        <mat-form-field >
            <mat-label>Nom du rapport</mat-label>
            <input 
            matInput 
            [(ngModel)] = "nomRapport"
            type        = "text" 
            placeholder = "Nom du rapport de suspension" 
            >
        </mat-form-field> 
        
        <mat-card-actions align="end" >
            <button *ngIf="!waiting"
                    mat-raised-button 
                    color   = "primary"
                    style   = "margin-left: 10px;"
                    (click) = "telecharger()"
        >
                <mat-icon>download</mat-icon>Télécharger le rapport                                    
            </button>
            <mat-spinner *ngIf="waiting" diameter="24"></mat-spinner>
    </mat-card-actions>
</mat-card>
