<mat-card appearance="outlined" class="mat-card-no-shadow" style="padding: 0px;">
    <form [formGroup]="FiltreFormGroup">
        <mat-expansion-panel id="expandFilter" [(expanded)]="expandPannel" #expandFilter>            
            <mat-expansion-panel-header style="background-color: #fff">
                <mat-panel-title class="label-accent">
                    <mat-icon>search</mat-icon>Veuillez sélectionner les options de votre recherche...
                </mat-panel-title>
            </mat-expansion-panel-header>
                                    
            <mat-form-field>
                <mat-label>Cabinet</mat-label>
                <mat-select name="habilitation" id="habilitation" formControlName="habilitation" multiple>
                    <mat-option *ngFor="let entite of entites; let indice = index" [value]="entite.habilitation">
                        {{ entite.raison_sociale }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="!FiltreFormGroup?.valid && FiltreFormGroup.touched">Vous devez sélectionner au moins un cabinet pour votre recherche</mat-error>
            </mat-form-field>            

            <mat-form-field class="formDate">
                <mat-label>Date de début</mat-label>
                <input matInput type="date" id="date_debut" formControlName="date_debut">
                <mat-error *ngIf="date_debut?.invalid && (date_debut?.dirty || date_debut?.touched)">{{date_debut?.errors?.erreur }}</mat-error>
            </mat-form-field>
    
            <mat-form-field class="formDate">
                <mat-label>Date de fin</mat-label>
                <input matInput type="date" id="date_fin" formControlName="date_fin">
                <mat-error *ngIf="date_fin?.invalid && (date_fin?.dirty || date_fin?.touched)">{{ date_fin?.errors?.erreur }}</mat-error>
            </mat-form-field>
            
            <mat-error *ngIf="FiltreFormGroup?.invalid && (FiltreFormGroup?.dirty || FiltreFormGroup?.touched)">{{ FiltreFormGroup.errors?.erreur }}</mat-error>
            
            <mat-action-row>
                <div class="div-container-between" style="padding: 8px;">
                    <div  class="div-container-center" style="width:100%; display: flex; justify-content: center;">                        
                        <button mat-flat-button 
                                *ngIf       = "!waitingResearch"
                                color       = "primary" 
                                (click)     = "onSearchingStatistiques()" 
                                [disabled]  = "waitingResearch || !FiltreFormGroup.valid">
                            <mat-icon>search</mat-icon>Rechercher
                        </button>
                        <mat-spinner diameter="24" *ngIf = "waitingResearch"></mat-spinner>
                    </div>
                </div>
            </mat-action-row>
        </mat-expansion-panel>                        
    </form>
</mat-card>

<div>
    <div *ngFor="let stat_entite of stats_entite">
        <app-entite-statistiques [ELEMENT_DATA]="stat_entite"></app-entite-statistiques>
        <br>
    </div>    
</div>