<mat-card appearance="outlined" (window:resize)="onResize($event)">
    <h4>{{ titre_page }} </h4>

    <form [formGroup]="formGroupArticle">
        <mat-grid-list cols="12" rowHeight="50">

            <mat-grid-tile [colspan]="breakpointSmall? 12 : 6">
                <mat-form-field>
                    <mat-label>Référence</mat-label>
                    <input matInput type="text" id="reference" formControlName="reference" oninput="this.value = this.value.toUpperCase()" required>
                    <mat-error *ngIf="reference?.invalid && (reference?.dirty || reference?.touched)">Référence obligatoire</mat-error>
                </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="breakpointSmall? 12 : 6">
                <mat-form-field>
                    <mat-label>Désignation</mat-label>
                    <input matInput type="text" id="designation" formControlName="designation" required>
                    <mat-error *ngIf="designation?.invalid && (designation?.dirty || designation?.touched)" required>Désignation obligatoire</mat-error>
                </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="breakpointSmall? 12 : 6">
                <mat-form-field>
                    <mat-label>Catégorie</mat-label>
                    <mat-select name="categorie" id="categorie" formControlName="categorie"required>
                        <mat-option *ngFor="let categorie of categories" [value]="categorie.id">
                            {{ categorie.libelle }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="categorie?.errors?.invalid && (categorie?.dirty || categorie?.touched)">Vous devez sélectionner une catégorie</mat-error>
                </mat-form-field>
            </mat-grid-tile>
           
            <mat-grid-tile [colspan]="breakpointSmall? 12 : 6">
                <mat-form-field>
                    <mat-label>Fournisseur</mat-label>
                    <mat-select name="fournisseur" id="fournisseur" formControlName="fournisseur" required>
                        <mat-option *ngFor="let fournisseur of fournisseurs" [value]="fournisseur.id">
                            {{ fournisseur.libelle }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="fournisseur?.errors?.invalid && (fournisseur?.dirty || fournisseur?.touched)">Vous devez sélectionner un fournisseur</mat-error>
                </mat-form-field>
            </mat-grid-tile>

        </mat-grid-list>

        <mat-form-field class="textarea">
            <mat-label>Description</mat-label>
            <textarea matInput id="description" formControlName="description" style="height: 150px;" required></textarea>
            <mat-error *ngIf="description?.invalid && (description?.dirty || description?.touched)">Description obligatoire</mat-error>
        </mat-form-field>
    
        <mat-grid-list  cols="12" rowHeight="50">
            <mat-grid-tile [colspan]="breakpointSmall? 12 : 3"> 
                <mat-form-field>
                    <mat-label>Prix HT (€)</mat-label>
                    <input matInput type="number" min=0 step="0.01" id="prix_ht" formControlName="prix_ht">
                    <mat-error *ngIf="prix_ht?.invalid && (prix_ht?.dirty || prix_ht?.touched)">Prix HT supérieur à 0 €</mat-error>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="breakpointSmall? 12 : 3"> 
                <mat-form-field>
                    <mat-label>Prix location HT / mois (€)</mat-label>
                    <input matInput type="number" min=0 step="0.01" id="location_ht" formControlName="location_ht">
                    <mat-error *ngIf="location_ht?.invalid && (location_ht?.dirty || location_ht?.touched)">Location supérieur à 0 €</mat-error>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="breakpointSmall? 12 : 3"> 
                <mat-form-field>
                    <mat-label>Frais de port HT (€)</mat-label>
                    <input matInput type="number" min=0 step="0.01" id="port_ht" formControlName="port_ht">
                    <mat-error *ngIf="port_ht?.invalid && (port_ht?.dirty || port_ht?.touched)">Frais de port supérieur à 0 €</mat-error>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="breakpointSmall? 12 : 3"> 
                <mat-form-field>
                    <mat-label>Pourcentage de remise (%)</mat-label>
                    <input matInput type="number" min=0 max=100 step="0.01" id="remise" formControlName="remise">
                    <mat-error *ngIf="remise?.invalid && (remise?.dirty || remise?.touched)">Pourcentage de remise compris entre 0 et 100 %</mat-error>
                </mat-form-field>
            </mat-grid-tile>
        </mat-grid-list>
        <mat-form-field>
            <mat-label>Texte à afficher pour la remise</mat-label>
            <input matInput type="text" id="txt_remise" formControlName="txt_remise">
            <mat-error *ngIf="txt_remise?.invalid && (txt_remise?.dirty || txt_remise?.touched)">Texte de la remise obligatoire</mat-error>
        </mat-form-field>

        <mat-slide-toggle name="actif" id="actif" formControlName="actif" color="primary">
            {{(formGroupArticle.controls.actif.value ? 'Activé' : 'Désactivé' )}}
        </mat-slide-toggle>

        <div class="div-container-right" style="padding-top: 16px;">
            <button mat-button color="warn" class="button-width" (click)="onClickAnnuler()" style="padding-right: 16px;">
                <span>Annuler</span>
            </button>
            <button 
                mat-flat-button 
                *ngIf       = "!waitingValidation"
                color       = "primary" 
                class       = "button-width" 
                (click)     = "onClickValider()" 
                [disabled]  = "formGroupArticle.pristine || (!formGroupArticle.valid && formGroupArticle.dirty)">
                <mat-icon>save</mat-icon>
                <span>Enregistrer</span>
            </button>
            <mat-spinner diameter="24" *ngIf="waitingValidation"></mat-spinner>
        </div>
    </form>
</mat-card>