<mat-card appearance="outlined" class="mat-card-padding">
    <mat-card-title>Liste des invitations</mat-card-title>
    <mat-card-content>
        <!-- <div class="mat-elevation-z8 container"> -->
            <table mat-table [dataSource]="dataSource" matSort class           = "table-primary">
          
              <!-- Nom Column -->
              <ng-container matColumnDef="inv_nom">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom </th>
                <td mat-cell *matCellDef="let element"> {{element.nom}} </td>
              </ng-container>
          
              <!-- Prénom Column -->
              <ng-container matColumnDef="inv_prenom">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Prénom </th>
                <td mat-cell *matCellDef="let element"> {{element.prenom}} </td>
              </ng-container>
          
              <!-- Email Column -->
              <ng-container matColumnDef="inv_email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                <td mat-cell *matCellDef="let element"> {{element.email}} </td>
              </ng-container>
          
              <!-- Groupe Column -->
              <ng-container matColumnDef="grp_nom">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Groupe </th>
                <td mat-cell *matCellDef="let element"> {{element.groupe.nom}} </td>
              </ng-container>
          
              <!-- Dirigeant Column -->
              <ng-container matColumnDef="inv_dirigeant">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Dirigeant </th>
                <td mat-cell *matCellDef="let element"> {{element.dirigeant ? 'Oui' : 'Non'}} </td>
              </ng-container>
          
              <!-- Succursales Column -->
              <ng-container matColumnDef="inv_succursales">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Succursales </th>
                <td mat-cell *matCellDef="let element"> {{element.invSuccursales ? 'Oui' : 'Non'}} </td>
              </ng-container>
          
              <!-- Etat Column -->
              <ng-container matColumnDef="inv_etat">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Etat </th>
                <td mat-cell *matCellDef="let element" 
                    [class.red-text]="element.etat.code==='ANNULER'">
                  {{element.etat.libelle}}
                </td>
              </ng-container>
              
              
          
              <ng-container matColumnDef="action">
                <th mat-header-cell 
                    *matHeaderCellDef 
                    class   = "derniere-colonne" 
                    style   = "text-align: right;">
                </th>
                
                <td mat-cell 
                    *matCellDef = "let element" 
                    class       = "colonne-module" 
                    style       = "text-align: right;">
                    
                    <button mat-icon-button 
                            *ngIf   ="element.etat.code==='ENVOYE'"
                            color   = "warn"
                            style   = "width: 48px; height: 48px;"
                            (click) = "onCancel(element.id)"
                            matTooltip="Annuler cette invitation">
                            <mat-icon   color="warn" 
                                    >cancel
                        </mat-icon>
                    </button>

                </td>
            </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
          <!-- </div> -->
    </mat-card-content>
      
</mat-card>
