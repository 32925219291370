<mat-card appearance="outlined">
    <h4>{{ titre_page }} </h4>

    <form [formGroup]="formGroupFournisseur">
        
        <mat-form-field>
            <mat-label>Code</mat-label>
            <input matInput type="text" id="code" formControlName="code" oninput="this.value = this.value.toUpperCase()" required>
            <mat-error *ngIf="code?.invalid && (code?.dirty || code?.touched)">Code obligatoire</mat-error>
        </mat-form-field>
        
        <mat-form-field>
            <mat-label>Dénomination</mat-label>
            <input matInput type="text" id="libelle" formControlName="libelle" required>
            <mat-error *ngIf="libelle?.invalid && (libelle?.dirty || libelle?.touched)">Dénomination obligatoire</mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Email</mat-label>
            <input matInput type="mail" id="email" formControlName="email">
            <mat-error *ngIf="email?.invalid && (email?.dirty || email?.touched)">{{ formGroupFournisseur.controls.email.errors?.erreur }}</mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Télephone</mat-label>
            <input matInput type="text" id="telephone" formControlName="telephone" appTelephone>
            <mat-error *ngIf="email?.invalid && (email?.dirty || email?.touched)">{{ formGroupFournisseur.controls.telephone.errors?.erreur }}</mat-error>
        </mat-form-field>

        <app-adresse [adresse]="adresseFournisseur" controlKey="adresse"></app-adresse>
       
        <mat-form-field>
            <mat-label>Pays</mat-label>
            <input matInput type="text" id="pays" formControlName="pays" oninput="this.value = this.value.toUpperCase()">
        </mat-form-field>

        <mat-slide-toggle name="actif" id="actif" formControlName="actif" color="primary">
            {{(formGroupFournisseur.controls.actif.value ? 'Activé' : 'Désactivé' )}}
        </mat-slide-toggle>

        <div class="div-container-right" style="padding-top: 16px;">
            <button mat-button color="warn" class="button-width" (click)="onClickAnnuler()" style="padding-right: 16px;">
                <span>Annuler</span>
            </button>
            <button mat-flat-button 
                    color       = "primary" 
                    class       = "button-width" 
                    (click)     = "onClickValider()" 
                    *ngIf       = "!waitingValidation"
                    [disabled]  = "(formGroupFournisseur.pristine && !dirty) || (!formGroupFournisseur.valid && formGroupFournisseur.dirty) || (invalidAdresse && dirty)">
                <mat-icon>save</mat-icon>
                <span>Enregistrer</span>
            </button>
            <mat-spinner diameter="24" *ngIf="waitingValidation"></mat-spinner>
        </div>
    </form>
</mat-card>