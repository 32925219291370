import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClePublique } from '../interfaces/cle-publique';

@Injectable({
  providedIn: 'root'
})
export class ApiCleService {

  url: string = '/api/cle';
  constructor(private http: HttpClient) { }

  getCle(idEntite:number):Observable<ClePublique>{
    return this.http.get<ClePublique>(this.url+"/"+idEntite)
  }

  activeDesactiveCle(cle:ClePublique):Observable<ClePublique>{
    return this.http.put<ClePublique>(this.url,cle)
  }

  regenererCle(cle:ClePublique):Observable<ClePublique>{
    return this.http.put<ClePublique>(this.url+'/regenerer',cle)
  }
}
