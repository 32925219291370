<mat-card appearance="outlined" >
    <mat-card-header>
        <mat-card-title>{{factures.length}} facture<span *ngIf="factures.length >1">s</span></mat-card-title>
    </mat-card-header>

    
    <div class="container" *ngIf="dataSourceMatTable">
        <table mat-table 
                matTableResponsiveLarge 
                matTableExporter 
                [dataSource]="dataSourceMatTable" 
                #exporter="matTableExporter" 
                [hiddenColumns]="[11]" 
                class="table-primary">

            <!-- Service Column -->
            <ng-container matColumnDef="Service">
                <th mat-header-cell *matHeaderCellDef style="text-align: left; padding-right: 16px;" class="premiere-colonne colonne-invisible"> Service </th>
                <td mat-cell *matCellDef="let facture" style="text-align: left;" class="colonne-invisible">
                    <mat-icon [ngStyle]     = "{'color': apiFacture.getColorFacture(facture.type_document) }" 
                                svgIcon     = "{{apiUtilitaire.getIconeService(facture.Service.code) }}"
                                matTooltip  = "{{facture.Service.libelle}}">
                        <span class="cdk-visually-hidden">{{ apiUtilitaire.getIconeName(facture.Service.code) }}</span>
                    </mat-icon>
                </td>
            </ng-container>

            <!-- Type Column -->
            <ng-container matColumnDef="Type">
                <th mat-header-cell *matHeaderCellDef class="colonne-invisible"> Type </th>
                <td mat-cell *matCellDef="let facture" [ngStyle]="{'color': apiFacture.getColorFacture(facture.type_document) }" class="colonne-invisible">
                    {{ facture.type_document }}
                </td>
            </ng-container>

            <!-- Numéro de facture -->
            <ng-container matColumnDef="Numero">
                <th mat-header-cell *matHeaderCellDef style="text-align: left;" class="premiere-colonne colonne-invisible">  N° Facture/Avoir </th>
                <td mat-cell *matCellDef="let facture" style="text-align: left;" class="colonne-invisible">
                   {{facture.numero}}
                </td>
            </ng-container>

            <!-- Référence -->
            <ng-container matColumnDef="Reference">
                <th mat-header-cell *matHeaderCellDef> N° Référence </th>
                <td mat-cell *matCellDef="let facture" class="commande">{{ facture.reference }}</td>
            </ng-container>

            <!-- Date -->
            <ng-container matColumnDef="Date">
                <th mat-header-cell *matHeaderCellDef style="width: 92px;"> Date </th>
                <td mat-cell *matCellDef="let facture">
                    <div *ngIf="facture.dateCreation >= '19000101'">{{facture.dateCreation | date: 'dd/MM/yyyy'}}</div>
                    <div *ngIf="facture.dateCreation < '19000101'">__/__/____</div>
                </td>
            </ng-container>

           <!-- Total TTC Column -->
           <ng-container matColumnDef="Montant">
            <th mat-header-cell *matHeaderCellDef class="total" style="padding-right: 8px; padding-left: 8px;"> Total TTC </th>
            <td mat-cell *matCellDef="let facture" class="total">
                {{ facture.totalTTC | number:'1.2-2':'fr' }} €
            </td>
        </ng-container>

            <!-- Statut-->
            <ng-container matColumnDef="Statut">
                <th mat-header-cell *matHeaderCellDef class="etat-padding"> État </th>
                <td mat-cell *matCellDef="let facture" class="etat-padding">
                    <button mat-stroked-button class="button-etat button-width" color="primary"
                    [ngStyle]="{ color: apiFacture.getBackgroundColorByStatus(facture.Etat.code) }"
                    (click)="dialogHistoriqueFacture(facture.id)">{{ facture.Etat.libelle }}</button>
                </td>
            </ng-container>

            <!-- Actions -->
            <ng-container matColumnDef="Actions">
                <th mat-header-cell *matHeaderCellDef style="padding-left: 16px;"> </th>
                <td mat-cell *matCellDef="let facture" class="commande">
                    <a mat-flat-button title="Visualiser la facture" 
                            [routerLink]="['/app/facture/recap-facture/',facture.id,1]"
                            target="_blank"
                            color="primary"
                        class="custom-button" >Ouvrir</a>
                </td>
            </ng-container>

            

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="div-container-between" style="align-items: center;background-color:white">
            <app-export-table [fileExportName]="'FACTURE'" [exporter]="exporter" [nb_ligne]="factures.length"></app-export-table>

            <mat-paginator [pageSizeOptions]="[10,25,50]"
                showFirstLastButtons 
                aria-label="Nombre de lignes visibles...">
            </mat-paginator>
        </div>
         
         
    </div>
</mat-card>
