import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { InvitationCollaborateurBdd } from 'src/app/interfaces/invitation-collaborateur/invitation-collaborateur-bdd';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { ApiInvitationCollaborateurService } from 'src/app/services/api-invitation-collaborateur.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { InscriptionCollaborateurComponent } from '../inscription-collaborateur/inscription-collaborateur.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    selector: 'app-confirmation-invitation-entite',
    templateUrl: './confirmation-invitation-entite.component.html',
    styleUrls: ['./confirmation-invitation-entite.component.scss'],
    standalone: true,
    imports: [NgIf, MatCardModule, MatButtonModule, MatIconModule, InscriptionCollaborateurComponent,MatProgressSpinnerModule]
})
export class ConfirmationInvitationEntiteComponent implements OnInit {

  // Initialisation du component
  tocken        : string  = '';   
  tockenValide  : boolean = false;
  nouvelAdherent: boolean = true;

  // Interface de données
  entite       : Entite  = <Entite>{};
  destinataire : ProfilUtilisateur  = <ProfilUtilisateur>{};
  expediteur   : ProfilUtilisateur  = <ProfilUtilisateur>{};
  invitation   : InvitationCollaborateurBdd = <InvitationCollaborateurBdd>{};
  
  // Interface responsive
  innerWidth   : any;
  innerHeight  : any;
  waiting      : boolean = false

  constructor(private routed: ActivatedRoute
            , private route: Router
            , private toast: PersoSnackbarService
            , private apiInvitation: ApiInvitationCollaborateurService) { }

  ngOnInit(): void {
    // Récupération du token depuis l'url (reçu par mail)
    this.tocken        = this.routed.snapshot.params['tocken'];
    this.innerWidth   = window.innerWidth;
    this.innerHeight  = window.innerHeight;

    if ( this.tocken != undefined && this.tocken != '' ) {
      
      this.apiInvitation.getInvitationCollaborateur(this.tocken)
        .subscribe(
          (data: InvitationCollaborateurBdd) => {
            
            this.invitation   = data;            
            this.tockenValide = true;
            let date_aujourdhui: Date = new Date();
            let date_invitation: Date = new Date(this.invitation.date_creation);
            date_invitation.setDate(date_invitation.getDate() + 7);
          
            if(this.invitation.obsolete == true) {
              this.toast.showAlert("Le lien utilisé est obsolète.");
              this.route.navigate(['/login']);
            } else {
              if( date_invitation < date_aujourdhui) {
                this.toast.showAlert("Le lien utilisé est obsolète.");
                this.route.navigate(['/login']);
              }
            }
            
            // Permet d'afficher soit la page d'inscription, soit la page de confirmation d'invitation
            if(this.invitation.id_utl_destinataire > 0) {
              this.nouvelAdherent = false;
            } else {
              this.nouvelAdherent = true;
            }
          },
          (err) => {
            this.toast.showAlert("Le lien utilisé est obsolète.");
            this.route.navigate(['/login']);
          }
        );
    }
  }
  

  public accepterInvitation() {    
    this.waiting = true
    this.apiInvitation.postAccepterInvitation(this.invitation)
    .subscribe(
      (data) => {
        this.waiting = false
        this.toast.showInfo("L'invitation a été acceptée avec succès.Vous retrouverez le cabinet dans Mon Compte > Mes Cabinets/Entités");
        this.route.navigate(['/login']);
      },
      (err) => {
        this.waiting = false
        this.toast.showAlert("Une erreur est survenue." + err.message);
      },
      () => {}
    );
  }

  public refuserInvitation() {
    this.waiting = true
    this.apiInvitation.postRefuserInvitation(this.invitation)
    .subscribe(
      (data) => {
        this.waiting = false
        this.toast.showInfo("L'invitation a été refusée. Un email a été envoyé au dirigeant pour le prévenir de votre refus.");
        this.route.navigate(['/login']);
      },
      (err) => {
        this.waiting = false
        this.toast.showAlert("Une erreur est survenue." + err.message);
      },
      () => {}
    );
  }

  public isMobileFormat() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    
    if (this.innerWidth <= 500 || this.innerHeight <= 500) {
      return true;
    } else {
      return false;
    }
  }
}
