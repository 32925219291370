
<mat-card appearance="outlined" class="centered">
    <mat-card-content [formGroup]="MessageFormGroup">
        <ckeditor [editor]="editor" formControlName="messageText" ></ckeditor>
        
    </mat-card-content>
    
    <mat-card-actions>
        <div class="div-container-between" style="padding: 8px;">
            <div class="div-container-left" style="width:100%; display: flex; justify-content: center;">
                <app-upload-files   (uploadedFiles)  = "getFiles($event)" [uploadParameter]="uploadParameter"> </app-upload-files>
                <div class="div-button-center">
                    <button mat-flat-button color="primary" (click)="onSendMessage()" [disabled]="!MessageFormGroup.valid" [hidden]="waiting_Save" >Envoyer</button>
                    <mat-spinner diameter="24" *ngIf="waiting_Save"></mat-spinner>
                    <button mat-flat-button color="primary" (click)="onAnnule()">Annuler</button>
                </div>
            </div>
        </div>
    </mat-card-actions>
    
</mat-card>
