<mat-form-field *ngIf="entites$ | async as entites" style="width: 100%;">
    <mat-label>{{fieldName}}</mat-label>
    <mat-select required="required"
                [disabled]  = "!activated"
                [(value)]   = "entite"
                (selectionChange)="onSelectEntite($any($event).value)">
        <mat-option *ngFor="let entite of entites" 
                        [value]     = "entite" 
                        [disabled]  = "entite.coordonnees_bancaire.etat.code!=apiEntite.CONST_CODE_ETAT_COORDONNEES_BANCAIRE_VALIDE && CoordBanqDisabled">
                {{ entite.raison_sociale }}
                <span *ngIf="entite.coordonnees_bancaire.etat.code!=apiEntite.CONST_CODE_ETAT_COORDONNEES_BANCAIRE_VALIDE && CoordBanqDisabled" style="color: red;"> -- Coordonnées bancaires non validées</span>
        </mat-option>
    </mat-select>
    
</mat-form-field>
