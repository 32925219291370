<mat-card appearance="outlined" *ngIf="groupes">
    
    <mat-card-header>
        <mat-card-title>Gestion des groupes</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        
        <div class="group">
            <mat-form-field>
                <mat-label>Type d'entité</mat-label>
                <mat-select [(value)]="selectedType" (selectionChange)="selectType()">
                    
                    <mat-option *ngFor="let type of typesEntite" [value]="type">
                        {{type.libelle}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <!-- Table des groupes -->
        <table 
            mat-table 
            [dataSource]    = "groupes"  
            *ngIf           = "groupLinks"
            >
            <ng-container matColumnDef="groupe">
                <th mat-header-cell *matHeaderCellDef> Groupe </th>
                <td mat-cell *matCellDef="let groupe"> {{groupe.nom}} </td>
            </ng-container>
            
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef >
                    Lié à l'entité
                </th>
                <td mat-cell *matCellDef="let element;let i = index">
                    <mat-checkbox
                    [formControl]   = "getControl(i,'belongToType')"
                    (change)        = "setBelongValue(i,$event.checked)"
                    ></mat-checkbox>
                </td>
            </ng-container>
            
            <ng-container matColumnDef="defaut">
                <th mat-header-cell *matHeaderCellDef >Défaut</th>
                <td mat-cell *matCellDef="let element;let i=index">
                
                    <mat-checkbox
                     [formControl]="getControl(i,'default')"
                    ></mat-checkbox>
                </td>
            </ng-container>
            
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

    </mat-card-content>
    <mat-card-actions 
        *ngIf   = "groupLinks && !edition" 
        align   = "end">
        <button 
            *ngIf="!waitingTypes"
            mat-raised-button 
            color               = "primary"
            (click)             = "enregistreLiens()"
            >
            Enregistrer
        </button>
        <mat-spinner diameter="24" *ngIf="waitingTypes"></mat-spinner>
    </mat-card-actions>
</mat-card>
