import { Component, OnInit,Inject, Input } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { ExportType, MatTableExporterDirective } from 'mat-table-exporter';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';


export interface DialogData {
  fileExportName: string;
}

@Component({
    selector: 'app-export-table',
    templateUrl: './export-table.component.html',
    styleUrls: ['./export-table.component.scss'],
    standalone: true,
    imports: [MatButtonModule]
})
export class ExportTableComponent implements OnInit {
  @Input() fileExportName!:string;
  @Input() exporter       : MatTableExporterDirective = <MatTableExporterDirective>{};
  @Input() nb_ligne       : number = 0;

  bDisabledButton         : boolean = true;

  newFileName!:string;
  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    this.newFileName = this.fileExportName.concat('_',new Date().toISOString());
  }

  ngOnChanges(): void {
    if(this.nb_ligne > 0) {
      this.bDisabledButton = false;
    } else {
      this.bDisabledButton = true;
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogFileComponent, {
      data: {fileExportName: this.newFileName},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.newFileName = result;
        
        this.exporter.exportTable(ExportType.XLSX, {fileName : this.newFileName})
      } 
    });
  }

}

@Component({
    selector: 'dialog-file',
    templateUrl: 'dialog-file.component.html',
    standalone: true,
    imports: [MatDialogModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, FormsModule, MatButtonModule, MatIconModule]
})
export class DialogFileComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogFileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
