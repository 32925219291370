import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Suivi } from 'src/app/interfaces/acquittement/suivi';
import { ApiAcquittementService } from 'src/app/services/api-acquittement.service';
import { ApiConcentrateurService } from '../../../services/api-concentrateur.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableResponsiveDirective } from '../../../directive/mat-table-responsive/mat-table-responsive.directive';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, DatePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { Entite } from 'src/app/interfaces/profil-user/entite';

@Component({
    selector: 'app-table-acquittement',
    templateUrl: './table-acquittement.component.html',
    styleUrls: ['./table-acquittement.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatExpansionModule, MatIconModule, NgIf, MatButtonModule, MatProgressSpinnerModule, MatTableModule, MatTableResponsiveDirective, MatCheckboxModule, DatePipe]
})
export class TableAcquittementComponent implements OnInit, OnChanges {
     
  // Données à afficher
  @Input() ELEMENT_DATA   : Array<Suivi> = [];
  @Input() entiteFiltre   : Entite = <Entite>{};
  @Input() entites        : Entite[] = [];

  // Affichage des données dans la table + gestion du sélecteur
  selection   = new SelectionModel<Suivi>(true, []);
  dataSource  = new MatTableDataSource<Suivi>();

  // Gestion de l'affichage (bouton Acquittement grisé après activation)
  waitingAcquittement: boolean = false;
  expandPannel: boolean = true;
  
  // Tableau des suivis
  displayedColumns: string[] = ['select', 'habilitation', 'raisonSociale', 'date', 'immatriculation', 'dossier', 'type_op', 'expert', 'message'];

  constructor(private apiAcquittement: ApiAcquittementService
            , private apiConcentrateur : ApiConcentrateurService) { 
  }

  ngOnInit(): void {                        
  }

  ngOnChanges(): void {
    if(this.ELEMENT_DATA != undefined) {
      this.dataSource.data = this.ELEMENT_DATA;  
      this.selection.clear();
    }
  }

  public getLibelleOperation(type_operation: number) {
    switch (type_operation) {
      case this.apiAcquittement.CONST_OPERATION_1A:
        return '1A';
      case this.apiAcquittement.CONST_OPERATION_1B:
        return '1B';
      case this.apiAcquittement.CONST_OPERATION_1C:
        return '1C';
      default:
        return 'Inconnu';
    }
  }

  public postRemettreADisposition() { 
    this.waitingAcquittement = true;
    let entite : Entite | undefined = <Entite>{};

    // Détermine quelles lignes sont sélectionnées, et va remettre les acquittements
    for(let i = 0; i < this.dataSource.data.length; i++) {
      this.dataSource.data[i].message = '';

      if(this.selection.isSelected(this.dataSource.data[i])) {

        if (this.entiteFiltre.acces_concentrateur == undefined) {
          // si on ne connait pas l'entité sur laquelle on filtre, on va rechercher l'entité dans le tableau
          // c'est le cas où 'Tous' a été sélectionné dans le component MesAcquittementsComponent
          entite = this.entites.find(element => element.habilitation == this.dataSource.data[i].habilitation && element.indice == this.dataSource.data[i].indice_cabinet);
        } else {
          entite = {...this.entiteFiltre}
        }

        // on vérifie que l'acces concentrateur soit bien actif pour ce cabinet
        if (entite != undefined && entite.acces_concentrateur != undefined && entite.acces_concentrateur.actif == true) {
          this.apiConcentrateur.remettreDispositionAcquittement(this.dataSource.data[i].idSession, entite).subscribe(
            (data: any) => {
              this.dataSource.data[i].message = data.message;
            },
            (err: any) => {
              if ('fault' in err.error) {
                this.dataSource.data[i].message = err.error.fault.faultstring;
              } else {
                if (err.error != undefined) {
                  this.dataSource.data[i].message = err.error;
                }
              }          
            }
          )
        } else {
          // l'acces concentrateur pour ce cabinet est inexixtant on inactif, on informe l'utilisateur
          this.dataSource.data[i].message = 'L\'accès au concentrateur est inexistant ou inactif';
        }
      }
    }
    this.waitingAcquittement = false;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;        
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {      
    if (this.isAllSelected()) {
        this.selection.clear();
        return;
      }

      this.selection.select(...this.dataSource.data);
      
      console.log(this.dataSource.data);
      console.log(this.selection);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Suivi): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
}
