<footer>
    <div class="ffea_main">
        <div class="ffea_footer">            
            <p class="copyright">
                <a href="https://www.ffea.fr/mentions-legales/">Mentions légales</a>
                <a href="https://www.ffea.fr/politique-de-confidentialite/">Politique de confidentialité</a><br>
                © {{currentYear}} - Tous droits réservés FFEA
            </p>            
        </div>
    </div>
</footer>