import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { TypeDemande } from 'src/app/interfaces/support/type-demande';
import { Environnement } from 'src/app/interfaces/utilitaire/environnement';
import { ApiUtilitaireService } from 'src/app/services/tools/api-utilitaire.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { ApiSupportService } from 'src/app/services/api-support.service';
import { FiltreRechercheTypeDemande } from 'src/app/interfaces/support/filtre-recherche-type-demande';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgIf, NgFor } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { ContexteService, TYPE_DEMANDE } from 'src/app/services/contexte.service';

export interface OutPutRechercheTypeDemande{
  type_demande: Array<TypeDemande>
}
@Component({
    selector: 'app-filtre-type-demande',
    templateUrl: './filtre-type-demande.component.html',
    styleUrls: ['./filtre-type-demande.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatExpansionModule, MatIconModule, MatFormFieldModule
              , MatInputModule, NgIf, MatSelectModule, NgFor, MatOptionModule, MatButtonModule, MatProgressSpinnerModule]
})
export class FiltreTypeDemandeComponent implements OnInit {

  // Gestion de l'affichage
  waitingResearch       : boolean = false;
  expandPannel          : boolean = true;

  // Interface des filtres de recherche des types de demande
  filtre_recherche      : FiltreRechercheTypeDemande  = <FiltreRechercheTypeDemande>{};
  type_demandes         : Array<TypeDemande>          = Array<TypeDemande>();
  environnements        : Array<Environnement>        = Array<Environnement>();

  // Formulaire des filtres de recherche
  FiltreFormGroup       : FormGroup;
  
  // Accesseur en lecture des controls du formulaire
  get email_destinataire()          { return this.FiltreFormGroup.get('email_destinataire'); }
  get type_demande()  : FormArray   { return <FormArray>this.FiltreFormGroup.get('type_demande') as FormArray; }
  readonly type:string = TYPE_DEMANDE
  // Gestion de l'évènement recherche type demande
  @Output() research = new EventEmitter<OutPutRechercheTypeDemande>();

  constructor(private fb              : FormBuilder
            , private apiSupport      : ApiSupportService
            , private apiUtilitaire   : ApiUtilitaireService
            , private toast           : PersoSnackbarService
            , private contexte        : ContexteService
          ) {
    // Initialisation du formulaire des filtres de recherche
    this.FiltreFormGroup = this.fb.group({
      email_destinataire: '',
      type_demande      : [Array<TypeDemande>(), [Validators.required]]
    });

    // Subscribe here, this will automatically update 
    // "isDataModify" whenever a change to the subject is made.
    this.apiSupport.isDataModify.subscribe( (value: boolean) => {
      if(value) {
        this.apiSupport.isDataModify.next(false);
        this.getTypeDemandes();
        this.onSearchTypeDemande();
      }
    });
  }

  ngOnInit(): void {
    this.initFilter()
    this.getTypeDemandes();
    this.getEnvironnements();
    const liste:TypeDemande[] = this.contexte.getTableData(this.type)
    this.research.next({'type_demande':liste})
  }

  initFilter(){
    const filtre = this.contexte.getFiltre(TYPE_DEMANDE)
    if (filtre!=null) {
      this.FiltreFormGroup.patchValue(filtre)
    }
  }

  // Chargement des types de demandes
  public getTypeDemandes(): void {
    this.apiSupport.getListeTypeDemande()
      .subscribe(
        (data: TypeDemande[]) => {
          this.type_demandes = data;
          this.FiltreFormGroup.patchValue({
            type_demande: this.type_demandes
          });
        },
        err => { 
          '/!\\ error getTypeDemandes: ' + console.log(err);
        }
      );
  }

  // Chargement des environnements
  public getEnvironnements(): void {
    this.apiUtilitaire.getEnvironnement(this.apiUtilitaire.CONST_CODE_ENVIRONNEMENT_ALL)
      .subscribe(
        (data: Environnement[]) => {
          this.environnements = data;
          this.FiltreFormGroup.patchValue({
            environnement: this.environnements
          });
        },
        err => { 
          '/!\\ error getEnvironnements: ' + console.log(err);
        }
      );
  }

  public onSearchTypeDemande(): void {
    this.filtre_recherche = <FiltreRechercheTypeDemande>{};
    this.filtre_recherche.email_destinataire  = this.FiltreFormGroup.controls.email_destinataire.value;
    this.filtre_recherche.type_demande        = this.FiltreFormGroup.controls.type_demande.value;
  
    this.waitingResearch = true;

    this.apiSupport.postSearchingTypeDemande(this.filtre_recherche)
      .subscribe(
        (type_demandes: TypeDemande[]) => {
          if(type_demandes != undefined) {
            // Mise en place de la position, qui sera utilisé pour sélectionner dans le tableau des résultats
            for(let i = 0; i < type_demandes.length; i++) {
              type_demandes[i].position = i + 1;
            }

            this.research.emit({
              type_demande: type_demandes
            });
  
            this.toast.showInfo("Recherche terminée");
        } else {
          this.research.emit({
            type_demande: Array<TypeDemande>()
          });
          this.toast.showInfo("Aucun résultat");
        }
          
          this.waitingResearch = false;
        },
        (err: any) => {
          this.research.emit({
            type_demande: Array<TypeDemande>()
          });

          '/!\\ error postSearchingTypeDemande: ' + console.log(err);
          this.toast.showError(err.error);
          this.waitingResearch = false;
        }
      );     
  }

  ngOnDestroy(){
    this.contexte.setFiltre(this.FiltreFormGroup.value) 
  }

}
