import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FiltreStatistiques } from '../interfaces/statistiques/filtre-statistiques';
import { McelGlobale } from '../interfaces/statistiques/mcel-globale';
import { StatistiquesEntite } from '../interfaces/statistiques/statistiques-entite';

@Injectable({
  providedIn: 'root'
})
export class ApiStatistiqueService {

  url: string = '/api';

  constructor(private http: HttpClient) { }

  public postStatistiques(filtre: FiltreStatistiques): Observable<StatistiquesEntite[]> {    
    return this.http.post<StatistiquesEntite[]>(this.url+"/statistiques", filtre);
  }

  public getMCELGlobal(listeCodePostaux: string, type_recherche: string): Observable<McelGlobale[]> {
    return this.http.get<McelGlobale[]>(this.url+"/statistiques/mcel-globale?code_postal="+listeCodePostaux+"&type_recherche="+type_recherche);
  }

  public getAnneeMCELGlobal(): Observable<string[]> {
    return this.http.get<string[]>(this.url+"/statistiques/mcel-globale/annee")
  }

  public getStatParHabilitation(annee:string, habilitation:string):Observable<string>{
    return this.http.get<string>(this.url + "/statistiques/habilitation/" + habilitation + "/" + annee)
  }

  public getStatParTauxTva(annee:string, tva:string):Observable<string>{
    return this.http.get<string>(this.url + "/statistiques/taux-tva/" + tva + "/" + annee)
  }

  
}
