<mat-card appearance="outlined">
      <form [formGroup]="formGroupPhrasier">
      
      <h4>{{ titre_page }}</h4>

            <div class="div-container-left">
                  <mat-form-field>
                        <mat-label>Titre</mat-label>
                        <input matInput type="text" id="titre" formControlName="titre" maxlength="50" required>
                        <mat-error *ngIf="formGroupPhrasier.get('titre')?.hasError('required')">La saisie du titre est obligatoire</mat-error>
                  </mat-form-field>
            </div>
            <div class="div-container-left">
                  <mat-form-field>
                        <mat-label>Catégorie</mat-label>
                        <mat-select 
                              required
                              formControlName   = "categorie" 
                              (selectionChange) = "onSelectChangeCategorie($event)"
                              [(value)]         ="categorieSelected.id"
                              >
                              <mat-option *ngFor="let categorie of categoriesFiltre" [value]="categorie.id">
                                    {{ categorie.libelle }}
                              </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formGroupPhrasier.get('categorie')?.hasError('required')">La sélection d'une catégorie titre est obligatoire</mat-error>
                  </mat-form-field>

                  <mat-form-field>
                        <mat-label>Nouvelle Catégorie</mat-label>
                        <input matInput type="text" id="new_categorie" formControlName="new_categorie" oninput="this.value = this.value.toUpperCase()" maxlength="100">
                        <mat-error *ngIf="formGroupPhrasier.get('new_categorie')?.hasError('required')">La saisie d'une nouvelle catégorie titre est obligatoire</mat-error>
                  </mat-form-field>
            </div>
            <div class="div-container-left">
                  <mat-form-field>
                        <mat-label>Sous-catégorie</mat-label>
                        <mat-select name="sous_categorie" id="sous_categorie" formControlName="sous_categorie" (selectionChange)="onSelectChangeSousCategorie($event)" [(value)]="sousCategorieSelected.id">
                              <mat-option *ngFor="let sous_categorie of sous_categoriesFiltre" [value]="sous_categorie.id">
                                    {{ sous_categorie.libelle }}
                              </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formGroupPhrasier.get('sous_categorie')?.hasError('required')">La sélection d'une sous-catégorie titre est obligatoire</mat-error>
                  </mat-form-field>
                  <mat-form-field>
                        <mat-label>Nouvelle Sous-catégorie</mat-label>
                        <input 
                              matInput 
                              type              = "text" 
                              formControlName   = "new_sous_categorie" 
                              oninput           = "this.value = this.value.toUpperCase()"
                              maxlength         = "100" 
                              >
                        <mat-error *ngIf="formGroupPhrasier.get('new_sous_categorie')?.hasError('required')">La saisie d'une nouvelle sous-catégorie titre est obligatoire</mat-error>
                  </mat-form-field>
            </div>
            <div class="div-container-left">
                  <mat-form-field class="textarea" style="max-width:100%;">
                        <mat-label>Phrase</mat-label>
                        <textarea matInput id="phrase" formControlName="phrase" (keyup)="autoGrowTextZone($event)" class="textarea"></textarea>
                        <mat-error *ngIf="formGroupPhrasier.get('phrase')?.hasError('required')">La saisie d'une phrase est obligatoire</mat-error>
                  </mat-form-field>
            </div>
            <div class="div-container-right" style="margin-top: 16px;">
                  <button mat-flat-button type="button" color="basic" (click)="onClickAnnuler()"><mat-icon>west</mat-icon>Annuler</button>
                  <button mat-flat-button type="button" color="primary" (click)="onClickValider()" *ngIf="!waitingSave" [disabled]="formGroupPhrasier.invalid">Valider<mat-icon>done</mat-icon></button>
                  <mat-spinner diameter="24" *ngIf="waitingSave"></mat-spinner>
            </div>
      </form>
</mat-card>