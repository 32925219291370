<mat-card appearance="outlined" class="mat-card-padding">
    <div class="div-container-between">
        <mat-card-title>{{modeles.length}} modèle<span *ngIf="modeles.length >1">s</span></mat-card-title>
        <button mat-icon-button class="button-etat" color="primary" (click)="onCreer()"><mat-icon color="primary" matTooltip="Créer un modèle">note_add</mat-icon></button>
    </div>
    <div class="container">
        <table mat-table matTableResponsiveLarge [dataSource]="dataSourceMatTable" class="table-primary">
        
           <!-- Nom du modèle --> 
            <ng-container matColumnDef="nom">
                <th mat-header-cell *matHeaderCellDef> Modèle </th>
                <td mat-cell *matCellDef="let modele" > {{ modele.mdl_nom }} </td> 
            </ng-container>
            
             <!-- Sous-catégorie --> 
             <ng-container matColumnDef="sous_categorie">
                <th mat-header-cell *matHeaderCellDef> Sous-catégorie </th>
                <td mat-cell *matCellDef="let modele" > {{ modele.souscategorie.sca_nom }} </td> 
            </ng-container>

            <!-- Date de création --> 
            <ng-container matColumnDef="date_creation">
                <th mat-header-cell *matHeaderCellDef> Crée le </th>
                <td mat-cell *matCellDef="let modele">{{modele.mdl_date_creation | date: 'dd/MM/yyyy HH:mm:ss'}}</td> 
            </ng-container>

            <!-- Créateur du modèle --> 
            <ng-container matColumnDef="createur">
                <th mat-header-cell *matHeaderCellDef> Par </th>
                <td mat-cell *matCellDef="let modele" > {{ modele.utlCreateur.prenom}} {{ modele.utlCreateur.nom}} </td> 
            </ng-container>

            <!-- Date de modification -->
            <ng-container matColumnDef="date_modification">
                <th mat-header-cell *matHeaderCellDef> Modifié le </th>
                <td mat-cell *matCellDef="let modele" > {{this.apiModeleFacade.getDateNull(modele.mdl_date_modification)==false? (modele.mdl_date_modification | date: 'dd/MM/yyyy HH:mm:ss') : ('')}} </td>
            </ng-container>    
            
             <!-- Modifieur du modèle --> 
             <ng-container matColumnDef="modifieur">
                <th mat-header-cell *matHeaderCellDef> Par </th>
                <td mat-cell *matCellDef="let modele" > {{ modele.utlModifieur.prenom}} {{ modele.utlModifieur.nom}} </td> 
            </ng-container>
    
            <!-- Editer -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="derniere-colonne" style="text-align: right;"></th>
                <td mat-cell *matCellDef="let modele" class="colonne-module" style="text-align: right;">
                    <button mat-icon-button color="primary"
                        style="width: 48px; height: 48px;"
                        (click)="onEditer(modele)">
                        <mat-icon color="primary" matTooltip="Editer ce modèle">edit</mat-icon>
                    </button>

                    <button mat-icon-button class="button-etat" color="primary"
                        style="width: 48px; height: 48px;"
                        (click)="onDupliquer(modele)">
                        <mat-icon color="primary" matTooltip="Dupliquer ce modèle">file_copy</mat-icon>
                    </button>

                    <button mat-icon-button class="button-etat" color="primary"
                        style="width: 48px; height: 48px;"
                        (click)="onSupprimer(modele)">
                        <mat-icon color="warn" matTooltip="Supprimer ce modèle">delete</mat-icon>
                    </button>

                </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator
            [pageSizeOptions]="[5,10,25]"
            showFirstLastButtons 
            aria-label="Nombre de lignes visibles...">
        </mat-paginator>
    </div>
</mat-card>

