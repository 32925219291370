import { Injectable } from '@angular/core';
import{shareReplay} from 'rxjs/operators'
import { HttpClient } from '@angular/common/http';
import { Parametre } from '../interfaces/parametre';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { ApiErrorService } from './support/api-error.service';
import { catchError, map } from 'rxjs/operators';

export interface Balise{
  nom       : string,
  prenom    : string,
  immat     : string
}

@Injectable({
  providedIn: 'root'
})


export class ApiParametreService {

  url: string = '/api/param';
  private codeParametreSubject = new BehaviorSubject<string>('');

  codeParametreAction$ = this.codeParametreSubject.asObservable();

  parametres$           = this.http.get<Parametre[]>(this.url+'/ /').pipe(shareReplay(1))
  typesParametres$      = this.parametres$.pipe(map((parametres)=> [...new Set(parametres.map(p=> p.type))]))
 
  parametreWithFilter$  = combineLatest([
    this.parametres$,
    this.codeParametreAction$
  ]).pipe(
    map(([parametres,code])=> parametres.find(parametre => parametre.code === code))
    ,shareReplay(1)
  )

  parametresWithType$ = ""

  constructor(private http: HttpClient
            , private apiError: ApiErrorService) { }

  public putParametre(parametres: Parametre[]): Observable<Parametre[]> {    
    return this.http.put<Parametre[]>(this.url, parametres)
                    .pipe(catchError(this.apiError.handleError<Parametre[]>('putParametre')));
  }

  public getParametre(code:string){
    this.codeParametreSubject.next(code);
    
  }
  
  public getParametresFromType(type:string){
    return this.http.get<Parametre[]>(this.url + '/ /' + type).pipe(shareReplay(1))
  }

  public getMessageWithouthBalise(message:string,balise:Balise):string{
    let messageWithoutBalise:string = message
    if (balise.immat) {
      messageWithoutBalise = messageWithoutBalise.replace("%IMMAT%",balise.immat)
    }
    if (balise.nom) {
      messageWithoutBalise = messageWithoutBalise.replace("%NOM%",balise.nom)
    }
    if (balise.nom) {
      messageWithoutBalise = messageWithoutBalise.replace("%PRENOM%",balise.prenom)
    }
    return messageWithoutBalise;
  }
}
