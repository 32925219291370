import { Component, OnInit } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { TypeElement, TypeElementRel, TypeValeurResultat, TypeValeurResultatWithElements } from 'src/app/interfaces/adela/resultat-analyse';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AdelaFacadeService } from 'src/app/services/adela/adela-facade.service';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-composante',
    templateUrl: './composante.component.html',
    styleUrls: ['./composante.component.scss'],
    standalone: true,
    imports: [NgIf, MatCardModule, ReactiveFormsModule, MatGridListModule, NgFor, MatSlideToggleModule, MatButtonModule]
})
export class ComposanteComponent implements OnInit {

typeValeurResultatElements : TypeValeurResultatWithElements[]=[];
typeValeurElements$!      : Observable<TypeValeurResultat[]>
typeValeurElements        : TypeValeurResultat[]=[];
typeValeurElementForm!    : FormGroup;

elementArray():FormArray{
  return this.typeValeurElementForm.get(['elementArray']) as FormArray
}

typeElement(i:number):FormArray{
  return this.elementArray().at(i).get(['typeElement']) as FormArray
}

checked(i:number,j:number):FormControl{
  return this.typeElement(i).at(j).get(['checked']) as FormControl
}

  constructor(private apiAdelaFacade : AdelaFacadeService
              ,private fb            : FormBuilder) { }

  ngOnInit(): void {
   
    this.apiAdelaFacade.typesValeurResultatWithElements$.subscribe(
      (typeValeurResultatElement:TypeValeurResultatWithElements[])=>{
        
        this.typeValeurElementForm = this.generateFormGroup(typeValeurResultatElement)
      }
    )
  }

  Enregistrer(){
    this.apiAdelaFacade.enregistreLiens(this.typeValeurElementForm)
  }

  generateFormGroup(data:TypeValeurResultatWithElements[]):FormGroup{
    return this.fb.group({elementArray:this.fb.array(data.map((element)=>this.generateFormControl(element)))})
  }

  generateFormControl(data:TypeValeurResultatWithElements):FormGroup{
    return this.fb.group({
      libelle: data.libelle,
      code_categorie:data.code_categorie,
      id:data.id,
      typeElement:this.fb.array(data.relations.map((relation)=>this.generateTypeElementControl(relation)))
    })
  }

  generateTypeElementControl(relation:TypeElementRel):FormGroup{
    return this.fb.group({
      id: relation.id,
      idtpv:relation.tpv_id,
      idtpe:relation.tpe_id,
      code:relation.code,
      libelle:relation.libelle,
      checked:relation.id===0? false:true
    })
  }
}
