<mat-card appearance="outlined" style="height: auto; padding: 12px 24px 0 24px!important;">
    <mat-card-title>
        <div style="float: right; width: 100%;">
            <h2 style="text-decoration:underline ; text-decoration-color: #e48213;">{{ docTemp.titre }}</h2>
            <a href="">
                <h4 style="color: #e48213">{{ docTemp.Categorie.libelle }}</h4>
            </a>
            <span style="float: rigth; font-size: 11px;">{{ docTemp.datePublication | date: 'EEEE dd MMMM YYYY'}}</span>
        </div>
    </mat-card-title>
    <mat-card-subtitle>
        <span class="tag">Actualité</span>
        <span class="tag">Doc</span>
        <span class="tag">Juridique</span>
        <span class="tag">Voiture</span>
        <span class="tag"> ... </span>

    </mat-card-subtitle>
    <mat-card-content class=".mat-card-content">

    </mat-card-content>


    <!-- chemin de navigation -->
    <!-- titre de l'article -->
    <div style="float: left; margin: 0 12px 0 0; position: relative; width: 180px; background-color: #c3c3c3;"><img
            src="https://picsum.photos/200" alt="légende de l'image" style="width: 100%;">
        <div style="width: 100%; padding: 0 3px; text-align: center; color: #e9e9e9;">
            <p>legende de l'image</p>
        </div>

    </div>
    <div style="text-align: justify;" [innerHTML]="docTemp.contenu"></div>
    

    <mat-card-footer>
        <p style="width: 100%; background: linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(255,255,255,1) 100%);">
            <span style="float: right; margin: 3px;">écris par gilles gabet</span>
        </p>
    </mat-card-footer>

</mat-card>