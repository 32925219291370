import { Component, Input, OnInit } from '@angular/core';
import { StatistiquesEntite } from 'src/app/interfaces/statistiques/statistiques-entite';
import { RepartitionDangerositeComponent } from '../repartition-dangerosite/repartition-dangerosite.component';
import { RepartitionVeComponent } from '../repartition-ve/repartition-ve.component';
import { RepartitionVgeVeiComponent } from '../repartition-vge-vei/repartition-vge-vei.component';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { RepartitionMsgConcentrateurExpertComponent } from '../repartition-msg-concentrateur-expert/repartition-msg-concentrateur-expert.component';
import { RepartionMsgConcentrateurComponent } from '../repartion-msg-concentrateur/repartion-msg-concentrateur.component';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-entite-statistiques',
    templateUrl: './entite-statistiques.component.html',
    styleUrls: ['./entite-statistiques.component.scss'],
    standalone: true,
    imports: [MatCardModule, RepartionMsgConcentrateurComponent, RepartitionMsgConcentrateurExpertComponent, MatExpansionModule, MatIconModule, RepartitionVgeVeiComponent, RepartitionVeComponent, RepartitionDangerositeComponent]
})
export class EntiteStatistiquesComponent implements OnInit {

  // Gestion de l'affichage
  expandPannel: boolean = false

  // Données à afficher
  @Input() ELEMENT_DATA: StatistiquesEntite = <StatistiquesEntite>{};
  
  constructor() {
   }

  ngOnInit(): void {
  }
}
