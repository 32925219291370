import { DatePipe, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { MatTableExporterModule } from 'mat-table-exporter';
import { FiltreRecherchePrelevement } from 'src/app/interfaces/facture/filtre-recherche-prelevement';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ApiFactureService } from 'src/app/services/api-facture.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { MatTableResponsiveLargeDirective } from '../../../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';
import { ExportTableComponent } from '../../../../templates/export-table/export-table.component';
import { ContexteService, TYPE_CABINET, TYPE_INFOBANCAIRE } from 'src/app/services/contexte.service';

@Component({
    selector: 'app-table-informations-bancaires',
    templateUrl: './table-informations-bancaires.component.html',
    styleUrls: ['./table-informations-bancaires.component.scss'],
    standalone: true,
    imports: [MatExpansionModule, MatIconModule, NgIf, MatTableModule, MatTableResponsiveLargeDirective, MatTableExporterModule, MatTooltipModule, MatButtonModule, ExportTableComponent, MatPaginatorModule, DatePipe]
})
export class TableInformationsBancairesComponent implements OnInit {

  CONST_CODE_ETAT_COORDONNEES_BANCAIRE_VALIDE: string = 'VALIDE' as const;
  CONST_CODE_ETAT_COORDONNEES_BANCAIRE_REFUSE: string = 'REFUSE' as const;
  CONST_CODE_ETAT_COORDONNEES_BANCAIRE_A_VALIDER: string = 'A_VALIDER' as const;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;
  @Input() dataSource_entites: any;

  dataSourceMatTable: MatTableDataSource<any> = new MatTableDataSource<any>();
  
  expandPannel        : boolean = false;
  isExporting : boolean=false;

  filtre_recherche_prelevement: FiltreRecherchePrelevement = <FiltreRecherchePrelevement>{};  

  // Tableau des entites / coordonnées bancaire
  displayedColumns    : Array<string> = [ 'ent_numero_client', 'ent_raison_sociale', 'ett_bnc_code', 'bnc_iban', 'bnc_bic', 'bnc_rum', 'bnc_date_mandat', 'actions'];
  readonly type      : string = TYPE_INFOBANCAIRE

  constructor(private router    : Router
            , private apiFacture: ApiFactureService
            , private toast     : PersoSnackbarService
            , private contexte  : ContexteService
            , private cdr       : ChangeDetectorRef ) { }

  ngOnInit(): void {
    // if(this.dataSource_entites != undefined) {
      
      
    //   this.dataSourceMatTable.data = this.dataSource_entites;
      
    //   if(this.dataSourceMatTable.data.length > 0) {
    //     this.expandPannel = true;
    //   }
    // }
  }
  
  ngAfterViewInit() {
    this.paginator!.pageIndex = this.contexte.getPageIndex(this.type)
    this.paginator!.pageSize = this.contexte.getPages(this.type)
    this.dataSourceMatTable.paginator = this.paginator;
    this.dataSourceMatTable.sort      = this.sort;
    this.cdr.detectChanges();
  }
  
  ngOnChanges() {
    this.dataSourceMatTable.paginator = this.paginator;
    // obligatoire
    if(this.dataSource_entites != undefined) {
      this.dataSourceMatTable.data = this.dataSource_entites;
      if(this.dataSourceMatTable.data.length > 0) {
        this.expandPannel = true;
      }      
    }     
  }

  public onClickOpenMandatPrelevement(entite: Entite) {
    let dateToday: Date = new Date();
    let dateLastYear: Date = new Date();    
    dateLastYear.setFullYear(dateLastYear.getFullYear() - 1);

    this.filtre_recherche_prelevement.date_debut              = dateLastYear;
    this.filtre_recherche_prelevement.date_fin                = dateToday;
    this.filtre_recherche_prelevement.entite                  = entite;
    this.filtre_recherche_prelevement.iban                    = entite.coordonnees_bancaire.iban;
    this.filtre_recherche_prelevement.numero_lot              = '';
    this.filtre_recherche_prelevement.reference_bout_en_bout  = '';
    this.filtre_recherche_prelevement.reference_mandat        = '';
    this.apiFacture.filtre_recherche_prelevement.next(this.filtre_recherche_prelevement);
  }

  public onValiderCoordonneesBancaire(ent_id: number): void {
    this.router.navigate(['/app/administration-extranet/comptabilite/informations-bancaires/', ent_id]);
  }

  ngOnDestroy(){
    this.contexte.setType(this.type)
    this.contexte.setPageIndex(this.dataSourceMatTable.paginator!.pageIndex)
    this.contexte.setTableData(this.dataSource_entites)
    this.contexte.setPages(this.paginator? this.paginator.pageSize : 5)
  }

}
