<mat-card appearance="outlined" class="mat-card">
    <mat-card-title>Veuillez saisir un numéro de bordereau...</mat-card-title>
    <form [formGroup]="formGroupBordereau">
        <mat-form-field>
            <mat-label>Bordereau</mat-label>
            <input matInput type="text" id="bordereau" formControlName="bordereau">
            <mat-error *ngIf="bordereau?.invalid && (bordereau?.dirty || bordereau?.touched)">{{ bordereau.errors?.erreur }}</mat-error>
        </mat-form-field>
        
        <div class="div-button-right">
            <button mat-raised-button color="warn" (click)="onDeclineRemise()" [mat-dialog-close]="false">Annuler</button>
            <button mat-raised-button color="primary" (click)="onAcceptRemise()" [disabled]="formGroupBordereau.invalid" [mat-dialog-close]="true">Remettre les règlements</button>
        </div>
    </form>        
</mat-card>