import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { now } from 'd3-timer';
import { Observable } from 'rxjs';
import { Actualite } from '../interfaces/actualite';
import { Document } from '../interfaces/document';
import { PersoSnackbarService } from './perso-snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class ApiDocutechService {

  urlApi: string = '/api';

  doc: Actualite = {
    id: 0,
    titre: 'Lorem ipsum dolor sit amet',
    description: `
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ornare tortor sem, eu tristique tortor laoreet eu. Sed vitae pharetra velit. Sed iaculis nunc ut lacus cursus dapibus. Suspendisse tristique mi id enim auctor eleifend. Mauris ultrices massa diam, ut ultricies velit mattis quis. Nulla mollis diam lectus, et lacinia nunc finibus vel. Nulla nisi risus, finibus et velit id, ultricies euismod magna. In in mi massa. Sed posuere mollis elit ac mattis.
    `,
    contenu: `
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ornare tortor sem, eu tristique tortor laoreet eu. Sed vitae pharetra velit. Sed iaculis nunc ut lacus cursus dapibus. Suspendisse tristique mi id enim auctor eleifend. Mauris ultrices massa diam, ut ultricies velit mattis quis. Nulla mollis diam lectus, et lacinia nunc finibus vel. Nulla nisi risus, finibus et velit id, ultricies euismod magna. In in mi massa. Sed posuere mollis elit ac mattis.

Proin fringilla nunc a est luctus molestie. Integer nec finibus est. Phasellus mattis augue quis lectus porta, sed eleifend nisl tincidunt. Praesent scelerisque est molestie, pulvinar massa nec, tristique erat. Pellentesque commodo venenatis scelerisque. Praesent nibh turpis, accumsan ut nulla ac, aliquam feugiat nibh. Vestibulum nec orci velit. Fusce congue lectus eu tristique vehicula.

In velit dolor, tristique quis cursus porta, cursus eu mi. Suspendisse fermentum sodales nisi ac laoreet. Fusce convallis ullamcorper faucibus. Nunc luctus risus non massa sodales, sed iaculis orci consectetur. Mauris varius porttitor euismod. Sed eu arcu vel dolor malesuada pharetra non ut justo. Suspendisse quis mauris non enim volutpat scelerisque. Suspendisse quam augue, tempus a semper et, condimentum eget orci.

Nullam suscipit, dui quis luctus porttitor, diam risus gravida diam, vel mollis orci turpis ut mauris. Cras vehicula interdum tellus, a mollis sapien. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec porttitor, mi lobortis auctor ultricies, arcu mauris scelerisque erat, non efficitur erat mauris eu eros. Etiam augue enim, pulvinar eget ullamcorper sit amet, rutrum quis massa. Sed molestie non erat quis rutrum. Cras sed velit eu ligula ultrices pulvinar. Donec gravida semper finibus. Aliquam nisl odio, posuere id sapien quis, luctus malesuada ante. Sed in mauris placerat, rutrum dolor sed, mollis lacus. Nullam egestas sem tellus, interdum porta lorem elementum non. Aliquam eros risus, iaculis vitae lorem in, interdum facilisis nunc.

Morbi tincidunt rhoncus accumsan. Nunc sit amet eleifend urna, et elementum sapien. Ut tristique lectus non nisi efficitur pharetra. Donec et sapien lorem. Fusce maximus ipsum ipsum, ut commodo leo porttitor sit amet. Sed vel magna et erat luctus pretium eget quis augue. Ut tincidunt, ipsum vitae consectetur posuere, odio mi ornare lacus, sed egestas justo magna et magna. Quisque non finibus ante. Donec eget risus quam. Proin eleifend eleifend tristique. Etiam finibus tortor suscipit massa feugiat, a lacinia neque ultrices. Vestibulum id porta nisi. Nunc consequat erat non elementum iaculis. Aliquam vehicula tempor ullamcorper. Quisque sagittis tristique eros vel consectetur. Vestibulum sit amet leo quis enim commodo maximus. 
    `,
    idFamille: 0,
    idEtat: 0,
    idCategorie: 0,
    dateCreation: new Date(),
    datePublication: new Date(),
    dataPublicationFin: new Date(),
    Tags: [],
    Fichiers: [],
    Familles: [],
    Etat: {
      id: 0,
      libelle: '',
      description: '',
      ordre: 0,
      code: ''
    },
    estVitrine: true,
    estActif: true,
    estDejaLu: true
  };

  constructor(
    private http: HttpClient
    , private toast: PersoSnackbarService
  ) {

   }

  public ngOnInit() {
  
  }

  public getLastNews(): Observable<any>{
    return this.http.get(this.urlApi + `/docs/derniersDocuments`)
  }

  public getActualites(): any {
    let indice: number = 0;
    let docTemp: any = {
      titre: 'Lorem ipsum dolor sit amet consectetur adipiscing elit',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ornare tortor sem, eu tristique tortor laoreet eu. Sed vitae pharetra velit. Sed iaculis nunc ut lacus cursus dapibus. Suspendisse tristique mi id enim auctor eleifend. Mauris ultrices massa diam, ut ultricies velit mattis quis. Nulla mollis diam lectus, et lacinia nunc finibus vel. Nulla nisi risus, finibus et velit id, ultricies euismod magna. In in mi massa. Sed posuere mollis elit ac mattis.',
      contenu: `
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ornare tortor sem, eu tristique tortor laoreet eu. Sed vitae pharetra velit. Sed iaculis nunc ut lacus cursus dapibus. Suspendisse tristique mi id enim auctor eleifend. Mauris ultrices massa diam, ut ultricies velit mattis quis. Nulla mollis diam lectus, et lacinia nunc finibus vel. Nulla nisi risus, finibus et velit id, ultricies euismod magna. In in mi massa. Sed posuere mollis elit ac mattis.
Proin fringilla nunc a est luctus molestie. Integer nec finibus est. Phasellus mattis augue quis lectus porta, sed eleifend nisl tincidunt. Praesent scelerisque est molestie, pulvinar massa nec, tristique erat. Pellentesque commodo venenatis scelerisque. Praesent nibh turpis, accumsan ut nulla ac, aliquam feugiat nibh. Vestibulum nec orci velit. Fusce congue lectus eu tristique vehicula.
In velit dolor, tristique quis cursus porta, cursus eu mi. Suspendisse fermentum sodales nisi ac laoreet. Fusce convallis ullamcorper faucibus. Nunc luctus risus non massa sodales, sed iaculis orci consectetur. Mauris varius porttitor euismod. Sed eu arcu vel dolor malesuada pharetra non ut justo. Suspendisse quis mauris non enim volutpat scelerisque. Suspendisse quam augue, tempus a semper et, condimentum eget orci.

Nullam suscipit, dui quis luctus porttitor, diam risus gravida diam, vel mollis orci turpis ut mauris. Cras vehicula interdum tellus, a mollis sapien. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec porttitor, mi lobortis auctor ultricies, arcu mauris scelerisque erat, non efficitur erat mauris eu eros. Etiam augue enim, pulvinar eget ullamcorper sit amet, rutrum quis massa. Sed molestie non erat quis rutrum. Cras sed velit eu ligula ultrices pulvinar. Donec gravida semper finibus. Aliquam nisl odio, posuere id sapien quis, luctus malesuada ante. Sed in mauris placerat, rutrum dolor sed, mollis lacus. Nullam egestas sem tellus, interdum porta lorem elementum non. Aliquam eros risus, iaculis vitae lorem in, interdum facilisis nunc.
Morbi tincidunt rhoncus accumsan. Nunc sit amet eleifend urna, et elementum sapien. Ut tristique lectus non nisi efficitur pharetra. Donec et sapien lorem. Fusce maximus ipsum ipsum, ut commodo leo porttitor sit amet. Sed vel magna et erat luctus pretium eget quis augue. Ut tincidunt, ipsum vitae consectetur posuere, odio mi ornare lacus, sed egestas justo magna et magna. Quisque non finibus ante. Donec eget risus quam. Proin eleifend eleifend tristique. Etiam finibus tortor suscipit massa feugiat, a lacinia neque ultrices. Vestibulum id porta nisi. Nunc consequat erat non elementum iaculis. Aliquam vehicula tempor ullamcorper. Quisque sagittis tristique eros vel consectetur. Vestibulum sit amet leo quis enim commodo maximus. 
      `
    }
    var docs: Array<typeof docTemp>= [];

    for (indice=0; indice<=5; indice++) {
      docs.push({
        titre: 'Lorem ipsum dolor sit amet consectetur adipiscing elit',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ornare tortor sem, eu tristique tortor laoreet eu. Sed vitae pharetra velit. Sed iaculis nunc ut lacus cursus dapibus. Suspendisse tristique mi id enim auctor eleifend. Mauris ultrices massa diam, ut ultricies velit mattis quis. Nulla mollis diam lectus, et lacinia nunc finibus vel. Nulla nisi risus, finibus et velit id, ultricies euismod magna. In in mi massa. Sed posuere mollis elit ac mattis.',
        contenu: `
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ornare tortor sem, eu tristique tortor laoreet eu. Sed vitae pharetra velit. Sed iaculis nunc ut lacus cursus dapibus. Suspendisse tristique mi id enim auctor eleifend. Mauris ultrices massa diam, ut ultricies velit mattis quis. Nulla mollis diam lectus, et lacinia nunc finibus vel. Nulla nisi risus, finibus et velit id, ultricies euismod magna. In in mi massa. Sed posuere mollis elit ac mattis.
  Proin fringilla nunc a est luctus molestie. Integer nec finibus est. Phasellus mattis augue quis lectus porta, sed eleifend nisl tincidunt. Praesent scelerisque est molestie, pulvinar massa nec, tristique erat. Pellentesque commodo venenatis scelerisque. Praesent nibh turpis, accumsan ut nulla ac, aliquam feugiat nibh. Vestibulum nec orci velit. Fusce congue lectus eu tristique vehicula.
  In velit dolor, tristique quis cursus porta, cursus eu mi. Suspendisse fermentum sodales nisi ac laoreet. Fusce convallis ullamcorper faucibus. Nunc luctus risus non massa sodales, sed iaculis orci consectetur. Mauris varius porttitor euismod. Sed eu arcu vel dolor malesuada pharetra non ut justo. Suspendisse quis mauris non enim volutpat scelerisque. Suspendisse quam augue, tempus a semper et, condimentum eget orci.

  Nullam suscipit, dui quis luctus porttitor, diam risus gravida diam, vel mollis orci turpis ut mauris. Cras vehicula interdum tellus, a mollis sapien. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec porttitor, mi lobortis auctor ultricies, arcu mauris scelerisque erat, non efficitur erat mauris eu eros. Etiam augue enim, pulvinar eget ullamcorper sit amet, rutrum quis massa. Sed molestie non erat quis rutrum. Cras sed velit eu ligula ultrices pulvinar. Donec gravida semper finibus. Aliquam nisl odio, posuere id sapien quis, luctus malesuada ante. Sed in mauris placerat, rutrum dolor sed, mollis lacus. Nullam egestas sem tellus, interdum porta lorem elementum non. Aliquam eros risus, iaculis vitae lorem in, interdum facilisis nunc.
  Morbi tincidunt rhoncus accumsan. Nunc sit amet eleifend urna, et elementum sapien. Ut tristique lectus non nisi efficitur pharetra. Donec et sapien lorem. Fusce maximus ipsum ipsum, ut commodo leo porttitor sit amet. Sed vel magna et erat luctus pretium eget quis augue. Ut tincidunt, ipsum vitae consectetur posuere, odio mi ornare lacus, sed egestas justo magna et magna. Quisque non finibus ante. Donec eget risus quam. Proin eleifend eleifend tristique. Etiam finibus tortor suscipit massa feugiat, a lacinia neque ultrices. Vestibulum id porta nisi. Nunc consequat erat non elementum iaculis. Aliquam vehicula tempor ullamcorper. Quisque sagittis tristique eros vel consectetur. Vestibulum sit amet leo quis enim commodo maximus. 
        `
      });
    }

    return docs;
  }
}


