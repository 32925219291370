<mat-card appearance="outlined" class="mat-card-no-shadow" style="padding: 0px;">
    <form [formGroup]="FiltreFormGroup">
        <mat-expansion-panel id="expandFilter" [(expanded)]="expandPannel" #expandFilter>                                
            <mat-expansion-panel-header style="background-color: #fff">
                <mat-panel-title class="label-accent">
                    <mat-icon>search</mat-icon>Veuillez sélectionner les options de votre recherche...
                </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-form-field>
                <mat-label>Nom</mat-label>
                <input matInput type="text" placeholder="Nom" name="nom" id="nom" formControlName="nom" oninput="this.value = this.value.toUpperCase()">
                <mat-error *ngIf="nom?.invalid && (nom?.dirty || nom?.touched)">{{ nom?.errors?.erreur }}</mat-error>
            </mat-form-field> 

            <mat-form-field>
                <mat-label>Prénom</mat-label>
                <input  appTitleCase matInput type="text" placeholder="Prénom" name="prenom" id="prenom" formControlName="prenom">
                <mat-error *ngIf="prenom?.invalid && (prenom?.dirty || prenom?.touched)">{{ prenom?.errors?.erreur }}</mat-error>
            </mat-form-field> 

            <mat-form-field>
                <mat-label>Agrément</mat-label>
                <input matInput type="text" placeholder="Agrément" name="agrement" id="agrement" formControlName="agrement">
                <mat-error *ngIf="agrement?.invalid && (agrement?.dirty || agrement?.touched)">{{ agrement?.errors?.erreur }}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Groupe d'utilisateurs</mat-label>
                <mat-select required formControlName="groupe" multiple>
                    <mat-option *ngFor="let groupe of groupes" [value]="groupe" >
                        {{ groupe.nom }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="!FiltreFormGroup?.valid && FiltreFormGroup.touched">Vous devez sélectionner au moins un groupe d'utilisateurs pour votre recherche</mat-error>
            </mat-form-field> 
            
            <!-- US 326 : Rajout des critères IDContact et Habilitation -->
            <mat-form-field>
                <mat-label>Numéro contact</mat-label>
                <input matInput type="number" placeholder="Numéro client" name="idcontact" id="idcontact" formControlName="idcontact">
                <mat-error *ngIf="idcontact?.invalid && (idcontact?.dirty || idcontact?.touched)">{{ idcontact?.errors?.erreur }}</mat-error>
            </mat-form-field> 
            <mat-form-field>
                <mat-label>Habilitation</mat-label>
                <input matInput type="number" placeholder="Habilitation" name="habilitation" id="habilitation" formControlName="habilitation">
                <mat-error *ngIf="habilitation?.invalid && (habilitation?.dirty || habilitation?.touched)">{{ habilitation?.errors?.erreur }}</mat-error>
            </mat-form-field> 
            <!-- Fin US326 -->        

            <mat-action-row>
                <div class="div-container-between" style="padding: 8px;">
                    <div class="div-container-center" style="width:100%; display: flex; justify-content: center;">
                        <button 
                            mat-flat-button 
                            submit 
                            *ngIf       = "!waitingResearch"
                            color       = "primary" 
                            [disabled]  = "waitingResearch || !FiltreFormGroup.valid" 
                            (click)     = "onSearchingUtilisateurs()">
                            <mat-icon>search</mat-icon>Rechercher                                    
                        </button>
                        <mat-spinner diameter="24" *ngIf="waitingResearch"></mat-spinner>
                    </div>
                </div>
            </mat-action-row>
        </mat-expansion-panel>
    </form>
</mat-card>