<!-- <div class="container">
    <div class="content">
      <p>{{information}}</p>
      <button 
        mat-raised-button 
        color           ="primary"
        style="margin-top: 50px;"
        >
        Retour à l'accueil
    </button>
    </div>
  </div> -->

  <div class="container">
    <div class="content">
        <p>{{information}}</p>
    </div>
    <div class="button-container">
      <button (click)="goToHome()">Retour à l'accueil</button>
    </div>
  </div>