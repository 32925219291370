<mat-card appearance="outlined">
    <mat-card-title>Grille Tarifaire {{ this.code_service | uppercase }}</mat-card-title>
    
    <mat-card-content  class=".mat-card-content">
        <table mat-table matTableResponsive [dataSource] =  "(prestationsDisplayed$ |async)  ?? [] " class="table-primary">

            <ng-container matColumnDef="prestation">
                <th mat-header-cell *matHeaderCellDef class="premiere-colonne td-premiere-colonne"> Libellé </th>
                <td mat-cell *matCellDef="let prestation" class="td-premiere-colonne"> {{ prestation.libelle }} </td>
            </ng-container>

            <ng-container matColumnDef="prix_ht" style="text-align: right;">
                <th mat-header-cell *matHeaderCellDef class="derniere-colonne"> Prix HT </th>
                <td mat-cell *matCellDef="let prestation"> {{prestation.tarif}} €</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </mat-card-content>
</mat-card>