import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { TypeDemande } from 'src/app/interfaces/support/type-demande';
import { ApiSupportService } from 'src/app/services/api-support.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { ActionDeMasseTypeDemandeComponent } from '../action-de-masse-type-demande/action-de-masse-type-demande.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableResponsiveLargeDirective } from '../../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgStyle } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { ContexteService, TYPE_DEMANDE } from 'src/app/services/contexte.service';

@Component({
    selector: 'app-table-type-demande',
    templateUrl: './table-type-demande.component.html',
    styleUrls: ['./table-type-demande.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatExpansionModule, MatIconModule, NgIf, MatButtonModule, MatTableModule, MatTableResponsiveLargeDirective, MatCheckboxModule, NgStyle, MatPaginatorModule]
})
export class TableTypeDemandeComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;
  @Input() dataSource_type_demande: any;
  
  selection   = new SelectionModel<TypeDemande>(true, []);
  dataSourceMatTable: MatTableDataSource<any> = new MatTableDataSource<any>();
  
  expandPannel        : boolean = true;

  // Tableau des types demande
  displayedColumns    : Array<string> = [ 'select', 'typ_libelle', 'typ_email_destinataire', 'typ_environnement_extranet_ffea', 'typ_environnement_services_ffea', 'typ_actif', 'actions'];
  readonly type      : string = TYPE_DEMANDE
  constructor(private router    : Router
            , public dialog     : MatDialog
            , private toast     : PersoSnackbarService
            , private apiSupport: ApiSupportService
            , private contexte        : ContexteService
            , private cdr             : ChangeDetectorRef) { }

  ngOnInit(): void {
    this.contexte.setType(this.type)
    // this.dataSourceMatTable.data = this.contexte.getTableData(this.type);
  }

  ngAfterViewInit() {
    this.paginator!.pageIndex = this.contexte.getPageIndex(this.type)
    this.paginator!.pageSize = this.contexte.getPages(this.type)
    this.dataSourceMatTable.paginator = this.paginator;
    this.dataSourceMatTable.sort      = this.sort;
    this.cdr.detectChanges();
  }

  ngOnChanges() {
    this.dataSourceMatTable.paginator = this.paginator;
    // obligatoire
    if(this.dataSource_type_demande != undefined) {
      this.dataSourceMatTable.data = this.dataSource_type_demande;
      this.selection.clear();
    }     
  }

  public onClickOpenTypeDemande(typ_id: number) {
    this.router.navigate(['/app/administration-extranet/support/type-demande/', typ_id]);
  }

  public onCreer(): void {
    this.router.navigate(['/app/administration-extranet/support/type-demande/nouveau']);
  }

  public onActiver(): void {    
    let dataSource_type_demande = this.getDataSourceSelected();
    if(dataSource_type_demande != undefined) {
      if(dataSource_type_demande.length > 0 ) {
        const dialogRef = this.dialog.open(ActionDeMasseTypeDemandeComponent, {data : {type: 'activate', dataSource_type_demande: this.getDataSourceSelected()}});   
    
        dialogRef.afterClosed().subscribe(result => {
          if(result) {
            this.apiSupport.isDataModify.next(true);
            this.selection.clear();
          }      
        });
      } else {
        this.toast.showAlert('Vous devez sélectionner au moins un type de demande')
      }
    }
  }

  public onDesactiver(): void {
    let dataSource_type_demande = this.getDataSourceSelected();
    if(dataSource_type_demande != undefined) {
      if(dataSource_type_demande.length > 0 ) {
        const dialogRef = this.dialog.open(ActionDeMasseTypeDemandeComponent, {data : {type: 'deactivate', dataSource_type_demande: this.getDataSourceSelected()}});

        dialogRef.afterClosed().subscribe(result => {
          if(result) {
            this.apiSupport.isDataModify.next(true);
          }      
        });
      } else {
        this.toast.showAlert('Vous devez sélectionner au moins un type de demande')
      }
    }
  }

  public onSupprimer(): void {
    let dataSource_type_demande = this.getDataSourceSelected();
    if(dataSource_type_demande != undefined) {
      if(dataSource_type_demande.length > 0 ) {
        const dialogRef = this.dialog.open(ActionDeMasseTypeDemandeComponent, {data : {type: 'delete', dataSource_type_demande: dataSource_type_demande}});

        dialogRef.afterClosed().subscribe(result => {
          if(result) {
            this.apiSupport.isDataModify.next(true);
          }      
        });
      } else {
        this.toast.showAlert('Vous devez sélectionner au moins un type de demande')
      }
    }    
  }

  // Récupère les types de demande sélectionnés dans la table
  private getDataSourceSelected(): Array<TypeDemande> {
    let dataSource: Array<TypeDemande> = Array<TypeDemande>();

    // Détermine quelles lignes sont sélectionnées, et les ajoute dans les données à transmettre à la mat-dialog
    for(let i = 0; i < this.dataSource_type_demande.length; i++) {
      if(this.selection.isSelected(this.dataSource_type_demande[i])) {
        dataSource.push(this.dataSource_type_demande[i]);
      }
    }

    return dataSource;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceMatTable.data.length;
    return numSelected === numRows;        
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {      
    if (this.isAllSelected()) {
        this.selection.clear();
        return;
      }

      this.selection.select(...this.dataSourceMatTable.data);  
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: TypeDemande): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  ngOnDestroy(){
    this.contexte.setPageIndex(this.dataSourceMatTable.paginator!.pageIndex)
    this.contexte.setTableData(this.dataSource_type_demande)
    this.contexte.setPages(this.paginator? this.paginator.pageSize : 5)
  }

}
