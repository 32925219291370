import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EntiteSearchComponent } from 'src/app/components/templates/entite-search/entite-search.component';
import { EtatCoordonneesBancaire } from 'src/app/interfaces/profil-user/coordonnees-bancaire/etat-coordonnees-bancaire';
import { FiltreRechercheCoordonneesBancaire } from 'src/app/interfaces/profil-user/coordonnees-bancaire/filtre-recherche-coordonnees-bancaire';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { ContexteService, TYPE_INFOBANCAIRE, TYPE_UTILISATEUR } from 'src/app/services/contexte.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
export interface OutPutRechercheCoordonneesBancaire{
  entites: Array<Entite>
}
@Component({
    selector: 'app-filtre-informations-bancaires',
    templateUrl: './filtre-informations-bancaires.component.html',
    styleUrls: ['./filtre-informations-bancaires.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatExpansionModule, MatIconModule, MatFormFieldModule, MatInputModule, NgIf
              , MatSelectModule, MatOptionModule, NgFor, MatButtonModule, MatProgressSpinnerModule, EntiteSearchComponent
              , MatTooltipModule]
})
export class FiltreInformationsBancairesComponent implements OnInit {

  // Gestion de l'affichage
  waitingResearch       : boolean = false;
  expandPannel          : boolean = true;

  // Interface des filtres de recherche des articles
  filtre_recherche      : FiltreRechercheCoordonneesBancaire  = <FiltreRechercheCoordonneesBancaire>{};
  etats$                 : Array<EtatCoordonneesBancaire>      = Array<EtatCoordonneesBancaire>();

  // Formulaire des filtres de recherche
  FiltreFormGroup       : FormGroup;
  entiteSelected    : Entite|null =null
  searching         : boolean = false
  readonly typeContexte      : string = TYPE_INFOBANCAIRE

  get numero_client() { return this.FiltreFormGroup.get('numero_client'); }
  get etats()          { return this.FiltreFormGroup.get('etats'); }

  // Gestion de l'évènement recherche coordonnées bancaire
  @Output() research = new EventEmitter<OutPutRechercheCoordonneesBancaire>();

  constructor(private fb          : FormBuilder
            , private apiEntite   : ApiEntiteService
            , private toast       : PersoSnackbarService
            , private contexte    : ContexteService) { 
    
    // Initialisation du formulaire des filtres de recherche
    this.FiltreFormGroup = this.fb.group({
      etats          : [Array<EtatCoordonneesBancaire>(), [Validators.required]],
      numero_client  : ''
    });
  }

  ngOnInit(): void {
    this.getEtatCoordonneesBancaire();
    const liste = this.contexte.getTableData(this.typeContexte)
    if (liste.length>0) {
      this.research.emit({
        entites: liste
      });
    }
  }

  initFilter(){
    const filtre = this.contexte.getFiltre(this.typeContexte)
    if (filtre!=null) {
      this.FiltreFormGroup.patchValue(filtre)
    }
  }

  setEntite(entite:Entite|null){
    this.searching = false
    if (entite) {
      this.entiteSelected = entite
    }
  }

  searchingEntite(){
    this.searching = true
  }

  cancelEntite(){
    this.entiteSelected = null
  }

  
  public getEtatCoordonneesBancaire(): void {
    this.apiEntite.getEtatCoordonneesBancaire()
      .subscribe(
        (data : Array<EtatCoordonneesBancaire>) => {
          this.etats$ = data;          
          this.FiltreFormGroup.patchValue({
            etats: this.etats$
          });
          this.initFilter()
        }
      );
  }

  public onSearchCoordonneesBancaire(): void {
    this.filtre_recherche               = <FiltreRechercheCoordonneesBancaire>{};
    this.filtre_recherche.numero_client = this.FiltreFormGroup.controls.numero_client.value;
    this.filtre_recherche.entite        = this.entiteSelected? this.entiteSelected : <Entite>{};
    this.filtre_recherche.etats         = this.FiltreFormGroup.controls.etats.value;
    
    this.waitingResearch = true;

    this.apiEntite.postCoordonneesBancaire(this.filtre_recherche)
      .subscribe(
        (entites: Array<Entite>) => {
          this.research.emit({
            entites: entites
          });

          this.expandPannel = false;
          this.toast.showInfo("Recherche terminée");
          this.waitingResearch = false;
        },
        (err: any) => {
          this.research.emit({
            entites: Array<Entite>()
          });

          '/!\\ error postCoordonneesBancaire: ' + console.log(err);
          this.toast.showError(err.error);
          this.waitingResearch = false;
        }
      );
  }

  ngOnDestroy(){
    this.contexte.setType(this.typeContexte)
    this.contexte.setFiltre(this.FiltreFormGroup.value) 
  }
}
