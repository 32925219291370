<mat-card appearance="outlined" class="mat-card-no-shadow"  style="padding: 0px;">
    <form [formGroup]="FiltreFormGroup">
        <mat-expansion-panel id="expandFilter" [(expanded)]="expandPannel" #expandFilter> 

            <mat-expansion-panel-header style="background-color: #fff; padding-top: 3px; padding-bottom: 3px;">
                <mat-panel-title class="label-accent">
                    <mat-icon>search</mat-icon>Veuillez sélectionner un état et les éventuelles options de votre recherche...
                </mat-panel-title>
            </mat-expansion-panel-header>                                

            <mat-form-field>
                <mat-label>État</mat-label>
                <mat-select name="etats" id="etats" formControlName="etats" multiple>
                    <mat-option *ngFor="let etat of etats$" [value]="etat">
                        {{ etat.libelle }}
                    </mat-option>
                </mat-select>
            </mat-form-field> 

            <button mat-raised-button (click)="searchingEntite()" color="primary" style="margin: 10px;">Rechercher une entite</button>
            <app-entite-search *ngIf="searching" (entiteEvent)="setEntite($event)"></app-entite-search>
            <div *ngIf="entiteSelected" class="entite">
                <div style="margin-right: 15px;">
                    {{entiteSelected.raison_sociale}}
                </div>
                <button mat-mini-fab (click)="cancelEntite()"  matTooltip="Supprimer l'entité de la recherche"><mat-icon>close</mat-icon></button>
            </div>

            <mat-action-row>    
                <div class="div-container-between" style="padding: 8px;">
                    <div class="div-container-center" style="width:100%; display: flex; justify-content: center;">
                        <button 
                            mat-flat-button 
                            submit 
                            *ngIf       = "!waitingResearch"
                            color       = "primary" 
                            [disabled]  = "waitingResearch || !FiltreFormGroup.valid" 
                            (click)     = "onSearchCoordonneesBancaire()">
                            <mat-icon>search</mat-icon>Rechercher                                    
                        </button>
                        <mat-spinner diameter="24" *ngIf="waitingResearch"></mat-spinner>
                    </div>
                </div>
            </mat-action-row>
        </mat-expansion-panel>                
    </form>
</mat-card>