
<h1 mat-dialog-title>Veuillez saisir un nom de fichier</h1>

<mat-dialog-content>
  <div>
    <mat-form-field  style="padding-right: 0px; padding-left: 0px; width: 100%;">
      <mat-label>Fichier d'export</mat-label>
      <input matInput [(ngModel)]="data.fileExportName">
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button mat-raised-button  color="warn" (click)="onNoClick()"><mat-icon>close</mat-icon>
    <span>Annuler</span></button>
  <button mat-raised-button  color="primary" class="button-width" [mat-dialog-close]="data.fileExportName" cdkFocusInitial><mat-icon>done</mat-icon>
    <span>Valider</span></button>
</mat-dialog-actions>
