import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { ApiFactureService } from 'src/app/services/api-facture.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { DialogData } from '../../templates/oui-non-annuler/oui-non-annuler.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-dialog-refacturation',
    templateUrl: './dialog-refacturation.component.html',
    styleUrls: ['./dialog-refacturation.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, MatExpansionModule, MatButtonModule, MatDialogModule, MatIconModule, NgIf, MatProgressSpinnerModule]
})
export class DialogRefacturationComponent implements OnInit {

  waitingCopy     : boolean = false;
  entites$        : Entite[] = [];
  idFacture       : number = 0;
  idUtilisateur   : number = 0;
  idEntite        : number = 0;
  FiltreFormGroup : any;
  
  get entite() { return this.FiltreFormGroup.get('entite'); }

  constructor(public dialogRef: MatDialogRef<DialogRefacturationComponent>
            , @Inject(MAT_DIALOG_DATA) public data: DialogData
            , private apiFacture: ApiFactureService
            , private toast: PersoSnackbarService
            , private _fb: FormBuilder
            , private apiEntite: ApiEntiteService
            , private apiAuth: ApiAuthentificationService) { }

  ngOnInit(): void {
    this.idFacture        = this.dialogRef._containerInstance._config.data.idFacture;
    this.idUtilisateur    = this.dialogRef._containerInstance._config.data.idUtilisateur;
    this.idEntite         = this.dialogRef._containerInstance._config.data.idEntite;
    
    this.chargeListeCabinet();
    
    this.FiltreFormGroup  = this._fb.group({
      entite: ["", [Validators.required]]    
    });    
  }

  public onSelectEntite(newEntite: any) {
    this.idEntite = newEntite.value;
  }

  public refacturer() {
    this.waitingCopy = true;

    if(this.idFacture > 0) {
      this.apiFacture.putRefacturation(this.idFacture, this.idEntite)
      .subscribe(
        (data : any) => {
          this.toast.showInfo("Facture créée");  
          this.waitingCopy = false;
        },
        (err: any) => {
          console.error('/!\ err: ' + err.message); 
          this.waitingCopy = false;
        }
      );
    } else {
        this.toast.showInfo("Une erreur est survenue pendant la refacturation");
        this.waitingCopy = false;
    }  
  }

  public chargeListeCabinet() {
    for(let i = 0; i < this.apiAuth.relUserEnt.length; i++) {
      if(this.apiAuth.relUserEnt[i].est_active) {
        this.entites$.push(this.apiAuth.relUserEnt[i].entite);
      }            
    }
  }  

  filterEntitesOfType(type: boolean){
    return this.entites$.filter(entite => entite.EstActif == type && entite.EstInscrit == true);
  }
}
