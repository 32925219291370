    <mat-card appearance="outlined" class="mat-card-no-shadow" style="padding: 0px;">
            <mat-expansion-panel [(expanded)]="expandPannel" style="background-color: #fff">

                <mat-expansion-panel-header style="background-color: #fff">
                    <mat-panel-title>
                        <mat-icon color="primary">analytics</mat-icon>                                                                   
                    </mat-panel-title>
                    <mat-panel-description>{{listCodePostaux}}</mat-panel-description>
                </mat-expansion-panel-header>
            <mat-card-subtitle>
                <span style="width: 220px;">L'évaluation de ces éléments se base sur un total de <a class="label-primary">{{ dossier }}</a> dossiers répartis sur <a class="label-primary">{{ ELEMENT_DATA.nb_reparateur }}</a> réparateur(s).</span>
            </mat-card-subtitle>

            <mat-card-content class="container">
                <table mat-table matTableResponsiveLarge matTableExporter [dataSource]="dataSource" #exporter="matTableExporter" class="table-primary">
                    <!-- Type Column -->
                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef class="premiere-colonne"> Type </th>
                        <td mat-cell *matCellDef="let mcel">{{ mcel.type }}</td>
                    </ng-container>
                
                    <!-- Minimum Column-->
                    <ng-container matColumnDef="min">
                        <th mat-header-cell *matHeaderCellDef class="total" style="padding-right: 8px; padding-left: 8px;"> Min. </th>
                        <td mat-cell *matCellDef="let mcel" class="total">{{ mcel.min }} €</td>
                    </ng-container>

                    <!-- Moyenne Column-->
                    <ng-container matColumnDef="moy">
                        <th mat-header-cell *matHeaderCellDef class="total" style="padding-right: 8px; padding-left: 8px;"> Moy. </th>
                        <td mat-cell *matCellDef="let mcel" class="total">{{ mcel.moy }} €</td>
                    </ng-container>
                
                    <!-- Maximum Column-->
                    <ng-container matColumnDef="max">
                        <th mat-header-cell *matHeaderCellDef class="total derniere-colonne" style="padding-right: 8px; padding-left: 8px;"> Max. </th>
                        <td mat-cell *matCellDef="let mcel" class="total">{{ mcel.max }} €</td>
                    </ng-container>
                                                    
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </mat-card-content>
            <app-export-table [fileExportName]="'Observatoire'" [exporter]="exporter" [nb_ligne]="this.dataSource.data.length"></app-export-table>
        </mat-expansion-panel>
    </mat-card>