import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

@Injectable({ providedIn: 'root' })
export class ReferenceValidator {
    
    verificationFormatReference(): ValidatorFn {
        return (control:AbstractControl):ValidationErrors|null =>{
          let reference : string = control.value;
          let a_regex   : any = /^[0-9A-Z]{7,24}$/;
          
          if (!a_regex.test(reference)) {      
            return { erreur: 'Référence de dossier invalide (minimum 7 caractères).' }
          }
      
          return null;
        }
      }


  }