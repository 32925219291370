<app-filter-email
    [hidden]        = "modeEdit"
    [operations$]   = "operations$" 
    (filterEvent)   = "applyFilter($event)"
    [waiting]       = "waitingSearch"
    (createMail)    = "createMail()"
    >
</app-filter-email>

<app-list-email     
    [hidden]            = "modeEdit"
    [emailsDisplayed$]  = "emails$"
    (contenuSelected)   = "editContenu($event)"
    >
    
</app-list-email>

<app-edit-email 
    *ngIf               = "modeEdit" 
    [mail]              = "emailSelected"
    [operationsLeft]    = "operationsLeft"
    (mailEvent)         = "saveContenu($event)"
    [waiting]           = "waitingSave"
    [balises$]          = "balises$"
    >
</app-edit-email>
