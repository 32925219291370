<mat-card appearance="outlined" >
    <form [formGroup]="formMail">
        
        <mat-form-field>
            <mat-label>Opérations</mat-label>
            <mat-select formControlName="operations" multiple>
                <mat-option *ngFor="let operation of operationsLeft" [value]="operation" >
                    {{ operation.nom }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="!formMail?.valid && formMail.touched">Vous devez sélectionner au moins une opération</mat-error>
        </mat-form-field> 

        <mat-form-field>
            <mat-label>Note</mat-label>
            <input matInput type="text" placeholder="Descriptif/Context du mail" formControlName="note" >
        </mat-form-field>

        <mat-form-field>
            <mat-label>Sujet</mat-label>
            <input matInput type="text" placeholder="Sujet du mail" formControlName="sujet" >
        </mat-form-field>

        <app-list-balise 
            [balises$]      = "balises$" 
            (baliseEvent)   = "getBalise($event)"
            >
        </app-list-balise>
        
        <ckeditor
                    #editor
                    [editor]="Editor"
                    formControlName = "contenu" 
                     >
        </ckeditor>

    </form>

    <mat-card-actions align="end" >
        <div *ngIf="!waiting">
            <button mat-raised-button color = "primary" (click)   = "save()">Enregistrer</button>
            <button mat-raised-button color = "primary" (click)   = "cancel()">Annuler</button>
        </div>
        <mat-spinner *ngIf="waiting" diameter="24"></mat-spinner>
    </mat-card-actions>

</mat-card>