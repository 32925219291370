import { ModePaiement } from "../facture/mode-paiement";

export interface TypeEntite {
      id          : number;
      libelle     : string;
      code        : string;
      env_id      : number;
      defaut     : number|boolean;
      modePaiement?:ModePaiement
}

export const InitTypeEntite : TypeEntite={
      'code':''
      ,'env_id':0
      ,'id':0
      ,'libelle':''
      ,'defaut':0
}
