<!-- Informations générales de l'entité -->
<mat-card appearance="outlined">
    <mat-card-title>Informations générales</mat-card-title>    
    <mat-card-content class=".mat-card-content">
        <div class="div-container">
            <app-carte-entite [entite]="entite" tvaIntracommunautaire=""></app-carte-entite>
            <app-carte-adresse [adresse]="adresseEntite" [title]="'Contact: ' + entite.numero_client"  ></app-carte-adresse>
        </div>
    </mat-card-content>
</mat-card>

<h3 style="text-align: center;">{{ titre_page }}</h3>

<form [formGroup]="CoordonneesBancaireFormGroup">
    <!-- Informations des coordonnées bancaires -->
    <mat-card appearance="outlined">
        <mat-card-title>Coordonnées bancaires</mat-card-title>
        <mat-card-subtitle>Veuillez vérifier l'exactitude des informations ci-dessous</mat-card-subtitle>
        <mat-card-content class="mat-card-content">
            <mat-grid-list cols="12" rowHeight="50">
                <mat-grid-tile colspan="4">
                    <mat-form-field>
                        <mat-label>IBAN</mat-label>
                        <input matInput type="text" id="bnc_iban" formControlName="bnc_iban" required readonly="true">
                        <mat-error *ngIf="bnc_iban?.invalid && (bnc_iban?.dirty || bnc_iban?.touched)">{{ bnc_iban?.errors?.erreur }}</mat-error>
                    </mat-form-field>
                    <button 
                        mat-icon-button  
                        color       = "primary" 
                        matTooltip="Ouvir un aperçu du fichier IBAN"
                        (click)     = "onClickApercuPDF('iban')" 
                        [disabled]  = "this.CoordonneesBancaireFormGroup.controls.bnc_nom_fichier_iban.value === ''"
                        >
                        <mat-icon>preview</mat-icon>
                    </button>
                </mat-grid-tile>
                <mat-grid-tile colspan="3">
                    <mat-form-field>
                        <mat-label>BIC</mat-label>
                        <input matInput type="text" id="bnc_bic" formControlName="bnc_bic" required readonly="true">
                        <mat-error *ngIf="bnc_bic?.invalid && (bnc_bic?.dirty || bnc_bic?.touched)">{{ bnc_bic?.errors?.erreur }}</mat-error>
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="5">
                    <mat-form-field>
                        <mat-label>Pièce jointe</mat-label>
                        <input matInput type="text" id="bnc_nom_fichier_iban" formControlName="bnc_nom_fichier_iban" required readonly="true">                
                        <mat-error *ngIf="bnc_nom_fichier_iban?.invalid && (bnc_nom_fichier_iban?.dirty || bnc_nom_fichier_iban?.touched)">{{ bnc_nom_fichier_iban?.errors?.erreur }}</mat-error>
                    </mat-form-field>   
                </mat-grid-tile>
            </mat-grid-list>

           
        </mat-card-content>
    </mat-card>

    <!-- Informations du mandat de prélèvement -->
    <mat-card appearance="outlined">
        <mat-card-title> Mandat de prélèvement</mat-card-title>
        <mat-card-subtitle>Veuillez vérifier l'exactitude des informations ci-dessous</mat-card-subtitle>
        <mat-card-content class="mat-card-content">
            <mat-grid-list cols="12" rowHeight="85px">
                <mat-grid-tile colspan="5">
                    
                    <mat-form-field>
                        <mat-label>RUM</mat-label>
                        <input matInput type="text" id="bnc_rum" formControlName="bnc_rum" readonly="true">
                        <mat-error *ngIf="bnc_rum?.invalid && (bnc_rum?.dirty || bnc_rum?.touched)">{{ bnc_rum?.errors?.erreur }}</mat-error>
                    </mat-form-field>
                    <button 
                            mat-icon-button 
                            matTooltip = "Ouvir un aperçu du fichier RUM"
                            color       = "primary" 
                            (click)     = "onClickApercuPDF('rum')" 
                            [disabled]  = "this.CoordonneesBancaireFormGroup.controls.bnc_nom_fichier_rum.value === ''"
                            >
                        <mat-icon>preview</mat-icon>
                    
                    </button>
                </mat-grid-tile>
                <mat-grid-tile colspan="2">

                    <mat-form-field>
                        <mat-label>Date du mandat</mat-label>
                        <input matInput type="text" id="bnc_date_mandat" formControlName="bnc_date_mandat" readonly="true">
                        <mat-error *ngIf="bnc_date_mandat?.invalid && (bnc_date_mandat?.dirty || bnc_date_mandat?.touched)">{{ bnc_date_mandat?.errors?.erreur }}</mat-error>
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="5">

                    <mat-form-field>
                        <mat-label>Pièce jointe</mat-label>
                        <input matInput type="text" id="bnc_nom_fichier_rum" formControlName="bnc_nom_fichier_rum" readonly="true">                
                        <mat-error *ngIf="bnc_nom_fichier_rum?.invalid && (bnc_nom_fichier_rum?.dirty || bnc_nom_fichier_rum?.touched)">{{ bnc_nom_fichier_rum?.errors?.erreur }}</mat-error>
                    </mat-form-field>   
                </mat-grid-tile>
            </mat-grid-list>
           

            

            <mat-form-field style="max-width:100%;" class="textarea">
                <mat-label>Commentaire</mat-label>
                <textarea matInput id="bnc_commentaire" formControlName="bnc_commentaire" oninput="this.value = this.value.toUpperCase()"></textarea>                
            </mat-form-field>

        </mat-card-content>
        

        <mat-card-actions align="end">
                <button *ngIf="this.bEtatAValider && waitingValidation===false" 
                        mat-flat-button 
                        color   = "warn" 
                        class   = "button-width" 
                        (click) = "onClickRefuser()">
                    <mat-icon>close</mat-icon>
                    <span>Refuser</span>
                </button>

                <button *ngIf="this.bEtatAValider && waitingValidation===false" 
                        mat-flat-button 
                        color       = "primary" 
                        class       = "button-width" 
                        (click)     = "onClickValider()" 
                        [disabled]  = "!CoordonneesBancaireFormGroup.valid">
                    <mat-icon>done</mat-icon>
                    <span>Valider</span>
                </button>

                <mat-spinner *ngIf="waitingValidation===true" diameter="24"></mat-spinner>
        </mat-card-actions>    
    </mat-card>
</form>