import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Suspension, SuspensionFiltre, SuspensionRappel, TypeSuspension } from '../interfaces/suspension';
import { catchError } from 'rxjs/operators';
import { ApiErrorService } from 'src/app/services/support/api-error.service';

@Injectable({
  providedIn: 'root'
})
export class SuspensionService {

  url: string       = '/api/suspension';
  // suspension$  = this.getTypesSuspension()

  constructor(private http:HttpClient
            , private apiError    : ApiErrorService) { }

  // 1 -  SUSPENSION

  //1.1 - Select
  public getSuspensions(filtre:SuspensionFiltre| null):Observable<Suspension[]>{
    
    return this.http.post<Suspension[]>(this.url+'/suspensions',filtre)
                    .pipe(catchError(this.apiError.handleError<Suspension[]>('getSuspensions',[])));
  }

  public getSuspension(idSuspension :number):Observable<Suspension | boolean> {
    return this.http.get<Suspension>(this.url+'/suspension/'+idSuspension)
                    .pipe(catchError(this.apiError.handleError<boolean>('getSuspension',false)));
  }             

  public getSuspensionFromEntite(idEntite:number):Observable<Suspension>{
    return this.http.get<Suspension>(this.url+'/entite/'+idEntite)
                    
  }

  //1.2 - Update
  public putSuspension(suspension:Suspension):Observable<Suspension | boolean>{
    return this.http.put<Suspension>(this.url+'/suspension', suspension)
                    .pipe(catchError(this.apiError.handleError<boolean>('putSuspension',false)));
  }

  //1.3 - Insert
  public postSuspension(suspension:Suspension):Observable<Suspension | boolean>{
    return this.http.post<Suspension>(this.url+'/suspension', suspension)
                    .pipe(catchError(this.apiError.handleError<boolean>('postSuspension',false)));
  }

  //1.4 - Delete
  public deleteSuspension(idSuspension:number): Observable<boolean> {    
    return this.http.delete<boolean>(this.url+idSuspension)
                    .pipe(catchError(this.apiError.handleError<boolean>('deleteSuspension',false)));
                    
  }

  //1.5 - Rappel
  public postRappel(rappel:SuspensionRappel):Observable<Suspension | boolean>{
    return this.http.post<Suspension>(this.url+'/rappel', rappel)
  }

  //1.6 Clôture de la suspension
  public clotureSuspension(id:number):Observable<Suspension | boolean>{
    return this.http.get<Suspension>(this.url+'/cloture/'+id)
                    // .pipe(catchError(this.apiError.handleError<boolean>('clotureSuspension',false)));
  }

  //1.7 Clôture du cabinet
  public clotureCabinet(id:number):Observable<Suspension | boolean>{
    return this.http.get<Suspension>(this.url+'/definitive/'+id)
                    // .pipe(catchError(this.apiError.handleError<boolean>('clotureCabinet',false)));
  }

  public telechargerRapport(){
    return this.http.get( this.url+'/rapport' , { 
      responseType: "blob"
      , headers: {
        'Content-Type': 'application/json'
        , Accept: 'application/octet-stream,application/pdf,image/png,image/jpeg,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'} 
      })
  }
}
