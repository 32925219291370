import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { FiltreStatistiques } from 'src/app/interfaces/statistiques/filtre-statistiques';
import { StatistiquesEntite } from 'src/app/interfaces/statistiques/statistiques-entite';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { ApiStatistiqueService } from 'src/app/services/api-statistique.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { EntiteStatistiquesComponent } from '../entite-statistiques/entite-statistiques.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-mes-statistiques',
    templateUrl: './mes-statistiques.component.html',
    styleUrls: ['./mes-statistiques.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatExpansionModule, MatIconModule, MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, NgIf, MatInputModule, MatButtonModule, MatProgressSpinnerModule, EntiteStatistiquesComponent]
})
export class MesStatistiquesComponent implements OnInit {

  // Gestion de l'affichage
  waitingResearch       : boolean = false;
  expandPannel          : boolean = true;

  // Liste des interfaces pour les combos
  profilUtilisateur  : ProfilUtilisateur = <ProfilUtilisateur>{};
  entites            : Entite[] = [];
  
  // Formulaire de filtrage
  filtre: FiltreStatistiques = <FiltreStatistiques>{};
  FiltreFormGroup: FormGroup;

  // Tableau de statistiques entités
  stats_entite: Array<StatistiquesEntite> = [];
  
  get habilitation(){ return this.FiltreFormGroup.get('habilitation'); }
  get date_debut()  { return this.FiltreFormGroup.get('date_debut'); }
  get date_fin()    { return this.FiltreFormGroup.get('date_fin'); }

  constructor(private _fb   : FormBuilder
            , private authUser      : ApiAuthentificationService
            , private apiStatistique: ApiStatistiqueService
            , private apiEntite     : ApiEntiteService
            , private toast         : PersoSnackbarService) {
    // Initialisation du formulaire de filtrage
    this.FiltreFormGroup = this._fb.group({
      date_debut  : '',
      date_fin    : '',
      habilitation: [Array<string>(), [Validators.required]],   
    }, { validator: this.verificationFormGroup.bind(this) });
  }

  ngOnInit(): void {
    this.profilUtilisateur = this.authUser.userConnect;

    // Récupération des paramètres du formulaire de filtrage
    this.chargeListeCabinet();    

    this.FiltreFormGroup.patchValue({
      date_debut      : '',
      date_fin        : '',
      habilitation    : []
    });
  }

  verificationFormGroup(fgFormGroup: FormGroup): any {
    let dateDebut : Date = new Date(fgFormGroup.controls.date_debut.value);
    let dateFin   : Date = new Date(fgFormGroup.controls.date_fin.value);
    let dateToday : Date = new Date();  

    dateDebut.setHours(0,0,0,0);
    dateToday.setHours(23,59,59,999); 
    dateFin.setHours(23,59,59,999);

    //fgFormGroup.controls.date_debut.setErrors({erreur: ''}); 
    if(dateDebut.getFullYear() != dateFin.getFullYear()) {
      return {erreur : 'Les dates de début et fin doivent être de la même année'}
    }

    if(dateDebut.getTime() > dateToday.getTime()){
      return { erreur: 'Date de début doit être antérieure à la date du jour' }
    }

    if(dateDebut > dateFin) { 
      return { erreur: 'Date de début doit être antérieure à la date de fin' }
    }

    if(dateFin.getTime() > dateToday.getTime() || (dateDebut.getTime() != null && dateFin.getTime() < dateDebut.getTime())){
      return { erreur: 'Date de fin doit être antérieure à la date du jour et postérieure à la date de début' }
    }

    return null;
  }

  public onSearchingStatistiques() {
    this.waitingResearch = true;

    this.stats_entite = [];

    this.filtre = {
      date_debut      : this.FiltreFormGroup.controls.date_debut.value,
      date_fin        : this.FiltreFormGroup.controls.date_fin.value,
      habilitation    : this.FiltreFormGroup.controls.habilitation.value
    };
    
    this.apiStatistique.postStatistiques(this.filtre)
    .subscribe(
      (data: StatistiquesEntite[]) => {        
        this.stats_entite = data;
        this.toast.showInfo('Recherche terminée');
        this.waitingResearch = false;
        this.expandPannel = false;
      },
      (err: any) => {
        console.error('/!\ err: ', err.message);
        this.waitingResearch = false;
      }
    );
  }

  public chargeListeCabinet() {
    if ( this.profilUtilisateur.droits_utilisateur.est_admin) {
      this.apiEntite.getAllEntite()
      .subscribe(
        (data: Entite[] ) => {              
          this.entites = data;
        }
      );
    } else {
      this.apiEntite.getEntitesByIdUtilisateur(this.profilUtilisateur.id)
       .subscribe(
         (data: Entite[])  => { 
          this.entites = data;
        }
      );  
    }
  }
}
