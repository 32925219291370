<ng-container *ngIf="isMobileFormat(); then loginInLine else loginInColumn"></ng-container>

<ng-template #loginInLine>
    <mat-card appearance="outlined" style="margin-top: 100px;">
        <mat-card-title>Mot de passe oublié ?</mat-card-title>
        <mat-card-subtitle>Saisissez l'adresse e-mail associée à votre compte.
            Vous recevrez un e-mail contenant un lien qui vous permettra de créer un nouveau mot de passe
        </mat-card-subtitle>
        
        <form [formGroup]="resetForm" (ngSubmit)="askResetPassword()">              

            <mat-form-field>
                <mat-label>Adresse e-mail</mat-label>
                <input matInput type="text" formControlName="adresseEmail">
            </mat-form-field>

            <div class="button div-container" style="padding-top: 16px;">
                <button mat-raised-button color="primary" [disabled]="!resetForm.valid">Recevoir un e-mail</button>
            </div>
            
        </form>
    </mat-card>
</ng-template>

<ng-template #loginInColumn class="host">
    <mat-card appearance="outlined" style="margin-top: 100px;">
        <mat-card-title>Mot de passe oublié ?</mat-card-title>
        
        <mat-card-subtitle>Saisissez l'adresse e-mail associée à votre compte.
            Vous recevrez un e-mail contenant un lien qui vous permettra de créer un nouveau mot de passe</mat-card-subtitle>

        <mat-card-content class=".mat-card-content">        
            <form [formGroup]="resetForm" (ngSubmit)="askResetPassword()">              

                <mat-form-field>
                    <mat-label>Adresse e-mail</mat-label>
                    <input matInput type="text" formControlName="adresseEmail">
                </mat-form-field>

                <div class="button div-container" style="padding-top: 16px;">
                    <button mat-raised-button style="width: 100%;" color="primary" [disabled]="!resetForm.valid">Recevoir un e-mail</button>
                </div>                
            </form>
        </mat-card-content>
    </mat-card>
</ng-template>