<h2 mat-dialog-title class="dialog-title">Sélectionner une Analyse</h2>

<mat-dialog-content>
  <div class="table-container">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 custom-table">
      
      <!-- Analyse Column -->
      <ng-container matColumnDef="categorie">
        <th mat-header-cell *matHeaderCellDef class="custom-header-cell"> Analyse </th>
        <td mat-cell *matCellDef="let analyseRetenue" class="custom-cell"> {{ analyseRetenue.categorie }} </td>
      </ng-container>
  
      <!-- Détail Column -->
      <ng-container matColumnDef="designation">
        <th mat-header-cell *matHeaderCellDef class="custom-header-cell"> Détail </th>
        <td mat-cell *matCellDef="let analyseRetenue" class="custom-cell custom-cell-detail"> 
          {{ analyseRetenue.designation }} 
        </td>
      </ng-container>
  
      <!-- Type analyse Column -->
      <ng-container matColumnDef="typeAnalyse">
        <th mat-header-cell *matHeaderCellDef class="custom-header-cell custom-header-type-analyse"> 
          Type d'analyse 
        </th>
        <td mat-cell *matCellDef="let analyseRetenue" class="custom-cell custom-cell-type-analyse"> 
          {{ data.getTypeAnalyse(analyseRetenue.idTypeAnalyse) }} 
        </td>
      </ng-container>
  
      <!-- Tarif Column -->
      <ng-container matColumnDef="tarif">
        <th mat-header-cell *matHeaderCellDef class="custom-header-cell custom-header-tarif"> Tarif </th>
        <td mat-cell *matCellDef="let analyseRetenue" class="custom-cell custom-cell-tarif"> 
          {{ analyseRetenue.tarif }} € 
        </td>
      </ng-container>
  
      <!-- Other columns (no changes needed) -->
      <ng-container matColumnDef="fluide">
        <th mat-header-cell *matHeaderCellDef class="custom-header-cell"> Type de fluide </th>
        <td mat-cell *matCellDef="let analyseRetenue" class="custom-cell"> 
          {{ data.getTypeFluide(analyseRetenue.idFluide) }} 
        </td>
      </ng-container>
  
      <ng-container matColumnDef="Organe">
        <th mat-header-cell *matHeaderCellDef class="custom-header-cell"> Organe de prélèvement </th>
        <td mat-cell *matCellDef="let analyseRetenue" class="custom-cell"> 
          {{ data.getOrganePrelevement(analyseRetenue.idOrgane) }} 
        </td>
      </ng-container>
  
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef class="custom-header-cell">Sélection</th>
        <td mat-cell *matCellDef="let element" class="custom-cell">
          <button 
            mat-icon-button 
            color="primary" 
            (click)="onSelect(element)" 
            class="custom-select-button"
            matTooltip="Cliquer pour retenir cette analyse"
            >
            <mat-icon>done</mat-icon>
          </button>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  
</mat-dialog-content>

<mat-dialog-actions class="dialog-actions">
  <button mat-button (click)="onCancel()">Annuler</button>
</mat-dialog-actions>
