import { Component, Input, OnInit } from '@angular/core';
import { StatsRepartitionMsg } from 'src/app/interfaces/statistiques/stats-repartition-msg';
import { PieChartModule } from '@swimlane/ngx-charts';

@Component({
    selector: 'app-repartion-msg-concentrateur',
    templateUrl: './repartion-msg-concentrateur.component.html',
    styleUrls: ['./repartion-msg-concentrateur.component.scss'],
    standalone: true,
    imports: [PieChartModule]
})
export class RepartionMsgConcentrateurComponent implements OnInit {

   // options
   gradient: boolean = true;
   showLegend: boolean = true;
   showLabels: boolean = true;
   isDoughnut: boolean = false;
   colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  public chartData: {
    name: string,
    value ? : string | number
  } [];    
 
  // Données à afficher
  @Input() ELEMENT_DATA: StatsRepartitionMsg = <StatsRepartitionMsg>{};

  constructor() {
    this.chartData = [
      {
        name: '1A OUI',
        value: 0
      },
      {
        name: '1A NON',
        value: 0
      },
      {
        name: '1B OUI',
        value: 0
      },
      {
        name: '1B NON',
        value: 0
      },
      {
        name: '1C    ',
        value: 0
      }
  ];
   }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.chartData = [
      {
        name: '1A OUI',
        value: this.ELEMENT_DATA.total_1a_oui
      },
      {
        name: '1A NON',
        value: this.ELEMENT_DATA.total_1a_non
      },
      {
        name: '1B OUI',
        value: this.ELEMENT_DATA.total_1b_oui
      },
      {
        name: '1B NON',
        value: this.ELEMENT_DATA.total_1b_non
      },
      {
        name: '1C',
        value: this.ELEMENT_DATA.total_1c
      }
    ];
  }
}
