import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable,of } from 'rxjs';
import { tap,catchError } from 'rxjs/operators';
import { Modele } from 'src/app/interfaces/support/modele';
import { ApiAuthentificationService } from '../api-authentification.service';
import { PersoSnackbarService } from '../perso-snackbar.service';
import { ApiErrorService } from './api-error.service';

@Injectable({
  providedIn: 'root'
})
export class ApiModeleService {
  url: string = '/api';

  constructor(private http: HttpClient
    , private apiAuth : ApiAuthentificationService
    , private toast       : PersoSnackbarService
    , private apiError    : ApiErrorService) { }

  //3.1 - Select
  public getModeles():Observable<Modele[]>{
    return this.http.get<Modele[]>(this.url+"/support/modele")
                .pipe(catchError(this.apiError.handleError<Modele[]>('getModeles',[])))
  }

   //3.2 - Update
   public putModele(modele: Modele): Observable<Modele> {    
    return this.http.put<Modele>(this.url+"/support/modele", modele)
                    .pipe(catchError(this.apiError.handleError<Modele>('putModele')));
  }

   //3.3 - Insert
   public postModele(modele: Modele): Observable<Modele> {    
    return this.http.post<Modele>(this.url+"/support/modele", modele)
                    .pipe(catchError(this.apiError.handleError<Modele>('postModele')));
  }

  //3.4 - Delete
  public deleteModele(idModele:number) {    
    return this.http.delete(this.url+"/support/modele/"+idModele)
                    .pipe(catchError(this.apiError.handleError<boolean>('deleteModele',false)));
  }

 
}
