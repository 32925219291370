import { Component } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { GpeUtlDrt, Groupe, GroupeInit, GroupeLink, TypeGroupes } from '../groupe-interface';
import { FormArray, FormControl, FormGroup, FormsModule, NgModel, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { Environnement } from 'src/app/interfaces/utilitaire/environnement';
import { InitTypeEntite, TypeEntite } from 'src/app/interfaces/profil-user/type-entite';
import { forkJoin, throwError } from 'rxjs';
import { GroupeService } from '../data-access/groupe.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { MatDialog } from '@angular/material/dialog';
import { ApiUtilitaireService } from 'src/app/services/tools/api-utilitaire.service';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { NgFor, NgIf } from '@angular/common';
import { OuiNonAnnulerComponent } from '../../templates/oui-non-annuler/oui-non-annuler.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { catchError, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-groupe-edit',
  templateUrl: './groupe-edit.component.html',
  styleUrls: ['./groupe-edit.component.scss'],
  standalone:true,
  imports:[MatFormFieldModule,MatSelectModule,MatInputModule,MatOptionModule, MatButtonModule
          , MatIconModule, MatTooltipModule, MatInputModule, ReactiveFormsModule, FormsModule
          , MatCardModule,NgIf,NgFor,MatProgressSpinnerModule,MatTableModule,MatCheckboxModule
          ]
})
export class GroupeEditComponent {

  selectedGroup   : Groupe                = GroupeInit
  selectedType    : TypeEntite            = InitTypeEntite
  groupeInit       = GroupeInit
  groupes         : Groupe[]              = []
  waiting         : boolean               = false
  edition         : boolean               = false
  environnements  : Environnement[]       = []
  typesEntite     : TypeEntite[]          = []
  idEnv           : number                = 0
  
  waitingTypes    : boolean               = false
  groupLinks!     : FormArray
  displayedColumns: string[] = ['groupe', 'type', 'defaut'];
  end             : boolean               = false

  constructor(private apiGroupe:GroupeService
    , private apiToast  : PersoSnackbarService
    , private apiEntite : ApiEntiteService) { }
    
  ngOnInit(): void {
    forkJoin([
              this.apiGroupe.getGroupes("", true,true)
              , this.apiEntite.getAllTypeEntite()
            ]
              ).subscribe(([groupes,types])=>{
                this.groupes        = groupes
                this.typesEntite    = types
                this.end = true
              })
  }
  selectType(){
    this.initGroupLinks()
  }

  initGroupLinks(){
   
    this.groupLinks = new FormArray(
      this.groupes.map(
        (groupe:Groupe)=>new FormGroup(
          {
              belongToType: new FormControl<boolean>(this.setBelongInitValue(groupe))
            , id          : new FormControl<number>(groupe.id)
            , default     : new FormControl<boolean>({value:this.setDefaultInitValue(groupe),disabled:!this.setBelongInitValue(groupe)})
          }
        )
      )
    )
  }

  setBelongInitValue(groupeEnCours:Groupe){
   const groupe = this.groupes.find((groupe:Groupe)=>groupe.id===groupeEnCours.id)
    if (!groupe) {
      return false
    }
    return groupe.typesEntite.find((type:TypeEntite)=>type.id===this.selectedType.id)? true:false
  }

  setDefaultInitValue(groupeEnCours:Groupe){
    const groupe = this.groupes.find((groupe:Groupe)=>groupe.id===groupeEnCours.id)
    if (!groupe) {
      return false
    }
    return groupe.typesEntite.find((type:TypeEntite)=>type.id===this.selectedType.id && type.defaut===1)? true:false
  }

  setBelongValue(index:number,checked:boolean){
    if (checked) {
      (this.groupLinks.at(index) as FormGroup).get('default')?.enable()
    }else{
      (this.groupLinks.at(index) as FormGroup).get('default')?.setValue(false);
      (this.groupLinks.at(index) as FormGroup).get('default')?.disable()
    }
    
  }

  getControl(index: number, controlName: string): FormControl {
    return (this.groupLinks.at(index) as FormGroup).get(controlName) as FormControl;
  }

  enregistreLiens(){
    let message:string = ''
    const groupeLinks:GroupeLink[] = this.groupLinks.value.filter((control:any)=>control.belongToType===true).map((control:any)=>{
      return {'defaut':control.default,'idGroupe':control.id}
        }
      )
      if (groupeLinks.filter((link:GroupeLink)=> link.defaut===true).length>1) {
        message = 'Vous ne pouvez sélectionner qu\'un seul type par défaut'
      }
      if (message!='') {
        this.apiToast.showError(message)
        return;
      }
    const typeGroupes : TypeGroupes = {'idType':this.selectedType.id,'groupes':groupeLinks}
    
    this.waitingTypes = true
   
    this.apiGroupe.putTypesEntite(typeGroupes).pipe(mergeMap(
      ()=>{return this.apiGroupe.getGroupes("", true,true)})
      , catchError(error=>{return throwError(error)})
    ).subscribe(
      (groupes:Groupe[])=>{
        this.groupes      = groupes
        this.waitingTypes = false
        this.apiToast.showInfo('Les liens ont bien été enregistrés')
      }
      ,()=>this.waitingTypes = false
    )

  }
  cancel(){
    this.edition = false
  }
}
