import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { MatButtonModule } from '@angular/material/button';

export interface DialogData {
  type_pdf: any;
  id: number;
  entite: Entite;
}
@Component({
    selector: 'app-iframe-dialog',
    templateUrl: './iframe-dialog.component.html',
    styleUrls: ['./iframe-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogModule, MatButtonModule]
})
export class IframeDialogComponent implements OnInit {

  // Gestion des PDF
  pdfSrc          : any = null;
  contentType     : string = '';
  contentTypeRUM  : string =''
  contentTypeIBAN : string = ''

  constructor(public _sanitizer                      : DomSanitizer
              , public dialogRef                     : MatDialogRef<IframeDialogComponent>
              , private apiEntite                    : ApiEntiteService
              , @Inject(MAT_DIALOG_DATA) public data : DialogData) { }

  ngOnInit(): void {
    
    this.contentTypeIBAN  = this.getContentType(this.data.entite.coordonnees_bancaire.nom_fichier_iban)
    this.contentTypeRUM   = this.getContentType(this.data.entite.coordonnees_bancaire.nom_fichier_rum)

    if(this.data.type_pdf === 'iban') {
      this.onClickApercuPDFIBAN();
    } else {
      this.onClickApercuPDFRUM();
    }
  }


  public onClickApercuPDFRUM() {
    this.contentType = this.contentTypeRUM
    this.apiEntite.getDownFile('rum', this.data.id, this.data.entite.coordonnees_bancaire.id)
    .subscribe(
      (retFileData: any) => {
        if(retFileData != undefined) {
          this.readFile(retFileData);
        }
      }
    );
  }

  public onClickApercuPDFIBAN() {
    this.contentType = this.contentTypeIBAN
    this.apiEntite.getDownFile('iban', this.data.id, this.data.entite.coordonnees_bancaire.id)
    .subscribe(
      (retFileData: any) => {
        if(retFileData != undefined) {
          this.readFile(retFileData);
        }
      }
    );
  }

  public readFile(retFileData: any) {
    let blob    = new Blob([retFileData], { type: this.contentType});
    let fileURL = URL.createObjectURL(blob);
    this.pdfSrc = this._sanitizer.bypassSecurityTrustResourceUrl(fileURL);
  }

  onClickClose(): void {
    this.dialogRef.close();
  }

  getContentType(file : string){
    const extension         = file.split('.').pop()
    let contentType :string = ''
    
    switch (extension?.toUpperCase()) {
      case 'PDF':
        contentType = 'application/pdf'
        break;

      case 'JPG':
      contentType = 'image/jpeg'
      break;

      case 'PNG':
        contentType = 'image/png'
        break
        
      default:
        contentType = 'image/jpeg'
    }
    
    return contentType
  }
}
