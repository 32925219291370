<mat-card appearance="outlined" [hidden]="isDirigeant || userConnect.droits_utilisateur.est_admin">
    <form [formGroup]="collaborateursForm">    
        <mat-card-title>
            <mat-icon>account_circle</mat-icon>
            <span>Liste des utilisateurs</span>
        </mat-card-title>
        
        <mat-card-subtitle>
            Liste des utilisateurs présents dans le cabinet dont dépend la succursale.<br>
            Pour chaque utilisateur, vous pouvez activer/désactiver les droits d'accès, et changer le rôle de celui-ci.
        </mat-card-subtitle>            
        

        <mat-card-content class=".mat-card-content">            
            <div class="container">
                <table mat-table matTableResponsiveLarge [dataSource]="dataSource" formArrayName="rows" class="table-primary">        

                    <!-- Photo profil Column -->
                    <ng-container matColumnDef="photo_profil">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let relation"> 
                            <mat-icon class="img-profil grey-icon" *ngIf="!relation.utilisateur?.photo_profil?.profile_image">account_circle</mat-icon>
                            <img class="img-profil" style="margin-right: 8px; margin-left: 8px;" 
                                *ngIf="relation.utilisateur?.photo_profil?.profile_image" 
                                [src]="_sanitizer.bypassSecurityTrustResourceUrl(relation.utilisateur.photo_profil.profile_image)">
                        </td>
                    </ng-container>
                    
                    <!-- Nom Column -->
                    <ng-container matColumnDef="nom">
                        <th mat-header-cell *matHeaderCellDef> Nom </th>
                        <td mat-cell *matCellDef="let relation"> {{ relation.utilisateur.nom | uppercase }} </td>
                    </ng-container>

                    <!-- Prénom Column -->
                    <ng-container matColumnDef="prenom">
                        <th mat-header-cell *matHeaderCellDef> Prénom </th>
                        <td mat-cell *matCellDef="let relation"> {{ relation.utilisateur.prenom }} </td>
                    </ng-container>  
                    
                    <!-- Type Column -->
                    <ng-container matColumnDef="est_dirigeant">
                        <th mat-header-cell *matHeaderCellDef> Type </th>
                        <td mat-cell *matCellDef="let relation; let index = index" [formGroupName]="paginator.pageSize * paginator.pageIndex + index" class="fix-width-column">                                             
                            <mat-select name="est_dirigeant"
                                    formControlName="est_dirigeant" required>
                                    <mat-option *ngFor="let type of type_collaborateurs" [value]="type.est_dirigeant">{{ type.libelle }}</mat-option>                                    
                            </mat-select>
                        </td>
                    </ng-container>
                    
                    <!-- Relation Column -->
                    <ng-container matColumnDef="est_active">
                        <th mat-header-cell *matHeaderCellDef> Activé/Désactivé </th>
                        <td mat-cell *matCellDef="let relation; let index = index" [formGroupName]="paginator.pageSize * paginator.pageIndex + index" class="fix-width-column">                                                                            
                            <mat-slide-toggle (change)="onToggle($event, relation)" name="actif" formControlName="est_active" color="primary">
                                {{(relation.est_active ? 'Activé' : 'Désactivé' )}}                                                
                            </mat-slide-toggle>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[5,10,25]" showFirstLastButtons></mat-paginator>
            </div>
        </mat-card-content>

        <mat-card-actions style="display: flex; justify-content: end !important;">
            <button 
                mat-raised-button 
                *ngIf       = "!waiting_save"
                (click)     = "enregistrerCollaborateurs()" 
                type        = "submit" 
                color       = "primary" 
                [disabled]  = "!collaborateursForm.valid || waiting_save"
                >
                Enregistrer les collaborateurs
            </button>
            <mat-spinner diameter="24" *ngIf="waiting_save"></mat-spinner>
        </mat-card-actions>
    </form>
</mat-card>