<mat-card appearance="outlined" class="mat-card-no-shadow" style="padding: 0px;">
    <form [formGroup]="FiltreFormGroup">
        <mat-expansion-panel id="expandFilter" [expanded]="isExpanded" #expandFilter>
            
            <mat-expansion-panel-header style="background-color: #fff">
                <mat-panel-title class="label-accent">
                    <mat-icon>search</mat-icon>Veuillez sélectionner les éventuelles options de votre recherche...
                </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-form-field>
                <mat-label>Votre Référence</mat-label>
                <input matInput type="text" id="reference" formControlName="reference"
                    oninput="this.value = this.value.toUpperCase()">
                <mat-error *ngIf="reference?.invalid && (reference?.dirty || reference?.touched)">{{
                    reference.errors?.erreur }}</mat-error>
            </mat-form-field>

            <button mat-raised-button (click)="searchingEntite()" color="primary" style="margin: 10px;" *ngIf="profilUtilisateur$.droits_utilisateur.droit_module_admin_compta || profilUtilisateur$.droits_utilisateur.droit_module_admin_adela">Rechercher une entite</button>
            <app-entite-search *ngIf="searching" (entiteEvent)="setEntite($event)"></app-entite-search>
            <div *ngIf="entiteSelected" class="entite">
                <div style="margin-right: 15px;">
                    {{entiteSelected.raison_sociale}}
                </div>
                <button mat-mini-fab (click)="cancelEntite()"  matTooltip="Supprimer l'entité de la recherche"><mat-icon>close</mat-icon></button>
            </div>
            
            <mat-form-field *ngIf="!(profilUtilisateur$.droits_utilisateur.droit_module_admin_compta || profilUtilisateur$.droits_utilisateur.droit_module_admin_adela)">
                <mat-label>Cabinet</mat-label>
                <mat-select name="entite" id="entite" formControlName="idEntite">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let entite of entites" [value]="entite.id">
                        {{ entite.raison_sociale }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field style="max-width: 500px;">
                <mat-label>États</mat-label>
                <mat-select name="etats" id="etats" formControlName="etats" multiple required>                    
                        <mat-option *ngFor="let etat of etats$; let indice = index" 
                            [value]             = "etat.code">
                            {{indice + 1}}. {{ etat.libelle }} 
                        </mat-option>
                </mat-select>
                <mat-error *ngIf="etats?.invalid && (etats?.dirty || etats?.touched)">Vous devez sélectionner au moins un état</mat-error>
            </mat-form-field>

            <mat-form-field style="max-width: 500px;">
                <mat-label>Catégories</mat-label>
                <mat-select name="categories" id="categories" formControlName="categories" multiple required>                    
                        <mat-option *ngFor="let categorie of categories$; let indice = index" 
                            [value]             = "categorie.code">
                            {{indice + 1}}. {{ categorie.libelle }} 
                        </mat-option>
                </mat-select>
                <mat-error *ngIf="categories?.invalid && (categories?.dirty || categories?.touched)">Vous devez sélectionner au moins un état</mat-error>
            </mat-form-field>

            
                <mat-form-field class="formDate">
                    <mat-label>Date de début</mat-label>
                    <input matInput type="date" id="dateDebut" formControlName="dateDebut">
                    <mat-error *ngIf="dateDebut?.invalid && (dateDebut?.dirty || dateDebut?.touched)">{{
                        dateDebut.errors?.erreur }}</mat-error>
                </mat-form-field>

                <mat-form-field class="formDate">
                    <mat-label>Date de fin</mat-label>
                    <input matInput type="date" id="dateFin" formControlName="dateFin">
                    <mat-error *ngIf="dateFin?.invalid && (dateFin?.dirty || dateFin?.touched)">{{ dateFin.errors?.erreur }}
                    </mat-error>
                </mat-form-field>
            
            <mat-action-row>
                <div class="div-container-between" style="padding: 8px;">
                    <div class="div-button-left">
                        <button mat-stroked-button color="basic" class="label-primary"
                        *ngIf="profilUtilisateur$.droits_utilisateur.droit_module_adela"
                            (click)="goToCommandKIT()">
                            <mat-icon color="primary">shopping_bag</mat-icon>
                            <span>Commande de Kits (ADELA)</span>
                        </button>
                        <button mat-stroked-button color="basic" class="label-primary"
                        *ngIf="profilUtilisateur$.droits_utilisateur.droit_module_outil_diagnostic"
                            (click)="goToCommandDIAG()">
                            <mat-icon color="primary">work</mat-icon>
                            <span>Commande d'Outil Diagnostic</span>
                        </button>
                    </div>
                    <div class="div-button-right">
                        <button mat-flat-button 
                                *ngIf       = "!waitingResearch"
                                color       = "primary"
                                (click)     = "findAll()" 
                                [disabled]  = "waitingResearch || !FiltreFormGroup.valid"
                                >
                            <mat-icon>search</mat-icon>
                            <span class="hide">Rechercher</span>
                        </button>
                        <mat-spinner diameter="24" *ngIf="waitingResearch"></mat-spinner>
                    </div>                  
                </div>
            </mat-action-row>
        </mat-expansion-panel>
    </form>
</mat-card>

<mat-card appearance="outlined" class="mat-card-no-shadow" style="padding: 0px;">
    <mat-accordion>
        <mat-expansion-panel [expanded]="haveSomeCommand" #expandCommand style="background-color: #fff">
            <mat-expansion-panel-header style="background-color: #fff">
                <mat-panel-title>
                    <mat-icon color="primary">shopping_bag</mat-icon>
                    <span style="width: 220px;" *ngIf="commande$ != undefined"><span class="span-primary">{{ commande$.length }}</span> COMMANDE<span *ngIf="commande$.length > 1">S</span></span>
                    <span style="width: 220px;" *ngIf="commande$ == undefined"><span class="span-primary">0</span> COMMANDE</span>
                </mat-panel-title>
                <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>
            <app-table-commande [dataSource]="commande$" (refreshCommand)="refreshCommand($event)" [listeEtat]="etats$"></app-table-commande>
        </mat-expansion-panel>
    </mat-accordion>
</mat-card>