import { Injectable } from '@angular/core';
import { TypesuspensionService } from '../data_access/type-suspension.service';
import {  TypeSuspension } from '../interfaces/suspension';
import {  Subject, merge } from 'rxjs';
import { scan } from 'rxjs/operators';
import { _isNumberValue } from '@angular/cdk/coercion';
import { CST_ACTION_ADD, CST_ACTION_MODIFY } from 'src/app/interfaces/constantes';



@Injectable({
  providedIn: 'root'
})
export class TypeSuspensionFacadeService {

  constructor(private typeSuspensionService:TypesuspensionService) { }

  private typeSuspensionAddedSubject    = new Subject<TypeSuspension>()
  typeSuspensionAddedAction$            = this.typeSuspensionAddedSubject.asObservable()

  private typeSuspensionRemovedSubject  = new Subject<any>()
  typeSuspensionRemovedAction$          = this.typeSuspensionRemovedSubject.asObservable()

  private typeSuspensionModifiedSubject = new Subject<TypeSuspension>()
  typeSuspensionModifiedAction$         = this.typeSuspensionModifiedSubject.asObservable()

  typesSuspension$  = merge(
    this.typeSuspensionService.getTypesSuspension(),
    this.typeSuspensionAddedAction$,
    this.typeSuspensionModifiedAction$,
    this.typeSuspensionRemovedAction$
  ).pipe(
    scan((acc,value)=>{
      if (value instanceof Array) {
        return [...value] as TypeSuspension[]
      }else if(_isNumberValue(value)){
        //We remove the TypeSuspension
        const index = (acc as TypeSuspension[]).findIndex((typeSuspension:TypeSuspension) => typeSuspension.id === value)
          if (index) {
            (acc as TypeSuspension[]).splice(index,1)
            return acc
          }else{
            //We didn't find in the array the TypeSuspension (normally impossible!), so we do nothing and bring back the entire array
            return acc as TypeSuspension[]
          }
      }else{
        if (value.action===CST_ACTION_ADD) {
          //We add the TypeSuspension
          return [...acc as TypeSuspension[],value] as TypeSuspension[]
        }else{
          //We modify the TypeSuspension
          return (acc as TypeSuspension[]).map((typeSuspension:TypeSuspension)=> typeSuspension.id===value.id? value : typeSuspension)
        
        }
      }
    })
  ) 

  add(typeSuspension:TypeSuspension){
    this.typeSuspensionService.postTypeSuspension(typeSuspension).subscribe(
       (newTypeSuspension)=>{
        if (newTypeSuspension !=false) {
          this.typeSuspensionAddedSubject.next({...typeSuspension,id:(newTypeSuspension as TypeSuspension).id,action:CST_ACTION_ADD}) 
        }
       }
      
    )    
  }

  remove(IdTypeSuspension:number){
    this.typeSuspensionService.deleteTypeSuspension(IdTypeSuspension).subscribe(
     (result:any)=>{
        if (result.success === true) {
          this.typeSuspensionRemovedSubject.next(IdTypeSuspension)
        }
      }
    )
  }

  modify(typeSuspension:TypeSuspension){
    this.typeSuspensionService.putTypeSuspension(typeSuspension).subscribe(
      (data)=>{if (data !=false) {
        this.typeSuspensionModifiedSubject.next({...typeSuspension,action:CST_ACTION_MODIFY})
      }
      }
    )
  }

}
