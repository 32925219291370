import { Component, OnInit } from '@angular/core';
import { HistoriqueFacture } from 'src/app/interfaces/facture/historique-facture';
import { ApiFactureService } from 'src/app/services/api-facture.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { NgStyle, DatePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatTableResponsiveDirective } from '../../../directive/mat-table-responsive/mat-table-responsive.directive';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
    selector: 'app-dialog-historique-facture',
    templateUrl: './dialog-historique-facture.component.html',
    styleUrls: ['./dialog-historique-facture.component.scss'],
    standalone: true,
    imports: [MatDialogModule, MatTableModule, MatTableResponsiveDirective, MatButtonModule, NgStyle, DatePipe]
})
export class DialogHistoriqueFactureComponent implements OnInit {
  id: number = 0;
  historiques: HistoriqueFacture[] = [];

  // Tableau des dossiers
  displayedColumns: string[] = ['date', 'etat', 'description'];
  dataSource: any;

  constructor(private apiFacture  : ApiFactureService
            , private toast       : PersoSnackbarService) { }

  ngOnInit(): void {
    this.chargeHistoriqueFacture(); 
  }

  public getBackgroundColorByLibelle(status: string) {
    return this.apiFacture.getBackgroundColorByLibelle(status);
  }

  public chargeHistoriqueFacture(){
    if(this.id > 0) {
      this.apiFacture.getHistoriqueFacture(this.id)
      .subscribe(
        (data: HistoriqueFacture[] ) => {
          this.historiques = data;
          this.dataSource = this.historiques;    
        },
        (err: any) => {         
          console.error('/!\ err: ' + err.message); 
        }
      );
    } else {
        this.toast.showInfo("Une erreur est survenue. Aucun historique n'est disponible");
    }     
  }
}
