import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiErrorService } from 'src/app/services/support/api-error.service';
import { Contenu, FiltreContenu, Operation } from '../interfaces/contenu';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmailDataAccesService {

  url: string       = '/api/email/';

  constructor(private http:HttpClient
            , private apiError    : ApiErrorService) { }

  // 1 -  CONTENU

  //1.1 - Select
  public getEmails(filtre:FiltreContenu| null):Observable<Contenu[]>{
    let params = new HttpParams()
    if (filtre) {
      params = params.append('idOperation',filtre.idOperation.toString())
      params = params.append('sujet',filtre.sujet)
      params = params.append('contenu',filtre.contenu)
    }
    return this.http.get<Contenu[]>(this.url+'contenu',{params:params})
                    .pipe(catchError(this.apiError.handleError<Contenu[]>('getEmails',[])));
  }

  public getOperations(code :string = ''): Observable<Operation[]> {
    return this.http.get<Operation[]>(this.url+'operation/' +  code)
                    .pipe(catchError(this.apiError.handleError<Operation[]>('getOperations',[])));
  }             

  //1.2 - Update
  public putContenu(contenu:Contenu):Observable<Contenu | boolean>{
    return this.http.put<Contenu>(this.url+'contenu', contenu)
                    .pipe(catchError(this.apiError.handleError<boolean>('putContenu',false)));
  }

  public putOperation(operation:Operation):Observable<Operation | boolean>{
    return this.http.put<Operation>(this.url+'operation', operation)
                    .pipe(catchError(this.apiError.handleError<boolean>('putOperation',false)));
  }

  //1.3 - Insert
  public postContenu(contenu:Contenu):Observable<Contenu | boolean>{
    return this.http.post<Contenu>(this.url+'/contenu', contenu)
                    .pipe(catchError(this.apiError.handleError<boolean>('postContenu',false)));
  }

}
