    <mat-expansion-panel [(expanded)]="expandPannel" style="background-color: #fff">
        <mat-expansion-panel-header style="background-color: #fff">
            <mat-panel-title>
                <mat-icon color="primary">file</mat-icon>
                <span style="width: 220px;" *ngIf="dataSource_prelevements!= undefined"><span class="span-primary">{{ dataSource_prelevements.length }}</span>HISTORIQUE PAIEMENT<span *ngIf="dataSource_prelevements.length > 1">S</span></span>
                <span style="width: 220px;" *ngIf="dataSource_prelevements == undefined"><span class="span-primary">0</span>HISTORIQUE PAIEMENT</span>        
            </mat-panel-title>
            <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>

            <div class="container">                
                <table mat-table matTableResponsiveLarge matTableExporter [dataSource]="dataSourceMatTable" #exporter="matTableExporter" class="table-primary">
                
                    <!-- Date Column -->
                    <ng-container matColumnDef="date_creation">
                        <th mat-header-cell *matHeaderCellDef class="table-padding"> Date </th>
                        <td mat-cell *matCellDef="let prelevement" class="table-padding">
                            <div *ngIf="prelevement.dateCreation >= '19000101'">{{prelevement.dateCreation | date: 'dd/MM/yyyy'}}</div>
                            <div *ngIf="prelevement.dateCreation < '19000101'">__/__/____</div>
                        </td>
                    </ng-container>
                    
                    <!-- Type --> 
                    <ng-container matColumnDef="prl_type">
                        <th mat-header-cell *matHeaderCellDef class="table-padding" style="text-align: right;"> Mode paiement </th>
                        <td mat-cell *matCellDef="let prelevement" class="table-padding" style="text-align: right;"> {{ prelevement.ModePaiement.libelle }} </td> 
                    </ng-container>

                      <!-- Montant --> 
                      <ng-container matColumnDef="prl_montant_ordre" style="width: 150px;" >
                        <th mat-header-cell *matHeaderCellDef class="table-paddings"  style="text-align: right;"> Montant </th>
                        <td mat-cell *matCellDef="let prelevement" class="table-padding" style="text-align: right; font-weight: bold;" > {{ prelevement.montant | number:'1.2-2' }} €</td> 
                    </ng-container>

                     <!-- Facture --> 
                    <ng-container matColumnDef="prl_facture">
                        <th mat-header-cell *matHeaderCellDef class="table-padding" style="text-align: right;"> N° Facture </th>
                        <td mat-cell *matCellDef="let prelevement" class="table-padding" style="text-align: right;"> {{ prelevement.facture.numero }} </td> 
                    </ng-container>

                     <!-- Etat --> 
                     <ng-container matColumnDef="etat">
                        <th mat-header-cell *matHeaderCellDef class="table-padding" style="text-align: right;"> Etat </th>
                        <td mat-cell *matCellDef="let prelevement" class="table-padding" style="text-align: right;"> {{ prelevement.facture.Etat.libelle }} </td> 
                    </ng-container>

                     <!-- Reference Column -->
                     <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef ></th>
                        <td mat-cell *matCellDef="let prelevement">
                            <button mat-flat-button title="Visualiser la facture" (click)="openFacture(prelevement.facture.id)"
                                class="custom-button" style="text-decoration: underline;">Ouvrir</button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <div class="div-container-between" style="align-items: center;background-color:white">
                    <app-export-table [fileExportName]="this.fileExportName" [exporter]="exporter" [nb_ligne]="this.dataSourceMatTable.data.length"></app-export-table>
                    <mat-paginator [pageSizeOptions]="[10,25,50]"
                        showFirstLastButtons 
                        aria-label="Nombre de lignes visibles...">
                        </mat-paginator>
                </div>
            </div>
    </mat-expansion-panel>
