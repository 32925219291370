import { DatePipe, NgIf, NgStyle } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTableExporterModule } from 'mat-table-exporter';
import { Commande, CommandeFacturee } from 'src/app/interfaces/commande/commande';
import { ApiCommandeService } from 'src/app/services/api-commande.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { MatTableResponsiveLargeDirective } from '../../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';
import { ExportTableComponent } from '../../../templates/export-table/export-table.component';

@Component({
    selector: 'app-facturation-liste',
    templateUrl: './facturation-liste.component.html',
    styleUrls: ['./facturation-liste.component.scss'],
    standalone: true,
    imports: [MatCardModule, NgIf, MatTableModule, MatTableResponsiveLargeDirective, MatTableExporterModule, MatIconModule, NgStyle, MatButtonModule, MatProgressSpinnerModule, ExportTableComponent, MatPaginatorModule, DatePipe]
})
export class FacturationListeComponent implements OnInit {
  
  @Input() commandes! : Commande[]

  dataSourceMatTable  : MatTableDataSource<Commande> = new MatTableDataSource<Commande>();
  displayedColumns    : Array<String> = ['idCategorie', 'option', 'numero', 'raison_sociale', 'expert', 'date_creation', 'idEtat', 'actions'];
  columnNames = ['idcategorie', 'option', 'numero', 'raison_sociale', 'expert', 'date_creation', 'etat', 'actions'];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;

  constructor(public apiCommande : ApiCommandeService
              ,private toast         : PersoSnackbarService) { }

  ngOnInit(): void {
  }

  factureCommande(commandeAFacturer:Commande){
    let index : number;
    let commandeTrouvee : Commande;

    index = this.commandes.findIndex((commande)  => commande.id === commandeAFacturer.id)
    if (index >= 0){
      commandeTrouvee = this.commandes[index];
      commandeTrouvee.estTraitement = true;
    }
    
    const commandes:Commande[]=[commandeAFacturer]
    this.apiCommande.facturerCommandes(commandes).subscribe(
      (commandesFacturees:CommandeFacturee[]) => this.tagCommandesFacturees(commandesFacturees)
      ,(error)=>{
        commandeTrouvee.estTraitement = false;
        console.log(error)
      }
      
    )
  }

  tagCommandesFacturees(commandesFacturees:CommandeFacturee[]){
    let isErrorFacturation:boolean  = false;
    let errorFacturation:string     = '';
    let index : number;
    let commandeTrouvee : Commande;

    commandesFacturees.forEach((commandeFacturee)=> {
      index = this.commandes.findIndex((commande) => commande.id === commandeFacturee.id)
      if (index >= 0 ){
        commandeTrouvee = this.commandes[index];
        commandeTrouvee.estFacturee = true;        
      }else{
        isErrorFacturation  = true;
        errorFacturation    = commandeFacturee.resultat;
      }
    }
    
   )
   
   if (isErrorFacturation === false) {
     if (commandesFacturees.length === 1) {
       this.toast.showInfo('La commande a bien été facturée')
     }else{
       this.toast.showError('Les commandes ont bien été facturées')
     }
    }else{
    this.toast.showError(errorFacturation) 
  }
  }

  ngOnChanges(){
       
    this.dataSourceMatTable.data = this.commandes
    this.dataSourceMatTable.paginator = this.paginator
  }
}
