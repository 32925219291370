import { Component, OnInit,Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Categorie } from 'src/app/interfaces/support/categorie';
import { ApiSupportService } from 'src/app/services/api-support.service';
import { ModeleFacadeService } from 'src/app/services/modele/modele-facade.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { MatButtonModule } from '@angular/material/button';
import { AutofocusDirective } from '../../../../directive/autofocus.directive';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-edit-categorie',
    templateUrl: './edit-categorie.component.html',
    styleUrls: ['./edit-categorie.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, AutofocusDirective, MatButtonModule]
})
export class EditCategorieComponent implements OnInit {
  @Input() categorie             : Categorie = <Categorie>{};
  @Output() resultValidateEvent = new EventEmitter<boolean>() ;
  CategorieFormGroup!             : FormGroup     
  titre                           : string =''

  constructor(private _fb               : FormBuilder
              ,private apiModeleFacade  : ModeleFacadeService
              , private toast           : PersoSnackbarService) { }

  ngOnInit(): void {
    this.initForm()
    this.Editer()
  }

  ngOnChanges(changes:SimpleChanges){
    if (this.categorie.cat_id==0) {
      this.titre = "Création d'une catégorie"
    }else{
      this.titre = "Edition d'une catégorie"
    }
    this.initForm()
    this.Editer()
  }

  initForm(){
    this.CategorieFormGroup        = this._fb.group({
      nom      : ['', [Validators.required]],
      code     : ['']
    })
  }

  Editer(){
    this.CategorieFormGroup.patchValue({
      nom   : this.categorie.cat_nom,
      code  : this.categorie.cat_code
    })
  }

  onValide(){
    this.categorie.cat_code = this.CategorieFormGroup.controls.code.value
    this.categorie.cat_nom  = this.CategorieFormGroup.controls.nom.value

    if (this.categorie.cat_id==0) {
      //Création de catégorie
      this.apiModeleFacade.creeCategorie(this.categorie)
    }else{
      //Il s'agit d'une modification
      this.apiModeleFacade.modifierCategorie(this.categorie)
    }
    this.resultValidateEvent.emit(true) 
  }

  onAnnule(){
    this.resultValidateEvent.emit(true)
  }
}
