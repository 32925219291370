import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaliseMail } from '../../interfaces/balise';
import { EMPTY, Observable } from 'rxjs';
import { NgFor } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-list-balise',
    templateUrl: './list-balise.component.html',
    styleUrls: ['./list-balise.component.scss'],
    standalone: true,
    imports: [MatListModule, NgFor,MatButtonModule]
})
export class ListBaliseComponent implements OnInit {
  
  @Input()  balises$      : Observable<BaliseMail[]>  = EMPTY
  @Output() baliseEvent   = new EventEmitter<BaliseMail>()
  
  balises : BaliseMail[]  = []

  constructor() { }

  ngOnInit(): void {
    this.balises$.subscribe(
      (balises:BaliseMail[])=>{
        this.balises = balises
      }
    )
  }
  
  onSelected(selected:boolean,balise:BaliseMail){
    if (selected===true) {
      this.baliseEvent.next(balise)
    }
  }
  
  onClick(balise:BaliseMail){
    this.baliseEvent.next(balise)
    
  }
}
