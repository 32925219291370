import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { Fournisseur } from 'src/app/interfaces/commande/fournisseur/fournisseur';
import { ApiFournisseurService } from 'src/app/services/api-fournisseur.service';
import { FiltreRechercheFournisseur } from 'src/app/interfaces/commande/fournisseur/filtre-recherche-fournisseur';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { ContexteService, TYPE_FOURNISEUR } from 'src/app/services/contexte.service';

@Component({
    selector: 'app-filtre-liste-fournisseurs',
    templateUrl: './filtre-liste-fournisseurs.component.html',
    styleUrls: ['./filtre-liste-fournisseurs.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatExpansionModule, MatIconModule, MatFormFieldModule, MatInputModule, NgIf, MatButtonModule, MatProgressSpinnerModule]
})
export class FiltreListeFournisseursComponent implements OnInit {

  @Output() newItemEvent = new EventEmitter<Array<Fournisseur>>();

  // Gestion de l'affichage
  expandPannel    : boolean = true;
  waitingResearch : boolean = false;
    
  // Formulaire de filtrage
  FiltreFormGroup: FormGroup;

  get denomination()  { return this.FiltreFormGroup.get('denomination'); }
  get code()          { return this.FiltreFormGroup.get('code'); }

  constructor(
      private _fb             : FormBuilder
    , private toast           : PersoSnackbarService
    , private apiFournisseur  : ApiFournisseurService
    , private contexte        : ContexteService
  ) {
    
      // Déclaration du formulaire
      this.FiltreFormGroup = this._fb.group({
        denomination    : ['',''],
        code            : ['', '']
      })
  }

  ngOnInit(): void {
    this.addNewItem(this.contexte.getTableData(TYPE_FOURNISEUR))
    this.initFilter()
  }

    initFilter(){
      const filtre = this.contexte.getFiltre(TYPE_FOURNISEUR)
      if (filtre!=null) {
        this.FiltreFormGroup.patchValue(filtre)
      }
    }

  public onSearchingSupplier() : void {
    
    let filtreFournisseur : FiltreRechercheFournisseur = <FiltreRechercheFournisseur>{};

    filtreFournisseur.code          = this.FiltreFormGroup.controls.code.value;
    filtreFournisseur.denomination  = this.FiltreFormGroup.controls.denomination.value;

    this.waitingResearch = true;
    this.addNewItem([]);

    this.apiFournisseur.putSearchingSupplierByFilter(filtreFournisseur)
    .subscribe(
      (data: Fournisseur[]) => {
        this.addNewItem(data);
        this.toast.showInfo('Recherche terminée');
        this.waitingResearch = false;
        this.expandPannel = false;
      },
      (err: any) => {
        console.error('/!\ err: ', err.message);
        this.waitingResearch = false;
      });
  }

  public addNewItem(value: Array<Fournisseur>) {
    this.newItemEvent.emit(value);
  }

  ngOnDestroy(){
    this.contexte.setFiltre(this.FiltreFormGroup.value) 
  }
}
