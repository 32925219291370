import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FormulaireAdc } from '../interfaces/adc/formulaire-adc';
import { Compagnie } from '../interfaces/adela/compagnie';
import { Etat } from '../interfaces/dossier/etat';

interface Status {
  id              : number,
  idUtilisateur   : number,
  reference       : string,
  immatriculation : string,
  habilitation    : string,
  dateReceptionCle: string,
  code            : string,
  commentaireRefus: string,
  commentaireDiagnostic: string
}

@Injectable({
  providedIn: 'root'
})
export class ApiAdcService {

  urlApi: string = '/api';

  // Liste des status de dossier ADC
  CONST_STATUS_ADC_1_DEPOSE: string     = 'DEPOSE' as const;
  CONST_STATUS_ADC_2_VALIDE: string     = 'VALIDE' as const;
  CONST_STATUS_ADC_3_ATTENTE_CLE: string= 'ATTENTE_CLE' as const;
  // CONST_STATUS_ADC_4_ANALYSE: string    = 'ANALYSE' as const;
  CONST_STATUS_ADC_5_DIAGNOSTIC: string = 'DIAGNOSTIC' as const;
  CONST_STATUS_ADC_6_CLOTURE: string    = 'CLOTURE' as const;
  CONST_STATUS_ADC_9_REFUSE: string     = 'REFUSE' as const;

  constructor(
    private http: HttpClient
    ) { }
  
    // Liste des compagnies d'assurance
    public getCompagnie(): Observable<Compagnie[]> {
      return this.http.get<Compagnie[]>(this.urlApi + '/compagnie');
    }

    // Liste des états de dossier ADC
    public getEtat(): Observable<Etat[]> {
      return this.http.get<Etat[]>(this.urlApi + '/adc/dossier/etat');
    }

    // Enregistrer le formulaire ADC
    public saveFormulaireAdc(formAdc: FormulaireAdc): Observable<FormulaireAdc> {
      console.info('date sinistre: '+formAdc.dateSinistre);
      console.info(formAdc);
      return this.http.post<FormulaireAdc>(this.urlApi + '/adc', formAdc);
    }
    
    // Modification du formulaire ADC
    public updateFormulaireAdc(formAdc: FormulaireAdc): Observable<FormulaireAdc> {
      return this.http.put<FormulaireAdc>(this.urlApi + '/adc', formAdc);
    }

    // Chargement d'un dossier ADC (tableau à une occurrence)
    public getFormulaireAdc(id: number): Observable<FormulaireAdc[]> {
      return this.http.get<FormulaireAdc[]>(this.urlApi + '/adc/' + id);
    }

    // Chargement de dossier ADC selon le filtre de recherche
    public getAllFolder(dataFilter: any): Observable<FormulaireAdc[]> {
      return this.http.post<FormulaireAdc[]>(this.urlApi + '/adc/dossier', dataFilter);
    }

    // Téléchargement d'un dossier ADC ou d'un fichier du dossier
    public download(document: number, id: number, reference?: string) {
      if ( document != 0 ) {
        return this.http.get( this.urlApi + '/adc/' +id+ '/download/' + document, { responseType: "blob", headers: {'Content-Type': 'application/json', Accept: "application/pdf,image/png,image/jpeg"} });
      } else {
        return this.http.get( this.urlApi + '/adc/' +id+ '/impression/confirmation' , { responseType: "blob", headers: {'Content-Type': 'application/json', Accept: "application/pdf,image/png,image/jpeg"} });
      }
    }

    public downloadRapport(id: number) {
      return this.http.get(this.urlApi + '/adc/' +id+ '/impression/analyse', { responseType: "blob", headers: {'Content-Type': 'application/json', Accept: "application/pdf"} })
    }

    // Changement de status du formulaire ADC
    public setStatus(id: number, idUtilisateur: number, ref: string, immat: string, habilitation: string, code: string, refus?: string, diagnostic?: string) {
      let formStatus: Status = <Status>{
        id: id,
        idUtilisateur: idUtilisateur,
        reference: ref,
        immatriculation: immat,
        habilitation: habilitation,
        code: code,
        commentaireRefus: refus,
        commentaireDiagnostic: diagnostic
      };
      
      return this.http.patch( this.urlApi + '/adc', formStatus)
    }
    
    public setDateReceptionCle(id: number, dateReceptionCle: string) {
      let formCle: Status = <Status>{
        id: id,
        dateReceptionCle: dateReceptionCle,
        code: this.CONST_STATUS_ADC_5_DIAGNOSTIC
      };
      return this.http.patch( this.urlApi + '/adc/cle', formCle)
    }

}

