<mat-card appearance="outlined" class="mat-card-padding" >
    <form [formGroup]="typeSuspensionForm">
       

            <mat-form-field>
                <mat-label>Nom</mat-label>
                <input matInput 
                        type                = "text" 
                        placeholder         = "Nom du type de suspension" 
                        formControlName     = "nom" 
                        >
                <mat-error >Le nom est obligatoire</mat-error>
            </mat-form-field> 

            <!-- <mat-form-field>
                <mat-label>Code</mat-label>
                <input  matInput 
                        type            = "text" 
                        placeholder     = "Code du type de suspension" 
                        oninput         = "this.value = this.value.toUpperCase()"
                        maxlength       = "20"
                        formControlName = "code">
                <mat-error >Le code est obligatoire</mat-error>
            </mat-form-field>  -->
           
            
            <mat-action-row>
                <div class="div-container-between" style="padding: 8px;">
                    <div class="div-container-center" style="width:100%; display: flex; justify-content: center;">
                        <button mat-flat-button 
                                color       = "primary" 
                                [disabled]  = "typeSuspensionForm.invalid" 
                                (click)     = "saveTypeSuspension()">
                            <mat-icon>save</mat-icon>Enregistrer                                    
                        </button>
                        
                        <button mat-flat-button 
                            type button
                            color       = "primary" 
                            (click)     = "cancelTypeSuspension()">
                            <mat-icon>cancel</mat-icon>Annuler                                    
                        </button>
                    </div>
                </div>
            </mat-action-row>
    </form>
</mat-card>
