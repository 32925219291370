import { NgFor, NgIf } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { of, throwError } from 'rxjs';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { TelephoneDirective } from 'src/app/directive/telephone.directive';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { EntiteAccesConcentrateur } from 'src/app/interfaces/profil-user/entite-acces-concentrateur';
import { IndiceSuccursale } from 'src/app/interfaces/profil-user/indice-succursale';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { TypeEntite } from 'src/app/interfaces/profil-user/type-entite';
import { RelationUtilisateurEntite } from 'src/app/interfaces/utilisateur-entite/relation-utilisateur-entite';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { ApiRelUtilisateurEntiteService } from 'src/app/services/api-rel-utilisateur-entite.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { Etablissement, EtablissementInitial, ReponseSiren, SirenService } from 'src/app/services/siren.service';
import { siretFromSiren } from 'src/app/validators/siretFromSiren';
import { TelephoneDirective as TelephoneDirective_1 } from '../../../directive/telephone.directive';
import { AdresseComponent } from '../../templates/adresse/adresse.component';
import { DialogLogoEntiteComponent } from '../dialog-logo-entite/dialog-logo-entite.component';
import { MailValidator } from 'src/app/validators/mail.validator';

export interface OutputParent{
  acces_concentrateur_actif         : boolean,
  creer_succursale                  : boolean,
  entite_est_succursale             : boolean,
  est_dirigeant_principal           : boolean,
  utl_profil                        : ProfilUtilisateur,
  entite                            : Entite,
  ent_id                            : number,
  RelUtilisateurEntite              : RelationUtilisateurEntite,
  acces_concentrateur               : EntiteAccesConcentrateur,
  succursales                       : Array<RelationUtilisateurEntite>,
  display                           : boolean,
  disabled_informations_generales   : boolean,
  disabled_invitation_collaborateur : boolean,
  disabled_liste_collaborateur      : boolean
}
@Component({
    selector: 'app-entite-informations-generales',
    templateUrl: './entite-informations-generales.component.html',
    styleUrls: ['./entite-informations-generales.component.scss'],
    standalone: true,
    imports: [NgIf, ReactiveFormsModule, MatCardModule, MatIconModule, MatButtonModule, MatFormFieldModule
              , MatInputModule, TelephoneDirective_1, MatSelectModule, NgFor, MatOptionModule, AdresseComponent
              , RouterLink, MatTooltipModule, MatProgressSpinnerModule, MatGridListModule]
})
export class EntiteInformationsGeneralesComponent implements OnInit {
  CONST_NOM_TYPE_ENTITE_SUCCURSALE = 'Succursale' as const;
  CONST_NOM_TYPE_ENTITE_PRINCIPALE = 'Cabinet Expertise' as const;

  @Output() cellClicked = new EventEmitter<OutputParent>();
  
  entite                     : Entite = <Entite>{};
  creer_succursale           : boolean = false;
  acces_concentrateur_actif  : boolean = false;
  est_dirigeant_principal    : boolean = false;
  waiting_save               : boolean = false;

  utl_profil                 : ProfilUtilisateur = <ProfilUtilisateur>{};
  RelUtilisateurEntite       : RelationUtilisateurEntite = <RelationUtilisateurEntite>{};

  ent_id                    : number = 0;
  typeEntites               : Array<TypeEntite> = new Array<TypeEntite>();
  typeEntiteSelected        : number = 0;
  typeEntiteCode            : string = '';
  succursale                : Entite = <Entite>{}; 

  acces_concentrateur : EntiteAccesConcentrateur = <EntiteAccesConcentrateur>{};
  
  entite_est_succursale : boolean = false;
  succursales         : Array<RelationUtilisateurEntite> = new Array<RelationUtilisateurEntite> ();
  typeAdela           : boolean  = false
  typeEntiteForm = this.fb.group({
    id: [0],
    libelle: ['']
  });

  //Intégration du composant Adresse:
  disabledFields          : boolean = false

  breakpointSmall : boolean = true

  // description d'un schema de validation de données
  entiteForm = this.fb.group({
    id                  : [null, Validators.required],
    id_entite_principale: [null],
    raison_sociale      : ['', Validators.required],
    indice              : [''],
    numero_client       : [''],
    logo                : [null],
    id_type_entite      : ['', Validators.required],
    typ_ent_nom         : [''],
    habilitation        : [''],
    siren               : ['', [Validators.required, this.checkLengthSIREN]],
    siret               : ['', [Validators.required, this.apiSiren.checkLengthSIRET]],
    telephone           : ['', this.checkNumberPhone],
    fax                 : ['', this.checkNumberPhone],
    mail_facturation    : ['', [Validators.email, this.MailValidator.checkValidatorMail()]],
    mail_statistique    : ['', [Validators.email, this.MailValidator.checkValidatorMail()]],
    mail_contact        : ['', [Validators.email, this.MailValidator.checkValidatorMail()]],
    active              : [0],
    idAdresse           : [0]
  },
  {
    validators: [this.siretFromSiren.siretMustBeginLikeSiren()],
    updateOn: 'blur',
  }
  );
  
  get siren()             { return this.entiteForm.get('siren'); }
  get siret()             { return this.entiteForm.get('siret'); }
  get telephone()         { return this.entiteForm.get('telephone'); } 
  get fax()               { return this.entiteForm.get('fax'); }           
  get mail_facturation()  { return this.entiteForm.get('mail_facturation'); }
  get mail_statistique()  { return this.entiteForm.get('mail_statistique'); }
  get mail_contact()      { return this.entiteForm.get('mail_contact'); }

  siret$ = this.siret?.valueChanges
  siretValid:boolean = false;

  constructor(private apiEntite : ApiEntiteService
            , public  apiAuth: ApiAuthentificationService
            , private apiRelUtilisateurEntite: ApiRelUtilisateurEntiteService 
            , private toast     : PersoSnackbarService
            , public dialog     : MatDialog
            , private fb        : FormBuilder
            , public _sanitizer : DomSanitizer
            , private routed    : ActivatedRoute
            , private phoneDirective        : TelephoneDirective
            , private apiSiren : SirenService
            , private siretFromSiren : siretFromSiren
            , private MailValidator : MailValidator) { 
    this.routed.params.subscribe(val => {
      // this.ngOnInit();
    });
  }

  onResize(event:any) {
    //For responsiveness:
    this.breakpointSmall = (event.target.innerWidth <= 600) ? true : false;
  }

  VerifierForm(){
  }

  ngOnInit(): void {
    // On force le reset des valeurs par défaut, car lorsqu'on recharge le component entité,    
    // des valeurs peuvent déjà être configuré par une "session" précédente (session dans le sens appel depuis une autre page)
    // mais ne pas être réinitialisé car le component ne refait pas la déclaration des variables puisqu'il a déjà été initialisé par la "session" précédente 
    
    //For responsiveness:
    this.breakpointSmall = (window.innerWidth <= 600) ? true : false;

    this.siret?.valueChanges.pipe(mergeMap(
      (siret)=> {
        this.siretValid = false;
        
        if (siret.length === 14 && this.creer_succursale===true) 
        {
        return this.apiSiren.getEtablissement(siret)
      }else{
        return of(null)
      }}
    ))!.subscribe(
      (data : ReponseSiren | null)=> {if (data ) {
        
        
        switch (data.header.statut) {
          case 200:
            
          if (data.etablissement.periodesEtablissement[0].etatAdministratifEtablissement==='A') {
              this.siretValid=true;
              this.initialiseDatasWithSiret(data.etablissement)
            }
            else
            {
             
              this.siretValid=false;
              this.toast.showError('Cet établissement n\'est plus actif, vous ne pouvez donc pas l\'enregistrer')
              this.initialiseDatasWithSiret(EtablissementInitial)
            }
            
            break;
        
          case 404:
            //non trouvés
            this.toast.showError(data.header.message)
            this.initialiseDatasWithSiret(data.etablissement)
            
            break;
          
            case 410:
            //La requête de sélection du siret dans la base a échoué
            this.toast.showError(data.header.message)
            this.initialiseDatasWithSiret(data.etablissement)
            break;

          case 409:
            //Un établissement avec ce SIRET existe déjà dans la base
            this.toast.showError(data.header.message)
            this.initialiseDatasWithSiret(data.etablissement)
          break;
        }
      }}

      ,(error)=>{
        this.siretValid = false;
        this.initialiseDatasWithSiret(EtablissementInitial)
        this.toast.showError(error.error)
      }
        
    )
   
    this.ent_id              = 0;
    
    this.creer_succursale    = false;
    this.entite_est_succursale = false;
    
    this.typeEntiteSelected  = 0;
    this.typeEntiteCode      = '';
    this.RelUtilisateurEntite= <RelationUtilisateurEntite>{};
    this.succursales         = new Array<RelationUtilisateurEntite> ();
    this.typeEntites         = new Array<TypeEntite>();
    this.utl_profil          = <ProfilUtilisateur>{};
    this.succursale          = <Entite>{};
    this.acces_concentrateur = <EntiteAccesConcentrateur>{};
    this.acces_concentrateur_actif = false;


    // Récupération de l'identifiant entité saisie en paramètre de l'URL
    this.ent_id     = this.routed.snapshot.params['id'];
    this.utl_profil = this.apiAuth.userConnect;

    // Désactive la modification des champs, car ils ne sont pas censé être modifié par l'utilisateur
    // Sauf si l'utilisateur qui accède au component est le dirigeant de l'entité mère / succursale
    if(!this.utl_profil.droits_utilisateur.est_admin) {
      this.disabledFields = true;
      this.entiteForm.controls['raison_sociale'].disable();
      this.entiteForm.controls['habilitation'].disable();
      this.entiteForm.controls['numero_client'].disable();
      this.entiteForm.controls['indice'].disable();
      this.entiteForm.controls['id_type_entite'].disable();
      this.entiteForm.controls['siren'].disable();
      this.entiteForm.controls['siret'].disable();
      this.entiteForm.controls['fax'].disable();
      this.entiteForm.controls['mail_facturation'].disable();
      this.entiteForm.controls['mail_statistique'].disable();
      this.entiteForm.controls['mail_contact'].disable();
    }

    // Détermine si l'appel au component est pour créer une succursale ou visualiser le détails d'une entité
    if(this.routed.snapshot.paramMap.get('data') != null && this.routed.snapshot.paramMap.get('data') === 'succursale') {
      this.creer_succursale = true;
      this.entiteForm.controls['id'].disable(); 
    }

    // Détermine si l'appel au component est pour visualiser une succursale en tant que dirigeant principal
    if(this.routed.snapshot.paramMap.get('acces') != null && this.routed.snapshot.paramMap.get('acces') === 'dirigeant_principal') {
      this.est_dirigeant_principal = true;      
    }    

    if(this.creer_succursale == true || this.est_dirigeant_principal == true) {
      this.entiteForm.controls['raison_sociale'].enable();

      if(!this.creer_succursale) {
        this.entiteForm.controls['habilitation'].enable();
        this.entiteForm.controls['indice'].enable();
        this.entiteForm.controls['siren'].enable();
      }
      this.disabledFields = false
      this.entiteForm.controls['siret'].enable();
      this.entiteForm.controls['telephone'].enable();
      this.entiteForm.controls['fax'].enable();
      this.entiteForm.controls['mail_facturation'].enable();
      this.entiteForm.controls['mail_statistique'].enable();
      this.entiteForm.controls['mail_contact'].enable();
    }

    this.chargeData();
  }

  initialiseDatasWithSiret(etablissement:Etablissement){
    
    this.entiteForm.patchValue({ raison_sociale      : etablissement?.uniteLegale.denominationUniteLegale});
    
    this.entiteForm.get('adresse.adresseLigne1')?.setValue(this.apiSiren.getVoieFormatee(etablissement))
    this.entiteForm.get('adresse.adresseLigne2')?.setValue(etablissement?.adresseEtablissement.complementAdresseEtablissement)
    this.entiteForm.get('adresse.adresseCP')?.setValue(etablissement?.adresseEtablissement.codePostalEtablissement)
    this.entiteForm.get('adresse.adresseVille')?.setValue(etablissement?.adresseEtablissement.libelleCommuneEtablissement)

  }
  
  chargeData(): void {
    if(this.ent_id > 0) {
      this.apiEntite.getEntiteById(this.ent_id)
      .pipe(
        tap((entites: Entite[]) => {
          this.entite = entites[0];
          
          this.typeAdela = this.entite.typ_ent_code==='ADELA'
          if(!this.creer_succursale) { 
            this.typeEntiteSelected = entites[0].id_type_entite;
            
            // On détecte qu'il s'agit d'une entité mère
            // On débloque tous les champs, sauf s'il n'est pas dirigeant
            let rel: any = this.apiAuth.relUserEnt.find(rel => rel.entite_id == this.ent_id)
            
            if(rel != undefined) {
              if(rel.est_dirigeant) {
                this.disabledFields = false;
                this.entiteForm.controls['raison_sociale'].enable();
                this.entiteForm.controls['habilitation'].enable();
                this.entiteForm.controls['indice'].enable();
                // this.entiteForm.controls['id_type_entite'].enable();
                this.entiteForm.controls['siren'].enable();
                this.entiteForm.controls['siret'].enable();
                this.entiteForm.controls['telephone'].enable();
                this.entiteForm.controls['fax'].enable();
                this.entiteForm.controls['mail_facturation'].enable();
                this.entiteForm.controls['mail_statistique'].enable();
                this.entiteForm.controls['mail_contact'].enable();
              }  
            }
            
            // On détecte qu'il s'agit d'une succursale
            // On bloque les champs qui ne doivent pas être modifiable, car lié à l'entité mère
            if(entites[0].typ_ent_nom == this.CONST_NOM_TYPE_ENTITE_SUCCURSALE) {
              this.entite_est_succursale = true;
              this.entiteForm.controls['habilitation'].disable();
              this.entiteForm.controls['indice'].disable();
              this.entiteForm.controls['id_type_entite'].disable();
              this.entiteForm.controls['siren'].disable();
            }
          }
        }),
        switchMap(() => this.apiEntite.getAllTypeEntite()),
        tap((type_entites: TypeEntite[]) => {
          this.typeEntites = type_entites;
  
          // Permet de déterminer s'il s'agit d'une succursale
          // Sert à afficher / masquer le champ indice etc.
          if(this.creer_succursale) {
  
            // Dans le cas de la création d'une succursale d'une entité principale, on supprime les autres types succursale pour éviter des erreurs de saisies
            if (this.typeEntites.find(type => type.code == this.apiEntite.CONST_CODE_TYPE_ENTITE_EXPERT)) {
              this.typeEntites.splice(this.typeEntites.findIndex(type => type.code == this.apiEntite.CONST_CODE_TYPE_ENTITE_EXPERT), 1);
            }
            if (this.typeEntites.find(type => type.code == this.apiEntite.CONST_CODE_TYPE_ENTITE_PRESTATAIRE)) {
              this.typeEntites.splice(this.typeEntites.findIndex(type => type.code == this.apiEntite.CONST_CODE_TYPE_ENTITE_PRESTATAIRE), 1);
            }
            if (this.typeEntites.find(type => type.code == this.apiEntite.CONST_CODE_TYPE_ENTITE_REPARATEUR)) {
              this.typeEntites.splice(this.typeEntites.findIndex(type => type.code == this.apiEntite.CONST_CODE_TYPE_ENTITE_REPARATEUR), 1);
            }
  
            this.typeEntites.find(type => {
              if(type.code === this.apiEntite.CONST_CODE_TYPE_ENTITE_SUCCURSALE) {
                this.typeEntiteSelected = type.id;
                this.typeEntiteCode     = type.code;
              }
            });
          } else {
            this.typeEntites.find(type => {
              if(type.id === this.typeEntiteSelected) {
                this.typeEntiteCode = type.code;
              }
            });
          }        
        }),
        switchMap(() => this.apiEntite.getProchainIndiceSuccursale(this.ent_id)),
        tap((indice_succursale : IndiceSuccursale) => {
          this.entiteForm.patchValue({
            indice: indice_succursale.indice               
          });
        }),
        catchError((error: HttpErrorResponse) => {
          this.toast.showError(error.error);
          return throwError(error);
        })
      ).subscribe(() => {
        if(!this.creer_succursale) {
          
          this.entiteForm.get('adresse.adresseLigne1')?.setValue(this.entite.adresse)
          this.entiteForm.get('adresse.adresseLigne2')?.setValue(this.entite.adresse_cplt)
          this.entiteForm.get('adresse.adresseLigne3')?.setValue(this.entite.adresse_ligne3)
          this.entiteForm.get('adresse.adresseCP')?.setValue(this.entite.code_postal)
          this.entiteForm.get('adresse.adresseVille')?.setValue(this.entite.ville)
          this.entiteForm.patchValue({
            id                  : this.entite.id,
            id_entite_principale: this.entite.id_entite_principale,
            raison_sociale      : this.entite.raison_sociale,
            numero_client      : this.entite.numero_client,
            indice              : this.entite.indice,
            logo                : this.entite.logo,
            id_type_entite      : this.entite.id_type_entite,
            typ_ent_nom         : this.entite.typ_ent_nom,
            habilitation        : this.entite.habilitation,
            siren               : this.entite.siren,
            siret               : this.entite.siret,
            telephone           : this.entite.telephone,
            fax                 : this.entite.fax,
            mail_facturation    : this.entite.mail_facturation,
            mail_statistique    : this.entite.mail_statistique,
            mail_contact        : this.entite.mail_contact,
            active              : this.entite.EstActif,
            idAdresse           : this.entite.idAdresse
          });
  
          if(this.entite.acces_concentrateur.id > 0) {
            this.acces_concentrateur = {
              id                  : this.entite.acces_concentrateur.id,
              habilitation        : this.entite.acces_concentrateur.habilitation,
              indice              : this.entite.acces_concentrateur.indice,
              mot_de_passe        : this.entite.acces_concentrateur.mot_de_passe,
              date_creation       : this.entite.acces_concentrateur.date_creation,
              date_modification   : this.entite.acces_concentrateur.date_modification,
              actif               : this.entite.acces_concentrateur.actif,
              habilitation_indice : this.entite.acces_concentrateur.habilitation_indice,
              mailAuto            : this.entite.acces_concentrateur.mailAuto,
              mailConcentrateur   : this.entite.acces_concentrateur.mailConcentrateur
            };
  
            if(this.acces_concentrateur.actif) { this.acces_concentrateur_actif = true; }
          }
        } else {
          // Dans le cas d'une création de succursale, on affecte l'id de l'entité principale et les informations communes
          this.entiteForm.patchValue({
            id_entite_principale: this.entite.id,              
            id_type_entite      : this.typeEntiteSelected,
            typ_ent_nom         : this.apiEntite.CONST_CODE_TYPE_ENTITE_SUCCURSALE,
            habilitation        : this.entite.habilitation,
            siren               : this.entite.siren              
          });
        }
  
        // Après le chargement complet des données, on les émets auprès des autres components
        this.cellClicked.emit({
          acces_concentrateur_actif         : this.acces_concentrateur_actif,
          creer_succursale                  : this.creer_succursale,
          entite_est_succursale             : this.entite_est_succursale,
          est_dirigeant_principal           : this.est_dirigeant_principal,
          utl_profil                        : this.utl_profil,
          entite                            : this.entite,
          ent_id                            : this.ent_id,
          RelUtilisateurEntite              : this.RelUtilisateurEntite,
          acces_concentrateur               : this.acces_concentrateur,
          succursales                       : this.succursales,
          display                           : true,
          disabled_informations_generales   : false,
          disabled_liste_collaborateur      : true,
          disabled_invitation_collaborateur : true
        });
      });                                        
  
      this.apiRelUtilisateurEntite.getRelUtilisateurEntite(this.utl_profil.id, this.ent_id)
        .subscribe(
          (data: RelationUtilisateurEntite[]) => { 
            
            data.find(rel_utl_ent => {
              if(rel_utl_ent.entite_id == this.ent_id && rel_utl_ent.utilisateur_id == this.utl_profil.id){
                this.RelUtilisateurEntite = rel_utl_ent
  
                if(this.est_dirigeant_principal) { this.RelUtilisateurEntite.est_dirigeant = this.est_dirigeant_principal }
              }
  
              // Après le chargement complet des données, on les émets auprès des autres components
              this.cellClicked.emit({
                acces_concentrateur_actif         : this.acces_concentrateur_actif,
                creer_succursale                  : this.creer_succursale,
                entite_est_succursale             : this.entite_est_succursale,
                est_dirigeant_principal           : this.est_dirigeant_principal,
                utl_profil                        : this.utl_profil,
                entite                            : this.entite,
                ent_id                            : this.ent_id,
                RelUtilisateurEntite              : this.RelUtilisateurEntite,
                acces_concentrateur               : this.acces_concentrateur,
                succursales                       : this.succursales,
                display                           : true,
                disabled_informations_generales   : false,
                disabled_liste_collaborateur      : true,
                disabled_invitation_collaborateur : true
              });
            });
          }, 
          (err) => { console.log(err)});
      
      this.apiRelUtilisateurEntite.getRelUtilisateurEntite(this.utl_profil.id, 0)
      .subscribe(
        (data: RelationUtilisateurEntite[]) => { 
          this.succursales = data;
          for(let i = this.succursales.length - 1; i >= 0; i--) {
            if(this.succursales[i].entite.typ_ent_nom != this.CONST_NOM_TYPE_ENTITE_SUCCURSALE || this.succursales[i].entite.id_entite_principale != this.ent_id) {
              this.succursales.splice(i, 1);
            }          
          }
  
          // Après le chargement complet des données, on les émets auprès des autres components
          this.cellClicked.emit({
            acces_concentrateur_actif         : this.acces_concentrateur_actif,
            creer_succursale                  : this.creer_succursale,
            entite_est_succursale             : this.entite_est_succursale,
            est_dirigeant_principal           : this.est_dirigeant_principal,
            utl_profil                        : this.utl_profil,
            entite                            : this.entite,
            ent_id                            : this.ent_id,
            RelUtilisateurEntite              : this.RelUtilisateurEntite,
            acces_concentrateur               : this.acces_concentrateur,
            succursales                       : this.succursales,
            display                           : true,
            disabled_informations_generales   : false,
            disabled_liste_collaborateur      : true,
            disabled_invitation_collaborateur : true
          });
        }, 
        (err) => { console.log(err)});
    }
  }


  enregistreData(bAppelPourDesactivation : boolean): void {            
    this.waiting_save = true;
    
    // Cas d'une création de succursale
    if(this.creer_succursale) {
      this.succursale.id                    = this.entiteForm.value.id;
      this.succursale.id_entite_principale  = this.entiteForm.value.id_entite_principale;
      this.succursale.raison_sociale        = this.entiteForm.value.raison_sociale;                  
      
      // Lorsqu'un formControl est disabled, il faut utiliser la fonction getRawValue pour recupérer sa valeur
      // car utiliser .value.xxx renverrait undefined
      this.succursale.id_type_entite         = this.entiteForm.getRawValue().id_type_entite;

      this.typeEntites.find(typeEntite => {
        if(typeEntite.id === this.entiteForm.getRawValue().id_type_entite) {
         this.succursale.typ_ent_nom = typeEntite.libelle;
        }
      });

      this.succursale.habilitation           = this.entiteForm.getRawValue().habilitation;
      this.succursale.indice                 = this.entiteForm.getRawValue().indice;
      this.succursale.siren                  = this.entiteForm.getRawValue().siren;

      this.succursale.siret                  = this.entiteForm.value.siret;    
      
      this.succursale.adresse                = this.entiteForm.value.adresse.adresseLigne1;
      this.succursale.adresse_cplt           = this.entiteForm.value.adresse.adresseLigne2;
      this.succursale.adresse_ligne3         = this.entiteForm.value.adresse.adresseLigne3;
      this.succursale.ville                  = this.entiteForm.value.adresse.adresseVille;
      this.succursale.code_postal            = this.entiteForm.value.adresse.adresseCP;

      this.succursale.telephone              = this.phoneDirective.getPhoneNumberWithoutFormat(this.entiteForm.value.telephone);
      this.succursale.fax                    = this.entiteForm.value.fax;
      this.succursale.mail_facturation       = this.entiteForm.value.mail_facturation;
      this.succursale.mail_statistique       = this.entiteForm.value.mail_statistique;
      this.succursale.mail_contact           = this.entiteForm.value.mail_contact;

      // La TVA et le compte de tiers ne sont pas configurable par ce formulaire,
      // car il s'agit d'informations que seul la FFEA peut et doit compléter
      // via le SAS d'Inscription  

      this.succursale.EstActif                = this.entiteForm.value.active;

      //on doit inscrire la nouvelle succursale pour la voir dans la liste:
      
      //on prend en compte la tva de l'entité mère:
      this.succursale.EstInscrit = true
      const tva  = {"id":this.entite.tva? this.entite.tva.id : 0
                    ,"libelle":this.entite.tva? this.entite.tva.libelle : ''
                    ,"pourcentage" : this.entite.tva? this.entite.tva.pourcentage : ''}
      this.succursale = {...this.succursale,tva}
      // On crée la succursale
      this.apiEntite.postEntite(this.succursale, this.utl_profil.id)
        .subscribe((data: Entite) => {
          this.succursale = data;
            this.toast.showInfo('Succursale créé.');

            // On met à jour les relations utilisateurs / entités puisqu'on a une nouvelle succursale 
            this.apiRelUtilisateurEntite.getRelUtilisateurEntite(this.utl_profil.id, 0)
              .subscribe((relutlent: RelationUtilisateurEntite[]) => {
                this.apiAuth.relUserEnt = relutlent;

                this.cellClicked.emit({
                  acces_concentrateur_actif         : this.acces_concentrateur_actif,
                  creer_succursale                  : this.creer_succursale,
                  entite_est_succursale             : this.entite_est_succursale,
                  est_dirigeant_principal           : this.est_dirigeant_principal,
                  utl_profil                        : this.utl_profil,
                  entite                            : this.succursale,
                  ent_id                            : this.ent_id,
                  RelUtilisateurEntite              : this.RelUtilisateurEntite,
                  acces_concentrateur               : this.acces_concentrateur,
                  succursales                       : this.succursales,
                  display                           : true,
                  disabled_informations_generales   : true,
                  disabled_liste_collaborateur      : false,
                  disabled_invitation_collaborateur : true
                });

                this.waiting_save = false;
              });      
          },
          (error: any) => { 
            console.log('/!\\ err post entite: ' + error);
            this.toast.showError('Erreur lors de la création de la succursale.');
            this.waiting_save = false;
          }
        );
    } else {
      // Cas d'une modification de l'entité
      this.entite.id                    = this.entiteForm.value.id;
      this.entite.id_entite_principale  = this.entiteForm.value.id_entite_principale;
      this.entite.raison_sociale        = this.entiteForm.getRawValue().raison_sociale;
      this.entite.indice                = this.entiteForm.getRawValue().indice;
      this.entite.id_type_entite        = this.entiteForm.getRawValue().id_type_entite;

      this.typeEntites.find(typeEntite => {
         if(typeEntite.id === this.entiteForm.getRawValue().id_type_entite) {
          this.entite.typ_ent_nom = typeEntite.libelle;
         }
      });
      
      this.entite.habilitation          = this.entiteForm.getRawValue().habilitation;
      this.entite.siren                 = this.entiteForm.getRawValue().siren;
      this.entite.siret                 = this.entiteForm.getRawValue().siret;    
      
      this.entite.adresse               = this.entiteForm.value.adresse.adresseLigne1;
      this.entite.adresse_cplt          = this.entiteForm.value.adresse.adresseLigne2;
      this.entite.adresse_ligne3        = this.entiteForm.value.adresse.adresseLigne3;
      this.entite.ville                 = this.entiteForm.value.adresse.adresseVille;
      this.entite.code_postal           = this.entiteForm.value.adresse.adresseCP;
      this.entite.idAdresse             = this.entiteForm.value.idAdresse;

      this.entite.telephone             = this.phoneDirective.getPhoneNumberWithoutFormat(this.entiteForm.value.telephone);
      this.entite.fax                   = this.entiteForm.getRawValue().fax;
      this.entite.mail_facturation      = this.entiteForm.getRawValue().mail_facturation;
      this.entite.mail_statistique      = this.entiteForm.getRawValue().mail_statistique;
      this.entite.mail_contact          = this.entiteForm.getRawValue().mail_contact;
      
      // La TVA et le compte de tiers ne sont pas configurable par ce formulaire,
      // car il s'agit d'informations que seul la FFEA peut et doit compléter
      // via le SAS d'Inscription      
      
      this.entite.EstActif            = this.entiteForm.value.active;
      
      // On enregistre les modifications
      this.apiEntite.putEntite(this.entite)
      .subscribe((data: any) => {
        if(bAppelPourDesactivation) {
          if(this.entite.EstActif) {
            this.toast.showInfo('L\'entité est activée');
          } else {
            this.toast.showInfo('L\'entité est désactivée');
          }          
        } else {
          this.toast.showInfo('Informations générales enregistrées');
        }
          
          this.chargeData();
          this.waiting_save = false;
        },
        (error: any) => { 
          console.log('/!\\ err put entite: ' + error);
          this.toast.showError('Erreur lors de l\'enregistrement');
          this.waiting_save = false;
        }
      );
    }
  } 

  onClickDelete() {    
    this.entite.logo = '';

    this.apiEntite.uploadLogo(this.ent_id, this.entite)
          .subscribe( 
          (data) => {                              
            this.toast.showInfo("Logo de l'entité supprimé")
            this.chargeData();
          },
          (err) => { '/!\\ error deleteLogo: ' + console.log(err)});        
  }

  onClickUpload() {
    const dialogRef = this.dialog.open(DialogLogoEntiteComponent);  
    dialogRef.afterClosed()
    .subscribe(
      (data: boolean) => { 
        if (data && dialogRef.componentInstance.croppedImage != '') {
  
          this.entite.logo = dialogRef.componentInstance.croppedImage;
          this.apiEntite.uploadLogo(this.ent_id, this.entite)
          .subscribe( 
          (data) => {             
            this.toast.showInfo("Logo de l'entité mis à jour")
            this.chargeData();
          },
          (err) => { '/!\\ error uploadLogo: ' + console.log(err)});  
        }
      },
        (err: any) => { 
          '/!\\ error dialogPhotoProfil: ' + console.log(err);
      }
    );  
  }

  // Gere l'activation et la désactivation
  onClickDisableEnableSuccursale(isActive :boolean) :void {
    this.entiteForm.value.active = isActive;
    this.enregistreData(true);
  }

  // Sélection d'un type entité
  SelectionneTypeEntite(event: any) { 
    this.typeEntiteSelected = event.value;
    
    this.typeEntites.find(type => {
      if(type.id === this.typeEntiteSelected) {
        this.typeEntiteCode = type.code;
      }
    });
   }

  isSuccursale() : boolean {
    let typeEntite :any = this.typeEntites.find(typeEntite => typeEntite.id == this.entite.id_type_entite);
    
    if (typeEntite != undefined && typeEntite.code == this.apiEntite.CONST_CODE_TYPE_ENTITE_SUCCURSALE) {
      return true
    } 

    return false;
  } 
  // Controle du format de téléphone : +33 4 00 00 00 00 / 04 00 00 00 00
  checkNumberPhone(control: FormControl) {
    let regExp = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
    if (control.value === '') { return null};
    return (regExp.test(control.value) ? null : { erreur: 'Le format du numéro de téléphone est incorrect' });
  }
    
  checkLengthSIREN(control: FormControl ) : ValidationErrors | null  {
    let siren: string = control.value;
   
    // Cas de base : aucun siren n'est renseigné
    if(siren.length == 0 || siren == undefined) {      
      return { erreur:  'Le numéro SIREN est obligatoire' }
    } else {
      // Cas de base : le siren ne respecte pas le nombre de caractère
      // SIREN = 8 chiffres + 1 chiffre de contrôle
      if(siren.length != 9) {
        return { erreur:  'Le numéro SIREN ne respecte pas le format suivant : 9 chiffres' }
      } else {
        // Donc le SIREN est un numérique à 9 chiffres

        let somme : number = 0;
        let tmp   : any;

        for(let cpt = 0; cpt < siren.length; cpt++){

          // Les positions paires : 2ème, 4ème, 6ème et 8ème chiffre
          if((cpt % 2) == 1) {
            tmp = +siren.charAt(cpt) * 2; // On le multiplie par 2

            // Si le résultat est supérieur à 9, on lui soustrait 9
            if(tmp > 9) {
              tmp -= 9; 
            }
          } else {
            tmp = +siren.charAt(cpt);
          }
                    
          somme += parseInt(tmp);          
        }

        if((somme % 10) == 0) {
          return null; // Si la somme est un multiple de 10, alors le SIREN est valide
        } else {
          return { erreur:  'Le numéro SIREN est invalide' }
        }
      }
    }    
  }
}
