<div class="chart-col">
    <ngx-charts-bar-horizontal-normalized        
        [scheme]="'cool'"
        [results]="chartData"
        [xAxis]="true"
        [legend]="true"
        [legendTitle]="'Légende'"
        [legendPosition]="legendPosition"
        [showXAxisLabel]="true"
        [xAxisLabel]="'Répartition des critères de dangerosité'"
        [animations]="false">
    </ngx-charts-bar-horizontal-normalized>
</div>