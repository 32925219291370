<mat-card appearance="outlined">
    <mat-card-header>
        <mat-card-title>Sélectionner votre filtre</mat-card-title>
    </mat-card-header>
    <mat-card-content class="mat-card-content">
    
        
        <mat-form-field>
            <mat-label>Numéro de facture contient</mat-label>
            <input  type        = "text" 
                    matInput 
                    [(ngModel)] = "filterNumFacture"
                    (keyupX)     = "setFilter.click()">
                    
        </mat-form-field>

        <mat-form-field class="formDate">
            <mat-label>Factures à partir de</mat-label>
            <input  matInput 
                    type ="date"
                    [(ngModel)]="filterDateDebut"
                    (changeX)="setFilter.click()">
                    
        </mat-form-field>

        <mat-form-field class="formDate">
            <mat-label>Factures jusqu'au</mat-label>
            <input  matInput  
                    type="date"
                    [(ngModel)]="filterDateFin"
                    (changeX)="setFilter.click()"
                    >
                    
        </mat-form-field>

        <mat-form-field class="etat">
            <mat-label>États sélectionnés</mat-label>
            <mat-select multiple  [(ngModel)]="etatsSelected" (selectionChangeX)="setFilter.click()">
                <mat-option *ngFor="let etat of etats; let indice = index" [value]="etat.id">
                    {{ etat.libelle }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </mat-card-content>
    <mat-card-actions  align="end">
        <button mat-raised-button color="primary" (click)="selectFilter()"><mat-icon>search</mat-icon>Rechercher</button>
        <button style="display: none;" #setFilter (click)="selectFilter()"></button>
    </mat-card-actions>
</mat-card>
