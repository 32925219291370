import { NgIf, NgFor } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { TypeFacturation } from 'src/app/interfaces/profil-user/type-facturation';

@Component({
  selector: 'app-entite-administration',
  templateUrl: './entite-administration.component.html',
  styleUrls: ['./entite-administration.component.scss'],
  standalone: true,
  imports: [MatCardModule, MatIconModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatProgressSpinnerModule, MatButtonModule, NgIf, MatSelectModule, NgFor]
})

export class EntiteAdministrationComponent implements OnInit, OnChanges {

  @Input() entite               : Entite = <Entite>{};

  waiting_save : Boolean = false;
  typesFacturation : Array<TypeFacturation> = new Array<TypeFacturation>();

   // description d'un schema de validation de données
   administrationEntiteForm = this.fb.group({
    compteTiers     : new FormControl<string>('',[Validators.required]),
    typeFacturation : new FormControl<TypeFacturation|null>(null,[Validators.required])
  })

  get compteTiers()     { return this.administrationEntiteForm.get('compteTiers'); }
  get typeFacturation() { return this.administrationEntiteForm.get('typeFacturation'); }

  constructor(
    private fb        : FormBuilder,
    private apiEntite : ApiEntiteService,
    private toast     : PersoSnackbarService) { 
  }

  ngOnChanges(): void {
    this.initForm();
  }

  ngOnInit(): void {

    //Chargement de la liste des types de facturation
    this.apiEntite.getTypesFacturation().subscribe(
      (data: TypeFacturation[] ) => {
        this.typesFacturation = data;
        this.initForm();
      },
      (err) => { console.error('/!\\ error getTypesFacturation: ' + err); }
    )
  }

  private initForm() : void {
    if (this.entite != undefined) {
      this.administrationEntiteForm.patchValue({
        compteTiers     : this.entite.compteTiers,
        typeFacturation : this.typesFacturation.find((element) => ( element.id_type_facturation == this.entite.id_type_facturation ))
      });
  
      this.administrationEntiteForm.updateValueAndValidity();
    }
  }

  public enregistreData() : void {
    this.waiting_save = true;

    if (this.compteTiers!.value != null) {
      this.entite.compteTiers =  this.compteTiers!.value;
    }

    this.entite.id_type_facturation = this.typeFacturation?.value?.id_type_facturation;

    // On enregistre les modifications
    this.apiEntite.putEntite(this.entite)
    .subscribe((data: any) => {
        this.toast.showInfo('Informations enregistrées');
        this.waiting_save = false;
      },
      (error: any) => { 
        console.log('/!\\ err put entite: ' + error);
        this.toast.showError('Erreur lors de l\'enregistrement');
        this.waiting_save = false;
      }
    );

  }
}
