<form [formGroup]="invitationForm" *ngIf="estDirigeant || estAdministrateur || est_dirigeant_principal">
    <mat-card appearance="outlined">
        <mat-card-title>
            <div class="div-container-left">
                <mat-icon color="primary">mail</mat-icon>
                <span>Inviter un collaborateur</span>
            </div>
        </mat-card-title>
        <mat-card-content class=".mat-card-content">                                                                            
                    <mat-form-field>
                        <mat-label for="nom">Nom</mat-label>
                        <input  matInput 
                                type            = "text" 
                                id              = "nom" 
                                name            = "nom" 
                                formControlName = "nom" 
                                oninput         = "this.value = this.value.toUpperCase()"
                                >
                            <mat-error *ngIf="nom?.invalid && (nom?.dirty || nom?.touched)">{{ nom?.errors?.erreur }}</mat-error>
                    </mat-form-field>
                    
                    <mat-form-field>
                        <mat-label for="prenom">Prénom </mat-label>
                        <input appTitleCase matInput type="text" id="prenom" name="prenom" formControlName="prenom" placeholder="Prénom">
                        <mat-error *ngIf="prenom?.invalid && (prenom?.dirty || prenom?.touched)">{{ prenom?.errors?.erreur }}</mat-error>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label for="email">Email </mat-label>
                        <input matInput type="email" id="email" name="email" formControlName="email" placeholder="Email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">                        
                        <mat-error *ngIf="email?.invalid && (email?.dirty || email?.touched)">Le format de l'adresse email est incorrect</mat-error>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Rôle</mat-label>
                        <mat-select name="groupe" formControlName="groupe" required>
                            <mat-option *ngFor="let groupe of groupes" [value]="groupe.id" >
                                {{ groupe.nom }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                 
                    <mat-slide-toggle formControlName="dirigeant" color="primary" style="margin-right: 20px;">  Est dirigeant</mat-slide-toggle>

                    <mat-slide-toggle formControlName="invSuccursales" color="primary" *ngIf="entite?.typ_ent_code!='SUCCURSALE'">  Inviter pour toutes les succursales</mat-slide-toggle>

        </mat-card-content>
        <mat-card-actions>
            <div class="button" >                    
                <button (click)="inviterCollaborateur()" 
                        mat-raised-button color="primary" 
                        [disabled]="invitationForm.pristine || !(invitationForm.valid && invitationForm.dirty)"
                        *ngIf = "isWaiting === false"
                        >
                    <mat-icon>send</mat-icon>                    
                    <span>Envoyer une invitation</span>
                </button>
                <mat-spinner 
                    *ngIf       = "isWaiting === true"
                    diameter    = "24"
                    >
                    </mat-spinner>
            </div>
        </mat-card-actions>
    </mat-card>
</form>
<app-shell [idEntite]="id_entite"></app-shell>