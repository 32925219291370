<mat-card appearance="outlined" class="mat-card-no-shadow" style="padding: 0px;">
    <form [formGroup]="FiltreFormGroup">
        <mat-expansion-panel id="expandFilter" [(expanded)]="ExpandPannel" #expandFilter>
            
            <mat-expansion-panel-header style="background-color: #fff; padding-top: 3px; padding-bottom: 3px;">
                <mat-panel-title class="label-accent">
                    <mat-icon>search</mat-icon>Veuillez saisir une immatriculation et les éventuelles options de votre recherche...
                </mat-panel-title>
            </mat-expansion-panel-header>
            
            <mat-form-field>
                <mat-label>Immatriculation</mat-label>
                <input matInput type="text" id="immatriculation" formControlName="immatriculation" oninput="this.value = this.value.toUpperCase()" required appImmat>
                <mat-hint>Formats autorisés : AA-123-AA / 9999ZZZ99</mat-hint>
                <mat-error *ngIf="immatriculation?.invalid && (immatriculation?.dirty || immatriculation?.touched)">{{immatriculation?.errors?.erreur }}</mat-error>
            </mat-form-field>  
            
            <mat-form-field>
                <mat-label>Réparateur</mat-label>
                <mat-select (selectionChange)="onSelectEntite($event)" name="entite" id="entite" formControlName="entite">
                    <mat-option *ngFor="let entite of entites" [value]="entite">
                        {{ entite.raison_sociale }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-action-row>    
                <div class="div-container-between" style="padding: 8px;">
                    <div class="div-container-center" style="width:100%; display: flex; justify-content: center;">
                        <button mat-flat-button 
                                *ngIf       = "!waitingResearch"
                                submit 
                                color       = "primary" 
                                [disabled]  = "waitingResearch || !FiltreFormGroup.valid" 
                                (click)     = "onSearchingFile()">
                            <mat-icon>search</mat-icon>Rechercher                                    
                        </button>
                        <mat-spinner diameter="24" *ngIf="waitingResearch"></mat-spinner>
                    </div>
                </div>
            </mat-action-row>
        </mat-expansion-panel>
    </form>
</mat-card>