
<div>
    <div [ngSwitch]="data.type">
        <h3 *ngSwitchCase = 1 mat-dialog-title><mat-icon color="primary">info</mat-icon>Confirmation</h3>
        <h3 *ngSwitchCase = 2 mat-dialog-title><mat-icon color="warn">warning</mat-icon>Avertissement</h3>
    </div>
</div>
<mat-dialog-content>
    <div class="div-center">
        <!-- <p [innerHTML]="data.message"></p> -->
        <p>{{data.message}}</p>
    </div>
  
</mat-dialog-content>
<mat-dialog-actions align="center">
    <div class="div-center">
        <button mat-raised-button [mat-dialog-close]="true" cdkFocusInitial 
        color="primary">Oui</button>
        <button mat-raised-button [mat-dialog-close]="false" 
        color="basic">Non</button>
        <button mat-raised-button [mat-dialog-close] ="undefined"
        *ngIf="data.yes_no_cancel == 3"
        color="basic">Annuler</button>
    </div>
</mat-dialog-actions>
