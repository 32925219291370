import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable,of } from 'rxjs';
import { tap,catchError } from 'rxjs/operators';
import { ApiAuthentificationService } from '../api-authentification.service';
import { PersoSnackbarService } from '../perso-snackbar.service';
import { Categorie } from 'src/app/interfaces/support/categorie';
import { ApiErrorService } from './api-error.service';

@Injectable({
  providedIn: 'root'
})
export class ApiCategorieService {
  url: string = '/api';


  constructor(private http          : HttpClient
              , private apiError    : ApiErrorService) { }

  // 1 - CATEGORIES

  //1.1 - Select
  public getCategories():Observable<Categorie[]>{
    return this.http.get<Categorie[]>(this.url+"/support/categorie")
                    .pipe(catchError(this.apiError.handleError<Categorie[]>('getCategories',[])));
  }

  //1.2 - Update
  public putCategorie(categorie:Categorie):Observable<Categorie>{
    return this.http.put<Categorie>(this.url+"/support/categorie", categorie)
                    .pipe(catchError(this.apiError.handleError<Categorie>('putCategorie')));
  }

  //1.3 - Insert
  public postCategorie(categorie:Categorie):Observable<Categorie>{
    return this.http.post<Categorie>(this.url+"/support/categorie", categorie)
                    .pipe(catchError(this.apiError.handleError<Categorie>('postCategorie')));
  }

  //1.4 - Delete
  public deleteCategorie(idCategorie:number): any {    
    return this.http.delete(this.url+"/support/categorie/"+idCategorie)
                    .pipe(catchError(this.apiError.handleError<boolean>('deleteCategorie',false)));
  }

}
