import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Article } from 'src/app/interfaces/commande/article';
import { FiltreRechercheArticle } from 'src/app/interfaces/commande/article/filtre-recherche-article';
import { Fournisseur } from 'src/app/interfaces/commande/fournisseur/fournisseur';
import { Categorie } from 'src/app/interfaces/phrasier/categorie';
import { ApiArticleService } from 'src/app/services/api-article.service';
import { ApiFournisseurService } from 'src/app/services/api-fournisseur.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgIf, NgFor } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { ContexteService, TYPE_ARTICLE, TYPE_UTILISATEUR } from 'src/app/services/contexte.service';

export interface OutPutRechercheArticles{
  articles: Array<Article>
}
@Component({
    selector: 'app-filtre-articles',
    templateUrl: './filtre-articles.component.html',
    styleUrls: ['./filtre-articles.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatExpansionModule, MatIconModule, MatFormFieldModule, MatInputModule, NgIf, MatSelectModule, NgFor, MatOptionModule, MatButtonModule, MatProgressSpinnerModule]
})
export class FiltreArticlesComponent implements OnInit {

  // Gestion de l'affichage
  waitingResearch       : boolean = false;
  expandPannel          : boolean = true;

  // Interface des filtres de recherche des articles
  filtre_recherche      : FiltreRechercheArticle  = <FiltreRechercheArticle>{};
  categories            : Array<Categorie>        = Array<Categorie>();
  fournisseurs          : Array<Fournisseur>      = Array<Fournisseur>();

  // Formulaire des filtres de recherche
  FiltreFormGroup       : FormGroup;
  readonly type      : string = TYPE_ARTICLE

  // Accesseur en lecture des controls du formulaire
  get reference()       { return this.FiltreFormGroup.get('reference'); }
  get description()     { return this.FiltreFormGroup.get('description'); }
  get categorie()       : FormArray  { return <FormArray>this.FiltreFormGroup.get('categorie') as FormArray; }
  get fournisseur()     : FormArray  { return <FormArray>this.FiltreFormGroup.get('fournisseur') as FormArray; }
  get statut()          { return this.FiltreFormGroup.get('statut'); }

  // Gestion de l'évènement recherche dossier
  @Output() research = new EventEmitter<OutPutRechercheArticles>();

  constructor(private fb              : FormBuilder
            , private apiArticle      : ApiArticleService
            , private apiFournisseur  : ApiFournisseurService
            , private toast           : PersoSnackbarService
            , private contexte         : ContexteService) {
    // Initialisation du formulaire des filtres de recherche
    this.FiltreFormGroup = this.fb.group({
      reference     : '',
      description   : '',
      categorie     : [Array<Categorie>(), [Validators.required]],
      fournisseur   : [Array<Fournisseur>(), [Validators.required]],
      statut        : 'ALL'
    });

        // Subscribe here, this will automatically update 
        // "isDataModify" whenever a change to the subject is made.
        this.apiArticle.isDataModify.subscribe( (value: boolean) => {
          if(value) {
            this.apiArticle.isDataModify.next(false);
            this.onSearchArticle();
          }
      });
  }

  ngOnInit(): void {
    this.getCategories();
    this.getFournisseurs();
    this.research.emit({
      articles: this.contexte.getTableData(this.type)            
    });
    this.initFilter()
  }

  initFilter(){
    const filtre = this.contexte.getFiltre(this.type)
    if (filtre!=null) {
      this.FiltreFormGroup.patchValue(filtre)
    }
  }

  // Chargement des catégories d'article
  public getCategories(): void {
    this.apiArticle.getCategories()
    .subscribe(
      (data: Categorie[])  => { 
       this.categories = data;
       this.FiltreFormGroup.patchValue({
        categorie: this.categories
       });
     },
     (err: any) => {
       '/!\\ error getCategories: ' + console.log(err);
     }
   );
  }

  // Chargement des fournisseurs d'article
  public getFournisseurs(): void {
    this.apiFournisseur.getSupplier()
    .subscribe(
      (data: Fournisseur[]) => {
        this.fournisseurs = data;
        this.FiltreFormGroup.patchValue({
          fournisseur: this.fournisseurs
        });
      },
      err => { 
        '/!\\ error getFournisseurs: ' + console.log(err);
      }
    );
  }

  // Recherche des articles
  public onSearchArticle(): void {
    this.filtre_recherche               = <FiltreRechercheArticle>{};
    this.filtre_recherche.reference     = this.FiltreFormGroup.controls.reference.value;
    this.filtre_recherche.description   = this.FiltreFormGroup.controls.description.value;
    this.filtre_recherche.categories    = this.FiltreFormGroup.controls.categorie.value;
    this.filtre_recherche.fournisseurs  = this.FiltreFormGroup.controls.fournisseur.value;
    this.filtre_recherche.statut        = this.FiltreFormGroup.controls.statut.value;
    
    this.waitingResearch = true;
    
    this.apiArticle.postSearchingArticle(this.filtre_recherche)
      .subscribe(
        (articles: Article[]) => {
          
          // Mise en place de la position, qui sera utilisé pour sélectionner dans le tableau des résultats
          for(let i = 0; i < articles.length; i++) {
            articles[i].position = i + 1;
          }

          this.research.emit({
            articles: articles            
          });          

          this.toast.showInfo("Recherche terminée");
          this.waitingResearch = false;
        },
        (err: any) => {
          this.research.emit({
            articles: Array<Article>()            
          });

          '/!\\ error getItems: ' + console.log(err);
          this.toast.showError(err.error);
          this.waitingResearch = false;
        }
      );
  }
  ngOnDestroy(){
    this.contexte.setType(this.type)
    this.contexte.setFiltre(this.FiltreFormGroup.value) 
  }


}
