import { Component, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { Mail } from '../../mail-interface';
import { DatePipe, NgIf } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { convertCustomDateToISO } from 'src/app/date-util';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-mail-list',
  templateUrl: './mail-list.component.html',
  styleUrls: ['./mail-list.component.scss'],
  standalone:true,
  imports: [
    ReactiveFormsModule,  MatInputModule, MatSelectModule,  MatButtonModule,  MatDatepickerModule, MatCheckboxModule,
    MatNativeDateModule,  MatFormFieldModule, MatTableModule, MatIconModule,  MatPaginatorModule, MatSortModule,DatePipe
    , MatCardModule, NgIf
  ],
})
export class MailListComponent {
  @Input()  mails           : Mail[]    = [];
  @Input() resetDone        : boolean   = true;
  @Output() selectedMail    = new EventEmitter<Mail>();
  @Output() selectedIdMail  = new EventEmitter<string>()

  displayedColumns  : string[] = ['ml_statut', 'ml_type_envoi', 'ml_date_demande', 'ml_destinataire', 'ml_sujet', 'actions', 'selection'];
  dataSource        : MatTableDataSource<Mail> = new MatTableDataSource();
  // selectedMails     : Mail[] = []; // Tableau pour stocker les mails sélectionnés
  selectedMailIds   : Set<number> = new Set(); // Utilisation d'un Set pour stocker les ml_id sélectionnés

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChildren(MatCheckbox) checkboxes!: QueryList<MatCheckbox>; // Utilisé pour accéder aux cases à cocher

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnChanges() {
    // Convertir le format de date personnalisé en format ISO pour chaque mail
  this.dataSource.data = this.mails.map(mail => {
    return {
      ...mail,
      ml_date_demande: convertCustomDateToISO(mail.ml_date_demande),
      ml_date_envoi: convertCustomDateToISO(mail.ml_date_envoi)
    };
  });

  // Vérifie si les mails doivent être réinitialisés
  if (this.resetDone === true) {
    // Effacer la sélection de mails
    this.selectedMailIds.clear();
    if (this.checkboxes) {
      this.checkboxes.forEach((checkbox) => (checkbox.checked = false));
    }
  } else {
    // Si `resetDone` est false, maintenir l'état des cases à cocher en fonction de `selectedMailIds`
    if (this.checkboxes) {
      this.checkboxes.forEach((checkbox, index) => {
        const mail = this.dataSource.data[index];
        checkbox.checked = this.selectedMailIds.has(mail.ml_id);
      });
    }
  }
    
  }

  getStatus(status: number | null): string {
    switch (status) {
      case -1: return 'Erreur';
      case 0: return 'Pas envoyé';
      case 1: return 'Envoyé';
      default: return 'Inconnu';
    }
  }

  getTypeEnvoi(type: number | null): string {
    switch (type) {
      case 1: return 'Immédiat';
      case 2: return 'Différé';
      default: return 'Inconnu';
    }
  }

  // Méthode pour vérifier si un mail est sélectionné
  isSelected(mail: Mail): boolean {
    return this.selectedMailIds.has(mail.ml_id);
  }

  selectMail(mail: Mail) {
    this.selectedMail.emit(mail);
  }

  // Méthode pour gérer la sélection des mails
  toggleSelection(mail: any) {
    
    if (this.selectedMailIds.has(mail.ml_id)) {
      this.selectedMailIds.delete(mail.ml_id); // Supprimer l'ID du Set si déjà sélectionné
    } else {
      this.selectedMailIds.add(mail.ml_id); // Ajouter l'ID au Set si non sélectionné
    }
  }

   // Méthode pour vérifier s'il y a au moins un mail sélectionné
   isAnyMailSelected(): boolean {
    // return this.selectedMails.length > 0;
    return this.selectedMailIds.size > 0;
  }

  resetSelectedMails(){
    // Générer la liste des IDs des mails sélectionnés sous forme de chaîne de caractères
    const IdMailList: string = Array.from(this.selectedMailIds).join(',');

    // Utiliser la chaîne d'IDs comme avant
    this.selectedIdMail.next(IdMailList);

    // Réinitialiser la sélection après l'envoi des mails
    this.selectedMailIds.clear(); // Vide le Set des mails sélectionnés
    if (this.checkboxes) {
      this.checkboxes.forEach((checkbox) => (checkbox.checked = false)); // Désélectionne toutes les cases à cocher visibles
    }
  }
}
