import { Component, OnInit } from '@angular/core';
import { ServiceExterne } from 'src/app/interfaces/services-ffea/service-externe';
import { ApiUtilitaireService } from 'src/app/services/tools/api-utilitaire.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-recherche-status',
    templateUrl: './recherche-status.component.html',
    styleUrls: ['./recherche-status.component.scss'],
    standalone: true,
    imports: [NgIf, MatToolbarModule, MatIconModule, MatFormFieldModule]
})
export class RechercheStatusComponent implements OnInit {

  actif: boolean = true;
  services_externe: Array<ServiceExterne> = Array<ServiceExterne>();

  constructor(private apiUtilitaire : ApiUtilitaireService) { }

  ngOnInit(): void {
    this.getServicesExterne(this.apiUtilitaire.CONST_CODE_ENVIRONNEMENT_SERVICES_FFEA);
  }

  getServicesExterne(code_environnement: string) {
    this.apiUtilitaire.getServiceExterne(code_environnement)
      .subscribe(
        (data: ServiceExterne[]) => {
          this.services_externe = data;
          this.getLibelleStatus();
        },
        (err: any) => {
          '/!\\ error getServiceExterne: ' + console.log(err);
        }
      );
  }

  getLibelleStatus(): string {    
    let status      : string = '';
    let nb_desactive: number = 0;

    for(let i = 0; i < this.services_externe.length; i++) {
      if(!this.services_externe[i].actif) {        
        nb_desactive++        
        this.actif = false;
      }
    }

    if(!this.actif) {
      for(let i = 0; i < this.services_externe.length; i++) {
        if(!this.services_externe[i].actif) {        
          switch (nb_desactive) {
            case 1 : 
              status = this.services_externe[i].libelle;
              break;
            default : 
              if(i == this.services_externe.length-1) {
                status += this.services_externe[i].libelle;
              } else {
                status += this.services_externe[i].libelle + ', '
              }
              break;      
          }
        }
      }
  
      switch (nb_desactive) {
        case 0 : return status
        case 1 : return 'La recherche sur le service externe ' + status + ' est désactivée.'
        default : 
          return 'La recherche sur les services externes ' + status + ' est désactivée.'
  
      }  
    } else {
      return '';
    }
    
  }
}
