import { Injectable } from '@angular/core';
import {  Observable,of } from 'rxjs';
import { PersoSnackbarService } from '../perso-snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class ApiErrorService {

  constructor(private toast       : PersoSnackbarService) { }

  public handleError<T>(operation = 'operation', result?: T) {
    
    return (error: any): Observable<T> => {
      this.toast.showError(error.error);  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
