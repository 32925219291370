import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InscriptionService {
  url = '/api/hc/inscription'
  dataEntite:any = null

  constructor(private http: HttpClient) { }

  saveInscription(inscription:any):Observable<number>{
    return this.http.post<number>(this.url,inscription).pipe(
      timeout(120000), // Timeout après 2 min
      catchError(error => {
        // Gérer l'erreur de timeout
        return throwError('La requête a dépassé le temps limite');
      })
    )
  }

  memoriseData(data:any){
    this.dataEntite = data
  }
}
