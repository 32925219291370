<mat-card appearance="outlined">
    <mat-card-title>Résultat de l'analyse</mat-card-title>

    <mat-card-content *ngIf="etat.code === this.const_etat_en_cours_diagnostic || etat.code === this.const_etat_cloture">
        <div *ngIf="!form_bsm">
            <p class="MargeMoyenne"></p>
            <div class="content">
                <div class="titlebox">Résultats Physico-Chimique</div>
                
                <div class="listeValeur">
                    <mat-form-field *ngFor="let valeur_resultat of resultat_categorie_physicochimique.tabst_ValeurResultat" class="formFieldValeur">
                        <mat-label>{{valeur_resultat.st_TypeValeurResultat.libelle}}</mat-label>
                        <input matInput type="text" [value]="valeur_resultat.valeur" oninput="this.value = this.value.toUpperCase()" readonly>        
                    </mat-form-field>
                </div>
            </div>
        </div>
        
        <div *ngIf="form_bsm">
            <p class="MargeMoyenne"></p>
            <div class="content">
                <div class="titlebox">Résultats Physico-Chimique</div>
                <div class="listeValeur">
                    <mat-form-field *ngFor="let valeur_resultat of resultat_categorie_physicochimique_bsm.tabst_ValeurResultat" class="formFieldValeur">
                        <mat-label>{{valeur_resultat.st_TypeValeurResultat.libelle}}</mat-label>
                        <input matInput type="text" [value]="valeur_resultat.valeur" oninput="this.value = this.value.toUpperCase()" readonly>        
                    </mat-form-field>
                </div>
            </div>
        </div>

        <p class="MargeMoyenne"></p>
        <div class="content">
            <div class="titlebox">Résultats Spectrométrique</div>
            <div class="listeValeur">
                <mat-form-field *ngFor="let valeur_resultat of resultat_categorie_spectrometrie.tabst_ValeurResultat" class="formFieldValeur">
                    <mat-label>{{valeur_resultat.st_TypeValeurResultat.libelle}}</mat-label>
                    <input matInput type="text" [value]="valeur_resultat.valeur" oninput="this.value = this.value.toUpperCase()" readonly>        
                </mat-form-field>
            </div>        
        </div>
        <div *ngIf="form_bsm">
            <p class="MargeMoyenne"></p>
            <mat-card-subtitle>Notation générale</mat-card-subtitle>
            <br>
            <div>
                <form [formGroup]="form_notation_bsm">
                    <mat-form-field>
                        <mat-label>Quantité de polluants issus de la combustion</mat-label>
                        <mat-select name="polluant_combustion" id="polluant_combustion" formControlName="polluant_combustion">                              
                            <mat-option *ngFor="let note of notation$" [value]="note">
                                    {{ note }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
        
                    <mat-form-field>
                        <mat-label>Quantité de polluants issus du système d'injection</mat-label>
                        <mat-select name="polluant_injection" id="polluant_injection" formControlName="polluant_injection">                              
                            <mat-option *ngFor="let note of notation$" [value]="note">
                                    {{ note }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
        
                    <mat-form-field>
                        <mat-label>Quantité de polluants issus de la filtration de l'air</mat-label>
                        <mat-select name="polluant_filtration" id="polluant_filtration" formControlName="polluant_filtration">                              
                            <mat-option *ngFor="let note of notation$" [value]="note">
                                    {{ note }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
        
                    <mat-form-field>
                        <mat-label>Quantité de polluants issus du circuit de refroidissement</mat-label>
                        <mat-select name="polluant_refroiddisement" id="polluant_refroiddisement" formControlName="polluant_refroidissement">                              
                            <mat-option *ngFor="let note of notation$" [value]="note">
                                    {{ note }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
        
                    <mat-form-field>
                        <mat-label>Quantité de polluants issus du haut-moteur</mat-label>
                        <mat-select name="polluant_haut_moteur" id="polluant_haut_moteur" formControlName="polluant_haut_moteur">                              
                            <mat-option *ngFor="let note of notation$" [value]="note">
                                    {{ note }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
        
                    <mat-form-field>
                        <mat-label>Quantité de polluants issus du bas-moteur</mat-label>
                        <mat-select name="polluant_bas_moteur" id="polluant_bas_moteur" formControlName="polluant_bas_moteur">                              
                            <mat-option *ngFor="let note of notation$" [value]="note">
                                    {{ note }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label><b>Note globale</b></mat-label>
                        <mat-select name="note_globale" id="note_globale" formControlName="note_globale">                              
                            <mat-option *ngFor="let note of notation_globale$" [value]="note.id">
                                <img [src]="note.chemin" class="img-note-globale"/>  {{ note.libelle }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<mat-card appearance="outlined">
    <mat-card-content class=".mat-card-content">
        <div>
            <p class="MargeMoyenne"></p>
            <mat-card-subtitle>Référence laboratoire</mat-card-subtitle>
            <br>
            <!-- Référence du dossier Vernolab -->
            <mat-form-field>
                <mat-label>Référence laboratoire</mat-label>
                <input matInput type="text" [value]="resultat_analyse.num_labo" oninput="this.value = this.value.toUpperCase()" readonly>        
            </mat-form-field>
        </div>

        <div>
            <p class="MargeMoyenne"></p>
            <mat-card-subtitle>Photos</mat-card-subtitle>
            <br>
            <!-- Photos envoyé par Vernolab dans les résultats d'analyse -->
            <mat-grid-list cols="3" rowHeight="1:1">
                <mat-grid-tile *ngFor="let photo of imageToShow">
                    <div class="photo-wrap">
                        <img [src]="_sanitizer.bypassSecurityTrustResourceUrl(photo)" class="photo-center"/>                        
                        <button mat-icon-button class="photo-button-zoom-in" (click)="onClickZoomIn(photo)"><mat-icon color="primary" class="icon-display-32">zoom_in</mat-icon></button>                        
                    </div>                                        
                </mat-grid-tile>
              </mat-grid-list>                          
        </div>
    </mat-card-content>
</mat-card>

<app-phrasier (newItemEvent)="addPhrasier($event)" *ngIf="showPhrasier" [showAllTable]="false"></app-phrasier>

<mat-card appearance="outlined">
    <mat-card-content class=".mat-card-content">                
        <div>            
            <p class="MargeMoyenne"></p>
            <mat-card-subtitle>        
                <button mat-flat-button *ngIf="etat.code === this.const_etat_en_cours_diagnostic" (click)="onClickPhrasier()"><mat-icon color="primary" class="icon-display-32">description</mat-icon></button>
                <span class="span-button">Phrasier</span>
            </mat-card-subtitle>
            <!-- Diagnostic + phrasier -->
            <form [formGroup]="PhrasierFormGroup">                                            
                <mat-form-field class="textarea" style="max-width:100%;">
                    <mat-label>Diagnostic</mat-label>
                    <textarea matInput formControlName="interpretation" (keyup)="autoGrowTextZone($event)" class="textarea"></textarea>
                </mat-form-field>

                <!-- Bouton d'action -->
                <tr style ="display: flex; justify-content: center">
                    <ng-container *ngIf="!(waitingSending || waitingModified || waitingClosed || waitingPreview)">
                        <td class="button-container" *ngIf="etat.code === this.const_etat_en_cours_diagnostic">                        
                            <button mat-flat-button 
                                    *ngIf       = "waitingModified"
                                    style       = "text-align: center;" 
                                    color       = "primary" 
                                    [disabled]  = "waitingModified || !PhrasierFormGroup.valid" 
                                    (click)     = "onEnregistrer()">
                                <mat-icon>save</mat-icon>
                                <span class="span-button">Enregistrer les modifications</span>
                            </button>
                        </td>
                
                        <td class="button-container" *ngIf="etat.code === this.const_etat_en_cours_diagnostic">                        
                            <button mat-flat-button 
                                    *ngIf       = "!waitingClosed"
                                    style       = "text-align: center;" 
                                    color       = "primary" 
                                    [disabled]  = "waitingClosed || !PhrasierFormGroup.valid" 
                                    (click)     = "onCloturer()">
                                <mat-icon>task</mat-icon>
                                <span class="span-button">Clôturer le dossier</span>
                            </button>
                        </td>
                        
                        <div class="div-container" *ngIf="etat.code === this.const_etat_cloture">
                            <td class="button-container">                        
                                <button mat-flat-button 
                                        style       = "text-align: center;" 
                                        color       = "primary" 
                                        [disabled]  = "waitingPreview" 
                                        (click)     = "onApercuRapport()">
                                    <mat-icon>download</mat-icon>
                                    <span class="span-button">Télécharger le rapport</span>
                                </button>
                            </td>
                            
                            <td class="button-container">                        
                                <button mat-flat-button 
                                        style       = "text-align: center;" 
                                        color       = "primary" 
                                        [disabled]  = "waitingSending" 
                                        (click)     = "onEnvoyerRapport()">
                                    <mat-icon>email</mat-icon>
                                    <span class="span-button">Recevoir le rapport</span>
                                </button>
                            </td>
                        </div>
                    </ng-container>
                    <mat-spinner diameter="24"  *ngIf="waitingSending || waitingModified || waitingClosed || waitingPreview"></mat-spinner>
                  </tr>
            </form>
        </div>                                
    </mat-card-content>
    
    <mat-card-content *ngIf="etat.code === this.const_etat_en_cours_analyse">
        <p class="MargeMoyenne">
            <i>Le dossier est en cours d'analyse, vous recevez une notification lorsque les résultats seront disponible.</i>
        </p>   
    </mat-card-content> 
</mat-card>
