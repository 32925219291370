<mat-card appearance="outlined" class="mat-card">
    <mat-card-title>Combien d'étiquettes souhaitez-vous imprimer ?</mat-card-title>
    <form [formGroup]="formGroupQuantite">
        <!-- <mat-form-field>
            <mat-label>Nombre d'étiquette</mat-label>
            <input matInput type="number" id="quantite" formControlName="quantite" min="1" max="100" step="1" required>
            <mat-error *ngIf="quantite?.invalid && (quantite?.dirty || quantite?.touched)">{{ quantite.errors?.erreur }}</mat-error>
        </mat-form-field> -->

        <mat-form-field>
            <mat-label>Nombre de copie</mat-label>
            <input matInput type="number" id="copie" formControlName="copie" min="1" max="3" step="1" required>
            <mat-error *ngIf="copie?.invalid && (copie?.dirty || copie?.touched)">{{ copie.errors?.erreur }}</mat-error>
        </mat-form-field>
        
        <div class="div-button-right">
            <button mat-raised-button color="warn" (click)="onDeclineImpression()" [mat-dialog-close]="false">Annuler</button>
            <button mat-raised-button style="margin-left: 10px;" color="primary" (click)="onAcceptImpression()" [mat-dialog-close]="true">Lancer l'impression</button>
        </div>
    </form>        
</mat-card>