import { Component, OnInit } from '@angular/core';
import { TypeDemande } from 'src/app/interfaces/support/type-demande';
import { TableTypeDemandeComponent } from '../table-type-demande/table-type-demande.component';
import { FiltreTypeDemandeComponent } from '../filtre-type-demande/filtre-type-demande.component';

export interface OutPutRechercheTypeDemande{
  type_demande: Array<TypeDemande>;
}
@Component({
    selector: 'app-fiche-liste-type-demande',
    templateUrl: './fiche-liste-type-demande.component.html',
    styleUrls: ['./fiche-liste-type-demande.component.scss'],
    standalone: true,
    imports: [FiltreTypeDemandeComponent, TableTypeDemandeComponent]
})
export class FicheListeTypeDemandeComponent implements OnInit {

  type_demande        : Array<TypeDemande> = Array<TypeDemande>();
  rafraichir_recherche: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  // Transmets les résultats de la recherche au component des résultats
  public onChildResearchTypeDemande(event: OutPutRechercheTypeDemande): void {
    this.type_demande = event.type_demande;
  }
}
