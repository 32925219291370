import { Component, OnInit } from '@angular/core';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { FiltreMcelComponent } from '../filtre-mcel/filtre-mcel.component';
import { MesStatistiquesComponent } from '../mes-statistiques/mes-statistiques.component';
import { NgIf } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';

@Component({
    selector: 'app-statistiques',
    templateUrl: './statistiques.component.html',
    styleUrls: ['./statistiques.component.scss'],
    standalone: true,
    imports: [MatTabsModule, NgIf, MesStatistiquesComponent, FiltreMcelComponent]
})
export class StatistiquesComponent implements OnInit {

  // Identifiant des l'utilisateur
  utl_profil: ProfilUtilisateur = <ProfilUtilisateur>{};
  
  constructor(private authUser: ApiAuthentificationService) { }

  ngOnInit(): void {
    this.utl_profil = this.authUser.userConnect;
  }

}
