<div class="div-center" style="width: 90%; vertical-align:top;">

  <!--  Partie formulaire recherche véhicule   -->
  <mat-card appearance="outlined" class="mat-card">
    <mat-card-subtitle style="text-align:center; font-size: x-large;">Identification du véhicule</mat-card-subtitle>

    <form [formGroup]="saisieForm$" (submit)="searchingVehicule()">
      <mat-tab-group animationDuration="0ms" mat-align-tabs="center">

        <mat-tab label="Recherche par immatriculation">
          <div class="div-container">
            <div style="width: 100%;"></div>
            <div class="div-container" style="width: 100%; height: 64px;">
              <img src="assets/img/plaque-immatriculation-gauche.png" height="42">
              <input matInput autofocus type="text" class="label-immatriculation" placeholder="AA-123-AA" name="immat"
                oninput="this.value = this.value.toUpperCase()" formControlName="immatriculation"
                style="width: 172px; text-align: center; font-size: xx-large ;">
              <img src="assets/img/plaque-immatriculation-droite.png" height="42">
            </div>
          </div>
        </mat-tab>

        <mat-tab label="Recherche par numéro VIN">
          <div class="div-container">
            <p style="margin: 35px;"></p>
            <input matInput style="text-align: center; font-size: xx-large ;" type="text" placeholder="XXXXXXXXXXXXXXXXX"
              name="vin" oninput="this.value = this.value.toUpperCase()"  maxlength="17" formControlName="vin">
            <p style="margin: 36px"></p>
          </div>
        </mat-tab>

      </mat-tab-group>
    </form>

    <div class="button-container">      
      <button mat-raised-button style="text-align: center; background-color: #005CAA; color: #fff;"
        [disabled]="waitingResearch" (click)="searchingVehicule()">
        <mat-icon>search</mat-icon>
        Rechercher
      </button>
    </div>        
  </mat-card>

  <div class="spinner-container" *ngIf="waitingResearch">
    <mat-spinner diameter="24"></mat-spinner>
  </div>

  <!-- CARACTERISTIQUE TECHNIQUE -->
  <mat-card  appearance="outlined"  class="mat-card-main" style="margin-bottom: 20px;">

    <mat-card appearance="outlined" *ngIf="historyVehicle$.immatriculation != undefined && historyVehicle$.immatriculation != '' || historyVehicle$.vin != undefined && historyVehicle$.vin != '' "
      class="mat-card-no-shadow">

      <mat-card-subtitle style="margin-top: 16px;">
        <mat-icon class="icon-display" style="height: 30px; vertical-align: top;">directions_car_filled</mat-icon>
        &nbsp;&nbsp;&nbsp; Caractéristiques techniques générales :
      </mat-card-subtitle>
      <mat-card-content>
        <mat-grid-list cols="12" rowHeight="50" style="margin-top: 20px;">
          <mat-grid-tile colspan="2">
            <mat-form-field *ngIf=" vehiculeFound || searchType === SEARCH_IMMAT">
              <mat-label for="immat">Immatriculation :</mat-label>
              <input matInput type="text" placeholder="Numéro d'immatriculation" name="vin"
                value="{{ historyVehicle$.immatriculation }}" readonly>
            </mat-form-field>
          </mat-grid-tile>
  
          <mat-grid-tile colspan="4">
            <mat-form-field *ngIf=" vehiculeFound || searchType === SEARCH_VIN">
              <mat-label for="vin">V.I.N: véhicule identifiant number :</mat-label>
              <input matInput type="text" placeholder="Numéro d'immatriculation" name="immat"
                value="{{ historyVehicle$.vin }}" readonly>
            </mat-form-field>
          </mat-grid-tile>
          
          <mat-grid-tile colspan="4">
            <mat-form-field *ngIf=" vehiculeFound ">
              <mat-label for="type_mine">Type MINE :</mat-label>
              <input matInput type="text" placeholder="Type MINE" name="immat" 
                value="{{ historyVehicle$.type_mine }}" readonly>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile colspan="2">
            <mat-form-field *ngIf=" vehiculeFound ">
              <mat-label for="mec">MEC :</mat-label>
              <input matInput type="text" placeholder="M.E.C" name="immat" 
                value="{{ historyVehicle$.mec| date : 'dd/MM/yyyy'  }}" readonly>
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
  
  
  
  
        <mat-accordion style="height: 64px;" *ngIf=" vehiculeFound ">
          <mat-expansion-panel>
  
            <mat-expansion-panel-header >
              <mat-panel-title><mat-icon color="primary">build</mat-icon></mat-panel-title>
              <mat-panel-description>plus d'information... (marque, modèle, ...)</mat-panel-description>
            </mat-expansion-panel-header>
  
              <mat-form-field>
                <mat-label for="date_certificat">Date du certificat : </mat-label>
                <input matInput type="text" placeholder="Date du certification"
                  name="date_certificat" value=" {{ historyVehicle$.date_certificat | date : 'dd/MM/yyyy' }}" readonly>
              </mat-form-field>
  
              <mat-form-field>
                <mat-label for="mec">Marque : </mat-label>
                <input matInput type="text" placeholder="Marque du véhicule" name="immat"
                  value=" {{ historyVehicle$.marque }} " readonly>
              </mat-form-field>
  
              <mat-form-field>
                <mat-label for="mec">Modèle :</mat-label>
                <input matInput type="text" placeholder="Modèle du véhicule" name="immat"
                  value="{{ historyVehicle$.modele }}" readonly>
              </mat-form-field>
  
              <mat-form-field>
                <mat-label for="mec">Puissance fiscale :</mat-label>
                <input matInput type="text" placeholder="Numéro d'immatriculation" name="immat"
                  value="{{ historyVehicle$.puissance_fiscale }}" readonly>
              </mat-form-field>
  
              <mat-form-field>
                <mat-label for="mec">genre :</mat-label>
                <input matInput type="text" placeholder="Numéro d'immatriculation" name="immat"
                  value="{{ historyVehicle$.genre }} " readonly>
              </mat-form-field>
  
              <mat-form-field>
                <mat-label for="mec">Formule :</mat-label>
                <input matInput type="text" placeholder="numéro de formule" name="formule"
                  value="{{ historyVehicle$.numero_formule }} " readonly>
              </mat-form-field>
  
              <mat-form-field>
                <mat-label for="mec">Énergie :</mat-label>
                <input matInput type="text" placeholder="Numéro d'immatriculation" name="immat"
                  value="{{ historyVehicle$.energie }}" readonly>
              </mat-form-field>
  
              <mat-form-field>
                <mat-label for="mec">Carrosserie :</mat-label>
                <input matInput type="text" placeholder="Numéro d'immatriculation" name="immat"
                  value="{{ historyVehicle$.carrosserie }}" readonly>
              </mat-form-field>
  
          </mat-expansion-panel>
        </mat-accordion>

      </mat-card-content>
    </mat-card>

    <!-- INCOHERENCE -->
    <mat-card appearance="outlined" *ngIf="( historyVehicle$.immatriculation != undefined && historyVehicle$.immatriculation != '' ) || ( historyVehicle$.vin != undefined && historyVehicle$.vin != '' )"
      class="mat-card-no-shadow">

      <mat-card-subtitle>
        <table>
          <tr>
            <td style="width: 40%;">
              <mat-icon class="icon-display" style="height: 30px; vertical-align: top;">history</mat-icon>
                &nbsp;&nbsp;&nbsp; HISTORIQUE CONCENTRATEUR & WSA :
            </td>
            <td style="width: 30%;"><span *ngIf="nb_Concentrateur < 1" style="color: grey;"> {{AUCUNE_INFO}} </span></td>
            <td style="width: 30%;"></td>
          </tr>
        </table>
      </mat-card-subtitle>


      <!-- HISTORIQUE : CONCENTRATEUR & WSA -->
      <div *ngIf="nb_Incoherence >= 1">        
        <!-- SUR UN ECRAN LARGE -->
        <ng-container *ngIf="isMobileFormat(); then histoWSAInColumn else histoWSAInLine"></ng-container>

        <!-- SUR UN ECRAN LARGE -->
        <ng-template #histoWSAInLine>

          <div class="container-concentrateur-titre">
            <div class="cell-concentrateur-d1 titre_table">Date expertise</div>
            <div class="cell-concentrateur-d2 titre_table">Date sinistre</div>
            <div class="cell-concentrateur-d3 titre_table">Date mouvement</div>
            <div class="cell-concentrateur-e2 titre_table">Éco.</div>
            <div class="cell-concentrateur-e3 titre_table">Rép. Tech.</div>
            <div class="cell-concentrateur-e1 titre_table">VGE</div>
          </div>

          <div class="container-concentrateur" *ngFor="let item of tab_Incoherence" style="border-bottom: 1px solid rgb(39,58,130); margin-top: 8px;">
            <div class="cell-concentrateur-d1">{{ item.date_expertise | date: 'dd/MM/yyyy' }}</div>
            <div class="cell-concentrateur-d2">{{ item.date_sinistre | date: 'dd/MM/yyyy' }}</div>
            <div class="cell-concentrateur-d3">{{ item.date_mouvement | date: 'dd/MM/yyyy' }}</div>
            
            <div class="cell-concentrateur-e2" [ngStyle]="{ color: getColorText(item.reparabilite_economique ? 'Oui' : 'Non') }">
                {{ (item.reparabilite_economique ? 'Oui' : 'Non') }}
            </div>
            
            <div class="cell-concentrateur-e3" [ngStyle]="{ color: getColorText(item.reparabilite_technique ? 'Oui' : 'Non') }">
                {{ (item.reparabilite_technique ? 'Oui' : 'Non') }}
            </div>

            <div class="cell-concentrateur-e1" [ngStyle]="{ color: getColorText(item.procedure_vge ? 'Oui' : 'Non') }">
                {{ (item.procedure_vge ? 'Oui' : 'Non') }}
            </div>
            
            <div class="cell-concentrateur-siv">
                <ul>
                    <li *ngFor="let siv of item.tab_OperationSIV">{{ siv }}</li>
                </ul>
            </div>
            
            <div class="cell-concentrateur-zone">
                <ul>
                    <li *ngFor="let choc of item.tab_ZoneChoc" style="color: rgb(39,58,130);">{{ choc }}</li>
                </ul>
            </div>

            <div class="cell-concentrateur-montant">
                <ul>
                    <li>
                        <p>Montant réparation : </p>
                        <p>{{ item.montant_ht_reparation }} HT</p>
                    </li>
                </ul>
            </div>
          </div>

        </ng-template>

        <!-- SUR UN ECRAN MOBILE -->
        <ng-template #histoWSAInColumn>
          <div *ngFor="let item of tab_Incoherence" style="border-bottom: 1px solid rgb(39,58,130); margin-top: 8px;">

            <ul style="list-style-type: none; padding: 0px;">
              <li><span style="width: 50%; display: inline-block;">Date expertise : </span>{{ item.date_expertise | date: 'dd/MM/yyyy' }}</li>
              <li><span style="width: 50%; display: inline-block;">Date sinistre : </span>{{ item.date_sinistre | date: 'dd/MM/yyyy' }}</li>
              <li><span style="width: 50%; display: inline-block;">Date Mouvement : </span>{{ item.date_mouvement | date: 'dd/MM/yyyy' }}</li>
              <li><span style="width: 50%; display: inline-block;">&Eacute;co : </span><span [ngStyle]="{ 'background-color': getColorText(item.reparabilite_economique ? 'Oui' : 'Non') }" style="color: white; border-radius: 6px; padding: 0px 6px 0px 6px;">{{ (item.reparabilite_economique ? 'Oui' : 'Non') }}</span></li>
              <li><span style="width: 50%; display: inline-block;">Rép : </span><span [ngStyle]="{ 'background-color': getColorText(item.reparabilite_technique ? 'Oui' : 'Non') }" style="color: white; border-radius: 6px; padding: 0px 6px 0px 6px;">{{ (item.reparabilite_technique ? 'Oui' : 'Non') }}</span></li>
              <li><span style="width: 50%; display: inline-block;">VGE : </span><span [ngStyle]="{ 'background-color': getColorText(item.procedure_vge ? 'Oui' : 'Non') }" style="color: white; border-radius: 6px; padding: 0px 6px 0px 6px;">{{ (item.procedure_vge ? 'Oui' : 'Non') }}</span></li>
              <li>
                <ul style="list-style-type: none; padding: 0px;">
                  <span style="width: 50%; display: inline-block;">Opération SIV : </span>
                  <li *ngFor="let siv of item.tab_OperationSIV">
                    {{ siv }}
                  </li>
                </ul>

                <div>
                  <ul style="list-style-type: none; padding: 0px;">
                    <li *ngFor="let choc of item.tab_ZoneChoc" style="color: rgb(39,58,130);">
                      {{ choc }}
                    </li>
                  </ul>
                </div>

                <div>
                  <ul style="list-style-type: none; padding: 0px;">
                    <li>Montant réparation : <span style="color: rgb(39,58,130);font-weight: 600; font-size: 1.4em;">{{ item.montant_ht_reparation }} HT</span></li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>

        </ng-template>

      </div>
    </mat-card>
    
    <!-- CONCENTRATEUR -->
    <mat-card appearance="outlined" *ngIf="historyVehicle$.immatriculation != undefined && historyVehicle$.immatriculation != '' || historyVehicle$.vin != undefined && historyVehicle$.vin != '' "
      class="mat-card-no-shadow">
      
      <mat-card-subtitle>
        <table>
          <tr>
            <td style="width: 40%;">
              <mat-icon class="icon-display" style="height: 30px; vertical-align: top;">history</mat-icon>
                &nbsp;&nbsp;&nbsp; HISTORIQUE :
            </td>
            <td style="width: 30%;"><span *ngIf="nb_Concentrateur < 1" style="color: grey;"> {{AUCUNE_INFO}} </span></td>
            <td style="width: 30%;"></td>
          </tr>
        </table>
      </mat-card-subtitle>

      <mat-accordion *ngIf="nb_Concentrateur > 0">
        <mat-expansion-panel (opened)="nb_Concentrateur > 0" (closed)="nb_Concentrateur < 1">
          
          <mat-expansion-panel-header>
            <mat-panel-title>{{ nb_Concentrateur }} Opération(s)</mat-panel-title>                
          </mat-expansion-panel-header>

          
          
                <div *ngFor="let item of tab_Concentrateur; let index = index" style="border-bottom: 1px solid rgb(39,58,130);">

                  <mat-expansion-panel style="border: none;" hideToggle>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <div class="div-container-between">
                          <div><span style="vertical-align: top;">{{ !isMobileFormat() ? 'VGE: ' : '' }}</span><mat-icon mat-list-icon [ngStyle]="{'color': item.procedure_vge ? '#f44336' : '#273a82'}">{{ (item.procedure_vge ? 'car_crash' : 'note') }}</mat-icon></div>
                          <div>{{ !isMobileFormat() ? 'Opération: ' : '' }}<span style="color: rgb(39,58,130); font-weight: 600;">  {{ item.operation }}</span></div>
                          <div><span [ngStyle]="{'color': item.etat_message == 'succès' ? '#04970E' : '#f44336'}">{{ item.etat_message == 'succès' ? 'Succès' : 'Erreur'}}</span></div>
                          <div>{{ !isMobileFormat() ? 'Envoyé le: ' : '' }}{{ item.date_envoi | date: 'dd/MM/yyyy' }}</div>
                          
                        </div>
                      </mat-panel-title>
                     <!-- <mat-panel-description>
                        {{ item.date_envoi | date: 'dd/MM/yyyy' }}
                      </mat-panel-description> -->
                    </mat-expansion-panel-header>

                    <!-- <ng-template #histoInLine></ng-template> -->
                    <ng-container *ngIf="isMobileFormat(); then SIV_mobile else SIV_desktop">
                    </ng-container>

                      <ng-template #SIV_mobile>
                        <ul style="list-style-type: none; padding: 0px;">
                          <!-- <li style="vertical-align: baseline;"><mat-icon mat-list-icon color="primary">note</mat-icon> Opération: <span style="color: rgb(39,58,130); font-weight: 600;">{{ item.operation }}</span></li> -->
                          <li>VGE: <span [ngStyle]="{ 'background-color': getColorText(item.etat_message) }" style="color: white; border-radius: 6px; padding: 0px 6px 0px 6px;">{{ (item.procedure_vge ? 'Oui' : 'Non') }}</span></li>
                          <li>Envoyé le: <span style="color: rgb(39,58,130); font-weight: 600;">{{ item.date_envoi | date: 'dd/MM/yyyy' }}</span></li>
                          <li>Effet le: <span style="color: rgb(39,58,130); font-weight: 600;">{{ item.date_effet | date: 'dd/MM/yyyy' }}</span></li>
                          <li>État: <span [ngStyle]="{'color': item.etat_message == 'succès' ? '#04970E' : '#f44336'}">{{ item.etat_message == 'succès' ? 'Succès' : 'Erreur'}}</span>
                            
                          <!-- <li>Erreur: <span [ngStyle]="{ color: getColorText(item.etat_message) }">{{ item.message_erreur }}</span></li> -->
                        </ul>
                      </ng-template>

                      <ng-template #SIV_desktop>
                        <ul style="list-style-type: none; padding: 0px;">
                          <li>Effet le: <span style="color: rgb(39,58,130); font-weight: 600;">{{ item.date_effet | date: 'dd/MM/yyyy' }}</span></li></ul>
                      </ng-template>

                   
                      <div *ngFor="let erreur of tab_Concentrateur[index].tab_Erreurs; let index=index" style="border-bottom: 0px solid #989898">
                      <ul style="list-style-type: none; padding: 0px; border-bottom: 1px solid #989898;">
                        <li>Erreur <span [ngStyle]="{ 'background-color': getColorText(item.etat_message) }" style="color: white; border-radius: 6px; padding: 0px 6px 0px 6px;">{{ erreur.code }}</span></li>
                        <li><span style="color: rgb(39,58,130);">{{ erreur.libelle }}</span></li>
                      </ul>
                    </div>


                  </mat-expansion-panel>
                
                </div>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-card>

        <!-- ADELA -->

        <mat-card
          appearance="outlined"
          *ngIf="historyVehicle$.immatriculation != undefined && historyVehicle$.immatriculation != '' || historyVehicle$.vin != undefined && historyVehicle$.vin != '' "
          class="mat-card-no-shadow">
          <mat-card-subtitle>
            <table>
              <tr>
                <td style="width: 40%;">
                  <mat-icon class="icon-display" style="height: 30px; vertical-align: top;">invert_colors</mat-icon>
                  &nbsp;&nbsp;&nbsp; ADELA :
                </td>
                <td style="width: 30%;"><span *ngIf="nb_ADELA < 1" style="color: grey;"> {{AUCUNE_INFO}} </span></td>
                <td style="width: 30%;"></td>
              </tr>
            </table>

          </mat-card-subtitle>
          <mat-accordion *ngIf="nb_ADELA + nb_ADELA_Poudre> 0">
            <mat-expansion-panel (opened)="nb_ADELA + nb_ADELA_Poudre > 0 " (closed)="nb_ADELA + nb_ADELA_Poudre < 1 ">

              <mat-expansion-panel-header  [expandedHeight]="'auto'" [collapsedHeight]="'auto'" style="min-height: 48px;">

                <mat-panel-title>
                  <div>
                  <div *ngIf="nb_ADELA!=0">
                    {{'analyse'|pluralFormat : nb_ADELA}} de fluide, Diagnostique et Expertise des Lubrifiants
                  </div>
                  <div *ngIf="nb_ADELA_Poudre!=0">
                    {{'analyse'|pluralFormat : nb_ADELA_Poudre}} de poudre
                  </div>
                </div>
                </mat-panel-title>

                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <ng-container *ngIf="isMobileFormat(); then adelaInColumn else adelaInLine"></ng-container>

              <ng-template #adelaInLine>
                <table>
                  <tr style="border-bottom: 1px solid rgb(39,58,130) !important;">
                    <td></td>
                    <td>
                      <mat-label class="titre_table">Immat.</mat-label>
                    </td>
                    <td>
                      <mat-label class="titre_table">Type</mat-label>
                    </td>
                    <td>
                      <mat-label class="titre_table">demandée le</mat-label>
                    </td>
                    <td>
                      <mat-label class="titre_table">clôturée le</mat-label>
                    </td>
                    <td>
                      <mat-label class="titre_table" style="text-align: right">Km</mat-label>
                    </td>
                  </tr>
                  <tr *ngFor="let item of tab_ADELA" style="border-bottom: 1px solid rgb(39,58,130);">
                    <td>
                      <mat-icon mat-list-icon color="primary"><span class="material-symbols-outlined">{{item.tpe_code=== '2' ? 'labs' : 'invert_colors'}}</span></mat-icon>
                    </td>
                    <td class="col_200">{{ item.immatriculation }}</td>
                    <td class="col_200">{{ item.type_fluide!=''? item.type_fluide : item.tpe_libelle}}</td>
                    <td class="col_200">{{ item.date_envoi_mission| date: 'dd/MM/yyyy' }}</td>
                    <td class="col_200">{{ item.date_cloture_mission | date: 'dd/MM/yyyy' }}</td>
                    <td style="text-align: right">{{ item.kilometrage!=0? item.kilometrage : '' }} </td>
                  </tr>
                </table>
              </ng-template>

              <ng-template #adelaInColumn>
                <div *ngFor="let item of tab_ADELA">
                  <div style="border-bottom: 1px solid rgb(39,58,130);">
                    <ul style="list-style-type: none; padding: 0px;">
                      <li>
                        <mat-icon mat-list-icon color="primary">note</mat-icon>
                      </li>
                      <li>Immatriculation: {{ item.immatriculation }}</li>
                      <li>Type fluide: {{ item.type_fluide }}</li>
                      <li>Demandé le{{ item.date_envoi_mission| date: 'dd/MM/yyyy' }}</li>
                      <li>Clôturer le: {{ item.date_cloture_mission | date: 'dd/MM/yyyy' }}</li>
                      <li>Km: {{ item.kilometrage }}</li>
                    </ul>
                  </div>

                </div>
              </ng-template>


            </mat-expansion-panel>
          </mat-accordion>
        </mat-card>

        <!-- ANALYSE DE CLÉ -->

        <mat-card
          appearance="outlined"
          *ngIf="historyVehicle$.immatriculation != undefined && historyVehicle$.immatriculation != '' || historyVehicle$.vin != undefined && historyVehicle$.vin != '' "
          class="mat-card-no-shadow">
          <mat-card-subtitle>
            <table>
              <tr>
                <td style="width: 40%;">
                  <mat-icon class="icon-display" style="height: 30px; vertical-align: top;">vpn_key</mat-icon>
                  &nbsp;&nbsp;&nbsp; ANALYSE DE CLÉ :
                </td>
                <td style="width: 30%;"><span *ngIf="nb_ADC < 1" style="color: grey;"> {{AUCUNE_INFO}} </span></td>
                <td style="width: 30%;"></td>
              </tr>
            </table>
          </mat-card-subtitle>
          <mat-accordion *ngIf="nb_ADC > 0">
            <mat-expansion-panel (opened)="nb_ADC > 0" (closed)="nb_ADC < 1">

              <mat-expansion-panel-header>

                <mat-panel-title>
                  <mat-label></mat-label>
                  <input class="inputNotDisabled" matInput type="text" placeholder="" name="" readonly
                    value="{{ historyVehicle$.adc.kilometrage == 0 ? 'Km non-renseigné': historyVehicle$.adc.kilometrage+' Km'  }}"
                    [disabled]="true">

                </mat-panel-title>
                <mat-panel-description>
                  <!-- description -->

                </mat-panel-description>
              </mat-expansion-panel-header>

              <ng-container *ngIf="isMobileFormat(); then adcInColumn else adcInLine"></ng-container>

              <ng-template #adcInLine>
                <table>
                  <tr style="border-bottom: 1px solid rgb(39,58,130) !important;">
                    <td></td>
                    <td>
                      <mat-label class="titre_table">Étape</mat-label>
                    </td>
                    <td class="titre_table col_200">
                      <mat-label class="titre_table">Status</mat-label>
                    </td>
                    <td class="titre_table col_200">
                      <mat-label class="titre_table">Date</mat-label>
                    </td>
                  </tr>
                  <tr *ngFor="let item of tab_Adc" style="border-bottom: 1px solid rgb(39,58,130);">
                    <td>
                      <mat-icon mat-list-icon color="primary">note</mat-icon>
                    </td>
                    <td class="col_200">{{ item.statut_dossier }}. </td>
                    <td class="col_200">{{ item.etat_dossier }}</td>
                    <td class="col_200">{{ item.date_modification | date: 'dd/MM/yyyy' }}</td>
                    <td></td>
                  </tr>
                </table>
              </ng-template>

              <ng-template #adcInColumn>
                <table>
                  <div *ngFor="let item of tab_Adc" style="border-bottom: 1px solid rgb(39,58,130);">
                    <mat-icon mat-list-icon color="primary">note</mat-icon>
                    <ul style="list-style-type: none; padding: 0px;">
                      <li>Étape: {{ item.statut_dossier }}</li>
                      <li>Status: {{ item.etat_dossier }}</li>
                      <li>Date: {{ item.date_modification | date: 'dd/MM/yyyy' }}</li>
                    </ul>
                  </div>
                </table>
              </ng-template>

            </mat-expansion-panel>
          </mat-accordion>
        </mat-card>

        <!-- CEA -->

        <mat-card
          appearance="outlined"
          *ngIf="historyVehicle$.immatriculation != undefined && historyVehicle$.immatriculation != '' || historyVehicle$.vin != undefined && historyVehicle$.vin != '' "
          class="mat-card-no-shadow">
          <mat-card-subtitle>
            <table>
              <tr>
                <td style="width: 40%;">
                  <mat-icon class="icon-display" style="height: 30px; vertical-align: top;">fact_check</mat-icon>
                  &nbsp;&nbsp;&nbsp; CEA :
                </td>
                <td style="width: 30%;"><span *ngIf="nb_CEA < 1" style="color: grey;"> {{AUCUNE_INFO}} </span></td>
                <td style="width: 30%;"></td>
              </tr>
            </table>

          </mat-card-subtitle>
          <mat-accordion *ngIf="nb_CEA > 0">
            <mat-expansion-panel (opened)="nb_CEA > 0" (closed)="nb_CEA < 1">

              <mat-expansion-panel-header>

                <mat-panel-title>
                  {{ nb_CEA }} Carré Expert Auto
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <ng-container *ngIf="isMobileFormat(); then ceaInColumn else ceaInLine"></ng-container>

              <ng-template #ceaInLine>
                <table>
                  <tr style="border-bottom: 1px solid rgb(39,58,130) !important;">
                    <td></td>
                    <td>
                      <mat-label class="titre_table">Immat.</mat-label>
                    </td>
                    <td class="titre_table col_200">
                      <mat-label class="titre_table">demandé le</mat-label>
                    </td>
                    <td class="titre_table col_200">
                      <mat-label class="titre_table">clôturer le</mat-label>
                    </td>
                    <td class="titre_table col_200">
                      <mat-label class="titre_table" style="text-align: right">Km</mat-label>
                    </td>
                  </tr>
                  <tr *ngFor="let item of tab_CEA" style="border-bottom: 1px solid rgb(39,58,130);">
                    <td>
                      <mat-icon mat-list-icon color="primary">note</mat-icon>
                    </td>
                    <td class="col_200">{{ item.immatriculation }}</td>
                    <td class="col_200">{{ item.date_demande | date: 'dd/MM/yyyy' }}</td>
                    <td class="col_200">{{ item.date_cloture | date: 'dd/MM/yyyy' }}</td>
                    <td style="text-align: right">{{ item.kilometrage }} </td>
                  </tr>
                </table>
              </ng-template>

              <ng-template #ceaInColumn>
                  <div *ngFor="let item of tab_CEA">
                    <div style="border-bottom: 1px solid rgb(39,58,130);">
                      <mat-icon mat-list-icon color="primary">note</mat-icon>
                      <ul style="list-style-type: none; padding: 0px;">
                        <li>Immatriculation: {{ item.immatriculation }}</li>
                        <li>Demandé le: {{ item.date_demande | date: 'dd/MM/yyyy' }}</li>
                        <li>Clôturer le: {{ item.date_cloture | date: 'dd/MM/yyyy' }}</li>
                        <li>Km: {{ item.kilometrage }}</li>
                      </ul>
                    </div>
                  </div>
              </ng-template>


            </mat-expansion-panel>
          </mat-accordion>
        </mat-card>

        <!-- APPEL OFFRE -->

        <mat-card
          appearance="outlined"
          *ngIf="historyVehicle$.immatriculation != undefined && historyVehicle$.immatriculation != '' || historyVehicle$.vin != undefined && historyVehicle$.vin != '' "
          class="mat-card-no-shadow">
          <mat-card-subtitle>
            <table>
              <tr>
                <td style="width: 40%;">
                  <mat-icon class="icon-display" style="height: 30px; vertical-align: top;">table_view</mat-icon>
                  &nbsp;&nbsp;&nbsp; APPELS OFFRES :
                </td>
                <td style="width: 30%;"><span *ngIf="nb_AppelOffres < 1" style="color: grey;"> {{AUCUNE_INFO}} </span></td>
                <td style="width: 30%;"></td>
              </tr>
            </table>

          </mat-card-subtitle>
          <mat-accordion *ngIf="nb_AppelOffres > 0">
            <mat-expansion-panel (opened)="nb_AppelOffres > 0" (closed)="nb_AppelOffres < 1">

              <mat-expansion-panel-header>

                <mat-panel-title>
                  {{ nb_AppelOffres }} Appel(s) Offre(s)
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <ng-container *ngIf="isMobileFormat(); then aoInColumn else aoInLine"></ng-container>

              <ng-template #aoInLine>
                <table>
                  <tr style="border-bottom: 1px solid rgb(39,58,130) !important;">
                    <td></td>
                    <td>
                      <mat-label class="titre_table">Immat.</mat-label>
                    </td>
                    <td>
                      <mat-label class="titre_table" style="text-align: center;">demandé le</mat-label>
                    </td>
                    <td>
                      <mat-label class="titre_table" style="text-align: center;">clôturer le</mat-label>
                    </td>
                    <td>
                      <mat-label class="titre_table" style="text-align: right">Km</mat-label>
                    </td>
                  </tr>
                  <tr *ngFor="let item of tab_AppelOffres" style="border-bottom: 1px solid rgb(39,58,130);">
                    <td>
                      <mat-icon mat-list-icon color="primary">note</mat-icon>
                    </td>
                    <td>{{ item.immatriculation }}</td>
                    <td style="text-align: center;">{{ item.date_demande | date: 'dd/MM/yyyy' }}</td>
                    <td style="text-align: center;">{{ item.date_cloture | date: 'dd/MM/yyyy' }}</td>
                    <td style="text-align: right;">{{ item.kilometrage }} </td>
                  </tr>
                </table>
              </ng-template>

              <ng-template #aoInColumn>
                <div *ngFor="let item of tab_AppelOffres">
                  <div style="border-bottom: 1px solid rgb(39,58,130);">
                    <mat-icon mat-list-icon color="primary">note</mat-icon>
                    <ul>
                      <li>Immatriculation: {{ item.immatriculation }}</li>
                      <li>Demandé le: {{ item.date_demande | date: 'dd/MM/yyyy' }}</li>
                      <li>Clôturer le: {{ item.date_cloture | date: 'dd/MM/yyyy' }}</li>
                      <li>Km: {{ item.kilometrage }}</li>
                    </ul>
                  </div>
                </div>
              </ng-template>


            </mat-expansion-panel>
          </mat-accordion>
        </mat-card>

        <!-- 
  <!-- 
      <!-- 
          1000VO
        -->

        <mat-card
          appearance="outlined"
          *ngIf="historyVehicle$.immatriculation != undefined && historyVehicle$.immatriculation != '' || historyVehicle$.vin != undefined && historyVehicle$.vin != '' "
          class="mat-card-no-shadow">
          <mat-card-subtitle>
            <table>
              <tr>
                <td style="width: 5%"><img src="assets/img/Picto_1000VO.png"></td>
                <td style="vertical-align:center; width: 35%; color: #FF4081">&nbsp; 1000 VO :</td>
                <td style="width: 30%;"><span *ngIf="nb_1000VO < 1" style="color: grey;"> {{AUCUNE_INFO}} </span></td>
                <td style="width: 30%;"></td>
              </tr>
            </table>
          </mat-card-subtitle>
          <mat-accordion *ngIf="nb_1000VO > 0">
            <mat-expansion-panel class="mat-card-accent">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ nb_1000VO }} Affaire(s) 1000 VO
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div *ngIf="nb_1000VO > 0">

                <ng-container *ngIf="isMobileFormat(); then VO1000InColumn else VO1000InLine"></ng-container>

                <ng-template #VO1000InLine>
                  <div class="container-concentrateur-titre">

                    <div class="cell-concentrateur-d1 titre_table">Date expertise</div>
                    <div class="cell-concentrateur-d2 titre_table">Kilométrage</div>
                    <div class="cell-concentrateur-d3 titre_table">Conforme</div>
                    <div class="cell-concentrateur-e1 titre_table">Dangereux</div>
                    <div class="cell-concentrateur-e3 titre_table">Tech. Rép.</div>
                    <div class="cell-concentrateur-e2 titre_table">Réparé</div>
  
                  </div>
  
                  <div class="container-concentrateur" style="border-bottom: 1px solid rgb(39,58,130);">
                    <div class="cell-concentrateur-d1">{{ historyVehicle$.sid.st_Affaires1000VO.expertise.date | date: 'dd/MM/yyyy' }}</div>
                    <div class="cell-concentrateur-d1">{{ historyVehicle$.sid.st_Affaires1000VO.expertise.date_conformite | date: 'dd/MM/yyyy' }}</div>
                    <div class="cell-concentrateur-d2">{{ historyVehicle$.sid.st_Affaires1000VO.expertise.kilometrage }}</div>
                    <div class="cell-concentrateur-d3">{{ historyVehicle$.sid.st_Affaires1000VO.expertise.conforme ? 'Oui' : 'Non' }}</div>
                    <div class="cell-concentrateur-e1" [ngStyle]="{ color: getReverseColorText(historyVehicle$.sid.st_Affaires1000VO.expertise.dangereux ? 'Oui' : 'Non') }">{{ (historyVehicle$.sid.st_Affaires1000VO.expertise.dangereux ? 'Oui' : 'Non') }}</div>
                    <div class="cell-concentrateur-e2" [ngStyle]="{ color: getColorText(historyVehicle$.sid.st_Affaires1000VO.expertise.tech_rep ? 'Oui' : 'Non') }">{{ (historyVehicle$.sid.st_Affaires1000VO.expertise.tech_rep ? 'Oui' : 'Non') }}</div>
                    <div class="cell-concentrateur-e3" [ngStyle]="{ color: getColorText(historyVehicle$.sid.st_Affaires1000VO.expertise.repare ? 'Oui' : 'Non') }">{{ (historyVehicle$.sid.st_Affaires1000VO.expertise.repare ? 'Oui' : 'Non') }}</div>
                    <div class="cell-concentrateur-siv">
                      <ul>
                        <li *ngFor="let siv of historyVehicle$.sid.st_Affaires1000VO.expertise.tab_OperationSIV">
                          {{ siv }}
                        </li>
                      </ul>
                    </div>
  
                    <div class="cell-concentrateur-montant">
                      <ul>
                        <li>
                          <p>Montant réparation : </p>
                          <p>{{ ( historyVehicle$.sid.st_Affaires1000VO.expertise.montant_reparation === 0 ? 0 :
                            historyVehicle$.sid.st_Affaires1000VO.expertise.montant_reparation ) }} HT</p>
                        </li>
                      </ul>
                    </div>
                  </div>     
                </ng-template>

                <ng-template #VO1000InColumn>
                  <div style="border-bottom: 1px solid rgb(39,58,130);">                      
                      <div ><span  style="width: 50%; display: inline-block;">Date expertise: </span>{{ historyVehicle$.sid.st_Affaires1000VO.expertise.date | date: 'dd/MM/yyyy' }}</div>
                      <div ><span  style="width: 50%; display: inline-block;">Date conformité: </span>{{ historyVehicle$.sid.st_Affaires1000VO.expertise.date_conformite | date: 'dd/MM/yyyy' }}</div>
                      <div ><span  style="width: 50%; display: inline-block;">Kilométrage: </span>{{ historyVehicle$.sid.st_Affaires1000VO.expertise.kilometrage }}</div>
                      <div ><span  style="width: 50%; display: inline-block;">Conforme: </span>{{ historyVehicle$.sid.st_Affaires1000VO.expertise.conforme ? 'Oui' : 'Non' }}</div>
                      <div ><span  style="width: 50%; display: inline-block;">Dangereux </span><span [ngStyle]="{ color: getReverseColorText(historyVehicle$.sid.st_Affaires1000VO.expertise.dangereux ? 'Oui' : 'Non') }">{{ (historyVehicle$.sid.st_Affaires1000VO.expertise.dangereux ? 'Oui' : 'Non') }}</span></div>
                      <div ><span  style="width: 50%; display: inline-block;">Tech. Rép. </span><span [ngStyle]="{ color: getColorText(historyVehicle$.sid.st_Affaires1000VO.expertise.tech_rep ? 'Oui' : 'Non') }">{{ (historyVehicle$.sid.st_Affaires1000VO.expertise.tech_rep ? 'Oui' : 'Non') }}</span></div>
                      <div ><span  style="width: 50%; display: inline-block;">Réparé: </span><span [ngStyle]="{ color: getColorText(historyVehicle$.sid.st_Affaires1000VO.expertise.repare ? 'Oui' : 'Non') }">{{ (historyVehicle$.sid.st_Affaires1000VO.expertise.repare ? 'Oui' : 'Non') }}</span></div>
                      <div>
                        <ul style="list-style-type: none; padding: 0px;">
                          <li *ngFor="let siv of historyVehicle$.sid.st_Affaires1000VO.expertise.tab_OperationSIV">
                            {{ siv }}
                          </li>
                        </ul>
                      </div>
                      <div>
                        <ul style="list-style-type: none; padding: 0px;">
                          <li>
                            Montant réparation : <span style="color: rgb(39,58,130);font-weight: 600; font-size: 1.4em;">{{ ( historyVehicle$.sid.st_Affaires1000VO.expertise.montant_reparation === 0 ? 0 : historyVehicle$.sid.st_Affaires1000VO.expertise.montant_reparation ) }} HT</span></li>
                        </ul>
                      </div>
                  </div>  
                </ng-template>

              </div>

            </mat-expansion-panel>
          </mat-accordion>
        </mat-card>

        <!-- 
    <!-- 
        <!-- 
          2500VO
        -->

        <mat-card
          appearance="outlined"
          *ngIf="historyVehicle$.immatriculation != undefined && historyVehicle$.immatriculation != '' || historyVehicle$.vin != undefined && historyVehicle$.vin != '' "
          class="mat-card-no-shadow">
          <mat-card-subtitle>
            <table>
              <tr>
                <td style="width: 5%"><img src="assets/img/Picto_2500VO.png"></td>
                <td style="vertical-align:center; width: 35%; color: #FF4081">&nbsp; 2500 VO :</td>
                <td style="width: 30%;"><span *ngIf="nb_2500VO < 1" style="color: grey;"> {{AUCUNE_INFO}} </span></td>
                <td style="width: 30%;"></td>
              </tr>
            </table>
          </mat-card-subtitle>
          <mat-accordion *ngIf="nb_2500VO > 0">
            <mat-expansion-panel class="mat-card-accent">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ nb_2500VO }} Affaire(s) 2500 VO
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>

              </mat-expansion-panel-header>

              <div *ngIf="nb_2500VO > 0">

              <ng-container *ngIf="isMobileFormat(); then VO2500InColumn else VO2500InLine"></ng-container>

              <ng-template #VO2500InLine>
                  <div class="container-concentrateur-titre">
                    <div class="cell-concentrateur-d1 titre_table">Date expertise</div>
                    <div class="cell-concentrateur-d2 titre_table">Kilométrage</div>
                    <div class="cell-concentrateur-d3 titre_table">Conforme</div>
                    <div class="cell-concentrateur-e1 titre_table">Dangereux</div>
                    <div class="cell-concentrateur-e3 titre_table">Tech. Rép.</div>
                    <div class="cell-concentrateur-e2 titre_table">Réparé</div>
                  </div>
                  <div class="container-concentrateur" style="border-bottom: 1px solid rgb(39,58,130);">
                    <div class="cell-concentrateur-d1">{{ historyVehicle$.sid.st_Affaires2500VO.expertise.date | date: 'dd/MM/yyyy' }}</div>
                    <div class="cell-concentrateur-d2">{{ historyVehicle$.sid.st_Affaires2500VO.expertise.kilometrage }}</div>
                    <div class="cell-concentrateur-d3">{{ historyVehicle$.sid.st_Affaires2500VO.expertise.conforme ? 'Oui' : 'Non' }}</div>
                    <div class="cell-concentrateur-e1"
                      [ngStyle]="{ color: getReverseColorText(historyVehicle$.sid.st_Affaires2500VO.expertise.dangereux ? 'Oui' : 'Non') }">{{ (historyVehicle$.sid.st_Affaires2500VO.expertise.dangereux ? 'Oui' : 'Non') }}</div>
                    <div class="cell-concentrateur-e2" [ngStyle]="{ color: getColorText(historyVehicle$.sid.st_Affaires2500VO.expertise.tech_rep ? 'Oui' : 'Non') }"> {{ (historyVehicle$.sid.st_Affaires2500VO.expertise.tech_rep ? 'Oui' : 'Non') }}</div>
                    <div class="cell-concentrateur-e3" [ngStyle]="{ color: getColorText(historyVehicle$.sid.st_Affaires2500VO.expertise.repare ? 'Oui' : 'Non') }">{{ (historyVehicle$.sid.st_Affaires2500VO.expertise.repare ? 'Oui' : 'Non') }}</div>
                    <div class="cell-concentrateur-siv">
                      <ul>
                        <li *ngFor="let siv of historyVehicle$.sid.st_Affaires2500VO.expertise.tab_OperationSIV">
                          {{ siv }}
                        </li>
                      </ul>
                    </div>
  
                    <div class="cell-concentrateur-montant">
                      <ul>
                        <li>
                          <p>Montant réparation : </p>
                          <p>
                            {{ ( historyVehicle$.sid.st_Affaires2500VO.expertise.montant_reparation === 0 ? 0 :
                            historyVehicle$.sid.st_Affaires2500VO.expertise.montant_reparation ) }} HT
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                
              </ng-template>
              
              <ng-template #VO2500InColumn>
                <div>
                  <div><span  style="width: 50%; display: inline-block;">Date expertise: </span> {{ historyVehicle$.sid.st_Affaires2500VO.expertise.date | date: 'dd/MM/yyyy' }}</div>
                  <div><span  style="width: 50%; display: inline-block;">Kilométrage: </span> {{ historyVehicle$.sid.st_Affaires2500VO.expertise.kilometrage }}</div>
                  <div><span  style="width: 50%; display: inline-block;">Conforme: </span> {{ historyVehicle$.sid.st_Affaires2500VO.expertise.conforme ? 'Oui' : 'Non' }}</div>
                  <div><span  style="width: 50%; display: inline-block;">Dangereux: </span><span [ngStyle]="{ color: getReverseColorText(historyVehicle$.sid.st_Affaires2500VO.expertise.dangereux ? 'Oui' : 'Non') }">{{ (historyVehicle$.sid.st_Affaires2500VO.expertise.dangereux ? 'Oui' : 'Non') }}</span></div>
                  <div><span  style="width: 50%; display: inline-block;">Tech. Rép. </span><span [ngStyle]="{ color: getColorText(historyVehicle$.sid.st_Affaires2500VO.expertise.tech_rep ? 'Oui' : 'Non') }"> {{ (historyVehicle$.sid.st_Affaires2500VO.expertise.tech_rep ? 'Oui' : 'Non') }}</span></div>
                  <div><span  style="width: 50%; display: inline-block;">Réparé: </span><span [ngStyle]="{ color: getColorText(historyVehicle$.sid.st_Affaires2500VO.expertise.repare ? 'Oui' : 'Non') }">{{ (historyVehicle$.sid.st_Affaires2500VO.expertise.repare ? 'Oui' : 'Non') }}</span></div>
                </div>
                <div style="border-bottom: 1px solid rgb(39,58,130);">
                  <!-- <div class="cell-concentrateur-siv"> -->
                    <ul style="list-style-type: none; padding: 0px;">
                      <li *ngFor="let siv of historyVehicle$.sid.st_Affaires2500VO.expertise.tab_OperationSIV">
                        {{ siv }}
                      </li>
                    </ul>
                  <!-- </div> -->

                  <!-- <div class="cell-concentrateur-montant"> -->
                    <ul style="list-style-type: none; padding: 0px;">
                      <li>Montant réparation : <span style="color: rgb(39,58,130);font-weight: 600; font-size: 1.4em;">{{ ( historyVehicle$.sid.st_Affaires2500VO.expertise.montant_reparation === 0 ? 0 : historyVehicle$.sid.st_Affaires2500VO.expertise.montant_reparation ) }} HT</span></li>
                    </ul>
                  </div>
                <!-- </div> -->
              </ng-template>
            </div>

              

            </mat-expansion-panel>
          </mat-accordion>
        </mat-card>

        <!-- 
    <!-- 
        <!-- 
          5000VO
        -->

        <mat-card
          appearance="outlined"
          *ngIf="historyVehicle$.immatriculation != undefined && historyVehicle$.immatriculation != '' || historyVehicle$.vin != undefined && historyVehicle$.vin != '' "
          class="mat-card-no-shadow">
          <mat-card-subtitle>
            <table>
              <tr>
                <td style="width: 5%"><img src="assets/img/Picto_5000VO.png"></td>
                <td style="vertical-align:center; width: 35%; color: #FF4081">&nbsp; 5000 VO :</td>
                <td style="width: 30%;"><span *ngIf="nb_5000VO < 1" style="color: grey;"> {{AUCUNE_INFO}} </span></td>
                <td style="width: 30%;"></td>
              </tr>
            </table>
          </mat-card-subtitle>
          <mat-accordion *ngIf="nb_5000VO > 0">
            <mat-expansion-panel class="mat-card-accent">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ nb_5000VO }} Affaire(s) 5000 VO
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div *ngIf="nb_5000VO > 0">

                <ng-container *ngIf="isMobileFormat(); then VO5000InColumn else VO5000InLine"></ng-container>

                <ng-template #VO5000InLine>
                  <div class="container-concentrateur-titre">
                    <div class="cell-concentrateur-d1 titre_table">Date expertise</div>
                    <div class="cell-concentrateur-d2 titre_table">Kilométrage</div>
                    <div class="cell-concentrateur-d3 titre_table">Conforme</div>
                    <div class="cell-concentrateur-e1 titre_table">Dangereux</div>
                    <div class="cell-concentrateur-e3 titre_table">Tech. Rép.</div>
                    <div class="cell-concentrateur-e2 titre_table">Réparé</div>
                  </div>
  
                  <div class="container-concentrateur" *ngFor="let item of tab_Affaires5000VO"
                    style="border-bottom: 1px solid rgb(39,58,130);">
                    <div class="cell-concentrateur-d1">{{ item.expertise.date | date: 'dd/MM/yyyy' }}</div>
                    <div class="cell-concentrateur-d2">{{ item.expertise.kilometrage }}</div>
                    <div class="cell-concentrateur-d3">{{ item.expertise.conforme ? 'Oui' : 'Non' }}</div>
                    <div class="cell-concentrateur-e1" [ngStyle]="{ color: getReverseColorText(item.expertise.dangereux ? 'Oui' : 'Non') }">{{ (item.expertise.dangereux ? 'Oui' : 'Non') }}</div>
                    <div class="cell-concentrateur-e2" [ngStyle]="{ color: getColorText(item.expertise.tech_rep ? 'Oui' : 'Non') }">{{ (item.expertise.tech_rep ? 'Oui' : 'Non') }}</div>
                    <div class="cell-concentrateur-e3" [ngStyle]="{ color: getColorText(item.expertise.repare ? 'Oui' : 'Non') }">{{ (item.expertise.repare ? 'Oui' : 'Non') }}</div>
                    <div class="cell-concentrateur-siv">
                      <ul>
                        <li *ngFor="let siv of item.expertise.tab_OperationSIV">
                          {{ siv }}
                        </li>
                      </ul>
                    </div>
  
                    <div class="cell-concentrateur-montant">
                      <ul>
                        <li>
                          <p>Montant réparation : </p>
                          <p> {{ ( item.expertise.montant_reparation === 0 ? 0 : item.expertise.montant_reparation ) }} HT</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </ng-template>

                <ng-template #VO5000InColumn>
                  
                  <div *ngFor="let item of tab_Affaires5000VO"
                  style="border-bottom: 1px solid rgb(39,58,130);">
                    <ul style="list-style-type: none; padding: 0px;">
                      <li><span style="width: 50%; display: inline-block;">Date expertise: </span>{{ item.expertise.date | date: 'dd/MM/yyyy' }}</li>
                      <li><span style="width: 50%; display: inline-block;">Kilométrage: </span>{{ item.expertise.kilometrage }}</li>
                      <li><span style="width: 50%; display: inline-block;">Conforme: </span>{{ item.expertise.conforme ? 'Oui' : 'Non' }}</li>
                      <li><span style="width: 50%; display: inline-block;">Dangereux: </span><span [ngStyle]="{ color: getReverseColorText(item.expertise.dangereux ? 'Oui' : 'Non') }">{{ (item.expertise.dangereux ? 'Oui' : 'Non') }}</span></li>
                      <li><span style="width: 50%; display: inline-block;">Tech. Rép. : </span><span [ngStyle]="{ color: getColorText(item.expertise.tech_rep ? 'Oui' : 'Non') }">{{ (item.expertise.tech_rep ? 'Oui' : 'Non') }}</span></li>
                      <li><span style="width: 50%; display: inline-block;">Réparé: </span><span [ngStyle]="{ color: getColorText(item.expertise.repare ? 'Oui' : 'Non') }">{{ (item.expertise.repare ? 'Oui' : 'Non') }}</span></li>
                    </ul>
                    <div>
                      <ul style="list-style-type: none; padding: 0px;">
                        <li *ngFor="let siv of item.expertise.tab_OperationSIV">
                          {{ siv }}
                        </li>
                      </ul>
                    </div>
  
                    <div>
                      <ul style="list-style-type: none; padding: 0px;">
                        <li>Montant réparation : <span style="color: rgb(39,58,130);font-weight: 600; font-size: 1.4em;">{{ ( item.expertise.montant_reparation === 0 ? 0 : item.expertise.montant_reparation ) }} HT</span></li>
                      </ul>
                    </div>
                  </div>
                </ng-template>
                
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-card>



    <mat-card
      appearance="outlined"
      *ngIf="( historyVehicle$.immatriculation != undefined && historyVehicle$.immatriculation != '' ) || ( historyVehicle$.vin != undefined && historyVehicle$.vin != '' )"
      class="mat-card-no-shadow">

      <ng-container *ngIf="isMobileFormat(); then buttonInColumn else buttonInLine"></ng-container>

      <ng-template  #buttonInLine >
        <tr  style="display: flex; justify-content: center">
          <td class="button-container">
            <div class="spinner-container-small" *ngIf="waitingDownload">
              <mat-spinner diameter="24"></mat-spinner>
            </div>
            
            <button mat-stroked-button color="primary" type="submit"  [disabled]="waitingDownload"
              (click)="printDocument()">
              <mat-icon>download</mat-icon>
              Télécharger le rapport
            </button>
          </td>
  
          <td class="button-container">            
            <div class="spinner-container-small" *ngIf="waitingSend">
              <mat-spinner diameter="24"></mat-spinner>
            </div>
            <button mat-stroked-button color="primary" type="submit"  [disabled]="waitingSend"
              (click)="sendDocumentByMail()">
              <mat-icon>mail</mat-icon>
              Recevoir le rapport
            </button>
          </td>
  
          <td class="button-container" *ngIf="vehiculeFound">            
            <div class="spinner-container-small" *ngIf="waitingsendQueryByMail">
              <mat-spinner diameter="24"></mat-spinner>
            </div>
            <button mat-stroked-button color="accent"  [disabled]="waitingsendQueryByMail"
              (click)="toggleSendQueryTo()">
              <mat-icon>mail</mat-icon>
              Demander plus d'information
            </button>
          </td>
        </tr>
      </ng-template>

      <ng-template #buttonInColumn>
          <div class="button-column-container">
            <button mat-stroked-button class="button-column-container" color="primary" type="submit"  [disabled]="waitingDownload"
              (click)="printDocument()">
              <ng-container *ngIf="waitingDownload; then spinnerDownload else textDownload"></ng-container>
              <ng-template #spinnerDownload><mat-spinner diameter="24"></mat-spinner>Télécharger le rapport</ng-template>
              <ng-template #textDownload><mat-icon width="24">download</mat-icon>Télécharger le rapport</ng-template>
            </button>
          </div>
  
          <div class="button-column-container">
            <button mat-stroked-button color="primary" type="submit"  [disabled]="waitingSend"
              (click)="sendDocumentByMail()">
              <ng-container *ngIf="waitingSend; then spinnerSend else textSend"></ng-container>
              <ng-template #spinnerSend><mat-spinner diameter="24"></mat-spinner>Recevoir le rapport</ng-template>
              <ng-template #textSend><mat-icon width="24">mail</mat-icon>Recevoir le rapport</ng-template>
            </button>
          </div>
          
          <div class="button-column-container">
            <button mat-stroked-button color="accent"  [disabled]="waitingsendQueryByMail"
            (click)="toggleSendQueryTo()">
              <mat-icon width="24">mail</mat-icon>Demander plus d'information
            </button>
          </div>
      </ng-template>

      
    </mat-card>

    <!-- DEMANDE DE REQUETE COMPLETAIRE A UN/PLUSIEURS CABINET(S) -->

    <mat-card appearance="outlined" *ngIf="sendQuery === true">

      <div style="display: flex; justify-content: flex-end; width: 100%;">
        <button mat-icon-button color="accent" type="link" (click)="toggleSendQueryTo()">
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <mat-form-field>
        <mat-label for="queryFrom">Expédié depuis : </mat-label>
        <input matInput type="mail" placeholder="votre adresse mail" name="queryFrom"
          value="{{ profilUtilisateur.email }}" [disabled]="true" multiple>
      </mat-form-field>


      <mat-form-field class="textarea">
        <mat-label for="mailmsg">Tapez votre demande ici : </mat-label>
        <textarea matInput type="text" placeholder="Message à envoyer avec le document" name="mailmsg"
          style="height: 180px;" [value]="">{{ texteMail }}</textarea>
      </mat-form-field>
     
        <button mat-raised-button color="accent" type="submit"  (click)="sendQueryByMail()"
          style="width: 140px;">
          <ng-container *ngIf="waitingsendQueryByMail; then spinnerQueryByMail else textQueryByMail"></ng-container>
          <ng-template #spinnerQueryByMail><div class="div-container"><mat-spinner diameter="24"></mat-spinner><span>Envoyer</span></div></ng-template>
          <ng-template #textQueryByMail><div class="div-container"><mat-icon width="24">mail</mat-icon><span>Envoyer</span></div></ng-template>
        </button>

    </mat-card>

  </mat-card>

</div>