<div (window:resize)="onResize($event)">
    <form *ngIf="entite != undefined" [formGroup]="entiteForm">
          <div *ngIf="entite == undefined">
                <p class="label-error">Aucune entité ne correspond à cet identifiant.</p>
          </div>
          
          <div *ngIf="entite != undefined">
                <div *ngIf="!creer_succursale">
                      <mat-card appearance="outlined">
                              <mat-card-title>
                                    <div class="div-container-left">
                                          <mat-icon>apartment</mat-icon>
                                          <span>Logo</span>
                                    </div>
                              </mat-card-title>
                            <mat-card-content style="display: flex; justify-content: center !important;">
                                  <div style="    height: 150px; display: flex; justify-content: center;align-items: center;">
                                        <mat-icon class="grey-icon" style="transform: scale(8);" *ngIf="!entiteForm.get('logo')?.value">apartment</mat-icon>
                                        <img *ngIf="entiteForm.get('logo')?.value" [src]="_sanitizer.bypassSecurityTrustResourceUrl(entiteForm.get('logo')?.value)">                        
                                  </div>                    
                            </mat-card-content>
                            <mat-card-actions style="display: flex; justify-content: center !important;" *ngIf="RelUtilisateurEntite.est_dirigeant == true || utl_profil.droits_utilisateur.est_admin == true">
                                  <button mat-button color="primary" (click)="onClickUpload()">
                                        <mat-icon>cloud_upload</mat-icon>
                                        Déposer
                                  </button>
                                  <button mat-button color="warn" (click)="onClickDelete()">
                                        <mat-icon>delete</mat-icon>
                                        Supprimer
                                  </button>
                            </mat-card-actions>
                      </mat-card>
                </div>
                                  
                <mat-card appearance="outlined" >
                      <mat-card-title>
                        <div class="div-container-left" *ngIf="!creer_succursale && entite.typ_ent_nom != this.CONST_NOM_TYPE_ENTITE_SUCCURSALE">
                              <mat-icon>feed</mat-icon>
                              <span >Informations générales</span>
                        </div>      
                        <div class="div-container-left" *ngIf="creer_succursale || entite.typ_ent_nom == this.CONST_NOM_TYPE_ENTITE_SUCCURSALE">
                              <mat-icon>feed</mat-icon>
                              <span >Informations sur la succursale</span>
                        </div>
                      </mat-card-title>
                  
                      <mat-error>
                        <span *ngIf="entiteForm.errors?.siretDoesntMatchSiren===true">
                          Les 9 premiers chiffres du SIRET ne correspondent pas au SIREN
                        </span>
                  </mat-error>

                      <mat-card-content  class=".mat-card-content">
                                    <div class="rs">
                                          <mat-form-field 
                                                *ngIf="!creer_succursale" 
                                                style="margin: 5px; background-color:antiquewhite;border-radius: 10px;max-width: 140px;" >
                                                <mat-label  style="color: brown; font-weight: 600;">Contact N°</mat-label>
                                                <input 
                                                      matInput 
                                                      required 
                                                      type              = "text" 
                                                      formControlName   = "numero_client" 
                                                      placeholder       = "Numéro de contact"
                                                      style             = "color:brown; font-weight: 600;"
                                                      >
                                                <mat-hint>Réf. Client</mat-hint>
                                          </mat-form-field>

                                          <mat-form-field style="max-width: 200px;margin:5px">
                                                <mat-label>Type entité</mat-label>
                                                <mat-select 
                                                      (selectionChange) = "SelectionneTypeEntite($event)" 
                                                      formControlName   = "id_type_entite" 
                                                      [disabled]        = "creer_succursale" 
                                                      required
                                                      >
                                                      <mat-option *ngFor="let type of typeEntites" [value]="type.id">{{ type.libelle }}</mat-option>
                                                </mat-select>
                                          </mat-form-field>

                                          <mat-form-field style="margin: 5px;max-width: 130px;">
                                                <mat-label for="siren">SIREN</mat-label>
                                                <input matInput type="text" formControlName="siren" placeholder="Numéro de SIREN" name="siren"
                                                oninput="this.value = this.value.toUpperCase()" required>                                    
                                                <mat-error *ngIf="siren?.invalid && (siren?.dirty || siren?.touched)">{{ siren?.errors?.erreur }}</mat-error>                                    
                                          </mat-form-field>

                                          <mat-form-field style="margin: 5px;max-width: 170px;">
                                                <mat-label for="siret">SIRET</mat-label>
                                                <input matInput 
                                                            type              = "text" 
                                                            formControlName   = "siret" 
                                                            placeholder       = "Numéro de SIRET" 
                                                            name              = "siret"
                                                            oninput           = "this.value = this.value.replace(/[^0-9]/g, '')"
                                                            required
                                                            >
                                                <mat-error *ngIf="siret?.invalid && (siret?.dirty || siret?.touched)">{{ siret?.errors?.erreur }}</mat-error>
                                               
                                          </mat-form-field>

                                          <mat-form-field style="margin: 5px;max-width: 300px;">
                                                <mat-label for="raison_sociale">Raison Sociale</mat-label>
                                                <input matInput required type="text" placeholder="Raison Sociale"
                                                      formControlName="raison_sociale" id="raison_sociale" name="raison_sociale"
                                                      oninput="this.value = this.value.toUpperCase()" >
                                                <mat-error>Obligatoire</mat-error>
                                          </mat-form-field>

                                    </div>

                                    <div class="tel">
                                          <mat-form-field style="max-width: 150px;margin:5px" *ngIf="!typeAdela" >
                                                <mat-label>Habilitation N°</mat-label>
                                                <input matInput
                                                       type             = "text" 
                                                       formControlName  = "habilitation" 
                                                       maxlength        = "10"
                                                       placeholder      = "Numéro d'habilitation"
                                                      required
                                                      >
                                                      <mat-error>Obligatoire</mat-error>
                                          </mat-form-field>
                                          
                                          <mat-form-field 
                                                *ngIf="creer_succursale || typeEntiteCode === 'SUCCURSALE'"
                                                style="max-width: 50px;margin:5px"
                                                >
                                                <mat-label for="indice">Indice</mat-label>
                                                <input matInput type="text" formControlName="indice" placeholder="Indice"
                                                    name="indice" required>
                                                <mat-error>Obligatoire</mat-error>
                                          </mat-form-field>

                                          <mat-form-field style="margin:5px;max-width: 150px;">
                                                <mat-label for="telephone">Téléphone</mat-label>
                                                <input matInput type="text" formControlName="telephone" placeholder="Numéro de téléphone" appTelephone>
                                                <mat-error *ngIf="telephone?.invalid && (telephone?.dirty || telephone?.touched)">{{ telephone?.errors?.erreur}}</mat-error>
                                          </mat-form-field>
                                    
                                          <mat-form-field style="margin:5px;max-width: 150px;">
                                                <mat-label for="fax">Fax</mat-label>
                                                <input matInput type="text" formControlName="fax" placeholder="Fax" appTelephone>
                                                <mat-error *ngIf="fax?.invalid && (fax?.dirty || fax?.touched)">{{ fax?.errors?.erreur}}></mat-error>
                                          </mat-form-field>
                                    </div>
                  
                            

                            <div style="margin-top:40px">
                                  <app-adresse  controlKey="adresse" ></app-adresse>
                            </div>
                        <div class="mail-group">
                              <mat-form-field>
                                    <mat-label for="mail_facturation">Mail Facturation</mat-label>
                                    <input matInput type="email" formControlName="mail_facturation"
                                          placeholder="Adresse email de facturation">
                                    <mat-error *ngIf="mail_facturation?.invalid && (mail_facturation?.dirty || mail_facturation?.touched)">{{ mail_facturation?.errors?.checkValidatorMail}}</mat-error>
                              </mat-form-field>
                        
                              <mat-form-field>
                                    <mat-label for="mail_statistique">Mail Statistique</mat-label>
                                    <input matInput type="email" formControlName="mail_statistique"
                                          placeholder="Adresse email de statistique">
                                    <mat-error *ngIf="mail_statistique?.invalid && (mail_statistique?.dirty || mail_statistique?.touched)">{{ mail_statistique?.errors?.checkValidatorMail}}</mat-error>
                              </mat-form-field>
                        
                              <mat-form-field>
                                    <mat-label for="mail_contact">Mail Contact</mat-label>
                                    <input matInput type="email" formControlName="mail_contact"
                                          placeholder="Adresse email de contact">
                                    <mat-error *ngIf="mail_contact?.invalid && (mail_contact?.dirty || mail_contact?.touched)">{{ mail_contact?.errors?.checkValidatorMail}}</mat-error>
                              </mat-form-field>
          
                        </div>
                              <div class="div-button-right" *ngIf="RelUtilisateurEntite.est_dirigeant == true || utl_profil.droits_utilisateur.droit_module_admin_extranet == true || est_dirigeant_principal == true">
                                    <button     mat-raised-button 
                                                *ngIf             = "RelUtilisateurEntite.est_dirigeant == true || est_dirigeant_principal == true" 
                                                color             = "primary" 
                                                type              = "button" 
                                                style             = "margin-right: 10px;" 
                                                [routerLink]      = " ['/'+apiAuth.root+'/profil/' +apiAuth.userConnect.id]"
                                                fragment          = "1">
                                          <span >Mes entités</span>
                                    </button>
                                    
                                    <div *ngIf="isSuccursale() && entite.id != undefined && entite.id > 0 && entite.EstActif" [matTooltipDisabled]="!acces_concentrateur_actif" matTooltip="Désactiver l'accès au concentrateur avant de désactiver la succursale" matTooltipPosition="left">
                                        <button type="button" mat-button color="warn" (click)="onClickDisableEnableSuccursale(false)" [disabled]="acces_concentrateur_actif">
                                            <mat-icon>remove</mat-icon>
                                            Désactiver
                                        </button>
                                    </div>
                
                                    <div *ngIf="isSuccursale() && entite.id != undefined && entite.id > 0 && !entite.EstActif">
                                        <button type="button" mat-button color="primary" (click)="onClickDisableEnableSuccursale(true)">
                                            <mat-icon>add</mat-icon>
                                            Activer
                                        </button>
                                    </div>

                                  <button *ngIf="creer_succursale"  mat-raised-button color="primary" type="submit" 
                                        [disabled]="((entiteForm.pristine ) || (!entiteForm.valid && entiteForm.dirty) ||  waiting_save || entiteForm.errors?.siretDoesntMatchSiren===true) || siretValid === false" (click)="enregistreData(false)"
                                        >
                                        <span *ngIf="creer_succursale">Créer la succursale</span>
                                  </button>

                              <!-- <button mat-raise-button (click)="VerifierForm()">Vérifier Form</button> -->
                                  <button 
                                          mat-raised-button 
                                          *ngIf       = "!creer_succursale && !waiting_save" 
                                          color       = "primary" 
                                          type        = "submit" 
                                          [disabled]  = "(entiteForm.pristine) || (!entiteForm.valid && entiteForm.dirty) || waiting_save" 
                                          (click)     = "enregistreData(false)"
                                        >
                                        <span *ngIf="!creer_succursale">Enregistrer les modifications</span>
                                  </button>
                                  <mat-spinner diameter="24" *ngIf="waiting_save"></mat-spinner>
                              </div>
                      </mat-card-content>
                </mat-card>                  
          </div>
    </form>
</div>