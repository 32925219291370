import { Component, OnInit } from '@angular/core';
import { TableTicketComponent } from '../table-ticket/table-ticket.component';
import { FiltreTicketComponent } from '../filtre-ticket/filtre-ticket.component';
@Component({
    selector: 'app-recherche-ticket',
    templateUrl: './recherche-ticket.component.html',
    styleUrls: ['./recherche-ticket.component.scss'],
    standalone: true,
    imports: [FiltreTicketComponent, TableTicketComponent]
})
export class RechercheTicketComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
