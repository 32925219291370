import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiAuthentificationService } from '../services/api-authentification.service';
import { ApiUtilitaireService } from '../services/tools/api-utilitaire.service';


@Injectable({
  providedIn: 'root'
})
export class ProtectAuthGuard  {

  constructor(private apiAuth: ApiAuthentificationService
            , private apiUtilitaire: ApiUtilitaireService){}
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    // Dans le cas où l'on est redirigé vers le menu principal après être connecté depuis la page login
    // On va directement autorisé l'utilisateur à accéder au menu, sans recharger les informations de l'utilisateur / token
    // Puisque ces derniers sont déjà autorisé par la connexion
    if(this.apiAuth.appelDepuisLogin) {
      this.apiAuth.appelDepuisLogin = false;
      switch(this.apiAuth.root) {
        case this.apiAuth.CONST_URL_ROOT_EXTRANET :
          if(this.apiAuth.userConnect.environnement.code != this.apiUtilitaire.CONST_CODE_ENVIRONNEMENT_EXTRANET_FFEA) {
            this.apiAuth.logOut();            
            return false;
          } else {
            return true;
          } 
          break;
        case this.apiAuth.CONST_URL_ROOT_SERVICE :
          if(this.apiAuth.userConnect.environnement.code != this.apiUtilitaire.CONST_CODE_ENVIRONNEMENT_SERVICES_FFEA) {
            this.apiAuth.logOut();
            return false;
          } else {
            return true;
          }   
          break;  
        default :
        return false;          
      } 
    } else {
      // Vérifier si l'utilisateur à un token courte durée en local
      // Si oui : on cherche à savoir s'il est toujours validé
        // Si oui : on accorde l'accès à la page
        // Si non : on rafraîchit le token courte durée grâce au token longue durée
      // Si non : on cherche à le rafraîchir grâce au token longue durée
      if(this.apiAuth.getUsertokenCDOnLocalStorage() != '' && this.apiAuth.getUsertokenCDOnLocalStorage() != null) {
        
        return this.apiAuth.tokenValide()
          .pipe(
            tap((data: boolean) => {
              
              if(!data) {        
                this.apiAuth.logOut();      
              } else {
                switch(this.apiAuth.root) {
                  case this.apiAuth.CONST_URL_ROOT_EXTRANET :
                    if(this.apiAuth.userConnect.environnement.code != this.apiUtilitaire.CONST_CODE_ENVIRONNEMENT_EXTRANET_FFEA) {
                      this.apiAuth.logOut();
                    }
                    break;
                  case this.apiAuth.CONST_URL_ROOT_SERVICE :
                    if(this.apiAuth.userConnect.environnement.code != this.apiUtilitaire.CONST_CODE_ENVIRONNEMENT_SERVICES_FFEA) {
                      this.apiAuth.logOut();
                    }
                    break;  
                  default :  
                  this.apiAuth.logOut(); 
                } 
              }
            })
          );
      } else {
        return this.apiAuth.refreshTokenCD()
        .pipe(
          tap((data: boolean) => {
            if(!data) {   
              this.apiAuth.logOut();           
            } else {
              switch(this.apiAuth.root) {
                case this.apiAuth.CONST_URL_ROOT_EXTRANET :
                  if(this.apiAuth.userConnect.environnement.code != this.apiUtilitaire.CONST_CODE_ENVIRONNEMENT_EXTRANET_FFEA) {
                    this.apiAuth.logOut();
                  }
                  break;
                case this.apiAuth.CONST_URL_ROOT_SERVICE :
                  if(this.apiAuth.userConnect.environnement.code != this.apiUtilitaire.CONST_CODE_ENVIRONNEMENT_SERVICES_FFEA) {
                    this.apiAuth.logOut();
                  }
                  break;  
                default :   
                this.apiAuth.logOut();
              } 
            }
          })
        );
      }
    }
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // Dans le cas où l'on est redirigé vers le menu principal après être connecté depuis la page login
    // On va directement autorisé l'utilisateur à accéder au menu, sans recharger les informations de l'utilisateur / token
    // Puisque ces derniers sont déjà autorisé par la connexion
    if(this.apiAuth.appelDepuisLogin) {
      this.apiAuth.appelDepuisLogin = false;
      switch(this.apiAuth.root) {
        case this.apiAuth.CONST_URL_ROOT_EXTRANET :
          if(this.apiAuth.userConnect.environnement.code != this.apiUtilitaire.CONST_CODE_ENVIRONNEMENT_EXTRANET_FFEA) {
            this.apiAuth.logOut();
            return false;
          } else {
            return true;
          } 
          break;
        case this.apiAuth.CONST_URL_ROOT_SERVICE :
          if(this.apiAuth.userConnect.environnement.code != this.apiUtilitaire.CONST_CODE_ENVIRONNEMENT_SERVICES_FFEA) {
            this.apiAuth.logOut();
            return false;
          } else {
            return true;
          }   
          break;  
        default :
        return false;          
      } 

    } else {
      // Vérifier si l'utilisateur à un token courte durée en local
      // Si oui : on cherche à savoir s'il est toujours validé
        // Si oui : on accorde l'accès à la page
        // Si non : on rafraîchit le token courte durée grâce au token longue durée
      // Si non : on cherche à le rafraîchir grâce au token longue durée
      if(this.apiAuth.getUsertokenCDOnLocalStorage() != '' && this.apiAuth.getUsertokenCDOnLocalStorage() != null) {
        if (!this.apiAuth.tokenValide()) {
          this.apiAuth.logOut();
          return false;
        }else{
          return true;
        }


        return this.apiAuth.tokenValide()
        .pipe(
          tap((data: boolean) => {
            if(!data) {       
              this.apiAuth.logOut();       
            } else {
              switch(this.apiAuth.root) {
                case this.apiAuth.CONST_URL_ROOT_EXTRANET :
                  if(this.apiAuth.userConnect.environnement.code != this.apiUtilitaire.CONST_CODE_ENVIRONNEMENT_EXTRANET_FFEA) {
                    this.apiAuth.logOut();
                  }
                  break;
                case this.apiAuth.CONST_URL_ROOT_SERVICE :
                  if(this.apiAuth.userConnect.environnement.code != this.apiUtilitaire.CONST_CODE_ENVIRONNEMENT_SERVICES_FFEA) {
                    this.apiAuth.logOut();
                  }
                  break;  
                default :   
                this.apiAuth.logOut();
              } 
            }
          })
        );
      } else {
        return this.apiAuth.refreshTokenCD()
        .pipe(
          tap((data: boolean) => {
            if(!data) {         
              this.apiAuth.logOut();     
            } else {
              switch(this.apiAuth.root) {
                case this.apiAuth.CONST_URL_ROOT_EXTRANET :
                  if(this.apiAuth.userConnect.environnement.code != this.apiUtilitaire.CONST_CODE_ENVIRONNEMENT_EXTRANET_FFEA) {
                    this.apiAuth.logOut();
                  }
                  break;
                case this.apiAuth.CONST_URL_ROOT_SERVICE :
                  if(this.apiAuth.userConnect.environnement.code != this.apiUtilitaire.CONST_CODE_ENVIRONNEMENT_SERVICES_FFEA) {
                    this.apiAuth.logOut();
                  }
                  break;  
                default :   
                this.apiAuth.logOut();
              } 
            }
          })
        );
      }
    }
  }
}
