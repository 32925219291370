import { Component, OnInit,Input,Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Categorie } from 'src/app/interfaces/support/categorie';
import { Modele } from 'src/app/interfaces/support/modele';
import { SousCategorie } from 'src/app/interfaces/support/sous-categorie';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ModeleFacadeService } from 'src/app/services/modele/modele-facade.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, AsyncPipe } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
    selector: 'app-edit-modele',
    templateUrl: './edit-modele.component.html',
    styleUrls: ['./edit-modele.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, MatInputModule, CKEditorModule, MatButtonModule, AsyncPipe]
})
export class EditModeleComponent implements OnInit {
  @Output() resultValidateEvent = new EventEmitter<boolean>() ;
  @Input() modele             : Modele = <Modele>{};
  ModeleFormGroup!            : FormGroup;
  sousCategories              : SousCategorie[]=[];
  
  public editor = ClassicEditor

  constructor(public apiModeleFacade  : ModeleFacadeService
            , private _fb             : FormBuilder
            ) { }

  ngOnInit(): void {
    this.initForm()
    this.onEditer()
  }

  initForm(){
    this.ModeleFormGroup        = this._fb.group({
      modeleText      : ['', [Validators.required]],
      idCategorie     : ['',[Validators.required]],
      idSousCategorie : ['',[Validators.required]],
      nom             : ['',[Validators.required]],
      code            : ['']
    })
  }
  
  ngOnChanges(changes:SimpleChanges){
    this.initForm()
    this.onEditer()
  }

  getSousCategories(idCategorie:number){
    this.sousCategories = this.apiModeleFacade.getSousCategoriesFromCategorie(idCategorie)
  }

  onEditer(){
    let idCategorie     : number = 0;
    let idSousCategorie : number = 0;

    //il faut rechercher la catégorie et la sous-catégorie de ce modèle
    const sousCategorie= this.apiModeleFacade.sousCategories$.getValue().find(sousCategorie => sousCategorie.sca_id === (this.modele? this.modele.mdl_sou_cat_id:0))
    idCategorie       = sousCategorie? sousCategorie.sca_cat_id : 0
    idSousCategorie   = sousCategorie? sousCategorie.sca_id : 0 
    //on recharge la liste des sous-catégories pour le modèle édité:
    this.sousCategories = this.apiModeleFacade.getSousCategoriesFromCategorie(idCategorie)
  
    this.ModeleFormGroup.patchValue({
        modeleText        : this.modele? this.modele.mdl_contenu : '',
        idCategorie       : this.modele? idCategorie : idCategorie,
        idSousCategorie   : this.modele? this.modele.mdl_sou_cat_id : idSousCategorie,
        nom               : this.modele? this.modele.mdl_nom : '',
        code              : this.modele? this.modele.mdl_code : ''
      }
    )
  }

  onValiderModele(){
    this.modele.mdl_contenu    = this.ModeleFormGroup.controls.modeleText.value
    this.modele.mdl_nom        = this.ModeleFormGroup.controls.nom.value
    this.modele.mdl_sou_cat_id = this.ModeleFormGroup.controls.idSousCategorie.value
    this.modele.mdl_code       = this.ModeleFormGroup.controls.code.value
    
    
    if (this.modele.mdl_id !=0) {
      //modification du modèle
      this.apiModeleFacade.modifierModele(this.modele)
    }else{
      //création d'un modèle
      this.apiModeleFacade.creerModele(this.modele)
      }
      
      this.resultValidateEvent.emit(true)

  }
  onAnnule(){
    this.resultValidateEvent.emit(true)
  }

  trackByIdCategorie(index:number, item:Categorie){
    return item.cat_id
  }

  trackByIdSousCategorie(index:number, item:SousCategorie){
    return item.sca_id
  }
}
