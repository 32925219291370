<mat-card appearance="outlined" class="mat-card-no-shadow"  style="padding: 0px;">
    <form [formGroup]="FiltreFormGroup">
        <mat-expansion-panel id="expandFilter" [(expanded)]="expandPannel" #expandFilter> 

            <mat-expansion-panel-header style="background-color: #fff; padding-top: 3px; padding-bottom: 3px;">
                <mat-panel-title class="label-accent">
                    <mat-icon>search</mat-icon>Veuillez sélectionner un état et les éventuelles options de votre recherche...
                </mat-panel-title>
            </mat-expansion-panel-header>                                

            <mat-form-field class="formDate">
                <mat-label>Date de début</mat-label>
                <input matInput type="date" id="date_debut" formControlName="date_debut" required>
                <mat-error *ngIf="date_debut?.invalid && (date_debut?.dirty || date_debut?.touched)">{{date_debut?.errors?.erreur }}</mat-error>
            </mat-form-field>
    
            <mat-form-field class="formDate">
                <mat-label>Date de fin</mat-label>
                <input matInput type="date" id="date_fin" formControlName="date_fin" required>
                <mat-error *ngIf="date_fin?.invalid && (date_fin?.dirty || date_fin?.touched)">{{ date_fin?.errors?.erreur }}</mat-error>
            </mat-form-field>
            <div *ngIf="entiteEnCours==null">

                <mat-form-field>
                    <mat-label>N° Lot</mat-label>
                    <input matInput type="text" id="numero_lot" formControlName="numero_lot">
                    <mat-error *ngIf="numero_lot?.invalid && (numero_lot?.dirty || numero_lot?.touched)">{{ numero_lot?.errors?.erreur }}</mat-error>
                </mat-form-field>
    
                <button mat-raised-button (click)="searchingEntite()" color="primary" style="margin: 10px;">Rechercher une entite</button>
                <app-entite-search *ngIf="searching" (entiteEvent)="setEntite($event)"></app-entite-search>
                <div *ngIf="entiteSelected" class="entite">
                    <div style="margin-right: 15px;">
                        {{entiteSelected.raison_sociale}}
                    </div>
                    <button mat-mini-fab (click)="cancelEntite()"  matTooltip="Supprimer l'entité de la recherche"><mat-icon>close</mat-icon></button>
                </div>
    
                <mat-form-field>
                    <mat-label>IBAN</mat-label>
                    <input matInput type="text" id="iban" formControlName="iban">
                    <mat-error *ngIf="iban?.invalid && (iban?.dirty || iban?.touched)">{{ iban?.errors?.erreur }}</mat-error>
                </mat-form-field>
    
                <mat-form-field>
                    <mat-label>Référence bout en bout</mat-label>
                    <input matInput type="text" id="reference_bout_en_bout" formControlName="reference_bout_en_bout">
                    <mat-error *ngIf="reference_bout_en_bout?.invalid && (reference_bout_en_bout?.dirty || reference_bout_en_bout?.touched)">{{ reference_bout_en_bout?.errors?.erreur }}</mat-error>
                </mat-form-field>
    
                <mat-form-field>
                    <mat-label>Référence mandat</mat-label>
                    <input matInput type="text" id="reference_mandat" formControlName="reference_mandat">
                    <mat-error *ngIf="reference_mandat?.invalid && (reference_mandat?.dirty || reference_mandat?.touched)">{{ reference_mandat?.errors?.erreur }}</mat-error>
                </mat-form-field>
            </div>
           
            <mat-form-field style="max-width: 350px;" >
                <mat-label>Type de paiement</mat-label>
                <mat-select 
                    formControlName="idModePaiement"
                >
                    <mat-option value="0">Tous</mat-option>
                    <mat-option 
                        *ngFor  = "let modePaiement of modesPaiement" 
                        [value]   = "modePaiement.id"
                        >{{modePaiement.libelle}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-action-row>    
                <div class="div-container-between" style="padding: 8px;">
                    <div class="div-container-center" style="width:100%; display: flex; justify-content: center;">
                        <button mat-flat-button 
                                *ngIf       = "!waitingResearch"
                                submit 
                                color       = "primary" 
                                [disabled]  = "waitingResearch || !FiltreFormGroup.valid" 
                                (click)     = "onSearchPrelevements(true)">
                            <mat-icon>search</mat-icon>Rechercher                                    
                        </button>
                        <mat-spinner diameter="24" *ngIf="waitingResearch"></mat-spinner>
                    </div>
                </div>
            </mat-action-row>
        </mat-expansion-panel>                
    </form>
</mat-card>
