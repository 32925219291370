import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNom]',
  standalone:true
})
export class NomDirective {

  constructor(private el: ElementRef) { }

  @HostListener('keyup') onKeyUp() {
    let value = this.el.nativeElement.value
    
    if (value) {
        value = value.replace(/[^a-zA-Z -]*/g, '')
        this.el.nativeElement.value = value;
   }
  }

}