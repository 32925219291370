import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { GroupeService } from 'src/app/components/groupe/data-access/groupe.service';
import { Groupe } from 'src/app/components/groupe/groupe-interface';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiUtilisateurService } from 'src/app/services/api-utilisateur.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { ApiUtilitaireService } from 'src/app/services/tools/api-utilitaire.service';
import { TitleCaseDirective } from '../../../../directive/title-case.directive';
import { ContexteService, TYPE_UTILISATEUR } from 'src/app/services/contexte.service';

@Component({
    selector: 'app-filtre-liste-utilisateurs',
    templateUrl: './filtre-liste-utilisateurs.component.html',
    styleUrls: ['./filtre-liste-utilisateurs.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatExpansionModule, MatIconModule, MatFormFieldModule, MatInputModule
              , NgIf, TitleCaseDirective, MatSelectModule, NgFor, MatOptionModule, MatButtonModule, MatProgressSpinnerModule]
})
export class FiltreListeUtilisateursComponent implements OnInit {

  // Combo type 
  groupes              : any[] = [];
  
  // Données de sorties
  @Output() newItemEvent = new EventEmitter<Array<ProfilUtilisateur>>();

  // Gestion de l'affichage
  expandPannel    : boolean = true;
  waitingResearch : boolean = false;
    
  // Formulaire de filtrage
  FiltreFormGroup: FormGroup;
  readonly type      : string = TYPE_UTILISATEUR
  // Accesseur en lecture des formControl
  get groupe()           : FormArray  { return <FormArray>this.FiltreFormGroup.get('groupe') as FormArray }
  get nom()             { return this.FiltreFormGroup.get('nom'); }
  get prenom()          { return this.FiltreFormGroup.get('prenom'); }
  get agrement()        { return this.FiltreFormGroup.get('agrement'); }
  get idcontact()       { return this.FiltreFormGroup.get('idcontact'); }
  get habilitation()    { return this.FiltreFormGroup.get('habilitation'); }

  constructor(
      private _fb                 : FormBuilder
    , private apiUtilisateur      : ApiUtilisateurService
    , private apiUtilitaire       : ApiUtilitaireService
    , private apiAuthentification : ApiAuthentificationService
    , private toast               : PersoSnackbarService
    , private apiGroupe           : GroupeService
    , private contexte            : ContexteService
  ) {
    // Déclaration du formulaire
    this.FiltreFormGroup = this._fb.group({
      nom       : ['', ''],
      prenom    : ['', ''],
      agrement  : ['', ''],
      groupe      : [[], [Validators.required]],
      idcontact : ['',''],
      habilitation: ['','']
    })
    
    // Subscribe here, this will automatically update 
    // "isDataModify" whenever a change to the subject is made.
    this.apiUtilisateur.isDataModify.subscribe( (value: boolean) => {
      if(value) {
        this.apiUtilisateur.isDataModify.next(false);
        this.onSearchingUtilisateurs();
      }
    });
  }

  ngOnInit(): void {
    // Récupération des paramètres du formulaire de filtrage
   
    this.addNewItem(this.contexte.getTableData(this.type))
    this.chargeFiltre()
  }

  initFilter(){
    const filtre = this.contexte.getFiltre(this.type)
    if (filtre!=null) {
      this.FiltreFormGroup.setValue(filtre)
      //Artifice pour récupérer les groupes sauvegardés:
      const savegroups = this.contexte.getFiltreListe(this.type) 
      const selectedgroups    = this.groupes.filter((groupe:any)=> savegroups.find((group:any)=>group.id===groupe.id) )
      this.groupe.patchValue(selectedgroups)
    }
  }

  chargeFiltre(): void {
    
    this.apiGroupe.getGroupes(this.apiAuthentification.GetEnvironmentCode())
    .subscribe(
      (data: Groupe[]) => {
        this.groupes = data.map((groupe)=> {return {'id':groupe.id,'nom':groupe.nom}})
        // this.groupe.patchValue(this.contexte.getFiltreListe(this.type))
        
        
        this.initFilter()
      },
      (err: any) => {
        console.log('err getGroupes() ' + err.error);
      }
    );
  }

  onSearchingUtilisateurs() : void {
    this.waitingResearch = true;
    
    this.apiUtilisateur.postListeUtilisateurParEnvironnement(this.apiUtilitaire.CONST_CODE_ENVIRONNEMENT_EXTRANET_FFEA, this.FiltreFormGroup.value)
    .subscribe(
      (data: ProfilUtilisateur[]) => {
        this.addNewItem(data);
        this.toast.showInfo('Recherche terminée');
        this.waitingResearch = false;
        this.expandPannel = false;
      },
      (err: any) => {
        console.error('/!\ err: ', err.message);
        this.waitingResearch = false;
      });
  }

  public addNewItem(value: Array<ProfilUtilisateur>) {
    this.newItemEvent.emit(value);
  }

  ngOnDestroy(){
    this.contexte.setFiltreListe(this.groupe.value)
    this.contexte.setType(this.type)
    this.contexte.setFiltre(this.FiltreFormGroup.value) 
  }

}
