<mat-toolbar *ngIf="profilUtilisateur.droits_utilisateur.droit_module_admin_compta == true" class="navbar">
    <div class="div-container-between" >
        <div style="width: 180px;">
            <a mat-button  *ngIf="true" class="label-accent"><mat-icon >account_balance</mat-icon>Comptabilité</a>
        </div>
        <div class="div-container-left" style="width: 80%;">

        </div>
        
        <div class="label-accent" style="padding-right: 8px; font-size: 14px;">
            {{ dateDuJour | date: 'EEEE d MMMM, y' }}
        </div>
    </div>
    
</mat-toolbar>

<mat-card appearance="outlined" class="mat-card-no-shadow" style="padding: 0px; margin-top: 16px; margin-bottom: 16px;">
    <form [formGroup]="FiltreFormGroup">
        <mat-expansion-panel id="expandFilter" [(expanded)]="expandPannel" #expandFilter>            
            <mat-expansion-panel-header>
                <mat-panel-title class="label-accent">
                    <mat-icon>import_export</mat-icon>
                    <p>Exporter les factures en comptabilité du mois de ...</p>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div>
                <mat-form-field>
                    <mat-label>Mois</mat-label>
                    <mat-select name="mois" id="mois" formControlName="mois">
                        <mat-option></mat-option>
                        <mat-option *ngFor="let item of moi$" [value]="item">{{ item }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Année</mat-label>
                    <mat-select name="annee" id="annee" formControlName="annee">
                        <mat-option></mat-option>
                        <mat-option *ngFor="let item of annee$" [value]="item">{{ item }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <mat-action-row>
                <div class="div-container" style="padding: 8px; width: 100%;">
                    <div class="button-container">
                        
                        <button 
                            mat-raised-button 
                            submit 
                            *ngIf       = "!waitingExport"
                            color       = "primary" 
                            style       = "margin-left: 0px!important;"
                            title       = "Exporter les factures selon le mois et critères de recherches saisie"
                            [disabled]  = "waitingExport"
                            (click)     = "onExportInvoice()"
                        >
                        <mat-icon>import_export</mat-icon>Exporter les factures
                        </button>
                        <mat-spinner diameter="24" *ngIf="waitingExport"></mat-spinner>
                    </div>
                </div>
            </mat-action-row>
        </mat-expansion-panel>
    </form>
</mat-card>
<mat-card appearance="outlined" class="mat-card-no-shadow" style="padding: 0px; margin-top: 16px; margin-bottom: 16px;">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon color="primary">file</mat-icon>
                            <span style="width: 220px;" *ngIf="export$ != undefined"><span class="span-primary">{{ export$.length }}</span>EXPORT<span *ngIf="export$.length > 1">S</span></span>
                            <span style="width: 220px;" *ngIf="export$ == undefined"><span class="span-primary">0</span>EXPORT</span>                                                                                                                     
                        </mat-panel-title>
                        <mat-panel-description></mat-panel-description>
                    </mat-expansion-panel-header>

            <mat-card-subtitle>Mon dernier export</mat-card-subtitle>

            <div class="container" style="width: 100%;">
                <table mat-table [dataSource]="export$" class="table-primary">

                    <!-- Service Column -->
                    <ng-container matColumnDef="icone">
                        <th mat-header-cell *matHeaderCellDef  class="premiere-colonne">  </th>
                        <td mat-cell *matCellDef="let export" [ngStyle]="{ 'color': colorLigne }">
                            <mat-icon color="primary">{{ getIconeService(export.numeroFacture) }}</mat-icon>
                        </td>
                    </ng-container>

                    <!-- Service Column -->
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef> Date </th>
                        <td mat-cell *matCellDef="let export" [ngStyle]="{ 'color': colorLigne }">
                            {{ export.date }}
                        </td>
                    </ng-container>

                    <!-- Service Column -->
                    <ng-container matColumnDef="numeroFacture">
                        <th mat-header-cell *matHeaderCellDef> N° </th>
                        <td mat-cell *matCellDef="let export" [ngStyle]="{ 'color': colorLigne }">
                            {{ export.numeroFacture }}
                        </td>
                    </ng-container>

                    <!-- Service Column -->
                    <ng-container matColumnDef="cpt_general">
                        <th mat-header-cell *matHeaderCellDef> Cpte General </th>
                        <td mat-cell *matCellDef="let export" [ngStyle]="{ 'color': colorLigne }">
                            {{ export.cpt_general }}
                        </td>
                    </ng-container>

                    <!-- Service Column -->
                    <ng-container matColumnDef="cpt_tiers">
                        <th mat-header-cell *matHeaderCellDef> Cpte Tiers </th>
                        <td mat-cell *matCellDef="let export" [ngStyle]="{ 'color': colorLigne }">
                            {{ export.cpt_tiers }}
                        </td>
                    </ng-container>

                    <!-- Service Column -->
                    <ng-container matColumnDef="cabinet">
                        <th mat-header-cell *matHeaderCellDef> Cabinet </th>
                        <td mat-cell *matCellDef="let export" [ngStyle]="{ 'color': colorLigne }">
                            {{ export.cabinet }}
                        </td>
                    </ng-container>

                    <!-- Service Column -->
                    <ng-container matColumnDef="montant_ht">
                        <th mat-header-cell *matHeaderCellDef> Montant HT </th>
                        <td mat-cell *matCellDef="let export" [ngStyle]="{ 'color': colorLigne }" style="text-align: right; width: 80px;">
                            {{ export.montant_ht == 0 ? '' : export.montant_ht | number: '1.2-2' }} 
                        </td>
                    </ng-container>

                    <!-- Service Column -->
                    <ng-container matColumnDef="remise_ht">
                        <th mat-header-cell *matHeaderCellDef> Remise </th>
                        <td mat-cell *matCellDef="let export" [ngStyle]="{ 'color': colorLigne }" style="text-align: right; width: 80px;">
                            {{ export.remise_ht == 0 ? '' : export.remise_ht | number: '1.2-2' }}
                        </td>
                    </ng-container>

                    <!-- Service Column -->
                    <ng-container matColumnDef="montant_total_ht">
                        <th mat-header-cell *matHeaderCellDef> Total HT </th>
                        <td mat-cell *matCellDef="let export" [ngStyle]="{ 'color': colorLigne }" style="text-align: right; width: 80px;">
                            {{ export.montant_total_ht == 0 ? '' : export.montant_total_ht | number: '1.2-2' }}
                        </td>
                    </ng-container>

                    <!-- Service Column -->
                    <ng-container matColumnDef="port_ht">
                        <th mat-header-cell *matHeaderCellDef> Port HT </th>
                        <td mat-cell *matCellDef="let export" [ngStyle]="{ 'color': colorLigne }" style="text-align: right; width: 80px;">
                            {{ export.port_ht == 0 ? '' : export.port_ht | number: '1.2-2' }}
                        </td>
                    </ng-container>

                    <!-- Service Column -->
                    <ng-container matColumnDef="montant_tva">
                        <th mat-header-cell *matHeaderCellDef> Montant TVA </th>
                        <td mat-cell *matCellDef="let export" [ngStyle]="{ 'color': colorLigne }" style="text-align: right; width: 80px;">
                            {{ export.montant_tva == 0 ? '' : export.montant_tva | number: '1.2-2' }}
                        </td>
                    </ng-container>

                    <!-- Service Column -->
                    <ng-container matColumnDef="debit">
                        <th mat-header-cell *matHeaderCellDef> Débit </th>
                        <td mat-cell *matCellDef="let export" [ngStyle]="{ 'color': colorLigne }" style="text-align: right; width: 80px;">
                            {{ export.debit == 0 ? '' : export.debit | number: '1.2-2' }}
                        </td>
                    </ng-container>

                    <!-- Service Column -->
                    <ng-container matColumnDef="credit">
                        <th mat-header-cell *matHeaderCellDef  class="derniere-colonne"> Crédit </th>
                        <td mat-cell *matCellDef="let export" [ngStyle]="{ 'color': colorLigne }" style="text-align: right; width: 80px;">
                            {{ export.credit == 0 ? '' : export.credit | number: '1.2-2' }}
                        </td>
                    </ng-container>

                    

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </mat-expansion-panel>
</mat-card>