<ng-container *ngIf="isMobileFormat(); then template_small_screen else template_full_screen"></ng-container>

<!-- Template pour petit écran -->
<ng-template #template_small_screen>
    <mat-card appearance="outlined" *ngIf="tockenValide && nouvelAdherent == false" style="margin-top: 100px;">
        <mat-card-title>Nouveau collaborateur -  {{ invitation.entite.raison_sociale }}</mat-card-title>
        <br>
        <mat-card-content class=".mat-card-content">
            <span>                
                {{ invitation.destinataire.nom }} {{ invitation.destinataire.prenom }}, le cabinet {{ invitation.entite.raison_sociale }} vous invite a rejoindre son cabinet d'expertise en tant {{ (invitation.dirigeant ? 'que dirigeant' : 'qu\'expert') }}.<br><br>
                Pour confirmer votre invitation, cliquer sur le bouton "Accepter", sinon sur le bouton "Refuser". 
            </span>
        </mat-card-content> 

        <mat-card-actions>
            <div class="button">                
                <button 
                    *ngIf="!waiting"
                    mat-raised-button 
                    color   = "warn" 
                    style   = "margin-left: 10px; margin-bottom: 16px; margin-right: 8px;" 
                    (click) = "refuserInvitation()"
                    >
                    <mat-icon>close</mat-icon>
                    <span>Refuser</span>
                </button>
                <mat-spinner 
                    *ngIf="waiting"
                    diameter="24">
                </mat-spinner>
                <button 
                    mat-raised-button 
                    *ngIf   = "!waiting"
                    color   = "primary" 
                    style   = "margin-left: 10px; margin-bottom: 16px;" 
                    (click) = "accepterInvitation()"
                    >
                    <mat-icon>done</mat-icon>
                    <span>Accepter</span>
                </button>
            </div>
        </mat-card-actions>        
    </mat-card>
</ng-template>

<!-- Template pour grand écran -->
<ng-template #template_full_screen class="host">
    <mat-card appearance="outlined" *ngIf="tockenValide  && nouvelAdherent == false" style="margin-top: 100px;">
        <mat-card-title>Nouveau collaborateur - {{ invitation.entite.raison_sociale }}</mat-card-title>
        <br>
        <mat-card-content class=".mat-card-content">
            <span>
                {{ invitation.destinataire.prenom }} {{ invitation.destinataire.nom }}, le cabinet {{ invitation.entite.raison_sociale }} vous invite a rejoindre son cabinet d'expertise
                <span *ngIf="invitation.invSuccursales===true">ainsi que toutes ses succursales</span> en tant {{ (invitation.dirigeant ? 'que dirigeant' : 'qu\'expert') }}.<br><br>
                Pour confirmer votre invitation, cliquer sur le bouton "Accepter", sinon sur le bouton "Refuser". 
            </span>
        </mat-card-content> 

        <mat-card-actions>
            <div class="button">                
                <button 
                    *ngIf="!waiting"
                    mat-raised-button 
                    color   = "warn" 
                    style   = "margin-left: 10px; margin-bottom: 16px;" 
                    (click) = "refuserInvitation()"
                    >
                    <mat-icon>close</mat-icon>
                    <span>Refuser</span>
                </button>
                <button *ngIf   = "!waiting" mat-raised-button color="primary" style="margin-left: 10px; margin-bottom: 16px;" (click)="accepterInvitation()">
                    <mat-icon>done</mat-icon>
                    <span>Accepter</span>
                </button>
                <mat-spinner
                    style="margin-left: 20px;" 
                    *ngIf="waiting"
                    diameter="24">
                </mat-spinner>

            </div>
        </mat-card-actions>
    </mat-card>
</ng-template>

<app-inscription-collaborateur [invitation]="this.invitation" *ngIf="nouvelAdherent == true"></app-inscription-collaborateur>