<h1 mat-dialog-title>Enregistrement des coordonnées bancaires</h1>
<form [formGroup]="formBancaire" action="">
    <mat-dialog-content  style="max-width: 650px;">
        <div>
            Attention, l’utilisation du bouton ENREGISTRER lancera un nouveau processus de validation de vos informations bancaires. 
            Durant cette période, la facturation sur cette entité ne sera pas possible.
            Il sera nécessaire d’attendre une nouvelle validation de vos informations bancaires par notre service comptable.
        </div>
        
        <input  
            #UploadIBAN
            type        = "file" single accept=".jpg,.png,.pdf" 
            class       = "file-input" 
            placeholder = "Sélectionnez votre fichier..."
            (change)    = "onClickUploadIBAN($event)"
        >
        <input #UploadRUM
            single accept=".jpg,.png,.pdf"
            type="file" 
            class="file-input" 
            (change)="onClickUploadRUM($event)" 
            placeholder="Sélectionnez votre fichier..."
        >           

        <div class="div-container-left" >
                <button 
                    mat-mini-fab 
                    color   = "accent" 
                    (click) = "UploadIBAN.click()" 
                >
                    <mat-icon><span class="material-symbols-outlined">
                        attach_file
                        </span>
                    </mat-icon>
                </button>
            <mat-form-field>
                <mat-label for="text">IBAN</mat-label>
                <input matInput type="text" placeholder="Numéro IBAN" name="iban" formControlName="iban">
                <mat-error *ngIf="iban?.invalid && (iban?.dirty || iban?.touched)">{{ iban?.errors?.erreur }}</mat-error>
            </mat-form-field>
        </div>
        <div class="div-container-left" >
            <mat-form-field>
                <mat-label for="text">BIC</mat-label>
                <input matInput type="text" placeholder="Numéro BIC" name="bic" formControlName="bic">
                <mat-error *ngIf="bic?.invalid && (bic?.dirty || bic?.touched)">{{ bic?.errors?.erreur }}</mat-error>
            </mat-form-field>
        </div>

        <div>
        <span [ngStyle]="{  color : CoordonneeBancaire$.nom_fichier_iban != '' ? '#273a82' : '#FF4081' }">
            {{ IBANFile || CoordonneeBancaire$.nom_fichier_iban != '' ? CoordonneeBancaire$.nom_fichier_iban : "Vous devez associer un fichier IBAN pour valider votre saisi." }}
            <br>
            <mat-hint>5Mo max.</mat-hint>
        </span>

        <div class="div-container-left" style="margin-top: 20px;">
            <button 
                mat-mini-fab 
                color   = "accent" 
                class   = "btn-upload" 
                (click) = "UploadRUM.click()" 
            >
                <mat-icon>attach_file</mat-icon>
            </button>
            <mat-form-field>
                <mat-label for="text">Numéro de référence unique de mandat (RUM)</mat-label>
                <input 
                        matInput 
                        type            = "text" 
                        placeholder     = "Numéro RUM" 
                        name            = "rum" 
                        formControlName = "rum"
                        minlength       = "3"
                        maxlength       = "35"
                        style           = "text-transform:uppercase"
                        [required]      = "mandatRequired"
                        >
                <mat-error *ngIf="rum?.invalid && (rum?.dirty || rum?.touched)">{{ rum?.errors?.erreur }}</mat-error>
            </mat-form-field>
            <mat-form-field style="max-width: 150px;">
                <mat-label for="text">Date du mandat</mat-label>
                <input 
                    matInput 
                    type            = "date" 
                    placeholder     = "Date de mandat RUM" 
                    name            = "date_mandat" 
                    formControlName = "date_mandat" 
                    [required]      = "mandatRequired"
                    >
                <mat-error *ngIf="date_mandat?.invalid && (date_mandat?.dirty || date_mandat?.touched)">Date de mandat obligatoire</mat-error>
            </mat-form-field>

        </div>
                    <span [ngStyle]="{  color : CoordonneeBancaire$.nom_fichier_rum != '' ? '#273a82' : '#FF4081' }">
                        {{ RUMFile || CoordonneeBancaire$.nom_fichier_rum != '' ? CoordonneeBancaire$.nom_fichier_rum : "Vous devez associer un fichier RUM pour valider votre saisi." }}
                        <br>
                        <mat-hint>5Mo max.</mat-hint>
                    </span>
            
        </div>
            
          
    </mat-dialog-content>
</form>
<mat-dialog-actions align="end">
  <button mat-raised-button [mat-dialog-close]="false" mat-dialog-close cdkFocusInitial>Annuler</button>
  <button mat-raised-button color="primary" (click)="enregistreData()" [disabled]="!formBancaire.valid">Enregistrer</button>
</mat-dialog-actions>
