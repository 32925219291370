<mat-card appearance="outlined" (window:resize)="onResize($event)">
    <form [formGroup]="entiteForm">
        <mat-card-header>
            <mat-card-title>Création d'un cabinet</mat-card-title>
        </mat-card-header>
        <mat-card-content  class=".mat-card-content">
            <div class="content">
                <div class="titlebox">Informations Générales</div>

                <div class="div-container-left">
                    <mat-form-field >
                        <mat-label>Type d'entité</mat-label>
                        <mat-select
                            formControlName="typeEntite" 
                            (selectionChange)="setTypeEntite($event.value)"
                            required
                            >
                            <mat-option *ngFor="let type of typesEntite" [value]="type" (select)="setTypeEntite(type)">{{ type.libelle }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="typeEntite?.hasError('required')">La sélection d'un type d'entité est obligatoire</mat-error>
                    </mat-form-field>
    
                    
                    <mat-form-field *ngIf="typeEntiteSelected.code!='ADELA'">
                        <mat-label >Habilitation N°</mat-label>
                        <input  matInput 
                                type            = "text" 
                                formControlName = "habilitation" 
                                placeholder     = "Numéro d'habilitation"
                                maxlength       = "10"
                                >
                            <mat-error *ngIf="habilitation?.hasError('required')">Le numéro d'habilitation est obligatoire</mat-error>
                    </mat-form-field>
                </div>
                
                <app-siren controlKey="groupSiren" (adresseEvent) = "setAdresse($event)"></app-siren>
                <br>
                <app-adresse controlKey = "groupAdresse" [adresse]="adresse"></app-adresse>
                <br>
                <div class="div-container-left">
                    <mat-form-field style="width: 30%;">
                        <mat-label for="tva">TVA</mat-label>
                        <mat-select formControlName="tva">
                            <mat-option *ngFor="let item of tvas" [value]="item.id">{{ item.libelle }} - {{ item.pourcentage }}%</mat-option>
                        </mat-select>
                        
                        <mat-error *ngIf="tva?.invalid && (tva?.dirty || tva?.touched)">La TVA est obligatoire</mat-error>
                    </mat-form-field>
                    <mat-hint>ATTENTION : Pour la TVA, sélectionnez "FR - 20%" pour les cabinets localisés en France métropolitaine, "DOM - 5,5%" pour les cabinets localisés dans les DOM ou "PAS - 0%" pour les cabinets sans TVA.<br/>
                        Ces informations seront contrôlées par la FFEA.
                    </mat-hint>
                </div>
                <div class="div-container-left">
                    <mat-form-field>
                        <mat-label >mail facturation</mat-label>
                        <input  matInput 
                                type            = "email" 
                                formControlName = "mailfacturation" 
                                placeholder     = "adresse mail facturation"
                                oninput         = "this.value=this.value.trim();"
                                >
                            <mat-error *ngIf="mailfacturation?.hasError('required')">L'adresse email obligatoire</mat-error>
                            <mat-error *ngIf="mailfacturation?.hasError('checkValidatorMail')">{{ mailfacturation?.errors?.checkValidatorMail }}</mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label >mail contact</mat-label>
                        <input  matInput 
                                type            = "email" 
                                formControlName = "mailcontact" 
                                placeholder     = "adresse mail contact"
                                oninput         = "this.value=this.value.trim();"
                                >
                            <mat-error *ngIf="mailcontact?.hasError('required')">L'adresse email obligatoire</mat-error>
                            <mat-error *ngIf="mailcontact?.hasError('checkValidatorMail')">{{ mailcontact?.errors?.checkValidatorMail }}</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="content">
                <div class="titlebox">Dirigeant</div>
                <div class="div-container-left">

                    <mat-form-field id="groupeUti">
                        <mat-label>Groupe d'utilisateurs</mat-label>
                        <mat-select required
                                    formControlName = "groupe"
                                    placeholder     = "Groupe d'utilisateurs"
                                    >
                            <mat-option *ngFor="let groupe of groupesDisplayed" [value]="groupe.id" >
                                {{ groupe.nom }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="groupe?.hasError('required')">La sélection d'un groupe d'utilisateur est obligatoire</mat-error>
                    </mat-form-field>

                    <mat-form-field id="agrement">
                            <mat-label for="agrement">Agrément</mat-label>
                            <input  matInput
                                    type            = "text"
                                    formControlName = "agrement" 
                                    placeholder     = "XXXXXX-VE" 
                                    maxlength       = "9"
                                    oninput         = "this.value = this.value.toUpperCase()">
                            <mat-error *ngIf="agrement?.hasError('required')">Le numéro d'agrément VE est obligatoire</mat-error>
                            <mat-error *ngIf="agrement?.hasError('formatAgrementVE')">{{ agrement?.errors?.formatAgrementVE }}</mat-error>
                    </mat-form-field>
                </div>
                
                <div class="div-container-left">
                    <mat-form-field>
                        <mat-label>Nom </mat-label>
                        <input matInput 
                                type            = "text" 
                                required
                                formControlName = "nom" 
                                placeholder     = "Nom"
                                oninput         = "this.value = this.value.toUpperCase()"
                                maxlength       = "50"
                                >
                            <mat-error *ngIf="nom?.hasError('required')">Le nom du dirigeant est obligatoire</mat-error>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label for="prenom">Prénom </mat-label>
                        <input  matInput
                                appTitleCase
                                type            = "text" 
                                required
                                formControlName = "prenom" 
                                placeholder     = "Prénom"
                                minlength       = "3"
                                maxlength       = "50"
                                >
                            <mat-error *ngIf="prenom?.hasError('required')">Le prénom du dirigeant est obligatoire</mat-error>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label >Email</mat-label>
                        <input  matInput 
                                type            = "email" 
                                required
                                formControlName = "mail"
                                placeholder     = "Email"
                                oninput         = "this.value=this.value.trim();">
                        <mat-error *ngIf="mail?.hasError('required')">L'adresse email obligatoire</mat-error>
                        <mat-error *ngIf="mail?.hasError('checkValidatorMail')">{{ mail?.errors?.checkValidatorMail }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content> 
    </form>

    <mat-card-actions align="end">
        <button mat-raised-button 
                color       = "primary"
                (click)     = "annuler()"
                >
                <mat-icon>west</mat-icon><span class="span-button">Annuler</span> 
        </button>

        <button
                mat-raised-button 
                color       = "primary"
                [disabled]  = "entiteForm.invalid"
                (click)     = "enregistrer()"
                >
                <ng-container *ngIf="waiting; then spinner else Save"></ng-container>
                <ng-template #spinner><div class="div-container"><mat-spinner class="mat-spinner" diameter="24"></mat-spinner><span>Enregistrer</span></div></ng-template>
                <ng-template #Save><div class="div-container"><mat-icon width="24">save</mat-icon><span>Enregistrer</span></div></ng-template>
        </button>

    </mat-card-actions>
</mat-card>
