<mat-card appearance="outlined" class="mat-card-no-shadow" style="padding: 0px;">
    <form [formGroup]="FiltreFormGroup">
        <mat-expansion-panel id="expandFilter" [(expanded)]="expandPannel" #expandFilter>            
            <mat-expansion-panel-header style="background-color: #fff">
                <mat-panel-title class="label-accent">
                    <mat-icon>search</mat-icon>Veuillez sélectionner les options de votre recherche...
                </mat-panel-title>
            </mat-expansion-panel-header>                                     

            <div class="div-container-left">
                <mat-form-field style="max-width: 300px;">
                    <mat-label>Type recherche</mat-label>
                    <mat-select name="type_recherche" id="type_recherche" formControlName="type_recherche">
                        <mat-option *ngFor="let type_recherche of types_recherche;" [value]="type_recherche.code">
                            {{ type_recherche.libelle }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="!FiltreFormGroup?.valid && FiltreFormGroup.touched">Vous devez sélectionner un type de recherche</mat-error>
                </mat-form-field> 

                <mat-form-field>
                    <mat-label>Codes Postaux</mat-label>
                    <mat-chip-grid #chipList formArrayName="codesPostaux">
                        <mat-chip-row *ngFor="let cp of cpControls?.value" 
                                [removable]="removable" (removed)="remove(cp)">
                        {{cp}}
                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip-row>
                        <input placeholder="Nouvau code postal..."
                                type       = "text"
                                oninput    = "this.value=this.value.replace(/[^0-9]/g,'');"
                                minlength="5"
                                maxlength="5"
                            [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            [matChipInputAddOnBlur]="addOnBlur"
                            (matChipInputTokenEnd)="add($event)">
                    </mat-chip-grid>
                    <!-- <mat-error *ngIf="cpControls?.invalid && (cpControls?.dirty || cpControls?.touched)">{{ cpControls?.errors?.erreur }}</mat-error> -->
                </mat-form-field>
            </div>
            <mat-action-row>
                <div class="div-container-between" style="padding: 8px;">
                    <div  class="div-container-center" style="width:100%; display: flex; justify-content: center;">            
                        <button mat-flat-button 
                                *ngIf       = "!waitingResearch"
                                color       = "primary" 
                                (click)     = "onSearchingStatistiques()" 
                                [disabled]  = "waitingResearch || !FiltreFormGroup.valid">
                            <mat-icon>search</mat-icon>Rechercher
                        </button>
                        <mat-spinner diameter="24" *ngIf = "waitingResearch"></mat-spinner>
                    </div>
                </div>
            </mat-action-row>
        </mat-expansion-panel>                        
    </form>
</mat-card>

<mat-card appearance="outlined" class="mat-card-no-shadow" style="padding: 0px;text-align: center;" >      
    <app-table-mcel *ngFor="let mcel_globale of stats_mcel_globale" [ELEMENT_DATA]="mcel_globale"></app-table-mcel>
</mat-card>
