import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ParametreUtilisateurComponent } from 'src/app/components/profil-user/parametres-utilisateur/fiche/parametre-utilisateur.component';
import { UtilisateurParam } from 'src/app/services/api-utilisateur-param.service';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-parametre-utilisateur-edit',
  templateUrl: './parametre-utilisateur-edit.component.html',
  styleUrls: ['./parametre-utilisateur-edit.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, MatButtonModule, MatInputModule, MatFormFieldModule, MatCardModule, NgIf]
})
export class ParametreUtilisateurEditComponent implements OnInit {

  parametreForm!    : FormGroup
  parametreChanged  : UtilisateurParam = this.data

  constructor( public dialogRef: MatDialogRef<ParametreUtilisateurComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UtilisateurParam
    , private fb :FormBuilder) { }

  ngOnInit(): void {
    this.initForm()
  }

  private initForm() : void {
    this.parametreForm = this.fb.group({
      parametreNote:[this.data.utl_prm_note, [Validators.required]],
      parametreNom:[this.data.utl_prm_nom, [Validators.required]]
    })
  }

  onValidate(){

    this.parametreChanged.utl_prm_nom   = this.parametreForm.controls.parametreNom.value;
    this.parametreChanged.utl_prm_note  = this.parametreForm.controls.parametreNote.value;

    this.dialogRef.close(this.parametreChanged);
  }

  onCancel(){
    this.dialogRef.close(false);
  }

  public autoGrowTextZone(event : any) {
    if (event.target.style.height != null && event.target.style.height != undefined) {
      event.target.style.height = "0px";
      event.target.style.height = (event.target.scrollHeight + 25)+"px";
    }
  }

}
