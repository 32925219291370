import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Contenu } from '../../interfaces/contenu';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatTableResponsiveLargeDirective } from '../../../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';
import { NgIf } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-list-email',
    templateUrl: './list-email.component.html',
    styleUrls: ['./list-email.component.scss'],
    standalone: true,
    imports: [MatCardModule, NgIf, MatTableModule, MatTableResponsiveLargeDirective, MatTableExporterModule, MatButtonModule, MatTooltipModule, MatIconModule, MatPaginatorModule]
})
export class ListEmailComponent implements OnInit {

  @Input() emailsDisplayed$       : Observable<Contenu[]>= new Observable<Contenu[]>()
  @Input() modeEdit               : boolean = false
  @Output() contenuSelected     = new EventEmitter<Contenu>()


  ds      : MatTableDataSource<Contenu> = new MatTableDataSource
 
  displayedColumns    : Array<String> = [ 'note', 'actions'];
  columnNames         = [ 'note', 'actions'];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;
  
  constructor(public  dialog       : MatDialog) { }

  ngOnInit(): void {
       
    this.emailsDisplayed$.subscribe((emails)=>{
      this.ds.data = emails;
    })
  }

  ngAfterViewInit(){
    this.ds.paginator = this.paginator
  }

  editer(contenu:Contenu){
    this.contenuSelected.next(contenu)
  }

}
