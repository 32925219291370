<mat-card appearance="outlined">
    <mat-card-title>
          <div class="div-container-left">
                <mat-icon>settings_applications</mat-icon>
                <span>Administration</span>
          </div>
    </mat-card-title>
    <form [formGroup]="administrationEntiteForm">
        <mat-card-content class="mat-card-content">
            <mat-form-field>
                <mat-label>Type de facturation</mat-label>
                <mat-select formControlName="typeFacturation">
                    <mat-option *ngFor="let typeFacturation of typesFacturation" [value]="typeFacturation">{{ typeFacturation.libelle_type_facturation }}</mat-option>
                </mat-select>
                <mat-error *ngIf="typeFacturation?.hasError('required')">La sélection d'un type de facturation est obligatoire</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label >Compte tiers</mat-label>
                <input 
                    matInput 
                    type              = "text" 
                    formControlName   = "compteTiers" 
                    placeholder       = "Compte tiers">
                    <mat-error *ngIf="compteTiers?.hasError('required')">Le compte tiers est obligatoire</mat-error>
            </mat-form-field>

        </mat-card-content>
        <mat-card-actions align="end">
            <button 
            mat-raised-button
            *ngIf       = "!waiting_save"
            color       = "primary" 
            type        = "submit" 
            [disabled]  = "(administrationEntiteForm.pristine) || (administrationEntiteForm.invalid && administrationEntiteForm.dirty)" 
            (click)     = "enregistreData()"
            >
                <span>Enregistrer les modifications</span>
            </button>
            <mat-spinner diameter="24" *ngIf="waiting_save"></mat-spinner>
        </mat-card-actions>
    </form>
</mat-card>
