<mat-card appearance="outlined" class="mat-card-no-shadow" style="padding: 0px;">
    <form [formGroup]="FiltreFormGroup">
        <mat-expansion-panel id="expandFilter" [(expanded)]="expandPannel" #expandFilter>                                
            <mat-expansion-panel-header style="background-color: #fff">
                <mat-panel-title class="label-accent">
                    <mat-icon>search</mat-icon>Veuillez sélectionner un type et les éventuelles options de votre recherche...
                </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-form-field>
                <mat-label>Nom</mat-label>
                <input matInput type="text" placeholder="Nom" name="nom" id="nom" formControlName="nom" oninput="this.value = this.value.toUpperCase()">
                <mat-error *ngIf="nom?.invalid && (nom?.dirty || nom?.touched)">{{ nom?.errors?.erreur }}</mat-error>
            </mat-form-field> 

            <mat-form-field>
                <mat-label>Prénom</mat-label>
                <input  appTitleCase matInput type="text" placeholder="Prénom" name="prenom" id="prenom" formControlName="prenom">
                <mat-error *ngIf="prenom?.invalid && (prenom?.dirty || prenom?.touched)">{{ prenom?.errors?.erreur }}</mat-error>
            </mat-form-field> 

<!--             <mat-form-field>
                <mat-label>Environnement</mat-label>
                <mat-select required name="environnement" id="environnement" formControlName="tabenvironnement" multiple (selectionChange)="onEnvironmentChange($event)">
                    <mat-option *ngFor="let environnement of environnements" [value]="environnement" >
                        {{ environnement.libelle }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="!FiltreFormGroup?.valid && FiltreFormGroup.touched">Vous devez sélectionner au moins un environnement pour votre recherche</mat-error>
            </mat-form-field>  -->

            <mat-form-field>
                <mat-label>Groupe d'utilisateurs</mat-label>
                <mat-select required name="groupe" id="groupe" formControlName="groupe" multiple>
                    <mat-option *ngFor="let groupe of groupes" [value]="groupe" >
                        {{ groupe.nom }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="!FiltreFormGroup?.valid && FiltreFormGroup.touched">Vous devez sélectionner au moins un groupe d'utilisateurs pour votre recherche</mat-error>
            </mat-form-field> 

            <mat-form-field>
                <mat-label>Services</mat-label>
                <mat-select name="service" id="service" formControlName="tabservice" multiple>
                    <mat-option *ngFor="let service of services" [value]="service" >
                        {{ service.libelle }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="!FiltreFormGroup?.valid && FiltreFormGroup.touched">Vous devez sélectionner au moins un service pour votre recherche</mat-error>                
            </mat-form-field> 
            
            <mat-form-field>
                <mat-label>Services externes</mat-label>
                <mat-select name="serviceexterne" id="serviceexterne" formControlName="tabserviceexterne" multiple>
                        <mat-option *ngFor="let serviceexterne of filteredtabserviceexterneFormData" 
                            [value]="serviceexterne">
                           {{ serviceexterne.libelle }} 
                        </mat-option>
                   
                </mat-select>
            </mat-form-field>
            <mat-action-row>
                <div class="div-container-between" style="padding: 8px;">
                    <div class="div-container-center" style="width:100%; display: flex; justify-content: center;">
                        <button mat-flat-button 
                                *ngIf       = "!waitingResearch"
                                submit 
                                color       = "primary" 
                                [disabled]  = "waitingResearch || !FiltreFormGroup.valid" 
                                (click)     = "onSearchingUtilisateurs()">
                            <mat-icon>search</mat-icon>Rechercher                                    
                        </button>
                        <mat-spinner diameter="24" *ngIf = "waitingResearch"></mat-spinner>
                    </div>
                </div>
            </mat-action-row>
        </mat-expansion-panel>
    </form>
</mat-card>