import { NgClass, NgIf } from '@angular/common';
import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { Parametre } from 'src/app/interfaces/parametre';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiCommandeService } from 'src/app/services/api-commande.service';
import { ApiUtilitaireService } from 'src/app/services/tools/api-utilitaire.service';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, MatMenuModule, MatIconModule, MatExpansionModule, MatListModule, RouterLinkActive, RouterLink, NgClass]
})
export class SidenavComponent implements OnInit {
  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  @Input() serviceFFEA:boolean = false

  opened: boolean = false;
  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  CONST_URL_ADELA       : string = 'ADELA'      as const;
  CONST_URL_ETIQUETTE   : string = 'ETIQUETTE'  as const;
  CONST_URL_PHRASIER    : string = 'PHRASIER'   as const;
  CONST_URL_KITS        : string = 'KITS'       as const;
  CONST_URL_ADELA_TARIF : string = 'ADELA_TARIF'as const;
  CONST_URL_HISTORY     : string = 'HISTORIQUE' as const;
  CONST_URL_STATS_SIV   : string = 'STATS_SIV'  as const;
  CONST_URL_STATS_MCEL  : string = 'STATS_MCEL' as const;
  CONST_URL_COMMANDE    : string = 'COMMANDE'   as const;
  CONST_URL_COMMANDE_KITS: string = 'COMMANDE_KITS' as const;
  CONST_URL_COMMANDE_OUTIL: string = 'COMMANDE_OUTIL' as const;
  CONST_URL_DOSSIERS      : string = 'DOSSIERS' as const;
  CONST_URL_FACTURES      : string = 'FACTURES' as const;
  CONST_URL_ADC           : string = 'ADC'      as const;
  CONST_URL_ADC_TARIF     : string = 'ADC_TARIF'as const;
  
  URL_CARRE_EXPERT_AUTO : string = 'http://www.carre-expert-auto.org/';
  URL_IFOR2A_AGATE      : string = 'https://ifor2aportail.agate-erp.fr/';
  
  utl_profil?           : ProfilUtilisateur;
  classBody             : string = '';

  cmdCategorie          : any = {
    KIT_ADELA : '',
    DIAG      : ''
  };



  constructor(public authUser: ApiAuthentificationService
            , private route: Router
            , private apiCmd: ApiCommandeService
            , public apiParam: ApiUtilitaireService
            ) { }
  
  ngOnInit(): void {
    
    this.utl_profil = this.authUser.userConnect;
    
    if(this.utl_profil?.droits_utilisateur?.droit_module_adc || this.utl_profil?.droits_utilisateur?.droit_module_adela || this.utl_profil?.droits_utilisateur?.droit_module_outil_diagnostic) {
      this.loadCategorieCommande();
    }
    
    this.classBody = ( this.authUser.isServiceFFEA() ? this.authUser.CONST_BODY_SERVICE : this.authUser.CONST_BODY_EXTRANET )
  }
  
  public idUserConnected() {
    if (this.authUser.utilisateur_authentifie == true) { return this.authUser.userConnect.id; }
    else { return undefined; }
  }

  public clickOnAccount(anchor: string = '') {
    this.route.navigate([ '/'+this.authUser.root+'/profil/' +this.idUserConnected()], {fragment: anchor});
  } 

  public isServiceFFEA() {
    return this.authUser.isServiceFFEA();
  }

  public clickOnDisconnect() {
    this.authUser.logOut();         
  } 

  public clickOnAcquittement() {
    this.route.navigate(['/app/acquittement/mes-acquittements']);
  }

  public isVisibleADELA() { 
    if(this.utl_profil?.droits_utilisateur?.est_admin
        || this.utl_profil?.droits_utilisateur?.droit_module_adela)
        {
      return true;
    }        
    return false;
  }

  public isVisibleConcentrateur() { 
    if(this.utl_profil?.droits_utilisateur?.est_admin
      || this.utl_profil?.droits_utilisateur?.droit_module_historique_vehicule
      || this.utl_profil?.droits_utilisateur?.droit_rdea
      || this.utl_profil?.droits_utilisateur?.droit_module_acquittement)
       {
      return true;
    }        
    return false;
  }

  public isVisibleStats() { 
    if(this.utl_profil?.droits_utilisateur?.droit_module_statistiques){
      return true;
    }        
    return false;
  }

  public isVisibleADC() { 
    return false;
  }

  public isVisibleTicket(){
    return true;
  }
  
  public openPage(goToPage: string) {    
    switch (goToPage) {
      case this.CONST_URL_ADELA:
        this.route.navigate(['/app/adela']);
        break;
      
      case this.CONST_URL_ETIQUETTE:
        this.route.navigate(['/app/adela/etiquette']);
        break;
          
      case this.CONST_URL_PHRASIER:
        this.route.navigate(['/app/phrasier']);
        break;

      case this.CONST_URL_KITS:
        this.route.navigate(['/app/article/kits', this.cmdCategorie.KIT_ADELA]);
        break;

      case this.CONST_URL_ADELA_TARIF:
        this.route.navigate(['/app/tarif', 'adela']);
        break;

      case this.CONST_URL_HISTORY:
        this.route.navigate(['/app/historiquevehicule']);
        break;

      case this.CONST_URL_STATS_SIV:
        this.route.navigate(['/app/statistiques/mes-statistiques']);
        break;
      
      case this.CONST_URL_STATS_MCEL:
          this.route.navigate(['/app/statistiques/mes-statistiques']);
          break;

      case this.CONST_URL_COMMANDE:
        this.route.navigate(['/app/commande/mes-commandes']);
        break;

      case this.CONST_URL_COMMANDE_KITS:
        this.route.navigate(['/app/article/kits', this.cmdCategorie.KIT_ADELA]);
        break;

      case this.CONST_URL_COMMANDE_OUTIL:
        this.route.navigate(['/app/article/kits', this.cmdCategorie.DIAG]);
        break;

      case this.CONST_URL_DOSSIERS:
        this.route.navigate(['/app/dossier/mes-dossiers']);
        break;

      case this.CONST_URL_FACTURES:
        this.route.navigate(['/app/facture/mes-factures']);
        break; 

      case this.CONST_URL_ADC:
        this.route.navigate(['/app/adc']);
        break; 

      case this.CONST_URL_ADC_TARIF:
        this.route.navigate(['/app/tarif', 'adc']);
        break;
          
      default:
        break;
    }
  }

  // Chargement des catégorie de commandes
  public loadCategorieCommande() {
    this.apiCmd.getAnyCategorie()
    .subscribe(
      (data: any) => {
        this.cmdCategorie = data;
      },
      (err) => { console.error(err) },
      () => {}
    )
  }
}
