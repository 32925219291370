<div [hidden]="!(viewDossierPoudre===false && viewDossierFluide===false)">
<mat-card  appearance="outlined"  class="mat-card-no-shadow" style="padding: 0px;text-align: center;">
    <form [formGroup]="FiltreFormGroup">
        <mat-expansion-panel id="expandFilter" [(expanded)]="ExpandPannel" #expandFilter>
            
            <mat-expansion-panel-header style="background-color: #fff; padding-top: 3px; padding-bottom: 3px;">
                <mat-panel-title class="label-accent">
                    <mat-icon>search</mat-icon>Veuillez sélectionner un service et les éventuelles options de votre recherche...
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card-content class=".mat-card-content">
            
            <mat-form-field >
                <mat-label>Service *</mat-label>
                <mat-select name                = "service" 
                            id                  = "service" 
                            formControlName     = "tab_Service" 
                            multiple [(ngModel)]= "servicesSelected" 
                            (selectionChange)   = "selectService()">
                    <mat-option *ngFor="let service of services$"
                        [value]="service">
                        {{ service.libelle }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="!FiltreFormGroup?.valid && FiltreFormGroup.touched">
                Vous devez sélectionner au moins un service
                </mat-error>
            </mat-form-field>
            
            <mat-form-field *ngIf="adelaSelected()">
                <mat-label>ADELA - types d'éléments *</mat-label>
                <mat-select name="typeElement" id="typeElement" formControlName="tab_TypeElement" multiple>
                    <mat-option *ngFor="let typeElement of typesElements" 
                        [value]="typeElement">
                        {{ typeElement.libelle }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="!FiltreFormGroup?.valid && FiltreFormGroup.touched"  style="margin-bottom:auto;">
                Vous devez sélectionner au moins un service                    
                </mat-error>
            </mat-form-field>

            <mat-form-field >
                <mat-label>État *</mat-label>
                <mat-select name="etats" id="etats" formControlName="tab_Etat" multiple>
                    <mat-optgroup *ngFor="let etats of filteredFormData" [label]="etats.nomService">
                        <mat-option *ngFor="let etat of etats.tab_Etat; let indice = index" 
                            [value]="etat">
                            {{ indice +1 }}. {{ etat.libelle }} 
                        </mat-option>
                    </mat-optgroup>
                </mat-select>
                <mat-error *ngIf="etat?.invalid && (etat?.dirty || etat?.touched)">{{etat.errors?.erreur}}</mat-error>
            </mat-form-field>

            <mat-form-field >
                <mat-label>Immatriculation</mat-label>
                <input matInput type="text" id="immatriculation" formControlName="immatriculation"
                    oninput="this.value = this.value.toUpperCase()" appImmat>
                <mat-error *ngIf="immatriculation?.invalid && (immatriculation?.dirty || immatriculation?.touched)">{{
                    immatriculation.errors?.erreur }}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Votre Référence</mat-label>
                <input matInput type="text" id="reference" formControlName="reference"
                    oninput="this.value = this.value.toUpperCase()">
                <mat-error *ngIf="reference?.invalid && (reference?.dirty || reference?.touched)">{{
                    reference.errors?.erreur }}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="profilUtilisateur$.droits_utilisateur.droit_module_admin_adela" appearance="outline">
                <mat-label>Etiquette</mat-label>
                <input matInput type="text" id="etiquette" formControlName="etiquette">
                <mat-error *ngIf="etiquette?.invalid && (etiquette?.dirty || etiquette?.touched)">{{
                    etiquette.errors?.erreur }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Cabinet</mat-label>
                <mat-select name="entite" id="entite" formControlName="entite">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let entite of entites$" [value]="entite">
                        {{ entite.raison_sociale }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Numéro de contact</mat-label>
                <input matInput type="text" formControlName="numClient">
            </mat-form-field>

            <mat-form-field class="formDate" appearance="outline">
                <mat-label>Date de début</mat-label>
                <input matInput type="date" id="dateDebut" formControlName="dateDebut">
                <mat-error *ngIf="dateDebut?.invalid && (dateDebut?.dirty || dateDebut?.touched)">{{
                    dateDebut.errors?.erreur }}</mat-error>
            </mat-form-field>

            <mat-form-field class="formDate" appearance="outline">
                <mat-label>Date de fin</mat-label>
                <input matInput type="date" id="dateFin" formControlName="dateFin">
                <mat-error *ngIf="dateFin?.invalid && (dateFin?.dirty || dateFin?.touched)">{{ dateFin.errors?.erreur }}
                </mat-error>
            </mat-form-field>
        </mat-card-content>
            <mat-action-row>    
                <div class="div-container-between" style="padding: 8px;">
                    <div class="div-container-left" style="width:100%">
                        <button mat-stroked-button type="button" color="basic" class="label-primary"
                        *ngIf="profilUtilisateur$.droits_utilisateur.droit_module_adela || profilUtilisateur$.droits_utilisateur.droit_module_admin_adela"
                            (click)="NouvelleAnalyseADELA()">
                            <mat-icon>invert_colors</mat-icon>
                            Nouvelle analyse ADELA fluide
                        </button>                        
                        <button mat-stroked-button type="button" color="basic" class="label-primary"
                        *ngIf="profilUtilisateur$.droits_utilisateur.droit_module_adela || profilUtilisateur$.droits_utilisateur.droit_module_admin_adela"
                            (click)="NouvelleAnalyseADELAPoudre()">
                            <mat-icon ><span class="material-symbols-outlined">
                                labs
                                </span></mat-icon>
                            Nouvelle analyse ADELA poudre
                        </button>
                    </div>
                    <div class="div-container-right">                        
                        <button mat-flat-button 
                                *ngIf       = "!waitingResearch"
                                color       = "primary" 
                                (click)     = "searchingFolder()"
                                [disabled]  = "waitingResearch || !FiltreFormGroup.valid">
                            <mat-icon>search</mat-icon>
                            Rechercher
                        </button>
                        <mat-spinner diameter="24" *ngIf="waitingResearch"></mat-spinner>
                    </div>
                </div>                
            </mat-action-row>
        </mat-expansion-panel>
    </form>
</mat-card>
</div>
<app-analyse-adela-poudre *ngIf="viewDossierPoudre===true" [idDossierComp]="idDossier" (close)="closeDossier()"></app-analyse-adela-poudre>
<app-analyse-adela *ngIf="viewDossierFluide===true" [idDossierComp]="idDossier" (close)="closeDossier()"></app-analyse-adela>

<ng-container *ngIf="viewTableADELA"   
>
    <div [hidden]="!(viewDossierPoudre===false && viewDossierFluide===false)">
        <mat-card   
                     
                    appearance="outlined"   
                     
                    class="mat-card-no-shadow" 
                    style="padding: 0px;text-align: center;">
                <mat-expansion-panel style="background-color: #fff" [(expanded)]="adelaExpanded">
                    <mat-expansion-panel-header style="background-color: #fff">
                        <mat-panel-title>
                            <mat-icon color="primary">invert_colors</mat-icon>
                            <span style="width: 220px;" *ngIf="this.dossier_ADELA$ != undefined"><span class="span-primary">{{ this.dossier_ADELA$.length }}</span> DOSSIER<span *ngIf="this.dossier_ADELA$.length > 1">S</span> ADELA : </span>
                            <span style="width: 220px;" *ngIf="this.dossier_ADELA$ == undefined"><span class="span-primary">0</span> DOSSIER ADELA : </span>
                        </mat-panel-title>
                        <mat-panel-description></mat-panel-description>
                    </mat-expansion-panel-header>

                    <app-table-adela *ngIf="profilUtilisateur$.droits_utilisateur.droit_module_admin_adela || profilUtilisateur$.droits_utilisateur.droit_module_adela"
                        [dataSource]="dossier_ADELA$" (refreshADELA)="refreshADELA($event)" (getDossier)="getDossier($event)"></app-table-adela>
                </mat-expansion-panel>
        </mat-card>
    </div>
</ng-container>

<ng-container *ngIf="viewTableADC">
    <mat-card appearance="outlined" *ngIf="viewDossierPoudre===false && viewDossierFluide===false" class="mat-card-no-shadow" style="padding: 0px;text-align: center;" >
        <mat-expansion-panel style="background-color: #fff">
            <mat-expansion-panel-header style="background-color: #fff">
                <mat-panel-title>
                    <mat-icon color="primary">key</mat-icon>
                    <span style="width: 220px;" *ngIf="this.dossier_ADC$ != undefined"><span class="span-primary">{{ this.dossier_ADC$.length }}</span> DOSSIER<span *ngIf="this.dossier_ADC$.length > 1">S</span> ADC : </span>
                    <span style="width: 220px;" *ngIf="this.dossier_ADC$ == undefined"><span class="span-primary">0</span> DOSSIER ADC : </span>
                </mat-panel-title>
                <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>

            <app-table-adc *ngIf="profilUtilisateur$.droits_utilisateur.droit_module_admin_adc || profilUtilisateur$.droits_utilisateur.droit_module_adc"
        [dataSource]="dossier_ADC$"  (refreshADC)="refreshADC($event)" ></app-table-adc>
        </mat-expansion-panel>
    </mat-card>
</ng-container>
