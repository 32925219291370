import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { HistoTicket } from 'src/app/interfaces/support/histo-ticket';
import { MessageTicket } from 'src/app/interfaces/support/message-ticket';
import { Ticket } from 'src/app/interfaces/support/ticket';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiSupportService } from 'src/app/services/api-support.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { saveAs } from "file-saver";
import { Observable, Subject, of} from 'rxjs';
import { takeUntil,map,catchError } from 'rxjs/operators';
import { Modele } from 'src/app/interfaces/support/modele';
import { TicketFacadeService } from 'src/app/services/ticket/ticket-facade.service';
import { Location, NgIf, NgClass, NgFor, AsyncPipe, DatePipe } from '@angular/common';
import { MessageComponent } from '../message/message.component';
import { ModeleComponent } from '../modeles/modele/modele.component';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-fiche-ticket',
    templateUrl: './fiche-ticket.component.html',
    styleUrls: ['./fiche-ticket.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, MatCardModule, MatTooltipModule, RouterLink, NgClass, MatIconModule, NgFor, 
              MatButtonModule, MatTableModule, ModeleComponent, MessageComponent, AsyncPipe, DatePipe]
})

export class FicheTicketComponent implements OnInit, OnDestroy {
  
  idTicket          : number=0;
  ticket$           : Observable<Ticket>        = new Observable<Ticket>();
  ticketEnCours     : Ticket = <Ticket>{};
  messageOrigine$   : Observable<MessageTicket> = new Observable<MessageTicket>();
  reponse           : boolean = false;
  messageSent       : boolean = false;
  histoTicket       : HistoTicket = <HistoTicket>{};
  messageOrigine    : MessageTicket=<MessageTicket>{} ;
  voirHisto         : boolean = false;
  destroyed         : Subject<void> = new Subject<void>();
  selectModele      : boolean = false;
  displayedColumns  : string[] = ['etat', 'user', 'date'];
  initReqMod        : boolean = false;
  modele            : Modele = <Modele>{};
  isAdmin           : boolean=false

  constructor(public apiSupportService      : ApiSupportService
            , private routed                : ActivatedRoute
            , private router                :Router
            , private apiAuth               : ApiAuthentificationService
            , public sanitizer              : DomSanitizer
            , private toast                 : PersoSnackbarService
            , private ticketFacade          : TicketFacadeService
            , private location              :Location)
             {}

  ngOnInit(): void {
    this.isAdmin = this.apiAuth.userConnect.droits_utilisateur.est_admin
    this.messageOrigine.tabpiecejointe = [];
    this.messageOrigine.utilisateur=<ProfilUtilisateur>{};
    this.idTicket = this.routed.snapshot.params['idTicket']
    this.loadTicket(this.idTicket)
  }
  
  loadTicket(idTicket:number){
    this.ticket$          = this.apiSupportService.getTicketComplet(idTicket).pipe(map(result=> {return result[0]}),takeUntil(this.destroyed));
    this.messageOrigine$  = this.ticket$.pipe(map(result=>{return result.listeMessage.find(message=>message.tck_mss_origine===true)!},takeUntil(this.destroyed)),catchError(error => {console.error(error);return of(error);})) 
    this.ticket$.subscribe((res)=>{
      this.ticketEnCours = res;
      if (res!=undefined) {
        if ((this.ticketEnCours.date_premiere_lecture =='0000-00-00T00:00:00.000' && this.ticketEnCours.id_utilisateur!=this.apiAuth.authUser.id)) {
          this.ticket$          = this.apiSupportService.updateFirstLectureTicket(idTicket).pipe(map(result=> {return result[0]}),takeUntil(this.destroyed),catchError(error => {console.error(error);return of(error);}));
        }
      }
    },(error)=>this.toast.showError(error.error)
    );
    
  }

  onCloseTicket(){
    this.updateTicket(this.apiSupportService.CONST_CODE_ETAT_TICKET_CLOTURE)
  }

  onReOpenTicket(){
    this.updateTicket(this.apiSupportService.CONST_CODE_ETAT_TICKET_ENCOURS)
  }
  
  updateTicket(codeEtat:string){
    this.histoTicket.codeEtat       = codeEtat;
    this.histoTicket.id_ticket      = this.idTicket;
    this.histoTicket.id_utilisateur = this.apiAuth.userConnect.id
    this.ticket$                    = this.ticketFacade.updateHistoTicket(this.histoTicket)
  }

  onMessageFinished(messageSent:boolean){
    this.reponse        = false;
    this.selectModele   = false;
    this.modele.mdl_id  = 0 //pour signifier qu'il n'y a plus de modèle en cours
    
    if (messageSent==true) {
      this.loadTicket(this.idTicket)
    }
  }
  onSetResponse(){
    this.reponse = true;
  }

  onGetBack(){
    // this.location.back()
    this.router.navigate(["/app/support/liste-tickets"])
    
  }

  onSetHisto(){
    this.voirHisto = !this.voirHisto;
  }

  onSetModele(){
  //   if (!this.initReqMod) {
  //     //on déclanche les requêtes de sélection des modèles,catégorie et sous-catégorie
  //   // this.apiSupportService.getModeles()
  //   // this.apiSupportService.getCategories()
  //   // this.apiSupportService.getSousCategories()
  //   this.initReqMod = true;
  // }
    this.selectModele = true;
    this.reponse          = true
  }

  getModele(modele:Modele){
    this.modele = modele
  }

  getLibelleHisto(){
    if (this.voirHisto) {
      return "Cacher l'historique";
    }else{
      return "Voir l'historique"
    }
  }
  // getNewArrayModele(listeModeles:Modele[]){
  //   this.modeles=listeModeles
  // }

  dowloadFile(idPJ:number,idTicket:number,NomFichier:string) {
    this.apiSupportService.getDownFile(idPJ, idTicket)
      .subscribe(
        (data: any) => {
          if(data != undefined) {
            saveAs(data, NomFichier);
            this.toast.showInfo('Fichier téléchargé.')
          }
        }
      )

  }

  trackById(index: number, item: any): string {
    return item.id;
    }

  ngOnDestroy() {
    this.destroyed.next(); // emits an undefined value to raise the flag
    this.destroyed.complete(); // prevents all further emission
  }
}
