import { DatePipe, NgIf, NgStyle } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { saveAs } from "file-saver";
import { MatTableExporterModule } from 'mat-table-exporter';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { ApiAdcService } from 'src/app/services/api-adc.service';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { ExportTableComponent } from '../../templates/export-table/export-table.component';

@Component({
    selector: 'app-table-adc',
    templateUrl: './table-adc.component.html',
    styleUrls: ['./table-adc.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatTableModule, MatTableExporterModule, MatButtonModule, NgIf, NgStyle, MatIconModule, ExportTableComponent, MatPaginatorModule, DatePipe]
})
export class TableAdcComponent implements OnInit, AfterViewInit, OnChanges {
  
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;
  @Input() dataSource: any;
  @Output() refreshADC = new EventEmitter<boolean>();
  @Output() idDossier     = new EventEmitter<number>();

  dataSourceMatTable: MatTableDataSource<any> = new MatTableDataSource<any>();
  
  // Gestion de l'affichage
  waitingResearch: boolean = false;
  ExpandPannel: boolean = false;
  isAdminADC: boolean = false;

  profilUtilisateur$: ProfilUtilisateur = <ProfilUtilisateur>{};
  // Tableau des dossiers ADC
  displayedColumns: string[] = []
  

  constructor(
    private rooter    : Router
    , private authUser  : ApiAuthentificationService
    , private apiADC    : ApiAdcService
    , public toast      : PersoSnackbarService
    , public dialog     : MatDialog) {

  }

  ngOnInit(): void {
    this.profilUtilisateur$ = this.authUser.userConnect;
    this.isAdminADC = this.authUser.userConnect.droits_utilisateur.droit_module_admin_adc;

    if ( this.isAdminADC )  {
      this. displayedColumns = ['immatriculation', 'reference', 'cabinet', 'expert', 'etat', 'date', 'open'];
    } else {
      this. displayedColumns = ['immatriculation', 'reference', 'cabinet', 'date', 'etat', 'download'];
    }
  }


  ngAfterViewInit() {
    this.dataSourceMatTable.paginator = this.paginator;
    this.dataSourceMatTable.sort = this.sort;
  }

  ngOnChanges() {
    // obligatoire
    if(this.dataSource != undefined) {
      this.dataSourceMatTable.data = this.dataSource;
    }    
  }

  // Téléchargement du formulaire
  public downloadDossier(id: number, etatCode?: string,  reference?: any) {
    
    switch (etatCode) {
      case this.apiADC.CONST_STATUS_ADC_9_REFUSE:
        this.toast.showInfo('Ce document est inaccessible pour le moment');
        break;
      case this.apiADC.CONST_STATUS_ADC_1_DEPOSE:
      case this.apiADC.CONST_STATUS_ADC_2_VALIDE:
      case this.apiADC.CONST_STATUS_ADC_3_ATTENTE_CLE:
      case this.apiADC.CONST_STATUS_ADC_5_DIAGNOSTIC:
        this.apiADC.download( 0, id, reference)
        .subscribe(
          data => {
            if(data != undefined) {
              saveAs(data, 'Confirmation-ADC-'+reference+'.pdf');
              this.toast.showInfo('Confirmation de la demande d\'analyse téléchargé');
            }          
          },
          err => {    
            this.toast.showError('Problème rencontré lors du téléchargement de la demande d\'analyse.');     
            console.error('/!\ err: ' + err.message); 
          },
        );
        break;
      case this.apiADC.CONST_STATUS_ADC_6_CLOTURE:
        this.apiADC.download( 0, id, reference)
        .subscribe(
          data => {
            if(data != undefined) {
              saveAs(data, 'Rapport-ADC-'+reference+'.pdf');
              this.toast.showInfo('Confirmation de la demande d\'analyse téléchargé');
            }          
          },
          err => {    
            this.toast.showError('Problème rencontré lors du téléchargement de la demande d\'analyse.');     
            console.error('/!\ err: ' + err.message); 
          },
        );
        this.apiADC.downloadRapport(id)
        .subscribe(
          data => {
            if(data != undefined) {
              saveAs(data, 'Confirmation-ADC-'+reference+'.pdf');
              this.toast.showInfo('Confirmation de la demande d\'analyse téléchargé');
            }          
          },
          err => {    
            this.toast.showError('Problème rencontré lors du téléchargement de la demande d\'analyse.');     
            console.error('/!\ err: ' + err.message); 
          },
        );
        break;

      default:
        break;
    }
  }

  // Historique du dossier ?
  public dialogHistoriqueDossier(id: any) {

  }

  // Ouverture du formulaire
  public openFile(status: string, id: number) {
    console.info('route to:', [ '/app/adc', id, status ])
    this.rooter.navigate([ '/app/adc', id, status ]);
  }

  public getColorByStatus(code: string) {
    switch(code) {
      case this.apiADC.CONST_STATUS_ADC_1_DEPOSE:
        return '#e48213';
        break;
      case this.apiADC.CONST_STATUS_ADC_2_VALIDE:				        
        return '#04970E';
        break;
      case this.apiADC.CONST_STATUS_ADC_3_ATTENTE_CLE:	
        return 'rgb(39,58,130)';
        break;
      // case this.apiADC.CONST_STATUS_ADC_4_ANALYSE:		    
      //   return '#e48213';
      //   break;
      case this.apiADC.CONST_STATUS_ADC_5_DIAGNOSTIC:	  
        return '#e48213';
        break;
      case this.apiADC.CONST_STATUS_ADC_6_CLOTURE:				      
        return 'rgb(39,58,130)';
        break;
      case this.apiADC.CONST_STATUS_ADC_9_REFUSE:		            
        return '#f44336';
        break;	
      default:
          return 'rgb(39,58,130)';
          break;
    }
  }

  public getIcone(code: string) {
    switch (code) {
      case this.apiADC.CONST_STATUS_ADC_1_DEPOSE:
        return 'folder';
        break;
      case this.apiADC.CONST_STATUS_ADC_2_VALIDE:
        return 'folder_special';
        break;
      case this.apiADC.CONST_STATUS_ADC_3_ATTENTE_CLE:
        return 'key';
        break;
      // case this.apiADC.CONST_STATUS_ADC_4_ANALYSE:
      //   return 'crop_free';
      //   break;
      case this.apiADC.CONST_STATUS_ADC_5_DIAGNOSTIC:
        return 'filter_center_focus';
        break;
      case this.apiADC.CONST_STATUS_ADC_6_CLOTURE:
        return 'done';
        break;
      case this.apiADC.CONST_STATUS_ADC_9_REFUSE:
        return 'close';
        break;
      default: 
        return'file'
    }
  } 

  public getIconeName(code: string) {
    switch (code) {
      case this.apiADC.CONST_STATUS_ADC_1_DEPOSE:
        return 'Déposé';
        break;
      case this.apiADC.CONST_STATUS_ADC_2_VALIDE:
        return 'Validé';
        break;
      case this.apiADC.CONST_STATUS_ADC_3_ATTENTE_CLE:
        return 'En attente des clés';
        break;
      // case this.apiADC.CONST_STATUS_ADC_4_ANALYSE:
      //   return 'crop_free';
      //   break;
      case this.apiADC.CONST_STATUS_ADC_5_DIAGNOSTIC:
        return 'En cours de diagnostics';
        break;
      case this.apiADC.CONST_STATUS_ADC_6_CLOTURE:
        return 'Clôturé';
        break;
      case this.apiADC.CONST_STATUS_ADC_9_REFUSE:
        return 'Refusé';
        break;
      default: 
        return''
    }
  }

}
