<mat-card appearance="outlined" class="mat-card-no-shadow"  style="padding: 0px;">
    <form [formGroup]="FiltreFormGroup">
        <mat-expansion-panel id="expandFilter" [(expanded)]="expandPannel" #expandFilter> 

            <mat-expansion-panel-header style="background-color: #fff; padding-top: 3px; padding-bottom: 3px;">
                <mat-panel-title class="label-accent">
                    <mat-icon>search</mat-icon>Veuillez sélectionner un fournisseur, une catégorie et les éventuelles options de votre recherche...
                </mat-panel-title>
            </mat-expansion-panel-header>                                

            <mat-form-field>
                <mat-label>Référence</mat-label>
                <input matInput type="text" id="reference" formControlName="reference">
                <mat-error *ngIf="reference?.invalid && (reference?.dirty || reference?.touched)">{{ reference?.errors?.erreur }}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Catégorie</mat-label>
                <mat-select name="categorie" id="categorie" formControlName="categorie" multiple required>
                    <mat-option *ngFor="let categorie of categories" [value]="categorie">
                        {{ categorie.libelle }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="!FiltreFormGroup?.valid && FiltreFormGroup.touched">Vous devez sélectionner au moins une catégorie pour votre recherche</mat-error>
            </mat-form-field> 

            <mat-form-field>
                <mat-label>Fournisseur</mat-label>
                <mat-select name="fournisseur" id="fournisseur" formControlName="fournisseur" multiple required>
                    <mat-option *ngFor="let fournisseur of fournisseurs" [value]="fournisseur">
                        {{ fournisseur.libelle }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="!FiltreFormGroup?.valid && FiltreFormGroup.touched">Vous devez sélectionner au moins un fournisseur pour votre recherche</mat-error>
            </mat-form-field>             

            <mat-form-field>
                <mat-label>Description</mat-label>
                <input matInput type="text" id="description" formControlName="description">
                <mat-error *ngIf="description?.invalid && (description?.dirty || description?.touched)">{{description?.errors?.erreur }}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Statut</mat-label>
                <mat-select name="statut" id="statut" formControlName="statut">
                    <mat-option value="ALL">Tous</mat-option>
                    <mat-option value="ACTIF">Actif</mat-option>
                    <mat-option value="INACTIF">Inactif</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-action-row>    
                <div class="div-container-between" style="padding: 8px;">
                    <div class="div-container-center" style="width:100%; display: flex; justify-content: center;">
                        <button 
                            mat-flat-button 
                            submit 
                            *ngIf       = "!waitingResearch"
                            color       = "primary" 
                            [disabled]  = "waitingResearch || !FiltreFormGroup.valid" 
                            (click)     = "onSearchArticle()">
                            <mat-icon>search</mat-icon>Rechercher                                    
                        </button>
                        <mat-spinner diameter="24" *ngIf="waitingResearch"></mat-spinner>
                    </div>
                </div>
            </mat-action-row>
        </mat-expansion-panel>                
    </form>
</mat-card>
