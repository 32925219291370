import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Compagnie } from 'src/app/interfaces/adela/compagnie';
import { Service } from 'src/app/interfaces/dossier/service';
import { LogFicheServiceExterne } from 'src/app/interfaces/services-ffea/log-fiche-service-externe';
import { LogRecherche } from 'src/app/interfaces/services-ffea/log-recherche';
import { ServiceExterne } from 'src/app/interfaces/services-ffea/service-externe';
import { Environnement } from 'src/app/interfaces/utilitaire/environnement';
import { Fichier, UploadFile } from 'src/app/interfaces/fichier';
import { Parametre } from 'src/app/interfaces/parametre';

@Injectable({
  providedIn: 'root'
})
export class ApiUtilitaireService {

  CONST_CODE_SERVICE_ADELA              : string = 'ADELA'          as const;
  CONST_CODE_SERVICE_ADC                : string = 'ADC'            as const;
  CONST_CODE_SERVICE_APPEL_OFFRE        : string = 'AO'             as const;
  CONST_CODE_SERVICE_OUTIL_DIAGNOSTIC   : string = 'DIAG'           as const;
  CONST_CODE_SERVICE_EQUIPEMENT_EXPERT  : string = 'KIT'            as const;
  CONST_CODE_SERVICE_REPARATEUR         : string = 'REPARATEUR'     as const;
  CONST_CODE_SERVICE_CONCENTRATEUR      : string = 'CONCENTRATEUR'  as const;

  CONST_ABREGE_SERVICE_ADELA            : string = 'AD'   as const;
  CONST_ABREGE_SERVICE_ADC              : string = 'AC'   as const;
  CONST_ABREGE_SERVICE_APPEL_OFFRE      : string = 'AO'   as const;
  CONST_ABREGE_SERVICE_OUTIL_DIAGNOSTIC : string = 'OD'   as const;
  CONST_ABREGE_SERVICE_EQUIPEMENT_EXPERT: string = 'EE'   as const;
  CONST_ABREGE_SERVICE_CONCENTRATEUR    : string = 'UC'   as const;

  CONST_CODE_ENVIRONNEMENT_EXTRANET_FFEA: string = 'EXTR' as const;
  CONST_CODE_ENVIRONNEMENT_SERVICES_FFEA: string = 'FFEA' as const;
  CONST_CODE_ENVIRONNEMENT_ALL          : string = 'ALL'  as const;

  CONST_CODE_SRX_EXPERTS_GROUPE         : string = 'EXPERTS_GROUPE' as const;
  CONST_CODE_SRX_ALLIANCE_EXPERTS       : string = 'ALLIANCE_EXPERTS' as const;
  CONST_CODE_SRX_CREATIV_EXPERTIZ       : string = 'CREATIV_EXPERTIZ' as const;

  private CONST_CODE_PARAM_URL_MAJ_OUTIL_DIAG   : string = 'URL_MAJ_OUTIL_DIAG' as const;
  
  private urlAPI: string = '/api';
  urlMajOutilDiagnostic : string = '';

  constructor(private http  : HttpClient) {}

  // Modification d'un service externe
  public putServiceExterne(srx_id: number, utl_id: number, service_externe: ServiceExterne): Observable<ServiceExterne> {
    return this.http.put<ServiceExterne>(this.urlAPI+'/service-externe/'+srx_id+'/'+utl_id, service_externe);
  }

  // Chargement d'un service externe par identifiant
  public getServiceExterneById(srx_id: number): Observable<ServiceExterne> {
    return this.http.get<ServiceExterne>(this.urlAPI+"/service-externe/"+srx_id);
  }

  // Liste des services externe
  public getServiceExterne(code_environnement: string, srv_code: string = ''): Observable<ServiceExterne[]> {
    let param_srv_code: string = ( srv_code === '' ? '' : '&srv_code='+srv_code)
    return this.http.get<ServiceExterne[]>(this.urlAPI + '/service-externe?environnement='+code_environnement+param_srv_code);
  }

  // Chargement des logs recherche
  public postLogRecherche(filter: any): Observable<LogRecherche[]> {
    return this.http.post<LogRecherche[]>(this.urlAPI + '/log-recherche', filter);
  }

  // Chargement des logs fiche service externe
  public postLogFiche(filter: any): Observable<LogFicheServiceExterne[]> {
    return this.http.post<LogFicheServiceExterne[]>(this.urlAPI + '/log-fiche', filter);
  }

  public postServiceExterneWithFilter(filter: any): Observable<ServiceExterne[]> {
    return this.http.post<ServiceExterne[]>(this.urlAPI + '/service-externe-avec-condition', filter);
  }

  // Liste des environnements
  public getEnvironnement(code_environnement: string=this.CONST_CODE_ENVIRONNEMENT_ALL): Observable<Environnement[]> {
    return this.http.get<Environnement[]>(this.urlAPI + '/environnement?environnement='+code_environnement);
  }

  // Liste des services
  public getService(code_environnement: string): Observable<Service[]> {
    return this.http.get<Service[]>(this.urlAPI + '/service?environnement='+code_environnement);
  }

  // Liste des compagnie d'assurance
  public getCompagnie(): Observable<Compagnie[]> {
    return this.http.get<Compagnie[]>(this.urlAPI + '/compagnie')
  }

  // Récupération d'un paramètre
  public getParametre(param: string): Observable<any> {
    return this.http.get<any>( this.urlAPI + '/param/' + param)
  }

  uploadFilesToFichiers(uploadfiles:UploadFile[],categorie:string=''):Fichier[]{
    let fichiers:Fichier[]=[]
    uploadfiles.forEach((uploadfile)=>{
      const fichier:Fichier = {
        'name'        : uploadfile.file?.name? uploadfile.file?.name : '' ,
        'categorie'   : categorie,
        'id'          : 0,
        'contenu'     : uploadfile.contenu,
        'taille'      : uploadfile.file?.size? uploadfile.file?.size : 0,
        'typeFichier' : uploadfile.file?.type? uploadfile.file?.type : ''
      }
      
      fichiers.push(fichier)
    })
    return fichiers
  }

  public getIconeService(code: string) {
    switch (code) {
      case this.CONST_CODE_SERVICE_ADELA:
        return 'invert_colors';
      case this.CONST_CODE_SERVICE_ADC:
        return 'key';
      case this.CONST_CODE_SERVICE_APPEL_OFFRE:
        return 'table_view';
      case this.CONST_CODE_SERVICE_OUTIL_DIAGNOSTIC:
        return 'work';
      case this.CONST_CODE_SERVICE_EQUIPEMENT_EXPERT:
        return 'shopping_cart'
      default: 
        return'default'
    }
  }  

  public getIconeName(code: string) {
    switch (code) {
      case this.CONST_CODE_SERVICE_ADELA:
        return 'ADELA';
      case this.CONST_CODE_SERVICE_ADC:
        return 'ADC';
      case this.CONST_CODE_SERVICE_APPEL_OFFRE:
        return 'APPEL D\'OFFRE';
      case this.CONST_CODE_SERVICE_OUTIL_DIAGNOSTIC:
        return 'OUTIL DIAGNOSTIC';
      case this.CONST_CODE_SERVICE_EQUIPEMENT_EXPERT:
        return 'EQUIPEMENT EXPERT'
      default: 
        return 'INCONNU'
    }
  }
  
  public getUrlMaJOutilDiag() {
    if (this.urlMajOutilDiagnostic == '') {
      // si la variable est vide on va recherche sa valeur
      this.getParametre(this.CONST_CODE_PARAM_URL_MAJ_OUTIL_DIAG)
      .subscribe(
        (data: Parametre) => {
          this.urlMajOutilDiagnostic = data.valeur;
        }
        , err => {console.log(err)}
      )
    }
  }
}
