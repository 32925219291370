<mat-toolbar class="navbar">
    <div class="div-container-between" >
        <div style="width: 180px;">
            <a mat-button  *ngIf="true" class="label-accent"><mat-icon >account_balance</mat-icon>Comptabilité</a>
        </div>
    </div>
    
</mat-toolbar>

<mat-card appearance="outlined" class="mat-card-no-shadow">
        <mat-expansion-panel id="expandFilter" [(expanded)]="expandPannel" #expandFilter>            
            <mat-expansion-panel-header>
                <mat-panel-title class="label-accent">
                    <mat-icon>search</mat-icon>Veuillez sélectionner un cabinet...
                </mat-panel-title>
            </mat-expansion-panel-header>                                

            <button mat-raised-button (click)="searchingEntite()" color="primary" style="margin: 10px;">Rechercher une entite</button>
            <app-entite-search *ngIf="searching" (entiteEvent)="setEntite($event)"></app-entite-search>
            <div *ngIf="entiteSelected" class="entite">
                <div style="margin-right: 15px;">
                    {{entiteSelected.raison_sociale}}
                </div>
                <button mat-mini-fab (click)="cancelEntite()"  matTooltip="Supprimer l'entité de la recherche"><mat-icon>close</mat-icon></button>
            </div>

            <mat-action-row>
                <div class="div-button-center" style="padding: 8px; width: 100%;">
                    <div class="button-container">                        
                        <button 
                            mat-flat-button 
                            *ngIf       = "!waitingReleves"
                            color       = "primary" 
                            (click)     = "onDownloadReleves()" 
                            [disabled]  = "waitingReleves || !entiteSelected">
                            <mat-icon>file_download</mat-icon>Édition Relevés
                        </button>
                        <mat-spinner diameter="24" *ngIf="waitingReleves"></mat-spinner>
                    </div>
                </div>
            </mat-action-row>
        </mat-expansion-panel>                
</mat-card>