import { DatePipe, NgFor, NgIf, NgStyle, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormsModule, NgModel, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from "file-saver";
import { Fichier } from 'src/app/interfaces/fichier';
import { CoordonneesBancaire } from 'src/app/interfaces/profil-user/coordonnees-bancaire';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { RelationUtilisateurEntite } from 'src/app/interfaces/utilisateur-entite/relation-utilisateur-entite';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { DialogEntiteCoordonneeBancaireComponent } from '../dialog-entite-coordonnee-bancaire/dialog-entite-coordonnee-bancaire.component';
import { MatSelectModule } from '@angular/material/select';
import { ApiFactureService } from 'src/app/services/api-facture.service';
import { ModePaiement } from 'src/app/interfaces/facture/mode-paiement';

@Component({
    selector: 'app-entite-coordonnee-bancaire',
    templateUrl: './entite-coordonnee-bancaire.component.html',
    styleUrls: ['./entite-coordonnee-bancaire.component.scss'],
    standalone: true,
    imports: [NgIf, MatCardModule, MatIconModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule
              , NgStyle, MatButtonModule, MatProgressSpinnerModule, DatePipe, MatTooltipModule,MatSelectModule,NgFor
              , FormsModule,NgSwitch,NgSwitchCase]
})
export class EntiteCoordonneeBancaireComponent implements OnInit {
  
  // Gestion de l'affichage
  waiting_save                  : boolean = false;

  ent_id                        : number = 0;
  utl_id                        : number = 0;
  bnq_id                        : number = 0;

  isAdmin                       : boolean = false;
  isDirigeant                   : boolean = false;

  limitFileSize                 : number = 5; // Taille limite d'un fichier upload (5 Mo)

  @Input() RelUtilisateurEntite : RelationUtilisateurEntite = <RelationUtilisateurEntite>{};  

  @Input() entite               : Entite = <Entite>{};

  userConnect                   : ProfilUtilisateur = <ProfilUtilisateur>{};

  CoordonneeBancaire$           : CoordonneesBancaire = <CoordonneesBancaire>{}

  // upload des mandats et IBAN
  IBANFile  : string = '';
  RUMFile   : string = '';
  
  file_IBAN : Fichier = <Fichier>{};
  file_RUM  : Fichier = <Fichier>{};


  formBancaire = this._fb.group({
    id:                 [0],
    date_creation:      new FormControl<Date>(new Date),
    date_modification:  new FormControl<Date>(new Date),
    iban:               [''],
    bic:                [''],
    rum:                [''],
    date_mandat:        [''],
    nom_fichier_iban:   [''],
    pdf_iban:           [''],
    nom_fichier_rum:    [''],
    pdf_rum:            ['']
  })
  
  modesPaiement           : ModePaiement[]=[]
  idSelectedModePaiement  : number = 0
  modePaiementEnCours     : ModePaiement|null = null

  constructor(private apiAuth                 : ApiAuthentificationService
            , public apiEntite                : ApiEntiteService
            , private routed                  : ActivatedRoute
            , private _fb                     : FormBuilder
            , public dialog                   : MatDialog
            , public apiFacture              : ApiFactureService
            , private toast                   : PersoSnackbarService) {
              this.routed.params.subscribe(val => {
                this.ngOnInit();
              });
             }

  ngOnInit(): void {
    // On force le reset des valeurs par défaut, car lorsqu'on recharge le component liste-utilisateur,    
    // des valeurs peuvent déjà être configuré par une "session" précédente (session dans le sens appel depuis une autre page)
    // mais ne pas être réinitialisé car le component ne refait pas la déclaration des variables puisqu'il a déjà été initialisé par la "session" précédente
    this.ent_id               = 0;
    this.utl_id               = 0;
    this.bnq_id               = 0;
    this.isAdmin              = false;
    this.isDirigeant          = false;    
    this.userConnect          = <ProfilUtilisateur>{};
    this.CoordonneeBancaire$  = <CoordonneesBancaire>{}
    this.IBANFile             = '';
    this.RUMFile              = '';    
    this.file_IBAN            = <Fichier>{};
    this.file_RUM             = <Fichier>{};

    // Affectation des valeurs par défaut
    this.ent_id       = this.routed.snapshot.params['id'];
    this.utl_id       = this.apiAuth.authUser.id;
    this.userConnect  = this.apiAuth.userConnect;
    this.isAdmin      = this.userConnect.droits_utilisateur.est_admin ||this.userConnect.droits_utilisateur.droit_coord_bancaire;
    this.isDirigeant  = this.RelUtilisateurEntite.est_dirigeant;

    this.formBancaire.controls['iban'].disable();
    this.formBancaire.controls['bic'].disable();
    this.formBancaire.controls['rum'].disable();
    this.formBancaire.controls['date_mandat'].disable();

this.apiFacture.getModePaiement().subscribe(
  (modesPaiements:ModePaiement[]) => {
        this.modesPaiement = modesPaiements
        this.idSelectedModePaiement = this.entite.idModePaiementDefaut? this.entite.idModePaiementDefaut : 0
        this.checkModePaiement()
      }
    )
  }

  ngOnChanges(): void {
    
    if(this.RelUtilisateurEntite != undefined) {
      this.isDirigeant = this.RelUtilisateurEntite.est_dirigeant;
    }

    if(this.entite != undefined && this.entite.coordonnees_bancaire != undefined){
      this.CoordonneeBancaire$  = this.entite.coordonnees_bancaire;
      this.bnq_id               = this.entite.coordonnees_bancaire.id;
      this.initializeForm();
    }
  }

  private initializeForm(){
    this.formBancaire.patchValue({
      id                : this.entite.coordonnees_bancaire.id,
      date_creation     : this.entite.coordonnees_bancaire.date_creation,
      date_modification : this.entite.coordonnees_bancaire.date_modification,
      iban              : this.entite.coordonnees_bancaire.iban,
      bic               : this.entite.coordonnees_bancaire.bic,
      rum               : this.entite.coordonnees_bancaire.rum,
      date_mandat       : this.entite.coordonnees_bancaire.date_mandat.split('T')[0],
      nom_fichier_iban  : this.entite.coordonnees_bancaire.nom_fichier_iban,
      nom_fichier_rum   : this.entite.coordonnees_bancaire.nom_fichier_rum
    });
  }


  public enregistreData(): void {
    this.waiting_save = true;

    this.CoordonneeBancaire$.id                = this.formBancaire.value.id!;
    this.CoordonneeBancaire$.date_creation     = this.formBancaire.value.date_creation!;
    this.CoordonneeBancaire$.date_modification = this.formBancaire.value.date_modification!;
    this.CoordonneeBancaire$.iban              = this.formBancaire.value.iban!;
    this.CoordonneeBancaire$.bic               = this.formBancaire.value.bic!;
    this.CoordonneeBancaire$.rum               = this.formBancaire.value.rum!;
    this.CoordonneeBancaire$.date_mandat       = this.formBancaire.value.date_mandat!;    
    this.CoordonneeBancaire$.pdf_iban          = this.formBancaire.value.pdf_iban!;    
    this.CoordonneeBancaire$.pdf_rum           = this.formBancaire.value.pdf_rum!;

    this.apiEntite.putCoordonneesBancaire(this.ent_id, this.formBancaire.value as CoordonneesBancaire, this.apiAuth.userConnect.id)
      .subscribe(
        (coordonnees_bancaire: CoordonneesBancaire) => {

          this.apiEntite.getEntiteById(this.ent_id)
          .subscribe(
            (data: Entite[]) => {
              this.CoordonneeBancaire$  = data[0].coordonnees_bancaire;
              this.bnq_id               = data[0].coordonnees_bancaire.id;
    
              this.formBancaire.patchValue({
                id                : data[0].coordonnees_bancaire.id,
                date_creation     : data[0].coordonnees_bancaire.date_creation,
                date_modification : data[0].coordonnees_bancaire.date_modification,
                iban              : data[0].coordonnees_bancaire.iban,
                bic               : data[0].coordonnees_bancaire.bic,
                rum               : data[0].coordonnees_bancaire.rum,
                date_mandat       : data[0].coordonnees_bancaire.date_mandat.split('T')[0],
                nom_fichier_iban  : data[0].coordonnees_bancaire.nom_fichier_iban,
                nom_fichier_rum   : data[0].coordonnees_bancaire.nom_fichier_rum
              });                            

              this.toast.showInfo('Données bancaires enregistrées');
              this.waiting_save = false;
            },
            (err) => { 
              this.toast.showError('Erreur lors de l\'enregistrement');
              this.waiting_save = false;
            }
          );          
        },
        (err) => { console.log(err) }
      );
  }

  // envoi du document IBAN
  public onClickUploadIBAN(event: any): void {
    const file = event.target.files[0];
    
    this.file_IBAN.typeFichier = 'iban';
    this.file_IBAN.name        = event.target.files[0].name;
    this.formBancaire.patchValue({
      nom_fichier_iban : event.target.files[0].name
    })
    this.CoordonneeBancaire$.nom_fichier_iban = event.target.files[0].name;

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.file_IBAN.contenu = reader.result as string;
      this.file_IBAN.taille = event.target.files[0].size;

      if (this.file_IBAN.taille/1024/1024 > this.limitFileSize) {
        this.toast.showInfo('La taille limite d\'un fichier est de '+this.limitFileSize+' Mo')
      } else {
        this.formBancaire.patchValue({
          pdf_iban : reader.result as string
        });
      }      
    };
  }

  // envoi du document RUM
  public onClickUploadRUM(event: any): void {
    const file = event.target.files[0];
    
    this.file_RUM.typeFichier = 'rum';
    this.file_RUM.name        = event.target.files[0].name;
    this.CoordonneeBancaire$.nom_fichier_rum = event.target.files[0].name;
    this.formBancaire.patchValue({
      nom_fichier_rum : event.target.files[0].name
    })

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {      

      this.file_RUM.contenu = reader.result as string;
      this.file_RUM.taille = event.target.files[0].size;

      if (this.file_RUM.taille/1024/1024 > this.limitFileSize) {
        this.toast.showInfo('La taille limite d\'un fichier est de '+this.limitFileSize+' Mo')
      } else {
        this.formBancaire.patchValue({
          pdf_rum : reader.result as string
        });
      }      
    };
  }

  // téléchargement de l'IBAN ou RUM
  public dowloadFile(type: string) {
    this.apiEntite.getDownFile(type, this.ent_id, this.bnq_id)
      .subscribe(
        (data: any) => {
          if(data != undefined) {
            switch (type) {
              case 'iban':
                saveAs(data, this.CoordonneeBancaire$.nom_fichier_iban);
                break;
              case 'rum':
                saveAs(data, this.CoordonneeBancaire$.nom_fichier_rum);
                break;
              default:
                break;
            }
            this.toast.showInfo('Fichier téléchargé.')
          }
          this.toast.showInfo('Document ' +type+ ' téléchargé.');
        }
      )

  }

  //US 333 : adatper le libellé du bouton en fontion d'une création ou modification
  public getUpdateBtnName(){
    let buttonLabel:string
    if (this.entite.coordonnees_bancaire && this.entite.coordonnees_bancaire.id!=0) {
      buttonLabel = 'Modifier les coordonnées bancaires'
    }else{
      buttonLabel='Ajouter des coordonnées bancaires'
    }
    return buttonLabel;
  }

  public openSetDataWindow(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus    = true;
    
    dialogConfig.data = {
      entite: this.entite,
      RelationUtilisateurEntite: this.RelUtilisateurEntite,
      ent_id:this.ent_id,
      modesPaiement:this.modesPaiement
    };

    const dialogRef = this.dialog.open(DialogEntiteCoordonneeBancaireComponent,dialogConfig)
    dialogRef.afterClosed().subscribe(
      data => {if (data != false) {
        this.CoordonneeBancaire$ = data;
        this.entite.coordonnees_bancaire = this.CoordonneeBancaire$;
        this.ngOnChanges();
      }
        }
  );  
  }

  public getLibelleEtat() {
    let libelle: string = '';

    switch(this.entite.coordonnees_bancaire.etat.code) {
      case this.apiEntite.CONST_CODE_ETAT_COORDONNEES_BANCAIRE_VALIDE:
        libelle = 'Coordonnées bancaires validées';
        break;
      case this.apiEntite.CONST_CODE_ETAT_COORDONNEES_BANCAIRE_REFUSE:
        libelle = 'Coordonnées bancaires refusées';
        break;
      case this.apiEntite.CONST_CODE_ETAT_COORDONNEES_BANCAIRE_A_VALIDER:
        libelle = 'En attente de validation par la FFEA';
        break;
      default:
        libelle = 'Veuillez compléter les coordonnées bancaires';
        break;      
    }

    return libelle;
  }

  enregistrerModePaiementDefaut(){
    this.apiEntite.patchModePaiement(this.entite.id!,this.idSelectedModePaiement).subscribe(
      (coordonneesBancaires:CoordonneesBancaire)=>{
        
        this.toast.showInfo('Le mode de paiement a bien été mis à jour')
        this.entite.idModePaiementDefaut = this.idSelectedModePaiement
        this.entite.coordonnees_bancaire = coordonneesBancaires
      }
      ,(error)=>{
        this.toast.showError('Une erreur a empeché la mise à jour du mode de paiement')
        this.idSelectedModePaiement!=this.entite.idModePaiementDefaut
      }
    )
  }  

  checkModePaiement(){
    const modePaiement  = this.modesPaiement.find((modePaiement:ModePaiement)=>modePaiement.id===this.idSelectedModePaiement)
    if (modePaiement) {
      this.modePaiementEnCours = modePaiement
    }else{
      this.modePaiementEnCours = null
    }
  }
}

