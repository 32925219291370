import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-rapport-suspension',
    templateUrl: './rapport-suspension.component.html',
    styleUrls: ['./rapport-suspension.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, FormsModule, NgIf, MatButtonModule, MatIconModule, MatProgressSpinnerModule]
})
export class RapportSuspensionComponent implements OnInit { 
  
  @Input() waitingRapport$  : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  @Output() rapportEvent    : EventEmitter<string> = new EventEmitter()

  waiting :boolean = false
  constructor() { }

  nomRapport  : string  = 'EXTRANET - COMPTA - Rapport des suspensions - ' + new Date().toLocaleDateString('fr-fr') + ' ' + new Date().toLocaleTimeString('fr-fr')

  ngOnInit(): void {
    this.waitingRapport$.subscribe(
      (data:boolean)=>this.waiting = data
    )
  }

  telecharger(){
    this.waitingRapport$.next(true)
    this.rapportEvent.next(this.nomRapport)
  }
}
