import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { ApiArticleService } from 'src/app/services/api-article.service';
import { ResultatActionEnMasse } from 'src/app/interfaces/resultat-action-en-masse';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatTableResponsiveLargeDirective } from '../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';

export interface DialogData {
  type: string;
  dataSource_articles: any;
}
@Component({
    selector: 'app-action-de-masse-articles',
    templateUrl: './action-de-masse-articles.component.html',
    styleUrls: ['./action-de-masse-articles.component.scss'],
    standalone: true,
    imports: [MatDialogModule, MatTableModule, MatTableResponsiveLargeDirective, MatPaginatorModule, NgIf, MatButtonModule, MatIconModule, MatProgressSpinnerModule]
})
export class ActionDeMasseArticlesComponent implements OnInit {
  CONST_CODE_TYPE_ACTION_SUPPRESSION    : string = 'delete'     as const;
  CONST_CODE_TYPE_ACTION_ACTIVATION     : string = 'activate'   as const;
  CONST_CODE_TYPE_ACTION_DESACTIVATION  : string = 'deactivate' as const;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;

  dataSourceMatTable  : MatTableDataSource<any> = new MatTableDataSource<any>();

  titre_page          : string = '';
  sous_titre_page     : string = '';

  type_action_de_masse: string = '';

  // Tableau des articles
  displayedColumns    : Array<string> = [ 'rtc_reference', 'rtc_titre', 'rtc_resultat'];

  // Gestion de l'affichage
  waitingApi          : boolean = false;
  action_confirmee    : boolean = false;


  constructor(public dialogRef                      : MatDialogRef<ActionDeMasseArticlesComponent>
            , private apiArticle                    : ApiArticleService
            , private toast                         : PersoSnackbarService
            , @Inject(MAT_DIALOG_DATA) public data  : DialogData) { }

  ngOnInit(): void {    
    this.type_action_de_masse = this.data.type;      

    if(this.data.dataSource_articles != undefined) {
      switch (this.data.type) {
        case this.CONST_CODE_TYPE_ACTION_SUPPRESSION:
          if (this.data.dataSource_articles.length == 1) {
            this.titre_page     = 'Suppression d\'un article';
            this.sous_titre_page = 'L\'article suivant va être supprimé';
          } else {
            this.titre_page     = 'Suppresion de ' + this.data.dataSource_articles.length +' articles';
            this.sous_titre_page = 'Les articles suivants vont être supprimés';
          }
          break;
  
        case this.CONST_CODE_TYPE_ACTION_ACTIVATION:
          if (this.data.dataSource_articles.length == 1) {
            this.titre_page     = 'Activation d\'un article';
            this.sous_titre_page = 'L\'article suivant va être activé';
          } else {
            this.titre_page     = 'Activation de ' + this.data.dataSource_articles.length +' articles';
            this.sous_titre_page = 'Les articles suivants vont être activés';
          }
          break;
  
        case this.CONST_CODE_TYPE_ACTION_DESACTIVATION:
          if (this.data.dataSource_articles.length == 1) {
            this.titre_page     = 'Désactivation d\'un article';
            this.sous_titre_page = 'L\'article suivant va être désactivé';
          } else {
            this.titre_page     = 'Désactivation de ' + this.data.dataSource_articles.length +' articles';
            this.sous_titre_page = 'Les articles suivants vont être désactivés';
          }
          break;
  
        default:
          this.dialogRef.close();
          break;
      }
      this.dataSourceMatTable.data = this.data.dataSource_articles;
    }
  }

  ngAfterViewInit() {
    this.dataSourceMatTable.paginator = this.paginator;
    this.dataSourceMatTable.sort      = this.sort;
  }

  ngOnChanges() {
    // obligatoire
    if(this.data.dataSource_articles != undefined) {
      this.dataSourceMatTable.data = this.data.dataSource_articles;
    }     
  }

  public onSuppressionMasse(): void {
    this.waitingApi = true;

    if(this.dataSourceMatTable.data.length > 0) {
      this.apiArticle.postSuppressionEnMasseArticle(this.dataSourceMatTable.data)
      .subscribe(
        (resultat: ResultatActionEnMasse[]) => {
          for(let i = 0; i < resultat.length; i++) {
            this.dataSourceMatTable.data.find(article => {
              if(article.id == resultat[i].id ) {
                article.resultat = resultat[i].resultat;
              }
            });
          }
          this.toast.showInfo("Article(s) supprimé(s)");
          this.waitingApi       = false;
          this.action_confirmee = true;
        },
        (err: any) => {
          this.toast.showError(err.error);
          this.waitingApi = false;
        }
      );
    } else {
      this.toast.showInfo("Aucun article sélectionné à supprimer");
      this.waitingApi = false;
    }    
  }
  
  public onActivationMasse(): void {
    this.waitingApi = true;

    if(this.dataSourceMatTable.data.length > 0) {
      this.apiArticle.postActivationEnMasseArticle(this.dataSourceMatTable.data)
      .subscribe(
        (resultat: ResultatActionEnMasse[]) => {
          for(let i = 0; i < resultat.length; i++) {
            this.dataSourceMatTable.data.find(article => {
              if(article.id == resultat[i].id ) {
                article.resultat = resultat[i].resultat;
              }
            });
          }
          this.toast.showInfo("Article(s) activé(s)");
          this.waitingApi       = false;
          this.action_confirmee = true;
        },
        (err: any) => {
          this.toast.showError(err.error);
          this.waitingApi = false;
        }
      );
    } else {
      this.toast.showInfo("Aucun article sélectionné à activer");
      this.waitingApi = false;
    }
  }

  public onDesactivationMasse(): void {
    this.waitingApi = true;

    if(this.dataSourceMatTable.data.length > 0) {
      this.apiArticle.postDesactivationEnMasseArticle(this.dataSourceMatTable.data)
      .subscribe(
        (resultat: ResultatActionEnMasse[]) => {
          for(let i = 0; i < resultat.length; i++) {
            this.dataSourceMatTable.data.find(article => {
              if(article.id == resultat[i].id ) {
                article.resultat = resultat[i].resultat;
              }
            });
          }
          this.toast.showInfo("Article(s) désactivé(s)");
          this.waitingApi       = false;
          this.action_confirmee = true;
        },
        (err: any) => {
          this.toast.showError(err.error);
          this.waitingApi = false;
        }
      );
    } else {
      this.toast.showInfo("Aucun article sélectionné à désactiver");
      this.waitingApi = false;
    }
  }

  public onClickRetour(): void{
    this.dialogRef.close();
  }
}
