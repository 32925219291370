import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, ValidationErrors, ValidatorFn, Validators, ReactiveFormsModule } from '@angular/forms';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { CheckValidator } from 'src/app/validators/check.validator';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
    selector: 'app-update-password',
    templateUrl: './update-password.component.html',
    styleUrls: ['./update-password.component.scss'],
    standalone: true,
    imports: [MatExpansionModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatIconModule, MatTooltipModule, NgIf, MatButtonModule]
})
export class UpdatePasswordComponent implements OnInit {

  bExpandPannel: boolean = false;

  get new() { return this.updateForm.get('new'); }
  get confirmation() { return this.updateForm.get('confirmation'); }

   // US 346 : afficher/masquer le mot de passe
   hideOldPassword      : boolean = true;
   hideNewPassword      : boolean = true;
   hideConfirmPassword  : boolean = true;

  updateForm = this.fBuilder.group({
    current       : new FormControl('', Validators.required),
    new           : new FormControl('',[Validators.required, this.checkValidator.verificationFormatMotDePasse()]),
    confirmation  : new FormControl('',[Validators.required, this.checkValidator.verificationFormatMotDePasse()])
  });

  constructor(
    private fBuilder: FormBuilder, 
    private authService: ApiAuthentificationService,
    private toast: PersoSnackbarService,
    private checkValidator : CheckValidator
  ) { }

  ngOnInit(): void {
    
  }

  updatePassword(): void {
    // Récupération de l'identifiant saisie en paramètre de l'URL
    if (this.authService.userConnect.id > 0){      
      this.authService.putPasswordUtilisateur(
                                              this.authService.userConnect.id, 
                                              '', 
                                              this.updateForm.value.current? this.updateForm.value.current : '', 
                                              this.updateForm.value.new? this.updateForm.value.new :'', 
                                              this.updateForm.value.confirmation? this.updateForm.value.confirmation : '')
      .subscribe(
        (succes: boolean) => {}
        ,(err) => {} // Gérer en amont
        ,() => {
          this.toast.showInfo("Mot de passe modifié");
          this.cancelPassword();
        }
      );   
    }
  }

  cancelPassword(): void {
    this.bExpandPannel = false;
  }

  public verificationFormatMotDePasse(): ValidatorFn {
    return (control:AbstractControl):ValidationErrors|null =>{

      let motdepasse: string = control.value;
      let regex: any = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/;
  
      if(!regex.test(motdepasse)) {
        return { erreur: 'Format incorrect : mot de passe doit contenir au minimum 8 caractères dont une majuscule, une minuscule, un nombre et un caractère spécial'}      
      }
  
      return null;
    }
  }
}
