import { Component, OnInit } from '@angular/core';
import { Fournisseur } from 'src/app/interfaces/commande/fournisseur/fournisseur';
import { TableListeFournisseursComponent } from '../table-liste-fournisseurs/table-liste-fournisseurs.component';
import { NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { FiltreListeFournisseursComponent } from '../filtre-liste-fournisseurs/filtre-liste-fournisseurs.component';
@Component({
    selector: 'app-fiche-liste-fournisseurs',
    templateUrl: './fiche-liste-fournisseurs.component.html',
    styleUrls: ['./fiche-liste-fournisseurs.component.scss'],
    standalone: true,
    imports: [FiltreListeFournisseursComponent, MatCardModule, MatExpansionModule, MatIconModule, NgIf, TableListeFournisseursComponent]
})
export class FicheListeFournisseursComponent implements OnInit {

  ds_Fournisseurs         : Array<Fournisseur> = Array<Fournisseur>();

  constructor() { }

  ngOnInit(): void {
  }

  public addItem(newItem: Array<Fournisseur>) : void {
    this.ds_Fournisseurs = newItem;
  }

}
