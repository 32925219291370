<mat-toolbar *ngIf="profilUtilisateur.droits_utilisateur.droit_module_admin_compta == true" class="navbar">
    <div class="div-container-between" >
        <div style="width: 180px;">
            <a mat-button  *ngIf="true" class="label-accent"><mat-icon >account_balance</mat-icon>Comptabilité</a>
        </div>
        <div class="div-container-left" style="width: 80%;">

        </div>
        
        <div class="label-accent" style="padding-right: 8px; font-size: 14px;">
            <!-- {{ dateDuJour | date: 'EEEE d MMMM, y' }} -->
        </div>
    </div>
    
</mat-toolbar>

<mat-card appearance="outlined" class="mat-card-no-shadow" style="padding: 0px">
    <form [formGroup]="FiltreFormGroup">
        <mat-expansion-panel id="expandFilter" [(expanded)]="expandPannel" #expandFilter>            
            <mat-expansion-panel-header>
                <mat-panel-title class="label-accent">
                    <mat-icon>search</mat-icon>Veuillez sélectionner un service et une année à calculer...
                </mat-panel-title>
            </mat-expansion-panel-header>                    

            <mat-form-field>
                <mat-label>Services</mat-label>
                <mat-select name="service" id="service" formControlName="service">
                    <mat-option [value] = "0">TOUS</mat-option>
                    <mat-option *ngFor="let service of services" [value]="service.id">
                        {{ service.libelle }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="!FiltreFormGroup?.valid && FiltreFormGroup.touched">Vous devez sélectionner au moins un service pour votre recherche</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Année</mat-label>
                <mat-select name="annee" id="annee" formControlName="annee">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let item of annee" [value]="item">{{ item }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-action-row>
                <div class="div-button-center" style="padding: 8px; width: 100%;">
                    <div class="button-container">                        
                        <button mat-flat-button 
                                *ngIf       = "!waitingResearch"
                                color       = "primary" 
                                (click)     = "onRechercheCA()" 
                                [disabled]  = "waitingResearch || !FiltreFormGroup.valid">
                            <mat-icon>search</mat-icon>Rechercher
                        </button>
                        <mat-spinner diameter="24" *ngIf="waitingResearch"></mat-spinner>
                    </div>
                </div>
            </mat-action-row>
        </mat-expansion-panel>                
    </form>
</mat-card>

<mat-card appearance="outlined" class="mat-card-no-shadow" style="padding: 0px">
    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon color="primary">file</mat-icon>
                    <span style="width: 220px;" *ngIf="chiffre_affaires != undefined"><span class="span-primary">{{ chiffre_affaires.length }}</span>CHIFFRE D'AFFAIRES</span>
                    <span style="width: 220px;" *ngIf="chiffre_affaires == undefined"><span class="span-primary">0</span>CHIFFRE D'AFFAIRE</span>                                                               
                </mat-panel-title>
                <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>
    
            
            <mat-card-subtitle>Mes Chiffres Affaires</mat-card-subtitle>  
            <app-table-chiffres-affaires [ELEMENT_DATA]="chiffre_affaires"></app-table-chiffres-affaires>
        </mat-expansion-panel>
    </mat-accordion>
</mat-card>