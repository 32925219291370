import { Component, OnInit, Input,Output, EventEmitter } from '@angular/core';
import { Modele } from 'src/app/interfaces/support/modele';
import { ApiSupportService } from 'src/app/services/api-support.service';
import { NgFor } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-liste-modele',
    templateUrl: './liste-modele.component.html',
    styleUrls: ['./liste-modele.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatFormFieldModule, MatSelectModule, MatOptionModule, NgFor]
})
export class ListeModeleComponent implements OnInit {
@Input() modeles:Modele[]=[]
@Output() modeleSelectedEvent = new EventEmitter<Modele>() ;

  constructor(public apiSupportService      : ApiSupportService) { }

  ngOnInit(): void {
  }

  getModele(modele:Modele){
    this.modeleSelectedEvent.emit(modele)
  }
}
