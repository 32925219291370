import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'pluralFormat',
    standalone: true
})

export class PluralFormatPipe implements PipeTransform {
  transform(name: string, count : number, pluralForm : string|null=null): string {
    let nameForm = name;
    if (count>1) {
        if (pluralForm===null) {
            nameForm+='s'
        }else{
            nameForm = pluralForm
        }
    }
    return count + ' ' + nameForm ;
  }

}
