import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';

export interface RelationUtilisateurEntite {
      id                      : number;
      utilisateur_id          : number, 
      entite_id               : number, 
      est_dirigeant           : boolean
      est_active              : boolean

      utilisateur             : ProfilUtilisateur
      entite                  : Entite
}

export interface Utilisateur {
      nom         : string,
      prenom      : string,
      motdepasse  : string,
      agrement_ve : string,
      email       : string,
      idgrp            : number,
      actif       : boolean
}

export const UtilisateurInit:Utilisateur={
            'agrement_ve'     : ''
      ,     'email'           : ''
      ,     'idgrp'         : 0
      ,     'motdepasse'      : ''
      ,     'nom'             : ''
      ,     'prenom'          : ''
      ,     'actif'           : true
}

export interface UtilisateurEntite {
      utilisateur : Utilisateur
      , entite    : Entite
}

