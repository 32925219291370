import { Component, OnInit } from '@angular/core';
import { Article } from 'src/app/interfaces/commande/article';
import { TableArticlesComponent } from '../table-articles/table-articles.component';
import { NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { FiltreArticlesComponent } from '../filtre-articles/filtre-articles.component';

export interface OutPutRechercheArticles{
  articles: Array<Article>;
}
@Component({
    selector: 'app-mes-articles',
    templateUrl: './mes-articles.component.html',
    styleUrls: ['./mes-articles.component.scss'],
    standalone: true,
    imports: [FiltreArticlesComponent, MatCardModule, MatExpansionModule, MatIconModule, NgIf, TableArticlesComponent]
})
export class MesArticlesComponent implements OnInit {

  articles            : Array<Article> = Array<Article>();
  rafraichir_recherche: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  // Transmets les résultats de la recherche au component des résultats
  public onChildResearchArticles(event: OutPutRechercheArticles): void {
    this.articles = event.articles;
  }

}
