// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Components
import { MesAcquittementsComponent } from './components/acquittement/mes-acquittements/mes-acquittements.component';
import { AnalyseAdcComponent } from './components/adc/analyse-adc/analyse-adc.component';
import { AnalyseAdelaComponent } from './components/adela/analyse-adela/analyse-adela.component';
import { EtiquetteAdelaComponent } from './components/adela/etiquette-adela/etiquette-adela.component';
import { FicheListeCabinetsComponent } from './components/administration-extranet/gestion-cabinets/fiche-liste-cabinets/fiche-liste-cabinets.component';
import { FicheListeUtilisateursComponent } from './components/administration-extranet/gestion-utilisateurs/fiche-liste-utilisateurs/fiche-liste-utilisateurs.component';
import { AppInstallComponent } from './components/application/app-install/app-install.component';
import { FicheArticleComponent } from './components/article/fiche-article/fiche-article.component';
import { MesArticlesComponent } from './components/article/mes-articles/mes-articles.component';
import { ForgottenPasswordComponent } from './components/authentification/forgotten-password/forgotten-password.component';
import { LoginComponent } from './components/authentification/login/login.component';
import { ResetPasswordComponent } from './components/authentification/reset-password/reset-password.component';
import { KitsComponent } from './components/commande/kits/kits.component';
import { MesCommandesComponent } from './components/commande/mes-commandes/mes-commandes.component';
import { RecapCommandeComponent } from './components/commande/recap-commande/recap-commande.component';
import { TableCommandeComponent } from './components/commande/table-commande/table-commande.component';
import { DocApercuComponent } from './components/docutech/doc-apercu/doc-apercu.component';
import { VignetteComponent } from './components/docutech/vignette/vignette.component';
import { MesDossiersComponent } from './components/dossiers/mes-dossiers/mes-dossiers.component';
import { MesFacturesComponent } from './components/facture/mes-factures/mes-factures.component';
import { RecapFactureComponent } from './components/facture/recap-facture/recap-facture.component';
import { InscriptionComponent } from './components/inscription/inscription.component';
import { ConfirmationInvitationEntiteComponent } from './components/invitation/confirmation-invitation-entite/confirmation-invitation-entite.component';
import { PhrasierComponent } from './components/phrasier/espace-phrasier/phrasier.component';
import { FormPhrasierComponent } from './components/phrasier/form-phrasier/form-phrasier.component';
import { EntiteComponent } from './components/profil-user/entite/entite.component';
import { ProfilComponent } from './components/profil-user/profil/profil.component';
import { UtilisateurComponent } from './components/profil-user/utilisateur/utilisateur.component';
import { FicheEntiteServiceComponent } from './components/services-ffea/profil/fiche-entite-service/fiche-entite-service.component';
import { LogFiltreServiceExterneComponent } from './components/services-ffea/service-externe/log-service-externe/log-filtre-service-externe/log-filtre-service-externe.component';
import { StatistiquesComponent } from './components/statistique/statistiques/statistiques.component';
import { ContacterSupportComponent } from './components/support/contacter-support/contacter-support.component';
import { GrilleTarifaireComponent } from './components/tarif/grille-tarifaire/grille-tarifaire.component';
import { HomeComponent } from './components/templates/home/home.component';
import { MainPageComponent } from './components/templates/main-page/main-page.component';
import { SimplePageComponent } from './components/templates/simple-page/simple-page.component';
import { HistoriqueVehiculeComponent } from './components/vehicule/historique-vehicule/historique-vehicule.component';

// Guards
import { AnalyseAdelaPoudreComponent } from './components/adela/analyse-adela-poudre/analyse-adela-poudre.component';
import { ComposanteComponent } from './components/adela/composante/composante.component';
import { EtiquetteComponent } from './components/adela/etiquette/etiquette.component';
import { HomeComptabiliteComponent } from './components/administration-extranet/gestion-comptabilite/home-comptabilite/home-comptabilite.component';
import { FicheInformationsBancairesComponent } from './components/administration-extranet/gestion-comptabilite/informations-bancaires/fiche-informations-bancaires/fiche-informations-bancaires.component';
import { ShellEmailComponent } from './components/administration-extranet/gestion-email/ui/shell-email/shell-email.component';
import { FicheFournisseurComponent } from './components/administration-extranet/gestion-fournisseurs/fiche-fournisseur/fiche-fournisseur.component';
import { FicheListeFournisseursComponent } from './components/administration-extranet/gestion-fournisseurs/fiche-liste-fournisseurs/fiche-liste-fournisseurs.component';
import { GestionParametreComponent } from './components/administration-extranet/gestion-parametre/gestion-parametre.component';
import { ShellPrestationComponent } from './components/administration-extranet/gestion-prestations/shell-prestation/shell-prestation.component';
import { FicheTypeDemandeComponent } from './components/administration-extranet/gestion-support/fiche-type-demande/fiche-type-demande.component';
import { SupportComponent } from './components/administration-extranet/gestion-support/support/support.component';
import { FacturationShellComponent } from './components/compta/facturation/facturation-shell/facturation-shell.component';
import { FactureShellComponent } from './components/compta/facture/facture-shell/facture-shell.component';
import { GroupeShellComponent } from './components/groupe/groupe-shell/groupe-shell.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ShelSuiviComponent } from './components/rdea/ui/shel-suivi/shel-suivi.component';
import { RechercheServicesExterneComponent } from './components/services-ffea/reparateur/dossier/recherche-services-externe/recherche-services-externe.component';
import { FicheServiceExterneComponent } from './components/services-ffea/service-externe/fiche-service-externe/fiche-service-externe.component';
import { FiltreServiceExterneComponent } from './components/services-ffea/service-externe/filtre-service-externe/filtre-service-externe.component';
import { ListeUtilisateursServicesComponent } from './components/services-ffea/utilisateurs/liste-utilisateurs-services/liste-utilisateurs-services.component';
import { ShellTvaHabiliationComponent } from './components/statistique/shell-tva-habiliation/shell-tva-habiliation.component';
import { ShellSuspensionComponent } from './components/suspension/ui/shell-suspension/shell-suspension.component';
import { ShellTypesuspensionComponent } from './components/suspension/ui/shell-typesuspension/shell-typesuspension.component';
import { FicheTicketComponent } from './components/ticket/fiche-ticket/fiche-ticket.component';
import { ModeleComponent } from './components/ticket/modeles/modele/modele.component';
import { RechercheTicketComponent } from './components/ticket/recherche-ticket/recherche-ticket.component';
import { ProtectAcquittementGuard } from './security/protect-acquittement.guard';
import { ProtectAdministrationAdelaGuard } from './security/protect-administration-adela.guard';
import { ProtectAdministrationComptabiliteGuard } from './security/protect-administration-comptabilite.guard';
import { ProtectAdministrationExtranetGuard } from './security/protect-administration-extranet.guard';
import { ProtectAdministrationGuard } from './security/protect-administration.guard';
import { protectAdministrationWsaGuard } from './security/protect-administration-wsa.guard';
import { ProtectAuthGuard } from './security/protect-auth.guard';
import { ProtectEnvironmentExtranetGuard } from './security/protect-environment-extranet.guard';
import { ProtectEnvironmentServicesGuard } from './security/protect-environment-services.guard';
import { ProtectExtranetModificationInformationEntiteGuard } from './security/protect-extranet-modification-information-entite.guard';
import { ProtectExtranetModificationTrombinoscopeEntiteGuard } from './security/protect-extranet-modification-trombinoscope-entite.guard';
import { ProtectGestionEmailsGuard } from './security/protect-gestion-emails.guard';
import { ProtectServiceAdcGuard } from './security/protect-service-adc.guard';
import { ProtectServiceAdelaGuard } from './security/protect-service-adela.guard';
import { ProtectServiceHistoriqueVehiculeGuard } from './security/protect-service-historique-vehicule.guard';
import { ProtectServiceStatistiquesGuard } from './security/protect-service-statistiques.guard';
import { ProtectSuspensionGuard } from './security/protect-suspension.guard';
import { GroupeEditComponent } from './components/groupe/groupe-edit/groupe-edit.component';
import { WsaComponent } from './components/wsa/ui/wsa.component';
import { MissionComponent } from './components/wsa/ui/mission/mission.component';
import { RedirectionComponent } from './components/wsa/ui/redirection/redirection.component';
import { MailShellComponent } from './components/mail/ui/mail-shell/mail-shell.component';
import { InscriptionRefuseeComponent } from './components/inscription/inscription-refusee/inscription-refusee.component';
import { ShellRapport1aComponent } from './components/concentrateur/ui/shell-rapport1a/shell-rapport1a.component';

const routes: Routes = [
  
  // Routes des SERVICES FFEA
  { path: 'svc', component: MainPageComponent, canActivate: [ProtectEnvironmentServicesGuard, ProtectAuthGuard], canActivateChild: [ProtectAuthGuard], title : 'Services FFEA'
    , children: [
      { path: 'home'                              , component: HomeComponent,  title: 'Accueil' },                                                                                                              // Menu principal
      { path: 'reparateur/dossier'                , component: RechercheServicesExterneComponent, title: 'Dossier réparateur'},                                                                               // Page de recherche d'un dossier réparateur
      { path: 'profil/:utl_id'                    , component: ProfilComponent, title: 'Profil'},                                                                                                             // Page profil utilisateur
      { path: 'entite/:ent_id'                    , component: FicheEntiteServiceComponent,  title: 'Fiche entité'},                                                                                           // Page entité fiche
      { path: 'admin/user'                        , component: ListeUtilisateursServicesComponent, title: 'Administration utilisateur' },                                                                      // Page Utilisateur des services FFEA
      { path: 'admin/service-externe'             , component: FiltreServiceExterneComponent, title: 'Administration services externes'},                                                                     // Page des services externe
      { path: 'admin/service-externe/:id'         , component: FicheServiceExterneComponent,  title: 'Fiche services externes'},                                                                               // Page fiche d'un service externe
      { path: 'admin/log-service-externe'         , component: LogFiltreServiceExterneComponent, title: 'Logs services externes'},                                                                            // Page log des services externes
      { path: 'support/nous-contacter'            , component: ContacterSupportComponent,  title: 'Support - Nous contacter'},                                                                                 // Page de support
      { path: '**'                                , component: NotFoundComponent, pathMatch: 'full' },                                                                                                                          // Chemin par défaut
    ]
  },

  { path: 'app', component: MainPageComponent, canActivate: [ProtectEnvironmentExtranetGuard, ProtectAuthGuard], canActivateChild: [ProtectAuthGuard],  title: 'Extranet FFEA'
    , children: [
      { path: 'home'                                , component: HomeComponent, title: 'Accueil'},                                                                                                            // Menu principal
      { path: 'profil/:utl_id'                      , component: ProfilComponent, title: 'Profil'},                                                                                                           // Page profil utilisateur                                  
      
      { path: 'tarif/:code_service'                 , component: GrilleTarifaireComponent   , canActivate: [ProtectServiceAdelaGuard],  title: 'Tarif' },                                                                                                   // Page tarifaire des services ADELA, ADC, ...

      { path: 'entite'                              , component: EntiteComponent            , canActivate: [ProtectExtranetModificationInformationEntiteGuard],  title: 'Entité'},                             // Page entité
      { path: 'entite/:id'                          , component: EntiteComponent            , canActivate: [ProtectExtranetModificationInformationEntiteGuard],  title: 'Fiche entité' },                       // Page entité fiche
      { path: 'entite/:ent_id/utilisateur/:utl_id'  , component: UtilisateurComponent       , canActivate: [ProtectExtranetModificationTrombinoscopeEntiteGuard], title: 'Utilisateur de l\'entité'},         // Page entité/utilisateur fiche
      { path: 'entite/:id/succursale'               , component: EntiteComponent            , canActivate: [ProtectExtranetModificationInformationEntiteGuard], title: 'Succursale de l\'entité' },            // Page création d'une succursale
      
      { path: 'historiquevehicule'                  , component: HistoriqueVehiculeComponent, canActivate: [ProtectServiceHistoriqueVehiculeGuard],  title: 'Historique véhicule'},                            // Page des historiques de véhicule
      { path: 'historiquevehicule/:immat'           , component: HistoriqueVehiculeComponent, canActivate: [ProtectServiceHistoriqueVehiculeGuard],  title: 'Historique véhicule - Immatriculation' },          // Page des historiques de véhicule
      { path: 'historiquevehicule/:vin'             , component: HistoriqueVehiculeComponent, canActivate: [ProtectServiceHistoriqueVehiculeGuard], title: 'Historique véhicule - VIN' },                      // Page des historiques de véhicule
      
      { path: 'rdea-suivi'                          , component: ShelSuiviComponent         , title: 'Suivi dossiers RDEA Certif' 
        ,children : [
          { path: 'edit'                            , component: ShelSuiviComponent         , title: 'dossier RDEA Certif' }
          ,{ path: 'siv'                            , component: ShelSuiviComponent         , title: 'Suivi SIV' }
        ]
      },                            // Page des historiques de véhicule
      { path: 'rapport1a'                          , component: ShellRapport1aComponent         , title: 'Rapport des opérations 1B non envoyées' },

      { path: 'adela-poudre/dossier/:id'            , component: AnalyseAdelaPoudreComponent, canActivate: [ProtectServiceAdelaGuard],  title: 'Dossier ADELA - Poudre' },
      { path: 'adela/dossier/:id'                   , component: AnalyseAdelaComponent      , canActivate: [ProtectServiceAdelaGuard], title: 'Dossier ADELA' },                                               // Analyse des lubrefiants automobile
      { path: 'adela/etiquette'                     , component: EtiquetteAdelaComponent    , canActivate: [ProtectAdministrationAdelaGuard], title: 'Étiquette ADELA' },                                             // Page d'impression des étiquettes
      { path: 'adela/impression-etiquette'          , component: EtiquetteComponent         , canActivate: [ProtectAdministrationAdelaGuard], title: 'Impression des étiquette ADELA' },                                             // Page d'impression des étiquettes
      { path: 'adela/composantes'                   , component: ComposanteComponent        , canActivate: [ProtectAdministrationAdelaGuard], title: 'Composants' },                                             // Page de gestion des composantes d'un fluide ou d'une poudre
      { path: 'phrasier'                            , component: PhrasierComponent          , canActivate: [ProtectAdministrationAdelaGuard], title: 'Phrasier'                                                   // Page Espace phrasier
      ,children:[                           
        { path: 'edit'                              , component: PhrasierComponent, canActivate: [ProtectAdministrationAdelaGuard], title: 'Edition d\'une phrase' }                                   // Page Formulaire phrasier
      ]
      },                                                                                         
      
      { path: 'adc'                                 , component: AnalyseAdcComponent        , canActivate: [ProtectServiceAdcGuard], title: 'ADC' },                                                           // Analyse des clés
      { path: 'adc/:idDossier/:status'              , component: AnalyseAdcComponent        , canActivate: [ProtectServiceAdcGuard], title: 'Dossier ADC' },                                                   // Analyse des clés
      
      { path: 'commande/mes-commandes'              , component: MesCommandesComponent, title: 'Commandes' },                                                                                                  // Gestion des commandes
      { path: 'commande/table-commande'             , component: TableCommandeComponent, title: 'Liste des commandes' },                                                                                       // Gestion des commandes
      { path: 'commande/recap-commande/:id'         , component: RecapCommandeComponent, title: 'Récapitulatif commande' },                                                                                    // Gestion des commandes
      { path: 'article/kits/:idCategorie'           , component: KitsComponent, title: 'Commande de kits' },                                                                                                   // Page du catalogue des kits
      { path: 'catalogue/kits-adela'                , component: KitsComponent, title: 'Commande de kits' },                                                                                                   // Page du catalogue des kits
      
      { path: 'adela-poudre'                        , component:AnalyseAdelaPoudreComponent,   title: 'ADELA - Poudre' },
      { path: 'adela'                               , component:AnalyseAdelaComponent,   title: 'ADELA' },  

      { path: 'dossier/mes-dossiers'                , component: MesDossiersComponent, title: 'Dossiers', canActivate: [ProtectServiceAdelaGuard] 
      , children:[
          { path: 'adela-poudre'                        , component:MesDossiersComponent,   title: 'ADELA - Poudre' },
          { path: 'adela'                               , component:MesDossiersComponent,   title: 'ADELA' },                                                       // Analyse des lubrefiants automobile
        ]

      },                                                                                                    // Page mes dossiers
      
      { path: 'facture/mes-factures'                , component: MesFacturesComponent, title: 'Liste des factures' },                                                                                          // Page mes factures
      { path: 'facture/recap-facture/:id/:admin'    , component: RecapFactureComponent, title: 'Récapitulatif facture' },
      
      { path: 'administration-extranet/comptabilite' , component: HomeComptabiliteComponent, canActivate: [ProtectAdministrationComptabiliteGuard], title: 'Comptabilité' },                                                                                          // Page comptabilité
      { path: 'administration-extranet/comptabilite/informations-bancaires/:id_entite' , component: FicheInformationsBancairesComponent, canActivate: [ProtectAdministrationComptabiliteGuard], title: 'Fiche informations bancaires' },                              // Page comptabilité fiche informations bancaires                              // Page comptabilité fiche informations bancaires
      
      {path:  'administration-extranet/support'     , component: SupportComponent, canActivate: [ProtectAdministrationGuard], title: 'Gestion du support'},                                                                               // Page des types de demande
      {path:  'administration-extranet/support/type-demande/:typ_id', component: FicheTypeDemandeComponent, canActivate: [ProtectAdministrationGuard], title: 'Fiche type demande'},                                                              // Page fiche type demande

      { path: 'articles'                            , component: MesArticlesComponent, canActivate: [ProtectAdministrationExtranetGuard], title: 'Liste des articles' },                                                                                          // Page des articles
      { path: 'article/:id_article'                 , component: FicheArticleComponent, canActivate: [ProtectAdministrationExtranetGuard], title: 'Fiche Article' },                                                                                              // Fiche d'un article

      { path: 'administration-extranet/cabinets'    , component: FicheListeCabinetsComponent, canActivate: [ProtectAdministrationExtranetGuard], title: 'Gestion des cabinets' 
        ,children:[
          { path: 'edit'    , component: FicheListeCabinetsComponent, canActivate: [ProtectAdministrationExtranetGuard], title: 'Création d\'un cabinet' }
        ]
      },        
      
      { path: 'administration-extranet/utilisateurs', component: FicheListeUtilisateursComponent, canActivate: [ProtectAdministrationExtranetGuard], title: 'Gestion des utilisateurs' },
      { path: 'administration-extranet/fournisseurs', component: FicheListeFournisseursComponent, canActivate: [ProtectAdministrationExtranetGuard], title: 'Gestion des fournisseurs' },
      { path: 'administration-extranet/fournisseur/:id_fournisseur', component: FicheFournisseurComponent, canActivate: [ProtectAdministrationExtranetGuard], title: 'Fiche fournisseur' },
      { path: 'administration-extranet/parametres',   component: GestionParametreComponent, canActivate: [ProtectAdministrationGuard], title: 'Gestion des paramètres' },
      { path: 'administration-extranet/mails'     ,   component: MailShellComponent, canActivate: [ProtectAdministrationGuard], title: 'Consultation des mails' },
      
      { path: 'administration-extranet/prestations',  component: ShellPrestationComponent, canActivate: [ProtectAdministrationAdelaGuard], title: 'Gestion des prestations' 
        ,children:[
          { path: 'edit'                            ,  component: ShellPrestationComponent, canActivate: [ProtectAdministrationAdelaGuard], title: 'Edition d\'une prestation' }
        ]
      },
      
      { path: 'administration-extranet/emails',       component: ShellEmailComponent, canActivate: [ProtectGestionEmailsGuard], title: 'Gestion des emails' 
        ,children:[
          { path: 'edit'                      ,       component: ShellEmailComponent, canActivate: [ProtectGestionEmailsGuard], title: 'Edition d\'un email'} 
        ]
      },
      { path: 'acquittement/mes-acquittements'      , component: MesAcquittementsComponent  , canActivate: [ProtectAcquittementGuard], title: 'Acquittement' },                                                // Page mes acquittements

      { path: 'wsa/cabinets'                        , component: WsaComponent         , canActivate: [protectAdministrationWsaGuard], title: 'Gestion des redirections de cabinets' },                                                // Page mes acquittements
      { path: 'wsa/missions'                        , component: MissionComponent     , canActivate: [protectAdministrationWsaGuard], title: 'Gestion des redirections de missions' },
      { path: 'wsa/redirections'                    , component: RedirectionComponent , canActivate: [protectAdministrationWsaGuard], title: 'Historique des redirections' },                                                // Page mes acquittements
      
      { path: 'statistiques/mes-statistiques'       , component: StatistiquesComponent      , canActivate: [ProtectServiceStatistiquesGuard], title: 'Statistiques' },                                         // Page mes statistiques
      { path: 'actualite/:id'                       , component: DocApercuComponent, title: 'Actualité' },
      { path: 'support/nous-contacter'              , component: ContacterSupportComponent, title: 'Support - Nous contacter' },
      { path: 'support/liste-tickets'               , component: RechercheTicketComponent, title: 'Support - Les tickets' },
      { path: 'support/ticket/:idTicket'            , component: FicheTicketComponent, title: 'Support - Ticket' },                                                                                 // Page de support                                                                             // Page de support
      { path: 'support/modele'                      , component: ModeleComponent, canActivate: [ProtectAdministrationGuard], title: 'Support - Modèle' 
        ,children:[
          { path: 'edit'                      , component: ModeleComponent, canActivate: [ProtectAdministrationGuard], title: 'Support - Edition d\'un modèle' }
        ]
      },                                                                                 // Page de support                                                                             // Page de support
      
      { path: 'suspension/type'                     , component: ShellTypesuspensionComponent, canActivate: [ProtectSuspensionGuard], title: 'Type de suspension' 
        ,children : [
          { path: 'edit'                            , component: ShellTypesuspensionComponent, canActivate: [ProtectSuspensionGuard], title: 'Type de suspension' }
        ]
      },
      { path: 'suspension'                          , component: ShellSuspensionComponent, canActivate: [ProtectSuspensionGuard], title: 'Suspensions' 
        , children : [
          { path: 'edit'                            , component: ShellSuspensionComponent, canActivate: [ProtectSuspensionGuard], title: 'Suspension' },     
        ]
      },
      { path: 'suspension/entite/:id'               , component: ShellSuspensionComponent, canActivate: [ProtectSuspensionGuard], title: 'Suspension' },

      { path: 'groupe'                              , component: GroupeEditComponent, canActivate: [ProtectAdministrationGuard], title: 'Groupes' },
      { path: 'droit'                               , component: GroupeShellComponent, canActivate: [ProtectAdministrationGuard], title: 'Droits' },

      { path: 'compta/factures'                     , component: FactureShellComponent, canActivate: [ProtectAdministrationComptabiliteGuard], title: 'Compta - Factures' },                                                                                 // Page de support                                                                             // Page de support
      { path: 'compta/facturation'                  , component: FacturationShellComponent, canActivate: [ProtectAdministrationComptabiliteGuard], title: 'Compta - Facturation' },                                                                                 // Page de support                                                                             // Page de support 
      { path: 'compta/statistique'                  , component: ShellTvaHabiliationComponent, canActivate: [ProtectAdministrationComptabiliteGuard], title: 'Compta - Statistique' },
      { path: '**'                                  , component: NotFoundComponent, pathMatch: 'full' },                                                                                                                     // Chemin par défaut
    ]
  },
  
    { path: '', component: SimplePageComponent
      , children: [
        { path: 'service'                           , component: LoginComponent , title: 'Services FFEA' },                                                                                                    // Page de connexion pour les SERVICES FFEA
        { path: 'login'                             , component: LoginComponent , title: 'Extranet FFEA' },                                                                                                    // Page de connexion pour l'EXTRANET FFEA
        { path: 'login/forgotten-password'          , component: ForgottenPasswordComponent , title: 'Mot de passe oublié ?' },                                                                                // Page de demande de reinitialisation du mot de passe
        { path: 'login/reset-password/:id_token'    , component: ResetPasswordComponent , title: 'Réinitialisation du mot de passe' },                                                                         // Page de réinitialisation du mot de passe
        { path: 'install'                           , component: AppInstallComponent },                                                                                                                                 // component de mise a jour et raccourci de page d'accueil
        { path: 'inscription/:login/:idEntite/:pass', component: InscriptionComponent, title: 'Inscription' },
        { path: 'inscription-refusee', component: InscriptionRefuseeComponent, title: 'Inscription refusée' },
        { path: 'invitation-collaborateur/:tocken'  , component: ConfirmationInvitationEntiteComponent, title: 'Invitation collaborateur' },                                                                   // Page de confirmation d'invitation du collaborateur à rejoindre l'entité
      
        // developpement...                                                                                                                                          // page de test
        { path: 'dev/histo'                       , component: VignetteComponent },                                                                                                                                     // test affichage historique mobile
        { path: 'dev/actu'                        , component: DocApercuComponent },
        
        // si aucun path ne correspond => login extranet
        { path: ''                                 , redirectTo: 'login', pathMatch: 'full' },
        { path: '**'                              , component: NotFoundComponent, pathMatch: 'full' },                                                                                                                           // Chemin par défaut
      ]
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
