import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLinkActive } from '@angular/router';
import { Adresse, AdresseInit, TypeAdresse, TypesAdresse } from 'src/app/interfaces/adresse/adresse';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { AdresseService } from '../adresse/adresse.service';
import { EditAdresseComponent } from '../adresse/edit-adresse/edit-adresse.component';
import { EntiteAdresse } from './entite-adresse';

@Component({
    selector: 'app-entite-adresse',
    templateUrl: './entite-adresse.component.html',
    styleUrls: ['./entite-adresse.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, MatExpansionModule, MatIconModule, MatButtonModule, MatTooltipModule, MatListModule, RouterLinkActive, EditAdresseComponent]
})
export class EntiteAdresseComponent implements OnInit {

  @Input()  entites       : Entite[] = []
  @Input() types          : string = 'LIV'
  @Output() adresseEvent  = new EventEmitter<EntiteAdresse>()
  
  modeEdit          : boolean = false
  addressToEdit     : Adresse = AdresseInit
  typesAdresse      : TypeAdresse[] = []
  idEntiteSelected  : number = 0
  
  constructor(private apiAdresse: AdresseService) {}


  ngOnInit(): void {
    // this.dataSource.data = this.entitesAdresses
    this.types.split(',').forEach(
      (type:string)=> {
        const typeAdresse = TypesAdresse.find((elt)=> elt.type === type)
        if (typeAdresse) {
          this.typesAdresse.push(typeAdresse)
        }
      } 
    )
  }

  getFormatedAddress(adresse:Adresse){
    return this.apiAdresse.getFormatedAdresse(adresse)
  }

  selectAddress(adresse:Adresse,entite:Entite){
    const entiteAdresse : EntiteAdresse = {'adresse':adresse, 'entite':entite}
    this.adresseEvent.next(entiteAdresse)
  }

  addAddress(idEntite:number){
    this.idEntiteSelected = idEntite
    this.addressToEdit = {...AdresseInit, 'relAdr':{...this.addressToEdit.relAdr!,'tbl_id':idEntite}}
    this.modeEdit         = true
  }

  modifyAddress(address:Adresse,idEntite:number){
    this.idEntiteSelected = idEntite
    this.addressToEdit    = address
    this.modeEdit         = true
  }

  getAdresseEdited(adresseToSave:Adresse|null){
    if (adresseToSave != null) {
      
      if (adresseToSave.relAdr?.adr_id!=0) {
        //Il s'agit d'une adresse enregistrée dans la table Adresse et non dans la table Entite
        if (adresseToSave.id === 0) {
          this.apiAdresse.postAdresse(adresseToSave).subscribe(
            (adresseSaved:Adresse)=> {
              this.entites.map((entite:Entite)=> entite.id === this.idEntiteSelected? entite.adresses? entite.adresses = [...entite.adresses,adresseSaved] : [] : entite )
            }
          )
        }else{
          this.apiAdresse.putAdresse(adresseToSave).subscribe(
            (adresseSaved:Adresse)=>{
              this.entites = this.entites.map(
                (entite:Entite)=> entite.id === this.idEntiteSelected? 
                                                        
                                                        entite.adresses? 
                                                              entite = {...entite, adresses: entite.adresses.map(
                                                                (adresse:Adresse)=> adresse.id===adresseSaved.id? adresseSaved : adresse
                                                                ) }
                                                             : entite = entite
                                                      : entite 
                ) as Entite[]
              }
          )
        }
      }
      }
    this.modeEdit = false
  }

}
