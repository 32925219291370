import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiErrorService } from 'src/app/services/support/api-error.service';
import { RDEAFile } from '../interfaces/rdeaFile';
import { Suivi, SuiviFiltre, SuiviSiv } from '../interfaces/suivi';

@Injectable({
  providedIn: 'root'
})
export class SuiviService {

  url               : string = 'api/rdea'
  
  CONST_STATUT_ENCOURS  : number = 0 as const;
	CONST_STATUT_TERMINE  : number = 1 as const;
	CONST_STATUT_ARCHIVE  : number = 2 as const;

  constructor(private http:HttpClient
              , private apiError:ApiErrorService) { }

  //1.1 - Select
  public getSuivis(filtre:SuiviFiltre| null):Observable<Suivi[]>{
    
    return this.http.post<Suivi[]>(this.url+'/suivis',filtre)
                    .pipe(catchError(this.apiError.handleError<Suivi[]>('getSuivis',[])));
  }

  public archiveSuivi(suivi:Suivi):Observable<Suivi>{
    return this.http.put<Suivi>(this.url+'/suivi',suivi)
  }

  public getReferences():Observable<any>{
    return this.http.get(this.url+'/references')
  }

  public sendSuivi(){
    // return this.http.put(this.urlConcentrateur)
  }

  public saveSuivi(rdeaSuivi:Suivi):Observable<Suivi>{
    if (rdeaSuivi.id===0) {
      return this.http.post<Suivi>(this.url+'/suivi',rdeaSuivi)
    }else{
      return this.http.put<Suivi>(this.url+'/suivi',rdeaSuivi)
    }
  }

  public getDossier(idSuivi:number):Observable<RDEAFile>{
    return this.http.get<RDEAFile>(this.url+'/dossier/'+idSuivi)
  }

  public getSuiviSiv(filtreSiv:SuiviFiltre):Observable<SuiviSiv[]>{
    return this.http.post<SuiviSiv[]>(this.url+'/siv',filtreSiv)
  }

  public getLibelleStatut(statut:number) : string {
    let libelle : string;

    switch (statut) {
      case this.CONST_STATUT_ENCOURS:
        libelle = 'En cours';
        break

      case this.CONST_STATUT_TERMINE:
        libelle = 'Terminé';
        break
        
      case this.CONST_STATUT_ARCHIVE:
        libelle = 'Archivé';
        break
        
      default:
        libelle = '';
    }

    return libelle;
  }
}
