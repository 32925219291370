<ng-container *ngIf="isMobileFormat(); then loginInLine else loginInColumn"></ng-container>

<ng-template #loginInLine>
    <mat-card appearance="outlined" style="margin-top: 100px;">
            <form [formGroup]="loginForm">
                <mat-form-field style="margin-top: 32px;">
                  <mat-label>Adresse email</mat-label>
                  <input matInput type="email" formControlName="adresseEmail">
                  <mat-error *ngIf="adresseEmail?.invalid && (adresseEmail?.dirty || adresseEmail?.touched)">Le format de l'adresse email est incorrect</mat-error>
                </mat-form-field>
               
                    <mat-form-field>
                        <mat-label>Mot de passe</mat-label>
                        <input matInput formControlName="motDePasse" [type]="hidePassword ? 'password' : 'text'" >
                        <mat-icon style="cursor: pointer" matSuffix mat-icon-button color="primary" [matTooltip]="!hidePassword ? 'Masquer le mot de passe' : 'Afficher le mot de passe'" (click)="hidePassword = !hidePassword">{{!hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </mat-form-field>    
               
                
                <!-- remember me -->
                <div>
                    <mat-checkbox id=rememberMe color="primary" formControlName="rememberMe" style="padding-top: 16px;">Se souvenir de moi</mat-checkbox>
                </div>
                
                
                <div class="button div-container" style="padding-top: 16px;">                
                    <!-- forgotten password -->
                    <a mat-basic-button routerLink="/login/forgotten-password">Mot de passe oublié ?</a>                
                    <!-- connexion -->
                    <button mat-raised-button color="primary" (click)="connexion()"                     
                    [disabled]="waitingConnexion ||!loginForm.valid">Connexion</button>                                
                </div>
            </form>    
    </mat-card>
</ng-template>

<ng-template #loginInColumn class="host">
    <mat-card appearance="outlined" style="margin-top: 100px;">
        <mat-card-title>
            <img id="logo-login" src="../../../../assets/img/charte/FFEA 2 lignes aplat coul.png" alt="" height="64">
        </mat-card-title>
    
        <mat-card-content class=".mat-card-content">
            <form [formGroup]="loginForm">
                <mat-form-field style="margin-top: 32px;">
                  <mat-label>Adresse email</mat-label>
                  <input matInput type="email" formControlName="adresseEmail">
                  <mat-error *ngIf="adresseEmail?.invalid && (adresseEmail?.dirty || adresseEmail?.touched)">Le format de l'adresse email est incorrect</mat-error>
                </mat-form-field>
                
                    <mat-form-field>
                        <mat-label>Mot de passe</mat-label>
                        <input matInput formControlName="motDePasse" [type]="hidePassword ? 'password' : 'text'" >
                        <mat-icon style="cursor: pointer" matSuffix mat-icon-button color="primary" [matTooltip]="!hidePassword ? 'Masquer le mot de passe' : 'Afficher le mot de passe'" (click)="hidePassword = !hidePassword">{{!hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </mat-form-field>    
                
                <!-- remember me -->
                <div>    
                    <mat-checkbox id=rememberMe color="primary" formControlName="rememberMe" style="padding-top: 16px;">Se souvenir de moi</mat-checkbox>
                </div>
                
                
                <div class="button div-container" style="padding-top: 16px;">                
                    <!-- forgotten password -->
                    <a mat- mat-stroked-button routerLink="/login/forgotten-password" fragment="{{ fragmentForgetPassword }}">Mot de passe oublié ?</a>                
                    <!-- connection -->
                    <button mat-flat-button 
                            *ngIf       = "!waitingConnexion"
                            color       = "primary" 
                            style       = "margin-left: 10px;" 
                            (click)     = "connexion()"
                            [disabled]  = "waitingConnexion ||!loginForm.valid">Connexion</button>                                
                            <mat-spinner diameter="24" *ngIf="waitingConnexion"></mat-spinner>
                </div>
            </form>                        
        </mat-card-content>
    </mat-card>
</ng-template>