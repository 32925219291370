import { AsyncPipe, NgFor, NgIf, UpperCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, forkJoin } from 'rxjs';
import { map, shareReplay, take, tap } from 'rxjs/operators';
import { Pagination, PaginationInitial } from 'src/app/interfaces/pagination';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { RelationUtilisateurEntite } from 'src/app/interfaces/utilisateur-entite/relation-utilisateur-entite';
import { ProtectExtranetModificationTrombinoscopeEntiteGuard } from 'src/app/security/protect-extranet-modification-trombinoscope-entite.guard';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { ApiRelUtilisateurEntiteService } from 'src/app/services/api-rel-utilisateur-entite.service';
import { PhoneFormatPipe } from '../../../pipe/phone-format.pipe';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { GroupeService } from '../../groupe/data-access/groupe.service';

export interface OutputParent3{
  RelUtilisateurEntite              : Array<RelationUtilisateurEntite>; 
}

export interface FiltreUtilisateur{
  nom           : string,
  prenom        : string,
  agrement      : string,
  desactivated  : boolean
}
@Component({
    selector: 'app-liste-utilisateurs',
    templateUrl: './liste-utilisateurs.component.html',
    styleUrls: ['./liste-utilisateurs.component.scss'],
    standalone: true,
    imports: [NgIf, MatCardModule, MatIconModule, MatSlideToggleModule, ReactiveFormsModule, FormsModule, MatFormFieldModule, MatInputModule
            , NgFor, MatExpansionModule, MatButtonModule, MatPaginatorModule, AsyncPipe, UpperCasePipe, PhoneFormatPipe, MatTableModule]
})
export class ListeUtilisateursComponent implements OnInit, OnChanges {
  
  // Évènement émit au component parent pour lui transférer la liste des utilisateurs de l'entité
  @Output() cellClicked = new EventEmitter<OutputParent3>();

  length                        : number = 0;
  pageIndex                     : number = 0;
  pageSize                      : number = 5;
  previousPageIndex?            : number;
  pageSizeOptions               = PaginationInitial.pageSizes;
  displayedColumns: string[] = ['agrement', 'nom', 'prenom', 'email', 'telephone', 'dirigeant', 'groupe','accesConcentrateur', 'details'];
  
  dataSource = new MatTableDataSource<RelationUtilisateurEntite>([]);
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  //mise en place du filtre US 381:
  
  utilisateursEntite$           = this.apiRelUtilisateurEntite.getRelUtilisateurEntite(0, +this.routed.snapshot.params['id']).pipe(shareReplay(1))
  private filtreSelectedSubject = new BehaviorSubject<FiltreUtilisateur>({"agrement":'',"nom":'',"prenom":'',"desactivated":false})
  
  filtreSelectedAction$         = this.filtreSelectedSubject.asObservable()
  private paginationSubject     = new BehaviorSubject<Pagination>(PaginationInitial)
  
  pagination$                   = this.paginationSubject.asObservable()

  utilisateursEntiteFiltres$ = combineLatest([
    this.utilisateursEntite$,
    this.filtreSelectedAction$
  ]).pipe(
      map(([utilisateursEntite,filtreUtilisateur]) =>
        utilisateursEntite.filter(utilisateurEntite => {
            return utilisateurEntite.utilisateur.nom.toUpperCase().includes(filtreUtilisateur.nom.toUpperCase()) 
                  && utilisateurEntite.utilisateur.prenom.toUpperCase().includes(filtreUtilisateur.prenom.toUpperCase())
                  && utilisateurEntite.utilisateur.agrement_ve.toUpperCase().includes(filtreUtilisateur.agrement.toUpperCase())
                  && ((!filtreUtilisateur.desactivated && utilisateurEntite.est_active === true) || (filtreUtilisateur.desactivated && utilisateurEntite.est_active === false))
        })
      )
  )
  
  filtreNom           : string  = ''
  filtrePrenom        : string  = ''
  filtreAgrement      : string  = ''
  filtreDesactive     : boolean = false

  ent_id              : number = 0;
  utl_id              : number = 0;
  
  isAdmin             : boolean = false;
  isDirigeant         : boolean = false;
  isAdela             : boolean = false;
  @Input() isDirigeantPrincipal: boolean = false;
  
  RelUtilisateurEntite: Array<RelationUtilisateurEntite> = new Array<RelationUtilisateurEntite> ();  
  
  @Input() entite     : Entite = <Entite>{};
  userConnect         : ProfilUtilisateur = <ProfilUtilisateur>{};

  // Déclaration du formulaire  
  profilUtilisateurForm = this.fBuilder.group({
    id            : [null],
    agrement_ve   : [''],
    nom           : [''],
    prenom        : [''],
    email         : [''],
    telephone     : [''],
    date_creation : [''],
    date_derniere_modification: [''],
    actif         : [null],
    role_entite   : {
      id          : [0],
      libelle     : ['']
    }
  });

  constructor(private apiRelUtilisateurEntite: ApiRelUtilisateurEntiteService
            , private apiAuth: ApiAuthentificationService
            , private routed: ActivatedRoute
            , private route: Router
            , private fBuilder: FormBuilder
            , private guardModifUser: ProtectExtranetModificationTrombinoscopeEntiteGuard
            , public _sanitizer: DomSanitizer
            , public apiEntite: ApiEntiteService
            , private apiGroupe : GroupeService
          ) { 
              
              // this.routed.params.subscribe(val => {
              //   this.ngOnInit();
              // });
            }

  ngOnInit(): void {
    // On force le reset des valeurs par défaut, car lorsqu'on recharge le component liste-utilisateur,    
    // des valeurs peuvent déjà être configuré par une "session" précédente (session dans le sens appel depuis une autre page)
    // mais ne pas être réinitialisé car le component ne refait pas la déclaration des variables puisqu'il a déjà été initialisé par la "session" précédente
    this.initializeValues()

    this.utilisateursEntite$ = this.apiRelUtilisateurEntite.getRelUtilisateurEntite(0, this.ent_id);

    combineLatest([
      this.apiGroupe.getGroupes('').pipe(take(1)),
      this.utilisateursEntiteFiltres$.pipe()
    ]).subscribe(
      ([groupes,utilisateurs])=>{
        
        this.dataSource.data = utilisateurs.map((utilisateur:RelationUtilisateurEntite)=>{
          const groupe = groupes.find((groupeCherche)=> groupeCherche.id === utilisateur.utilisateur.idgrp)
          if (groupe) {
            return {...utilisateur,'groupName':groupe.nom}
          }else{
            return {...utilisateur,'groupName':''}
          }
        })
        this.dataSource.paginator = this.paginator;
      }
    )
  
  this.dataSource.paginator = this.paginator;
  }

  ngOnChanges() {
    // this.initializeValues()
  }

  initializeValues() {
    this.length               = 0;
    this.pageIndex            = 0;
    this.pageSize             = 5;
    this.ent_id               = 0;
    this.utl_id               = 0;    
    this.isAdmin              = false;
    this.isDirigeant          = false;    
    this.RelUtilisateurEntite = new Array<RelationUtilisateurEntite> ();          
    this.userConnect          = <ProfilUtilisateur>{};
    
    // Affectation des valeurs par défaut
    this.ent_id       = this.routed.snapshot.params['id'];
    this.utl_id       = this.apiAuth.authUser.id;
    this.userConnect  = this.apiAuth.userConnect;
    this.isAdmin      = this.userConnect.droits_utilisateur.est_admin;
    this.isAdela      = (this.entite.typ_ent_code==this.apiEntite.CONST_CODE_TYPE_ENTITE_ADELA);

    this.getData();
  }

  getData(): void {
    // Récupération de la listes des utilisateurs associé à une entité
    this.apiRelUtilisateurEntite.getRelUtilisateurEntite(0, this.ent_id)
      .subscribe(
        (data: RelationUtilisateurEntite[]) => {        
          this.RelUtilisateurEntite = data;
          
          this.cellClicked.emit({
            RelUtilisateurEntite : this.RelUtilisateurEntite
          });
          
          // est ce que l'utilisateur connecté est le dirigeant ?
          for(let i = this.RelUtilisateurEntite.length - 1; i >= 0; i--) {
            if(this.RelUtilisateurEntite[i].est_dirigeant === true && this.RelUtilisateurEntite[i].entite_id == this.ent_id && this.RelUtilisateurEntite[i].utilisateur_id == this.utl_id) {
              this.isDirigeant = true;
            }
          }
          
          this.length = this.RelUtilisateurEntite.length;
         }, 
        (err) => { console.log(err) }
      );
  }

  openProfilUtilisateur(utl_id: any, $e: Event): void {
    $e.stopPropagation;
    this.guardModifUser.ent_id = this.ent_id;
    this.route.navigate(['/app/entite/' + this.ent_id + '/utilisateur/' + utl_id]);
  }

  getLabelRoleEntite(estDirigeant: boolean) {
    if(estDirigeant) {
      return 'Dirigeant·e'
    } else {
      return ''
    }
  }

  getAccesConcentrateur(user: RelationUtilisateurEntite) {
    let autorisation: boolean = false;

    for(let i=0; i<user.utilisateur.acces_concentrateur.length;i++) {
      if(user.utilisateur.acces_concentrateur[i].cle_expert === user.utilisateur.agrement_ve + this.entite.habilitation) {
        autorisation = true;
      }
    }

    return autorisation;
  }

  onFilterChanged(){
    const filtreUtilisateur:FiltreUtilisateur = {"nom":this.filtreNom,"prenom":this.filtrePrenom,"agrement":this.filtreAgrement,"desactivated":this.filtreDesactive}
    this.filtreSelectedSubject.next(filtreUtilisateur)
  }

  onChangePaginator(event: PageEvent) {
    this.length             = event.length;
    this.pageIndex          = event.pageIndex;
    this.pageSize           = event.pageSize;
    this.previousPageIndex  = event.previousPageIndex;

    this.paginationSubject.next({...PaginationInitial,"currentPage":this.pageIndex,"pageSize":this.pageSize,"selectedSize":this.pageSize})
  }
}
