import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

@Injectable({ providedIn: 'root' })
export class AgrementValidator {
    verificationFormatAgrementVE():ValidatorFn {
      return (control: AbstractControl):ValidationErrors | null  => {
        
        let s_agrement_ve:  string = control.value;
        let a_regex:        any = /^[0-9]{6}(-VE)?$/;
        
        if (s_agrement_ve != '' && !a_regex.test(s_agrement_ve)) {      
          return { formatAgrementVE: 'L"agrément VE doit faire 6 chiffres. Facultatif : terminé par "-VE" pour les experts agréés aux Véhicules Endommagés'}
        }
    
        return null;
      };
    }

  }