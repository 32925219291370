<mat-card appearance="outlined" class="mat-card-no-shadow" style="padding: 0px;">
    <form [formGroup]="FiltreFormGroup">
        <mat-expansion-panel id="expandFilter" [(expanded)]="expandPannel" #expandFilter>                                
            <mat-expansion-panel-header style="background-color: #fff">
                <mat-panel-title class="label-accent">
                    <mat-icon>search</mat-icon>Veuillez sélectionner les options de votre recherche...
                </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-form-field>
                <mat-label>Dénomination</mat-label>
                <input matInput type="text" placeholder="Dénomination" name="denomination" id="denomination" formControlName="denomination" oninput="this.value = this.value.toUpperCase()">
                <mat-error *ngIf="denomination?.invalid && (denomination?.dirty || denomination?.touched)">{{ denomination?.errors?.erreur }}</mat-error>
            </mat-form-field> 

            <mat-form-field>
                <mat-label>Code</mat-label>
                <input matInput type="text" placeholder="Code" name="code" id="code" formControlName="code" oninput="this.value = this.value.toUpperCase()">
                <mat-error *ngIf="code?.invalid && (code?.dirty || code?.touched)">{{ code?.errors?.erreur }}</mat-error>
            </mat-form-field> 

            <mat-action-row>
                <div class="div-container-between" style="padding: 8px;">
                    <div class="div-container-center" style="width:100%; display: flex; justify-content: center;">
                        <button mat-flat-button submit color="primary" [disabled]="waitingResearch || !FiltreFormGroup.valid" (click)="onSearchingSupplier()">
                            <mat-icon>search</mat-icon>Rechercher                                    
                        </button>
                    </div>
                </div>
            </mat-action-row>
        </mat-expansion-panel>
    </form>
</mat-card>

<div class="spinner-container" *ngIf="waitingResearch">
    <mat-spinner diameter="24"></mat-spinner>
</div>