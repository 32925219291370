import { Component } from '@angular/core';
import { InscriptionService } from '../../../services/inscription.service';
import {  MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inscription-refusee',
  templateUrl: './inscription-refusee.component.html',
  styleUrls: ['./inscription-refusee.component.scss'],
  standalone:true,
  imports:[MatButtonModule]
})
export class InscriptionRefuseeComponent {
  dataEntite : any = null
  information : string = ''

  constructor(private apiInscription  : InscriptionService,
              private router          : Router
  ){}

  ngOnInit(){
    this.dataEntite = this.apiInscription.dataEntite
    
    if (this.dataEntite) {
      if (this.dataEntite.autoriserMigration===false) {
        this.information = "Le cabinet " + this.dataEntite.raison_sociale + " d'habilitation " + this.dataEntite.habilitation +  " n'est pas autorisé à être migré sur le nouvel extranet" 
      }else if(this.dataEntite.estMigre === 1){
        this.information = "Le cabinet " + this.dataEntite.raison_sociale  + " d'habilitation " + this.dataEntite.habilitation + " est déjà migré sur le nouvel extranet" 
      }else if(this.dataEntite.siretExistant === 1){
        this.information = "Il existe déjà un cabinet dans le nouvel extranet avec le siret " + this.dataEntite.siret
      }else{
        this.router.navigate(['/']);
      }
    }else{
      this.router.navigate(['/']);
    }
  }

  goToHome(){
    this.router.navigate(['/']);
  }
}
