import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormBuilder, FormGroup, FormGroupDirective, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, mergeMap, switchMap } from 'rxjs/operators';
import { Adresse } from 'src/app/interfaces/adresse/adresse';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { Etablissement, EtablissementInitial, ReponseSiren, SirenService } from 'src/app/services/siren.service';

@Component({
    selector: 'app-siren',
    templateUrl: './siren.component.html',
    styleUrls: ['./siren.component.scss'],
    viewProviders: [
        {
            provide: ControlContainer,
            useExisting: FormGroupDirective
        }
    ],
    standalone: true,
    imports: [ReactiveFormsModule, MatFormFieldModule, MatInputModule, NgIf,MatGridListModule]
})

export class SirenComponent implements OnInit {
  
  @Input()  controlKey    = 'sirenGroup'
  @Input()  siretInit = ''
  @Input()  searchSiret : boolean = true
  @Output() adresseEvent  = new EventEmitter<Adresse>()

  sirenFormGroup : FormGroup = this._fb.group({
      siret         : ['',[Validators.required, this.apiSiren.checkLengthSIRET]]
    , siren         : ['',Validators.required]
    , raisonSociale : ['',Validators.required]
  })

  get siret()             { return this.sirenFormGroup.get('siret'); }

  siretValid : boolean = false
  
  constructor(
                private _fb         : FormBuilder
              , private apiSiren    : SirenService
              , private parentForm  : FormGroupDirective
              , private toast       : PersoSnackbarService
  ) { }

  ngOnInit(): void {
    this.parentForm.form.addControl(this.controlKey,this.sirenFormGroup)

    if (this.searchSiret) {
      this.siret?.valueChanges.pipe(
        debounceTime(500),  // Ajoute un délai pour éviter les appels API trop fréquents
        switchMap(value => this.searchSiret$(value)),
        catchError(error => {
          // Gère l'erreur ici, par exemple en affichant un message d'erreur
          console.error('Erreur lors de la recherche du SIRET:', error);
          // Renvoyer un observable vide pour que l'observable ne s'arrête pas
          return of(null);
        })
      ).subscribe(
        (data : ReponseSiren | null)=> {
        if (data ) {
          switch (data.header.statut) {
            case 200:
              
            if (data.etablissement.periodesEtablissement[0].etatAdministratifEtablissement==='A') {
                this.siretValid=true;
                this.setEtablissement(data.etablissement)
              }
              else
              {
              
                this.siretValid=false;
                this.toast.showError('Cet établissement n\'est plus actif, vous ne pouvez donc pas l\'enregistrer')
                this.setEtablissement(EtablissementInitial)
              }
              
              break;
          
            case 404:
              //non trouvés
              this.toast.showError(data.header.message)
              this.setEtablissement(data.etablissement)
              
              break;
            
              case 410:
              //La requête de sélection du siret dans la base a échoué
              this.toast.showError(data.header.message)
              this.setEtablissement(data.etablissement)
              break;

            case 409:
              //Un établissement avec ce SIRET existe déjà dans la base
              this.toast.showError(data.header.message)
              this.setEtablissement(data.etablissement)
            break;
          }
        }}
      )

      if (this.siretInit!='') {
        this.siret?.setValue(this.siretInit)      
      }
    } else {
      this.siret?.valueChanges.subscribe ((siret :string) =>
        this.sirenFormGroup.patchValue({
          siren         : siret.substring(0,9)
        })
      )
    }
  }

  searchSiret$(siret:string):Observable<any>{
    
    if (siret.length === 14) {
      return this.apiSiren.getEtablissement(siret).pipe(
        catchError(error => {
          // Gère l'erreur ici si nécessaire
          this.siretValid = false;
          this.setEtablissement(EtablissementInitial)
          this.toast.showError(error.error)
          console.error('Erreur lors de l\'appel API:', error);
          return of(null);
        })
      );
    } else {
      return of(null);  // Renvoyer un observable vide si la longueur du SIRET n'est pas correcte
    }
  }

  
  setEtablissement(etablissement:Etablissement){
    let raisonSociale:string = ''
    if (etablissement.uniteLegale.categorieJuridiqueUniteLegale==='1000') {
      //Il s'agit d'une entreprise personnelle et la raison sociale est le nom + prénom
      raisonSociale = etablissement.uniteLegale.nomUniteLegale + ' '  + etablissement.uniteLegale.prenomUsuelUniteLegale
    }else{
      raisonSociale = etablissement.uniteLegale.denominationUniteLegale
    }
    this.sirenFormGroup.patchValue({
        raisonSociale : raisonSociale
      , siren         : etablissement.siren
    })
    this.sirenFormGroup.get('raisonSociale')?.setValue(raisonSociale)
    this.setAdresse(etablissement)
  }

  setAdresse(etablissement:Etablissement){
    const adresse : Adresse = {
        'ligne1'  : this.apiSiren.getVoieFormatee(etablissement)
      , 'ligne2'  : etablissement.adresseEtablissement.complementAdresseEtablissement
      , 'ligne3'  : ''
      , 'cp'      : etablissement.adresseEtablissement.codePostalEtablissement
      , 'ville'   : etablissement.adresseEtablissement.libelleCommuneEtablissement
    }
    this.adresseEvent.next(adresse)
  }

  ngOnDestroy(){
    this.parentForm.form.removeControl(this.controlKey)
  }
}
