<div [hidden]="modeEdit === true">
    <mat-card appearance="outlined" >
        <mat-card-header>
            <mat-card-title></mat-card-title>
        </mat-card-header>

        
        <div class="container" *ngIf="prestationsDisplayed$">
            <table mat-table 
            
                    matTableResponsiveLarge 
                    matTableExporter 
                    [dataSource]="ds" 
                    #exporter="matTableExporter" 
                    class="table-primary">

                <!-- Sélection -->
                <ng-container matColumnDef="selection">
                    <th mat-header-cell *matHeaderCellDef style="text-align: center; padding-right: 16px;" class="premiere-colonne colonne-invisible"> Sélection </th>
                    <td mat-cell *matCellDef="let prestation" style="text-align: center;" class="colonne-invisible">
                        <mat-checkbox></mat-checkbox>
                    </td>
                </ng-container>
                
                <!-- Active -->
                <ng-container matColumnDef="active">
                    <th mat-header-cell *matHeaderCellDef style="text-align: left; padding-right: 16px;" class="premiere-colonne colonne-invisible"> Activée </th>
                    <td mat-cell *matCellDef="let prestation" style="text-align: left;" class="colonne-invisible">
                        <mat-icon [ngClass]="prestation.actif===true? 'done-icon'  : 'cancel-icon'">{{prestation.actif===true? 'done' : 'close'}}</mat-icon>
                    </td>
                </ng-container>

                 <!-- Affichée -->
                 <ng-container matColumnDef="affiche">
                    <th mat-header-cell *matHeaderCellDef style="text-align: left; padding-right: 16px;" class="premiere-colonne colonne-invisible"> Affichée </th>
                    <td mat-cell *matCellDef="let prestation" style="text-align: left;" class="colonne-invisible">
                        <mat-icon color="primary">{{prestation.affiche===true? 'visibility' : 'visibility_off'}}</mat-icon>
                    </td>
                </ng-container>

                <!-- Code -->
                <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef style="text-align:left; padding-right: 16px;" class="premiere-colonne colonne-invisible"> Code </th>
                    <td mat-cell *matCellDef="let prestation" style="text-align: left;" class="colonne-invisible">
                        {{ prestation.code }}
                    </td>
                </ng-container>

                <!-- Catégorie -->
                <ng-container matColumnDef="categorie">
                    <th mat-header-cell *matHeaderCellDef > Catégorie </th>
                    <td mat-cell *matCellDef="let prestation" >
                        {{ prestation.categorie }}
                    </td>
                </ng-container>

                <!-- Désignation -->
                <ng-container matColumnDef="designation">
                    <th mat-header-cell *matHeaderCellDef style="text-align: left; padding-right: 16px;" >  Désignation </th>
                    <td mat-cell *matCellDef="let prestation" style="text-align: left;" >
                    {{prestation.designation}}
                    </td>
                </ng-container>

                <!-- libellé tarif -->
                <ng-container matColumnDef="libelle">
                    <th mat-header-cell *matHeaderCellDef style="text-align: left; padding-right: 16px;" >  Libellé grille tarifaire </th>
                    <td mat-cell *matCellDef="let prestation" style="text-align: left;" >
                    {{prestation.libelle}}
                    </td>
                </ng-container>

                <!-- Code Laboratoire -->
                <ng-container matColumnDef="codeLaboratoire">
                    <th mat-header-cell *matHeaderCellDef style="text-align: left; padding-right: 16px;"> Code Labo </th>
                    <td mat-cell *matCellDef="let prestation" >{{ prestation.codeLaboratoire }}</td>
                </ng-container>

                <!-- Organe -->
                <ng-container matColumnDef="organe">
                    <th mat-header-cell *matHeaderCellDef style="text-align: left; padding-right: 16px;"> Organe </th>
                    <td mat-cell *matCellDef="let prestation">
                        {{prestation.organe}}    
                    </td>
                </ng-container>

                <!-- Fluide -->
                <ng-container matColumnDef="fluide">
                    <th mat-header-cell *matHeaderCellDef style="text-align: left; padding-right: 16px;"> Fluide </th>
                    <td mat-cell *matCellDef="let prestation">
                        {{prestation.fluide}}    
                    </td>
                </ng-container>

                <!-- Prix -->
                <ng-container matColumnDef="tarif">
                    <th mat-header-cell *matHeaderCellDef  style="text-align: right; padding-right: 16px;" class="th-right"> Tarif HT</th>
                    <td mat-cell *matCellDef="let prestation" class="th-right">
                        {{prestation.tarif}} €    
                    </td>
                </ng-container>

            
                <!-- Actions -->
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef style="padding-left: 16px" > </th>
                    <td mat-cell *matCellDef="let prestation" class="th-right">
                       
                        <button mat-icon-button class="button-etat" 
                                style       = "width: 48px; height: 48px;"
                                matTooltip  = "Editer la prestation"
                                (click)     = "editer(prestation)">
                                <mat-icon color="primary">edit</mat-icon>
                        </button>

                        <button mat-icon-button class="button-etat" 
                                style       = "width: 48px; height: 48px;"
                                matTooltip  = "Dupliquer la prestation"
                                (click)     = "dupliquer(prestation)">
                                <mat-icon color="primary">content_copy</mat-icon>
                        </button>

                        <button mat-icon-button class="button-etat" 
                                style       = "width: 48px; height: 48px;"
                                matTooltip  = "Supprimer la prestation"
                                (click)     = "supprimer(prestation)">
                                <mat-icon color="warn">cancel</mat-icon>
                        </button>
                    </td>
                </ng-container>

                

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <div class="div-container-between" style="align-items: center;background-color:white">
                <mat-paginator [pageSizeOptions]="[10,25,50]"
                    showFirstLastButtons 
                    aria-label="Nombre de lignes visibles...">
                </mat-paginator>
            </div>
            
            
        </div>
    </mat-card>

</div>