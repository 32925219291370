<mat-toolbar class="navbar">
    <mat-label class="label-accent" style="font-size: 18px;">
          <mat-icon class="img-logo" *ngIf="!Entite$.logo">apartment</mat-icon>
          <img class="img-logo" style="margin-right: 8px; margin-left: 8px;"
                *ngIf="Entite$.logo" 
                [src]="_sanitizer.bypassSecurityTrustResourceUrl(Entite$.logo)">
          {{ Entite$.raison_sociale }}
    </mat-label>   
</mat-toolbar>

<mat-tab-group animationDuration="0ms" #tab>      
    <mat-tab>
          <ng-template mat-tab-label>
                <mat-icon>feed</mat-icon>
                <span class="mat-tab-label">Informations générales</span>
          </ng-template>
          <!-- app-entite-service déconnecté -->
          <app-entite-service [isDirigeant]="isDirigeant"></app-entite-service>         
    </mat-tab>   
    <mat-tab *ngIf="isDirigeant">
          <ng-template mat-tab-label>
                <mat-icon>car_repair</mat-icon>
                <span class="mat-tab-label">Comptes Réparateur</span>
          </ng-template>
          <!-- app-entite-service-externe déconnecté -->
          <app-entite-service-externe [isDirigeant]="isDirigeant"></app-entite-service-externe>         
    </mat-tab>   
</mat-tab-group>
