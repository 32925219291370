import { NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import * as saveAs from 'file-saver';
import { AlphaNumDirective } from 'src/app/directive/alphaNumdirective';
import { Analyse } from 'src/app/interfaces/adela/analyse';
import { Etiquette } from 'src/app/interfaces/adela/etiquette';
import { FormulaireAdela } from 'src/app/interfaces/adela/formulaire-adela';
import { ListeElements } from 'src/app/interfaces/adela/liste-elements';
import { ListeParametrage } from 'src/app/interfaces/adela/liste-parametrage';
import { Adresse, AdresseInit } from 'src/app/interfaces/adresse/adresse';
import { Fichier, UploadFile } from 'src/app/interfaces/fichier';
import { HistoriqueVehicule } from 'src/app/interfaces/vehicule/historique-vehicule';
import { ApiAdelaService } from 'src/app/services/api-adela.service';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiBreakpointService } from 'src/app/services/api-breakpoint.service';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { HitoriqueVehiculeService } from 'src/app/services/hitorique-vehicule.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { ApiUtilitaireService } from 'src/app/services/tools/api-utilitaire.service';
import { CheckValidatorService } from 'src/app/services/tools/check-validator.service';
import { ReferenceValidator } from 'src/app/validators/reference.validator';
import { ImmatDirective } from '../../../directive/immat.directive';
import { CarteAdresseComponent } from '../../templates/cards/carte-adresse/carte-adresse.component';
import { EntiteListComponent } from '../../templates/entite-list/entite-list.component';
import { EntiteSearchComponent } from '../../templates/entite-search/entite-search.component';
import { UploadFilesComponent, UploadParameter, initialUploadParameter } from '../../templates/upload-files/upload-files.component';
import { ResultatAnalyseEditComponent } from '../resultat-analyse-edit/resultat-analyse-edit.component';
import { Subscription } from 'rxjs';
import { Entite } from 'src/app/interfaces/profil-user/entite';


@Component({
    selector: 'app-analyse-adela-poudre',
    templateUrl: './analyse-adela-poudre.component.html',
    styleUrls: ['./analyse-adela-poudre.component.scss'],
    standalone: true,
    imports: [MatCardModule, NgIf, NgStyle, MatIconModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule
              , NgFor, MatOptionModule, EntiteListComponent, MatInputModule, ImmatDirective, UploadFilesComponent
              , MatButtonModule, MatProgressSpinnerModule, ResultatAnalyseEditComponent,EntiteSearchComponent
            ,CarteAdresseComponent,AlphaNumDirective
          ]
})
export class AnalyseAdelaPoudreComponent implements OnInit {
  @Input()                idDossierComp : number = 0;
  @Output()             close = new EventEmitter<boolean>()

  idDossier               : number = 0;
  formAdelaPoudre         : FormulaireAdela = <FormulaireAdela>{};
  adelaPoudreForm!        : FormGroup;
  files                   : Fichier[] = [];
  waiting                 : boolean   = false;
  idSelectedEntite        : number    = 0;
  prestations$            = this.apiAdela.getAnalyseFromSelection(0,0,0,2)
  prestations             : Analyse[] = []
  idSelectedPrestation    : number    = 0
  selectedPrestation      : Analyse|null= <Analyse>{}
  modeCreation            : boolean = true;
  AfficherResultat        : boolean = false;
  etatDossier             : string = '';
  etatCode                : string = '';
  validateButtonLabel     : string = 'Enregistrer ma demande';
  uploadParameter         : UploadParameter = initialUploadParameter
  energies                : ListeElements[] = []; 
  usages                  : ListeElements[] = [];
  searching               : boolean = false
  adresseEntite           : Adresse = AdresseInit
  etiquettes              : Etiquette[]=[]
  
  constructor(private _fb           : FormBuilder,
              public apiAdela      : ApiAdelaService,
              private toast         : PersoSnackbarService,
              private router        : Router,
              private route         : ActivatedRoute,
              public apiAuth       : ApiAuthentificationService,
              private apiUtil       : ApiUtilitaireService,
              private apiHisto      : HitoriqueVehiculeService,
              public apiBreakpoint : ApiBreakpointService
            ) {}
  
  get vin()               { return this.adelaPoudreForm.get('vin'); }
  get energie()         { return this.adelaPoudreForm.get('energie'); }
  get typeMoteur()      { return this.adelaPoudreForm.get('typeMoteur'); }
  get numEtiquette()      { return this.adelaPoudreForm.get('etiquette'); }
  get mec()             { return this.adelaPoudreForm.get('mec'); }
  get modele()          { return this.adelaPoudreForm.get('modele'); }
  get marqueVehicule()  { return this.adelaPoudreForm.get('marqueVehicule'); }
  get quantite()        { return this.adelaPoudreForm.get('quantite'); }
  get reference()       { return this.adelaPoudreForm.get('reference'); }

  referenceValidator = inject(ReferenceValidator)

  ngOnInit(): void {
    
    this.route.params.subscribe(params => {
      const idDossierRoute = +params['id'];
      
      // Récupération dans l'URL de l'id du dossier
      if (this.idDossierComp > 0) {
        //L'id est transmis par le composant parent
        this.idDossier = this.idDossierComp
      }else{
        //L'id est transmis par le routeur:
        this.idDossier = idDossierRoute;
      }
      this.initDossier()
      
  });

  this.apiAdela.getAllListe().subscribe(
    (liste:ListeParametrage)=>{
      this.energies = liste.tab_Energie
      this.usages   = liste.tab_Usage
    }
  )
  }

  verificationFormatTypeMoteur(): any {
    return null;
  }

  verificationFormatMEC():any{
    return null
  }
  verificationFormatModele():any{
    return null
  }
  verificationFormatMarqueVehicule():any{
    return null
  }

  initDossier(){
  // this.idDossier = this.route.snapshot.params['id'];
  this.modeCreation = this.idDossier === 0 || !this.idDossier
  if (!this.modeCreation) {
    this.validateButtonLabel = 'Valider la demande d\'analyse'
  }
  this.adelaPoudreForm = this._fb.group({
    prestation      : [{value:'',disabled:!this.modeCreation},Validators.required],
    comment         : [{value:'',disabled:!this.modeCreation}],
    etiquette       : [{value:'',disabled:!this.modeCreation},[Validators.required]],
    immat           : ['',Validators.required],
    vin             : ['',[CheckValidatorService.verificationFormatVIN]],
    energie         : [0, [Validators.required]],
    typeMoteur      : [{value : '', disabled: false}, [this.verificationFormatTypeMoteur.bind(this)]],
    kilometrage     : [0],
    capaciteCarter  : [0.0],
    mec             : [{value : '', disabled: false}, [this.verificationFormatMEC.bind(this)]],
    modele          : [{value : '', disabled: false}, [this.verificationFormatModele.bind(this)]],
    marqueVehicule  : [{value : '', disabled: false}, [this.verificationFormatMarqueVehicule.bind(this)]],
    usage           : [0],
    appoint         : [false],
    quantite        : [{value : 0.0, disabled: true}, [Validators.required]],
    reference       : new FormControl({value:'',disabled:!this.modeCreation},[this.referenceValidator.verificationFormatReference()])
  })
   
  
  this.uploadParameter.activated = this.modeCreation
  
   this.prestations$.subscribe((prestations)=>{
    this.prestations = prestations;
    if (prestations.length > 0) {
      this.idSelectedPrestation = this.prestations[0].id
      this.selectedPrestation = this.prestations[0]
      this.adelaPoudreForm.controls.prestation.setValue(this.idSelectedPrestation)
    }
  })

  if (this.idDossier > 0) {
    this.apiAdela.getDossier(this.idDossier).subscribe(
      (data:any) =>{
        
        this.formAdelaPoudre                          = data;
        this.etatDossier                              = data.etat;
        this.etatCode                                 = data.etatCode;
        this.formAdelaPoudre.tab_PiecesJointes        = data.st_Dossier.tab_PiecesJointes
        this.formAdelaPoudre.idEntite                 = data.st_Dossier.idEntite
        this.formAdelaPoudre.idDossier                = data.st_Dossier.idDossier
        this.idSelectedEntite                         = data.st_Dossier.idEntite
        this.formAdelaPoudre.commentaire              = data.st_Dossier.commentaire
        this.formAdelaPoudre.numEtiquette             = data.st_Dossier.numEtiquette
        this.formAdelaPoudre.immatriculation          = data.st_Dossier.immatriculation
        this.formAdelaPoudre.vin                      = data.st_Dossier.vin
        this.formAdelaPoudre.marqueVehicule           = data.st_Dossier.marqueVehicule;
        this.formAdelaPoudre.modele                   = data.st_Dossier.modele;
        this.formAdelaPoudre.dateMEC                  = data.st_Dossier.dateMEC;
        this.formAdelaPoudre.typeMoteur               = data.st_Dossier.typeMoteur
        this.formAdelaPoudre.kilometrage              = data.st_Dossier.kilometrage
        this.formAdelaPoudre.capaciteCarter           = data.st_Dossier.capaciteCarter
        this.formAdelaPoudre.idUsage                  = data.st_Dossier.idUsage
        this.formAdelaPoudre.idIncendie               = data.st_Dossier.idIncendie;
        this.formAdelaPoudre.quantiteAppoints         = data.st_Dossier.quantiteAppoints;
        this.formAdelaPoudre.appoints                 = data.st_Dossier.appoints;
        this.formAdelaPoudre.idEnergie                = data.st_Dossier.idEnergie;
        this.formAdelaPoudre.referenceDossierExpert   = data.st_Dossier.referenceDossierExpert;
        this.validateButtonLabel                      =  this.setValidateButtonLabel();
        
        this.adelaPoudreForm.patchValue({
          comment         : this.formAdelaPoudre.commentaire,
          prestation      : this.idSelectedPrestation,
          etiquette       : this.formAdelaPoudre.numEtiquette,
          immat           : this.formAdelaPoudre.immatriculation,
          vin             : this.formAdelaPoudre.vin,
          energie         : this.formAdelaPoudre.idEnergie,
          typeMoteur      : this.formAdelaPoudre.typeMoteur,
          kilometrage     : this.formAdelaPoudre.kilometrage,
          capaciteCarter  : this.formAdelaPoudre.capaciteCarter,
          mec             : this.formAdelaPoudre.dateMEC.split("T")[0],
          modele          : this.formAdelaPoudre.modele,
          marqueVehicule  : this.formAdelaPoudre.marqueVehicule,
          usage           : this.formAdelaPoudre.idUsage,
          appoint         : this.formAdelaPoudre.appoints,
          quantite        : '',
          reference       : this.formAdelaPoudre.referenceDossierExpert
        })
        
        // L'état du dossier est 'ANALYSE' ou 'DIAGNOSTIC' ou 'CLOTURE'
        if(this.etatCode === this.apiAdela.CONST_ETAT_ADELA_4_EnCoursAnalyse || this.etatCode === this.apiAdela.CONST_ETAT_ADELA_5_EnCoursDiagnostic || this.etatCode === this.apiAdela.CONST_ETAT_ADELA_6_Cloture) {
          this.AfficherResultat = true;
          if (this.etatCode ===this.apiAdela.CONST_ETAT_ADELA_6_Cloture) {
            this.adelaPoudreForm.disable()
          }
        } else {
          this.AfficherResultat = false;
        }
      }
    )    
  }

  }
  
  getInfosVehicule(event:any){
    if (event.target) {
      const Immat = event.target.value
      this.apiHisto.getInfosImmatriculation(Immat).subscribe(
        (histoVehicule : HistoriqueVehicule)=>this.setInfosVehicules(histoVehicule)
      )
    }
  }

  setInfosVehicules(histoVehicule:HistoriqueVehicule){
    
    let energie : ListeElements|undefined;

    // Si le code énergie est vide, on recherche avec le libelle sinon on cherche avec le code
    energie = (histoVehicule.code_energie == '' ? this.energies.find(energie => energie.nom == histoVehicule.energie) : this.energies.find(energie => energie.code == histoVehicule.code_energie));

    this.adelaPoudreForm.patchValue({
      vin             : histoVehicule.vin,
      energie         : energie != undefined ? energie.id : 0,
      mec             : histoVehicule.mec,
      modele          : histoVehicule.modele,
      marqueVehicule  : histoVehicule.marque,
    });
  }
  
  closeDossier(){
    if (this.idDossierComp===0) {
      //on est arrivé par le router
      this.router.navigate(['/app/home']);
    }else{
      //on est arrivé par un composant parent:
      this.close.next(true)
    }
  }
  
  getFiles(files:UploadFile[]):void{
    //Il faut convertir les fichiers de type UploadFile vers le type Fichier:
    this.files = this.apiUtil.uploadFilesToFichiers(files);
    
  }
  
  onSetPrestation(idPrestation: number){
    this.idSelectedPrestation = idPrestation;
    const prestation = this.prestations.find((prestation:Analyse)=>prestation.id===idPrestation)
    if (prestation) {
      this.selectedPrestation = prestation  
      
    }
    
  }

  onSetEntite(entite : Entite){
    this.idSelectedEntite = entite.id!;
    this.apiAdela.getNextAvailableEtiquette(entite.id!).subscribe(
      (etiquettes:Etiquette[])=> {
        this.etiquettes = etiquettes
      }
      )
  }

  public validateAnalyse(){
    
    if (this.modeCreation) {
      this.enregistrerAnalyse()
    }else{
      switch (this.etatCode) {
        case this.apiAdela.CONST_ETAT_ADELA_1_Depose:
          this.validerDemandeAnalyse()
          break;

        case this.apiAdela.CONST_ETAT_ADELA_3_EnAttenteEchantillon:
          this.validerReceptionEchantillon()
          break;
          
        default:
          break;
      }
    }
  }

  public validerDemandeAnalyse() {
    this.waiting = true;

    this.apiAdela.validerDemandeADELA(this.idDossier,this.formAdelaPoudre)
      .subscribe(
        (data : FormulaireAdela) => { 
          this.toast.showInfo("Demande d'analyse a bien été validé.")
          this.router.navigate(['/app/home']);
          this.waiting = false;
        },
        (err) => { 
          console.error('/!\\ error validerDemandeADELA: '+err);
          this.waiting = false;
        }
      );
  }

  public validerReceptionEchantillon(){
    this.waiting = true;
    this.apiAdela.validerDemandeADELA(this.idDossier,this.formAdelaPoudre)
    .subscribe(
      (data : FormulaireAdela) => { 
        this.toast.showInfo("La réception de l'échantillon a bien été validée.")
        this.router.navigate(['/app/home']);
        this.waiting = false;
      },
      (err) => { 
        console.error('/!\\ error validerReceptionEchantillonADELA: '+err);
        this.waiting = false;
      }
    );
  }

  refuserDemande(){
    this.waiting = true
    this.apiAdela.refuserDemandeADELA(this.formAdelaPoudre.idDossier, this.formAdelaPoudre)
      .subscribe(
        (data : FormulaireAdela) => { 
          this.toast.showInfo("Demande d'analyse a bien été refusé.")
          this.closeDossier();
          this.waiting = false;
        },
        (err) => { 
          console.error('/!\\ error refuserDemandeADELA: '+err);
          this.waiting = false;
        }
      );
  }

  public enregistrerAnalyse(){
    
    this.waiting = true;
    
    this.formAdelaPoudre.idEntite               = this.idSelectedEntite
    this.formAdelaPoudre.commentaire            = this.adelaPoudreForm.controls.comment.value 
    this.formAdelaPoudre.numEtiquette           = this.adelaPoudreForm.controls.etiquette.value
    this.formAdelaPoudre.idUtilisateur          = this.apiAuth.userConnect.id
    this.formAdelaPoudre.idPrestationDemandée   = this.idSelectedPrestation.toString()
    this.formAdelaPoudre.immatriculation        = this.apiAdela.getImmatNonFormated(this.adelaPoudreForm.controls.immat.value)
    this.formAdelaPoudre.vin                    = this.adelaPoudreForm.controls.vin.value.replaceAll(' ','')
    this.formAdelaPoudre.tpe_code               = this.apiAdela.CONST_TYPE_ELEMENT_POUDRE;
    this.formAdelaPoudre.marqueVehicule          = this.adelaPoudreForm.controls.marqueVehicule.value;
    this.formAdelaPoudre.modele                  = this.adelaPoudreForm.controls.modele.value;
    this.formAdelaPoudre.dateMEC                 = this.adelaPoudreForm.controls.mec.value;
    this.formAdelaPoudre.idEnergie               = this.adelaPoudreForm.controls.energie.value;
    this.formAdelaPoudre.idUsage                 = this.adelaPoudreForm.controls.usage.value;
    this.formAdelaPoudre.kilometrage             = this.adelaPoudreForm.controls.kilometrage.value;
    this.formAdelaPoudre.typeMoteur              = this.adelaPoudreForm.controls.typeMoteur.value; 
    this.formAdelaPoudre.referenceDossierExpert  = this.adelaPoudreForm.controls.reference.value; 
    
    this.apiAdela.enregistrerAnalyse(this.formAdelaPoudre)
      .subscribe(
        (data) => { 
          this.idDossier = data.idDossier
          this.toast.showInfo(
            "Votre demande d'analyse a bien été prise en compte." 
            + "La facturation de cette analyse sera effectuée lors de votre prochain relevé de factures mensuel." 
            + "Le tarif mensuel est de " + this.selectedPrestation!.tarif+ " € HT"
            );
          
          // envoi des pieces jointes au formulaire
          this.sendFiles(this.idDossier)

          this.router.navigate(['/app/home']);
          this.waiting = false;
        },
        (err) => { 
          console.error('/!\\ error enregistrerAnalyse: '+err);
          this.waiting = false;
        }
      );   
                
  }

  sendFiles(idDossier : number){
    this.files.forEach(element => {
      this.apiAdela.envoiPieceJointe(idDossier, this.formAdelaPoudre.idEntite, element)
      .subscribe(
        (data) => {
          console.info('fichier '+ element.name +' enregistré');
        },
        (err) => {
          console.error('/!\\ error enregistrerAnalyse: '+err);
          this.waiting = false;
        }
      )
    });
  }

  dowloadFile(fileName:string){
    
    this.apiAdela.downloadPieceJointe(this.formAdelaPoudre.idEntite,this.formAdelaPoudre.idDossier,fileName)
    .subscribe(
      (data: any) => {
        saveAs(data, fileName);
        
        this.toast.showInfo('Document téléchargé.');
      }
    )
  }

  setValidateButtonLabel(){
    let label:string='';

    if (this.modeCreation) {
      label =   'Valider la demande d\'analyse'
    } else{
      switch (this.etatCode) {
        case this.apiAdela.CONST_ETAT_ADELA_1_Depose:
          label = 'Validation d\'une demande d\'analyse'
          break;

        case this.apiAdela.CONST_ETAT_ADELA_3_EnAttenteEchantillon:
          label = 'Valider la réception de l\'échantillon'
          break;
          
        default:
          break;
      }
    }
    return label;
  }
}
