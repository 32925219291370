import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

@Injectable({ providedIn: 'root' })
export class MailValidator {
    checkValidatorMail():ValidatorFn {
      return (control: AbstractControl):ValidationErrors | null  => {
        
      const regularExpression =/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let chaine: string = control.value.trim();

      if (!chaine || chaine.trim() === '' || chaine.length === 0) {
        return null; // Valeur valide si vide ou nulle
      }
    
      if (regularExpression.test(String(chaine).toLowerCase())) {
        return null;
      } else {
        return { checkValidatorMail: 'Format de l\'adresse mail incorrect' };
      }
    }
  }
}