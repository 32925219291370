import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { formatDate, NgIf, NgFor } from '@angular/common';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { ApiFactureService } from 'src/app/services/api-facture.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { saveAs } from 'file-saver';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { EntiteSearchComponent } from '../../templates/entite-search/entite-search.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'app-releves',
    templateUrl: './releves.component.html',
    styleUrls: ['./releves.component.scss'],
    standalone: true,
    imports: [MatToolbarModule, NgIf, MatButtonModule, MatIconModule, MatCardModule, ReactiveFormsModule, MatExpansionModule
              , MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, MatProgressSpinnerModule,EntiteSearchComponent
              , MatTooltipModule]
})
export class RelevesComponent implements OnInit {

  // Gestion de l'affichage
  waitingReleves        : boolean = false;
  expandPannel          : boolean = false;

  // Liste des interfaces pour les combos
  profilUtilisateur  : ProfilUtilisateur = <ProfilUtilisateur>{};
  entites            : Entite[] = [];

  entiteSelected    : Entite|null =null
  searching         : boolean = false

  // get entite() { return this.FiltreFormGroup.get('entite'); }

  constructor(private _fb          : FormBuilder
            , private authUser     : ApiAuthentificationService
            , private apiFacture   : ApiFactureService
            , private toast        : PersoSnackbarService) { }

  ngOnInit(): void {
    this.profilUtilisateur = this.authUser.userConnect;
    this.searching= true
  }

  setEntite(entite:Entite|null){
    this.searching = false
    if (entite) {
      this.entiteSelected = entite
    }
  }

  searchingEntite(){
    this.searching = true
  }

  cancelEntite(){
    this.entiteSelected = null
  }

  public onDownloadReleves() { 
    this.waitingReleves = true;
    const idEntite:number= this.entiteSelected?.id? this.entiteSelected.id : 0
    this.apiFacture.getEditionReleves(idEntite)
    .subscribe(
      (data: Blob) => {            
        saveAs(data, 'Relevé ARD - ' + this.entiteSelected?.raison_sociale + ' - ' + formatDate(new Date(),'dd-MM-yyyy', 'fr') + '.pdf');
        this.toast.showInfo("Téléchargement de l'édition des relevés terminé");
        this.waitingReleves = false;
      },
      (err) => {
        this.toast.showError(err.error);
        this.waitingReleves = false;
      }
    );
  }
}
