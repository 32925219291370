import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DossierReparateur } from 'src/app/interfaces/services-ffea/reparateur/dossier-reparateur';
import { ConfigReparateur } from 'src/app/interfaces/services-ffea/reparateur/config-reparateur';
import { FiltreRecherche } from 'src/app/interfaces/services-ffea/reparateur/filtre-recherche';

@Injectable({
  providedIn: 'root'
})
export class ApiReparateurService {

  CONST_CODE_SRX_EXPERTS_GROUPE   = 'EXPERTS_GROUPE'    as const;
  CONST_CODE_SRX_ALLIANCE_EXPERTS = 'ALLIANCE_EXPERTS'  as const;
  CONST_CODE_SRX_CREATIV_EXPERTIZ = 'CREATIV_EXPERTIZ'  as const;

  url : string = '/api/reparateur' as const;

  constructor(private http: HttpClient) { }

  public postSearchingFile(filtre_recherche: FiltreRecherche, utl_id: number): Observable<DossierReparateur> {    
    return this.http.post<DossierReparateur>(this.url+"/dossier/"+utl_id, filtre_recherche);
  }

  // Récupération des paramètres de service externe
  public getConfigReparateurEntite(ent_id: number) {
    return this.http.get<ConfigReparateur[]>( this.url +'/param-service-externe?ent_id='+ent_id )
  }

  // Enregistre les paramètres de service externe
  public postConfigReparateurEntite(config: ConfigReparateur[]) {
    return this.http.post<ConfigReparateur[]>( this.url+'/param-service-externe' , config )
  }
}
