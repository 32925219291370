import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {  TypeSuspension, TypeSuspensionInitial } from '../../interfaces/suspension';
import { Observable } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { map } from 'rxjs/operators';
import { CST_ACTION_ADD, CST_ACTION_MODIFY, CST_ACTION_REMOVE } from 'src/app/interfaces/constantes';
import { MatTableResponsiveLargeDirective } from '../../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-table-typesuspension',
    templateUrl: './table-typesuspension.component.html',
    styleUrls: ['./table-typesuspension.component.scss'],
    standalone: true,
    imports: [NgIf, MatCardModule, MatButtonModule, MatIconModule, MatTooltipModule, MatTableModule, MatTableResponsiveLargeDirective, MatPaginatorModule, AsyncPipe]
})
export class TableTypesuspensionComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;
 
  @Input() typesSuspension$! : Observable<TypeSuspension[]>
  @Output() typeSuspensionEditEvent = new EventEmitter<TypeSuspension>();
  
  displayedColumns            : string[] = ['nom' ,'code', 'actions']

  typesSuspensionAsMatTableDataSource$!:Observable<MatTableDataSource<TypeSuspension>> 

  constructor() { }

  ngOnInit(): void {
    this.typesSuspensionAsMatTableDataSource$ = this.typesSuspension$.pipe(
      map((typesSuspensions:TypeSuspension[])=>{
        const dataSource  = new MatTableDataSource<TypeSuspension>();
        dataSource.data   = typesSuspensions;
        return dataSource;
      })
    )
  }

  onEditer(typeSuspension:TypeSuspension){
    typeSuspension.action = CST_ACTION_MODIFY
    this.typeSuspensionEditEvent.next(typeSuspension)
  }
  
  onSupprimer(typeSuspension:TypeSuspension){
    typeSuspension.action = CST_ACTION_REMOVE
    this.typeSuspensionEditEvent.next(typeSuspension)
  }

  onCreer(){
    const typeSuspensionACreer:TypeSuspension = {...TypeSuspensionInitial,'action':CST_ACTION_ADD}
    this.typeSuspensionEditEvent.next(typeSuspensionACreer)
  }
}
