            <div class="grid-container">
                <button mat-raised-button (click)="onCreer()" color="primary" type="submit" class="grid-item">
                    <mat-icon>add</mat-icon>
                    <span class="span-button">Nouveau</span>
                </button>

                <button mat-raised-button (click)="onSupprimer()" color="warn" type="submit" class="grid-item">
                    <mat-icon>delete</mat-icon>
                    <span class="span-button">Supprimer</span>
                </button>    

                <button mat-raised-button (click)="onActiver()" style="background-color: #04970E; color: #FFFFFF;" type="submit" class="grid-item">
                    <mat-icon>check</mat-icon>
                    <span class="span-button">Activer</span>
                </button>
    
                <button mat-raised-button (click)="onDesactiver()" color="warn" type="submit" class="grid-item">
                    <mat-icon>close</mat-icon>
                    <span class="span-button">Désactiver</span>
                </button>                                        
            </div>            

            <div class="container">                
                <table mat-table matTableResponsiveLarge [dataSource]="dataSourceMatTable" class="table-primary">

                    <!-- Checkbox Column -->
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef class="premiere-colonne" style="padding-left: 24px;">
                        <mat-checkbox type="checkbox" (change)="$event ? masterToggle() : null" class="checkbox"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                                        [aria-label]="checkboxLabel()"></mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? selection.toggle(row) : null"
                                        [checked]="selection.isSelected(row)"
                                        [aria-label]="checkboxLabel(row)"
                                        class="checkbox"></mat-checkbox>
                        </td>
                    </ng-container>

                    <!-- Position Column -->
                    <ng-container matColumnDef="position" >
                        <th mat-header-cell *matHeaderCellDef> No. </th>
                        <td mat-cell *matCellDef="let article"> {{article.position}} </td>
                    </ng-container>

                    <!-- Référence article --> 
                    <ng-container matColumnDef="rtc_reference">
                        <th mat-header-cell *matHeaderCellDef class="table-padding"> Référence </th>
                        <td mat-cell *matCellDef="let article" class="table-padding"> {{ article.reference }} </td> 
                    </ng-container>
                    
                    <!-- Libellé / Titre --> 
                    <ng-container matColumnDef="rtc_titre">
                        <th mat-header-cell *matHeaderCellDef class="table-padding"> Désignation </th>
                        <td mat-cell *matCellDef="let article" class="table-padding"> {{ article.titre }} </td> 
                    </ng-container>
                    
                    <!-- nom_founisseur --> 
                    <ng-container matColumnDef="frn_libelle">
                        <th mat-header-cell *matHeaderCellDef class="table-padding"> Fournisseur </th>
                        <td mat-cell *matCellDef="let article" class="table-padding"> {{ article.fournisseur.libelle }} </td> 
                    </ng-container>
                    
                    <!-- statut --> 
                    <ng-container matColumnDef="rtc_actif">
                        <th mat-header-cell *matHeaderCellDef class="table-padding"> État </th>
                        <td mat-cell *matCellDef="let article" class="table-padding"> 
                            <mat-icon [ngStyle]="{ color: (article.actif ? '#04970E' : '#FF4081')}">{{ article.actif ? 'check_small' : 'cancel' }}</mat-icon> </td> 
                    </ng-container>

                    <!-- Prix unitaire Hors-taxe --> 
                    <ng-container matColumnDef="rtc_prix_ht">
                        <th mat-header-cell *matHeaderCellDef style="text-align: right;" class="table-padding"> Prix HT </th>
                        <td mat-cell *matCellDef="let article" class="table-padding" style="text-align: right; font-weight: bold;" [ngStyle]="{ color: (article.prix_ht > 0) ? '#273a82' : '#c9c9c9'}"> 
                            {{ article.prix_ht | number:'1.2-2' }} €
                        </td> 
                    </ng-container>
                    
                    <!-- Prix à la location mensuelle --> 
                    <ng-container matColumnDef="rtc_location_ht">
                        <th mat-header-cell *matHeaderCellDef class="table-padding" style="text-align: right;"> HT/Mois </th>
                        <td mat-cell *matCellDef="let article" class="table-padding" style="text-align: right; font-weight: bold;" [ngStyle]="{ color: (article.location_ht > 0) ? '#e48213' : '#c9c9c9'}" > {{ article.location_ht | number:'1.2-2' }} €</td> 
                    </ng-container>
                    
                    <!-- Remise --> 
                    <ng-container matColumnDef="rtc_remise">
                        <th mat-header-cell *matHeaderCellDef class="table-padding" style="text-align: right;"> Remise </th>
                        <td mat-cell *matCellDef="let article" class="table-padding" style="text-align: right;" [ngStyle]="{ color: (article.remise > 0) ? '#000' : '#c9c9c9'}" > {{ article.remise | number:'1.2-2' }} %</td> 
                    </ng-container>

                    <!-- Frais de port --> 
                    <ng-container matColumnDef="rtc_port_ht">
                        <th mat-header-cell *matHeaderCellDef class="table-padding" style="text-align: right;"> Frais de port </th>
                        <td mat-cell *matCellDef="let article" class="table-padding" style="text-align: right;" [ngStyle]="{ color: (article.port > 0) ? '#000' : '#c9c9c9'}" > {{ article.port | number:'1.2-2' }} €</td> 
                    </ng-container>

                    <!-- Actions -->
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef class="derniere-colonne" style="text-align: right;"></th>
                        <td mat-cell *matCellDef="let article" class="colonne-module" style="text-align: right;">
                            <button mat-icon-button class="button-etat" color="primary"
                                style="width: 48px; height: 48px;"
                                (click)="onClickOpenArticle(article.id)">
                                <mat-icon color="primary">edit</mat-icon>
                            </button>

                            <button mat-icon-button class="button-etat" color="primary"
                                style="width: 48px; height: 48px;"
                                (click)="onDuppliquer(article.id)">
                                <mat-icon color="primary">content_copy</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[5,10,25]"
                    showFirstLastButtons 
                    aria-label="Nombre de lignes visibles...">
                 </mat-paginator>
            </div>
  
