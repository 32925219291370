import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { Fichier, UploadFile } from 'src/app/interfaces/fichier';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { MessageTicket } from 'src/app/interfaces/support/message-ticket';
import { PjMessageTicket } from 'src/app/interfaces/support/pj-message-ticket';
import { Ticket } from 'src/app/interfaces/support/ticket';
import { TypeDemande } from 'src/app/interfaces/support/type-demande';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { ApiSupportService } from 'src/app/services/api-support.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { CheckValidatorService } from 'src/app/services/tools/check-validator.service';
import { UploadParameter, initialUploadParameter, UploadFilesComponent } from '../../templates/upload-files/upload-files.component';
import { ApiAdelaService } from 'src/app/services/api-adela.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { ImmatDirective } from '../../../directive/immat.directive';
import { TelephoneDirective } from '../../../directive/telephone.directive';
import { TitleCaseDirective } from '../../../directive/title-case.directive';
import { MatInputModule } from '@angular/material/input';
import { NgFor, NgIf } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

interface file {
  name        : string,
  mimeType    : string,
  size        : number,
  monFichier  : File
}
@Component({
    selector: 'app-contacter-support',
    templateUrl: './contacter-support.component.html',
    styleUrls: ['./contacter-support.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, FormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule, NgFor, NgIf, MatInputModule, TitleCaseDirective, TelephoneDirective, ImmatDirective, CKEditorModule, UploadFilesComponent, MatExpansionModule, MatButtonModule, MatIconModule, MatProgressSpinnerModule]
})
export class ContacterSupportComponent implements OnInit {

  // public editorConfig : CKEditor4.Config
  //  = {
	// 	toolbar: [
	// 		[ 'Styles', 'Format', 'Font', 'FontSize'],
	// 		[ 'Bold', 'Italic' ],
	// 		[ 'Undo', 'Redo' ],
  //     {name : 'Clipboard', items : ['Cut','Copy','Paste','PasteText','PasteFromWord']}
  //     ,
  //     ['Image','Table']
      
	// 	]
  //   ,extraPlugins: 'colorbutton'
	// }

  // Gestion de l'affichage
  waitingSending      : boolean = false;
  sizeError           : boolean = false;
  fileName            : string = '';
  isServiceFFEA       : boolean = false;
  root_url            : string = '';

  // Liste des interfaces pour les champs
  profilUtilisateur  : ProfilUtilisateur = <ProfilUtilisateur>{};
  entites            : Entite[] = [];
  types_demande      : TypeDemande[] = [];
  files              : Array<file> = [];
  uploadFiles        : UploadFile[]=[]
  uploadParameter    : UploadParameter = initialUploadParameter
  // Formulaire de ticket
  TicketFormGroup: FormGroup;
  
  public editor = ClassicEditor

  get id_entite()         { return this.TicketFormGroup.get('id_entite'); }
  get id_utilisateur()    { return this.TicketFormGroup.get('id_utilisateur'); }
  get id_type_demande()   { return this.TicketFormGroup.get('id_type_demande'); }
  get description()       { return this.TicketFormGroup.get('description'); }
  get agrement_ve()       { return this.TicketFormGroup.get('agrement_ve'); }
  get nom()               { return this.TicketFormGroup.get('nom'); }
  get prenom()            { return this.TicketFormGroup.get('prenom'); }
  get numero_telephone()  { return this.TicketFormGroup.get('numero_telephone'); }
  get email()             { return this.TicketFormGroup.get('email'); }
  get immatriculation()   { return this.TicketFormGroup.get('immatriculation'); }
  get vin()               { return this.TicketFormGroup.get('vin'); }
  get pieces_jointe()     { return this.TicketFormGroup.get('pieces_jointe'); }  
  get titre()               { return this.TicketFormGroup.get('titre'); }

  constructor(private _fb       : FormBuilder
            , private authUser  : ApiAuthentificationService
            , private apiEntite : ApiEntiteService
            , private toast     : PersoSnackbarService
            , private apiSupport: ApiSupportService
            , private router    : Router
            , private apiAdela  : ApiAdelaService) {
    this.TicketFormGroup = this._fb.group({
      id_entite       : [''],
      id_utilisateur  : ['', [Validators.required]],
      agrement_ve     : [{value: '', disabled: true}, [Validators.required]],
      nom             : [{value: '', disabled: true}, [Validators.required]],
      prenom          : [{value: '', disabled: true}, Validators.required],
      numero_telephone: [{value: '', disabled: true}, [Validators.required]],
      email           : [{value: '', disabled: true}, [Validators.required]],
      immatriculation : ['', [CheckValidatorService.verificationFormatImmatriculation.bind(this)]],
      vin             : ['', [CheckValidatorService.verificationFormatVIN]],
      id_type_demande : ['', [Validators.required]],
      titre           : ['', [Validators.required]],
      description     : ['', [Validators.required]]
    });
   }

  ngOnInit(): void {
    this.uploadParameter.accept       = 'image/*,.pdf,.xls,.xlsx,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    this.uploadParameter.buttonText   = 'Sélectionner des pièces-jointes'
    // this.editorConfig.toolbar.
    this.profilUtilisateur  = this.authUser.userConnect;
    this.isServiceFFEA      = this.authUser.isServiceFFEA();
    this.root_url           = ( this.isServiceFFEA ? this.authUser.CONST_URL_ROOT_SERVICE : this.authUser.CONST_URL_ROOT_EXTRANET)

    // Récupération des paramètres du formulaire de ticket
    this.chargeListeCabinet();    
    this.chargeTypeDemande();

    this.TicketFormGroup.patchValue({
      id_entite       : '',
      id_utilisateur  : this.profilUtilisateur.id,
      agrement_ve     : this.profilUtilisateur.agrement_ve,
      nom             : this.profilUtilisateur.nom,
      prenom          : this.profilUtilisateur.prenom,
      numero_telephone: this.profilUtilisateur.telephone,
      email           : this.profilUtilisateur.email,
      immatriculation : '',
      vin             : '',
      id_type_demande : '',
      description     : ''
    });
  }

  public onSendingTicket(): void {
    let ticket: Ticket = <Ticket>{};
    let message : MessageTicket = <MessageTicket>{};

    this.waitingSending = true;
    //on construit le ticket:
    ticket = {
      id_entite       : this.TicketFormGroup.controls.id_entite.value,
      id_utilisateur  : this.TicketFormGroup.controls.id_utilisateur.value,
      immatriculation : this.apiAdela.getImmatNonFormated(this.TicketFormGroup.controls.immatriculation.value),
      vin             : this.TicketFormGroup.controls.vin.value,
      id_type_demande : this.TicketFormGroup.controls.id_type_demande.value,
      description     : this.TicketFormGroup.controls.description.value,
      titre           : this.TicketFormGroup.controls.titre.value,
      id_etat         : 0,
      listeMessage    : []
    }
    
    //on construit le message:
    message = {
      tck_mss_id      : 0,
      tck_mss_date    : '',
      tck_mss_tck_id  : 0,
      tck_mss_message : this.TicketFormGroup.controls.description.value,
      tck_mss_utl_id  : this.TicketFormGroup.controls.id_utilisateur.value,
      tck_mss_origine : true,
      utilisateur     :<ProfilUtilisateur>{},
      tabpiecejointe   : []
    }
    
    if (this.uploadFiles.length != 0) {
      this.uploadFiles.forEach((uploadFile:UploadFile)=>{
        let pj : PjMessageTicket = <PjMessageTicket>{}
          pj.contenu = uploadFile.contenu? uploadFile.contenu : '';
          pj.nomFichier = uploadFile.file?.name;
          pj.id = 0;
          pj.id_message = 0;
          message.tabpiecejointe.push(pj); 
      })
    }
    
    ticket.listeMessage.push(message);
    this.creerTicket(ticket);
  }

  public chargeListeCabinet() {
    this.apiEntite.getEntitesByIdUtilisateur(this.profilUtilisateur.id)
      .subscribe(
        (data: Entite[])  => { 
        this.entites = data;
      }
    );  
  }

  public chargeTypeDemande() {    
    this.apiSupport.getListeTypeDemande()
    .subscribe(
      (data: TypeDemande[]) => {
        for(let i = data.length-1; i >= 0; i--) {
          if(!data[i].actif) {
            data.splice(i,1);
          } else {
            if(this.isServiceFFEA) {
              if(!data[i].services_ffea) {
                data.splice(i,1);
              }
            } else {
              if(!data[i].extranet_ffea) {
                data.splice(i,1);
              }
            }
          }
        }
        this.types_demande = data;         
      },
      (err: any) => {
        console.error('/!\ err: ', err.message);        
      }
    );
  }

  public getFiles(files:UploadFile[]){
    this.uploadFiles = files
  }

  public creerTicket(ticket: Ticket): any {
    this.apiSupport.postTicket(ticket)
    .subscribe(
      (data: any) => {
        this.toast.showInfo('Votre ticket a bien été envoyé au support FFEA, vous allez recevoir un accusé de réception par mail.');              
        this.waitingSending = false;
        this.router.navigate(['/'+this.root_url + '/home']);
      },
      (err: any) => {
        this.toast.showError(err.message);
        this.waitingSending = false;
      }
    );
  }
}
