import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Suivi } from 'src/app/interfaces/acquittement/suivi';
import { RapportFilter } from '../rapport1a-interface';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { RelationUtilisateurEntite } from 'src/app/interfaces/utilisateur-entite/relation-utilisateur-entite';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
@Injectable({
  providedIn: 'root'
})
export class ConcentrateurService {
  apiUrl        = 'api/suivi'
  // estAdmin      : boolean = this.apiAuthentification.userConnect.droits_utilisateur.est_admin
  entitesUser   : Entite[] = []
  
  constructor(private http:HttpClient
    , private apiAuthentification : ApiAuthentificationService
    , private apiEntite           : ApiEntiteService
  ) { 
    this.apiAuthentification.relUserEnt.forEach((rel:RelationUtilisateurEntite) => {
      if (rel.entite.habilitation != '' ) {
        this.entitesUser.push(rel.entite);
      }
    })
    // this.estAdmin = this.apiAuthentification.userConnect.droits_utilisateur.est_admin
  }

  estAdmin(){
    return this.apiAuthentification.userConnect.droits_utilisateur.est_admin
  }
  postRapports1aOrphelins(filterRapport:RapportFilter):Observable<Suivi[]>{
    return this.http.post<Suivi[]>(this.apiUrl, filterRapport)
  }
  
}
