import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable,of } from 'rxjs';
import { tap,catchError } from 'rxjs/operators';
import { ApiErrorService } from './api-error.service';
import { SousCategorie } from 'src/app/interfaces/support/sous-categorie';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiSousCategorieService {
  url: string = '/api';

  constructor(private http: HttpClient,
              private apiError    : ApiErrorService) { }

   //2.1 - Select
   public getSousCategories(): Observable<SousCategorie[]>{
    return this.http.get<SousCategorie[]>(this.url+"/support/souscategorie")
                    .pipe(catchError(this.apiError.handleError<SousCategorie[]>('getSousCategories',[])));
  }
  
  //2.2 - Update
  public putSousCategorie(sousCategorie:SousCategorie):Observable<SousCategorie>{
    return this.http.put<SousCategorie>(this.url+"/support/souscategorie", sousCategorie)
                    .pipe(catchError(this.apiError.handleError<SousCategorie>('putSousCategorie')));
  }

  //2.3 - Insert
  public postSousCategorie(sousCategorie:SousCategorie):Observable<SousCategorie>{
    return this.http.post<SousCategorie>(this.url+"/support/souscategorie", sousCategorie)
                    .pipe(catchError(this.apiError.handleError<SousCategorie>('postSousCategorie')));
  }

  //2.4 - Delete
  public deleteSousCategorie(idSousCategorie:number): any {    
    return this.http.delete(this.url+"/support/souscategorie/"+idSousCategorie)
                    .pipe(catchError(this.apiError.handleError<boolean>('deleteSousCategorie',false)));
  }
}
