import { Breakpoints } from '@angular/cdk/layout';
import { NgClass } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { RouterOutlet } from '@angular/router';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiBreakpointService } from 'src/app/services/api-breakpoint.service';
import { NavBarComponent } from '../nav-bar/nav-bar.component';
import { SidenavComponent } from '../sidenav/sidenav.component';

@Component({
    selector: 'app-main-page',
    templateUrl: './main-page.component.html',
    styleUrls: ['./main-page.component.scss'],
    standalone: true,
    imports: [NavBarComponent, MatSidenavModule, NgClass, SidenavComponent, RouterOutlet]
})
export class MainPageComponent implements OnInit {
  opened      : boolean = false;
  serviceFFEA : boolean = false
  Breakpoints = Breakpoints;
  public currentBreakpoint:string = '';

  @ViewChild(MatSidenav) sidenav!: MatSidenav;

  constructor(
    private authUser : ApiAuthentificationService
    , private apiBreakpoint : ApiBreakpointService) { }

  ngOnInit(): void {
    if(this.authUser.isServiceFFEA()) {
      this.serviceFFEA = true
      document.documentElement.style.setProperty('--primary', '#273a82');
    } else {
      this.serviceFFEA = false
      document.documentElement.style.setProperty('--primary', '#e9e9e9');
    }   
    this.apiBreakpoint.breakpoint$.subscribe(() =>
    this.apiBreakpoint.breakpointChanged()
  ); 
  }

  triggered(){
    this.opened=!this.opened
  }

}
