import { DatePipe, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Subscription } from 'rxjs';
import { EntiteSearchComponent } from 'src/app/components/templates/entite-search/entite-search.component';
import { EtatPrelevement } from 'src/app/interfaces/facture/etat-prelevement';
import { FiltreRecherchePrelevement } from 'src/app/interfaces/facture/filtre-recherche-prelevement';
import { ModePaiement } from 'src/app/interfaces/facture/mode-paiement';
import { Prelevement } from 'src/app/interfaces/facture/prelevement';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ApiFactureService } from 'src/app/services/api-facture.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';

export interface OutPutRecherchePrelevement{
  prelevements: Array<Prelevement>
}
@Component({
    selector: 'app-filtre-historique-prelevements',
    templateUrl: './filtre-historique-prelevements.component.html',
    styleUrls: ['./filtre-historique-prelevements.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatExpansionModule, MatIconModule, MatFormFieldModule, MatInputModule, NgIf
              , MatSelectModule, MatOptionModule, NgFor, MatButtonModule, MatProgressSpinnerModule,EntiteSearchComponent
              , MatTooltipModule]
})
export class FiltreHistoriquePrelevementsComponent implements OnInit, OnDestroy {
 // US 328 : on affiche la liste des prélèvements pour le user et du coup l'entité est déjà identifiée. La liste des filtres sera réduite.
 @Input()  entiteEnCours!:Entite;

  // Gestion de l'affichage
  waitingResearch       : boolean = false;
  expandPannel          : boolean = true;

  etats$                 : Array<EtatPrelevement>     = Array<EtatPrelevement>();

  // Formulaire des filtres de recherche
  FiltreFormGroup       : FormGroup;
  entiteSelected    : Entite|null =null
  searching         : boolean = false

  get date_debut()              { return this.FiltreFormGroup.get('date_debut'); }
  get date_fin()                { return this.FiltreFormGroup.get('date_fin'); }
  get numero_lot()              { return this.FiltreFormGroup.get('numero_lot'); }
  get iban()                    { return this.FiltreFormGroup.get('iban'); }
  get reference_bout_en_bout()  { return this.FiltreFormGroup.get('reference_bout_en_bout'); }
  get reference_mandat()        { return this.FiltreFormGroup.get('reference_mandat'); }
  get idModePaiement()          { return this.FiltreFormGroup.get('idModePaiement'); }

  // Gestion de l'évènement recherche prélèvements
  @Output() research = new EventEmitter<OutPutRecherchePrelevement>();

  filtre_recherche_prelevement: FiltreRecherchePrelevement = <FiltreRecherchePrelevement>{};  
  sub_filtre: Subscription = new Subscription();

  modesPaiement         : ModePaiement[]=[]
  
  constructor(private fb          : FormBuilder
            , private apiFacture  : ApiFactureService
            , private datePipe    : DatePipe
            , private toast       : PersoSnackbarService) { 
    // Initialisation du formulaire des filtres de recherche
    this.FiltreFormGroup = this.fb.group({
      date_debut            : ['', [this.verificationFormatDateDebut.bind(this)]],
      date_fin              : ['', [this.verificationFormatDateFin.bind(this)]],
      numero_lot            : '',
      iban                  : '',
      reference_bout_en_bout: '',
      reference_mandat      : '',
      idModePaiement        : [0]
    });

    // Par défaut, les dates de début/fin sont configuré d'hier à aujourd'hui
    const today     = new Date();
    const lastYear  = new Date(today);
    lastYear.setFullYear(today.getFullYear() - 1);
    
    this.FiltreFormGroup.patchValue({
      date_debut: this.datePipe.transform(lastYear, "yyyy-MM-dd"),
      date_fin  : this.datePipe.transform(today, "yyyy-MM-dd")
    });
  }

  ngOnInit(): void {
    this.apiFacture.getModePaiement().subscribe(
      (modesPaiements:ModePaiement[]) => {
            //On enlève les prélèvements non encore implémentés pour l'instant :
            this.modesPaiement = modesPaiements.filter((modePaiement:ModePaiement)=>modePaiement.code!='PRELEVEMENT')
          }
        )

    this.sub_filtre = this.apiFacture.filtre_recherche_prelevement.subscribe({
      next: (filtre: FiltreRecherchePrelevement) => {
        this.filtre_recherche_prelevement = filtre;
        this.filtre_recherche_prelevement.etats = this.etats$;
    
        this.FiltreFormGroup.patchValue({
          date_debut            : this.datePipe.transform(this.filtre_recherche_prelevement.date_debut, "yyyy-MM-dd"),
          date_fin              : this.datePipe.transform(this.filtre_recherche_prelevement.date_fin, "yyyy-MM-dd"),
          numero_lot            : this.filtre_recherche_prelevement.numero_lot,
          iban                  : this.filtre_recherche_prelevement.iban,
          reference_bout_en_bout: this.filtre_recherche_prelevement.reference_bout_en_bout,
          reference_mandat      : this.filtre_recherche_prelevement.reference_mandat,
          etats                 : this.filtre_recherche_prelevement.etats,
          idModePaiement        : this.filtre_recherche_prelevement.idModePaiement
        });

        this.onSearchPrelevements(false);
      }
    });
  }

 
  ngOnDestroy(): void {
    this.sub_filtre.unsubscribe();
  }

  setEntite(entite:Entite|null){
    this.searching = false
    if (entite) {
      this.entiteSelected = entite
    }
  }

  searchingEntite(){
    this.searching = true
  }

  cancelEntite(){
    this.entiteSelected = null
  }


 

  public bindFormulaireFiltre(): void {
    this.filtre_recherche_prelevement                         = <FiltreRecherchePrelevement>{};
    this.filtre_recherche_prelevement.date_debut              = this.FiltreFormGroup.controls.date_debut.value;
    this.filtre_recherche_prelevement.date_fin                = this.FiltreFormGroup.controls.date_fin.value;
    this.filtre_recherche_prelevement.numero_lot              = this.FiltreFormGroup.controls.numero_lot.value;
    this.filtre_recherche_prelevement.entite                  = this.entiteSelected? this.entiteSelected : <Entite>{};
    this.filtre_recherche_prelevement.iban                    = this.FiltreFormGroup.controls.iban.value;
    this.filtre_recherche_prelevement.reference_bout_en_bout  = this.FiltreFormGroup.controls.reference_bout_en_bout.value;
    this.filtre_recherche_prelevement.reference_mandat        = this.FiltreFormGroup.controls.reference_mandat.value;          
    this.filtre_recherche_prelevement.idModePaiement          = this.FiltreFormGroup.controls.idModePaiement.value;

  }

  public onSearchPrelevements(bind: boolean): void {    
    if(bind) {
      this.bindFormulaireFiltre();
    }
    //US328 : si on est dans l'onglet prélèvement du user, l'entité est déjà définie (et non modifiable):
    if (this.entiteEnCours!=null) {
      this.filtre_recherche_prelevement.entite =this.entiteEnCours;
    }
    this.waitingResearch = true;

    this.apiFacture.postReglement(this.filtre_recherche_prelevement)
      .subscribe(
        (prelevements: Array<Prelevement>) => {
          console.log(prelevements);
          
          this.research.emit({
            prelevements: prelevements
          });
          this.expandPannel = false;
          this.toast.showInfo("Recherche terminée");
          this.waitingResearch = false;
        },
        (err: any) => {
          this.research.emit({
            prelevements: Array<Prelevement>()
          });

          '/!\\ error postPrelevement: ' + console.log(err);
          this.toast.showError(err.error);
          this.waitingResearch = false;
        }
      );
  }

  verificationFormatDateDebut(fcControler: FormControl): any {
    if(this.FiltreFormGroup == undefined){
      return null;
    }
    
    let dateFin     : Date = new Date(this.FiltreFormGroup.controls.date_fin.value);
    let dateDebut   : Date = new Date(fcControler.value);
    let dateToday   : Date = new Date();

    dateDebut.setHours(0,0,0,0);
    dateToday.setHours(23,59,59,999); 

    if(dateFin != undefined) {
      dateFin.setHours(23,59,59,999);
      if(dateDebut > dateFin) { return { erreur: 'La date de début doit être antérieure à la date de fin' }}
    }        

    if(dateDebut.getTime() > dateToday.getTime()){
      return { erreur: 'La date de début doit être antérieure à la date du jour' }
    }
    
    return null;
  }

  verificationFormatDateFin(fcControler: FormControl): any {
    if(this.FiltreFormGroup == undefined){
      return null;
    }

    let dateFin   : Date = new Date(fcControler.value);
    let dateDebut : Date = new Date(this.FiltreFormGroup.controls.date_debut.value);
    let dateToday : Date = new Date();    
    
    dateDebut.setHours(0,0,0,0);
    dateToday.setHours(23,59,59,999); 
    dateFin.setHours(23,59,59,999);
    
    if(isNaN(dateFin.getTime())) { return {erreur : 'La date de fin doit être postérieure à la date de début et antérieure à aujourd\'hui'}}

    if(dateFin.getTime() > dateToday.getTime() || (dateDebut.getTime() != null && dateFin.getTime() < dateDebut.getTime())){
      return { erreur: 'La date de fin doit être postérieure à la date de début et antérieure à aujourd\'hui' }
    }
    
    return null;
  }

}
