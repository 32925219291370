import { Component, OnInit } from '@angular/core';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur'
import { TableListeUtilisateursComponent } from '../table-liste-utilisateurs/table-liste-utilisateurs.component';
import { NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { FiltreListeUtilisateursComponent } from '../filtre-liste-utilisateurs/filtre-liste-utilisateurs.component';

@Component({
    selector: 'app-fiche-liste-utilisateurs',
    templateUrl: './fiche-liste-utilisateurs.component.html',
    styleUrls: ['./fiche-liste-utilisateurs.component.scss'],
    standalone: true,
    imports: [FiltreListeUtilisateursComponent, MatCardModule, MatExpansionModule, MatIconModule, NgIf, TableListeUtilisateursComponent]
})
export class FicheListeUtilisateursComponent implements OnInit {
  
  // Source de données utilisées par les différents component
  ds_Utilisateurs         : Array<ProfilUtilisateur> = Array<ProfilUtilisateur>();
  
  constructor() { }

  ngOnInit(): void {
  }

  public addItem(newItem: Array<ProfilUtilisateur>) : void {
    this.ds_Utilisateurs = newItem;
  }
}
