import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { EntiteTva } from 'src/app/interfaces/profil-user/entite-tva';
import { ApiStatistiqueService } from 'src/app/services/api-statistique.service';
import { AnneeValidator } from 'src/app/validators/annee.validator';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { AnneeDirective } from '../../../directive/annee.directive';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
@Component({
    selector: 'app-taux-tva',
    templateUrl: './taux-tva.component.html',
    styleUrls: ['./taux-tva.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, MatInputModule, AnneeDirective, NgIf, MatButtonModule, MatProgressSpinnerModule, MatGridListModule, MatIconModule]
})
export class TauxTvaComponent implements OnInit {
  
  @Input() maxAnnee     : string = ''
  @Input() minAnnee     : string = ''
  @Input() entitesTva   : EntiteTva[] = []

  filterForm!   : FormGroup
  waiting       : boolean = false
  tauxTVA       : string = ''
  nbOperations  : number = 0
  annee         : string =''
  

  constructor(private anneeValidator : AnneeValidator
              ,private apiStat : ApiStatistiqueService) { }

  ngOnInit(): void {
    this.filterForm = new FormGroup({
      annee         : new FormControl(this.minAnnee,[Validators.required,this.anneeValidator.valideAnnee(this.minAnnee,this.maxAnnee)])
    , tauxTva       : new FormControl('',Validators.required)
  })
  }

  onSearch(){
    this.waiting = true
    
    const annee         = this.filterForm.value.annee
    const tauxTva       = this.filterForm.value.tauxTva
    
    this.apiStat.getStatParTauxTva(annee,tauxTva).subscribe(
      
      (data:any)=>{
        this.waiting      = false

        this.annee        = annee
        this.tauxTVA      = tauxTva
        this.nbOperations = data
      }
      ,(error)=>this.waiting = false
    )
  }
}
