import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { fromEvent } from 'rxjs';

@Directive({
    selector: '[appAnnee]',
    standalone: true
})

export class AnneeDirective {

@Input()  minAnnee  : string = '2000'
@Input()  maxAnnee  : string = '2099'
@Output() anneeChange = new EventEmitter<string>();

  constructor(private element: ElementRef) { 
    fromEvent(element.nativeElement, "input").subscribe(() => {
      this.transformValue(this.element.nativeElement.value);
    });
  }
  
  ngAfterViewChecked() {
    this.transformValue(this.element.nativeElement.value);
    }

  transformValue(value:string){
    
    if(!value) {
      return this.anneeChange.next(this.minAnnee.slice(0,2))
    }
    
    //on ne tolère que les chiffres
    value = value.replace(/[^0-9]/g, '')
    //on ne dépasse pas 4 chiffres:
    value = value.slice(0, 4)
    
    //On s'arrange pour être entre les valeurs min et max:
    if (value.length===4) {
      if (value<this.minAnnee) {
        value = this.minAnnee
      }else if (value>this.maxAnnee) {
        value = this.maxAnnee
      }
    }

    let valueFormated : string = value;
    this.element.nativeElement.value = valueFormated
    this.anneeChange.next(valueFormated)
  }
}
