<mat-card appearance="outlined">
    <form>
        <mat-card-title>Formulaire de contact</mat-card-title>
        
        <mat-card-content [formGroup]="TicketFormGroup">            
            <mat-form-field>
                <mat-label>Cabinet</mat-label>
                <mat-select name="id_entite" id="id_entite" formControlName="id_entite">
                    <mat-option>Aucun</mat-option>
                    <mat-option *ngFor="let entite of entites" [value]="entite.id">{{ entite.raison_sociale }}</mat-option>
                </mat-select>
                <mat-error *ngIf="id_entite?.invalid && (id_entite?.dirty || id_entite?.touched)">{{ id_entite?.errors?.erreur }}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>N° Agrément VE</mat-label>
                <input matInput type="text" id="agrement_ve" formControlName="agrement_ve" oninput="this.value = this.value.toUpperCase()" readOnly required>
                <mat-error *ngIf="agrement_ve?.invalid && (agrement_ve?.dirty || agrement_ve?.touched)">{{ agrement_ve?.errors?.erreur }}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Nom</mat-label>
                <input matInput type="text" id="nom" formControlName="nom" readOnly required oninput="this.value = this.value.toUpperCase()">
                <mat-error *ngIf="nom?.invalid && (nom?.dirty || nom?.touched)">{{ nom?.errors?.erreur }}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Prénom</mat-label>
                <input appTitleCase matInput type="text" id="prenom" formControlName="prenom" readOnly required>
                <mat-error *ngIf="prenom?.invalid && (prenom?.dirty || prenom?.touched)">{{ prenom?.errors?.erreur }}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Adresse email</mat-label>
                <input matInput type="email" id="email" formControlName="email" readOnly required>
                <mat-error *ngIf="email?.invalid && (email?.dirty || email?.touched)">{{ email?.errors?.erreur }}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>N° Téléphone</mat-label>
                <input matInput type="text" id="numero_telephone" formControlName="numero_telephone" readOnly required appTelephone>
                <mat-error *ngIf="numero_telephone?.invalid && (numero_telephone?.dirty || numero_telephone?.touched)">{{ numero_telephone?.errors?.erreur }}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Immatriculation</mat-label>
                <input matInput type="text" id="immatriculation" formControlName="immatriculation" oninput="this.value = this.value.toUpperCase()" appImmat>
                <mat-error *ngIf="immatriculation?.invalid && (immatriculation?.dirty || immatriculation?.touched)">{{ immatriculation?.errors?.erreur }}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>VIN</mat-label>
                <input matInput type="text" id="vin" formControlName="vin" oninput="this.value = this.value.toUpperCase()" length="17">
                <mat-hint>Alphanumérique de 17 caractères</mat-hint>
                <mat-error *ngIf="vin?.invalid && (vin?.dirty || vin?.touched)">{{ vin?.errors?.erreur }}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Catégorie de la demande</mat-label>
                <mat-select name="id_type_demande" id="id_type_demande" formControlName="id_type_demande" required>
                    <mat-option *ngFor="let type_demande of types_demande" [value]="type_demande.id">{{ type_demande.libelle }}</mat-option>
                </mat-select>
                <mat-error *ngIf="id_type_demande?.invalid && (id_type_demande?.dirty || id_type_demande?.touched)">{{ id_type_demande?.errors?.erreur }}</mat-error>
            </mat-form-field>
        
            <mat-form-field style="max-width:none;">
                <mat-label>Sujet du problème</mat-label>
                <input matInput id="titre" formControlName="titre" required>
                <mat-error *ngIf="titre?.invalid && (titre?.dirty || titre?.touched)">{{ titre?.errors?.erreur }}</mat-error>
            </mat-form-field>

            <ckeditor    
                        [editor]="editor"
                        formControlName="description"
                        ></ckeditor>
            
        </mat-card-content>
        <div style="margin: 20px 0px 20px;">

            <app-upload-files  
                                (uploadedFiles)  = "getFiles($event)"
                                [uploadParameter]="uploadParameter"
                    >
            </app-upload-files>
        </div>

        <mat-action-row>
            <div class="div-button-center" style="padding: 8px;">
                <div class="button-container">                    
                    <button mat-flat-button 
                            *ngIf       = "!waitingSending"
                            color       = "primary" 
                            (click)     = "onSendingTicket()" 
                            [disabled]  = "waitingSending || !TicketFormGroup.valid">
                        <mat-icon>send</mat-icon>Envoyer
                    </button>
                    <mat-spinner diameter="24" *ngIf  = "waitingSending"></mat-spinner>
                </div>
            </div>
        </mat-action-row>
    </form>
</mat-card>