import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Parametre } from 'src/app/interfaces/parametre';
import { ApiParametreService } from 'src/app/services/api-parametre.service';
import { ParametreExtranetEditComponent } from './parametre-Extranet-edit/parametre-Extranet-edit.component';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { APIUtilisateurParamService, UtilisateurParam } from 'src/app/services/api-utilisateur-param.service';
import { ParametreUtilisateurEditComponent } from './parametre-utilisateur-edit/parametre-utilisateur-edit.component';

@Component({
    selector: 'app-gestion-parametre',
    templateUrl: './gestion-parametre.component.html',
    styleUrls: ['./gestion-parametre.component.scss'],
    standalone: true,
    imports: [MatCardModule, NgIf, NgFor, MatGridListModule, MatButtonModule, AsyncPipe, MatTabsModule]
})
export class GestionParametreComponent implements OnInit {
  
  parametresExtranet$         : Observable<Parametre[]> = this.apiParametres.parametres$
  typesParametresExtranet$    : Observable<string[]>    = this.apiParametres.typesParametres$
  parametresUtilisateur       : UtilisateurParam[] = [];
  typesParametresUtilisateur  : string[] = []

  constructor(private apiParametres   : ApiParametreService
              , public dialog         : MatDialog
              , private toast         : PersoSnackbarService
              , public  apiUtiParam  : APIUtilisateurParamService
              ) { }

  ngOnInit(): void {
    this.apiUtiParam.getParam().subscribe((data: UtilisateurParam[]) => {
      this.parametresUtilisateur = data;
      this.typesParametresUtilisateur = [...new Set(this.parametresUtilisateur.map((parametres)=> parametres.utl_prm_type))];
    },
    (err) => {
      console.error('/!\\ error getParam: ' + err)
    });
  }

  openEditExtranetParameter(parametre:Parametre):void{
    const dialogRef = this.dialog.open(ParametreExtranetEditComponent,{data:parametre})

    dialogRef.afterClosed().subscribe((parametreChanged:Parametre|boolean)=>{
      
      
        if (typeof parametreChanged != "boolean") {
          this.apiParametres.putParametre([parametreChanged]).subscribe(()=>this.toast.validate('Le paramètre a bien été modifié')
          )
        }
      }
    )
  }

  openEditUserParameter(userParameter: UtilisateurParam): void {
    const dialogRef = this.dialog.open(ParametreUtilisateurEditComponent, {data:userParameter})

    dialogRef.afterClosed().subscribe((parametreChanged:UtilisateurParam|boolean)=>{
        if (typeof parametreChanged != "boolean") {
          this.apiUtiParam.updateParam(parametreChanged).subscribe(()=>this.toast.validate('Le paramètre a bien été modifié')
          )
        }
      }
    )
  }
}
