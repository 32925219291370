import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { PRIMARY_OUTLET, Router, RouterLink, RouterLinkActive, UrlSegmentGroup, UrlTree } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { NotificationService } from 'src/app/notifications/notification.service';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { AppUpdateService } from 'src/app/services/app-update.service';

@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.scss'],
    providers: [Title],
    standalone: true,
    imports: [NgIf, MatToolbarModule, NgClass, MatButtonModule, RouterLinkActive, MatIconModule, RouterLink, MatMenuModule, MatDividerModule, MatBadgeModule, NgFor, AsyncPipe]
})
export class NavBarComponent implements OnInit {
  
  @Input() bModeConnecte: boolean = false;
  @Output() triggerEvent = new EventEmitter<boolean>();

  URL_CARRE_EXPERT_AUTO : string = 'http://www.carre-expert-auto.org/';
  URL_IFOR2A_AGATE      : string = 'https://ifor2aportail.agate-erp.fr/';
  utl_profil            : ProfilUtilisateur = <ProfilUtilisateur>{};

  //
  tree: UrlTree = <UrlTree>{};
  primary: UrlSegmentGroup = <UrlSegmentGroup>{};
  classNavBar: string = 'navbar';
  isServiceFFEA: boolean = false;
  
  cmdCategorie: any = {
    KIT_ADELA: '',
    DIAG: ''
  };

  public hasNewUpdate$: Observable<boolean>;
  subscription !: Subscription;
  notifications$ = this.notificationService.notificationsLeft$
  
  constructor(private authUser: ApiAuthentificationService
            , private apiUpdate: AppUpdateService
            , private router: Router           
            , public _sanitizer: DomSanitizer ,
            public notificationService :NotificationService,
            public title : Title
  ) {
    this.hasNewUpdate$ = this.apiUpdate.newVersionFound$.pipe(
      filter((v: boolean) => v === true)
    );
   }

  ngOnInit(): void {    
    this.utl_profil = this.authUser.userConnect;
        
    // récupération de la racine du site
    this.tree = this.router.parseUrl(this.router.url);
    this.primary = this.tree.root.children[PRIMARY_OUTLET];
    if ( this.primary != undefined ) {
      if ( this.primary.segments[0].path === this.authUser.CONST_URL_ROOT_SERVICE || this.primary.segments[0].path === 'service' ) {
        this.isServiceFFEA = true;
        this.classNavBar = this.authUser.CONST_BANDEAU_SERVICE  
      }
    }
    this.notifications$.subscribe()
  }

  triggerToolbar(){
    this.triggerEvent.next(true);    
  }

  public naviguerVers() {
    if(this.bModeConnecte) {
      this.authUser.navigateToHome()
    } else {
      this.authUser.navigateToLoginPage()
    }
  }

  /* Méthode concernant l'installation de l'application */
  public get installAppLater$(): boolean {
    return this.apiUpdate.installAppLater$;
  }

  public get canInstall$(): Observable<boolean> {
    return this.apiUpdate.canInstallApp$;
  }

  public install(): void {    
    this.apiUpdate.install();    
  }

  public updateApp(): void {
    this.apiUpdate.getUpdate();
  }

  public idUserConnected() {
    if (this.authUser.utilisateur_authentifie == true) { return this.authUser.userConnect.id; }
    else { return undefined; }
  }

  /* Méthode concernant les actions des boutons */
  public clickOnAccount(anchor: string = '') {
    this.router.navigate([ '/'+this.authUser.root+'/profil/' +this.idUserConnected()], {fragment: anchor});
  } 

  public clickOnFolder() {
    if (this.authUser.isServiceFFEA()) {
      this.router.navigate(['/'+this.authUser.root+'/reparateur/dossier']);
    } else {
      this.router.navigate(['/app/dossier/mes-dossiers']);
    }
  } 
}