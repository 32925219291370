<!--  -->
<div *ngIf="isDirigeant || isAdmin">

    <mat-card appearance="outlined">
        <mat-card-title>
            <div class="div-container-left">
                <mat-icon>account_balance</mat-icon>
                <span>Coordonnées bancaires</span>
            </div>
      </mat-card-title>
      
      <mat-card-content  class=".mat-card-content" *ngIf="entite.coordonnees_bancaire">
        <div class="content" *ngIf="entite.coordonnees_bancaire.id!=0 && entite.coordonnees_bancaire.etat">
            <div class="titlebox">Etat des coordonnées bancaires</div>
            <div style="text-align: center;">
                <mat-icon *ngIf="entite.coordonnees_bancaire.etat.code === apiEntite.CONST_CODE_ETAT_COORDONNEES_BANCAIRE_VALIDE" style="color: #04970E;font-size: 64px; width: 64px; height: 64px;">check_small</mat-icon>
                <mat-icon *ngIf="entite.coordonnees_bancaire.etat.code === apiEntite.CONST_CODE_ETAT_COORDONNEES_BANCAIRE_REFUSE" style="color: #FF4081;font-size: 64px; width: 64px; height: 64px;">cancel</mat-icon>
                <mat-icon *ngIf="entite.coordonnees_bancaire.etat.code === apiEntite.CONST_CODE_ETAT_COORDONNEES_BANCAIRE_A_VALIDER || entite.coordonnees_bancaire.etat.code == ''" color="primary" style="font-size: 64px; width: 64px; height: 64px;">pending_actions</mat-icon>
                <mat-hint class="div-container">{{ getLibelleEtat() }}</mat-hint>
            </div>
    
            <div *ngIf="entite.coordonnees_bancaire.commentaire != ''">
                {{ entite.coordonnees_bancaire.commentaire }}
            </div>
    
            <h3 class="div-container">
                <div>le {{entite.coordonnees_bancaire.date_creation | date}}</div>
                <div>à {{entite.coordonnees_bancaire.date_creation | date:'HH:mm'}}</div>
            </h3>
    
            <h4 *ngIf="entite.coordonnees_bancaire.etat.code === apiEntite.CONST_CODE_ETAT_COORDONNEES_BANCAIRE_A_VALIDER">
                Vous ne pouvez pas utiliser ce cabinet pour facturer tant que les coordonnées bancaires ne sont pas validées.
            </h4>                                    
        </div>

        <div class="content" >
            <div class="titlebox">Pièces des coordonnées bancaires</div>
                                           
            <form [formGroup]="formBancaire" action="">

                <div class="div-container-left" *ngIf="modePaiementEnCours?.code===apiFacture.CONST_MODE_PAIEMENT_PRELEVEMENT">
                    <button 
                        class="btn-upload"
                        mat-mini-fab 
                        color="primary" 
                        matTooltip ="Télécharger le fichier IBAN"
                        *ngIf="CoordonneeBancaire$.nom_fichier_iban != ''"
                        (click)="dowloadFile('iban')">
                        <mat-icon>download</mat-icon>
                     </button>  
                    <mat-form-field>
                        <mat-label for="text">IBAN</mat-label>
                        <input matInput type="text" formControlName="iban" readonly="true">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label for="text">BIC</mat-label>
                        <input matInput type="text" placeholder="Numéro BIC" name="bic" formControlName="bic" readonly="true">
                    </mat-form-field>
                   
                </div>
                
                <div *ngIf="modePaiementEnCours?.code===apiFacture.CONST_MODE_PAIEMENT_PRELEVEMENT">
                    
                    <span [ngStyle]="{  color : CoordonneeBancaire$.nom_fichier_iban != '' ? '#273a82' : '#FF4081' }">
                        {{ IBANFile || CoordonneeBancaire$.nom_fichier_iban != '' ? CoordonneeBancaire$.nom_fichier_iban : "Aucun justificatif IBAN associé." }}
                    </span>

                    <div class="div-container-left" style="margin-top: 20px;">
                        <button 
                            mat-mini-fab 
                            class       = "btn-upload"
                            color       = "primary" 
                            matTooltip  = "Télécharger le fichier RUM"
                            *ngIf       = "CoordonneeBancaire$.nom_fichier_rum != ''"
                            (click)     = "dowloadFile('rum')">
                            <mat-icon>download</mat-icon>
                        </button> 
                        <mat-form-field>
                            <mat-label>Numéro de référence unique de mandat (RUM)</mat-label>
                            <input 
                                matInput 
                                type            = "text" 
                                placeholder     = "Numéro RUM"  
                                formControlName = "rum" 
                                readonly        = "true">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Date du mandat</mat-label>
                            <input 
                                matInput 
                                type            = "date" 
                                placeholder     = "Date de mandat RUM" 
                                formControlName = "date_mandat" 
                                readonly        = "true">
                        </mat-form-field>
                    </div>

                    <div class="div-button-right">
                        <button 
                            mat-raised-button 
                            *ngIf       = "!waiting_save &&  entite.coordonnees_bancaire.etat"
                            (click)     = "openSetDataWindow()" 
                            color       = "primary" 
                            type        = "submit" 
                            [disabled]  = "entite.coordonnees_bancaire? entite.coordonnees_bancaire.etat.code== apiEntite.CONST_CODE_ETAT_COORDONNEES_BANCAIRE_A_VALIDER : false"
                            >
                            {{getUpdateBtnName()}}
                        </button>
                        <mat-spinner diameter="24" *ngIf="waiting_save"></mat-spinner>
                    </div>

                    <span [ngStyle]="{  color : CoordonneeBancaire$.nom_fichier_rum != '' ? '#273a82' : '#FF4081' }">
                        {{ RUMFile || CoordonneeBancaire$.nom_fichier_rum != '' ? CoordonneeBancaire$.nom_fichier_rum : "Aucun mandat RUM associé." }}
                    </span>
                    
                </div>

                <div class="body" *ngIf="modePaiementEnCours">
                    <div *ngIf="modePaiementEnCours.code===apiFacture.CONST_MODE_PAIEMENT_VIREMENT" class = "centered-div">
                        <div>Vous êtes en paiement par virement bancaire.</div>
                        <div>Vos coordonnées bancaires ne sont pas nécessaires.</div>
                        <div>Merci de bien indiquer le numéro de facture dans le libellé de votre virement.</div>
                    </div>
                    <div *ngIf="modePaiementEnCours.code===apiFacture.CONST_MODE_PAIEMENT_CHEQUE" class = "centered-div">
                        <div>Vous êtes en paiement par chèque.</div>
                        <div>Vos coordonnées bancaires ne sont pas nécessaires.</div>
                        <div>Merci de bien indiquer au dos du chèque le numéro de facture.</div>
                    </div>
                </div>
            </form>
        </div>
        <div class="content" >
            <div class="titlebox">Paiement par défaut</div>
            
            <mat-form-field style="max-width: 350px;" >
                <mat-label>Sélection du mode de paiement</mat-label>
                <mat-select 
                    [disabled]="!(userConnect.droits_utilisateur.est_admin || userConnect.droits_utilisateur.droit_module_admin_compta)"
                    [(ngModel)]="idSelectedModePaiement"
                    (selectionChange)="checkModePaiement()"
                >
                    <mat-option value="0">Aucun</mat-option>
                    <mat-option 
                        *ngFor  = "let modePaiement of modesPaiement" 
                        [value]   = "modePaiement.id"
                        >{{modePaiement.libelle}}</mat-option>
                </mat-select>
            </mat-form-field>
            <button 
                mat-raised-button 
                color       = "primary" 
                (click)     = "enregistrerModePaiementDefaut()"
                [disabled]  = "!(userConnect.droits_utilisateur.est_admin || userConnect.droits_utilisateur.droit_module_admin_compta)"
                >Enregistrer</button>

        </div>


      </mat-card-content>
    </mat-card>
</div>