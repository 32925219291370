<div #containerRef class="chart-col">
    <ngx-charts-pie-chart
        [results]="chartData"
        (window:resize)="resizeChart(containerRef.offsetWidth)"
        [view]="view"
        [scheme]="'cool'"
        [labels]="true"
        [legend]="true"
        [legendPosition]="legendPosition"
        [legendTitle]="''"
        [animations]="false">
    </ngx-charts-pie-chart>
</div>