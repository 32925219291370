import { Injectable } from '@angular/core';
import { Contexte } from '../interfaces/contexte';

export const TYPE_CABINET       = 'cabinet'
export const TYPE_UTILISATEUR   = 'utilisateur'
export const TYPE_RDEA          = "rdea"
export const TYPE_FOURNISEUR     = "fournisseur"
export const TYPE_DEMANDE        = "demande"
export const TYPE_FACTURE        = "facture"
export const TYPE_INFOBANCAIRE   = "infobancaire"
export const TYPE_ARTICLE         = "article"


@Injectable({
  providedIn: 'root'
})

export class ContexteService {

  private contexte : Contexte | undefined;

  constructor() {}

  setType(type:string){

    if (this.contexte == undefined) {
      this.contexte = <Contexte>{};
    }
    
    if (this.contexte != undefined) {
      this.contexte.type = type;
    }
  }

  setFiltreListe(liste:any[]){
    
    if (this.contexte == undefined) {
      this.contexte = <Contexte>{};
    }

    if (this.contexte != undefined) {
      this.contexte.listeFiltre = liste;
    }
  }

  getFiltreListe(type:string){

    if (this.contexte!= undefined && type===this.contexte.type) {
      return this.contexte? this.contexte.listeFiltre : []
    }else{
      return []
    }
  }

  setTableData(data:any[]){
    
    if (this.contexte == undefined) {
      this.contexte = <Contexte>{};
    }

    if (this.contexte != undefined) {
      this.contexte.liste = data;
    }

  }

  getTableData(type:string){    
    if (this.contexte != undefined && type===this.contexte.type) {
      return this.contexte? this.contexte.liste : []
    }else{
      return []
    }
  }

  setPageIndex(index:number){
    if (this.contexte != undefined) {
      this.contexte.pageIndex = index
    }
  }

  getPageIndex(type:string){
    if (this.contexte != undefined && type===this.contexte.type) {
      return this.contexte? this.contexte.pageIndex :0
    }else{
      return 0
    }
  }

  setFiltre(filtre:any){
    
    if (this.contexte == undefined) {
      this.contexte = <Contexte>{};
    }

    if (this.contexte != undefined) {
      this.contexte.filtre = filtre
    }
  }

  getFiltre(type:string){

    if (this.contexte != undefined && type===this.contexte.type) {
      return this.contexte.filtre
    }else{
      return null
    }
  }

  setPages(pages:number){
    if (this.contexte != undefined) {
      this.contexte.pages = pages;
    }
  }

  getPages(type:string){
    
    if (this.contexte != undefined && type===this.contexte.type) {
      return this.contexte.pages
    }else{
      return 10
    }
  }

  deleteContext(){
    this.contexte = undefined;
  }
}
