import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SuiviSiv } from '../../interfaces/suivi';
import { DatePipe, NgFor, NgIf } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-list-suivi-siv',
  templateUrl: './list-suivi-siv.component.html',
  styleUrls: ['./list-suivi-siv.component.scss'],
  standalone:true,
  imports:[NgFor, MatCardModule,NgIf,DatePipe,MatButtonModule, MatIconModule]
})
export class ListSuiviSivComponent {

  @Input() suivisSiv : SuiviSiv[] = []
  @Input() dossier   : string     =''
  @Output() retourEvent = new EventEmitter<boolean>()

  getType(type:number):string{
    let typeOpe:string = ''
    switch (type) {
      case 1:
        typeOpe='1A'
        break;
      case 2:
        typeOpe ='1B'
        break;
      case 3 : 
        typeOpe ='1C'
        break
      default:
        break;
    }
    return typeOpe
  }

  getVge(envoiSiv:number):string{
    let vge : string = ''
    switch (envoiSiv) {
      case 1:
        vge = 'oui'
        break;
      case 0 :
        vge = 'non'
        break;
      default:
        break;
    }
    return vge
  }

  getStatut(codeTraitement:number):string{
    
    let statut:string = ''
    switch (codeTraitement) {
      case 1:
        statut='erreur'
        break;
      case 0:
        statut = 'succès'
        break
      default:
        break;
    }
    return statut

  }

  setRetour(){
    this.retourEvent.next(true)
  }
}
