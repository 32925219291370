import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { fromEvent } from 'rxjs';

export const PHONE_SEPARATOR = " "

@Directive({
    selector: '[appTelephone]',
    standalone: true
})
 export class TelephoneDirective {

  @Input() phoneNumber: string=''
  @Output() phoneNumberChange = new EventEmitter<string>();

  constructor(private element: ElementRef) {
    fromEvent(element.nativeElement, "input").subscribe(() => {
      this.formatPhoneNumber(this.element.nativeElement.value);
    });
  }
  
  ngAfterViewChecked() {
    this.formatPhoneNumber(this.element.nativeElement.value);
    }

  formatPhoneNumber(value: string) {
        
    if(!value) {
      return this.phoneNumberChange.next('')
    }

    value = value.replace(/[^0-9]/g, '')
    
    value = value.slice(0, 10)

    const s           : string = value
    const origLength = s.length
    let valueFormated : string = '';

    for(let i = 0; i < origLength; ++i) {
      if (i===0 && s.charAt(i)!='0') {
        //We do nothing because the phone number must start with '0'
      }else{
        valueFormated += s.charAt(i)
      }
      if(i % 2 !=0 && i!=origLength-1) {
        valueFormated += PHONE_SEPARATOR
      }
    }
    
    this.element.nativeElement.value = valueFormated
    this.phoneNumberChange.next(valueFormated)

  }

  getPhoneNumberWithoutFormat(formatedPhoneNumber:string|null):string{
    if (formatedPhoneNumber!=undefined) {
      return formatedPhoneNumber.replace(PHONE_SEPARATOR,'')
    } else {
      return '' 
    }
  }

  verificationFormatTelephoneRequis(control: FormControl) {
    let regExp = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
    if (control.value === '') { return null};
    return (regExp.test(control.value) ? null : { erreur: 'Le numéro de téléphone doit commencer par 0 et être composé de 5 nombres de 2 chiffres' });
  }
}
