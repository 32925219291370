import { Pipe, PipeTransform } from '@angular/core';

const separator = ' '

@Pipe({
    name: 'phoneFormat',
    standalone: true
})

export class PhoneFormatPipe implements PipeTransform {
  transform(phoneNumber: string): string {
    if (!phoneNumber) {
      return ''
    }
    let phoneNumberFormated = "";
    let i = 0;
  
    for (let index = 0; index < phoneNumber.length; index+=2) {
      phoneNumberFormated += phoneNumber.substring(index,index+2) + separator
    }
  
    return phoneNumberFormated;
  }

}
