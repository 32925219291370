import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Service } from 'src/app/interfaces/dossier/service';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { LogFicheServiceExterne } from 'src/app/interfaces/services-ffea/log-fiche-service-externe';
import { LogRecherche } from 'src/app/interfaces/services-ffea/log-recherche';
import { ServiceExterne } from 'src/app/interfaces/services-ffea/service-externe';
import { Environnement } from 'src/app/interfaces/utilitaire/environnement';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { ApiUtilitaireService } from 'src/app/services/tools/api-utilitaire.service';
import { LogFicheServiceExterneComponent } from '../log-fiche-service-externe/log-fiche-service-externe.component';
import { LogRechercheServiceExterneComponent } from '../log-recherche-service-externe/log-recherche-service-externe.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-log-filtre-service-externe',
    templateUrl: './log-filtre-service-externe.component.html',
    styleUrls: ['./log-filtre-service-externe.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatExpansionModule, MatIconModule, MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, NgIf, MatInputModule, MatButtonModule, MatProgressSpinnerModule, LogRechercheServiceExterneComponent, LogFicheServiceExterneComponent]
})
export class LogFiltreServiceExterneComponent implements OnInit {

  // Gestion de l'affichage
  waitingResearch       : boolean = false;
  expandPannel          : boolean = true;

  // Liste des interfaces pour les combos
  profilUtilisateur   : ProfilUtilisateur = <ProfilUtilisateur>{};
  environnements      : Array<Environnement> = Array<Environnement>();
  services            : Array<Service> = Array<Service>();
  services_externe    : Array<ServiceExterne> = Array<ServiceExterne>();
  
  // Formulaire de filtrage
  FiltreFormGroup: FormGroup;

  // Tableau de log services externe
  logs_recherche: Array<LogRecherche> = Array<LogRecherche>();

  // Tableau de log fiche service externe
  logs_fiche: Array<LogFicheServiceExterne> = Array<LogFicheServiceExterne>();

  // Accesseur en lecture des formControl
  get environnement()   : FormArray  { return <FormArray>this.FiltreFormGroup.get('environnement') as FormArray }
  get service()         : FormArray  { return <FormArray>this.FiltreFormGroup.get('service') as FormArray }
  get service_externe() : FormArray  { return <FormArray>this.FiltreFormGroup.get('service_externe') as FormArray }
  get date_debut()                   { return this.FiltreFormGroup.get('date_debut'); }
  get date_fin()                     { return this.FiltreFormGroup.get('date_fin'); }

  constructor(private _fb           : FormBuilder
            , private authUser      : ApiAuthentificationService
            , private apiUtilitaire : ApiUtilitaireService
            , private toast         : PersoSnackbarService) {
    // Initialisation du formulaire de filtrage
    this.FiltreFormGroup = this._fb.group({
      date_debut      : ['', [this.verificationFormatDateDebut.bind(this)]],
      date_fin        : ['', [this.verificationFormatDateFin.bind(this)]],
      environnement   : [Array<Environnement>(), [Validators.required]],
      service         : [Array<Service>(), [Validators.required]],
      service_externe : [Array<ServiceExterne>(), [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.profilUtilisateur = this.authUser.userConnect; 
    
    // Récupération des paramètres du formulaire de filtrage
    this.chargeFiltre();
  }

  public onSearchingServicesExterne() {
    this.waitingResearch  = true;
    this.logs_recherche = [];
    this.logs_fiche       = [];

    this.apiUtilitaire.postLogRecherche(this.FiltreFormGroup.value)
    .subscribe(
      (data: LogRecherche[]) => {
        this.logs_recherche   = data;
        this.toast.showInfo('Recherche terminée');
        this.waitingResearch  = false;
        this.expandPannel     = false;
      },
      (err: any) => {
        console.error('/!\ err: ', err.message);
        this.waitingResearch = false;
      });

    this.apiUtilitaire.postLogFiche(this.FiltreFormGroup.value)
    .subscribe(
      (data: LogFicheServiceExterne[]) => {
        this.logs_fiche       = data;
        this.waitingResearch  = false;
        this.expandPannel     = false;
      },
      (err: any) => {
        console.error('/!\ err: ', err.message);
        this.waitingResearch = false;
      });
  }

  public chargeFiltre() {
    this.apiUtilitaire.getServiceExterne(this.apiUtilitaire.CONST_CODE_ENVIRONNEMENT_SERVICES_FFEA)
      .subscribe(
        (data: ServiceExterne[]) => {
          this.services_externe = data;
          this.FiltreFormGroup.patchValue({            
            service_externe     : this.services_externe
          });
        },
        (err: any) => {
          console.log('err getServiceExterne() ' + err.error);
        }
      );
      
    this.apiUtilitaire.getEnvironnement(this.apiUtilitaire.CONST_CODE_ENVIRONNEMENT_SERVICES_FFEA)
      .subscribe(
        (data: Environnement[]) => {
          this.environnements = data;
          this.FiltreFormGroup.patchValue({            
            environnement     : this.environnements
          });
        },
        (err: any) => {
          console.log('err getEnvironnement() ' + err.error);
        }
      );

    this.apiUtilitaire.getService(this.apiUtilitaire.CONST_CODE_ENVIRONNEMENT_SERVICES_FFEA)
      .subscribe(
        (data: Service[]) => {
          this.services = data;
          this.FiltreFormGroup.patchValue({            
            service     : this.services
          });
        },
        (err: any) => {
          console.log('err getEnvironnement() ' + err.error);
        }
      );
  }

  verificationFormatDateDebut(fcControler: FormControl): any {
    if(this.FiltreFormGroup == undefined){
      return null;
    }
    
    let dateFin     : Date = new Date(this.FiltreFormGroup.controls.date_fin.value);
    let dateDebut   : Date = new Date(fcControler.value);
    let dateToday   : Date = new Date();

    dateDebut.setHours(0,0,0,0);
    dateToday.setHours(23,59,59,999); 

    if(dateFin != undefined) {
      dateFin.setHours(23,59,59,999);
      if(dateDebut > dateFin) { return { erreur: 'La date de début doit être  antérieure à la date de fin' }}
    }        

    if(dateDebut.getTime() > dateToday.getTime()){
      return { erreur: 'La date de début doit être antérieure à la date du jour' }
    }
    
    return null;
  }

  verificationFormatDateFin(fcControler: FormControl): any {
    if(this.FiltreFormGroup == undefined){
      return null;
    }

    let dateFin   : Date = new Date(fcControler.value);
    let dateDebut : Date = new Date(this.FiltreFormGroup.controls.date_debut.value);
    let dateToday : Date = new Date();    
    
    dateDebut.setHours(0,0,0,0);
    dateToday.setHours(23,59,59,999); 
    dateFin.setHours(23,59,59,999);
    
    if(isNaN(dateFin.getTime())) { return {erreur : 'La date de fin doit être postérieure à la date de début et antérieure à aujourd\'hui'}}

    if(dateFin.getTime() > dateToday.getTime() || (dateDebut.getTime() != null && dateFin.getTime() < dateDebut.getTime())){
      return { erreur: 'La date de fin doit être postérieure à la date de début et antérieure à aujourd\'hui' }
    }
    
    return null;
  }
}
