import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface FILTRE_UTI_PARAM{
  idUtilisateur : number
  idEntite      : number
  code?         : string
  type?         : string
}
export interface UtilisateurParam {
  utl_prm_id        : number,
  utl_prm_code      : string,
  utl_prm_type      : string,
  utl_prm_note      : string,
  utl_prm_nom       : string
}

export interface UtilisateurParamVal {
  utl_pva_id        : number,
  utl_pva_utl_id    : number,
  utl_pva_ent_id    : number,
  utl_pva_valeur    : string,
  utiParam?         : UtilisateurParam
}

export interface UtilisateurParamValExtended extends UtilisateurParamVal {
  isActivated: boolean;
  value: string;
}

@Injectable({
  providedIn: 'root'
})


export class APIUtilisateurParamService {
  
  CONST_UTI_PARAM_TYPE_RAPPORT_AUTOMATIQUE = "RAPPORT_AUTOMATIQUE"

  CONST_UTI_PARAM_CODE_RAPPORT_1B_NON_ENVOYEES : string = 'RAPPORT_1B_NON_ENVOYEES';
  
	
  private apiUrl    = '/api/utiparam';
  private apiValUrl = '/api/utiparamval'
  
  utiParams:UtilisateurParam[]=[]

  constructor(private http: HttpClient) {}

  //Valeur des paramètres utilisateurs:
  getParamValeurs(filtre:FILTRE_UTI_PARAM):Observable<UtilisateurParamVal[]>{
    return this.http.post<UtilisateurParamVal[]>(this.apiValUrl+'/liste',filtre);
  }

  createParamValeur(utiParamVal:UtilisateurParamVal){
    console.log(utiParamVal);
    
    return this.http.post<UtilisateurParamVal>(this.apiValUrl,utiParamVal)
  }

  updateParamValeur(utiParamVal:UtilisateurParamVal){
    return this.http.put<UtilisateurParamVal>(this.apiValUrl,utiParamVal)
  }


  deleteParamValeur(idUtiParamVal:number){
    return this.http.delete(this.apiValUrl+"/"+idUtiParamVal)
  }

   updateParam(param: UtilisateurParam): Observable<UtilisateurParam> {    
    return this.http.put<UtilisateurParam>(this.apiUrl, param);
  }

  deleteParam(idParam: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}/${idParam}`);
  }

  getParam(): Observable<UtilisateurParam[]> {
    return this.http.get<UtilisateurParam[]>(this.apiUrl);
  }
  
  getTypeLabel(type :String) : string {
    let title : string;

    switch (type) {
      case this.CONST_UTI_PARAM_TYPE_RAPPORT_AUTOMATIQUE:
        title = "Rapports automatiques";
        break;
      default :
        title = "Paramètres utilisateur"
        break;
    }
    
    return title
  }

}
