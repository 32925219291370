export interface UpdatePassword {
    current         : string,
    token          : string,
    new             : string,
    confirmation    : string
}

export class UpdatePassword implements UpdatePassword{
    current         : string ='';
    token          : string ='';
    new             : string  ='';
    confirmation    : string='';
    newCrypted?     :string='';

    constructor(){}
}