import { AfterViewInit, Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { StatsRepartitionMsgExpert } from 'src/app/interfaces/statistiques/stats-repartition-msg-expert';
import { MatTableResponsiveLargeDirective } from '../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';

@Component({
    selector: 'app-repartition-msg-concentrateur-expert',
    templateUrl: './repartition-msg-concentrateur-expert.component.html',
    styleUrls: ['./repartition-msg-concentrateur-expert.component.scss'],
    standalone: true,
    imports: [MatTableModule, MatTableResponsiveLargeDirective, MatPaginatorModule]
})
export class RepartitionMsgConcentrateurExpertComponent implements OnInit, AfterViewInit, OnChanges {

  // Données à afficher
  @Input() ELEMENT_DATA: Array<StatsRepartitionMsgExpert> = [];
  
  // Affichage des données dans la table
  dataSource  = new MatTableDataSource<StatsRepartitionMsgExpert>();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;
  
  // Tableau de répartition des messages experts
  displayedColumns: string[] = ['agrement_ve', 'expert', 'total_dossier', 'total_1a_oui', 'total_1a_non', 'total_1b_oui', 'total_1b_non', 'total_1c'];

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.dataSource.data = this.ELEMENT_DATA;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

}
