import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Service } from 'src/app/interfaces/dossier/service';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { ServiceExterne } from 'src/app/interfaces/services-ffea/service-externe';
import { Environnement } from 'src/app/interfaces/utilitaire/environnement';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { ApiUtilitaireService } from 'src/app/services/tools/api-utilitaire.service';
import { TableServiceExterneComponent } from '../table-service-externe/table-service-externe.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-filtre-service-externe',
    templateUrl: './filtre-service-externe.component.html',
    styleUrls: ['./filtre-service-externe.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatExpansionModule, MatIconModule, MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, NgIf, MatButtonModule, MatProgressSpinnerModule, TableServiceExterneComponent]
})
export class FiltreServiceExterneComponent implements OnInit {
  // Gestion de l'affichage
  waitingResearch       : boolean = false;
  expandPannel          : boolean = true;

  // Liste des interfaces pour les combos
  profilUtilisateur   : ProfilUtilisateur = <ProfilUtilisateur>{};
  environnements      : Array<Environnement> = Array<Environnement>();
  services            : Array<Service> = Array<Service>();
  services_externe    : Array<ServiceExterne> = Array<ServiceExterne>();
  
  // Formulaire de filtrage
  FiltreFormGroup: FormGroup;

  // Tableau de services externe
  ds_services_externe: Array<ServiceExterne> = Array<ServiceExterne>();

  // Accesseur en lecture des formControl
  get environnement()   : FormArray  { return <FormArray>this.FiltreFormGroup.get('environnement') as FormArray }
  get service()         : FormArray  { return <FormArray>this.FiltreFormGroup.get('service') as FormArray }
  get service_externe() : FormArray  { return <FormArray>this.FiltreFormGroup.get('service_externe') as FormArray }

  constructor(private _fb           : FormBuilder
            , private authUser      : ApiAuthentificationService
            , private apiUtilitaire : ApiUtilitaireService
            , private toast         : PersoSnackbarService) { 
    // Initialisation du formulaire de filtrage
    this.FiltreFormGroup = this._fb.group({
      environnement   : [Array<Environnement>(), [Validators.required]],
      service         : [Array<Service>(), [Validators.required]],
      service_externe : [Array<ServiceExterne>(), [Validators.required]]
    });
  }

  ngOnInit(): void {   
    this.profilUtilisateur = this.authUser.userConnect; 
    
    // Récupération des paramètres du formulaire de filtrage
    this.chargeFiltre();    
  }

  public onSearchingServicesExterne() {
    this.waitingResearch = true;
    this.ds_services_externe = [];

    this.apiUtilitaire.postServiceExterneWithFilter(this.FiltreFormGroup.value)
    .subscribe(
      (data: ServiceExterne[]) => {
        this.ds_services_externe = data;
        this.toast.showInfo('Recherche terminée');
        this.waitingResearch = false;
        this.expandPannel = false;
      },
      (err: any) => {
        console.error('/!\ err: ', err.message);
        this.waitingResearch = false;
      });
  }

  public chargeFiltre() {
    this.apiUtilitaire.getServiceExterne(this.apiUtilitaire.CONST_CODE_ENVIRONNEMENT_SERVICES_FFEA)
      .subscribe(
        (data: ServiceExterne[]) => {
          this.services_externe = data;
          this.FiltreFormGroup.patchValue({            
            service_externe     : this.services_externe
          });
        },
        (err: any) => {
          console.log('err getServiceExterne() ' + err.error);
        }
      );
      
    this.apiUtilitaire.getEnvironnement(this.apiUtilitaire.CONST_CODE_ENVIRONNEMENT_SERVICES_FFEA)
      .subscribe(
        (data: Environnement[]) => {
          this.environnements = data;
          this.FiltreFormGroup.patchValue({            
            environnement     : this.environnements
          });
        },
        (err: any) => {
          console.log('err getEnvironnement() ' + err.error);
        }
      );

    this.apiUtilitaire.getService(this.apiUtilitaire.CONST_CODE_ENVIRONNEMENT_SERVICES_FFEA)
      .subscribe(
        (data: Service[]) => {
          this.services = data;
          this.FiltreFormGroup.patchValue({            
            service     : this.services
          });
        },
        (err: any) => {
          console.log('err getEnvironnement() ' + err.error);
        }
      );
  }

}
