import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DialogData } from 'src/app/components/templates/oui-non-annuler/oui-non-annuler.component';
import { ApiFactureService } from 'src/app/services/api-facture.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { saveAs } from 'file-saver';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-dialog-bordereau',
    templateUrl: './dialog-bordereau.component.html',
    styleUrls: ['./dialog-bordereau.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, NgIf, MatButtonModule, MatDialogModule]
})
export class DialogBordereauComponent implements OnInit {

  get bordereau() { return this.formGroupBordereau.get('bordereau'); }

  reglementSelectedFilter: Array<number> = [];
  mode_paiement: string = '';

  formGroupBordereau: any;

  constructor(public dialogRef: MatDialogRef<DialogBordereauComponent>
            , @Inject(MAT_DIALOG_DATA) public data: DialogData
            , private _fb: FormBuilder
            , private toast: PersoSnackbarService
            , private apiFacture: ApiFactureService) { }

  ngOnInit(): void {
    this.reglementSelectedFilter = this.dialogRef._containerInstance._config.data.reglementSelectedFilter;
    this.mode_paiement = this.dialogRef._containerInstance._config.data.mode_paiement;
    
    this.formGroupBordereau = this._fb.group({
      bordereau  : ['', [Validators.required]]          
    });
  }

  public onDeclineRemise(){
    this.toast.showInfo("Remise des règlements annulé");
  }

  public onAcceptRemise(){
    this.apiFacture.postRemiseReglement(this.reglementSelectedFilter, this.formGroupBordereau.controls.bordereau.value, this.mode_paiement)
    .subscribe(
      (data: Blob) => {        
        if(this.mode_paiement == 'CHEQUE') {          
          saveAs(data, 'REM CHQ ' + this.formGroupBordereau.controls.bordereau.value+'.xlsx');
        } else {
          saveAs(data, 'REM VIRT ' + this.formGroupBordereau.controls.bordereau.value+'.xlsx');
        }
                
        this.toast.showInfo("Remise en banque effectuée");
      },
      (err: any) => {
        console.error('/!\ err: ', err.message);
      }
    );
  }
}
