import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FiltreCommande } from 'src/app/interfaces/commande/commande';
import { FiltreCommandeInitial } from 'src/app/services/api-commande.service';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { EntiteSearchComponent } from 'src/app/components/templates/entite-search/entite-search.component';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-facturation-filtre',
    templateUrl: './facturation-filtre.component.html',
    styleUrls: ['./facturation-filtre.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, FormsModule, MatButtonModule
               ,EntiteSearchComponent,MatIconModule,NgIf
    ]
})
export class FacturationFiltreComponent implements OnInit {
 
  @Output() filterSelected = new EventEmitter<FiltreCommande>();

  filterDateDebut   : string = ''
  filterDateFin     : string = ''
  filterContact     : string = ''
  entiteSelected    : Entite|null =null
  searching         : boolean = false

  constructor() { }

  ngOnInit(): void {
  }

  selectFilter(){
    const FiltreCommande:FiltreCommande = {...FiltreCommandeInitial,"dateDebut":this.filterDateDebut,"dateFin":this.filterDateFin
                                              ,"idEntite":this.entiteSelected? this.entiteSelected?.id! : 0}
    this.filterSelected.emit(FiltreCommande)
  }

  setEntite(entite:Entite|null){
    this.searching = false
    if (entite) {
      this.entiteSelected = entite
    }
  }

  searchingEntite(){
    this.searching = true
  }

  cancelEntite(){
    this.entiteSelected = null
  }
}
