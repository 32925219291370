<mat-tab-group style="margin-top: -20px;">
    <mat-tab >
        
        <ng-template mat-tab-label>
            <div class="div-container-left">
                <span class="material-symbols-outlined">
                    settings
                </span>
                <span class="mat-tab-label">Gestion des paramètres de l'extranet</span>
            </div>
        </ng-template>
        
        <ng-template matTabContent>
            <mat-card appearance="outlined">
                <mat-card-header>
                    <mat-card-title>Gestion des paramètres de l'extranet</mat-card-title>
                </mat-card-header>
        
                <mat-card-content class=".mat-card-content">
                    <ng-container *ngIf="typesParametresExtranet$ |async as typesParametresExtranet">
                        <ng-container *ngIf="parametresExtranet$ |async as parametresExtranet">
                            <div class="content" *ngFor="let typeParametreExtranet of typesParametresExtranet" >
                                <div class="titlebox">{{typeParametreExtranet}}</div>
                                <mat-grid-list cols="10" rowHeight="30px" class="title">
                                    <mat-grid-tile colspan="3">Libellé</mat-grid-tile>
                                    <mat-grid-tile colspan="3">Code</mat-grid-tile>
                                    <mat-grid-tile colspan="3">Valeur</mat-grid-tile>
                                </mat-grid-list>
        
                                <div    *ngFor = "let parametreExtranet of parametresExtranet" appearance      = "outline" > 
                                    <mat-grid-list cols="10" rowHeight="50"   *ngIf="parametreExtranet.type==typeParametreExtranet" style="border-bottom: 1px dashed brown;">
                                        <mat-grid-tile colspan="3" ><div>{{parametreExtranet.note}}</div></mat-grid-tile>
                                        <mat-grid-tile colspan="3" ><div>{{parametreExtranet.code}}</div></mat-grid-tile>
                                        <mat-grid-tile colspan="3" class="valeur"><div>{{parametreExtranet.valeur}}</div></mat-grid-tile>
                                        <mat-grid-tile colspan="1" ><button mat-raised-button color="primary" (click)="openEditExtranetParameter(parametreExtranet)">Modifier</button></mat-grid-tile>
                                    </mat-grid-list>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </mat-card-content>
            </mat-card>
        </ng-template>      
    </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
        <div class="div-container-left">
            <span class="material-symbols-outlined">
                manage_accounts
            </span>
            <span class="mat-tab-label">Gestion des paramètres utilisateurs</span>
        </div>
    </ng-template>

    <ng-template matTabContent>
        <mat-card appearance="outlined">
            <mat-card-header>
                <mat-card-title>Gestion des paramètres utilisateurs</mat-card-title>
            </mat-card-header>

            <mat-card-content class=".mat-card-content">
                <ng-container *ngIf="typesParametresUtilisateur">
                    <ng-container *ngIf="parametresUtilisateur">
                        <div class="content" *ngFor="let typeParametreUtilisateur of typesParametresUtilisateur" >
                            <div class="titlebox">{{apiUtiParam.getTypeLabel(typeParametreUtilisateur)}}</div>
                            <div class="div-container-left title" style="gap: 0; text-align: center;padding: 8px;" >
                                <span style="width: 30%;">Nom</span>
                                <span style="width: 30%;">Code</span>
                                <span style="width: 30%;">Description</span>
                                <span style="width: 10%;"></span>
                            </div>
                            <div *ngFor = "let parametreUtilisateur of parametresUtilisateur" appearance = "outline">
                                <div class="div-container-left" style="gap: 0;padding: 8px; border-bottom: 1px dashed brown;" *ngIf="parametreUtilisateur.utl_prm_type == typeParametreUtilisateur">
                                    <span style="width: 30%;">{{parametreUtilisateur.utl_prm_nom}}</span>
                                    <span style="width: 30%;">{{parametreUtilisateur.utl_prm_code}}</span>
                                    <span style="width: 30%;white-space: pre-wrap;">{{parametreUtilisateur.utl_prm_note}}</span>
                                    <div style="width: 10%;vertical-align: middle;" class="div-button-center"><button mat-raised-button color="primary" (click)="openEditUserParameter(parametreUtilisateur)">Modifier</button></div>
                                </div>                    
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </mat-card-content>
        </mat-card>
    </ng-template>
  </mat-tab>
</mat-tab-group>
