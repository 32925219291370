import { Injectable } from '@angular/core';
import { ApiAdelaService } from '../api-adela.service';
import { Observable, combineLatest } from 'rxjs';
import { TypeElementRel, TypeValeurResultatWithElements } from 'src/app/interfaces/adela/resultat-analyse';
import { map } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { PersoSnackbarService } from '../perso-snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class AdelaFacadeService {
  urlApi: string = '/api';

  typeElements$ = this.apiAdela.typeElements$;
  typesElementRel               : TypeElementRel[]=[];
  typesValeurResultatWithElements$:Observable<TypeValeurResultatWithElements[]> = combineLatest([
    this.apiAdela.typeValeurElements$,
    this.apiAdela.typeElements$
  ]).pipe(
    map(([typesValeursElements,typeElements]) =>
      {
        let typesValeurResultatWithElements:TypeValeurResultatWithElements[]=[];
        typesValeursElements.forEach((typeValeurElement) =>{
          
          const typeElementRel:TypeElementRel = {
            id        : typeValeurElement.relId
            ,tpe_id   : typeValeurElement.reltpeid
            ,tpv_id   : typeValeurElement.reltpvid
            ,libelle  : ''
            ,code     : ''
          }

          let elementFound:TypeValeurResultatWithElements|undefined = typesValeurResultatWithElements.find(element => element.id === typeValeurElement.id && element.id_categorie === typeValeurElement.id_categorie)

          if (!elementFound) {
            elementFound = {
              id                    : typeValeurElement.id
              ,code                 : typeValeurElement.code
              ,libelle              : typeValeurElement.libelle
              ,unite                : typeValeurElement.unite
              ,position_fichier_xls : typeValeurElement.position_fichier_xls
              ,code_categorie       : typeValeurElement.code_categorie
              ,categorie            : typeValeurElement.categorie 
              ,ordre                : typeValeurElement.ordre
              ,id_categorie         : typeValeurElement.id_categorie
              ,relations            : []
            }

            typeElements.forEach((element)=>{
              const relElement:TypeElementRel = {
                id:0,
                tpe_id:element.id,
                tpv_id:elementFound!.id,
                libelle:element.libelle,
                code:element.code
              }

              if (relElement.tpe_id===typeElementRel.tpe_id) {
                typeElementRel.libelle = relElement.libelle
                typeElementRel.code = relElement.code
                elementFound!.relations.push(typeElementRel)
              }else{
                elementFound!.relations.push(relElement)
              }
            })

            typesValeurResultatWithElements=[...typesValeurResultatWithElements,elementFound]
            
          }else{
            elementFound.relations = elementFound.relations.map((element)=> element.tpe_id===typeElementRel.tpe_id? {...typeElementRel,libelle:element.libelle,code:element.code} :element)  
          }
          
        })
        
        return typesValeurResultatWithElements
      }
    )
  )

  enregistreLiens(lienForm:FormGroup){
    this.typesElementRel=[];

    lienForm.value.elementArray.forEach((element:any)=>{
      element.typeElement.forEach((type:any)=>{
        if (type.checked===true) {
          const rowLink:TypeElementRel = {id:0,tpv_id:type.idtpv,tpe_id:type.idtpe,libelle:type.libelle,code:type.code};
          this.typesElementRel.push(rowLink);
        }
      })
    })
    this.apiAdela.enregistreLiensTypeValeurElement(this.typesElementRel).subscribe(
      (retour)=>this.toast.validate('Vos modifications ont bien été enregistrées'),
      (error)=>this.toast.showError('Un problème est survenu au niveau de la base de données')
      
    )
  }

  constructor(private apiAdela : ApiAdelaService
              ,private toast:PersoSnackbarService) { }

}
