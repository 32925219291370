import { Component, OnInit } from '@angular/core';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { TableListeUtilisateursServicesComponent } from './table-liste-utilisateurs-services/table-liste-utilisateurs-services.component';
import { FiltreListeUtilisateursServicesComponent } from './filtre-liste-utilisateurs-services/filtre-liste-utilisateurs-services.component';

@Component({
    selector: 'app-liste-utilisateurs-services',
    templateUrl: './liste-utilisateurs-services.component.html',
    styleUrls: ['./liste-utilisateurs-services.component.scss'],
    standalone: true,
    imports: [FiltreListeUtilisateursServicesComponent, TableListeUtilisateursServicesComponent]
})
export class ListeUtilisateursServicesComponent implements OnInit {

  ds_ProfilsUtilisateur: Array<ProfilUtilisateur> = Array<ProfilUtilisateur>();

  constructor() { }

  ngOnInit(): void {

  }

  addItem(newItem: Array<ProfilUtilisateur>) : void {
    this.ds_ProfilsUtilisateur = newItem;
  }

}
