<mat-card appearance="outlined" style="margin-top: 80px;">
    <div class="div-container">
        <h4 style="font-size: 1.6em;">Bienvenue sur le nouveau site de la fédération française des experts automobiles.</h4>
        <p style="width: 80%;">
            Afin de procéder à votre ré-inscription dans de meilleures conditions, nous vous demandons
            de bien vouloir procéder à la vérification, la complétion et la correction des informations suivantes ayant
            un lien direct avec votre activité au sein de la FFEA.<br/>
            Vous recevrez un email de confirmation une fois l'inscription terminée.
        </p>
    </div>

    <form [formGroup]="inscriptionARD">
        <mat-stepper orientation="vertical" [ngClass]="{'openUpStepper' : deplierStepper == false}" [linear]="verrouillerStepper" #stepper>
            
            <mat-step [stepControl]="inscriptionARD.get('dirigeantARD')!" [errorMessage]="errorMessageStepper">
                <ng-template matStepLabel class="label-stepper">
                    <mat-icon style="font-size: 32px; margin-right: 20px;">supervisor_account</mat-icon>Profil Dirigeant·e
                </ng-template>
                <div formGroupName="dirigeantARD">
                    
                    <mat-form-field>
                        <mat-label for="agrement_ve">Agrément VE</mat-label>
                        <input matInput type="text" formControlName="agrement_ve" placeholder="XXXXXX-VE" [readonly]="!dirigeantModifie" oninput="this.value = this.value.toUpperCase()" required>
                        <mat-error *ngIf="inscriptionARD.get('dirigeantARD.agrement_ve')?.hasError('required')">Le numéro d'agrément VE est obligatoire</mat-error>
                        <mat-error *ngIf="inscriptionARD.get('dirigeantARD.agrement_ve')?.hasError('formatAgrementVE')">{{ inscriptionARD.get('dirigeantARD.agrement_ve')?.errors?.formatAgrementVE }}</mat-error>
                        <mat-error *ngIf="inscriptionARD.get('dirigeantARD.agrement_ve')?.hasError('DoublonAgrementVE')">{{ inscriptionARD.get('dirigeantARD.agrement_ve')?.errors?.DoublonAgrementVE }}</mat-error>
                    </mat-form-field>
    
                    <mat-form-field>
                        <mat-label for="nom">Nom </mat-label>
                        <input matInput type="text" formControlName="nom" placeholder="Nom" oninput="this.value = this.value.toUpperCase()" required>
                        <mat-error *ngIf="nom?.invalid && (nom?.dirty || nom?.touched)">{{ nom?.errors?.erreur }}</mat-error>
                    </mat-form-field>
                    
                    <mat-form-field>
                        <mat-label for="prenom">Prénom </mat-label>
                        <input appTitleCase
                                matInput type   = "text" 
                                formControlName = "prenom" 
                                placeholder     = "Prénom" 
                                minlength       = "1"
                                maxlength       = "50"
                                required
                                >
                        <mat-error *ngIf="prenom?.invalid && (prenom?.dirty || prenom?.touched)">{{ prenom?.errors?.erreur }}</mat-error>
                    </mat-form-field>
    
                    <mat-form-field>
                        <mat-label for="email">Email </mat-label>
                        <input matInput type="email" formControlName="email" placeholder="Email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" required>
                        <mat-hint>ATTENTION : Cette adresse email servira pour vous connecter à l'extranet.</mat-hint>
                        <mat-error *ngIf="inscriptionARD.get('dirigeantARD.email')?.hasError('required')">L'adresse email obligatoire</mat-error>
                        <mat-error *ngIf="inscriptionARD.get('dirigeantARD.email')?.hasError('checkValidatorMail')">{{ inscriptionARD.get('dirigeantARD.email')?.errors?.checkValidatorMail }}</mat-error>
                        <mat-error *ngIf="inscriptionARD.get('dirigeantARD.email')?.hasError('DoublonEmailExpert')">{{ inscriptionARD.get('dirigeantARD.email')?.errors?.DoublonEmailExpert }}</mat-error>
                    </mat-form-field>             
    
                    <mat-form-field>
                        <mat-label for="telephone">telephone </mat-label>
                        <input matInput type="tel" formControlName="telephone" placeholder="telephone" appTelephone>
                        <mat-error *ngIf="inscriptionARD.get('dirigeantARD.telephone')?.hasError('dixchiffres')">{{ inscriptionARD.get('dirigeantARD.telephone')?.errors?.dixchiffres }}</mat-error>
                    </mat-form-field>
    
                    <mat-form-field>
                        <mat-label>Rôle </mat-label>
                        <mat-select formControlName="idgrp" required (selectionChange)="SelectionneGoupe($event, -1)">
                            <mat-option *ngFor="let groupe of groupes" [value]="groupe.id">
                                {{groupe.nom}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                     <!-- Button Suivant -->
                    <div class="div-container-right">
                        <button mat-raised-button color="primary" (click)="modifierDirigeant()">
                            Modifier le dirigeant
                        </button>
                        <button mat-raised-button color="primary" (click)="stepper.next()" [disabled]="inscriptionARD.get('dirigeantARD')!.invalid">
                            Suivant
                        </button>
                    </div>
                    
                </div>
            </mat-step>

            <mat-step [stepControl]="inscriptionARD.get('entiteARD')!" [errorMessage]="errorMessageStepper">
                <ng-template matStepLabel class="label-stepper">
                    <mat-icon style="font-size: 32px; margin-right: 20px">apartment</mat-icon>{{ inscriptionARD.get('groupSiren.raisonSociale')!.value }}
                </ng-template>
                <div formGroupName="entiteARD">
                   
                    <mat-form-field>
                        <mat-label>N° Habilitation</mat-label>
                        <input matInput type="text" formControlName="habilitation" placeholder="Numéro d'habilitation" oninput="this.value = this.value.toUpperCase()" readonly required>
                        <mat-error *ngIf="habilitation?.invalid && (habilitation?.dirty || habilitation?.touched)">Le numéro d'habilitation est obligatoire</mat-error>
                    </mat-form-field>
        
                    <mat-form-field>
                        <mat-label>Type d'entité </mat-label>
                        <input matInput type="text" formControlName="typ_ent_nom" readonly>
                    </mat-form-field>

                    <div style="margin-top: 10px;">
                        <app-siren controlKey="groupSiren" (adresseEvent) = "setAdresse($event)" [searchSiret]=false></app-siren>
                    </div>   
                    <div style="margin-top: 30px;">
                        <app-adresse controlKey = "adresse" [adresse]="adresse"></app-adresse>
                    </div>

                    <mat-form-field>
                        <mat-label>N° téléphone</mat-label>
                        <input matInput type="text" id="telephone" formControlName="telephone" appTelephone>
                        <mat-error *ngIf="inscriptionARD.get('entiteARD.telephone')?.hasError('required')">Le numéro de téléphone est obligatoire</mat-error>
                        <mat-error *ngIf="inscriptionARD.get('entiteARD.telephone')?.hasError('dixchiffres')">{{ inscriptionARD.get('entiteARD.telephone')?.errors?.dixchiffres }}</mat-error>
                    </mat-form-field>
        
                    <mat-form-field>
                        <mat-label>Fax</mat-label>
                        <input matInput type="text" id="fax" formControlName="fax" appTelephone>
                        <mat-error *ngIf="inscriptionARD.get('entiteARD.fax')?.hasError('dixchiffres')">{{ inscriptionARD.get('entiteARD.fax')?.errors?.dixchiffres }}</mat-error>
                    </mat-form-field>
        
                    <mat-form-field>
                        <mat-label>Email facturation</mat-label>
                        <input matInput type="email" formControlName="mail_facturation" oninput="this.value = this.value.toLowerCase()">
                        <mat-error *ngIf="mail_facturation?.hasError('checkValidatorMail')">{{ mail_facturation?.errors?.checkValidatorMail }}</mat-error>
                    </mat-form-field>
        
                    <mat-form-field>
                        <mat-label>Email statistique</mat-label>
                        <input matInput type="email" formControlName="mail_statistique" oninput="this.value = this.value.toLowerCase()">
                        <mat-error *ngIf="mail_statistique?.hasError('checkValidatorMail')">{{ mail_statistique?.errors?.checkValidatorMail }}</mat-error>
                    </mat-form-field>
        
                    <mat-form-field>
                        <mat-label>Email contact</mat-label>
                        <input matInput type="email" formControlName="mail_contact" oninput="this.value = this.value.toLowerCase()">
                        <mat-error *ngIf="mail_contact?.hasError('checkValidatorMail')">{{ mail_contact?.errors?.checkValidatorMail }}</mat-error>
                    </mat-form-field>
        
                    <mat-form-field>
                        <mat-label for="tva">TVA</mat-label>
                        <mat-select (change)="SelectionneTVA($event)" formControlName="idtva">
                            <mat-option *ngFor="let item of tvas" [value]="item.id">{{ item.libelle }} - {{ item.pourcentage }}%</mat-option>
                        </mat-select>
                        
                        <mat-error *ngIf="idtva?.invalid && (idtva?.dirty || idtva?.touched)">La TVA est obligatoire</mat-error>
                    </mat-form-field>

                    <div>
                        <mat-hint>ATTENTION : Pour la TVA, sélectionnez "FR - 20%" pour les cabinets localisés en France métropolitaine, "DOM - 5,5%" pour les cabinets localisés dans les DOM ou "PAS - 0%" pour les cabinets sans TVA.<br/>
                        </mat-hint>
                    </div>

                    <div class="div-container-right">
                        <button *ngIf="users.controls.length > 0 " mat-raised-button color="primary" (click)="stepper.next()" [disabled]="inscriptionARD.get('entiteARD')!.invalid || inscriptionARD.get('adresse')!.invalid">
                            Suivant
                        </button>
                    </div>

                </div>
            </mat-step>

            <ng-container formArrayName="usersARD">
                <mat-step *ngFor="let employe of users.controls; let i=index" [stepControl]="employe" [errorMessage]="errorMessageStepper">
                    <ng-template matStepLabel class="label-stepper">
                        <mat-icon style="font-size: 32px;">person</mat-icon> {{ i + 1 }}. {{ employe.get('nom')!.value }} {{ employe.get('prenom')!.value }}
                    </ng-template>

                    <div [formGroupName]="i">
                        <mat-form-field>
                            <mat-label for="agrement_ve">Agrément VE</mat-label>
                            <input matInput type="text" formControlName="agrement_ve" placeholder="XXXXXX-VE" oninput="this.value = this.value.toUpperCase()" required>
                            <mat-error *ngIf="employe.get('agrement_ve')?.hasError('required')">Le numéro d'agrément VE est obligatoire</mat-error>
                            <mat-error *ngIf="employe.get('agrement_ve')?.hasError('formatAgrementVE')">{{ employe.get('agrement_ve')?.errors?.formatAgrementVE }}</mat-error>
                            <mat-error *ngIf="employe.get('agrement_ve')?.hasError('DoublonAgrementVE')">{{ employe.get('agrement_ve')?.errors?.DoublonAgrementVE }}</mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label for="nom">Nom </mat-label>
                            <input matInput type="text" formControlName="nom" placeholder="Nom" oninput="this.value = this.value.toUpperCase()" required>                    
                            <mat-error *ngIf="nom?.invalid && (nom?.dirty || nom?.touched)">{{ nom?.errors?.erreur }}</mat-error>
                        </mat-form-field>                                
                        
                        <mat-form-field>
                            <mat-label for="prenom">Prénom </mat-label>
                            <input appTitleCase matInput type="text" formControlName="prenom" placeholder="Prénom" required>
                            <mat-error *ngIf="prenom?.invalid && (prenom?.dirty || prenom?.touched)">{{ prenom?.errors?.erreur }}</mat-error>
                        </mat-form-field>
        
                        <mat-form-field>
                            <mat-label for="email">Email </mat-label>
                            <input matInput type="email" formControlName="email" placeholder="Email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" required>
                            <mat-hint>ATTENTION : Cette adresse email servira pour vous connecter à l'extranet.</mat-hint>
                            <mat-error *ngIf="employe.get('email')?.hasError('required')">L'adresse email obligatoire</mat-error>
                            <mat-error *ngIf="employe.get('email')?.hasError('checkValidatorMail')">{{ employe.get('email')?.errors?.checkValidatorMail }}</mat-error>
                            <mat-error *ngIf="employe.get('email')?.hasError('DoublonEmailExpert')">{{ employe.get('email')?.errors?.DoublonEmailExpert }}</mat-error>
                        </mat-form-field>             
        
                        <mat-form-field>
                            <mat-label for="telephone">telephone </mat-label>
                            <input matInput type="tel" formControlName="telephone" placeholder="telephone" appTelephone>
                            <mat-error *ngIf="employe.get('telephone')?.hasError('dixchiffres')">{{ employe.get('telephone')?.errors?.dixchiffres }}</mat-error>
                        </mat-form-field>
                                                
                        <mat-form-field>
                            <mat-label>Rôle </mat-label>
                            <mat-select formControlName="idgrp" required (selectionChange)="SelectionneGoupe($event, i)">
                                <mat-option *ngFor="let groupe of groupes" [value]="groupe.id">
                                    {{groupe.nom}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div>
                            <mat-slide-toggle formControlName="est_dirigeant" color="primary" style="margin-right: 20px;">  Est dirigeant</mat-slide-toggle>
                            <mat-slide-toggle *ngIf="employe.get('idExterne')!.value != 0" formControlName="estImporte" color="primary">  A importer</mat-slide-toggle>
                        </div>
                        
                        <div class="div-container-right" >
                            <button *ngIf="employe.get('idExterne')!.value == 0" mat-icon-button color="warn" (click)="deleteNewEmployee(stepper, i)">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <button *ngIf="i < users.controls.length-1" mat-raised-button color="primary" (click)="stepper.next()" [disabled]="!isEmployeeStepValid(i)">
                                Suivant
                            </button>
                        </div>
                    </div>
                </mat-step>
            </ng-container>            
        </mat-stepper>

        <div class="div-container-right">
            <div class="div-container-right">
                <button mat-raised-button color="primary" (click)="addNewEmployee(stepper)">
                    Ajouter un collaborateur
                    <mat-icon>person</mat-icon>
                </button>
            </div>
            <button 
                *ngIf="!waiting && !inscriptionEnded"
                mat-raised-button 
                color       = "primary" 
                (click)     = "saveInscription()" 
                [disabled]  = "inscriptionARD.invalid">
                <span class="span-button">Valider</span>
                <mat-icon>done</mat-icon>
            </button>
        </div>
    </form>
    
    <app-progress [task]="task" *ngIf="waiting||inscriptionEnded"></app-progress>
   
</mat-card>
