import { EtatCoordonneesBancaire, EtatCoordonnesBancaireInit } from "./coordonnees-bancaire/etat-coordonnees-bancaire";

export interface CoordonneesBancaire {
    id                      : number,
	date_creation           : Date,
	date_modification       : Date,
	iban                    : string,
	bic                     : string,
	rum						: string,
	nom_fichier_iban		: string,
	pdf_iban		   		: string,
	nom_fichier_rum			: string,
	pdf_rum					: string,
	date_mandat				: string,
	statut_manadat			: string,
	compteur_statut			: number,
	commentaire				: string,
	etat					: EtatCoordonneesBancaire,
	idModePaiement			: number,
	rel_ent_bnc_id			: number,
	rel_ent_id				: number,
	rel_bnc_id				: number,
	rel_ent_bnc_date_debut	: Date,
	rel_ent_bnc_date_fin	: Date,
	rel_ent_bnc_actif		: boolean
}

export const CoordonneesBancaireInit : CoordonneesBancaire={
	'bic'						: ''
	,'commentaire'				: ''
	,'compteur_statut'			: 0
	,'date_creation'			: new Date()
	,'date_mandat' 				: ''
	,'date_modification' 		: new Date()
	,'etat'						: EtatCoordonnesBancaireInit
	, 'iban'					: ''
	,'id'						: 0
	,'nom_fichier_iban'			: ''
	,'nom_fichier_rum'			: ''
	,'pdf_iban'					: ''
	,'pdf_rum'					: ''
	,'rel_bnc_id'				: 0
	,'rel_ent_bnc_actif'		: true
	,'rel_ent_bnc_date_debut'	: new Date()
	,'rel_ent_bnc_date_fin'		: new Date()
	,'rel_ent_bnc_id'			: 0
	,'rel_ent_id'				: 0
	,'rum'						: ''
	,'statut_manadat'			: ''
	,'idModePaiement'			: 0
}
