<mat-card appearance="outlined" class="mat-card-padding" *ngIf="suspensionAsMatTableDataSource$ |async as suspension">
    <div class="div-container-between">
        <mat-card-title>{{suspension.data.length}} suspension<span *ngIf="suspension.data.length >1">s</span></mat-card-title>
    </div>

    <div class="container">
        <table  
                mat-table 
                matTableResponsiveLarge 
                [dataSource]    = "suspension" 
                class           = "table-primary">
        
           <!-- Statut de suspension --> 
            <ng-container matColumnDef="statut">
                <th mat-header-cell *matHeaderCellDef> Statut </th>
                <td mat-cell *matCellDef="let suspension" > {{ suspension.statut.nom }} </td> 
            </ng-container>
            
             <!-- Habilitation --> 
             <ng-container matColumnDef="habilitation">
                <th mat-header-cell *matHeaderCellDef> Habilitation </th>
                <td mat-cell *matCellDef="let suspension" > {{ suspension.entite.habilitation }} </td> 
            </ng-container>

            <!-- Contact --> 
            <ng-container matColumnDef="contact">
                <th mat-header-cell *matHeaderCellDef> Contact </th>
                <td mat-cell *matCellDef="let suspension" > {{ suspension.entite.numero_client }} </td> 
            </ng-container>

            <!-- Raison Sociale --> 
            <ng-container matColumnDef="raison_sociale">
                <th mat-header-cell *matHeaderCellDef> Raison Sociale </th>
                <td mat-cell *matCellDef="let suspension" > {{ suspension.entite.raison_sociale }} </td> 
            </ng-container>

            <!-- Ville --> 
            <ng-container matColumnDef="ville">
                <th mat-header-cell *matHeaderCellDef> Ville </th>
                <td mat-cell *matCellDef="let suspension" > {{ suspension.entite.ville }} </td> 
            </ng-container>
            
             <!-- SIREN --> 
             <ng-container matColumnDef="siren">
                <th mat-header-cell *matHeaderCellDef> SIREN </th>
                <td mat-cell *matCellDef="let suspension" > {{ suspension.entite.siren }} </td> 
            </ng-container>

            <!-- Date de suspension --> 
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef> date </th>
                <td mat-cell *matCellDef="let suspension" > {{ suspension.date | date: 'dd/MM/yyyy HH:mm:ss' }} </td> 
            </ng-container>
            
             <!-- Durée --> 
             <!-- <ng-container matColumnDef="duree">
                <th mat-header-cell *matHeaderCellDef> Habilitation </th>
                <td mat-cell *matCellDef="let suspension" > {{ suspension.entite.habilitation }} </td> 
            </ng-container> -->


            
            <!-- Editer -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell 
                    *matHeaderCellDef 
                    class   = "derniere-colonne" 
                    style   = "text-align: right;">
                </th>
                
                <td mat-cell 
                    *matCellDef = "let typeSuspension" 
                    class       = "colonne-module" 
                    style       = "text-align: right;">
                    
                    <button mat-icon-button 
                            color   = "primary"
                            style   = "width: 48px; height: 48px;"
                            (click) = "onEditer(typeSuspension)">
                        
                            <mat-icon   color="primary" 
                                    matTooltip="Editer cette suspension">edit
                        </mat-icon>
                    </button>

                </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator
            [pageSizeOptions]="[5,10,25]"
            showFirstLastButtons 
            aria-label="Nombre de lignes visibles...">
        </mat-paginator>
    </div>
</mat-card>



