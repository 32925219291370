<app-filtre-articles (research)="onChildResearchArticles($event)"></app-filtre-articles>
<mat-card appearance="outlined" class="mat-card-no-shadow"  style="padding: 0px;">
    <mat-expansion-panel [expanded]="true" style="background-color: #fff">
        <mat-expansion-panel-header style="background-color: #fff">
            <mat-panel-title>
                <mat-icon color="primary">file</mat-icon>
                <span style="width: 220px;" *ngIf="articles!= undefined"><span class="span-primary">{{ articles.length }}</span>ARTICLE<span *ngIf="articles.length > 1">S</span></span>
                <span style="width: 220px;" *ngIf="articles == undefined"><span class="span-primary">0</span>ARTICLE</span>        
            </mat-panel-title>
            <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>
        <app-table-articles [dataSource_articles]="articles"></app-table-articles>
    </mat-expansion-panel>
</mat-card>