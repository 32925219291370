<div [hidden]="modeEdit === true">
    <mat-card appearance="outlined">
    
        <mat-card-title>Sélectionnez votre filtre</mat-card-title>
        <mat-card-content >
            <mat-form-field>
                <mat-label>Catégorie</mat-label>
                <input matInput type="text" [(ngModel)] = "filterCategorie" oninput="this.value = this.value.toUpperCase()">
            </mat-form-field>

            <mat-form-field *ngIf="fluides$ |async as fluides">
                <mat-label>Fluide</mat-label>
                <mat-select [(ngModel)] = "filterFluide">
                    <mat-option value = 0>TOUTES</mat-option>
                    <mat-option *ngFor="let fluide of fluides" [value]="fluide.id">{{ fluide.nom }}</mat-option>
                </mat-select>
            </mat-form-field> 
            
            <mat-form-field *ngIf="organes$ |async as organes">
                <mat-label>Organe</mat-label>
                <mat-select [(ngModel)] = "filterOrgane">
                    <mat-option value = 0>TOUTES</mat-option>
                    <mat-option *ngFor="let organe of organes" [value]="organe.id">{{ organe.nom }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Désignation</mat-label>
                <input matInput type="text" [(ngModel)] = "filterDesignation" oninput="this.value = this.value.toUpperCase()">
            </mat-form-field>

            <mat-form-field>
                <mat-label>Libellé grille tarifaire</mat-label>
                <input matInput type="text" [(ngModel)] = "filterLibelle" oninput="this.value = this.value.toUpperCase()">
            </mat-form-field>

            <mat-form-field style="max-width: 100px;">
                <mat-label>Code Laboratoire</mat-label>
                <input matInput type="text" [(ngModel)] = "filterCode" oninput="this.value = this.value.toUpperCase()">
            </mat-form-field>
            <div style="display: flex;">
                <mat-radio-group [(ngModel)] ="filterAffiche" class="radio-group">
                    <mat-radio-button value = "0" >  Toutes les prestations</mat-radio-button>
                    <mat-radio-button value = "1" >  Les prestations présentes dans les tarifs</mat-radio-button>
                    <mat-radio-button value = "2" >  Les prestations absentes des tarifs</mat-radio-button>
                </mat-radio-group>
            
                <mat-radio-group [(ngModel)] ="filterActive" class="radio-group ">
                    <mat-radio-button value = "0" >  Toutes les prestations</mat-radio-button>
                    <mat-radio-button value = "1" >  Les prestations activées</mat-radio-button>
                    <mat-radio-button value = "2" >  Les prestations désactivées</mat-radio-button>
                </mat-radio-group>
            </div>
    </mat-card-content>
        <mat-card-actions  align="end">
            <button mat-raised-button color="primary" (click)="selectFilter()">Rechercher <mat-icon color="white">search</mat-icon></button>
            <button mat-raised-button color="primary" (click)="create()" style="margin-left: 10px;">Créer une prestation<mat-icon color="white">add</mat-icon></button>
        </mat-card-actions>

    </mat-card>
</div>