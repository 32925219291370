export interface EntiteAccesConcentrateur {
    id                  : number,
    habilitation        : string,
    indice              : string,
    mot_de_passe        : string,
    date_creation       : Date,
    date_modification   : Date,
    actif               : boolean,
    habilitation_indice : string,
    mailAuto            : boolean,
    mailConcentrateur   : string
}

export const EntiteAccesConcentrateurInit : EntiteAccesConcentrateur={
    'actif'                 : false
    ,'date_creation'        : new Date()
    ,'date_modification'    : new Date()
    ,'habilitation'         : ''
    ,'habilitation_indice'  : ''
    ,'id'                   : 0
    ,'indice'               : ''
    ,'mailAuto'             : false
    ,'mailConcentrateur'    :''
    ,'mot_de_passe'          : ''
}
