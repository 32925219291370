import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ApiPhrasierService } from 'src/app/services/api-phrasier.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { PhrasierComponent } from '../espace-phrasier/phrasier.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-dialog-confirmation-suppression',
    templateUrl: './dialog-confirmation-suppression.component.html',
    styleUrls: ['./dialog-confirmation-suppression.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatButtonModule, MatDialogModule]
})
export class DialogConfirmationSuppressionComponent implements OnInit {

  id: number = 0;

  constructor(  private apiPhrasier : ApiPhrasierService
              , private toast       : PersoSnackbarService) { }

  ngOnInit(): void {    
  }

  onAcceptSuppression(): void {
      this.deletePhrasier(this.id);
  }

  onDeclineSuppression(): void {
    this.toast.showInfo("Suppression annulé");
  }

    // Suppression d'un phrasier
    public deletePhrasier(id: number) : void { 
      this.apiPhrasier.deletePhrasier(id)
        .subscribe(
          (data: any) => { 
            this.toast.showInfo("Phrasier supprimé");
          },
          (err: any) => { '/!\\ error deletePhrasier: ' + console.log(err) }
        );
    }
}
