import { Component, OnInit } from '@angular/core';
import { Contenu, ContenuInit, FiltreContenu, Operation } from '../../interfaces/contenu';
import { EmailFacadeService, Result, ResultInit } from '../../facades/email-facade.service';
import { BehaviorSubject, Observable, merge } from 'rxjs';
import { scan } from 'rxjs/operators';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { BaliseMail } from '../../interfaces/balise';
import { EditEmailComponent } from '../edit-email/edit-email.component';
import { NgIf } from '@angular/common';
import { ListEmailComponent } from '../list-email/list-email.component';
import { FilterEmailComponent } from '../filter-email/filter-email.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-shell-email',
    templateUrl: './shell-email.component.html',
    styleUrls: ['./shell-email.component.scss'],
    standalone: true,
    imports: [FilterEmailComponent, ListEmailComponent, NgIf, EditEmailComponent]
})
export class ShellEmailComponent implements OnInit {

  operations$       : Observable<Operation[]>   = this.emailFacade.operations$
  operations        : Operation[]=[]
  operationsLeft    : Operation[] = []
  emails$           : Observable<Contenu[]>     = this.emailFacade.emails$ 
  emailSelected!    : Contenu
  modeEdit          : boolean                   = false
  resultSave$       : Observable<Result>        = this.emailFacade.resultSaveAction$ 
  resultSearch$     : Observable<boolean>       = this.emailFacade.resultSearchAction$
  balises$          : Observable<BaliseMail[]>  = this.emailFacade.balises$ 
  waitingSave       : boolean                   = false
  waitingSearch     : boolean                   = false
  waitingManuel$    : BehaviorSubject<boolean>  = new BehaviorSubject<boolean>(false)
  waiting$          = merge  
                    (
                          this.waitingManuel$,
                          this.operations$
                            ).pipe(scan((acc,value)=>{
                              if (value instanceof Array){
                                return false
                              }else{
                                return value as boolean
                              }
                            },false)) 
  currentElement: string='' ;
  constructor(private emailFacade :EmailFacadeService,
              private apiToast : PersoSnackbarService
              , private activatedRoute: ActivatedRoute
              , private router      : Router) { }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe(url => {
      
      const elements    = this.router.url.split('/');
      this.currentElement = elements[elements.length - 1];
      
      switch (this.currentElement) {
        
        case 'emails':
          this.modeEdit = false
          break;
        
          case 'edit':
          this.modeEdit = true
          break;

        default:
          break;
      }
    });

    this.loadOperations()
    
    this.resultSave$.subscribe(
      (result:Result)=> {
        this.modeEdit = false
        this.router.navigate(['app/administration-extranet/emails']);
        this.waitingSave = false
        if (result.result === true) {
          this.apiToast.showInfo('Le mail a bien été enregistré')
          this.loadOperations()
        }else{
          this.apiToast.showError('Suite à un problème technique, le mail n\'a pas pu être enregistré')
        }
      }
      
    )

    this.resultSearch$.subscribe(
      ()=>{
        this.waitingSearch = false
      }
    )
  }

  loadOperations(){
    this.operations$.subscribe(
      (operations:Operation[])=> {
        this.operations = operations
      }
    )
  }

  applyFilter(filtre:FiltreContenu){
    this.waitingSearch = true
    this.emailFacade.filter(filtre)
  }

  editContenu(contenu:Contenu){
    this.modeEdit       = true
    this.router.navigate(['app/administration-extranet/emails/edit']);
    this.emailSelected  = contenu
    this.setOperationsLeft()
  }

  saveContenu(contenu:Contenu | null){
    if (!contenu) {
      this.modeEdit = false
      this.router.navigate(['app/administration-extranet/emails']);
    }else{
      this.waitingSave = true

      if (contenu.id===0) {
        this.emailFacade.add(contenu)
      }else{
        this.emailFacade.modify(contenu)
      }
    }
    
  }

  setOperationsLeft(){
    this.operationsLeft = this.operations.filter((ope:Operation) => ope.contenu?.id===0 || ope.contenu?.id === this.emailSelected?.id)
  }

  createMail(){
    this.setOperationsLeft()
    this.emailSelected = ContenuInit
    this.modeEdit = true
    this.router.navigate(['app/administration-extranet/emails/edit']);
  }
}
