import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatFormFieldControl, MatFormFieldModule } from '@angular/material/form-field';
import { ApiRelUtilisateurEntiteService } from 'src/app/services/api-rel-utilisateur-entite.service';
import { RelationUtilisateurEntite } from 'src/app/interfaces/utilisateur-entite/relation-utilisateur-entite';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { EntiteServiceExterneComponent } from '../entite-service-externe/entite-service-externe.component';
import { EntiteServiceComponent } from '../entite-service/entite-service.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';

@Component({
    selector: 'app-fiche-entite-service',
    templateUrl: './fiche-entite-service.component.html',
    styleUrls: ['./fiche-entite-service.component.scss'],
    standalone: true,
    imports: [MatToolbarModule, MatFormFieldModule, NgIf, MatIconModule, MatTabsModule, EntiteServiceComponent, EntiteServiceExterneComponent]
})
export class FicheEntiteServiceComponent implements OnInit {

  ent_id: number = 0;
  Entite$: Entite = <Entite>{};
  isDirigeant: boolean = false;

  constructor(
    private routed: ActivatedRoute
    , private apiEntite: ApiEntiteService
    , private apiAuth: ApiAuthentificationService
    , private apiRelUtilisateurEntite: ApiRelUtilisateurEntiteService
    , public _sanitizer       : DomSanitizer
  ) { }

  ngOnInit(): void {
    this.ent_id = this.routed.snapshot.params['ent_id'];
    //
    this.loadEntite();
    this.askIfDirigeant();
  }
  
  private loadEntite() {
    // chargement de l'entite
    this.apiEntite.getEntiteById(this.ent_id)
    .subscribe(
      (data: Entite[]) => {
        this.Entite$ = data[0];
      },
      err => { console.log(err.message) }
    )
  }

  private askIfDirigeant() {
    this.apiRelUtilisateurEntite.getRelUtilisateurEntite(this.apiAuth.userConnect.id, this.ent_id)
    .subscribe( 
      (data: RelationUtilisateurEntite[]) => {
        this.isDirigeant = ( data[0].est_dirigeant === true && data[0].est_active === true )
      },
      (err) => { console.log(err.message) }
    )
  }

}
