import { Injectable } from '@angular/core';
import { EmailDataAccesService } from '../services/data-acces.service';
import { Subject, merge } from 'rxjs';
import { Contenu, FiltreContenu } from '../interfaces/contenu';
import { mergeMap, scan, startWith } from 'rxjs/operators';
import { CST_ACTION_ADD, CST_ACTION_MODIFY } from 'src/app/interfaces/constantes';
import { BaliseDataAccesService } from '../services/balise-data-access.service';

export interface Result{
  action  : string
  result  : boolean
}
export const ResultInit:Result = {"action":'',"result":false}
@Injectable({
  providedIn: 'root'
})
export class EmailFacadeService {

  constructor(private apiEmail  : EmailDataAccesService,
              private apiBalise : BaliseDataAccesService) { }

  private resultSaveSubject   = new Subject<Result>()
  resultSaveAction$           = this.resultSaveSubject.asObservable()

  private resultSearchSubjet  = new Subject<boolean>()
  resultSearchAction$         = this.resultSearchSubjet.asObservable()

  private emailAddedSubject    = new Subject<Contenu>()
  emailAddedAction$            = this.emailAddedSubject.asObservable()

  private emailModifiedSubject = new Subject<any>()
  emailModifiedAction$         = this.emailModifiedSubject.asObservable()

  private emailFiltreSubject   = new Subject<FiltreContenu>()
  emailFiltreAction$           = this.emailFiltreSubject.asObservable().pipe(mergeMap(
    (filtre:FiltreContenu)=> {
      return this.apiEmail.getEmails(filtre)
    }
  ))

  balises$    = this.apiBalise.getBalises()

  operations$ = this.apiEmail.getOperations()

  emails$   = merge(
    this.emailFiltreAction$,
    this.emailAddedAction$,
    this.emailModifiedAction$
  ).pipe(
    startWith([])
    ,scan((acc,value)=>{
      if (value instanceof Array) {
        {
          this.resultSearchSubjet.next(true)
          return [...value] as Contenu[]
        }
      }else{
        if (value.id === 0) {
          //We add the email
          return [...acc as Contenu[],value] as Contenu[]
        }else{
          //We modify the email
          return (acc as Contenu[]).map((contenu:Contenu)=> contenu.id===value.id? value : contenu) as Contenu[]
        }
      }
    })
  )

  add(contenu:Contenu){
    this.apiEmail.postContenu(contenu).subscribe(
       (newContenu)=>{
        let result:Result=ResultInit
        result.action = CST_ACTION_ADD
        if (newContenu !=false) {
          result.result = true
          this.emailAddedSubject.next(newContenu as Contenu) 
          this.resultSaveSubject.next(result)
        }else{
          this.resultSaveSubject.next(result)
        }
       }
       ,(error)=>console.log(error)
       
    )    
  }

  modify(contenu:Contenu){
    this.apiEmail.putContenu(contenu).subscribe(
      (modifiedContenu)=>{
        let result:Result=ResultInit
        result.action = CST_ACTION_MODIFY
        if (modifiedContenu !=false) {
          result.result = true
          this.emailModifiedSubject.next(modifiedContenu as Contenu)
          this.resultSaveSubject.next(result)
        }else{
          this.resultSaveSubject.next(result)
        }
      }
    )
  }

  filter(filtreContenu:FiltreContenu){
    this.emailFiltreSubject.next(filtreContenu)
  }

}
