import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as saveAs from 'file-saver';
import { PersoSnackbarService } from './perso-snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class PieceJointeService {
  
  url:string = 'api'
  
  constructor(private http : HttpClient
            , private toast: PersoSnackbarService) { }

  downloadPieceJointe(idPJ: number,nom:string){
    this.http.get( this.url+'/pj/' + idPJ , { 
            responseType: "blob"
            , headers: {
              'Content-Type': 'application/json'
              , Accept: 'application/octet-stream,application/pdf,image/png,image/jpeg,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'} 
            }).subscribe(
              (data: any) => {
                if(data != undefined) {
                  saveAs(data, nom);
                  this.toast.showInfo('Fichier téléchargé.')
                }
              }
            )

  }

}
