import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject,  Observable,  Subject,  combineLatest,  merge, of, timer } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FactureShellComponent } from '../components/compta/facture/facture-shell/facture-shell.component';
import { ApiAuthentificationService } from '../services/api-authentification.service';

export interface TypeNotification{
  id          : number,
  libelle     : string,
  code        : string,
  entity      : string
}

export interface Notified{
  id            : number,
  userid        : number,
  idnot         : number,
  status        : number,
  notification  : string
}
export interface Notification{
  id         : number,
  idUser     : number,
  idnot?     : number,
  identity?  : number,
  userid?    : number,
  typeentity?:string,
  notifies?  : Array<Notified>
  type?      : TypeNotification
}

export const CST_NOT_ADL_VALIDE         = 'ADL_VALIDE'
export const CST_NOT_ADL_REFUSE         = 'ADL_REFUSE'
export const CST_NOT_ATT_ECHAN          = 'ADL_ATT_ECHANT'
export const CST_NOT_ADL_ANALYSE        = 'ADL_ANALYSE'
export const CST_NOT_ADL_DIAGNOSTIQUE   = 'ADL_DIAGNOSTIQUE'
export const CST_NOT_ADL_CLOTURE        = 'ADL_CLOTURE'
export const CST_NOT_ADL_DEPOSE         = 'ADL_DEPOSE'
export const CST_NOT_MESSAGE            = 'MESSAGE'
export const CST_NOT_COORD_BANC_VALIDE  = 'COORD_BANC_VALIDE'
export const CST_NOT_COORD_BANC_REFUSE  = 'COORD_BANC_REFUSE'
export const CST_NOT_COORD_BANC_A_REMPLIR  = 'COORD_BANC_A_REMPLIR'

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  
  private notificationUrl = 'api/notification';
  manuel$ = new Subject<void>();

  NbLastNotifications : number = 5
  notifications$ = merge(timer(0, 600000),this.manuel$).pipe(
    mergeMap(() => this.apiAuth.userConnect.id>0? this.http.get<Notification[]>(this.notificationUrl) : of([]))
    ) as Observable<Notification[]>
    
  notificationRemovedSubject  = new BehaviorSubject<Number>(0)
  notificationRemovedAction$  = this.notificationRemovedSubject.asObservable()
  
  notificationsLeft$ = combineLatest([
    this.notifications$,
    this.notificationRemovedAction$
  ]).pipe(
    map(([notifications,id])=>{
      
      if (notifications) {
        const index = notifications.findIndex((notification) => notification.id === id)
        if (index > -1) {
          notifications.splice(index,1)
        }
      }
      return notifications
    })
  )

  
  constructor(private http          : HttpClient
              , private router      : Router
              , private apiAuth     : ApiAuthentificationService){}

  removeNotification(notification:Notification){
    const notifiedToUpdate = notification.notifies![0]

    this.readNotification(notifiedToUpdate).subscribe(
      (notifie:Notified)=>{
        this.notificationRemovedSubject.next(notification.id);
      },
      (error)=> console.log(error)
    )
  }

  buildItemMenu(notification:Notification){
    const item  = {"libelle":notification.type?.code}
  }

  readNotification(notifie:Notified):Observable<Notified>{
    return this.http.post<Notified>(this.notificationUrl,notifie)
  }

  getIconeNotification(notification:Notification){
    let icone:string = 'done'
    switch (notification.type?.code) {
      case CST_NOT_ADL_REFUSE:
      case CST_NOT_COORD_BANC_REFUSE:
        icone = 'warning'
        break;
    
      default:
        break;
    }
    return icone
  }

  clickItemMenu(notification:Notification){
    let url:string='';

    switch (notification.type?.code) {
      case CST_NOT_ADL_VALIDE:
      case CST_NOT_ADL_ANALYSE:
      case CST_NOT_ADL_CLOTURE:
      case CST_NOT_ADL_DEPOSE:
      case CST_NOT_ADL_DIAGNOSTIQUE:
      case CST_NOT_ADL_REFUSE:
        switch (notification.typeentity) {
          
          case 'FLUIDE':
            url = 'app/adela/dossier/';
            this.router.navigateByUrl('app/adela/dossier/'+notification.identity);
            break;
          
          case 'POUDRE':
              url = 'app/adela-poudre/dossier/';
            this.router.navigateByUrl('app/adela-poudre/dossier/'+notification.identity);
            break;
          
          default:
            break;
        }
        
        this.router.navigate([url + notification.identity]);
        break;
      case CST_NOT_COORD_BANC_REFUSE:
      case CST_NOT_COORD_BANC_VALIDE:
      case CST_NOT_COORD_BANC_A_REMPLIR:
        //fragment          = "1"
        this.router.navigate(['app/entite/' + notification.identity],{'fragment':'1'});
        break;

      case CST_NOT_MESSAGE:
        this.router.navigateByUrl('app/support/ticket/' + notification.identity);
        break;

      default:
        break;
    }
    
    //on passe la notification à lue:
    this.removeNotification(notification)
    
  }
  changeStateLastNotifications(){
    this.http.get(this.notificationUrl+'/dernieres/'+this.NbLastNotifications).subscribe(
      //We have to get the last notifications
      
      ()=>{
        this.notificationRemovedSubject.next(0)
        this.manuel$.next()},
      (error)=>console.log(error)
      
      
    )
  }

  lireToutesNotifications(){
    this.http.get(this.notificationUrl+'/toutes').subscribe(
      //We have to get the last notifications
      
      ()=>{
        this.notificationRemovedSubject.next(0)
        this.manuel$.next()},
      (error)=>console.log(error)
      
      
    )
  }
}
