import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// interfaces
import { CleAdc } from 'src/app/interfaces/adc/cle-adc';
import { FichierAdc } from 'src/app/interfaces/adc/fichier-adc';
import { FormulaireAdc } from 'src/app/interfaces/adc/formulaire-adc';
import { Compagnie } from 'src/app/interfaces/adela/compagnie';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
//
import { saveAs } from "file-saver";

interface file {
  id: number,
  typeFichier: string,
  categorie: string,
  name: string,
  mimeType: string,
  size: number,
  monFichier: File
}
// services
import { NgClass, NgFor, NgIf, UpperCasePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { ApiAdcService } from 'src/app/services/api-adc.service';
import { ApiAdelaService } from 'src/app/services/api-adela.service';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { ApiUtilisateurService } from 'src/app/services/api-utilisateur.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { ImmatDirective } from '../../../directive/immat.directive';


@Component({
    selector: 'app-analyse-adc',
    templateUrl: './analyse-adc.component.html',
    styleUrls: ['./analyse-adc.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatStepperModule, NgClass, ReactiveFormsModule, MatIconModule, MatFormFieldModule, MatInputModule, NgIf, MatSelectModule, NgFor, MatOptionModule, MatButtonModule, ImmatDirective, MatListModule, MatDividerModule, MatCheckboxModule, MatProgressSpinnerModule, UpperCasePipe]
})
export class AnalyseAdcComponent implements OnInit {

  CONST_CATEGORIE_CG       = 'Carte grise' as const;
  CONST_CATEGORIE_PV       = 'Procés verbal' as const;
  CONST_CATEGORIE_SINISTRE = 'Déclaration de sinistre' as const;
  CONST_CATEGORIE_REMISE   = 'Remise de clé' as const;

  CONST_AUTRE_COMPAGNIE   = 999999 as const;

  allEntite: boolean = false;
  
  // gestion des spinner d'attente de traitement
  waiting_Save      : boolean = false;
  waiting_Confirm   : boolean = false;
  waiting_Refused   : boolean = false;
  waiting_Attente_cle:boolean = false;
  waiting_Analyse   :boolean = false;
  waiting_Diagnostic:boolean = false;
  // mode de saisie du stepper. true => ordonné
  isLinear          : boolean = true;
  // mode de saisie des champs
  isReadOnly        : boolean = false;
  isAdminADC        : boolean = false;
  isRefused         : boolean = false;
  isDiagnostic      : boolean = false;
  isAutreCompagnie  : boolean = false;
  // etape actuelle du dossier
  Actualstatus      : string = '';
  titreEtape        : string = '';

  // Formulaire ADC + formBuilder
  idADC           : number = 0;
  formAdc         : FormulaireAdc = <FormulaireAdc>{};
  formCle         : any;

  // Formulaire de controle des elements HTML
  Etape1FormGroup : any;
  Etape2FormGroup : any;
  Etape3FormGroup : any;
  Etape4FormGroup : any;
  formDEPOSE      : any;        // formulaire validant ou refusant la demande déposé
  formATTENTE_CLE : any;
  formANALYSE     : any;
  formDIAG        : any;
  // Liste des interfaces pour les selecteurs/combos
  profilUtilisateur$  : ProfilUtilisateur = <ProfilUtilisateur>{};
  compagnies$         : Compagnie[] = [];
  entites$            : Entite[] = [];
  cles                : CleAdc[] = [];
  fichiers            : FichierAdc[] = [];
  files               : Array<file> = [];
  typeCles = ['Principale', 'Secondaire']

  // Formulaire Etape 1
  get reference()       { return this.Etape1FormGroup.get('reference'); }
  get numeroSinistre()  { return this.Etape1FormGroup.get('numeroSinistre') }
  get dateSinistre()    { return this.Etape1FormGroup.get('dateSinistre')}
  get compagnie()       { return this.Etape1FormGroup.get('compagnie'); }
  get idCompagnie()       { return this.Etape1FormGroup.get('idCompagnie'); }
  get autreCompagnie()  { return this.Etape1FormGroup.get('autreCompagnie'); }
  get entite()          { return this.Etape1FormGroup.get('entite'); }
  get emailExpert()     { return this.Etape1FormGroup.get('emailExpert'); }
  // Formulaire Etape 2
  get immatriculation() { return this.Etape2FormGroup.get('immatriculation'); }
  get vin()             { return this.Etape2FormGroup.get('vin'); }
  get marqueVehicule()  { return this.Etape2FormGroup.get('marqueVehicule'); }
  get modele()          { return this.Etape2FormGroup.get('modele'); }
  get dateMEC()         { return this.Etape2FormGroup.get('dateMEC'); }
  get kilometrage()     { return this.Etape2FormGroup.get('kilometrage') }
  // Formulaire Etape 3
  get numeroScelle()    { return this.formCle.get('numeroScelle') }
  get typeCle()         { return this.formCle.get('typeCle') }
  get file_cg()         { return this.Etape3FormGroup.get('file_cg') }
  get file_pv()         { return this.Etape3FormGroup.get('file_pv') }
  get file_declaration(){ return this.Etape3FormGroup.get('file_declaration') }
  get file_remisedecle(){ return this.Etape3FormGroup.get('file_remisedecle') }
  // get cles()            { return this.Etape3FormGroup.get('cles'); }
  get commentaire()     { return this.Etape4FormGroup.get('commentaire');}
  get commentaireRefus()     { return this.Etape4FormGroup.get('commentaireRefus');}
  get diagnostic()      { return this.formDIAG.get('diagnostic');}
  get dateReceptionCle()     { return this.Etape4FormGroup.get('dateReceptionCle');}
  get isAccepted()      {return this.Etape4FormGroup.get('isAccepted');}

  //US 329 : blocage si les coordonnées bancaires ne sont pas validées:
  coordBancValidees : boolean = true;

  constructor(
    private _fb       : FormBuilder,
    private authUser  : ApiAuthentificationService,
    public  apiAdc    : ApiAdcService,
    private apiAuth   : ApiAuthentificationService,
    public apiEntite : ApiEntiteService,    

    private apiUser   : ApiUtilisateurService,
    private toast     : PersoSnackbarService,
    private route     : ActivatedRoute,
    private router    : Router,
    private apiAdela  : ApiAdelaService
  ) {

  }

  ngOnInit(): void {
    //
    this.profilUtilisateur$ = this.authUser.userConnect;
    this.isReadOnly = ( this.profilUtilisateur$.droits_utilisateur.droit_module_admin_adc);
    this.isAdminADC = ( this.profilUtilisateur$.droits_utilisateur.droit_module_admin_adc);

    this.idADC        = this.route.snapshot.params['idDossier']
    this.Actualstatus = this.route.snapshot.params['status']

    // Cas où l'on accède au component pour visualiser tout le dossier
    // On affiche toutes les entités, même celles désactivé, pour avoir une visualisation de l'entité
    if(this.idADC > 0) {
      this.allEntite = true;
    }

    //[1.] : Informations dossier
    this.Etape1FormGroup = this._fb.group({
      reference:      [{value: '', disabled: this.isReadOnly},                    [Validators.required, this.verificationFormatReference]],
      numeroSinistre: [{value: '', disabled: this.isReadOnly},                    [Validators.required, this.verificationFormatNumeroSinistre]],
      dateSinistre:   [{value: '', disabled: this.isReadOnly},                    [Validators.required, this.verificationDateSinistre.bind(this)]],
      idCompagnie:    [{value: 0, disabled: this.isReadOnly},                     [Validators.required]],
      compagnie:      [{value: <Compagnie>{}, disabled: this.isReadOnly},         [this.CheckSelectionCompagnie.bind(this)]],
      autreCompagnie: [{value: '', disabled: this.isReadOnly},                    [this.verificationFormatAutreCompagnie.bind(this)]],
      entite:         [{value: <Entite>{}, disabled: this.isReadOnly},            [this.CheckSelectionCabinet.bind(this)]],
      utilisateur:    [{value: <ProfilUtilisateur>{}, disabled: this.isReadOnly}],
      emailExpert:    [{value: '', disabled: this.isReadOnly},                    [Validators.required]]
    });
    //[2.] : Informations Véhicule
    this.Etape2FormGroup = this._fb.group({
      immatriculation     : [{value: '', disabled: this.isReadOnly}, [Validators.required, this.verificationFormatImmatriculation]],
      vin                 : [{value: '', disabled: this.isReadOnly}, [Validators.required, this.verificationFormatVIN]],
      marqueVehicule      : [{value: '', disabled: this.isReadOnly}, [this.verificationFormatMarqueVehicule.bind(this)]],
      modele              : [{value: '', disabled: this.isReadOnly}, [this.verificationFormatModele.bind(this)]],
      dateMEC             : [{value: '', disabled: this.isReadOnly}, [this.verificationFormatMEC.bind(this)]],
      kilometre           : [{value: '', disabled: this.isReadOnly}]
    });
    
    //[3.] : Liste des clé et documents
    this.Etape3FormGroup = this._fb.group({
      formCle: [],
      cles: this._fb.array([]),
      file_cg: [{value: '', disabled: this.isReadOnly}, [Validators.required]],
      file_pv: [{value: '', disabled: this.isReadOnly}],
      file_declaration: [{value: '', disabled: this.isReadOnly}],
      file_remisedecle: [{value: '', disabled: this.isReadOnly}, [Validators.required]]
    });
    // [4.] : Commentaire de l'expert et acceptation des conditions
    this.Etape4FormGroup = this._fb.group({
      commentaire     : [{value: '', disabled: this.isReadOnly}, [Validators.required]],
      commentaireRefus: [{value: '', disabled: !this.isReadOnly}],
      isAccepted      : [{value: false, disabled: this.isReadOnly}, [Validators.required, this.CheckAcceptation.bind(this)]]
    });

    // Formualire de clé
    this.formCle = this._fb.group({
      numeroScelle: ['', [Validators.required]],
      typeCle     : ['', [Validators.required]]
    })

    // [5.] Validation ou Refus du dossier
    this.formDEPOSE = this._fb.group({
      commentaireRefus: ['', [this.checkUpdateDEPOSE.bind(this)]]
    })
    // [6.] Réception des clés
    this.formATTENTE_CLE = this._fb.group({
      dateReceptionCle: ['', [Validators.required]]
    })
    // // [6.] Réception des clés
    // this.formANALYSE = this._fb.group({
    //   diganostic: ['', [Validators.required]]
    // })
    // [6.] Réception des clés
    this.formDIAG = this._fb.group({
      diagnostic: ['', [Validators.required]]
    })
    
    // Chargement des combo
    this.ChargeListeCompagnie();
    this.chargeUtilisateur();
    this.chargeListeCabinet();
    
    this.isLinear = ( this.profilUtilisateur$.droits_utilisateur.droit_module_admin_adc)
    // Chargement d'un formulaire ADC
    if ( this.idADC > 0 && ( this.profilUtilisateur$.droits_utilisateur.droit_module_admin_adc) ) {
      this.isLinear   = false;
      console.info('isLinear: '+this.isLinear, 'isReadOnly: '+this.isReadOnly, 'isAdminADC:' +this.isAdminADC);
      // Est-ce m'administrateur ADC qui accede ?
      if ( !this.profilUtilisateur$.droits_utilisateur.droit_module_admin_adc && !this.profilUtilisateur$.droits_utilisateur.est_admin ) {
        this.router.navigate(['/app/dossier/mes-dossiers']);
      }
      // Chargement du formulaire ADC
      this.chargeFicheADC();
    } else {
      // Nouveau formulaire : remplissage des valeurs
      this.isLinear   = true;
      this.Etape1FormGroup.patchValue({
        emailExpert: this.apiAuth.authUser.login
      });
    }
  }

  //
  public verificationFormatReference(fcControler: FormControl): any {
    let reference: string = fcControler.value;
    let a_regex: any = /^[0-9A-Z]{1,24}$/;

    if (!a_regex.test(reference)) {
      return { erreur: 'Référence de dossier invalide. 24 caractères maximum (Lettre ou chiffre). Aucun symbole accepté.' }
    }

    return null;
  }
  //
  public checkUpdateDEPOSE(fcControler: FormControl): any {
    if (this.isRefused && this.formDEPOSE.value.commentaireRefus == '' && this.formDEPOSE.value.commentaireRefus.length > 5) {
      return { erreur: 'Le motif de refus est obligatoire'}
    }
    return null;
  }
  //
  public verificationFormatNumeroSinistre(fcControler: FormControl): any {
    let reference: string = fcControler.value;
    let a_regex: any = /^[0-9A-Z]{1,50}$/;

    if (!a_regex.test(reference)) {
      return { erreur: 'Numéro de sinistre invalide. 50 caractères maximum (Lettre ou chiffre). Aucun symbole accepté.' }
    }

    return null;
  }
  //
  public verificationFormatAutreCompagnie(fcControler: FormControl): any {
    let autreCompagnie: string = fcControler.value;

    if (this.Etape1FormGroup == undefined) {
      return null;
    }

    if (autreCompagnie == '' && this.Etape1FormGroup.controls.compagnie.value.id == this.CONST_AUTRE_COMPAGNIE) {
      return { erreur: 'Saisie obligatoire' }
    }

    return null;
  }
  //
  public verificationFormatImmatriculation(fcControler: FormControl): any {
    let plaque: string = fcControler.value;
    let regex: any = /^[0-9A-Z\-\ ]{1,10}$/;

    if (!regex.test(plaque)) {
      return { erreur: 'Saisie obligatoire : immatriculation limité à 10 caractères' }
    }

    return null;
  }
  //
  public verificationFormatVIN(fcControler: FormControl): any {
    let vin: string = fcControler.value;
    let regex: any = /^[0-9A-Z]{17}$/;

    if (!regex.test(vin)) {
      return { erreur: 'Saisie obligatoire : n° VIN de 17 caractères' }
    }

    return null;
  }
  //
  public verificationFormatMEC(fcControler: FormControl): any {
    let MEC: string = fcControler.value;

    if (MEC == '') {
      return { erreur: 'Saisie obligatoire : date de mise en circulation du véhicule' }
    }

    let dateMEC : Date = new Date(fcControler.value);
    let dateToday       : Date = new Date();
    
    dateMEC.setHours(0,0,0,0); 

    if(dateMEC.getTime() > dateToday.getTime()){
      return { erreur: 'La date de mise en circulation doit être égale ou inférieure à la date du jour' }
    }

    return null;
  }
  //
  public verificationDateSinistre(fcControler: FormControl): any {
    let date: string = fcControler.value;

    if (date == '') {
      return { erreur: 'Saisie obligatoire : date de sinistre. Cette date doit être inférieure ou égale à la date du jour' }
    }

    let dateSinistre : Date = new Date(fcControler.value);
    let dateToday       : Date = new Date();
    
    dateSinistre.setHours(0,0,0,0); 

    if(dateSinistre.getTime() > dateToday.getTime()){
      return { erreur: 'La date de sinistre doit être égale ou inférieure à la date du jour' }
    }

    return null;
  }
  //
  public verificationFormatMarqueVehicule(fcControler: FormControl): any {
    let marque: string = fcControler.value;
    let regex: any = /^[A-Z0-9\ \-\.]{1,50}$/;

    if (!regex.test(marque)) {
      return { erreur: 'Saisie obligatoire : marque du véhicule (limité à 50 caractères)' }
    }
    return null;
  }
  //
  public verificationFormatModele(fcControler: FormControl): any {
    let modele: string = fcControler.value;
    let regex: any = /^[A-Z0-9\ \-\.]{1,50}$/;

    // if(this.Etape1FormGroup == undefined) {
    //   return null
    // }

    if (!regex.test(modele)) {
      return { erreur: 'Saisie obligatoire : modèle du véhicule (limité à 50 caractères)' }
    }
    return null;
  }
  //
  public CheckAcceptation(fcControler: FormControl): any {
    let checked: boolean = fcControler.value;

    if (!checked) {
      return { erreur: 'Vous devez acceptez les conditions d\'envoi.' }

    } else {
      return null;
    }
  }
  //
  public CheckSelectionCompagnie(): any {
    if(this.Etape1FormGroup == undefined){
      return null;
    }
    
    if (this.Etape1FormGroup.controls.compagnie.value.id == undefined) {      
      return { erreur: 'Sélectionnez la compagnie' }
    } else {
      if(this.Etape1FormGroup.controls.compagnie.value.id <= 0) {
        return { erreur: 'Sélectionnez la compagnie'}
      } else {
        return this.verificationFormatAutreCompagnie(this.Etape1FormGroup.controls.autreCompagnie.value);
      }
    }
  }
  public CheckSelectionCabinet(): any {
    if(this.Etape1FormGroup == undefined || this.Etape1FormGroup.controls.entite.value == undefined){
      return null;
    }
    
    if (this.Etape1FormGroup.controls.entite.value.id == undefined) {      
      return { erreur: 'Sélectionnez le cabinet d\'expertise' }
    } else {
      if(this.Etape1FormGroup.controls.entite.value.id <= 0) {
        return { erreur: 'Sélectionnez le cabinet d\'expertise'}
      } else {
        return this.verificationFormatAutreCompagnie(this.Etape1FormGroup.controls.entite.value);
      }
    }
  }
  // Sélection d'une compagnie d'assurance
  public onSelectChangeCompagnie(newSelect: any): void {
    // on pousse la valeur sélectionné dans le form
    this.Etape1FormGroup.patchValue({
      idCompagnie: newSelect.value.id
    });
    this.isAutreCompagnie = (newSelect.value.id == this.CONST_AUTRE_COMPAGNIE);
    if (newSelect.value.id != this.CONST_AUTRE_COMPAGNIE) {
      this.Etape1FormGroup.patchValue({
        autreCompagnie: '',
      });
    }
  }
  // Chargement de la liste des compagnies d'assurrance
  public ChargeListeCompagnie() {
    this.apiAdc.getCompagnie()
      .subscribe(
        (data: Compagnie[]) => {
          let compagnieAutre: Compagnie = {
            id: 999999,
            code_gta: '000',
            nom: 'Autre'
          };

          this.compagnies$ = data;
          this.compagnies$.push(compagnieAutre);

          // Trie du tableau par code GTA croissant
          this.compagnies$ = this.compagnies$.sort((a, b) => {
            if (a.code_gta < b.code_gta) { return -1; }
            if (a.code_gta > b.code_gta) { return 1; }
            return -1;
          });
        },
        err => {
          this.compagnies$ = [];
          console.error('/!\\ err: ' + err);
        }
      );
  }

  public chargeListeCabinet(): void {
    
    if(this.idADC > 0) {
      this.apiEntite.getEntitesByIdUtilisateur(this.Etape1FormGroup.controls.utilisateur.value.id)
      .subscribe(
        (data) => { 
          this.entites$ = data;

          this.Etape1FormGroup.patchValue({
            entite: this.entites$.find( (element) => ( element.id == this.formAdc.idEntite ))
          });
        }
      );
    } else {
      for(let i = 0; i < this.authUser.relUserEnt.length; i++) {
        if(this.authUser.relUserEnt[i].est_active) {
          this.entites$.push(this.authUser.relUserEnt[i].entite);
        }            
      }

      this.Etape1FormGroup.patchValue({
        entite: this.entites$.find( (element) => ( element.id == this.formAdc.idEntite ))
      });
    }
 
  } 

  public chargeUtilisateur(): void {
    this.apiUser.getUtilisateur(this.profilUtilisateur$.id)
      .subscribe(
        (data: ProfilUtilisateur) => {
          this.profilUtilisateur$ = data;
        },
        (err) => { console.error('/!\\ error getAnalyse: ' + err); }
      );
  }

  // Chargement du dossier ADC passé en parametre
  public chargeFicheADC(): void {

    this.waiting_Save = true;

    let fileADC: Array<FormulaireAdc> = [];
    this.apiAdc.getFormulaireAdc(this.idADC)
    .subscribe(
      (data : FormulaireAdc[]) => {

        fileADC = data;
        this.formAdc = data[0];
        this.formAdc.idCompagnie = data[0].idCompagnie;
        // formulaire introuvable
        if ( data === undefined || data.length < 1 ) {
          this.toast.showError('Ce formulaire est introuvable');
          this.router.navigate(['/app/dossier/mes-dossiers']);
        }
        // formulaire dans un état different
        if ( fileADC[0].etat.code != this.Actualstatus ) {
          this.toast.showError('Le status du formulaire est incorrect');
          this.router.navigate(['/app/dossier/mes-dossiers']);
        }

        // [1. dossier]
        this.isAutreCompagnie = ( fileADC[0].idCompagnie == 0 );
        this.Etape1FormGroup.patchValue({
          reference:        fileADC[0].referenceDossierExpert,
          numeroSinistre:   fileADC[0].numeroSinistre,
          dateSinistre:     fileADC[0].dateSinistre.split('T')[0],
          idCompagnie:      fileADC[0].idCompagnie,
          compagnie:        fileADC[0].compagnie,
          entite:           this.entites$.find( (element) => ( element.id == this.formAdc.idEntite )),
          autreCompagnie:   fileADC[0].autreCompagnie,
          emailExpert:      fileADC[0].utilisateur.email,
          utilisateur:      fileADC[0].utilisateur
        });
        if ( fileADC[0].idCompagnie === this.CONST_AUTRE_COMPAGNIE ) {
          this.Etape1FormGroup.patchValue({ compagnie:        this.compagnies$.find( (element) => ( element.id == this.CONST_AUTRE_COMPAGNIE )) })
        } else {
          this.Etape1FormGroup.patchValue({ compagnie:        this.compagnies$.find( (element) => ( element.id == fileADC[0].idCompagnie )) })
        }
        // [2. VEHICULE] (optionnel)
        this.Etape2FormGroup.patchValue({
          immatriculation: fileADC[0].immatriculation,
          vin:             fileADC[0].vin,
          marqueVehicule:  fileADC[0].marque,
          modele:          fileADC[0].modele,
          dateMEC:         fileADC[0].dateMEC.split('T')[0],
          kilometre:       fileADC[0].kilometre,
        })
        // [3. CLE + FICHIERS]
        this.cles = fileADC[0].tab_Cle;
        this.fichiers = fileADC[0].tab_Fichier;
        this.fichiers.forEach( (element: FichierAdc) => {
          let categorie: string = element.name.split('-')[2]
          switch (categorie) {
            case this.CONST_CATEGORIE_CG:
              this.Etape3FormGroup.patchValue({file_cg: element.name})
              break;
            case this.CONST_CATEGORIE_REMISE:
              this.Etape3FormGroup.patchValue({file_remisedecle: element.name})
              break;
            case this.CONST_CATEGORIE_SINISTRE:
              this.Etape3FormGroup.patchValue({file_declaration: element.name})
              break;
            case this.CONST_CATEGORIE_PV:
              this.Etape3FormGroup.patchValue({file_pv: element.name})
              break;
            default:
              break;
          }
        });
        // [4. COMMENTAIRE / EXPLICATION DE LA DEMANDE]
        this.Etape4FormGroup.patchValue({
          commentaire:      fileADC[0].commentaire
        })
        this.Etape1FormGroup.controls['reference'].disabled;
        this.formDIAG.patchValue({
          diagnostic: this.formAdc.reponse
        })
        // // on ajoute le tableau de fichier à uploader.
        this.titreEtape = this.formAdc.etat.libelle;
        switch (this.Actualstatus) {
          case this.apiAdc.CONST_STATUS_ADC_1_DEPOSE:
            // Selon le status du dossier on active des requirements pour certains champs (ici le commentaire de refus)
            //this.Etape4FormGroup.setControl( 'commentaireRefus', ['', Validators.required]);
            break;
          case this.apiAdc.CONST_STATUS_ADC_5_DIAGNOSTIC:
            this.isDiagnostic = true;
            break;
          default:
            break;
        }
      }
      , err => {
        console.log(err);
      }
      , () => {}
    )
    this.waiting_Save = false;
  }
  // Affect formulaire to data
  public bindADCFormulaire(): void {
    // 1.
    this.formAdc.referenceDossierExpert = this.Etape1FormGroup.controls.reference.value;
    this.formAdc.numeroSinistre = this.Etape1FormGroup.controls.numeroSinistre.value;
    this.formAdc.dateSinistre = this.Etape1FormGroup.controls.dateSinistre.value;
    if (this.Etape1FormGroup.controls.compagnie.value.id == this.CONST_AUTRE_COMPAGNIE) {
      this.formAdc.idCompagnie = 0
      this.formAdc.autreCompagnie = this.Etape1FormGroup.controls.autreCompagnie.value;
    } else {
      this.formAdc.idCompagnie = this.Etape1FormGroup.controls.compagnie.value.id;
      this.formAdc.autreCompagnie = '';
    }
    this.formAdc.idEntite = this.Etape1FormGroup.controls.entite.value.id;
    this.formAdc.entite = this.Etape1FormGroup.controls.entite;
    this.formAdc.habilitation = this.Etape1FormGroup.controls.entite.value.habilitation;
    this.formAdc.idUtilisateur = this.profilUtilisateur$.id;
    // [2. VEHICULE] (optionnel)
    this.formAdc.immatriculation = this.apiAdela.getImmatNonFormated(this.Etape2FormGroup.controls.immatriculation.value);
    this.formAdc.vin = this.Etape2FormGroup.controls.vin.value;
    this.formAdc.marque = this.Etape2FormGroup.controls.marqueVehicule.value;
    this.formAdc.modele = this.Etape2FormGroup.controls.modele.value;
    this.formAdc.dateMEC = this.Etape2FormGroup.controls.dateMEC.value;
    this.formAdc.kilometre = this.Etape2FormGroup.controls.kilometre.value;
    // [3. COMMENTAIRE / EXPLICATION DE LA DEMANDE]
    // on ajoute la liste de clé
    this.formAdc.tab_Cle = this.cles;

    // on ajoute le tableau de fichier à uploader.
    this.formAdc.tab_Fichier = [];
    this.files.forEach(element => {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        // Ajout des informations du tableau dans le fichier JSON
        this.formAdc.tab_Fichier.push({ id: 0, 'typeFichier':'', 'categorie':'', name: element.name, contenu: e.target.result })
        // console.info(e.target.result);
      };
      reader.readAsDataURL(element.monFichier);
    })
    this.formAdc.commentaire = this.Etape4FormGroup.controls.commentaire.value;
  }
  // Sauvegarde du formulaire
  public saveData(): void {
    this.waiting_Save = true;

    // 1.
    this.formAdc.referenceDossierExpert = this.Etape1FormGroup.controls.reference.value;
    this.formAdc.numeroSinistre = this.Etape1FormGroup.controls.numeroSinistre.value;
    this.formAdc.dateSinistre = this.Etape1FormGroup.controls.dateSinistre.value;
    if ( this.Etape1FormGroup.controls.autreCompagnie.value != '') {
      this.formAdc.idCompagnie = 0
      this.formAdc.autreCompagnie = this.Etape1FormGroup.controls.autreCompagnie.value;
    } else {
      this.formAdc.idCompagnie = this.Etape1FormGroup.controls.compagnie.value.id;
      this.formAdc.autreCompagnie = '';
    }
    this.formAdc.idEntite = this.Etape1FormGroup.controls.entite.value.id;
    this.formAdc.habilitation = this.Etape1FormGroup.controls.entite.value.habilitation;
    this.formAdc.idUtilisateur = this.profilUtilisateur$.id;
    // [2. VEHICULE] (optionnel)
    this.formAdc.immatriculation = this.apiAdela.getImmatNonFormated(this.Etape2FormGroup.controls.immatriculation.value);
    this.formAdc.vin = this.Etape2FormGroup.controls.vin.value;
    this.formAdc.marque = this.Etape2FormGroup.controls.marqueVehicule.value;
    this.formAdc.modele = this.Etape2FormGroup.controls.modele.value;
    this.formAdc.dateMEC = this.Etape2FormGroup.controls.dateMEC.value;
    this.formAdc.kilometre = this.Etape2FormGroup.controls.kilometre.value;
    // [3. LES CLES]
    // on ajoute la liste de clé
    this.formAdc.tab_Cle = this.cles;
    // [4. COMMENTAIRE / ACCEPTION]
    this.formAdc.commentaire = this.Etape4FormGroup.controls.commentaire.value;

    // on ajoute le tableau de fichier à uploader.
    this.formAdc.tab_Fichier = [];
    if ( this.files.length === 0 ) {
      this.apiAdc.saveFormulaireAdc(this.formAdc)
            .subscribe(
              (data) => {
                this.toast.showInfo("Votre demande d'analyse a bien été prise en compte.")
                this.router.navigate(['/app/home']);
              },
              (err) => {
                console.error('/!\\ error enregistrerAnalyse: ' + err)
                this.waiting_Save = false;
              }
            )
    } else {
      this.files.forEach((element, index) => {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          // Ajout des informations du tableau dans le fichier JSON
          this.formAdc.tab_Fichier.push({ id: 0, 'typeFichier':'', 'categorie':'', name: element.name, contenu: e.target.result })
          // Si tous les fichier sont compris dans le tableau...
          if (index === (this.files.length - 1)) {

            this.apiAdc.saveFormulaireAdc(this.formAdc)
              .subscribe(
                (data) => {
                  this.toast.showInfo("Votre demande d'analyse a été déposée.")
                  this.waiting_Save = false;
                  // this.router.navigate(['/app/home']);
                },
                (err) => {
                  console.error('/!\\ error enregistrerAnalyse: ' + err)
                  this.waiting_Save = false;
                }
              )
          }
          // console.info(e.target.result);
        };
        reader.readAsDataURL(element.monFichier);
      })
    }
  }
  // Update du formulaore : 
  public updateValid(): void {
    this.waiting_Confirm = true;
    //...
    this.apiAdc.setStatus(this.idADC, this.formAdc.idUtilisateur, this.formAdc.referenceDossierExpert, this.formAdc.immatriculation, this.formAdc.habilitation, this.apiAdc.CONST_STATUS_ADC_2_VALIDE, '')
    .subscribe(
      (res) => {
        this.toast.showInfo("Votre demande d'analyse a bien été prise en compte.")
        this.waiting_Confirm = false;
        this.router.navigate(['/app/dossier/mes-dossiers']);
      },
      (err) => {
        console.error('/!\\ error enregistrerAnalyse: ' + err)
        this.waiting_Confirm = false;
      },
      () => { this.waiting_Confirm = false; }
    )
  }
  // Mise à jour du formulaire pour un refus
  public updateRefuse(): void {
    this.waiting_Refused = true;

    this.apiAdc.setStatus(this.idADC, this.formAdc.idUtilisateur, this.formAdc.referenceDossierExpert, this.formAdc.immatriculation, this.formAdc.habilitation, this.apiAdc.CONST_STATUS_ADC_9_REFUSE, this.formDEPOSE.value.commentaireRefus)
    .subscribe(
      data => {
        this.waiting_Refused = false;
        this.toast.showInfo('Votre refus est enregistré');
        this.router.navigate(['/app/dossier/mes-dossiers']);
        
      },
      err => {
        this.waiting_Refused = false;
        console.log(err)
      }
    )
  }
  // Mise à jour de la date de réception des clés
  public updateAttenteCle(): void {
    this.waiting_Attente_cle = true;
    this.apiAdc.setDateReceptionCle( this.formAdc.id, this.formATTENTE_CLE.value.dateReceptionCle)
    .subscribe(
      data => {
        this.waiting_Attente_cle = false;
        this.toast.showInfo('Date de réception enregistrée');
        this.router.navigate(['/app/dossier/mes-dossiers']);
      },
      err => {
        this.waiting_Attente_cle = false;
        console.log(err)
      }
    )
  }
  // Mise à jour de l'analyse'
  public updateDiagnostic(): void {
    this.waiting_Analyse = true;
    this.apiAdc.setStatus(this.idADC, this.formAdc.idUtilisateur, this.formAdc.referenceDossierExpert, this.formAdc.immatriculation, this.formAdc.habilitation, this.apiAdc.CONST_STATUS_ADC_6_CLOTURE, '', this.formDIAG.value.diagnostic)
    .subscribe(
      data => {
        this.waiting_Analyse = false;
        this.toast.showInfo('Diagnostic enregistré');
        this.router.navigate(['/app/dossier/mes-dossiers']);
      },
      err => {
        this.waiting_Analyse = false;
        console.log(err)
      }
    )
  }
  // combo de type de clé
  public onSelectTypeCle(newSelect: any): void {
    this.formCle.patchValue({
      typeCle: this.formCle.controls.typeCle.value
    });
  }
  // Ajout une clé au tableau de clé
  public addKey(): void {
    let newKey: CleAdc = <CleAdc>{};
    newKey.id = 0;
    newKey.numeroScelle = this.formCle.controls.numeroScelle.value;
    newKey.typeCle = this.formCle.controls.typeCle.value;
    this.cles.push(newKey);
    // raz des champs
    this.formCle.patchValue({
      numeroScelle: '',
      typeCle: ''
    })
  }

  // fermeture de la page: retour à la recherche de dossier
  public close() {
    this.router.navigate(['/app/dossier/mes-dossiers']);
  }

  // sélection d'un fichier local à destination de l'API
  public addFileToQueue(e: any, type: string) {
    // suppression des fichiers de meme categorie 
    // attention, un seul fichier est autortisé par catégorie La catégorie est défini dans le html
    for (let i = this.files.length - 1; i >= 0; i--) {
      if (this.files[i].categorie === type) {
        this.files.splice(i, 1);
      }
    }

    // ajout de la categorie de fichier
    this.files.push({
      id:0,
      typeFichier: 'adc',
      categorie: type,
      name: type + '-' + e.target.files[0].name,
      mimeType: e.target.files[0].type,
      size: e.target.files[0].size,
      monFichier: e.target.files[0]
    })
  }

  // Download du fichier
  public downloadFile(idFile: number, fileName: string) {
    this.apiAdc.download( idFile, this.formAdc.id )
    .subscribe(
      res => {
        if(res != undefined) {
          saveAs(res, fileName);
          this.toast.showInfo('Fichier téléchargé.')
        }          
      },
      err => {
        console.log(err)
      }
    )
  }

  filterEntitesOfType(type: boolean){
    if(this.allEntite == false) {
      return this.entites$.filter(entite => entite.EstActif == !type && entite.EstInscrit == true);
    } else {
      return this.entites$;
    }    
  }


}
