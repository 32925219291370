<mat-card appearance="outlined">
    <mat-card-title>Sélectionnez votre filtre</mat-card-title>
    <br>
    <mat-card-content>
        <form [formGroup]="filterForm" >
            
            <mat-form-field class="formDate">
                <mat-label>Date de début</mat-label>
                <input matInput   type ="date" formControlName="date_debut">
                <mat-error *ngIf="filterForm.controls.date_debut.hasError('required')">Date de début obligatoire</mat-error>
            </mat-form-field>
        
            <mat-form-field class="formDate">
                <mat-label>Date de fin</mat-label>
                <input matInput   type ="date" formControlName="date_fin">
                <mat-error *ngIf="filterForm.controls.date_fin.hasError('required')">Date de fin obligatoire</mat-error>
            </mat-form-field>
            
            <mat-form-field *ngIf="userAdmin===false">
                <mat-label>Entité</mat-label>
                <mat-select
                        formControlName     = "entite"
                        >
                    <mat-option
                        value="Toutes">
                        Toutes
                    </mat-option>
                    <mat-option 
                        *ngFor="let entite of entitesUser" 
                        [value]     = "entite.habilitation" 
                        >
                        {{entite.raison_sociale}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="filterForm.controls.entite.hasError('required')">Sélection d'une entité obligatoire</mat-error>
            </mat-form-field>
            
            <div *ngIf="userAdmin===true">
                <button mat-raised-button (click)="searchingEntite()" color="primary" style="margin: 10px;" >Rechercher une entite</button>
                <app-entite-search *ngIf="searching" (entiteEvent)="setEntite($event)"></app-entite-search>
                <div *ngIf="entiteSelected" class="entite">
                    <div style="margin-right: 15px;">
                        {{entiteSelected!.raison_sociale}}
                    </div>
                    <button mat-mini-fab (click)="cancelEntite()"  matTooltip="Supprimer l'entité de la recherche"><mat-icon>close</mat-icon></button>
                </div>
            </div>

        </form>
    </mat-card-content>
    <mat-card-actions align="end">
        <button mat-raised-button color="primary" *ngIf="!waitingResearch" [disabled]="filterForm.invalid" (click)="applyFilter()">
            <mat-icon>search</mat-icon>
            <span class="hide">Rechercher</span>
        </button>
        <mat-spinner *ngIf="waitingResearch" diameter="24"></mat-spinner>
    </mat-card-actions>
</mat-card>
