<ng-container *ngIf="isMobileFormat(); then logInLine else logInColumn"></ng-container>

<!-- Template grand écran -->
<ng-template #logInColumn class="host">
    <form [formGroup]="collaborateurForm">
        <mat-card appearance="outlined" style="margin-top: 100px;">
            <mat-card-title>Inscription Extranet FFEA</mat-card-title>
            <mat-card-subtitle>Informations personnelles</mat-card-subtitle>
            <mat-card-content class=".mat-card-content">
                
                    <mat-form-field *ngIf="invitation.expert === true" hintLabel="Six chiffres maximum">
                        <mat-label for="agrement_ve">Agrément VE</mat-label>
                        <input matInput #input  type="number" id="agrement_ve" name="agrement_ve" formControlName="agrement_ve" maxlength="6">
                            <mat-error *ngIf="agrement_ve?.invalid && (agrement_ve?.dirty || agrement_ve?.touched)"> Six chiffres maximum</mat-error>
                            <mat-hint align="end">{{input.value.length}}/6</mat-hint>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label for="nom">Nom YY</mat-label>
                        <input matInput type="text" id="nom" name="nom" formControlName="nom" placeholder="Nom" oninput="this.value = this.value.toUpperCase()">                    
                        <mat-error *ngIf="nom?.invalid && (nom?.dirty || nom?.touched)">{{ nom?.errors?.erreur }}</mat-error>
                    </mat-form-field>                                
                    
                    <mat-form-field>
                        <mat-label for="prenom">Prénom </mat-label>
                        <input appTitleCase 
                                matInput 
                                type            = "text" 
                                id              = "prenom" 
                                name            = "prenom" 
                                formControlName = "prenom" 
                                placeholder     = "Prénom"
                                minlength       = "3"
                                maxlength       = "50"
                                >
                        <mat-error *ngIf="prenom?.invalid && (prenom?.dirty || prenom?.touched)">{{ prenom?.errors?.erreur }}</mat-error>
                    </mat-form-field>

                    <mat-form-field >
                        <mat-label for="email">Email </mat-label>
                        <input matInput type="email" id="email" name="email" formControlName="email" placeholder="Email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
                        <!-- <mat-hint>ATTENTION : Cet adresse email sera votre login lors de la connexion à l’extranet.</mat-hint> -->
                        <mat-hint>Cette email sera votre login de connexion.</mat-hint>
                        <mat-error *ngIf="email?.invalid && (email?.dirty || email?.touched)">Le format de l'adresse email est incorrect</mat-error>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label for="telephone">Téléphone</mat-label>
                        <input matInput type="tel" id="telephone" name="telephone" formControlName="telephone"
                            placeholder="Téléphone" appTelephone>
                            <mat-error *ngIf="telephone?.invalid && (telephone?.dirty || telephone?.touched)">{{ telephone?.errors?.erreur }}</mat-error>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Rôle </mat-label>
                        <mat-select name="id_groupe" id="id_groupe" formControlName="id_groupe">
                            <mat-option *ngFor="let groupe of groupes" [value]="groupe.id">
                                {{groupe.nom}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
 
                    <div class="flex">
                        <mat-form-field>
                            <mat-label>Mot de passe</mat-label>
                            <input 
                                matInput 
                                type            = "password"
                                formControlName = "new"
                                placeholder     = "Choisissez un mot de passe"
                                [type]          = "hidePasswordNewPassword ? 'password' : 'text'">
                                <mat-icon 
                                    matSuffix 
                                    mat-icon-button 
                                    style           = "cursor: pointer" 
                                    color           = "primary" 
                                    [matTooltip]    = "!hidePasswordNewPassword ? 'Masquer le mot de passe' : 'Afficher le mot de passe'" 
                                    (click)         = "hidePasswordNewPassword = !hidePasswordNewPassword"
                                    >{{!hidePasswordNewPassword ? 'visibility_off' : 'visibility'}}
                                </mat-icon>    
                                <mat-error *ngIf="new?.invalid && (new?.dirty || new?.touched)">{{new?.errors?.erreurs}}</mat-error>
                            </mat-form-field>
                            
                            <mat-form-field>
                                <mat-label>Confirmation du mot de passe</mat-label>
                                <input matInput type="password" id="confirmation" name="confirmation" formControlName="confirmation"
                                placeholder="Confirmez votre mot de passe" [type]="hidePasswordConfirmPassword ? 'password' : 'text'">
                                <mat-icon style="cursor: pointer" matSuffix mat-icon-button color="primary" [matTooltip]="!hidePasswordConfirmPassword ? 'Masquer le mot de passe' : 'Afficher le mot de passe'" (click)="hidePasswordConfirmPassword = !hidePasswordConfirmPassword">{{!hidePasswordConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                                <mat-error *ngIf="confirmation?.invalid && (confirmation?.dirty || confirmation?.touched)">{{confirmation?.errors?.erreurs}}</mat-error>
                            </mat-form-field>
                        </div>
                        <mat-hint>8 caractères minimum dont une majuscule, une minuscule, un nombre, un caractère spécial</mat-hint>
            </mat-card-content>

            <mat-card-actions>
                <div class="button" >                    
                    <button (click)="inscriptionCollaborateur()"
                             mat-raised-button color="primary" 
                             [disabled]="collaborateurForm.pristine || !(collaborateurForm.valid && collaborateurForm.dirty)"
                             *ngIf="isWaiting === false"
                             >
                        Valider l'inscription
                    </button>
                    <mat-spinner 
                    *ngIf="isWaiting === true"
                    diameter="24"
                    >
                    </mat-spinner>
                </div>
            </mat-card-actions>
        </mat-card>
    </form>
</ng-template>

<!-- Template petit écran -->
<ng-template #logInLine>
    <form [formGroup]="collaborateurForm">
        <mat-card appearance="outlined" style="margin-top: 100px;">
            <mat-card-title>Inscription Extranet FFEA</mat-card-title>
            <mat-card-subtitle>Informations personnelles</mat-card-subtitle>
            <mat-card-content class=".mat-card-content">                                                            
                <mat-form-field *ngIf="invitation.expert === true">
                    <mat-label for="agrement_ve">Agrément VE</mat-label>
                    <input matInput type="number" id="agrement_ve" name="agrement_ve" formControlName="agrement_ve" min="1" max="999999">
                        <mat-error *ngIf="agrement_ve?.invalid && (agrement_ve?.dirty || agrement_ve?.touched)">{{ agrement_ve?.errors?.erreur }}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label for="nom">Nom </mat-label>
                    <input matInput type="text" id="nom" name="nom" formControlName="nom" placeholder="Nom" oninput="this.value = this.value.toUpperCase()">                    
                    <mat-error *ngIf="nom?.invalid && (nom?.dirty || nom?.touched)">{{ nom?.errors?.erreur }}</mat-error>
                </mat-form-field>                                
                
                <mat-form-field>
                    <mat-label for="prenom">Prénom </mat-label>
                    <input appTitleCase matInput type="text" id="prenom" name="prenom" formControlName="prenom" placeholder="Prénom">
                    <mat-error *ngIf="prenom?.invalid && (prenom?.dirty || prenom?.touched)">{{ prenom?.errors?.erreur }}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label for="email">Email </mat-label>
                    <input matInput type="email" id="email" name="email" formControlName="email" placeholder="Email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
                    <mat-hint>ATTENTION : Cet adresse email sera votre login lors de la connexion à l’extranet.</mat-hint>                        
                    <mat-error *ngIf="email?.invalid && (email?.dirty || email?.touched)">Le format de l'adresse email est incorrect</mat-error>
                </mat-form-field>             

                <mat-form-field>
                    <mat-label for="telephone">Téléphone </mat-label>
                    <input matInput type="tel" id="telephone" name="telephone" formControlName="telephone"
                        placeholder="Téléphone" appTelephone>
                        <mat-error *ngIf="telephone?.invalid && (telephone?.dirty || telephone?.touched)">{{ telephone?.errors?.erreur }}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Rôle </mat-label>
                    <mat-select name="id_groupe" id="id_groupe" formControlName="id_groupe">
                        <mat-option *ngFor="let groupe of groupes" [value]="groupe.id">
                            {{groupe.nom}}
                        </mat-option>
                    </mat-select>
                </mat-form-field> 
                
                <mat-form-field>
                    <mat-label>Mot de passe</mat-label>
                    <input 
                        matInput 
                        type            = "password" 
                        formControlName = "new"
                        placeholder     = "Choisissez un mot de passe"
                        [type]          = "hidePasswordNewPassword ? 'password' : 'text'"
                        >
                        <mat-icon 
                            matSuffix 
                            mat-icon-button 
                            style           = "cursor: pointer" 
                            color           = "primary" 
                            [matTooltip]    = "!hidePasswordNewPassword ? 'Masquer le mot de passe' : 'Afficher le mot de passe'" 
                            (click)         = "hidePasswordNewPassword = !hidePasswordNewPassword"
                            >{{!hidePasswordNewPassword ? 'visibility_off' : 'visibility'}}
                        </mat-icon>    
                        <mat-error *ngIf="new?.invalid && (new?.dirty || new?.touched)">{{new?.errors?.erreurs}}</mat-error>
                    </mat-form-field>
                    
                    <mat-form-field>
                        <mat-label>Confirmation du mot de passe</mat-label>
                        <input 
                            matInput 
                            type            = "password"
                            formControlName = "confirmation"
                            placeholder     = "Confirmez votre mot de passe" 
                            [type]          = "hidePasswordConfirmPassword ? 'password' : 'text'"
                            >
                        <mat-icon 
                            matSuffix 
                            mat-icon-button 
                            style           = "cursor: pointer" 
                            color           = "primary" 
                            [matTooltip]    = "!hidePasswordConfirmPassword ? 'Masquer le mot de passe' : 'Afficher le mot de passe'" 
                            (click)         = "hidePasswordConfirmPassword = !hidePasswordConfirmPassword"
                            >{{!hidePasswordConfirmPassword ? 'visibility_off' : 'visibility'}}
                        </mat-icon>
                        <mat-error *ngIf="confirmation?.invalid && (confirmation?.dirty || confirmation?.touched)">{{confirmation?.errors?.erreurs}}</mat-error>
                    </mat-form-field>
                    
                    <mat-hint>8 caractères minimum dont une majuscule, une minuscule, un nombre, un caractère spécial</mat-hint>
            </mat-card-content>
            <mat-card-actions>
                <div class="button">                    
                    <button (click)="inscriptionCollaborateur()" mat-raised-button color="primary" [disabled]="collaborateurForm.pristine || !(collaborateurForm.valid && collaborateurForm.dirty)">
                        Valider l'inscription
                    </button>
                </div>
            </mat-card-actions>
        </mat-card>
    </form>
</ng-template>
