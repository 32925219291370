import { Component, OnInit,Input,Output, EventEmitter} from '@angular/core';
import { Categorie } from 'src/app/interfaces/support/categorie';
import { Modele } from 'src/app/interfaces/support/modele';
import { SousCategorie } from 'src/app/interfaces/support/sous-categorie';
import { ApiSupportService } from 'src/app/services/api-support.service';
import { ModeleFacadeService } from 'src/app/services/modele/modele-facade.service';
import {Subject} from 'rxjs';
import { takeUntil} from 'rxjs/operators';
import { ListeModeleComponent } from '../liste-modele/liste-modele.component';
import { TableModeleComponent } from '../table-modele/table-modele.component';
import { EditModeleComponent } from '../edit-modele/edit-modele.component';
import { EditSousCategorieComponent } from '../edit-sous-categorie/edit-sous-categorie.component';
import { EditCategorieComponent } from '../edit-categorie/edit-categorie.component';
import { NgIf } from '@angular/common';
import { FiltreModeleComponent } from '../filtre-modele/filtre-modele.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-modele',
    templateUrl: './modele.component.html',
    styleUrls: ['./modele.component.scss'],
    standalone: true,
    imports: [FiltreModeleComponent, NgIf, EditCategorieComponent, EditSousCategorieComponent, EditModeleComponent, TableModeleComponent, ListeModeleComponent]
})
export class ModeleComponent implements OnInit {
  @Input() modeSelectModele       : boolean = false//Faux si on affiche la table, vrai si on affiche la liste de sélection
  @Output() modeleSelectedEvent   = new EventEmitter<Modele>() ;

  modeles                         : Modele[]=[];
  modele                          : Modele = <Modele>{};
  categorie                       : Categorie = <Categorie>{};
  sousCategorie                   : SousCategorie = <SousCategorie>{};
  modeEditModele                  : boolean = false
  modeEditCategorie               : boolean = false
  modeEditSousCategorie           : boolean = false
  idSousCategorie                 : number = 0
  sousCategorieChange             : boolean = false;
  destroyed                       : Subject<void> = new Subject<void>();
  currentElement: string='' ;

  constructor(private apiModeleFacade : ModeleFacadeService
              , private activatedRoute: ActivatedRoute
              , private router      : Router) { }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe(url => {
      
      const elements    = this.router.url.split('/');
      this.currentElement = elements[elements.length - 1];
      
      switch (this.currentElement) {
        
        case 'modele':
          this.modeEditModele = false
          break;
        
          case 'edit':
          this.modeEditModele = true
          break;

        default:
          break;
      }
    });
    this.apiModeleFacade.getAll()
    this.apiModeleFacade.modeles$.pipe(takeUntil(this.destroyed)).subscribe((res)=>this.modeles =res)
  }

  getNewArrayModele(listeModeles:Modele[]){
    this.modeles=listeModeles
  }

  getNewIdSousCategorie(idSousCategorie:number){
    this.idSousCategorie = idSousCategorie
  }

  getEditMode(modeEdit : boolean){
    this.modeEditModele = true
    this.router.navigate(['app/support/modele/edit']);
  }
  
  getResultValideModele(result:boolean){
    this.modeEditModele = false
    this.router.navigate(['app/support/modele']);
  }
  getResultValideCategorie(result:boolean){
    this.modeEditCategorie = false
  }
  getResultValideSousCategorie(result:boolean){
    this.modeEditSousCategorie = false
    this.sousCategorieChange   = true
  }
  
  getModeleEdit(modeleAEditer:Modele){
    this.modeEditModele = true
    this.router.navigate(['app/support/modele/edit']);
    this.modele = modeleAEditer
    //On ajoute la sous catégorie:
    if (modeleAEditer.mdl_sou_cat_id==0) {
      this.modele.mdl_sou_cat_id = this.idSousCategorie
    }
  }

  getCategorieEdit(categorieAEditer:Categorie){
    this.modeEditCategorie      = true
    this.categorie               = categorieAEditer
  }

  getSousCategorieEdit(sousCategorieAEditer:SousCategorie){
    this.modeEditSousCategorie  = true
    this.sousCategorie          = sousCategorieAEditer
    this.sousCategorieChange    = false
  }

  getModeleSelected(modele:Modele){
    this.modeleSelectedEvent.emit(modele)
  }

  onAnnule(){
    // this.editionModele = false;
  }

  ngOnDestroy() {
    this.destroyed.next(); // emits an undefined value to raise the flag
    this.destroyed.complete(); // prevents all further emission
  }
  
}
