import { TypeEntite } from "src/app/interfaces/profil-user/type-entite"
import { Utilisateur } from "src/app/interfaces/utilisateur-entite/relation-utilisateur-entite"

export interface Droit {
        id      : number
    ,   nom     : string
    ,   code    : string
}

export interface Groupe {
       id               : number
    ,  nom              : string
    ,   description     : string
    ,   idenv           : number
    ,   droits          : Droit[]
    ,   utilisateurs    : Utilisateur[]
    ,   GroupeParDefaut : boolean
    ,   codeEntite      : string
    ,   typesEntite     : TypeEntite[]
    ,   estExpert       : boolean
}

export const GroupeInit : Groupe={
    "id"                : 0
    ,"description"      : ''
    ,"nom"              : ''
    , 'idenv'           : 0
    ,"droits"           : []
    ,"utilisateurs"     : []
    ,"GroupeParDefaut"  : false
    ,"codeEntite"       : ''
    ,"typesEntite"      : []
    , "estExpert"       : false
}

export interface GpeUtlDrt {
        groupe          : number
    ,   droits?         : string
    ,   utilisateurs?   : string
    ,   typesEntite?    : TypeEntite[]
}

export interface GroupeLink {
        idGroupe    : number
    ,   defaut     : boolean
}
export interface TypeGroupes {
        idType    : number
    ,   groupes   : GroupeLink[]
}