
<mat-card appearance="outlined" class="mat-card-no-shadow" style="padding: 0px;text-align: center;">
    <mat-expansion-panel [(expanded)]="expandPannel" style="background-color: #fff">
        <mat-expansion-panel-header style="background-color: #fff">
            <mat-panel-title>
                <mat-icon color="primary">file</mat-icon>
                <span style="width: 220px;" *ngIf="dataSource != undefined"><span class="span-primary">{{ dataSource.length }}</span>SERVICE<span *ngIf="dataSource.length > 1">S</span> EXTERNE</span>        
                <span style="width: 220px;" *ngIf="dataSource == undefined"><span class="span-primary">0</span>SERVICE EXTERNE</span>        
            </mat-panel-title>
            <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>

            <mat-card-subtitle>Mes services externes</mat-card-subtitle>

            <div class="container">
                <table mat-table matTableResponsiveLarge [dataSource]="dataSourceMatTable" class="table-primary">                   

                    <!-- Service externe libelle Column-->
                    <ng-container matColumnDef="srx_libelle">
                        <th mat-header-cell *matHeaderCellDef> Service Externe </th>
                        <td mat-cell *matCellDef="let service_externe">{{ service_externe.libelle }}</td>
                    </ng-container>

                    <!-- Service libelle Column-->
                    <ng-container matColumnDef="srv_libelle">
                        <th mat-header-cell *matHeaderCellDef> Service </th>
                        <td mat-cell *matCellDef="let service_externe">{{ service_externe.service.libelle }}</td>
                    </ng-container>

                    <!-- Service externe actif Column-->
                    <ng-container matColumnDef="srx_actif">
                        <th mat-header-cell *matHeaderCellDef> État </th>
                        <td mat-cell *matCellDef="let service_externe">{{ service_externe.actif ? 'Activé' : 'Désactivé' }}</td>
                    </ng-container>

                    <!-- Modifier -->
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef class="derniere-colonne" style="text-align: center;"></th>
                        <td mat-cell *matCellDef="let service_externe">
                            <button mat-icon-button class="button-etat" color="primary"
                                style="width: 48px; height: 48px;"
                                (click)="onClickEdit(service_externe.id)">
                                <mat-icon color="primary">edit</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[5,10,25]"
                    showFirstLastButtons 
                    aria-label="Nombre de lignes visibles...">
                 </mat-paginator>
            </div>
        </mat-expansion-panel>
    </mat-card>
