import { Component, Input, OnInit } from '@angular/core';
import { PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { RelationUtilisateurEntite } from 'src/app/interfaces/utilisateur-entite/relation-utilisateur-entite';
import { ProtectExtranetModificationInformationEntiteGuard } from 'src/app/security/protect-extranet-modification-information-entite.guard';
import { PhoneFormatPipe } from '../../../pipe/phone-format.pipe';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor, AsyncPipe, UpperCasePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-liste-succursale',
    templateUrl: './liste-succursale.component.html',
    styleUrls: ['./liste-succursale.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatIconModule, NgIf, MatSlideToggleModule, MatFormFieldModule, NgFor, MatExpansionModule, MatButtonModule, ReactiveFormsModule, FormsModule, MatInputModule, MatPaginatorModule, AsyncPipe, UpperCasePipe, PhoneFormatPipe]
})
export class ListeSuccursaleComponent implements OnInit {

  length                        : number = 0;
  pageIndex                     : number = 0;
  pageSize                      : number = 5;
  previousPageIndex?            : number;
  pageSizeOptions               : Array<number> = [5,10,25];
  
  obs$                          : Observable<RelationUtilisateurEntite[]> = new Observable<RelationUtilisateurEntite[]>();
  
  @Input() est_dirigeant        : boolean = false;
  nb_succursale                 : number = 0;
  id_entite                     : number = 0;
  seeInactiveSuccursales        : boolean = false;

  @Input() RelUtilisateurEntite : Array<RelationUtilisateurEntite> = new Array<RelationUtilisateurEntite>();
  RelUtlEntFilter  : Array<RelationUtilisateurEntite> = new Array<RelationUtilisateurEntite>();

  constructor(private route         : Router
            , private guardModifEnt : ProtectExtranetModificationInformationEntiteGuard
            , public _sanitizer     : DomSanitizer) { }

  ngOnInit(): void {      
    // On force le reset des valeurs par défaut, car lorsqu'on recharge le component liste-utilisateur,    
    // des valeurs peuvent déjà être configuré par une "session" précédente (session dans le sens appel depuis une autre page)
    // mais ne pas être réinitialisé car le component ne refait pas la déclaration des variables puisqu'il a déjà été initialisé par la "session" précédente  
    this.length                 = 0;
    this.pageIndex              = 0;
    this.pageSize               = 5;
    this.obs$                   = new Observable<RelationUtilisateurEntite[]>();
    this.nb_succursale          = 0;    
    this.id_entite              = 0;
    this.seeInactiveSuccursales = false;
    this.RelUtlEntFilter        = new Array<RelationUtilisateurEntite>();          
  }

  ngOnChanges(): void {
    if(this.RelUtilisateurEntite != undefined) {
      this.nb_succursale = this.RelUtilisateurEntite.length;
      if(this.RelUtilisateurEntite[0] != undefined) {
        this.id_entite     = this.RelUtilisateurEntite[0].entite.id_entite_principale;
      }   

      // L'utilisateur souhaite voir les succursales actives et inactives
      // Autrement dit, il n'y a aucun filtre
      if(this.seeInactiveSuccursales) {
        this.RelUtlEntFilter = this.RelUtilisateurEntite;        
      } else {
        // L'utilisateur souhaite voir uniquement les succursales actives
        this.RelUtlEntFilter = this.RelUtilisateurEntite.filter(rel => rel.entite.EstActif == !this.seeInactiveSuccursales)
      }       

      this.obs$   = of(this.RelUtlEntFilter.slice(this.pageIndex*this.pageSize, this.pageIndex*this.pageSize+this.pageSize));
      this.length = this.RelUtlEntFilter.length;
    }    
  }

  getAccesConcentrateur(entite: Entite) {
    let autorisation: boolean = false;

    if(entite.acces_concentrateur.actif === true) {
      autorisation = true;
    }    

    return autorisation;
  }

  openSuccursale(id_entite: any, $e: Event): void {
    // $e.stopPropagation;    
    this.guardModifEnt.ent_id = id_entite;   
    
    // Si on détecte que l'utilisateur est le dirigeant principal de l'entité mère
    // On lui accorder l'accès en tant que dirigeant à la succursale
    if(this.est_dirigeant)  {
      this.route.navigate(['/app/entite/' + id_entite, {acces : "dirigeant_principal"}]);
    } else {
      // Sinon, on lui octroit un accès normal dépendant de son affiliation à la succursale
      this.route.navigate(['/app/entite/' + id_entite]);
    }
  }

  onSuccursalesToggle(event: MatSlideToggleChange) {
    if (this.seeInactiveSuccursales != event.checked) {
      this.seeInactiveSuccursales = event.checked;

      // L'utilisateur souhaite voir les succursales actives et inactives
      // Autrement dit, il n'y a aucun filtre
      if(this.seeInactiveSuccursales) {
        this.RelUtlEntFilter = this.RelUtilisateurEntite;
      } else {
        // L'utilisateur souhaite voir uniquement les succursales actives
        this.RelUtlEntFilter = this.RelUtilisateurEntite.filter(rel => rel.entite.EstActif == !this.seeInactiveSuccursales);
      }    
      
      this.obs$   = of(this.RelUtlEntFilter.slice(this.pageIndex*this.pageSize, this.pageIndex*this.pageSize+this.pageSize));
      this.length = this.RelUtlEntFilter.length;
    }
  }

  onChangePaginator(event: PageEvent) {
    this.length             = event.length;
    this.pageIndex          = event.pageIndex;
    this.pageSize           = event.pageSize;
    this.previousPageIndex  = event.previousPageIndex;

    // Update des données à afficher
    this.obs$               = of(this.RelUtlEntFilter.slice(this.pageIndex*this.pageSize, this.pageIndex*this.pageSize+this.pageSize));
  }

}
