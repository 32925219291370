import { NgFor } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { Adresse, AdresseInit, CST_TYPE_ADR_CABINET, RelAdresse, TypeAdresse } from 'src/app/interfaces/adresse/adresse';
import { AdresseComponent } from '../adresse.component';

@Component({
    selector: 'app-edit-adresse',
    templateUrl: './edit-adresse.component.html',
    styleUrls: ['./edit-adresse.component.scss'],
    standalone: true,
    imports: [ReactiveFormsModule, MatCardModule, MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, AdresseComponent, MatButtonModule]
})
export class EditAdresseComponent implements OnInit {
  
  @Input()  adresse         : Adresse= {...AdresseInit}
  @Input()  typesAdresse    : TypeAdresse[] = []
  @Output() adresseEvent    : EventEmitter<Adresse|null> = new EventEmitter()

  typeControl! : FormControl
  formAdresse : FormGroup = new FormGroup({})

  constructor() { 
   
  }

  ngOnInit(): void {
    this.typeControl = new FormControl(this.typesAdresse[0].type)
    
    this.formAdresse = new FormGroup(
      {type : this.typeControl}
    )
  }

  annuler(){
    this.adresseEvent.next(null)
  }
  
  valider(){
    
    const relAdr : RelAdresse = {...this.adresse.relAdr,
      'fct'   : this.formAdresse.value.type
      ,'type' : CST_TYPE_ADR_CABINET
    }
    const adresseToSave : Adresse = {
        ...this.adresse
        , 'ligne1'  : this.formAdresse.value.adresse.adresseLigne1
        , 'ligne2'  : this.formAdresse.value.adresse.adresseLigne2
        , 'ligne3'  : this.formAdresse.value.adresse.adresseLigne3
        , 'cp'      : this.formAdresse.value.adresse.adresseCP
        , 'ville'   : this.formAdresse.value.adresse.adresseVille
        ,'relAdr'   : relAdr
      }
    
    this.adresseEvent.next(adresseToSave)
    
  }
}
