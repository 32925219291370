      

            <div class="container">
                <table mat-table *ngIf="dataSource.data" [dataSource]="dataSource" class="table-primary">
                                        
                    <!-- Année Column -->
                    <ng-container matColumnDef="annee">
                        <th mat-header-cell *matHeaderCellDef> Année </th>
                        <td mat-cell *matCellDef="let chiffre_affaires">
                            {{ chiffre_affaires.annee }}
                        </td>
                    </ng-container>

                    <!-- Mois Column --> 
                    <ng-container matColumnDef="mois">
                        <th mat-header-cell *matHeaderCellDef> Mois </th>
                        <td mat-cell *matCellDef="let chiffre_affaires"> 
                            {{ chiffre_affaires.mois }}
                        </td>
                    </ng-container>

                    <!-- Montant HT Facture Column --> 
                    <ng-container matColumnDef="montant_ht_facture">
                        <th mat-header-cell *matHeaderCellDef> Montant HT Facture </th>
                        <td mat-cell *matCellDef="let chiffre_affaires"> {{ chiffre_affaires.montant_ht_facture }} €</td>
                    </ng-container>

                    <!-- Montant HT Avoir Column--> 
                    <ng-container matColumnDef="montant_ht_avoir">
                        <th mat-header-cell *matHeaderCellDef> Montant HT Avoir </th>
                        <td mat-cell *matCellDef="let chiffre_affaires">{{ chiffre_affaires.montant_ht_avoir }} €</td>
                    </ng-container>
                        
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        
    