<app-filtre-liste-utilisateurs (newItemEvent)="addItem($event)"></app-filtre-liste-utilisateurs>
<mat-card appearance="outlined" class="mat-card-no-shadow" style="padding: 0px;">
    <mat-expansion-panel [expanded]="true" style="background-color: #fff">
        
        <mat-expansion-panel-header style="background-color: #fff">
            <mat-panel-title>
                <mat-icon color="primary">file</mat-icon>
                <span style="width: 220px;" *ngIf="ds_Utilisateurs != undefined"><span class="span-primary">{{ ds_Utilisateurs.length }}</span>UTILISATEUR<span *ngIf="ds_Utilisateurs.length > 1">S</span></span>
                <span style="width: 220px;" *ngIf="ds_Utilisateurs == undefined"><span class="span-primary">0</span>UTILISATEUR</span>        
            </mat-panel-title>
        </mat-expansion-panel-header>
        <app-table-liste-utilisateurs [dataSource]="ds_Utilisateurs"></app-table-liste-utilisateurs>
    </mat-expansion-panel>
</mat-card>