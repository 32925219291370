import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Observable, forkJoin } from 'rxjs';
import { TypeDemande } from 'src/app/interfaces/support/type-demande';
import { ApiSupportService } from 'src/app/services/api-support.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { TicketFacadeService, TicketState } from 'src/app/services/ticket/ticket-facade.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-filtre-ticket',
    templateUrl: './filtre-ticket.component.html',
    styleUrls: ['./filtre-ticket.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatExpansionModule, MatIconModule, MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, NgIf, MatInputModule, MatButtonModule, MatProgressSpinnerModule]
})
export class FiltreTicketComponent implements OnInit {

  
  // Gestion de l'affichage
  expandPannel    : boolean = true;
  waitingResearch : boolean = false;
  
   // Combo type 
    typesInit           : Array<any> = Array<any>();
    typesSaved          : Array<any> = Array<any>();
   
    //Combo etat
    etatsInit          : Array<any> = Array<any>();
    etatsSelectedInit  : Array<any> = Array<any>();
    etatsSaved         : Array<any> = Array<any>();

  // Formulaire de filtrage
  FiltreFormGroup!: FormGroup;

  vm$: Observable<TicketState> = this.ticketFacade.vm$;

  // Accesseur en lecture des formControl
  get numero()            : FormArray  { return <FormArray>this.FiltreFormGroup.get('numero') as FormArray }
  get contact()           { return this.FiltreFormGroup.get('contact'); }
  get habilitation()      { return this.FiltreFormGroup.get('habilitation'); }
  get texte()             { return this.FiltreFormGroup.get('texte'); }
  get immatriculation()   { return this.FiltreFormGroup.get('immatriculation'); }
  get etats()           : FormArray  { return <FormArray>this.FiltreFormGroup.get('etats') as FormArray }
  get types()           : FormArray  { return <FormArray>this.FiltreFormGroup.get('types') as FormArray }

  constructor(private _fb                 : FormBuilder
                      , private apiSupportService       : ApiSupportService
                      , private toast                   : PersoSnackbarService
                      , private ticketFacade            : TicketFacadeService
                      ) {}

  ngOnInit(): void {
    const {criteria} = this.ticketFacade.getStateSnapshot();
    
     // Déclaration du formulaire
     this.FiltreFormGroup = this._fb.group({
      numero            : ['',''],
      contact           : ['', ''],
      habilitation       : ['', ''],
      immatriculation   : ['', ''],
      texte             : ['', ''],
      types           : [Array<any>(), [Validators.required]],
      etats           : [Array<any>(), [Validators.required]]
    }); 
    this.chargeFiltre(criteria);
     
  }
 
  chargeFiltre(criteria:any){
    forkJoin([this.apiSupportService.getListeTypeDemande(),this.apiSupportService.getListeEtatTicket()])
            .subscribe(([typeDemandes,etats]:[TypeDemande[],any])=>{
                this.typesInit = typeDemandes;
                this.etatsInit = etats
                this.etatsSelectedInit = etats.filter((element : TypeDemande)=>element.code!='CODE_CLOTURE')
                this.FiltreFormGroup.patchValue(criteria,{emitEvent:false})
                const etatsSaved = criteria.etats.length > 0? etats.filter((elementSrc:any)=>criteria.etats?.find((element:any)=>element.id ===elementSrc.id)) : this.etatsSelectedInit
                const typesSaved = criteria.types.length > 0? this.typesInit.filter((elementSrc:any)=>criteria.types?.find((element:any)=>element.id ===elementSrc.id)) : this.typesInit
                this.FiltreFormGroup.controls.etats.setValue(etatsSaved);
                this.FiltreFormGroup.controls.types.setValue(typesSaved);
            })
    
  };

  public onSearchingTickets() {
    this.waitingResearch = true;
    
    this.ticketFacade.findTickets(this.FiltreFormGroup.value).subscribe(()=>{
        this.waitingResearch = false;
        this.toast.showInfo('Recherche terminée');
        this.expandPannel = true;
      })
    
  }

}
