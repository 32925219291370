import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MessageTicket } from 'src/app/interfaces/support/message-ticket';
import { Modele } from 'src/app/interfaces/support/modele';
import { PjMessageTicket } from 'src/app/interfaces/support/pj-message-ticket';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiSupportService } from 'src/app/services/api-support.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { UploadParameter, initialUploadParameter, UploadFilesComponent } from '../../templates/upload-files/upload-files.component';
import { UploadFile } from 'src/app/interfaces/fichier';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';

interface file {
  name        : string,
  mimeType    : string,
  size        : number,
  monFichier  : File
}

@Component({
    selector: 'app-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, CKEditorModule, UploadFilesComponent, MatButtonModule, MatProgressSpinnerModule, NgIf]
})
export class MessageComponent implements OnInit {
@Input()  idTicket        :number = 0;
@Input()  modele          : Modele = <Modele>{};
@Output() MessageFinished = new EventEmitter<boolean>();

  sizeError           : boolean = false;
  fileName            : string = '';
  files               : Array<file> = [];
  MessageFormGroup!   : FormGroup;
  message             : MessageTicket =<MessageTicket>{};
  pj                  : PjMessageTicket = <PjMessageTicket>{};
  uploadParameter     : UploadParameter = initialUploadParameter
  uploadFiles         : UploadFile[]=[]
  waiting_Save        : boolean = false;

  get messageText()       { return this.MessageFormGroup.get('messageText'); }
  get piecesjointe()      { return this.MessageFormGroup.get('piecesjointe'); }

  public editor = ClassicEditor

  constructor(  private _fb         : FormBuilder
              , private apiSupport  : ApiSupportService
              , private toast       : PersoSnackbarService
              , private authUser    : ApiAuthentificationService) { }

  ngOnInit(): void {
    this.MessageFormGroup =this._fb.group({
      messageText : ['', [Validators.required]]
    })
  }

  ngOnChanges(changes:SimpleChanges){
    if (this.modele && this.MessageFormGroup) {
      this.MessageFormGroup.controls.messageText.setValue(this.modele.mdl_contenu)
    }
  }

  onSendMessage(){

    this.message.tck_mss_message  = this.MessageFormGroup.controls.messageText.value,
    this.message.tck_mss_tck_id   = this.idTicket
    this.message.tck_mss_utl_id   = this.authUser.userConnect.id
    this.message.tck_mss_origine  = false;
    this.message.tck_mss_mdl_id   = this.modele.mdl_id
    this.message.tabpiecejointe    = [];

    if (this.uploadFiles.length != 0) {
      this.uploadFiles.forEach((uploadFile:UploadFile)=>{
        let pj : PjMessageTicket = <PjMessageTicket>{}
          pj.contenu = uploadFile.contenu? uploadFile.contenu : '';
          pj.nomFichier = uploadFile.file?.name;
          pj.id = 0;
          pj.id_message = 0;
          this.message.tabpiecejointe.push(pj); 
      })
    }
    
    this.creerMessage(this.message);
  }

  onAnnule(){
    this.MessageFinished.emit(false)
  }

  getFiles(files:UploadFile[]):void{
    this.uploadFiles = files;
  }

  public creerMessage(message: MessageTicket): any {
    this.waiting_Save = true;

    this.apiSupport.postMessage(message)
    .subscribe(
      (data: any) => {
        this.waiting_Save = false;
        this.toast.showInfo('Votre message a bien été envoyé.');     
        this.MessageFinished.emit(true)         
      },
      (err: any) => {
        this.waiting_Save = false;
        console.error('/!\ err: ', err.message);
        this.MessageFinished.emit(true)
      }
    );
  }

}
