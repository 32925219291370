import { Component, OnInit } from '@angular/core';
import { Suspension, SuspensionFiltre, SuspensionInitiale, SuspensionRappel, TypeSuspension } from '../../interfaces/suspension';
import { BehaviorSubject, Observable, Subject, merge, of } from 'rxjs';
import { SuspensionFacadeService } from '../../facades/suspension-facade.service';
import { ActivatedRoute, Params, Route, Router } from '@angular/router';
import { mergeMap, scan } from 'rxjs/operators';
import { TypeSuspensionFacadeService } from '../../facades/type-suspension-facade.service';
import { MatDialog } from '@angular/material/dialog';
import { OuiNonAnnulerComponent } from 'src/app/components/templates/oui-non-annuler/oui-non-annuler.component';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import * as saveAs from 'file-saver';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { TableSuspensionComponent } from '../table-suspension/table-suspension.component';
import { FilterSuspensionComponent } from '../filter-suspension/filter-suspension.component';
import { EditSuspensionComponent } from '../edit-suspension/edit-suspension.component';
import { NgIf } from '@angular/common';
import { RapportSuspensionComponent } from '../rapport-suspension/rapport-suspension.component';


@Component({
    selector: 'app-shell-suspension',
    templateUrl: './shell-suspension.component.html',
    styleUrls: ['./shell-suspension.component.scss'],
    standalone: true,
    imports: [RapportSuspensionComponent, NgIf, EditSuspensionComponent, FilterSuspensionComponent, TableSuspensionComponent]
})
export class ShellSuspensionComponent implements OnInit {

  modeEdit          : boolean = false
  origineEntite     : boolean = false
  suspensions$      : Observable<Suspension[]>      = this.facadeSuspension.suspensions$
  typesSuspension$  : Observable<TypeSuspension[]>  = this.facadeTypeSuspension.typesSuspension$
  suspension$       : BehaviorSubject<Suspension>   = this.facadeSuspension.currentSuspensionSubject
  waitingRapport$   : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  waitingOperation$ : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  waitingManuel$    : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  waiting$          = merge  
                    (
                          this.waitingManuel$,
                          this.suspensions$
                            ).pipe(scan((acc,value)=>{
                              if (value instanceof Array){
                                this.waitingOperation$.next(false)
                                return false
                              }else{
                                return value as boolean
                              }
                            },false)) 
  currentElement : string = ''

  constructor(
                private facadeSuspension        : SuspensionFacadeService
              , private facadeTypeSuspension    : TypeSuspensionFacadeService 
              , private activatedRoute          : ActivatedRoute
              , private  dialog                 : MatDialog
              , private router                   : Router
              , private toast: PersoSnackbarService
              ) { }


  ngOnInit(): void {
    this.activatedRoute.url.subscribe(url => {
      
      const elements    = this.router.url.split('/');
      this.currentElement = elements[elements.length - 1];
      
      switch (this.currentElement) {
        
        case 'suspension':
          this.modeEdit           = false
          break;
        
        case 'edit':
          this.modeEdit         = true
          break;

        default:
          break;
      }
    });
    
    this.activatedRoute.paramMap.pipe(mergeMap(
      (params:Params)=> {
        const idEntite = + params.get('id')
        if (idEntite) {
          return this.facadeSuspension.getEntiteOrSuspension(idEntite)
        }else{
          return of(null)
        }
      }
    )).subscribe(
      (entite)=>{
        
        if (entite!=null) {
          this.modeEdit           = true
          this.router.navigate(['app/suspension/edit']);
          this.origineEntite      = true

          if ('actions' in entite) {
            this.suspension$.next(entite)
          }else{
            //on va créer une suspension
            const newSuspension:Suspension = {...SuspensionInitiale,'entite':entite as Entite,'idEntite':entite.id as number,'pjs':[]}
            
            this.suspension$.next(newSuspension)
          }
        }else{
            //pb
        }
      }
    )
  }

  applyFilter(suspensionFiltre:SuspensionFiltre){
    this.waitingManuel$.next(true)
    this.facadeSuspension.filter(suspensionFiltre)
  }

  saveSuspension(suspensionToSave:Suspension | boolean){
    if (typeof suspensionToSave === 'boolean') {
      //on a cliqué sur Fermer sans rien enregistrer
      this.modeEdit = false 
      if (this.origineEntite===true) {
        //on vient d'un cabinet, on retourne sur la page de recherche des cabinets
        this.router.navigate(['/app/administration-extranet/cabinets']);
        
      }     
    }else{
      this.router.navigate(['app/suspension']);
      if (suspensionToSave.id===0) {
        this.facadeSuspension.add(suspensionToSave)
      }else{
        this.facadeSuspension.modify(suspensionToSave)
      }
    }
  }

  editSuspension(suspensionToEdit:Suspension){
    //il faut d'abord récupérer les détails de la suspension:
    this.facadeSuspension.getSuspension(suspensionToEdit.id).subscribe(
      (suspension:Suspension | boolean)=> {
        if (typeof suspension != 'boolean'){
          
          this.suspension$.next(suspension)
          this.modeEdit= true
          this.router.navigate(['app/suspension/edit']);
        }
      }
    )
  }

  envoyerRappel(rappel : SuspensionRappel){
    const message:string = 'Vous vous aprêtez à envoyer le ' 
                            + this.facadeSuspension.getOrdinalRappel(rappel.index) 
                            + ' rappel. Voulez-vous continuer?'
    this.demandeConfirmation(message).subscribe(
      res => {
        switch (res) {
           case true:
            this.facadeSuspension.sendRecall(rappel).subscribe(
              (suspension : Suspension | boolean)=>{
                if (typeof suspension != 'boolean'){
                  this.suspension$.next(suspension)
                  this.modeEdit= true
                  this.router.navigate(['app/suspension/edit']);
                  this.waitingOperation$.next(false)
                }
              },
              (error)=> {
                this.waitingOperation$.next(false)
                this.toast.showError(error.error); 
              }
            )       
             break;
          default:
             break;
         }
     }
    )
   
  }

  cloturer(cloture:SuspensionRappel){
    let message:string=''
    switch (cloture.index) {
      case 1:
        message = 'Vous vous aprêtez à clôturer la suspension. Voulez-vous continuer?'
        break;
      
      case 2:
        message = 'Vous vous aprêtez à clôturer la cabinet. Voulez-vous continuer?'
        break;

      default:
        break;
    }
    this.demandeConfirmation(message).subscribe(
      res => {
        switch (res) {
           case true:
            this.facadeSuspension.cloturer(cloture).subscribe(
              (suspension : Suspension | boolean)=>{
                if (typeof suspension != 'boolean'){
                  this.suspension$.next(suspension)
                  this.modeEdit= true
                  this.router.navigate(['app/suspension/edit']);
                  this.waitingOperation$.next(false)
                }
              },
              (error)=>{
                this.toast.showError(error.error);
                this.waitingOperation$.next(false)
              }
            )    
             break;
          default:
             break;
         }
     }
    )
  }

  demandeConfirmation(message:string){
    const dialogOuiNon = this.dialog.open(OuiNonAnnulerComponent, { 
      width: '320px', 
      data: { 
        title: "",
        message: message        
        , yes_no_cancel: 2 
      } 
    });
    return dialogOuiNon.afterClosed()
  }

  telecharger(nomRapport:string){
    this.facadeSuspension.telecharger().subscribe(
      (data:any)=> {
        if(data != undefined) {
                  saveAs(data, nomRapport+'.pdf');
                  this.toast.showInfo('Le rapport est téléchargé.')
                }
        this.waitingRapport$.next(false)
      }
    )
  }
}
