<mat-card appearance="outlined">
   
    
    <div class="container">
        <button mat-raised-button (click)="searchingEntite()" color="primary">Rechercher une entite</button>
        <app-entite-search *ngIf="searching" (entiteEvent)="setEntite($event)"></app-entite-search>
        <app-carte-adresse [adresse]="adresseEntite" *ngIf="adresseEntite.ligne1!=''" [title]="'Entité sélectionnée'"></app-carte-adresse>
        <div style="margin:20px">
            <mat-card-title *ngIf="entiteSelected">Liste des étiquettes de l'entité {{entiteSelected?.raison_sociale}}</mat-card-title>
        </div>
        
        <table mat-table matTableFilter matTableResponsive [filterType]="filterType" [exampleEntity]="filterEntity" [dataSource]="dataSource" class="table-primary">
    
                <!-- Numéro Column -->
                <ng-container matColumnDef="numero">
                    <th mat-header-cell *matHeaderCellDef>Numéro</th>
                    <td mat-cell *matCellDef="let etiquette"> {{etiquette.numero}}</td>
                </ng-container>
    
                 <!-- Type Column -->
                 <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef>Type d'analyse</th>
                    <td mat-cell *matCellDef="let etiquette"> {{etiquette.type_analyse}}</td>
                </ng-container>

                 <!-- Date Impression Column -->
                 <ng-container matColumnDef="date_impression">
                    <th mat-header-cell *matHeaderCellDef>Date d'impression</th>
                    <td mat-cell *matCellDef="let etiquette">                         
                        <i *ngIf="etiquette.date >= '19000101'">{{etiquette.date | date: 'dd/MM/yyyy HH:mm:ss'}}</i>
                        <i *ngIf="etiquette.date < '19000101'"></i>                    
                    </td>
                </ng-container>

                <!-- Date Utilisation Column -->
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef>Date d'utilisation</th>
                    <td mat-cell *matCellDef="let etiquette">                         
                        <i *ngIf="etiquette.date_utilisation >= '19000101'">{{etiquette.date_utilisation | date: 'dd/MM/yyyy HH:mm:ss'}}</i>
                        <i *ngIf="etiquette.date_utilisation < '19000101'"></i>                    
                    </td>
                </ng-container>
                
                <!-- Bouton Imprimer Column -->
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="derniere-colonne"></th>
                    <td mat-cell *matCellDef="let etiquette">
                        <button 
                            mat-icon-button 
                            (click) = "onImprimerEtiquette(etiquette.id, etiquette.id_entite)" 
                            color   = "primary"
                            matTooltip = "Imprimer l'étiquette"
                            >
                            <mat-icon>print</mat-icon>
                        </button>                    
                    </td>
                </ng-container>
    
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>            
            </table>
            <mat-paginator [pageSizeOptions]="[10,25,50]" showFirstLastButtons></mat-paginator> 
    </div>
</mat-card>