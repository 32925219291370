export interface Analyse {
    id              : number,
    code            : string,
    idTypeAnalyse   : number,
    categorie       : string,
    designation     : string,
    idOrgane        : number,
    idFluide        : number,
    idExterne       : number,
    codeLaboratoire : string,
    actif           : boolean,
    tarif           : string,
    organe?         : string,
    fluide?         : string,
    idTypeElement   : number,
    libelle?        : string,
    affiche?        : boolean,
    action?         : string
}

export const AnalyseInitiale : Analyse = {
    id:0,
    code :'',
    idTypeAnalyse:0,
    categorie:'',
    designation:'',
    idOrgane:0,
    idFluide:0,
    idExterne:0,
    codeLaboratoire:'',
    actif:false,
    tarif:'',
    idTypeElement:0
}

export interface AnalyseFilter{
    idFluide?       : number,
    idOrgane?       : number,
    codeLabo?       : string,
    designation?    : string,
    active?         : string,
    type?           : string,
    categorie?      : string,
    affiche?        : string,
    libelle?        : string
}

export const AnalyseInitialFilter : AnalyseFilter = {"idFluide":0,"idOrgane":0, "active":"1","categorie":'',"codeLabo":'',"designation":'',"libelle":'',"type":''}
