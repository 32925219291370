import { Component, Inject, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';

import { Facture } from 'src/app/interfaces/facture/facture';
import { ModePaiement } from 'src/app/interfaces/facture/mode-paiement';
import { Reglement } from 'src/app/interfaces/facture/reglement';

import { ApiFactureService } from 'src/app/services/api-facture.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { DialogData, OuiNonAnnulerComponent } from '../../templates/oui-non-annuler/oui-non-annuler.component';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { NgIf, NgStyle, NgFor, DecimalPipe } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';

@Component({
    selector: 'app-reglement',
    templateUrl: './reglement.component.html',
    styleUrls: ['./reglement.component.scss'],
    standalone: true,
    imports: [MatDialogModule, MatDividerModule, ReactiveFormsModule, NgIf, MatCardModule, MatCheckboxModule, MatFormFieldModule, MatInputModule, NgStyle, MatSelectModule, NgFor, MatOptionModule, MatButtonModule, DecimalPipe]
})
export class ReglementComponent implements OnInit, OnChanges {

  CONST_MODE_PAIEMENT : string = 'paiement' as const;
  CONST_MODE_REMBOURSEMENT : string = 'remboursement' as const;
  CONST_MODE_AVOIR : string = 'avoir' as const;


  isPaiement: boolean = true
  withPaiement: boolean = true;

  // variable pour le paiement
  sommePaiements: number = 0;
  sommeDue: number = 0;
  commentaireDefault: string = '';
  paiement: ModePaiement = <ModePaiement>{};

  titre: string = '';
  dataSource: any = {};
  modePaiement$: Array<any> = [];
  facture$: Facture = <Facture>{};
  reglements: Reglement[] = [];
  formReglement: any = {};

  constructor(
    public dialogRef: MatDialogRef<ReglementComponent>
    , @Inject(MAT_DIALOG_DATA) public data: DialogData
    , private _fb: FormBuilder
    , private apiFacture: ApiFactureService
    , private toast: PersoSnackbarService
    , private dialog: MatDialog
  ) {

  }

  ngOnInit(): void {
    //
    this.dataSource = this.dialogRef._containerInstance._config.data;
    this.facture$ = this.dialogRef._containerInstance._config.data.facture;
    this.reglements = this.dialogRef._containerInstance._config.data.reglements;
    // description du formulaire
    this.isPaiement = (this.dataSource.mode != this.CONST_MODE_AVOIR);
    this.formReglement = this._fb.group({
      id: [0],
      idFacture: [this.facture$.id],
      ModePaiement: [{}, [Validators.required]],
      dateReglement: [Date, [Validators.required]],
      libelle: ['', [Validators.required]],
      commentaire: ['', [Validators.required]],
      montant: [0, [Validators.required, Validators.min( this.dataSource.mode === this.CONST_MODE_PAIEMENT ? 0 : -999999), Validators.max( this.dataSource.mode === this.CONST_MODE_PAIEMENT ? 999999 : 0)]]
    });

    // valeur par defaut des champs
    this.setDefaultValues();
  }

  ngOnChanges(): void {
    this.checkValidators;
  }

  // sélection du mode de paiement de l'avoir
  public onSelectTypeOfPaiement($event: any) {
    this.formReglement.patchValue({
      ModePaiement: $event
    })
  }
  // enregistrement du remboursement de la facture
  public onSaveCreditNote() {
    // Si le creation de l'avoir se fait avec un règlement...
    if ( this.withPaiement ) {

      if (this.formReglement.controls.montant.value > 0) {
        this.toast.showInfo("Vous ne pouvez pas saisir de montant positif sur un avoir de facture !")
        return
      }
    } else {
      // pas de remboursement (pour le moment:  saisie ultérieure possible)
      this.formReglement.controls.montant.enable()
      this.formReglement.controls.dateReglement.enable()
      this.formReglement.controls.libelle.enable()
      this.formReglement.patchValue({
        id: 0,
        idFacture: this.facture$.id,
        ModePaiement: this.modePaiement$[0],
        dateReglement: Date,
        libelle: '',
        commentaire: '',
        montant: 0
      })
    }
    // confirmation du règlement    
    const dialogOuiNon = this.dialog.open(OuiNonAnnulerComponent, {
      width: '320px',
      data: {
        title: "Saisie d'un avoir sur facture",
        message: "Voulez-vous enregistrer l'avoir de la facture " + this.facture$.numero + (this.formReglement.controls.montant.value === 0 ? " sans remboursement ?" : " pour un montant de " + this.formReglement.controls.montant.value + " € ?")
        , yes_no_cancel: 2
      }
    });

    dialogOuiNon.afterClosed()
      .subscribe(
        res => {
          switch (res) {
            case true:
              // eregistrement de l'avoir de la facture
              this.apiFacture.setCreditNote(this.formReglement.value)
                .subscribe(
                  (data: Reglement) => {
                    this.toast.showInfo('Avoir enregistré');
                    this.dialogRef.close(true);
                  },
                  err => {
                    console.log(err)
                  }
                )
              break;

            default:
              break;
          }
        });


  }
  // enregistrement du reglement de la facture
  public onAddPaiement() {
    if (this.formReglement.controls.montant.value < 0) {
      this.toast.showInfo("Vous ne pouvez pas saisir de montant négatif pour un règlement de facture !")
      return
    }

    if (this.formReglement.controls.montant.value > this.sommeDue) {
      this.toast.showInfo("Attention, la somme réglé est supérieure à la somme due.")
      // return
    }

    // confirmation du règlement   
    const dialogOuiNon = this.dialog.open(OuiNonAnnulerComponent, {
      width: '320px',
      data: {
        title: "Règlement d'une facture",
        message: (this.formReglement.controls.montant.value > this.sommeDue ? `<b><span style="color: #FF4081;">Attention !<br> La somme réglée est supérieure à la somme due.</span></b>
        <br><br>
        `:``) + `Voulez-vous enregistrer le règlement de ` + this.formReglement.controls.montant.value + ` € ?`
        , yes_no_cancel: 2
      }
    });

    dialogOuiNon.afterClosed()
      .subscribe(
        res => {
          switch (res) {
            case true:
              // envoi du reglement en base de données
              this.apiFacture.setReglement(this.formReglement.value)
                .subscribe(
                  (data: Reglement) => {
                    this.toast.showInfo('Enregistrement du règlement');
                    this.dialogRef.close(true);
                  },
                  err => {
                    console.log(err)
                  }
                )
              break;

            default:
              break;
          }
        });

  }
  // Ajout d'un remboursement
  public onAddCredit() {
    if (this.formReglement.controls.montant.value > 0) {
      this.toast.showInfo("Vous ne pouvez pas saisir de montant positif pour un remboursement de facture !")
      return
    }
    // confirmation du règlement   
    const dialogOuiNon = this.dialog.open(OuiNonAnnulerComponent, {
      width: '320px',
      data: {
        title: "Remboursement d'une facture",
        message: "Voulez-vous enregistrer le règlement de " + this.formReglement.controls.montant.value + " € ?"
        , yes_no_cancel: 2
      }
    });

    dialogOuiNon.afterClosed()
      .subscribe(
        res => {
          switch (res) {
            case true:
              // envoi du reglement en base de données
              this.apiFacture.setReglement(this.formReglement.value)
                .subscribe(
                  (data: Reglement) => {
                    this.toast.showInfo('Enregistrement du règlement');
                    this.dialogRef.close(true);
                  },
                  err => {
                    console.log(err)
                  }
                )
              break;

            default:
              break;
          }
        });

  }

  private setDefaultValues() {

    // 1. quelle est la somme déjà versé ? la même somme versé pour la facture.
    if (this.reglements != undefined) {
      this.reglements.forEach(element => {
        this.sommePaiements += element.montant;
      });
    }
    // est ce un paiement ?
    if ( this.dataSource.mode === this.CONST_MODE_PAIEMENT ) {
      this.titre = 'REGLEMENT sur'
      // c'est un règlement d'une facture
      this.modePaiement$ = [
        { id: 0, code: this.apiFacture.CONST_MODE_PAIEMENT_CHEQUE, libelle: 'Chèque' }
        , { id: 0, code: this.apiFacture.CONST_MODE_PAIEMENT_VIREMENT, libelle: 'Virement' }
      ];
      // Commentaire de paiement
      this.commentaireDefault = 'Règlement de la ' + this.facture$.type_document + ' N° ' + this.facture$.numero + '. ';
      this.withPaiement = true;
      this.sommeDue = Math.round( (this.facture$.totalTTC - this.sommePaiements) * 100 ) / 100;
      if (this.sommeDue < 0) {
        this.sommeDue = 0;
      }
    } else {
      if ( this.dataSource.mode === this.CONST_MODE_AVOIR ) {
        this.titre = 'AVOIR sur'
      } else {
        this.titre = 'REMBOURSEMENT sur'
      }
      // c'est un avoir
      this.modePaiement$ = [
        { id: 0, code: this.apiFacture.CONST_MODE_PAIEMENT_AUCUN, libelle: 'Aucun' }
        , { id: 0, code: this.apiFacture.CONST_MODE_PAIEMENT_CHEQUE, libelle: 'Chèque' }
        , { id: 0, code: this.apiFacture.CONST_MODE_PAIEMENT_VIREMENT, libelle: 'Virement' }
      ];
      // Commentaire de remboursement
      this.commentaireDefault = 'Annulation de la ' + this.facture$.type_document + ' N° ' + this.facture$.numero + '. ';
      this.commentaireDefault += (this.sommePaiements == 0 ? ' Aucun remboursement' : 'Remboursement de ' + this.sommePaiements.toString() + ' €. ')
      this.withPaiement = (this.sommePaiements > 0);
      this.sommePaiements *= -1
      if (this.sommePaiements > 0) {
        this.sommePaiements = 0;
        this.toast.showInfo("Vous ne pouvez pas saisir de montant positif sur un avoir de facture !")
        return
      }
      // aucun remboursement => aucun requirement de validation de formulaire
      if (this.sommePaiements == 0) {
        this.formReglement.get('montant').clearValidators()
        this.formReglement.get('ModePaiement').clearValidators()
        this.formReglement.get('dateReglement').clearValidators()
        this.formReglement.get('libelle').clearValidators()

        this.formReglement.controls.montant.clearValidators()
        this.formReglement.controls.ModePaiement.clearValidators()
        this.formReglement.control.dateReglement.clearValidators()
        this.formReglement.control.libelle.clearValidators()
        
        this.formReglement.clearValidators()
      }
    }

    // pour quelle date
    let todayISOString : any = new Date().toISOString();
    this.formReglement.patchValue({
      montant: (this.isPaiement ? this.sommeDue : this.sommePaiements),
      dateReglement: todayISOString.substring(0, 10),
      ModePaiement: (this.sommePaiements == 0 ? this.modePaiement$[0] : null),
      commentaire: this.commentaireDefault
    })
  }
  //
  public getBackgroundColorByStatus() {
    let valeur: string = '';
    switch ( this.dataSource.mode === this.CONST_MODE_PAIEMENT ) {
      case true:
        valeur = 'rgb(39,58,130)';
        break;
      case false:
        valeur = '#FF4081';
        break;
      default:
        break;
    }
    return valeur;
  }

  public checkValidators() {
  if ( this.withPaiement ) {
    // this.formReglement.get('montant').setValidators([Validators.required, Validators.min( this.dataSource.mode === this.CONST_MODE_PAIEMENT ? 0 : -999999), Validators.max( this.dataSource.mode === this.CONST_MODE_PAIEMENT ? 999999 : 0)])
    // this.formReglement.get('ModePaiement').setValidators(Validators.required)
    // this.formReglement.get('dateReglement').setValidators(Validators.required)
    // this.formReglement.get('libelle').setValidators>(Validators.required)
    this.formReglement.controls.montant.enable()
    // this.formReglement.controls.ModePaiement.enable()
    this.formReglement.controls.dateReglement.enable()
    this.formReglement.controls.libelle.enable()
  } else {
    this.formReglement.controls.montant.disable()
    // this.formReglement.controls.ModePaiement.disable()
    this.formReglement.controls.dateReglement.disable()
    this.formReglement.controls.libelle.disable()
    this.formReglement.patchValue({
      ModePaiement: this.modePaiement$[0]
    })
    // this.formReglement.clearValidators()
    // this.formReglement.controls.commentaire.setValidators(Validators.required)
  }
  this.formReglement.patchValue({
    commentaire: 'Annulation de la ' + this.facture$.type_document + ' N° ' + this.facture$.numero +'. '+ ( this.withPaiement ? 'Remboursement de ' + this.sommePaiements.toString() + ' €. ' : ' Sans remboursement')
  })
  this.formReglement.updateValueAndValidity();  
  }

  public setCredit() {
    this.withPaiement = !this.withPaiement;

    this.checkValidators()
  }

}
