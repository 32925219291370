export interface EtatCoordonneesBancaire {
    id      : number;
    code    : string;
    libelle : string;    
}

export const EtatCoordonnesBancaireInit : EtatCoordonneesBancaire ={
    'code'      : ''
    ,'id'       : 0
    ,'libelle'  : ''
}
