import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TypeSuspension, TypeSuspensionInitial } from '../../interfaces/suspension';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { Subscription } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';

@Component({
    selector: 'app-edit-type-suspension',
    templateUrl: './edit-type-suspension.component.html',
    styleUrls: ['./edit-type-suspension.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatExpansionModule, MatButtonModule, MatIconModule]
})
export class EditTypeSuspensionComponent implements OnInit {

  @Input()  typeSuspension : TypeSuspension = TypeSuspensionInitial
  @Output() typeSuspensionToSave = new EventEmitter<TypeSuspension>()
  typeSuspensionForm : FormGroup = new FormGroup({})

  constructor(private formBuilder:FormBuilder
              , private router        : Router
  ) { }

  ngOnInit(): void {
    this.initFormGroup()
  }

  initFormGroup(){
    
    this.typeSuspensionForm = this.formBuilder.group({
      nom   : [this.typeSuspension? this.typeSuspension.nom : ''  , Validators.required],
      code  : [this.typeSuspension? this.typeSuspension.code : '' ]
    })
    
  }

  saveTypeSuspension(){
    const typeSuspensionASauver : TypeSuspension = {...this.typeSuspension
                                                      ,"nom" : this.typeSuspensionForm.controls['nom'].value
                                                      , "code" : this.typeSuspensionForm.controls['code'].value}
    this.typeSuspensionToSave.next(typeSuspensionASauver)
  }
  
  cancelTypeSuspension(){
    this.typeSuspensionToSave.next()
  }
}
