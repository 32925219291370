<mat-card appearance="outlined" *ngIf="commande.id<=0" (window:resize)="onResize($event)">
    <div *ngIf="onPhoneModePortrait()">
        <mat-icon color="accent">info</mat-icon>
        <label for="" class="label-accent">Pour un meilleur confort visuel, il est recommandé d'orienter votre appareil dans le sens horizontal.</label>
    </div>
    <mat-stepper orientation="vertical" [ngClass]="{openUpStepper: isOpen == true}" #stepper [linear]="isLinear" (selectionChange)="onStepChange($event)">
        <!-- description de l'outil diagnostic -->
        <mat-step *ngIf="codeCategorie == apiCommande.CONST_CATEGORIE_OUTIL_DIAG" label="Outil de diagnostic">
            <div class="div-container">
                <p class="label-indication"
                    style="font-style: italic; font-size: 1.6em!important; font-weight: bold; padding: 16px;">"Un outil
                    diag avec une longueur d'avance"</p>
            </div>

            <div class="div-container">
                <div>
                    <p>
                        <a href="../../../../assets/img/outil_diagnostic.jpg" target="_blank"><img class="img-contain"
                                src="../../../../assets/img/outil_diagnostic.jpg" alt="" height="220"
                                style="padding: 16px;"></a>
                    </p>
                </div>
                <div>
                    <p class="span-alert label-h3" style="padding: 16px;">Cahier des charges défini par des Experts en
                        Automobiles</p>
                    <ul class="label-primary" style="list-style-type: none;">
                        <li>
                            <mat-icon color="primary" style="font-size: 14px!important;">check_circle</mat-icon> Outil
                            de lecture conçu pour répondre aux besoins de l'Expert
                        </li>
                        <li>
                            <mat-icon color="primary" style="font-size: 14px!important;">check_circle</mat-icon>
                            Intégration simplifiée à notre environnement de travail
                        </li>
                        <li>
                            <mat-icon color="primary" style="font-size: 14px!important;">check_circle</mat-icon> Assure
                            l'indépendance dans ses opérations d'expertises
                        </li>
                        <li>
                            <mat-icon color="primary" style="font-size: 14px!important;">check_circle</mat-icon>
                            Formation produit spécifique et dédiée aux Experts
                        </li>
                        <li>
                            <mat-icon color="primary" style="font-size: 14px!important;">check_circle</mat-icon>
                            Connexion sans fil avec une tablette PC Windows
                        </li>
                        <li>
                            <mat-icon color="primary" style="font-size: 14px!important;">check_circle</mat-icon> Prise
                            en main intuitive et scan rapide du véhicule
                        </li>
                        <li>
                            <mat-icon color="primary" style="font-size: 14px!important;">check_circle</mat-icon>
                            Évolution constante
                        </li>
                        <li>
                            <mat-icon color="primary" style="font-size: 14px!important;">check_circle</mat-icon>
                            Assistance technique Hotline
                        </li>
                    </ul>
                </div>
            </div>
            <div class="div-container" style="padding: 32px;">
                <div>
                    <p style="font-weight: 400;">Référence :<span class="span-alert label-h3"> VS9166</span></p>
                    <p>Désignation : <span class="label-primary label-h3"> MDS 105, Outil de Diagnostic incluant le
                            développement spécifique expert</span> </p>
                    <p style="font-weight: 400;">Référence : <span class="span-alert label-h3"> VC7411</span></p>
                    <p>Désignation : <span class="label-primary label-h3">Mise à jour & Hot line technique pendant 36
                            mois</span></p>
                    <p>
                        <button mat-button color="primary" (click)="readMore()">
                            <mat-icon>more_horiz</mat-icon> En savoir plus sur l'outil FFEA
                        </button>
                    </p>
                    <div *ngIf="isVisibleEnSavoirPlus == true" class="label-primary mat-card-primary"
                        style="padding: 16px;">
                        <ul>
                            <li>Prise OBD + logiciel de diagnostic pour l'installation sur un PC. Branchement par USB ou
                                Bluetooth</li>
                            <li>Utilisable dans chaque véhicule particulier et véhicule utilitaire courant</li>
                            <li>Diagnostic multimarque complet</li>
                            <li>Avec fonction de diagnostic global</li>
                            <li>Utilisation facile : seulement 3 clics pour sélectionner le véhicule</li>
                            <li>Scan de véhicule ultra rapide</li>
                            <li>Vue d'ensemble des états immédiatement sous les yeux</li>
                            <li>Bluetooth avec puissance d'émission double</li>
                            <li>Robustesse électrique et mécanique</li>
                            <li>Boîtier résistant aux rayures et aux projections d'eau</li>
                            <li>Fonction "Lampe de poche" intégrée</li>
                            <li>Sonnerie et cordon pour protection contre la perte</li>
                            <li>Indentification automatique du code moteur</li>
                            <li>Reconnaissance automatisée des véhicules diesel / essence</li>
                            <li>Filtrage des fonctions et valeurs mesurées en temps réel</li>
                            <li>Lecture automatique du (des) VIN et du kilométrage</li>
                            <li>Affichage permanent du statut de la connexion véhicule</li>
                            <li>Contrôle continu de la tension de bord</li>
                            <li>Impression ou sauvegarde des lectures</li>
                        </ul>
                    </div>


                </div>
            </div>
            <div class="div-container" style="padding: 32px;">
                <button mat-raised-button matStepperNext color="primary" class="button-standard"
                    style="width: 180px;">Commander / Louer</button>
                <!-- <button mat-raised-button color="basic" class="button-standard" style="width: 180px;">Mettre à jour</button> -->
                <a mat-stroked-button href="{{ apiParam.urlMajOutilDiagnostic }}" color="primary" target="_blank" class="link-active">
                    <mat-icon class="blue-icon link-icon">next_week</mat-icon>
                    <span>Mettre à jour</span>
                </a>
            </div>

        </mat-step>

        <!-- formulaire Entite (codeCategorie == apiCommande.CONST_CATEGORIE_KIT_OUTIL_DIAG) -->
        <mat-step *ngIf="true" label="Sélectionnez votre entité">
            <form [formGroup]="infoFacture">
                <div>
                    <mat-form-field  >
                        <mat-label>Entité</mat-label>
                        <mat-select
                                formControlName     = "entite"
                                (selectionChange)   = "onSelectEntite($event)" 
                                required
                                >
                            <mat-option 
                                *ngFor="let entite of filterEntitesOfType(true)" 
                                [value]     = "entite.id" 
                                [disabled]  = "entite.coordonnees_bancaire.etat.code!=apiEntite.CONST_CODE_ETAT_COORDONNEES_BANCAIRE_VALIDE">
                                {{entite.raison_sociale}}
                            <span *ngIf="entite.coordonnees_bancaire.etat.code!=apiEntite.CONST_CODE_ETAT_COORDONNEES_BANCAIRE_VALIDE" style="color: red;">  -- Coordonnées bancaires non validées</span></mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div *ngIf="entite" class="div-container-left" style="align-items: stretch;margin-bottom: 20px;">
                        <app-carte-adresse  [adresse]="adresseEntite"></app-carte-adresse>
                        <app-carte-entite [entite]="entite"></app-carte-entite>
                    </div>
                </div>    
                <div class="tel">
                    <mat-form-field>
                        <mat-label for="telephone">téléphone: </mat-label>
                        <input 
                            matInput 
                            type            = "text" 
                            formControlName = "telephone" 
                            placeholder     = "Numéro de téléphone"
                            required 
                            appTelephone>
                        <mat-error *ngIf="telephone?.invalid && (telephone?.dirty || telephone?.touched)">{{telephone!.errors!.dixchiffres}}</mat-error>
                    </mat-form-field>
    
                    <mat-form-field>
                        <mat-label>Email du destinataire</mat-label>
                        <input 
                            matInput
                            type            = "text" 
                            type            = "mail" 
                            formControlName = "mailExpert"  
                            required
                            >
                        <mat-error *ngIf="mailExpert?.invalid && (mailExpert?.dirty || mailExpert?.touched)">{{mailExpert!.errors!.checkValidatorMail}}</mat-error>
                    </mat-form-field>
                    
                    <mat-form-field>
                        <mat-label for="mail">mail de facturation : </mat-label>
                        <input 
                            matInput 
                            type            = "text" 
                            formControlName = "mail" 
                            placeholder     = "mail" 
                            required
                            >
                        <mat-error *ngIf="mail?.invalid && (mail?.dirty || mail?.touched)">{{mail!.errors!.checkValidatorMail}}
                        </mat-error>
                    </mat-form-field>
                </div>
                 
                
                <div class="align-center">
                    <button mat-raised-button color="primary" matStepperNext class="button-standard"
                        [disabled]="!infoFacture.valid">Suivant</button>
                </div>
                
            </form>
        </mat-step>

        <!-- formulaire Entite (codeCategorie == apiCommande.CONST_CATEGORIE_KIT_OUTIL_DIAG) -->
        <mat-step *ngIf="true" label="Sélectionnez votre adresse de livraison">
            <br>
            <form [formGroup]="infoLivraison">
                <div style="margin-bottom: 10px;">
                    <button mat-raised-button color = "primary" (click)="OuvreSelectionAdresse()">Sélectionner une adresse</button>
                </div>
                   
                    <app-carte-adresse  [adresse]="adresseLivraisonEntite" *ngIf="adresseLivraisonEntite.ligne1!=''"></app-carte-adresse>

                    <div class="align-center">
                        <button mat-raised-button color="primary" matStepperNext class="button-standard"
                            [disabled]="!infoLivraison.valid">Suivant</button>
                    </div>
            </form>
        </mat-step>

        <!-- Choix de l'entité : codeCategorie == apiCommande.CONST_CATEGORIE_KIT_KITADELA -->
        <mat-step *ngIf="false" label="Choix de l'entité" [stepControl]="formGroupEntite">
            <div class="align-center">
                <form [formGroup]="formGroupEntite">
                    Pour quel entité souhaitez-vous faire cette commande :
                    <mat-form-field>
                        <mat-label>Entité</mat-label>
                        <mat-select formControlName="entite">
                            <mat-option *ngFor="let entite of filterEntitesOfType(true)" [value]="entite.id">
                                {{entite.raison_sociale}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
            </div>
            <div class="align-center">
                <button mat-raised-button color="primary" matStepperNext class="button-standard"
                    [disabled]="!formGroupEntite.valid">Suivant</button>
            </div>

        </mat-step>

        <!-- Choix des articles à commander -->
        <mat-step label="Choix des articles" [stepControl]="formGroupArticle">

            <form [formGroup]="formGroupArticle">
                <div class="listeArticle mat-card-article">
                    <mat-card appearance="outlined" *ngFor="let article of listArticles" class="cardArticle">
                        <mat-card-title class="label-primary" style="font-size: 18px; font-weight: 600;">{{ article.titre }}</mat-card-title>

                        <mat-card-content class="mat-card-content" style="white-space: pre-line; color: #646464;">
                            {{ article.description }}
                        </mat-card-content>

                        <div class="div-container" style="width: 100%;">
                            <div class="prix div-container-left fullWidthOnPhone" style="vertical-align: bottom; width: 100%;">
                                <div>
                                    <span   class="label-indication"
                                            style="font-size: 14px!important; padding: 8px;">
                                        Prix HT&nbsp;:&nbsp;
                                    </span>
                                    <span *ngIf="article.remise <= 0">
                                        {{ article.prix_ht == 0 ? article.location_ht : article.prix_ht }}&nbsp;€{{article.prix_ht == 0 ? '/mois' : ''}}
                                    </span>                                      
                                    <span *ngIf="article.remise > 0" style="font-size: 14px; text-decoration: line-through; text-decoration-color: rgb(204,12,57);">
                                        {{ article.prix_ht == 0 ? article.location_ht : article.prix_ht }}&nbsp;€{{article.prix_ht == 0 ? '/mois' : ''}}
                                    </span>  
                                    <span *ngIf="article.remise > 0">
                                        &nbsp;{{ article.prix_ht == 0 ? article.location_ht - article.location_ht * article.remise / 100 : article.prix_ht - article.prix_ht * article.remise / 100 }}&nbsp;€{{article.prix_ht == 0 ? '/mois' : ''}}
                                    </span>                                                                                             
                                </div>                                
                            </div>

                            <div *ngIf="article.remise > 0" style="width: 100%;">
                                <div>
                                    <span style="padding: 8px;" class="div-container-remise">
                                        - {{article.remise}}% {{article.txt_remise}}
                                     </span> 
                                </div>                                
                            </div>  

                            <div class="quantite div-container-right fullWidthOnPhone">
                                <button 
                                    mat-mini-fab color
                                    matTooltip = "Retirer un article"
                                    color       = "primary" 
                                    (click)     = "decreaseQuantity(article.id)"
                                    [disabled]  = "commande.id > 0"
                                    >
                                    <mat-icon><span class="material-symbols-outlined">
                                        exposure_neg_1
                                        </span></mat-icon>
                                </button>

                                <div style="font-size: 24px; padding: 0 8px;">
                                    {{ tabQuantite[article.id] }}
                                </div>

                                <button 
                                    mat-mini-fab color 
                                    matTooltip  = "Ajouter un article"
                                    color       = "primary" 
                                    (click)     = "increaseQuantity(article.id)"
                                    [disabled]  = "commande.id > 0"
                                    >
                                    <mat-icon>plus_one</mat-icon>
                                </button>
                                <button mat-icon-button color="basic" aria-label="Corbeille"
                                    (click)="emptyQuantity(article.id)" [disabled]="commande.id > 0" style="width: 42px; color: #646464;">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </div>
                    </mat-card>
                    <div *ngIf="isLocation">
                        <p *ngFor="let option of $listOptions">{{ option.description }}</p>
                    </div>
                </div>
                <input style="display:none" matInput type="number" formControlName="quantite_total"
                    [(ngModel)]="quantite_total">
                <br>
                <div class="align-center">
                    <button mat-raised-button color="warn" matStepperPrevious class="button-standard"
                        (click)="supprimerArticles()">Précédent</button>
                    <button mat-raised-button color="primary" matStepperNext (click)="ajouterArticles()"
                        class="button-standard" [disabled]="!formGroupArticle.valid">Suivant</button>
                </div>
            </form>
        </mat-step>

        <!-- Récapitulatif de la commande -->
        <mat-step label="Récapitulatif de votre commande">
            <form [formGroup]="formValidation">
                <div *ngIf="codeCategorie == apiCommande.CONST_CATEGORIE_OUTIL_DIAG" style="padding: 16px;">
                    <div *ngIf="!isLocation" style="text-align: center;">
                        <p class="label-primary">Comment souhaitez-vous régler votre commande ?</p>
                        <mat-radio-group>
                            <mat-radio-button value="1" style="width: 33%; margin-right: 64px; min-width: 200px;">
                                <!-- width: 33%; margin-right: 64px; min-width: 200px; -->
                                <!-- <mat-card class="label-primary mat-card-primary style="text-align: left; height: 200px;" -->
                                <div class="label-primary mat-card-primary">
                                    <p class="label-primary" style="font-size: 1.6em; font-weight: bold;">Par chèque :
                                    </p>
                                    <p>À l'ordre de <span style="font-size: 1.2em;">AR&D</span>.</p>
                                    <p>À envoyer à :</p>
                                    <!-- <div style="font-size: 18px;"> -->
                                        <ul style="list-style-type: none;">
                                            <li>AR&D</li>
                                            <li>41-43 rue des Plantes</li>
                                            <li>75014 Paris</li>
                                        </ul>
                                    <!-- </div> -->
                            
                                </div>
                                <!-- </mat-card> -->
                            </mat-radio-button>
                            <mat-radio-button value="2" style="width: 33%;">
                                <!-- <mat-card class="label-primary mat-card-primary" -->
                                    <div class="label-primary mat-card-primary">

                                        <!-- style="text-align: left; height: 200px;"> -->
                                        <p class="label-primary" style="font-size: 1.6em; font-weight: bold;">Par virement
                                            bancaire :</p>
                                        <p>FFEA RECHERCHE ET DEVELOPPEMENT, </p>
                                        <p>BANQUE SOCIÉTÉ GÉNÉRALE</p>
                                        <ul>
                                            <li>IBAN : FR76 3000 3015 5000 0202 1524 696</li>
                                            <li>BIC : SOGEFRPP</li>
                                        </ul>
                                        <p class="label-accent">En indiquant votre numéro de facture lors du virement</p>
                                    </div>
                                    <!-- </mat-card> -->
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>

                </div>

                <!-- tableau recapitulatif de commande -->
                <ng-container *ngIf="onPhoneModePortrait(); then DivInColumnn else TableInLine"></ng-container>
                <ng-template #DivInColumnn>
                    <div *ngFor=" let item of commande.tab_LigneCommande"
                    style="border-bottom: 1px solid rgb(39,58,130); margin-top: 8px;">
                        <ul style="list-style-type: none;">
                            <li><span class="label-totaux">{{ item.quantite }} x </span><span class="label-totaux-libelle">{{ item.article }}</span></li>
                            <li>PUHT : {{ item.prix_unitaire_ht }}</li>
                            <li>% Remise : {{ item.pourcentage_remise }}&nbsp;%</li>                            
                            <div style="width: 100%; text-align: right; ">
                                Total HT : <span style="color: rgb(39,58,130); font-size: 1.6em;"> {{ item.total_ht }}&nbsp;€ </span>
                                Total Remise HT : <span style="color: rgb(39,58,130); font-size: 1.6em;"> {{ item.total_remise_ht }}&nbsp;€ </span>
                            </div>
                        </ul>
                    </div>
                </ng-template>
                <ng-template #TableInLine>
                    <div class="container">
                        <table mat-table [dataSource]="dataSource" class="tableArticle">
                            <!-- Article Column -->
                            <ng-container matColumnDef="article">
                                <th mat-header-cell *matHeaderCellDef> Article </th>
                                <td mat-cell *matCellDef="let ligne_commande"> {{ligne_commande.article}} </td>
                            </ng-container>
    
                            <!-- Quantité Column -->
                            <ng-container matColumnDef="quantite">
                                <th mat-header-cell *matHeaderCellDef> Quantité </th>
                                <td mat-cell *matCellDef="let ligne_commande"> {{ligne_commande.quantite}} &nbsp;&nbsp;x
                                </td>
                            </ng-container>
    
                            <!-- PUHT Column -->
                            <ng-container matColumnDef="prix_unitaire_ht">
                                <th mat-header-cell *matHeaderCellDef> Prix unitaire HT </th>
                                <td mat-cell *matCellDef="let ligne_commande">{{ligne_commande.prix_unitaire_ht}}&nbsp;€ </td>
                            </ng-container>
    
                            <!-- Total HT Column -->
                            <ng-container matColumnDef="total_ht">
                                <th mat-header-cell *matHeaderCellDef> Total HT </th>
                                <td mat-cell *matCellDef="let ligne_commande"> {{ligne_commande.total_ht}}&nbsp;€ </td>
                            </ng-container>
    
                            <!-- / mois -->
                            <ng-container matColumnDef="par_Mois">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let ligne_commande"> {{ isLocation ? '/ mois' : '' }}</td>
                            </ng-container>

                            <!-- % Remise Column -->
                            <ng-container matColumnDef="pourcentage_remise">
                                <th mat-header-cell *matHeaderCellDef> % Remise </th>
                                <td mat-cell *matCellDef="let ligne_commande"> {{ligne_commande.pourcentage_remise}}&nbsp;% </td>
                            </ng-container>

                            <!-- Total Remise HT Column -->
                            <ng-container matColumnDef="total_remise_ht">
                                <th mat-header-cell *matHeaderCellDef> Total remise HT </th>
                                <td mat-cell *matCellDef="let ligne_commande"> {{ligne_commande.total_remise_ht}}&nbsp;€ </td>
                            </ng-container>
    
                            <!-- TVA Column -->
                            <ng-container matColumnDef="tauxTVA">
                                <th mat-header-cell *matHeaderCellDef>TVA</th>
                                <td mat-cell *matCellDef="let ligne_commande"> {{ ligne_commande.tauxTVA }} %</td>
                            </ng-container>
    
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>
                </ng-template>

                <!-- recapitulatif d'adresse de livraison/facturations -->
                <div class="div-container-between">
                    <div class="adresseLivraison2 div-padding">
                        <div *ngIf="isSameAddress">
                            <app-carte-adresse  [adresse]="adresseLivraisonEntite" [title]="'Livr. et Fact.'"></app-carte-adresse>
                        </div>
                        <div *ngIf="!isSameAddress">
                            <app-carte-adresse  [adresse]="adresseEntite" [title]="'Facturation'"></app-carte-adresse>
                            <app-carte-adresse  [adresse]="adresseLivraisonEntite" [title]="'Livraison'"></app-carte-adresse>
                        </div>
                    </div>
                    <mat-divider [vertical]="true"></mat-divider>
                    <div class="div-padding div-container-LineOrRow">
                        <div *ngFor="let element of dataSource2; index as index; last as isLast" class="div-container-right" style="text-align: right;">
                                <div class="totauxLibelle" [ngStyle]="{ 'font-weight' : isLast ? 'bold' : '300' }">{{element.libelle}}</div>
                                <div class="totauxPrix" [ngStyle]="{ 'font-weight' : isLast ? 'bold' : '300', 'font-size': isLast ? '1.6em':'1.2em' }">{{element.prix}}&nbsp;€</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="isLocation" class="div-container div-padding"
                    style="padding: 32px; text-align: center; flex-direction: column; justify-content: space-between; align-items: baseline;">
                    <div style="text-align: left;">
                        <p>Votre demande de location sera soumise à l'acceptation de notre organisme financier partenaire.</p>
                        <p>Le paiement se fera par prélèvement via n° SIRET</p>
                        <div *ngIf="isLocation">
                            <p *ngFor="let option of $listOptions">{{ option.description }}</p>
                        </div>
                        <!-- <p>Au terme des 36 mois, une contrepartie de 100€ HT vous sera demandé pour l'achat du matériel.</p> -->
                    </div>
                    <div style="text-align: center;">
                        <section>
                            <mat-checkbox formControlName="acceptCondition" required>
                                En cochant cette case vous acceptez les conditions de paiement et location de cet
                                appareil.
                            </mat-checkbox>
                        </section>
                    </div>
                </div>

                <div class="div-container align-center" style="padding: 32px;">
                    <button mat-raised-button color="warn" matStepperPrevious class="button-standard"
                        (click)="supprimerArticles()"><mat-icon width="24">west</mat-icon>Précédent</button>
                    <button mat-raised-button color="primary"
                        (click)="dialogConfirmOrder()"
                        [disabled]="!formValidation.valid">
                        <ng-container *ngIf="waitingOrder; then spinnerOrder else ValidOrder"></ng-container>
                        <ng-template #spinnerOrder><div class="div-container"><mat-spinner class="mat-spinner" diameter="24"></mat-spinner><span>{{ !isLocation ? 'Valider la commande' : 'Soumettre la location' }}</span></div></ng-template>
                        <ng-template #ValidOrder><div class="div-container"><mat-icon width="24">shopping_cart_checkout</mat-icon><span>{{ !isLocation ? 'Valider la commande' : 'Soumettre la location' }}</span></div></ng-template>
                    </button>
                </div>
            </form>
        </mat-step>
    </mat-stepper>
</mat-card>

<!-- Récapitulatif de validation de la commande -->
<mat-card appearance="outlined" *ngIf="commande.id>0" class="align-center-command" style="padding-top: 16px;">
    <div>
        <mat-card-title>
            <span class="material-icons">check_circle</span>
            Commande validée
        </mat-card-title>
        <mat-card-content  class=".mat-card-content"></mat-card-content>
        <p>
            Votre commande n°<strong>{{commande.numero}}</strong> du {{commande.date_creation | date: 'dd/MM/yyyy HH:mm:ss'}} a bien été enregistré.
        </p>
        <div class="div-container"> 
            <button mat-raised-button (click)="reinitialiserCommande()">Nouvelle commande</button>
            <button mat-raised-button color="primary" (click)="navigateToHome()">Retourner vers l'accueil</button>
        </div>
    </div>
</mat-card>

<app-footer></app-footer>