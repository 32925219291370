import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Filtre } from '../components/facture/mes-factures/mes-factures.component';
import { ChiffreAffaires } from '../interfaces/facture/chiffre-affaires';
import { EtatFacture } from '../interfaces/facture/etat-facture';
import { EtatPrelevement } from '../interfaces/facture/etat-prelevement';
import { Facture, MesFactures } from '../interfaces/facture/facture';
import { FiltreRecherchePrelevement } from '../interfaces/facture/filtre-recherche-prelevement';
import { HistoriqueFacture } from '../interfaces/facture/historique-facture';
import { ModePaiement } from '../interfaces/facture/mode-paiement';
import { Prelevement } from '../interfaces/facture/prelevement';
import { Reglement } from '../interfaces/facture/reglement';
import { PersoSnackbarService } from './perso-snackbar.service';

export const FiltreInitial : Filtre = {"Etats": [],"Services":[],"Types":[],"annee":'',"idEntite":0,"idUser":0,"mois":"","numero":"","debut":"","fin":"","etats":[]}
@Injectable({
  providedIn: 'root'
})

export class ApiFactureService {

  CONST_TYPE_FACTURE                        : string = 'FACTURE' as const;
  CONST_TYPE_AVOIR                          : string = 'AVOIR' as const;

  CONST_ETAT_FACTURE_1_DUE                  : string = 'DUE' as const;
  CONST_ETAT_FACTURE_2_PARTIELLEMENT_REGLE  : string = 'PARTIELLEMENT_PAYE' as const;
  CONST_ETAT_FACTURE_3_REGLE                : string = 'PAYE' as const;
  CONST_ETAT_FACTURE_4_ANNULE               : string = 'ANNULE' as const;
  CONST_ETAT_FACTURE_5_ARCHIVE              : string = 'ARCHIVE' as const;
  CONST_ETAT_FACTURE_6_AVOIR                : string = 'AVOIR' as const;

  CONST_LIB_ETAT_FACTURE_1_DUE                  : string = 'Dûe' as const;
  CONST_LIB_ETAT_FACTURE_2_PARTIELLEMENT_REGLE  : string = 'P. Payé' as const;
  CONST_LIB_ETAT_FACTURE_3_REGLE                : string = 'Payé' as const;
  CONST_LIB_ETAT_FACTURE_4_ANNULE               : string = 'Annulé' as const;
  CONST_LIB_ETAT_FACTURE_5_ARCHIVE              : string = 'Archive' as const;
  CONST_LIB_ETAT_FACTURE_6_AVOIR                : string = 'Avoir' as const;

  CONST_MODE_PAIEMENT_AUCUN                 : string = 'AUCUN' as const;
  CONST_MODE_PAIEMENT_CHEQUE                : string = 'CHEQUE' as const;
  CONST_MODE_PAIEMENT_VIREMENT              : string = 'VIREMENT' as const;
  CONST_MODE_PAIEMENT_PRELEVEMENT           : string = 'PRELEVEMENT' as const;
  CONST_FIRST_YEAR                          : number = 2021
  url                         : string = '/api';
  urlCompta                   : string = '/api/compta'
  filtre                      : Filtre = <Filtre>{};
  // Subject d'interface des filtres de recherche des prelevements
  public filtre_recherche_prelevement: Subject<FiltreRecherchePrelevement> = new Subject();  

  
  // surement à supprimer ... ?
  // factures$   : Array<Facture> = new Array<Facture> ()

factures$ = this.http.put<Facture[]>(this.urlCompta+"/factures", FiltreInitial)
etats$    = this.http.get<EtatFacture[]>(this.url+"/facture/info/etat")

  constructor(private http: HttpClient
            , private toast: PersoSnackbarService) {}

  /********* Début - Prélèvement *********/
  
  public getEtatPrelevement(): Observable<EtatPrelevement[]> {
    return this.http.get<EtatPrelevement[]>(this.url + '/facture/prelevement/etats');
  }

  public postPrelevement(filtre: FiltreRecherchePrelevement): Observable<Prelevement[]> {
    return this.http.post<Prelevement[]>(this.url + '/facture/prelevements', filtre);
  }
  
  public postReglement(filtre: FiltreRecherchePrelevement): Observable<Prelevement[]> {
    return this.http.post<Prelevement[]>(this.url + '/facture/reglements', filtre);
  }

  /********* Fin - Prélèvement *********/
  
  
  public getLastFilter(): Filtre {
    return this.filtre;
  }
  
  public getEtat(): Observable<EtatFacture[]> {
    return this.http.get<EtatFacture[]>(this.url+"/facture/info/etat");
  }
  // Recherche d'une facture
  public getFacture(id: number): Observable<Facture> {
    return this.http.get<Facture>( this.url+"/facture/"+id);
  }
  // Recherche d'une facture
  public getFactureOrigine(id: number): Observable<Facture[]> {
    return this.http.get<Facture[]>( this.url+"/facture/factureorigine/"+id);
  }
  // Recherche de toutes les factures selon les conditions de recherche saisie
  public getAllFacture(filtre: Filtre): Observable<MesFactures[]> {
    this.filtre = filtre;
    return this.http.patch<MesFactures[]>(this.url+"/facture", filtre);
  }

  public getAllFactureCompta(filtre: Filtre): Observable<Facture[]> {
    return this.http.put<Facture[]>(this.urlCompta+"/factures", filtre);
  }

  // Récupération des reglement d'une facture
  public getReglement(id: number): Observable<Reglement[]> {
    return this.http.get<Reglement[]>( this.url +"/facture/reglement/"+ id);
  }
  // Récupération des reglement d'une facture
  public setReglement(objet: Reglement): Observable<any> {
    return this.http.post<any>( this.url +"/facture/reglement", objet);
  }
  // enregistrement d'un avoir
  public setCreditNote(objet: Reglement): Observable<any> {
    return this.http.post<any>( this.url +"/facture/avoir", objet);
  }

  public getExportFacture(filtre: Filtre): Observable<any> {
    return this.http.patch<any>( this.url+ "/facture/export/compta", filtre);
  }

  public getDownloadFacture(id: any, type: string): Observable<any> {
    return this.http.get(this.url + '/facture/telecharger/'+id, { responseType: "blob", headers: {'Content-Type': 'application/json', Accept: "application/pdf"} });
  }

  public getHistoriqueFacture(id: any): Observable<HistoriqueFacture[]> {
    return this.http.get<HistoriqueFacture[]>(this.url + '/facture/info/historique?id='+id);
  }

  public putRefacturation(id_facture: number, id_entite: number) {
    return this.http.get(this.url + '/facture/refacturation/depuis-existant?' + 'id_entite=' + id_entite + '&id_facture=' + id_facture);
  }

  public getAnnee(): Observable<any> {
    return this.http.get<any>( this.url + '/facture/compta/annee');
  }
  
  public getAnnees(): Array<number> {
    let years : Array<number> = [];
    const currentYear  = new Date().getFullYear();
    for (let index = this.CONST_FIRST_YEAR; index <= currentYear; index++) {
      years.push(index)
    }
    return years
  }

  public getModePaiement(): Observable<ModePaiement[]> {
    return this.http.get<ModePaiement[]>(this.url + '/facture/mode-paiement/all');
  }

  public getReglementARemettre(mode_paiement: string): Observable<Reglement[]> {
    return this.http.get<Reglement[]>(this.url + '/facture/reglement/non-remis/banque?mode_paiement='+mode_paiement);
  }

  public postRemiseReglement(reglements: Array<number>, bordereau: string, mode_paiement: string): Observable<any> {
    return this.http.post(this.url + '/facture/remise-reglement/banque?bordereau='+bordereau+'&mode_paiement='+mode_paiement, reglements, { responseType: "blob" });
  }

  // Téléchargement de l'édition des relevés d'un cabinet
  public getEditionReleves(id_entite: number): Observable<any> {
    return this.http.get(this.url + '/facture/edition/releves/'+id_entite, { responseType: "blob", headers: {'Content-Type': 'application/json', Accept: "application/octet-stream,application/pdf,image/png,image/jpeg"} });
  }

  // Récupération du chiffre d'affaires mensuel de l'année et du service sélectionné
  public getChiffreAffaires(service: string, annee: string): Observable<ChiffreAffaires[]> {
    return this.http.get<ChiffreAffaires[]>(this.url + '/facture/chiffre/affaires?service='+service+'&annee='+annee);
  }

  public getBackgroundColorByStatus(status: string) {
    switch(status) {
      case this.CONST_ETAT_FACTURE_1_DUE:
        return 'rgb(39,58,130)';
        break;
      case this.CONST_ETAT_FACTURE_2_PARTIELLEMENT_REGLE:
        return '#e48213';
        break;
      case this.CONST_ETAT_FACTURE_3_REGLE:
        return '#34CD3E';
        break;
      case this.CONST_ETAT_FACTURE_4_ANNULE:
        return '#f44336';
        break;
      case this.CONST_ETAT_FACTURE_6_AVOIR:
        return '#f44336';
        break;
      default:
        return 'rgb(39,58,130)';
        break;

    }
  }

  public getBackgroundColorByLibelle(status: string) {
    switch(status) {
      case this.CONST_LIB_ETAT_FACTURE_1_DUE:
        return 'rgb(39,58,130)';
        break;
      case this.CONST_LIB_ETAT_FACTURE_2_PARTIELLEMENT_REGLE:
        return '#e48213';
        break;
      case this.CONST_LIB_ETAT_FACTURE_3_REGLE:
        return '#34CD3E';
        break;
      case this.CONST_LIB_ETAT_FACTURE_4_ANNULE:
        return '#f44336';
        break;
      case this.CONST_LIB_ETAT_FACTURE_6_AVOIR:
        return '#f44336';
        break;
      default:
        return 'rgb(39,58,130)';
        break;

    }
  }

  public getColorFacture(type: string) {
    switch(type) {
      case this.CONST_TYPE_FACTURE:
        return 'rgb(39,58,130)'
        break;
      case this.CONST_TYPE_AVOIR:
        return '#f44336'
        break;
      default:
        return '#646464'
        break;
    }
  }

  public putModePaiement(idFacture:number,idModePaiement:number):Observable<any>{
    const modePaiementEntite = {'idEntite':idFacture,'idModePaiement':idModePaiement}
    return this.http.put<Facture>(this.url+'/facture/mode-paiement',modePaiementEntite)
  }
}
