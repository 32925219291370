import { NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { Service } from 'src/app/interfaces/dossier/service';
import { EtatFacture } from 'src/app/interfaces/facture/etat-facture';
import { MesFactures } from 'src/app/interfaces/facture/facture';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { ApiFactureService } from 'src/app/services/api-facture.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { ApiUtilitaireService } from 'src/app/services/tools/api-utilitaire.service';
import { MesChiffresAffairesComponent } from '../chiffre-affaires/mes-chiffres-affaires/mes-chiffres-affaires.component';
import { MesExportsComponent } from '../mes-exports/mes-exports.component';
import { RelevesComponent } from '../releves/releves.component';
import { MesRemisesComponent } from '../remise/mes-remises/mes-remises.component';
import { TableFactureComponent } from '../table-facture/table-facture.component';
import { EntiteSearchComponent } from '../../templates/entite-search/entite-search.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ContexteService, TYPE_FACTURE } from 'src/app/services/contexte.service';

export interface Filtre {
  idUser    : number,
  Types     : Array<string>,
	numero    : string,
	Services  : Array<Service>,
	idEntite  : number,
  mois      : string,
  annee     : string,
	dateDebut? : Date,
	dateFin?   : Date,
  debut     : string,
  fin       : string,
  etats     : Array<number>,
	Etats     : Array<EtatFacture>,
}

@Component({
    selector: 'app-mes-factures',
    templateUrl: './mes-factures.component.html',
    styleUrls: ['./mes-factures.component.scss'],
    standalone: true,
    imports: [MatTabsModule, MatCardModule, ReactiveFormsModule, MatExpansionModule, MatIconModule, NgIf, MatFormFieldModule
              , MatSelectModule, NgFor, MatOptionModule, MatInputModule, MatButtonModule, MatProgressSpinnerModule
              , TableFactureComponent, MesExportsComponent, MesRemisesComponent, MesChiffresAffairesComponent, RelevesComponent
              , EntiteSearchComponent, MatTooltipModule]
})
export class MesFacturesComponent implements OnInit {

  // constante en corélation avec les constante de l'API
  CONST_CODE_SERVICE_ADELA          = 'ADELA' as const;
  CONST_CODE_SERVICE_ADC            = 'ADC' as const;
  CONST_CODE_SERVICE_APPEL_OFFFRE   = 'AO' as const;
  CONST_CODE_SERVICE_DIAGNOSTIC     = 'DIAG' as const;

  // Gestion de l'affichage
  waitingResearch       : boolean = false;
  waitingExport         : boolean = false;
  expandPannel          : boolean = true;
  isExportVisible       : boolean = false;

  dateDuJour            : number  = 0;

  // Liste des interfaces pour les combos
  profilUtilisateur  : ProfilUtilisateur = <ProfilUtilisateur>{};
  entites            : Entite[] = [];
  services           : Array<Service> = [];
  etats              : Array<EtatFacture> = [];
  types              : Array<string> = ['FACTURE','AVOIR'];
  moi$               : Array<string> = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
  annee$             : Array<string> = [];

  // Formulaire de filtrage
  filtre             : Filtre = <Filtre>{};
  FiltreFormGroup: any;
  selected = new FormControl(0);
  
  // Tableau de factures
  export$: any = {};
  factures: Array<MesFactures> = [];    

  entiteSelected    : Entite|null =null
  searching         : boolean = false
  readonly typeContexte : string = TYPE_FACTURE

  get service(): FormArray  { return <FormArray>this.FiltreFormGroup.get('service') as FormArray }
  get type(): FormArray     { return <FormArray>this.FiltreFormGroup.get('type') as FormArray }
  get etat(): FormArray     { return <FormArray>this.FiltreFormGroup.get('etat') as FormArray }
  get facture()             { return this.FiltreFormGroup.get('facture') }
  get entite()              { return this.FiltreFormGroup.get('entite') }
  get dateDebut()           { return this.FiltreFormGroup.get('dateDebut') }
  get dateFin()             { return this.FiltreFormGroup.get('dateFin') }
  get mois()                { return this.FiltreFormGroup.get('mois')}
  get annee()               { return this.FiltreFormGroup.get('annee') }
  
  constructor(private _fb          : FormBuilder
            , private authUser     : ApiAuthentificationService
            , private apiEntite    : ApiEntiteService
            , private apiFacture   : ApiFactureService
            , private apiUtilitaire: ApiUtilitaireService
            , private toast        : PersoSnackbarService
            , public  dialog       : MatDialog
            , private routed       : ActivatedRoute
            , private contexte     : ContexteService){}
            

  ngOnInit(): void {
    
    this.factures = this.contexte.getTableData(this.typeContexte)

    if( this.apiFacture.filtre != undefined && (this.authUser.userConnect.id === this.apiFacture.filtre.idUser)) {
      this.filtre = this.apiFacture.filtre;
    } else {
      this.filtre = <Filtre>{};
    }
    
    this.profilUtilisateur = this.authUser.userConnect;
  
    // Récupération des paramètres du formulaire de filtrage
    this.chargeListeCabinet();    
    this.chargeListeService();     

    // Initialisation du formulaire de filtrage
    this.FiltreFormGroup = this._fb.group({
      idUser          : [this.profilUtilisateur.id],
      service         : [Array<number>(), [Validators.required]],
      type            : [Array<string>(), [Validators.required]],
      etat            : [Array<number>(), [Validators.required]],
      facture         : [''],
      idEntite        : [0],
      dateDebut       : ['', [this.verificationFormatDateDebut.bind(this)]],
      dateFin         : ['', [this.verificationFormatDateFin.bind(this)]]      
    });

    this.FiltreFormGroup.patchValue({
      annee$: this.annee$
    });

    this.routed.fragment
    .subscribe(tabsToSelect => {
      if ( tabsToSelect != undefined ) {
        this.selected.setValue(+tabsToSelect)
      }
    })
    this.initFilter()
    
  }

  initFilter(){
    const filtre = this.contexte.getFiltre(this.typeContexte)
    if (filtre!=null) {
      this.FiltreFormGroup.patchValue(filtre)
    }else{
      this.initDates()
    }
  }

  initDates() {
    const today = new Date();
    const lastYear = new Date(today);
    lastYear.setFullYear(today.getFullYear() - 1);
    this.FiltreFormGroup.patchValue({
      dateDebut :[lastYear.toISOString().substring(0, 10)],
      dateFin :[today.toISOString().substring(0, 10)]  
    })
   
  }

  setEntite(entite:Entite|null){
    this.searching = false
    if (entite) {
      this.entiteSelected = entite
    }
  }

  searchingEntite(){
    this.searching = true
  }

  cancelEntite(){
    this.entiteSelected = null
  }
  public verificationFormatDateDebut(fcControler: FormControl): any {
    if(this.FiltreFormGroup == undefined){
      return null;
    }
    
    let dateFin     : Date = new Date(this.FiltreFormGroup.controls.dateFin.value);
    let dateDebut   : Date = new Date(fcControler.value);
    let dateToday   : Date = new Date();
    
    dateDebut.setHours(0,0,0,0);
    dateToday.setHours(23,59,59,999); 
    
    if(dateFin != undefined) {
      dateFin.setHours(23,59,59,999);
      if(dateDebut > dateFin) { return { erreur: 'Date de début doit être antérieure à la date de fin' }}
    }        

    if(dateDebut.getTime() > dateToday.getTime()){
      return { erreur: 'Date de début doit être antérieure à la date du jour' }
    }
    
    return null;
  }
  public verificationFormatDateFin(fcControler: FormControl): any {
    if(this.FiltreFormGroup == undefined){
      return null;
    }

    let dateFin   : Date = new Date(fcControler.value);
    let dateDebut : Date = new Date(this.FiltreFormGroup.controls.dateDebut.value);
    let dateToday : Date = new Date();    
    
    dateDebut.setHours(0,0,0,0);
    dateToday.setHours(23,59,59,999); 
    dateFin.setHours(23,59,59,999);
    
    if(dateFin.getTime() > dateToday.getTime() || (dateDebut.getTime() != null && dateFin.getTime() < dateDebut.getTime())){
      return { erreur: 'Date de fin doit être antérieure à la date de début ou du jour' }
    }
    
    return null;
  }

  // recherche des factures selon les filtres demandés
  public onSearchingInvoice() { 
    let filtre      : Filtre = <Filtre>{};
    let tab_service : Array<Service> = [];
    let tab_etat    : Array<EtatFacture> = [];

    this.waitingResearch = true;
    
    filtre.idUser     = this.FiltreFormGroup.controls.idUser.value;
    filtre.numero     = this.FiltreFormGroup.controls.facture.value;
    if (this.profilUtilisateur.droits_utilisateur.droit_module_admin_compta || this.profilUtilisateur.droits_utilisateur.droit_module_admin_adela) {
      filtre.idEntite   = this.entiteSelected?.id? this.entiteSelected.id : 0;
    } else {
      filtre.idEntite   = this.FiltreFormGroup.controls.idEntite.value;
    }
    filtre.dateDebut  = this.FiltreFormGroup.controls.dateDebut.value;
    filtre.dateFin    = this.FiltreFormGroup.controls.dateFin.value;

    for(let i = 0; i < this.FiltreFormGroup.controls.service.value.length; i++) {
      let service: any;
      service = this.services.find(service => service.id == this.FiltreFormGroup.controls.service.value[i]);
      tab_service.push(service);
    }
    
    for(let i = 0; i < this.FiltreFormGroup.controls.etat.value.length; i++) {
      let etat: any;
      etat = this.etats.find(etat => etat.id == this.FiltreFormGroup.controls.etat.value[i]);
      tab_etat.push(etat);
    }

    filtre.Services   = tab_service;
    filtre.Etats      = tab_etat;
    filtre.Types      = this.FiltreFormGroup.controls.type.value;
    
    this.apiFacture.getAllFacture(filtre)
    .subscribe(
      (data: MesFactures[]) => {
          this.factures = data;          
          this.toast.showInfo('Recherche terminée');
          this.expandPannel = false;
          this.waitingResearch = false;
        },
        (err: any) => {
          console.error('/!\ err: ', err.message);
          this.waitingResearch = false;
        }
      );
  }
  
  private chargeListeCabinet() {
    if (!(this.profilUtilisateur.droits_utilisateur.droit_module_admin_compta || this.profilUtilisateur.droits_utilisateur.droit_module_admin_adela)) {
      this.apiEntite.getEntitesByIdUtilisateur(this.profilUtilisateur.id, 0, '', true)
       .subscribe(
         (data: Entite[])  => { 
          this.entites = data;
        }
      )  
    }
  }

  private chargeListeService() {
    this.apiUtilitaire.getService(this.apiUtilitaire.CONST_CODE_ENVIRONNEMENT_EXTRANET_FFEA)
    .subscribe(
      (data: Service[] ) => {
        this.services = data;
        this.chargeListeEtat();        
      },
      (err: any) => { 
        console.error('/!\ err: ', err.message); 
      }
    );
  }

  public chargeListeEtat() { 
    this.apiFacture.getEtat()
      .subscribe(
        (data: EtatFacture[]) => {
          this.etats = data;
          this.defaultSelection();
        },
        (err: any) => {
          console.error('/!\ err: ', err.message);
        }
      );
  }

  // Parcours des services FFEA et restriction des accès à ces derniers selon les droits utilisateur.
  private defaultSelection(){
    // parcours des services disponible
    for ( let index = this.services.length-1; index >= 0; index-- ) {
      // gestion des acces au service utilisateurs
      switch (this.services[index].code) {
        // Acces service : ANALYSE DE CLÉ
        case this.CONST_CODE_SERVICE_ADC: {
          if ( this.services[index].actif == false || ( !this.profilUtilisateur.droits_utilisateur.droit_module_adc && !this.profilUtilisateur.droits_utilisateur.droit_module_admin_adc && !this.profilUtilisateur.droits_utilisateur.est_admin  && !this.profilUtilisateur.droits_utilisateur.droit_module_admin_compta) ) { 
            this.services.splice(index ,1);
          } 
          break;
        }
        // Acces service : ADELA
        case this.CONST_CODE_SERVICE_ADELA: {
          if ( this.services[index].actif == false || ( !this.profilUtilisateur.droits_utilisateur.droit_module_adela && !this.profilUtilisateur.droits_utilisateur.droit_module_admin_adela && !this.profilUtilisateur.droits_utilisateur.est_admin && !this.profilUtilisateur.droits_utilisateur.droit_module_admin_compta) ) { 
            this.services.splice(index ,1);
            } 
          break;
        }
        // Acces service : OUTILS DIAGNOSTIC
        case this.CONST_CODE_SERVICE_DIAGNOSTIC: {
          if ( this.services[index].actif == false || ( !this.profilUtilisateur.droits_utilisateur.droit_module_outil_diagnostic && !this.profilUtilisateur.droits_utilisateur.est_admin  && !this.profilUtilisateur.droits_utilisateur.droit_module_admin_compta) ) { 
            this.services.splice(index ,1);
          }
          break;
        }        
        default: {
          break;
        }
      }      
    }

    let service : Array<number> = [];
    let etat    : Array<number> = [];

    if ( this.filtre.idUser == undefined ) {            
      for(let i = 0; i < this.services.length; i++) {
        service.push(this.services[i].id);
      }

      for(let i = 0; i < this.etats.length; i++) {
        etat.push(this.etats[i].id);
      }

      this.FiltreFormGroup.patchValue({
        idUser          : this.profilUtilisateur.id,
        type            : this.types,
        service         : service,
        etat            : etat,
        facture         : '',
        idEntite        : 0,
        mois            : [],
        annee           : [],
        dateDebut       : '',
        dateFin         : ''  
      });
    } else {
      for(let i = 0; i < this.filtre.Services.length; i++) {
        service.push(this.filtre.Services[i].id);
      }

      for(let i = 0; i < this.filtre.Etats.length; i++) {
        etat.push(this.filtre.Etats[i].id);
      }

      this.FiltreFormGroup.patchValue({
        service         : service,
        idUser          : this.filtre.idUser,
        type            : this.filtre.Types,
        etat            : etat,
        facture         : this.filtre.numero,
        idEntite        : this.filtre.idEntite,
        mois            : this.filtre.mois,
        annee           : this.filtre.annee,
        dateDebut       : this.filtre.dateDebut,
        dateFin         : this.filtre.dateFin  
      });
    }

  }

  ngOnDestroy(){
    this.contexte.setType(this.typeContexte)
    this.contexte.setTableData(this.factures)
    this.contexte.setFiltre(this.FiltreFormGroup.value) 
  }

}
