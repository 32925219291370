
<mat-card appearance="outlined" >
    <mat-card-title>
            <mat-icon color="primary">description</mat-icon>&nbsp; Journaux des recherches de dossier réparateur
    </mat-card-title>
    
    <mat-card-content  class=".mat-card-content">
            <mat-label *ngIf="nb_logs == 0 || logs_recherche == undefined">
                  Il n'y a pas de logs de recherche service externe.
            </mat-label>
            <div *ngIf="logs_recherche != undefined">
                  <mat-accordion *ngFor="let log of obs$ | async, index as indice">                
                        <mat-expansion-panel hideToggle="false">
                                <mat-expansion-panel-header style="padding-top: 4px !important; padding-bottom: 4px !important;">
                                    <mat-panel-title>                                    
                                          <mat-label class="grey-icon">{{ pageIndex * pageSize + indice + 1 }}. </mat-label>
                                          <mat-label>{{ log.date | date: 'dd/MM/yyyy HH:mm:ss' }}</mat-label>  
                                          <mat-label style="padding-left: 20px;">{{ log.utilisateur.nom }} {{log.utilisateur.prenom }}</mat-label>                                                                                                                                                       
                                    </mat-panel-title>      
                                    
                                    <mat-panel-description class="panel-description">                                                                                                                                                                               
                                          <div class="panel-description-entite-details">
                                                <mat-label>{{ log.service_externe.libelle }}</mat-label>
                                          </div>
                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                  
                                <mat-form-field>
                                    <mat-label for="service">Service</mat-label>
                                    <input matInput type="text" placeholder="Service" name="service"
                                        value="{{ log.service_externe.service.libelle }}" [disabled]="true">
                                </mat-form-field>

                                <mat-form-field>
                                    <mat-label for="code_reparateur">Code réparateur</mat-label>
                                    <input matInput type="text" placeholder="Code réparateur" name="code_reparateur"
                                        value="{{ log.code_reparateur }}" [disabled]="true">
                                </mat-form-field>

                                <mat-form-field>
                                    <mat-label for="immatriculation">Immatriculation</mat-label>
                                    <input matInput type="text" placeholder="Immatriculation" name="immatriculation"
                                        value="{{ log.immatriculation }}" [disabled]="true">
                                </mat-form-field>

                                <mat-form-field class="textarea">
                                    <mat-label for="message_erreur">Message d'erreur</mat-label>
                                    <textarea matInput type="text" placeholder="Message d\'erreur" name="message_erreur"
                                        value="{{ log.message_erreur }}" [disabled]="true">
                                    </textarea>
                                </mat-form-field>
                          </mat-expansion-panel>
                    </mat-accordion>
                  <mat-paginator *ngIf="nb_logs != 0 && logs_recherche != undefined" [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons (page)="onChangePaginator($event)"></mat-paginator>
            </div>            
    </mat-card-content>
</mat-card>