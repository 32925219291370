
        <!-- <mat-card style="background-color: #fff; margin-top: 16px;" class="mat-card-padding"> -->
            <mat-card-subtitle>Mes Commandes</mat-card-subtitle>
            
            <div class="container">
                <table mat-table matTableResponsiveLarge [dataSource]="dataSourceMatTable" class="table-primary" class="table-primary" matTableExporter #exporter="matTableExporter" [hiddenColumns]="[7]">                    
                    
                    <!-- Icon Column -->
                    <ng-container matColumnDef="idCategorie">
                        <th mat-header-cell *matHeaderCellDef style="text-align: left; padding-right: 16px;" class="premiere-colonne colonne-invisible">Catégorie</th>
                        <td mat-cell *matCellDef="let element" style="text-align: left;" class="colonne-invisible">
                            <mat-icon color="primary" svgIcon={{apiCmd.getIconCategorie(element.Categorie.code)}}
                                matTooltip="{{element.Categorie.libelle}}"
                            >
                                <span class="cdk-visually-hidden">{{ element.Categorie.libelle == 'Outil Diagnostic' ? 'Outil diagnostic' : 'Kits' }}</span>
                            </mat-icon>
                        </td>
                    </ng-container>
                    
                    <!-- Option Column -->
                    <ng-container matColumnDef="option">
                        <th mat-header-cell *matHeaderCellDef  class="colonne-invisible" style="padding-right: 8px; padding-left: 8px;">Option</th>
                        <td mat-cell *matCellDef="let element" title="{{ element.option }}" 
                            [ngStyle]="{ 'color': apiCmd.getOptionColor(element.option) }" 
                            class="colonne-invisible">
                            {{ element.option }}
                        </td>
                    </ng-container>
                    
                    <!-- Référence Column -->
                    <ng-container matColumnDef="numero">
                        <th mat-header-cell *matHeaderCellDef  style="padding-left: 16px;">Référence</th>
                        <td mat-cell *matCellDef="let element" class="commande">
                            <button mat-flat-button target="_blank" (click)="openCommandDetails(element)"
                            class="custom-button" style="text-decoration: underline;">{{element.numero}}</button>
                        </td>
                    </ng-container>
        
                    <!-- Cabinet Column -->
                    <ng-container matColumnDef="raison_sociale">
                        <th mat-header-cell *matHeaderCellDef >Cabinet</th>
                        <td mat-cell *matCellDef="let element" class="cabinet">{{element.raison_sociale}}</td>
                    </ng-container>
        
                    <!-- Expert Column -->
                    <ng-container matColumnDef="expert">
                        <th mat-header-cell *matHeaderCellDef >Expert</th>
                        <td mat-cell *matCellDef="let element">{{element.nom+' '+element.prenom}}</td>
                    </ng-container>
        
                    <!-- Date Column -->
                    <ng-container matColumnDef="date_creation">
                        <th mat-header-cell *matHeaderCellDef  style="width: 92px;">Date</th>
                        <td mat-cell *matCellDef="let element">
                            <div *ngIf="element.date_creation >= '19000101'">{{element.date_creation | date: 'dd/MM/yyyy'}}</div>
                            <div *ngIf="element.date_creation < '19000101'">__/__/____</div>                            
                        </td>
                    </ng-container>
        
                    <!-- Etat Column -->
                    <ng-container matColumnDef="idEtat">
                        <th mat-header-cell *matHeaderCellDef  class="etat-padding">État</th>
                        <td mat-cell *matCellDef="let element" class="etat-padding">
                            <button mat-stroked-button class="button-etat button-width" color="primary" [matTooltip]="element.Etat.description"
                            (click)="openHistoCommande(element.id,element.numero)"
                            [ngStyle]="{ color: apiCmd.getEtatColor(element.Etat.code) }">{{ element.Etat.libelle }}</button>
                        </td>
                    </ng-container>

                    <!-- Facture Column -->
                        <ng-container matColumnDef="idfacture">
                        <th mat-header-cell *matHeaderCellDef  >Facture</th>
                        <td mat-cell *matCellDef="let element">
                            <button 
                                mat-icon-button 
                                *ngIf       = "element.idfacture>0"
                                color       = "primary"
                                matTooltip  = "Télécharger la facture"
                                (click)     = "downloadInvoice(element)"
                            >
                            <mat-icon>download</mat-icon>
                        </button>
                        </td>
                    </ng-container>
        
                    <!-- Actions Column -->
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef class="derniere-colonne" style="text-align: center;"></th>
                        <td mat-cell *matCellDef="let element">
                            <div class="div-button-left">
                                
                                <button mat-icon-button class="button-etat" color="primary" [matTooltip]="element.Etat.description"
                                    style="width: 48px; height: 48px;">
                                    <mat-icon color="primary" [ngStyle]="{ color: apiCmd.getEtatColor(element.Etat.code) }" [svgIcon]="apiCmd.getIcone(element.Etat.code)"></mat-icon>
                                </button>
                                
                                <button mat-icon-button class="button-etat" color="primary"
                                    *ngIf="(apiAuth.userConnect.droits_utilisateur.droit_module_admin_compta == true || apiAuth.userConnect.droits_utilisateur.droit_module_admin_adela == true) && element.Etat.code == apiCmd.CONST_ETAT_PREPARATION && element.option == apiCmd.CONST_OPTION_ACHAT && (element.estTraitement == false || element.estTraitement == undefined)"
                                    style       = "width: 48px; height: 48px;"
                                    (click)     = "expedierCommande(element)"
                                    matTooltip  = "Expédier la commande">
                                    <mat-icon color="primary">local_shipping</mat-icon>
                                </button>

                                <button mat-icon-button class="button-etat" color="warn"
                                    *ngIf="(apiAuth.userConnect.droits_utilisateur.droit_module_admin_compta == true || apiAuth.userConnect.droits_utilisateur.droit_module_admin_adela == true) && element.Etat.code == apiCmd.CONST_ETAT_PREPARATION && (element.estTraitement == false || element.estTraitement == undefined)"
                                    style       = "width: 48px; height: 48px;"
                                    (click)     = "annulerCommande(element)"
                                    matTooltip  = "Annuler la commande">
                                    <mat-icon color="warn">cancel</mat-icon>
                                </button>

                                <mat-spinner *ngIf="element.estTraitement == true" style="margin: auto;" diameter="24"></mat-spinner>
                            </div>
                        </td>
                    </ng-container>
                            
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>                    
                </table>

                <div class="div-container-between" style="align-items: center;background-color:white">
                    <app-export-table [fileExportName]="'EXTRANET - Commandes'" [exporter]="exporter" [nb_ligne]="this.dataSourceMatTable.data.length"></app-export-table>
                    <mat-paginator [pageSizeOptions]="[10,25,50]" showFirstLastButtons  aria-label="Nombre de lignes visibles..."></mat-paginator>
                </div>                
            </div>
        <!-- </mat-card> -->
    