import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Article } from '../interfaces/commande/article';
import { FiltreRechercheArticle } from '../interfaces/commande/article/filtre-recherche-article';
import { ResultatActionEnMasse } from '../interfaces/resultat-action-en-masse';
import { Categorie } from '../interfaces/phrasier/categorie';

@Injectable({
  providedIn: 'root'
})
export class ApiArticleService {

  public isDataModify: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  url: string = '/api';

  constructor(private http : HttpClient) { }

  // Suppression d'article en masse
  public postSuppressionEnMasseArticle(articles: Array<Article>) : Observable<ResultatActionEnMasse[]> {
    return this.http.post<ResultatActionEnMasse[]>( this.url + '/article/action-en-masse/suppression', articles );
  }

  // Activation d'article en masse
  public postActivationEnMasseArticle(articles: Array<Article>) : Observable<ResultatActionEnMasse[]> {
    return this.http.post<ResultatActionEnMasse[]>( this.url + '/article/action-en-masse/activation', articles );
  }

  // Désactivation d'article en masse
  public postDesactivationEnMasseArticle(articles: Array<Article>) : Observable<ResultatActionEnMasse[]> {
    return this.http.post<ResultatActionEnMasse[]>( this.url + '/article/action-en-masse/desactivation', articles );
  }

  // Recherche d'un article avec des filtres
  public postSearchingArticle(filtre_recherche: FiltreRechercheArticle) : Observable<Article[]> {
    return this.http.post<Article[]>( this.url + '/article', filtre_recherche );
  }

  // Modification d'un article
  public putArticle(rtc_id: number, article: Article) : Observable<Article> {
    return this.http.put<Article>(this.url+'/article/fiche/'+rtc_id, article)
  }

  // Création d'un article
  public postArticle(article: Article) : Observable<Article> {
    return this.http.post<Article>(this.url+'/article/fiche', article)
  }

  // Récupérer la liste catégorie d'article ou commande
  public getCategories() : Observable<Categorie[]> {
    return this.http.get<Categorie[]>( this.url +'/article/categorie?opt=all')
  }

  // Récupérer une catégorie
  public getCategoriesById(id: number) : Observable<Categorie[]> {
    return this.http.get<Categorie[]>( this.url +'/article/categorie/'+id)
  }

  // Récupération d'un article 
  public getArticle(id: number = 0, ref: string = '') {
    let urlParametre: string = '';
    urlParametre += (id > 0 ? 'id='+id : '');
    urlParametre += (urlParametre != '' ? '&' : '')
    urlParametre += (ref != '' ? 'ref='+ref : '');

    return this.http.get<Article>(this.url + '/article/fiche?' + urlParametre)
  }
}
