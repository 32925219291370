import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Article } from 'src/app/interfaces/commande/article';
import { Fournisseur } from 'src/app/interfaces/commande/fournisseur/fournisseur';
import { Categorie } from 'src/app/interfaces/phrasier/categorie';
import { ApiArticleService } from 'src/app/services/api-article.service';
import { ApiFournisseurService } from 'src/app/services/api-fournisseur.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgIf, NgFor } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';

@Component({
    selector: 'app-fiche-article',
    templateUrl: './fiche-article.component.html',
    styleUrls: ['./fiche-article.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, NgIf, MatSelectModule
              , NgFor, MatOptionModule, MatSlideToggleModule, MatButtonModule, MatIconModule
              , MatProgressSpinnerModule, MatGridListModule]
})
export class FicheArticleComponent implements OnInit {

  // Accesseur en lecture du formulaire
  get reference()   { return this.formGroupArticle.get('reference'); }
  get designation() { return this.formGroupArticle.get('designation'); }
  get description() { return this.formGroupArticle.get('description'); }
  get categorie()   { return this.formGroupArticle.get('categorie'); }
  get fournisseur() { return this.formGroupArticle.get('fournisseur'); }
  get prix_ht()     { return this.formGroupArticle.get('prix_ht'); }
  get location_ht() { return this.formGroupArticle.get('location_ht'); }
  get port_ht()     { return this.formGroupArticle.get('port_ht'); }
  get remise()      { return this.formGroupArticle.get('remise'); }
  get txt_remise()  { return this.formGroupArticle.get('txt_remise'); }

  // Variables pour gérer l'article (chargement, création, modification)
  id                : number = 0;
  article           : Article = <Article>{};

  // Variables pour gérer l'affichage
  waitingValidation : boolean = false;
  bModeCreation     : boolean = false;
  titre_page        : string  = '';

  // Variables d'interface pour gérer les combos
  categories    : Array<Categorie>   = Array<Categorie>();
  fournisseurs  : Array<Fournisseur> = Array<Fournisseur>();

  // Variables utilisées pour le formulaire
  formGroupArticle: FormGroup = this.fb.group({
    reference   : [{value: '', disabled : false}, [Validators.required]],
    designation : [{value: '', disabled : false}, [Validators.required]],
    description : [{value: '', disabled : false}, [Validators.required]],
    categorie 	: [{value: null, disabled : false}, [Validators.required]],
    fournisseur	: [{value: null, disabled : false}, [Validators.required]],
    prix_ht     : [{value: 0, disabled : false}, [Validators.required]],
    location_ht	: [{value: 0, disabled : false}],
    port_ht	    : [{value: 0, disabled : false}],
    remise      : [{value: 0, disabled : false}],
    actif       : [{value: false, disabled : false}, [Validators.required]],
    txt_remise  : [{value: '', disabled: false}]
  });

  breakpointSmall : boolean = true

  constructor(private fb              : FormBuilder
            , private router          : Router
            , private routed          : ActivatedRoute
            , private apiArticle      : ApiArticleService
            , private apiFournisseur  : ApiFournisseurService
            , private toast           : PersoSnackbarService) {
  }

  onResize(event:any) {
    //For responsiveness:
    this.breakpointSmall = (event.target.innerWidth <= 600) ? true : false;
  }

  ngOnInit(): void {
     //For responsiveness:
     this.breakpointSmall = (window.innerWidth <= 600) ? true : false;
     
    this.id = this.routed.snapshot.params['id_article']

    // Chargement des combos
    this.getCategories();
    this.getFournisseurs();

    if (this.id > 0) {                  
      if(this.routed.snapshot.queryParams.type == 'dupplicate') {
        // Mode dupplication
        this.bModeCreation  = true;
        this.titre_page     = 'Duppliquer un article'
        this.formGroupArticle.controls['reference'].enable();
      } else {
        // Mode édition
        this.bModeCreation  = false;
        this.titre_page     = 'Modifier un article'
        this.formGroupArticle.controls['reference'].disable();
      }
      
      this.getArticle();
    } else {
      // Mode création
      this.titre_page     = 'Créer un article';
      this.bModeCreation  = true;    
    }
  }

  // Chargement des catégories d'article
  private getCategories(): void {
    this.apiArticle.getCategories()
    .subscribe(
      (data: Categorie[])  => { 
        this.categories = data;
      },
      (err: any) => {
        '/!\\ error getCategories: ' + console.log(err);
      }
    );
  }

  // Chargement des fournisseurs d'article
  private getFournisseurs(): void {
    this.apiFournisseur.getSupplier()
    .subscribe(
      (data: Fournisseur[]) => {
        this.fournisseurs = data;
      },
      err => { 
        '/!\\ error getSupplier: ' + console.log(err);
      }
    );
  }

  // Chargement de l'article
  private getArticle(): void {
    this.apiArticle.getArticle(this.id, '')
    .subscribe(
      (article: Article) => {        
        this.formGroupArticle.patchValue({
          reference   : article.reference,
          designation : article.titre,
          description : article.description,
          categorie 	: article.categorie,
          fournisseur	: article.fournisseur.id,
          prix_ht     : article.prix_ht,
          location_ht	: article.location_ht,
          port_ht	    : article.port,
          remise      : article.remise,
          txt_remise  : article.txt_remise,
          actif       : article.actif
        });

        if(this.routed.snapshot.queryParams.type == 'dupplicate') {
          // Mode dupplication : on reset la référence
          this.formGroupArticle.patchValue({
            reference: ''
          });
        }
      },
      (err: any) => { 
        '/!\\ error getArticle: ' + console.log(err);
      });
  }

  // Liaison de l'article et du formulaire
  private bindFormulaireArticle(): void {
    this.article = <Article>{};

    if(this.bModeCreation) {
      this.article.id = 0
    } else {
      this.article.id = this.id
    }

    this.article.actif            = this.formGroupArticle.controls.actif.value;
    this.article.categorie        = this.formGroupArticle.controls.categorie.value;
    this.article.description      = this.formGroupArticle.controls.description.value;
    this.article.fournisseur      = <Fournisseur>{};
    this.article.fournisseur.id   = this.formGroupArticle.controls.fournisseur.value;
    this.article.location_ht      = this.formGroupArticle.controls.location_ht.value;
    this.article.port             = this.formGroupArticle.controls.port_ht.value;
    this.article.prix_ht          = this.formGroupArticle.controls.prix_ht.value;
    if(!this.bModeCreation) {
      this.article.reference = this.formGroupArticle.getRawValue().reference;
    } else {
      this.article.reference = this.formGroupArticle.controls.reference.value;
    }    
    this.article.remise           = this.formGroupArticle.controls.remise.value;
    this.article.titre            = this.formGroupArticle.controls.designation.value;
    this.article.txt_remise       = this.formGroupArticle.controls.txt_remise.value;
  }

  // Modification de l'article
  private putArticle(rtc_id: number, article: Article) {
    this.waitingValidation = true;

    this.apiArticle.putArticle(rtc_id, article)
      .subscribe(
        (data: any) => { 
          this.toast.showInfo("Article modifié");
          this.router.navigate(['/app/articles']);
          this.waitingValidation = false;
        },
        (err: any) => { 
          '/!\\ error putArticle: ' + console.log(err);
          this.toast.showError(err.error);
          this.waitingValidation = false;
         },
        () => { 
          this.waitingValidation = false;
         }
      );
  }

  // Création de l'article
  private postArticle(article: Article) {
    this.waitingValidation = true;
    
    this.apiArticle.postArticle(article)
      .subscribe(
        (data: Article) => { 
          this.article = data;
          this.toast.showInfo("Article créé")
          this.router.navigate(['/app/articles']);
          this.waitingValidation = false;
        },
        (err: any) => { 
          '/!\\ error postArticle: ' + console.log(err); 
          this.toast.showError(err.error);
          this.waitingValidation = false;
        },
        () => { 
          this.waitingValidation = false;
        }
      );
  }

  // Retour à la liste des articles
  public onClickAnnuler(): void {
    this.router.navigate(['/app/articles'])
  }

  // Validation des modifications
  public onClickValider(){
    this.bindFormulaireArticle()

    if(this.bModeCreation) {
      this.postArticle(this.article);
    } else {
      this.putArticle(this.id, this.article);
    }    
  }

}
