<mat-card appearance="outlined" class="mat-card">
    <mat-card-subtitle>Refacturation</mat-card-subtitle>
    <form [formGroup]="FiltreFormGroup">
        <mat-card-content class=".mat-card-content">            
                <mat-form-field>
                    <mat-label>À facturer au cabinet</mat-label>
                    <mat-select [(ngModel)]="idEntite" name="entite" id="entite" formControlName="entite" (selectionChange)="onSelectEntite($event)">
                        <mat-option *ngFor="let entite of filterEntitesOfType(true)" [value]="entite.id">
                            {{ entite.raison_sociale }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>                        
        </mat-card-content>        

        <mat-action-row>        
            <div class="div-container" style="padding: 8px;">
                <button mat-stroked-button type="button" color="accent" [mat-dialog-close]="false">
                    <mat-icon>close</mat-icon>
                    Annuler
                </button>
                
                <div class="button-container">                    
                    <button mat-flat-button 
                            *ngIf               = "!waitingCopy"
                            color               = "primary" 
                            [mat-dialog-close]  = "true" 
                            [disabled]          = "waitingCopy || !FiltreFormGroup.valid" 
                            (click)             = "refacturer()"
                            >
                        <mat-icon>content_copy</mat-icon>
                        Refacturer
                    </button>
                    <mat-spinner diameter="24" *ngIf="waitingCopy"></mat-spinner>
                </div>
            </div>            
        </mat-action-row>
    </form>
</mat-card>