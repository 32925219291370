import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { RelationUtilisateurEntite } from '../interfaces/utilisateur-entite/relation-utilisateur-entite';
import { ApiAuthentificationService } from '../services/api-authentification.service';

@Injectable({
  providedIn: 'root'
})
export class ProtectExtranetModificationTrombinoscopeEntiteGuard  {
  
  ent_id : number = 0;
  
  constructor(private apiAuth : ApiAuthentificationService
            , private router: Router){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
      let local_relUserEnt: RelationUtilisateurEntite = <RelationUtilisateurEntite>{};

      this.ent_id = route.params['ent_id'];
              
      // Accès dédié aux adhérent extranet
      if ( this.apiAuth.root === this.apiAuth.CONST_URL_ROOT_SERVICE ) {
        return false;
      }

      // Accès administrateur autorisé
      if(this.apiAuth.userConnect.droits_utilisateur.est_admin) {
        return true;
      }

      this.apiAuth.relUserEnt.find(relUserEnt => {
        if(relUserEnt.entite_id == this.ent_id) {
          local_relUserEnt = relUserEnt;
        }
      });
      
      // Accès dédié aux dirigeants de l'entité
      if(local_relUserEnt.entite_id === undefined) {
        return false;
      } else {
        if(local_relUserEnt.entite_id <= 0 || !local_relUserEnt.est_dirigeant) {
          return false;
        }
      }
            
      return true;
  }
  
}
