import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ListeEntitesComponent } from '../liste-entites/liste-entites.component';
import { UtilisateurComponent } from '../utilisateur/utilisateur.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';

@Component({
    selector: 'app-profil',
    templateUrl: './profil.component.html',
    styleUrls: ['./profil.component.scss'],
    standalone: true,
    imports: [MatTabsModule, MatIconModule, UtilisateurComponent, ListeEntitesComponent]
})
export class ProfilComponent implements OnInit {

  isServiceFFEA : boolean = false;
  selected = new FormControl(0);

  constructor( 
    private authUser : ApiAuthentificationService,
    private routed: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.isServiceFFEA = this.authUser.isServiceFFEA();

    this.routed.fragment
    .subscribe(tabsToSelect => {
      if ( tabsToSelect != undefined ) {
        this.selected.setValue(+tabsToSelect)
      }
    });
  
  }

}
