import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Adresse, AdresseInit, TypeAdresse, TypesAdresse } from 'src/app/interfaces/adresse/adresse';
import { CST_ACTION_REMOVE } from 'src/app/interfaces/constantes';
import { OuiNonAnnulerComponent } from '../../oui-non-annuler/oui-non-annuler.component';
import { AdresseService } from '../adresse.service';
import { EditAdresseComponent } from '../edit-adresse/edit-adresse.component';
import { TableAdresseComponent } from '../table-adresse/table-adresse.component';

@Component({
    selector: 'app-shell-adresse',
    templateUrl: './shell-adresse.component.html',
    styleUrls: ['./shell-adresse.component.scss'],
    standalone: true,
    imports: [NgIf, TableAdresseComponent, EditAdresseComponent]
})
export class ShellAdresseComponent implements OnInit {

  @Input() adresses?  : Adresse[]=[]
  @Input() types      : string = ''
  @Input() idTable    : number = 0

  modeEdition     : boolean = false
  adresseEditee   : Adresse = AdresseInit
  typesAdresse    : TypeAdresse[] = []

  constructor(private apiAdresse:AdresseService
              ,private  dialog       : MatDialog) { }

  ngOnInit(): void {
    
    this.types.split(',').forEach(
      (type:string)=> {
        const typeAdresse = TypesAdresse.find((elt)=> elt.type === type)
        if (typeAdresse) {
          this.typesAdresse.push(typeAdresse)
        }
      } 
    )
  }

  editAdresse(adresse:Adresse){
    if (adresse.action === CST_ACTION_REMOVE) {
      const dialogOuiNon = this.dialog.open(OuiNonAnnulerComponent, { 
        width: '320px', 
        data: { 
          title: "",
          message: "Voulez-vous vraiment supprimer cette adresse : " + this.apiAdresse.getFormatedAdresse(adresse)        
          , yes_no_cancel: 2 
        } 
      });
      dialogOuiNon.afterClosed()
     .subscribe(
       res => {
        switch (res) {
           case true:
            this.apiAdresse.deleteAdresse(adresse.id!).subscribe(
              ()=>{
                this.adresses = this.adresses?.filter((adr:Adresse)=> adr.id != adresse.id)
              }
            )  
             break;
          default:
             break;
         }
     });
    }else{
      this.adresseEditee = adresse
      this.modeEdition = true
    }
  }

  saveAdresse(adresseToSave:Adresse|null){
    if (adresseToSave ===null) {
      this.modeEdition = false
    }else{
        if (adresseToSave.id && adresseToSave.id> 0) {
          this.apiAdresse.putAdresse(adresseToSave).subscribe(
            (adresseSaved:Adresse)=>{
              this.adresses = this.adresses? this.adresses.map((adresse:Adresse)=> adresse.id===adresseSaved.id? adresseSaved : adresse) : []
              this.modeEdition = false
            }
          )
        }else{
          adresseToSave.relAdr!.tbl_id = this.idTable
          this.apiAdresse.postAdresse(adresseToSave).subscribe(
            (adresseSaved:Adresse)=> {
              this.adresses? this.adresses = [...this.adresses,adresseSaved] : this.adresses = []
              this.modeEdition = false
            }
            
          )
        }
      }
    }
    
  }

