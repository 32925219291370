import { Component } from '@angular/core';
import { FilterRapport1aComponent } from '../filter-rapport1a/filter-rapport1a.component';
import { ListRapport1aComponent } from '../list-rapport1a/list-rapport1a.component';
import { RapportFilter } from '../../rapport1a-interface';
import { Suivi } from 'src/app/interfaces/acquittement/suivi';
import { ConcentrateurService } from '../../data_access/concentrateur.service';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';

@Component({
  selector: 'app-shell-rapport1a',
  templateUrl: './shell-rapport1a.component.html',
  styleUrls: ['./shell-rapport1a.component.scss'],
  standalone:true,
  imports : [FilterRapport1aComponent,ListRapport1aComponent]
})
export class ShellRapport1aComponent {
  
  filteredRapports  : Suivi[]   = [];
  entitesUser       : Entite[]  = this.apiConcentrateur.entitesUser;
  estAdmin          : boolean   = this.apiConcentrateur.estAdmin();
  waitingResearch   : boolean   = false;

  constructor(
    private apiConcentrateur:ConcentrateurService
  , private toast: PersoSnackbarService){}

  onFilterChange(filter: RapportFilter) {
    this.waitingResearch = true;
    
    this.apiConcentrateur.postRapports1aOrphelins(filter).subscribe(
      (suivis:Suivi[]) => {     
        if (suivis != null || suivis != undefined) {
          this.filteredRapports = suivis;
        } else {
          this.toast.showInfo("Aucune opération trouvée")
          this.filteredRapports = []
        }
        this.waitingResearch = false;
      },
        (error: any) => {
          this.waitingResearch = false;
          this.toast.showError(error?.error?.fault?.detail ? error?.error?.fault?.detail : error?.error?.fault?.faultstring);
      } 
    );
  }

}
