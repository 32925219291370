

<div class="account">
    <button mat-menu-item  class="button-activeLink" (click)="clickOnAccount()" *ngIf="utl_profil">
        <mat-icon matListItemIcon >
            <span class="material-symbols-outlined">account_circle</span>
        </mat-icon>
        <span>{{ utl_profil.nom }} {{ utl_profil.prenom }}</span>
    </button>
</div>
<ng-container *ngIf="isServiceFFEA(); then isService else isExtranet"></ng-container>

<ng-template #isService>
    <div *ngIf="utl_profil != undefined" id="tiles" class="container">
        
        <mat-expansion-panel *ngIf="utl_profil.droits_utilisateur?.droit_module_reparateur">
            <mat-expansion-panel-header>
                <mat-icon matListItemIcon >car_repair</mat-icon>
                <span>Réparateur</span>
            </mat-expansion-panel-header>

            <mat-nav-list>
                <a mat-list-item 
                    routerLinkActive="activeLink" 
                    routerLink="/svc/reparateur/dossier"
                    >
                    <mat-icon matListItemIcon  matListItemIcon><span class="material-symbols-outlined">
                        search
                        </span></mat-icon>
                    <span class="span-a">Rechercher un dossier</span>
                </a>
                
                <a mat-list-item  
                    routerLinkActive="activeLink" 
                    routerLink="/svc/profil/{{ utl_profil.id }}"
                    >
                    <mat-icon matListItemIcon  ><span class="material-symbols-outlined">
                        account_circle
                        </span></mat-icon>
                    <span class="span-a">Profil utilisateur</span>
                </a>

                <a mat-list-item  
                    routerLinkActive="activeLink" 
                    routerLink="/svc/profil/{{ utl_profil.id }}"
                    >
                    <mat-icon matListItemIcon  ><span class="material-symbols-outlined">
                        garage
                    </span></mat-icon>
                    <span class="span-a">Compte réparateur</span>
                </a>

            </mat-nav-list>
        </mat-expansion-panel>

         <!-- ADMIN SERVICE : SERVICE FFEA -->

        <mat-expansion-panel *ngIf="utl_profil.droits_utilisateur?.est_admin || utl_profil.droits_utilisateur?.droit_admin_ffea">
            <mat-expansion-panel-header>
                <mat-icon matListItemIcon >manage_accounts</mat-icon>
                <span>Administrateur</span>
            </mat-expansion-panel-header>

            <mat-nav-list>
                <a mat-list-item 
                    routerLinkActive="activeLink" 
                    routerLink="/svc/admin/user"
                    >
                    <mat-icon matListItemIcon  ><span class="material-symbols-outlined">
                        switch_account
                        </span></mat-icon>
                    <span class="span-a">Gestion des utilisateurs</span>
                </a>
                
                <a mat-list-item  
                    routerLinkActive="activeLink" 
                    routerLink="/svc/admin/service-externe"
                    >
                    <mat-icon matListItemIcon  ><span class="material-symbols-outlined">
                        settings_applications
                        </span></mat-icon>
                    <span class="span-a">Gestion des services externes</span>
                </a>

                <a mat-list-item  
                    routerLinkActive="activeLink" 
                    routerLink="/svc/admin/log-service-externe"
                    >
                    <mat-icon matListItemIcon  ><span class="material-symbols-outlined">
                        description
                    </span></mat-icon>
                    <span class="span-a">Journaux des services externes</span>
                </a>

            </mat-nav-list>
        </mat-expansion-panel>
    </div>
</ng-template>

<ng-template #isExtranet>
    <div *ngIf="utl_profil != undefined">
    <!-- ACCUEIL -->
    
        <mat-expansion-panel  class="disable_ripple">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon matListItemIcon ><span class="material-symbols-outlined">home</span></mat-icon>
                    <span class="title">ACCUEIL</span>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-nav-list>
                <a  mat-list-item 
                    routerLinkActive    = "activeLink" 
                    [routerLink]        = " ['/'+authUser.root+'/profil/' +authUser.userConnect.id]"
                    fragment            = "0">
                    <mat-icon matListItemIcon  ><span class="material-symbols-outlined">
                        account_circle
                        </span></mat-icon>
                    <span class="span-a">Mon profil</span>
                </a>
                
                <a  mat-list-item  
                    routerLinkActive    ="activeLink" 
                    [routerLink]        = " ['/'+authUser.root+'/profil/' +authUser.userConnect.id]"
                    fragment            = "1">
                    <mat-icon matListItemIcon  ><span class="material-symbols-outlined">
                        apartment
                        </span></mat-icon>
                    <span class="span-a">Mes entités</span>
                </a>

                <a mat-list-item  
                    routerLinkActive="activelink"
                    routerLink="/app/facture/mes-factures"
                    fragment            = "2"
                    *ngIf="utl_profil?.droits_utilisateur?.droit_module_mes_factures || utl_profil?.est_dirigeant">
                    <mat-icon matListItemIcon  ><span class="material-symbols-outlined">
                        account_balance
                        </span></mat-icon>
                    <span class="span-a">Mes factures</span>
                </a>

            </mat-nav-list>
        </mat-expansion-panel>

    <!-- ADELA -->
    
        <mat-expansion-panel *ngIf="isVisibleADELA()" class="disable_ripple">
            <mat-expansion-panel-header>
                <mat-panel-title>
                        <mat-icon matListItemIcon><span class="material-symbols-outlined">invert_colors</span></mat-icon>
                        <span class="title">ADELA</span>
                </mat-panel-title>
              
            </mat-expansion-panel-header>

            <mat-nav-list>
                <a mat-list-item routerLinkActive="activeLink" routerLink="/app/adela">
                    <mat-icon matListItemIcon><span class="material-symbols-outlined">
                        invert_colors
                        </span></mat-icon>
                    <span class="span-a">Nouvelle analyse de fluide</span>
                </a>
                
                <a mat-list-item  routerLinkActive="activeLink" routerLink="/app/adela-poudre">
                    <mat-icon matListItemIcon  ><span class="material-symbols-outlined">
                        labs
                        </span></mat-icon>
                    <span class="span-a">Nouvelle analyse de poudre</span>
                </a>

                <a mat-list-item  routerLinkActive="activeLink" [routerLink]="['/app/article/kits', this.cmdCategorie.KIT_ADELA]"
                    *ngIf="utl_profil?.droits_utilisateur?.droit_module_adela">
                    <mat-icon matListItemIcon ><span class="material-symbols-outlined">shopping_bag</span></mat-icon>
                    <span class="span-a">Commande de kits</span>
                </a>

                <a mat-list-item  routerLinkActive="activeLink" routerLink="/app/dossier/mes-dossiers">
                    <mat-icon matListItemIcon ><span class="material-symbols-outlined">folder</span></mat-icon>
                    <span class="span-a">Mes demandes d'analyse</span>
                </a>

                <a mat-list-item  routerLinkActive="activeLink" [routerLink]="['/app/commande/mes-commandes']">
                    <mat-icon matListItemIcon  ><span class="material-symbols-outlined">
                        list_alt
                    </span></mat-icon>
                    <span class="span-a">Mes commandes</span>
                </a>
                
                <a mat-list-item  routerLinkActive="activeLink" [routerLink]="['/app/tarif', 'adela']">
                    <mat-icon matListItemIcon ><span class="material-symbols-outlined">euro</span></mat-icon>
                    <span class="span-a">Grille tarifaire</span>
                </a>

            </mat-nav-list>
        </mat-expansion-panel>

        <!-- HISTORIQUE VEHICULE -->
        <mat-expansion-panel *ngIf="isVisibleConcentrateur()">
            <mat-expansion-panel-header>
                <mat-icon ><span class="material-symbols-outlined">history</span></mat-icon>
                <span class="title">Concentrateur</span>
            </mat-expansion-panel-header>
            <mat-nav-list>
                
                <a mat-list-item routerLink="/app/historiquevehicule" *ngIf="utl_profil.droits_utilisateur.est_admin || utl_profil.droits_utilisateur.droit_module_historique_vehicule">
                    <mat-icon matListItemIcon ><span class="material-symbols-outlined">search</span></mat-icon>
                    <span  class="span-a">Historique véhicule</span>
                </a>

                <a mat-list-item routerLink="/app/rdea-suivi" *ngIf="utl_profil.droits_utilisateur.est_admin || utl_profil.droits_utilisateur.droit_rdea">
                    <mat-icon matListItemIcon ><span class="material-symbols-outlined">upload_file</span></mat-icon>
                    <span class="span-a">RDEA Certif</span>
                </a>
                <a mat-list-item (click)="clickOnAcquittement()" *ngIf="utl_profil.droits_utilisateur.est_admin || utl_profil.droits_utilisateur.droit_module_acquittement">
                    <mat-icon matListItemIcon ><span class="material-symbols-outlined">sync_alt</span></mat-icon>
                    <span  class="span-a">Acquittement</span>
                </a>

                <a mat-list-item routerLink="/app/rapport1a">
                    <mat-icon matListItemIcon ><span class="material-symbols-outlined">problem</span></mat-icon>
                    <span  class="span-a">Rapport des opérations 1B non envoyées</span>
                </a>

            </mat-nav-list>
        </mat-expansion-panel>

        <!-- STATISTIQUES CONCENTRATEUR -->
        <mat-expansion-panel *ngIf="isVisibleStats()">
            <mat-expansion-panel-header >
                <mat-icon matListItemIcon ><span class="material-symbols-outlined">leaderboard</span></mat-icon>
                <span class="title">Statistiques</span>
            </mat-expansion-panel-header>
            <mat-nav-list>
                <a mat-list-item routerLink="/app/statistiques/mes-statistiques" 
                    *ngIf="utl_profil.est_dirigeant || utl_profil.droits_utilisateur.est_admin">
                    <mat-icon matListItemIcon ><span class="material-symbols-outlined">call_missed_outgoing</span></mat-icon><span>Utilisation du concentrateur</span>
                </a>
                <a mat-list-item routerLink="/app/statistiques/mes-statistiques">
                    <mat-icon matListItemIcon ><span class="material-symbols-outlined">call_missed_outgoing</span></mat-icon><span>Observatoire des tarifs publics</span>
                </a>
            
            </mat-nav-list>
        </mat-expansion-panel>
        
        <!-- MAGASIN -->
        <mat-expansion-panel *ngIf="this.utl_profil?.droits_utilisateur?.droit_module_outil_diagnostic">
            <mat-expansion-panel-header >
                <mat-icon matListItemIcon ><span class="material-symbols-outlined"></span>shopping_cart</mat-icon>
                <span class="title">Outils diagnostic</span>
            </mat-expansion-panel-header>
            <mat-nav-list>
                
                <a mat-list-item routerLinkActive="activeLink"
                    [routerLink]="['/app/article/kits', this.cmdCategorie.DIAG]">
                      <mat-icon matListItemIcon >
                        <span class="material-symbols-outlined">work</span>
                    </mat-icon>
                    <span>Outils diagnostic</span>
                </a>

                <a mat-list-item routerLinkActive="activeLink" 
                    href="{{ utl_profil.id > 0 ? apiParam.urlMajOutilDiagnostic : '' }}" 
                    target="_blank" 
                    class="link-activeLink">
                    <mat-icon matListItemIcon ><span class="material-symbols-outlined">next_week</span></mat-icon><span>Mise à jour Outil diagnostic</span>
                </a>

            </mat-nav-list>
        </mat-expansion-panel>

        <!-- ADC -->
        <mat-expansion-panel *ngIf="isVisibleADC()">
            <mat-expansion-panel-header >
                <mat-icon matListItemIcon ><span class="material-symbols-outlined">key</span></mat-icon>
                <span class="title">Analyses de clés</span>
            </mat-expansion-panel-header>

            <mat-nav-list>
                <a  mat-list-item 
                    routerLinkActive="activeLink"
                    routerLink="/app/adc">
                    <mat-icon matListItemIcon >
                        <span class="material-symbols-outlined">add</span>
                    </mat-icon>
                    <span>Nouvelle analyse</span>
                </a>
                <a  mat-list-item 
                    routerLinkActive="activeLink"
                    [routerLink]="['/app/article/kits', this.cmdCategorie.KIT_ADELA]"
                    *ngIf="utl_profil?.droits_utilisateur?.droit_module_adela"
                    
                    >
                    <mat-icon matListItemIcon >
                        <span class="material-symbols-outlined">shopping_bag</span>
                    </mat-icon>
                    <span >Commande de kits</span>
                </a>
                <a  mat-list-item 
                    routerLinkActive="activeLink"
                    [routerLink]="['/app/tarif', 'adc']"
                    >
                    <mat-icon matListItemIcon >
                        <span class="material-symbols-outlined">euro</span>
                    </mat-icon>
                    <span>Grille tarifaire</span>
                </a>
            </mat-nav-list>
        </mat-expansion-panel>

        <!-- ADMINISTRATION EXTRANET -->
        <mat-expansion-panel *ngIf="utl_profil.droits_utilisateur.droit_module_admin_extranet">
            <mat-expansion-panel-header >
                <mat-icon matListItemIcon ><span class="material-symbols-outlined">settings_applications</span></mat-icon>
                <span class="title">Administration extranet</span>
            </mat-expansion-panel-header>

            <mat-list>
                <div *ngIf="utl_profil.droits_utilisateur.est_admin">
                    <mat-list-item 
                        routerLinkActive="activeLink" 
                        routerLink="/app/administration-extranet/cabinets"
                        >
                        <mat-icon matListItemIcon ><span class="material-symbols-outlined">apartment</span></mat-icon>
                        <span matListItemTitle>Gestion des cabinets</span>
                    </mat-list-item>

                    <mat-list-item 
                        routerLinkActive="activeLink" 
                        routerLink="/app/administration-extranet/utilisateurs"
                        >
                        <mat-icon matListItemIcon ><span class="material-symbols-outlined">account_circle</span></mat-icon>
                        Gestion des utilisateurs
                    </mat-list-item>

                    <a mat-list-item 
                        routerLinkActive="activeLink" 
                        routerLink="/app/administration-extranet/fournisseurs"
                        >
                        <mat-icon matListItemIcon ><span class="material-symbols-outlined">trolley</span></mat-icon><span>Gestion des fournisseurs</span>
                    </a>
                </div>

                <mat-list-item 
                    routerLinkActive="activeLink" 
                    routerLink="/app/administration-extranet/parametres"
                    *ngIf="utl_profil.droits_utilisateur.est_admin"
                    >
                    <mat-icon matListItemIcon ><span class="material-symbols-outlined">settings</span></mat-icon>
                    <span>Gestion des paramètres</span>
                </mat-list-item>
            
                <a mat-list-item 
                    routerLinkActive="activeLink" 
                    routerLink="/app/administration-extranet/prestations"
                    *ngIf="utl_profil.droits_utilisateur.est_admin"
                    >
                    <mat-icon matListItemIcon ><span class="material-symbols-outlined">handyman</span></mat-icon>
                    <span>Gestion des prestations</span>
                </a>

            </mat-list>
        </mat-expansion-panel>

        <!-- SUPPORT -->
        <mat-expansion-panel *ngIf="isVisibleTicket()">

            <mat-expansion-panel-header >
                <mat-icon matListItemIcon ><span class="material-symbols-outlined">support_agent</span></mat-icon>
                <span class="title">Support</span>
            </mat-expansion-panel-header>
            
            <mat-nav-list>
                <a mat-list-item 
                    routerLinkActive="activeLink" 
                    routerLink="/app/support/liste-tickets"
                    >
                    <mat-icon matListItemIcon ><span class="material-symbols-outlined">sell</span></mat-icon><span>Gestion des tickets</span>
                </a>

                <a mat-list-item 
                    routerLinkActive="activeLink" 
                    routerLink="/app/support/modele"
                    *ngIf="utl_profil.droits_utilisateur.est_admin"
                    >
                    <mat-icon matListItemIcon >
                        <span class="material-symbols-outlined">description</span>
                    </mat-icon>
                    <span>Gestion des modèles de réponses</span>
                </a>

                <a mat-list-item 
                    routerLinkActive="activeLink" 
                    routerLink="/app/administration-extranet/support"
                    *ngIf="utl_profil.droits_utilisateur.est_admin"
                    >
                    <mat-icon matListItemIcon >
                        <span class="material-symbols-outlined">contact_support</span>
                    </mat-icon>
                    <span>gestion des types de demande</span>
                </a>
            
            </mat-nav-list>

        </mat-expansion-panel>

        <!-- ADMINISTRATION COMPTABILITE -->
        <mat-expansion-panel *ngIf="utl_profil.droits_utilisateur.droit_module_admin_compta">
            
            <mat-expansion-panel-header >
                <mat-icon matListItemIcon  ><span class="material-symbols-outlined">euro</span></mat-icon>
                <span class="title">Administration Comptabilité</span>
            </mat-expansion-panel-header>
            
            <mat-nav-list>
                <a mat-list-item 
                    routerLinkActive="activeLink" 
                    routerLink="/app/compta/factures"
                    >
                    <mat-icon matListItemIcon >
                        <span class="material-symbols-outlined">receipt_long</span>
                    </mat-icon>
                    <span>Factures</span>
                </a>

                <a mat-list-item
                    routerLinkActive="activeLink"
                    routerLink="/app/facture/mes-factures"
                    >
                    <mat-icon matListItemIcon >
                        <span class="material-symbols-outlined">account_balance</span>
                    </mat-icon>
                    <span>Mes factures</span>
                </a>

                <a mat-list-item 
                    routerLinkActive="activeLink" 
                    routerLink="/app/compta/facturation"
                    >
                    <mat-icon matListItemIcon ><span class="material-symbols-outlined">
                        order_play
                        </span>
                    </mat-icon>
                    <span>Facturation</span>
                </a>

                <a mat-list-item 
                    routerLinkActive="activeLink" 
                    routerLink="/app/administration-extranet/comptabilite">
                    <mat-icon matListItemIcon >
                        <span class="material-symbols-outlined">feed</span>
                    </mat-icon>
                    <span>Gestion des coordonnées bancaires</span>
                </a>

                <a mat-list-item
                    routerLinkActive="activelink"
                    routerLink="/app/compta/statistique">
                    <mat-icon matListItemIcon>
                          <span class="material-symbols-outlined">trending_up</span>
                    </mat-icon>
                    <span class=>Statistiques</span>
              </a>  
            
            </mat-nav-list>

        </mat-expansion-panel>

        <!-- SUSPENSIONS -->
        <mat-expansion-panel *ngIf="utl_profil.droits_utilisateur.droit_module_suspension">
            
            <mat-expansion-panel-header >
                <mat-icon matListItemIcon  ><span class="material-symbols-outlined">no_accounts</span></mat-icon>
                <span class="title">Suspensions</span>
            </mat-expansion-panel-header>
    
            <mat-nav-list>
                <a mat-list-item 
                    routerLinkActive="activeLink" 
                    routerLink="/app/suspension/type"
                    >
                    <mat-icon matListItemIcon >
                        <span class="material-symbols-outlined">format_list_numbered</span>
                    </mat-icon>
                    <span>Gestion des types de suspension</span>
                </a>

                <a mat-list-item 
                    routerLinkActive="activeLink" 
                    routerLink="/app/suspension" 
                    >
                    <mat-icon matListItemIcon >
                        <span class="material-symbols-outlined">no_accounts</span>
                    </mat-icon>
                    <span>Gestion des suspensions</span>
                </a>
            
            </mat-nav-list>

        </mat-expansion-panel>

        <!-- ADMINISTRATION ADELA -->
        <mat-expansion-panel *ngIf="utl_profil.droits_utilisateur.droit_module_admin_adela">
                    
            <mat-expansion-panel-header >
                <mat-icon matListItemIcon  ><span class="material-symbols-outlined">invert_colors</span></mat-icon>
                <span class="title">Administration ADELA</span>
            </mat-expansion-panel-header>
            
            <mat-nav-list>
                <a mat-list-item 
                    routerLinkActive="activeLink" 
                    routerLink="/app/adela/composantes"
                    >
                    <mat-icon matListItemIcon >
                        <span class="material-symbols-outlined">view_compact_alt</span>
                    </mat-icon>
                    <span>Gestion des composants</span>
                </a>
                
                <a mat-list-item 
                    routerLinkActive="activeLink" 
                    routerLink="/app/administration-extranet/prestations" 
                    >
                    <mat-icon matListItemIcon >
                        <span class="material-symbols-outlined">handyman</span>
                    </mat-icon>
                    <span>Gestion des prestations</span>
                </a>

                <a mat-list-item 
                    routerLinkActive="activeLink" 
                    routerLink="/app/articles"
                    >
                    <mat-icon matListItemIcon ><span class="material-symbols-outlined">qr_code</span></mat-icon><span>Gestion des articles</span>
                </a>

                <a  mat-list-item
                    routerLinkActive="activeLink"
                    routerLink="/app/adela/etiquette">
                    <mat-icon matListItemIcon>
                        <span class="material-symbols-outlined">calendar_month</span>  
                    </mat-icon>
                    <span>Historique des étiquettes</span>
                </a>

                <a  mat-list-item
                    routerLinkActive="activeLink"
                    routerLink="/app/adela/impression-etiquette">
                    <mat-icon matListItemIcon>
                        <span class="material-symbols-outlined">print</span>  
                    </mat-icon>
                    <span>Impression des étiquettes</span>
                </a>

                <a  mat-list-item
                    routerLinkActive="activeLink"
                    routerLink="/app/phrasier">
                    <mat-icon matListItemIcon>
                        <span class="material-symbols-outlined">description</span>
                    </mat-icon>
                    <span>Gestion des phrasiers</span>
                </a>
            
            </mat-nav-list>

        </mat-expansion-panel>

        <!-- GESTION DES EMAILS -->
        <mat-expansion-panel *ngIf="utl_profil.droits_utilisateur.est_admin || utl_profil.droits_utilisateur.droit_module_admin_compta">
                    
            <mat-expansion-panel-header >
                <mat-icon matListItemIcon  ><span class="material-symbols-outlined">email</span></mat-icon>
                <span class="title">Gestion des emails</span>
            </mat-expansion-panel-header>
            
            <mat-nav-list>
                <a mat-list-item 
                    routerLinkActive="activeLink" 
                    routerLink="/app/administration-extranet/emails"
                    >
                    <mat-icon matListItemIcon >
                        <span class="material-symbols-outlined">mail</span>
                    </mat-icon>
                    <span>Paramétrage des emails</span>
                </a>
            
                <a mat-list-item 
                    routerLinkActive="activeLink" 
                    routerLink="/app/administration-extranet/mails"
                    *ngIf="utl_profil.droits_utilisateur.est_admin"
                    >
                    <mat-icon matListItemIcon ><span class="material-symbols-outlined">visibility</span></mat-icon>
                    <span>Consultation des emails</span>
                </a>

            </mat-nav-list>

        </mat-expansion-panel>

        <!-- GESTION DES GROUPES/DROITS -->
        <mat-expansion-panel *ngIf="utl_profil.droits_utilisateur.est_admin">
                    
            <mat-expansion-panel-header >
                <mat-icon matListItemIcon  ><span class="material-symbols-outlined">groups</span></mat-icon>
                <span class="title">Gestion des groupes</span>
            </mat-expansion-panel-header>
            
            <mat-nav-list>
                <a mat-list-item 
                    routerLinkActive="activeLink" 
                    routerLink="/app/groupe"
                    >
                    <mat-icon matListItemIcon >
                        <span class="material-symbols-outlined">groups</span>
                    </mat-icon>
                    <span>Liens entre types d'entités et groupes</span>
                </a>

                <a mat-list-item 
                    routerLinkActive="activeLink" 
                    routerLink="/app/droit"
                    >
                    <mat-icon matListItemIcon >
                        <span class="material-symbols-outlined">passkey</span>
                    </mat-icon>
                    <span>Gestion des droits</span>
                </a>
            
            </mat-nav-list>

        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="utl_profil.droits_utilisateur.droit_module_admin_wsa">
                    
            <mat-expansion-panel-header >
                <mat-icon matListItemIcon  ><span class="material-symbols-outlined">settings</span></mat-icon>
                <span class="title">Administration WSA</span>
            </mat-expansion-panel-header>
            
            <mat-nav-list>
                <a mat-list-item 
                    routerLinkActive="activeLink" 
                    routerLink="/app/wsa/cabinets"
                    >
                    <mat-icon matListItemIcon >
                        <span class="material-symbols-outlined">apartment</span>
                    </mat-icon>
                    <span>Redirection de  cabinets</span>
                </a>

                <a mat-list-item 
                    routerLinkActive="activeLink" 
                    routerLink="/app/wsa/missions"
                    >
                    <mat-icon matListItemIcon >
                        <span class="material-symbols-outlined">work</span>
                    </mat-icon>
                    <span>Redirection de missions</span>
                </a>

                <a mat-list-item 
                    routerLinkActive="activeLink" 
                    routerLink="/app/wsa/redirections"
                    >
                    <mat-icon matListItemIcon >
                        <span class="material-symbols-outlined">calendar_month</span>
                    </mat-icon>
                    <span>Historique des redirections</span>
                </a>

            </mat-nav-list>

        </mat-expansion-panel>

        <!-- LIENS UTILES -->
        <mat-expansion-panel>
            <mat-expansion-panel-header >
                <mat-icon matListItemIcon ><span class="material-symbols-outlined">link</span></mat-icon>
                <span class="title">Liens utiles</span>
            </mat-expansion-panel-header>  

            <mat-nav-list>
                <a mat-list-item 
                    href  = "{{ URL_IFOR2A_AGATE }}"
                    target      = "_blank">
                    <mat-icon matListItemIcon ><span class="material-symbols-outlined">local_library</span></mat-icon><span>Portail Agathe iFor2A</span>
                </a>

                <a mat-list-item 
                    href  = "{{ URL_CARRE_EXPERT_AUTO }}"
                    target      = "_blank">
                <mat-icon matListItemIcon ><span class="material-symbols-outlined">fit_screen</span></mat-icon><span>Carré Expert Auto</span>
            </a>
            
            </mat-nav-list>
        </mat-expansion-panel>

    </div>
</ng-template>

<br>
<button mat-menu-item 
        (click)     = "clickOnDisconnect()" 
        [ngClass]="serviceFFEA === true ? 'sidenavFFEA':'sidenav'"
        >
    <mat-icon matListItemIcon ><span class="material-symbols-outlined">logout</span></mat-icon>
    <span>Déconnexion</span>
</button>