import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiAuthentificationService } from '../services/api-authentification.service';

@Injectable({
  providedIn: 'root'
})
export class ProtectServiceHistoriqueVehiculeGuard  {

  constructor(private apiAuth : ApiAuthentificationService
              , private router: Router){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const tree: UrlTree = this.router.parseUrl("/app/home");

    if ( this.apiAuth.root === this.apiAuth.CONST_URL_ROOT_SERVICE ) {
      return false
    }

    if(!this.apiAuth.userConnect.droits_utilisateur.droit_module_historique_vehicule) {
      return tree;
    }
    
    return true;
  }
  
}
