import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CheckValidatorService {

  constructor() { }

  public static checkMail(control: FormControl){

    const regularExpression =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let chaine: string = control.value;

    if (chaine == null) {
      return null;
    }
    if (chaine.length === 0) {
      return null;
    }

    if (regularExpression.test(String(chaine).toLowerCase())) {
      return null;
    } else {
      return { erreur: 'adresse mail incorrecte' };
    }
  }

  public static verificationFormatVIN(fcControler: FormControl): any {
    let vin: string = fcControler.value;
    let regex : any = /^[0-9A-Z]{17}$/;
    
    if (!regex.test(vin) && vin != '') {      
      return { erreur: 'Format incorrect : n° VIN de 17 caractères' }
    }    
    
    return null;
  }

  public static verificationFormatImmatriculation(fcControler: FormControl): any {
    let plaque: string = fcControler.value;    
    let regex : any = /^[0-9A-Z\-\ ]{1,10}$/;

    if (plaque != '' && !regex.test(plaque)) {      
      return { erreur: 'Format incorrect : immatriculation AA-123-AA ou 9999ZZZ99' }
    }    
    
    return null;
  }

  public static verificationFormatCodePostal(fcControler: FormControl): any {
    let code_postal: string = fcControler.value;
    let regex: any = /^[0-9]{5}$/;

    if(code_postal != '' && !regex.test(code_postal)) {
      return { erreur: 'Format incorrect : code postal'}      
    }

    return null;
  }

  public static verificationFormatMotDePasse(fcControler: FormControl): any {
    let motdepasse: string = fcControler.value;
    let regex: any = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if(!regex.test(motdepasse)) {
      return { erreur: 'Format incorrect : mot de passe doit contenir au minimum 8 caractères dont une majuscule, une minuscule, un nombre et un caractère spécial'}      
    }

    return null;
  }

  public static verificationNumeroTelephone(fcControler: FormControl): any {
    let regExp = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
    if (fcControler.value === '') { return null};
    return (regExp.test(fcControler.value) ? null : { erreur: 'Le format du numéro de téléphone est incorrect' });
  }

}
