import { NgFor, NgIf, NgStyle } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NomDirective } from 'src/app/directive/nom.directive';
import { TelephoneDirective } from 'src/app/directive/telephone.directive';
import { AccesConcentrateur } from 'src/app/interfaces/profil-user/acces-concentrateur';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { RelationUtilisateurEntite } from 'src/app/interfaces/utilisateur-entite/relation-utilisateur-entite';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { ApiRelUtilisateurEntiteService } from 'src/app/services/api-rel-utilisateur-entite.service';
import { ApiUtilisateurService } from 'src/app/services/api-utilisateur.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { AgrementValidator } from 'src/app/validators/agrement.validator';
import { TelephoneDirective as TelephoneDirective_1 } from '../../../directive/telephone.directive';
import { TitleCaseDirective } from '../../../directive/title-case.directive';
import { UpdatePasswordComponent } from '../../authentification/update-password/update-password.component';
import { GroupeService } from '../../groupe/data-access/groupe.service';
import { Groupe } from '../../groupe/groupe-interface';
import { DialogAccesConcentrateurValidationComponent } from '../dialog-acces-concentrateur-validation/dialog-acces-concentrateur-validation.component';
import { DialogPhotoProfilComponent } from '../dialog-photo-profil/dialog-photo-profil.component';
@Component({
    selector: 'app-utilisateur',
    templateUrl: './utilisateur.component.html',
    styleUrls: ['./utilisateur.component.scss'] // Chemin du fichier SCSS du component utilisateur
    ,
    standalone: true,
    imports: [NgIf, ReactiveFormsModule, MatCardModule, MatIconModule, MatFormFieldModule, MatInputModule, TitleCaseDirective
              , MatSelectModule, NgFor, MatOptionModule, MatSlideToggleModule, TelephoneDirective_1, MatTooltipModule
              , MatButtonModule, MatProgressSpinnerModule, MatDividerModule, UpdatePasswordComponent, NgStyle,NomDirective
              , FormsModule]
})
export class UtilisateurComponent implements OnInit {

  entite                  : Entite = <Entite>{};                        // Objet entite de l'interface
  profilUtilisateur       : ProfilUtilisateur = <ProfilUtilisateur>{};  // Objet profilUtilisateur de l'interface
  isAdmin                 : boolean = false;                            // Droit utilisateur
  acces_concentrateur     : AccesConcentrateur = <AccesConcentrateur>{};// Accès Concentrateur de l'interface
  utl_id!                 : number;                                     // identifiant utilisateur (! = non-null)
  ent_id                  : number = 0;                                 // Optionnel, numéro de l'entite associé
  userConnect             : ProfilUtilisateur = <ProfilUtilisateur>{};  // Utilisateur connecté
  rel_utl_ent             : RelationUtilisateurEntite = <RelationUtilisateurEntite>{};
  relUtilisateurEntite    : Array<RelationUtilisateurEntite> = new Array<RelationUtilisateurEntite> ();
  groupes                 : Groupe[]=[];
  isServiceFFEA           : boolean = false;
  waiting                 : boolean = false;
  isChecked               : boolean = false;

  get email()       { return this.profilUtilisateurForm.get('email'); }
  get nom()         { return this.profilUtilisateurForm.get('nom'); }
  get prenom()      { return this.profilUtilisateurForm.get('prenom'); }
  get agrement_ve() { return this.profilUtilisateurForm.get('agrement_ve'); }
  get telephone()   { return this.profilUtilisateurForm.get('telephone'); }
    
  // Déclaration du formulaire
  // ! Dans le cas des services FFEA, l'agrément et le téléphone ne sont pas affichés. Le fait d'imposer une validation sur ces champs-là
  //fait que le bouton "Enregistrer" ne sera jamais enabled car ces 2 champs seront toujours invalides.
  profilUtilisateurForm = this.fBuilder.group({
    id                          : new FormControl<number>(0),
    agrement_ve                 : new FormControl('', [this.agrementValidator.verificationFormatAgrementVE]),
    nom                         : new FormControl('', [Validators.required]),
    prenom                      : new FormControl('', [Validators.required]),
    email                       : ['', [Validators.required]],
    reinitialisation_motdepasse : new FormControl<boolean>(false),
    telephone                   : new FormControl<string>(''),
    date_creation               : new FormControl<string>(''),
    date_derniere_modification  : new FormControl<string>(''),
    actif                       : new FormControl<boolean>(false),
    profileImage                : new FormControl<string>(''),
    estDirigeant                : new FormControl<boolean>(false),
    access_anea_partage         : new FormControl<boolean>(false),
    idGroupe                    : new FormControl<number>(0)
  });

  accesConcentrateurForm = this.fBuilder.group({
    autorisation : [false]
  });

  /*
    - apiUtilisateur  : Service de communication avec les API Utilisateurs
    - fBuilder        : Simplifier l'écriture d'un formulaire
    - route           : Récupérer paramètre 'id' dans URL
    - router          : Naviguer vers une autre URL
  */
   
  constructor(private apiUtilisateur          : ApiUtilisateurService
            , private apiAuth                 : ApiAuthentificationService
            , private apiRelUtilisateurEntite : ApiRelUtilisateurEntiteService
            , private apiEntite               : ApiEntiteService
            , private fBuilder                : FormBuilder
            , private route                   : ActivatedRoute
            , private router                  : Router
            , private toast                   : PersoSnackbarService
            , public _sanitizer               : DomSanitizer
            , public dialog                   : MatDialog
            , private phoneDirective          : TelephoneDirective
            , private apiGroupe               : GroupeService
            , private agrementValidator       : AgrementValidator
            ) { }

  // ngOnInit est une fonction Angular appelé une fois le component initialisé
  // Il sert à exécuter le code "de travail" du component
  ngOnInit(): void {    
    // Récupération de l'identifiant saisie en paramètre de l'URL
    this.ent_id         = this.route.snapshot.params['ent_id'];
    this.utl_id         = this.route.snapshot.params['utl_id'];
    this.userConnect    = this.apiAuth.userConnect;    
    this.isServiceFFEA  = this.apiAuth.isServiceFFEA();
    this.getUtilisateur();
    //this.apiGroupe.getGroupesParCodeEntite(this.apiEntite.CONST_CODE_TYPE_ENTITE_EXPERT).subscribe(
    this.apiGroupe.getGroupes(this.apiAuth.GetEnvironmentCode()).subscribe(
      (groupes: Groupe[])=>this.groupes = groupes)
    
  }
  
  /* VERFICATION FORMAT SAISIE */
  // Vérification du format du nom
  // Le nom doit faire entre 1 et 50 caractères avec uniquement des majuscules, tirets ou des espaces
  verificationFormatNom(fcControler: FormControl): ValidationErrors | null {
    let s_nom:    string  = fcControler.value;
    let a_regex:  any     = /^[A-Z\-\ ]{1,50}$/;
    
    if (!a_regex.test(s_nom)) {
      return { erreur: 'Le nom doit contenir uniquement tirets ou des espaces' }
    }

    return null;
  }
  
  

  // Vérification du format du prénom
  // Le prénom doit faire entre 1 et 50 caractères avec une majuscule en première lettre et le reste en minuscule
  verificationFormatPrenom(fcControler: FormControl): any {

    let s_prenom: string  = fcControler.value;
    let a_regex:  any     = /^[A-Za-z\-\ ]{3,50}$/;
    
    if (!a_regex.test(s_prenom)) {
      return { erreur: 'Le prénom doit faire entre 3 et 50 caractères avec une majuscule en première lettre, tirets ou des espaces' }
      //return { erreur: 'Le prénom doit faire entre 3 et 50 caractères' }
    }

    return null;
  }

  // Vérification du format
  verificationFormatRoleEntite(control: FormControl): any {
    if ( this.ent_id != undefined ) {
      if ( control.value != 0 && control.value != null ) {
        return null;
      } else {
        return { erreur: 'Sélectionnez un rôle pour l\'entite en cours' };
      }
    } else {
      return null;
    }
  }

  /* COMMUNICATION AVEC L'API */
  getUtilisateur() : void {    
    
    if(this.utl_id > 0) {
      this.apiUtilisateur.getUtilisateur(this.utl_id)
      .subscribe((data: ProfilUtilisateur) => {
          this.profilUtilisateur = data;

          // Assigner les valeurs au formulaire
          this.profilUtilisateurForm.patchValue({
            id:                           this.profilUtilisateur.id,
            agrement_ve:                  this.profilUtilisateur.agrement_ve,
            nom:                          this.profilUtilisateur.nom,
            prenom:                       this.profilUtilisateur.prenom,
            email:                        this.profilUtilisateur.email,
            reinitialisation_motdepasse:  this.profilUtilisateur.reinitialisation_motdepasse,
            telephone:                    this.phoneDirective.getPhoneNumberWithoutFormat(this.profilUtilisateur.telephone),
            date_creation:                this.profilUtilisateur.date_creation,
            date_derniere_modification:   this.profilUtilisateur.date_derniere_modification,
            actif:                        this.profilUtilisateur.actif,
            profileImage:                 this.profilUtilisateur.photo_profil.profile_image,
            estDirigeant:                 this.isChecked,
            access_anea_partage:          this.profilUtilisateur.access_anea_partage,
            idGroupe                    : this.profilUtilisateur.idgrp
          });
          
          if(this.ent_id == undefined) { this.ent_id = 0 }
          })
        , catchError((error: HttpErrorResponse) => { 
          this.toast.showError(error.error);
          return throwError(error);
        }
      )
    }

    if(this.ent_id > 0 && this.utl_id > 0) {
      this.apiRelUtilisateurEntite.getRelUtilisateurEntite(this.utl_id, this.ent_id)
      .subscribe((data: RelationUtilisateurEntite[]) => {
        this.relUtilisateurEntite = data;
          this.relUtilisateurEntite.find(rel_ent_utl => {
            // Si l'entité visualisé en cours est égale à la relation entrain d'être parcouru,
            // on set la valeur de la combo "estDirigeant"
            // Vrai = Dirigeant - Faux = Pas dirigeant
            if(rel_ent_utl.entite_id == this.ent_id) {
              this.rel_utl_ent = rel_ent_utl;              
              this.isChecked = this.rel_utl_ent.est_dirigeant
              // this.profilUtilisateurForm.controls['estDirigeant'].setValue(this.rel_utl_ent.est_dirigeant);  
            }
          });
          // Récupération du détails de l'entité et affectation de l'accès au Concentrateur (= droit d'émettre au Concentrateur)
          this.accesConcentrateurForm.controls['autorisation'].setValue(false);
          if (this.profilUtilisateur.acces_concentrateur) {
            for(let i=0; i<this.profilUtilisateur.acces_concentrateur.length; i++) {
              if(this.profilUtilisateur.acces_concentrateur[i].cle_expert === this.profilUtilisateur.agrement_ve + this.entite.habilitation) {
                this.accesConcentrateurForm.controls['autorisation'].setValue(true);
              }
            } 
          }
      })
    }

    if(this.ent_id > 0) {
      this.apiEntite.getEntiteById(this.ent_id)
      .subscribe(
        (data: Entite[]) => {
          this.entite = data[0];
          if (this.profilUtilisateur.acces_concentrateur) {
            for(let i=0; i<this.profilUtilisateur.acces_concentrateur.length; i++) {
              if(this.profilUtilisateur.acces_concentrateur[i].cle_expert === this.profilUtilisateur.agrement_ve + this.entite.habilitation) {
                this.accesConcentrateurForm.controls['autorisation'].setValue(true);
              }
            }
            
          }
        },
        (err) => {'/!\\ error getEntiteById: ' + console.log(err)}
      );
    }
  }

  enregistrerUtilisateur(): void{
    // Assigner les valeurs à l'objet
    this.waiting = true
      
    this.profilUtilisateur.id                           = this.utl_id;
    this.profilUtilisateur.agrement_ve                  = this.profilUtilisateurForm.value.agrement_ve!;
    this.profilUtilisateur.nom                          = this.profilUtilisateurForm.value.nom!;
    this.profilUtilisateur.prenom                       = this.profilUtilisateurForm.value.prenom!;
    this.profilUtilisateur.email                        = this.profilUtilisateurForm.value.email!;
    this.profilUtilisateur.reinitialisation_motdepasse  = this.profilUtilisateurForm.value.reinitialisation_motdepasse!;
    this.profilUtilisateur.telephone                    = this.profilUtilisateurForm.value.telephone!;
    this.profilUtilisateur.date_creation                = this.profilUtilisateurForm.value.date_creation!;
    this.profilUtilisateur.date_derniere_modification   = this.profilUtilisateurForm.value.date_derniere_modification!;
    this.profilUtilisateur.actif                        = this.profilUtilisateurForm.value.actif!;   
    this.profilUtilisateur.access_anea_partage          = this.profilUtilisateurForm.value.access_anea_partage!; 
    this.profilUtilisateur.idgrp                        = this.profilUtilisateurForm.value.idGroupe!
    this.rel_utl_ent.est_dirigeant                      = this.profilUtilisateurForm.value.estDirigeant!;
    this.rel_utl_ent.est_active                         = true;
    
    this.apiUtilisateur.putUtilisateur(this.utl_id, this.profilUtilisateur)
    .subscribe(data => 
      {
        if(this.utl_id == this.apiAuth.userConnect.id) {
          this.apiAuth.userConnect.agrement_ve        = this.profilUtilisateur.agrement_ve;
          this.apiAuth.userConnect.nom                = this.profilUtilisateur.nom;
          this.apiAuth.userConnect.prenom             = this.profilUtilisateur.prenom;
          this.apiAuth.userConnect.email              = this.profilUtilisateur.email;
          this.apiAuth.userConnect.telephone          = this.profilUtilisateur.telephone;
        }
        
        // ENREGISTRER le role de l'utilisateur pour l'entité
        this.enregistrerRelation();
        this.toast.validate("Modification effectuée")
        this.getUtilisateur();
        this.waiting = false
    });    
  }

  ActiveUnactiveUser(): void{
    this.apiUtilisateur.activateOrDeactivateUser(this.utl_id).subscribe(data => {  
      this.toast.showInfo('Compte '+ (this.profilUtilisateur.actif ? 'désactivé' : 'activé'))
      this.profilUtilisateur.actif = !this.profilUtilisateur.actif;
      if ( this.apiAuth.userConnect.id === this.utl_id ) {
        this.apiAuth.navigateToLoginPage();  
      }  
    },
    err => this.toast.showError(err.message))
  }

  private enregistrerRelation() {
    if (this.ent_id != undefined && this.ent_id > 0) {           
      this.apiUtilisateur.putRoleEntite(this.rel_utl_ent)
        .subscribe(data => {
          this.goBack();
        }
          , error => console.log('err: '+error.toString()));
    }
  }

  onClickDelete() {        
    this.profilUtilisateur.photo_profil.profile_image = '';

    this.apiUtilisateur.uploadProfileImage(this.utl_id, this.profilUtilisateur)
          .subscribe( 
          (data) => {      
            if(this.apiAuth.userConnect.id == this.profilUtilisateur.id) {
              this.apiAuth.userConnect.photo_profil.profile_image = '';
            }
                        
            this.toast.showInfo("Photo de profil supprimée")
            this.getUtilisateur();
          },
          (err) => { '/!\\ error deleteProfileImage: ' + console.log(err)});        
  }

  onClickDisableEnableRelUtlEnt(isActive :boolean) {
    
    this.rel_utl_ent.est_dirigeant  = this.profilUtilisateurForm.value.estDirigeant!;
    this.rel_utl_ent.est_active     = isActive;

    this.enregistrerRelation();
    if(isActive){
      this.toast.validate("L'utilisateur est activé");
    } else {
      this.toast.showInfo("L'utilisateur est désactivé");
    }
  }

  onClickUpload() {  
    const dialogRef = this.dialog.open(DialogPhotoProfilComponent);  
    dialogRef.afterClosed()
          .subscribe(
            (data: boolean) => { 
              if(data) {                
                this.profilUtilisateur.photo_profil.profile_image = dialogRef.componentInstance.croppedImage;
      
                this.apiUtilisateur.uploadProfileImage(this.utl_id, this.profilUtilisateur)
                  .subscribe( 
                  (data) => { 
                    if(this.apiAuth.userConnect.id == this.profilUtilisateur.id) {
                      this.apiAuth.userConnect.photo_profil.profile_image = this.profilUtilisateur.photo_profil.profile_image;   
                    }                
                    this.toast.showInfo("Photo de profil mise à jour")
                    this.getUtilisateur();
                  },
                  (err) => { '/!\\ error uploadProfileImage: ' + console.log(err)});  
                      }
                    },
                    (err: any) => { 
                      '/!\\ error dialogPhotoProfil: ' + console.log(err);
                    },
                    () => { console.log('complete: dialogPhotoProfil') }
            );  
  }

  onToggle(event: MatSlideToggleChange) {            
    this.acces_concentrateur.cle_expert   = this.profilUtilisateur.agrement_ve + this.entite.habilitation;
    this.acces_concentrateur.agrement     = this.profilUtilisateur.agrement_ve;
    this.acces_concentrateur.habilitation = this.entite.habilitation;

    if(event.checked === true) {
      this.apiUtilisateur.postCreerAccesConcentrateur(this.acces_concentrateur, this.userConnect.id, this.ent_id, this.profilUtilisateur.id)
      .subscribe(
        (data: AccesConcentrateur) => {
          this.acces_concentrateur = data;
          this.toast.showInfo("Accès Concentrateur créé");
        },
        (err) => {
          this.accesConcentrateurForm.controls['autorisation'].setValue(false);
          '/!\\ error postCreerAccesConcentrateur: ' + console.log(err);          
        }
      );
    } else {
      // Confirmation de la désactivation
      const dialogRef = this.dialog.open(DialogAccesConcentrateurValidationComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result == true){
          this.apiUtilisateur.postSupprimerAccesConcentrateur(this.acces_concentrateur, this.userConnect.id, this.ent_id, this.profilUtilisateur.id)
          .subscribe(
            (data: AccesConcentrateur) => {
              this.acces_concentrateur = data;          
              this.toast.showInfo("Accès Concentrateur supprimé");
            },
            (err) => {
              this.accesConcentrateurForm.controls['autorisation'].setValue(true);
              '/!\\ error postSupprimerAccesConcentrateur: ' + console.log(err)        
            }
          );
        } else {
          this.accesConcentrateurForm.controls['autorisation'].setValue(true);
        }
      });
    }
  }

  goBack(): void {
    if ( this.ent_id != undefined && this.ent_id != 0 ) { 
      this.router.navigate([ '/app/entite/' + this.ent_id]);
    }
  }

  isConcentrateurActif() : boolean {
    return this.accesConcentrateurForm.controls.autorisation.value!;
  }

  onDirigeantChange(event: any): void {
    this.isChecked = event.checked
  }
}