
<mat-card appearance="outlined" *ngIf="typeValeurElementForm">
    <mat-card-header>
        <mat-card-title>Gestion des composants</mat-card-title>
    </mat-card-header>
    
    <mat-card-content class=".mat-card-content">
        
        <div [formGroup]="typeValeurElementForm" class="flex">
            <ng-container formArrayName="elementArray">
                
                <div class="content" >
                    <div class="titlebox">Composantes sans catégorie</div>
                    <mat-grid-list cols="5" rowHeight="30px" style="width: 500px;" class="title">
                        <mat-grid-tile colspan="3">Composante</mat-grid-tile>
                        <mat-grid-tile colspan="1">Huile</mat-grid-tile>
                        <mat-grid-tile colspan="1">Poudre</mat-grid-tile>
                    </mat-grid-list>
                    <div    *ngFor          = "let result of elementArray().controls; let i=index"
                            [formGroupName] = "i"
                            class           = "formFieldValeur"
                            appearance      = "outline">
                        <mat-grid-list cols="5" rowHeight="30px" style="width: 500px;" *ngIf="result.get('code_categorie')!.value===''">
                            <mat-grid-tile colspan="3">{{result.get('libelle')!.value}}</mat-grid-tile>
                            
                            
                                <ng-container  formArrayName="typeElement">
                                        <div *ngFor = "let element of typeElement(i).controls;let j=index">
                                            <mat-grid-tile colspan="1">
                                                <mat-slide-toggle [formControl]="checked(i,j)" [checked]="element.get('checked')!.value">
                                                </mat-slide-toggle>
                                            </mat-grid-tile>
                                            
                                        </div>
                                </ng-container>
                            
                        </mat-grid-list>
                    </div>
                </div>

                <div class="content" >
                    <div class="titlebox">Composantes physico-chimiques</div>
                    <mat-grid-list cols="5" rowHeight="30px" style="width: 500px;" class="title">
                        <mat-grid-tile colspan="3">Composante</mat-grid-tile>
                        <mat-grid-tile colspan="1">Huile</mat-grid-tile>
                        <mat-grid-tile colspan="1">Poudre</mat-grid-tile>
                    </mat-grid-list>
                    <div    *ngFor          = "let result of elementArray().controls; let i=index"
                            [formGroupName] = "i"
                            class           = "formFieldValeur"
                            appearance      = "outline">
                        <mat-grid-list cols="5" rowHeight="30px" style="width: 500px;" *ngIf="result.get('code_categorie')!.value==='PHYSICOCHIMIQUE'">
                            <mat-grid-tile colspan="3">{{result.get('libelle')!.value}}</mat-grid-tile>
                            
                            
                                <ng-container  formArrayName="typeElement">
                                        <div *ngFor = "let element of typeElement(i).controls;let j=index">
                                            <mat-grid-tile colspan="1">
                                                <mat-slide-toggle [formControl]="checked(i,j)" [checked]="element.get('checked')!.value">
                                                </mat-slide-toggle>
                                            </mat-grid-tile>
                                            
                                        </div>
                                </ng-container>
                            
                        </mat-grid-list>
                    </div>
                </div>

                <div class="content" >
                    <div class="titlebox">Composantes physico-chimiques bsm</div>
                    <mat-grid-list cols="5" rowHeight="30px" style="width: 500px;" class="title">
                        <mat-grid-tile colspan="3">Composante</mat-grid-tile>
                        <mat-grid-tile colspan="1">Huile</mat-grid-tile>
                        <mat-grid-tile colspan="1">Poudre</mat-grid-tile>
                    </mat-grid-list>
                    <div    *ngFor          = "let result of elementArray().controls; let i=index"
                            [formGroupName] = "i"
                            class           = "formFieldValeur"
                            appearance      = "outline">
                        <mat-grid-list cols="5" rowHeight="30px" style="width: 500px;" *ngIf="result.get('code_categorie')!.value==='PHYSICOCHIMIQUE_BSM'">
                            <mat-grid-tile colspan="3">{{result.get('libelle')!.value}}</mat-grid-tile>
                            
                            
                                <ng-container  formArrayName="typeElement">
                                        <div *ngFor = "let element of typeElement(i).controls;let j=index">
                                            <mat-grid-tile colspan="1">
                                                <mat-slide-toggle [formControl]="checked(i,j)" [checked]="element.get('checked')!.value">
                                                </mat-slide-toggle>
                                            </mat-grid-tile>
                                            
                                        </div>
                                </ng-container>
                            
                        </mat-grid-list>
                    </div>
                </div>

                <div class="content" >
                    <div class="titlebox">Composantes spectrométriques</div>
                    <mat-grid-list cols="5" rowHeight="30px" style="width: 500px;" class="title">
                        <mat-grid-tile colspan="3">Composante</mat-grid-tile>
                        <mat-grid-tile colspan="1">Huile</mat-grid-tile>
                        <mat-grid-tile colspan="1">Poudre</mat-grid-tile>
                    </mat-grid-list>
                    <div    *ngFor          = "let result of elementArray().controls; let i=index"
                            [formGroupName] = "i"
                            class           = "formFieldValeur"
                            appearance      = "outline">
                        <mat-grid-list cols="5" rowHeight="30px" style="width: 500px;" *ngIf="result.get('code_categorie')!.value==='SPECTROMETRIE'">
                            <mat-grid-tile colspan="3">{{result.get('libelle')!.value}}</mat-grid-tile>
                            
                            
                                <ng-container  formArrayName="typeElement">
                                        <div *ngFor = "let element of typeElement(i).controls;let j=index">
                                            <mat-grid-tile colspan="1">
                                                <mat-slide-toggle [formControl]="checked(i,j)" [checked]="element.get('checked')!.value">
                                                </mat-slide-toggle>
                                            </mat-grid-tile>
                                            
                                        </div>
                                </ng-container>
                            
                        </mat-grid-list>
                    </div>
                </div>
            </ng-container>

        </div>
    </mat-card-content>
    <mat-card-actions align="end">
       <button mat-raised-button color="primary" (click)="Enregistrer()">Enregistrer</button>
   </mat-card-actions>
</mat-card>