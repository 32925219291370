import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

@Injectable({ providedIn: 'root' })
export class AnneeValidator {

    public valideAnnee(anneeMin:string,anneeMax:string):ValidatorFn {
      return (control: AbstractControl):ValidationErrors | null  => {
        
        const annee = control.value;
        if (!annee) {
          return null;
        }

        if (annee >= anneeMin && annee <= anneeMax) {
          return null;
        }else{
          return { anneeDoesNotMatch: true };
        }
      };
    }

  }