import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaliseMail } from '../interfaces/balise';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ApiErrorService } from 'src/app/services/support/api-error.service';

@Injectable({
  providedIn: 'root'
})
export class BaliseDataAccesService {

  url: string       = '/api/balise';

  constructor(private http        : HttpClient
            , private apiError    : ApiErrorService) { }

  public getBalises(): Observable<BaliseMail[]> {
    return this.http.get<BaliseMail[]>(this.url)
                    .pipe(catchError(this.apiError.handleError<BaliseMail[]>('getBalises',[])));
  } 
}
