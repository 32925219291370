import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Categorie } from '../interfaces/phrasier/categorie';
import { Phrasier } from '../interfaces/phrasier/phrasier';
import { SousCategorie } from '../interfaces/phrasier/sous-categorie';
import { PersoSnackbarService } from './perso-snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class ApiPhrasierService {

  url: string = '/api';

  constructor(private http: HttpClient, private toast: PersoSnackbarService) { }

  public getPhrasier(id: number) : Observable<Phrasier> {
    return this.http.get<Phrasier>(this.url+"/phrasier/unique/"+id)
      .pipe(
        tap((phrasier : Phrasier) => {
          
          return phrasier;
        }), 
        catchError((error : HttpErrorResponse) => {
          this.toast.showError(error.error);
          return throwError(error);
        })        
      )
  }

  // Chargements des phrasiers
  public getPhrasiers(idCategorie: number, idSousCategorie: number) : Observable<Phrasier[]> { 
    return this.http.get<Phrasier[]>(this.url+"/phrasier?categorie="+idCategorie+"&sous-categorie="+idSousCategorie)
      .pipe(
        tap((phrasier : Phrasier[]) => {
          return phrasier;
        }), 
        catchError((error : HttpErrorResponse) => {
          this.toast.showError(error.error);
          return throwError(error);
        })        
      )
  }

  // Création d'un phrasier
  public postPhrasier(phrasier : Phrasier) : Observable<Phrasier> {
    return this.http.post<Phrasier>(this.url+'/phrasier', phrasier);
  }

  // Modification d'un phrasier
  public putPhrasier(id: number, phrasier : Phrasier) : any {
    return this.http.put<any>(this.url+'/phrasier/'+id, phrasier);
  }

  // Suppression d'un phrasier
  public deletePhrasier(id: number) : any {
    return this.http.delete<any>(this.url+'/phrasier?id='+id);
  }

  // Chargements des catégories
  public getCategories() : Observable<Categorie[]> {
    return this.http.get<Categorie[]>(this.url+"/phrasier/categories")
    .pipe(
      tap((categorie : Categorie[]) => {
        return categorie;
      }), 
      catchError((error : HttpErrorResponse) => {
        this.toast.showError(error.error);
        return throwError(error);
      })        
    )
  }

  // Chargements des sous-catégories
  public getSousCategories() : Observable<SousCategorie[]> {
    return this.http.get<SousCategorie[]>(this.url+"/phrasier/sous-categories")
    .pipe(
      tap((sous_categorie : SousCategorie[]) => {
        return sous_categorie;
      }), 
      catchError((error : HttpErrorResponse) => {
        this.toast.showError(error.error);
        return throwError(error);
      })        
    )
  }

/*  
    // Ajouter une commande
    public postCommande(commande: Commande): Observable<Commande> {
      return this.http.post<Commande>(this.url+'/commande', commande);
    }*/
}
