import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AppUpdateService } from 'src/app/services/app-update.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-app-install',
    templateUrl: './app-install.component.html',
    styleUrls: ['./app-install.component.scss'],
    standalone: true,
    imports: [NgIf, MatToolbarModule, MatButtonModule, MatIconModule, AsyncPipe]
})
export class AppInstallComponent implements OnInit {


  constructor(private appUpdateSvc: AppUpdateService) { }

  ngOnInit(): void {
  }

  public get installAppLater$(): boolean {
    return this.appUpdateSvc.installAppLater$;
  }

  public get canInstall$(): Observable<boolean> {
    return this.appUpdateSvc.canInstallApp$;
  }

  public install(): void {
    this.appUpdateSvc.install();
  }

  public onInstallAppLater(): void { 
    this.appUpdateSvc.setStorageInstallAppLater();
  }
}
