
<mat-card appearance="outlined">
    <mat-card-title>{{title}}</mat-card-title>
    <br>
    
    <form [formGroup]="prestationForm">
       
        <mat-form-field >
            <mat-label>Désignation</mat-label>
            <input matInput formControlName = "designation">
            <mat-error *ngIf="prestationForm.get('designation')?.hasError('required')">Champ obligatoire</mat-error>
        </mat-form-field>
        
        <mat-form-field >
            <mat-label>Libellé grille tarifaire</mat-label>
            <input matInput formControlName = "libelle">
            <mat-error *ngIf="prestationForm.get('libelle')?.hasError('required')">Champ obligatoire</mat-error>
        </mat-form-field>
        
        <mat-slide-toggle formControlName="affiche" color="primary" style="margin-left: 20px;margin-right: 20px;">
            {{(prestationForm.controls.affiche.value ? "Afficher dans la grille tarifaire" : "Ne pas afficher dans la grille tarifaire" )}}
        </mat-slide-toggle>
    
        <div>
            <mat-form-field >
                <mat-label>Catégorie</mat-label>
                <input matInput formControlName = "categorie" oninput="this.value = this.value.toUpperCase()">
                <mat-error *ngIf="prestationForm.get('categorie')?.hasError('required')">Champ obligatoire</mat-error>
            </mat-form-field>


            <mat-form-field *ngIf="typeElements$ |async as typeElements">
                <mat-label>Type d'élément</mat-label>
                <mat-select formControlName="TypeElement" (selectionChange)="onSelectChangeTypeElement($event)">
                    <mat-option *ngFor="let typeElement of typeElements" [value]="typeElement">{{ typeElement.libelle }}</mat-option>
                </mat-select>
                <mat-error *ngIf="prestationForm.get('TypeElement')?.hasError('required')">Champ obligatoire</mat-error>
            </mat-form-field>

            <mat-form-field style="max-width: 100px;">
                <mat-label>Tarif</mat-label>
                <input matInput type="number" min="0.00" max="1000000.00" step="0.01" formControlName = "tarif">
                <span matTextSuffix>€</span>
                <mat-error *ngIf="prestationForm.get('tarif')?.hasError('required')">Champ obligatoire</mat-error>
            </mat-form-field>

            <mat-checkbox formControlName = "actif">
                active
            </mat-checkbox>
        </div>

        <div *ngIf="prestationForm.controls.TypeElement.value.code == 1">
            <mat-form-field *ngIf="typeAnalyses$ |async as typeAnalyses">
                <mat-label>Type d'analyse</mat-label>
                <mat-select formControlName="idTypeAnalyse">
                    <mat-option *ngFor="let typeAnalyse of typeAnalyses" [value]="typeAnalyse.id">{{ typeAnalyse.nom }}</mat-option>
                </mat-select>
                <mat-error *ngIf="prestationForm.get('idTypeAnalyse')?.hasError('required')">Champ obligatoire</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="fluides$ |async as fluides">
                <mat-label>Fluide</mat-label>
                <mat-select  formControlName="idFluide">
                    <mat-option *ngFor="let fluide of fluides" [value]="fluide.id">{{ fluide.nom }}</mat-option>
                </mat-select>
                <mat-error *ngIf="prestationForm.get('idFluide')?.hasError('required')">Champ obligatoire</mat-error>
            </mat-form-field> 
            
            <mat-form-field *ngIf="organes$ |async as organes">
                <mat-label>Organe</mat-label>
                <mat-select formControlName="idOrgane">
                    <mat-option *ngFor="let organe of organes" [value]="organe.id">{{ organe.nom }}</mat-option>
                </mat-select>
                <mat-error *ngIf="prestationForm.get('idOrgane')?.hasError('required')">Champ obligatoire</mat-error>
            </mat-form-field>
        </div>

        <mat-form-field >
            <mat-label>Code Laboratoire</mat-label>
            <input matInput formControlName = "codeLaboratoire" oninput="this.value = this.value.toUpperCase()" maxlength="12">
            <mat-error *ngIf="prestationForm.get('codeLaboratoire')?.hasError('required')">Champ obligatoire</mat-error>
            <mat-error *ngIf="prestationForm.get('codeLaboratoire')?.hasError('maxLength')">Maximum 12 caractères</mat-error>
        </mat-form-field>
        
        <mat-form-field >
            <mat-label>Code </mat-label>
            <input matInput formControlName = "code" oninput="this.value = this.value.toUpperCase()" maxlength="6">
            <mat-error *ngIf="prestationForm.get('code')?.hasError('maxLength')">Maximum 6 caractères</mat-error>
        </mat-form-field>
        
        <mat-card-actions align="end">
            <button mat-raised-button color ="primary" (click)   = "cancel()">Annuler</button>
            <button mat-raised-button color ="primary" (click)   = "save()" [disabled]="!prestationForm.valid"> Enregistrer</button>
        </mat-card-actions>
    </form>
</mat-card>