import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { Article } from 'src/app/interfaces/commande/article';
import { ApiArticleService } from 'src/app/services/api-article.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { ActionDeMasseArticlesComponent } from '../action-de-masse-articles/action-de-masse-articles.component';
import { NgStyle, DecimalPipe } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableResponsiveLargeDirective } from '../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ContexteService, TYPE_ARTICLE } from 'src/app/services/contexte.service';
@Component({
    selector: 'app-table-articles',
    templateUrl: './table-articles.component.html',
    styleUrls: ['./table-articles.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatIconModule, MatTableModule, MatTableResponsiveLargeDirective, MatCheckboxModule, NgStyle, MatPaginatorModule, DecimalPipe]
})
export class TableArticlesComponent implements OnInit, OnChanges, AfterViewInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;
  @Input() dataSource_articles: any;

  selection   = new SelectionModel<Article>(true, []);
  dataSourceMatTable: MatTableDataSource<any> = new MatTableDataSource<any>();
  
  expandPannel        : boolean = true;

  // Tableau des articles
  displayedColumns    : Array<string> = [ 'select', 'rtc_reference', 'rtc_titre', 'frn_libelle', 'rtc_actif', 'rtc_prix_ht', 'rtc_location_ht', 'rtc_port_ht', 'rtc_remise', 'actions'];
  readonly type      : string = TYPE_ARTICLE

  constructor(private router    : Router
            , public dialog     : MatDialog
            , private toast     : PersoSnackbarService
            , private apiArticle: ApiArticleService
            , private contexte        : ContexteService
            , private cdr             : ChangeDetectorRef ) { }

  ngOnInit(): void {
  }
  
  ngAfterViewInit() {
    this.paginator!.pageIndex = this.contexte.getPageIndex(this.type)
    this.paginator!.pageSize = this.contexte.getPages(this.type)
    this.dataSourceMatTable.paginator = this.paginator;
    this.dataSourceMatTable.sort      = this.sort;
    this.cdr.detectChanges();
  }

  ngOnChanges() {
    this.dataSourceMatTable.paginator = this.paginator;
    // obligatoire
    if(this.dataSource_articles != undefined) {
      this.dataSourceMatTable.data = this.dataSource_articles;
      this.selection.clear();
    }     
  }

  public onClickOpenArticle(rtc_id: number) {
    this.router.navigate(['/app/article/', rtc_id]);
  }

  public onCreer(): void {
    this.router.navigate(['/app/article/nouveau']);
  }

  public onActiver(): void {    
    let dataSource_articles = this.getDataSourceSelected();
    if(dataSource_articles != undefined) {
      if(dataSource_articles.length > 0 ) {
        const dialogRef = this.dialog.open(ActionDeMasseArticlesComponent, {data : {type: 'activate', dataSource_articles: this.getDataSourceSelected()}});   
    
        dialogRef.afterClosed().subscribe(result => {
          if(result) {
            this.apiArticle.isDataModify.next(true);
            this.selection.clear();
          }      
        });
      } else {
        this.toast.showAlert('Vous devez sélectionner au moins un article')
      }
    }
  }

  public onDesactiver(): void {
    let dataSource_articles = this.getDataSourceSelected();
    if(dataSource_articles != undefined) {
      if(dataSource_articles.length > 0 ) {
        const dialogRef = this.dialog.open(ActionDeMasseArticlesComponent, {data : {type: 'deactivate', dataSource_articles: this.getDataSourceSelected()}});

        dialogRef.afterClosed().subscribe(result => {
          if(result) {
            this.apiArticle.isDataModify.next(true);
          }      
        });
      } else {
        this.toast.showAlert('Vous devez sélectionner au moins un article')
      }
    }
  }

  public onSupprimer(): void {
    let dataSource_articles = this.getDataSourceSelected();
    if(dataSource_articles != undefined) {
      if(dataSource_articles.length > 0 ) {
        const dialogRef = this.dialog.open(ActionDeMasseArticlesComponent, {data : {type: 'delete', dataSource_articles: dataSource_articles}});

        dialogRef.afterClosed().subscribe(result => {
          if(result) {
            this.apiArticle.isDataModify.next(true);
          }      
        });
      } else {
        this.toast.showAlert('Vous devez sélectionner au moins un article')
      }
    }
    
  }

  public onDuppliquer(rtc_id: number): void {
    this.router.navigate(['/app/article/', rtc_id], { queryParams: { type: 'dupplicate' }});
  }

  // Récupère les articles sélectionnés dans la table
  private getDataSourceSelected(): Array<Article> {
    let dataSource: Array<Article> = Array<Article>();

    // Détermine quelles lignes sont sélectionnées, et les ajoute dans les données à transmettre à la mat-dialog
    for(let i = 0; i < this.dataSource_articles.length; i++) {
      if(this.selection.isSelected(this.dataSource_articles[i])) {
        dataSource.push(this.dataSource_articles[i]);
      }
    }

    return dataSource;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceMatTable.data.length;
    return numSelected === numRows;        
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {      
    if (this.isAllSelected()) {
        this.selection.clear();
        return;
      }

      this.selection.select(...this.dataSourceMatTable.data);  
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Article): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  ngOnDestroy(){
    this.contexte.setType(this.type)
    this.contexte.setPageIndex(this.dataSourceMatTable.paginator!.pageIndex)
    this.contexte.setTableData(this.dataSource_articles)
    this.contexte.setPages(this.paginator? this.paginator.pageSize : 5)
  }
}
