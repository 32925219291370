<div>
    <h1 mat-dialog-title>Historique du dossier</h1>

    <div class="container" mat-dialog-content>
        <table mat-table matTableResponsive [dataSource]="dataSource" class="table-primary">

            <!-- Date Column -->
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef class="premiere-colonne"> Date </th>
                <td mat-cell *matCellDef="let dossier" class="td-premiere-colonne"> {{dossier.date | date: 'dd/MM/yyyy HH:mm:ss'}} </td>
            </ng-container>

            <!-- Etat Column -->
            <ng-container matColumnDef="etat">
                <th mat-header-cell *matHeaderCellDef class="etat-padding"> État </th>
                <td mat-cell *matCellDef="let dossier" class="etat-padding">
                    <button mat-stroked-button class="button-etat button-width" color="primary"
                        [ngStyle]="{ color: getColorByLibelle(dossier.etat) }">
                        {{dossier.etat}}
                    </button>
                </td>
            </ng-container>

            <!-- Description Column -->
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef class="derniere-colonne"> Description </th>
                <td mat-cell *matCellDef="let dossier"> {{dossier.description}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="paginatorPageSizeOptions"showFirstLastButtons></mat-paginator>
    </div>
    <br>
    <div class="div-button-center">
        <button mat-button color="warn" [mat-dialog-close]="true" class="button-width">Fermer</button>
    </div>
</div>