<mat-card appearance="outlined" >
    <mat-card-header>
        <mat-card-title>{{suivis.length}} Dossier<span *ngIf="suivis.length >1">s</span> RDEA Certif</mat-card-title>
    </mat-card-header>

    
    <div class="container" *ngIf="dataSourceMatTable">
        <mat-spinner *ngIf="waiting" style="margin: auto;"></mat-spinner>
        <table mat-table 
                matTableResponsiveLarge 
                matTableExporter 
                [dataSource]    = "dataSourceMatTable" 
                #exporter       = "matTableExporter" 
                class           = "table-primary">


            <!-- Etat Column -->
            <ng-container matColumnDef="statut">
                <th mat-header-cell *matHeaderCellDef style="text-align: left; padding-right: 16px;" class="premiere-colonne colonne-invisible"> Etat </th>
                <td mat-cell 
                    *matCellDef = "let suivi" 
                    style       = "text-align: left;" 
                    [ngClass]   = "suivi.statut===apiSuiviRDEA.CONST_STATUT_ARCHIVE ? 'red' : ''"
                    >
                    {{ apiSuiviRDEA.getLibelleStatut(suivi.statut) }}
                </td>
            </ng-container>

            <!-- Dossier Column -->
            <ng-container matColumnDef="dossier">
                <th mat-header-cell *matHeaderCellDef style="text-align: left; padding-right: 16px;" class="premiere-colonne colonne-invisible"> Dossier </th>
                <td mat-cell *matCellDef="let suivi" style="text-align: left;" class="colonne-invisible">
                    {{ suivi.dossier }}
                </td>
            </ng-container>

            <!-- Immat Column -->
            <ng-container matColumnDef="immat">
                <th mat-header-cell *matHeaderCellDef class="colonne-invisible"> Immatriculation </th>
                <td mat-cell *matCellDef="let suivi"  class="colonne-invisible" appImmat>
                    {{ suivi.immat }}
                </td>
            </ng-container>

            <!-- Date de création -->
            <ng-container matColumnDef="date_creation">
                <th mat-header-cell *matHeaderCellDef style="text-align: left; padding-right: 16px;" class="premiere-colonne colonne-invisible">  Date de création </th>
                <td mat-cell *matCellDef="let suivi" style="text-align: left;" class="colonne-invisible">
                   {{suivi.date_creation| date: 'dd/MM/yyyy'}}
                </td>
            </ng-container>

            <!-- Date d'envoi -->
            <ng-container matColumnDef="date_envoi">
                <th mat-header-cell *matHeaderCellDef style="text-align: left; padding-right: 16px;" class="premiere-colonne colonne-invisible">  Date d'envoi </th>
                <td mat-cell *matCellDef="let suivi" style="text-align: left;" class="colonne-invisible">
                   {{suivi.date_envoi| date: 'dd/MM/yyyy'}}
                </td>
            </ng-container>

           <!-- Entite -->
           <ng-container matColumnDef="entite_nom">
            <th mat-header-cell *matHeaderCellDef class="total" style="padding-right: 8px; padding-left: 8px;"> Entité </th>
            <td mat-cell *matCellDef="let suivi" class="total">
                {{ suivi.nom_entite }} 
            </td>
        </ng-container>

            <!-- Actions -->
            <ng-container matColumnDef="Actions">
                
                <th mat-header-cell *matHeaderCellDef style="padding-left: 16px;"> </th>
                <td mat-cell *matCellDef="let suivi">
                    <div class="div-container">
                        <button 
                            *ngIf="!waiting"
                            mat-icon-button 
                            class           ="button-etat" 
                            style           = "width: 48px; height: 48px;"
                            matTooltip      = "Editer la prestation"
                            (click)         = "editer(suivi)">
                            <mat-icon color="primary">edit</mat-icon>
                        </button>
                        <button 
                            *ngIf="!waiting"
                            mat-icon-button 
                            class           ="button-etat" 
                            style           = "width: 48px; height: 48px;"
                            matTooltip      = "Voir suivi SIV"
                            (click)         = "editerSiv(suivi)">
                        <mat-icon color="primary">list</mat-icon>
                        </button>
                        <mat-slide-toggle 
                            *ngIf="suivi.statut != apiSuiviRDEA.CONST_STATUT_ARCHIVE"
                            [checked]   = "suivi.statut === apiSuiviRDEA.CONST_STATUT_ARCHIVE"
                            (change)    = "archive(suivi)"
                            >Archiver
                        </mat-slide-toggle>
                    </div>
                </td>
            </ng-container>
            
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="div-container-between" style="align-items: center;background-color:white">
            <app-export-table 
                *ngIf="suivis.length>0"
                [fileExportName]="'DOSSIERS_RDEA'" 
                [exporter]="exporter" 
                [nb_ligne]="suivis.length"
                ></app-export-table>

            <mat-paginator [pageSizeOptions]="[10,25,50]"
                showFirstLastButtons 
                aria-label="Nombre de lignes visibles...">
            </mat-paginator>
        </div>
         
         
    </div>
</mat-card>

