import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TypeDemande } from 'src/app/interfaces/support/type-demande';
import { Environnement } from 'src/app/interfaces/utilitaire/environnement';
import { ApiSupportService } from 'src/app/services/api-support.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { ApiUtilitaireService } from 'src/app/services/tools/api-utilitaire.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgIf, NgFor } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-fiche-type-demande',
    templateUrl: './fiche-type-demande.component.html',
    styleUrls: ['./fiche-type-demande.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, NgIf, MatSelectModule, NgFor, MatOptionModule, MatSlideToggleModule, MatButtonModule, MatIconModule, MatProgressSpinnerModule]
})
export class FicheTypeDemandeComponent implements OnInit {

  // Accesseur en lecture du formulaire
  get typ_code()                    { return this.formGroupTypeDemande.get('typ_code'); }
  get typ_libelle()                 { return this.formGroupTypeDemande.get('typ_libelle'); }
  get email_destinataire()          { return this.formGroupTypeDemande.get('email_destinataire'); }
  get environnement() : FormArray   { return <FormArray>this.formGroupTypeDemande.get('environnement') as FormArray; }
  
  // Variables pour gérer le type de demande (chargement, création, modification)
  id          : number = 0;
  type_demande: TypeDemande = <TypeDemande>{};

  // Variables pour gérer l'affichage
  waitingValidation : boolean = false;
  bModeCreation     : boolean = false;
  titre_page        : string  = '';

  // Variables d'interface pour gérer les combos
  environnements: Array<Environnement> = Array<Environnement>();

  // Variables utilisées pour le formulaire
  formGroupTypeDemande: FormGroup = this.fb.group({
    typ_code          : [{value: '', disabled : false}, [Validators.required]],
    typ_libelle       : [{value: '', disabled : false}, [Validators.required]],
    email_destinataire: [{value: '', disabled : false}, [Validators.required]],
    environnement     : [Array<Environnement>(), [Validators.required]],
    actif             : [{value: false, disabled : false}, [Validators.required]],
  });

  constructor(private fb              : FormBuilder
            , private router          : Router
            , private routed          : ActivatedRoute
            , private apiSupport      : ApiSupportService
            , private apiUtilitaire   : ApiUtilitaireService
            , private toast           : PersoSnackbarService) { }

  ngOnInit(): void {
    this.id = this.routed.snapshot.params['typ_id'];

    // Chargement des combos
    this.getEnvironnements();

    if (this.id > 0) {                        
      // Mode édition
      this.bModeCreation  = false;
      this.titre_page     = 'Modifier un type de demande'      
      this.formGroupTypeDemande.controls['typ_code'].disable();
      this.getTypeDemande();
    } else {
      // Mode création
      this.titre_page     = 'Créer un type de demande';
      this.bModeCreation  = true;    
      this.formGroupTypeDemande.controls['typ_code'].enable();
    }
  }

  // Chargement des environnements
  public getEnvironnements(): void {
    this.apiUtilitaire.getEnvironnement(this.apiUtilitaire.CONST_CODE_ENVIRONNEMENT_ALL)
      .subscribe(
        (data: Environnement[]) => {
          this.environnements = data;   
          
          if(this.bModeCreation) {
            this.formGroupTypeDemande.patchValue({
              environnement: this.environnements
            });
          } else {
            let environnement: Array<number> = Array<number>();
        
            if(this.type_demande.environnements != undefined) {
              for(let i = 0; i < this.type_demande.environnements.length; i++) {          
                environnement.push(this.type_demande.environnements[i].id);
              }
  
              this.formGroupTypeDemande.patchValue({
                environnement: environnement
              });
            }            
          }
        },
        err => { 
          '/!\\ error getEnvironnements: ' + console.log(err);
        }
      );
  }

  // Chargement de l'article
  private getTypeDemande(): void {
    this.apiSupport.getTypeDemande(this.id)
    .subscribe(
      (type_demande: TypeDemande) => {
        this.type_demande = type_demande;
        let environnement: Array<number> = Array<number>();
        
        for(let i = 0; i < this.type_demande.environnements.length; i++) {          
          environnement.push(this.type_demande.environnements[i].id);
        }

        this.formGroupTypeDemande.patchValue({
          typ_code          : type_demande.code,
          typ_libelle       : type_demande.libelle,
          email_destinataire: type_demande.email_destinataire,
          environnement     : environnement,
          actif             : type_demande.actif
        });
      },
      (err: any) => { 
        '/!\\ error getTypeDemande: ' + console.log(err);
      });
  }

// Liaison du type de demande et du formulaire
private bindFormulaireTypeDemande(): void {
  this.type_demande = <TypeDemande>{};

  if(this.bModeCreation) {
    this.type_demande.id = 0;
  } else {
    this.type_demande.id = this.id;
  }

  if(!this.bModeCreation) {
    this.type_demande.code = this.formGroupTypeDemande.getRawValue().typ_code;
  } else {
    this.type_demande.code = this.formGroupTypeDemande.controls.typ_code.value;
  }   

  this.type_demande.libelle             = this.formGroupTypeDemande.controls.typ_libelle.value;
  this.type_demande.actif               = this.formGroupTypeDemande.controls.actif.value;
  this.type_demande.email_destinataire  = this.formGroupTypeDemande.controls.email_destinataire.value;
  
  let tmp_environnement: Array<number> = Array<number>();
  tmp_environnement = this.formGroupTypeDemande.controls.environnement.value;

  if(tmp_environnement != undefined) {
    for(let i = this.environnements.length-1; i >= 0; i--) {
      if(tmp_environnement.find(id => this.environnements[i].id === id) == undefined) {
        this.environnements.splice(i, 1);
      }
    }
  
    this.type_demande.environnements = this.environnements;
  }
  
}

// Modification du type de demande
private putTypeDemande(typ_id: number, type_demande: TypeDemande) {
  this.waitingValidation = true;

  this.apiSupport.putTypeDemande(typ_id, type_demande)
    .subscribe(
      (data: any) => { 
        this.toast.showInfo("Type de demande modifié");
        this.router.navigate(['/app/administration-extranet/support']);
        this.waitingValidation = false;
      },
      (err: any) => { 
        '/!\\ error putTypeDemande: ' + console.log(err);
        this.toast.showError(err.error);
        this.waitingValidation = false;
       },
      () => { 
        
        this.waitingValidation = false;
       }
    );
}

// Création du type de demande
private postTypeDemande(type_demande: TypeDemande) {
  this.waitingValidation = true;
  
  this.apiSupport.postTypeDemande(type_demande)
    .subscribe(
      (data: TypeDemande) => { 
        this.type_demande = data;
        this.toast.showInfo("Type de demande créé")
        this.router.navigate(['/app/administration-extranet/support']);
        this.waitingValidation = false;
      },
      (err: any) => { 
        '/!\\ error postTypeDemande: ' + console.log(err); 
        this.toast.showError(err.error);
        this.waitingValidation = false;
      },
      () => { 
        this.waitingValidation = false;
      }
    );
}

// Retour à la liste des types de demande
public onClickAnnuler(): void {
  this.router.navigate(['/app/administration-extranet/support'])
}

// Validation des modifications
public onClickValider(){
  this.bindFormulaireTypeDemande()

  if(this.bModeCreation) {
    this.postTypeDemande(this.type_demande);
  } else {
    this.putTypeDemande(this.id, this.type_demande);
  }    
}

}
