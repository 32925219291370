import { Component } from '@angular/core';
import { MailService } from '../../data-access/mail.service';
import { Mail, MailFilter } from '../../mail-interface';
import { MailFileComponent } from '../mail-file/mail-file.component';
import { MailFilterComponent } from '../mail-filter/mail-filter.component';
import { MailListComponent } from '../mail-list/mail-list.component';
import { NgIf } from '@angular/common';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';

@Component({
  selector: 'app-mail-shell',
  templateUrl: './mail-shell.component.html',
  styleUrls: ['./mail-shell.component.scss'],
  standalone:true,
  imports:[MailFileComponent,MailFilterComponent,MailListComponent,NgIf]
})
export class MailShellComponent {
  filteredMails : Mail[]    = [];
  selectedMail  : Mail|null = null;
  resetDone     : boolean   = false;

  constructor(private mailService: MailService
              ,private apiToast : PersoSnackbarService
  ) {}

  onFilterChange(filter: MailFilter) {
    this.selectedMail = null;
    
    this.mailService.postFilteredMails(filter).subscribe((mails:Mail[]) => {      
      this.filteredMails = mails
      console.log(this.filteredMails);
      
    });
  }

  onSelectMail(mail: Mail) {
    this.selectedMail = mail;
  }

  onResetMails(idMailList:string){
    this.mailService.putRazNbEssais(idMailList).subscribe(
      (data:any)=>{
      if (data.success === true) {
        this.apiToast.showInfo('Une nouvelle tentative de renvoi des mails sélectionnés va être entreprise')
        this.resetDone = true
      }else{
        this.apiToast.showError('Un problème technique empêche le renvoi des mails sélectionnés')
      }
      
    }
    ,(error:any)=>this.apiToast.showError('Un problème technique empêche le renvoi des mails sélectionnés'))
  }
}
