import { Token } from "../authentification/token";

export interface Authentification {
    id                      : number;
    login                   : string;
    mot_de_passe            : string;
    user_agent              : string;
    token_ld                : string;
    token_cd                : string;
    tokenLD                 : Token;
    tokenCD                 : Token;
}

export class Authentification implements Authentification{
    id              : number = 0;
    login           : string = "";
    mot_de_passe    : string = "";
    user_agent      : string = "";
    token_ld        : string = "";
    token_cd        : string = "";
    root            : string = "";
    tokenCD         : Token = {'token':'','expery_date':''}; 
    tokenLD         : Token = {'token':'','expery_date':''};

    constructor(){}
}


