<mat-card *ngIf="mail"  appearance="outlined">
    <mat-card-header>
      <mat-card-title>{{ mail?.ml_sujet }}</mat-card-title>
      <mat-card-subtitle>De: {{ mail?.ml_expediteur }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <p><strong>Destinataire:</strong> {{ mail?.ml_destinataire }}</p>
      <p *ngIf="mail?.ml_cc!=''"><strong>CC:</strong> {{ mail?.ml_cc }}</p>
      <p *ngIf="mail?.ml_cci!=''"><strong>CCI:</strong> {{ mail?.ml_cci }}</p>
      <p><strong>Date d'envoi:</strong> {{ mail?.ml_date_envoi | date:'short' }}</p>
      <p><strong>Contenu:</strong></p>
      <div [innerHTML]="mail?.ml_contenu"></div>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="mail?.ml_erreur!=''">
    <mat-card-header style="height: 50px;">
      <mat-icon class="mat-icon-red"><span class="material-symbols-outlined">
        warning
        </span>
      </mat-icon>
      
    </mat-card-header>
    <mat-card-content>
      {{mail!.ml_erreur}}
    </mat-card-content>
  </mat-card>
  