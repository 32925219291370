import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { HistoEtatCommande } from 'src/app/interfaces/commande/histo-etat-commande';
import { ApiCommandeService } from 'src/app/services/api-commande.service';
import { MatButtonModule } from '@angular/material/button';
import { NgStyle, DatePipe } from '@angular/common';
import { MatTableResponsiveDirective } from '../../../directive/mat-table-responsive/mat-table-responsive.directive';
import { MatTableModule } from '@angular/material/table';

@Component({
    selector: 'app-dialog-histo-etat',
    templateUrl: './dialog-histo-etat.component.html',
    styleUrls: ['./dialog-histo-etat.component.scss'],
    standalone: true,
    imports: [MatDialogModule, MatTableModule, MatTableResponsiveDirective, NgStyle, MatButtonModule, DatePipe]
})
export class DialogHistoEtatComponent implements OnInit {

  id: number = 0;
  historique: HistoEtatCommande[] = [];

  // Tableau 
  displayedColumns: string[] = ['date', 'etat'];
  histoCommande: HistoEtatCommande[];
  refCommande:string= '';
  constructor(  private apiCmd    : ApiCommandeService,
                private dialogRef: MatDialogRef<DialogHistoEtatComponent>
              ,@Inject(MAT_DIALOG_DATA) data : any) 
              { this.histoCommande = data.histoCommande,this.refCommande = data.refCommande }

  ngOnInit(): void {
    
  }
  public getEtatColor(code: string) {
    switch (code) {
      case this.apiCmd.CONST_ETAT_ATTENTE:
        return '#e48213';
        break;
      case this.apiCmd.CONST_ETAT_REFUSEE:
      case this.apiCmd.CONST_ETAT_ANNULEE:
        return '#f44336';
        break;
      default:
        return 'rgb(39,58,130)'
        break;
    }
  }
}
