import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { NgFor, NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { TypeEntite } from 'src/app/interfaces/profil-user/type-entite';
import { MatOptionModule } from '@angular/material/core';
import { ContexteService, TYPE_CABINET } from 'src/app/services/contexte.service';
import { TypeFacturation } from 'src/app/interfaces/profil-user/type-facturation';

@Component({
    selector: 'app-filtre-liste-cabinets',
    templateUrl: './filtre-liste-cabinets.component.html',
    styleUrls: ['./filtre-liste-cabinets.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatExpansionModule, MatIconModule, MatFormFieldModule
            , MatInputModule, NgIf, MatButtonModule, MatProgressSpinnerModule,MatSelectModule, MatOptionModule,NgFor]
})
export class FiltreListeCabinetsComponent implements OnInit {

  @Output() newItemEvent = new EventEmitter<Array<Entite>>();
  @Input()  typesEntite       : TypeEntite[]      = []
  @Input()  typesFacturation  : TypeFacturation[] = []

  // Gestion de l'affichage
  expandPannel    : boolean = true;
  waitingResearch : boolean = false;
    
  // Formulaire de filtrage
  FiltreFormGroup: FormGroup;

    // Accesseur en lecture des formControl
    get habilitation()  { return this.FiltreFormGroup.get('habilitation'); }
    get numeroContact() { return this.FiltreFormGroup.get('numeroContact'); }
    get raison_sociale()  { return this.FiltreFormGroup.get('raison_sociale'); }
    get siren()  { return this.FiltreFormGroup.get('siren'); }
    get ville()  { return this.FiltreFormGroup.get('ville'); }
    get typeEntite()  { return this.FiltreFormGroup.get('typeEntite'); }
    get typeFacturation()  { return this.FiltreFormGroup.get('typeFacturation'); }

  constructor(
      private _fb         : FormBuilder
    , private toast       : PersoSnackbarService
    , private apiEntite   : ApiEntiteService
    , private contexte    : ContexteService
    
  ) {
      // Déclaration du formulaire
      this.FiltreFormGroup = this._fb.group({
        habilitation    : ['',''],
        numeroContact   : ['', ''],
        raison_sociale  : ['', ''],
        siren           : ['', ''],
        ville           : ['', ''],
        typeEntite      : [''],
        typeFacturation : ['']
      })

    this.apiEntite.isDataModify.subscribe( (value: boolean) => {
      if(value) {
        this.apiEntite.isDataModify.next(false);
        this.onSearchingCabinets();
      }
    });
  }

  ngOnInit(): void {
    this.initFilter()
    this.addNewItem(this.contexte.getTableData(TYPE_CABINET))
  }

  initFilter(){
    const filtre = this.contexte.getFiltre(TYPE_CABINET)
    if (this.contexte.getFiltre(TYPE_CABINET)!=null) {
      this.FiltreFormGroup = filtre
    }
  }

  onSearchingCabinets() : void {
    this.waitingResearch = true;
    this.contexte.setFiltre(this.FiltreFormGroup)  

    this.apiEntite.getEntiteByFilter(this.FiltreFormGroup.controls.habilitation.value
                                    , this.FiltreFormGroup.controls.numeroContact.value
                                    , this.FiltreFormGroup.controls.raison_sociale.value
                                    , this.FiltreFormGroup.controls.siren.value
                                    , this.FiltreFormGroup.controls.ville.value
                                    , this.FiltreFormGroup.controls.typeEntite.value
                                    , this.FiltreFormGroup.controls.typeFacturation.value)
    .subscribe(
      (data: Entite[]) => {
        this.addNewItem(data);
        
        this.toast.showInfo('Recherche terminée');
        this.waitingResearch = false;
        this.expandPannel = false;
      },
      (err: any) => {
        console.error('/!\ err: ', err.message);
        this.waitingResearch = false;
      });
  }

  public addNewItem(value: Array<Entite>) {
    this.newItemEvent.emit(value);
  }
}
