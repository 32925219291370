<mat-card appearance="outlined" *ngIf="groupes">
    
    <mat-card-header>
        <mat-card-title>Gestion des droits</mat-card-title>
    </mat-card-header>
    
    <mat-card-content class=".mat-card-content">
        <div class="group">
            <div *ngIf="!edition" class="group">
                <mat-form-field >
                    <mat-label>Groupe</mat-label>
                    <mat-select [(value)]="selectedGroup" (selectionChange)="selectGroup()">
                        <mat-option *ngFor="let groupe of groupes" [value]="groupe">
                            {{groupe.nom}}
                        </mat-option>
                    </mat-select>
                            
                </mat-form-field>
                <div class="buttons">
                    <button mat-mini-fab color = "primary" (click)="editGroup(groupeInit)" matTooltip="Ajouter un groupe"><mat-icon>add</mat-icon></button>
                    <button mat-mini-fab color = "primary" *ngIf="selectedGroup.id>0" (click)="editGroup(selectedGroup)" matTooltip="Modifier le nom de ce groupe"><mat-icon >edit</mat-icon></button>
                    <button mat-mini-fab color = "accent" *ngIf="selectedGroup.id>0" (click)="deleteGroup(selectedGroup)" matTooltip="Supprimer ce groupe"><mat-icon>delete</mat-icon></button>
                </div>
            </div>
        </div>

        <div *ngIf="edition" class="group">
            <mat-form-field appearance="outline">
                <mat-label>Nom du groupe</mat-label>
                <input  
                        matInput
                        required
                        type            = "text" 
                        [(ngModel)]     = "selectedGroup.nom"
                        placeholder     = "Nom du groupe"
                >
                        
            </mat-form-field>
            
            <mat-form-field appearance="outline">
                <mat-label>Environnement</mat-label>
                <mat-select   [(ngModel)]="idEnv">
                    <mat-option *ngFor="let env of environnements" [value]="env.id">{{env.libelle}}</mat-option>
                </mat-select>
            </mat-form-field>
            
            <div class="buttons">
                <button 
                    mat-mini-fab 
                    color       = "primary" 
                    (click)     = "validateGroup()" 
                    matTooltip  = "Valider"
                    [disabled]="idEnv===0 || selectedGroup.nom==''"
                    ><mat-icon *ngIf="!waitingGroupe">done</mat-icon></button>
                <mat-spinner *ngIf="waitingGroupe"></mat-spinner>
                <button mat-mini-fab color = "accent"  (click)="cancel()" matTooltip="Annuler"><mat-icon>close</mat-icon></button>
            </div>
        </div>

        <form [formGroup]="FormDroit">
        <div *ngIf="selectedGroup">
            <fieldset class="cadre" *ngIf="droits">
                <legend class="legend">Droits disponibles </legend>
                    <mat-selection-list
                        formControlName="droitsListe"
                        multiple    = "true">
                            <mat-list-option
                                *ngFor="let droit of droits"    
                                [value]     = "droit.id" 
                                
                            >
                                {{droit.nom}}
                            </mat-list-option>
                    </mat-selection-list>
                    
                </fieldset>
            </div>
            <mat-card-actions align="end">
                <button 
                    mat-raised-button 
                    *ngIf       = "!waiting"
                    color       = "primary" 
                    (click)     = "validateDroits()" 
                    [disabled]  = "selectedGroup.id <= 0" 
                    >
                    Enregistrer
                </button>
                <mat-spinner diameter="24" *ngIf="waiting"></mat-spinner>
            </mat-card-actions>
        </form>
    </mat-card-content>

</mat-card>
