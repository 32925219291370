<mat-tab-group [selectedIndex]="selected.value" animationDuration="0ms" class ="tabretrait">
      <mat-tab>
            <ng-template mat-tab-label>
                  <div class="div-container-left">
                        <mat-icon>account_circle</mat-icon>
                        <span class="mat-tab-label">Mon profil</span>
                  </div>
            </ng-template>

            <app-utilisateur></app-utilisateur>
      </mat-tab>

      <mat-tab>
            <ng-template mat-tab-label>
                  <div class="div-container-left">
                        <mat-icon>apartment</mat-icon>
                        <span class="mat-tab-label">Mes entités</span>
                  </div>
            </ng-template>

            <app-liste-entites></app-liste-entites>

      </mat-tab>
</mat-tab-group>