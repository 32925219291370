import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { AnalyseFilter, AnalyseInitialFilter } from 'src/app/interfaces/adela/analyse';
import { ListeElements } from 'src/app/interfaces/adela/liste-elements';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-filter-prestation',
    templateUrl: './filter-prestation.component.html',
    styleUrls: ['./filter-prestation.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, FormsModule, NgIf, MatSelectModule, MatOptionModule
              , NgFor, MatRadioModule, MatButtonModule, MatIconModule, AsyncPipe]
})
export class FilterPrestationComponent implements OnInit {

  @Input()    fluides$ : Observable<ListeElements[]> = new Observable<Array<ListeElements>>();
  @Input()    organes$ : Observable<ListeElements[]> = new Observable<Array<ListeElements>>();
  @Input()    modeEdit : boolean = true
  @Output()   filterSelected = new EventEmitter<AnalyseFilter>();
  @Output()   createPrestation =new EventEmitter<boolean>();

  filterFluide!      : number;
  filterOrgane!      : number;
  filterCode        : string = '';
  filterDesignation : string = '';
  filterActive      : string = '1';
  filterType        : string = '';
  filterCategorie   : string = '';
  filterLibelle     : string = '';
  filterAffiche     : string = '1';

  

  constructor() {}

  ngOnInit(): void {}
  
  selectFilter(){
    const FiltrePrestation:AnalyseFilter = {...AnalyseInitialFilter
                                              ,"codeLabo"   : this.filterCode? this.filterCode : ''
                                              ,"designation": this.filterDesignation? this.filterDesignation : ''
                                              ,"idFluide"   : this.filterFluide? this.filterFluide : 0
                                              ,"idOrgane"   : this.filterOrgane? this.filterOrgane : 0
                                              , "active"      : this.filterActive? this.filterActive : "1"
                                              , "type"      : this.filterType? this.filterType : ''
                                              , "categorie" : this.filterCategorie? this.filterCategorie : ''
                                              , "affiche"   : this.filterAffiche? this.filterAffiche : "1"
                                              , "libelle"   : this.filterLibelle? this.filterLibelle : ''}
    this.filterSelected.emit(FiltrePrestation)
  }

  create(){
    this.createPrestation.next(true)
  }

  
}
