<mat-card appearance="outlined">
    <!-- {{indexTab}} -->
    <mat-tab-group id="tabActualite">
        <mat-tab *ngFor="let doc of docs; let index = index" label="{{ index + 1 }}. "
            style="padding: 0 6px 3px 6px;">
            <!-- <p style="font-weight: bold; font-size: 18px;">{{ doc.titre }}</p> -->
            <ul style="list-style-type: none; padding: 0px;">
                <li class="title">{{ doc.titre }}</li>
                <li style="overflow: hidden;padding-top: 8px;"><span class="tag">Actualité</span><span class="tag">Doc</span><span class="tag">Juridique</span><span class="tag">Voiture</span></li>
                <!-- <div style="float: left; margin: 6px 12px 0 0; position: relative; width: 128px; background-color: #c3c3c3;">
                    <img src="https://picsum.photos/200" alt="légende de l'image" style="width: 100%;">
                </div> -->
                <li style="margin-top: 6px; text-align: justify; font-size: 12px; max-width:800px;">{{ doc.description }} <a [routerLink]="['/app/actualite']" style="color: #e48213"> ... lire la suite ...</a></li>
            </ul>
        </mat-tab>
    </mat-tab-group>
</mat-card>

