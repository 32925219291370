<mat-card appearance="outlined">
    <mat-card-title>Résultats de l'analyse</mat-card-title>
    
    <div [formGroup]="AnalyseForm"  *ngIf="done">

        <div class="content" *ngIf="resultsSansCatArray.length > 0">
            <div class="titlebox">Résultats sans catégorie</div>
            <div    formArrayName   = "resultsSansCatArray" 
                    class           = "listeValeur"
                    >
                <mat-form-field *ngFor      = "let result of resultsSansCatArray.controls; let i=index" 
                                [formGroupName] = "i"
                                class           = "formFieldValeur textarea"
                                appearance      = "outline"
                                >
                
                    <mat-label >{{result.get('resultLabel')!.value}}</mat-label>
                    <input  matInput
                            type            = "text" 
                            [value]         = "result.get('resultValue')!.value" 
                            oninput         = "this.value = this.value.toUpperCase()" 
                            formControlName = "resultValue"
                                    >       
                    <span matSuffix class="unit">{{result.get('resultUnit')!.value}}</span>
                    <span class="oldValue" *ngIf="!result.get('resultInit')!.value">{{result.get('resultValueInit')!.value}}*</span>
                </mat-form-field>
                
            </div>
        </div>

        <div class="content" *ngIf="resultsPCArray.length > 0">
            <div class="titlebox">Résultats physico-chimiques</div>
            <div    formArrayName   = "resultsPCArray" 
                    class           = "listeValeur"
                    >
                <mat-form-field *ngFor      = "let result of resultsPCArray.controls; let i=index" 
                                [formGroupName] = "i"
                                class           = "formFieldValeur textarea"
                                appearance      = "outline"
                                >
                
                    <mat-label >{{result.get('resultLabel')!.value}}</mat-label>
                    <input  matInput
                            type            = "text" 
                            [value]         = "result.get('resultValue')!.value" 
                            oninput         = "this.value = this.value.toUpperCase()" 
                            formControlName = "resultValue"
                                    >       
                    <span matSuffix class="unit">{{result.get('resultUnit')!.value}}</span>
                    <span class="oldValue" *ngIf="!result.get('resultInit')!.value">{{result.get('resultValueInit')!.value}}*</span>
                </mat-form-field>
                
            </div>
            
        </div>

        <div class="content" *ngIf="resultsPCBArray.length > 0">
            <div class="titlebox">Résultats physico-chimiques bsm</div>
            <div    formArrayName   = "resultsPCBArray" 
                    class           = "listeValeur"
                    >
                <mat-form-field *ngFor      = "let result of resultsPCBArray.controls; let i=index" 
                                [formGroupName] = "i"
                                class           = "formFieldValeur textarea"
                                appearance      = "outline"
                                >
                
                    <mat-label >{{result.get('resultLabel')!.value}}</mat-label>
                    <input  matInput
                            type            = "text" 
                            [value]         = "result.get('resultValue')!.value" 
                            oninput         = "this.value = this.value.toUpperCase()" 
                            formControlName = "resultValue"
                                    >       
                    <span matSuffix class="unit">{{result.get('resultUnit')!.value}}</span>
                    <span class="oldValue" *ngIf="!result.get('resultInit')!.value">{{result.get('resultValueInit')!.value}}*</span>
                </mat-form-field>
                
            </div>
        </div>      

        <div class="content" *ngIf="resultsSpectArray.length > 0">
            <div class="titlebox">Résultats spectrométriques</div>
            <div    formArrayName   = "resultsSpectArray" 
                    class           = "listeValeur"
                    >
                <mat-form-field *ngFor      = "let result of resultsSpectArray.controls; let i=index" 
                                [formGroupName] = "i"
                                class           = "formFieldValeur textarea"
                                appearance      = "outline"
                                >
                
                    <mat-label >{{result.get('resultLabel')!.value}}</mat-label>
                    <input  matInput
                            type            = "text" 
                            [value]         = "result.get('resultValue')!.value" 
                            oninput         = "this.value = this.value.toUpperCase()" 
                            formControlName = "resultValue"
                                    >       
                    <span matSuffix class="unit">{{result.get('resultUnit')!.value}}</span>
                    <span class="oldValue" *ngIf="!result.get('resultInit')!.value">{{result.get('resultValueInit')!.value}}*</span>
                </mat-form-field>
                
            </div>
        </div>
        
        <div class="oldValue" style="margin:10px;" >* Valeur d'origine</div>
        
        <div class="content">
            <div class="titlebox">Interprétation des résultats</div>
            <mat-form-field class="textarea" style="max-width:100%">
                <mat-label>Interprétation</mat-label>
                <textarea
                    matInput
                    formControlName ="interpretation"
                    type            = "text"
                    rows            = "5"
                ></textarea>
            </mat-form-field>
        </div>

        <div class="div-container-right" style="margin-top: 15px;" >
            <button   
                                *ngIf="!waiting && (etatCode? etatCode!='CLOTURE' : true )"
                                mat-flat-button style="text-align: center;" 
                                color       = "primary" 
                                (click)     = "save()"
                                type        = "button"
                                >
                            <mat-icon>save</mat-icon>
                            Enregistrer
            </button>
            <button   
                                *ngIf="!waiting && (etatCode? etatCode!='CLOTURE' : true )"
                                mat-flat-button style="text-align: center;" 
                                color       = "warn" 
                                (click)     = "close()"
                                type        = "button"
                                >
                            <mat-icon>lock</mat-icon>
                            Cloturer
            </button>
            <mat-spinner *ngIf="waiting" diameter="24"></mat-spinner>
        </div>
    </div>
</mat-card>
