<mat-card appearance="outlined">
    <mat-card-title>Filtre sur les modèles</mat-card-title>
    <mat-card-content  class=".mat-card-content">
        <div class="div-container">
            <div class="div-container-between">
                <mat-form-field>
                    <mat-label>Contient</mat-label>
                    <input  matInput
                            type = "text" 
                            id = "textContenu"
                            [(ngModel)]="textContenu"
                            (keyup)="setFilter.click()">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Code</mat-label>
                    <input  matInput
                            type = "text" 
                            id = "code"
                            [(ngModel)]="code"
                            (keyup)="setFilter.click()">
                </mat-form-field>
            </div>
            <div class="div-container-between">
                <div class="div-container-between">
                    <mat-form-field>
                        <mat-label>Catégorie</mat-label>
                        <mat-select (selectionChange) = "getSousCategories($event.value)" [(value)]="idCategorie">
                            <mat-option value="0">
                                Toutes
                            </mat-option>
                            <mat-option *ngFor="let categorie of this.apiModeleFacade.categories$|async" 
                                        [value]="categorie.cat_id"
                                        >
                                {{categorie.cat_nom}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="div-container-right">
                        <button mat-icon-button class="button-etat" color="primary" (click)="onCreeCategorie()"><mat-icon color="primary" matTooltip="Créer une catégorie">note_add</mat-icon></button>
                        <ng-container *ngIf="idCategorie > 0">
                            <button  mat-icon-button class="button-etat" color="primary" (click)="onModifieCategorie()"><mat-icon color="primary" matTooltip="Editer cette catégorie">edit</mat-icon></button>
                            <button *ngIf="sousCategories.length==0" mat-icon-button class="button-etat" color="warn" (click)="onSupprimeCategorie()"><mat-icon color="warn" matTooltip="Supprimer cette catégorie">delete</mat-icon></button>
                        </ng-container>
                    </div>
                </div>
                <div class="div-container-between">
                    <mat-form-field>
                        <mat-label>Sous-catégorie</mat-label>
                        <mat-select (selectionChange) = "getModeles()"
                                    [(value)]="idSousCategorie">
                            <mat-option value="0">
                                Toutes
                            </mat-option>
                            <mat-option *ngFor="let souscategorie of sousCategories$|async" 
                                        [value]="souscategorie.sca_id"
                                    >
                                {{souscategorie.sca_nom}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="div-container-right">
                        <button mat-icon-button class="button-etat" color="primary" (click)="onCreeSousCategorie()"><mat-icon color="primary" matTooltip="Créer une sous-catégorie">note_add</mat-icon></button>
                        <ng-container *ngIf="idSousCategorie > 0">
                            <button mat-icon-button class="button-etat" color="primary" (click)="onModifieSousCategorie()"><mat-icon color="primary" matTooltip="Editer cette sous-catégorie">edit</mat-icon></button>
                            <button mat-icon-button class="button-etat" color="warn" (click)="onSupprimeSousCategorie()"><mat-icon color="warn" matTooltip="Supprimer cette sous-catégorie">delete</mat-icon></button>
                        </ng-container>
                    </div>
                </div>
            </div>
            <button style="display: none;" #setFilter (click)="getModeles()"></button>
        </div>
    </mat-card-content>
</mat-card>

