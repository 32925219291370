<mat-card appearance="outlined" class="mat-card-padding">
<div class="container">
    <div class="div-container-between">
        <mat-card-title>{{adresses? adresses.length : 'Aucune'}} adresse<span *ngIf="adresses? adresses.length >1 : false">s</span></mat-card-title>
        <button mat-icon-button class="button-etat" 
                color="primary" 
                (click)="onCreer()">
                <mat-icon   color="primary" 
                            matTooltip="Créer une adresse">note_add
                </mat-icon>
        </button>
    </div>
    <table mat-table matTableResponsiveLarge [dataSource]="dataSourceMatTable" class="table-primary">
         
        <!-- lignes -->
        <ng-container matColumnDef="lignes">
            <th mat-header-cell *matHeaderCellDef class="premiere-colonne"> Lignes d'adresse </th>
            <td mat-cell *matCellDef="let adresse" class="table-padding"> {{ getLignes(adresse) }} </td>
        </ng-container>

        <!-- CP --> 
        <ng-container matColumnDef="cp">
            <th mat-header-cell *matHeaderCellDef class="table-padding"> Code Postal </th>
            <td mat-cell *matCellDef="let adresse" class="table-padding"> {{ adresse.cp }} </td> 
        </ng-container>            
        
        <!-- Ville -->
        <ng-container matColumnDef="ville">
            <th mat-header-cell *matHeaderCellDef class="table-padding"> Ville </th>
            <td mat-cell *matCellDef="let adresse" class="table-padding"> 
            {{adresse.ville}}
            </td>
        </ng-container>
        
        <!-- Ouvrir -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell 
                *matHeaderCellDef 
                class   = "derniere-colonne" 
                style   = "text-align: right;">
            </th>
            
            <td mat-cell 
                *matCellDef = "let adresse" 
                class       = "colonne-module" 
                style       = "text-align: right;">
                
                <button mat-icon-button 
                        color   = "primary"
                        style   = "width: 48px; height: 48px;"
                        (click) = "onEditer(adresse)">
                    
                        <mat-icon   color="primary" 
                                matTooltip="Editer cette adresse">edit
                    </mat-icon>
                </button>

                <button mat-icon-button 
                        class   = "button-etat" 
                        color   = "primary"
                        style   = "width: 48px; height: 48px;"
                        (click) = "onSupprimer(adresse)"
                        >
                    <mat-icon   color="warn" 
                                matTooltip="Supprimer cette adresse">
                                delete
                    </mat-icon>
                </button>

            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator
        [pageSizeOptions]="[5,10,25]"
        showFirstLastButtons 
        aria-label="Nombre de lignes visibles...">
    </mat-paginator>
</div>
</mat-card>