<div [formGroup]="formAdresse">
    <mat-card appearance="outlined">
        
        <mat-form-field style="max-width: 100%;">
            <mat-label>Type d'adresse</mat-label>
            <mat-select formControlName = "type" required>
                <mat-option *ngFor="let type of typesAdresse" 
                    [value]="type.type"
                    >
                    {{type.libelle}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <app-adresse [adresse]="adresse" controlKey="adresse" [forceResize]="true"></app-adresse>
        
        <mat-card-actions align="end">
            <button mat-raised-button color="primary" (click)="valider()">Enregistrer</button>
            <button mat-raised-button color="primary" (click)="annuler()">Annuler</button>
        </mat-card-actions>

    </mat-card>
</div>