import { NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Service } from 'src/app/interfaces/dossier/service';
import { ChiffreAffaires } from 'src/app/interfaces/facture/chiffre-affaires';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiFactureService } from 'src/app/services/api-facture.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { ApiUtilitaireService } from 'src/app/services/tools/api-utilitaire.service';
import { TableChiffresAffairesComponent } from '../table-chiffres-affaires/table-chiffres-affaires.component';

@Component({
    selector: 'app-mes-chiffres-affaires',
    templateUrl: './mes-chiffres-affaires.component.html',
    styleUrls: ['./mes-chiffres-affaires.component.scss'],
    standalone: true,
    imports: [NgIf, MatToolbarModule, MatButtonModule, MatIconModule, MatCardModule, ReactiveFormsModule, MatExpansionModule, MatFormFieldModule, MatSelectModule, MatOptionModule, NgFor, MatProgressSpinnerModule, TableChiffresAffairesComponent]
})
export class MesChiffresAffairesComponent implements OnInit {

  // constante en corélation avec les constante de l'API
  CONST_CODE_SERVICE_ADELA          = 'ADELA' as const;
  CONST_CODE_SERVICE_ADC            = 'ADC' as const;
  CONST_CODE_SERVICE_APPEL_OFFFRE   = 'AO' as const;
  CONST_CODE_SERVICE_DIAGNOSTIC     = 'DIAG' as const;

  // Gestion de l'affichage
  waitingResearch       : boolean = false;
  expandPannel          : boolean = false;

  // Liste des interfaces pour les combos
  profilUtilisateur   : ProfilUtilisateur = <ProfilUtilisateur>{};
  annee               : Array<number> = [];
  services            : Array<Service> = [];

  // Filtre
  FiltreFormGroup: any;

  // Résultats de la recherche
  chiffre_affaires: Array<ChiffreAffaires> = [];
  
  constructor(private apiFacture: ApiFactureService
            , private apiUtilitaire: ApiUtilitaireService
            , private authUser: ApiAuthentificationService
            , private _fb: FormBuilder
            , private toast: PersoSnackbarService) { }

  ngOnInit(): void {
    this.profilUtilisateur = this.authUser.userConnect;

    // Récupération des paramètres du formulaire de filtrage
    this.chargeListeAnnee();
    this.chargeListeService();

    // Initialisation du formulaire de filtrage
    this.FiltreFormGroup = this._fb.group({
      service : ['', [Validators.required]],
      annee   : ['', [Validators.required]]
    });
  }

  // récupération des la liste des années de facture disponible
  public chargeListeAnnee() {
    // Ajout des années disponible en facturation
    this.annee = this.apiFacture.getAnnees()
    
  }

  public chargeListeService() {
    this.apiUtilitaire.getService(this.apiUtilitaire.CONST_CODE_ENVIRONNEMENT_EXTRANET_FFEA)
    .subscribe(
      (data: Service[] ) => {
        this.services = data;
        this.defaultSelection();
      },
      (err: any) => { 
        console.error('/!\ err: ', err.message); 
      }
    );
  }

  // Parcours des services FFEA et restriction des accès à ces derniers selon les droits utilisateur.
  private defaultSelection(){
    // parcours des services disponible
    for ( let index = this.services.length-1; index >= 0; index-- ) {
      // gestion des acces au service utilisateurs
      switch (this.services[index].code) {
        // Acces service : ANALYSE DE CLÉ
        case this.CONST_CODE_SERVICE_ADC: {
          if ( !this.profilUtilisateur.droits_utilisateur.droit_module_adc && !this.profilUtilisateur.droits_utilisateur.droit_module_admin_adc && !this.profilUtilisateur.droits_utilisateur.est_admin ) { 
            this.services.splice(index ,1);
          } 
          break;
        }
        // Acces service : ADELA
        case this.CONST_CODE_SERVICE_ADELA: {
          if ( !this.profilUtilisateur.droits_utilisateur.droit_module_adela && !this.profilUtilisateur.droits_utilisateur.droit_module_admin_adela && !this.profilUtilisateur.droits_utilisateur.est_admin ) { 
            this.services.splice(index ,1);
            } 
          break;
        }
        // Acces service : OUTILS DIAGNOSTIC
        case this.CONST_CODE_SERVICE_DIAGNOSTIC: {
          if ( !this.profilUtilisateur.droits_utilisateur.droit_module_outil_diagnostic && !this.profilUtilisateur.droits_utilisateur.est_admin ) { 
            this.services.splice(index ,1);
          }
          break;
        }        
        default: {
          break;
        }
      }
    }
  }

  public onRechercheCA() {
    this.waitingResearch = true;
    this.chiffre_affaires = [];
    
    this.apiFacture.getChiffreAffaires(this.FiltreFormGroup.controls.service.value, this.FiltreFormGroup.controls.annee.value)
      .subscribe(
        (data: ChiffreAffaires[]) => {
          this.chiffre_affaires = data;          
          this.toast.showInfo('Recherche terminée');
          this.waitingResearch = false;
        },
        (err: any) => {
          console.error('/!\ err: ', err.message);
          this.waitingResearch = false;
        }
      );
  }
}
