import { NgIf } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TASK } from 'src/app/interfaces/task.interface';
import { NewlineToBrPipe } from 'src/app/pipe/newline-to-br.pipe';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
  standalone:true,
  imports : [NewlineToBrPipe,NgIf,MatProgressSpinnerModule]
})
export class ProgressComponent implements OnChanges {

  @Input() task! : TASK
  progressPercentage: number = 0;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.task) {      
      this.updateProgressPercentage();
    }
  }
  
  updateProgressPercentage() {
    if (this.task && this.task.total > 0) {
      this.progressPercentage = (this.task.counting / this.task.total) * 100;
    } else {
      this.progressPercentage = 0;
    }
  }

  applyChanges(task:TASK){
    switch (task.status) {
          
      case 'in_progress':
        break;
      
        case 'completed':
        break;
      
        case 'failed' :
        break;

      default:
        break;
    }
  }
}
