import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { Fichier } from '../interfaces/fichier';
import { CoordonneesBancaire } from '../interfaces/profil-user/coordonnees-bancaire';
import { EtatCoordonneesBancaire } from '../interfaces/profil-user/coordonnees-bancaire/etat-coordonnees-bancaire';
import { FiltreRechercheCoordonneesBancaire } from '../interfaces/profil-user/coordonnees-bancaire/filtre-recherche-coordonnees-bancaire';
import { Entite } from '../interfaces/profil-user/entite';
import { EntiteAccesConcentrateur } from '../interfaces/profil-user/entite-acces-concentrateur';
import { EntiteTva } from '../interfaces/profil-user/entite-tva';
import { IndiceSuccursale } from '../interfaces/profil-user/indice-succursale';
import { TypeEntite } from '../interfaces/profil-user/type-entite';
import { ResultatActionEnMasse } from '../interfaces/resultat-action-en-masse';
import { UtilisateurEntite } from '../interfaces/utilisateur-entite/relation-utilisateur-entite';
import { ApiAuthentificationService } from './api-authentification.service';
import { ApiRelUtilisateurEntiteService } from './api-rel-utilisateur-entite.service';
import { TypeFacturation } from '../interfaces/profil-user/type-facturation';

@Injectable({
  providedIn: 'root'
})
export class ApiEntiteService {

  entites : Entite[]=[]
  

  public isDataModify: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  urlApi: string = '/api/entite';

  CONST_CODE_TYPE_ENTITE_EXPERT       = 'EXPERT'      as const;
  CONST_CODE_TYPE_ENTITE_PRESTATAIRE  = 'PRESTATAIRE' as const;
  CONST_CODE_TYPE_ENTITE_REPARATEUR   = 'REPARATEUR'  as const;
  CONST_CODE_TYPE_ENTITE_SUCCURSALE   = 'SUCCURSALE'  as const;
  CONST_CODE_TYPE_ENTITE_ADELA        = 'ADELA'       as const;
  CONST_CODE_TYPE_ENTITE_RDEA_CERTIF  = 'RDEA'       as const;
  
  CONST_LIBELLE_TYPE_ENTITE_REPARATEUR = 'Réparateur' as const;

  CONST_CODE_ETAT_COORDONNEES_BANCAIRE_VALIDE: string = 'VALIDE' as const;
  CONST_CODE_ETAT_COORDONNEES_BANCAIRE_REFUSE: string = 'REFUSE' as const;
  CONST_CODE_ETAT_COORDONNEES_BANCAIRE_A_VALIDER: string = 'A_VALIDER' as const;
  
  // permet de spécifier le format d'échange de données entre le front et le back
  jsonContentType = new HttpHeaders().set('Content-Type', 'application/json');
  httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }), responseType: 'text' as 'json' }; 
  
  entitesActivated$ = this.http.get<Entite[]>(this.urlApi, { headers: this.jsonContentType }).pipe(
    map((entites) => entites.filter((entite) => entite.EstActif == (entite.EstInscrit == true)))
    ,shareReplay(1)
  );
  

  constructor(private http: HttpClient,
              private apiRelUtilisateurEntite :ApiRelUtilisateurEntiteService,
              private apiAuthentification : ApiAuthentificationService) { }

  public addEntite(entite:Entite){
    const index = this.entites.findIndex((ent:Entite)=> ent.id === entite.id)
    if (index == -1) {
      this.entites.push(entite)
    }
  }

  public getEntite(idEntite:number){
    const index = this.entites.findIndex((ent:Entite)=> ent.id === idEntite)
    if (index>-1) {
      return this.entites[index]
    }else{
      return null
    }
  }
  public getTVA(): Observable<EntiteTva[]> {
    return this.http.get<EntiteTva[]>('/api/hc/tva');
  }  

  public uploadLogo(n_Id: number, entite: Entite): Observable<Entite> {
    // Ajout d'une option dans l'header de la requête pour convertir le format de retour JSON en text car sinon, il y aura une erreur de parsing JSON
    let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }), responseType: 'text' as 'json' }; 
    return this.http.put<Entite>(this.urlApi+"/"+n_Id+"/logo",entite, httpOptions);
  }

  // *************** Accès Concentrateur ***************
  public getAccesConcentrateur(id_utl_dirigeant: number, id_ent: number): Observable<EntiteAccesConcentrateur> {
    return this.http.get<EntiteAccesConcentrateur>(this.urlApi+"/acces/concentrateur/"+id_utl_dirigeant+"/"+id_ent)
  }
  
  public postAccesConcentrateur(acces_concentrateur : EntiteAccesConcentrateur, id_utl_dirigeant: number, id_ent: number): Observable<EntiteAccesConcentrateur> {    
    return this.http.post<EntiteAccesConcentrateur>(this.urlApi+"/acces/concentrateur/"+id_utl_dirigeant+"/"+id_ent, acces_concentrateur);
  }
  // *************** Fin - Accès Concentrateur ***************
  

  // *************** Coordonnées Bancaire ***************
  public getEtatCoordonneesBancaire(): Observable<EtatCoordonneesBancaire[]> {
    return this.http.get<EtatCoordonneesBancaire[]>(this.urlApi+"/coordonnees-bancaire/etats");
  }

  public postCoordonneesBancaire(filtre_recherche: FiltreRechercheCoordonneesBancaire): Observable<Entite[]> {
    return this.http.post<Entite[]>(this.urlApi+"/coordonnees-bancaire", filtre_recherche);
  }

  public postValiderCoordonneesBancaire(entite: Entite, id_utilisateur: number): Observable<Entite> {
    return this.http.post<Entite>(this.urlApi+'/coordonnees-bancaire/valider/'+id_utilisateur, entite);
  }

  public postRefuserCoordonneesBancaire(entite: Entite, id_utilisateur: number): Observable<Entite> {
    return this.http.post<Entite>(this.urlApi+'/coordonnees-bancaire/refus/'+id_utilisateur, entite);
  }

  // *************** Fin - Coordonnées Bancaire ***************

  // récupération d'une entite à partir d'un id utilisateur
  public getEntiteByIDUtilisateur(n_id : number) : Observable<Entite[]> {

    return this.http.get<Entite[]>(this.urlApi+`?id_utl=`+n_id, { headers: this.jsonContentType })
      .pipe(
        map( (res: Entite[]) => {
            return APIEntiteToEntite(res) || [];
          }, catchError(err => {
            console.log('/!\\ catchError: '+ err);
            return throwError(err); 
          })
        )
      );
  }

  // Récupération d'une entité à partir de son habilitation + indice
  public getEntiteByHabilitationIndice(habilitation : string, indice: string) : Observable<Entite[]> {
    return this.http.get<Entite[]>(this.urlApi+`?habilitation=`+habilitation + '&indice='+indice, { headers: this.jsonContentType });
  }

  // Récupération d'une entité à partir de son id
  public getEntiteById(id : number) : Observable<Entite[]> {
    return this.http.get<Entite[]>(this.urlApi+`?id=`+id, { headers: this.jsonContentType });
  }

  public getEntitesByIdUtilisateur(idUtilisateur : number, idEntite : number = 0, type: string = '', SeulementRelationActive: boolean = false) : Observable<Entite[]> {
    return this.apiRelUtilisateurEntite.getEntitesByIDUtilisateur(idUtilisateur, idEntite, type, SeulementRelationActive)
    .pipe(
      map(
      (data :Entite[]) => {
        return data
      }
      ));
  }

/*   public getSousEntitesByIdUtilisateur(idUtilisateur : number, idEntite : number = 0) : Observable<Entite[]> {
    return this.apiRelUtilisateurEntite.getSousEntitesByIDUtilisateur(idUtilisateur, idEntite)
    .pipe(
      map(
      (data :Entite[]) => {
        return data
      }
      ));
  } */

  // Liste des entites par filtre
  public getEntiteByFilter(habilitation : string, numero_contact : string, raison_sociale : string, siren : string, ville : string, typeEntite:string, typeFacturation:string) : Observable<Entite[]> {
    let filtre : string = '&environnement='+this.apiAuthentification.GetEnvironmentCode();
    
    if (habilitation != '') {
      filtre += '&habilitation='+habilitation
    };

    if (numero_contact != '') {
      filtre += '&numerocontact='+numero_contact
    };

    if (raison_sociale != '') {
      filtre += '&nom='+raison_sociale
    };

    if (siren != '') {
      filtre += '&siren='+siren
    };

    if (ville != '') {
      filtre += '&ville='+ville
    };

    if (typeEntite!='') {
      filtre += '&type='+typeEntite
    }

    if (typeFacturation!='') {
      filtre += '&typefacturation='+typeFacturation
    }

    return this.http.get<Entite[]>(this.urlApi+'?filtre=1' + filtre, { headers: this.jsonContentType });
  }

  // Liste des entites appartenant à un groupe + entite père du groupe
  public getEntiteByIdGroupe(id : number) : Observable<Entite[]> {
    return this.http.get<Entite[]>(this.urlApi+`?idgroupe=`+id, { headers: this.jsonContentType });
  }

  public getEntiteTypeOf(type: string) {
    // liste des entites d'un certain type
    return this.http.get<Entite[]>(this.urlApi+`?type=`+type, { headers: this.jsonContentType });
  }
      
  // Récupération de toutes les entites
  public getAllEntite(): Observable<Entite[]> {
    return this.http.get<Entite[]>(this.urlApi, { headers: this.jsonContentType });
  }

  
  // Récupération des types entites
  public getAllTypeEntite(env_code: string = ''): Observable<TypeEntite[]> {
    let URLoption = ( env_code === '' ? '' : '?env_id='+env_code)
    return this.http.get<TypeEntite[]>('/api/type_entite'+URLoption, { headers: this.jsonContentType });
  }

  // Récupération des types entites hors connexion (pas de vérification du token)
  public getAllTypeEntiteHC(env_code: string = ''): Observable<TypeEntite[]> {
    let URLoption = ( env_code === '' ? '' : '?env_id='+env_code)
    return this.http.get<TypeEntite[]>('/api/hc/type_entite'+URLoption, { headers: this.jsonContentType });
  }

  // Récupération des informations bancaire
  public getCoordonneesBancaire(id: number): Observable<CoordonneesBancaire> {
    return this.http.get<CoordonneesBancaire>(this.urlApi + '/profilbancaire/' + id)
  }

  // modifier les informations bancaires
  public putCoordonneesBancaire(idEntite: number, data: CoordonneesBancaire, idUser: number): Observable<CoordonneesBancaire> {
    return this.http.put<CoordonneesBancaire>(this.urlApi + '/' + idEntite + '/profilbancaire?user='+idUser , data, { headers: this.jsonContentType})
  }

   // ajouter une entité
  public postEntite(entite: Entite, id_dirigeant: number): Observable<Entite> {
    return this.http.post<Entite>(`/api/hc/entite/`+id_dirigeant, entite);
  }

  public postUtilisateurEntite(utilisateurEntite:UtilisateurEntite){
    return this.http.post<UtilisateurEntite>(this.urlApi,utilisateurEntite)
  }

  // modifier une entité
  public putEntite(data: Entite): Observable<Entite> {
    // Typage de la données en JSON. A passer dans le header de la requete REST
    return this.http.put<Entite>(this.urlApi + "/" + data.id, data, { headers: this.jsonContentType});
  }

  public getProchainIndiceSuccursale(id_entite: number): Observable<IndiceSuccursale> {
    return this.http.get<IndiceSuccursale>(this.urlApi + '/' + id_entite + '/succursale/nouvel-indice');
  }

  // Upload es fichiers IBAN, RUM, ...
  public postUploadFile(type: string, id: number, data: Fichier): Observable<Fichier> {
    return this.http.post<Fichier>( this.urlApi + '/' + id + '/' + type, data);
  }

  // Téléchargement de fichier IBAN, RUM, ...
  public getDownFile(type: string, id_Entite: number, id_Coordonnees_bancaire: number) {
    return this.http.get( this.urlApi + '/' + id_Entite + '/' + type+ '/' + id_Coordonnees_bancaire, { responseType: "blob", headers: {'Content-Type': 'application/json', Accept: "application/octet-stream,application/pdf,image/png,image/jpeg"} });
  }

  // Désactivation d'une entités
  public deleteEntite(id: number): Observable<Entite> {
    return this.http.delete<Entite>(this.urlApi+`?id=`+id);
  }

  public patchCompteTiers(id: number, numero: string) {
    return this.http.patch<any>( this.urlApi+ '/tiers', {id: id, compteTiers: numero})
  }

    // Action de masse - MassUpdate
    public postMassUpdateActivate(tab_Entite :Array<Entite>): Observable<ResultatActionEnMasse[]> {
      return this.http.post<ResultatActionEnMasse[]>(this.urlApi + '/action-en-masse/activation', tab_Entite);
    }

    public postMassUpdateDeactivate(tab_Entite :Array<Entite>): Observable<ResultatActionEnMasse[]> {
      return this.http.post<ResultatActionEnMasse[]>(this.urlApi + '/action-en-masse/desactivation', tab_Entite);
    }

    public postMassUpdateResetPasswordConcentrateur(tab_Entite :Array<Entite>): Observable<ResultatActionEnMasse[]> {
      return this.http.post<ResultatActionEnMasse[]>(this.urlApi + '/action-en-masse/reset-password-concentrateur', tab_Entite);
    }

    public patchModePaiement(idEntite:number,idModePaiement:number):Observable<CoordonneesBancaire>{
      const modePaiementEntite = {'idEntite':idEntite,'idModePaiement':idModePaiement}
      return this.http.patch<CoordonneesBancaire>(this.urlApi+'/modepaiement',modePaiementEntite)
    }

    public getTypesFacturation():Observable<TypeFacturation[]>{
      return this.http.get<TypeFacturation[]>(this.urlApi + '/typefacturation');  
    }
  
}

export const APIEntiteToEntite = (res: Entite[]) : Entite[] => {
  return res.map( res => ({
    id                  : res.id,
    idexterne           : res.idexterne,
    id_entite_principale: res.id_entite_principale,
    raison_sociale      : res.raison_sociale,
    logo                : res.logo,
    id_type_entite      : res.id_type_entite,
    typ_ent_nom         : res.typ_ent_nom,
    typ_ent_code         : res.typ_ent_code,
    habilitation        : res.habilitation,
    indice              : res.indice,
    compteTiers         : res.compteTiers,
    siren               : res.siren,
    siret               : res.siret,
    adresse             : res.adresse,
    adresse_cplt        : res.adresse_cplt,
    adresse_ligne3      : res.adresse_ligne3,
    ville               : res.ville,
    code_postal         : res.code_postal,
    telephone           : res.telephone,
    fax                 : res.fax,
    mail_facturation    : res.mail_facturation,
    mail_statistique    : res.mail_statistique,
    mail_contact        : res.mail_contact,
    EstActif            : res.EstActif,
    EstInscrit          : res.EstInscrit,
    tva                 : res.tva,
    coordonnees_bancaire: res.coordonnees_bancaire,
    numero_client       : res.numero_client,
    acces_concentrateur : res.acces_concentrateur,
    historique_coordonnees_bancaire: res.historique_coordonnees_bancaire,
    idAdresse           : res.idAdresse
  }));
}
