import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RapportFilter } from '../../rapport1a-interface';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { EntiteSearchComponent } from 'src/app/components/templates/entite-search/entite-search.component';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { MatIconModule } from '@angular/material/icon';
import { NgFor, NgIf } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-filter-rapport1a',
  templateUrl: './filter-rapport1a.component.html',
  styleUrls: ['./filter-rapport1a.component.scss'],
  standalone:true,
  imports:[ReactiveFormsModule, MatInputModule, MatSelectModule, MatButtonModule, MatNativeDateModule,
    MatFormFieldModule,MatDatepickerModule,MatCardModule,EntiteSearchComponent,MatIconModule,NgIf,NgFor, MatProgressSpinnerModule]
})
export class FilterRapport1aComponent {

  @Input()  entitesUser : Entite[] = []
  @Input()  userAdmin   : boolean = false
  @Input()  waitingResearch : boolean = false

  @Output() filterChange = new EventEmitter<RapportFilter>();

  filterForm        : FormGroup;
  searching         : boolean = false
  entiteSelected    : Entite|null = null

  constructor(private fb: FormBuilder) {
    this.filterForm = this.fb.group({
      date_debut      : ['', Validators.required],
      date_fin        : ['', Validators.required],
      entite          : ['', Validators.required]
    });
  }

  ngOnInit(){
    this.initDates()
  }

  initDates() {
    const today = new Date();
    const lastYear = new Date(today);
    lastYear.setFullYear(today.getFullYear() - 1);
    this.filterForm.patchValue({
      date_debut  : lastYear.toISOString().substring(0, 10),
      date_fin    : today.toISOString().substring(0, 10)  
    })
    this.filterForm.updateValueAndValidity();
  }

  setEntite(entite:Entite|null){
    this.searching = false
    if (entite) {
      this.entiteSelected = entite;
      this.filterForm.patchValue({
        entite  : this.entiteSelected.habilitation
      })
    }else{
      this.entiteSelected = null;
      this.filterForm.patchValue({
        entite  : ''
      })
    }
    this.filterForm.updateValueAndValidity();
  }

  searchingEntite(){
    this.searching = true
  }

  cancelEntite(){
    this.entiteSelected = null
    this.filterForm.patchValue({
      entite  : ''
    })
    this.filterForm.updateValueAndValidity();
  }
  
  applyFilter() {
    let filter        : RapportFilter = <RapportFilter>{};
    filter.habilitation = [];

    if (this.userAdmin===false) {
      if (this.filterForm.controls.entite.value == "Toutes") {
          // on prend toutes les habilitations
          this.entitesUser.forEach((ent:Entite) => {
            filter.habilitation.push(ent.habilitation);
          }
        )
      } else {
        filter.habilitation.push(this.filterForm.controls.entite.value);
      }
    } else {
      if (this.entiteSelected) {
        filter.habilitation.push(this.entiteSelected.habilitation);
      }
    }

    filter.date_debut = this.filterForm.controls.date_debut.value;
    filter.date_fin = this.filterForm.controls.date_fin.value;

    console.log(filter);

    this.filterChange.emit(filter);
  }
}
