import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Adresse } from 'src/app/interfaces/adresse/adresse';

@Injectable({
  providedIn: 'root'
})
export class AdresseService {
url:string = '/api/adresse'
  constructor(private http:HttpClient) { }

  postAdresse(adresse:Adresse){
    return this.http.post<Adresse>( this.url , adresse );
  }

  putAdresse(adresse:Adresse){
    return this.http.put<Adresse>( this.url , adresse );
  }

  getFormatedAdresse(adresse:Adresse){
    return (adresse.ligne1 + ' ' + adresse.ligne2 + ' ' + adresse.cp + ' ' + adresse.ville).trim()
  }

  deleteAdresse(id:number){
    return this.http.delete<any>( this.url  +'/'+id);
  }
}
