import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ChiffreAffaires } from 'src/app/interfaces/facture/chiffre-affaires';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-table-chiffres-affaires',
    templateUrl: './table-chiffres-affaires.component.html',
    styleUrls: ['./table-chiffres-affaires.component.scss'],
    standalone: true,
    imports: [NgIf, MatTableModule]
})
export class TableChiffresAffairesComponent implements OnInit {

  // Données à afficher
  @Input() ELEMENT_DATA: Array<ChiffreAffaires> = [];

  // Affichage des données dans la table + gestion du sélecteur  
  dataSource  = new MatTableDataSource<ChiffreAffaires>();

  // Tableau des suivis
  displayedColumns: string[] = ['annee', 'mois', 'montant_ht_facture', 'montant_ht_avoir'];

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if(this.ELEMENT_DATA != undefined) {
      this.dataSource.data = this.ELEMENT_DATA;
    }    
  }

}
