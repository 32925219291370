import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HistoriqueVehicule } from '../interfaces/vehicule/historique-vehicule';

@Injectable({
  providedIn: 'root'
})
export class HitoriqueVehiculeService {

  urlApi: string = '/api';

  // permet de spécifier le format d'échange de données entre le front et le back
  jsonContentType = new HttpHeaders().set('Content-Type', 'application/json');
  httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }), responseType: 'text' as 'json' }; 
  // Parametre de l'url
  paramTypeRecherche: string = 'type_recherche';
  paramImmatriculation: string = 'immatriculation';
  paramVehiculeIdentifiantNumber: string = 'vin';

  constructor(
    private http: HttpClient
  ) { }

  getHistoriqueImmatriculation(immatriculation: string): Observable<HistoriqueVehicule>{
    return this.http.get<HistoriqueVehicule>(this.urlApi+'/historique-vehicule?'+this.paramTypeRecherche+'=1&'+this.paramImmatriculation+'='+immatriculation);
  }

  getHistoriqueVIN(vin: string): Observable<HistoriqueVehicule>{
    return this.http.get<HistoriqueVehicule>(this.urlApi+'/historique-vehicule?'+this.paramTypeRecherche+'=2&'+this.paramVehiculeIdentifiantNumber+'='+vin);
  }

  getHistoriqueVehiculeDocument(data: any): Observable<any> {
    let options = { headers: { "Content-Type": "application/json", Accept: "application/pdf" } };
    return this.http.post(this.urlApi+'/historique-vehicule', data, { responseType: "blob", headers: {'Content-Type': 'application/json', Accept: "application/pdf"} });
  } 

  sendHistoriqueVehiculeDocument(data: any): Observable<any> {
    return this.http.post(this.urlApi+'/historique-vehicule', data, { responseType: "blob", headers: {'Content-Type': 'application/json', Accept: "application/pdf"} });
  } 

  getInfosImmatriculation(immatriculation: string): Observable<HistoriqueVehicule>{
    return this.http.get<HistoriqueVehicule>(this.urlApi+'/historique-vehicule/infos?'+this.paramTypeRecherche+'=1&'+this.paramImmatriculation+'='+immatriculation);
  }
}



