<div  *ngIf="task && task.status!='completed'">
    <div class="center-text">
        VEUILLEZ PATIENTER
    </div>
    
    <div class="center-text" style="margin-top: 20px;">
        {{task.subtask}}
    </div>
    
    <div class="center-text" style="margin-top: 10px;">
        {{task.progress}}
    </div>
</div>

<div class="spin-container"><mat-spinner  *ngIf="!task || task.status !='completed'" diameter="24"></mat-spinner></div>

<!-- <div class="progress-bar-container">
    <div class="progress-bar" [style.width.%]="progressPercentage"></div>
</div> -->

<div *ngIf="task && task.status==='failed'" style="color: red; margin-top: 20px;">
    <div [innerHTML]="task.result | newlineToBr"></div>
</div>

<div *ngIf="task && task.status==='completed'" style="color: green;margin-top: 20px;">
    <div [innerHTML]="task.result | newlineToBr"></div>
</div>
