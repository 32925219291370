import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';
import { ConfigReparateur } from 'src/app/interfaces/services-ffea/reparateur/config-reparateur';
import { ServiceExterne } from 'src/app/interfaces/services-ffea/service-externe';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { ApiReparateurService } from 'src/app/services/services-ffea/api-reparateur.service';
import { ApiUtilitaireService } from 'src/app/services/tools/api-utilitaire.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgFor, NgIf, UpperCasePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-entite-service-externe',
    templateUrl: './entite-service-externe.component.html',
    styleUrls: ['./entite-service-externe.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatIconModule, ReactiveFormsModule, NgFor, MatFormFieldModule, MatInputModule, NgIf, MatButtonModule, MatProgressSpinnerModule, UpperCasePipe]
})
export class EntiteServiceExterneComponent implements OnInit {

  waiting_save        : boolean = false;
  @Input() isDirigeant: boolean = false;

  ent_id              : number = 0;
  services$           : ServiceExterne[] = [];
        
  listForm : FormGroup  = this._fb.group({
    name              : new FormControl(''),
    serviceExterneRow : new FormArray([
      new FormControl(null)
    ]) 
  });
  configForm: FormGroup = this._fb.group({
    id                    : [0],
    idEntite              : [0],
    idServiceExterne      : [0],
    codeServiceExterne    : [''],
    libelleServiceExterne : [''],
    codeReparateur        : ['']
  })

  get serviceExterneRow()        : FormArray { return <FormArray>this.listForm.get('serviceExterneRow') as FormArray; }

  constructor(
    private apiReparateur : ApiReparateurService
    , private _fb : FormBuilder
    , private routed : ActivatedRoute
    , private toast : PersoSnackbarService
    , private apiTools : ApiUtilitaireService
  ) { }

  ngOnInit(): void {
    this.ent_id = this.routed.snapshot.params['ent_id']
    this.chargeListeServiceExterne()
  }

  private addRows(element: any) {
    return this._fb.group({
      id                    : element.id,                     
      idEntite              : element.idEntite,               
      idServiceExterne      : element.idServiceExterne,       
      codeServiceExterne    : element.codeServiceExterne,     
      libelleServiceExterne : element.libelleServiceExterne,  
      codeReparateur        : element.codeReparateur         
    })
  }

  private chargeListeServiceExterne() {

    this.apiTools.getServiceExterne(this.apiTools.CONST_CODE_ENVIRONNEMENT_SERVICES_FFEA, this.apiTools.CONST_CODE_SERVICE_REPARATEUR)
    .pipe(
      tap((dataServiceExterne: ServiceExterne[]) => {
        this.services$ = dataServiceExterne;

        this.listForm.controls.serviceExterneRow;
        this.serviceExterneRow.controls = [];

        // Initialisation et chargement de la liste des services externes pour les réparateurs
        this.services$.forEach(serviceExterne => {
          let ConfigReparateur : FormGroup = this._fb.group({
            id                    : 0,
            idEntite              : 0,
            idServiceExterne      : 0,
            codeServiceExterne    : '',
            libelleServiceExterne : '',
            codeReparateur        : ''
          });
          ConfigReparateur.patchValue({
            idEntite              : this.ent_id,
            idServiceExterne      : serviceExterne.id,
            codeServiceExterne    : serviceExterne.code,
            libelleServiceExterne : serviceExterne.libelle,
          })
          this.serviceExterneRow.push(ConfigReparateur)
        });
      })
      , switchMap(() => this.apiReparateur.getConfigReparateurEntite(this.ent_id))
      , tap((dataConfig: ConfigReparateur[]) => {
          dataConfig.forEach(config => {
            let index: number = 0;
            index = this.serviceExterneRow.value.findIndex(
              (service: any) => {
                return service.codeServiceExterne === config.codeServiceExterne
              })
            console.info('index: '+index)
            this.serviceExterneRow.controls[index].patchValue({
              id                    : config.id,
              idEntite              : config.idEntite,
              codeReparateur        : config.codeReparateur
            })
          });
        })
      ).subscribe(
        () => {}
        ,(err) => {
          console.log(err.message);
        }
      )
    // ) 

  }

  // 
  public saveConfiguration() {
    
    let configurationReparateurs: ConfigReparateur[] = []
    this.serviceExterneRow.value.forEach((config: any) => {
      this.waiting_save = true;
      let configReparateur: ConfigReparateur = <ConfigReparateur>{}
      configReparateur.id               = config.id
      configReparateur.idEntite         = this.ent_id
      configReparateur.idServiceExterne = config.idServiceExterne
      configReparateur.codeReparateur   = config.codeReparateur
      configurationReparateurs.push(configReparateur)

    });
    
    this.apiReparateur.postConfigReparateurEntite(configurationReparateurs)
    .subscribe(
      (data: any) => {
        this.waiting_save = false;
        this.toast.validate(data.message)
      },
      (err) => {
        this.toast.showError(err.message); 
        this.waiting_save = false;
      },
      () => {this.waiting_save = false}
    )
  }
}
