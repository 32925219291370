import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appThousandSeparator]',
  standalone: true
})
export class ThousandSeparatorDirective {

  constructor(private el: ElementRef) { }

  @HostListener('blur') onBlur() {
    let value = this.el.nativeElement.value
    
    if (value) {
      value = value.replace(/[^0-9]*/g, '')
      const reverseValue=value.split('').reverse().join('')
      let formatedValue :string=''
      for (let i = 0; i < reverseValue.length; i++) {
        
        formatedValue+=reverseValue[i]
        if ((i+1) % 3===0) {
          formatedValue+=' '
        }
        
      }
      
        this.el.nativeElement.value = formatedValue.split('').reverse().join('').trim();
   }
  }

}