<h1 mat-dialog-title>{{ dataSource.titre }}</h1>
<mat-divider></mat-divider>
<div mat-dialog-content style="max-width: 400px;">
    <div class="div-container" >
            <form [formGroup]="formReglement">
                <div style="text-align: center;">
                    <p class="label-primary titre">{{ titre }} </p>
                    <p class="label-primary titre">{{ facture$.type_document }} n° <span class="label-accent">{{ facture$.numero }}</span></p>
                    <p class="label-primary titre">{{ facture$.Entite.raison_sociale }}</p>
                    
                    <ng-container *ngIf="isPaiement; then Paiement else CreditNote"></ng-container>
    
                    <!-- SAISIE D'UN PAIEMENT -->
                    <ng-template #Paiement>
                        <table style="padding-top: 16px;margin: auto;">
                            <tr>
                                <td style="width: 180px;text-align: left;" >Total HT</td>
                                <td class="label-primary" style="width: 120px; text-align: right;">{{ facture$.totalHT | number:'1.2-2':'fr' }} €</td>
                            </tr>
                            <tr>
                                <td style="width: 180px;text-align: left;">Frais de Port</td>
                                <td class="label-primary" style="width: 120px; text-align: right;">{{ facture$.portHT | number:'1.2-2':'fr' }} €</td>
                            </tr>
                            <tr>
                                <td style="width: 180px;text-align: left;">TVA ({{ facture$.tauxTVA }} %)</td>
                                <td class="label-primary" style="width: 120px; text-align: right;">{{ facture$.totalTVA | number:'1.2-2':'fr' }} €</td>
                            </tr>
                            <tr  class="label-primary" style="font-size: 18px;">
                                <td style="width: 180px;text-align: left;">Total TTC</td>
                                <td style="width: 120px; text-align: right;">{{ facture$.totalTTC | number:'1.2-2':'fr' }} €</td>
                            </tr>
                            <tr class="label-accent" style="font-size: 18px;">
                                <td style="width: 180px;text-align: left;">Reste due</td>
                                <td style="width: 120px; text-align: right;">{{ sommeDue | number:'1.2-2':'fr' }} €</td>
                            </tr>
                        </table>
                    </ng-template>
                


                <!-- SAISIE D'UN AVOIR -->
                <ng-template #CreditNote>
                    <table style="padding-top: 16px;">
                        <tr>
                            <td style="width: 180px;">Total HT</td>
                            <td class="label-primary" style="width: 120px; text-align: right;">{{ (isPaiement ? 1 : -1) * facture$.totalHT | number:'1.2-2':'fr' }} €</td>
                        </tr>
                        <tr>
                            <td style="width: 180px;">Frais de Port</td>
                            <td class="label-primary" style="width: 120px; text-align: right;">{{ (isPaiement ? 1 : -1) * facture$.portHT | number:'1.2-2':'fr' }} €</td>
                        </tr>
                        <tr>
                            <td style="width: 180px;">TVA ({{ facture$.tauxTVA }} %)</td>
                            <td class="label-primary" style="width: 120px; text-align: right;">{{ (isPaiement ? 1 : -1) * facture$.totalTVA | number:'1.2-2':'fr' }} €</td>
                        </tr>
                        <tr style="border: 1px solid #FF4081;">
                            <td style="width: 180px;">Total TTC</td>
                            <td class="label-accent" style="width: 120px; text-align: right; font-size: 18px;">{{ (isPaiement ? 1 : -1) * facture$.totalTTC | number:'1.2-2':'fr' }} €</td>
                        </tr>
                    </table>
                </ng-template>

                    <mat-checkbox *ngIf="dataSource.mode != 'paiement'" [checked]="withPaiement" (change)="setCredit()">
                        Saisir un remboursement
                    </mat-checkbox>

                <div *ngIf="withPaiement || isPaiement">
                    <div class="content">
                        <div class="titlebox">Modalités de paiement</div>
                    
                    <!-- montant -->
                    <mat-form-field style="width: 120px;">
                        <mat-label>Montant : </mat-label>
                        <input 
                            matInput 
                            type            = "text" 
                            id              = "montant" 
                            formControlName = "montant" 
                            (disabled)      = "true"
                            placeholder     = "Montant qui sera remboursé"
                            [ngStyle]       = "{ 'color': getBackgroundColorByStatus() }"
                            style           = "text-align: right;"
                            >
                            <span matSuffix class="mat-Suffix">€</span>
                        <mat-error *ngIf="formReglement.montant?.invalid && (formReglement.montant?.dirty || formReglement.montant?.touched)">
                            {{ formReglement.montant.errors?.erreur }}</mat-error>
                    </mat-form-field>
    
                    <!-- date de paiement  -->
                    <mat-form-field style="width: 150px;">
                        <mat-label>Date de règlement: </mat-label>
                        <input matInput type="date" id="dateReglement" formControlName="dateReglement" 
                        placeholder="Date du paiement"
                        [ngStyle]="{ 'color': getBackgroundColorByStatus() }"
                        style="text-align: right;" value="" required>
                        <mat-error *ngIf="formReglement.dateReglement?.invalid && (formReglement.dateReglement?.dirty || formReglement.dateReglement?.touched)">
                            {{ formReglement.dateReglement.errors?.erreur }}</mat-error>
                    </mat-form-field>
                    
                    <!-- mode de paiement  -->
                    <mat-form-field>
                        <mat-label>Type de règlement: </mat-label>
                        <mat-select name="ModePaiement" id="ModePaiement" formControlName="ModePaiement" 
                        (change)="onSelectTypeOfPaiement($event)"
                        [ngStyle]="{ 'color': getBackgroundColorByStatus() }"
                        style="text-align: right;" required>
                            <mat-option *ngFor="let modePaiement of modePaiement$" [value]="modePaiement" style=" text-align: right;">
                                {{ modePaiement.libelle }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!-- référence bancaire (n°cheque, id de virement) -->
                    <mat-form-field >
                        <mat-label>Numéro de chèque / virement: </mat-label>
                        <input matInput type="text" id="libelle" formControlName="libelle" [ngStyle]="{ 'color': getBackgroundColorByStatus() }" style="text-align: right;" required>
                        <mat-error *ngIf="formReglement.libelle?.invalid && (formReglement.libelle?.dirty || formReglement.libelle?.touched)">
                            {{ formReglement.libelle.errors?.erreur }}</mat-error>
                    </mat-form-field>


                    <!-- commentaire -->
                    <mat-form-field class="textarea">
                        <mat-label>Commentaire: </mat-label>
                        <textarea matInput type="textarea" id="commentaire" formControlName="commentaire" placeholder="Commentaire de l'annulation" [ngStyle]="{ 'color': getBackgroundColorByStatus() }" style="height: 80px;">
                        Annulation de la facture {{ facture$.numero }}
                        </textarea>
                        <mat-error *ngIf="formReglement.commentaire?.invalid && (formReglement.commentaire?.dirty || formReglement.commentaire?.touched)">
                            {{ formReglement.commentaire.errors?.erreur }}</mat-error>
                    </mat-form-field>
                </div>
                </div>
    

                </div>
            </form>
    </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
    <ng-container *ngIf="isPaiement; then saisiePaiement else saisieRemboursement"></ng-container>

    <ng-template #saisiePaiement>
        <div class="div-center">
            <button mat-raised-button color="basic" [mat-dialog-close]="0">Annuler</button>
            <button mat-raised-button color="primary" 
            [disabled]="!formReglement.valid"
            (click)="( dataSource.mode === 'paiement' ? onAddPaiement() : onAddCredit() )">Valider</button>
        </div>
    </ng-template>
    
    <ng-template #saisieRemboursement>
        <div class="div-center">
            <button mat-raised-button color="basic" [mat-dialog-close]="false">Annuler</button>
            <button mat-raised-button color="accent" 
            [disabled]="!formReglement.valid"
            (click)="onSaveCreditNote()">Valider</button>
        </div>
    </ng-template>
</div>