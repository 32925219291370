import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ResultatActionEnMasse } from 'src/app/interfaces/resultat-action-en-masse';
import { ApiSupportService } from 'src/app/services/api-support.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatTableResponsiveLargeDirective } from '../../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';
import { MatCardModule } from '@angular/material/card';

export interface DialogData {
  type                    : string;
  dataSource_type_demande : any;
}
@Component({
    selector: 'app-action-de-masse-type-demande',
    templateUrl: './action-de-masse-type-demande.component.html',
    styleUrls: ['./action-de-masse-type-demande.component.scss'],
    standalone: true,
    imports: [MatDialogModule, MatTableModule, MatTableResponsiveLargeDirective, MatPaginatorModule
            , NgIf, MatButtonModule, MatIconModule, MatProgressSpinnerModule,MatCardModule]
})
export class ActionDeMasseTypeDemandeComponent implements OnInit {
  CONST_CODE_TYPE_ACTION_SUPPRESSION    : string = 'delete'     as const;
  CONST_CODE_TYPE_ACTION_ACTIVATION     : string = 'activate'   as const;
  CONST_CODE_TYPE_ACTION_DESACTIVATION  : string = 'deactivate' as const;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;
  
  dataSourceMatTable  : MatTableDataSource<any> = new MatTableDataSource<any>();

  titre_page          : string = '';
  sous_titre_page     : string = '';

  type_action_de_masse: string = '';

  // Tableau des types demande
  displayedColumns    : Array<string> = [ 'typ_libelle', 'typ_resultat'];

  // Gestion de l'affichage
  waitingApi          : boolean = false;
  action_confirmee    : boolean = false;
  
  constructor(public dialogRef  : MatDialogRef<ActionDeMasseTypeDemandeComponent>
            , private apiSupport: ApiSupportService
            , private toast     : PersoSnackbarService
            , @Inject(MAT_DIALOG_DATA) public data  : DialogData) { }

  ngOnInit(): void {
    this.type_action_de_masse = this.data.type;      

    if(this.data.dataSource_type_demande != undefined) {
      switch (this.data.type) {
        case this.CONST_CODE_TYPE_ACTION_SUPPRESSION:
          if (this.data.dataSource_type_demande.length == 1) {
            this.titre_page       = 'Suppression d\'un type de demande';
            this.sous_titre_page  = 'Le type de demande suivant va être supprimé';
          } else {
            this.titre_page       = 'Suppresion de ' + this.data.dataSource_type_demande.length +' types de demande';
            this.sous_titre_page  = 'Les types de demande suivants vont être supprimés';
          }
          break;
  
        case this.CONST_CODE_TYPE_ACTION_ACTIVATION:
          if (this.data.dataSource_type_demande.length == 1) {
            this.titre_page       = 'Activation d\'un type de demande';
            this.sous_titre_page  = 'Le type de demande suivant va être activé';
          } else {
            this.titre_page       = 'Activation de ' + this.data.dataSource_type_demande.length +' types de demande';
            this.sous_titre_page  = 'Les types de demande suivants vont être activés';
          }
          break;
  
        case this.CONST_CODE_TYPE_ACTION_DESACTIVATION:
          if (this.data.dataSource_type_demande.length == 1) {
            this.titre_page     = 'Désactivation d\'un type de demande';
            this.sous_titre_page = 'Le type de demande suivant va être désactivé';
          } else {
            this.titre_page       = 'Désactivation de ' + this.data.dataSource_type_demande.length +' types de demande';
            this.sous_titre_page  = 'Les types de demande suivants vont être désactivés';
          }
          break;
  
        default:
          this.dialogRef.close();
          break;
      }
      this.dataSourceMatTable.data = this.data.dataSource_type_demande;
    }
  }

  ngAfterViewInit() {
    this.dataSourceMatTable.paginator = this.paginator;
    this.dataSourceMatTable.sort      = this.sort;
  }

  ngOnChanges() {
    // obligatoire
    if(this.data.dataSource_type_demande != undefined) {
      this.dataSourceMatTable.data = this.data.dataSource_type_demande;
    }     
  }

  public onClickRetour(): void{
    this.dialogRef.close();
  }

  public onSuppressionMasse(): void {
    this.waitingApi = true;

    if(this.dataSourceMatTable.data.length > 0) {
      this.apiSupport.postSuppressionEnMasseTypeDemande(this.dataSourceMatTable.data)
      .subscribe(
        (resultat: ResultatActionEnMasse[]) => {
          for(let i = 0; i < resultat.length; i++) {
            this.dataSourceMatTable.data.find(type_demande => {
              if(type_demande.id == resultat[i].id ) {
                type_demande.resultat = resultat[i].resultat;
              }
            });
          }
          this.toast.showInfo("Type(s) de demande supprimé(s)");
          this.waitingApi       = false;
          this.action_confirmee = true;
        },
        (err: any) => {
          this.toast.showError(err.error);
          this.waitingApi = false;
        }
      );
    } else {
      this.toast.showInfo("Aucun type de demande sélectionné à supprimer");
      this.waitingApi = false;
    }    
  }
  
  public onActivationMasse(): void {
    this.waitingApi = true;

    if(this.dataSourceMatTable.data.length > 0) {
      this.apiSupport.postActivationEnMasseTypeDemande(this.dataSourceMatTable.data)
      .subscribe(
        (resultat: ResultatActionEnMasse[]) => {
          for(let i = 0; i < resultat.length; i++) {
            this.dataSourceMatTable.data.find(type_demande => {
              if(type_demande.id == resultat[i].id ) {
                type_demande.resultat = resultat[i].resultat;
              }
            });
          }
          this.toast.showInfo("Type(s) de demande activé(s)");
          this.waitingApi       = false;
          this.action_confirmee = true;
        },
        (err: any) => {
          this.toast.showError(err.error);
          this.waitingApi = false;
        }
      );
    } else {
      this.toast.showInfo("Aucun type de demande sélectionné à activer");
      this.waitingApi = false;
    }
  }

  public onDesactivationMasse(): void {
    this.waitingApi = true;

    if(this.dataSourceMatTable.data.length > 0) {
      this.apiSupport.postDesactivationEnMasseTypeDemande(this.dataSourceMatTable.data)
      .subscribe(
        (resultat: ResultatActionEnMasse[]) => {
          for(let i = 0; i < resultat.length; i++) {
            this.dataSourceMatTable.data.find(type_demande => {
              if(type_demande.id == resultat[i].id ) {
                type_demande.resultat = resultat[i].resultat;
              }
            });
          }
          this.toast.showInfo("Type(s) de demande désactivé(s)");
          this.waitingApi       = false;
          this.action_confirmee = true;
        },
        (err: any) => {
          this.toast.showError(err.error);
          this.waitingApi = false;
        }
      );
    } else {
      this.toast.showInfo("Aucun type de demande sélectionné à désactiver");
      this.waitingApi = false;
    }
  }

}
