import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FicheListeTypeDemandeComponent } from '../fiche-liste-type-demande/fiche-liste-type-demande.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';

@Component({
    selector: 'app-support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.scss'],
    standalone: true,
    imports: [MatTabsModule, MatIconModule, FicheListeTypeDemandeComponent]
})
export class SupportComponent implements OnInit {

  selected = new FormControl(0);

  constructor(private routed: ActivatedRoute) { }

  ngOnInit(): void {
    this.routed.fragment
    .subscribe(tabsToSelect => {
      if ( tabsToSelect != undefined ) {
        this.selected.setValue(+tabsToSelect)
      }
    });
  }

}
