import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { CarteAdresseComponent } from 'src/app/components/templates/cards/carte-adresse/carte-adresse.component';
import { CarteEntiteComponent } from 'src/app/components/templates/cards/carte-entite/carte-entite.component';
import { Adresse } from 'src/app/interfaces/adresse/adresse';
import { EtatCoordonneesBancaire } from 'src/app/interfaces/profil-user/coordonnees-bancaire/etat-coordonnees-bancaire';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { IframeDialogComponent } from '../iframe-dialog/iframe-dialog.component';
@Component({
    selector: 'app-fiche-informations-bancaires',
    templateUrl: './fiche-informations-bancaires.component.html',
    styleUrls: ['./fiche-informations-bancaires.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, NgIf, MatButtonModule
            , MatIconModule, MatDividerModule, MatProgressSpinnerModule,MatTooltipModule,MatGridListModule
            , CarteAdresseComponent, CarteEntiteComponent]
})
export class FicheInformationsBancairesComponent implements OnInit {

  // Gestion de l'affichage
  waitingValidation     : boolean = false;
  expandPannel          : boolean = true;
  bEtatAValider         : boolean = false;
  titre_page            : string = '';
  adresseEntite         : Adresse = <Adresse>{};

  // Variables pour gérer les coordonnées bancaire (chargement, validation, refus)
  id                    : number = 0;
  entite                : Entite = <Entite>{};
  etats$                : Array<EtatCoordonneesBancaire>      = Array<EtatCoordonneesBancaire>();
  
  // Formulaire des coordonnées bancaires
  CoordonneesBancaireFormGroup       : FormGroup;

  get bnc_iban()              { return this.CoordonneesBancaireFormGroup.get('bnc_iban'); }
  get bnc_bic()               { return this.CoordonneesBancaireFormGroup.get('bnc_bic'); }
  get bnc_rum()               { return this.CoordonneesBancaireFormGroup.get('bnc_rum'); }
  get bnc_nom_fichier_iban()  { return this.CoordonneesBancaireFormGroup.get('bnc_nom_fichier_iban'); }
  get bnc_file_iban()         { return this.CoordonneesBancaireFormGroup.get('bnc_file_iban'); }
  get bnc_nom_fichier_rum()   { return this.CoordonneesBancaireFormGroup.get('bnc_nom_fichier_rum'); }
  get bnc_file_rum()          { return this.CoordonneesBancaireFormGroup.get('bnc_file_rum'); }
  get bnc_date_mandat()       { return this.CoordonneesBancaireFormGroup.get('bnc_date_mandat'); }
  get bnc_commentaire()       { return this.CoordonneesBancaireFormGroup.get('bnc_commentaire'); }

  constructor(private fb          : FormBuilder
            , private routed      : ActivatedRoute
            , private router      : Router
            , private apiEntite   : ApiEntiteService
            , private dialog      : MatDialog
            , private apiAuth     : ApiAuthentificationService
            , private toast       : PersoSnackbarService) {
    // Initialisation du formulaire des coordonnées bancaire
    this.CoordonneesBancaireFormGroup = this.fb.group({
      bnc_iban            : ['', [Validators.required]],
      bnc_bic             : ['', [Validators.required]],
      bnc_rum             : [''],
      bnc_nom_fichier_iban: ['', [Validators.required]],
      bnc_file_iban       : [''],
      bnc_nom_fichier_rum : [''],
      bnc_file_rum        : [''],
      bnc_date_mandat     : [''],
      bnc_commentaire     : ['']
    });
  }

  ngOnInit(): void {
    this.id = this.routed.snapshot.params['id_entite'];

    this.getEntites();
    this.getEtatCoordonneesBancaire();
  }

  public getEntites(): void {
    this.apiEntite.getEntiteById(this.id)
      .subscribe(
        (data: Entite[])  => { 
        this.entite = data[0];
        this.adresseEntite.id       = this.entite.idAdresse;
        this.adresseEntite.ligne1   = this.entite.adresse;
        this.adresseEntite.ligne2   = this.entite.adresse_cplt;
        this.adresseEntite.ligne3   = this.entite.adresse_ligne3;
        this.adresseEntite.cp       = this.entite.code_postal;
        this.adresseEntite.ville    = this.entite.ville;
        this.adresseEntite.contact  = this.entite.numero_client
        this.adresseEntite.nom      = this.entite.raison_sociale

        this.CoordonneesBancaireFormGroup.patchValue({
          bnc_iban            : this.entite.coordonnees_bancaire.iban,
          bnc_bic             : this.entite.coordonnees_bancaire.bic,
          bnc_rum             : this.entite.coordonnees_bancaire.rum,
          bnc_nom_fichier_iban: this.entite.coordonnees_bancaire.nom_fichier_iban,
          bnc_file_iban       : this.entite.coordonnees_bancaire.pdf_iban,
          bnc_nom_fichier_rum : this.entite.coordonnees_bancaire.nom_fichier_rum,
          bnc_file_rum        : this.entite.coordonnees_bancaire.pdf_rum,
          bnc_date_mandat     : this.entite.coordonnees_bancaire.date_mandat,
          bnc_commentaire     : this.entite.coordonnees_bancaire.commentaire
        });

        switch (this.entite.coordonnees_bancaire.etat.code) {
          case this.apiEntite.CONST_CODE_ETAT_COORDONNEES_BANCAIRE_A_VALIDER:
            this.CoordonneesBancaireFormGroup.controls['bnc_iban'].enable();
            this.CoordonneesBancaireFormGroup.controls['bnc_bic'].enable();
            this.CoordonneesBancaireFormGroup.controls['bnc_rum'].enable();
            this.CoordonneesBancaireFormGroup.controls['bnc_nom_fichier_iban'].enable();
            this.CoordonneesBancaireFormGroup.controls['bnc_file_iban'].enable();
            this.CoordonneesBancaireFormGroup.controls['bnc_nom_fichier_rum'].enable();
            this.CoordonneesBancaireFormGroup.controls['bnc_file_rum'].enable();
            this.CoordonneesBancaireFormGroup.controls['bnc_date_mandat'].enable();
            this.CoordonneesBancaireFormGroup.controls['bnc_commentaire'].enable();
            this.titre_page = 'Les coordonnées bancaires suivantes sont à valider ou refuser';
            this.bEtatAValider = true;
            break;
          case this.apiEntite.CONST_CODE_ETAT_COORDONNEES_BANCAIRE_VALIDE:
            this.CoordonneesBancaireFormGroup.controls['bnc_iban'].disable();
            this.CoordonneesBancaireFormGroup.controls['bnc_bic'].disable();
            this.CoordonneesBancaireFormGroup.controls['bnc_rum'].disable();
            this.CoordonneesBancaireFormGroup.controls['bnc_nom_fichier_iban'].disable();
            this.CoordonneesBancaireFormGroup.controls['bnc_file_iban'].disable();
            this.CoordonneesBancaireFormGroup.controls['bnc_nom_fichier_rum'].disable();
            this.CoordonneesBancaireFormGroup.controls['bnc_file_rum'].disable();
            this.CoordonneesBancaireFormGroup.controls['bnc_date_mandat'].disable();
            this.CoordonneesBancaireFormGroup.controls['bnc_commentaire'].disable();
            this.titre_page = 'Les coordonnées bancaires suivantes ont été validé';
            this.bEtatAValider = false;
            break;
          case this.apiEntite.CONST_CODE_ETAT_COORDONNEES_BANCAIRE_REFUSE:
            this.CoordonneesBancaireFormGroup.controls['bnc_iban'].disable();
            this.CoordonneesBancaireFormGroup.controls['bnc_bic'].disable();
            this.CoordonneesBancaireFormGroup.controls['bnc_rum'].disable();
            this.CoordonneesBancaireFormGroup.controls['bnc_nom_fichier_iban'].disable();
            this.CoordonneesBancaireFormGroup.controls['bnc_file_iban'].disable();
            this.CoordonneesBancaireFormGroup.controls['bnc_nom_fichier_rum'].disable();
            this.CoordonneesBancaireFormGroup.controls['bnc_file_rum'].disable();
            this.CoordonneesBancaireFormGroup.controls['bnc_date_mandat'].disable();
            this.CoordonneesBancaireFormGroup.controls['bnc_commentaire'].disable();
            this.titre_page = 'Les coordonnées bancaires suivantes ont été refusé';
            this.bEtatAValider = false;
            break;          
          default:
            break;
        }
      }
    );  
  }

  public getEtatCoordonneesBancaire(): void {
    this.apiEntite.getEtatCoordonneesBancaire()
      .subscribe(
        (data : Array<EtatCoordonneesBancaire>) => {
          this.etats$ = data;
        }
      );
  }

  public onClickValider() {
    this.waitingValidation = true;
    this.entite.coordonnees_bancaire.commentaire = this.CoordonneesBancaireFormGroup.controls.bnc_commentaire.value;

    this.apiEntite.postValiderCoordonneesBancaire(this.entite, this.apiAuth.userConnect.id)
      .subscribe(
        (data: Entite) => {
          this.toast.showInfo('Validation des coordonnées bancaires terminée. Un email a été envoyé à l\'entité');          
          this.waitingValidation = false;
          this.router.navigate(['/app/administration-extranet/comptabilite']);
          this.waitingValidation = false;
        },
        (err: any) => {
          this.toast.showError(err.error);
          this.waitingValidation = false;
        }
      );

  }

  public onClickRefuser() {
    this.waitingValidation = true;
    this.entite.coordonnees_bancaire.commentaire = this.CoordonneesBancaireFormGroup.controls.bnc_commentaire.value;

    this.apiEntite.postRefuserCoordonneesBancaire(this.entite, this.apiAuth.userConnect.id)
      .subscribe(
        (data: Entite) => {
          this.toast.showInfo('Refus des coordonnées bancaires terminé. Un email a été envoyé à l\'entité');          
          this.waitingValidation = false;
          this.router.navigate(['/app/administration-extranet/comptabilite']);
          this.waitingValidation = false;
        },
        (err: any) => {
          this.toast.showError(err.error);
          this.waitingValidation = false;
        }
      );

  }

  public onClickApercuPDF(type_pdf: string) {
    if(type_pdf === 'iban') {
      this.dialog.open(IframeDialogComponent, {data: { type_pdf: 'iban', id: this.id, entite: this.entite}, panelClass: 'custom-dialog-container-pdf'});
    } else {
      this.dialog.open(IframeDialogComponent, {data: { type_pdf: 'rum', id: this.id, entite: this.entite}, panelClass: 'custom-dialog-container-pdf'});
    }
  }

  // Retour à la liste des coordonnées bancaires
  public onClickAnnuler(): void {
    this.router.navigate(['/app/administration-extranet/comptabilite']);
  }
}
