<div [hidden]="modeEdit === true">
    <mat-card appearance="outlined" >
        <mat-card-header>
            <mat-card-title></mat-card-title>
        </mat-card-header>

        
        <div class="container" *ngIf="emailsDisplayed$">
            <table mat-table 
            
                    matTableResponsiveLarge 
                    matTableExporter 
                    [dataSource]    = "ds" 
                    class           = "table-primary">

                
                <!-- Sujet -->
                <ng-container matColumnDef="note">
                    <th mat-header-cell *matHeaderCellDef style="text-align: left; padding-right: 16px;" class="premiere-colonne colonne-invisible"> Descriptif </th>
                    <td mat-cell *matCellDef="let email" style="text-align: left;" class="colonne-invisible">
                        {{email.note}}
                    </td>
                </ng-container>

                <!-- Actions -->
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef style="padding-left: 16px" > </th>
                    <td mat-cell *matCellDef="let email" class="th-right">
                       
                        <button mat-icon-button class="button-etat" 
                                style       = "width: 48px; height: 48px;"
                                matTooltip  = "Editer le mail"
                                (click)     = "editer(email)">
                                <mat-icon color="primary">edit</mat-icon>
                        </button>

                    </td>
                </ng-container>

                

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <div class="div-container-between" style="align-items: center;background-color:white">
                <mat-paginator [pageSizeOptions]="[10,25,50]"
                    showFirstLastButtons 
                    aria-label="Nombre de lignes visibles...">
                </mat-paginator>
            </div>
            
            
        </div>
    </mat-card>

</div>
