
<mat-card appearance="outlined" class="mat-card-no-shadow" style="padding: 0px;text-align: center;">
    <mat-expansion-panel [(expanded)]="expandPannel" style="background-color: #fff">
        
        <mat-expansion-panel-header style="background-color: #fff">
            <mat-panel-title>
                <mat-icon color="primary">file</mat-icon>
                <span style="width: 220px;" *ngIf="dataSource != undefined"><span class="span-primary">{{ dataSource.length }}</span>UTILISATEUR<span *ngIf="dataSource.length > 1">S</span></span>
                <span style="width: 220px;" *ngIf="dataSource == undefined"><span class="span-primary">0</span>UTILISATEUR</span>        
            </mat-panel-title>
        </mat-expansion-panel-header>

            <div class="container">
                <table mat-table matTableResponsiveLarge [dataSource]="dataSourceMatTable" class="table-primary">
                
                   <!-- Prénom de l'utilisateur --> 
                    <ng-container matColumnDef="utl_prenom">
                        <th mat-header-cell *matHeaderCellDef> Prénom </th>
                        <td mat-cell *matCellDef="let ProfilUtilisateur" > {{ ProfilUtilisateur.prenom }} </td> 
                    </ng-container>
                    
                    <!-- Nom de l'utilisateur -->
                    <ng-container matColumnDef="utl_nom">
                        <th mat-header-cell *matHeaderCellDef> Nom </th>
                        <td mat-cell *matCellDef="let ProfilUtilisateur" > {{ ProfilUtilisateur.nom }} </td>
                    </ng-container>
            
                    <!-- Role de l'utilisateur -->
                    <ng-container matColumnDef="drt_code">
                        <th mat-header-cell *matHeaderCellDef> Groupe d'utilisateurs</th>
                        <td mat-cell *matCellDef="let ProfilUtilisateur" > {{ ProfilUtilisateur.droits_utilisateur.libelle }} </td>
                    </ng-container>
                    
                    <!-- droit sur le module réparateur -->
                    <ng-container matColumnDef="drt_acces_module_reparateur">
                        <th mat-header-cell *matHeaderCellDef class="colonne-module">Accès Services réparateur</th>
                        <td mat-cell *matCellDef="let ProfilUtilisateur" class="colonne-module">
                            <mat-icon *ngIf="ProfilUtilisateur.droits_utilisateur.droit_module_reparateur" color="primary">done</mat-icon>
                            <mat-icon *ngIf="!ProfilUtilisateur.droits_utilisateur.droit_module_reparateur" color="warn">close</mat-icon>
                        </td>
                    </ng-container>

                    <!-- Acces à Anea Partage -->
                    <ng-container matColumnDef="drt_acces_anea_partage">
                        <th mat-header-cell *matHeaderCellDef class="colonne-module">Accès Anea Partage</th>
                        <td mat-cell *matCellDef="let ProfilUtilisateur" class="colonne-module">
                            <mat-icon *ngIf="ProfilUtilisateur.access_anea_partage" color="primary">done</mat-icon>
                            <mat-icon *ngIf="!ProfilUtilisateur.access_anea_partage" color="warn">close</mat-icon>
                        </td>
                    </ng-container>
            
                    <!-- Modifier -->
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef class="derniere-colonne" style="text-align: center;">Modifier</th>
                        <td mat-cell *matCellDef="let ProfilUtilisateur" class="colonne-module">
                            <button mat-icon-button class="button-etat" color="primary"
                                style="width: 48px; height: 48px;"
                                (click)="onModifier(ProfilUtilisateur.id)">
                                <mat-icon color="primary">edit</mat-icon>
                            </button>
                        </td>
                    </ng-container>
            
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator
                    [pageSizeOptions]="[5,10,25]"
                    showFirstLastButtons 
                    aria-label="Nombre de lignes visibles...">
                </mat-paginator>
            </div>
            
        </mat-expansion-panel>
    </mat-card>

