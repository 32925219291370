import { Injectable } from "@angular/core";
import { FormGroup, ValidatorFn } from "@angular/forms";

@Injectable({ providedIn: 'root' })
export class siretFromSiren {

    public siretMustBeginLikeSiren() {
      return (formGroup: FormGroup) => {
        
        const siren = formGroup.controls['siren'];
        const siret = formGroup.controls['siret']
  
        if (!siren || !siret) {
          return null;
        }

        if (siret.value.substring(0,9)==siren.value) {
            return null;
        }else{
            return { siretDoesntMatchSiren: true };
        }
      };
    }

  }