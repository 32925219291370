<mat-card appearance="outlined">
    <form [formGroup]="filterForm">
        

            <mat-form-field>
                <mat-label>Habilitation</mat-label>
                <input matInput type="text" placeholder="Habilitation"  formControlName="habilitation" oninput="this.value = this.value.toUpperCase()">
                
            </mat-form-field> 

            <mat-form-field>
                <mat-label>Numéro Contact</mat-label>
                <input matInput type="text" placeholder="Numéro Contact" formControlName="contact">
                
            </mat-form-field> 
           
            <mat-form-field>
                <mat-label>Raison sociale</mat-label>
                <input matInput type="text" placeholder="Raison sociale"  formControlName="raison_sociale">
                
            </mat-form-field>

            <mat-form-field>
                <mat-label>SIREN</mat-label>
                <input matInput type="text" placeholder="SIREN"  formControlName="siren">
                
            </mat-form-field>
            
            <mat-form-field>
                <mat-label>Statut</mat-label>
                <mat-select formControlName="statut">
                    <mat-option [value]="">Toutes</mat-option>
                    <mat-option *ngFor="let statut of statuts" [value]= statut.code>{{statut.nom}}</mat-option>
                </mat-select>
                
            </mat-form-field>

        </form>
        <mat-card-actions align="end" *ngIf="{result : waiting$|async} as waiting">
             <button mat-flat-button 
                            *ngIf       = "!waiting.result "
                            color       = "primary" 
                            [disabled]  = "waiting.result || !filterForm.valid" 
                            (click)     = "onSearchSuspension()">
                        <mat-icon>search</mat-icon>Rechercher                                    
            </button>
                   
            <mat-spinner diameter="24" *ngIf="waiting.result"></mat-spinner>
        </mat-card-actions>
</mat-card>
