import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Analyse, AnalyseFilter, AnalyseInitiale } from 'src/app/interfaces/adela/analyse';
import { ListeElements } from 'src/app/interfaces/adela/liste-elements';
import { ListeTypeAffaire } from 'src/app/interfaces/adela/liste-type-affaire';
import { TypeElement } from 'src/app/interfaces/adela/resultat-analyse';
import { DataAccessService } from '../services/data-access.service';
import { ListPrestationComponent } from '../list-prestation/list-prestation.component';
import { EditPrestationComponent } from '../edit-prestation/edit-prestation.component';
import { NgIf } from '@angular/common';
import { FilterPrestationComponent } from '../filter-prestation/filter-prestation.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-shell-prestation',
    templateUrl: './shell-prestation.component.html',
    styleUrls: ['./shell-prestation.component.scss'],
    standalone: true,
    imports: [FilterPrestationComponent, NgIf, EditPrestationComponent, ListPrestationComponent]
})
export class ShellPrestationComponent implements OnInit {

  fluides$              : Observable<ListeElements[]>     = this.prestationService.fluides$
  organes$              : Observable<ListeElements[]>     = this.prestationService.organes$
  typeAnalyses$         : Observable<ListeTypeAffaire[]>  = this.prestationService.typeAnalyses$
  typeElements$         : Observable<TypeElement[]>       = this.prestationService.typeElements$
  
  private analyseFilterSubject  = new BehaviorSubject<AnalyseFilter>({})
  filterAction$                 =  this.analyseFilterSubject.asObservable()
  
  prestationsDisplayed$ : Observable<Analyse[]>           = combineLatest([
    this.prestationService.prestationsAll$
    , this.filterAction$
  ]).pipe(map(([prestations,filter])=>
    prestations.filter(
      (prestation:any) => 
                  (filter.idFluide        && filter.idFluide! > 0   ? prestation.idFluide         === filter.idFluide : true)
                  && (filter.idOrgane     && filter.idOrgane! > 0   ? prestation.idOrgane         === filter.idOrgane : true)
                  && (filter.designation  && filter.designation!='' ? prestation.designation?.toUpperCase().includes(filter.designation!) : true)
                  && (filter.libelle      && filter.libelle!=''     ? prestation.libelle?.toUpperCase().includes(filter.libelle!) : true)
                  && ( filter.codeLabo    && filter.codeLabo!=''    ? prestation.codeLaboratoire?.toUpperCase().includes(filter.codeLabo!) : true)
                  && (filter.active === "0"  ? true : filter.active === "1"? prestation.actif === true : prestation.actif === false )
                  && (filter.categorie!=''   ? prestation.categorie?.toUpperCase().includes(filter.categorie) : true)
                  && (filter.affiche === "0" ? true : filter.affiche === "1"? prestation.affiche === true : prestation.affiche === false )
    )
  )) 

  modeEdit          : boolean = false;
  prestationEdited? : Analyse
  currentElement    : string = ''

  constructor(private prestationService : DataAccessService
              , private router          : Router
              , private activatedRoute  : ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.url.subscribe(url => {
      
      const elements    = this.router.url.split('/');
      this.currentElement = elements[elements.length - 1];
      
      switch (this.currentElement) {
        
        case 'prestations':
          this.modeEdit           = false
          break;
        
        case 'edit':
          this.modeEdit         = true
          break;

        default:
          break;
      }
    });
  }
  
  updateFilter(event:AnalyseFilter){
    this.analyseFilterSubject.next(event)
  }

  editPrestation(prestationSelected:any){
    
    if (prestationSelected.canceled===true) {
      this.deletePrestation(prestationSelected)
    }else{
      this.prestationEdited = prestationSelected
      this.modeEdit = true
      this.router.navigate(['app/administration-extranet/prestations/edit']);
    }
  }

  createPrestation(){
    this.prestationEdited = AnalyseInitiale
    this.modeEdit = true
    this.router.navigate(['app/administration-extranet/prestations/edit']);
  }

  deletePrestation(prestationToDelete:Analyse){
    this.prestationService.deletePrestation(prestationToDelete)
  }

  savePrestation(prestationToSave :Analyse | undefined){
    this.modeEdit = false;
    this.router.navigate(['app/administration-extranet/prestations']);
    this.prestationService.savePrestation(prestationToSave)

  }
}
