import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appAlphaNum]',
  standalone: true
})
export class AlphaNumDirective {

  constructor(private el: ElementRef) { }

  @HostListener('keyup') onKeyUp() {
    let value = this.el.nativeElement.value
    if (value) {
      value = value.replace(/[^a-zA-Z0-9]*/g, '')
        this.el.nativeElement.value = value;
   }
  }

}