
<div class = "content" style="margin-bottom: 10px;">
    <div class="titlebox">Liste des balises disponibles</div>
    <div class="div-container" >
        <button 
            mat-stroked-button 
            *ngFor  = "let balise of balises"  
            color   = "primary" 
            (click) = "onClick(balise)"
            >{{balise.nom}}</button>
    </div>
</div>
