import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

@Injectable({ providedIn: 'root' })
export class CheckValidator {
    
    verificationFormatMotDePasse(): ValidatorFn {
        return (control:AbstractControl):ValidationErrors|null =>{
            let motdepasse: string = control.value;
            let regex: any = /^(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])(?=\D*\d)(?=[^!“#$%&'()*+,./:;<=>?@\[\]^_`{|}~-]*[!“#$%&'()*+,./:;<=>?@[\]^_`{|}~-]).{8,}$/
            if(!regex.test(motdepasse)) {
              return { erreur: 'Format incorrect'}      
            }
        
            return null;
        }
      }


  }