import { Component, OnInit } from '@angular/core';
import { FiltreRecherchePrelevement } from 'src/app/interfaces/facture/filtre-recherche-prelevement';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { OutPutRechercheCoordonneesBancaire, FiltreInformationsBancairesComponent } from '../filtre-informations-bancaires/filtre-informations-bancaires.component';
import { TableInformationsBancairesComponent } from '../table-informations-bancaires/table-informations-bancaires.component';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-liste-informations-bancaires',
    templateUrl: './liste-informations-bancaires.component.html',
    styleUrls: ['./liste-informations-bancaires.component.scss'],
    standalone: true,
    imports: [FiltreInformationsBancairesComponent, MatCardModule, TableInformationsBancairesComponent]
})
export class ListeInformationsBancairesComponent implements OnInit {

  entites: Array<Entite> = Array<Entite>();

  constructor() { }

  ngOnInit(): void {
  }

    // Transmets les résultats de la recherche au component des résultats
    public onChildResearchCoordonneesBancaire(event: OutPutRechercheCoordonneesBancaire): void {
      this.entites = event.entites;
    }
}
