<div>
    <h1 mat-dialog-title>Historique des états de la commande {{refCommande}}</h1>

    <div class="container" mat-dialog-content>
        <table mat-table matTableResponsive [dataSource]="histoCommande" class="table-primary">

            <!-- Date Column -->
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef class="premiere-colonne"> Date </th>
                <td mat-cell *matCellDef="let histo" class="td-premiere-colonne"> {{histo.date | date: 'dd/MM/yyyy HH:mm:ss'}} </td>
            </ng-container>            

            <!-- Etat Column -->
            <ng-container matColumnDef="etat">
                <th mat-header-cell *matHeaderCellDef class="etat-padding"> État </th>
                <td mat-cell *matCellDef="let histo" class="etat-padding"
                [ngStyle]="{ color: getEtatColor(histo.Etat.code) }">{{ histo.Etat.libelle }}
                    
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <br>
    <div class="div-button-center" mat-dialog-actions>
        <button mat-button color="warn" [mat-dialog-close]="true" class="button-width">Fermer</button>
    </div>
</div>