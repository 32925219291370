import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiAdelaService } from 'src/app/services/api-adela.service';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { ApiUtilisateurService } from 'src/app/services/api-utilisateur.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { ApiUtilitaireService } from 'src/app/services/tools/api-utilitaire.service';

import { NgClass, NgFor, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TitleCaseDirective } from 'src/app/directive/title-case.directive';
import { Analyse } from 'src/app/interfaces/adela/analyse';
import { Compagnie } from 'src/app/interfaces/adela/compagnie';
import { Etat } from 'src/app/interfaces/adela/etat';
import { Etiquette } from 'src/app/interfaces/adela/etiquette';
import { FormulaireAdela } from 'src/app/interfaces/adela/formulaire-adela';
import { ListeElements } from 'src/app/interfaces/adela/liste-elements';
import { ListeParametrage } from 'src/app/interfaces/adela/liste-parametrage';
import { ListeTypeAffaire } from 'src/app/interfaces/adela/liste-type-affaire';
import { Adresse, AdresseInit } from 'src/app/interfaces/adresse/adresse';
import { Fichier, UploadFile } from 'src/app/interfaces/fichier';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { HistoriqueVehicule } from 'src/app/interfaces/vehicule/historique-vehicule';
import { HitoriqueVehiculeService } from 'src/app/services/hitorique-vehicule.service';
import { CheckValidatorService } from 'src/app/services/tools/check-validator.service';
import { ImmatDirective } from '../../../directive/immat.directive';
import { MatTableResponsiveDirective } from '../../../directive/mat-table-responsive/mat-table-responsive.directive';
import { AdresseComponent } from '../../templates/adresse/adresse.component';
import { UploadFilesComponent, UploadParameter, initialUploadParameter } from '../../templates/upload-files/upload-files.component';
import { ResultatAnalyseEditComponent } from '../resultat-analyse-edit/resultat-analyse-edit.component';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import { MatDividerModule } from '@angular/material/divider';
import { MailValidator } from 'src/app/validators/mail.validator';
import { AnalyseAdelaSelectionComponent } from '../analyse-adela-selection/analyse-adela-selection.component';
import { MatDialog } from '@angular/material/dialog';
import {  MatTooltipModule } from '@angular/material/tooltip';
import * as saveAs from 'file-saver';
import { from, iif, of } from 'rxjs';
import { switchMap, concatMap, finalize, catchError } from 'rxjs/operators';
@Component({
    selector: 'app-analyse-adela',
    templateUrl: './analyse-adela.component.html',
    styleUrls: ['./analyse-adela.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatIconModule, MatStepperModule, NgClass, ReactiveFormsModule, MatFormFieldModule
            , MatInputModule, NgIf, MatSelectModule, NgFor, MatOptionModule, AdresseComponent, MatCheckboxModule
            , MatButtonModule, ImmatDirective, UploadFilesComponent, MatRadioModule, MatTableModule
            , MatTableResponsiveDirective, MatProgressSpinnerModule, ResultatAnalyseEditComponent
            , TitleCaseDirective, MatDividerModule, MatToolbarModule,MatTooltipModule],
    providers: [
                {
                  provide: STEPPER_GLOBAL_OPTIONS,
                  useValue: {showError: true},
                }]
})

export class AnalyseAdelaComponent implements OnInit {

  @Input()                idDossierComp : number = 0;
  @Output()             close = new EventEmitter<boolean>()
  
  // Constantes utile pour la gestion d'affichage des formulaires
  const_form_avarie_sur_vehicule    = '1' as const; 
  const_form_avarie_sans_vehicule   = '2' as const; 
  const_form_bilan_moteur_preventif = '3' as const; 
  const_form_vehicule_incendie      = '4' as const; 
  const_form_huile_neuve            = '5' as const; 

  const_autre_compagnie             = 999999 as const;
  const_combustible                 = '2' as const;
  const_huile_en_service            = '4' as const;

  const_organe_defaut               = 'AUCU' as const;
  const_errorMessageStepper         = 'Information(s) manquante(s) ou incorrecte(s)' as const;

  info_bilan_moteur                 = '';  

  waiting_Save             : boolean = false;       
  waitingModified          : boolean = false;
  waitingRefused           : boolean = false;
  waitingAccepted          : boolean = false;
  waitingInfosVehicule     : boolean = false

  //selectSousEntite         : boolean = false;
  allEntite                : boolean = false; // Définit si on doit afficher les entités actives et inactives

  limitNumberOfFile        : number = 5;
  limitFileSize            : number = 5;

  // Booleens pour la gestion d'affichage des formulaires
  form_avarie_sur_vehicule          = false; 
  form_avarie_sans_vehicule         = false; 
  form_bilan_moteur_preventif       = false; 
  form_vehicule_incendie            = false; 
  form_huile_neuve                  = false; 
  form_confirmation_analyse         = false;
  form_lecture_seule_analyse_retenue= false;
  form_resultat_analyse             = false;
  select_combustible                = false;
  select_huile_en_service           = false;  
  // adresseParticulier :Adresse  = AdresseInit

  // Formulaire Etape 1
  get reference()               { return this.Etape1FormGroup.get('reference'); }
  get numEtiquette()            { return this.Etape1FormGroup.get('numEtiquette'); }
  get nom_particulier()         { return this.Etape1FormGroup.get('nom_particulier'); }
  get prenom_particulier()      { return this.Etape1FormGroup.get('prenom_particulier'); }
  get email_particulier()       { return this.Etape1FormGroup.get('email_particulier'); }
  get entite()                  { return this.Etape1FormGroup.get('entite'); }
  get compagnie()               { return this.Etape1FormGroup.get('compagnie'); }
  get autreCompagnie()          { return this.Etape1FormGroup.get('autreCompagnie'); }
  get emailExpert()             { return this.Etape1FormGroup.get('emailExpert'); }
  get typeAnalyse()             { return this.Etape1FormGroup.get('typeAnalyse'); }
  get adresseLigne()            { return this.Etape1FormGroup.get('adresse.adresseLigne1'); }

  // Formulaire Etape 2
  get immatriculation() { return this.Etape2FormGroup.get('immatriculation'); }
  get vin()             { return this.Etape2FormGroup.get('vin'); }
  get energie()         { return this.Etape2FormGroup.get('energie'); }
  get typeMoteur()      { return this.Etape2FormGroup.get('typeMoteur'); }
  get mec()             { return this.Etape2FormGroup.get('mec'); }
  get modele()          { return this.Etape2FormGroup.get('modele'); }
  get marqueVehicule()  { return this.Etape2FormGroup.get('marqueVehicule'); }
  get quantite()        { return this.Etape2FormGroup.get('quantite'); }

  // Formulaire Etape 3
  get typeFluide()    { return this.Etape3FormGroup.get('typeFluide'); }
  get marqueFluide()    { return this.Etape3FormGroup.get('marqueFluide'); }
  get gradeSAE()        { return this.Etape3FormGroup.get('gradeSAE'); }
  get typeCommercial()  { return this.Etape3FormGroup.get('typeCommercial'); }
  get datePrelevement() { return this.Etape3FormGroup.get('datePrelevement'); }
  get organePrelevement() { return this.Etape3FormGroup.get('organePrelevement'); }
  get lieuPrelevement() { return this.Etape3FormGroup.get('lieuPrelevement'); }
  get combustible() { return this.Etape3FormGroup.get('combustible'); }
  get temperaturePrelevement() { return this.Etape3FormGroup.get('temperaturePrelevement'); }
  
  // Formulaire Etape 4
  get commentaire()     { return this.Etape4FormGroup.get('commentaire'); }

  // Formulaire Etape 6
  get analyseRetenue()   { return this.Etape6FormGroup.get('analyseRetenue'); }

  // Tableau des tarifs d'analyse
  displayedColumnsAnalyseAEffectuer   : string[] = ['selecteur', 'categorie', 'designation', 'tarif'];
  displayedColumnsAnalyseRetenue      : string[] = ['selecteur', 'categorie', 'typeAnalyse', 'fluide', 'Organe', 'designation', 'tarif'];
  displayedColumnsAnalyseRetenueAdmin : string[] = ['selecteur', 'categorie', 'designation', 'tarif'];
  dataSource        : any;
  dataSource2       : any;
  dataSourceAnalyseRetenue : any[]=[];

  // Formulaire complet ADELA envoyé au back-end
  idDossier         : number = 0;
  form_adela        : FormulaireAdela = <FormulaireAdela>{};

  isLinear          : boolean = false; // true by default
  modeCreation      : boolean = true;
  
  Etape1FormGroup: any;
  Etape2FormGroup: any;
  Etape3FormGroup: any;
  Etape4FormGroup: any;
  Etape5FormGroup: any;
  Etape6FormGroup: any;
  Etape7FormGroup: any;

  // Liste des interfaces pour les selecteurs/combos
  profilUtilisateur$      : ProfilUtilisateur = <ProfilUtilisateur>{};
  compagnies$             : Compagnie[] = [];
  entites$                : Entite[] = [];
  listeUtilisateur$       : ProfilUtilisateur[] = [];

  // Listes pour le remplissage du formulaire
  listes$                   : ListeParametrage = <ListeParametrage>{};
  typeAnalyses$             : ListeTypeAffaire [] = [];
  typeFluides$              : ListeElements[] = [];
  energies$                 : ListeElements[] = []; 
  usages$                   : ListeElements[] = [];
  incendies$                : ListeElements[] = [];  
  organesPrelevement$       : ListeElements[] = [];   
  lieuxPrelevement$         : ListeElements[] = [];
  combustibles$             : ListeElements[] = [];
  temperaturesPrelevement$  : ListeElements[] = [];
  etats                    : Etat[] = [];
  analyses$                 : Analyse[] = [];
  analyses_all$             : Analyse[] = [];
  addFiles$                 : Fichier[] = [];
  organeParDefaut?          : ListeElements = <ListeElements>{};
  uploadParameter           :UploadParameter  = initialUploadParameter
 disableFields              : boolean = false
  nextAvailableEtiquette    : number=0
 etape1 : boolean = false;
 etape2 : boolean = false;
 etape3 : boolean = false;
 etape4 : boolean = false;
 etape5 : boolean = false;
 adresseParticulier : Adresse = AdresseInit
 etiquettes          : Etiquette[]=[]
 

  constructor(private _fb           : FormBuilder,
              public apiAdela      : ApiAdelaService,
              public apiUtilitaire : ApiUtilitaireService,
              public apiEntite     : ApiEntiteService,
              private apiAuth       : ApiAuthentificationService,
              private apiUser       : ApiUtilisateurService,
              private toast         : PersoSnackbarService,
              private router        : Router,
              private route         : ActivatedRoute,
              private apiHisto      : HitoriqueVehiculeService,
              private MailValidator : MailValidator,
              public dialog         : MatDialog
              ) {
                this.chargeEtat();
                this.chargeListe();
              }

  ngOnInit(): void {   
    // Récupération des informations de l'utilisateur connecté
    this.profilUtilisateur$   = this.apiAuth.userConnect;
    // this.modeCreation = (!this.profilUtilisateur$.droits_utilisateur.est_admin);
    
    this.uploadParameter.activated  = this.modeCreation
    this.uploadParameter.buttonText ='Sélectionner des pièces-jointes'  
       
    // Récupération dans l'URL de l'id du dossier ou du paramètre d'entrée
    const idDossierRoute = this.route.snapshot.params['id'];
    if (idDossierRoute > 0) {
      this.idDossier = idDossierRoute  
    }else{
      this.idDossier = this.idDossierComp;
    }
    
    this.form_adela.idDossier = this.idDossier;

    if(this.form_adela.idDossier != undefined) {
      this.idDossier = this.form_adela.idDossier;
    }


    if(this.idDossier > 0) {
      this.allEntite = true;
    }
        
    // Initialisation des formulaires
    this.Etape1FormGroup = this._fb.group({
      reference             : ['', [Validators.required, this.verificationFormatReference]],
      numEtiquette          : [null, [Validators.required]],
      compagnie             : [null, [Validators.required]],
      autreCompagnie        : ['', [Validators.required]],      
      nom_particulier       : [{value : '', disabled: false}],
      prenom_particulier    : [{value : '', disabled: false}],
      email_particulier     : [{value : '', disabled: false}, [this.MailValidator.checkValidatorMail()]],
      entite                : [null, [Validators.required]],
      emailExpert           : ['', [Validators.required, this.MailValidator.checkValidatorMail()]],
      expertiseJudiciaire   : [false],
      typeAnalyse           : [null, [Validators.required]]
    });

    this.Etape2FormGroup = this._fb.group({
      immatriculation : ['', [Validators.required]],
      vin             : ['', [CheckValidatorService.verificationFormatVIN]],
      energie         : [{value : null, disabled: false}],
      typeMoteur      : [{value : '', disabled: false}],
      kmsHeures       : [0],
      capaciteCarter  : [0.0],
      mec             : [{value : '', disabled: false}],
      modele          : [{value : '', disabled: false}],
      marqueVehicule  : [{value : '', disabled: false}],
      usage           : [<ListeElements>{}],
      incendie        : [<ListeElements>{}],
      appoint         : [false],
      quantite        : [{value : 0.0, disabled: true}]
    });
    this.Etape3FormGroup = this._fb.group({
      typeFluide            : [{value : null, disabled: false}, Validators.required],
      marqueFluide          : ['', [Validators.required]],
      gradeSAE              : [{value: '', disabled: false}],
      organePrelevement     : [{value : null, disabled: false}, Validators.required],
      lieuPrelevement       : [{value : null, disabled: false}, Validators.required],
      combustible           : [{value : null, disabled: this.select_combustible}, Validators.required],
      typeCommercial        : ['',],
      kmsHeures             : [0],
      datePrelevement       : [{value : '', disabled: false}, [this.verificationFormatDatePrelevement]],
      temperaturePrelevement: [{value : null, disabled: false}, Validators.required]
    });  
    this.Etape4FormGroup = this._fb.group({
      commentaire : [''],
      tab_PiecesJointes : [[]]
    });
    

    this.Etape5FormGroup = this._fb.group({
      analyse : [<Analyse>{}]
    });
    this.Etape6FormGroup = this._fb.group({
      analyseRetenue : [<Analyse>{}, [this.verificationFormatAnalyseRetenue.bind(this)]]
    });
    this.Etape7FormGroup = this._fb.group({
      depot_flacon: ['']
    });

    this.chargeListeCabinet();

    if(this.form_adela.idDossier > 0) {
      this.isLinear     = false; // Permet d'accéder à tous les steppers sans contrôle
      this.modeCreation = false; // Masque le bouton valider la demande d'analyse
      this.chargeDossier(this.form_adela.idDossier);
    } else {
      // Récupération des paramètres du formulaire ADELA
      this.ChargeListeCompagnie(); 
      this.chargeListeAnalyse();

      this.Etape1FormGroup.patchValue({
        emailExpert: this.apiAuth.authUser.login
      });
      this.Etape1FormGroup.updateValueAndValidity();
    }  
  }

closeDossier(enregistrer : boolean = false){
  if (enregistrer == true) {
    this.modifierDemandeADELA();
  }

  if (this.modeCreation) {
    // mode création, on arrive du menu, on retourne dans le menu
    this.router.navigate(['/app/home']);
  }else{
    //on est arrivé par un composant parent:
    this.close.next(true)
  }
}

public onSelectEntite(e: any) {  
  
  this.apiAdela.getNextAvailableEtiquette(e.value).subscribe(
    (etiquettes:Etiquette[])=> {
      this.etiquettes = etiquettes
    }
    )
}

  verificationFormatAnalyseRetenue(fcControler: FormControl): any {
    let analyseRetenue  : any = fcControler.value;
    let etatCode : string;

    if(this.Etape6FormGroup == undefined || this.form_adela.idEtatDossier == undefined || this.etats == undefined) {
      return null;
    }

    etatCode = this.getCodeEtat(this.form_adela.idEtatDossier)
    
    if(etatCode == this.apiAdela.CONST_ETAT_ADELA_1_Depose) {
      if(parseInt(analyseRetenue) > 0) {
        return null;
      } else {
        return { erreur: 'Sélectionnez l\'analyse retenue' }
      }
    } 
    
    return null;
  }
  
  verificationFormatReference(fcControler: FormControl): any {
    let reference : string = fcControler.value;
    let a_regex   : any = /^[0-9A-Z]{7,24}$/;
    
    if (!a_regex.test(reference)) {      
      return { erreur: 'Référence de dossier invalide (minimum 7 caractères).' }
    }

    return null;
  }

  verificationFormatDatePrelevement(fcControler: FormControl): any {
    let datePrelevement : Date = new Date(fcControler.value);
    let dateToday       : Date = new Date();
    
    datePrelevement.setHours(0,0,0,0); 

    if(datePrelevement.getTime() > dateToday.getTime()){
      return { erreur: 'La date de prélèvement doit être égale ou inférieure à la date du jour' }
    }
    
    return null;
  }

  verificationFormatNomParticulier(fcControler: FormControl): any {
    let s_nom:    string  = fcControler.value;
    let a_regex:  any     = /^[A-Z\-\ ]{1,50}$/;
    
    if(this.profilUtilisateur$ == undefined) { return null }

    if (this.profilUtilisateur$.droits_utilisateur.droit_module_admin_adela) {
      if (!a_regex.test(s_nom)) {      
        return { erreur: 'Uniquement des lettre, des tirets ou des espaces' }
      }
    }
    
    return null;
  }
  
  verificationFormatPrenomParticulier(fcControler: FormControl): any {
    let s_prenom: string  = fcControler.value;
    let a_regex:  any     = /^[A-Z][a-z\-\ ]{1,50}$/;
    
    if(this.profilUtilisateur$ == undefined) { return null }

    if (this.profilUtilisateur$.droits_utilisateur.droit_module_admin_adela) {
      if (!a_regex.test(s_prenom)) {      
        return { erreur: 'Entre 2 et 50 caractères : lettres, tirets ou espaces' }
      }
    }

    return null;
  }


  onSelectChangeCompagnie(newSelect: any): void {
    if (newSelect != undefined && newSelect.value != null) {
      if(newSelect.value.id != this.const_autre_compagnie) {
        this.Etape1FormGroup.patchValue({
          autreCompagnie: ''
        });
        this.autreCompagnie.disable();
      } else {
        this.autreCompagnie.enable();
      }
      this.Etape1FormGroup.updateValueAndValidity();
    }
  }

  onSelectChangeTypeAnalyse(newSelect: any): void {  
    
    // raz des booleens
    this.form_avarie_sur_vehicule     = false; 
    this.form_avarie_sans_vehicule    = false; 
    this.form_bilan_moteur_preventif  = false; 
    this.form_vehicule_incendie       = false; 
    this.form_huile_neuve             = false;
    this.select_huile_en_service      = false; 

    if(this.form_adela.idDossier == undefined || this.form_adela.idDossier <= 0) {
      // Dans le cas d'un nouveau dossier, lorsqu'on modifie le type d'analyse, toutes les sous-catégories sont réinitialisées      
      this.Etape2FormGroup.patchValue({
        immatriculation : '',
        vin             : '',
        energie         : null,
        typeMoteur      : '',
        kmsHeures       : 0,
        capaciteCarter  : 0.0,
        mec             : '',
        modele          : '',
        marqueVehicule  : '',
        usage           : <ListeElements>{},
        incendie        : <ListeElements>{},
        appoint         : false,
        quantite        : 0.0
      });
  
      this.Etape3FormGroup.patchValue({
        typeFluide            : null,
        marqueFluide          : '',
        gradeSAE              : '',
        organePrelevement     : null,
        lieuPrelevement       : null,
        combustible           : null,
        typeCommercial        : '',
        kmsHeures             : 0,
        datePrelevement       : '',
        temperaturePrelevement: null
      });
      
      this.Etape4FormGroup.patchValue({
        commentaire: '',
        piecesJointes: []
      });

      // raz des formGroup
      this.isDisabledEtape2FormGroup(false);
      this.isDisabledEtape3FormGroup(false);

      // par defaut on ré active tous les required du form group 2
      this.Etape2FormGroup.get('immatriculation').setValidators(Validators.required);
      // this.Etape2FormGroup.get('vin').setValidators(Validators.required);
      this.Etape2FormGroup.get('energie').setValidators(Validators.required);
      this.Etape2FormGroup.get('typeMoteur').setValidators(Validators.required);
      this.Etape2FormGroup.get('mec').setValidators(Validators.required);
      this.Etape2FormGroup.get('modele').setValidators(Validators.required);
      this.Etape2FormGroup.get('marqueVehicule').setValidators(Validators.required);
      this.Etape2FormGroup.get('quantite').setValidators(Validators.required);    
    }

    switch(newSelect.value.id) { 
      case parseInt(this.const_form_avarie_sur_vehicule): {
        this.form_avarie_sur_vehicule    = true;        
        this.isDisabledGradeSAE(this.select_huile_en_service);
        this.isDisabledCombustible(this.select_combustible);
        this.info_bilan_moteur = '';
        break; 
      }
      case parseInt(this.const_form_avarie_sans_vehicule): {
        this.form_avarie_sans_vehicule   = true; 
        this.isDisabledEtape2FormGroup(true);
        this.isDisabledGradeSAE(this.select_huile_en_service);
        this.isDisabledCombustible(this.select_combustible);
        this.info_bilan_moteur = '';
        break; 
      }
      case parseInt(this.const_form_bilan_moteur_preventif): {
        this.form_bilan_moteur_preventif = true; 
        this.isDisabledGradeSAE(this.select_huile_en_service);
        this.isDisabledCombustible(this.select_combustible);
        this.info_bilan_moteur = `Pour rappel, le Bilan Santé Moteur est un contrôle préventif. Ce type d'analyse ne convient absolument pas à une analyse suite à avarie.
        Veuillez également noter que ce type d'analyse ne comporte pas de diagnostic rédigé.`;
        break; 
      }
      case parseInt(this.const_form_vehicule_incendie): {
        this.form_vehicule_incendie      = true; 
        this.isDisabledGradeSAE(this.select_huile_en_service);
        this.isDisabledCombustible(this.select_combustible);
        this.info_bilan_moteur = '';
        break; 
      }
      case parseInt(this.const_form_huile_neuve): {
        this.info_bilan_moteur = '';
        this.form_huile_neuve=true;
        // mise en option des certains required demandé pour l'huile neuve
        this.Etape2FormGroup.get('energie').clearValidators();
        this.Etape2FormGroup.get('typeMoteur').clearValidators();
        this.Etape2FormGroup.get('mec').clearValidators();
        this.Etape2FormGroup.get('modele').clearValidators();
        this.Etape2FormGroup.get('marqueVehicule').clearValidators();
        this.Etape2FormGroup.get('quantite').clearValidators();
        this.isDisabledEtape2FormGroup(false);
        this.isDisabledEtape3FormGroup(true);
        this.isDisabledGradeSAE(false);
        this.isDisabledCombustible(false);        
        break; 
      }
      default: { 
        break; 
      }
    }  

    this.filtrerAnalyses();
    this.Etape5FormGroup.patchValue({
      analyse: <Analyse>{}
    });
  }
  
  getFiles(uploadfiles:UploadFile[]):void{
    this.addFiles$ = this.apiUtilitaire.uploadFilesToFichiers(uploadfiles,'pj')
  }

  onSelectChangeTypeFluide(newSelect: any) {
    if(newSelect.value.id == this.const_huile_en_service) {
      this.select_huile_en_service = true;
    } else {
      this.select_huile_en_service = false;
    }   

    if(newSelect.value.id == this.const_combustible) {
      this.select_combustible = true;
    } else {
      this.select_combustible = false;
    }    

    this.filtrerAnalyses();
    this.Etape5FormGroup.patchValue({
      analyse : <Analyse>{}
    });

    this.isDisabledGradeSAE(this.select_huile_en_service);
    this.isDisabledCombustible(this.select_combustible);
  }

  onSelectChangeOrganePrelevement(newSelect: any){
    this.filtrerAnalyses();
    this.Etape5FormGroup.patchValue({
      analyse : <Analyse>{}
    });
  }

  isDisabledEtape2FormGroup(value: boolean) {
    if(value) {
      this.Etape2FormGroup.controls['energie'].disable();
      this.Etape2FormGroup.controls['typeMoteur'].disable();
      this.Etape2FormGroup.controls['mec'].disable();
      this.Etape2FormGroup.controls['modele'].disable();
      this.Etape2FormGroup.controls['marqueVehicule'].disable();
    } else {
      this.Etape2FormGroup.controls['energie'].enable();
      this.Etape2FormGroup.controls['typeMoteur'].enable();
      this.Etape2FormGroup.controls['mec'].enable();
      this.Etape2FormGroup.controls['modele'].enable();
      this.Etape2FormGroup.controls['marqueVehicule'].enable();
    }
    this.modeModification();
  }

  isDisabledEtape3FormGroup(value: boolean) {
    
    if(value) {
      this.Etape3FormGroup.controls['typeFluide'].disable();
      this.Etape3FormGroup.controls['organePrelevement'].disable();
      this.Etape3FormGroup.controls['lieuPrelevement'].disable();
      this.Etape3FormGroup.controls['datePrelevement'].disable();
      this.Etape3FormGroup.controls['temperaturePrelevement'].disable();
      this.Etape3FormGroup.controls['kmsHeures'].disable();
    } else {
      this.Etape3FormGroup.controls['typeFluide'].enable();
      this.Etape3FormGroup.controls['organePrelevement'].enable();
      this.Etape3FormGroup.controls['lieuPrelevement'].enable();
      this.Etape3FormGroup.controls['datePrelevement'].enable();
      this.Etape3FormGroup.controls['temperaturePrelevement'].enable();
      this.Etape3FormGroup.controls['kmsHeures'].enable();
    }

    this.modeModification();
  }

  isDisabledQuantite(value: boolean) {
    if(value) {
      this.Etape2FormGroup.controls['quantite'].enable();
    } else {
      this.Etape2FormGroup.patchValue({
        quantite: 0.0
      });
      this.Etape2FormGroup.controls['quantite'].disable();      
     }
     this.modeModification();
  }

  isDisabledGradeSAE(value: boolean) {
    
    if(!value) {
      this.Etape3FormGroup.controls['gradeSAE'].enable();
    } else {
      this.Etape3FormGroup.patchValue({
        gradeSAE: ''      
      });
      this.Etape3FormGroup.controls['gradeSAE'].disable();     
     }
     this.modeModification();
  }

  isDisabledCombustible(value: boolean) {
    this.select_combustible = value;
        
    if(this.select_combustible) {
      this.Etape3FormGroup.controls['combustible'].enable();
    } else {
      this.Etape3FormGroup.patchValue({
        combustible: null      
      });
      this.Etape3FormGroup.controls['combustible'].disable();   
    }

    this.modeModification();
  }

  modeModification() {        
    let autoriserEdition: boolean;
    let etatCode : string;

    etatCode = this.getCodeEtat(this.form_adela.idEtatDossier)

    if (this.modeCreation) {
      autoriserEdition = true;
    } else {
      if ( etatCode == this.apiAdela.CONST_ETAT_ADELA_6_Cloture || etatCode == this.apiAdela.CONST_ETAT_ADELA_X_Refuse || etatCode == '' ) {
        autoriserEdition = false; 
      } else {
        autoriserEdition = true;  
      }
    }
  
    if (!autoriserEdition) {
      // Mode lecture seule
      this.disableFields = true //pour le composant enfant (adresse)

      this.Etape1FormGroup.controls['reference'].disable();
      this.Etape1FormGroup.controls['numEtiquette'].disable();
      this.Etape1FormGroup.controls['compagnie'].disable();
      this.Etape1FormGroup.controls['autreCompagnie'].disable();
      this.Etape1FormGroup.controls['nom_particulier'].disable();
      this.Etape1FormGroup.controls['prenom_particulier'].disable();
      this.Etape1FormGroup.controls['email_particulier'].disable();

      this.Etape1FormGroup.controls['entite'].disable();
      this.Etape1FormGroup.controls['emailExpert'].disable();
      this.Etape1FormGroup.controls['expertiseJudiciaire'].disable();
      this.Etape1FormGroup.controls['typeAnalyse'].disable();

      this.Etape2FormGroup.controls['immatriculation'].disable();
      this.Etape2FormGroup.controls['vin'].disable();
      this.Etape2FormGroup.controls['energie'].disable();
      this.Etape2FormGroup.controls['typeMoteur'].disable();
      this.Etape2FormGroup.controls['kmsHeures'].disable();
      this.Etape2FormGroup.controls['capaciteCarter'].disable();
      this.Etape2FormGroup.controls['modele'].disable();
      this.Etape2FormGroup.controls['mec'].disable();
      this.Etape2FormGroup.controls['marqueVehicule'].disable();
      this.Etape2FormGroup.controls['usage'].disable();
      this.Etape2FormGroup.controls['incendie'].disable();
      this.Etape2FormGroup.controls['appoint'].disable();
      this.Etape2FormGroup.controls['quantite'].disable();
      
      this.Etape3FormGroup.controls['typeFluide'].disable();
      this.Etape3FormGroup.controls['marqueFluide'].disable();
      this.Etape3FormGroup.controls['gradeSAE'].disable();
      this.Etape3FormGroup.controls['organePrelevement'].disable();
      this.Etape3FormGroup.controls['lieuPrelevement'].disable();
      this.Etape3FormGroup.controls['combustible'].disable();
      this.Etape3FormGroup.controls['typeCommercial'].disable();
      this.Etape3FormGroup.controls['kmsHeures'].disable();
      this.Etape3FormGroup.controls['datePrelevement'].disable();
      this.Etape3FormGroup.controls['temperaturePrelevement'].disable();

      this.Etape4FormGroup.controls['commentaire'].disable();

      this.Etape5FormGroup.controls['analyse'].disable();
    } else {
      // Mode édition
      this.disableFields = false //pour le composant enfant (adresse)

      this.Etape1FormGroup.controls['reference'].enable();
      this.Etape1FormGroup.controls['numEtiquette'].enable();
      this.Etape1FormGroup.controls['compagnie'].enable();
      // this.Etape1FormGroup.controls['autreCompagnie'].enable();
      this.onSelectChangeCompagnie(this.Etape1FormGroup.controls['compagnie']);
      this.Etape1FormGroup.controls['nom_particulier'].enable();
      this.Etape1FormGroup.controls['prenom_particulier'].enable();
      this.Etape1FormGroup.controls['email_particulier'].enable();
  
      // this.Etape1FormGroup.controls['entite'].enable();
      this.Etape1FormGroup.controls['emailExpert'].enable();
      this.Etape1FormGroup.controls['expertiseJudiciaire'].enable();
      this.Etape1FormGroup.controls['typeAnalyse'].enable();

      this.Etape2FormGroup.controls['immatriculation'].enable();
      this.Etape2FormGroup.controls['vin'].enable();
      this.Etape2FormGroup.controls['energie'].enable();
      this.Etape2FormGroup.controls['typeMoteur'].enable();
      this.Etape2FormGroup.controls['mec'].enable();
      this.Etape2FormGroup.controls['kmsHeures'].enable();
      this.Etape2FormGroup.controls['capaciteCarter'].enable();
      this.Etape2FormGroup.controls['modele'].enable();
      this.Etape2FormGroup.controls['marqueVehicule'].enable();
      this.Etape2FormGroup.controls['usage'].enable();
      this.Etape2FormGroup.controls['incendie'].enable();
      this.Etape2FormGroup.controls['appoint'].enable();
      this.Etape2FormGroup.controls['quantite'].enable();
      
      // this.Etape3FormGroup.controls['typeFluide'].enable();
      this.Etape3FormGroup.controls['marqueFluide'].enable();
      // this.Etape3FormGroup.controls['gradeSAE'].enable();
      // this.Etape3FormGroup.controls['organePrelevement'].enable();
      // this.Etape3FormGroup.controls['lieuPrelevement'].enable();
      // this.Etape3FormGroup.controls['combustible'].enable();
      this.Etape3FormGroup.controls['typeCommercial'].enable();
      this.Etape3FormGroup.controls['kmsHeures'].enable();
      //this.Etape3FormGroup.controls['datePrelevement'].enable();
      // this.Etape3FormGroup.controls['temperaturePrelevement'].enable();

      this.Etape4FormGroup.controls['commentaire'].enable();

      this.Etape5FormGroup.controls['analyse'].enable();
    }
  }

  filtrerAnalyses(){   
    if(!this.modeCreation) {
      return true;
    }
    
    if (this.Etape1FormGroup.controls.typeAnalyse.value != null) {
      this.apiAdela.getAnalyseFromSelection(this.Etape1FormGroup.controls.typeAnalyse.value.id, this.Etape3FormGroup.controls.organePrelevement.value == null ? 0 : this.Etape3FormGroup.controls.organePrelevement.value.id, this.Etape3FormGroup.controls.typeFluide.value == null ? 0 :this.Etape3FormGroup.controls.typeFluide.value.id,1)
      .subscribe(
        (data: Analyse[]) => {
          
          this.analyses$    = data;
          this.dataSource   = this.analyses$;
          return true;
      },
        err => {
          console.error('/!\\ err: ' + err.message);
          return false;
        }
      )
    }
    return false;
  }
  
  public chargeListe() { 
    this.apiAdela.getAllListe()
    .subscribe(
      (data: ListeParametrage) => {
        
        this.listes$                  = data;
        this.combustibles$            = data.tab_Combustible
        this.energies$                = data.tab_Energie;
        this.typeFluides$             = data.tab_Fluide;
        this.incendies$               = data.tab_Incendie;
        this.lieuxPrelevement$        = data.tab_Lieu;
        this.organesPrelevement$      = data.tab_Organe;
        this.temperaturesPrelevement$ = data.tab_Temperature;
        this.typeAnalyses$            = data.tab_Type_Analyse;
        this.usages$                  = data.tab_Usage;
        
        if(this.form_adela.idDossier > 0){
          this.chargeListeAnalyse();                    
        }

        this.selectDefaultValue()
      },
      err => {
        // this.listes$ = {};
        console.error('/!\\ err: ' + err.message);
      }
      )
  }

  public ChargeListeCompagnie() { 
    this.apiUtilitaire.getCompagnie()
      .subscribe(
        (data: Compagnie[] ) => { 
          let compagnieAutre : Compagnie = {
            id          : this.const_autre_compagnie,
            code_gta    : '000',
            nom         : 'Autre',
            actif       : true,
            id_externe  : 0
          };          
          
          this.compagnies$ = data;
          this.compagnies$.push(compagnieAutre);
          
          // Trie du tableau par code GTA croissant
          this.compagnies$ = this.compagnies$.sort((a,b) => {
            if(a.code_gta < b.code_gta) { return -1; }
            if(a.code_gta > b.code_gta) { return 1; }
            return -1;
          });

          if(this.form_adela.idDossier > 0) {
            if (this.form_adela.idCompagnie > 0) {
              this.Etape1FormGroup.patchValue({
                compagnie : this.compagnies$.find(compagnie => compagnie.id == this.form_adela.idCompagnie),
              });
            } else {
              this.Etape1FormGroup.patchValue({
                compagnie : compagnieAutre
              });
            }
            this.onSelectChangeCompagnie(this.Etape1FormGroup.controls.compagnie);            
          }
        },
        err => { 
          this.compagnies$ = [];
          console.error('/!\\ err: ' + err);
        }
      );
  }

  filterEntitesOfType(type: boolean){
    
    if(this.allEntite == false) {
      return this.entites$.filter(entite => entite.EstActif == (!type && entite.EstInscrit == true));
    } else {
      return this.entites$;
    }    
  }

  public chargeListeCabinet() { 
    
    if (this.profilUtilisateur$.droits_utilisateur.droit_module_admin_adela) {
      // mode admin
      if (this.modeCreation) {
        // admin en création il y a toutes les entités
        this.apiEntite.getAllEntite()
        .subscribe(
          (data: Entite[] ) => {
            this.entites$ = data
          },
          err => { 
            console.error('/!\ err: ' + err.message); 
          }
        );
      } else {
        // admin en édition, il y a toutes les entités du créateur de la demande
        this.apiEntite.getEntiteByIDUtilisateur(this.form_adela.idUtilisateur)
        .subscribe(
          (data: Entite[] ) => {
            if(this.form_adela.idDossier > 0){
              this.entites$ = data
            }
          },
          err => { 
            console.error('/!\ err: ' + err.message); 
          }
        );
      }
    } else {
      // L'utilisateur expert n'a accès qu'à ses cabinets
      for(let i = 0; i < this.apiAuth.relUserEnt.length; i++) {
        if(this.apiAuth.relUserEnt[i].est_active) {
          this.entites$.push(this.apiAuth.relUserEnt[i].entite);
        }            
      }
    }
    
  } 

  public chargeListeAnalyse() {
    this.apiAdela.getAnalyse()
      .subscribe(
        (data: Analyse[]) => {
          let etatCode : string;

          this.analyses$ = data;
          this.analyses_all$ = data;
          
          // Trie du tableau par ordre alphabétique
          this.analyses$ = this.analyses$.sort((a,b) => {
            if(a.categorie < b.categorie) { return -1; }
            if(a.categorie > b.categorie) { return 1; }
            return -1;
          });

          // Trie du tableau par ordre alphabétique
          this.analyses_all$ = this.analyses_all$.sort((a,b) => {
            if(a.categorie < b.categorie) { return -1; }
            if(a.categorie > b.categorie) { return 1; }
            return -1;
          });

          this.dataSource   = this.analyses$;
          this.dataSource2  = this.analyses_all$;                              

          if(this.form_adela.idDossier > 0){
            if(this.typeAnalyses$.find(typeAnalyse => typeAnalyse.id == this.form_adela.idtypeAnalyse) != undefined) {
              this.Etape1FormGroup.patchValue({
                typeAnalyse : this.typeAnalyses$.find(typeAnalyse => typeAnalyse.id == this.form_adela.idtypeAnalyse)        
              });
            }
            
            this.Etape2FormGroup.patchValue({
              energie         : this.energies$.find(energie => energie.id == this.form_adela.idEnergie),
              usage           : this.usages$.find(usage => usage.id == this.form_adela.idUsage),
              incendie        : this.incendies$.find(incendie => incendie.id == this.form_adela.idIncendie)            
            });
  
            if(this.typeFluides$.find(fluide => fluide.id == this.form_adela.idFluide) != undefined) {
              this.Etape3FormGroup.patchValue({
                typeFluide : this.typeFluides$.find(fluide => fluide.id == this.form_adela.idFluide),
              });
            }

            if(this.organesPrelevement$.find(organe => organe.id == this.form_adela.idOrgane) != undefined) {
              this.Etape3FormGroup.patchValue({
                organePrelevement : this.organesPrelevement$.find(organe => organe.id == this.form_adela.idOrgane)
              });
            }

            this.Etape3FormGroup.patchValue({
              lieuPrelevement       : this.lieuxPrelevement$.find(lieu => lieu.id == this.form_adela.idLieu),
              combustible           : this.combustibles$.find(combustible => combustible.id == this.form_adela.idCombustible),            
              temperaturePrelevement: this.temperaturesPrelevement$.find(temperature => temperature.id == this.form_adela.idTemperature)        
            });
            
            etatCode = this.getCodeEtat(this.form_adela.idEtatDossier);

            if(etatCode === this.apiAdela.CONST_ETAT_ADELA_1_Depose) {
              this.dataSource = this.analyses$;
            }else{
              this.dataSource2 = this.analyses$.filter((dataSource: Analyse) => dataSource.id == this.form_adela.idPrestationRetenue)
              //Dans le cas où on est en admin et qu'il faut alimenter la 2ème partie (la partie admin) du template:
              this.dataSourceAnalyseRetenue = this.dataSource2
            }

            // On active la sélection des combos qui servent à filtrer la table des analyses
            this.onSelectChangeTypeAnalyse({value : {id : this.form_adela.idtypeAnalyse}});
            this.onSelectChangeOrganePrelevement({value: {id : this.form_adela.idOrgane}});
            this.onSelectChangeTypeFluide({value :{id : this.form_adela.idFluide}});            
            this.filtrerAnalyses();

            // Puis on sélectionne l'analyse à effectuer enregistrée
            let analyseTmp : any = this.dataSource.find((dataSource: Analyse) => dataSource.id == parseInt(this.form_adela.idPrestationDemandée));
            if(analyseTmp != undefined){
              this.Etape5FormGroup.patchValue({
                analyse : analyseTmp.id
              });
            }            
            this.dataSource = this.analyses$.filter((dataSource: Analyse) => dataSource.id == parseInt(this.form_adela.idPrestationDemandée))

            // Puis on sélectionne l'analyse retenue enregistrée
            let analyseTmp2 : any
            analyseTmp2  = this.dataSource2.find((dataSource: Analyse) => dataSource.id == this.form_adela.idPrestationRetenue);              
            
            if(analyseTmp2 != undefined){
              //Pour la partie admin :
              this.dataSourceAnalyseRetenue=[analyseTmp2]
              this.Etape6FormGroup.patchValue({
                analyseRetenue : analyseTmp2.id
              });
            }            
          }
        },
        (err) => { 
          this.analyses$ = [];
          console.error('/!\\ error getAnalyse: '+err);
        }
      );
  }

  public chargeUtilisateur(id: number) {
    this.apiUser.getUtilisateur(id)
      .subscribe(
        (data: ProfilUtilisateur) => {
          this.Etape1FormGroup.patchValue({
            emailExpert : data.email
          });
        },
        (err) => { console.error('/!\\ error getAnalyse: '+err);}
      );
  }

  public chargeDossier(id: number) {
    this.apiAdela.getDossier(id)
      .subscribe(
        (data: any) => {
 
          this.form_adela = data;

          if (data.estFacturee) {
            this.form_lecture_seule_analyse_retenue = true;
            this.Etape6FormGroup.controls['analyseRetenue'].disable();
          } else {
            this.form_lecture_seule_analyse_retenue = false;
            this.Etape6FormGroup.controls['analyseRetenue'].enable();
          }

          this.bindFormAdelaFromDataSource(data);
          this.modeModification();          
          this.chargeUtilisateur(this.form_adela.idUtilisateur);          
          setTimeout(()=>{}, 2500); // Temporisation de 2,5s pour démo (surcharge requête serveur)
          this.ChargeListeCompagnie();
          setTimeout(()=>{}, 2500); // Temporisation de 2,5s pour démo (surcharge requête serveur)           
          this.affichageStepConfirmationAnalyse();
          //Pièces jointes
          this.form_adela.tab_PiecesJointes        = data.st_Dossier.tab_PiecesJointes

          this.Etape1FormGroup.patchValue({
            reference               : this.form_adela.referenceDossierExpert,
            numEtiquette            : this.form_adela.numEtiquette,
            autreCompagnie          : this.form_adela.autreCompagnie,
            nom_particulier         : this.form_adela.nom_particulier,
            prenom_particulier      : this.form_adela.prenom_particulier,
            email_particulier       : this.form_adela.email_particulier,
            expertiseJudiciaire     : this.form_adela.expertiseJudiciaire,
            entite                  : this.form_adela.idEntite
          });

          this.Etape2FormGroup.patchValue({
            immatriculation : this.apiAdela.getImmatNonFormated(this.form_adela.immatriculation),
            vin             : this.form_adela.vin,
            typeMoteur      : this.form_adela.typeMoteur,
            kmsHeures       : this.form_adela.kilometrage,
            capaciteCarter  : this.form_adela.capaciteCarter,
            mec             : this.form_adela.dateMEC.split("T")[0],
            modele          : this.form_adela.modele,
            marqueVehicule  : this.form_adela.marqueVehicule,
            appoint         : this.form_adela.appoints,
            quantite        : this.form_adela.quantiteAppoints
          });
    
          this.Etape3FormGroup.patchValue({
            marqueFluide          : this.form_adela.marqueFluide,
            gradeSAE              : this.form_adela.gradeSAE,            
            typeCommercial        : this.form_adela.typeCommercialFluide,
            kmsHeures             : this.form_adela.kmsDerniereVidange,
            datePrelevement       : this.form_adela.datePrelevement.split("T")[0]
          });
    
          this.Etape4FormGroup.patchValue({
            commentaire: this.form_adela.commentaire,
            tab_PiecesJointes: this.addFiles$
          });    
          
          this.Etape7FormGroup.patchValue({
            depot_flacon : this.form_adela.dateDepotFlacon.split("T")[0]
          });

        },
        (err) => { console.error('/!\\ error getDossier: '+err);}
      );
  }

  public chargeEtat() {
    this.apiAdela.getEtat()
      .subscribe(
        (data: Etat[]) => {
          this.etats = [];
          this.etats.push(...data);
        },
        (err) => { console.error('/!\\ error getEtat: '+err);}
      );
  }

  public affichageStepConfirmationAnalyse() {
    let etat : any = this.etats.find(etat => etat.id == this.form_adela.idEtatDossier);
    if(etat != undefined) {
      if(etat.code === this.apiAdela.CONST_ETAT_ADELA_1_Depose) {
        // L'état du dossier est 'DEPOSE' donc on affiche pour l'administrateur l'étape de confirmation d'analyse
        this.form_confirmation_analyse = true; 
      } else {
        this.form_confirmation_analyse = false;
      }

      if (this.modeCreation) {
        this.Etape1FormGroup.controls['entite'].enable();
      } else {
        this.Etape1FormGroup.controls['entite'].disable();
      }

/*       // L'état du dossier est 'VALIDÉ' ou 'ATTENTE_ECHANTILLON' ou 'ANALYSE' donc onc affiche pour l'administrateur l'analyse retenue en lecture seule
      if(etat.code === this.apiAdela.CONST_ETAT_ADELA_2_Valide || etat.code === this.apiAdela.CONST_ETAT_ADELA_3_EnAttenteEchantillon || etat.code === this.apiAdela.CONST_ETAT_ADELA_4_EnCoursAnalyse) {
        this.Etape6FormGroup.controls['analyseRetenue'].disable();
        this.form_lecture_seule_analyse_retenue = true;
      } else {
        this.Etape6FormGroup.controls['analyseRetenue'].enable();
        this.form_lecture_seule_analyse_retenue = false;
      } */

      // L'état du dossier est 'DIAGNOSTIC' ou 'CLOTURE'
      if(etat.code === this.apiAdela.CONST_ETAT_ADELA_5_EnCoursDiagnostic || etat.code === this.apiAdela.CONST_ETAT_ADELA_6_Cloture) {
        this.form_resultat_analyse = true;
        // this.Etape6FormGroup.controls['analyseRetenue'].disable();
        // this.form_lecture_seule_analyse_retenue = true;
      } else {
        this.form_resultat_analyse = false;
      }
     
    } else {
      this.form_confirmation_analyse = false;
      console.error('Etat de dossier inconnu.');
    }
    
  }

  public bindFormAdelaFromDataSource(data: any) {
    this.form_adela.idDossier               = data.st_Dossier.idDossier;
    this.form_adela.referenceDossierExpert  = data.st_Dossier.referenceDossierExpert;
    this.form_adela.numEtiquette            = data.st_Dossier.numEtiquette;
    this.form_adela.idEtatDossier           = data.st_Dossier.idEtatDossier;
    this.form_adela.habilitation            = data.st_Dossier.habilitation;
    this.form_adela.idCompagnie             = data.st_Dossier.idCompagnie;  
    this.form_adela.autreCompagnie          = data.st_Dossier.autreCompagnie;
    this.form_adela.idUtilisateur           = data.st_Dossier.idUtilisateur;
    this.form_adela.idEntite                = data.st_Dossier.idEntite;
    this.form_adela.idSousEntite            = data.st_Dossier.idSousEntite;
    this.form_adela.expertiseJudiciaire     = data.st_Dossier.expertiseJudiciaire;
    this.form_adela.idtypeAnalyse           = data.st_Dossier.idTypeAnalyse;
    this.form_adela.immatriculation         = data.st_Dossier.immatriculation;
    this.form_adela.vin                     = data.st_Dossier.vin;
    this.form_adela.marqueVehicule          = data.st_Dossier.marqueVehicule;
    this.form_adela.modele                  = data.st_Dossier.modele;
    this.form_adela.dateMEC                 = data.st_Dossier.dateMEC;
    this.form_adela.dateDepotFlacon         = data.st_Dossier.dateDepotFlacon
    this.form_adela.idEnergie               = data.st_Dossier.idEnergie;
    this.form_adela.idUsage                 = data.st_Dossier.idUsage;
    this.form_adela.idOrgane                = data.st_Dossier.idOrgane;
    this.form_adela.kilometrage             = data.st_Dossier.kilometrage;
    this.form_adela.capaciteCarter          = data.st_Dossier.capaciteCarter;
    this.form_adela.marqueFluide            = data.st_Dossier.marqueFluide;
    this.form_adela.typeCommercialFluide    = data.st_Dossier.typeCommercialFluide;
    this.form_adela.gradeSAE                = data.st_Dossier.gradeSAE;
    this.form_adela.appoints                = data.st_Dossier.appoints;
    this.form_adela.quantiteAppoints        = data.st_Dossier.quantiteAppoints;
    this.form_adela.kmsDerniereVidange      = data.st_Dossier.kmsDerniereVidange;
    this.form_adela.idLieu                  = data.st_Dossier.idLieu;
    this.form_adela.datePrelevement         = data.st_Dossier.datePrelevement;
    this.form_adela.idTemperature           = data.st_Dossier.idTemperature;
    this.form_adela.commentaire             = data.st_Dossier.commentaire;
    this.form_adela.idFluide                = data.st_Dossier.idFluide;
    this.form_adela.idCombustible           = data.st_Dossier.idCombustible;
    this.form_adela.idPrestationDemandée    = data.st_Dossier.idPrestationDemandee;
    this.form_adela.codePrestationDemandée  = data.st_Dossier.codePrestationDemandee;
    this.form_adela.idPrestationDemandeeAd  = data.st_Dossier.idPrestationDemandeeAd;
    this.form_adela.idPrestationRetenue     = data.st_Dossier.idPrestationRetenue;
    this.form_adela.idIncendie              = data.st_Dossier.idIncendie;
    this.form_adela.typeMoteur              = data.st_Dossier.typeMoteur;
    this.form_adela.nom_particulier         = data.st_Dossier.nom_particulier;
    this.form_adela.prenom_particulier      = data.st_Dossier.prenom_particulier;
    this.form_adela.email_particulier       = data.st_Dossier.email_particulier;
    
    this.adresseParticulier = {...this.adresseParticulier
                                ,"ligne1":data.st_Dossier.adresse_particulier
                                ,"cp":data.st_Dossier.code_postal_particulier
                                ,"ville":data.st_Dossier.ville_particulier
                              }
    
    if(this.form_adela.idFluide.toString() == this.const_huile_en_service) {
      this.select_huile_en_service = true;
    } else {
      this.select_huile_en_service = false;
    }   

    if(this.form_adela.idFluide.toString() == this.const_combustible) {
      this.select_combustible = true;
    } else {
      this.select_combustible = false;
    }  

  }

  public bindFormAdela() {
    this.form_adela.idDossier = this.form_adela.idDossier > 0 ? this.form_adela.idDossier : 0;
    this.form_adela.idtypeAnalyse = this.Etape1FormGroup.controls.typeAnalyse.value.id;            

    // 1.
    this.form_adela.referenceDossierExpert  = this.Etape1FormGroup.controls.reference.value;
    this.form_adela.numEtiquette            = this.Etape1FormGroup.controls.numEtiquette.value;
    
    // est-ce une compagnie d'assurance existante ?
    if(this.Etape1FormGroup.controls.compagnie.value.id == this.const_autre_compagnie){
      this.form_adela.idCompagnie           = 0
      this.form_adela.autreCompagnie        = this.Etape1FormGroup.controls.autreCompagnie.value;
    } else {
      this.form_adela.idCompagnie           = this.Etape1FormGroup.controls.compagnie.value.id;
      this.form_adela.autreCompagnie        = '';
    }
    
    // est-ce un particulier ?
    
      this.form_adela.nom_particulier         = this.Etape1FormGroup.controls.nom_particulier?    this.Etape1FormGroup.controls.nom_particulier.value     : "";
      this.form_adela.prenom_particulier      = this.Etape1FormGroup.controls.prenom_particulier? this.Etape1FormGroup.controls.prenom_particulier.value  : "";
      this.form_adela.email_particulier       = this.Etape1FormGroup.controls.email_particulier ? this.Etape1FormGroup.controls.email_particulier.value   : "";

      if (this.Etape1FormGroup.controls['adresse']) {        
        this.form_adela.adresse_particulier     = this.Etape1FormGroup.controls['adresse'].controls['adresseLigne1'].value;
        this.form_adela.code_postal_particulier = this.Etape1FormGroup.controls['adresse'].controls['adresseCP'].value
        this.form_adela.ville_particulier       = this.Etape1FormGroup.controls['adresse'].controls['adresseVille'].value;
      }
    

    this.form_adela.idEntite                = this.Etape1FormGroup.controls.entite.value;
    this.form_adela.habilitation            = this.Etape1FormGroup.controls.entite.value.habilitation;
    this.form_adela.idUtilisateur           = this.profilUtilisateur$.id;
    // this.form_adela.idEtatDossier           = 8;
    this.form_adela.idEtatDossier           = this.getIDEtat(this.apiAdela.CONST_ETAT_ADELA_1_Depose);
    this.form_adela.expertiseJudiciaire     = this.Etape1FormGroup.controls.expertiseJudiciaire.value;
    
    // [2. VEHICULE] (optionnel)
    if (this.form_avarie_sans_vehicule == false) {
      this.form_adela.immatriculation         = this.apiAdela.getImmatNonFormated(this.Etape2FormGroup.controls.immatriculation.value);
      this.form_adela.vin                     = this.Etape2FormGroup.controls.vin.value;
      this.form_adela.marqueVehicule          = this.Etape2FormGroup.controls.marqueVehicule.value;
      this.form_adela.modele                  = this.Etape2FormGroup.controls.modele.value;
      this.form_adela.dateMEC                 = this.Etape2FormGroup.controls.mec.value;
      this.form_adela.idEnergie               = this.Etape2FormGroup.controls.energie.value == undefined ?  0 : this.Etape2FormGroup.controls.energie.value.id;
      this.form_adela.idUsage                 = this.Etape2FormGroup.controls.usage.value == undefined ?  0 : this.Etape2FormGroup.controls.usage.value.id;
      this.form_adela.kilometrage             = this.Etape2FormGroup.controls.kmsHeures.value;
      this.form_adela.idIncendie              = this.Etape2FormGroup.controls.incendie.value == undefined ?  0 : this.Etape2FormGroup.controls.incendie.value.id;
      this.form_adela.typeMoteur              = this.Etape2FormGroup.controls.typeMoteur.value;     
    
          // ??? ou le placer ???
      this.form_adela.capaciteCarter          = this.Etape2FormGroup.controls.capaciteCarter.value;
    }
    

    // [2. FLUIDE]
    this.form_adela.idFluide                = this.Etape3FormGroup.controls.typeFluide.value == undefined ?  0 : this.Etape3FormGroup.controls.typeFluide.value.id;
    this.form_adela.marqueFluide            = this.Etape3FormGroup.controls.marqueFluide.value;
    this.form_adela.gradeSAE                = this.Etape3FormGroup.controls.gradeSAE.value;
    this.form_adela.idOrgane                = this.Etape3FormGroup.controls.organePrelevement.value == undefined ?  0 : this.Etape3FormGroup.controls.organePrelevement.value.id;
    this.form_adela.idLieu                  = this.Etape3FormGroup.controls.lieuPrelevement.value == undefined ?  0 : this.Etape3FormGroup.controls.lieuPrelevement.value.id;
    this.form_adela.idCombustible           = this.Etape3FormGroup.controls.combustible.value == undefined ?  0 : this.Etape3FormGroup.controls.combustible.value.id;
    this.form_adela.typeCommercialFluide    = this.Etape3FormGroup.controls.typeCommercial.value;
    this.form_adela.kmsDerniereVidange      = this.Etape3FormGroup.controls.kmsHeures.value;
    this.form_adela.datePrelevement         = this.Etape3FormGroup.controls.datePrelevement.value;
    this.form_adela.idTemperature           = this.Etape3FormGroup.controls.temperaturePrelevement.value == undefined ?  0 : this.Etape3FormGroup.controls.temperaturePrelevement.value.id;

    this.form_adela.appoints                = this.Etape2FormGroup.controls.appoint.value;
    this.form_adela.quantiteAppoints        = this.Etape2FormGroup.controls.quantite.value;
    
    // 3. COMMENTAIRE
    this.form_adela.commentaire             = this.Etape4FormGroup.controls.commentaire.value;
    this.form_adela.tab_PiecesJointes       = this.addFiles$;
    
    // 4. ANALYSE A EFFECTUER
    let analyseAEffectuer : any = this.analyses$.find(analyse => analyse.id === this.Etape5FormGroup.controls.analyse.value);
    if (analyseAEffectuer != undefined ) {
      this.form_adela.idPrestationDemandée    = analyseAEffectuer.id;        
      this.form_adela.codePrestationDemandée  = analyseAEffectuer.code;
    }

    this.form_adela.tpe_code               = this.apiAdela.CONST_TYPE_ELEMENT_FLUIDE;
  }

  public enregistrerAnalyse(){
    
    let analyseAEffectuer : any = this.analyses$.find(analyse => analyse.id === this.Etape5FormGroup.controls.analyse.value);
    
    if(analyseAEffectuer.id > 0 || analyseAEffectuer.id == -1){
      this.waiting_Save = true;
      this.bindFormAdela();
      
      this.apiAdela.enregistrerAnalyse(this.form_adela).pipe(
        switchMap((data) => {
          this.toast.showInfo("Votre demande d'analyse a bien été prise en compte.");
          this.idDossier = data.idDossier;
      
          // Conditionne l'envoi des pièces jointes : seulement si `addFiles$` contient des fichiers
          return iif(
            () => this.addFiles$.length > 0,
            from(this.addFiles$).pipe(
              concatMap((element) =>
                this.apiAdela.envoiPieceJointe(this.idDossier, this.form_adela.idEntite, element).pipe(
                  catchError((err) => {
                    console.error(err);
                    this.toast.showError(err.error);
                    throw err; // Arrête la séquence en cas d'erreur
                  })
                )
              )
            ),
            of(null) // Retourne un observable vide si `addFiles$` est vide, sans appeler le web-service
          );
        }),
        finalize(() => {
          this.closeDossier();
          this.waiting_Save = false;
        }),
        catchError((err) => {
          console.error(err);
          this.toast.showError(err.error? err.error : 'L\'opération a échoué ');
          this.waiting_Save = false;
          return of(null); // Termine la chaîne en cas d'erreur
        })
      ).subscribe();

    } else{
      this.toast.showInfo("Sélectionnez une analyse à effectuer.")
    }            
  }

  public refuserDemandeADELA() {
    this.waitingRefused = true
    
    // this.form_adela.idEtatDossier = 9;
    this.form_adela.idEtatDossier = this.getIDEtat(this.apiAdela.CONST_ETAT_ADELA_X_Refuse);

    this.apiAdela.refuserDemandeADELA(this.form_adela.idDossier, this.form_adela)
      .subscribe(
        (data : FormulaireAdela) => { 
          this.toast.showInfo("Demande d'analyse a bien été refusé.")
          this.closeDossier();
          this.waitingRefused = false;
        },
        (err) => { 
          console.error('/!\\ error refuserDemandeADELA: '+err);
          this.waitingRefused = false;
        }
      );
  }

  public validerDemandeADELA() {
    this.waitingAccepted = true;

    // Non, c'est dégueulasse, on prod l'ID validé est 1 et non pas 2 
    // this.form_adela.idEtatDossier = 2;
    this.form_adela.idEtatDossier = this.getIDEtat(this.apiAdela.CONST_ETAT_ADELA_2_Valide);
    
    this.form_adela.idPrestationDemandeeAd  = parseInt(this.form_adela.idPrestationDemandée);

    let analyseRetenue : any = this.analyses_all$.find(analyse => analyse.id === this.Etape6FormGroup.controls.analyseRetenue.value);
    
    if (this.modeCreation == true || this.apiUtilitaire.isMobileFormat() == true) {
      this.form_adela.idPrestationRetenue     = analyseRetenue.id;
    }else{
      this.form_adela.idPrestationRetenue     = this.dataSourceAnalyseRetenue[0].id;
    }

    this.apiAdela.validerDemandeADELA(this.form_adela.idDossier, this.form_adela)
    .subscribe(
      (data : FormulaireAdela) => { 
        this.toast.showInfo("Demande d'analyse a bien été validé.")
        this.router.navigate(['/app/home']);
        this.waitingAccepted = false;
      },
      (err) => { 
        console.error('/!\\ error validerDemandeADELA: '+err);
        this.waitingAccepted = false;
      }
    );

  }

  public modifierDemandeADELA() {
    this.waitingModified = true;

    this.bindFormAdela();

    let analyseRetenue : any = this.analyses_all$.find(analyse => analyse.id === this.Etape6FormGroup.controls.analyseRetenue.value);

    this.form_adela.idPrestationDemandeeAd  = parseInt(this.form_adela.idPrestationDemandée);
    if (this.modeCreation == true || this.apiUtilitaire.isMobileFormat() == true) {
      this.form_adela.idPrestationRetenue     = analyseRetenue.id;
    }else{
      this.form_adela.idPrestationRetenue     = this.dataSourceAnalyseRetenue[0].id;
    }
   
    this.apiAdela.modifierDemandeADELA(this.form_adela.idDossier, this.form_adela).pipe(
      switchMap((data: FormulaireAdela) => {
        this.toast.showInfo("Demande d'analyse a bien été modifiée.");
    
        // Conditionne l'envoi des pièces jointes : seulement si `addFiles$` contient des fichiers
        return iif(
          () => this.addFiles$.length > 0,
          from(this.addFiles$).pipe(
            concatMap((element) =>
              this.apiAdela.envoiPieceJointe(this.idDossier, this.form_adela.idEntite, element).pipe(
                catchError((err) => {
                  console.error(err);
                  this.toast.showError(err.error);
                  throw err;
                })
              )
            )
          ),
          of(null) // Retourne un observable vide si `addFiles$` est vide
        );
      }),
      finalize(() => {
        this.closeDossier();
        this.waitingModified = false;
      }),
      catchError((err) => {
        this.waitingModified = false
        console.error('/!\\ error modifierDemandeADELA: ' + err);
        this.toast.showError("Une erreur est survenue lors de la modification de la demande d'analyse.");
        return of(null);
      })
    ).subscribe();
  }
  
  public selectDefaultValue() {

    if (this.idDossier === 0) {
      this.organeParDefaut = this.organesPrelevement$.find(element => element.code === this.const_organe_defaut)
      this.Etape3FormGroup.patchValue({
        organePrelevement : this.organeParDefaut
      });
    }
  }

  public getIDEtat(codeEtatDossier: string) : number {
    let etat : any = this.etats.find(MonEtat => MonEtat.code === codeEtatDossier);
    return etat.id;
  }

  public getCodeEtat(idEtatDossier: Number) : string {
    let etat : any = this.etats.find(etat => etat.id == idEtatDossier);
    if(etat != undefined) {
      return etat.code
    } else {
      return ''
    }
  }

  getInfosVehicule(event:any){
    if (event.target) {
      const Immat = event.target.value
      this.waitingInfosVehicule = true;
      this.apiHisto.getInfosImmatriculation(Immat).subscribe(
        (histoVehicule : HistoriqueVehicule)=> {
          this.setInfosVehicules(histoVehicule);
          this.waitingInfosVehicule = false;
        }
      )
    }
  }

  setInfosVehicules(histoVehicule:HistoriqueVehicule){
    
    this.Etape2FormGroup.patchValue({
      vin             : histoVehicule.vin,
      // Si le code énergie est vide, on recherche avec le libelle sinon on cherche avec le code
      energie         : histoVehicule.code_energie == '' ? this.energies$.find(energie => energie.nom == histoVehicule.energie) : this.energies$.find(energie => energie.code == histoVehicule.code_energie),
      mec             : histoVehicule.mec,
      modele          : histoVehicule.modele,
      marqueVehicule  : histoVehicule.marque,
    });
  }

  ngOnDestroy() {
    
  }

  getTypeFluide(idFluide:number) {
    let typeFluide: string = "";
    let fluide : ListeElements | undefined;

    fluide = this.typeFluides$.find(fluide => fluide.id == idFluide);

    if (fluide != undefined) {
      typeFluide = fluide.nom;  
    }

    return typeFluide
  }

  getOrganePrelevement(idOrgane:number) {
    let libelleOrgane: string = "";
    let Organe : ListeElements | undefined;

    Organe = this.organesPrelevement$.find(Organe => Organe.id == idOrgane);

    if (Organe != undefined) {
      libelleOrgane = Organe.nom; 
    }

    return libelleOrgane
  }

  getTypeAnalyse(idTypeAnalyse:number) {
    let LibelleTypeAnalyse: string = "";
    let typeAnalyse : ListeTypeAffaire | undefined;

    typeAnalyse = this.typeAnalyses$.find(typeAnalyse => typeAnalyse.id == idTypeAnalyse);

    if (typeAnalyse != undefined) {
      LibelleTypeAnalyse = typeAnalyse.nom; 
    }

    return LibelleTypeAnalyse
  }

  openSelectionDialog() {
    const dialogRef = this.dialog.open(AnalyseAdelaSelectionComponent, {
      width: '1200px',
      data: {
        analyses: this.dataSource2,
        getTypeFluide: this.getTypeFluide.bind(this),
        getOrganePrelevement: this.getOrganePrelevement.bind(this),
        getTypeAnalyse: this.getTypeAnalyse.bind(this),
      }
    });

    dialogRef.afterClosed().subscribe((result: Analyse | undefined) => {
      if (result) {
        this.dataSourceAnalyseRetenue = [result]
      }
    });
  }
  
  dowloadFile(fileName:string){
    
    this.apiAdela.downloadPieceJointe(this.form_adela.idEntite,this.form_adela.idDossier,fileName)
    .subscribe(
      (data: any) => {
        saveAs(data, fileName);
        
        this.toast.showInfo('Document téléchargé.');
      }
    )
  }

  nouvelEtat(nouveauCodeEtatDossier : string) {
    if (nouveauCodeEtatDossier != '') {
      this.form_adela.idEtatDossier = this.getIDEtat(nouveauCodeEtatDossier);
      
      //Réactivation des champs
      this.isDisabledEtape2FormGroup(false);
      this.isDisabledEtape3FormGroup(false);
      this.onSelectChangeTypeAnalyse({value : {id : this.form_adela.idtypeAnalyse}});
      this.onSelectChangeTypeFluide({value :{id : this.form_adela.idFluide}});

      this.Etape1FormGroup.updateValueAndValidity();
      this.Etape2FormGroup.updateValueAndValidity();
      this.Etape3FormGroup.updateValueAndValidity();
      this.Etape4FormGroup.updateValueAndValidity();
      this.Etape5FormGroup.updateValueAndValidity();
      this.Etape6FormGroup.updateValueAndValidity();
      this.Etape7FormGroup.updateValueAndValidity();
    }
  }
}